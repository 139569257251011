import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const WhatToDoCard: React.FC<Props> = ({
  title,
  description,
  ...containerProps
}): JSX.Element => {
  const titleVariant = useBreakpointValue({ base: 'titleXSMedium', md: 'titleSBold' });
  const descriptionVariant = useBreakpointValue({ base: 'bodySBook', md: 'bodyMBook' });

  return (
    <Container
      backgroundColor="greytones.background"
      borderRadius="24px"
      alignItems="center"
      width="100%"
      maxWidth="580px"
      padding="24px"
      {...containerProps}
    >
      <Image name="magicWand" width="24px" height="24px" marginBottom={16} />
      <Text value={title} variant={titleVariant} marginBottom={16} textAlign="center" />
      <Text value={description} variant={descriptionVariant} textAlign="center" />
    </Container>
  );
};
