import * as React from 'react';
import { useIntl } from 'react-intl';

import { ScreenName } from 'src/types/screen-name';
import { ItalyGDPRContainer } from 'src/_italy/gdpr-consent/italy-gdpr-consent-container';

import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';
import navigate, { Action } from '../../navigate';

export const toItalyGDPRConsent: Action = navigate(ScreenName.ItalyGDPRConsent, {
  name: AnalyticsEvent.sectionShown,
  params: { sectionName: ScreenName.ItalyGDPRConsent },
});

export const ItalyGDPRConsent = (): JSX.Element => {
  const intl = useIntl();

  useDefaultNavigationOptions(undefined, {
    headerBackTitle: intl.formatMessage({
      id: 'it.gdpr-screen.nav.title',
    }),
  });

  return <ItalyGDPRContainer />;
};
