import React, { useCallback } from 'react';
import { StyleSheet, Pressable, Linking } from 'react-native';

import { Box, SmallSecondaryText, Image } from '../core';

type Props = {
  translationId: string;
  externalLink?: string;
  onPress?: () => any;
};
const style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
  },
  iconContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 20,
    maxHeight: 20,
    marginHorizontal: 4,
  },
});
export const FooterLink = ({ translationId, externalLink, onPress }: Props) => {
  const handlePress = useCallback(() => {
    if (externalLink) Linking.openURL(externalLink);
    if (onPress) onPress();
  }, [externalLink, onPress]);
  return (
    <Pressable onPress={handlePress} style={style.container}>
      <SmallSecondaryText id={translationId} />
      {externalLink && (
        <Box style={style.iconContainer}>
          <Image name="sidebar-svg.external-link" />
        </Box>
      )}
    </Pressable>
  );
};
