import * as React from 'react';

import { Footer } from '../../components/footer';

type Props = {
  onDownloadPdf: () => void;
};
export const DownloadPdfButton = ({ onDownloadPdf }: Props) => (
  <Footer
    applyContentSeparation
    translationKey="status.navigation.complete.title"
    onPress={onDownloadPdf}
  />
);
