import { LoggerInstance } from 'src/taxfix-business-logic/utils/logger';

function response(error: unknown): error is { response: { status: number } } {
  return typeof error === 'object' && error !== null && 'response' in error;
}

function message(error: unknown): error is Error {
  return error instanceof Error && typeof error.message === 'string';
}

export class SPIDLoginError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'IFrameSPIDLoginError';
  }
}

export const SPIDLoginErrorMessages = {
  tokenizeCredentialsRequestError: 'Error while creating tokenized credentials request',
  spidLoginUrlLoadError: 'SPIDLoginUrl failed to load',
};

class PrefillDocumentDownloadError extends Error {
  cause?: unknown;

  constructor(message: string, options?: { cause?: unknown }) {
    super(message);
    this.name = 'PrefillDocumentDownloadError';

    if (options?.cause) {
      this.cause = options.cause;
    }
  }
}

const prefillDocumentDownloadErrorMessages = {
  badGateway: 'Bad gateway error while downloading prefill documents from CWBI',
  default: 'Error while downloading prefill documents from CWBI',
  forbidden: 'Expired or invalid tokenized credential',
  networkError: 'Unable to reach the server or lost connection',
  timeoutFromFeatureFlag: 'Request timed out from feature flag',
  timeoutWithMessage: 'Request timed out without status code',
  timeoutWithStatusCode: 'Request timed out with status code 524',
  unknown: 'Web server is returning an unknown error',
};

const HttpStatusCodeMessages: Record<number, string> = {
  403: prefillDocumentDownloadErrorMessages.forbidden,
  502: prefillDocumentDownloadErrorMessages.badGateway,
  520: prefillDocumentDownloadErrorMessages.unknown,
  524: prefillDocumentDownloadErrorMessages.timeoutWithStatusCode,
};

export const logPrefillDocumentDownloadError = (logger: LoggerInstance, error: unknown): void => {
  let errorMessage = prefillDocumentDownloadErrorMessages.default;

  if (response(error)) {
    errorMessage = HttpStatusCodeMessages[error.response?.status] || errorMessage;
  } else if (message(error)) {
    if (error.message === 'FeatureFlagTimeoutError') {
      errorMessage = prefillDocumentDownloadErrorMessages.timeoutFromFeatureFlag;
    } else if (error.message.includes('timeout')) {
      errorMessage = prefillDocumentDownloadErrorMessages.timeoutWithMessage;
    } else if (error.message === 'Network Error') {
      errorMessage = prefillDocumentDownloadErrorMessages.networkError;
    }
  }

  logger.error(
    new PrefillDocumentDownloadError(errorMessage, {
      cause: error,
    }),
  );
};
