import { useBreakpointValue } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import { Button, Container, NBTheme, Text, TextLink } from 'src/taxfix-components/src';
import { DocumentUploadMode, flagsQuestionIds } from 'src/common/constants-it';
import { CheckboxRow } from 'src/components/checkbox/checkbox-row';
import { useNavigation } from 'src/hooks/navigation-hook';
import { actions as statusActions, NavigationLink } from 'src/stores/modules/status';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getNavigationActions, getBoundNavigationsActions } from 'src/routes/config-util';

type Props = {
  quizmasterLight: QuizmasterLight;
  navigationLink: NavigationLink;
  navButtonTranslationKey: string;
  isResubmission: boolean | undefined;
};

const styles = StyleSheet.create({
  checkbox: {
    minWidth: 30,
    maxWidth: 30,
    marginRight: 10,
  },
});

export const DocumentConsnetFooterComponent = ({
  quizmasterLight,
  navigationLink,
  navButtonTranslationKey,
  isResubmission,
}: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();
  const dispatch = useDispatch();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const buttonStyle = useBreakpointValue({
    base: { width: '100%', maxWidth: 500, paddingX: 20, marginTop: 15 },
    md: { width: 103 },
    lg: { width: 200 },
  });

  const handleContinue = () => {
    navigationActions[navigationLink.action](navigationLink.config, navigationLink.params);
  };
  const textMaxWidth = useBreakpointValue({ base: '100%', md: '85%' });
  const hasGivenDocumentsConsent = quizmasterLight[flagsQuestionIds.documentsUploadConsent].answer;

  const handleChecked = () => {
    quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(!hasGivenDocumentsConsent);
    dispatch(statusActions.requestRefreshStatus());
  };

  const handleNavigationToDocuments = () => {
    navigationActions.toRequiredDocumentsDigitalCaf('screen', {
      onNext: () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      },
      screenMode: DocumentUploadMode.EXPENSES_STEP,
    });
  };

  return (
    <Container
      flexDirection={flexDirection}
      alignItems="center"
      justifyContent={'center'}
      padding={isSmallScreen ? 0 : 15}
      shadow="sticky1"
      backgroundColor="white"
      style={{ elevation: 5 }}
    >
      {isResubmission && (
        <Container flexDirection="row" alignItems="center" marginRight={10} maxWidth={textMaxWidth}>
          <CheckboxRow
            isChecked={hasGivenDocumentsConsent}
            onChange={handleChecked}
            translationKey=""
            style={styles.checkbox}
            testId="required-document.review.permission"
          />
          <Container width={isSmallScreen ? '90%' : '100%'}>
            <Text
              onPress={handleChecked}
              selectable={false}
              variant="bodySBook"
              color={NBTheme.colors.greytones.graphicsDark}
            >
              {getTranslationText('status.it.consent-footer.checkbox-text')}
            </Text>
            <TextLink
              text={getTranslationText('status.it.consent-footer.navigate-to-documents-subtext')}
              variant="linkS"
              onPress={handleNavigationToDocuments}
            />
          </Container>
        </Container>
      )}
      <Button
        variant="primary"
        disabled={!hasGivenDocumentsConsent && isResubmission}
        style={buttonStyle}
        onPress={handleContinue}
        title={getTranslationText(navButtonTranslationKey)}
        testID="status.it.consent-footer-button"
      />
    </Container>
  );
};

const DocumentConsentFooter = compose<any>(
  withQuizmasterLight([flagsQuestionIds.documentsUploadConsent]),
)(DocumentConsnetFooterComponent);

export const DocumentConsentFooterWrapper = (
  navigationLink: NavigationLink,
  navButtonTranslationKey: string,
  isResubmission: unknown,
): JSX.Element => (
  <DocumentConsentFooter
    navigationLink={navigationLink}
    navButtonTranslationKey={navButtonTranslationKey}
    isResubmission={isResubmission}
  />
);
