import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppState, Linking, NativeEventSubscription } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import {
  CustomerSupportConfig,
  selectors as remoteConfigSelectors,
} from 'src/stores/modules/remote-config-firebase';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { LanguageCode } from 'src/types';

import { UtilProps, withUtil } from '../hocs/with-utils';
import { injectLocaleInfo } from '../i18n/locale-info';
import { showAlert } from '../services/alert';
import contactSupport, { getIsWorkingNow, getSupportPhoneLink } from '../utils/contactSupport';
import { isWeb } from '../utils/platform';

export type ContactSupportRenderProps = {
  articleViewEnabled: boolean;
  handleCall: () => void;
  handleChat: () => void;
  handleEmail: () => void;
  handleInitSearch: () => void;
  handleSearch: (searchTerm: string) => void;
  helpCenterButtonOrder: string[];
  isCallButtonAvailable: boolean;
  isCallButtonEnabled: boolean;
  isChatButtonEnabled: boolean;
  isCollectionsViewEnabled: boolean;
  message?: string;
  searchEnabled: boolean;
  selectedLanguage: string;
};

type Props = {
  children: any;
  emailOption: number;
  helpCenterButtonOrder: string[];
  isCallButtonAvailable: boolean;
  mobEnabledChat: boolean;
  phoneNumber: string;
  timeZone: string;
  timeZoneChat: string;
  util: UtilProps;
  workingHours: CustomerSupportConfig['phoneSupport']['workingHours'];
  workingHoursChat: CustomerSupportConfig['chatSupport']['workingHours'];
};

const mapStateToProps = (stores: any, { localeInfo }: any) => {
  const { language }: { language: LanguageCode } = localeInfo;
  const { mobEnabled, workingHours, phoneNumber, messages, timeZone } =
    remoteConfigSelectors.getPhoneSupportByCountry(stores);
  const helpCenterButtonOrder = remoteConfigSelectors.getHelpCenterButtonOrder(stores);
  const emailOption = remoteConfigSelectors.getHelpCenterEmailActiveMethod(stores);
  const {
    mobEnabled: mobEnabledChat,
    workingHours: workingHoursChat,
    timeZone: timeZoneChat,
  } = remoteConfigSelectors.getChatSupportByCountry(stores);

  return {
    emailOption,
    helpCenterButtonOrder,
    isCallButtonAvailable: mobEnabled,
    message: mobEnabled ? messages?.[language] : '',
    mobEnabledChat,
    phoneNumber,
    timeZone,
    timeZoneChat,
    workingHours,
    workingHoursChat,
  };
};

const ContactSupportComponent = ({
  children,
  emailOption,
  helpCenterButtonOrder,
  isCallButtonAvailable,
  mobEnabledChat,
  phoneNumber,
  timeZone,
  timeZoneChat,
  util,
  workingHours,
  workingHoursChat,
}: Props) => {
  const { formatMessage } = useIntl();
  const selectedLanguage = useSelector(settingsSelectors.selectedLocale) as string;

  const { articleViewEnabled, isCollectionsViewEnabled } = useSelector(
    remoteConfigSelectors.getSupportConfigByCountry,
  );

  const [isCallButtonEnabled, setIsCallButtonEnabled] = useState(
    isCallButtonAvailable && getIsWorkingNow(workingHours, timeZone),
  );
  const [isChatButtonEnabled, setIsChatButtonEnabled] = useState(
    mobEnabledChat && getIsWorkingNow(workingHoursChat, timeZoneChat),
  );

  let intervalId: undefined | ReturnType<typeof setTimeout>;
  let appStateChange: NativeEventSubscription | undefined;

  const checkWorkingHours = () => {
    setIsCallButtonEnabled(isCallButtonAvailable && getIsWorkingNow(workingHours, timeZone));
    setIsChatButtonEnabled(mobEnabledChat && getIsWorkingNow(workingHoursChat, timeZoneChat));
  };

  useEffect(() => {
    // When the app goes background->foreground force an update
    appStateChange = AppState.addEventListener('change', () => checkWorkingHours());
    intervalId = setInterval(checkWorkingHours, 60000);

    return () => {
      Analytics.log(AnalyticsEvent.b_inapp_ContactSupportClosed);
      intervalId && clearInterval(intervalId);
      appStateChange?.remove();
    };
  }, []);

  const handleEmail = () => {
    contactSupport(util.defaultContactSupportMessages, emailOption);
  };

  const handleChat = () => {
    Analytics.log(AnalyticsEvent.contactSupportChatButtonPressed);
  };

  const handleCall = async () => {
    Analytics.log(AnalyticsEvent.b_inapp_ContactSupportPressCall);
    const phoneLink = getSupportPhoneLink(phoneNumber);

    try {
      const supported = await Linking.canOpenURL(phoneLink);

      if (!supported || isWeb) {
        showAlert({
          titleText: formatMessage({
            id: 'account.contact-support.phone-unavailable.message-title',
          }),
          subtitleText: formatMessage(
            { id: 'account.contact-support.phone-unavailable.message-small' },
            { phone: phoneNumber },
          ),
        });

        return;
      }
    } catch (e) {
      logger.error(e as Error);
    }
  };

  const viewProps = {
    articleViewEnabled,
    handleCall: handleCall,
    handleChat: handleChat,
    handleEmail: handleEmail,
    helpCenterButtonOrder,
    isCallButtonAvailable,
    isCallButtonEnabled,
    isChatButtonEnabled,
    isCollectionsViewEnabled,
    selectedLanguage,
  };

  return children(viewProps);
};

const ContactSupportContainer = compose<any>(
  withUtil,
  injectLocaleInfo,
  connect(mapStateToProps),
)(ContactSupportComponent);
export { ContactSupportContainer };
