import { useEffect, useState } from 'react';
import { CountryCodes, Years } from '@taxfix/types';
import { useSelector } from 'react-redux';
import { ProductAmountType } from '@taxfix/payment-sdk/dist/product/resolve';

import { useQuizmasterLight } from 'src/_italy/_hooks';
import { flagsQuestionIds } from 'src/common/constants-it';

import { getProduct } from '../../../../services/submissions';
import { selectors as settingsSelectors } from '../../../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../../../stores/modules/user-auth';

export type Product = {
  id: string;
  paymentId?: number;
  amount: number;
  amountType: ProductAmountType;
  currency: string;
};

const useProductInfo = (): { product: Product | undefined } => {
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const year = useSelector(settingsSelectors.selectedYear);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
  const selectedProductBundle = quizmasterLight[flagsQuestionIds.productBundleSelection].answer;

  useEffect(() => {
    const fetchProductInfo = async () => {
      const response = await getProduct(
        accessToken,
        year as Years,
        CountryCodes.IT,
        selectedProductBundle,
      );
      setProduct(response);
    };
    fetchProductInfo();
  }, [accessToken, selectedProductBundle, year]);

  return { product };
};

export { useProductInfo };
