import React from 'react';
import { WebViewNavigation } from 'react-native-webview';

import { Button, Container, InputField, NavButton, Text } from 'src/taxfix-components/src';
import { useLargeScreenQuery } from 'src/components/core';

import { WebViewComponent } from './debug-cwbi-url-webview';

type DebugCWBIUrlComponentProps = {
  inputValue: string;
  URL: string;
  event: string;
  onTextChange(input: string): void;
  onConfirm(): void;
  onShouldStartLoadWithRequest: (event: WebViewNavigation) => boolean;
  goBack(): void;
  onMessage(event: { nativeEvent: { data: string } }): void;
};

export const DebugCWBIUrlComponent = ({
  inputValue,
  URL,
  event,
  onTextChange,
  onShouldStartLoadWithRequest,
  goBack,
  onConfirm,
  onMessage,
}: DebugCWBIUrlComponentProps): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();

  return (
    <>
      {isLargeScreen && (
        <Container>
          <NavButton text="Back" onPress={goBack} />
        </Container>
      )}
      <Container my="24px" width="80%">
        <Container flexDir="row" marginBottom="6px">
          <InputField placeholder="CWBI URL" value={inputValue} onChangeText={onTextChange} />
          <Button
            ml="12px"
            width={isLargeScreen ? '100px' : '50px'}
            title="GO"
            onPress={onConfirm}
            variant="secondary"
          />
        </Container>

        <Container mt="12px" padding="18px" backgroundColor={'gray.100'}>
          <Text variant="bodySBold" mb="10px" value="Received event:" />
          <Text variant="bodyMBook" value={event ?? '-none-'} />
        </Container>
      </Container>
      <Container flex={1} height="100%">
        {!!URL && (
          <WebViewComponent
            url={URL}
            onMessage={onMessage}
            onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          />
        )}
      </Container>
    </>
  );
};
