import { $PropertyType } from 'utility-types';
import Config from 'react-native-config';
import { gte, valid } from 'semver';
import { User } from '@taxfix/taxfix-sdk';
import { CountryCodes } from '@taxfix/types';

export const UPDATE_LEGAL_ACCEPTANCE = 'userLegal/UPDATE_LEGAL_ACCEPTANCE';
export const UPDATE_LEGAL_ACCEPTANCE_SUCCESS = 'userLegal/UPDATE_LEGAL_SUCCESS';
export const UPDATE_LEGAL_ACCEPTANCE_ERROR = 'userLegal/UPDATE_LEGAL_ERROR';
export const RESET_LEGAL_ACCEPTANCE = 'userLegal/RESET_LEGAL_ACCEPTANCE';
export type LegalAcceptanceType = 'tac' | 'privacy';
export type LegalAcceptance = {
  created: string;
  type: LegalAcceptanceType;
  version: string;
};
export type State = {
  currentAcceptance: Partial<Record<CountryCodes, LegalAcceptance[]>>;
  isLoading: boolean;
  error: Error | null | undefined;
};
type RootState = {
  userLegal: State;
};
export const initial: State = {
  currentAcceptance: {},
  isLoading: false,
  error: null,
};
type UpdateLegalAcceptanceAction = {
  type: typeof UPDATE_LEGAL_ACCEPTANCE;
};
type UpdateLegalAcceptanceSuccessAction = {
  type: typeof UPDATE_LEGAL_ACCEPTANCE;
  payload: {
    userLegal: LegalAcceptance;
  };
};
type UpdateLegalAcceptanceErrorAction = {
  type: typeof UPDATE_LEGAL_ACCEPTANCE;
  payload: {
    error: Error;
  };
};
type ResetLegalAcceptanceAction = {
  type: typeof UPDATE_LEGAL_ACCEPTANCE;
};
type Action =
  | UpdateLegalAcceptanceAction
  | UpdateLegalAcceptanceSuccessAction
  | UpdateLegalAcceptanceErrorAction
  | ResetLegalAcceptanceAction;

const updateLegalAcceptanceSuccessAction = (
  userLegal: LegalAcceptance,
  countryCode: CountryCodes,
): UpdateLegalAcceptanceSuccessAction | any => ({
  type: UPDATE_LEGAL_ACCEPTANCE_SUCCESS,
  payload: {
    userLegal,
    countryCode,
  },
});

const updateLegalAcceptanceErrorAction = (
  error: Error,
): UpdateLegalAcceptanceErrorAction | any => ({
  type: UPDATE_LEGAL_ACCEPTANCE_ERROR,
  payload: {
    error,
  },
});

const updateUserLegalAction =
  (accessToken: string, countryCode: CountryCodes) => async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_LEGAL_ACCEPTANCE,
      });
      const userLegal = await User.getCurrentUserLegalAcceptances(
        Config.API_BASE_URL,
        accessToken,
        {
          countryCode,
        },
      );

      // We need some base to compare to in case the user didn't accept tac/privacy yet.
      // Please note its not currently possible for a user to not have at least one.
      if (!userLegal.length) {
        userLegal.push({
          created: Date.now(),
          type: 'tac',
          version: '0.0.0',
        } as any);
        userLegal.push({
          created: Date.now(),
          type: 'privacy',
          version: '0.0.0',
        } as any);
      }

      dispatch(
        updateLegalAcceptanceSuccessAction(
          userLegal.map((legal) => ({
            created: legal.created,
            type: legal.type,
            version: legal.version,
          })) as any,
          countryCode,
        ),
      );
    } catch (error) {
      dispatch(updateLegalAcceptanceErrorAction(error));
    }
  };

const createUserLegalAction =
  (accessToken: string, countryCode: CountryCodes, type: LegalAcceptanceType, version: string) =>
  async (dispatch: any) => {
    try {
      await User.createCurrentUserLegalAcceptance(Config.API_BASE_URL, accessToken, {
        version,
        type,
        countryCode,
      } as any);
      dispatch(updateUserLegalAction(accessToken, countryCode));
    } catch (error) {
      dispatch(updateLegalAcceptanceErrorAction(error));
      throw error;
    }
  };

const resetLegalAcceptanceAction = (): ResetLegalAcceptanceAction | any => ({
  type: RESET_LEGAL_ACCEPTANCE,
});

export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type) {
    case UPDATE_LEGAL_ACCEPTANCE:
      return { ...state, isLoading: true, error: null };

    case UPDATE_LEGAL_ACCEPTANCE_SUCCESS:
      if (!action.payload.userLegal.length) {
        return { ...state, error: null, isLoading: false };
      }

      return {
        ...state,
        currentAcceptance: {
          ...state.currentAcceptance,
          [action.payload.countryCode]: action.payload.userLegal,
        },
        isLoading: false,
        error: null,
      };

    case UPDATE_LEGAL_ACCEPTANCE_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };

    case RESET_LEGAL_ACCEPTANCE:
      return { ...state, currentAcceptance: {}, isLoading: false, error: null };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
export const filterAcceptanceByCountryAndType = (
  currentAcceptance: $PropertyType<State, 'currentAcceptance'>,
  countryCode: CountryCodes,
  type: LegalAcceptanceType,
): LegalAcceptance[] => {
  return currentAcceptance[countryCode]?.filter((elem) => elem.type === type) ?? [];
};

const getAcceptanceByCountryAndType = (
  state: State,
  countryCode: CountryCodes,
  type: LegalAcceptanceType,
): LegalAcceptance[] =>
  filterAcceptanceByCountryAndType(state.currentAcceptance, countryCode, type);

export const getHighestAcceptanceVersion = (
  acceptance: LegalAcceptance[],
): string | null | undefined => {
  // Sort the array to have latest accepted version first
  const validAcceptance = acceptance.filter((elem) => valid(elem.version));
  validAcceptance.sort((elem1, elem2) => {
    if (gte(elem1.version, elem2.version)) {
      return -1;
    }

    return 1;
  });
  return validAcceptance.length ? validAcceptance[0].version : null;
};

const getHighestTacByCountry = (
  state: RootState,
  countryCode: CountryCodes,
): string | null | undefined => {
  const acceptance = filterAcceptanceByCountryAndType(
    state.userLegal.currentAcceptance,
    countryCode,
    'tac',
  );
  return getHighestAcceptanceVersion(acceptance);
};

const getHighestPrivacyByCountry = (
  state: RootState,
  countryCode: CountryCodes,
): string | null | undefined => {
  const acceptance = filterAcceptanceByCountryAndType(
    state.userLegal.currentAcceptance,
    countryCode,
    'privacy',
  );
  return getHighestAcceptanceVersion(acceptance);
};

const isLoading = (state: RootState): boolean => state.userLegal.isLoading;

const isError = (state: RootState): Error | null | undefined => state.userLegal.error;

export const actions = {
  updateUserLegalAction,
  updateLegalAcceptanceSuccessAction,
  updateLegalAcceptanceErrorAction,
  resetLegalAcceptanceAction,
  createUserLegalAction,
};
export const selectors = {
  isLoading,
  getAcceptanceByCountryAndType,
  getHighestTacByCountry,
  getHighestPrivacyByCountry,
  isError,
};
