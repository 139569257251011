// eslint-disable-next-line import/order
import { Platform } from 'react-native';
const codePush = Platform.OS !== 'web' && require('react-native-code-push');

import { CountryCodes } from '@taxfix/types';

import { selectCountryAndYear } from 'src/services/country-and-year';

import { showAlert } from '../../../../services/alert';
import { ButtonType } from '../../../../components/alert-overlay';

import { DebugMenuOptions, MenuOption } from './types';

const handleSelectCountry = async (selectedCountry: CountryCodes): Promise<void> => {
  selectCountryAndYear(selectedCountry);
};

export function buildResetAppStateOption(props: DebugMenuOptions): MenuOption {
  const resetAppState = async () => {
    const { onLogout } = props;
    await onLogout({
      skipRedirect: true,
    });
    handleSelectCountry(CountryCodes.IT);
    if (codePush) codePush.clearUpdates();
  };

  const handleClearAsyncStorage = () => {
    showAlert({
      titleText: 'Reset App State',
      subtitleText: 'Do you want to proceed?',
      cancelable: false,
      buttons: [
        {
          text: 'OK',
          type: ButtonType.confirm,
          onPress: resetAppState,
        },
        {
          text: 'Cancel',
          type: ButtonType.secondary,
          style: 'cancel',
        },
      ],
    });
  };

  return {
    text: 'Reset App State',
    onPress: handleClearAsyncStorage,
    showArrow: true,
  };
}
