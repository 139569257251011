import * as React from 'react';
import { CountryCodes } from '@taxfix/types';

import { AnswerComponentProps } from 'src/components/answers/types';
import AnswerValidator from 'src/components/answer-validator';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ValidationResult } from 'src/components/answers/shared/validators/types';
import { validate as validateTaxId } from 'src/services/tax-id-number';
import { InputWithValidation } from 'src/_italy/quizmaster/components/input-with-validation';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

const COMPANY_TAX_ID_LENGTH = 11;
const PERSONAL_TAX_ID_LENGTH = 16;

export type ITTaxNumberProps = AnswerComponentProps & {
  isPersonal: boolean;
};

type ValidationError = {
  translationKey: string;
};

type State = {
  value: string;
  errors: ValidationError[];
};

type ValidateFunction = (arg0: string) => ValidationResult;

export const ITTaxNumber: React.FC<ITTaxNumberProps> = ({
  isPersonal,
  onAnswer,
  question,
  value,
}) => {
  const { getTranslationText } = useItalyIntl();

  const initialState: State = {
    value: value || '',
    errors: [],
  };

  const [state, setState] = React.useState<State>(initialState);

  const handleChange = (value: string) => {
    setState({
      value: value.trim().toUpperCase(),
      errors: [],
    });
  };

  const handleSuccess = (value: string) => {
    setState({
      value,
      errors: [],
    });
    onAnswer(question, state.value);
  };

  const handleFailure = (errors: ValidationResult['errors']) => {
    Analytics.log(AnalyticsEvent.taxIdFailed, {
      errorMessage: JSON.stringify(errors),
    });
    setState((state) => ({
      ...state,
      errors: errors as ValidationError[],
    }));
  };

  const handleConfirm = (validate: ValidateFunction) => (value: string) => {
    validate(value);
  };

  const validator = (taxId: string): ValidationResult => {
    const errors = [];
    const onlyDigitsReg = /^\d+$/;
    let isValid = false;

    if (taxId.length === COMPANY_TAX_ID_LENGTH) {
      isValid = onlyDigitsReg.test(taxId);
    }

    if (taxId.length === PERSONAL_TAX_ID_LENGTH) {
      isValid = validateTaxId(taxId, CountryCodes.IT);
    }

    if (!isValid) {
      errors.push({
        translationKey: 'answers.tax-number.invalid',
      });
    }

    return {
      isValid,
      errors,
    };
  };

  const minLengthByQuestionType = isPersonal ? PERSONAL_TAX_ID_LENGTH : COMPANY_TAX_ID_LENGTH;

  const isReady = React.useMemo(
    () => state.value.length >= minLengthByQuestionType,
    [state.value, minLengthByQuestionType],
  );

  return (
    <AnswerValidator validate={validator} onFailure={handleFailure} onSuccess={handleSuccess}>
      {({ validate }) => (
        <InputWithValidation
          isReady={isReady}
          testId="answer.IT_tax_number.input"
          label={getTranslationText('answers.tax-id.label')}
          value={state.value}
          errors={state.errors}
          onChange={handleChange}
          onConfirm={handleConfirm(validate)}
        />
      )}
    </AnswerValidator>
  );
};

export default ITTaxNumber;
