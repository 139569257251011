import React, { useCallback, useState } from 'react';
import { WebViewNavigation } from 'react-native-webview';
import { Linking } from 'react-native';

import { isIOS } from 'src/utils/platform';
import { useNavigation } from 'src/hooks/navigation-hook';

import { DebugCWBIUrlComponent } from './debug-cwbi-url-component';

const cwbiPrivacyPolicyUrlIdentifier = 'privacy_policy';

export const DebugCWBIUrlContainer: React.FC = () => {
  const { navigation } = useNavigation();
  const [inputValue, setInputValue] = useState('');
  const [URL, setURL] = useState('');
  const [event, setEvent] = useState('');

  const handleInputChange = (input: string) => {
    if (URL) setURL('');
    setInputValue(input);
  };

  const handleConfirm = () => {
    setURL(inputValue);
  };

  const onShouldStartLoadWithRequest = useCallback((event: WebViewNavigation) => {
    // a workaround for iOS to open the privacy policy of CWBI in the mobile browser
    // iOS requires opening the link in a new page explicitly,
    // otherwise it will open the link in the same iFrame
    if (isIOS && event.url.toLowerCase().includes(cwbiPrivacyPolicyUrlIdentifier)) {
      Linking.openURL(event.url);
      return false;
    }

    return true;
  }, []);

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleMessage = useCallback((event: { nativeEvent: { data: unknown } }): void => {
    const data = event.nativeEvent.data;
    if (typeof data === 'string' && !data.startsWith('setImmediate')) {
      setEvent(data);
    }
  }, []);

  return (
    <DebugCWBIUrlComponent
      inputValue={inputValue}
      URL={URL}
      event={event}
      onTextChange={handleInputChange}
      onConfirm={handleConfirm}
      onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
      goBack={handleGoBack}
      onMessage={handleMessage}
    />
  );
};
