import React from 'react';

import { InformationBanner, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

export const UploadInfoBanner = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const getBoldText = (text: string) => <Text fontWeight="bold" value={text} variant="bodySBook" />;
  const getInformationBannerText = getTranslationText('identity-upload.upload-info-banner', {
    bold: getBoldText,
  });

  return (
    <InformationBanner>
      <Text variant="bodySBook" value={getInformationBannerText} />
    </InformationBanner>
  );
};
