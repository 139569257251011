import React, { useEffect } from 'react';
import { Animated } from 'react-native';
import { Answer, TreeNode } from '@taxfix/quizmaster/dist/types';

import { RolloverAnimationContext } from 'src/components/animations/rollover-animation-context';
import { useDataRollover } from 'src/hooks/useDataRollover';
import { Box } from 'src/components/core';

import QuestionCard, { Props as QuestionCardProps } from '../question-card';
import { testID } from '../../common/testID';

type Props = QuestionCardProps & {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  responseComponent?: React.ReactElement | null;
  rolloverSourceYearForSelectedYear?: number | null;
  node?: TreeNode;
  answerValue?: Answer | null | undefined;
  onRolloverAnswerChange?: (canShowRolloverInfo: boolean) => void;
  onRolloverAnimationReset?: () => void;
  onHasSeenRolloverInfoChange?: (isSeen: boolean) => void;
  hasSeenRolloverInfo?: boolean;
  showRolloverInfo?: boolean;
};

const QuestionAndAnswer = ({
  skippable,
  onSkip,
  onHandleStartSkip,
  onInformation,
  informationPositionTop,
  informationPositionWithText,
  informationText,
  shouldShowInfoButtonTooltip,
  onInfoButtonTooltipOpened,
  onInfoButtonTooltipClosed,
  onAnimationStateChange,
  hasSeenSwipeTutorial,
  questionTranslationKey,
  loopQuestionText,
  loopQuestionTranslationKey,
  translationValues,
  responseComponent = null,
  isLoading,
  type,
  cardColor,
  primaryTextColor,
  secondaryTextColor,
  isGovPrefilled,
  children,
  inlineInformation,
  top = 0,
  bottom = 1.4,
  left = 1.6,
  right = 1.6,
  customCardStyle,
  customCardInnerStyle,
  showRolloverInfo,
  subtitleTransformed,
  questionTransformed,
  node,
  answerValue,
  hasSeenRolloverInfo,
  onRolloverAnimationReset,
  onHasSeenRolloverInfoChange,
}: Props): JSX.Element => {
  const { moveToBottom, fadeOutBottomComponent, resetRolloverAnimation, triggerRolloverAnimation } =
    React.useContext(RolloverAnimationContext);
  const { shouldRolloverSubtitleBeHidden } = useDataRollover();
  const animationStyle = moveToBottom ? { transform: [{ translateY: moveToBottom }] } : {};

  let shouldShowRolloverSubtitle = false;

  if (node) {
    shouldShowRolloverSubtitle = shouldRolloverSubtitleBeHidden(node, answerValue);
  }

  useEffect(() => {
    if (isLoading && resetRolloverAnimation) {
      resetRolloverAnimation();
    }
  }, [isLoading, resetRolloverAnimation]);

  useEffect(() => {
    if (onHasSeenRolloverInfoChange && !isLoading) {
      if (showRolloverInfo) {
        onHasSeenRolloverInfoChange(true);
      } else if (hasSeenRolloverInfo && shouldShowRolloverSubtitle) {
        triggerRolloverAnimation();
      }
    }
  }, [
    showRolloverInfo,
    hasSeenRolloverInfo,
    isLoading,
    onRolloverAnimationReset,
    onHasSeenRolloverInfoChange,
    moveToBottom,
    fadeOutBottomComponent,
    shouldShowRolloverSubtitle,
    triggerRolloverAnimation,
  ]);

  return (
    <Animated.View>
      <Box left={left} right={right} bottom={bottom} top={top}>
        <Animated.View style={animationStyle}>
          <QuestionCard
            type={type}
            skippable={skippable}
            onInformation={onInformation}
            informationPositionTop={informationPositionTop}
            informationPositionWithText={informationPositionWithText}
            informationText={informationText}
            shouldShowInfoButtonTooltip={shouldShowInfoButtonTooltip}
            onInfoButtonTooltipOpened={onInfoButtonTooltipOpened}
            onInfoButtonTooltipClosed={onInfoButtonTooltipClosed}
            onSkip={onSkip}
            onAnimationStateChange={onAnimationStateChange}
            onHandleStartSkip={onHandleStartSkip}
            hasSeenSwipeTutorial={hasSeenSwipeTutorial}
            questionTranslationKey={questionTranslationKey}
            loopQuestionTranslationKey={loopQuestionTranslationKey}
            loopQuestionText={loopQuestionText}
            translationValues={translationValues}
            isLoading={isLoading}
            cardColor={cardColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            isGovPrefilled={isGovPrefilled}
            inlineInformation={inlineInformation}
            customCardStyle={customCardStyle}
            customCardInnerStyle={customCardInnerStyle}
            questionTransformed={questionTransformed}
            subtitleTransformed={subtitleTransformed}
            {...testID('question::card')}
          >
            {children}
          </QuestionCard>
        </Animated.View>
      </Box>
      {responseComponent}
    </Animated.View>
  );
};

export default React.memo(QuestionAndAnswer);
