import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Platform,
  TouchableOpacity,
  Animated,
  Easing,
  StatusBar,
  Dimensions,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Transition, Position, ToastBannerProps } from './types';

const DEFAULT_NAV_HEIGHT = Platform.OS === 'ios' ? 44 : 56;
const HEIGHT_NOTCH_SAFE = 100;
const DEFAULT_DURATION = 3000;

type NotchSafeDummyViewProps = {
  bannerHeight: number;
  color: string | undefined;
};

const NotchSafeDummyView = ({ color }: NotchSafeDummyViewProps) => (
  <View
    style={{
      position: 'absolute',
      width: '100%',
      height: HEIGHT_NOTCH_SAFE,
      top: -HEIGHT_NOTCH_SAFE,
      backgroundColor: color,
    }}
  />
);

const ToastBanner = ({
  backgroundColor,
  contentView,
  duration = DEFAULT_DURATION,
  hideRequested,
  onPostHide,
  onPress,
  position = Position.Top,
  transitions = [Transition.Move],
}: ToastBannerProps) => {
  const [contentHeight, setContentHeight] = useState(0);

  const isBannerMounted = useRef(true);
  const animation = new Animated.Value(-10);

  const hide = () => {
    Animated.timing(animation, {
      duration: 200,
      toValue: 0,
      useNativeDriver: true,
    }).start(() => onPostHide(isBannerMounted.current));
  };

  const show = () => {
    Animated.timing(animation, {
      duration: transitions.includes(Transition.Move) ? 330 : 250,
      toValue: 1,
      useNativeDriver: true,
      easing: transitions.includes(Transition.Move) ? Easing.elastic(1.1) : undefined,
    }).start(() => {
      setTimeout(hide, duration === undefined ? DEFAULT_DURATION : duration);
    });
  };

  const handleLayout = ({
    nativeEvent: {
      //@ts-ignore
      layout: { height },
    },
  }) => {
    if (height !== 0 && contentHeight === 0) {
      setContentHeight(height);
    }
  };

  useEffect(() => {
    return () => {
      isBannerMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (contentHeight !== 0) {
      animation.setValue(0);
      show();
    }
  }, [contentHeight]);

  useEffect(() => {
    if (hideRequested) hide();
  }, [hideRequested]);

  const translateY = animation.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [
      -1000,
      transitions.includes(Transition.MoveLinear) || transitions.includes(Transition.Move)
        ? -(contentHeight + HEIGHT_NOTCH_SAFE)
        : 0,
      0,
    ],
  });
  const opacity = animation.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, transitions.includes(Transition.FadeInOut) ? 0 : 1, 1],
  });

  const expoPaddingTop =
    // @ts-ignore
    Platform.OS === 'android' && global.Expo ? StatusBar.currentHeight : 0;
  const windowHeight = Dimensions.get('window').height;

  return (
    <SafeAreaView
      style={{
        position: 'absolute',
        width: '100%',
        paddingTop: position === Position.Top ? expoPaddingTop : 30,
        top:
          position === Position.Top
            ? 0
            : position === Position.Center
            ? windowHeight / 2
            : windowHeight,
      }}
    >
      <Animated.View
        style={{
          width: '100%',
          minHeight: DEFAULT_NAV_HEIGHT,
          transform: [{ translateY }],
          opacity,
          backgroundColor,
        }}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={onPress}
          style={{ width: '100%', height: '100%' }}
        >
          <NotchSafeDummyView bannerHeight={DEFAULT_NAV_HEIGHT} color={backgroundColor} />
          <View onLayout={handleLayout} style={{ flex: 1, justifyContent: 'center' }}>
            {contentView}
          </View>
        </TouchableOpacity>
      </Animated.View>
    </SafeAreaView>
  );
};
export { ToastBanner };
