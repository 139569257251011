import Device from 'react-native-device-info';
import { NativeModules } from 'react-native';
import Fingerprint2 from 'fingerprintjs2';
import Config from 'react-native-config';

import { isIOS, isWeb } from 'src/utils/platform';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import Analytics from '../biz-logic/analytics/index';

type RequestIdleCallbackOptions = {
  timeout: number;
};
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: () => number;
};

// @ts-ignore
interface ExtendedWindow extends Window {
  requestIdleCallback: (
    callback: (deadline: RequestIdleCallbackDeadline) => void,
    opts?: RequestIdleCallbackOptions,
  ) => void;
}
declare const window: ExtendedWindow;

// test if it is standard uuid shape
// (reference https://github.com/mixer/uuid-validate/blob/06554db1b093aa6bb429156fa8964e1cde2b750c/index.js#L2)
export const isValidUUID: (arg0: string) => boolean = (id) =>
  new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i).test(id);

// test if it is a nil uuid
// on real iOS device advertising id can be 00000000-0000-0000-0000-000000000000
// when tracking is limited
// https://github.com/blochberger/IDFA#facts
export const isNilUUID: (arg0: string) => boolean = (id) =>
  new RegExp(/^[0]{8}-[0]{4}-[0]{4}-[0]{4}-[0]{12}$/i).test(id);

const setWebDeviceId = (deviceId: string): void =>
  window.localStorage.setItem('deviceId', deviceId);

type FingerprintComponent = {
  key: string;
  value: string;
};

const getFingerprintComponents = (): Promise<FingerprintComponent[]> =>
  new Promise((resolve) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => Fingerprint2.get(resolve));
    } else {
      setTimeout(() => Fingerprint2.get(resolve), 500);
    }
  });

const generateDeviceId = async (): Promise<string> => {
  const components = await getFingerprintComponents();
  const values = components.map((component) => component.value);
  return Fingerprint2.x64hash128(values.join(''), 31);
};

export const initDeviceId = async (): Promise<string> => {
  const deviceId = await generateDeviceId();
  setWebDeviceId(deviceId);
  return deviceId;
};

const getWebDeviceId = async (): Promise<string> => {
  const deviceId = window.localStorage.getItem('deviceId');

  if (deviceId) {
    return deviceId;
  }

  return initDeviceId();
};

// returns renewable unique advertising identifier when available
// or regular Device unique identifier if not
export const getUniqueDeviceID: () => Promise<string> = async () => {
  const advertisingId: string | null | undefined = await Analytics.getAdvertisingId();

  if (advertisingId) {
    if (isValidUUID(advertisingId) && !isNilUUID(advertisingId)) return advertisingId;
  }

  const uniqueDeviceId = await Device.getUniqueId();

  if (isWeb && uniqueDeviceId === 'unknown') {
    const deviceId = await getWebDeviceId();
    return deviceId;
  }

  return uniqueDeviceId;
};
export const getCustomUUID: () => string = () => {
  // from this gist https://gist.github.com/jed/982883
  // we cannot use safer crypto API with react native
  function b(a: any): any {
    return a // eslint-disable-next-line no-bitwise
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) // @ts-ignore
      : // @ts-ignore
        ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
  }

  // @ts-ignore
  return b();
};

// return false for production mobile-app used with rooted or simulator device
export const isAuthorizedDevice = async () => {
  if (isWeb) return true;
  if (__DEV__ || Config.SHOW_DEBUG_SCREEN == 'true') return true;
  // for iOS we can use internal method of Sentry to retrieve if device is jailbroken/rooted
  if (isIOS) {
    try {
      const RNSentry = NativeModules.RNSentry;
      const info = await RNSentry.fetchNativeDeviceContexts();
      return !info.contexts.device.simulator && !info.contexts.os.rooted;
    } catch (error) {
      logger.warn(error as Error, {
        message: 'Fail to use Sentry to check if device is Authorized',
      });
      return true;
    }
  }
  // TODO for Android
  // we cannot call Sentry method from JS layer, we might have to replicate it
  // https://github.com/getsentry/sentry-java/blob/060811b70668b2ad941b29eace9340b264228f2a/sentry-android-core/src/main/java/io/sentry/android/core/internal/util/RootChecker.java#L89
  return true;
};
