/* global window */
import * as React from 'react';
import { useSelector } from 'react-redux';
import Config from 'react-native-config';
import { EppoSdk } from '@taxfix/eppo';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { Analyticsify } from 'src/biz-logic/web/analyticsify';

import { selectors as settingsSelectors } from '../stores/modules/settings';

type CookieConsentParams = {
  clientName?: string;
  language?: string;
  options?: {
    // Check https://github.com/taxfix/cookie-consent-manager#ccm-api for all options
    slideUpBanner?: boolean;
    fullPageOverlay?: boolean;
  };
  callback?: () => void;
};

export type Categories = {
  [categoryName: string]: boolean;
};

const getCookieConsentParams = (language: string | undefined): CookieConsentParams => {
  return {
    clientName: Config.COOKIE_CONSENT_CLIENT_NAME,
    language,
    options: {
      slideUpBanner: true,
      fullPageOverlay: true,
    },
  };
};

const applyTrackingConsent = async (trackingConsent: boolean) => {
  if (trackingConsent) {
    await Analyticsify.enable();
    EppoSdk.enable();
  } else {
    Analyticsify.disable();
    EppoSdk.disable();
  }
};

const CookieConsent = ({ children }: { children: React.ReactElement }) => {
  const selectedLocale = useSelector(settingsSelectors.selectedLocale) ?? undefined;
  const [isDisplayed, setIsDisplayed] = React.useState(true);

  React.useEffect(() => {
    // Do not show annoying cookie consent on development
    let cookieConsentManager: any;

    if ((!Config.IS_AUTOMATION && !__DEV__) || Config.SHOW_DEBUG_SCREEN !== 'true') {
      const cookieConsentParams = getCookieConsentParams(selectedLocale);

      if (
        cookieConsentParams.clientName &&
        cookieConsentParams.language &&
        cookieConsentParams.options
      ) {
        try {
          cookieConsentManager = new (window as any).CookieConsentManager(
            cookieConsentParams.clientName,
            cookieConsentParams.language,
            cookieConsentParams.options,
          );
          const applyConsent = async () => {
            const trackingConsent = cookieConsentManager.getAnalyticsCookie();
            await applyTrackingConsent(trackingConsent);
            setIsDisplayed(cookieConsentManager.shouldShowBanner());
          };
          applyConsent();
          cookieConsentManager.on('consentChanged', applyConsent);
        } catch (error: any) {
          logger.warn(`CookieConsent error: ${error.message}`);
        }
      }
    } else {
      const applyConsentDebug = async () => {
        await applyTrackingConsent(true);
        setIsDisplayed(false);
      };
      applyConsentDebug();
    }
    return () => {
      if (cookieConsentManager) {
        cookieConsentManager.cleanUp();
      }
    };
  }, [selectedLocale]);
  return !isDisplayed && children ? children : null;
};

export { CookieConsent };
