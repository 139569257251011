import { theme } from '../core';

import { Variant } from './PinInputComponent.types';

export const stylesForVariant = (variant: Variant | null | undefined, hasError: boolean) => {
  const col = theme.color;

  if (variant === 'dark-background' && !hasError) {
    return {
      label: {
        color: col.primaryBackgroundText,
      },
      link: {
        color: col.primaryBackgroundText,
      },
      input: {
        color: col.lightText,
      },
      border: {
        borderColor: col.primaryBackgroundText,
      },
      cursor: {
        backgroundColor: col.lightText,
      },
      error: {},
    };
  }

  if (variant === 'dark-background' && hasError) {
    return {
      label: {
        color: col.errorTextDark,
      },
      link: {
        color: col.errorTextDark,
      },
      input: {
        color: col.lightText,
      },
      border: {
        borderColor: col.errorBorderDark,
      },
      cursor: {
        backgroundColor: col.lightText,
      },
      error: {
        color: col.errorTextDark,
      },
    };
  }

  if (variant === 'light-background' && !hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      link: {},
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.border,
      },
      cursor: {
        backgroundColor: col.primary,
      },
      error: {},
    };
  }

  if (variant === 'light-background' && hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      link: {},
      input: {
        color: col.errorTextLight,
      },
      border: {
        borderColor: col.errorBorderLight,
      },
      cursor: {
        backgroundColor: col.errorTextLight,
      },
      error: {
        color: col.errorTextLight,
      },
    };
  }

  if (variant === 'italy' && !hasError) {
    return {
      label: {
        color: col.greyFillIcon,
      },
      link: {},
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.border,
        backgroundColor: col.background,
      },
      cursor: {
        backgroundColor: col.primary,
      },
      error: {},
    };
  }

  if (variant === 'italy' && hasError) {
    return {
      label: {
        color: col.greyFillIcon,
      },
      link: {},
      input: {
        color: col.errorTextLight,
      },
      border: {
        borderColor: col.errorBorderLight,
      },
      cursor: {
        backgroundColor: col.errorTextLight,
      },
      error: {
        color: col.errorTextLight,
      },
    };
  }

  return {
    label: {},
    link: {},
    input: {},
    border: {},
    cursor: {},
    error: {},
  };
};
