import { Pressable, useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { Container, Icon, Text, icons } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * link to review the questions
 */

export const ReviewQuestionButton: React.FC<Props> = ({
  textValue,
  onPress,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();
  const marginLeft = useBreakpointValue({ base: '17px', md: '25px' });
  const widthText = useBreakpointValue({ base: '201px', md: '235px' });
  return (
    <Pressable
      height="76px"
      borderRadius="5px"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      paddingLeft="24px"
      onPress={onPress}
      bg={colors.greytones.greyBlue}
      {...otherProps}
    >
      <Container flexDir="row">
        <Icon path={icons.documentPencil} color={colors.brand.brandGreen} size="30px" />
        <Text
          value={textValue}
          variant="bodyXSBook"
          color={colors.greytones.textDefault}
          marginLeft={marginLeft}
          maxWidth={widthText}
        />
      </Container>
      <Icon
        path={icons.chevronRight}
        color={colors.brand.brandGreen}
        size="17px"
        marginRight="14px"
      />
    </Pressable>
  );
};
