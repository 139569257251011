import { IBoxProps, IImageProps } from 'native-base';
import { RefObject } from 'react';
import { FileRejection } from 'react-dropzone';
import { Asset } from 'react-native-image-picker';

import { TextProps } from 'src/taxfix-components/src';
import { Option as OptionButtonType } from 'src/components/input/button-overlay';
import { CameraImageResult } from 'src/screens/android-camera-upload/camera';

export enum Screen {
  UPLOAD,
  PREVIEW,
}

export enum DocumentSide {
  FRONT = 'front',
  REAR = 'rear',
}

export type Step = {
  type: Screen;
  side: DocumentSide;
};

export enum AllowedDocTypes {
  ID_CARD = 'idCard',
  PASSPORT = 'passport',
  DRIVING_LICENSE = 'drivingLicense',
}

export type RouteParams = {
  onNext?(): void;
};

export type AllowedImageTypes = 'image/jpg' | 'image/jpeg' | 'image/png';

export type NativeFileResponse = (CameraImageResult | Asset) & {
  error?: unknown;
  didCancel?: boolean;
  fileSize?: number;
};

export type FileResponses = (NativeFileResponse | File)[];

export type OnFilesSelected = (files: FileResponses) => void;

export type OnFilesRejected = (fileRejections: FileRejection[]) => void;

export type OnReceiveAssets = (response: FileResponses) => void;

export type UploadOverlayButtons = OptionButtonType[];

export type RefViewShotElement = RefObject<{ capture(): Promise<void> }>;

export type UploadComponentProps = {
  files: string[];
  errorMessage: string | null;
  overlayButtons: UploadOverlayButtons;
  isUploadScreen: boolean;
  isLoading: boolean;
  onFilesRejected: OnFilesRejected;
  onReceiveAssets: OnReceiveAssets;
  onContinuePress(onPlatformSpecificClick: () => void): () => Promise<void>;
  onReuploadPress(onPlatformSpecificClick: () => void): () => void;
};

export type ImageProps = IImageProps & {
  name: string;
};

export type IdTypeContentProps = IBoxProps & {
  title: TextProps;
  image: ImageProps;
};

export type DropzoneRefObject = { open: () => void };

export type DropzoneRef = RefObject<DropzoneRefObject>;

export type UploadedContentAreaProps = {
  contentProps: IdTypeContentProps;
  onFilesSelected: OnFilesSelected;
  onFilesRejected: OnFilesRejected;
};

export type IdCardUploadedContentAreaProps = {
  side: DocumentSide;
  onFilesSelected: OnFilesSelected;
  onFilesRejected: OnFilesRejected;
};
