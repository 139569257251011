import { Action as BaseAction } from 'redux';
import { Calculations } from '@taxfix/income-tax-calculator';
import { IncomeTaxValue } from '@taxfix/quizmaster/dist/types';

export enum ActionTypes {
  UPDATE_TAX_CALCULATIONS_STATUS = 'UPDATE_TAX_CALCULATIONS_STATUS',
  UPDATE_TAX_CALCULATIONS = 'UPDATE_TAX_CALCULATIONS',
  UPDATE_INCOME_FIELDS = 'UPDATE_INCOME_FIELDS',
}

export type TaxCalculationsStatus = {
  taxYear: number;
  isCalculationsLoading: boolean;
};

export type TaxCalculations = {
  taxYear: number;
  incomeFieldHash: string;
  incomeFields: IncomeTaxValue[];
  calculations: Calculations;
};

export type IncomeFields = {
  taxYear: number;
  incomeFields: IncomeTaxValue[];
};

export type UpdateCalculationsLoadingAction =
  BaseAction<ActionTypes.UPDATE_TAX_CALCULATIONS_STATUS> & TaxCalculationsStatus;

export type UpdateTaxCalculationsAction = BaseAction<ActionTypes.UPDATE_TAX_CALCULATIONS> &
  TaxCalculations;

export type UpdateIncomeFieldsAction = BaseAction<ActionTypes.UPDATE_INCOME_FIELDS> & IncomeFields;

export type Action =
  | UpdateCalculationsLoadingAction
  | UpdateTaxCalculationsAction
  | UpdateIncomeFieldsAction;
