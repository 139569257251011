import { View, useTheme } from 'native-base';
import React from 'react';

import { Text } from 'src/taxfix-components/src';

import { StyledImage, StyledPressable, StyledText } from './styles';
import { SupportCardProps } from './types';

export const SupportCard = ({
  enable,
  onPress,
  primaryText,
  secondaryText,
  key,
  icon,
}: SupportCardProps) => {
  const { colors } = useTheme();
  return (
    <StyledPressable
      onPress={() => {
        if (enable) {
          onPress();
        }
      }}
      key={key}
      marginBottom="20px"
      paddingX="10px"
      paddingY="20px"
      borderRadius="12px"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <StyledImage size="52px" name={enable ? icon : `${icon}_disabled`} enable={enable} />
      <View flexDirection="column" ml="16px" flex={1} width="100%" justifyContent="flex-start">
        <StyledText fontSize="16px" fontWeight="700" enable={enable}>
          {primaryText}
        </StyledText>
        {secondaryText && (
          <View mt="4px">
            <Text
              fontSize="12px"
              color={colors.greytones.graphicsDark}
              style={enable ? null : { opacity: 0.2 }}
            >
              {secondaryText}
            </Text>
          </View>
        )}
      </View>
    </StyledPressable>
  );
};
