import React from 'react';
import { StyleSheet, View } from 'react-native';

import { theme, LinkText, TextInput, Box, BodySecondaryText, BodyPrimaryText } from '../core';
import { testAccessibility } from '../../common/testID';

import { Props } from './PinInputComponent.types';
import { stylesForVariant } from './PinInputComponent.utils';

const styles = StyleSheet.create({
  pinViewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  active: {
    borderBottomColor: theme.color.primaryText,
    borderBottomWidth: 1,
  },
  showHideLinkText: {
    marginLeft: 20,
    flex: 1,
  },
  inActive: {
    borderBottomColor: theme.color.border,
    borderBottomWidth: 1,
  },
  textInputContainer: {
    marginTop: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    paddingHorizontal: 10,
  },
  textInput: {
    paddingTop: 7,
    paddingBottom: 0,
    paddingHorizontal: 0,
    marginBottom: 8,
  },
});
export const PinInputComponent = ({
  value,
  variant,
  autoFocus,
  hasError,
  errorKey,
  isFocused,
  isObscured,
  actionKey,
  titleKey,
  handleChange,
  handleBlur,
  handleFocusTextInput,
  testId,
  loading,
  handleAction,
  textInputRef,
}: Props): JSX.Element => {
  const { label, input, border, link, error } = stylesForVariant(variant, !!hasError);
  const errorMessage =
    hasError && errorKey != null ? <BodySecondaryText id={errorKey} style={error} /> : null;
  return (
    <>
      <Box direction="row" alignVertically="center">
        <BodyPrimaryText id={titleKey} style={label} />
      </Box>
      <View style={styles.pinViewContainer}>
        <View style={[styles.textInputContainer, border, isFocused ? styles.active : {}]}>
          <TextInput
            ref={textInputRef}
            value={value}
            autoFocus={autoFocus}
            keyboardType="numeric"
            onChangeText={handleChange}
            onBlur={handleBlur}
            style={[styles.textInput, input]}
            onFocus={handleFocusTextInput}
            testId={testId}
            secureTextEntry={isObscured}
            tabIndex={0}
            {...testAccessibility()}
          />
        </View>
        {loading ? (
          <BodySecondaryText
            style={[
              styles.showHideLinkText,
              {
                color: 'grey',
              },
            ]}
            id={actionKey}
          />
        ) : (
          <LinkText
            style={[styles.showHideLinkText, link]}
            id={actionKey}
            onPress={handleAction}
            disabled={loading}
            testId={actionKey}
          />
        )}
      </View>
      {errorMessage && <Box bottom={2}>{errorMessage}</Box>}
    </>
  );
};
