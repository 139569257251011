import * as React from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text } from 'react-native';

import { CameraImageResult } from '../android-camera-upload/camera';
import { testID } from '../../common/testID';
import { theme, Box } from '../../components/core';

const styles: any = StyleSheet.create({
  container: {
    flex: 1,
  },
  preview: {
    flex: 1,
    // @ts-ignore
    width: null,
    // @ts-ignore
    height: null,
    alignSelf: 'stretch',
    backgroundColor: theme.color.black,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  bottomBar: {
    backgroundColor: 'black',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 70,
  },
  text: {
    color: theme.color.lightText,
    fontSize: 20,
    textAlign: 'center',
  },
});
export type Props = {
  tryAgainButtonString: string;
  uploadButtonString: string;
  image: CameraImageResult;
  onTryAgain: () => any;
  onProceed: () => any;
};
// TODO: Handle more than one image
export const ImagePreviewComponent = (props: Props) => {
  const {
    tryAgainButtonString,
    uploadButtonString,
    onProceed,
    onTryAgain,
    image: { uri },
  } = props;
  return (
    <View style={styles.container}>
      <Box style={styles.container}>
        <Image
          source={{
            uri,
          }}
          style={styles.preview}
          resizeMode="contain"
        />
      </Box>

      <View style={styles.bottomBar}>
        <Box flex alignHorizontally="start" left={2}>
          <TouchableOpacity onPress={onTryAgain}>
            <Text style={styles.text} {...testID('test.button.tryAgain')}>
              {tryAgainButtonString}
            </Text>
          </TouchableOpacity>
        </Box>

        <Box flex alignHorizontally="end" right={2}>
          <TouchableOpacity onPress={onProceed}>
            <Text style={styles.text} {...testID('test.button.uploadPhoto')}>
              {uploadButtonString}
            </Text>
          </TouchableOpacity>
        </Box>
      </View>
    </View>
  );
};
