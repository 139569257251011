import { $Shape } from 'utility-types';

import { Address } from 'src/types/geo';
import { AddressDetailsField } from 'src/types/address';

import { getOrderedStreetTypes } from '../common/constants-it';

export const orderAddressBasedOnConfig = (
  address: Address | any,
  addressConfig: AddressDetailsField[],
): $Shape<Address> =>
  addressConfig.reduce((prev, current: any) => {
    if (address[current.id] === undefined) return prev;
    return { ...prev, [current.id]: address[current.id] };
  }, {});
export const parseStreetTypeFromAddress = (streetName: string | null | undefined) => {
  const street = (streetName || '').toLowerCase().trim();
  let bestMatch;
  const streetTypes = getOrderedStreetTypes();

  // street types are ordered descendingly based on string length.
  // thus the first match is the best match
  for (let i = 0; i < streetTypes.length; i += 1) {
    if (street.startsWith(streetTypes[i])) {
      bestMatch = streetTypes[i];
      break;
    }
  }

  return bestMatch ? `${bestMatch[0].toUpperCase()}${bestMatch.slice(1)}` : '';
};
