import React from 'react';
import { compose } from 'redux';

import { flagsQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';

import { Props as YesNoContainerProps, YesNoContainer, Options } from './yes-no';

type FirstTimeFilingComponentProps = YesNoContainerProps & {
  quizmasterLight: QuizmasterLight;
};

const FirstTimeFilingComponentUndecorated = ({
  quizmasterLight,
  ...otherProps
}: FirstTimeFilingComponentProps): JSX.Element => {
  const { safeResetNavigation, getNavigationActions } = useNavigation();
  const handlePressYes = () => {
    quizmasterLight[flagsQuestionIds.flowStarted].saveAnswer(true);
    quizmasterLight[flagsQuestionIds.prefillMandateSigned].saveAnswer(false);
    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toStatus('screen'),
    ]);
  };

  return (
    <YesNoContainer
      {...otherProps}
      handlePressYes={handlePressYes}
      optionKeys={{
        [Options.YES_KEY]: 'it.answers.first-time-filing.yes',
        [Options.NO_KEY]: 'it.answers.first-time-filing.no',
      }}
    />
  );
};

export const FirstTimeFilingComponent = compose(
  withQuizmasterLight([flagsQuestionIds.flowStarted, flagsQuestionIds.prefillMandateSigned]),
)(
  // @ts-ignore
  FirstTimeFilingComponentUndecorated,
);
