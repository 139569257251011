import { reaction } from 'mobx';
import _ from 'lodash';

import { IQuestionStore } from '../QuestionStore.types';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

export const trackCategoryChange = (categories: any, oldCategories: any, year: number) => {
  const sortedNewCategories = _.sortBy(categories, 'id');

  const sortedOldCategories = _.sortBy(oldCategories, 'id');

  if (sortedNewCategories.length === sortedOldCategories.length) {
    const difference = sortedNewCategories.filter(
      (category, index) => category.isComplete !== sortedOldCategories[index].isComplete,
    );

    if (difference.length > 0) {
      Analytics.log(AnalyticsEvent.categoryFinished, {
        year,
        category: difference[0].id,
      });
    }
  }
};
const storedCategories: any = {};
export default (store: IQuestionStore) => {
  reaction(
    () => store.categories,
    (categories) => {
      if (!_.isEmpty(storedCategories[store.year])) {
        trackCategoryChange(categories, storedCategories[store.year], store.year);
      }

      if (!storedCategories[store.year]) storedCategories[store.year] = {};
      storedCategories[store.year] = [...categories];
    },
    {
      compareStructural: true,
      delay: 1000,
    } as any,
  );
};
