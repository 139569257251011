/* global __DEV__ */
import { Platform } from 'react-native';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import AsyncStorage from '@react-native-community/async-storage';
/* eslint-disable import/no-extraneous-dependencies */
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  compose,
  createStore,
  combineReducers,
  applyMiddleware,
  Reducer,
  AnyAction,
  Store,
} from 'redux';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import UserEventEmitter from 'src/biz-logic/user/UserEventEmitter';
import setupReactotron from 'src/setup-reactotron';

import actions from './store/actions';
import initial from './store/initial';
import reducer from './store/reducer';
import { errorTracking } from './store/error-tracking';
import { selectors as userAuthSelectors } from './modules/user-auth';
import { persistWhitelist, securePersistWhitelist, transformFilters } from './store/persist-filter';
import createDebounce from './middleware/debounce';
import { setStore } from './util';
import { getDynamicMiddlewares } from './middleware/middlewares';
import { createSelfClearingSecureStorage } from './utils/self-clearing-secure-storage';
import { migrations } from './migrations/index';
import { rootReducer } from './modules/root';

let Reactotron: any;

// flag used to disable reactotron and redux-devtools for automated testing
const isTestEnv = process.env.NODE_ENV === 'test';

const isDevEnv = __DEV__ && !isTestEnv;

if (isDevEnv) {
  Reactotron = setupReactotron(AsyncStorage);
  logger.log('Reactotron Configured, please remember to run "adb reverse tcp:9090 tcp:9090"');
}

export const persist = {
  key: 'taxfix-storage',
  version: 12,
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: persistWhitelist,
  transforms: transformFilters,
  migrate: createMigrate(migrations, {
    debug: false,
  }),
};

const secureStorage =
  Platform.OS !== 'web'
    ? createSelfClearingSecureStorage('taxfix-secure-storage', {
        keychainService: 'taxfixKeychain',
        sharedPreferencesName: 'taxfixSharedPreferences',
      })
    : AsyncStorage;

const createPersistedSecureReducer = (reducerName: string, baseReducer: any) => {
  const config = {
    key: `taxfix-${reducerName}`,
    storage: secureStorage,
    stateReconciler: autoMergeLevel2,
  };
  return persistReducer(config, baseReducer);
};

// @ts-expect-error
const window = window || {}; // eslint-disable-line

const composeFunction = isDevEnv ? (composeWithDevTools as typeof compose) : compose;

export const create = (current: typeof initial = initial, config: typeof persist = persist) => {
  const secureReducers = securePersistWhitelist.reduce((reducerMap, reducerName) => {
    return {
      ...reducerMap,
      [reducerName]: createPersistedSecureReducer(
        reducerName,
        (reducer as Record<string, any>)[reducerName],
      ),
    };
  }, {});
  const reducers = combineReducers({ ...reducer, ...secureReducers });
  const enhancers = [applyMiddleware(...getDynamicMiddlewares([thunk, createDebounce()]))];
  if (isDevEnv) {
    enhancers.push(Reactotron.createEnhancer());
  }
  const currentStore = createStore(
    persistReducer(config, rootReducer(reducers) as Reducer),
    // https://github.com/rt2zz/redux-persist/pull/1170
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    current,
    // @ts-ignore
    composeFunction(...enhancers),
  );
  setStore(currentStore);
  return currentStore;
};

export const createPersistor = (store: Store<any, AnyAction>) => persistStore(store);

export const attachListeners = (store: Store<any, AnyAction>) => {
  UserEventEmitter.subscribe((email: string) => {
    if (email && !userAuthSelectors.getEmailAddress(store.getState())) {
      store.dispatch(actions.userAuth.updateUserEmail(email));
    }
  });
  store.subscribe(() => {
    const state = store.getState();
    if (logger.errorTrackingInstance) {
      logger.errorTrackingInstance.updateGlobalTags(errorTracking(state));
    }
  });
};
