import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Config from 'react-native-config';
import { User } from '@taxfix/taxfix-sdk';
import { useRoute } from '@react-navigation/native';

import { updateTrackingConsent } from 'src/utils/tracking';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import * as Storage from 'src/utils/asyncStorage';
import { configKey } from 'src/services/firebase-functions';
import getNotificationsManager from 'src/utils/push-notifications';
import { ScreenName } from 'src/types/screen-name';

import { useNavigation } from './navigation-hook';

type Output = {
  handleMarketingChange: (enabled: boolean) => Promise<void>;
  handleTrackingChange: (enabled: boolean) => Promise<void>;
  trackingEnabled: boolean;
  marketingEmails: boolean;
  togglingMarketingEmails: boolean;
  togglingTracking: boolean;
};
export const usePrivacySettings = (): Output => {
  const manager = getNotificationsManager();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId);
  const email = useSelector(userAuthSelectors.getEmailAddress);

  const [trackingEnabled, setTrackingEnabled] = useState<boolean>(true);
  const [togglingTracking, setTogglingTracking] = useState<boolean>(true);
  const [marketingEmails, setMarketingEmails] = useState<boolean>(false);
  const [togglingMarketingEmails, setTogglingMarketingEmails] = useState<boolean>(true);

  const { isFocused } = useNavigation();

  const route = useRoute();

  useEffect(() => {
    const init = async () => {
      Storage.getItem(configKey.optOutFromTracking).then((value: any) => {
        // optOut == false => tracking enabled
        // optOut == true => tracking disabled
        setTrackingEnabled(!value);
        setTogglingTracking(false);
      });
      try {
        const consent = await User.getMarketingEmailConsent(Config.API_BASE_URL, accessToken);
        setMarketingEmails(consent);
        setTogglingMarketingEmails(false);
      } catch (error) {
        setTogglingMarketingEmails(false);
        setMarketingEmails(false);
      }
    };
    if (isFocused) {
      init();
    }
  }, [manager, accessToken, selectedCountry, route, isFocused]);

  const handleMarketingChange = async (value: boolean) => {
    try {
      setMarketingEmails(value);
      setTogglingMarketingEmails(true);
      await User.updateMarketingEmailConsent(Config.API_BASE_URL, accessToken, {
        consent: value,
        source: selectedCountry,
      });
    } catch (error) {
      setMarketingEmails(!value);
    }
    setTogglingMarketingEmails(false);
  };

  const handleTrackingChange = async (optedIn: boolean) => {
    if (!email || !userId) {
      return;
    }
    try {
      setTrackingEnabled(optedIn);
      setTogglingTracking(true);
      await updateTrackingConsent({
        email,
        userId,
        consent: optedIn,
        accessToken,
        selectedCountry,
        screenName: ScreenName.PrivacySettings,
      });
    } catch (e) {
      setTrackingEnabled(optedIn);
    }
    setTogglingTracking(false);
  };

  return {
    handleMarketingChange,
    handleTrackingChange,
    trackingEnabled,
    marketingEmails,
    togglingMarketingEmails,
    togglingTracking,
  };
};
