import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const ExpenseIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#E1F8E6" />
    <Path d="M18.6543 23.5078H22.493L21.8878 24.8652H19.2811L18.6543 23.5078Z" fill="#1C2033" />
    <Path d="M20.7508 22.8477H20.2363V23.9327H20.7508V22.8477Z" fill="#1C2033" />
    <Path d="M25.2941 16.9453H15.7275V22.6429H25.2941V16.9453Z" fill="#EFDDDC" />
    <Path d="M25.3333 17.0586H15.7451V22.5357H25.3333V17.0586Z" fill="#32C850" />
    <Path
      d="M20.5217 23.1633C23.1837 23.1633 25.3417 22.902 25.3417 22.5797C25.3417 22.2574 23.1837 21.9961 20.5217 21.9961C17.8597 21.9961 15.7017 22.2574 15.7017 22.5797C15.7017 22.902 17.8597 23.1633 20.5217 23.1633Z"
      fill="#1AA331"
    />
    <Path
      d="M20.5217 17.5988C23.1837 17.5988 25.3417 17.3375 25.3417 17.0152C25.3417 16.6929 23.1837 16.4316 20.5217 16.4316C17.8597 16.4316 15.7017 16.6929 15.7017 17.0152C15.7017 17.3375 17.8597 17.5988 20.5217 17.5988Z"
      fill="#32C850"
    />
    <Path d="M17.6685 45.1044H17.0806L19.2117 24.5059H20.8026L17.6685 45.1044Z" fill="#1C2033" />
    <Path d="M23.4871 45.0321H24.075L21.9438 24.4336H20.353L23.4871 45.0321Z" fill="#1C2033" />
    <Path
      d="M21.7971 45.019H21.0622L19.9253 26.3571C19.9253 26.3571 20.2192 25.3672 20.3273 25.3672C20.4354 25.3672 21.3562 25.7346 21.3562 25.7346L21.7971 45.019Z"
      fill="#1C2033"
    />
    <Path
      d="M25.6142 47.5253L25.7525 47.5685C25.4715 47.482 25.3851 47.2054 25.4715 46.9244L26.401 43.4229C26.4874 43.1419 26.7857 42.9862 27.0667 43.0727C27.3477 43.1592 27.5033 43.4574 27.4168 43.7384L26.2108 47.1535C26.12 47.4302 25.8909 47.6117 25.6142 47.5253Z"
      fill="#1C2033"
    />
    <Path
      d="M45.6507 47.5253L45.5124 47.5685C45.7934 47.482 45.8798 47.2054 45.7934 46.9244L44.864 43.4229C44.7775 43.1419 44.4792 42.9862 44.1982 43.0727C43.9173 43.1592 43.7616 43.4574 43.8481 43.7384L45.0542 47.1535C45.145 47.4302 45.3741 47.6117 45.6507 47.5253Z"
      fill="#1C2033"
    />
    <Path
      d="M46.714 40.2679L45.7673 43.013L45.2443 44.526H25.2855L24.8359 43.013L23.4224 38.2275L44.2846 37.2852L46.714 40.2679Z"
      fill="#1AA331"
    />
    <Path
      d="M46.714 40.2679L45.7673 43.013H24.8359L23.4224 38.2275L44.2846 37.2852L46.714 40.2679Z"
      fill="#1C2033"
    />
    <Path
      d="M35.2672 38.5955V31.4195C35.2672 30.5939 34.5971 29.9238 33.7715 29.9238H26.7814C25.9514 29.9238 25.2856 30.5939 25.2856 31.4195V38.5955V39.486H35.2628V38.5955H35.2672Z"
      fill="#32C850"
    />
    <Path
      d="M26.1718 31.4156C26.1718 30.59 26.8376 29.9199 27.6676 29.9199H26.7814C25.9514 29.9199 25.2856 30.59 25.2856 31.4156V38.5916V39.4821H26.1718V38.5916V31.4156Z"
      fill="#1C2033"
    />
    <Path
      d="M45.2443 38.5955V31.4195C45.2443 30.5939 44.5742 29.9238 43.7486 29.9238H36.7628C35.9328 29.9238 35.2671 30.5939 35.2671 31.4195V38.5955V39.486H45.2443V38.5955Z"
      fill="#32C850"
    />
    <Path
      d="M36.1533 31.4156C36.1533 30.59 36.819 29.9199 37.649 29.9199H36.7628C35.9328 29.9199 35.2671 30.59 35.2671 31.4156V38.5916V39.4821H36.1533V38.5916V31.4156Z"
      fill="#1C2033"
    />
    <Path
      d="M47.0035 44.5267H45.2441V35.1115C45.2441 34.7095 45.6591 34.4415 46.0266 34.6057L47.9719 35.479C48.188 35.5741 48.3177 35.7989 48.2961 36.0366L47.5698 44.0123C47.5396 44.3063 47.2975 44.5267 47.0035 44.5267Z"
      fill="#1AA331"
    />
    <Path
      d="M23.5258 44.5267H25.2853V35.1115C25.2853 34.7095 24.8703 34.4415 24.5028 34.6057L22.5575 35.479C22.3414 35.5741 22.2117 35.7989 22.2333 36.0366L22.9639 44.0123C22.9898 44.3063 23.2319 44.5267 23.5258 44.5267Z"
      fill="#1AA331"
    />
    <Path
      d="M45.1492 42.7788H25.3807C25.0003 42.7788 24.6934 42.4719 24.6934 42.0915V40.1678C24.6934 39.7874 25.0003 39.4805 25.3807 39.4805H45.1492C45.5296 39.4805 45.8366 39.7874 45.8366 40.1678V42.0915C45.8366 42.4719 45.5296 42.7788 45.1492 42.7788Z"
      fill="#3CDD57"
    />
    <Path
      d="M26.1848 42.3005V40.2255C26.1848 39.8149 26.4917 39.4863 26.8721 39.4863H25.3807C25.0003 39.4863 24.6934 39.8192 24.6934 40.2255V42.3005C24.6934 42.7112 25.0003 43.0397 25.3807 43.0397H26.8721C26.4917 43.0441 26.1848 42.7112 26.1848 42.3005Z"
      fill="#1C2033"
    />
    <Path
      d="M38.2151 33.1967C38.0378 33.1967 37.8779 33.0757 37.8303 32.9028C37.7871 32.7515 37.8433 32.5786 37.9687 32.4835C38.241 32.2716 38.6474 32.4921 38.6128 32.8379C38.5955 33.0411 38.4183 33.1967 38.2151 33.1967Z"
      fill="white"
    />
    <Path
      d="M42.2917 33.1967C42.1145 33.1967 41.9546 33.0757 41.907 32.9028C41.8638 32.7515 41.92 32.5786 42.0453 32.4835C42.3177 32.2716 42.724 32.4921 42.6894 32.8379C42.6721 33.0411 42.4949 33.1967 42.2917 33.1967Z"
      fill="white"
    />
    <Path
      d="M38.215 37.0043C37.8562 37.0043 37.6789 36.5418 37.9513 36.3084C38.2193 36.0706 38.6602 36.2997 38.6127 36.6542C38.5867 36.8487 38.4181 37.0043 38.215 37.0043Z"
      fill="white"
    />
    <Path
      d="M42.2914 37.004C41.9283 37.004 41.7597 36.5371 42.032 36.3037C42.3044 36.0702 42.7323 36.2994 42.6891 36.6538C42.6632 36.8484 42.4946 37.004 42.2914 37.004Z"
      fill="white"
    />
    <Path
      d="M28.2379 33.1965C27.8705 33.1965 27.7019 32.721 27.9829 32.4876C28.2466 32.2714 28.6529 32.4746 28.6356 32.8161C28.627 33.0279 28.4454 33.1965 28.2379 33.1965Z"
      fill="white"
    />
    <Path
      d="M32.3142 33.1967C32.137 33.1967 31.977 33.0757 31.9295 32.9028C31.8862 32.7515 31.9424 32.5786 32.0678 32.4835C32.3401 32.2716 32.7465 32.4921 32.7119 32.8379C32.6903 33.0411 32.5174 33.1967 32.3142 33.1967Z"
      fill="white"
    />
    <Path
      d="M28.2378 37.0058C27.892 37.0058 27.7148 36.5778 27.9482 36.3314C28.0649 36.2104 28.2551 36.1758 28.4064 36.2493C28.5577 36.3228 28.6485 36.4827 28.6312 36.647C28.6139 36.8459 28.441 37.0058 28.2378 37.0058Z"
      fill="white"
    />
    <Path
      d="M32.3143 37.0043C31.9512 37.0043 31.7783 36.5375 32.0549 36.304C32.1889 36.1873 32.3878 36.1743 32.5391 36.2738C32.6774 36.3645 32.7466 36.5418 32.7034 36.7017C32.6558 36.8746 32.4959 37.0043 32.3143 37.0043Z"
      fill="white"
    />
  </Icon>
);
