import { CountryCodes } from '@taxfix/types';

import { dynamicLinkParamKeys } from '../dynamic-link-types';
import { getNavigationActions, safeResetNavigation } from '../../routes/config-util';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from '../../stores/modules/settings';
import { actions as toolsActions, ConfigKeys } from '../../stores/modules/dev-tools';
import { actions as userAuthActions } from '../../stores/modules/user-auth';
import { actions as remoteConfigFirebaseActions } from '../../stores/modules/remote-config-firebase';
import { DynamicLinkCallbacks, RedirectRequest } from '../../stores/modules/dynamic-link-types';
import { selectCountryAndYear } from '../country-and-year';
import { parseQuery } from '../dynamic-link-util';
import { getCustomUUID } from '../device';

const getRedirectToUserTesting = ({
  dispatch,
  link,
  getState,
}: RedirectRequest): DynamicLinkCallbacks => {
  const query = parseQuery(link);
  const taxCountry: CountryCodes = query[dynamicLinkParamKeys.taxCountry];
  if (!taxCountry) throw new Error('param-not-found');
  const packageId: string = query[dynamicLinkParamKeys.taxPackage];
  if (packageId) dispatch(toolsActions.setConfig(ConfigKeys.PackageId, packageId));
  const randomNumberStr: string = query[dynamicLinkParamKeys.randomNumber];

  if (randomNumberStr) {
    const randomNumber = parseInt(randomNumberStr, 10);

    if (!Number.isNaN(randomNumber)) {
      dispatch(settingsActions.forceRandomNumber(randomNumber));
      dispatch(remoteConfigFirebaseActions.requestUpdate());
    }
  }

  dispatch(toolsActions.setConfig(ConfigKeys.ForceInTaxSeason, true));
  dispatch(toolsActions.setConfig(ConfigKeys.DeviceId, getCustomUUID()));

  const onBeforeRedirect = () => {
    dispatch(userAuthActions.reset());

    if (settingsSelectors.selectedCountry(getState()) !== taxCountry) {
      selectCountryAndYear(taxCountry);
    }
  };

  const onRedirect = () => {
    safeResetNavigation([getNavigationActions().toOnboardingSplash('screen')]);
  };

  return {
    onBeforeRedirect,
    onRedirect,
    onPostRedirect: null,
  };
};

export { getRedirectToUserTesting };
