import { useBreakpointValue } from 'native-base';
import React, { ReactNode } from 'react';
import { FileRejection } from 'react-dropzone';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { Documents } from '@taxfix/types';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { Container, IconButton, icons, NavButton, Text } from 'src/taxfix-components/src';
import { DocumentFolderFooter } from 'src/_italy/document-folder-footer/folder-footer-component';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { InformationButton } from 'src/components/information-button';
import { DocumentsExpensesUploadedList } from 'src/screens/containers/required-documents/required-documents-upload';
import { resolveFileName } from 'src/_italy/prefill-documents-result/utils';

import { CWBIDocumentTypes, DocumentGuidanceCategoryName } from '../../../common/constants-it';
import LinkableText from '../../../components/auth/LinkableText';
import { BodyTitleText, Box, theme } from '../../../components/core';
import NotificationBanner from '../../../components/notification-banner';
import { ThumbnailButton } from '../../../containers/input/thumbnail-button';
import { ScreenMode } from '../../../routes/config-util';
import { DocumentBunch } from '../../../stores/modules/required-documents';
import { actions as OverlayActions } from '../../../stores/modules/overlay';
import { isWeb } from '../../../utils/platform';
import { FileUploadCommon } from '../file-upload-common';

import { DocDeleteButton } from './doc-delete-button';
import StatusTag, { StatusTagStates } from './status-tag';
import { PrefilledExpensesList } from './prefilled-expenses-list';

type Props = {
  categoryKey: DocumentGuidanceCategoryName;
  errorBanner: string | null | undefined;
  notificationBanner: string | null | undefined;
  onOpenCamera: () => void;
  onOpenGallery: () => void;
  onOpenFileBrowser: () => Promise<void>;
  onPreview: (screenName: ScreenMode, params: {}) => void;
  onDeleteDocuments: (documentId?: number, shouldNavigateBack?: boolean) => void;
  translationValues: {
    count: number;
    taxYear: string | number;
    CUYear: string | number;
  };
  leadTranslationKey?: string;
  color?: string;
  docBunch: DocumentBunch;
  bottomButtons: {
    left: {
      disabled?: boolean;
      onPress: (...args: Array<any>) => any;
      titleKey: string;
    };
    right: {
      disabled?: boolean;
      onPress: (...args: Array<any>) => any;
      titleKey: string;
    };
  };
  titleText: string;
  children?: ReactNode;
  customLinkHandler?: (url: string) => void;
  onPressBackButton?: () => void;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  embedded?: boolean;
  expensesList?: DocumentsExpensesUploadedList[];
  prefilledExpenseSubtitleKey?: string;
  onDeleteExpenseItem?: (categoryKey: string, item: DocumentsExpensesUploadedList) => void;
};

// order to show IT documents in
const documentOrder = [
  Documents.States.Rejected, // Not approved
  Documents.States.NotConsidered, // Not approved
  Documents.States.Approved, // Approved
  Documents.States.Created, // wouldn't happen
];

// when switching @taxfix/types to the types from the @taxfix/documents-sdk,
// extend the types on the @taxfix/documents-sdk, bump the version here and use
// cwbi document types from there
const isPrefilledDocument = (documentType: string) => {
  return Object.values(CWBIDocumentTypes).includes(documentType);
};

export const RequiredDocumentsUpload: React.FC<Props> = (props) => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();

  const dispatch = useDispatch();

  const backNavigationText = getTranslationText('it.back.documents.list');
  const containerWidth = useBreakpointValue({ base: '100%', lg: 580 });
  const containerHeight = useBreakpointValue({ base: 120, md: 140 });
  const mainTextVariant = useBreakpointValue({ base: 'bodyMBook', md: 'bodyLBook' });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const styles = React.useMemo(
    () =>
      StyleSheet.create({
        pageContainer: {
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        itemContainer: {
          borderWidth: 1,
          borderColor: theme.color.lightBorder,
          width: containerWidth,
          height: containerHeight,
          alignItems: 'center',
          borderRadius: 20,
          marginTop: 16,
          flexDirection: 'row',
        },
      }),
    [containerWidth, containerHeight],
  );
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const {
    onOpenCamera,
    onOpenFileBrowser,
    onOpenGallery,
    onPreview,
    docBunch,
    color,
    onDrop,
    onDeleteDocuments,
    categoryKey,
    embedded,
  } = props;

  // sort the documents by the defined order above
  // if equal, sort by oldest (lowest id first)
  docBunch.items.sort((a, b) => {
    let aIndex, bIndex;
    // if it's a new state not considered yet, we assign a high number (9) so it goes last
    // indexOf returns -1 if it doesn't exist, which would sort it over all others
    documentOrder.indexOf(a.state) === -1
      ? (aIndex = 9)
      : (aIndex = documentOrder.indexOf(a.state));
    documentOrder.indexOf(b.state) === -1
      ? (bIndex = 9)
      : (bIndex = documentOrder.indexOf(b.state));
    return aIndex - bIndex || a.id - b.id;
  });

  const addButtonProps = React.useMemo(() => {
    return {
      color,
      options: [
        ...(!isWeb
          ? [
              {
                translationKey: 'requiredDocument.lead.capturePhoto',
                onPress: onOpenCamera,
              },
            ]
          : []),
        {
          translationKey: 'requiredDocument.lead.uploadDocument',
          onPress: onOpenFileBrowser,
        },
        {
          translationKey: isWeb
            ? 'requiredDocument.lead.photoGallery.web'
            : 'requiredDocument.lead.photoGallery',
          onPress: onOpenGallery,
        },
      ],
    };
  }, [onOpenCamera, onOpenFileBrowser, onOpenGallery, color]);

  const getGrid = React.useCallback(
    (states: Documents.States[], reviewed = false) => {
      const filteredDocuments = docBunch.items.filter((doc) => states.includes(doc.state));
      const docList = filteredDocuments.map((item, index) => {
        const { id, thumbnail, path, metadata, createdAt, type } = item;
        // This is a temporary solution on Web to display the thumbnails of the previously uploaded
        // documents, but it doesn't take care of newly uploaded document thumbnails. Those either
        // have to be fetched right after the upload finished or the <ThumbnailButton> component
        // needs to be updated to work on the Web with URLs that need authentication.
        // todo https://taxfix.atlassian.net/browse/ITA-975
        const uri = thumbnail ? `data:image/jpeg;base64,${thumbnail}` : undefined;

        let parsedMetadata = undefined;

        // Safely parse metadata as JSON
        if (metadata) {
          try {
            parsedMetadata = JSON.parse(metadata.toString());
          } catch (error) {}
        }

        const contentType = parsedMetadata?.contentType;
        const originalName = parsedMetadata?.originalName;
        const formattedDate = createdAt
          ? `Uploaded ${moment(createdAt).format('DD/MM/YYYY')}`
          : undefined;
        // if the document has been reviewed, the user can't delete it
        const onDelete = reviewed ? null : () => onDeleteDocuments(id, true);

        return (
          <Container key={id} style={styles.itemContainer}>
            <Container m={2}>
              <ThumbnailButton
                id={id}
                testId={id}
                uri={uri}
                onPress={() => {
                  onPreview('screen', {
                    id,
                    document: {
                      uri: path,
                      contentType,
                    },
                    handleDelete: onDelete,
                    categoryKey: categoryKey,
                  });
                }}
              />
            </Container>
            <Container maxWidth="50%">
              {originalName && (
                <Text
                  variant={mainTextVariant}
                  marginBottom={8}
                  value={resolveFileName(item, index)}
                  numberOfLines={2}
                  mr={1}
                />
              )}
              {formattedDate && (
                <Text variant="bodyXSBook" color="greytones.graphicsDark" value={formattedDate} />
              )}
            </Container>

            {reviewed ? (
              <StatusTag status={item.state} />
            ) : isPrefilledDocument(type) ? (
              <StatusTag status={StatusTagStates.prefilled} />
            ) : (
              <DocDeleteButton onPress={() => onDeleteDocuments(id, false)} />
            )}
          </Container>
        );
      });

      return docList;
    },
    [docBunch, onPreview, onDeleteDocuments, categoryKey, styles, mainTextVariant],
  );

  const handleLinkPressed = (url: string) => {
    if (props.customLinkHandler) {
      props.customLinkHandler(url);
    }
  };

  const handleOnCloseFolderFooter = () => {
    navigationActions.back();
  };

  const {
    errorBanner,
    notificationBanner,
    translationValues,
    leadTranslationKey,
    titleText,
    children,
    expensesList = [],
    prefilledExpenseSubtitleKey,
    onDeleteExpenseItem,
  } = props;

  const documentItemsReviewed = getGrid(
    [Documents.States.Approved, Documents.States.Rejected, Documents.States.NotConsidered],
    true,
  );
  const documentItemsCreated = getGrid([Documents.States.Created], false);

  const uploadedDocsTextVariant = useBreakpointValue({
    base: 'titleXXSMedium',
    md: 'titleXSMedium',
  });
  const uploadedDocsTextMarginTop = useBreakpointValue({ base: '10px', md: '44px' });
  const screenTitleVariant = useBreakpointValue({ base: 'titleSBold', md: 'titleLBold' });
  const hasCreatedDocs = documentItemsCreated.length > 0;
  const hasReviewedDocs = documentItemsReviewed.length > 0;

  const linkableTextStyle = {
    fontSize: isSmallScreen ? 14 : 16,
    lineHeight: isSmallScreen ? 18 : 20,
  };

  const hasExpenses = expensesList?.length > 0;
  const getBold = (text: string) => (
    <Text variant="subtitleMMedium" fontWeight="bold" value={text} />
  );

  return (
    <Box
      testId="required-documents-upload-root"
      style={[styles.pageContainer, { maxWidth: isLargeScreen ? 'auto' : 800 }]}
      flex
    >
      <Box testId="required-documents-upload-section" flex>
        {!isSmallScreen && <NavButton onPress={navigationActions.back} text={backNavigationText} />}

        <Box alignHorizontally="center" flex>
          {!embedded && notificationBanner && (
            <Box bottom={0.5}>
              <NotificationBanner errorType="Success">
                <BodyTitleText
                  style={{
                    color: theme.color.lightText,
                  }}
                >
                  {notificationBanner}
                </BodyTitleText>
              </NotificationBanner>
            </Box>
          )}
          {!embedded && errorBanner && (
            <NotificationBanner errorType="Warning">
              <BodyTitleText
                style={{
                  color: theme.color.lightText,
                }}
              >
                {errorBanner}
              </BodyTitleText>
            </NotificationBanner>
          )}

          <ScrollView
            style={{
              maxWidth: 1200,
              paddingHorizontal: !isSmallScreen ? 50 : 0,
            }}
          >
            <Box alignHorizontally="center" left={2} right={2}>
              <Box>
                {!embedded && (
                  <Box bottom={1} top={isLargeScreen ? 4 : 2}>
                    <Text variant={screenTitleVariant} value={titleText} />
                  </Box>
                )}
                {!embedded && leadTranslationKey && (
                  <Box bottom={isLargeScreen ? 2 : 3}>
                    <LinkableText
                      translationKey={leadTranslationKey}
                      translationValues={translationValues}
                      onPress={undefined}
                      textStyle={linkableTextStyle}
                      linkStyle={linkableTextStyle}
                    />
                  </Box>
                )}
                <Box bottom={3}>
                  <InformationButton
                    color={theme.color.primary}
                    size="xsmall"
                    withText
                    informationText={getTranslationText('general.find-more.link-text')}
                    onPress={() => handleLinkPressed('DocumentUploadGuidance')}
                  />
                </Box>
                {children != null && (
                  <Box right={2} bottom={1}>
                    {children}
                  </Box>
                )}
                {!isSmallScreen && (
                  <Container
                    width={isLargeScreen ? '100%' : 'auto'}
                    marginRight={isLargeScreen ? '24px' : '0px'}
                  >
                    <FileUploadCommon buttonProps={addButtonProps} onDrop={onDrop} />
                  </Container>
                )}
              </Box>
            </Box>
            <Box
              alignHorizontally="center"
              left={isLargeScreen ? 2 : 0}
              right={isLargeScreen ? 2 : 0}
            >
              {hasExpenses && prefilledExpenseSubtitleKey && (
                <Container marginBottom={16} marginTop={20} width={'100%'}>
                  <PrefilledExpensesList
                    translationKeysClosed={{
                      title: getTranslationText(
                        'it.documents-uploaded.prefilled-expenses-list.title.closed',
                      ),
                      subtitle: getTranslationText(
                        'it.documents-uploaded.prefilled-expenses-list.subtitle.closed',
                        {
                          bold: getBold,
                          document: titleText,
                        },
                      ),
                    }}
                    translationKeysOpen={{
                      title: getTranslationText(
                        'it.documents-uploaded.prefilled-expenses-list.title.open',
                      ),
                      subtitle: getTranslationText(prefilledExpenseSubtitleKey, { bold: getBold }),
                    }}
                    expensesList={expensesList}
                    onDeleteExpenseItem={onDeleteExpenseItem}
                    categoryKey={categoryKey}
                  />
                </Container>
              )}
            </Box>
            <Box left={2} right={2} bottom={4}>
              <Container justifyContent="space-between">
                {hasCreatedDocs && (
                  <Container>
                    <Text
                      marginTop={uploadedDocsTextMarginTop}
                      variant={uploadedDocsTextVariant}
                      value={getTranslationText(
                        'it.digital-caf.required-documents.upload.document-list.uploaded-docs',
                        { numberOfDocs: documentItemsCreated.length },
                      )}
                    />
                    <FlatList
                      data={documentItemsCreated}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      renderItem={(item: { item: any }) => {
                        return item.item;
                      }}
                    />
                  </Container>
                )}
                {hasReviewedDocs && (
                  <Container>
                    <Container
                      marginTop={uploadedDocsTextMarginTop}
                      display="flex"
                      alignItems="center"
                    >
                      <Text
                        variant={uploadedDocsTextVariant}
                        value={getTranslationText(
                          'it.digital-caf.required-documents.upload.document-list.reviewed-docs',
                          { numberOfDocs: documentItemsReviewed.length },
                        )}
                      />
                      <IconButton
                        borderRadius="6px"
                        variant="grey"
                        marginLeft="6px"
                        path={icons.Infooutlined}
                        size={4}
                        onPress={() => {
                          dispatch(
                            OverlayActions.show('DocumentUploadInfoOverlay', {
                              sections: [
                                {
                                  content:
                                    'it.digital-caf.tailored-documents.document.income-cu.employee.section.1.content',
                                },
                              ],
                            }),
                          );
                        }}
                      />
                    </Container>
                    <FlatList
                      data={documentItemsReviewed}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      renderItem={(item: { item: any }) => {
                        return item.item;
                      }}
                    />
                  </Container>
                )}
              </Container>
            </Box>
          </ScrollView>
        </Box>
        {isSmallScreen && (
          <Box style={{ justifyContent: 'flex-end' }}>
            <DocumentFolderFooter
              hasDocumentsUploaded={hasCreatedDocs}
              addButtonProps={addButtonProps}
              onDrop={onDrop}
              onCloseFolder={handleOnCloseFolderFooter}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
