import Config from 'react-native-config';
import { Dispatch } from 'redux';
import { Platform } from 'react-native';
import Device from 'react-native-device-info';
import * as routerSDK from '@taxfix/router-api-sdk';
import { Platforms } from '@taxfix/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import Analytics from 'src/biz-logic/analytics';
import { getUniqueDeviceID } from 'src/services/device';

import {
  State as Settings,
  RootState as RootSettingsState,
  selectors as settingsSelectors,
} from './settings';
import { State as DevTools, TaxSystem, TaxSystems } from './dev-tools';
import { State as RemoteConfig } from './remote-config-firebase';

export const FETCH_TAX_SYSTEM_SUCCESS = 'taxSystem/FETCH_TAX_SYSTEM_SUCCESS';
export const FETCH_TAX_SYSTEM_FAILED = 'taxSystem/FETCH_TAX_SYSTEM_FAILED';

type FetchTaxSystemSuccess = {
  type: typeof FETCH_TAX_SYSTEM_SUCCESS;
  selectedTaxSystem: TaxSystem;
};

type FetchTaxSystemFailed = {
  type: typeof FETCH_TAX_SYSTEM_FAILED;
  selectedTaxSystem: TaxSystem;
};

type Action = FetchTaxSystemSuccess | FetchTaxSystemFailed;

const fetchTaxSystemSuccess = (selectedTaxSystem: TaxSystem): FetchTaxSystemSuccess => ({
  type: FETCH_TAX_SYSTEM_SUCCESS,
  selectedTaxSystem,
});

const fetchTaxSystemFailed = (selectedTaxSystem: TaxSystem): FetchTaxSystemFailed => ({
  type: FETCH_TAX_SYSTEM_FAILED,
  selectedTaxSystem,
});

const mapRouterSystem = (
  route: routerSDK.v1.TaxEngine.RegisteredUserResponse['route'],
): TaxSystem => {
  switch (route) {
    case 'qm':
      return TaxSystems.Legacy;
    case 'te':
      return TaxSystems.TaxEngine;
  }
};

export const DEFAULT_TAX_SYSTEM = TaxSystems.Legacy;

const fetchTaxSystem =
  (taxYear: number | string, accessToken: string | null = null, userId: number | null = null) =>
  async (
    dispatch: Dispatch<FetchTaxSystemSuccess | FetchTaxSystemFailed>,
    getState: () => RootSettingsState,
  ): Promise<TaxSystem> => {
    const countryCode = settingsSelectors.selectedCountry(getState());
    const deviceId = await getUniqueDeviceID();

    try {
      let response: routerSDK.v1.TaxEngine.RegisteredUserResponse;
      let anonymousId;
      try {
        anonymousId = await Analytics.getAnonymousId();
      } catch (e) {
        // only debug level because this is optional
        logger.debug(e);
      }

      if (!accessToken || !userId) {
        response = await routerSDK.v1.TaxEngine.anonymousUser(Config.API_BASE_URL, {
          year: Number(taxYear),
          countryCode,
          platformName: Platform.OS as Platforms,
          platformVersion: Device.getVersion(),
          anonymousId,
          deviceId,
        });
      } else {
        response = await routerSDK.v1.TaxEngine.registeredUser(
          Config.API_BASE_URL,
          {
            tokenType: 'Token',
            accessToken,
          },
          {
            year: Number(taxYear),
            countryCode,
            userId,
            platformName: Platform.OS as Platforms,
            platformVersion: Device.getVersion(),
            anonymousId,
            deviceId,
          },
        );
      }

      const route = mapRouterSystem(response.route);
      dispatch(fetchTaxSystemSuccess(route));
      return route;
    } catch (error) {
      logger.error(error);
      dispatch(fetchTaxSystemFailed(DEFAULT_TAX_SYSTEM));
      return DEFAULT_TAX_SYSTEM;
    }
  };

export type State = {
  isLoading: boolean;
  selectedTaxSystem: TaxSystem | undefined;
};

export const initial: State = {
  isLoading: false,
  selectedTaxSystem: undefined,
};

export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case FETCH_TAX_SYSTEM_SUCCESS:
      return { ...state, selectedTaxSystem: action.selectedTaxSystem };

    case FETCH_TAX_SYSTEM_FAILED:
      return { ...state, selectedTaxSystem: action.selectedTaxSystem };

    default:
      return state;
  }
};

export type RootState = {
  taxSystem: State;
  remoteConfigFirebase: RemoteConfig;
  devTools: DevTools;
  settings: Settings;
};

const getTaxSystem = (state: RootState): TaxSystem | undefined => state.taxSystem.selectedTaxSystem;

export const actions = { fetchTaxSystem };
export const selectors = { getTaxSystem };
