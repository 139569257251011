import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Configs } from '../../utils/remote-config-helpers';
import { selectors as remoteConfigFirebaseSelectors } from '../../stores/modules/remote-config-firebase';

const mapStateToProps = (stores: any) => ({
  finalConfig: remoteConfigFirebaseSelectors.getRemoteConfig(stores).configs,
});

function DebugConfigViewerUndecorated({ finalConfig }: { finalConfig: Configs } | any) {
  const localConfig = require('../../../remote-config/local.json');
  const localConfigKeys = Object.keys(localConfig);
  const finalConfigKeys = Object.keys(finalConfig);
  const sortedKeys = localConfigKeys.concat(_.without(finalConfigKeys, ...localConfigKeys));

  return (
    <ScrollView>
      <View style={{ padding: 5 }}>
        <Text style={{ fontSize: 9 }}>{`{`}</Text>
        <View style={{ padding: 5 }}>
          {sortedKeys.map((k, index, arr) => {
            const isLast = arr.length - index === 1;
            return (
              <View key={index}>
                <Text style={{ fontSize: 9 }}>
                  <Text style={{ color: localConfigKeys.includes(k) ? 'red' : 'black' }}>
                    {`"${k}": `}
                  </Text>
                  <Text>{`${JSON.stringify(finalConfig[k], null, 2)}${isLast ? '' : ',\n'}`}</Text>
                </Text>
              </View>
            );
          })}
        </View>
        <Text style={{ fontSize: 9 }}>{`}`}</Text>
      </View>
    </ScrollView>
  );
}

// @ts-ignore
export const DebugConfigViewer = connect(mapStateToProps)(DebugConfigViewerUndecorated);
