import React from 'react';

import { BaseThemeProvider, ThemeProviderProps } from './BaseThemeProvider';
import {
  Theme as ItalyTheme,
  ThemeProps as ItalyThemeProps,
  Themes as ItalyThemes,
  NBTheme,
  theme as italyTheme,
} from './it';

const ItalyThemeProvider: React.FC<Omit<ThemeProviderProps, 'styledTheme'>> = (props) => (
  <BaseThemeProvider styledTheme={italyTheme} {...props} />
);

export { BaseThemeProvider, ItalyThemeProvider, NBTheme, italyTheme };
export type { ItalyTheme, ItalyThemeProps, ItalyThemes };
