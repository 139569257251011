import { $Values } from 'utility-types';

export enum LoginError {
  EmailInvalid = 'EmailInvalid',
  PasswordMissing = 'PasswordMissing',
  UserUnknown = 'UserUnknown',
  LoginFailed = 'LoginFailed',
  UserBlocked = 'UserBlocked',
  CloseAccountVerificationFailed = 'CloseAccountVerificationFailed',
  ConnectionFailed = 'ConnectionFailed',
  LoginSsoError = 'LoginSsoError',
}

export type LoginErrorKey = LoginError | null | undefined;
export type LoginErrorValues = $Values<typeof LoginError>;
export type EmailPassword = {
  emailAddress: string;
  password: string;
};
