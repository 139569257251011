import React from 'react';
import { FlatList, FlatListProps, View } from 'react-native';

import { SEPARATOR_SIZE, ListProps, FlatListItemType } from 'src/components/core/List/types';
import { ListRow } from 'src/components/core/List/ListRow';
import { testID } from 'src/common/testID';
import { theme } from 'src/components/core/theme';

const Separator = () => (
  <View
    style={{
      height: SEPARATOR_SIZE,
      width: '100%',
      backgroundColor: theme.color.border,
    }}
  />
);

const CustomFlatList = ({ data, ...rest }: FlatListProps<FlatListItemType<any>>) => (
  <FlatList data={data} {...rest} />
);

export const List = ({
  items,
  testId,
  rowStyle,
  numberOfLines,
  ellipsizeMode,
  onItemSelected,
  listRowComponentProps,
  ...rest
}: ListProps<any>) => {
  return (
    <CustomFlatList
      data={items}
      {...testID(testId)}
      keyboardShouldPersistTaps="handled"
      ListFooterComponent={Separator}
      keyExtractor={(item, index) => {
        if (typeof item !== 'function' && (item.id || item.text || item.translationKey)) {
          return item.id || item.text || item.translationKey || index.toString();
        }

        return index.toString();
      }}
      renderItem={({ item: MenuItem }) => {
        if (typeof MenuItem === 'function') {
          return (
            <MenuItem {...listRowComponentProps}>
              {({ hide, data, handleSelectItem }) => {
                if (hide) {
                  return null;
                }
                return (
                  <ListRow
                    data={data}
                    testId={testId}
                    rowStyle={rowStyle}
                    ellipsizeMode={ellipsizeMode}
                    numberOfLines={numberOfLines}
                    onItemSelected={handleSelectItem}
                  />
                );
              }}
            </MenuItem>
          );
        }

        return (
          <ListRow
            data={MenuItem}
            testId={testId}
            rowStyle={rowStyle}
            ellipsizeMode={ellipsizeMode}
            numberOfLines={numberOfLines}
            onItemSelected={onItemSelected}
          />
        );
      }}
      {...rest}
    />
  );
};
