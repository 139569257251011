import { NavigationState, Route } from '@react-navigation/native';

import { logger } from 'src/taxfix-business-logic/utils/logger';

function getCurrentRoute(navigationState?: NavigationState): Route<any> | undefined {
  if (!navigationState) return undefined;
  const route = navigationState.routes[navigationState.index];

  // dive into nested navigators
  if (route.state) {
    return getCurrentRoute(route.state as any);
  }

  return route;
}

export const useErrorTracking = () => {
  return (currentState?: NavigationState): void => {
    const currentScreen = getCurrentRoute(currentState);
    if (logger.errorTrackingInstance) {
      logger.errorTrackingInstance.updateGlobalTags({
        screen: currentScreen?.name,
      });
    }
  };
};
