import { Dispatch } from 'redux';
import { CountryCodes } from '@taxfix/types';
import { Responses } from '@taxfix/quizmaster/dist/types';

import {
  AnswerWithAnsweredAt,
  createSyncMetaMap,
  fillMissingSyncMeta,
  SyncMetaMap,
} from '../utils/sync-answers';

const UPDATE_SYNCMAP_RESET = 'sync-answers/UPDATE_SYNCMAP_RESET';
const UPDATE_SYNCMAP_SYNCSUCCESS = 'sync-answers/UPDATE_SYNCMAP_SYNCSUCCESS';
const UPDATE_SYNCMAP_SYNCFAIL = 'sync-answers/UPDATE_SYNCMAP_SYNCFAIL';
const DELETE_SYNCMETA_SUCCESS = 'sync-answers/DELETE_SYNCMETA_SUCCESS';
export type State = {
  syncMetaMap: SyncMetaMap;
  syncMetaMapIT: SyncMetaMap;
  syncMetaMapES: SyncMetaMap;
};
type UploadAction = {
  type: typeof UPDATE_SYNCMAP_RESET;
  payload: SyncMetaMap;
  countryCode: CountryCodes;
};
type Action = UploadAction;

const getSyncMetaMapByCountry = (state: any, countryCode: CountryCodes): SyncMetaMap => {
  switch (countryCode) {
    case CountryCodes.IT:
      return state.syncAnswers.syncMetaMapIT;
    case CountryCodes.ES:
      return state.syncAnswers.syncMetaMapES;
    default:
      return state.syncAnswers.syncMetaMap;
  }
};

const resetSyncMap =
  (allAnswers: Responses, countryCode: CountryCodes) =>
  (
    dispatch: Dispatch<any>,
    getState: () => {
      syncAnswers: State;
    },
  ) => {
    const syncMetaMap = getSyncMetaMapByCountry(getState(), countryCode);
    dispatch({
      type: UPDATE_SYNCMAP_RESET,
      payload: fillMissingSyncMeta(syncMetaMap, allAnswers),
      countryCode,
    });
  };

const updateSyncMap = (
  answers: AnswerWithAnsweredAt[],
  isSynced: boolean,
  countryCode: CountryCodes,
) => {
  if (isSynced) {
    return {
      type: UPDATE_SYNCMAP_SYNCSUCCESS,
      payload: createSyncMetaMap(answers, true),
      countryCode,
    };
  }

  return {
    type: UPDATE_SYNCMAP_SYNCFAIL,
    payload: createSyncMetaMap(answers, false),
    countryCode,
  };
};

const deleteSyncMeta =
  (year: number, answerID: string, countryCode: CountryCodes) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => {
      syncAnswers: State;
    },
  ) => {
    const syncMetaMap = getSyncMetaMapByCountry(getState(), countryCode);
    const newSyncMetaMap: any = {};
    Object.keys(syncMetaMap).forEach((key: string) => {
      const deleteKey = `${year}.${answerID}`;

      if (key !== deleteKey) {
        newSyncMetaMap[key] = syncMetaMap[key];
      }
    });
    dispatch({
      type: DELETE_SYNCMETA_SUCCESS,
      payload: newSyncMetaMap,
      countryCode,
    });
  };

const deleteSyncMetaByYear =
  (year: number, countryCode: CountryCodes) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => {
      syncAnswers: State;
    },
  ) => {
    const syncMetaMap = getSyncMetaMapByCountry(getState(), countryCode);
    const newSyncMetaMap: any = {};
    Object.keys(syncMetaMap).forEach((key: string) => {
      const deleteYear = `${year}.`;

      if (!key.startsWith(deleteYear)) {
        newSyncMetaMap[key] = syncMetaMap[key];
      }
    });
    dispatch({
      type: DELETE_SYNCMETA_SUCCESS,
      payload: newSyncMetaMap,
      countryCode,
    });
  };

export const actions = {
  resetSyncMap,
  updateSyncMap,
  deleteSyncMeta,
  deleteSyncMetaByYear,
};
export const selectors = {
  getSyncMetaMapByCountry,
};
export const initial: State = {
  syncMetaMap: {},
  syncMetaMapIT: {},
  syncMetaMapES: {},
};
export const getSyncMetaMapCountryPath = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.IT:
      return 'syncMetaMapIT';
    case CountryCodes.ES:
      return 'syncMetaMapES';
    default:
      return 'syncMetaMap';
  }
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type as any) {
    case UPDATE_SYNCMAP_RESET:
    case UPDATE_SYNCMAP_SYNCSUCCESS:
    case UPDATE_SYNCMAP_SYNCFAIL: {
      const path = getSyncMetaMapCountryPath(action.countryCode);
      return { ...state, [path]: { ...state[path], ...action.payload } };
    }

    case DELETE_SYNCMETA_SUCCESS: {
      const path = getSyncMetaMapCountryPath(action.countryCode);
      return { ...state, [path]: action.payload };
    }

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
