import { FormControl, Input, useTheme } from 'native-base';
import React from 'react';

import { icons } from '../../../Icons';
import { Icon } from '../Icon';

import { Props } from './types';

/**
 * The InputField component allows a user to provide input in a text field.
 */
export const InputField: React.FC<Props> = React.forwardRef(
  (
    { label, isInvalid = false, errorMessage, isDropdown = false, iconOpen = false, ...props },
    ref,
  ): JSX.Element => {
    const { colors } = useTheme();

    const InputRightElement = (
      <Icon
        path={iconOpen ? icons.chevronDown : icons.chevronUp}
        color={colors.brand.brandGreen}
        size={3}
        mr={10}
      />
    );

    const input = {
      ...props,
      ...(isDropdown && { InputRightElement }),
    };

    return (
      <FormControl isInvalid={isInvalid}>
        {label && <FormControl.Label isInvalid={isInvalid}>{label}</FormControl.Label>}
        <Input ref={ref} {...input} />
        {errorMessage && <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>}
      </FormControl>
    );
  },
);
