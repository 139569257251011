import React, { useEffect } from 'react';
import { HeaderBackButton } from '@react-navigation/elements';
import { useBreakpointValue } from 'native-base';

import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { useToastBannerToggler } from 'src/components/toast-banner';
import { Banner } from 'src/components/toast';
import { theme } from 'src/components/core';
import { Loading } from 'src/components/loading';

import { ModelloRedditiSignComponent } from './modello-redditi-sign-component';
import { useFetch } from './useFetchDocument';

const ERROR_DURATION = 4000;

type ActionParams = {
  submissionId: string;
};

type Props = Navigation<
  ScreenName.ModelloRedditiSign,
  {
    ModelloRedditiSign: NavigationParams<ActionParams>;
  }
>;

const toModelloRedditiSign: Action = navigate(ScreenName.ModelloRedditiSign, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ModelloRedditiSign,
  },
});

const ModelloRedditiSignScreen: React.FC<Props> = () => {
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { showBanner } = useToastBannerToggler();
  const { getTranslationText } = useItalyIntl();
  const { isError, setIsError, isLoading, document, onSign } = useFetch();

  const navigationBack = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={navigationBack} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.modello-redditi.header.title' },
    {
      headerLeft: headerLeftComponent,
    },
  );

  const translationKeys = {
    backNavigationText: getTranslationText('it.modello-redditi.header.back'),
    title: getTranslationText('it.modello-redditi.title'),
    subtitle: getTranslationText('it.modello-redditi.subtitle'),
    footerCheckboxKey: getTranslationText('it.modello-redditi.footer.checkbox'),
    footerButtonDisabledKey: getTranslationText('it.modello-redditi.footer.button.disabled'),
    footerButtonActiveKey: getTranslationText('it.modello-redditi.footer.button.active'),
  };

  useEffect(() => {
    if (isError) {
      showBanner({
        contentView: <Banner titleId="it.self-sign-document.error" />,
        backgroundColor: theme.color.errorBackground,
        duration: ERROR_DURATION,
      });
      setIsError(false);
    }
  }, [isError, setIsError, showBanner]);

  if (isLoading) return <Loading />;

  return (
    <ModelloRedditiSignComponent
      isLargeScreen={isLargeScreen}
      navigationBack={navigationBack}
      translationKeys={translationKeys}
      pdfUri={document}
      onSign={onSign}
    />
  );
};

export { ModelloRedditiSignScreen, toModelloRedditiSign };
