import React, { ReactNode, useMemo } from 'react';
import { ScrollView, Stack, useBreakpointValue } from 'native-base';
import { useSelector } from 'react-redux';

import { Container, NavButton, Text } from 'src/taxfix-components/src';
import LinkableText from 'src/components/auth/LinkableText';
import {
  PremiumProductBundle,
  ProductBundleValues,
  SelectedProductBundleValues,
} from 'src/common/constants-it';
import { useLargeScreenQuery } from 'src/components/core';
import { useNavigation } from 'src/hooks/navigation-hook';
import { isMobileScreen, isWeb } from 'src/utils/platform';

import { useItalyIntl } from '../_hooks';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { ScreenName } from '../../types/screen-name';
import { useNumberFormatter } from '../../utils/useNumberFormatter';
import { selectors as remoteConfigSelectors } from '../../stores/modules/remote-config-firebase';

import {
  ProductBundleCard,
  ProductBundleCardProps,
} from './product-bundle-card/product-bundle-card';
import { ProductBundlesPrices } from './use-product-prices';

type ProductBundleComponentProps = {
  onProductSelect: (selection: SelectedProductBundleValues) => void;
  onBackButtonPress?(): void;
  productBundlesPrices: ProductBundlesPrices;
  recommendedProductBundle: ProductBundleValues;
  showPremiumProduct: boolean;
  description?: ReactNode;
};

type CreateBundleCard = ProductBundleCardProps & { showFirstInOrder: boolean };

export const ProductBundleComponent: React.FC<ProductBundleComponentProps> = ({
  onProductSelect,
  onBackButtonPress,
  recommendedProductBundle,
  productBundlesPrices,
  showPremiumProduct = false,
  description,
}) => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  const pageTitleMarginTop = useBreakpointValue({ base: isWeb ? '24px' : 0, md: '76px' });
  const cardsContainerDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const cardsContainerSpacing = useBreakpointValue({ base: '16px', md: '20px' });
  const cardsContainerWidth = useBreakpointValue({
    base: 'full',
    md: '740px',
    lg: showPremiumProduct ? 'container' : '780px',
  });
  const linkButtonMarginTop = useBreakpointValue({ base: '0px', lg: '60px' });
  const linkButtonVerticalPadding = useBreakpointValue({ base: '24px', lg: '0px' });
  const paymentConfig = useSelector(remoteConfigSelectors.getItalyProductsPaymentConfig).default;
  const androidShadowOverflow = '24px';

  const handlePressBundlesDetails = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      buttonName: 'productBundleDetails',
      screenName: ScreenName.ProductBundle,
      bundleRecommendation: recommendedProductBundle,
    });
    navigationActions.toProductBundleDetails('screen', {
      showPremiumProduct,
    });
  };

  const bundleCards = useMemo(() => {
    const createBundleCard = (
      type: SelectedProductBundleValues,
      isRecommended: boolean,
      price: number,
      isFree?: boolean,
    ): CreateBundleCard => {
      const getFormattedNumber = useNumberFormatter();
      const priceFormated = getFormattedNumber(price);

      return {
        title: `it.product-bundle-screen.cards.${
          isRecommended ? `${type}.recommended` : type
        }-declaration.title`,
        subtitle: `it.product-bundle-screen.cards.${type}-declaration.subtitle`,
        descriptionItems: isRecommended
          ? [
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-1`,
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-2`,
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-3`,
            ]
          : [
              `it.product-bundle-screen.cards.${type}-declaration.description-item-1`,
              `it.product-bundle-screen.cards.${type}-declaration.description-item-2`,
              `it.product-bundle-screen.cards.${type}-declaration.description-item-3`,
            ],
        imageComponentKey: `${type}Declaration${
          showPremiumProduct && type === ProductBundleValues.guided ? 'Experiment' : ''
        }`,
        buttonLabel: getTranslationText(
          isFree
            ? 'it.product-bundle-screen.cards.declaration.free'
            : `it.product-bundle-screen.cards.${type}-declaration.price`,
          {
            priceFormated,
          },
        ),
        onPressCard: () => onProductSelect(type),
        recommended: isRecommended,
        showFirstInOrder: !showPremiumProduct && isRecommended,
      };
    };

    const isRecommendedInstant = recommendedProductBundle === ProductBundleValues.instant;
    const isRecommendedGuided = recommendedProductBundle === ProductBundleValues.guided;

    const bundles = [
      createBundleCard(
        ProductBundleValues.instant,
        isRecommendedInstant,
        productBundlesPrices.instant,
        paymentConfig.instant.isFree,
      ),
      createBundleCard(
        ProductBundleValues.guided,
        isRecommendedGuided,
        productBundlesPrices.guided,
        paymentConfig.guided.isFree,
      ),
    ];

    if (showPremiumProduct) {
      bundles.push(createBundleCard(PremiumProductBundle.premium, false, 129.99, false));
    }
    // sort the product bundle cards based on the recommended property,
    // showing that one always first
    return bundles.sort((a, b) => Number(b.showFirstInOrder) - Number(a.showFirstInOrder));
  }, [
    recommendedProductBundle,
    productBundlesPrices.instant,
    productBundlesPrices.guided,
    paymentConfig.instant.isFree,
    paymentConfig.guided.isFree,
    showPremiumProduct,
    getTranslationText,
    onProductSelect,
  ]);

  const LinkButton = () => (
    <Container
      justifyContent="center"
      alignItems="center"
      mt={linkButtonMarginTop}
      py={linkButtonVerticalPadding}
    >
      <LinkableText
        translationKey="it.product-bundle-screen.bundles-details.link-text"
        onPress={handlePressBundlesDetails}
      />
    </Container>
  );

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <Container position="absolute" top={0} zIndex={1}>
          <NavButton
            onPress={onBackButtonPress}
            text={getTranslationText('it.product-bundle-screen.back-button.text')}
          />
        </Container>
      )}

      <Text
        mt={pageTitleMarginTop}
        textAlign="center"
        variant={titleVariant}
        value={getTranslationText('it.product-bundle-screen.title.select-plan')}
      />
      {description && (
        <Text
          mt="10px"
          textAlign="center"
          variant={isLargeScreen ? 'bodyMBook' : 'bodySBook'}
          color="greytones.textLight"
          value={description}
        />
      )}
      <ScrollView mt={{ base: '24px', md: '40px' }}>
        <Stack
          direction={cardsContainerDirection}
          alignSelf="center"
          space={cardsContainerSpacing}
          width={cardsContainerWidth}
          pb={androidShadowOverflow}
          margin={isLargeScreen && showPremiumProduct ? '10px' : 'auto'}
        >
          {bundleCards.map((bundle) => (
            <ProductBundleCard key={`productBundleCard_${bundle?.title}`} {...bundle} />
          ))}
        </Stack>

        {!isMobileScreen && <LinkButton />}
      </ScrollView>

      {isMobileScreen && <LinkButton />}
    </>
  );
};
