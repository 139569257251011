import * as React from 'react';
import { Platform, KeyboardAvoidingView as RNKeyboardAvoidingView } from 'react-native';

type Props = {
  children: React.ReactNode;
};

function KeyboardAvoidingView(props: Props) {
  return (
    <RNKeyboardAvoidingView
      style={{
        flex: 1,
      }}
      behavior="padding"
      {...props}
    />
  );
}

function DummyKeyboardAvoidingView(props: Props) {
  return props.children;
}

export default Platform.OS === 'ios'
  ? (KeyboardAvoidingView as any)
  : (DummyKeyboardAvoidingView as any);
