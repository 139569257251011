import * as React from 'react';

import { CameraImageResult } from '../android-camera-upload/camera';
import { AnalyticsEvent } from '../../biz-logic/analytics';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { ImagePreviewContainer } from './image-preview-container';

export type ActionParams = {
  image: CameraImageResult;
  onTryAgain: () => void;
  onProceed: () => void;
};

type Props = Navigation<
  Routes.ImagePreview,
  {
    [Routes.ImagePreview]: NavigationParams<ActionParams>;
  }
>;

export const ImagePreviewScreen: React.FC<Props> = ({ route }) => {
  const { onProceed, onTryAgain, image } = route.params;

  useNavigationOptions({
    header: () => null,
  });

  return <ImagePreviewContainer onProceed={onProceed} onTryAgain={onTryAgain} image={image} />;
};

export const { action: toImagePreview, screen: imagePreviewScreen } = getRoute<ActionParams>({
  routeName: Routes.ImagePreview,
  tracking: AnalyticsEvent.sectionImagePreview,
  screenComponent: ImagePreviewScreen,
});
