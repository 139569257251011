import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { omit, get } from 'lodash';
import names from 'i18n-iso-countries';
import { CountryCodes } from '@taxfix/types';

import {
  AddressDetailsFieldWithValue,
  AddressDetailsKeys,
  AddressDetailsField,
} from 'src/types/address';
import { ItalyDetailAddressView } from 'src/_italy/quizmaster/address-question/detail-address';

import DetailAddressView from '../components/detail-address-view';
import AnswerValidator from '../../components/answer-validator';
import { Address, Place } from '../../types/geo';
import { injectLocaleInfo, LocaleInfo } from '../../i18n/locale-info';
import {
  ValidationResult,
  ValidationErrors,
} from '../../components/answers/shared/validators/types';
import {
  mapDefaultValidationErrors,
  mapDefaultValidationErrorsIT,
} from '../../components/answers/shared/validators/utils';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { State as RootState } from '../../stores/store/initial';

type SplitOutput = {
  number: string | null | undefined;
  numberExtension: string | null | undefined;
};

export const splitHouseNumber = (
  combined?: string | null | undefined,
  savedExtension?: string | null | undefined,
): SplitOutput => {
  if (!combined) {
    return {
      number: null,
      numberExtension: null,
    };
  }

  const str = combined.trim();
  const index = str.search(/\D/); // index of first non-digit (-1 if none)

  const number = index > -1 ? str.slice(0, index) : str;
  const numberExtension = index > -1 ? str.slice(index).trim() : '';
  return {
    number,
    numberExtension: savedExtension || numberExtension,
  };
};

// Note: we do this to avoid dynamic translations keys (for easily detecting unused translation keys).
const translationKeys: Record<string, string> = Object.freeze({
  addition: 'answers.address.detail.addition',
  street: 'answers.address.detail.street',
  streetType: 'answers.address.detail.streetType',
  number: 'answers.address.detail.number',
  postalCode: 'answers.address.detail.postalCode',
  city: 'answers.address.detail.city',
  country: 'answers.address.detail.countryCode',
  state: 'answers.address.detail.state',
});

type Props = {
  localeInfo: LocaleInfo;
  place: Place;
  fields: AddressDetailsField[];
  addressValidator: (arg0: Place) => ValidationResult;
  onChange: (isValid: boolean, place: Place) => void;
  selectedCountry: CountryCodes;
  isValidAddress?: boolean;
  handleOnContinePress?: () => void;
};

type State = {
  place: Place;
  fields: AddressDetailsFieldWithValue[];
  errors?: ValidationErrors;
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedCountry: settingsSelectors.selectedCountry(state),
  };
};

export class DetailAddressViewContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { number, numberExtension } = props.place.address as Address;
    this.state = {
      place: {
        ...props.place,
        address: { ...props.place.address, ...splitHouseNumber(number, numberExtension) },
      },
      fields: props.fields.map((field) => ({
        ...field,
        translationKey: translationKeys[field.id],
        value:
          field.id === AddressDetailsKeys.Country
            ? this.props.place.countryCode
            : (props.place.address as Address)[field.id] || props.place[field.id] || null,
      })),
      errors: {},
    };
  }

  getCountryName = (countryCode: string, language: string): string =>
    names.getName(countryCode, language);

  extractDisplayNameFromPlace = (place: Place): string => {
    if (!place.address) {
      return '';
    }

    const { address } = place;
    const street = address.street || '';
    const number = address.number || '';
    const numberExtension = address.numberExtension || '';
    const postalCode = address.postalCode || '';
    const city = address.city || '';
    const countryName = this.getCountryName(place.countryCode, this.props.localeInfo.language);
    return `${street} ${number}${numberExtension}, ${postalCode} ${city}, ${countryName}`;
  };

  handleChange =
    (validate: (input: Place) => ValidationResult) =>
    (details: Address): void => {
      const address = omit(details, [
        AddressDetailsKeys.Country,
        AddressDetailsKeys.State,
      ]) as Address;
      const countryCode = get(details, AddressDetailsKeys.Country, this.props.place.countryCode);
      const state = get(details, AddressDetailsKeys.State, this.props.place.state);
      const newPlace = {
        ...this.state.place,
        countryCode,
        postalCode: address.postalCode,
        address: { ...address, ...splitHouseNumber(address.number) },
        state,
      };
      const displayName = this.extractDisplayNameFromPlace(newPlace);
      validate({ ...newPlace, displayName });
    };

  handleSuccess = (place: Place): void => {
    this.setState(
      {
        place,
        errors: {},
      },
      () => {
        this.props.onChange(true, place);
      },
    );
  };

  handleFailure = (errors: ValidationResult['errors']): void => {
    const appErrors = Array.isArray(errors) ? mapDefaultValidationErrors(errors) : errors;
    this.setState(
      {
        errors: appErrors,
      },
      () => {
        this.props.onChange(false, this.state.place);
      },
    );
  };

  handleFailureIT = (errors: ValidationResult['errors']): void => {
    const appErrors = Array.isArray(errors) ? mapDefaultValidationErrorsIT(errors) : errors;
    this.setState(
      {
        errors: appErrors,
      },
      () => {
        this.props.onChange(false, this.state.place);
      },
    );
  };

  render(): JSX.Element {
    const {
      addressValidator,
      selectedCountry,
      isValidAddress,
      handleOnContinePress,
      localeInfo: { language },
    } = this.props;

    const isItalian = selectedCountry === CountryCodes.IT;

    return (
      <AnswerValidator
        value={this.state.place}
        validate={addressValidator}
        onFailure={isItalian ? this.handleFailureIT : this.handleFailure}
        onSuccess={this.handleSuccess}
      >
        {({ validate }) =>
          isItalian ? (
            <ItalyDetailAddressView
              fields={this.state.fields}
              onChange={this.handleChange(validate)}
              errors={this.state.errors}
              isValidAddress={isValidAddress}
              handleOnContinePress={handleOnContinePress}
              language={language}
            />
          ) : (
            <DetailAddressView
              language={language}
              fields={this.state.fields}
              onChange={this.handleChange(validate)}
              errors={this.state.errors}
              countryCode={selectedCountry}
            />
          )
        }
      </AnswerValidator>
    );
  }
}
export default compose(connect(mapStateToProps))(injectLocaleInfo(DetailAddressViewContainer));
