import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const ExpenseIconGrey: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#F6F6F6" />
    <Path d="M18.6533 23.5078H22.492L21.8868 24.8652H19.2801L18.6533 23.5078Z" fill="#707070" />
    <Path d="M20.7508 22.8477H20.2363V23.9327H20.7508V22.8477Z" fill="#707070" />
    <Path d="M25.2941 16.9453H15.7275V22.6429H25.2941V16.9453Z" fill="#EFDDDC" />
    <Path d="M25.3323 17.0586H15.7441V22.5357H25.3323V17.0586Z" fill="#E1E1E1" />
    <Path
      d="M20.5212 23.1633C23.1832 23.1633 25.3412 22.902 25.3412 22.5797C25.3412 22.2574 23.1832 21.9961 20.5212 21.9961C17.8592 21.9961 15.7012 22.2574 15.7012 22.5797C15.7012 22.902 17.8592 23.1633 20.5212 23.1633Z"
      fill="#E1E1E1"
    />
    <Path
      d="M20.5212 17.5988C23.1832 17.5988 25.3412 17.3375 25.3412 17.0152C25.3412 16.6929 23.1832 16.4316 20.5212 16.4316C17.8592 16.4316 15.7012 16.6929 15.7012 17.0152C15.7012 17.3375 17.8592 17.5988 20.5212 17.5988Z"
      fill="#E1E1E1"
    />
    <Path d="M17.668 45.1044H17.0801L19.2113 24.5059H20.8021L17.668 45.1044Z" fill="#707070" />
    <Path d="M23.4866 45.0321H24.0745L21.9434 24.4336H20.3525L23.4866 45.0321Z" fill="#707070" />
    <Path
      d="M21.7966 45.019H21.0617L19.9248 26.3571C19.9248 26.3571 20.2188 25.3672 20.3268 25.3672C20.4349 25.3672 21.3557 25.7346 21.3557 25.7346L21.7966 45.019Z"
      fill="#707070"
    />
    <Path
      d="M25.6132 47.5253L25.7516 47.5685C25.4706 47.482 25.3841 47.2054 25.4706 46.9244L26.4 43.4229C26.4864 43.1419 26.7847 42.9862 27.0657 43.0727C27.3467 43.1592 27.5023 43.4574 27.4159 43.7384L26.2098 47.1535C26.119 47.4302 25.8899 47.6117 25.6132 47.5253Z"
      fill="#707070"
    />
    <Path
      d="M45.6498 47.5253L45.5114 47.5685C45.7924 47.482 45.8789 47.2054 45.7924 46.9244L44.863 43.4229C44.7765 43.1419 44.4783 42.9862 44.1973 43.0727C43.9163 43.1592 43.7607 43.4574 43.8471 43.7384L45.0532 47.1535C45.144 47.4302 45.3731 47.6117 45.6498 47.5253Z"
      fill="#707070"
    />
    <Path
      d="M46.7135 40.2679L45.7668 43.013L45.2438 44.526H25.285L24.8355 43.013L23.4219 38.2275L44.2841 37.2852L46.7135 40.2679Z"
      fill="#E1E1E1"
    />
    <Path
      d="M46.7135 40.2679L45.7668 43.013H24.8355L23.4219 38.2275L44.2841 37.2852L46.7135 40.2679Z"
      fill="#707070"
    />
    <Path
      d="M35.2667 38.5955V31.4195C35.2667 30.5939 34.5966 29.9238 33.771 29.9238H26.7809C25.9509 29.9238 25.2852 30.5939 25.2852 31.4195V38.5955V39.486H35.2624V38.5955H35.2667Z"
      fill="#E1E1E1"
    />
    <Path
      d="M26.1713 31.4156C26.1713 30.59 26.8371 29.9199 27.6671 29.9199H26.7809C25.9509 29.9199 25.2852 30.59 25.2852 31.4156V38.5916V39.4821H26.1713V38.5916V31.4156V31.4156Z"
      fill="#707070"
    />
    <Path
      d="M45.2438 38.5955V31.4195C45.2438 30.5939 44.5738 29.9238 43.7481 29.9238H36.7623C35.9323 29.9238 35.2666 30.5939 35.2666 31.4195V38.5955V39.486H45.2438V38.5955Z"
      fill="#E1E1E1"
    />
    <Path
      d="M36.1528 31.4156C36.1528 30.59 36.8185 29.9199 37.6485 29.9199H36.7623C35.9323 29.9199 35.2666 30.59 35.2666 31.4156V38.5916V39.4821H36.1528V38.5916V31.4156Z"
      fill="#707070"
    />
    <Path
      d="M47.0026 44.5267H45.2432V35.1115C45.2432 34.7095 45.6582 34.4415 46.0256 34.6057L47.9709 35.479C48.187 35.5741 48.3167 35.7989 48.2951 36.0366L47.5689 44.0123C47.5386 44.3063 47.2965 44.5267 47.0026 44.5267Z"
      fill="#E1E1E1"
    />
    <Path
      d="M23.5253 44.5267H25.2848V35.1115C25.2848 34.7095 24.8698 34.4415 24.5023 34.6057L22.557 35.479C22.3409 35.5741 22.2112 35.7989 22.2328 36.0366L22.9634 44.0123C22.9893 44.3063 23.2314 44.5267 23.5253 44.5267Z"
      fill="#E1E1E1"
    />
    <Path
      d="M45.1482 42.7788H25.3797C24.9993 42.7788 24.6924 42.4719 24.6924 42.0915V40.1678C24.6924 39.7874 24.9993 39.4805 25.3797 39.4805H45.1482C45.5287 39.4805 45.8356 39.7874 45.8356 40.1678V42.0915C45.8356 42.4719 45.5287 42.7788 45.1482 42.7788Z"
      fill="#E1E1E1"
    />
    <Path
      d="M26.1838 42.3005V40.2255C26.1838 39.8149 26.4907 39.4863 26.8711 39.4863H25.3797C24.9993 39.4863 24.6924 39.8192 24.6924 40.2255V42.3005C24.6924 42.7112 24.9993 43.0397 25.3797 43.0397H26.8711C26.4907 43.0441 26.1838 42.7112 26.1838 42.3005Z"
      fill="#707070"
    />
    <Path
      d="M38.2146 33.1967C38.0374 33.1967 37.8774 33.0757 37.8299 32.9028C37.7866 32.7515 37.8428 32.5786 37.9682 32.4835C38.2405 32.2716 38.6469 32.4921 38.6123 32.8379C38.595 33.0411 38.4178 33.1967 38.2146 33.1967Z"
      fill="white"
    />
    <Path
      d="M42.2908 33.1967C42.1135 33.1967 41.9536 33.0757 41.906 32.9028C41.8628 32.7515 41.919 32.5786 42.0444 32.4835C42.3167 32.2716 42.723 32.4921 42.6885 32.8379C42.6712 33.0411 42.4939 33.1967 42.2908 33.1967Z"
      fill="white"
    />
    <Path
      d="M38.2145 37.0043C37.8557 37.0043 37.6784 36.5418 37.9508 36.3084C38.2188 36.0706 38.6597 36.2997 38.6122 36.6542C38.5862 36.8487 38.4176 37.0043 38.2145 37.0043Z"
      fill="white"
    />
    <Path
      d="M42.2914 37.004C41.9283 37.004 41.7597 36.5371 42.032 36.3037C42.3044 36.0702 42.7323 36.2994 42.6891 36.6538C42.6632 36.8484 42.4946 37.004 42.2914 37.004Z"
      fill="white"
    />
    <Path
      d="M28.2374 33.1965C27.87 33.1965 27.7014 32.721 27.9824 32.4876C28.2461 32.2714 28.6524 32.4746 28.6351 32.8161C28.6265 33.0279 28.4449 33.1965 28.2374 33.1965Z"
      fill="white"
    />
    <Path
      d="M32.3142 33.1967C32.137 33.1967 31.977 33.0757 31.9295 32.9028C31.8862 32.7515 31.9424 32.5786 32.0678 32.4835C32.3401 32.2716 32.7465 32.4921 32.7119 32.8379C32.6903 33.0411 32.5174 33.1967 32.3142 33.1967Z"
      fill="white"
    />
    <Path
      d="M28.2373 37.0058C27.8915 37.0058 27.7143 36.5778 27.9477 36.3314C28.0644 36.2104 28.2546 36.1758 28.4059 36.2493C28.5572 36.3228 28.648 36.4827 28.6307 36.647C28.6134 36.8459 28.4405 37.0058 28.2373 37.0058Z"
      fill="white"
    />
    <Path
      d="M32.3138 37.0043C31.9507 37.0043 31.7778 36.5375 32.0544 36.304C32.1885 36.1873 32.3873 36.1743 32.5386 36.2738C32.6769 36.3645 32.7461 36.5418 32.7029 36.7017C32.6553 36.8746 32.4954 37.0043 32.3138 37.0043Z"
      fill="white"
    />
  </Icon>
);
