import React, { useState, useEffect } from 'react';
import { Button, Text, TextInput, View, StyleSheet } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { BodyPrimaryText, theme } from '../components/core';
import {
  selectors as devToolsSelectors,
  actions as devToolsActionCreators,
  ConfigKeys,
} from '../stores/modules/dev-tools';
import { getUniqueDeviceID, getCustomUUID } from '../services/device';

type Props = {
  devToolsActions: typeof devToolsActionCreators;
  devToolsDeviceId: string | null | undefined;
};

const mapDispatchToProps = (dispatch: any) => ({
  devToolsActions: bindActionCreators(devToolsActionCreators, dispatch),
});

const mapStateToProps = (state: any) => ({
  devToolsDeviceId: devToolsSelectors.getConfig(state, ConfigKeys.DeviceId),
});

const styles = StyleSheet.create({
  container: {
    borderColor: theme.color.border,
    borderRadius: 4,
    borderWidth: 1,
  },
  contentContainer: {
    padding: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: theme.color.border,
  },
  separator: {
    marginHorizontal: 8,
    borderColor: theme.color.border,
    borderWidth: StyleSheet.hairlineWidth,
  },
  textInput: {
    minWidth: 200,
    borderColor: theme.color.primary,
    borderBottomWidth: 1,
    textAlign: 'center',
  },
  actionButton: {
    flex: 1,
    padding: 5,
    width: '100%',
  },
});

const ActionButton = ({ title, onPress, testID }: any) => {
  return (
    <View style={styles.actionButton}>
      <Button onPress={onPress} title={title} testID={testID} />
    </View>
  );
};

const DeviceIdUndecorated = (props: Props) => {
  const [deviceId, setDeviceId] = useState('');
  const [isEditing, setEditing] = useState(false);
  useEffect(() => {
    (async () => {
      setDeviceId(props.devToolsDeviceId || (await getUniqueDeviceID()));
    })();
  }, [props.devToolsDeviceId]);

  const handleEdit = () => setEditing(true);

  const handleSave = () => {
    props.devToolsActions.setConfig(ConfigKeys.DeviceId, deviceId);
    setEditing(false);
  };

  const handleCancel = () => setEditing(false);

  const handleReset = () => props.devToolsActions.setConfig(ConfigKeys.DeviceId, getCustomUUID());

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <BodyPrimaryText>DeviceId:</BodyPrimaryText>
        <View style={{ marginTop: 10 }}>
          {isEditing ? (
            <TextInput
              style={styles.textInput}
              defaultValue={deviceId}
              onChangeText={setDeviceId}
              onSubmitEditing={handleSave}
              autoCapitalize="none"
              selectTextOnFocus
              autoFocus
            />
          ) : (
            <Text selectable>{deviceId}</Text>
          )}
        </View>
      </View>
      {isEditing ? (
        <View style={styles.buttonContainer}>
          <ActionButton onPress={handleCancel} title="Cancel" />
          <View style={styles.separator} />
          <ActionButton onPress={handleSave} title="Save" />
        </View>
      ) : (
        <View style={styles.buttonContainer}>
          <ActionButton onPress={handleEdit} title="Edit" />
          <View style={styles.separator} />
          <ActionButton onPress={handleReset} title="Reset" testID="resetDeviceId" />
        </View>
      )}
    </View>
  );
};

export const DeviceId = connect(mapStateToProps, mapDispatchToProps)(DeviceIdUndecorated);
