import * as React from 'react';
import { Platform } from 'react-native';
import Config from 'react-native-config';
import { template } from 'lodash';
import { WebView, WebViewMessageEvent } from 'react-native-webview';
import { useSelector } from 'react-redux';
import { PaymentType } from '@taxfix/payment-sdk/dist/payment';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { ScreenName } from 'src/types/screen-name';
import { selectors as remoteConfigSelectors } from 'src/stores/modules/remote-config-firebase';
import { useQuizmasterLight } from 'src/_italy/_hooks';
import { ProductBundleValues, flagsQuestionIds } from 'src/common/constants-it';

import { Box, ChatBubble, Section, Text } from '../../components/core';
import { FullscreenLoader } from '../../components/loading';
import NotificationBanner from '../../components/notification-banner';
import { isWeb } from '../../utils/platform';
import { Footer } from '../../components/footer';
import { useLargeScreenQuery } from '../../components/core/MediaQuery';
import { theme } from '../../components/core/theme';

import { FormInputContent, PaymentFormProps } from './payment.types';
import { getCreditCardTemplate } from './stripe/stripe-html-template';

const { iframeResize } = (isWeb && require('iframe-resizer')) || {};

type Translations = {
  headline: string;
  button: string;
  intro: string;
  disclaimer: string;
  cardNumber: FormInputContent;
  cardExpiry: FormInputContent;
  cardCvc: FormInputContent;
  summary: {
    title: string;
    amount: string;
    originalAmount: string;
    defaultDiscount10?: string;
  };
  coupon: {
    add: string;
    remove: string;
    applied: string;
  };
  nextSteps?: {
    one: string;
    two: string;
    three: string;
  };
  priceOfferBadge: string;
  methodOptionsTitle: string;
  cardOptionTitle: string;
};

export const PaymentCreditCardWebview = (props: PaymentFormProps): JSX.Element => {
  const {
    title,
    subtitle,
    locale,
    stripePublishableKey,
    errorMessage,
    onSubmit,
    onFinished,
    onFailed,
    onInitializationError,
    showError,
    onShowDetails,
    hasCoupon,
    onEditCoupon,
    showChatBubble,
    showTaxYearEndBanner,
    accessToken,
    customerFields,
    formattedPrice: amount,
    priceInCents,
    intl,
    children,
    formattedOriginalPrice,
  } = props;
  const isLargeScreen = useLargeScreenQuery();
  const isPaypalEnabled = useSelector(remoteConfigSelectors.isPaypalEnabled).enabled;
  const quizmaster = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
  const isInstantBundleProduct =
    quizmaster[flagsQuestionIds.productBundleSelection].answer === ProductBundleValues.instant;

  const formattedCustomerFields = (customerFields || []).map((field) => ({
    ...field,
    label: intl.formatMessage({
      id: field.label,
    }),
    placeholder: intl.formatMessage({
      id: field.placeholder,
    }),
  }));

  const build = template(
    getCreditCardTemplate({ isPaypalEnabled, price: priceInCents })(formattedCustomerFields),
    {},
  );

  const contentTranslations = React.useMemo(() => {
    const translations: Translations = {
      headline: intl.formatMessage(
        {
          id: isInstantBundleProduct ? 'it.payment.instant.title' : 'it.payment.guided.title',
        },
        {
          productPrice: amount,
        },
      ),
      button: intl.formatMessage(
        {
          id: 'payment.credit-card.button',
        },
        {
          amount,
        },
      ),
      intro: intl.formatMessage(
        {
          id: subtitle,
        },
        {
          productPrice: amount,
        },
      ),
      disclaimer: intl.formatMessage({
        id: 'payment.disclaimer.creditCard',
      }),
      cardNumber: {
        label: intl.formatMessage({
          id: 'payment.credit-card.field.cardNumber',
        }),
        placeholder: intl.formatMessage({
          id: 'payment.credit-card.field.cardNumber.placeholder',
        }),
      },
      cardExpiry: {
        label: intl.formatMessage({
          id: 'payment.credit-card.field.expiration',
        }),
        placeholder: intl.formatMessage({
          id: 'payment.credit-card.field.expiration.placeholder',
        }),
      },
      cardCvc: {
        label: intl.formatMessage({
          id: 'payment.credit-card.field.code',
        }),
        placeholder: intl.formatMessage({
          id: 'payment.credit-card.field.code.placeholder',
        }),
      },
      summary: {
        title: intl.formatMessage({
          id: 'payment.summary.subtitle',
        }),
        amount: amount || '',
        originalAmount: formattedOriginalPrice,
        defaultDiscount10: intl.formatMessage({
          id: 'payment.summary.default-discount',
        }),
      },
      coupon: {
        add: intl.formatMessage({
          id: 'payment.credit-card.coupon.add',
        }),
        remove: intl.formatMessage({
          id: 'payment.credit-card.coupon.remove',
        }),
        applied: intl.formatMessage({
          id: 'payment.credit-card.coupon.applied',
        }),
      },
      priceOfferBadge: intl.formatMessage({
        id: 'it.payment.price-offer-badge',
      }),
      methodOptionsTitle: intl.formatMessage({
        id: 'it.payment.method-options-title',
      }),
      cardOptionTitle: intl.formatMessage({
        id: 'it.payment.card-option-title',
      }),
      nextSteps: {
        one: intl.formatMessage({
          id: isInstantBundleProduct
            ? 'it.payment.next-step.instant.one'
            : 'it.payment.next-step.guided.one',
        }),
        two: intl.formatMessage({
          id: isInstantBundleProduct
            ? 'it.payment.next-step.instant.two'
            : 'it.payment.next-step.guided.two',
        }),
        three: intl.formatMessage({
          id: isInstantBundleProduct
            ? 'it.payment.next-step.instant.three'
            : 'it.payment.next-step.guided.three',
        }),
      },
    };

    return translations;
  }, [amount, subtitle, intl, formattedOriginalPrice, isInstantBundleProduct]);

  const buildParams = {
    locale,
    stripePublishableKey,
    ...contentTranslations,
  };
  const html = build(buildParams);
  const [loading, setLoading] = React.useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(true);
  const webviewRef = React.useRef<any>(null);
  const refTimeout: any = React.useRef(null);

  React.useEffect(() => {
    refTimeout.current = setTimeout(() => {
      onInitializationError();
    }, 5000);
    return () => {
      clearTimeout(refTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    if (!loading && webviewRef.current) {
      // it seems that injecting complex JSON may cause issue/
      // we prefer transmit simple strings
      webviewRef.current.injectJavaScript(
        `document.dispatchEvent(new CustomEvent('updateCoupon',{detail:{hasCoupon:${hasCoupon.toString()},intro:"${
          contentTranslations.intro
        }",amount:"${contentTranslations.summary.amount}", priceInCents:${priceInCents}}}));true;`,
      );

      if (hasCoupon) {
        webviewRef.current.injectJavaScript(
          `document.querySelector(".default-discount").classList.add("default-discount-invisible")`,
        );
      }

      if (!hasCoupon) {
        webviewRef.current.injectJavaScript(
          `document.querySelector(".default-discount").classList.remove("default-discount-invisible")`,
        );
      }

      // Tax banner injection
      if (showTaxYearEndBanner) {
        const colorPrimary = theme.color.primary;
        // language=JavaScript
        webviewRef.current.injectJavaScript(`
          (function() {
            const warningHtml = \`<div style="display: flex; align-items: center;margin-bottom: 24px" class="tax-year-end-banner">
              <div style="flex: 0 0 auto;font-size: 24px;">
                ⚠️
              </div>
              <div style="flex: 1 1 auto;margin-left: 16px;color: ${colorPrimary};font-size: 13px;font-weight: bold">
                ${intl.formatMessage({
                  id: 'payment.tax-year-end-banner.message',
                })}
              </div>
            </div>\`
            const warningDiv = document.createElement('div');
            warningDiv.innerHTML = warningHtml
            const intro = document.querySelector('#intro');
            const existingTaxYearEndBanner = document.querySelector(".tax-year-end-banner")
            if (existingTaxYearEndBanner) {
              existingTaxYearEndBanner.remove();
            }
            if (intro) {
              intro.insertAdjacentElement('afterend', warningDiv);
            }
          }())
        `);
      }

      clearTimeout(refTimeout.current);
    }
  }, [hasCoupon, loading, contentTranslations, showTaxYearEndBanner, intl, priceInCents]);

  const confirmCardPayment = async (clientSecret: string) => {
    if (webviewRef.current)
      webviewRef.current.injectJavaScript(
        `document.dispatchEvent(new CustomEvent('confirmCardPayment',{detail:{clientSecret:'${clientSecret}'}}));true;`,
      );
  };

  const confirmPayPalPayment = async (clientSecret: string) => {
    if (webviewRef.current) {
      const isLocalhost = window.location.hostname === 'localhost';
      const redirectUrl = isLocalhost ? 'http://localhost:3000' : Config.PAYPAL_REDIRECT_BASE_URL;
      webviewRef.current.injectJavaScript(
        `document.dispatchEvent(new CustomEvent('confirmPayPalPayment',{detail:{clientSecret:'${clientSecret}',redirectUrl:'${redirectUrl}/'}}));true;`,
      );
    }
  };

  const refreshWindowWithError = () => {
    if (webviewRef.current)
      webviewRef.current.injectJavaScript(
        `document.dispatchEvent(new CustomEvent('refreshWindowWithError',{detail:{message:'something went wrong'}}));true;`,
      );
  };

  const onPressSubmit = () => {
    if (webviewRef.current)
      webviewRef.current.injectJavaScript(
        `document.dispatchEvent(new CustomEvent('submitForm'));true;`,
      );
  };

  const handleMessage = (event: WebViewMessageEvent) => {
    let message;

    try {
      message = JSON.parse(event.nativeEvent.data);
    } catch (e) {
      message = {
        type: 'link',
        value: event.nativeEvent.data,
      };
    }

    const { type, value } = message;

    if (type === 'log') {
      logger.debug('Received message from payment webview:', value);
      return;
    }

    if (type === 'link') {
      onShowDetails(value);
      return;
    }

    if (type === 'finished') {
      onFinished();
      return;
    }

    if (type === 'failed') {
      onFailed(value.code);
      return;
    }

    if (type === 'ready') {
      setLoading(false);
      return;
    }

    if (type === 'editCoupon') {
      onEditCoupon();
      return;
    }

    if (type === 'disableSubmit') {
      if (isWeb) setSubmitDisabled(value);
      return;
    }

    if (type === 'submit') {
      onSubmit(confirmCardPayment, value);
      return;
    }

    if (type === 'submit-paypal') {
      onSubmit(confirmPayPalPayment, value, PaymentType.PayPal);
      return;
    }

    if (type === 'reload') {
      refreshWindowWithError();
    }

    if (type === 'init-iframe-resizer') {
      if (iframeResize && webviewRef.current) {
        iframeResize(
          {
            checkOrigin: false,
          },
          webviewRef.current.frameRef,
        );
      }

      return;
    }

    logger.warn('unknown post message from webview', {
      type,
      value,
    });
  };

  return (
    <>
      {showError && (
        <NotificationBanner errorType="Error">
          <Text>{errorMessage}</Text>
        </NotificationBanner>
      )}
      <Section
        size="wide"
        style={
          isLargeScreen
            ? {
                marginTop: 70,
              }
            : undefined
        }
        centered
      >
        <Box flex>
          <WebView
            /* The opacity 0.99 is a work around for a bug on webview component
        reported since 18.03.19 on github: https://github.com/react-native-community/react-native-webview/issues/429
        This avoids android app to crash when user navigates back from a screen that implements webview. */
            style={{
              backgroundColor: 'transparent',
              ...Platform.select({
                android: {
                  opacity: 0.99,
                },
              }),
            }} // Workaround for live stripe environment
            // Need to load a https taxfix page, so stripe detects this as the current https location
            // The injected javascript(html) will replace the hole html with the actual webview
            // If we don't do this, stripe will refuse to work as it need to be loaded on https side
            source={{
              uri: `${Config.API_BASE_URL}/api/payments/view`,
              headers: {
                Authorization: accessToken ? `Token ${accessToken}` : null,
              },
            }}
            javaScriptEnabled={true}
            injectedJavaScript={html}
            onMessage={handleMessage}
            ref={webviewRef}
          />
          {showChatBubble && (
            <ChatBubble
              position={{
                bottom: 190,
                right: 10,
              }}
              screenName={ScreenName.ITPaymentCreditCard}
            />
          )}
          {loading && <FullscreenLoader />}
          {isWeb && (
            <Footer
              translationKey="web.payment.pay-continue"
              onPress={onPressSubmit}
              isDisabled={isSubmitDisabled}
            />
          )}
        </Box>
        {children}
      </Section>
    </>
  );
};
