import { AnswerType, AnswerTypes } from '@taxfix/answer-types';

import {
  validateLegacyAnswerType,
  withObjectPayload,
} from '../components/answers/shared/validators/utils';

const AnswerTypeInstance = AnswerType;

const { ibanTools: IbanValidationTools, validationFunction: IbanValidationFunction } =
  AnswerTypes.Iban;

const { validationFunction: ITAddressValidationFunction } = AnswerTypes.ITAddress;

const { validationFunction: AddressValidationFunction } = AnswerTypes.Address;

const { validationFunction: ForeignAddressValidationFunction } = AnswerTypes.ForeignAddress;

const { validationFunction: BankAccountValidationFunction } = AnswerTypes.BankAccount;

const { validationFunction: DistanceValidationFunction } = AnswerTypes.Distance;

const { validationFunction: TaxAuthorityValidationFunction } = AnswerTypes.TaxAuthority;

export const ibanTools = IbanValidationTools();

export const validateBIC = ibanTools.isValidBIC;

export const validateIban = withObjectPayload(IbanValidationFunction);

export const validateITAddress = (input: any) =>
  withObjectPayload(ITAddressValidationFunction)(input);

export const validateAddress = withObjectPayload(AddressValidationFunction);

export const validateForeignAddress = validateLegacyAnswerType(ForeignAddressValidationFunction);

export const validateBankAccount = withObjectPayload(BankAccountValidationFunction);

export const validateDistance = withObjectPayload(DistanceValidationFunction);

export const validateTaxAuthority = TaxAuthorityValidationFunction;

export default AnswerTypeInstance;
