import { SubmissionStatus } from 'src/utils/constants';
import { TaxSystem } from 'src/stores/modules/dev-tools';

export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  IT = 'it',
  ES = 'es',
  EN_GB = 'en-gb',
}
export type TaxYear = {
  status: SubmissionStatus;
  loading: boolean;
  disabled?: boolean;
  category?: {
    completed: number;
    length: number;
  };
  categories?: {
    completed: number;
    total: number;
  };
  imageSrc?: string | number;
  year: string | number;
  handleSelectYear: (year: string | number) => void;
  taxSystem: TaxSystem;
  progress?: number;
  reportUploaded?: boolean;
};
export type TaxYears = { [year in number | string]?: TaxYear };
export type SupportedLanguages = {
  DE: LanguageCode.DE | LanguageCode.EN;
  IT: LanguageCode.IT | LanguageCode.EN;
};

export type GenericCallback = (payload?: Record<any, unknown>) => void;

export type FastLaneCriteria = {
  isSingle: boolean;
  isEmployee: boolean;
  hasNoChildren: boolean;
  hasUncompletedCategories: boolean;
};
