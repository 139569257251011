import React from 'react';
import { Platform } from 'react-native';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MessageDescriptor, IntlShape, injectIntl } from 'react-intl';
import { getVersion } from 'react-native-device-info';
import Config from 'react-native-config';
import { SubmissionWithId, TaxCoreCalculations } from '@taxfix/submissions-types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { withQuizmasterLight, QuizmasterLight } from 'src/utils/with-quizmaster-light';
import { getRoundedDateOfBirth } from 'src/utils/date';
import {
  CWBIDocumentTypes,
  expenseListQuestionIds,
  expenseListQuestions,
  flagsQuestionIds,
  renovationExpenses,
  specificAnswerTypesQuestionIds,
} from 'src/common/constants-it';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { ScreenName } from 'src/types/screen-name';
import { BaseTaxCalculator } from 'src/taxfix-business-logic/tax-calculator';
import { QuizmasterTaxCalculator } from 'src/taxfix-business-logic/tax-calculator/Quizmaster';

import { ItalySubmissionComponent } from '../components/italy-submission';
import {
  getBoundNavigationsActions,
  getNavigationActions,
  safeResetNavigation,
} from '../../routes/config-util';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import {
  WithBackNavigationControl,
  withBackNavigationControl,
} from '../../hocs/with-back-navigation-control';
import { WithSubmitItaly, withSubmitItaly } from '../../hocs/with-submit-italy';
import { WithLegalConditions, withLegalConditions } from '../../hocs/with-legal-conditions';
import {
  actions as SubmissionActions,
  CreateDonationRequest,
  selectors as SubmissionSelectors,
} from '../../stores/modules/submission';
import {
  DocumentBunches,
  selectors as requiredDocumentsSelectors,
} from '../../stores/modules/required-documents';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { actions as OverlayActions } from '../../stores/modules/overlay';
import { taxCalculatorSelector } from '../../stores/modules/questions';
import initial from '../../stores/store/initial';
import { extractPersonalDataFromItalianTaxId } from '../../services/personal-data';
import { DraftSubmissionStates, getPaymentFromPaymentId } from '../../services/submissions';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { formatDate } from '../../i18n';
import { SubmissionConsent, withSubmissionConsent } from '../../hocs/with-submission-consent';
import { UseAnswers, withAnswersStore } from '../../hocs/with-answers-store';
import { logErrorMessages } from '../../common/log-error-messages';
import { getAnswers } from '../../stores/hooks/answers-stores-hook';

import { DonationPreferences } from './italy-donation';

type State = {
  isLoading: boolean;
  isCommercialistaConfirmed: boolean;
  isAmlConsentConfirmed: boolean;
};

export enum SubmissionComplexity {
  Easy = 'easy',
  Medium = 'medium',
  Complex = 'complex',
}

type OuterProps = { donationPreferences: DonationPreferences };

type Props = WithNavigation &
  WithBackNavigationControl &
  WithSubmitItaly &
  WithLegalConditions &
  SubmissionConsent &
  UseAnswers &
  OuterProps & {
    documentCreationError?: MessageDescriptor;
    isEmailConfirmed: boolean;
    accessToken: string;
    userId: number;
    submissionActions: typeof SubmissionActions;
    overlayActions: typeof OverlayActions;
    intl: IntlShape;
    quizmasterLight: QuizmasterLight;
    numberOfUserExpenses: number;
    docBunches: DocumentBunches;
    submission?: SubmissionWithId;
    taxYear?: number | null;
    userExpenseFoldersWithDocs: string[];
    taxCalculator: BaseTaxCalculator | QuizmasterTaxCalculator;
  };

const mapStateToProps = (state: typeof initial) => {
  const submissionStatus = SubmissionSelectors.getLatestSubmissionStatus(state);
  const isDonationConfirmed =
    submissionStatus === DraftSubmissionStates.DonationCreated ||
    submissionStatus === DraftSubmissionStates.DonationAndConsentCreated;

  const questionStore = getQuestionStoreByYearAndCountry(state);
  const { tailoredCategories } = getAnswers(questionStore);

  const userExpenseFoldersWithDocs = requiredDocumentsSelectors.getUserExpenseFoldersWithDocuments(
    state,
    tailoredCategories,
  );
  const submission = SubmissionSelectors.getActiveSubmission(state);

  let taxCalculator;
  if (settingsSelectors.selectedYear(state)) {
    taxCalculator = taxCalculatorSelector(state, settingsSelectors.selectedYear(state) as number);
  }

  return {
    userExpenseFoldersWithDocs,
    docBunches: requiredDocumentsSelectors.getDocumentBunches(state),
    taxYear: settingsSelectors.selectedYear(state),
    isDonationConfirmed,
    documentCreationError: SubmissionSelectors.error(state),
    accessToken: userAuthSelectors.getAccessToken(state),
    userId: userAuthSelectors.getUserId(state),
    isEmailConfirmed: userAuthSelectors.isEmailAddressConfirmed(state),
    submission,
    taxCalculator,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submissionActions: bindActionCreators(SubmissionActions, dispatch),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});
class UndecoratedItalySubmission extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      isCommercialistaConfirmed: false,
      isAmlConsentConfirmed: false,
    };
  }

  createDonationDocument = async () => {
    const { intl, submissionActions, accessToken, userId, donationPreferences, form730 } =
      this.props;
    const {
      '730/DatiAnagrafici/Nome': firstName,
      '730/DatiAnagrafici/Cognome': lastName,
      '730/SoggettoFiscale/CodiceFiscale': taxId,
    } = form730;
    const { gender, birthdate, birthplace, birthplaceProvince } =
      extractPersonalDataFromItalianTaxId(taxId);

    try {
      await submissionActions.createDonation(
        {
          firstName,
          lastName,
          taxId,
          sex: gender,
          dateBirth: formatDate(intl, birthdate),
          placeBirth: birthplace,
          province: birthplaceProvince,
          eight1000: donationPreferences.labels.churchAndState,
          eight1000_area: donationPreferences.labels.churchAndStateArea,
          five1000: donationPreferences.labels.nonProfit,
          five1000_code: donationPreferences.labels.nonProfitCode,
          two1000: donationPreferences.labels.politicalParty,
          two1000_ca: 'none',
          platform: Platform.OS,
          platformVersion: getVersion(),
          permissionText: intl.formatMessage({
            id: 'italy-submission.permission.text',
          }),
        } as CreateDonationRequest,
        {
          accessToken,
          apiBaseUrl: Config.API_BASE_URL,
        },
      );
      Analytics.log(AnalyticsEvent.donationSuccess);
      await submissionActions.updateLatestSubmissionStatus(accessToken, userId);
    } catch (err) {
      logger.error(err as Error, {
        message: logErrorMessages.donationDocError,
      });
      Analytics.log(AnalyticsEvent.donationFailed);
      throw err;
    }
  };

  handleConsentTextExpand = () => {
    Analytics.log(AnalyticsEvent.consentExpandClicked);
  };

  handleConfirmCommercialista = (isConfirmed: boolean) => {
    if (isConfirmed) {
      Analytics.log(AnalyticsEvent.consentPermissionClicked);
    }
    this.setState({
      isCommercialistaConfirmed: isConfirmed,
    });
  };

  handleConfirmAml = (isConfirmed: boolean) => {
    if (isConfirmed) {
      Analytics.log(AnalyticsEvent.consentPermissionClicked);
    }
    this.setState({
      isAmlConsentConfirmed: isConfirmed,
    });
  };

  // number of folders filled either with documents or expenseLists
  getNumberOfFilledExpenseFolders = () => {
    const catsFilledWithDocs = this.props.userExpenseFoldersWithDocs;

    // get list of categories that have expenseLists
    const catsWithExpenseLists: string[] = [];
    Object.keys(expenseListQuestions).forEach((expense) => {
      const expenseListId = expenseListQuestions[expense];
      if (this.props.quizmasterLight[expenseListId]?.answer !== undefined) {
        catsWithExpenseLists.push(expense);
      }
    });

    return new Set([...catsFilledWithDocs, ...catsWithExpenseLists]).size;
  };

  updateSubmissionComplexity = () => {
    const { docBunches, submission, userId } = this.props;
    const numberOfFilledExpenseFolders = this.getNumberOfFilledExpenseFolders();

    const hasAnyRenovationExpense = renovationExpenses.some(
      (renovationExpense) => docBunches[renovationExpense].items?.length > 0,
    );

    const hasDependants =
      this.props.quizmasterLight[specificAnswerTypesQuestionIds.hasDependants]?.answer?.[0] !==
      'none';

    const numberOfUserCUs =
      docBunches['DOCUMENT_INCOME_CU'].items.length +
      docBunches[CWBIDocumentTypes.CU_PDF].items.length;

    const hasOneOrLessCU = numberOfUserCUs <= 1;
    const hasMoreThanOneCU = numberOfUserCUs > 1;
    let submissionComplexity = SubmissionComplexity.Easy;

    if (hasAnyRenovationExpense) {
      submissionComplexity = SubmissionComplexity.Complex;
    } else if (
      hasMoreThanOneCU ||
      (hasOneOrLessCU && (numberOfFilledExpenseFolders >= 4 || hasDependants))
    ) {
      submissionComplexity = SubmissionComplexity.Medium;
    }

    Analytics.log(AnalyticsEvent.submissionComplexity, {
      submissionComplexity,
      screenName: ScreenName.ITSubmission,
      submissionId: submission?.id,
      userId: userId,
      year: this.props.taxYear,
    });
    return submissionComplexity;
  };

  doSubmit = async () => {
    const {
      disableGoBack,
      enableGoBack,
      createSubmission,
      createSubmissionConsentDocument,
      createAmlConsentDocument,
      overlayActions,
      quizmasterLight,
      accessToken,
      taxCalculator,
    } = this.props;

    // TODO: for instant flow it failed because of items of undefined plus if user doesn't have any CUs - it will also fail
    // const complexity = this.updateSubmissionComplexity();
    // await quizmasterLight[flagsQuestionIds.submissionComplexity].saveAnswer(complexity);

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          disableGoBack();

          try {
            await this.createDonationDocument();
          } catch (err) {
            logger.log(err, {
              message: logErrorMessages.donationDocError,
            });
            throw err;
          }

          try {
            await createSubmissionConsentDocument();
          } catch (err) {
            logger.log(err, {
              message: logErrorMessages.submissionConsentDocError,
            });
            throw err;
          }

          try {
            await createAmlConsentDocument();
          } catch (err) {
            logger.log(err, {
              message: logErrorMessages.amlConsentDocError,
            });
            throw err;
          }

          try {
            Analytics.log(AnalyticsEvent.submissionInitiated);
            await createSubmission();

            const { submission } = this.props;
            const paymentId = submission?.paymentId || null;

            let paymentType = null;
            let revenue = 0;
            let currency = 'EUR';

            if (paymentId) {
              try {
                const payment = await getPaymentFromPaymentId(accessToken, paymentId);

                paymentType = payment.type;
                revenue = payment?.amountCents ? payment.amountCents / 100 : 0;
                currency = payment?.currency ? payment.currency : 'EUR';
              } catch (error) {
                logger.error(error as Error);
              }
            }

            const calculationResult = submission?.calculationResult as TaxCoreCalculations;
            const refundAmount = calculationResult?.refund;

            // to extract birth date
            const { form730 } = this.props;
            const { '730/SoggettoFiscale/CodiceFiscale': taxId } = form730;
            const { birthdate } = extractPersonalDataFromItalianTaxId(taxId);
            const roundedBirthYear = getRoundedDateOfBirth(birthdate?.toISOString());
            const { mandatory } = taxCalculator?.mandatory;

            const eventParams = {
              paymentType,
              refundAmount,
              submissionMandatory: mandatory,
              roundedBirthYear,
              revenue,
              currency,
              submissionId: submission?.id,
            };

            Analytics.log(AnalyticsEvent.submissionSuccess, eventParams);

            quizmasterLight[flagsQuestionIds.commercialistaConsentGivenV500].saveAnswer(true);
            overlayActions.show('SuccessOverlay', {
              icon: 'interface-icons-svg.icon-checkmark-white',
              titleId: 'it.success-overlay.submission.title',
              descriptionId: 'it.success-overlay.submission.description',
              buttonId: 'it.success-overlay.submission.button',
              exitDirection: 'top',
              onNext: () => {
                safeResetNavigation([
                  getNavigationActions().toDashboard('screen'),
                  getNavigationActions().toPhoneNumber('screen', {
                    onNext: () => getBoundNavigationsActions().toDashboard('screen'),
                  }),
                ]);
                overlayActions.hide();
              },
            });
          } catch (err) {
            Analytics.log(AnalyticsEvent.submissionFailed);
            logger.error(err as Error, {
              message: logErrorMessages.submissionError,
            });
            throw err;
          }
        } catch (err) {
          this.setState({
            isLoading: false,
          });
          enableGoBack();
        }
      },
    );
  };

  handleBackButtonClick = () => {
    this.props.navigationActions.back();
  };

  render() {
    const { isLoading, isCommercialistaConfirmed, isAmlConsentConfirmed } = this.state;
    const { documentCreationError, isEmailConfirmed, consentDate, taxYear, form730 } = this.props;
    const {
      '730/DatiAnagrafici/Nome': firstName,
      '730/DatiAnagrafici/Cognome': lastName,
      '730/SoggettoFiscale/CodiceFiscale': taxId,
    } = form730;

    return (
      <ItalySubmissionComponent
        isCommercialistaConfirmed={isCommercialistaConfirmed}
        isAmlConsentConfirmed={isAmlConsentConfirmed}
        isConfirmEnabled={isEmailConfirmed}
        onConfirmCommercialista={this.handleConfirmCommercialista}
        onConfirmAml={this.handleConfirmAml}
        firstName={firstName}
        lastName={lastName}
        taxId={taxId || ''}
        consentDate={consentDate}
        taxYear={taxYear}
        onConsentTextExpand={this.handleConsentTextExpand}
        onSubmit={this.doSubmit}
        isLoading={isLoading}
        documentCreationError={documentCreationError}
        onBackButtonPress={this.handleBackButtonClick}
        handleTermsClicked={this.props.handleTermsClicked}
      />
    );
  }
}

export const ItalySubmissionContainer = compose<React.ComponentType<OuterProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withSubmissionConsent,
  withSubmitItaly,
  withNavigation,
  withBackNavigationControl,
  withAnswersStore,
  withLegalConditions,
  withQuizmasterLight([
    flagsQuestionIds.commercialistaConsentGivenV500,
    //flagsQuestionIds.submissionComplexity,
    specificAnswerTypesQuestionIds.hasDependants,
    ...expenseListQuestionIds,
  ]),
)(UndecoratedItalySubmission);
