export const privacyStyles = `
<style type="text/css">
.privacy {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.privacy h1 {
  font-size: 24px;
  font-family: 'Circular-Bold';
  color: var(--color-primary);
  letter-spacing: 0;
}

.privacy h3 {
  font-family: 'Circular-Bold';
  font-size: 20px;
  color: var(--color-primary);
  letter-spacing: 1.17px;
  text-transform: none;
  margin-top: initial;
}

.privacy .highlight {
  color: var(--color-primary);
  font-family: 'Circular-Bold';
}

.privacy .indent {
  padding-left: 40px;
}

.privacy .bold {
  font-family: 'Circular-Bold';
}

.privacy ul {
  list-style: none;
  color: black;
  letter-spacing: 0;
}

.privacy ul li {
  line-height: initial;
}

.privacy ol {
  color: black;
  letter-spacing: 0;
  list-style: none;
  counter-reset: list;
}
.privacy ol > li {
  line-height: initial;
  counter-increment: list;
  margin-bottom: 12px;
}

.privacy ol > li:before {
  content: counter(list) '. ';
  color: var(--color-primary);
  font-weight: Circular-Bold;
}

.privacy table {
  table-layout: fixed;
  width: 110%;
  margin: 15px 0 15px -10%;
  border: 1px solid black;
  border-collapse: collapse;
}

.privacy table thead {
  text-align: center;
}

.privacy table thead td {
  font-size: 12px;
}

.privacy table td {
  border: 1px solid black;
}

.privacy table tbody td {
  word-break: break-word;
  padding: 3px 6px;
  font-size: 12px;
}

.privacy table a {
  font-size: 12px;
}

.custom-table {
  overflow-x: auto;
  position: relative;
  margin-right: -20px;
}

.custom-table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.custom-table th {
  white-space: nowrap;
  background-color: rgba(0,0,0,.05)
}

.custom-table th, .custom-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid var(--color-border)
}
</style>
`;
