import React from 'react';

import { Container, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks';

import { usePaymentContext } from './payment.context';

const DisclaimerWithTotal: React.FC = () => {
  const { formattedOriginalPrice, formattedPrice } = usePaymentContext();
  const { getTranslationText } = useItalyIntl();

  return (
    <Container my={20}>
      <Container px={20} py={20} bg="gray.100" mx={{ md: 'auto', base: -24 }}>
        <Text variant="bodySBook" color="greytones.textDefault">
          {getTranslationText('it.payment.screen.disclaimer.description')}
        </Text>
      </Container>
      <Container flexDirection="row" justifyContent="space-between" py={20}>
        <Text
          variant="titleXSBook"
          color="greytones.textDefault"
          value={getTranslationText('payment.summary.subtitle')}
        />
        <Container flexDirection="row">
          {formattedOriginalPrice !== formattedPrice && (
            <Text
              variant="bodySBook"
              color="greytones.textLight"
              value={formattedOriginalPrice}
              strikeThrough
              alignItems="center"
              mr="5px"
            />
          )}
          <Text variant="bodyMBook" color="greytones.textLight" value={formattedPrice} />
        </Container>
      </Container>
    </Container>
  );
};

export { DisclaimerWithTotal };
