import * as React from 'react';
import { Years, Documents } from '@taxfix/types';
import { useDispatch } from 'react-redux';

import {
  getBoundNavigationsActions,
  getNavigationActions,
  safeResetNavigation,
} from 'src/routes/config-util';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { DocumentUploadMode, wayQuestionIds } from 'src/common/constants-it';
import { AlertOverlayButton, AlertOverlayProps } from 'src/components/alert-overlay';

import { ItalySidebar } from '../../../containers/sidebar/italy-sidebar';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../hooks/navigation-options-hook';
import { actions as OverlayActions } from '../../../stores/modules/overlay';
import navigate, { Action, Navigation, NavigationParams } from '../../navigate';

type ActionParams = {
  canSkipDocumentsUpload: boolean;
  isIdUploadSkipped: boolean;
  year: Years;
};

type Props = Navigation<
  'SkippedSectionReminder',
  {
    SkippedSectionReminder: NavigationParams<ActionParams>;
  }
> & {
  quizmasterLight: QuizmasterLight;
};

export const loadingIconStyles = {
  width: 78,
  height: 78,
  marginTop: 28,
  marginBottom: 25,
};

export const toSkippedSectionReminder: Action<ActionParams> = navigate('SkippedSectionReminder', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'SkippedSectionReminder',
  },
});

const SkippedSectionReminderUndecorated = ({ route, quizmasterLight }: Props) => {
  const { canSkipDocumentsUpload, isIdUploadSkipped, year } = route.params;
  const userName = quizmasterLight[wayQuestionIds.firstName].answer;
  const dispatch = useDispatch();

  useDefaultNavigationOptions({ id: 'it.billing-address.navigation.title' });

  const sidebar = () => <ItalySidebar />;

  useWebNavigationOptions({
    sidebar,
  });

  React.useEffect(() => {
    const buttons: AlertOverlayButton[] = [];
    if (!canSkipDocumentsUpload) {
      buttons.push({
        translationKey: 'it-reminder-overlay.upload-documents',
        // @ts-expect-error Undefined button type
        type: 'primary',
        style: {
          width: '100%',
        },
        onPress: () => {
          Analytics.log(AnalyticsEvent.retryRequiredDocumentsReminderPressed, {
            screenName: 'skipped-section-reminder',
          });
          dispatch(OverlayActions.hide());
          safeResetNavigation([
            getNavigationActions().toStatus('screen'),
            getNavigationActions().toRequiredDocumentsDigitalCaf('screen', {
              onNext: () => {
                getBoundNavigationsActions().reset({
                  index: 0,
                  actions: [getNavigationActions().toStatus('screen')],
                });
              },
              screenMode: DocumentUploadMode.EXPENSES_STEP,
            }),
          ]);
        },
      });
    }
    if (isIdUploadSkipped) {
      buttons.push({
        translationKey: 'it-reminder-overlay.upload-id',
        // @ts-expect-error Undefined button type
        type: 'primary',
        style: {
          width: '100%',
        },
        onPress: () => {
          Analytics.log(AnalyticsEvent.retryPrefillDocumentUploadReminderPressed, {
            screenName: 'skipped-section-reminder',
          });
          dispatch(OverlayActions.hide());
          safeResetNavigation([
            getNavigationActions().toStatus('screen'),
            getNavigationActions().toPrefillDocumentUpload('screen', {
              prefillDocumentType: Documents.NonReceiptTypes.Id,
              year,
            }),
          ]);
        },
      });
    }
    const params: AlertOverlayProps = {
      topIcon: {
        name: 'icons-svg.loading',
        style: loadingIconStyles,
      },
      titleTranslationKey: 'it-reminder-overlay.title',
      leadTranslationKey: 'it-reminder-overlay.description',
      translationValues: {
        userName,
      },
      buttons,
      appearFromTop: true,
      onClose: () => {
        Analytics.log(AnalyticsEvent.skippedStepsReminderClosed, {
          screenName: 'skipped-section-reminder',
        });
        safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
      },
    };
    dispatch(OverlayActions.show('AlertOverlay', params));
  }, [canSkipDocumentsUpload, isIdUploadSkipped, dispatch, userName, year]);
  return <></>;
};

export const SkippedSectionReminder = withQuizmasterLight([wayQuestionIds.firstName])(
  // @ts-ignore
  SkippedSectionReminderUndecorated,
);
