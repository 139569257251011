import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Container, Text, Button, IconButton, icons } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { theme } from '../../components/core';
import Overlay from '../../components/overlay';

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export type IdleHelpPopupProps = {
  onButtonPress: () => void;
  onLoadCallback: () => void;
  onClose: () => void;
};

export const ResultScreenIdleHelpPopup: React.FC<IdleHelpPopupProps> = ({
  onButtonPress,
  onLoadCallback,
  onClose,
}) => {
  const { getTranslationText } = useItalyIntl();

  const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

  const overlayWidth = useBreakpointValue({ base: '328px', md: '600px' });
  const overlayHeight = useBreakpointValue({ base: '352px', md: '400px' });
  const overlayPaddingX = useBreakpointValue({ base: '24px', md: '56px' });
  const titleVariant = useBreakpointValue({ base: 'titleSBold', md: 'titleMBold' });
  const descriptionVariant = useBreakpointValue({ base: 'bodySBook', md: 'bodyMBook' });
  const buttonWidth = useBreakpointValue({ base: 'xl', md: 'l' });

  const title = getTranslationText('prefill-result-screen.idle-pop.title');
  const description = getTranslationText('prefill-result-screen.idle-pop.text', {
    bold: getBoldText,
  });
  const buttonLabel = getTranslationText('prefill-result-screen.idle-pop.button');

  useEffect(() => {
    onLoadCallback();
  }, [onLoadCallback]);

  return (
    <Overlay
      backgroundColor={theme.color.overlay}
      style={{
        justifyContent: 'center',
      }}
      appearFromTop
      onCloseComplete={onClose}
    >
      {() => (
        <View style={styles.mainContainer}>
          <Container
            position="relative"
            bg="greytones.white"
            borderRadius="12px"
            width={overlayWidth}
            height={overlayHeight}
            paddingX={overlayPaddingX}
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            <Container position="absolute" top="16px" right="16px">
              <IconButton variant="grey" path={icons.closeSupport} onPress={onClose} size="24px" />
            </Container>
            <Text
              value={title}
              variant={titleVariant}
              color="greytones.textDefault"
              textAlign="center"
            />
            <Text
              value={description}
              variant={descriptionVariant}
              textAlign="center"
              color="greytones.textLight"
              mt={[24, 24, 32]}
            />
            <Button
              variant="primary"
              title={buttonLabel}
              onPress={onButtonPress}
              width={buttonWidth}
              mt={[32, 32, 48]}
            />
          </Container>
        </View>
      )}
    </Overlay>
  );
};
