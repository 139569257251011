import React from 'react';

import { Button } from 'src/taxfix-components/src';
import { UploadButtonVariant } from 'src/screens/components/file-drop-zone-web.web';

import { ButtonWithOverlay } from '../containers/input/button-with-overlay';

import { Option } from './input/button-overlay';
import { AddDocumentButton } from './add-document-button';

type Props = {
  onPress?: () => void;
  options?: Option[];
  color?: string;
  variant?: UploadButtonVariant;
  uploadButtonTitle?: string;
};

export const UploadDocumentButton: React.FC<Props> = ({
  options = [],
  color,
  variant = UploadButtonVariant.DropZone,
  uploadButtonTitle,
}) => {
  return (
    <ButtonWithOverlay options={options} color={color} fullWidthOnWeb>
      {({ handlePress }) =>
        variant === UploadButtonVariant.Primary ? (
          <Button variant="primary" title={uploadButtonTitle} onPress={handlePress} />
        ) : (
          <AddDocumentButton onPress={handlePress} />
        )
      }
    </ButtonWithOverlay>
  );
};
