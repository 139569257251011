import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider, DefaultTheme } from 'styled-components/native';
import { NativeBaseProvider } from 'native-base';

export interface ThemeProviderProps {
  styledTheme: DefaultTheme;
  children: ReactNode;
}

export const BaseThemeProvider: React.FC<ThemeProviderProps> = ({ styledTheme, children }) => {
  const inset = {
    frame: { x: 0, y: 0, width: 0, height: 0 },
    insets: { top: 0, left: 0, right: 0, bottom: 0 },
  };
  return (
    <NativeBaseProvider initialWindowMetrics={inset}>
      <StyledThemeProvider theme={styledTheme}>{children}</StyledThemeProvider>
    </NativeBaseProvider>
  );
};
