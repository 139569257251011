import { $Keys } from 'utility-types';
import { Dispatch } from 'redux';
import { Referrals } from '@taxfix/voucher-sdk';
import Config from 'react-native-config';
import { createSelector } from 'reselect';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

export const FETCH_BEGIN = 'referral-history/FETCH_BEGIN';
export const FETCH_SUCCESS = 'referral-history/FETCH_SUCCESS';
export const FETCH_FAILED = 'referral-history/FETCH_FAILED';
export const ADD_LOCAL_REFERRAL = 'referral-history/ADD_LOCAL_REFERRAL';
export const ReferralStates = {
  sent: 'sent',
  accepted: 'accepted',
  redeemed: 'redeemed',
};
export type ReferralState = $Keys<typeof ReferralStates>;
export type ReferralHistory = {
  refereeName: string | null | undefined;
  state: ReferralState;
  referralInviteSentAt: string | null | undefined;
  campaignName: string;
  referrerRewardAmountCents: number;
  refereeRewardAmountCents: number;
};
export type State = {
  isFetching: boolean;
  remoteHistory: ReferralHistory[];
  localHistory: ReferralHistory[];
};
export const initial: State = {
  isFetching: false,
  remoteHistory: [],
  localHistory: [],
};
type FetchReferralHistoryBegin = {
  type: typeof FETCH_BEGIN;
};
type FetchReferralHistorySuccess = {
  type: typeof FETCH_SUCCESS;
  payload: {
    remoteHistory: ReferralHistory[];
  };
};
type FetchReferralHistoryFailed = {
  type: typeof FETCH_FAILED;
};
type AddLocalReferral = {
  type: typeof ADD_LOCAL_REFERRAL;
  payload: {
    referral: ReferralHistory;
  };
};
type Action =
  | FetchReferralHistoryBegin
  | FetchReferralHistorySuccess
  | FetchReferralHistoryFailed
  | AddLocalReferral;

const retrieveReferralHistory =
  ({ accessToken }: { accessToken: string }) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: FETCH_BEGIN,
    });

    try {
      const remoteHistory = await Referrals.getStatus(Config.API_BASE_URL, accessToken);
      dispatch({
        type: FETCH_SUCCESS,
        payload: {
          remoteHistory,
        },
      });
    } catch (e) {
      dispatch({
        type: FETCH_FAILED,
      });
    }
  };

type AddReferral = {
  referralInviteSentAt: string;
  campaignName: string;
  referrerRewardAmountCents: number;
};

const addReferralLocal = ({
  referralInviteSentAt,
  campaignName,
  referrerRewardAmountCents,
}: AddReferral) => ({
  type: ADD_LOCAL_REFERRAL,
  payload: {
    referral: {
      referralInviteSentAt,
      campaignName,
      state: 'sent',
      refereeName: null,
      referrerRewardAmountCents,
    },
  },
});

export const actions = {
  retrieveReferralHistory,
  addReferralLocal,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case FETCH_BEGIN:
      return { ...state, isFetching: true };

    case FETCH_SUCCESS:
      return { ...state, isFetching: false, remoteHistory: action.payload.remoteHistory };

    case FETCH_FAILED:
      return { ...state, isFetching: false };

    case ADD_LOCAL_REFERRAL:
      return { ...state, localHistory: [action.payload.referral, ...state.localHistory] };

    default:
      return state;
  }
};
type RootState = {
  referralHistory: State;
};

// selectors
const isFetching = (state: RootState): boolean => state.referralHistory.isFetching;

const getReferralRemoteHistory = (state: RootState): ReferralHistory[] =>
  state.referralHistory.remoteHistory;

const getReferralLocalHistory = (state: RootState): ReferralHistory[] =>
  state.referralHistory.localHistory;

const getReferralHistory = createSelector(
  getReferralRemoteHistory,
  getReferralLocalHistory,
  (remoteHistory, localHistory) => {
    // we save history on local when it's sent
    // and if there is a data whose date is exactly the same.
    // we assume that it's the same data that has gone to the next step, so filter it out
    const filteredLocalHistory = localHistory.filter(
      (item) =>
        !(
          item.referralInviteSentAt &&
          remoteHistory
            .map((_item) => _item.referralInviteSentAt)
            .includes(item.referralInviteSentAt)
        ),
    );
    return [...remoteHistory, ...filteredLocalHistory];
  },
);
export const selectors = {
  isFetching,
  getReferralHistory,
};
export const persistFilter = createBlacklistFilter('referralHistory', ['isFetching']);
