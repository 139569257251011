import * as React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import {
  Box,
  Button,
  theme,
  Icon,
  OnboardingText,
  DisplayTitleText,
  BodyTitleText,
} from '../../components/core';

type Props = {
  onStartOnboardingPress: () => any;
  discount: string;
  banner?: React.ReactNode;
};
export const OnboardingSplashReferral = ({ onStartOnboardingPress, banner, discount }: Props) => (
  <SafeAreaView
    style={{
      flex: 1,
      backgroundColor: theme.color.primary,
    }}
  >
    {banner}
    <Box
      flex
      style={{
        justifyContent: 'space-between',
      }}
    >
      <Box top={6} left={4} right={4}>
        <Icon name="taxfixLogo" />
        <OnboardingText
          style={{
            color: theme.color.lightText,
          }}
          id="referral.onboarding.splash.subtitle"
        />
      </Box>

      <Box alignVertically="center" alignHorizontally="center">
        <Icon name="submission-svg.submission-result-losscarryforward" />
        <Box top={1} bottom={1}>
          <DisplayTitleText
            id="referral.onboarding.splash.title"
            values={{
              discount,
            }}
          />
        </Box>
        <BodyTitleText id="referral.onboarding.splash.secondary" />
      </Box>

      <Box left={2} right={2} bottom={2}>
        <Button
          testId="referral.onboarding.splash.cta"
          translationKey="referral.onboarding.splash.cta"
          onPress={onStartOnboardingPress}
          textColor={theme.color.primary}
          style={{
            backgroundColor: theme.color.lightText,
          }}
        />
      </Box>
    </Box>
  </SafeAreaView>
);
