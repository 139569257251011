import { useIntl as useIntlReact, IntlShape } from 'react-intl';

type Props = {
  getTranslationText: (id: string, values?: any) => string;
  intl: IntlShape;
};

export const useItalyIntl = (): Props => {
  const intl = useIntlReact();

  const getTranslationText = (id: string, values?: any): string => {
    return intl.formatMessage({ id }, values);
  };

  return { getTranslationText, intl };
};
