import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';

type Props = ListRowComponentPropsType<any> & WithNavigation;

const PrivacySettings = (props: Props) => {
  const { showIcon = false, textVariation = '' } = props;

  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions } = props;
    trackMenuItem(item);
    navigationActions.toPrivacySettingsIT('screen');
  };

  return props.children({
    hide: false,
    data: {
      iconName: showIcon ? 'menuItems.privacy' : undefined,
      translationKey: `${menuItem.privacySettings}${textVariation}`,
      testId: menuItem.emailChange,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const PrivacySettingsItalyMenu = compose(withNavigation)(PrivacySettings);
