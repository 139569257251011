import React, { ReactNode, PureComponent } from 'react';
import { Animated, Easing } from 'react-native';

import { isWeb } from '../utils/platform';

type Props = {
  children?: ReactNode;
  duration?: number;
};
export class SpinningImage extends PureComponent<Props> {
  spinAnimatedValue = new Animated.Value(0);

  componentDidMount() {
    this.startAnimation();
  }

  startAnimation = () => {
    const { duration = 500 } = this.props;
    Animated.loop(
      Animated.timing(this.spinAnimatedValue, {
        toValue: 1,
        duration,
        easing: Easing.linear,
        useNativeDriver: !isWeb,
      }),
    ).start();
  };

  render() {
    const interpolation = this.spinAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'] as string[],
    });
    const imageStyle = {
      transform: [
        {
          rotate: interpolation,
        },
      ],
    };
    return <Animated.View style={imageStyle}>{this.props.children}</Animated.View>;
  }
}
