import { Calculations } from '@taxfix/income-tax-calculator';
import { IncomeTaxValue } from '@taxfix/quizmaster/dist/types';

import { Action, ActionTypes } from './types';

export type IncomeTaxCalculatorState = {
  isCalculationsLoading?: boolean;
  incomeFields?: IncomeTaxValue[];
  incomeFieldHash: string;
  calculations: Calculations | null | undefined;
};

export type QuestionsState = Record<string, IncomeTaxCalculatorState>;

export type RootState = {
  questions: QuestionsState;
};

export const initial: Record<string, IncomeTaxCalculatorState> = {};
export const initialYearState = {
  isCalculationsLoading: false,
  incomeFields: [],
  incomeFieldHash: '',
  calculations: undefined,
};

const createYearState = (): IncomeTaxCalculatorState => initialYearState;

const getInitializedYearState =
  (taxYear: number) =>
  (state: QuestionsState): IncomeTaxCalculatorState =>
    state[`${taxYear}`] ? state[`${taxYear}`] : createYearState();

export const reducer = (state: QuestionsState = {}, action: Action): QuestionsState => {
  switch (action.type) {
    case ActionTypes.UPDATE_INCOME_FIELDS: {
      return {
        ...state,
        [`${action.taxYear}`]: {
          ...getInitializedYearState(action.taxYear)(state),
          incomeFields: action.incomeFields,
        },
      };
    }
    case ActionTypes.UPDATE_TAX_CALCULATIONS_STATUS: {
      return {
        ...state,
        [`${action.taxYear}`]: {
          ...getInitializedYearState(action.taxYear)(state),
          isCalculationsLoading: action.isCalculationsLoading,
        },
      };
    }
    case ActionTypes.UPDATE_TAX_CALCULATIONS: {
      return {
        ...state,
        [`${action.taxYear}`]: {
          ...getInitializedYearState(action.taxYear)(state),
          isCalculationsLoading: false,
          incomeFields: action.incomeFields,
          incomeFieldHash: action.incomeFieldHash,
          calculations: action.calculations,
        },
      };
    }
    default:
      return state;
  }
};
