import { HStack, useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { Card, Container, Text } from 'src/taxfix-components/src';

import { LargeAreaCollapsable } from '../../general/LargeAreaCollapsable';
import { CertifiedIconSolid } from '../../general/MultipathIcons/CertifiedIconSolid';

import { Props } from './types';

const Title = ({ value }: { value: string }) => {
  const { colors } = useTheme();
  return (
    <HStack alignItems="center">
      <Text variant="titleXSMedium" value={value} marginRight={0} />
      <CertifiedIconSolid fillColor={colors.brand.brandGreen} size={5} />
    </HStack>
  );
};

/**
 * A Collapsable on mobile and a Card on desktop with greenLight background and dynamic content
 */

export const AdviceFromExpertsCard: React.FC<Props> = ({
  title = 'Advice from our experts',
  children,
  ...otherProps
}): JSX.Element => {
  const collapsable = useBreakpointValue({ base: true, lg: false });

  return collapsable ? (
    <LargeAreaCollapsable
      variant="sticky1"
      maxWidth="335px"
      minHeight="57px"
      backgroundColor="secondaryFeedback.greenLight"
      startOpen={false}
      title={<Title value={title} />}
      horizontalPadding="20px"
      {...otherProps}
    >
      <Container mx="20px" mb="20px">
        {children}
      </Container>
    </LargeAreaCollapsable>
  ) : (
    <Card
      variant="sticky1"
      width="280px"
      backgroundColor="secondaryFeedback.greenLight"
      py={3}
      px={2}
      {...otherProps}
    >
      <Container mb={2}>
        <Title value={title} />
      </Container>
      {children}
    </Card>
  );
};
