import * as React from 'react';

import { PdfContainer } from '../../containers/pdf';
import { ShareButton } from '../../containers/share-button';
import CloseButton from '../../containers/close-button';
import HeaderButton from '../../containers/header-button';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

export type ActionParams = {
  uri: string;
  year: number;
};

type Props = Navigation<Routes.OpenPDF, { [Routes.OpenPDF]: NavigationParams<ActionParams> }>;

const OpenPDFScreen: React.FC<Props> = ({ route }) => {
  const { params } = route;

  const headerLeft = () => (
    <HeaderButton left>
      <CloseButton />
    </HeaderButton>
  );

  const shareButton = () => (
    <HeaderButton right>
      <ShareButton fileUri={params.uri} year={params.year} />
    </HeaderButton>
  );

  useDefaultNavigationOptions(undefined, {
    headerLeft,
    headerRight: shareButton,
  });

  return (
    <PdfContainer
      source={{
        uri: params.uri,
      }}
    />
  );
};

export const { action: toOpenPDF, screen: openPDFScreen } = getRoute<ActionParams>({
  routeName: Routes.OpenPDF,
  screenOptions: {
    isFullHeight: true,
  },
  screenComponent: OpenPDFScreen,
});

export default OpenPDFScreen;
