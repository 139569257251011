import React, { Ref, ReactNode, createRef } from 'react';
import { ScrollView } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { DisplayPrimaryText, Box, BodyPrimaryText, BodySecondaryText } from '../core';
import scrollToElement from '../../utils/scrollToElement';
import { isWeb } from '../../utils/platform';
import { StyleProp, StyleTextProp } from '../types';
import { ConnectionFailedType } from '../../screens/signup/signup-container.types';
import { FooterType } from '../footer.types';
import { maskEmail } from '../../utils/credentials';
import { LoginErrorValues } from '../../screens/login/Login.types';

import PinInput from './PinInput';
import { Types as PinTypes, Variant as PinVariants } from './PinInputComponent.types';
import EmailAddress, { Variant as EmailVariants } from './EmailAddress';
import PhoneNumberInput from './PhoneNumberInput';
import { KeyboardShouldPersistTaps, SignupError, Layout } from './Layout';
import Name from './Name';

export type ErrorKey =
  | (ConnectionFailedType | null | undefined)
  | (SignupError | null | undefined)
  | (LoginErrorValues | null | undefined);
type Props = {
  selectedCountry?: CountryCodes;
  titleKey?: string;
  titleValues?: Record<string, string>;
  confirmKey: string;
  onConfirm?: () => void;
  imageName?: string | null | undefined;
  disabled: boolean;
  scrollable?: boolean;
  loading: boolean;
  errorKey?: ErrorKey;
  titleStyle?: StyleTextProp;
  leadStyle?: StyleTextProp;
  emailOffset?: number;
  pinOffset?: number;
  firstNameOffset?: number;
  lastNameOffset?: number;
  email?: string;
  phoneNumber?: string;
  pin: string;
  firstName?: string;
  lastName?: string;
  onEmailChange?: (arg0: string) => void;
  onPhoneNumberChange?: (arg0: string) => void;
  onPinChange?: (arg0: string) => void;
  onFirstNameChange?: (arg0: string) => void;
  onLastNameChange?: (arg0: string) => void;
  isLockedEmail?: boolean;
  pinType?: PinTypes;
  pinVariant?: PinVariants;
  onPinForgot?: () => void;
  autoFocusPin?: boolean;
  autoFocusFirstName?: boolean;
  autoFocusLastName?: boolean;
  emailVariant?: EmailVariants;
  errorKeyEmail?: string | null | undefined;
  errorKeyFirstName?: string | null | undefined;
  errorKeyLastName?: string | null | undefined;
  children?: ReactNode;
  autoFocusEmail?: boolean;
  keyboardShouldPersistTaps?: KeyboardShouldPersistTaps;
  containerStyle?: StyleProp;
  childContainerStyle?: StyleProp;
  leadKey?: string;
  leadTextComponent?: typeof BodyPrimaryText | typeof BodySecondaryText;
  emailLabelKey?: string;
  pinLabelKey?: string;
  onInfoClick?: () => void;
  hideEmail?: boolean;
  showProgress?: boolean;
  progress?: number;
  isLockEmailHidden?: boolean;
  readOnlyEmail?: boolean;
  centered?: boolean;
  footerType?: FooterType;
  secondaryButton?: () => React.ReactNode;
  sensitiveDataModalExperimentVariant?: string;
};

class CredentialScrollAware extends React.PureComponent<Props> {
  static defaultProps = {
    pin: '',
  };

  layout: Ref<typeof Layout> | null | undefined;

  pinInputRef = createRef<PinInput>();

  phoneNumberInputRef = createRef<PhoneNumberInput>();

  emailInputRef = createRef<EmailAddress>();

  firstNameRef = createRef<Name>();

  lastNameRef = createRef<Name>();

  scrollViewRef: Ref<typeof ScrollView> | null | undefined;

  setScrollViewRef = (ref: any) => {
    this.scrollViewRef = ref;
  };

  setLayout = (ref: any) => {
    this.layout = ref;
  };

  handleEmailSubmit = () => {
    if (this.props.onPhoneNumberChange && this.phoneNumberInputRef.current) {
      this.phoneNumberInputRef.current.focus();
    } else if (this.pinInputRef.current) {
      this.pinInputRef.current.handleFocusTextInput();
    }
  };

  handlePhoneNumberSubmit = () => {
    if (this.pinInputRef.current) {
      this.pinInputRef.current.handleFocusTextInput();
    }
  };

  handleFirstNameSubmit = () => {
    if (this.lastNameRef.current) {
      this.lastNameRef.current.handleFocus();
    } else if (this.emailInputRef.current) {
      this.emailInputRef.current.handleFocus();
    }
  };

  handleLastNameSubmit = () => {
    if (this.emailInputRef.current) {
      this.emailInputRef.current.handleFocus();
    }
  };

  handlePinFocus = () => {
    const { pinOffset = 160, onPhoneNumberChange } = this.props;

    if (this.pinInputRef.current) {
      scrollToElement(this, this.pinInputRef.current, onPhoneNumberChange ? 220 : pinOffset);
    }
  };

  handleEmailFocus = () => {
    const { emailOffset = 180, onFirstNameChange } = this.props;

    if (this.emailInputRef.current) {
      scrollToElement(this, this.emailInputRef.current, onFirstNameChange ? 200 : emailOffset);
    }
  };

  handlePhoneNumberFocus = () => {
    if (this.phoneNumberInputRef.current) {
      scrollToElement(this, this.phoneNumberInputRef.current, 140);
    }
  };

  handleFirstNameFocus = () => {
    const { firstNameOffset = 180 } = this.props;

    if (this.firstNameRef.current) {
      scrollToElement(this, this.firstNameRef.current, firstNameOffset);
    }
  };

  handleLastNameFocus = () => {
    const { lastNameOffset = 160 } = this.props;

    if (this.lastNameRef.current) {
      scrollToElement(this, this.lastNameRef.current, lastNameOffset);
    }
  };

  render() {
    const emailAddressComponent = this.props.isLockEmailHidden ? null : this.props.isLockedEmail ? (
      <Box bottom={3}>
        <DisplayPrimaryText>{this.props.email}</DisplayPrimaryText>
      </Box>
    ) : (
      // @ts-ignore
      <EmailAddress
        readOnly={this.props.readOnlyEmail}
        titleKey={this.props.emailLabelKey}
        errorKey={this.props.errorKeyEmail}
        value={this.props.email}
        onChange={this.props.onEmailChange}
        autoFocus={this.props.autoFocusEmail}
        ref={this.emailInputRef}
        onFocus={this.handleEmailFocus}
        variant={this.props.emailVariant}
        onEmailSubmit={this.handleEmailSubmit}
      />
    );

    const firstNameComponent = this.props.onFirstNameChange ? (
      <Name
        titleKey="account.firstName.label"
        errorKey={this.props.errorKeyFirstName}
        value={this.props.firstName}
        onChange={this.props.onFirstNameChange}
        autoFocus={this.props.autoFocusFirstName}
        ref={this.firstNameRef}
        onFocus={this.handleFirstNameFocus}
        onSubmitEditing={this.handleFirstNameSubmit}
        testId="test.text.account.firstName"
      />
    ) : null;

    const lastNameComponent = this.props.onLastNameChange ? (
      <Name
        titleKey="account.lastName.label"
        errorKey={this.props.errorKeyLastName}
        value={this.props.lastName}
        onChange={this.props.onLastNameChange}
        autoFocus={this.props.autoFocusLastName}
        ref={this.lastNameRef}
        onFocus={this.handleLastNameFocus}
        onSubmitEditing={this.handleLastNameSubmit}
        testId="test.text.account.lastName"
      />
    ) : null;

    return (
      <Layout
        titleKey={this.props.titleKey || ''}
        titleValues={this.props.titleValues}
        leadKey={this.props.leadKey || ''}
        leadValues={{
          email: isWeb ? maskEmail(this.props.email) : this.props.email || '',
        }}
        confirmKey={this.props.confirmKey}
        onConfirm={this.props.onConfirm}
        imageName={this.props.imageName}
        disabled={this.props.disabled}
        scrollable={this.props.scrollable}
        loading={this.props.loading}
        scrollViewRef={this.setScrollViewRef}
        errorKey={this.props.errorKey}
        titleStyle={this.props.titleStyle}
        leadStyle={this.props.leadStyle}
        keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps}
        containerStyle={this.props.containerStyle}
        childContainerStyle={this.props.childContainerStyle}
        leadTextComponent={this.props.leadTextComponent}
        onInfoClick={this.props.onInfoClick}
        showProgress={this.props.showProgress}
        progress={this.props.progress}
        centered={this.props.centered}
        footerType={this.props.footerType}
        secondaryButton={this.props.secondaryButton}
        sensitiveDataModalExperimentVariant={this.props.sensitiveDataModalExperimentVariant}
      >
        {firstNameComponent}
        {lastNameComponent}
        {!this.props.hideEmail && emailAddressComponent}
        {this.props.onPhoneNumberChange && (
          <PhoneNumberInput
            countryCode={this.props.selectedCountry}
            ref={this.phoneNumberInputRef}
            phoneNumber={this.props.phoneNumber}
            onInputFocus={this.handlePhoneNumberFocus}
            onChange={this.props.onPhoneNumberChange}
            onPhoneNumberSubmit={this.handlePhoneNumberSubmit}
          />
        )}
        {this.props.onPinChange && (
          <PinInput
            labelKey={this.props.pinLabelKey}
            value={this.props.pin}
            onChange={this.props.onPinChange}
            ref={this.pinInputRef}
            onFocus={this.handlePinFocus}
            type={this.props.pinType}
            onForgot={this.props.onPinForgot}
            autoFocus={this.props.autoFocusPin}
            variant={this.props.pinVariant}
            loading={this.props.loading}
            testId="test.field.account.pin"
          />
        )}
        {this.props.children}
      </Layout>
    );
  }
}

export default CredentialScrollAware;
