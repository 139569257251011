import React from 'react';
import { View } from 'react-native';

import { Place as AddressAnswer } from '../../../../types/geo';

import SummaryText from './summary-text';

type AddressProps = {
  value: AddressAnswer;
  linkable?: boolean;
  color?: string;
};

function Address({ value, linkable, color }: AddressProps) {
  if (value == null || value.address == null) {
    return null;
  }

  const { street, number, numberExtension, addition, postalCode, city } = value.address;
  const additionText =
    addition != null ? (
      <SummaryText
        style={{
          textAlign: 'right',
        }}
        linkable={linkable}
        color={color}
      >
        {addition}
      </SummaryText>
    ) : null;
  return (
    <View>
      <SummaryText numberOfLines={1} ellipsizeMode="middle" linkable={linkable} color={color}>
        {street} {number}
        {numberExtension}
      </SummaryText>
      {additionText}
      <SummaryText
        numberOfLines={1}
        ellipsizeMode="middle"
        linkable={linkable}
        style={{
          textAlign: 'right',
        }}
        color={color}
      >
        {postalCode} {city}
      </SummaryText>
    </View>
  );
}

export default Address;
