import React, { ComponentType } from 'react';
import { View } from 'react-native';

import { DeviceId } from '../../../containers/device-id';
import Separator from '../../../components/separator';

const HomeDebugFooter: ComponentType = () => {
  return (
    <>
      <Separator />
      <View style={{ marginTop: 20 }}>
        <DeviceId />
      </View>
    </>
  );
};

export default HomeDebugFooter;
