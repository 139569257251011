export class FreelancersSurveyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FreelancersSurveyError';
  }
}

export const FreelancersSurveyErrorMessages = {
  parsingIFrameEventsError: 'Error while parsing the data from freelancer survey events',
};
