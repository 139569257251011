import * as React from 'react';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { useBreakpointValue } from 'native-base';

import { Text, TextLink, Container } from 'src/taxfix-components/src';
import { WithLogout, withLogout } from 'src/hocs/with-logout';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { useNavigation } from '../hooks/navigation-hook';
import { getNavigationActions, safeResetNavigation } from '../routes/config-util';
import { deleteAllAnswersIT } from '../stores-legacy';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { getDefaultLoginConfirmHandler } from '../screens/login/login-route';

export const LoginLink = (): JSX.Element => {
  const navigation = useNavigation();
  const { getTranslationText } = useItalyIntl();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const handleDefaultConfirm = getDefaultLoginConfirmHandler(navigation, selectedCountry);
  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const handlePress = async () => {
    // Delete local answers in order to avoid overrides
    deleteAllAnswersIT();
    navigation.safeResetNavigation([
      getNavigationActions().toLogin('screen', {
        onConfirm: handleDefaultConfirm,
      }),
    ]);
  };

  return (
    <Container flexDirection="row">
      {isWideScreen && (
        <Text
          flexWrap="nowrap"
          value={getTranslationText('onboarding.already-registered')}
          variant="bodyMBook"
          color="greytones.graphicsDark"
        />
      )}
      <TextLink
        mx={1}
        text={getTranslationText('login.title')}
        variant="linkM"
        onPress={handlePress}
      />
    </Container>
  );
};

type OnboardingLinkProps = WithLogout;

const OnboardingLinkUndecorated = ({ onLogout }: OnboardingLinkProps): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const isWideScreen = useBreakpointValue({ base: false, md: true });
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);

  const handlePress = () => {
    if (selectedCountry === CountryCodes.IT) {
      onLogout();
    } else {
      safeResetNavigation([getNavigationActions().toUniversalOnboarding('screen')]);
    }
  };

  return (
    <Container flexDirection={'row'}>
      {isWideScreen && (
        <Text
          flexWrap="nowrap"
          value={getTranslationText('it.header.not-registered')}
          variant="bodyMBook"
          color="greytones.graphicsDark"
        />
      )}
      <TextLink
        mx={1}
        text={getTranslationText('it.header.not-registered.button')}
        variant="linkM"
        onPress={handlePress}
      />
    </Container>
  );
};

export const OnboardingLink = withLogout(OnboardingLinkUndecorated as React.ComponentType);
