import React from 'react';
import { StyleSheet } from 'react-native';

import { OverlayContent } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';

type Props = {
  onClose: () => void;
  onCTAPress: () => void;
  title: string;
  description: string;
  buttonLabel: string;
};

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export const EndOfSeasonOverlay: React.FC<Props> = ({
  onCTAPress,
  title,
  description,
  buttonLabel,
}) => {
  return (
    <Overlay style={styles.overlay} appearFromTop>
      {() => (
        <OverlayContent
          handleOnClose={onCTAPress}
          handleMainButtonOnPress={onCTAPress}
          imageName="calendar30Sep"
          imageSize={{
            width: '180px',
            height: '150px',
          }}
          title={title}
          description={description}
          buttonTitle={buttonLabel}
          mainButtonTestId="eos-overlay-button"
        />
      )}
    </Overlay>
  );
};
