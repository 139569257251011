import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'native-base';
import { Documents } from '@taxfix/types';

import { Container, Text } from 'src/taxfix-components/src';

export enum StatusTagStates {
  prefilled = 'prefilled',
}

type StatusTagProps = {
  status: Documents.States | StatusTagStates;
  text?: string;
};

/**
 * This tag allows us to set the status of a document. The states
 * comes from types, and they are composed here with colors and
 * contents. The possible states are:
 *
 * * Created: no tag will be shown (delete icon instead)
 * * Approved: tag will say "Approved"/"Approvato"
 * * Rejected: tag will say "Not approved"/"Non approvato"
 * * Not considered: tag will say "Not approved"/"Non approvato"
 *
 * Any other not relevant state (Created, Deleted, or a new state) will
 * have a yellow tint and "default" error text
 *
 * @param status `Documents.States`
 */

const StatusTag: FC<StatusTagProps> = ({ status }) => {
  const theme = useTheme();
  const TagProperties = {
    [Documents.States.Created]: {
      color: theme.colors.yellow[500],
      backgroundColor: theme.colors.yellow[50],
      text: 'default',
    },
    [Documents.States.Approved]: {
      color: theme.colors.green[500],
      backgroundColor: theme.colors.green[50],
      text: 'approved',
    },
    [Documents.States.NotConsidered]: {
      color: theme.colors.red[500],
      backgroundColor: theme.colors.red[50],
      text: 'not-approved',
    },
    [Documents.States.Rejected]: {
      color: theme.colors.red[500],
      backgroundColor: theme.colors.red[50],
      text: 'not-approved',
    },
    // Not rendered states.
    [Documents.States.Deleted]: {
      color: theme.colors.yellow[500],
      backgroundColor: theme.colors.yellow[50],
      text: 'default',
    },
    [StatusTagStates.prefilled]: {
      color: theme.colors.blue[500],
      backgroundColor: theme.colors.blue[50],
      text: 'prefilled',
    },
  };
  let color, backgroundColor, text;
  // if a new document state is added, it won't break.
  // It will have the yellow color as default
  try {
    color = TagProperties[status].color;
    backgroundColor = TagProperties[status].backgroundColor;
    text = TagProperties[status].text;
  } catch (err) {
    // setting default values just in case
    color = theme.colors.yellow[500];
    backgroundColor = theme.colors.yellow[50];
    text = 'default';
  }

  const { formatMessage } = useIntl();
  return (
    <Container
      position="absolute"
      zIndex={10}
      top="8px"
      right="8px"
      borderRadius={8}
      backgroundColor={backgroundColor}
      paddingX={'8px'}
      paddingY={'2px'}
    >
      <Text
        borderRadius="6px"
        textTransform={'uppercase'}
        letterSpacing="1px"
        fontSize="10px"
        color={color}
        value={formatMessage({
          id: `it.digital-caf.required-documents.upload.document-list.status-${text}`,
        })}
      />
    </Container>
  );
};

export default StatusTag;
