import * as React from 'react';

import { ValidationResult } from './answers/shared/validators/types';

type Props<V> = {
  onFailure: (errors: ValidationResult['errors']) => any;
  onSuccess: (value: V) => any;
  validate: (value: V) => ValidationResult;
  value?: any;
  children: (arg0: { validate: (value: any) => any }) => React.ReactNode;
};

class AnswerValidator<V extends any> extends React.Component<Props<V>> {
  componentDidMount(): void {
    if (this.props.value) {
      this.validate(this.props.value);
    }
  }

  validate = (value: V): void => {
    const { isValid, errors } = this.props.validate(value);

    if (isValid) {
      this.props.onSuccess(value);
    } else {
      this.props.onFailure(errors);
    }
  };

  render(): React.ReactNode {
    const { validate } = this;
    return this.props.children({
      validate,
    });
  }
}

export default AnswerValidator;
