import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { logger, ErrorType } from 'src/taxfix-business-logic/utils/logger';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds, ProductBundleValues } from 'src/common/constants-it';

import { FullscreenLoader } from '../components/loading';
import { Payment, Product } from '../types/payment';
import { Box } from '../components/core';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import initial from '../stores/store/initial';
import { ProductResolver } from '../screens/payment/payment-route.common';

import { RetryableErrorBanner } from './retryable-error-banner';

type Props = {
  year: number;
  countryCode: CountryCodes;
  children: (arg0: { product?: Product }) => React.ReactNode;
  accessToken: string;
  nextNavigationAction: (params?: any) => void;
  productResolver: ProductResolver;
  quizmasterLight: QuizmasterLight;
};

const mapStateToProps = (state: typeof initial) => ({
  year: settingsSelectors.selectedYear(state),
  countryCode: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
});

type State = {
  product?: Product;
  payment?: Payment;
  showError?: boolean;
  isLoading: boolean;
};

class ProductFetch extends React.Component<Props, State> {
  state = {
    product: undefined,
    showError: false,
    isLoading: true,
  };

  componentDidMount() {
    this.getProduct();
  }

  isGuidedBundleProduct = () =>
    this.props.quizmasterLight[flagsQuestionIds.productBundleSelection].answer ===
    ProductBundleValues.guided;

  getProduct = async () => {
    const { year, countryCode, accessToken, productResolver, quizmasterLight } = this.props;
    const productVariation = quizmasterLight[flagsQuestionIds.productBundleSelection].answer;

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          if (!year) {
            throw new Error('cannot fetch product without year');
          }

          const product = await productResolver(accessToken, year, countryCode, productVariation);

          if (product) {
            this.setState({
              product,
              showError: false,
              isLoading: false,
            });
          } else {
            throw new Error('Product resolver not returning a product');
          }
        } catch (error) {
          logger.error(error as ErrorType, {
            message: 'Fetching product failed',
          });
          this.setState({
            showError: true,
            isLoading: false,
          });
        }
      },
    );
  };

  handleRetry = () => {
    this.getProduct();
  };

  render() {
    const { product, showError, isLoading } = this.state;
    return (
      <Box flex>
        {isLoading && <FullscreenLoader />}
        <RetryableErrorBanner onRetry={this.handleRetry} isVisible={showError} />
        {!(isLoading || showError) &&
          this.props.children({
            product,
          })}
      </Box>
    );
  }
}

export const ProductFetchContainer = compose<any>(
  connect(mapStateToProps),
  withQuizmasterLight([flagsQuestionIds.productBundleSelection]),
)(ProductFetch);
