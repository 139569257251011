import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { localeInfoShape } from './types';
import getLocaleInfo from './getLocaleInfo';

type Props = {
  children?: React.ReactNode;
  language: string;
  locale: string;
  intl: IntlShape;
}; // TODO: TS -Taxfix - how to inject intl via HOC?

class LocaleProvider extends React.Component<Props> {
  static childContextTypes = {
    localeInfo: localeInfoShape,
  };

  getChildContext() {
    const { intl, locale, language } = this.props;
    return {
      localeInfo: {
        // @ts-ignore
        language,
        // @ts-ignore
        locale,
        ...getLocaleInfo(locale, intl.formatNumber),
      },
    };
  }

  render() {
    // `Children.only` enables us not to add a <div /> for nothing
    return React.Children.only(this.props.children);
  }
}

export default injectIntl(LocaleProvider);
export { LocaleProvider as LocaleInfoProvider };
