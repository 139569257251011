import * as React from 'react';
import { View, Dimensions, TouchableOpacity, StyleSheet } from 'react-native';

import { isNative } from 'src/utils/platform';
import ScreenUtils from 'src/utils/screenSize';

import Image from '../Image';
import { theme } from '../theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    position: 'relative',
    height: 50,
    marginLeft: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    left: -15,
    paddingLeft: 15,
  },
});
const imageSourcePreix = 'category-icons.icon-category-questions-multiple-select';
const expandImage = `${imageSourcePreix}-expand`;
const collapseImage = `${imageSourcePreix}-collapse`;
const ICON_WIDTH = 30;
// accommodate for left & padding style of icon
const ICON_SPACE = ICON_WIDTH + 30;
const EXPAND_BTN_BREAKING_WIDTH = 700; /** A breakpoint at which the expand button position needs to be adjusted */

type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>;
  layoutChange: (layout: any) => void;
};

const ExpandContent = ({ children, layoutChange }: Props): JSX.Element => {
  const [isExpand, setIsExpand] = React.useState(false);
  const [textWidth, setTextWidth] = React.useState<number>();
  const { width: screenWidth } = Dimensions.get('window');
  let extraSpaceForNative: number;
  if (ScreenUtils.isScreenWidthLessThan(EXPAND_BTN_BREAKING_WIDTH))
    extraSpaceForNative = isNative ? -20 : 0;
  // view breaks on web small screens and has to be adjusted
  // according to screen width
  else extraSpaceForNative = isNative ? 30 : (40 * screenWidth) / 480;

  const getChildrenProps = (key: string | number): React.HTMLAttributes<unknown> => {
    const props = {
      style: {
        width: textWidth ?? 'auto',
        marginRight: 10,
      },
      key,
      numberOfLines: isExpand ? 0 : 1,
    };

    return props;
  };

  const calculateHeight = (layout: any): void => {
    layoutChange(layout);
  };

  const calculateViewWidth = (layout: any): void => {
    // Total width of string content, not truncated
    const totalWidthOfString = Math.trunc(layout.width);

    /** calculate the maximum available width for this component to occupy
     *  375 is the smallest screen we support, we need to increase the maximum width as screen grows
     */
    const maxWidth =
      screenWidth - layout.x - ICON_SPACE - extraSpaceForNative + (2 * screenWidth) / 375;

    if (totalWidthOfString > maxWidth) {
      setTextWidth(maxWidth);
    }
  };

  const handleContentLayout = ({ nativeEvent: { layout } }: any): void => {
    calculateViewWidth(layout);
    calculateHeight(layout);
  };

  const handleToggleExpand = (): void => {
    theme.easeInEaseOutOnNextRenderCycle();
    setIsExpand(!isExpand);
  };

  const renderChildren = (): React.ReactNode[] | null | undefined => {
    if (children) {
      const childrenArray = React.Children.toArray(children);
      return childrenArray.map((child, index) =>
        React.cloneElement(children, getChildrenProps(index as string | number)),
      );
    }

    return null;
  };

  return (
    <View onLayout={handleContentLayout} style={styles.container}>
      {renderChildren()}
      {textWidth && textWidth > 0 && (
        <TouchableOpacity onPress={handleToggleExpand} style={styles.button}>
          {/* @ts-ignore */}
          <Image width={ICON_WIDTH} name={isExpand ? collapseImage : expandImage} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ExpandContent;
