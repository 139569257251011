import { Box, FlatList } from 'native-base';
import React from 'react';

import { Props } from './types';

/**
 * Grid layout component, based on FlatList
 */

export const Grid: React.FC<Props> = ({
  data,
  columns,
  renderItem,
  testID,
  renderItemBoxProps,
  ...otherProps
}): JSX.Element => (
  <FlatList
    data={data}
    testID={testID}
    numColumns={columns}
    renderItem={({ item, index }): React.ReactElement => (
      <Box flexGrow={1} flexBasis={0} {...renderItemBoxProps}>
        {renderItem({ item, index })}
      </Box>
    )}
    {...otherProps}
  />
);
