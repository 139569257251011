import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { ScrollView as NBScrollView, useBreakpointValue, useTheme } from 'native-base';
import HTML from 'react-native-render-html';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, icons } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { theme } from 'src/components/core';
import Overlay from 'src/components/overlay';
import { actions as overlayActions } from 'src/stores/modules/overlay';

import { isIOS } from '../utils/platform';
import { selectors as firebaseSelectors } from '../stores/modules/remote-config-firebase';

type Props = {
  onClose(): void;
};

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
});

export const ResultOptimizationInfoOverlay = ({ onClose: onCloseComplete }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();
  const { goodToKnow, curiosities } = useSelector(firebaseSelectors.getItalyTaxGuideArticles);

  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const paddingLeftScrollView = useBreakpointValue({ base: 20, md: 64 });
  const paddingRightScrollView = useBreakpointValue({ base: 20, md: 44 });
  const paddingBottomScrollView = useBreakpointValue({ base: 24, md: 64 });

  const contentFontStyle = { fontSize: 16, lineHeight: 20 };

  const htmlStyles = {
    h1: {
      fontFamily: 'CircularStd-Bold',
      fontSize: 20,
      lineHeight: 24,
      color: colors.greytones.textDefault,
      marginTop: 20,
      marginBottom: 20,
    },
    p: {
      ...contentFontStyle,
      fontFamily: 'CircularStd-Book',
      color: colors.greytones.title,
      marginBottom: 10,
      padding: 0,
    },
    b: {
      fontFamily: 'CircularStd-Bold',
    },
    a: {
      ...contentFontStyle,
      fontFamily: 'CircularStd-Bold',
      color: colors.brand.brandGreen,
      textDecoration: 'none',
      textDecorationLine: 'none',
    },
  };

  const articlesMap: Record<string, string> = {
    bonus100euro: curiosities.bonus100euro,
    precompilato730: curiosities.musicBonus,
    howToReadTaxReturn: goodToKnow.howToReadTaxReturn,
  };

  const handleLinkPress = (url: string) => {
    const articleUrl = articlesMap[url];
    if (isIOS) {
      Linking.openURL(articleUrl);
    } else {
      dispatch(
        overlayActions.show('TaxGuideOverlay', {
          url: articleUrl,
          onClose: () => dispatch(overlayActions.hide()),
          openLinkExternally: () => Linking.openURL(articleUrl),
        }),
      );
    }
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isLargeScreen ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isLargeScreen ? 'right' : 'bottom'}
    >
      {({ onClose }: { onClose: () => void }) => (
        <>
          <IconButton
            color={colors.brand.brandGreen}
            padding="30px"
            alignSelf="flex-start"
            path={icons.close}
            onPress={onClose}
            size="18px"
          />

          <NBScrollView
            height="100%"
            paddingRight={paddingLeftScrollView}
            paddingLeft={paddingRightScrollView}
            paddingBottom={paddingBottomScrollView}
            display="flex"
          >
            <View onStartShouldSetResponder={() => true}>
              <HTML
                source={{
                  html: getTranslationText('it.result-optimization-screen.info-overlay.content'),
                }}
                tagsStyles={htmlStyles}
                onLinkPress={(event, href) => {
                  handleLinkPress(href);
                }}
              />
            </View>
          </NBScrollView>
        </>
      )}
    </Overlay>
  );
};
