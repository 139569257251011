import { User } from '@taxfix/taxfix-sdk';
import { useBreakpointValue } from 'native-base';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Config from 'react-native-config';
import { useDispatch, useSelector } from 'react-redux';

import { NavButton } from 'src/taxfix-components/src';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ButtonType } from 'src/components/alert-overlay';
import ErrorMessage from 'src/components/auth/ErrorMessage';
import {
  BodyPrimaryText,
  Box,
  Button,
  DisplayTitleText,
  Section,
  theme,
} from 'src/components/core';
import HtmlContent from 'src/components/html-content';
import NotificationBanner from 'src/components/notification-banner';
import { useLogout } from 'src/hooks/logout-hook';
import { navigationActions } from 'src/routes/intl/config-common';
import { parseMarkdown } from 'src/services/markdown-parser';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { ScreenName } from 'src/types/screen-name';

import { useItalyIntl } from '../../_italy/_hooks/use-italy-intl';

const styles = StyleSheet.create({
  section: {
    height: '100%',
  },
  closeAccountDescriptionContent: { minHeight: 110 },
  closeAccountDescriptionSecondContent: { minHeight: 250 },
  closeAccountDescriptionContentLargeScreen: { minHeight: 180 },
  buttonSmallScreen: {
    width: '100%',
  },
  buttonLargeScreen: {
    minWidth: 200,
  },
});

const contentStyles = `
<style type="text/css">
* {
  font-size: 16px;
  }
#content {
  margin: 0px;
}
ul {
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
}
ul ul {
  list-style-type: disc;
}
li{
  margin-bottom: 5px;
}
</style>
`;

export const CloseAccount = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<string>();
  const submittedSubmissions = useSelector(submissionSelectors.getSubmittedSubmissions);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userHasSubmitted = submittedSubmissions.length > 0;
  const { getTranslationText } = useItalyIntl();
  const dispatch = useDispatch();
  const logout = useLogout();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const backNavigationText = getTranslationText('account.title');

  const handleCloseAccount = async () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ItalyCloseAccount,
      buttonName: 'closeAccount',
    });
    setLoading(true);
    dispatch(overlayActions.hide());
    try {
      await User.deleteR2BF(Config.API_BASE_URL, accessToken);
      Analytics.log(AnalyticsEvent.accountClosed);
      logout({
        toastConfig: {
          titleId: 'it.account.close-account.success',
          iconName: 'attention',
          tintColor: theme.color.primaryText,
          iconColor: theme.color.darkBlueIcon,
          bgColor: theme.color.secondaryBackgroundLight,
        },
        isAccountClosure: true,
      });
    } catch (error) {
      setLoading(false);
      setErrorKey('it.account.close-account.verification.fail');
    }
  };

  const showCloseAccountConfirmationPopup = () => {
    Analytics.log(AnalyticsEvent.accountClosureStepShown, {
      stepName: 'closure',
    });
    setErrorKey(undefined);
    dispatch(
      overlayActions.show('AlertOverlay', {
        titleTranslationKey: 'it.account.close-account.confirmation.title',
        leadTranslationKey: 'it.account.close-account.confirmation.lead',
        buttons: [
          {
            translationKey: 'it.account.close-account.confirmation.close',
            type: ButtonType.warning,
            onPress: () => handleCloseAccount(),
            style: {
              backgroundColor: theme.color.pinkFull,
            },
          },
          {
            translationKey: 'it.account.close-account.confirmation.cancel',
            type: ButtonType.secondary,
            onPress: () => {
              Analytics.log(AnalyticsEvent.buttonPressed, {
                screenName: ScreenName.ItalyCloseAccount,
                buttonName: 'cancel',
              });
              dispatch(overlayActions.hide());
              setLoading(false);
            },
            style: {
              color: theme.color.pinkFull,
            },
            disableCloseOnPress: true,
          },
        ],
        appearFromTop: true,
      }),
    );
  };

  return (
    <>
      {errorKey && (
        <NotificationBanner errorType="Error">
          <ErrorMessage
            errorKey={errorKey}
            style={
              {
                color: 'white',
              } as any
            }
          />
        </NotificationBanner>
      )}
      {!isSmallScreen && (
        <Box bottom={2}>
          <NavButton onPress={navigationActions.back} text={backNavigationText} />
        </Box>
      )}
      <Box alignHorizontally="center" flex left={2} right={2}>
        <Section size="wide" style={styles.section}>
          <Box flex style={{ width: '100%' }}>
            <Box bottom={1.5} top={2}>
              <DisplayTitleText id="it.account.close-account.information.title" />
            </Box>
            <Box bottom={1} style={{ minHeight: 60 }}>
              <HtmlContent
                scrollEnabled={false}
                contentStyles={contentStyles}
                content={String(
                  parseMarkdown(
                    getTranslationText('it.account.close-account.information.first-body'),
                  ),
                )}
              />
            </Box>
            <Box
              left={1.5}
              style={
                isSmallScreen
                  ? styles.closeAccountDescriptionContent
                  : styles.closeAccountDescriptionContentLargeScreen
              }
            >
              <HtmlContent
                contentStyles={contentStyles}
                scrollEnabled={false}
                content={String(
                  parseMarkdown(
                    getTranslationText('it.account.close-account.information.description'),
                  ),
                )}
              />
            </Box>
            <Box top={1}>
              <BodyPrimaryText id={'it.account.close-account.information.second-body'} />
            </Box>
            <Box
              top={1}
              left={1.5}
              style={
                isSmallScreen
                  ? styles.closeAccountDescriptionSecondContent
                  : styles.closeAccountDescriptionContentLargeScreen
              }
            >
              <HtmlContent
                contentStyles={contentStyles}
                scrollEnabled={false}
                content={String(
                  parseMarkdown(
                    getTranslationText('it.account.close-account.information.second-description'),
                  ),
                )}
              />
              {userHasSubmitted && (
                <HtmlContent
                  contentStyles={contentStyles}
                  scrollEnabled={false}
                  content={String(
                    parseMarkdown(
                      getTranslationText('it.account.close-account.information.third-description'),
                    ),
                  )}
                />
              )}
            </Box>
          </Box>
          <Box
            bottom={2}
            style={isSmallScreen ? styles.buttonSmallScreen : styles.buttonLargeScreen}
          >
            <Button
              fillColor={theme.color.pinkFull}
              translationKey="it.account.close-account.information.proceed-closure"
              floating
              loading={loading}
              testId="it.account.close-account.information.proceed-closure"
              onPress={() => showCloseAccountConfirmationPopup()}
            />
          </Box>
        </Section>
      </Box>
    </>
  );
};
