import React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../hooks/navigation-options-hook';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ItalyDigiCafProfilePersonalData } from '../../../screens/containers/italy-digicaf-profile-personal-data';
import { ItalyWAYSidebar } from '../../../containers/sidebar/italy-way-sidebar';

type ActionParams = {
  onNext: () => void;
  progress: number;
  titleTranslationKey?: string;
  isNewUser: boolean;
  backButtonText?: string;
};
type Props = Navigation<
  'ProfilePersonalData',
  {
    ProfilePersonalData: NavigationParams<ActionParams>;
  }
>;
export const navigateTo: Action<ActionParams> = navigate('ProfilePersonalData', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ProfilePersonalData',
  },
});

const Sidebar = () => <ItalyWAYSidebar step="personal-information" />;

export const ProfilePersonalData = ({ route }: Props): JSX.Element => {
  const {
    onNext,
    progress,
    titleTranslationKey = 'it.digital-caf.qf.header-title',
    isNewUser = true,
    backButtonText,
  } = route.params;

  useWebNavigationOptions({
    sidebar: isNewUser ? Sidebar : undefined,
  });

  useDefaultNavigationOptions(
    { id: titleTranslationKey },
    {
      headerBackTitle: '',
    },
  );

  return (
    <ItalyDigiCafProfilePersonalData
      onNext={onNext}
      progress={progress}
      isNewUser={isNewUser}
      backButtonText={backButtonText}
    />
  );
};
