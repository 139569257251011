import { useCallback, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { CountryCodes, Documents } from '@taxfix/types';

import { flagsQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { NavigateToNext } from 'src/screens/containers/prefill/with-prefill-route';
import { uploadDocument } from 'src/services/camera-upload-documents';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import { selectors as prefillSelectors } from 'src/stores/modules/prefill';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { ErrorType, logger } from 'src/taxfix-business-logic/utils/logger';
import { PrefillStates } from 'src/types/prefills';
import { QuizmasterLight } from 'src/utils/with-quizmaster-light';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { NativeFileResponse, OnFilesRejected, OnFilesSelected } from '../id-document-upload/types';
import { isNativeFile } from '../id-document-upload/utils';
import { getErrorMessageKey, getUniqueErrorCodes } from '../_utils/files-upload';

const convertFileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type Params = {
  overlayActions: typeof OverlayActions;
  quizmasterLight?: QuizmasterLight;
  navigateToNext: NavigateToNext;
  createPrefill(): Promise<void>;
  onNext?(): void;
};

type UseFilesUpload = (params: Params) => {
  files: string[];
  errorMessage: string | null;
  isLoading: boolean;
  onFilesSelected: OnFilesSelected;
  onFilesRejected: OnFilesRejected;
  onUploadToServer(uri: string): Promise<void>;
  resetFiles(): void;
};

export const useFilesUpload: UseFilesUpload = ({
  overlayActions,
  quizmasterLight,
  navigateToNext,
  createPrefill,
  onNext,
}) => {
  const { safeResetNavigation, navigationActions } = useNavigation();

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [files, setFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;
  const accessToken = useSelector(userAuthSelectors.getAccessToken) as string;
  const prefillStatus = useSelector(prefillSelectors.getStatus) as string;

  const onFilesSelected: OnFilesSelected = useCallback(
    (response) => {
      // we assume that if the first file is native, the rest is also native
      if (isNativeFile(response[0])) {
        const uris = response
          .filter((it: NativeFileResponse) => Boolean(it.uri))
          .map((it: NativeFileResponse) => it.uri as string);
        // if a file is selected reset error to hide error message
        setErrorMessage(null);
        setFiles(uris);
        overlayActions.hide();
      } else {
        Promise.all(
          response.map((it) => {
            return convertFileToBase64(it as File);
          }),
        )
          .then((base64Values) => {
            setFiles(base64Values);
            // if a file is selected reset error to hide error message
            setErrorMessage(null);
          })
          .catch((e) => {
            setErrorMessage('identity-upload.errors.generic-error');
            // TODO fix logger error message
            logger.error(e);
          });
      }
    },
    [overlayActions],
  );

  const onFilesRejected = useCallback((rejections: FileRejection[]) => {
    // I am taking only the first error code because:
    // - current error messaging code doesn't support multiple messages at once
    // - from my analysis of error codes on Metabase I've never seen a case
    //   where 2 error codes happened at once for one user
    const errorMessageKey = getErrorMessageKey(rejections[0]?.errors[0]?.code);
    setErrorMessage(errorMessageKey);

    const uniqueRejectionCodes = getUniqueErrorCodes(rejections);
    Analytics.log(AnalyticsEvent.filesRejectionReasons, {
      codes: uniqueRejectionCodes,
    });
  }, []);

  const navigateAfterIdentityUpload = async (): Promise<void> => {
    if (typeof onNext === 'function') {
      onNext();
    } else {
      await navigateToNext(selectedYear, true);
    }
  };

  const onUploadToServer = async (uri: string) => {
    try {
      setErrorMessage(null);
      setIsLoading(true);

      overlayActions.show('UploadOverlay', {
        titleId: 'upload.progress.overlay.title',
      });

      // TODO: test this case and remove if it's not needed
      // if prefill already exists but user is still at the ID upload screen
      // we navigate next skipping another document and prefill upload
      if (prefillStatus && prefillStatus !== PrefillStates.Rejected) {
        if (typeof onNext === 'function') {
          onNext();
        } else {
          safeResetNavigation([navigationActions.toStatus('screen')]);
        }
      }

      await uploadDocument(
        Documents.NonReceiptTypes.Id,
        uri,
        accessToken,
        selectedYear,
        CountryCodes.IT,
        null,
        false,
      );

      // Create pre-fill (only with ID document as of https://taxfix.atlassian.net/browse/ITA-642)
      await createPrefill();

      if (quizmasterLight) {
        // For test cases that use undecorated shallow instance, quizmaster will not be available
        quizmasterLight[flagsQuestionIds.prefillUploadSkipped].saveAnswer(false);
      }

      setIsLoading(false);
      await navigateAfterIdentityUpload();
      setFiles([]);
      overlayActions.hide();
      Analytics.log(AnalyticsEvent.docUploadSuccess);
    } catch (error) {
      setIsLoading(false);
      Analytics.log(AnalyticsEvent.docUploadFailed, {
        error: (error as Error).message,
      });
      setErrorMessage('identity-upload.errors.upload-error');
      overlayActions.hide();
      logger.error(error as ErrorType, {
        message: 'Error while uploading ID',
      });
    }
  };

  const resetFiles = () => setFiles([]);

  return {
    files,
    errorMessage,
    isLoading,
    onFilesSelected,
    onFilesRejected,
    onUploadToServer,
    resetFiles,
  };
};
