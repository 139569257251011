import { Buffer } from 'buffer';

import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import Config from 'react-native-config';
import { DocumentsAPI, DocumentsTypes } from '@taxfix/documents-sdk';
import { CountryCodes } from '@taxfix/types';
import { SubmissionReviewStatusIT } from '@taxfix/submissions-types';
import { CertifiedStorage } from '@taxfix/italy-sdk';

import { useNavigation } from 'src/hooks/navigation-hook';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { getQuestionStores } from 'src/stores-legacy/util';
import { prefillQuestionIds, wayQuestionIds } from 'src/common/constants-it';
import { getDefaultTaxYear } from 'src/services/country-and-year/utils';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';

type Params = {
  submissionId: string;
};

const useFetch = (): {
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  document: string;
  onSign: () => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [document, setDocument] = useState<string>('');
  const [xmlDocumentId, setXmlDocumentId] = useState<number>();
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId) as number;
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const { getNavigationActions, safeResetNavigation } = useNavigation();

  const questionStores = getQuestionStores(CountryCodes.IT);
  const questionStore = questionStores[selectedYear];
  const questionIds: string[] = Object.values({ ...wayQuestionIds, ...prefillQuestionIds });
  const nodes = getNodesByIds(questionStore, questionIds);
  const quizmasterLight = getQuizmasterLight(questionStore, nodes);
  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const taxId = quizmasterLight[prefillQuestionIds.taxId]?.answer;

  const route = useRoute();
  const { submissionId } = route.params as Params;

  useEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);
      try {
        const selfSignDocs = await DocumentsAPI.v1.get(Config.API_BASE_URL, accessToken, {
          userId,
          types: [
            DocumentsTypes.v1.Documents.NonReceiptTypes.SignSelfCertificatePdf,
            DocumentsTypes.v1.Documents.NonReceiptTypes.SignSelfCertificateXml,
          ],
          year: selectedYear,
        });

        setXmlDocumentId(
          selfSignDocs?.data?.find(
            (doc) =>
              doc.type === DocumentsTypes.v1.Documents.NonReceiptTypes.SignSelfCertificateXml,
          )?.id,
        );
        const response = await DocumentsAPI.v1.getOne(Config.API_BASE_URL, accessToken, {
          id: Number(
            selfSignDocs?.data?.find(
              (doc) =>
                doc.type === DocumentsTypes.v1.Documents.NonReceiptTypes.SignSelfCertificatePdf,
            )?.id,
          ),
          getFileContents: true,
          acceptHeader: 'application/pdf',
        });

        const fileRaw = response.data as ArrayBuffer;
        const fileData = new Uint8Array(fileRaw);
        const fileBase64 = Buffer.from(fileData).toString('base64');

        setDocument(`data:application/pdf;base64,${fileBase64}`);
        setIsLoading(false);
      } catch (e) {
        logger.error(e as Error, { userId });
      }
    };
    fetchDocument();
  }, [accessToken, userId]);

  const onSign = async () => {
    try {
      Analytics.log(AnalyticsEvent.buttonPressed, {
        buttonName: 'SelfSignButton',
        screenName: ScreenName.SelfSignDocument,
      });

      await CertifiedStorage.selfSignCertificate(Config.API_BASE_URL, accessToken, {
        documentId: `${xmlDocumentId}`,
        submissionId,
        userData: {
          firstName,
          lastName,
          taxId,
          userId,
        },
      });

      Analytics.log(AnalyticsEvent.reviewStatusChanged, {
        submissionReviewStatus: SubmissionReviewStatusIT.ReadyToSubmit,
        agentId: null,
        submissionId,
      });

      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    } catch (e) {
      setIsError(true);
      logger.error(e as Error, { xmlDocumentId });
    }
  };

  return { isError, setIsError, isLoading, document, onSign };
};

export { useFetch };
