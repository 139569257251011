import * as React from 'react';
import { Keyboard } from 'react-native';
import { IBoxProps, useBreakpointValue } from 'native-base';

import { Container } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import Footer from './Footer';

type Props = {
  disabled?: boolean;
  noGutters?: boolean;
  fullscreen?: boolean;
  onConfirm: () => void;
} & IBoxProps;

const AnswerForm: React.FC<Props> = ({
  onConfirm,
  disabled = false,
  noGutters = false,
  fullscreen = false,
  children,
  ...props
}) => {
  const { getTranslationText } = useItalyIntl();
  const [keyboardOpen, setKeyboardOpen] = React.useState(false);

  React.useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardOpen(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardOpen(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const footer = {
    disabled,
    label: getTranslationText('answers.submit'),
    onPress: onConfirm,
  };

  const gutters = {
    paddingX: useBreakpointValue({ base: 1, md: '0px' }),
    marginBottom: 1,
  };

  return (
    <Container>
      <Container
        {...(!noGutters && gutters)}
        {...(!fullscreen && { maxHeight: '300px' })}
        {...props}
      >
        {children}
      </Container>
      {!keyboardOpen && <Footer {...footer} />}
    </Container>
  );
};

export default AnswerForm;
