import { $Values } from 'utility-types';

export const states = Object.freeze({
  Initial: 'INITIAL',
  Loading: 'LOADING',
  Editing: 'EDITING',
  Success: 'SUCCESS',
  Error: 'ERROR',
});
export enum ErrorKeys {
  InvalidArgument = 'account.request-email-change.errors.invalid-email',
  Conflict = 'account.request-email-change.errors.same-as-old-email',
  UnprocessableEntity = 'account.request-email-change.errors.email-already-in-use',
  Unauthorized = 'account.request-email-change.errors.invalid-credentials',
  Unknown = 'account.request-email-change.errors.unknown',
  EmailAddressInUse = 'EmailAddressInUse',
  EmailAddressInvalid = 'EmailAddressInvalid',
  NotFound = 'account.request-email-change.errors.sso-account-exist',
}
export type EmailChangeStates = $Values<typeof states>;

// actions
export type EditPayload = {
  field: 'email' | 'pwd';
  value: string;
};
export type ErrorPayload = {
  errorKey: ErrorKeys;
};
type StartEditingAction = {
  type: 'START_EDITING';
  payload: EditPayload;
};
type MarkInvalidAction = {
  type: 'MARK_INVALID';
  payload: ErrorPayload;
};
type SubmitAction = {
  type: 'SUBMIT';
};
type SubmitSuccessAction = {
  type: 'SUBMIT_SUCCESS';
};
type SubmitFailAction = {
  type: 'SUBMIT_FAIL';
  payload: ErrorPayload;
};
export type Actions =
  | StartEditingAction
  | MarkInvalidAction
  | SubmitAction
  | SubmitSuccessAction
  | SubmitFailAction;
export const actions = {
  startEditing: (payload: EditPayload): StartEditingAction => ({
    type: 'START_EDITING',
    payload,
  }),
  markInvalid: (error: ErrorPayload): MarkInvalidAction => ({
    type: 'MARK_INVALID',
    payload: error,
  }),
  submit: (): SubmitAction => ({
    type: 'SUBMIT',
  }),
  submitSuccess: (): SubmitSuccessAction => ({
    type: 'SUBMIT_SUCCESS',
  }),
  submitFail: (error: ErrorPayload): SubmitFailAction => ({
    type: 'SUBMIT_FAIL',
    payload: error,
  }),
};
// state
export type State = {
  status: EmailChangeStates;
  email: string;
  pwd: string;
  errorKey?: ErrorKeys | null;
};
export const initialState: State = {
  status: states.Initial,
  email: '',
  pwd: '',
  errorKey: null,
};
// --------
export const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case 'START_EDITING': {
      const { field, value } = action.payload;
      return { ...state, status: states.Editing, [field]: value, errorKey: null };
    }

    case 'SUBMIT':
      return { ...state, status: states.Loading };

    case 'SUBMIT_SUCCESS':
      return { ...state, status: states.Success };

    case 'MARK_INVALID':
    case 'SUBMIT_FAIL':
      return { ...state, status: states.Error, errorKey: action.payload.errorKey };

    default:
      return state;
  }
};
