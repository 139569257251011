import React from 'react';
import { Documents } from '@taxfix/taxfix-sdk';

import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { PrefillDocumentsResultListContainer } from 'src/_italy/prefill-documents-result/documents-list/prefill-documents-result-list-container';
import { ScreenName } from 'src/types/screen-name';

type ActionParams = {
  prefillDocumentsList: Documents.types.Document[];
  resultScreenExperiment?: boolean;
};

type Props = Navigation<
  ScreenName.PrefillDocumentsResultList,
  {
    PrefillDocumentsResultList: NavigationParams<ActionParams>;
  }
>;

export const toPrefillDocumentsResultList: Action<ActionParams> = navigate(
  ScreenName.PrefillDocumentsResultList,
  {
    name: AnalyticsEvent.sectionShown,
    params: {
      sectionName: ScreenName.PrefillDocumentsResultList,
    },
  },
);

export const PrefillDocumentsResultListRoute: React.FC<Props> = ({ route }) => {
  const { prefillDocumentsList, resultScreenExperiment } = route.params;

  useDefaultNavigationOptions({ id: 'it.prefill-documents-list-screen.header' });

  return (
    <PrefillDocumentsResultListContainer
      prefillDocumentsList={prefillDocumentsList}
      resultScreenExperiment={resultScreenExperiment}
    />
  );
};
