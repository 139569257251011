export type ValidationError = {
  isValid: boolean;
  validationErrors?: string;
};

export type NameFieldErrorKey = {
  key: string;
  testId: string;
};

export const getNameFieldErrorKeys = (isEmpty: boolean, fieldLabel: string): NameFieldErrorKey => {
  const errorKey = isEmpty ? 'it.personal-data.field-required' : 'it.personal-data.field-error';
  return {
    key: errorKey,
    testId: `${errorKey}.${fieldLabel}`,
  };
};

export const isEmptyInput = (input: string | ''): boolean => {
  return input.trim().length === 0;
};

// exchanges typically used italian curly apostrophe (U+02BC and U+2019) and cleans double-spacing
export const getSanitizedInput = (name: string | null | undefined): string => {
  if (!name) return '';
  const nameSanitized = name.replace(/[ʼ’]/g, "'").replace(/\s+/g, ' ');
  return nameSanitized;
};

// allows ONLY BasicLatin + LatinSupplement char set + apostrophe, excluding: all special char sets, numbers
export const nameValidator = (name: string): ValidationError => {
  const nameTestFailures = name.match(
    // eslint-disable-next-line no-control-regex
    /([^\x00-\xFF]|[\u00A1-\u00BF]|\u00D7|\u00F7|[\u0021-\u0026]|[\u0028-\u0040]|[\u005B-\u0060]|[\u007B-\u007E])/g,
  );

  return nameTestFailures
    ? { isValid: false, validationErrors: nameTestFailures.join(' ') }
    : { isValid: true };
};

// allows ONLY BasicLatin + LatinSupplement char set + 0-9 + "',.-/", excluding: all other special char sets
export const validateAddressField = (address: string): ValidationError => {
  const addressTestFailures = address.match(
    // eslint-disable-next-line no-control-regex
    /([^\x00-\xFF]|[\u00A1-\u00BF]|\u00D7|\u00F7|[\u0021-\u0026]|[\u0028-\u002B]|[\u003A-\u0040]|[\u005B-\u0060]|[\u007B-\u007E])/g,
  );
  return addressTestFailures
    ? { isValid: false, validationErrors: addressTestFailures.join(' ') }
    : { isValid: true };
};

// allows ONLY BasicLatin + LatinSupplement char set + "',.-", excluding: all other special char sets, numbers
export const validateCityField = (city: string): ValidationError => {
  const cityTestFailures = city.match(
    // eslint-disable-next-line no-control-regex
    /([^\x00-\xFF]|[\u00A1-\u00BF]|\u00D7|\u00F7|[\u0021-\u0026]|[\u0028-\u002B]|[\u002F-\u0040]|[\u005B-\u0060]|[\u007B-\u007E])/g,
  );
  return cityTestFailures
    ? { isValid: false, validationErrors: cityTestFailures.join(' ') }
    : { isValid: true };
};

export const validatePostalCodeField = (postalCode: string): ValidationError => {
  const invalidChars = postalCode.match(/[^0-9]/g);

  if (invalidChars) {
    return { isValid: false, validationErrors: invalidChars.join(' ') };
  }
  if (postalCode.length !== 5) {
    return { isValid: false };
  }
  return { isValid: true };
};
