import { Colors } from './colorsTypes';

const parseColor = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const rgb = {
      R: parseInt(result[1], 16),
      G: parseInt(result[2], 16),
      B: parseInt(result[3], 16),
    };
    return rgb;
  } else {
    throw new Error('Invalid hex');
  }
};

export const colors: Colors = {
  brand: {
    brandGreen: '#32C850',
    darkGreen: '#006246',
    transparentBackground: 'rgba(50, 200, 80, 0.8)',
  },
  secondaryFunctional: {
    partnerBlue: '#6995FF',
    partnerBlueDark: '#1E3383',
  },
  secondaryCategory: {
    aquaFull: '#00CBF4',
    aquaHalf: '#CCF5FD',
    goldFull: '#FFD300',
    goldHalf: '#FFF6CC',
    limeFull: '#7EF54A',
    limeHalf: '#E5FDDB',
    orangeFull: '#F9A646',
    orangeHalf: '#FEEDDA',
    pinkFull: '#FF4773',
    pinkHalf: '#FFDAE3',
    purpleFull: '#935FC1',
    purpleHalf: '#EBE2F4',
    salmonFull: '#FF9497',
    salmonHalf: '#FFEAEA',
    seagreenFull: '#00C769',
    seagreenHalf: '#CCF4E1',
    skyFull: '#059AFD',
    skyHalf: '#CDEBFF',
    blueBackground: '#AFDFFF',
    greenBackground: '#C6F1D0',
  },
  secondaryFeedback: {
    attentionOrange: '#FF9B00',
    errorRed: '#FF5B23',
    errorRedDark: '#871200',
    greenDark: '#115E21',
    greenLight: '#E1F8E6',
  },
  greytones: {
    background: '#F6F6F6',
    backgroundDark: '#222222',
    backgroundLight: '#FAF7FA',
    graphicsDark: '#949494',
    graphicsLight: '#E1E1E1',
    greyBlue: '#F6F7FA',
    textDefault: '#3B3B3B',
    textLight: '#707070',
    title: '#111111',
    white: '#FFFFFF',
    surface: '#F6F3F6',
  },

  transparencyDark: {
    dark80: 'rgba(59,59,59,0.8)',
    dark55: 'rgba(59,59,59,0.55)',
    dark30: 'rgba(59,59,59,0.3)',
    dark16: 'rgba(59,59,59,0.16)',
    dark08: 'rgba(59,59,59,0.08)',
    dark04: 'rgba(59,59,59,0.04)',
  },

  transparencyLight: {
    light80: 'rgba(255,255,255, 0.8)',
    light55: 'rgba(255,255,255, 0.55)',
    light30: 'rgba(255,255,255, 0.3)',
    light16: 'rgba(255,255,255, 0.16)',
    light08: 'rgba(255,255,255, 0.08)',
    light04: 'rgba(255,255,255, 0.04)',
  },

  overlay: (color, transparencyPercent) => {
    try {
      const rgb = parseColor(color);
      const opacity = (transparencyPercent / 100).toFixed(1);
      return `rgba(${rgb.R}, ${rgb.G}, ${rgb.B}, ${opacity})`;
    } catch (err) {
      return color;
    }
  },
};
