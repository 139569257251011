type LogErrorMessages = {
  donationOptionsFetchError: string;
  donationDocError: string;
  submissionConsentDocError: string;
  amlConsentDocError: string;
  spidConsentDocError: string;
  submissionError: string;
  resubmissionError: string;
  idDocFetchError: string;
  errorBoundaryCatchError: string;
  cwbiFolderRequestError: string;
  cwbiPollFolderStatusError: string;
  prefillDocumentsRequestError: string;
};

export const logErrorMessages: LogErrorMessages = {
  donationOptionsFetchError: 'Could not fetch donation preferences',
  donationDocError: 'Could not create donation document',
  submissionConsentDocError: 'Could not create submission consent document',
  amlConsentDocError: 'Could not create aml consent document',
  spidConsentDocError: 'Could not create SPID consent document',
  submissionError: 'Error while submitting declaration',
  resubmissionError: 'Error while resubmitting declaration',
  idDocFetchError: 'Identification document could not be retrieved',
  errorBoundaryCatchError: 'Error boundary: the app crashed',
  cwbiFolderRequestError: 'Error while fetching CWBI folder request',
  cwbiPollFolderStatusError: 'Error while fetching CWBI poll folder status',
  prefillDocumentsRequestError: 'Error while fetching prefill documents',
};
