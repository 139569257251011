import React from 'react';
import { Linking, Platform } from 'react-native';
import Config from 'react-native-config';

import { getNaiveRoute } from 'src/routes/naive-navigate';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

import { AppBlockingScreen } from './app-blocking-screen';

const navigateToAppStore = () => {
  const url =
    Platform.OS === 'android'
      ? `market://details?id=${Config.GOOGLE_PLAY_ID}`
      : `https://apps.apple.com/app/id${Config.APP_STORE_ID}`;
  Analytics.log(AnalyticsEvent.forceUpdateCTAPressed);
  Linking.openURL(url);
};

export const ForceUpdateScreen: React.FC = () => {
  return (
    <AppBlockingScreen
      image="error.error-no-connection"
      titleTextId="force-update.header"
      bodyTextId="force-update.body"
      buttonText="force-update.button"
      onPress={navigateToAppStore}
    />
  );
};

const routeName = 'ForceUpdateScreen';

export default getNaiveRoute({
  routeName,
  parentTab: 'auth',
  screenComponent: ForceUpdateScreen,
  screenOptions: {
    title: '',
    headerTransparent: true,
  },
  screenNavigationOptions: {
    isPublic: true,
  },
});
