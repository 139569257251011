import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Box, SmallSecondaryText, theme } from '../core';
import { StyleProp } from '../types';

import { CheckboxIcon } from './checkbox-icon';

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  withDarkText?: boolean;
  translationKey: string;
  onChange: (confirmed: boolean) => void;
  testId?: string;
  style?: StyleProp;
};
const styles = StyleSheet.create({
  row: {
    flex: 1,
  },
  rowVariant: {
    minHeight: 50,
    justifyContent: 'center',
  },
  text: {
    flex: 1,
    marginTop: 3,
  },
  textVariant: {
    flex: 1,
    marginTop: 3,
    marginLeft: 5,
    color: theme.color.primaryText,
  },
});
export const CheckboxRow = ({
  isChecked,
  isDisabled = false,
  translationKey,
  onChange,
  testId,
  withDarkText = false,
  style = {},
}: Props): JSX.Element => {
  const handlePress = () => {
    if (!isDisabled) onChange(!isChecked);
  };

  return (
    <TouchableOpacity
      style={[withDarkText ? styles.rowVariant : styles.row, style]}
      onPress={handlePress}
    >
      <Box direction="row" alignVertically="start" {...style.content}>
        <CheckboxIcon isChecked={isChecked} isDisabled={isDisabled} testId={testId || undefined} />
        <SmallSecondaryText
          style={withDarkText ? styles.textVariant : styles.text}
          id={translationKey}
          testId={testId ? `${testId}.text` : undefined}
        />
      </Box>
    </TouchableOpacity>
  );
};
