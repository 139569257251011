import React from 'react';
import { ScrollView, Stack } from 'native-base';

import { Container, Footer, NavButton, Text } from 'src/taxfix-components/src';
import { ProductBundleValues } from 'src/common/constants-it';
import { useLargeScreenQuery } from 'src/components/core';
import { isWeb } from 'src/utils/platform';
import { useItalyIntl } from 'src/_italy/_hooks';
import { useNumberFormatter } from 'src/utils/useNumberFormatter';

import { usePrefillResult } from '../_hooks/use-prefill-result';

import { ProductBundleCard } from './product-bundle-card/product-bundle-card';
import { ProductBundlesPrices } from './use-product-prices';

type Props = {
  onPressBack?(): void;
  productBundlesPrices: ProductBundlesPrices;
  recommendedProductBundle: ProductBundleValues;
  onPressPrimary(): void;
  onPressSecondary(): void;
  isNoSpidFlow?: boolean;
};

export const SingleProductBundle: React.FC<Props> = ({
  isNoSpidFlow,
  onPressBack,
  productBundlesPrices,
  onPressPrimary,
  onPressSecondary,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();
  const getFormattedNumber = useNumberFormatter();
  const { screenContent } = usePrefillResult();
  const taxExpertCardWarning = screenContent.taxExpertCardWarning;

  const translationKeys = {
    title: 'it.product-bundle-screen.single-bundle.screen.guided.title',
    description: `it.product-bundle-screen.single-bundle.screen.guided${
      isNoSpidFlow ? '.nospid.' : '.'
    }description`,
    primaryButtonTitle: `it.product-bundle-screen.single-bundle.button${
      isNoSpidFlow ? '.nospid.' : '.'
    }primary`,
    secondaryButtonTitle: 'it.product-bundle-screen.single-bundle.button.secondary',
  };

  const guidedBundleCard = {
    key: 'productBundleCard_guided.title',
    title: `it.product-bundle-screen.cards.single-bundle.guided-declaration.title`,
    price: getTranslationText(
      `it.product-bundle-screen.cards.single-bundle.guided-declaration.price`,
      { priceFormated: getFormattedNumber(productBundlesPrices.guided) },
    ),
    subtitle: 'it.product-bundle-screen.cards.single-bundle.guided-declaration.subtitle',
    descriptionItems: Array.from(
      { length: 6 },
      (_, index) =>
        `it.product-bundle-screen.cards.single-bundle.guided-declaration${
          isNoSpidFlow ? '.nospid.' : '.'
        }description-item-${index + 1}`,
    ),
    imageComponentKey: `guidedDeclaration`,
    isSingleCard: true,
    withTouchableSurface: false,
  };

  return (
    <>
      {isLargeScreen && onPressBack && (
        <Container position="absolute" top={0} zIndex={1}>
          <NavButton
            onPress={onPressBack}
            text={getTranslationText('it.product-bundle-screen.back-button.text')}
          />
        </Container>
      )}
      <Container px="24px">
        <Text
          mt={{ base: isWeb ? '24px' : 0, md: '76px' }}
          textAlign="center"
          variant={{
            base: 'titleSBold',
            md: 'titleMBold',
            lg: 'titleLBold',
          }}
          value={getTranslationText(translationKeys.title)}
        />

        <Text
          mt="10px"
          textAlign="center"
          variant={isLargeScreen ? 'bodyMBook' : 'bodySBook'}
          color="greytones.textLight"
          value={getTranslationText(translationKeys.description)}
          mb={24}
        />
      </Container>
      <ScrollView>
        {taxExpertCardWarning && (
          <Text
            variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
            value={getTranslationText(taxExpertCardWarning, {
              bold: (text: string) => <Text fontWeight="bold" value={text} />,
            })}
            bg="secondaryCategory.orangeHalf"
            alignSelf="center"
            color="secondaryFeedback.errorRed"
            textAlign="center"
            width="100%"
            paddingY="4px"
            maxW={isLargeScreen ? '580px' : undefined}
            mb={isLargeScreen ? '32px' : '16px'}
          />
        )}

        <Stack
          alignSelf="center"
          space={{ base: '16px', md: '20px' }}
          width={{ base: 'full', md: '580px' }}
          mb="8px"
        >
          <ProductBundleCard {...guidedBundleCard} />
        </Stack>
      </ScrollView>
      <Footer
        primaryButtonTitle={getTranslationText(translationKeys.primaryButtonTitle)}
        primaryButtonProps={{
          width: isLargeScreen ? 'auto' : 'full',
          px: '20px',
          ml: isLargeScreen ? '40px' : '0px',
        }}
        onPressPrimary={onPressPrimary}
        secondaryButtonTitle={getTranslationText(translationKeys.secondaryButtonTitle)}
        secondaryButtonProps={{ variant: 'tertiary', width: isLargeScreen ? 'auto' : 'full' }}
        onPressSecondary={onPressSecondary}
        borderColor={{ base: 'white', md: 'greytones.graphicsLight' }}
        px="24px"
      />
    </>
  );
};
