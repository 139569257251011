import {
  StatusPostBreakProcessFunction,
  StatusSkipFunction,
  StatusMiddleware,
  UPDATE_STATUS,
} from 'src/stores/modules/status';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { getLocalizedDateAndTime } from 'src/i18n/date-utils';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getTaxSeasonInfo } from 'src/services/tax-season';

// to show end-of-season warning banner if current date is between soft and hard end dates
// will display hard end date
export const endOfSeasonStatusBanner: StatusPostBreakProcessFunction = async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { enabled, afterHardEnd, endDateHard } = getTaxSeasonInfo(state);
  const values = getLocalizedDateAndTime(endDateHard, { showMinute: true });

  if (!enabled || afterHardEnd) {
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        banner: {
          type: 'TaxSeasonHardEnd',
          values,
        },
      },
    });
  }
};

export const skipIfEndOfSeason: StatusSkipFunction = async (getState) => {
  const state = getState();
  const { enabled, afterHardEnd } = getTaxSeasonInfo(state);

  if (!enabled || afterHardEnd) {
    return true;
  }

  return false;
};

export const skipIfOffSeason: StatusSkipFunction = async (getState) => {
  const state = getState();
  const { isOffSeason } = getTaxSeasonInfo(state);
  return isOffSeason;
};

export const endOfSeasonSubmitComplete: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
) => {
  const state = getState();
  const { enabled, afterHardEnd, betweenSoftHardEnd } = getTaxSeasonInfo(state);

  if (!enabled || afterHardEnd || betweenSoftHardEnd) {
    dispatch(
      breakAction(undefined, {
        title: {
          id: 'status.complete.section.todo.title',
        },
        messages: [],
      }),
    );
  }
};

export const preSeasonRedirect: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
) => {
  const { isOffSeason } = getTaxSeasonInfo(getState());

  if (isOffSeason) {
    dispatch(breakAction());
  } else {
    dispatch(failureAction('tax season not available'));
  }
};

export const softEndOfSeasonNotResubmissionBreak: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const state = getState();
  const year = settingsSelectors.selectedYear(state);

  if (!year) {
    dispatch(failureAction('year not selected'));
    return;
  }

  const isResubmission = submissionSelectors.isResubmissionByYear(state, year);
  const { betweenSoftHardEnd } = getTaxSeasonInfo(state);

  if (betweenSoftHardEnd && !isResubmission) {
    dispatch(breakAction());
  } else {
    next();
  }
};

export const endOfSeasonDisableSteps: StatusPostBreakProcessFunction = async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { enabled, afterHardEnd } = getTaxSeasonInfo(state);

  if (!enabled || afterHardEnd) {
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        stepIndex: -1,
      },
    });
  }
};
export const endOfSeasonDisableNavigation: StatusPostBreakProcessFunction = async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { enabled, afterHardEnd } = getTaxSeasonInfo(state);

  if (!enabled || afterHardEnd) {
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        navigation: null,
      },
    });
  }
};
