import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { flagsQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { ButtonName } from 'src/types/button-name';
import { ScreenName } from 'src/types/screen-name';

import { CardSetting, FlowStage } from './use-dashboard';
import { useQuizmasterLight } from './use-quizmaster-light';

type Props = {
  getPreseasonStage: () => CardSetting;
};

const logButtonPress = () => {
  Analytics.log(AnalyticsEvent.buttonPressed, {
    sectionName: ScreenName.Dashboard,
    buttonName: ButtonName.StartPreseasonCuDownload,
  });
};

export const usePreseasonDashboardStage = (): Props => {
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.preseasonCUDocument]);
  const hasCUDocument = quizmasterLight[flagsQuestionIds.preseasonCUDocument].answer;

  const navigateToStatus = () => {
    safeResetNavigation([getNavigationActions().toStatus('screen')]);
  };

  const getPreseasonStage = (): CardSetting => {
    const cardSetting = hasCUDocument
      ? {
          stage: FlowStage.preseasonCUDownloaded,
          handleOnPressCTA: () => {
            logButtonPress();
            navigateToStatus();
          },
        }
      : {
          stage: FlowStage.preseasonCUStart,
          handleOnPressCTA: () => {
            logButtonPress();
            navigateToStatus();
          },
        };

    return cardSetting;
  };

  return { getPreseasonStage };
};
