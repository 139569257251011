import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, ScrollView, useBreakpointValue } from 'native-base';
import { useSelector } from 'react-redux';

import { Container, NavButton } from 'src/taxfix-components/src';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { useLargeScreenQuery } from 'src/components/core';
import HtmlContent from 'src/components/html-content';
import { parseMarkdown } from 'src/services/markdown-parser';
import { getTaxSeasonInfo } from 'src/services/tax-season';

const contentStyles = `
<style type="text/css">
h2 {
    font-size: 20px;
    font-weight: bold;
    text-transform: none;
  }
ul {
    background-color: transparent;
    padding-top: 0em;
    padding-bottom: 0em;
    margin: 0px;
    }
li {
    margin-bottom: 5px;
  }  
</style>
`;

const styles = StyleSheet.create({
  screenContent: { minHeight: 600 },
  screenContentLargeScreen: { minHeight: 300 },
});

export const SPIDConsent = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { navigation } = useNavigation();
  const screenWidth = useBreakpointValue({ base: 'full', md: '628px' });
  const isLargeScreen = useLargeScreenQuery();
  const taxSeasonInfo = useSelector(getTaxSeasonInfo);
  const { isPreSeason } = taxSeasonInfo;

  const translationKey = isPreSeason
    ? 'it.spid.consent.screen.content.preseason'
    : 'it.spid.consent.screen.content.inseason';

  return (
    <>
      {isLargeScreen && navigation.canGoBack() && (
        <NavButton
          text={getTranslationText('account.create.header.back')}
          onPress={navigation.goBack}
        />
      )}
      <Container flex={1} alignSelf="center" width={screenWidth} mx={0.5} mb={2}>
        <ScrollView>
          <Box style={isLargeScreen ? styles.screenContentLargeScreen : styles.screenContent}>
            <HtmlContent
              contentStyles={contentStyles}
              content={String(parseMarkdown(getTranslationText(translationKey)))}
            />
          </Box>
        </ScrollView>
      </Container>
    </>
  );
};
