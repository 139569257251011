import * as React from 'react';
import { connect } from 'react-redux';
import { Voucher } from '@taxfix/payment-sdk';
import Config from 'react-native-config';
import { get } from 'lodash';
import { compose } from 'redux';

import { withQuizmasterLight, QuizmasterLight } from 'src/utils/with-quizmaster-light';
import { ProductBundleValues, flagsQuestionIds } from 'src/common/constants-it';
import { State as RootState } from 'src/stores/store/initial';

import { Product, CouponResult } from '../../types/payment';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { selectors as remoteConfigFirebaseSelectors } from '../../stores/modules/remote-config-firebase';

import { CouponInputErrors, getCouponInputError } from './coupon-input-container.types';
import { CouponInput as CouponInputComponent } from './coupon-input';

type OwnProps = {
  onCouponResultUpdate: (couponResult?: CouponResult) => void;
  onCouponCreateInit?: () => void;
  product?: Product;
  existingCouponCode?: string | any;
  showReset?: boolean;
};

type Props = OwnProps & {
  accessToken: string;
  quizmasterLight: QuizmasterLight;
  instantProductVouchers: Record<string, string>;
};

type State = {
  inputValue: string;
  isLoading: boolean;
  isCouponApplied: boolean;
  error?: CouponInputErrors;
};

const mapStateToProps = (state: RootState) => ({
  accessToken: userAuthSelectors.getAccessToken(state),
  instantProductVouchers: remoteConfigFirebaseSelectors.getInstantProductVouchers(state),
});

class CouponInput extends React.Component<Props, State> {
  state = {
    inputValue: '',
    isLoading: false,
    error: undefined,
    isCouponApplied: false,
  };

  componentDidMount = () => {
    const { existingCouponCode } = this.props;
    if (existingCouponCode)
      this.setState({
        inputValue: existingCouponCode,
        isCouponApplied: true,
      });
  };

  handleOnCouponReset = () => {
    this.setState(
      {
        isCouponApplied: false,
        inputValue: '',
      },
      () => {
        this.props.onCouponResultUpdate(undefined);
      },
    );
  };

  handleOnCouponUpdate = (coupon: string) => {
    this.setState({
      inputValue: coupon,
      error: undefined,
    });
  };

  handleOnCouponApply = () => {
    const { onCouponCreateInit, product, onCouponResultUpdate, accessToken, quizmasterLight } =
      this.props;

    const couponsMap = this.props.instantProductVouchers;

    const isInstant =
      quizmasterLight[flagsQuestionIds.productBundleSelection].answer ===
      ProductBundleValues.instant;

    const codeToApply = isInstant ? couponsMap[this.state.inputValue] : this.state.inputValue;

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          if (onCouponCreateInit) {
            onCouponCreateInit();
          }

          if (!product) {
            throw new Error('cannot create coupon without product');
          }

          const couponResult = await Voucher.apply(Config.API_BASE_URL, accessToken, {
            code: codeToApply,
            productId: product.id,
            metadata: {
              order: {
                productId: product.id,
              },
            },
          });

          this.setState(
            {
              isLoading: false,
              isCouponApplied: true,
            },
            () => {
              onCouponResultUpdate(couponResult);
            },
          );
          Analytics.log(AnalyticsEvent.validateCouponSuccess, {
            voucherCode: couponResult.code,
            voucherAmount: couponResult.amountCents,
          });
        } catch (err) {
          const errorMessage = get(err, 'response.data.message');
          this.setState(
            {
              isLoading: false,
              error: getCouponInputError(errorMessage),
            },
            () => {
              onCouponResultUpdate(undefined);
            },
          );
          Analytics.log(AnalyticsEvent.validateCouponFailed, {
            voucherCode: this.state.inputValue,
          });
        }
      },
    );
  };

  render() {
    const { isLoading, inputValue, error, isCouponApplied } = this.state;
    return (
      <CouponInputComponent
        onCouponApply={this.handleOnCouponApply}
        onCouponReset={this.handleOnCouponReset}
        onCouponUpdate={this.handleOnCouponUpdate}
        isEditable={!isCouponApplied}
        isLoading={isLoading}
        couponCode={inputValue}
        errorType={error}
        showReset={this.props.showReset}
      />
    );
  }
}

export const CouponInputContainer = compose<React.ComponentType<OwnProps>>(
  connect(mapStateToProps),
  withQuizmasterLight([flagsQuestionIds.productBundleSelection]),
)(CouponInput);
