import { Pressable } from 'native-base';
import React from 'react';

import { Container } from '../../layout/Container';
import { Icon } from '../Icon';
import { Text } from '../Text';

import { Props } from './types';

export const IconButton: React.FC<Props> = ({
  path,
  variant = 'brand',
  size = 6,
  text,
  onPress,
  textVariant = 'bodyXXSBook',
  iconProps,
  textProps,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Pressable onPress={onPress} disabled={disabled}>
      <Container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        alignSelf="center"
        {...otherProps}
      >
        <Icon
          color={variant === 'brand' ? 'brand.brandGreen' : 'greytones.graphicsDark'}
          size={size}
          path={path}
          {...iconProps}
        />

        {text && (
          <Text
            variant={textVariant}
            color={variant === 'brand' ? 'brand.brandGreen' : 'greytones.graphicsDark'}
            {...textProps}
          >
            {text}
          </Text>
        )}
      </Container>
    </Pressable>
  );
};
