import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { useBreakpointValue } from 'native-base';

import { NavButton } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { Box } from 'src/components/core';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';

import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { InformationContainer } from '../information/information-container';
import { InformationContactSupportButton } from '../../containers/information-contact-support-button';

const mapStateToProps = (state: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
  email: userAuthSelectors.getEmailAddress(state),
  userId: userAuthSelectors.getUserId(state),
});

const connector = connect(mapStateToProps);
type Props = {
  content: string;
  contentStyles?: string;
  intl: IntlShape;
} & ConnectedProps<typeof connector> &
  WithNavigation;

const PrivacyPolicy = (props: Props) => {
  const { navigationActions } = props;
  const { getTranslationText } = useItalyIntl();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const backNavigationText = getTranslationText('web.navigation.italy.back');

  return (
    <Box>
      <Box bottom={2}>
        {!isSmallScreen && <NavButton onPress={navigationActions.back} text={backNavigationText} />}
      </Box>
      <InformationContainer
        {...props}
        renderSupportButton={() => <InformationContactSupportButton />}
      ></InformationContainer>
    </Box>
  );
};

export default compose<any>(injectIntl, withNavigation, connector)(PrivacyPolicy);
