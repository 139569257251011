import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { theme } from './core';
import { StyleProp } from './types';

const styles = StyleSheet.create({
  separator: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});
type Props = {
  color?: string;
  size?: number;
  style?: StyleProp;
};

const Separator = ({
  color = theme.color.border,
  size = StyleSheet.hairlineWidth,
  style = {},
}: Props) => (
  <View
    style={[
      styles.separator,
      {
        borderBottomColor: color,
        borderBottomWidth: size,
      },
      style,
    ]}
  />
);

export default Separator;
