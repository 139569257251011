import * as React from 'react';
import { ScrollView } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import { Container, Footer, Image, NavButton, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

type TailoredExperienceFinalProps = {
  onBack: () => void;
  onContinue: () => void;
};

export const TailoredExperienceFinal: React.FC<TailoredExperienceFinalProps> = ({
  onBack,
  onContinue,
}) => {
  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();

  return (
    <>
      {isLargeScreen && onBack && (
        <NavButton onPress={onBack} text={getTranslationText('it.navigation.back-to-home')} />
      )}

      <ScrollView contentContainerStyle={{ height: '100%' }}>
        <Container
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width={{ md: '580px' }}
          mx={{ base: '24px', md: '0px' }}
        >
          <Image name="greenGuardBadge" width={[158, 207]} height={[158, 207]} />
          <Text
            mt="50px"
            mb="16px"
            color="greytones.backgroundDark"
            variant={{ base: 'titleSBold', md: 'titleMBold' }}
            value={getTranslationText('tailored-experience-final.title')}
            textAlign="center"
          />
          <Text
            color="greytones.textDefault"
            variant="bodyMBook"
            value={getTranslationText('tailored-experience-final.description', {
              lineBreak: isLargeScreen ? '' : '\n',
            })}
            textAlign="center"
            maxW="574px"
          />
        </Container>
      </ScrollView>

      <Footer
        primaryButtonTitle={getTranslationText('tailored-experience-final.cta.label')}
        width="full"
        primaryButtonProps={{ width: isLargeScreen ? 'auto' : 'full', px: '20px' }}
        onPressPrimary={onContinue}
        mt="auto"
        px="20px"
        backgroundColor="white"
        borderTopWidth={0}
      />
    </>
  );
};
