/* global Image */
import mergeImagesLib from 'merge-images';

type Options = {
  format?: string;
  quality?: number;
  width?: number;
  height?: number;
  crossOrigin?: string;
};

type ImageSize = {
  height: number;
  width: number;
  src: string;
};

const getImageSize = (src: string): Promise<ImageSize> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
        src,
      });
    };

    img.onerror = () => {
      reject(new Error('error getting image size'));
    };
  });
};

export type Base64String = string;

const mergeImages = async (
  images: Base64String[],
  customOptions: Options = {},
): Promise<Base64String> => {
  let heightOffset = 0;
  const imageSizesPromises = images.map((src) => getImageSize(src));
  return Promise.all(imageSizesPromises)
    .then((imageSizes) => {
      return imageSizes.map((imageSize) => {
        const offset = {
          x: 0,
          y: heightOffset,
        };
        heightOffset += imageSize.height;
        return { ...offset, src: imageSize.src };
      });
    })
    .then((imagesWithYOffset) => {
      const options = {
        height: heightOffset,
        ...customOptions,
      };
      return mergeImagesLib(imagesWithYOffset, options);
    });
};

export { mergeImages };
