import { IntlShape } from 'react-intl';

export const formatDate = (intl: IntlShape, date: string | Date) => {
  const intlOpts = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;
  return intl.formatDate(date, intlOpts);
};
export const formatDurationBoundary = (intl: IntlShape, date: Date) => {
  const intlOpts = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  } as const;
  return intl.formatDate(date, intlOpts);
};

export const formatDateWithPoints = (intl: IntlShape, date: Date): string => {
  const formattedDateWithSlash = formatDurationBoundary(intl, date);
  return formattedDateWithSlash.replace(/\//g, '.');
};
