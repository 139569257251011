import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { PaymentCustomer } from 'src/types/payment';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { getStore } from 'src/stores/util';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { wayQuestionIds } from 'src/common/constants-it';
import { getAnswers } from 'src/stores/hooks/answers-stores-hook';
import { getSanitizedInput } from 'src/validations/italy-input-fields';

type PaymentNavigationParams = {
  customer: PaymentCustomer;
};

export const usePaymentNavigationParams = (): PaymentNavigationParams => {
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const nodes = getNodesByIds(questionStore, [wayQuestionIds.firstName, wayQuestionIds.lastName]);
  const quizmasterLight = getQuizmasterLight(questionStore, nodes);

  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const email = useSelector(userAuthSelectors.getEmailAddress);

  const {
    form730: {
      '730/ResidenzaAnagrafica/Indirizzo': street,
      '730/ResidenzaAnagrafica/NumCivico': number,
      '730/ResidenzaAnagrafica/Comune': city,
      '730/ResidenzaAnagrafica/Cap': zipcode,
      '730/ResidenzaAnagrafica/Provincia': state,
    },
  } = getAnswers(questionStore);

  const billingAddress = {
    street: getSanitizedInput(`${street} ${number}`),
    zipcode,
    city,
    countryCode: CountryCodes.IT,
    state,
  };

  const customer = {
    firstName,
    lastName,
    email,
    ...billingAddress,
  };

  return { customer };
};
