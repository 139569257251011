import { NativeModules } from '../nativeModules';

type ResponsesBridge = {
  answer: (
    questionId: string,
    answerId: string,
    year: string,
    value: {
      value: any;
    },
    version: string,
  ) => Promise<any>;
  deleteResponsesWithAnswerIDsAndYears: (
    answerIDsAndYears: Array<{
      answerID: string;
      year: string;
    }>,
  ) => Promise<any>;
  fetchAll: () => Promise<any>;
  skip: (questionId: string, answerId: string, year: string, version: string) => Promise<any>;
};
const Bridge: ResponsesBridge = NativeModules.ResponsesBridge;
export default Bridge;
