import React, { Component, createRef } from 'react';
import { StyleSheet } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { validatePhoneNumber } from 'src/services/validations';

import FormField from '../form-field-extended';
import { Box, TextInput, theme } from '../core';

const styles = StyleSheet.create({
  label: {
    color: theme.color.primaryText,
    fontSize: 12,
    fontFamily: theme.fontFamily.bold,
    letterSpacing: 1.2,
  },
});
type Props = {
  countryCode?: CountryCodes;
  phoneNumber?: string;
  onChange: (phoneNumber: string) => void;
  onInputFocus: () => void;
  onPhoneNumberSubmit: () => void;
};
type State = {
  isPhoneNumberFieldErrorShown: boolean;
};

class PhoneNumberInput extends Component<Props, State> {
  state = {
    isPhoneNumberFieldErrorShown: false,
  };

  textInputRef = createRef<TextInput>();

  focus = () => {
    if (this.textInputRef.current) {
      this.textInputRef.current.focus();
    }
  };

  validate = () => {
    const { phoneNumber } = this.props;
    this.setState({
      isPhoneNumberFieldErrorShown: false,
    });

    if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      this.setState({
        isPhoneNumberFieldErrorShown: true,
      });
    }
  };

  render() {
    const { isPhoneNumberFieldErrorShown } = this.state;
    const { phoneNumber, onChange, onPhoneNumberSubmit, onInputFocus } = this.props;
    return (
      <Box bottom={3}>
        <FormField
          labelStyle={styles.label}
          labelTextTransform="uppercase"
          label={{
            key: 'account.create.phone-number',
          }}
          error={{
            key: isPhoneNumberFieldErrorShown
              ? 'account.contact-support-multi-channels.leave-phone-modal.phone-number.invalid'
              : '',
            level: 'Error',
          }}
        >
          {({ onBlur, onFocus }) => (
            <TextInput
              ref={this.textInputRef}
              value={phoneNumber}
              onChangeText={onChange}
              onBlur={() => {
                onBlur();
                this.validate();
              }}
              onFocus={() => {
                onFocus();
                onInputFocus();
              }}
              onSubmitEditing={onPhoneNumberSubmit}
              autoCautoCompleteType="tel"
              keyboardType="phone-pad"
            />
          )}
        </FormField>
      </Box>
    );
  }
}

export default PhoneNumberInput;
