import { $Keys } from 'utility-types';
import React from 'react';
import { ActivityIndicator, View, StyleSheet, TouchableHighlight } from 'react-native';
import Config from 'react-native-config';
import { View as AnimatableView } from 'react-native-animatable';
import { useIntl } from 'react-intl';

import { useNavigation } from 'src/hooks/navigation-hook';

import { PDF_PASSWORD_PROTECTED_ERROR } from '../utils/constants';
import { isWeb } from '../utils/platform';

import { theme, BodyTitleText, Icon, LinkText, Box } from './core';
import { ErrorKeys } from './auth/ErrorMessage';

const NotificationBannerLegacyType = {
  ReviewInstructions: 'ReviewInstructions',
  EmailInvalidOrInUse: ErrorKeys.EmailAddressInUse,
  AccountVerificationFailed: 'AccountVerificationFailed',
  CompleteSubmission: 'CompleteSubmission',
  EmailConfirmationPending: 'EmailConfirmationPending',
  EmailConfirmed: 'EmailConfirmed',
  EmailConfirmationRequested: 'EmailConfirmationRequested',
  IdentificationFailed: 'IdentificationFailed',
  IdentificationProtectedPDF: 'IdentificationProtectedPDF',
  ImageCapturePermissions: 'ImageCapturePermissions',
  Processing: 'Processing',
  SendDocuments: 'SendDocuments',
  ShowPDFFailed: 'ShowPDFFailed',
  SubmitFailed: 'SubmitFailed',
  CompleteSubmissionWithRefundForFree: 'CompleteSubmissionWithRefundForFree',
  RequiredDocuments: 'RequiredDocuments',
  ConnectionFailed: 'ConnectionFailed',
  ValidationFailed: 'ValidationFailed',
  GenericSignupError: 'GenericSignupError',
  EmailInvalid: 'EmailInvalid',
  LoginFailed: 'LoginFailed',
  UserUnknown: 'UserUnknown',
  UserBlocked: 'UserBlocked',
  PasswordMissing: 'PasswordMissing',
  ProductResolveFailed: 'ProductResolveFailed',
  StatusScreenFetchFailed: 'StatusScreenFetchFailed',
  TaxSeasonSoftEnd: 'TaxSeasonSoftEnd',
  TaxSeasonHardEnd: 'TaxSeasonHardEnd',
  TaCAcceptError: 'TaCAcceptError',
  CreateVoucherFailed: 'CreateVoucherFailed',
  EmailConfirmation: 'EmailConfirmation',
  CloseAccountVerificationFailed: 'CloseAccountVerificationFailed',
  SubmissionResetFailed: 'SubmissionResetFailed',
  EmailChangePending: 'EmailChangePending',
  EmailChangeConfirmed: 'EmailChangeConfirmed',
  UnsupportedOccupation: 'UnsupportedOccupation',
  ResubmissionInfo: 'ResubmissionInfo',
  LockScreenInfo: 'LockScreenInfo',
  LoginSsoError: 'LoginSsoError',
  DocumentSigningError: 'DocumentSigningError',
};

enum Level {
  Reminder = 'Reminder',
  Error = 'Error',
  Warning = 'Warning',
  WarningFilled = 'WarningFilled',
  Info = 'Info',
}

export type NotificationBannerTypeKey = $Keys<typeof NotificationBannerLegacyType>;

export type BannerSize = 'large' | 'small';

type LevelColors = {
  actionColor: string;
  backgroundColor: string;
  highlightColor: string;
  textColor: string;
};

type Props = {
  notificationType: NotificationBannerTypeKey | any;
  values?: Record<string, any>;
  onAction?: () => void;
  isError?: boolean;
  bannerSize?: BannerSize;
  hideDelay?: number;
  hideDuration?: number;
  testId?: string;
  actionTestId?: string;
  children?: React.ReactNode;
};

const styles = StyleSheet.create({
  animationContainer: {
    flex: 1,
    zIndex: 1,
  },
  container: {
    minHeight: 75,
  },
  smallContainer: {
    minHeight: 40,
  },
  containerWeb: {
    minHeight: 45,
    paddingTop: 12.5,
    paddingBottom: 12.5,
  },
  stretch: {
    justifyContent: 'center',
    flex: 1,
  },
  centerAlignment: {
    justifyContent: 'center',
  },
  inner: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  iconContainer: {
    flex: 0,
  },
  initialText: {
    color: theme.color.lightText,
  },
  text: {
    color: theme.color.lightText,
    paddingRight: 20,
  },
  postText: {
    color: theme.color.lightText,
  },
  buttonText: {
    color: theme.color.primaryBackgroundText,
  },
  indicator: {
    flex: 1,
  },
  sticky: {
    // We use this only for web to stick the banner to the top while scrolling
    // @ts-ignore
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  inlineColor: {
    color: theme.color.lightText,
    textDecorationLine: 'underline',
  },
});
type NotificationBannerConfig = {
  message?: string;
  button?: string | null | undefined;
  postButtonMessage?: string;
  level?: $Keys<typeof Level> | any;
  configAction?: (() => any) | null | undefined;
  icon?: string;
  iconColorChange?: boolean;
};

export const configForType = (
  notificationType: NotificationBannerTypeKey,
  isError: boolean,
  bannerSize?: BannerSize,
): NotificationBannerConfig => {
  const { navigationActions } = useNavigation();
  switch (notificationType) {
    case NotificationBannerLegacyType.ReviewInstructions:
      return {
        message: 'notification.re-submission.error.message',
        button: 'notification.re-submission.error.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.AccountVerificationFailed:
      return {
        message: 'notification.purchase-verification-failed.account.message',
        button: 'notification.purchase-verification-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.CompleteSubmissionWithRefundForFree:
      return {
        message: 'notification.complete-submission-free.message',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.CompleteSubmission:
      return {
        message: 'notification.complete-submission.message',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.EmailConfirmationPending:
      return {
        message:
          bannerSize === 'large'
            ? 'notification.email-confirmation-pending.message'
            : 'notification.email-confirmation-pending.message.short',
        button: 'notification.email-confirmation-pending.button',
        level: isError ? Level.Error : Level.Reminder,
      };

    case NotificationBannerLegacyType.EmailConfirmed:
      return {
        message: 'notification.email-confirmed.message',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.EmailConfirmationRequested:
      return {
        message:
          bannerSize === 'large'
            ? 'notification.email-confirmation-requested.message'
            : 'notification.email-confirmation-requested.message.short',
        button: null,
        level: isError ? Level.Error : Level.Reminder,
      };

    case NotificationBannerLegacyType.IdentificationFailed:
      return {
        message: 'notification.identification-failed.message',
        button: 'notification.identification-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.IdentificationProtectedPDF:
      return {
        message: PDF_PASSWORD_PROTECTED_ERROR,
        button: 'notification.identification-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.ImageCapturePermissions:
      return {
        message: 'notification.image-capture-permissions.message',
        button: 'notification.image-capture-permissions.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.Processing:
      return isError
        ? {
            level: Level.Error,
            button: '',
            message: '',
          }
        : {};

    case NotificationBannerLegacyType.SendDocuments:
      return {
        message: 'notification.send-documents.message',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.ShowPDFFailed:
      return {
        message: 'notification.show-pdf-failed.message',
        button: 'notification.show-pdf-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.SubmitFailed:
      return {
        message: 'notification.submit-failed.message',
        button: 'notification.submit-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.RequiredDocuments:
      return {
        message: 'notification.required-documents.message',
        button: 'notification.required-documents.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.GenericSignupError:
      return {
        message: 'account.error.failed.lead',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.ConnectionFailed:
      return {
        message: 'account.error.connection-failed.lead',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.ValidationFailed:
      return {
        message: 'account.error.validation-failed.lead',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.EmailInvalid:
      return {
        message: 'account.lock.error.email-address-invalid',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.PasswordMissing:
      return {
        message: 'account.lock.error.password-missing',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.UserUnknown:
      return {
        message: 'account.lock.error.user-unknown',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.UserBlocked:
      return {
        message: 'account.lock.error.user-blocked',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.LoginFailed:
      return {
        message: 'account.lock.error.failed',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.ProductResolveFailed:
      return {
        message: 'notification.error.product-resolve-failed.message',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.StatusScreenFetchFailed:
      return {
        message:
          Config.SHOW_DEBUG_SCREEN === 'true'
            ? 'status.error.fetch-failed.message-with-debug'
            : 'status.error.fetch-failed.message',
        button: 'status.error.fetch-failed.button',
        level: Level.Error,
      };

    case NotificationBannerLegacyType.EmailInvalidOrInUse:
      return {
        message: 'account.error.email-address-in-use',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.TaxSeasonSoftEnd:
      return {
        message: 'status.taxSeason.soft-end',
        button: null,
        level: Level.Warning,
        icon: 'mono.icon-bell',
      };

    case NotificationBannerLegacyType.TaxSeasonHardEnd:
      return {
        message: 'status.taxSeason.hard-end',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.TaCAcceptError:
      return {
        message: 'tac-change.error',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.CreateVoucherFailed:
      return {
        message: 'referral.send-voucher.error',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.EmailConfirmation:
      return {
        message:
          bannerSize === 'large'
            ? 'notification.it.email-confirmation.message'
            : 'notification.email-confirmation-pending.message.short',
        button: 'notification.email-confirmation-pending.button',
        level: isError ? Level.Error : Level.Reminder,
      };

    case NotificationBannerLegacyType.CloseAccountVerificationFailed:
      return {
        message: 'account.close-account.verification.fail',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.EmailChangePending:
      return {
        message: 'account.request-email-change.banner.pending',
        button: null,
        level: Level.WarningFilled,
      };

    case NotificationBannerLegacyType.EmailChangeConfirmed:
      return {
        message: 'account.request-email-change.banner.success',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.ResubmissionInfo:
      return {
        message: 'status.resubmission.info.banner.text',
        button: null,
        level: Level.WarningFilled,
      };

    case NotificationBannerLegacyType.LockScreenInfo:
      return {
        message: 'account.lock.info',
        button: null,
        level: Level.Reminder,
      };

    case NotificationBannerLegacyType.LoginSsoError:
      return {
        message: 'account.login.sso.error',
        button: null,
        level: Level.Error,
      };

    case NotificationBannerLegacyType.DocumentSigningError:
      return {
        message: 'document.signing.error.pre.title',
        button: 'document.signing.error.link',
        postButtonMessage: 'document.signing.error.post.title',
        level: Level.Error,
        configAction: () => navigationActions.toProfileDetails('screen'),
      };

    default:
      return {
        message: 'account.error.failed.lead',
        button: null,
        level: Level.Error,
      };
  }
};

export const colorsForLevel = (level: $Keys<typeof Level> | null | undefined): LevelColors => {
  switch (level) {
    case Level.Error:
      return {
        actionColor: theme.color.errorTextDark,
        backgroundColor: theme.color.errorBackground,
        highlightColor: theme.color.errorBorderDark,
        textColor: theme.color.lightText,
      };

    case Level.Warning:
      return {
        actionColor: theme.color.errorTextDark,
        backgroundColor: theme.color.orangeFill,
        highlightColor: theme.color.orangeFillPressed,
        textColor: theme.color.orangeText,
      };

    case Level.WarningFilled:
      return {
        actionColor: theme.color.errorTextDark,
        backgroundColor: theme.color.warningBackground,
        highlightColor: theme.color.warningBackgroundPressed,
        textColor: theme.color.lightText,
      };
    case Level.Info:
      return {
        actionColor: theme.color.errorTextDark,
        backgroundColor: theme.color.reminderWarningBackground,
        highlightColor: theme.color.orangeFillPressed,
        textColor: theme.color.orangeText,
      };

    default:
      return {
        actionColor: theme.color.primaryBackgroundText,
        backgroundColor: theme.color.primary,
        highlightColor: theme.color.primaryBackgroundText,
        textColor: theme.color.lightText,
      };
  }
};

const NotificationBannerLegacy: React.FC<Props> = ({
  notificationType,
  values,
  onAction,
  isError = false,
  bannerSize = 'large',
  hideDelay,
  hideDuration = 1000,
  testId = 'notification-banner.message',
  actionTestId = 'notification-banner.action',
  children,
}) => {
  const intl = useIntl();

  const {
    button,
    message,
    postButtonMessage,
    level,
    configAction,
    icon,
    iconColorChange = true,
  } = configForType(notificationType, isError, bannerSize);
  const { actionColor, backgroundColor, highlightColor, textColor } = colorsForLevel(level);
  const buttonAction = onAction || configAction;
  let action = null;
  const showStickyOnWeb = isWeb && level === Level.Error;

  if (typeof buttonAction === 'function' && button != null) {
    action = (
      <LinkText
        id={button}
        style={[
          styles.buttonText,
          {
            color: actionColor,
          },
          postButtonMessage ? styles.inlineColor : {},
        ]}
        values={values}
        testId={actionTestId}
      />
    );
  }

  if (typeof buttonAction === 'function' && button == null) {
    action = (
      <View style={styles.iconContainer}>
        <Icon
          name="color.arrow-dark"
          tintColor={
            level === Level.Error ? theme.color.errorTextDark : theme.color.primaryBackgroundText
          }
        />
      </View>
    );
  }

  const title =
    notificationType === NotificationBannerLegacyType.Processing ? (
      <ActivityIndicator style={styles.indicator} animating color="grey" />
    ) : (
      <View style={postButtonMessage ? styles.centerAlignment : styles.stretch}>
        {children || (
          <BodyTitleText
            style={[
              postButtonMessage ? styles.initialText : styles.text,
              {
                color: textColor,
              },
            ]}
            testId={testId}
          >
            {intl.formatMessage(
              {
                id: message,
              },
              values,
            )}
          </BodyTitleText>
        )}
      </View>
    );

  const afterButtonTitle = postButtonMessage
    ? intl
        .formatMessage({ id: postButtonMessage }, values)
        .split(' ')
        .map((_text, _index) => (
          <BodyTitleText
            key={`${_text}-${_index}`}
            style={[
              styles.postText,
              {
                color: textColor,
              },
            ]}
          >
            {`${_text} `}
          </BodyTitleText>
        ))
    : null;

  const inner = (
    <View style={styles.inner}>
      {icon && (
        <Box right={1}>
          <Icon name={icon} tintColor={iconColorChange ? textColor : ''} />
        </Box>
      )}
      {title}
      {action}
      {afterButtonTitle}
    </View>
  );
  const content = buttonAction ? (
    <TouchableHighlight
      underlayColor={highlightColor}
      style={styles.stretch}
      onPress={buttonAction}
    >
      {inner}
    </TouchableHighlight>
  ) : (
    <View style={styles.stretch}>{inner}</View>
  );
  const containerStyle = isWeb
    ? styles.containerWeb
    : bannerSize === 'large'
    ? styles.container
    : styles.smallContainer;
  const staticBanner = (
    <Box
      style={[
        containerStyle,
        {
          backgroundColor,
        },
      ]}
    >
      {content}
    </Box>
  );

  if (typeof hideDelay !== 'undefined') {
    return (
      <AnimatableView
        animation="slideOutUp"
        delay={hideDelay}
        duration={hideDuration}
        style={styles.animationContainer}
      >
        {staticBanner}
      </AnimatableView>
    );
  }

  if (showStickyOnWeb) {
    return <View style={styles.sticky}>{staticBanner}</View>;
  }

  return <>{staticBanner}</>;
};

export { NotificationBannerLegacy, NotificationBannerLegacyType };
