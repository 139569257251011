import React from 'react';
import { StyleSheet } from 'react-native';
import _ from 'lodash';

import {
  TextButton,
  CircleButton,
  Box,
  Image,
  SmallPrimaryText,
  DisplayTitleText,
  TinySecondaryText,
  theme,
  useLargeScreenQuery,
} from '../core';

type Props = {
  onScoreGiven: (arg0: number) => void;
  onDismiss: () => void;
};
const styles = StyleSheet.create({
  containerSmall: {
    backgroundColor: theme.color.lightFill,
    borderRadius: 12,
    width: 270,
  },
  containerLarge: {
    backgroundColor: 'white',
    borderRadius: 12,
    width: 580,
    height: 580,
  },
  innerContainerLarge: {
    paddingHorizontal: 115,
  },
  centerText: {
    textAlign: 'center',
  },
  scoresContainer: {
    justifyContent: 'center',
  },
  tipContainerSmall: {
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 5,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tipContainerLarge: {
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 55,
    paddingRight: 55,
  },
  scoreButton: {
    marginHorizontal: 4,
    marginBottom: 5,
  },
  dismissButton: {
    borderWidth: 0,
    flex: 0,
  },
  dismissButtonLarge: {
    paddingTop: 36,
  },
});
export const InquiryNPSComponent = ({ onScoreGiven, onDismiss }: Props) => {
  const isLargeScreen = useLargeScreenQuery();
  return (
    <Box
      style={isLargeScreen ? styles.containerLarge : styles.containerSmall}
      alignHorizontally="center"
    >
      {isLargeScreen ? (
        <Image key="large" name="feedback-modal.nps-header-new" />
      ) : (
        <Image key="small" name="feedback-modal.nps-header" />
      )}
      <Box
        top={isLargeScreen ? 4 : 2}
        left={2}
        right={2}
        // @ts-ignore
        styles={isLargeScreen ? styles.innerContainerLarge : undefined}
      >
        <DisplayTitleText style={styles.centerText} id="feedback-modal.nps.title" />
        <Box bottom={2} />
        <SmallPrimaryText style={styles.centerText} id="feedback-modal.nps.subtitle" />
      </Box>

      <Box top={1} direction="row" wrap style={styles.scoresContainer}>
        {_.range(11).map((score) => (
          <CircleButton onPress={() => onScoreGiven(score)} style={styles.scoreButton} key={score}>
            {score}
          </CircleButton>
        ))}
      </Box>

      <Box
        direction="row"
        wrap
        style={isLargeScreen ? styles.tipContainerLarge : styles.tipContainerSmall}
      >
        <TinySecondaryText id="feedback-modal.nps.scale.minimum" />
        <TinySecondaryText id="feedback-modal.nps.scale.maximum" />
      </Box>

      <TextButton
        translationKey="feedback-modal.dismiss"
        onPress={onDismiss}
        style={[styles.dismissButton, isLargeScreen ? styles.dismissButtonLarge : undefined]}
        textStyle={{
          margin: 10,
        }}
        testId="test.feedback-modal.not-now"
      />
    </Box>
  );
};
