export const ADD_WARNING = 'plausibility-checks/ADD_WARNING';
export const REMOVE_WARNING = 'plausibility-checks/REMOVE_WARNING';
export type State = Record<
  number,
  {
    warnings: string[];
  }
>;
export const initial: State = {};
type Payload = {
  progress?: number;
  isMandatoryFilerAlertShown?: boolean;
  questionId?: string;
  year: number;
};
type AddWarningAction = {
  type: typeof ADD_WARNING;
  payload: Payload;
};
type RemoveWarningAction = {
  type: typeof REMOVE_WARNING;
  payload: Payload;
};
type Action = AddWarningAction | RemoveWarningAction;

const addWarning = (year: number, questionId: string): AddWarningAction => ({
  type: ADD_WARNING,
  payload: {
    questionId,
    year,
  },
});

const removeWarning = (year: number, questionId: string): RemoveWarningAction => ({
  type: REMOVE_WARNING,
  payload: {
    questionId,
    year,
  },
});

export type Actions = {
  addWarning: typeof addWarning;
  removeWarning: typeof removeWarning;
};
export const actions = {
  addWarning,
  removeWarning,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case ADD_WARNING: {
      const {
        payload: { year, questionId },
      } = action;
      const currentWarnings = state[year] ? state[year].warnings : [];
      return {
        ...state,
        [year]: {
          warnings: [...new Set([...currentWarnings, questionId])],
        },
      } as any;
    }

    case REMOVE_WARNING: {
      const {
        payload: { year, questionId },
      } = action;
      const yearState = state[year];
      if (!yearState) return state;
      return {
        ...state,
        [year]: {
          warnings: yearState.warnings.filter((warningId) => warningId !== questionId),
        },
      };
    }

    default:
      return state;
  }
};
type RootState = {
  plausibilityChecks: State;
};

const getWarnings =
  (year: number) =>
  (state: RootState): Array<string> =>
    state.plausibilityChecks[year] ? state.plausibilityChecks[year].warnings : [];

const hasWarnings =
  (year: number) =>
  (state: RootState): boolean =>
    Boolean(getWarnings(year)(state).length);

export const selectors = {
  getWarnings,
  hasWarnings,
};
