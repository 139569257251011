import { CustomerField } from 'src/screens/payment/payment.types';

import style from '../../../../assets/templates/common/style.css';
import { contentLinks } from '../../../../assets/templates/common/content-links';
import { initIframeResizer } from '../../../../assets/templates/web/iframe-resizer';
import { isWeb } from '../../../utils/platform';

import { stripe } from './stripe-js';

const getHtml = (customerFields: CustomerField[] = [], isPaypalEnabled: boolean) => `
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0">

    <style type="text/css">
      ${style}

      body {
        margin: 20px;
        height: ${isWeb ? '100%' : 'calc(100vh - 40px)'};
        display: flex;
        flex-direction: column;
      }

      h1 {
        margin: 0 0 20px;
      }

      #next-steps {
        background-color: inherit;
        margin: 0 0 20px 0;
        padding: 0 0 0 20px;
      }

      #next-steps li {
        color: var(--color-secondary-text);
        margin-bottom: 0;
      }

      #disclaimer,
      .second-step-variant #stepDescription {
        color: var(--color-secondary-text);
        margin-bottom: 20px;
        display: block;
      }

      #main {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .input-fields {
        flex: 1;
      }

      .coupon-input {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
        margin-bottom: 20px;
      }

      .coupon-button {
        color: var(--color-primary);
        font-family: var(--font-bold);
        background-color: white;
        outline:none;
      }

      #coupon-icon {
        margin-left: 10px;
        color: var(--color-primary);
        font-family: var(--font-bold);
      }

      #submit {
        width: 100%;
        padding: 15px 0;
        background-color: var(--color-primary);
        color: var(--color-light-text);
        border-radius: 10px;
        font-family: var(--font-medium);
        margin-bottom: 16px;
      }
      #submit span {
        color: var(--color-light-text);
      }

      #submit[disabled] {
        background-color: var(--color-fill);
      }

      .web #submit {
        display: none;
      }

      .input-field {
        margin: 0 0 30px 0;
      }

      .input-field--label {
        margin: 0 0 10px 0;
        font-family: var(--font-bold);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
        display: block;
      }

      .input-field--error {
        color: var(--color-error-text-light);
      }

      .input-field--element {
        border-bottom: 1px solid var(--color-border);
        padding: 0 0 10px 0;
        margin: 0 0 10px 0;
      }

      .input-field--element.StripeElement--focus {
        border-bottom-color: var(--color-secondary-text);
      }

      .input-field--element.StripeElement--invalid {
        border-bottom-color: var(--color-error-text-light);
      }

      #errors {
        color: var(--color-error-text-light);
        margin-bottom: 20px;
      }

      .content-link {
        text-decoration: none;
      }

      #summary {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
      }

      #summary-total {
        display: flex;
        flex-direction: column;
      }

      .summary-title {
        font-family: var(--font-bold);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
      }

      .summary-title-total {
        color: var(--color-secondary-text);
        margin-bottom: 4px;
      }

      .default-discount-invisible {
        display: none;
      }

      #summary-price {
        display: flex;
        flex-direction: row;
      }

      #summary-amount {
        margin: 0 0 10px 0;
        font-family: var(--font-bold);
        font-size: 24px;
      }

      #summary-original-amount {
        margin-right: 20px;
        font-family: var(--font-bold);
        font-size: 24px;
        text-decoration: line-through;
        color: var(--color-fill);
      }

      #stepTitle,
      #stepDescription {
        display: none;
      }

      #method-option-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
      }
      
      #payment-options-title {
        margin: 20px 0;
        font-weight: bold;
      }

      #method-option-title {
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 16px;
        color: #949494;
      }

      .method-option-divider {
        border-bottom: 1px solid #0F0C0F1A;
        width: 40px;
        margin: 0 16px;
      }

      #limited-offer {
        background-color: #E1F8E6;
        border-radius: 99px;
        padding: 0 8px;
        width: max-content;
        margin-bottom: 20px;
      }

      #limited-offer .badge-content {
        text-transform: uppercase;
        color: #115E21;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
      }

      .second-step-variant #stepTitle {
        margin-top: 20px;
        font-family: var(--font-bold);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
        display: block;
      }


      .second-step-variant .input-field--label {
          color: var(--color-secondary-text);
          font-size: 14px;
          display: block;
          text-transform: none;
          letter-spacing: normal;
          font-family: var(--font-book);
      }

      .txf-input {
        color: var(--color-primary-text);
        font-family: "Circular-Book", "Helvetica Neue", Arial, "sans-serif";
        font-size: 16px;
        font-smooth: "antialiased";
        padding: 0;
        width: 100%;
        outline: none;
      }

      .txf-input::placeholder {
        color: var(--color-fill);
      }

    </style>
  </head>
  <body class="second-step-variant ${isWeb ? ' web' : ''}">
    <h1 id="headline"><%= headline %></h1>
    <ul id="next-steps">
      <li><%= nextSteps.one %></li>
      <li><%= nextSteps.two %></li>
      <li><%= nextSteps.three %></li>
    </ul>

    <div id="summary-price">
      <label id="summary-original-amount"><%= summary.originalAmount %></label>
      <label class="summary-amount" id="summary-amount"><%= summary.amount %></label>
    </div>
    <div id="limited-offer">
      <p class="badge-content"><%= priceOfferBadge %></p>
    </div>
    
    <form id="coupon-form"></form>
      

    <p id="payment-options-title"><%= methodOptionsTitle %></p>

    <div id="express-checkout-element"></div>

    ${
      isPaypalEnabled
        ? `
    <div id="method-option-title-container">
      <div class="method-option-divider"></div>
      <p id="method-option-title"><%= cardOptionTitle %></p>
      <div class="method-option-divider"></div>
    </div>
    `
        : ''
    }

    <form id="payment-form"></form>

    <div id="main">
      <div class="input-fields">

        ${customerFields
          .map(
            (field) => `
          <div class="input-field">
            <label class="input-field--label" for="${field.id}">${field.label}</label>
            <div class="input-field--element" form="payment-form">
              <input
                name="${field.id}"
                id="${field.id}"
                class="txf-input"
                placeholder="${field.placeholder}"
                ${Object.keys(field.inputProps || {})
                  .map((propKey: any) => `${propKey}="${(field.inputProps || {})[propKey]}"`)
                  .join(' ')}
              />
            </div>
          </div>
        `,
          )
          .join('')}

        <div class="input-field">
          <label class="input-field--label" for="card-number"><%= cardNumber.label %></label>
          <div class="input-field--element" id="card-number" form="payment-form"></div>
          <span class="input-field--error"></span>
        </div>

        <div class="input-field">
          <label class="input-field--label" for="card-expiry"><%= cardExpiry.label %></label>
          <div class="input-field--element" id="card-expiry" form="payment-form"></div>
          <span class="input-field--error"></span>
        </div>

        <div class="input-field">
          <label class="input-field--label" for="card-cvc"><%= cardCvc.label %></label>
          <div class="input-field--element" id="card-cvc" form="payment-form"></div>
          <span class="input-field--error"></span>
        </div>

        <p id="errors" role="alert"></p>


      <p id="disclaimer" class="content-with-links"><%= disclaimer %></p>

      <div class="coupon-input" id="add-coupon">
        <button class="coupon-button" form="coupon-form"><%= coupon.add %></button>
      </div>
      <div class="coupon-input" id="remove-coupon">
        <button class="coupon-button" form="coupon-form"><%= coupon.remove %></button>
        <label id="coupon-icon" >&#10003;</label>
      <label><%= coupon.applied %></label>
    </div>

      <div id="summary">
        <div id="summary-total">
          <label class="summary-title summary-title-total"><%= summary.title %></label>
          <label class="summary-title default-discount"><%= summary.defaultDiscount10 %></p>
        </div>
        <div id="summary-price">
          <label id="summary-original-amount"><%= summary.originalAmount %></label>
          <label class="summary-amount" id="summary-amount"><%= summary.amount %></label>
        </div>
      </div>

      <div id="footer">
        <button id="submit" disabled form="payment-form">
          <span id="submit-text"><%= button %></span>
          <span style="display: none" id="loader">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </span>
        </button>
      </div>
      </div>
    </form>
  </body>
</html>
`;

export const getCreditCardTemplate =
  ({ isPaypalEnabled, price }: { isPaypalEnabled: boolean; price: number | null }) =>
  (customerFields?: CustomerField[]): string =>
    `
document.body.parentElement.innerHTML = \`${getHtml(customerFields, isPaypalEnabled)}\`;

${
  isWeb
    ? `
var iframeResizer = document.createElement('script');
iframeResizer.setAttribute('src', '/static/js/iframe-resizer/iframeResizer.contentWindow.min.js');
iframeResizer.onload = function () {
  (${initIframeResizer.toString()})();
};
document.body.parentElement.appendChild(iframeResizer);
`
    : ''
}

var stripe = document.createElement('script');
stripe.setAttribute('src', 'https://js.stripe.com/v3/');
stripe.onload = function () {
  (${stripe.toString()})(${price}, ${isPaypalEnabled});
  (${contentLinks.toString()})();
};
document.body.parentElement.appendChild(stripe);

true; // note: this is required, or you'll sometimes get silent failures
`;
