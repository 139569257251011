import * as React from 'react';
import { useBreakpointValue } from 'native-base';

import { navigation } from 'src/services/navigation-service';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ProductFetchContainer } from '../../../containers/product-fetch';
import { ItalyBillingAddressContainer } from '../../../screens/containers/italy-billing-address';
import { getProduct } from '../../../services/submissions';

type ActionParams = {
  onNext: () => void;
};
type Props = Navigation<
  'BillingAddress',
  {
    BillingAddress: NavigationParams<ActionParams>;
  }
>;

export const toBillingAddress: Action<ActionParams> = navigate('BillingAddress', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'BillingAddress',
  },
});

export const BillingAddressRoute: React.FC<Props> = ({ route }) => {
  const { onNext } = route.params;
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { getTranslationText } = useItalyIntl();

  useDefaultNavigationOptions({ id: 'it.billing-address.navigation.title' });

  return (
    <ProductFetchContainer productResolver={getProduct}>
      {({ product }: any) => {
        return (
          <ItalyBillingAddressContainer
            onNext={onNext}
            product={product}
            isLargeScreen={isLargeScreen}
            backNavigationText={getTranslationText('web.navigation.italy.back')}
            onPressBackNavigation={navigation.back}
          />
        );
      }}
    </ProductFetchContainer>
  );
};
