import { StyleSheet } from 'react-native';

import { theme } from 'src/components/core/theme';

export const styles = StyleSheet.create({
  row: {
    borderTopColor: theme.color.border,
    backgroundColor: theme.color.lightFill,
  },
  inner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    flex: 1,
    paddingRight: 8,
    paddingLeft: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  infoText: {
    textAlign: 'right',
    flexDirection: 'row-reverse',
    transform: [{ translateX: -10 }],
  },
  textNoIcon: {
    transform: [{ translateX: -30 }],
  },
  textWithInfo: {
    transform: [{ translateX: -30 }],
    flex: 2,
  },
  icon: {
    top: 0,
    left: 0,
    width: 50,
    height: 50,
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  notification: {
    backgroundColor: theme.color.salmonBackground,
    borderRadius: 10,
    height: 10,
    width: 10,
    marginTop: -10,
    marginLeft: -10,
  },
});
