import React, { Component } from 'react';

import { ProgressBar as ProgressBarComponent } from '../components/core';

export type Props = {
  progress: number;
  color?: string;
  unfilledColor?: string;
};
type State = {
  displayedProgress: number;
};
export class ProgressBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayedProgress: props.progress,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.progress < this.state.displayedProgress) {
      /* In the case that progress decreases, then display an 1% increase in progress.
          This occurs when the user edits a previous question, in turn creating more unanswered
         questions. For example, they edit the "number of children" question from 1 to 5. Now their
         category progress is lower than before the edit.
          This is in place so that the user does not get discouraged when the progress decreases.
          Cap faked progress at 95%.
      */
      const progressLimit = nextProps.progress + 0.2 > 0.95 ? 0.95 : nextProps.progress + 0.2;
      let fakeProgress = Math.floor((this.state.displayedProgress + 0.01) * 100) / 100;
      fakeProgress = fakeProgress > progressLimit ? progressLimit : fakeProgress;
      this.setState(() => ({
        displayedProgress: fakeProgress,
      }));
    } else {
      this.setState({
        displayedProgress: nextProps.progress,
      });
    }
  }

  render() {
    const { color, unfilledColor } = this.props;
    return (
      <ProgressBarComponent
        progress={this.state.displayedProgress}
        color={color}
        unfilledColor={unfilledColor}
      />
    );
  }
}
