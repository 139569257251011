import { CountryCodes } from '@taxfix/types';

import { StatusMiddleware } from 'src/stores/modules/status';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  getBoundNavigationsActions,
  getNavigationActions,
  safeResetNavigation,
} from 'src/routes/config-util';
import { getQuestionStores } from 'src/stores-legacy/util';
import { wayQuestionIds } from 'src/common/constants-it';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';

type NavigationWithParams = {
  routeName: string;
  customParams?: Record<string, any>;
};

const getRouteNameAndParams = (
  navigationStack: NavigationWithParams[],
  onNext: () => void,
  includeProgressParam = true,
  initialStackSize?: number,
): any => {
  const stackSize =
    typeof initialStackSize === 'number' ? initialStackSize : navigationStack.length;

  if (stackSize === 0) {
    return {
      routeName: undefined,
      routeParams: undefined,
    };
  }

  const { routeName, customParams } = navigationStack[navigationStack.length - 1];
  const routeParams = {
    onNext,
    progress: includeProgressParam ? navigationStack.length / stackSize : undefined,
    ...(customParams || {}),
  };

  if (navigationStack.length > 1) {
    return getRouteNameAndParams(
      navigationStack.slice(0, navigationStack.length - 1),
      () => getBoundNavigationsActions()[routeName]('screen', routeParams),
      includeProgressParam,
      stackSize,
    );
  }

  return {
    routeName,
    routeParams,
  };
};

export const checkAndUpdateUserData: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const selectedYear = settingsSelectors.selectedYear(getState());

  if (!selectedYear) {
    dispatch(failureAction('tax year is not defined'));
    return;
  }

  const questionStores = getQuestionStores(CountryCodes.IT);
  const questionStore = questionStores[selectedYear];
  const questionIds: string[] = Object.values(wayQuestionIds);
  const nodes = getNodesByIds(questionStore, questionIds);
  const quizmasterLight = getQuizmasterLight(questionStore, nodes);
  const occupation = quizmasterLight[wayQuestionIds.occupation].answer;
  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const navigationStack: NavigationWithParams[] = [];

  if (!occupation) {
    navigationStack.push({
      routeName: 'toProfileOccupation',
      customParams: {
        isNewUser: false,
        titleTranslationKey: 'it.digital-caf.qf.returning-users.occupation.header-title',
      },
    });
  }

  if (!firstName || !lastName) {
    navigationStack.push({
      routeName: 'toProfilePersonalData',
      customParams: {
        isNewUser: false,
        titleTranslationKey: 'it.digital-caf.qf.returning-users.personal-data.header-title',
        backButtonText: !occupation
          ? 'it.digital-caf.qf.returning-users.personal-data.back-button.occupation'
          : 'it.digital-caf.qf.returning-users.personal-data.back-button.home',
      },
    });
  }

  const finalOnNext = () => {
    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toStatus('screen'),
    ]);
  };

  const { routeName, routeParams } = getRouteNameAndParams(navigationStack, finalOnNext);

  if (routeName) {
    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions()[routeName]('screen', routeParams),
    ]);
  } else {
    next();
  }
};
