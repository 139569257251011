import React from 'react';
import { StyleSheet } from 'react-native';

import { Box, BodyPrimaryText, DisplayTitleText, theme, Button, IconButton } from '../core';

type Props = {
  onHappyPressed: () => void;
  onDisappointedPressed: () => void;
  onDismiss: () => void;
};
const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.overlay,
  },
  centerText: {
    textAlign: 'center',
  },
  imageContainer: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  },
});
export const InquiryFeelingComponent = ({
  onHappyPressed,
  onDisappointedPressed,
  onDismiss,
}: Props) => {
  return (
    <Box
      style={{
        backgroundColor: 'white',
        width: '80%',
        borderRadius: 10,
        justifyContent: 'space-between',
      }}
    >
      <Box left={2.5} right={2.5}>
        <Box alignHorizontally="center" alignVertically="center" top={2}>
          <DisplayTitleText style={styles.centerText} id="feedback-modal.title" />
          <Box bottom={1.5} />
          <BodyPrimaryText style={styles.centerText} id="feedback-modal.subtitle" />
        </Box>
      </Box>

      <Box top={2} left={2.5} right={2.5}>
        <Box direction="row" alignVertically="end">
          <IconButton iconKey="disappointed" onPress={onDisappointedPressed} />
          <IconButton iconKey="happy" onPress={onHappyPressed} />
        </Box>
      </Box>
      <Box alignHorizontally="center" top={1} bottom={1}>
        <Button
          type="secondary"
          style={{
            borderWidth: 0,
          }}
          translationKey="feedback-modal.dismiss"
          onPress={onDismiss}
          testId="feedback-modal.not-now"
        />
      </Box>
    </Box>
  );
};
