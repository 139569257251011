import React from 'react';
import { Keyboard } from 'react-native';
import { IntlShape, injectIntl } from 'react-intl';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import get from 'lodash/get';
import { Referrals } from '@taxfix/voucher-sdk';
import Config from 'react-native-config';
import { CountryCodes, UserConsents } from '@taxfix/types';

import withRegister, { WithRegister } from 'src/hocs/with-register';
import {
  selectors as referralDataSelectors,
  actions as referralDataActionCreators,
} from 'src/stores/modules/referral-data';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import type { State as RootState } from 'src/stores/store/initial';
import { WelcomeOverlayContent } from 'src/components/welcome-overlay-content';

import { UserSelectors, actions as UserActions } from '../../stores/modules/user';
import CredentialScrollAware from '../../components/auth/CredentialScrollAware';
import { hasCredentials } from '../../utils/credentials';
import { Box } from '../../components/core';
import { enableBioAuth } from '../../utils/biometricAuth';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { selectors } from '../../stores/modules/settings';
import { getSignUpLegalRequirements } from '../../services/signup';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { WithQuestionStore, withQuestionStore } from '../../utils/withQuestionStore';
import { WithLegalConditions, withLegalConditions } from '../../hocs/with-legal-conditions';
import { isWeb } from '../../utils/platform';
import {
  WithBackNavigationControl,
  withBackNavigationControl,
} from '../../hocs/with-back-navigation-control';
import { parseMarkdown } from '../../services/markdown-parser';
import HtmlContent from '../../components/html-content';
import { actions as OverlayActions } from '../../stores/modules/overlay';
import { getNavigationActions, safeResetNavigation } from '../../routes/config-util';
import { flagsQuestionIds, wayQuestionIds } from '../../common/constants-it';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';
import AcceptTermsWithCheckBox from '../../components/AcceptTermsWithCheckBox';

import { Credentials } from './signup-container.types';

type State = Credentials & {
  error: string | null | undefined;
  isLoading: boolean;
  checkedTerms: boolean[];
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
  referralDataActions: bindActionCreators(referralDataActionCreators, dispatch),
});

const mapStateToProps = (state: RootState) => {
  const selectedCountry = selectors.selectedCountry(state);
  const taxSeasonInfo = getTaxSeasonInfo(state);
  const isPreseason = taxSeasonInfo.isPreSeason;
  const privacyConfig = getSignUpLegalRequirements(selectedCountry, isPreseason);
  return {
    email:
      UserSelectors.getOnboardingEmail(state) ||
      selectors.selectCurrentCountrySpecifics(state)?.emailForNotifications,
    selectedCountry: selectors.selectedCountry(state),
    privacyConfig,
    refereeMetadata: referralDataSelectors.getRefereeMetadata(state),
    year: selectors.selectedYear(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type CredentialsProps = {
  onConfirm?: () => void;
  progress?: number;
};

type Props = CredentialsProps &
  WithNavigation &
  WithBackNavigationControl &
  WithLegalConditions &
  ReduxProps &
  WithRegister &
  WithQuestionStore & {
    intl: IntlShape;
    titleKey?: string;
    category?: string;
    quizmasterLight: QuizmasterLight;
  };

export class UndecoratedSignUpCredentials extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: props.email || '',
      phoneNumber: '',
      pin: '',
      error: undefined,
      isLoading: false,
      checkedTerms: new Array(props.privacyConfig.length).fill(false),
    };
  }

  getErrorKey = (): string =>
    ['EmailAddressInUse', 'EmailAddressInvalid'].indexOf(this.state.error as string) >= 0
      ? 'errorKeyEmail'
      : 'errorKey';

  handleConfirmSuccess = async (accessToken: string): Promise<void> => {
    const { category, quizmasterLight, referralDataActions } = this.props;
    if (category) {
      Analytics.log(AnalyticsEvent.categoryFinished, {
        category,
      });
    }

    this.checkBioAuthStatus();

    // SPIDCredentialsConsentGiven only saved for pre-season
    quizmasterLight[flagsQuestionIds.SPIDCredentialsConsentGiven].saveAnswer(true);
    quizmasterLight[flagsQuestionIds.gdprConsentGiven].saveAnswer(true);

    Analytics.log(AnalyticsEvent.createUserConsentRecordSuccess, {
      consentType: UserConsents.Type,
      consentAccepted: UserConsents.State.Approved,
    });

    (async () => {
      const { refereeMetadata } = this.props;
      if (!refereeMetadata) return;

      try {
        await Referrals.createReferee(Config.API_BASE_URL, accessToken, refereeMetadata);
        referralDataActions.refereeCreateSuccess();
        Analytics.log(AnalyticsEvent.referralVoucherBecameReady);
      } catch (error: any) {
        let errorMessage = error.message;

        if (error.response) {
          errorMessage = error.response.data.message;
        }

        Analytics.log(AnalyticsEvent.referralVoucherBecameReadyFailed, {
          errorMessage,
        });
      }
    })();
  };

  // ask the user for permission to enable bio auth process
  checkBioAuthStatus = (): void => {
    const userName: string = this.props.quizmasterLight[wayQuestionIds.firstName].answer;
    const userNameCapitalized = userName?.[0]?.toLocaleUpperCase() + userName?.slice(1);

    const userData = {
      emailAddress: this.state.email,
      password: this.state.pin,
      updateBioAuthPermission: this.props.userActions.updateBioAuthPermission,
    };
    enableBioAuth(userData, this.props.intl).then(() => {
      this.props.overlayActions.show('SuccessOverlay', {
        containerStyles: { height: 700, width: 600 },
        contentComponent: WelcomeOverlayContent,
        closeWithScreenTap: false,
        onNext: () => {
          this.props.overlayActions.hide();
          safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
        },
        translationValues: { userName: userNameCapitalized },
      });
    });
  };

  handleRegisterUser = (): Promise<void> => {
    const { disableGoBack } = this.props;

    Analytics.log(AnalyticsEvent.signUpInitiated);

    this.setState(
      {
        isLoading: true,
      },
      disableGoBack,
    );
    return this.props.handleSignup({
      onError: this.handleConfirmFailure,
      onSuccess: this.handleConfirmSuccess,
    });
  };

  handleConfirmFailure = (error: unknown): void => {
    const { enableGoBack } = this.props;
    const errorkey = get(error, 'response.data.message', 'ConnectionFailed');

    this.setState(
      {
        isLoading: false,
        error: errorkey,
      },
      () => {
        Keyboard.dismiss();
        enableGoBack();
      },
    );
  };

  handleChangeEmail = (email: string): void =>
    this.setState(
      {
        email,
        error: undefined,
      },
      () =>
        this.props.userActions.updateOnboarding({
          email,
        }),
    );

  handleChangePhoneNumber = (phoneNumber: string): void =>
    this.setState({
      phoneNumber,
    });

  handleChangePin = (pin: string): void => {
    this.setState(
      {
        pin,
      },
      () =>
        this.props.userActions.updateOnboarding({
          pin,
        }),
    );

    if (pin.length >= 4 && !isWeb) {
      // Hide keyboard so user can see T&C checkbox
      // https://github.com/taxfix/mobile-app/issues/3506
      Keyboard.dismiss();
    }
  };

  handleTermsChange = (index: number) => {
    return (): void => {
      this.setState((prevState) => {
        const checkedTermsClone = [...prevState.checkedTerms];
        checkedTermsClone.splice(index, 1, !checkedTermsClone[index]);
        return {
          checkedTerms: checkedTermsClone,
        };
      });
    };
  };

  handleInfoClick = (): void => {
    const translationKey = 'account.create.info-button';
    const contentInfoClick = this.props.intl.formatMessage({
      id: translationKey,
    });
    isWeb && this.props.selectedCountry === CountryCodes.IT
      ? this.props.overlayActions.show('InfoOverlay', {
          position: 'right',
          bodyComponent: () => (
            <Box flex>
              <HtmlContent content={parseMarkdown(contentInfoClick)} />
            </Box>
          ),
        })
      : this.props.navigationActions.toInformation('modal', {
          isMarkdown: true,
          translationKey: translationKey,
        });
  };

  render(): JSX.Element {
    const errorKey = {
      [this.getErrorKey()]: this.state.error,
    };
    const { titleKey = 'account.create.title', selectedCountry, privacyConfig } = this.props;
    const hasUserCheckAllCheckboxes = this.state.checkedTerms.every((isChecked) => isChecked);
    const variants =
      selectedCountry === CountryCodes.IT
        ? {
            emailVariant: 'italy',
            pinVariant: 'italy',
          }
        : {};

    const disabled =
      !hasCredentials(this.state.email, this.state.pin) || !hasUserCheckAllCheckboxes;

    return (
      // @ts-ignore
      <CredentialScrollAware
        leadKey="account.create.subtitle"
        titleKey={titleKey}
        confirmKey="account.create.confirm"
        onConfirm={this.handleRegisterUser}
        imageName={null}
        disabled={disabled}
        scrollable
        loading={this.state.isLoading}
        email={this.state.email}
        onEmailChange={this.handleChangeEmail}
        pin={this.state.pin}
        onPinChange={this.handleChangePin}
        phoneNumber={this.state.phoneNumber}
        onInfoClick={this.handleInfoClick}
        showProgress={this.props.progress != null}
        progress={this.props.progress}
        {...variants}
        {...errorKey}
      >
        <Box top={4}>
          {privacyConfig.map((term, index) => (
            <AcceptTermsWithCheckBox
              key={term.name}
              handleTermsChange={this.handleTermsChange(index)}
              onTermsClicked={this.props.handleTermsClicked}
              termsAccepted={this.state.checkedTerms[index]}
              isLoading={this.state.isLoading}
              translationKey={term.translationKey}
              selectedCountry={selectedCountry}
            />
          ))}
        </Box>
      </CredentialScrollAware>
    );
  }
}
export const CredentialsContainer = compose<React.ComponentType<CredentialsProps>>(
  injectIntl,
  connector,
  withRegister,
  withNavigation,
  withBackNavigationControl,
  withQuestionStore,
  withLegalConditions,
  withQuizmasterLight([
    wayQuestionIds.firstName,
    flagsQuestionIds.SPIDCredentialsConsentGiven,
    flagsQuestionIds.gdprConsentGiven,
  ]),
)(UndecoratedSignUpCredentials);
