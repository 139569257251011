import React from 'react';
import { observer } from 'mobx-react';
import { TreeNode } from '@taxfix/quizmaster/dist/types';

import { withQuestionStore } from '../../utils/withQuestionStore';
import QuestionStore from '../../stores-legacy/QuestionStore';
import Summaries from '../../biz-logic/Summaries';
import { SummaryGroup } from '../../_italy/quizmaster/components/summaries/summary-group';

type Props = {
  questionStore: QuestionStore;
  root: string;
  onEdit?: (arg0: TreeNode) => void;
};

const SummaryContainer = ({ questionStore, root, onEdit }: Props) => {
  const groups = Summaries.createGroups(questionStore, root);
  return (
    <>
      {groups.map<React.ReactNode>((group) => (
        <SummaryGroup key={group.id} group={group} onEdit={onEdit} />
      ))}
    </>
  );
};

// @ts-ignore
export default withQuestionStore(observer(SummaryContainer));
