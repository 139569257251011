import * as React from 'react';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// This package is only to fix issue happening on android
// and it doesn't export anything for ios so it's
// safe to ignore this eslint error here.
// eslint-disable-next-line import/no-unresolved
import BackgroundColor from 'react-native-background-color';
import { CountryCodes } from '@taxfix/types';

import { ToastBannerPresenter, ToastBannerProvider } from 'src/components/toast-banner';
import { NativeBaseProvider as ItalyThemeProvider } from 'src/_italy/_theme';
import { ZendeskServiceInitializer } from 'src/services/zendesk-service-initializer';

import { hydrateQuestionStores } from '../stores-legacy';
import { Loading } from '../components/loading';
import { AppInitChecks } from '../components/app-init-checks';
import { Box, KeyboardAvoidingView, theme } from '../components/core';
import { checkTrackingAvailability } from '../utils/tracking';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import {
  FirebaseUserPropertyKeys,
  setFirebaseUserProperties,
} from '../services/firebase-analytics';
import {
  actions as remoteConfigFirebaseActions,
  selectors as remoteConfigFirebaseSelectors,
} from '../stores/modules/remote-config-firebase';
import { selectCountry, selectYearForCountry } from '../services/country-and-year';
import { quizmasterSyncAnswersCountries } from '../utils/constants';

import { RootContainer } from './root';
import NavigatorContainer from './navigation';
import { SyncAnswersContainer } from './sync-answers';
import { DynamicLinkContainer } from './dynamic-link';
import { AppInitializer } from './app-initializer';
import AppStateManager from './app-state';
import { CodePushChecker } from './code-push';
import { CookieConsent } from './cookie-consent';
import { AppLandingTracker } from './app-landing-tracker';
import { BrazeLauncher } from './braze-launcher';
import { HotjarLauncher } from './hotjar-launcher';
import { ErrorBoundary } from './error-boundary';
import { EppoWrapper } from './eppo-wrapper';

type Props = {
  selectedCountry: CountryCodes;
  hasSelectedCountry: boolean;
  preferredLanguages?: Array<string>;
  requestUpdateRemoteConfig: typeof remoteConfigFirebaseActions.requestUpdate;
  retrieveConfig: typeof remoteConfigFirebaseActions.retrieveConfig;
  isRemoteConfigDirty: boolean;
  isRemoteConfigRetrieved: boolean;
  fbRandomNumber: number;
};
type State = {
  trackingAvailabilityChecked: boolean;
};

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
  hasSelectedCountry: settingsSelectors.hasSelectedCountry(stores),
  isRemoteConfigDirty: remoteConfigFirebaseSelectors.isDirty(stores),
  isRemoteConfigRetrieved: remoteConfigFirebaseSelectors.isConfigRetrieved(stores),
  fbRandomNumber: settingsSelectors.getFBRandomNumber(stores),
});

const mapDispatchToProps = (dispatch: any) => ({
  requestUpdateRemoteConfig: bindActionCreators(
    remoteConfigFirebaseActions.requestUpdate,
    dispatch,
  ),
  retrieveConfig: bindActionCreators(remoteConfigFirebaseActions.retrieveConfig, dispatch),
});

function getCountrySpecificProviders(countryCode: CountryCodes): React.ElementType {
  switch (countryCode) {
    case CountryCodes.IT:
      return ItalyThemeProvider;
    default:
      return React.Fragment;
  }
}

const InternationalizationRoot = () => (
  // @ts-ignore
  <RootContainer>
    <>
      <CookieConsent>
        <>
          {/* on web, we can track events only after user gives their consent to do so - STATISTICS in the Cookie consent */}
          <AppLandingTracker />
          <BrazeLauncher />
          <HotjarLauncher />
          <EppoWrapper />
        </>
      </CookieConsent>
      <KeyboardAvoidingView>
        <ToastBannerProvider>
          <NavigatorContainer />
          <ToastBannerPresenter />
        </ToastBannerProvider>
      </KeyboardAvoidingView>
    </>
  </RootContainer>
);

export class Bootstrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { fbRandomNumber } = props;
    this.state = {
      trackingAvailabilityChecked: false,
    };
    setFirebaseUserProperties({
      [FirebaseUserPropertyKeys.RandomNumber]: fbRandomNumber.toString(),
    });
    this.selectDefaultCountry();
    this.props.requestUpdateRemoteConfig();
  }

  async componentDidMount(): Promise<void> {
    if (Platform.OS === 'android') BackgroundColor.setColor(theme.color.background);
    await hydrateQuestionStores();
  }

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isRemoteConfigDirty && this.props.isRemoteConfigDirty) {
      this.props.retrieveConfig();
    }

    if (prevProps.isRemoteConfigDirty && !this.props.isRemoteConfigDirty) {
      selectYearForCountry();
    }

    if (!this.state.trackingAvailabilityChecked && this.props.hasSelectedCountry) {
      checkTrackingAvailability({
        selectedCountry: this.props.selectedCountry,
      }).then(() =>
        this.setState({
          trackingAvailabilityChecked: true,
        }),
      );
    }
  }

  selectDefaultCountry = (): void => {
    selectCountry(CountryCodes.IT);
  };

  render() {
    const { hasSelectedCountry, selectedCountry, isRemoteConfigRetrieved } = this.props;
    const { trackingAvailabilityChecked } = this.state;
    const isLoading =
      !hasSelectedCountry || !isRemoteConfigRetrieved || !trackingAvailabilityChecked;
    const CountrySpecificProviders = getCountrySpecificProviders(selectedCountry);

    return isLoading ? (
      <Loading />
    ) : (
      <AppInitializer>
        <Box flex>
          <AppInitChecks>
            <DynamicLinkContainer>
              {({ onInitialFetch, onDynamicLink }: any) => (
                <AppStateManager onInitialFetch={onInitialFetch} onDynamicLink={onDynamicLink}>
                  <ErrorBoundary>
                    <>
                      {quizmasterSyncAnswersCountries.includes(selectedCountry) && (
                        <SyncAnswersContainer />
                      )}
                      <CountrySpecificProviders>
                        {Platform.OS !== 'web' && <CodePushChecker />}
                        <InternationalizationRoot />
                      </CountrySpecificProviders>
                      <ZendeskServiceInitializer />
                    </>
                  </ErrorBoundary>
                </AppStateManager>
              )}
            </DynamicLinkContainer>
          </AppInitChecks>
        </Box>
      </AppInitializer>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
