import React from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { Answer, TreeNode, Option, UserResponse } from '@taxfix/quizmaster/dist/types';
import { SubmissionWithId } from '@taxfix/submissions-types';

import type { State as RootState } from 'src/stores/store/initial';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { useStores } from 'src/stores/hooks/mobx-stores-hook';
import { UNSUPPORTED_ROLLOVER_QUESTIONS } from 'src/taxfix-business-logic/constants/question-answer';

// Answers types with list of answers to display.
const MULTIPLE_OPTIONS_ANSWER_TYPES = Object.freeze(['multiple_select', 'multiple_choice']);

const SUPPORTED_ANSWERS_FOR_ROLLOVER = [
  ...MULTIPLE_OPTIONS_ANSWER_TYPES,
  'yes_no',
  'date',
  'string',
  'number',
  'distance',
  'duration',
  'global_address',
  'address',
];

export type UseDataRollover = {
  getRolloverAnswer: (question: TreeNode) => UserResponse | null;
  shouldRolloverSubtitleBeHidden: (question: TreeNode, rolloverAnswer: UserResponse) => boolean;
  isRolloverEnabled: boolean;
  isAllQuestionEnabled: boolean;
  rolloverSourceYearForSelectedYear: number | null;
  findRolloverSourceYear: (targetYear: number) => number | null;
};

const useDataRollover = (): UseDataRollover => {
  const selectedYear = useSelector(settingsSelectors.selectedYear);
  const submissions = useSelector((state) =>
    submissionSelectors
      .getActiveSubmissions(state as RootState)
      .reduce<Record<string, SubmissionWithId>>(
        (acc, submission) => ({ ...acc, [submission.year]: submission }),
        {},
      ),
  );

  const isRolloverEnabled = false;
  const isFullQuestionFlowSupported = false;
  const isAllQuestionEnabled = false;

  const [rolloverSourceYearForSelectedYear, setRolloverSourceYearForSelectedYear] = React.useState<
    number | null
  >(null);

  const { questionStores } = useStores();
  const { sourceQuestionStore, sourceYearResponses } = React.useMemo(() => {
    if (rolloverSourceYearForSelectedYear) {
      const sourceQuestionStore = questionStores[rolloverSourceYearForSelectedYear];
      sourceQuestionStore?.rebuildIfResponsesUpdated();
      const sourceYearResponses = sourceQuestionStore?.activeResponseJS;
      return { sourceQuestionStore, sourceYearResponses: sourceYearResponses };
    }

    return {};
  }, [rolloverSourceYearForSelectedYear, questionStores]);

  // Find the most recent year before or after the targetYear that has a submission.
  // This year will be used to rollover the data from.
  const findRolloverSourceYear = React.useCallback(
    (targetYear: number) => {
      // only  get years with submissions and cast them to numbers
      const submissionYears = Object.keys(submissions)
        .filter((year) => submissions[year])
        .map((key) => Number(key));
      // sort submission years in ascending order
      const sortedYears = [...submissionYears].sort((a, b) => a - b);
      if (sortedYears.length) {
        // get absolute difference for each submission year and target year, return the submission year with the smallest difference
        let smallestDiff = Infinity;
        let smallestIndex = 0;
        for (let i = 0; i < sortedYears.length; i++) {
          const diff = Math.abs(sortedYears[i] - targetYear);
          if (diff < smallestDiff) {
            smallestDiff = diff;
            smallestIndex = i;
          }
        }
        return sortedYears[smallestIndex];
      } else return null;
    },
    [submissions],
  );
  /**
   * Returns a new UserResponse removing the answers deleted from options.
   *
   * This function prevent to return non-existing answers from the list of pre-defined options in multi-selects or single-selects.
   * @returns a new UserResponse object or null.
   */
  const cleanRemovedAnswersFromOption = (
    rolloverAnswer: UserResponse,
    options: Option[] = [],
  ): UserResponse | null => {
    const newAnswer = { ...rolloverAnswer };

    // multiple-select
    if (newAnswer.answer instanceof Array) {
      const newAnswers = rolloverAnswer.answer.filter((answer: unknown) =>
        options.some((option) => option.id === answer),
      );

      if (newAnswers.length) {
        newAnswer.answer = newAnswers;
        return newAnswer;
      }
      return null;
    }

    // multiple-choice
    if (!options.some((option) => option.id === newAnswer.answer)) {
      return null;
    }

    return newAnswer;
  };

  /**
   * Rollover an answer if any of the following conditions are met:
   *  - Answer type is supported
   *    - Remote config isAllQuestionEnabled is true and the question is supported.
   *    - Remote config isFullQuestionFlowSupported is true and answer is mark as allowDataRollover in data editor.
   *    - is WAY category and answer is mark as allowDataRollover in data editor.
   */
  const getRolloverAnswer = React.useCallback(
    (node: TreeNode): UserResponse | null => {
      const { id: answerId, questionId, response } = node;
      if (!sourceYearResponses || !response || !sourceQuestionStore) {
        return null;
      }

      const isAllQuestionsSupported =
        isAllQuestionEnabled && !UNSUPPORTED_ROLLOVER_QUESTIONS.includes(questionId);

      const isFullQFSupported = isFullQuestionFlowSupported && response.allowDataRollover;

      const isWAYCategorySupported =
        sourceQuestionStore.isWhoAreYouQuestion(node) && response.allowDataRollover;

      const isAnswerTypeSupported = SUPPORTED_ANSWERS_FOR_ROLLOVER.includes(response.type);

      if (
        sourceYearResponses?.[answerId] &&
        isAnswerTypeSupported &&
        (isAllQuestionsSupported || isFullQFSupported || isWAYCategorySupported)
      ) {
        const rolloverAnswer = sourceYearResponses[answerId];

        // prevent to rollover an answer that doesn't exist within the options anymore (was remove or changed in data editor)
        if (MULTIPLE_OPTIONS_ANSWER_TYPES.includes(response.type)) {
          return cleanRemovedAnswersFromOption(rolloverAnswer, response.properties.options);
        }
        return rolloverAnswer;
      }

      return null;
    },
    [sourceYearResponses, sourceQuestionStore, isAllQuestionEnabled, isFullQuestionFlowSupported],
  );

  const shouldRolloverSubtitleBeHidden = React.useCallback(
    (node: TreeNode, answerValue?: Answer | null | undefined) => {
      let question;
      const questionTypesWithRestrictionsToShowRolloverSubtitle = 'multiple_select';

      if (node) {
        question = getRolloverAnswer(node);
      }

      const rolloverAnswer: UserResponse = question?.answer;

      // This handle the logic to hide title in specific cases
      // For example:
      // - Where in rollover mode an answer is No/None/Either
      const hideSubtitle =
        isRolloverEnabled &&
        rolloverAnswer instanceof Array &&
        rolloverAnswer.includes(node?.response?.properties.emptyValue) &&
        node?.response?.type === questionTypesWithRestrictionsToShowRolloverSubtitle;

      const showRolloverSubtitle = !isNil(rolloverAnswer) && isNil(answerValue) && !hideSubtitle;

      return showRolloverSubtitle;
    },
    [getRolloverAnswer, isRolloverEnabled],
  );

  React.useEffect(() => {
    let sourceYear = null;
    if (isRolloverEnabled && selectedYear) {
      sourceYear = findRolloverSourceYear(selectedYear);
    }
    setRolloverSourceYearForSelectedYear(sourceYear);
  }, [selectedYear, isRolloverEnabled]);

  return {
    getRolloverAnswer,
    isRolloverEnabled,
    isAllQuestionEnabled,
    rolloverSourceYearForSelectedYear,
    findRolloverSourceYear,
    shouldRolloverSubtitleBeHidden,
  };
};

export { useDataRollover };
