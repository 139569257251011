import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { compose, bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import DocumentPicker from 'react-native-document-picker';

import { useNavigation } from 'src/hooks/navigation-hook';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import {
  NavigateToNext,
  withPrefillRoute,
} from 'src/screens/containers/prefill/with-prefill-route';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { openCamera, openGallery } from 'src/services/camera-upload-documents';

import { useFilesUpload, useQuizmasterLight, useItalyIntl } from '../../_hooks';
import { flagsQuestionIds } from '../../../common/constants-it';
import { WithPrefillCreation, withPrefillCreation } from '../../../hocs/with-prefill-creation';
import {
  AllowedImageTypes,
  FileResponses,
  NativeFileResponse,
  RouteParams,
  Screen,
  Step,
} from '../types';
import { ID_CARD_STEPS } from '../constants';
import { combineImages, isFileValid } from '../utils';
import { pickFile } from '../../../services/document-picker';
import { actions as PrefillActions } from '../../../stores/modules/prefill';
import { TrackingDocSubTypes } from '../main/types';

import { IdCardUploadComponent } from './component';

type Props = WithPrefillCreation &
  RouteParams & {
    navigateToNext: NavigateToNext;
  };

const IdCardUploadContainer: React.FC<Props> = ({ createPrefill, onNext, navigateToNext }) => {
  const { intl } = useItalyIntl();
  const dispatch = useDispatch();
  const prefillActions = useMemo(() => bindActionCreators(PrefillActions, dispatch), [dispatch]);
  const overlayActions = useMemo(() => bindActionCreators(OverlayActions, dispatch), [dispatch]);
  const { safeResetNavigation, getNavigationActions, navigationActions } = useNavigation();

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId) as number;
  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;

  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.prefillUploadSkipped]);
  const {
    files,
    errorMessage,
    isLoading,
    resetFiles,
    onFilesSelected,
    onFilesRejected,
    onUploadToServer,
  } = useFilesUpload({
    overlayActions,
    quizmasterLight,
    navigateToNext,
    createPrefill,
    onNext,
  });

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [filesToSubmit, setFilesToSubmit] = useState<string[]>([]);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  const [filePickingError, setFilePickingError] = useState<string | null>(null);
  const [isReuploadClicked, setIsReuploadClicked] = useState<boolean>(false);

  // is used for merging images on native devices
  const refViewShot = useRef(null);

  const currentStep: Step = ID_CARD_STEPS[currentStepIndex];
  const isUploadScreen = currentStep.type === Screen.UPLOAD;
  const isLastStep = currentStepIndex === ID_CARD_STEPS.length - 1;

  useEffect(() => {
    // we're fetching the latest prefill before checking the status in handleUpload
    try {
      prefillActions.retrieveLatestPrefill(accessToken, userId, selectedYear);
    } catch (e) {
      // we swallow the error since the prefill.getAll call is not stable atm
      // we try to just populate the store before we call prefill.create
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // readyToSubmit is needed to start useEffect only when all upload flow is completed
    // there should always be 2 files to be merged into one
    if (!readyToSubmit || filesToSubmit.length !== 2) return;

    combineImages(filesToSubmit, refViewShot)
      .then((mergedFileString) => {
        if (!mergedFileString) {
          return;
        }
        setFilePickingError('');
        // promise is not handled because there is an internal try catch
        // inside onUploadToServer
        onUploadToServer(mergedFileString);
      })
      .catch((err) => {
        setFilePickingError('identity-upload.errors.generic-error');
      });
  }, [readyToSubmit, filesToSubmit]);

  const goToNextStep = useCallback((): void => {
    if (isLastStep) return;
    setCurrentStepIndex((prevStepIndex) => prevStepIndex + 1);
  }, [isLastStep]);

  const onReceiveAssets = useCallback(
    (files: FileResponses) => {
      if (files.length === 0) {
        return;
      }
      Analytics.log(AnalyticsEvent.docUploadInitiated, {
        documentType: TrackingDocSubTypes.IdCard,
      });
      onFilesSelected(files);
      if (!isReuploadClicked) {
        goToNextStep();
      }
    },
    [goToNextStep, isReuploadClicked, onFilesSelected],
  );

  const onReceiveMobileAssets = useCallback(
    (response: NativeFileResponse | NativeFileResponse[] | null) => {
      const result = Array.isArray(response) ? response : [response];
      const { hasError, isCanceled } = isFileValid(result);
      if (hasError) {
        setFilePickingError('identity-upload.errors.permissions-error');
        return;
      }

      if (isCanceled) {
        return; // do nothing
      }

      setFilePickingError('');
      onReceiveAssets(result as NativeFileResponse[]);
    },
    [onReceiveAssets, isReuploadClicked],
  );

  const onOpenFileBrowser = useCallback(async () => {
    try {
      const filesData = await pickFile(DocumentPicker.types.images as AllowedImageTypes);
      overlayActions.hide();
      onReceiveMobileAssets(filesData);
    } catch (err) {
      setFilePickingError('identity-upload.errors.permissions-error');
    }
  }, [onReceiveMobileAssets, overlayActions]);

  const overlayButtons = [
    {
      translationKey: 'requiredDocument.lead.capturePhoto',
      onPress: () => {
        overlayActions.hide();
        openCamera(navigationActions, (response: any) => onReceiveMobileAssets(response));
      },
    },
    {
      translationKey: 'requiredDocument.lead.uploadDocument',
      onPress: onOpenFileBrowser,
    },
    {
      translationKey: 'requiredDocument.lead.photoGallery',
      onPress: () => {
        openGallery(
          intl,
          (response: any) => {
            overlayActions.hide();
            onReceiveMobileAssets(response);
          },
          null,
          false,
        );
      },
    },
  ];

  const onContinuePress = (onClickFromWeb: () => void) => async () => {
    if (isUploadScreen) {
      setIsReuploadClicked(false);
      onClickFromWeb();
      return;
    }

    if (files.length === 0) {
      return;
    }

    if (!readyToSubmit) {
      // in case error happens with the picking of the 2nd file, the file with
      // the error won't be added to the files array, so here we don't need
      // to handle a case when there are 2 files and an error for the second
      // file happens
      setFilesToSubmit((prevFilesToSubmit) => [...prevFilesToSubmit, ...files]);
      resetFiles(); // prevents preview flickering
    }

    if (!isLastStep) {
      goToNextStep();
      return;
    }
    setReadyToSubmit(true);
    setFilePickingError('');
  };

  const onReuploadPress = (onClickFromWeb: () => void) => () => {
    Analytics.log(AnalyticsEvent.docUploadAgain, {
      documentType: TrackingDocSubTypes.IdCard,
    });
    setIsReuploadClicked(true);
    setReadyToSubmit(false);
    onClickFromWeb();
  };

  return (
    <IdCardUploadComponent
      refViewShot={refViewShot}
      uploadSide={currentStep.side}
      files={files}
      errorMessage={filePickingError || errorMessage}
      isUploadScreen={isUploadScreen}
      isLoading={isLoading}
      overlayButtons={overlayButtons}
      onContinuePress={onContinuePress}
      onReuploadPress={onReuploadPress}
      onReceiveAssets={onReceiveAssets}
      onFilesRejected={onFilesRejected}
    />
  );
};

type DecoratedProps = React.FC<RouteParams>;

export const IdCardUploadContainerDecorated = compose<DecoratedProps>(
  withPrefillRoute,
  withPrefillCreation,
)(IdCardUploadContainer);
