import { CountryCodes } from '@taxfix/types';

import { ConfigKeys, TaxSystems } from '../../../../stores/modules/dev-tools';

import { DebugMenuOptions, MenuOption } from './types';

export function buildTaxSystemOption(props: DebugMenuOptions): MenuOption {
  const { overlayActions, devToolsActions, devToolsTaxSystem, selectedCountry } = props;

  if (selectedCountry !== CountryCodes.DE) {
    return null;
  }

  const handleTaxSystem = () => {
    overlayActions.show('SelectOverlay', {
      selectedValue: devToolsTaxSystem,
      options: [
        {
          label: 'No override (use default)',
          value: TaxSystems.NoOverride,
        },
        {
          label: 'Only Legacy',
          value: TaxSystems.Legacy,
        },
        {
          label: 'Use router to randomly select system',
          value: TaxSystems.Router,
        },
      ],
      onSelect: (selectedTaxSystem: Record<string, any>) => {
        devToolsActions.setConfig(ConfigKeys.TaxSystem, selectedTaxSystem);
      },
    });
  };

  return {
    text: 'Select Tax System',
    onPress: handleTaxSystem,
    showArrow: true,
  };
}
