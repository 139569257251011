import { SubmissionReviewStatusIT, States as SubmissionStates } from '@taxfix/submissions-types';
import { useTheme } from 'native-base';
import { useSelector } from 'react-redux';

import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import type { State as RootState } from 'src/stores/store/initial';
import { StepIconType, TaxYearCardProps } from 'src/taxfix-components/src';

import { selectors as settingsSelectors } from '../../stores/modules/settings';

import { useItalyIntl } from './use-italy-intl';

export enum FlowStage {
  startFlow = 'startFlow',
  continueFlow = 'continueFlow',
  mandatoryQFCompleted = 'mandatoryQFCompleted',
  submissionCompleted = 'submissionCompleted',
  latestSubmissionResult = 'latestSubmissionResult',
  identificationRejected = 'identificationRejected',
  resubmission = 'resubmission',
  readyForProcess = 'readyForProcess',
  readyToSubmit = 'readyToSubmit',
  prefillIdDocumentUpload = 'prefillIdDocumentUpload',
  prefillDonation = 'prefillIdDocumentUpload',
  prefillPayment = 'prefillIdDocumentUpload',
  outOfSeason = 'outOfSeason',
  downloadTimeout = 'downloadTimeout',
  downloadTimeoutError = 'downloadTimeoutError',

  prefillDownloaded = 'prefillDownloaded',
  prefillStart = 'prefillStart',
  noPrefillDocuments = 'noPrefillDocuments',
  // Self signing stages
  pendingSelfSigningDocument = 'pendingSelfSigningDocument',
  signedSelfSigningDocument = 'signedSelfSigningDocument',
  // Modelo Reddit Signing stages
  pendingModelloRedditiDocument = 'pendingModelloRedditiDocument',
  signedModelloRedditiDocument = 'signedModelloRedditiDocument',
  submittedWithModelloRediti = 'submittedWithModelloRediti',
  // preseason stages
  preseasonCUDownloaded = 'preseasonCUDownloaded',
  preseasonCUStart = 'preseasonCUStart',
  seasonStartSoon = 'seasonStartSoon',

  //FreelanceExperience
  freelanceExperienceStart = 'freelanceExperienceStart',
  freelanceExperienceFinished = 'freelanceExperienceFinished',
}

export type CardProps = Omit<TaxYearCardProps, 'onPress'>;

export type CardSetting = {
  stage: FlowStage;
  handleOnPressCTA?: (buttonName: string) => void;
};

export type DashboardScreenContentProps = {
  stepperIcons?: Array<StepIconType>;
  dashboardSubtitle: string;
};

type Props = {
  getCardContent: (card: CardSetting) => CardProps;
  getDashboardScreenContent: (stage: FlowStage) => DashboardScreenContentProps;
};

export const useDashboard = (): Props => {
  const { getTranslationText } = useItalyIntl();

  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;
  const taxSeason = selectedYear + 1;
  const submissionReviewStatus = useSelector(submissionSelectors.getSubmissionReviewStatus);
  const anySubmissionCurrentYear = useSelector((state: RootState) =>
    submissionSelectors.getSubmissionsByYear(state, selectedYear),
  );
  const { colors } = useTheme();

  const postModelloRedditiSubmissionCredit = anySubmissionCurrentYear.some(
    (_submission) =>
      submissionReviewStatus === SubmissionReviewStatusIT.MrCredito &&
      _submission.state === SubmissionStates.Submitted,
  );

  const postSubmissionMRTitle = postModelloRedditiSubmissionCredit
    ? getTranslationText('it.dashboard-submitted-with-mr-credit.title')
    : getTranslationText('it.dashboard-submitted-with-mr-debit.title');

  const getDashboardScreenContent = (stage: FlowStage): DashboardScreenContentProps => {
    switch (stage) {
      case FlowStage.continueFlow:
        return {
          stepperIcons: ['progress', 'progress', 'disabled', 'disabled'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.continue-flow'),
        };
      case FlowStage.mandatoryQFCompleted:
        return {
          stepperIcons: ['success', 'progress', 'disabled', 'disabled'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.mandatory-QF-Completed'),
        };
      case FlowStage.resubmission:
        return {
          stepperIcons: ['success', 'progress', 'progress', 'disabled'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.resubmission'),
        };
      case FlowStage.latestSubmissionResult:
        return {
          stepperIcons: ['success', 'success', 'success', 'success'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.latest-submission-result'),
        };
      case FlowStage.identificationRejected:
        return {
          stepperIcons: ['success', 'warning', 'progress', 'disabled'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.identification-rejected'),
        };
      case FlowStage.readyForProcess:
        return {
          stepperIcons: ['success', 'success', 'success', 'progress'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.ready-for-process'),
        };
      case FlowStage.readyToSubmit:
        return {
          stepperIcons: ['success', 'success', 'success', 'progress'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.ready-to-submit'),
        };
      case FlowStage.submittedWithModelloRediti:
        return {
          stepperIcons: ['success', 'success', 'success', 'success'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.latest-submission-result'),
        };

      case FlowStage.pendingModelloRedditiDocument:
        return {
          stepperIcons: ['success', 'success', 'success', 'progress'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.modello-redditi-signing'),
        };
      default:
        return {
          stepperIcons: ['start', 'start', 'start', 'start'],
          dashboardSubtitle: getTranslationText('it.dashboard.subtitle.start-flow'),
        };
    }
  };

  const getCardContent = (card: CardSetting): CardProps => {
    const collapsableTitle = getTranslationText('it.dashboard.collapsable-title', {
      taxSeason,
    });

    switch (card.stage) {
      case FlowStage.continueFlow:
      case FlowStage.prefillIdDocumentUpload:
      case FlowStage.prefillPayment:
      case FlowStage.prefillDonation:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-continueFlow.title'),
          subtitle: getTranslationText('it.dashboard-continueFlow.subtitle'),
          buttonText: getTranslationText('it.dashboard-continueFlow.textButton'),
          sourceImageName: 'thankyou',
        };
      case FlowStage.mandatoryQFCompleted:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-continueFlow.title'),
          subtitle: getTranslationText('it.dashboard-continueFlow.subtitle'),
          buttonText: getTranslationText('it.dashboard-continueFlow.textButton'),
          sourceImageName: 'thankyou',
        };
      case FlowStage.resubmission:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText('it.dashboard-resubmission.collapsable-subtitle'),
          title: getTranslationText('it.dashboard-resubmission.title'),
          buttonText: getTranslationText('it.dashboard-resubmission.text-button'),
          titleColor: colors.greytones.white,
          sourceImageName: 'peopleTaxExpert',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      case FlowStage.identificationRejected:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText(
            'it.dashboard-identificationRejected.collapsable-subtitle',
          ),
          title: getTranslationText('it.dashboard-identificationRejected.title'),
          buttonText: getTranslationText('it.dashboard-identificationRejected.text-button'),
          titleColor: colors.greytones.white,
          sourceImageName: 'passport',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      case FlowStage.latestSubmissionResult:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText(
            'it.dashboard-latestSubmissionResult.collapsable-subtitle',
          ),
          title: getTranslationText('it.dashboard-latestSubmissionResult.title'),
          subtitle: getTranslationText('it.dashboard-latestSubmissionResult.subtitle'),
          buttonText: getTranslationText('it.dashboard-latestSubmissionResult.text-button'),
          titleColor: colors.greytones.white,
          sourceImageName: 'prefill2',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      case FlowStage.readyForProcess:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText(
            'it.dashboard-ready-for-process.collapsable-subtitle',
          ),
          title: getTranslationText('it.dashboard-ready-for-process.title'),
          buttonText: getTranslationText('it.dashboard-ready-for-process.text-button'),
          titleColor: colors.greytones.white,
          sourceImageName: 'prefillOff2023',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      case FlowStage.readyToSubmit:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText(
            'it.dashboard-ready-to-submit.collapsable-subtitle',
          ),
          title: getTranslationText('it.dashboard-ready-to-submit.title'),
          buttonText: getTranslationText('it.dashboard-ready-to-submit.text-button'),
          titleColor: colors.greytones.white,
          sourceImageName: 'prefillOff2023',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      case FlowStage.outOfSeason:
        return {
          collapsableTitle,
          title: getTranslationText('dashboard.card.out-of-season-title'),
          sourceImageName: 'outOfSeason',
        };
      case FlowStage.seasonStartSoon:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard.card.preseason-title'),
          sourceImageName: 'preseasonCalendar',
        };
      case FlowStage.pendingSelfSigningDocument:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-pendingSelfSigning.title'),
          subtitle: getTranslationText('it.dashboard-pendingSelfSigning.subtitle'),
          buttonText: getTranslationText('it.dashboard-pendingSelfSigning.text-button'),
          sourceImageName: 'peopleTaxExpert',
        };
      case FlowStage.pendingModelloRedditiDocument:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-pendingModelloRedditi.title'),
          buttonText: getTranslationText('it.dashboard-pendingModelloRedditi.text-button'),
          titleColor: colors.greytones.white,
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
          sourceImageName: 'signature',
        };
      case FlowStage.signedModelloRedditiDocument:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-modelloRedditiSigned.title', { taxSeason }),
          subtitle: getTranslationText('it.dashboard-modelloRedditiSigned.subtitle'),
          buttonText: getTranslationText('it.dashboard-modelloRedditiSigned.text-button'),
          sourceImageName: 'prefillOff2023',
        };
      case FlowStage.submittedWithModelloRediti:
        return {
          collapsableTitle,
          collapsableSubtitle: getTranslationText(
            'it.dashboard-submitted-and-signed-mr.collapsable-subtitle',
          ),
          titleColor: colors.greytones.white,
          buttonText: getTranslationText('it.dashboard-latestSubmissionResult.text-button'),
          sourceImageName: 'prefill2',
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
          title: postSubmissionMRTitle,
        };
      case FlowStage.downloadTimeout:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-download-timeout.title'),
          paragraph: getTranslationText('it.dashboard-download-timeout.paragraph'),
          sourceImageName: 'downloadProcess',
          titleColor: colors.greytones.white,
          bgColor: colors.brand.brandGreen,
          maxWidthText: '100%',
        };
      case FlowStage.downloadTimeoutError:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-download-timeout-error.title'),
          subtitle: getTranslationText('it.dashboard-download-timeout-error.subtitle'),
          buttonText: getTranslationText('it.dashboard-download-timeout-error.textButton'),
          sourceImageName: 'thankyou',
        };
      case FlowStage.preseasonCUStart:
      case FlowStage.preseasonCUDownloaded:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-preseason.title'),
          subtitle: getTranslationText('it.dashboard-preseason.subtitle'),
          sourceImageName: 'calendar',
        };
      case FlowStage.freelanceExperienceStart:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-freelance-start.title'),
          subtitle: getTranslationText('it.dashboard-freelance-start.subtitle'),
          buttonText: getTranslationText('it.dashboard-freelance-start.textButton'),
          sourceImageName: 'thankyou',
        };
      case FlowStage.freelanceExperienceFinished:
        return {
          collapsableTitle,
          titleColor: colors.greytones.white,
          bgColor: colors.brand.brandGreen,
          title: getTranslationText('it.dashboard-freelance-finished.title'),
          sourceImageName: 'peopleTaxExpert',
        };
      default:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard-startFlow.title'),
          subtitle: getTranslationText('it.dashboard-startFlow.subtitle'),
          buttonText: getTranslationText('it.dashboard-startFlow.text-button'),
          sourceImageName: 'thankyou',
        };
    }
  };

  return { getCardContent, getDashboardScreenContent };
};
