/*
 * WARNING! if you change this file update the version of the document
 * in the file assets/docs/index.js
 */
export default `


<h1>Informativa sul trattamento dei dati personali</h1>

<p><b>Data di entrata in vigore: Marzo 2023</b></p>
<p><b>Ultimo aggiornamento: Marzo 2024</b></p>
<hr>
<p>Ai sensi dell’art. 13 del Reg. (UE) 2016/679 (il “<b>GDPR</b>”) la presente informativa sulla privacy (l’“<b>Informativa</b>”) descrive il trattamento di dati personali effettuato tramite le piattaforme mobile (scaricabile dagli app stores) e web (accessibile al seguente link: https://taxfix.it) per la compilazione della dichiarazione di redditi online, di titolarità di Taxfix SE (l’“<b>App</b>” o anche “<b>App/Webapp</b>”).<br>
  Quando  gli  utenti  utilizzano  l’App/Webapp  o  usufruiscono  dei  suoi  servizi  vengono raccolte informazioni che li riguardano. Per questo motivo, l’Informativa descrive chi sono  i  titolari  del  trattamento,  quali  dati  sono  raccolti,  le  finalità  e  le  modalità  di trattamento degli stessi e le misure di sicurezza che vengono adottate per proteggerli.
</p>

<h1>§ 1 - Titolare e Contitolari del trattamento</h1>

<ul>
    <li><p>1.1 Ai fini di cui alla presente Informativa, il titolare del trattamento dei dati personali raccolti,   elaborati   e   conservati   attraverso   l’App/Webapp,   o   attraverso   le piattaforme di comunicazione ad essere correlate, è la società Taxfix SE, con sede  legale  in  via  Köpenicker Str. 122, 10179 Berlino  (di  seguito  “<b>Taxfix</b>”  o “<b>Titolare</b>”).<br>
            È sempre possibile contattare Taxfix telefonicamente al numero +49 30 92106949 oppure via e-mail all’indirizzo: privacy@taxfix.de.<br>
            Per  eventuali  domande  riguardanti  il  trattamento  dei  dati  effettuato  nel  contesto dell’utilizzo  dell’App,  gli  utenti  dell’App  (gli  “<b>Utenti</b>”  o  “<b>Interessati</b>”,  o  anche singolarmente l”<b>Utente</b>” o “<b>Interessato</b>”) possono rivolgersi in qualsiasi momento anche  al  responsabile  della  protezione  dei  dati  (“<b>DPO</b>”)  designato  da  Taxfix, scrivendo ai contatti sopra indicati.
    </p></li>
    <li><p>1.2 Al fine di offrire agli Utenti un servizio di supporto completo nella compilazione e revisione delle dichiarazioni dei redditi, Taxfix si avvale dell’ausilio di Taxfix Italia S.t.p.r.l., con sede legale in Corso Giacomo Matteotti, 10, 20121 Milano (di seguito, “<b>Taxfix Italia</b>” o il “<b>Commercialista</b>”). Taxfix Italia mette a disposizione degli Utenti un team di consulenti fiscali e commercialisti abilitati all’esercizio della professione di commercialista in Italia che erogano tramite l’App servizi di consulenza fiscale (i “<b>Servizi  di  Consulenza</b>”),  come  definiti  nelle  Condizioni  Generali  di  Servizio (accessibili in App/Webapp o sul sito taxfix.it).<br>
            È sempre possibile contattare Taxfix Italia via e-mail all’indirizzo support@taxfix.it. Taxfix  non  fornisce  alcuna  consulenza  fiscale,  ma  gestisce  il  software  e  la piattaforma con cui vengono raccolti i Dati Personali (ivi compresi i Dati Fiscali – come  di  seguito  definiti)  e  attraverso  la  quale  il  Commercialista  può  accedere  a ogni  informazione  utile  per  la  preparazione  della  dichiarazione  dei  redditi  e,  in generale, l’erogazione dei Servizi di Consulenza.
    </p></li>
    <li><p>1.3 Taxfix  e  Taxfix  Italia  determinano  congiuntamente  le  finalità  e  le  modalità  del trattamento   dei   dati   personali   nel   contesto   dell’erogazione   dei   Servizi   di Consulenza,  e  sono  pertanto  –  con  riferimento  a  tale  contesto  –  contitolari  del trattamento, (i “<b>Contitolari</b>” o singolarmente il “<b>Contitolare</b>”).<br>
            Ai  sensi  dell’art.  26  del  GDPR,  i  Contitolari  hanno  disciplinato  –  in  modo trasparente, mediante un accordo di contitolarità del trattamento (l’“<b>Accordo</b>”) – le rispettive responsabilità in merito all’osservanza degli obblighi derivanti dal GDPR, con  particolare  riguardo  all’esercizio  dei  diritti  dell’Interessato  e  le  rispettive funzioni  di  comunicazione  delle  informazioni  di  cui  agli  artt.  13  e  14  del  GDPR. Il contenuto essenziale dell’Accordo è disponibile <a href="https://taxfix.it/wp-content/uploads/2023/07/2023-07-28-ESSENTIAL-CONTENT-OF-THE-JOINT-CONTROLLERSHIP-AGREEMENT-ITA.pdf" target="_blank">qui</a>.
    </p></li>
</ul>


<h1>§ 2 -  Dati personali</h1>

<ul>
    <li><p>2.1 <u>Dati di utilizzo raccolti automaticamente dall’App/Webapp</u><br>
          Ogni qualvolta gli Utenti accedono e si interfacciano con l’App, i sistemi informatici e le procedure software preposte al funzionamento della stessa acquisiscono, nel corso  del  loro  normale  esercizio,  i  dati  di  accesso,  navigazione  e  utilizzo dell’App/Webapp  (ad  esempio:  informazioni  sul  device  o  sul  browser  utilizzato dall’Utente, pagine visitate e dettagli sulle singole visualizzazioni in App/Webapp, data,  ora  e  durata  di  ciascuna  visita,  nonché  altri  parametri  relativi  al  sistema operativo e all’ambiente informatico dell’Utente – i “<b>Dati di Utilizzo</b>”).<br>
          In  particolare,  scaricando  e  installando  l’App  da  un  qualsiasi  app  store,  saranno trasmesse  al  provider  del  rispettivo  app  store  (e.g.  Google  o  Apple)  alcune informazioni  riguardanti  l’Utente  (come  nome  utente,  indirizzo  e-mail,  codice cliente  dell’account  app  store,  ora  del  download,  identificativo  del  dispositivo). Taxfix  non  ha  alcuna  influenza  sulla  raccolta  di  questi  dati  e  non  ne  è  pertanto responsabile.<br>
          In questa fase, l’Utente ha la possibilità di proteggere l’accesso al proprio account Taxfix sul proprio dispositivo mediante un codice PIN, che può essere modificato in qualsiasi momento nelle impostazioni dell’account.
          Infine, per migliorare il funzionamento dell’App/Webapp o per ripristinarlo in caso di  crash  (cioè  la  chiusura  inaspettata  dell’App/Webapp  a  causa  di  un  errore  di programma o l’assenza di reazioni alle azioni dell’Utente), l’App/Webapp invierà a Taxfix messaggi di errore. Tali messaggi di errore non contengono dati personali ma  unicamente  dati  tecnici  sul  dispositivo  utilizzato  dall’Utente  e  informazioni relative al punto del codice software dell’App che ha causato l’errore.
    </p></li>
    <li><p>2.2 <u>Dati comunicati dagli Utenti</u><br>
          L’App/Webapp,   scaricabile   da   qualsiasi   app   store,   o   in   versione   desktop accessibile  da  browser,  consente  agli  Utenti  di  effettuare  tutte  le  operazioni  di compliance  fiscale  relative  alla  trasmissione  delle  dichiarazioni  dei  redditi,  dalla registrazione   all’inserimento   di   dati   personali   e   informazioni   fiscali,   fino alla condivisione  dei  Dati  Fiscali  (come  di  seguito  definiti)  al  Commercialista  che  si occuperà   della   preparazione   e   trasmissione   della   dichiarazione   dei   redditi all’Agenzia delle Entrate.<br>
          Al primo avvio dell’App all’Utente è richiesto l’inserimento delle  sue  fonti  di  reddito.  Queste  informazioni  sono  necessarie  per  poter stabilire se l’App e i Servizi di Consulenza del Commercialista sono adatti ai fini della predisposizione della dichiarazione dei redditi dell’Utente che la richiede. In sede di registrazione, l’Utente dovrà inoltre fornire il suo indirizzo e-mail, e, a sua scelta,  il  numero  di  telefono,  necessari  per  creare  l’account  utente  Taxfix (l’“<b>Account</b>”)  e  contattare  l’Utente  in  caso  di  necessità  e/o  per  riscontrare  sue richieste.<br>
          Interagendo  con  l’App  –  ad  esempio  registrandosi,  utilizzando  i  Servizi  di Consulenza,  comunicando  con  il  supporto  –  gli  Utenti  comunicano  una  serie  di informazioni  ad  essi  riferibili  (i  “<b>Dati  Comunicati</b>”),  riconducibili  alle  seguenti principali  categorie:  <b>(i)</b>  dati  identificativi  (come  nome,  cognome,  luogo  e  data  di nascita, codice fiscale, indirizzo di residenza); <b>(ii)</b> dati di contatto (come numero di telefono,  indirizzo  e-mail);  e  <b>(iii)</b>  dati  fiscali  e  finanziari  (come  informazioni  sul reddito,  documenti  fiscali,  informazioni  di  fatturazione  e  pagamento  –  i  “<b>Dati Fiscali</b>”). I Dati di Utilizzo, i Dati Comunicati e i Dati Fiscali sono di seguito definiti “<b>Dati Personali</b>” o “<b>Dati</b>”.
      </p>
      <hr>
      <p>In particolare, la presentazione della dichiarazione dei redditi richiede, per motivi di  legge,  un’identificazione  univoca  del  soggetto  d’imposta.  Tale  identificazione avviene  a  fronte  di  un  documento  d’identità  ufficiale  (quali carta d’identità, patente di guida o passaporto, acquisiti per mezzo della fotocamera del dispositivo dell’Utente o per caricamento del rispettivo file elettronico) e previa verifica dei dati ivi contenuti. Le immagini   fotografiche   presenti   nei   documenti   d’identità   saranno   utilizzate esclusivamente  ai  fini  dell’identificazione  del  soggetto  d’imposta  e,  allo  stesso scopo,  messe  a  disposizione  del  Commercialista,  il  quale  è  tenuto  per  legge  a poter  fornire  informazioni  su  chi  ha  autorizzato  una  trasmissione  di  documenti fiscali all’Agenzia delle Entrate. Se necessario, i dati identificativi possono essere confrontati con i dati fiscali forniti per verificare l'identità dell’Utente.<br>
          Per    determinate    informazioni    fiscali,    l’Agenzia    delle    Entrate    richiede documentazione  delle  spese  sostenute;  un  elenco  di  tali  documenti  necessari  è disponibile nell’App.<br>
          Taxfix Italia offre la possibilità all’Utente di inserire nella dichiarazione dei redditi informazioni e dati che vengono messi a sua disposizione, all’interno del suo cassetto fiscale, dall’Agenzia delle Entrate e da soggetti terzi (in particolare dal datore di lavoro, dall'ufficio previdenziale competente, da aziende sanitarie, intermediari bancari, etc..). L’Utente potrà scegliere su suo esplicito consenso di condividere con Taxfix Italia i dati personali accedendo con le proprie credenziali SPID al portale https://portale.agenziaentrate.gov.it/ al fine dello scarico della documentazione richiesta per la fruizione del servizio offerto da Taxfix Italia. La base giuridica per il trattamento dei dati è l'articolo 6, paragrafo 1, comma 1, lettera b del GDPR (e.g. esecuzione di un contratto). Per permettere all'Utente il recupero dei propri dati dal portale dell' Agenzia delle Entrate, Taxfix Italia ha scelto come fornitore di servizi CWBI CodiceWeb Business Innovation S.r.l., un prestatore di servizi riconosciuto dall’AgID come soggetto aggregatore privato.<br>
          Taxfix Italia ha stipulato con CWBI un accordo per il trattamento dei dati per garantire che il trattamento dei vostri dati avvenga in conformità alle leggi vigenti.<br>
          I Dati Personali contenuti nella  documentazione condivisa dall’Utente (e.g. titolo, cognome, nome, stato civile, occupazione, indirizzo, lavoro, datore di lavoro, dati della certificazione dei redditi  da  lavoro,  abitazioni,  proventi  da  redditi  da  capitale  e  altri  proventi, assicurazioni,  spese  mediche,  reversibilità,  disabilità,  persone  assistite,  dati personali  dei  figli  conviventi  inclusi  i  costi  per  i  figli,  informazioni  su  disabilità  e reversibilità, genitori o altre persone, donazioni, spese domestiche, pagamento di assegni di mantenimento) vengono raccolti da Taxfix e messi a disposizione del Commercialista  che  li  utilizzerà  per  predisporre  la  dichiarazione  dei  redditi  e per fornire i Servizi di Consulenza.<br>
          I Dati Comunicati possono includere, quindi, anche dati personali appartenenti alle c.d. “categorie particolari di dati personali” ai sensi dell’art. 9 del GDPR (come, ad esempio dati sulla salute, eventuali spese per cure, informazioni sulla preferenza di una confessione religiosa e/o ente senza scopo di lucro e/o partito politico e/o associazione culturale inclusi nel Modello 730-1), ai fini della corretta trasmissione delle dichiarazioni dei redditi tramite la compilazione di tutti i campi obbligatori ai sensi di legge, nonché ai fini di ogni e qualsivoglia ulteriore trattamento previsto o necessario al fine di adempiere agli obblighi previsti dalla legge e/o dal contratto concluso con il Commercialista (“<b>Contratto di Incarico Professionale</b>”, disponibile in App/Webapp e consultabile sul sito taxfix.it).
    </p></li>
</ul>

<h1>§ 3 –  Principi   generali   di   protezione   dei   dati   adottati   da   Taxfix   e   dal Commercialista</h1>

<ul>
    <li><p>3.1 Il rispetto dei principi di liceità, correttezza, trasparenza del trattamento dei dati personali, nonché di ogni altro principio imposto dal GDPR o dalla normativa applicabile sulla protezione dei dati personali (ivi incluso il D. Lgs. 196/2003 come modificato dal D. Lgs 101/2018 – il “<b>Codice Privacy Italiano</b>”) è per noi essenziale.<br>
          I Dati Fiscali rimangono di esclusiva proprietà dell’Utente che li ha forniti. Taxfix e il Commercialista tratteranno i Dati Personali – nella piena consapevolezza della loro importanza e “sensibilità” – esclusivamente per le finalità illustrate nella presente Informativa, nonché nelle Condizioni Generali di Utilizzo dell’App/Webapp e nel Contratto di Incarico Professionale concluso da ciascun Utente con Taxfix Italia.<br>
          Taxfix e il Commercialista lavorano a stretto contatto per fornire agli Utenti un’esperienza utente fluida e dinamica nella compilazione e presentazione delle dichiarazioni dei redditi e si impegnano a trattare i Dati Personali nella misura più ridotta possibile e in maniera sicura (ossia rispettando i principi di minimizzazione e sicurezza nel trattamento). I Contitolari adottano a tal fine moderni metodi di cifratura dei dati e limitano la quantità di dati raccolti e trattati al minimo necessario. I Dati Fiscali vengono debitamente codificati e quindi archiviati in modo sicuro, tramite conservazione sostitutiva, come previsto dalla normativa applicabile. Anche la trasmissione delle dichiarazioni dei redditi all’Agenzia delle Entrate avviene tramite connessioni criptate.
    </p></li>
</ul>



<h1>§ 4 – Finalità e basi giuridiche del trattamento</h1>

<ul>
  <li><p>4.1 I <u>Dati di Utilizzo</u> saranno utilizzati: <b>(i)</b> al fine di monitorare il corretto funzionamento dell’App; <b>(ii)</b> in forma anonima e aggregata, per finalità statistiche legate alla comprensione di come l’App viene utilizzata dagli Utenti, per migliorare la facilità di accesso e aumentarne l’attrattività, nonché <b>(iii)</b> per rilevare eventuali problemi tecnici il prima possibile.<br>
        Detti trattamenti trovano la propria <b>base giuridica</b> nel legittimo interesse del Titolare al miglioramento dei propri prodotti e dei propri servizi digitali, e risultano compatibili con la posizione degli Interessati in quanto: (a) il monitoraggio del funzionamento dell’App e i dati statistici correlati al suo utilizzo non comportano identificazione diretta; e (b) l’interesse del Titolare appare ragionevolmente essere proprio anche degli Utenti, i quali potranno così fruire di un’App sempre più performante e ottimizzata.
  </p></li>
  <li><p>4.2 I <u>Dati Comunicati</u> saranno utilizzati esclusivamente per: <b>(i)</b> permettere all’Utente di registrarsi all’App; <b>(ii)</b> permettere l’erogazione di servizi (tra cui i servizi di consulenza collegati alla Dichiarazione dei redditi ed altri servizi finanziari) tramite l’App,via telefono e via videoconferenza (per il tramite di varie piattaforme provider); <b>(iii)</b> dare un riscontro ad eventuali richieste che gli Utenti dovessero avanzare con riferimento ai servizi erogati e/o all’attività dei Contitolari; <b>(iv)</b> permettere ai Contitolari di adempiere a obblighi di legge gravanti sugli stessi; nonché <b>(v)</b> garantire il diritto di difesa di ciascun Contitolare in sede giudiziale o arbitrale con riferimento ad eventuali controversie relative all’esecuzione dei contratti conclusi con ciascun Contitolare.<br>
          La <b>basi giuridiche</b> di tali trattamenti sono: (a) la necessità di eseguire misure contrattuali e precontrattuali, o attività comunque legate all’esecuzione di un contratto, (b) e di adempiere ai connessi obblighi di legge gravanti sul Titolare; (c) nonché il legittimo interesse di ciascun Contitolare alla difesa in sede giudiziale. Legittimo interesse che, nel bilanciamento degli interessi contrapposti tra le parti, non comporta una violazione dei diritti e delle libertà degli Interessati, essendo la difesa giudiziale un diritto garantito dall’ordinamento giuridico.<br>
          Il conferimento dei Dati Comunicati di cui sopra è necessario, poiché indispensabile all’esecuzione delle finalità per cui gli stessi sono trattati.
  </p></li>
</ul>

<h1>§ 5 – Destinatari dei Dati</h1>

<ul>
  <li><p>5.1 I Dati Personali saranno comunicati e potranno essere conosciuti da: <b>(i)</b> dipendenti e/o collaboratori a qualsiasi titolo dei Contitolari, debitamente istruiti e/o designati al trattamento e <b>(ii)</b> società controllate, controllanti e/o in qualsivoglia modo affiliate a Taxifx per l’espletamento delle finalità di cui sopra; nonché <b>(iii)</b> da soggetti terzi che prestano servizi accessori o strumentali alle attività dei Contitolari, quali ad esempio legate allo sviluppo, l’erogazione, la manutenzione e la gestione operativa del sito, specificamente nominati responsabili del trattamento. Una lista dei soggetti nominati responsabili può essere richiesta a Taxfix tramite gli indirizzi di contatto indicati nella presente Informativa.
  </p></li>
  <li><p>5.2 In particolare, Taxfix si avvale di servizi tecnici (ad es. hosting su server) di Google Inc., nel rispetto dei più elevati standard tecnici di sicurezza. Inoltre, i Dati Comunicati saranno debitamente verificati dal Commercialista, e quindi comunicati alle autorità fiscali italiane tramite il Commercialista. Di conseguenza, il Commercialista riceve da Taxfix e concede a Taxfix l’accesso ai Dati Comunicati, nonché ad eventuali comunicazioni con l’Agenzia delle Entrate in modo da poter informare l’Utente tramite l’App o via e-mail.
  </p></li>
  <li><p>5.3 La comunicazione dei Dati avviene, in ogni caso, solo qualora: <b>(i)</b> l’Utente abbia fornito espressamente il proprio consenso alla cessione dei Dati; <b>(ii)</b> la cessione sia necessaria per accertare, esercitare o difendere un diritto dei Contitolari in sede giudiziaria e non vi sia motivo di ritenere che l’Utente abbia un interesse cogente da tutelare che giustifichi la mancata cessione dei Dati; o <b>(iii)</b> la cessione sia consentita dalla legge e necessaria per l’adempimento di un obbligo contrattuale o per l’esecuzione di misure precontrattuali adottate su richiesta o per l’adempimento di un obbligo legale.
  </p></li>
  <li><p>5.4 I trattamenti dei Dati descritti nella presente Informativa possono essere parzialmente svolti dai provider di servizi terzi (e.g. CWBI, Stripe, Paypal, Adjust, Zendesk, Whatsapp e Segment) di cui Taxfix e il Commercialista si avvalgono. Oltre ai fornitori menzionati nella presente Informativa, possono rientrare in questa definizione in particolare centri di calcolo incaricati della conservazione delle nostre banche dati, fornitori IT che si occupano della manutenzione dei nostri sistemi nonché aziende di consulenza. I Dati vengono ceduti ai nostri fornitori esclusivamente per l’adempimento dei compiti ad essi correlati. Tutti i fornitori sono stati accuratamente selezionati e istruiti da Taxfix e dispongono di mezzi tecnici e organizzativi idonei alla tutela dei diritti degli Interessati e vengono sottoposti a regolari controlli da parte di Taxfix e del Commercialista. Come indicato al precedente punto 5.1 della presente Informativa, tali fornitori sono stati specificamente nominati responsabili esterni del trattamento ai sensi dell’art. 28 del GDPR.
  </p></li>
</ul>

<h1>§ 6 – Luogo del trattamento e trasferimento all’estero di Dati</h1>
<ul>
  <li><p>6.1 Il trattamento dei Dati Personali avrà luogo presso le sopraindicate sedi dei Contitolari, e i Dati Personali saranno conservati presso server siti all’interno dell’Unione Europea.
  </p></li>
  <li><p>6.2 Per ragioni di natura tecnica, può tuttavia accadere che l’infrastruttura venga manutenuta o in parte anche resa disponibile dagli USA (ad esempio per usufruire dei servizi di pagamento forniti da Stripe, dei servizi di supporto e assistenza forniti da Zendesk e dei servizi di analisi statistica dei Dati di Utilizzo prestati da Adjust e Segment, come descritti al punto 8 della presente Informativa). Trattando Dati Fiscali e, in generale, Dati Personali appartenenti a categorie particolari di dati, i Contitolari si adoperano anche in questo caso per garantire la massima trasparenza.<br>
          Tali trasferimenti dei Dati Personali all’estero avverranno soltanto (i) previa adozione di opportune garanzie quali, a titolo esemplificativo, la sottoscrizione delle clausole tipo approvate dalla Commissione Europea, o (ii) al ricorrere di una deroga al divieto di trasferimenti al di fuori dell’Unione Europea e, quindi, sempre a titolo esemplificativo, previa raccolta del consenso esplicito ed informato dell’Utente, o ancora soltanto nella misura in cui sia necessario all’esecuzione di un contratto concluso tra l’Utente e i Contitolari, o tra un Contitolare e un terzo a beneficio dell’Utente, ovvero all'esecuzione di misure precontrattuali adottate su richiesta dell’Utente.
  </p></li>
</ul>

<h1>§ 7 – Periodo di conservazione dei Dati</h1>

<ul>
    <li><p>7.1 I Dati Personali saranno conservati con le modalità sotto descritte per il solo tempo strettamente necessario al conseguimento delle finalità per cui sono raccolti. Successivamente i Dati Personali saranno anonimizzati o cancellati.
    </p></li>
    <li><p>7.2 In particolare, Dati di Utilizzo – che non consentono l’identificazione degli Utenti – non persistono più di 12 (dodici) mesi e vengono immediatamente cancellati dopo la loro aggregazione (salve eventuali necessità di accertamento di reati da parte dell'Autorità Giudiziaria competente).
    </p></li>
    <li><p>7.3 I Dati Comunicati (ivi inclusi i Dati Fiscali) saranno conservati per un periodo di 5 (cinque) anni a decorrere dal termine dell’anno di presentazione della dichiarazione dei redditi a cui si riferiscono. Tali dati saranno conservati per periodi di tempo maggiori e coincidenti con quelli previsti dalla normativa in tema di prescrizione, solo laddove necessario a: (i) dare esecuzione alle misure contrattuali/precontrattuali e per tutta la durata dell’eventuale rapporto contrattuale con il Titolare; (ii) adempiere ad obblighi di legge (ivi incluso, senza limitazione alcuna, l’art. 3 del DPR 332/1998); nonché (iii) ad assicurare la tutela giudiziaria dei diritti di ciascun Contitolare, nel rispetto dei termini di prescrizione ordinari.<br>
    Qualora, per ragioni contabili, fosse necessario conservare i Dati Fiscali (incluse informazioni riguardanti pagamenti effettuati), il termine di conservazione sarà di 5 anni dalla loro raccolta.
    </p></li>
</ul>

<h1>§ 8 – Modalità del trattamento</h1>

<ul>
  <li><p>8.1 I Dati Personali saranno trattati sia in forma cartacea sia con strumenti elettronici, informatici o automatizzati, tramite sistemi che ne assicurano la protezione, la sicurezza e la riservatezza. I Contitolari, inoltre, hanno adottato specifiche e adeguate misure di sicurezza logiche, giuridiche organizzative e tecniche per prevenire la perdita dei Dati Personali, usi illeciti o non consentiti degli stessi e accessi non autorizzati.<br>
          La dichiarazione dei redditi, i Dati Fiscali e ogni documentazione acquisita tramite l’App o tramite email saranno presentati dal Commercialista all’Agenzia delle Entrate e successivamente conservati utilizzando i servizi di IN.TE.S.A., un prestatore di servizi specializzato in queste operazioni secondo la legislazione fiscale italiana.
  </p></li>
  <li><p>8.2 Per la procedura di pagamento del compenso per i Servizi di Consulenza forniti dal Commercialista (secondo le Condizioni Generali di Servizio dell’App e i termini contenuti nel Contratto di Incarico Professionale concluso con quest’ultimo) Taxfix e Taxfix Italia si avvalgono dei servizi di Stripe Payments Europe, Ltd., società a responsabilità limitata soggetta a legge irlandese e con sede in The One Building, 1 Grand Canal Street Lower, Dublin 2, Ireland  (“<b>Stripe</b>”) e di PayPal (Europe) S.à r.l et Cie, S.C.A., società a responsabilità limitata soggetta a legge lussemburghese e con sede in 22-24 Boulevard Royal L-2449 Lussemburgo (“<b>Paypal</b>”). In relazione all’elaborazione del pagamento del compenso Taxfix e Taxfix Italia non memorizzano alcuna informazione sulla carta di credito utilizzata dall’Utente: i dati della carta di credito o gli estremi bancari sono trattati direttamente da Stripe e da Paypal. Stripe e Paypal raccolgono altri dati (quali indirizzo IP, identificativi del dispositivo o informazioni sul sistema operativo) per scopi propri, fra cui la prevenzione di abusi, il perfezionamento dei prodotti nonché per finalità commerciali. Il trattamento dei dati da parte di Stripe avviene in parte su server siti negli USA: nel caso in cui Dati Personali vengano trasmessi negli USA, Stripe si impegna pertanto a garantire i principi europei in materia di tutela dei dati e un livello di protezione equivalente a quello europeo anche nel quadro del trattamento di dati trasferiti negli USA.<br>
          Ulteriori informazioni sul trattamento dei dati da parte di Stripe sono reperibili nella rispettiva informativa privacy consultabile <u>qui</u>: <a href="https://stripe.com/it/privacy" target="_blank">https://stripe.com/it/privacy</a> e ulteriori informazioni sul trattamento dei dati da parte di Paypal sono reperibili nella rispettiva informativa privacy consultabile <u>qui</u>: <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank">https://www.paypal.com/webapps/mpp/ua/privacy-full</a><br>
          Le dichiarazioni dei redditi saranno visualizzabili nell’App/Webapp per tutto il periodo in cui l’account dell’Utente resterà attivo o fino a quando l’Utente ne richiederà la cancellazione. I Dati Fiscali, le dichiarazioni dei redditi e la relativa documentazione accessoria sono conservate da Taxfix allo scopo di migliorare il servizio e semplificare la preparazione della dichiarazione stessa l’anno successivo. Le informazioni fiscali e la documentazione sono salvate in forma codificata ed esclusivamente in Europa.
  </p></li>
  <li><p>8.3 L’Utente potrà rivolgersi in qualsiasi momento al servizio di supporto clienti di Taxfix e Taxfix Italia. Per prestare un servizio di supporto e assistenza tempestivo agli Utenti, Taxfix e Taxfix Italia si avvalgono di una soluzione ticket fornita dal fornitore esterno  Zendesk, Inc. (989 Market St., San Francisco, CA 94103, USA; “Zendesk”) Abbiamo stipulato con Zendesk un Addendum sul trattamento dei dati ai sensi dell'Art. 28 GDPR con Zendesk per garantire che il trattamento dei vostri dati avvenga in conformità alla legge applicabile e il trasferimento dei dati all’estero avviene nel rispetto di quanto previsto dal punto 6.2 della presente Informativa.<br>
          Inoltre, potremmo utilizzare i vostri dati di contatto (indirizzo e-mail, numero di telefono) per contattarvi in relazione alla vostra dichiarazione dei redditi, in particolare se è necessaria la presentazione di documenti mancanti o aggiuntivi per completare la dichiarazione dei redditi.
  </p></li>
  <li><p>8.4 Per migliorare e ottimizzare continuamente il funzionamento dell’App, per il rilevamento statistico e l’analisi del comportamento generale di utilizzo nonché per offrire all’Utente la migliore esperienza pratica possibile, Taxfix si avvale di tecnologie per l’analisi dei Dati di Utilizzo. Tali Dati di Utilizzo vengono salvati esclusivamente in forma pseudonimizzata, ovvero utilizzando un identificativo distinto e senza fare ricorso al nome dell’Utente o ad altre informazioni direttamente identificabili.<br>
          Per l’analisi dei Dati di Utilizzo, ci serviamo di una soluzione software di Adjust GmbH (“<b>Adjust</b>”), che rileva e analizza in forma anonimizzata gli eventi e le funzioni attivate nell’app nonché la navigazione al suo interno. Gli indirizzi IP e Mac rilevati e l’identificativo univoco del dispositivo dell’Utente (cosiddetto Identifier, ad es. sotto forma di ID pubblicitari Apple) vengono immediatamente anonimizzati da Adjust in modo da impedire qualsiasi collegamento alla sua persona. Le informazioni vengono utilizzate per generare valutazioni circa il comportamento generale dell’Utente, non riferibile alla persona, in relazione all’App e consentire in tal modo una ricerca di mercato autonoma per una progettazione in base alle esigenze di Taxfix e l’ottimizzazione dell’App. Tale analisi statistica dei Dati di Utilizzo non ha scopo commerciale e non è correlata all’invio di pubblicità. Ulteriori informazioni sul trattamento dei dati da parte di Adjust sono reperibili nella rispettiva informativa privacy consultabile <u>qui</u>: <a href="https://www.adjust.com/terms/privacy-policy/" target="_blank">https://www.adjust.com/terms/privacy-policy/</a>.<br>
          Per l’analisi dei Dati di Utilizzo, Taxfix si avvale inoltre di Segment.io, un servizio offerto da Segment.io, Inc, (101 15th St, San Francisco, CA 94103, USA), (“<b>Segment</b>”), che supporta nel raccogliere e analizzare i Dati di Utilizzo e a valutarli con l’ausilio delle tecnologie dedicate descritte in questo paragrafo e ad impiegarli al fine di ottimizzare l’esperienza pratica dell’App. Anche in questo caso, i Dati di Utilizzo raccolti sono trattati unicamente in forma pseudonimizzata: gli indirizzi IP, gli IDFA e gli ID pubblicitari di Google vengono aggregati subito dopo il rilevamento e i dati non vengono impiegati per ricondurre profili di utilizzo a dati personali dell’utente. Le informazioni pseudonimizzate riguardanti l’utilizzo dell’App vengono di norma trasmesse a un server di Segment negli USA e lì salvate. Segment garantisce espressamente per contratto il rispetto delle disposizioni europee in materia di protezione dei dati.<br>
          Ulteriori informazioni sul trattamento dei dati da parte di Segment sono reperibili nella rispettiva informativa privacy consultabile <u>qui</u>: <a href="https://segment.com/legal/privacy/" target="_blank">https://segment.com/legal/privacy/</a>
  </p></li>
</ul>


<h1>§ 9 – Sicurezza dei Dati</h1>

<ul>
  <li><p>9.1 Per garantire la sicurezza dei Dati, i Contitolari adottano misure tecniche allo stato dell’arte. Tutte le misure di sicurezza vengono continuamente adeguate al progresso tecnologico. Quando il dispositivo dell’Utente su cui l’App è installata è bloccato, tutti i Dati salvati sul dispositivo stesso sono codificati. La sicurezza dei Dati contro accessi non autorizzati dipende in modo determinante dalla protezione dell’accesso al dispositivo dell’Utente. L’attivazione del blocco automatico del dispositivo rende enormemente più difficoltoso il rilevamento dei Dati salvati da parte di terzi non autorizzati, anche se l’effettivo grado di protezione dipende dalle impostazioni di sicurezza selezionate e dalla lunghezza della password adottata (ad esempio, dettagli sulla codifica hardware del dispositivo iOS sono reperibili nelle indicazioni fornite da Apple sulla sicurezza di iOS). Come descritto al punto 2.1, i dati contenuti all’interno dell’App sono protetti contro l’accesso non autorizzato per mezzo di un PIN.  La comunicazione fra il dispositivo dell’Utente durante l’utilizzo dell’App e i web server utilizzati dai Contitolari avviene mediante codifica SSL. I fornitori utilizzati da Taxfix e da Taxfix Italia vengono sottoposti a rigidi controlli e hanno dimostrato di operare conformemente agli standard di sicurezza garantiti fornendo attestati di certificazione attuali o documenti equiparabili.
  </p></li>
  <li><p>9.2 I Dati dell’Utente sono memorizzati in linea di principio all’interno di un data center in Europa conformemente a elevati standard di sicurezza e in forma codificata (AES-256-CTR). Il data center dispone delle più moderne tecnologie di sicurezza ed è certificato ISO 27018. Laddove, nei casi descritti nella presente dichiarazione sulla tutela dei dati, sussistano punti di contatto con paesi terzi, si garantisce in ogni caso un livello tecnico di sicurezza equivalente agli standard europei. L’eventuale trattamento dei dati in paesi terzi avverrà ad ogni modo nel rispetto delle garanzie previste dalla legge, in conformità a quanto disposto dal punto 6.2 della presente Informativa.
  </p></li>
</ul>

<h1>§ 10 – Diritti dell’Utente</h1>

<ul>
  <li><p>10.1 Ciascun Utente (in qualità di “<b>Interessato<b>”) è titolare dei diritti conferiti agli interessati dagli artt. 12-23 del GDPR. In particolare, l’Utente ha in qualsiasi momento il diritto di richiedere e ottenere informazioni sul trattamento dei propri Dati Personali da parte dei Contitolari. Nel comunicare tali informazioni, i Contitolari forniranno spiegazioni sulla procedura di trattamento dei Dati oltre che una panoramica dei Dati Personali memorizzati, nonché ogni altra ragionevole informazione richiesta dagli Interessati.<br>
          Qualora i Dati fossero errati o non aggiornati, l’Utente ha il diritto di richiederne la correzione, rettifica e/o aggiornamento, nonché esigerne la cancellazione o la limitazione del trattamento. Se ciò, a causa di altre disposizioni di legge, non fosse eccezionalmente possibile, i Dati saranno trattati unicamente per quello specifico scopo giuridico, limitandone in ogni caso il trattamento.<br>
          L’Utente gode anche del diritto alla portabilità dei dati, ossia del diritto di ricevere i Dati in un formato strutturato di uso comune, leggibile da dispositivi automatici: su richiesta dell’Interessato, i Contitolari forniranno una copia digitale dei Dati Personali da egli resi disponibili.<br>
          Per l’esercizio dei diritti sopra descritti contro Taxfix o contro il Commercialista, l’Interessato può rivolgersi in qualsiasi momento ai riferimenti riportati al punto 1 della presente Informativa (e-mail e telefono). Lo stesso vale qualora l’Interessato desideri ricevere copie di garanzie come attestazione del livello adeguato di protezione dei dati. L’Interessato può inoltre opporsi al trattamento dei dati e proporre reclamo all’autorità di controllo competente. L’Interessato può esercitare questo diritto presso l’autorità di controllo competente del proprio Stato membro di residenza, del proprio luogo di lavoro o del luogo della presunta violazione. L’autorità di controllo competente in Italia è il Garante per la protezione dei dati personali.<br>
          L’Interessato ha, inoltre, il diritto di revocare il proprio consenso in qualsiasi momento. Di conseguenza il Contitolare non tratterà più in futuro i dati che erano oggetto di tale consenso. La revoca del consenso non pregiudica la liceità del trattamento basata sul consenso prima della revoca.
  </p></li>
</ul>

<h1>§ 11 - Modifiche</h1>

<ul>
  <li><p>11.1 Taxfix si riserva il diritto di modificare in qualsiasi momento la presente Informativa. La versione attuale dell’Informativa è sempre consultabile nelle impostazioni dell’account all’interno dell’App/Webapp e sul sito taxfix.it.
  </p></li>
</ul>

`;
