/* global fetch */
import { $Values, $Keys } from 'utility-types';
import Config from 'react-native-config';
import get from 'lodash/get';
import { CountryCodes } from '@taxfix/types';

const RequestMethod = Object.freeze({
  GET: 'GET',
  PUT: 'PUT',
});
const firebaseFunction = Object.freeze({
  appData: 'appDataEuropeWest1',
});
type FirebaseFunction = $Values<typeof firebaseFunction>;
export const configKey = Object.freeze({
  optOutFromTracking: 'tracking-opt-out',
  isReferralVoucherReady: 'is-referral-voucher-ready',
  ignoredWarnings: 'IgnoredWarnings',
});
// URL used when running firebase functions server locally
// const baseUrl = 'http://localhost:5001/taxfix-development/europe-west1/appDataEuropeWest1/';
const baseUrl = Config.FIREBASE_FUNCTIONS_BASE_URL;

function callFirebaseFunction(
  method: $Keys<typeof RequestMethod>,
  functionName: FirebaseFunction,
  country: CountryCodes,
  accessToken: string,
  body?: any,
) {
  const resourceUrl = `${baseUrl}${functionName}`;
  const url: any = new URL(resourceUrl);
  url.searchParams.append('countryCode', country);
  const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: accessToken ? `Token ${accessToken}` : null,
  };
  return fetch(url, {
    method,
    headers,
    credentials: 'include',
    body: body && JSON.stringify(body),
  });
}

type LoadDataParams = {
  country: CountryCodes;
  accessToken: string;
  key: string;
  errorHandler?: (err: string) => any;
  defaultValue?: any;
};
type UpdateDataParams = LoadDataParams & {
  updatedValue: any;
  successHandler?: () => any;
};

const getData =
  (functionName: FirebaseFunction) =>
  async ({
    country,
    accessToken,
    key,
    errorHandler = () => {},
    defaultValue,
  }: LoadDataParams): Promise<any> => {
    try {
      const response = await callFirebaseFunction(
        RequestMethod.GET as any,
        functionName,
        country,
        accessToken,
      );
      if (!response.ok) {
        throw new Error(`status-code: ${response.status}`);
      }

      const serverData = await response.json();
      return get(serverData, key, defaultValue);
    } catch (e) {
      errorHandler(e);
    }

    return defaultValue;
  };

const updateData =
  (functionName: FirebaseFunction) =>
  async ({
    country,
    accessToken,
    key,
    updatedValue,
    errorHandler = () => {},
    successHandler = () => {},
  }: UpdateDataParams): Promise<number> => {
    try {
      const response = await callFirebaseFunction(
        RequestMethod.PUT as any,
        functionName,
        country,
        accessToken,
        {
          [key]: updatedValue,
        },
      );

      if (!response.ok) {
        throw new Error(`status-code: ${response.status}`);
      }

      successHandler();
      return response.status;
    } catch (e) {
      errorHandler(e);
      return e;
    }
  };

export const appDataFunction = {
  getData: getData(firebaseFunction.appData),
  updateData: updateData(firebaseFunction.appData),
};
