import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { SVGProps } from '../types';

/**
 * StepsIcon OtherDetails with several paths, greyscale
 * Temporary component
 */

export const OtherDetailsIconGrey: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <G fill="none">
      <Path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#F6F6F6"
      />
      <Path d="M18.6543 23.5076H22.493L21.8878 24.865H19.2811L18.6543 23.5076Z" fill="#707070" />
      <Path d="M20.7508 22.8462H20.2363V23.9313H20.7508V22.8462Z" fill="#707070" />
      <Path d="M25.2941 16.9454H15.7275V22.643H25.2941V16.9454Z" fill="#EFDDDC" />
      <Path d="M25.333 17.0579H15.7449V22.535H25.333V17.0579Z" fill="#E1E1E1" />
      <Path
        d="M20.5217 23.1617C23.1837 23.1617 25.3417 22.9004 25.3417 22.5781C25.3417 22.2558 23.1837 21.9945 20.5217 21.9945C17.8597 21.9945 15.7017 22.2558 15.7017 22.5781C15.7017 22.9004 17.8597 23.1617 20.5217 23.1617Z"
        fill="#E1E1E1"
      />
      <Path
        d="M20.5217 17.5982C23.1837 17.5982 25.3417 17.3369 25.3417 17.0146C25.3417 16.6923 23.1837 16.431 20.5217 16.431C17.8597 16.431 15.7017 16.6923 15.7017 17.0146C15.7017 17.3369 17.8597 17.5982 20.5217 17.5982Z"
        fill="#E1E1E1"
      />
      <Path d="M17.6685 45.1047H17.0806L19.2117 24.5061H20.8026L17.6685 45.1047Z" fill="#707070" />
      <Path d="M23.4871 45.0312H24.075L21.9438 24.4327H20.353L23.4871 45.0312Z" fill="#707070" />
      <Path
        d="M21.7969 45.0182H21.062L19.925 26.3563C19.925 26.3563 20.219 25.3664 20.3271 25.3664C20.4351 25.3664 21.3559 25.7338 21.3559 25.7338L21.7969 45.0182Z"
        fill="#707070"
      />
      <Path
        d="M25.614 47.5257L25.7523 47.569C25.4713 47.4825 25.3848 47.2058 25.4713 46.9248L26.4007 43.4233C26.4872 43.1423 26.7855 42.9867 27.0664 43.0732C27.3474 43.1596 27.5031 43.4579 27.4166 43.7389L26.2105 47.154C26.1197 47.4306 25.8906 47.6122 25.614 47.5257Z"
        fill="#707070"
      />
      <Path
        d="M45.6505 47.5256L45.5122 47.5688C45.7931 47.4824 45.8796 47.2057 45.7931 46.9247L44.8637 43.4232C44.7773 43.1422 44.479 42.9866 44.198 43.073C43.917 43.1595 43.7614 43.4578 43.8478 43.7388L45.0539 47.1538C45.1447 47.4305 45.3738 47.6121 45.6505 47.5256Z"
        fill="#707070"
      />
      <Path
        d="M46.714 40.2674L45.7673 43.0125L45.2443 44.5255H25.2855L24.8359 43.0125L23.4224 38.227L44.2846 37.2846L46.714 40.2674Z"
        fill="#E1E1E1"
      />
      <Path
        d="M46.714 40.2674L45.7673 43.0124H24.8359L23.4224 38.227L44.2846 37.2846L46.714 40.2674Z"
        fill="#707070"
      />
      <Path
        d="M35.2669 38.5945V31.4185C35.2669 30.5928 34.5969 29.9228 33.7712 29.9228H26.7811C25.9511 29.9228 25.2854 30.5928 25.2854 31.4185V38.5945V39.485H35.2626V38.5945H35.2669Z"
        fill="#E1E1E1"
      />
      <Path
        d="M26.1716 31.4141C26.1716 30.5884 26.8373 29.9184 27.6673 29.9184H26.7811C25.9511 29.9184 25.2854 30.5884 25.2854 31.4141V38.5901V39.4806H26.1716V38.5901V31.4141Z"
        fill="#707070"
      />
      <Path
        d="M45.2443 38.5945V31.4185C45.2443 30.5928 44.5742 29.9228 43.7486 29.9228H36.7628C35.9328 29.9228 35.2671 30.5928 35.2671 31.4185V38.5945V39.485H45.2443V38.5945Z"
        fill="#E1E1E1"
      />
      <Path
        d="M36.1533 31.4141C36.1533 30.5884 36.819 29.9184 37.649 29.9184H36.7628C35.9328 29.9184 35.2671 30.5884 35.2671 31.4141V38.5901V39.4806H36.1533V38.5901V31.4141Z"
        fill="#707070"
      />
      <Path
        d="M47.0035 44.5256H45.2441V35.1103C45.2441 34.7083 45.6591 34.4403 46.0266 34.6045L47.9719 35.4778C48.188 35.5729 48.3177 35.7977 48.2961 36.0354L47.5698 44.0111C47.5396 44.3051 47.2975 44.5256 47.0035 44.5256Z"
        fill="#E1E1E1"
      />
      <Path
        d="M23.5261 44.5257H25.2855V35.1105C25.2855 34.7084 24.8705 34.4404 24.5031 34.6047L22.5578 35.4779C22.3416 35.573 22.2119 35.7978 22.2335 36.0356L22.9641 44.0113C22.99 44.3052 23.2321 44.5257 23.5261 44.5257Z"
        fill="#E1E1E1"
      />
      <Path
        d="M45.1492 42.7789H25.3807C25.0003 42.7789 24.6934 42.472 24.6934 42.0916V40.1679C24.6934 39.7875 25.0003 39.4806 25.3807 39.4806H45.1492C45.5296 39.4806 45.8366 39.7875 45.8366 40.1679V42.0916C45.8366 42.472 45.5296 42.7789 45.1492 42.7789Z"
        fill="#E1E1E1"
      />
      <Path
        d="M26.1848 42.2992V40.2242C26.1848 39.8135 26.4917 39.485 26.8721 39.485H25.3807C25.0003 39.485 24.6934 39.8178 24.6934 40.2242V42.2992C24.6934 42.7098 25.0003 43.0384 25.3807 43.0384H26.8721C26.4917 43.0427 26.1848 42.7098 26.1848 42.2992Z"
        fill="#707070"
      />
      <Path
        d="M38.2151 33.1951C38.0378 33.1951 37.8779 33.074 37.8303 32.9011C37.7871 32.7498 37.8433 32.5769 37.9687 32.4818C38.241 32.27 38.6474 32.4904 38.6128 32.8363C38.5955 33.0394 38.4183 33.1951 38.2151 33.1951Z"
        fill="white"
      />
      <Path
        d="M42.2915 33.1951C42.1143 33.1951 41.9543 33.074 41.9068 32.9011C41.8635 32.7498 41.9197 32.5769 42.0451 32.4818C42.3174 32.27 42.7238 32.4904 42.6892 32.8363C42.6719 33.0394 42.4947 33.1951 42.2915 33.1951Z"
        fill="white"
      />
      <Path
        d="M38.2152 37.0035C37.8564 37.0035 37.6792 36.541 37.9515 36.3075C38.2195 36.0698 38.6605 36.2989 38.6129 36.6534C38.587 36.8479 38.4184 37.0035 38.2152 37.0035Z"
        fill="white"
      />
      <Path
        d="M42.2916 37.0035C41.9285 37.0035 41.7599 36.5367 42.0323 36.3032C42.3046 36.0698 42.7326 36.2989 42.6893 36.6534C42.6634 36.8479 42.4948 37.0035 42.2916 37.0035Z"
        fill="white"
      />
      <Path
        d="M28.2379 33.1951C27.8705 33.1951 27.7019 32.7196 27.9829 32.4862C28.2466 32.27 28.6529 32.4732 28.6356 32.8147C28.627 33.0265 28.4454 33.1951 28.2379 33.1951Z"
        fill="white"
      />
      <Path
        d="M32.3144 33.1951C32.1372 33.1951 31.9773 33.074 31.9297 32.9011C31.8865 32.7498 31.9427 32.5769 32.068 32.4818C32.3404 32.27 32.7467 32.4904 32.7121 32.8363C32.6905 33.0394 32.5176 33.1951 32.3144 33.1951Z"
        fill="white"
      />
      <Path
        d="M28.2378 37.0035C27.892 37.0035 27.7148 36.5756 27.9482 36.3292C28.0649 36.2081 28.2551 36.1735 28.4064 36.247C28.5577 36.3205 28.6485 36.4805 28.6312 36.6447C28.6139 36.8436 28.441 37.0035 28.2378 37.0035Z"
        fill="white"
      />
      <Path
        d="M32.3143 37.0035C31.9512 37.0035 31.7783 36.5366 32.0549 36.3032C32.1889 36.1865 32.3878 36.1735 32.5391 36.2729C32.6774 36.3637 32.7466 36.541 32.7034 36.7009C32.6558 36.8738 32.4959 37.0035 32.3143 37.0035Z"
        fill="white"
      />
    </G>
  </Icon>
);
