import React from 'react';
import { useBreakpointValue } from 'native-base';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { Container, Footer, Image, NavButton, Text } from 'src/taxfix-components/src';
import { ButtonName } from 'src/types/button-name';

export type InstantFilingNextStepsComponentProps = {
  onPressBackNavigation: (btn?: ButtonName) => void;
  onPressContinueNavigation: () => void;
};

export const InstantFilingNextStepsComponent: React.FC<InstantFilingNextStepsComponentProps> = ({
  onPressContinueNavigation,
  onPressBackNavigation,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const primaryButtonProps = useBreakpointValue({ md: { maxWidth: '221px' } });
  const secondaryButtonProps = useBreakpointValue({
    base: { variant: 'tertiary' },
    md: { variant: 'tertiary', maxWidth: '193px' },
  });

  return (
    <Container flex={1} px="20px">
      {!isSmallScreen && (
        <NavButton
          text={getTranslationText('it.page.navigation.back')}
          onPress={() => onPressBackNavigation()}
        />
      )}
      <Container flex={1} justifyContent="center" alignItems="center">
        <Container marginX="auto" marginBottom={['58px', '40px']}>
          <Image name="handWithSPID" width={[233, 320]} height={[154, 212]} />
        </Container>
        <Text
          variant={isSmallScreen ? 'titleSBold' : 'titleMBold'}
          textAlign="center"
          value={getTranslationText('it.instant-filing-next-steps.title')}
          mb="16px"
          maxW="580px"
        />
        <Text
          variant="bodyMBook"
          textAlign="center"
          value={getTranslationText('it.instant-filing-next-steps.subtitle')}
          maxW={['308px', '380px']}
        />
      </Container>
      <Footer
        backgroundColor="white"
        py="0px"
        borderTopColor="white"
        primaryButtonTitle={getTranslationText('it.instant-filing-next-steps.button.back')}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonTitle={getTranslationText('it.instant-filing-next-steps.button.next')}
        secondaryButtonProps={secondaryButtonProps}
        onPressPrimary={() => onPressBackNavigation(ButtonName.ChangePlan)}
        onPressSecondary={onPressContinueNavigation}
      />
    </Container>
  );
};
