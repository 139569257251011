import { $Keys } from 'utility-types';

export const feedbackInquiryTypes = {
  FEELING: 'FEELING',
  NPS: 'NPS',
};
export const feedbackResultTypes = {
  HAPPY: 'HAPPY',
  DISAPPOINTED: 'DISAPPOINTED',
  PROMOTER: 'PROMOTER',
  PASSIVE: 'PASSIVE',
  DETRACTOR: 'DETRACTOR',
};
export type FeedbackInquiryType = $Keys<typeof feedbackInquiryTypes>;
export type FeedbackResultType = $Keys<typeof feedbackResultTypes>;
export type FeedbackModalType = FeedbackInquiryType | FeedbackResultType;
