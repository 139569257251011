import * as React from 'react';
import isNil from 'lodash/isNil';
import { ScrollView, StyleSheet } from 'react-native';
import { $PropertyType } from 'utility-types';
import { PlausibilityErrors } from '@taxfix/quizmaster/dist/types';

import { ValidationError } from 'src/components/answers/shared/validation-error';
import InputValidationBar from 'src/components/answers/shared/InputValidationBar';

import ButtonBar from '../../components/answers/ButtonBar';
import { TextButton, theme, Box, Icon, Button } from '../../components/core';
import { testID } from '../../common/testID';
import { WEB_SECTION_NARROW_WIDTH } from '../../utils/constants';
import { isWeb } from '../../utils/platform';

const styles = StyleSheet.create({
  containerHeightLimited: {
    maxHeight: 270,
  },
  buttonContainerWeb: {
    maxWidth: WEB_SECTION_NARROW_WIDTH,
  },
  button: {
    flex: 1,
    marginBottom: 10,
  },
  buttonBar: {
    paddingHorizontal: 0,
  },
  buttonContentWrapper: {
    marginHorizontal: 16,
  },
});
export type Option = {
  id?: string;
  translationKey?: string;
  values?: Record<string, any>;
  value?: string | boolean;
  label?: string;
  disabled?: boolean;
};

export type SelectedOption = Option | $PropertyType<Option, 'id'> | $PropertyType<Option, 'value'>;
export type Props = {
  options?: Option[];
  optionType: 'text' | 'button';
  onAnswer: (answer: any) => any;
  color?: string;
  colorSecondary?: string;
  disabledColor?: string;
  isFullHeight?: boolean;
  testId?: string | null | undefined;
  selectedValue?: SelectedOption;
  warnings?: PlausibilityErrors[];
  onPressInfo?: (warning: PlausibilityErrors) => void;
};
// @ts-ignore
export const getIsSelected = (selected?: SelectedOption, option: Option) => {
  let isSelected = false;

  if (!isNil(selected)) {
    if (selected === option) {
      isSelected = true;
    } else if (option.id != null && selected === option.id) {
      isSelected = true;
    } else if (option.value != null && selected === option.value) {
      isSelected = true;
    }
  }

  return isSelected;
};

const renderTextOptions = (
  options: Option[] = [],
  handleOptionPress: any,
  color: any,
  colorSecondary: any,
  disabledColor: any,
  selectedValue: any,
  warnings?: any,
  onPressInfo?: any,
) => {
  const align = options.length === 1 ? 'center' : 'left';
  return (
    <ButtonBar alignment="vertical" flexible={false} childStyle={styles.buttonBar}>
      {options.map((option, index) => {
        const isSelected = getIsSelected(selectedValue, option);
        const textButtonStyle = [isSelected ? { backgroundColor: colorSecondary } : null];

        return (
          <Box flex key={index} style={textButtonStyle}>
            <Box flex direction="row" alignVertically="center" style={styles.buttonContentWrapper}>
              <Box flex>
                <TextButton
                  key={option.id || option.translationKey}
                  values={option.values || {}}
                  align={align}
                  translationKey={option.translationKey || undefined}
                  textColor={!option.disabled ? color : disabledColor}
                  onPress={() => handleOptionPress(option)}
                  testId={`answer::${option.label || option.id || ''}`}
                  disabled={option.disabled}
                >
                  {option.id == null ? option.label : undefined}
                </TextButton>
              </Box>
              {isSelected && (
                <Icon
                  name="interface-icons-svg.icon-checkmark"
                  width={23}
                  height={23}
                  style={{
                    tintColor: color,
                  }}
                />
              )}
            </Box>
          </Box>
        );
      })}
      {warnings &&
        warnings[0] &&
        warnings.map((warning: any, i: number) => (
          <ValidationError
            key={i}
            type={InputValidationBar.Type.Warning}
            translationKey={warning.messageTranslationKey} // infoTranslationKey can exist while info is empty, so we use info.
            onPressInfo={warning.info && ((() => onPressInfo(warning)) as any)}
          />
        ))}
    </ButtonBar>
  );
};

const renderButtonOptions = (
  options: Option[] = [],
  handleOptionPress: any,
  color: any,
  disabledColor: any,
  selectedValue: any,
  warnings?: any,
  onPressInfo?: any,
) =>
  options.map((option, index) => (
    <Button
      type="secondary"
      key={index}
      values={option.values || {}}
      translationKey={option.translationKey || undefined}
      textColor={!option.disabled ? color : disabledColor} // @ts-ignore
      onPress={() => handleOptionPress(option)}
      testId={`answer::${option.label || option.id || ''}`}
      disabled={option.disabled}
      style={styles.button}
      fillColor={
        getIsSelected(selectedValue, option) ? theme.color.secondaryAccentGreenLight : undefined
      }
    >
      {option.id == null ? option.label : undefined}
    </Button>
  ));

const optionsRenderers = {
  text: renderTextOptions,
  button: renderButtonOptions,
};

const SingleSelect = ({
  options,
  onAnswer,
  color = theme.color.primary,
  colorSecondary = theme.color.secondary,
  disabledColor = theme.color.disabledGray,
  isFullHeight,
  selectedValue,
  optionType,
  warnings,
  onPressInfo,
}: Props) => {
  const handleOptionPress = (option: Option) => {
    const { id, value = '' } = option;
    const answer = id != null && typeof id !== 'undefined' ? id : value;
    onAnswer(answer);
  };

  const renderOption = optionsRenderers[optionType];
  return (
    <ScrollView
      nestedScrollEnabled
      style={[
        !isFullHeight ? styles.containerHeightLimited : undefined,
        isWeb && optionType === 'button' ? styles.buttonContainerWeb : undefined,
      ]}
      {...testID('test.scroll.single')}
    >
      {renderOption(
        options,
        handleOptionPress,
        color,
        colorSecondary,
        disabledColor,
        selectedValue,
        warnings,
        onPressInfo,
      )}
    </ScrollView>
  );
};

export { SingleSelect };
