import { Reducer } from 'redux';

import initial, { State } from '../store/initial';
import { persistWhitelist } from '../store/persist-filter';

import { selectors as settingsSelectors } from './settings';

export const RESET_APP_STATE = 'devTools/RESET_APP_STATE';
type Action = {
  type: string;
  [key: string]: any;
};

const resetAppState = () => ({
  type: RESET_APP_STATE,
});

export const actions = {
  resetAppState,
};

const resetSettingsState = (state: State) => {
  return {
    settings: {
      ...initial.settings,
      data: {
        ...initial.settings.data,
        selectedLocale: settingsSelectors.selectedLocale(state),
        selectedYear: settingsSelectors.selectedYear(state),
        hasSelectedCountry: settingsSelectors.hasSelectedCountry(state),
      },
    },
  };
};

export const rootReducer =
  (combinedReducers: Reducer) => (state: typeof initial, action: Action) => {
    let newState = state;

    if (action.type === RESET_APP_STATE) {
      const persistedReducers = persistWhitelist.reduce(
        (acc, reducerKey) => ({ ...acc, [reducerKey]: undefined }),
        {},
      );
      newState = { ...state, ...persistedReducers, ...resetSettingsState(state) };
    }

    return combinedReducers(newState, action);
  };
