import React, { ComponentType } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { BodyPrimaryText, DisplayPrimaryText, theme } from '../../../components/core';
import Separator from '../../../components/separator';

const getFlag = (): string => '🇮🇹';

const styles = StyleSheet.create({
  infoContainer: {
    borderColor: theme.color.border,
    borderRadius: 4,
    borderWidth: 1,
    marginBottom: 20,
  },
  info: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  horizontalSeparator: {
    marginHorizontal: 8,
    borderColor: theme.color.border,
    borderWidth: StyleSheet.hairlineWidth,
  },
});

type Props = {
  userId?: number;
  userEmail?: string;
  selectedYear?: number | null;
  selectedCountry: CountryCodes;
  devToolsPackageId: string;
};

const HomeDebugHeader: ComponentType<Props> = (props) => {
  const { userId, userEmail, selectedYear, selectedCountry, devToolsPackageId } = props;

  return (
    <>
      <View style={styles.infoContainer}>
        <View style={styles.info}>
          <BodyPrimaryText>Current user:</BodyPrimaryText>
          {userId ? (
            <>
              <BodyPrimaryText selectable>{userId}</BodyPrimaryText>
              <BodyPrimaryText selectable>{userEmail}</BodyPrimaryText>
            </>
          ) : (
            <BodyPrimaryText>None</BodyPrimaryText>
          )}
        </View>
        <Separator />
        <View style={styles.rowContainer}>
          <View style={styles.info}>
            <BodyPrimaryText>Selected year:</BodyPrimaryText>
            <BodyPrimaryText>{selectedYear || 'N/A'}</BodyPrimaryText>
          </View>
          <View style={styles.horizontalSeparator} />
          <View style={styles.info}>
            <BodyPrimaryText>Selected country:</BodyPrimaryText>
            <BodyPrimaryText>{`${getFlag()} ${selectedCountry} `}</BodyPrimaryText>
          </View>
        </View>
        <Separator />
        <View style={styles.info}>
          <BodyPrimaryText>PackageId:</BodyPrimaryText>
          <BodyPrimaryText>
            <Text selectable>{devToolsPackageId || 'default'}</Text>
          </BodyPrimaryText>
        </View>
      </View>
      <View style={{ marginBottom: 20 }}>
        <DisplayPrimaryText style={{ fontSize: 24 }}>Quick Menu</DisplayPrimaryText>
      </View>
    </>
  );
};

export default HomeDebugHeader;
