import React from 'react';
import { StyleSheet } from 'react-native';
import Config from 'react-native-config';
import { WebView, WebViewProps } from 'react-native-webview';
import { useSelector } from 'react-redux';

import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import { Loading } from 'src/components/loading';
import {
  CWBIMockServerSPIDMessage,
  SpidMessage,
  PreSeasonSpidMessage,
} from 'src/_italy/iframe-spid-login/constants';

const styles = StyleSheet.create({
  containerWebView: {
    height: '100%',
    width: '100%',
  },
});

type Props = WebViewProps & {
  url: string;
};

export const WebViewComponent: React.FC<Props> = ({ url, onMessage = () => {}, ...props }) => {
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const { mockServerURLIframeEvent } = useSelector(firebaseSelectors.getCwbiMockServerConfig);
  const CWBIBaseURL = useSelector(firebaseSelectors.getItalyCWBIBaseUrl);

  const trustURL =
    mockServerURLIframeEvent.length > 1 ? mockServerURLIframeEvent : Config.CWBI_MOCK_URL;

  const uri = `${Config.API_BASE_URL}/api/payments/view`;

  const INJECTED_JAVASCRIPT = `
    //* Script to bridge WebView --> Native *//
    const script = document.createElement('script');
    script.innerHTML = 'function sendMessage(eventName) {window.ReactNativeWebView.postMessage(eventName)}';
    document.body.appendChild(script);
    
    // Listen to message from child window
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
    const validMessages = [
      '${SpidMessage.providerSelectionScreenShown}',
      '${SpidMessage.providerScreenShown}',
      '${SpidMessage.requestLoginWithProvider}',
      '${SpidMessage.errorScreenShown}',
      '${SpidMessage.credentialsErrorShown}',
      '${SpidMessage.credentialsValidationFailed}',
      '${SpidMessage.spidFlowAuthorized}',
      '${SpidMessage.spidFlowRejected}',
      '${SpidMessage.spidFlowExpired}',
      '${SpidMessage.spidFlowError}',
      '${CWBIMockServerSPIDMessage.authorized}',
      '${PreSeasonSpidMessage.errorScreenShown}',
      '${PreSeasonSpidMessage.credentialsErrorShown}',
      '${PreSeasonSpidMessage.credentialsValidationFailed}',
      '${PreSeasonSpidMessage.spidFlowAuthorized}',
      '${PreSeasonSpidMessage.spidFlowRejected}',
      '${PreSeasonSpidMessage.spidFlowExpired}',
      '${PreSeasonSpidMessage.spidFlowError}',
    ];
    
    eventer(messageEvent, function (e) {

      if (e.origin !== '${CWBIBaseURL}' && e.origin !== '${trustURL}') return;

      const key = e.message ? 'message' : 'data';

      if (validMessages.includes(e[key]) || (typeof e[key] === "string" && e[key].startsWith('${SpidMessage.identityProviderSelected}'))) {
        sendMessage(e[key]);
      }
    });
    
    //* style outer iframe
    const style = document.createElement('style');
    style.textContent = '::-webkit-scrollbar { display: none; } body { margin: 0; }';
    document.head.append(style);

    //* make the outer iframe responsive
    const meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute("content", "width=device-width, initial-scale=1");
    document.head.append(meta);
    
    // * create inner iframe
    const ifr = document.createElement('iframe');
    ifr.setAttribute('src', '${url}');
    ifr.style.width = '100%';
    ifr.style.height = '100vh';
    ifr.style.margin = '0';
    ifr.style.borderWidth = '0px';
    document.body.prepend(ifr);
    true; // Required for injectedJS to work
  `;

  return (
    <WebView
      javaScriptEnabled={true}
      injectedJavaScriptForMainFrameOnly={true}
      containerStyle={styles.containerWebView}
      source={{
        uri,
        headers: {
          Authorization: accessToken ? `Token ${accessToken}` : null,
        },
      }}
      startInLoadingState={true}
      renderLoading={() => <Loading />}
      injectedJavaScript={INJECTED_JAVASCRIPT}
      scrollEnabled
      nestedScrollEnabled
      onMessage={onMessage}
      {...props}
    />
  );
};
