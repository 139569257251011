import React from 'react';
import { Linking } from 'react-native';

import { Props as YesNoContainerProps, YesNoContainer, MessageBannerConfig } from './yes-no';

type Dependent = 'spouse' | 'child' | 'relative';

export type DependentHasItalianFCComponentProps = YesNoContainerProps & {
  dependent: Dependent;
};

const getMessageBannerConfig = (dependent: Dependent): MessageBannerConfig => {
  const commonConfig = {
    selectedValue: false,
    onLinkPress() {
      Linking.openURL(
        'https://taxfix.it/guide-e-consigli/documenti-per-il-730/famiglia-e-salute/codice-fiscale-stranieri-comunitari/',
      );
    },
  };

  switch (dependent) {
    case 'spouse':
      return {
        ...commonConfig,
        message: 'it.answers.dependent-has-it-fc.spouse.message.text',
        linkText: 'it.answers.dependent-has-it-fc.spouse.message.link-text',
      };
    case 'child':
      return {
        ...commonConfig,
        message: 'it.answers.dependent-has-it-fc.child.message.text',
        linkText: 'it.answers.dependent-has-it-fc.child.message.link-text',
      };
    case 'relative':
      return {
        ...commonConfig,
        message: 'it.answers.dependent-has-it-fc.relative.message.text',
        linkText: 'it.answers.dependent-has-it-fc.relative.message.link-text',
      };
  }
};

export const DependentHasItalianFCComponent = (
  props: DependentHasItalianFCComponentProps,
): JSX.Element => {
  return (
    <YesNoContainer {...props} messageBannerConfig={getMessageBannerConfig(props.dependent)} />
  );
};
