import React from 'react';
import { TouchableOpacity, View, TouchableWithoutFeedback } from 'react-native';

import { StyleProp } from '../../types';
import { testID, testAccessibility } from '../../../common/testID';

export type InternalButtonProps = {
  disabled?: boolean;
  children?: any;
  onPress: () => void | Promise<void>;
  testId?: string;
  onPressIn?: () => void;
  onPressOut?: () => void;
};
export type InternalButtonPropsWithStyle = InternalButtonProps & {
  style?: StyleProp | any;
};

const InternalButton: React.FC<InternalButtonPropsWithStyle> = ({
  children,
  onPress,
  style,
  disabled = false,
  testId,
  onPressIn,
  onPressOut,
}: InternalButtonPropsWithStyle) =>
  disabled === true ? (
    <TouchableWithoutFeedback {...testID(testId)} {...testAccessibility()}>
      <View style={style}>{children}</View>
    </TouchableWithoutFeedback>
  ) : (
    <TouchableOpacity
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={style}
      {...testID(testId)}
      {...testAccessibility()}
    >
      {children}
    </TouchableOpacity>
  );

export default InternalButton;
