import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { CountryCodes } from '@taxfix/types';

import { resetBioAuthState } from '../../utils/biometricAuth';
import { actions as UserActions } from '../../stores/modules/user';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { usersAPIClient } from '../../services/users';

import RequestPasswordReset from './RequestPasswordReset';

export type Props = {
  email: string;
  selectedCountry: CountryCodes;
  userActions: typeof UserActions;
  isBioAuthEnabled: boolean;
  centered: boolean;
};

const handleConfirm = (
  {
    emailAddress,
    selectedCountry,
  }: {
    emailAddress: string;
    selectedCountry: CountryCodes;
  },
  userStore: Record<string, any>,
): Promise<any> =>
  usersAPIClient
    .requestPasswordReset({
      email: emailAddress.toLowerCase(),
      taxCountry: selectedCountry,
    })
    .then(() => {
      // reset bio auth permission
      resetBioAuthState(userStore);
    });

const mapDispatchToProps = (dispatch: any) => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

const mapStateToProps = (state: any) => ({
  isBioAuthEnabled: !_.isNil(state.user.isBioAuthEnabled) && Boolean(state.user.isBioAuthEnabled),
  selectedCountry: settingsSelectors.selectedCountry(state),
});

const UndecoratedForgotPasswordContainer = ({
  email,
  userActions,
  isBioAuthEnabled,
  centered,
  selectedCountry,
}: Props) => (
  <RequestPasswordReset
    initialEmailAddress={email}
    onConfirm={({ emailAddress }) =>
      handleConfirm(
        { emailAddress, selectedCountry },
        {
          isBioAuthEnabled,
          resetBioAuthPermission: userActions.resetBioAuthPermission,
        },
      )
    }
    centered={centered}
  />
);

export const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UndecoratedForgotPasswordContainer);
