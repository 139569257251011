import { $Keys } from 'utility-types';
import * as React from 'react';
import { ScrollView } from 'react-native';

import { Button, Container, NavButton } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { testID } from 'src/common/testID';

import {
  theme,
  Box,
  BodySecondaryText,
  DisplayTitleText,
  Section,
  useLargeScreenQuery,
} from '../../components/core';
import { Error } from '../../components/form-field-extended';
import SelectWithOverlay from '../../containers/input/select-with-overlay';
import { ITALY_DONATION_PREFERENCE_DEFAULT_VALUE } from '../../common/constants-it';
import { Option } from '../../moved-modules/single-select/single-select';

import { NonProfitCodeInput } from './italy-submission/non-profit-code-input';
import { DonationFormField } from './italy-submission/donation-form-field';

type InputFieldData = {
  inputValue: string;
};
export type SelectFieldData = {
  selectedValue: string;
  options: Option[];
};

export type DonationFormData = {
  churchAndState: SelectFieldData;
  churchAndStateArea: SelectFieldData;
  nonProfit: SelectFieldData;
  nonProfitCode: InputFieldData;
  politicalParty: SelectFieldData;
};

export type DonationFormFieldName = $Keys<DonationFormData>;

type Props = {
  donationFormData: DonationFormData;
  onDonationFieldChange: (
    field: DonationFormFieldName,
  ) => (value: string, textLabel?: string) => void;
  shouldDisplayNonProfitCodeField: boolean;
  shouldDisplayStateAreaField: boolean;
  onNonProfitCodeInputFocus?: () => void;
  onNonProfitCodeInputBlur?: () => void;
  nonProfitCodeInputError?: Error;
  onBackButtonPress(): void;
  onNext: () => void;
  isLoading: boolean;
  backNavigationText?: string;
};

export const ItalyDonationComponent = ({
  donationFormData,
  onDonationFieldChange,
  shouldDisplayStateAreaField,
  shouldDisplayNonProfitCodeField,
  onNonProfitCodeInputFocus,
  onNonProfitCodeInputBlur,
  nonProfitCodeInputError,
  onBackButtonPress,
  onNext,
  isLoading,
  backNavigationText = 'it.page.navigation.back',
}: Props): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();

  const colorResolver = (value: string) => {
    return value === ITALY_DONATION_PREFERENCE_DEFAULT_VALUE
      ? theme.color.secondaryText
      : theme.color.primary;
  };

  return (
    <>
      {isLargeScreen && (
        <NavButton text={getTranslationText(backNavigationText)} onPress={onBackButtonPress} />
      )}

      <Section size="wide" centered>
        <Box flex>
          <ScrollView>
            <Box left={2} right={2} top={isLargeScreen ? 9 : 2} bottom={2}>
              <Box bottom={1.5}>
                <DisplayTitleText id="italy-donation.screen.title" />
              </Box>
              <BodySecondaryText id="italy-donation.screen.subtitle" />
            </Box>
            <DonationFormField labelId="donation.field.church">
              <SelectWithOverlay
                onChange={onDonationFieldChange('churchAndState')}
                options={donationFormData.churchAndState.options}
                value={donationFormData.churchAndState.selectedValue}
                selectedItemColor={colorResolver(donationFormData.churchAndState.selectedValue)}
                isSelectFullScreenHeight
                dropdownType="text"
                testId="italy-donation.select.church"
              />
            </DonationFormField>
            {shouldDisplayStateAreaField && (
              <DonationFormField labelId="donation.field.church.area">
                <SelectWithOverlay
                  onChange={onDonationFieldChange('churchAndStateArea')}
                  options={donationFormData.churchAndStateArea.options}
                  value={donationFormData.churchAndStateArea.selectedValue}
                  selectedItemColor={colorResolver(
                    donationFormData.churchAndStateArea.selectedValue,
                  )}
                  isSelectFullScreenHeight
                  dropdownType="text"
                  testId="italy-donation.select.church.area"
                />
              </DonationFormField>
            )}
            <DonationFormField labelId="donation.field.nonProfit">
              <SelectWithOverlay
                onChange={onDonationFieldChange('nonProfit')}
                options={donationFormData.nonProfit.options}
                value={donationFormData.nonProfit.selectedValue}
                selectedItemColor={colorResolver(donationFormData.nonProfit.selectedValue)}
                isSelectFullScreenHeight
                dropdownType="text"
                testId="italy-donation.select.nonProfit"
              />
            </DonationFormField>
            {shouldDisplayNonProfitCodeField && (
              <DonationFormField labelId="donation.field.code" error={nonProfitCodeInputError}>
                <NonProfitCodeInput
                  value={donationFormData.nonProfitCode.inputValue}
                  onChange={onDonationFieldChange('nonProfitCode')}
                  onFocus={onNonProfitCodeInputFocus}
                  onBlur={onNonProfitCodeInputBlur}
                  testId="italy-donation.input.nonProfitCode"
                />
              </DonationFormField>
            )}
            <DonationFormField labelId="donation.field.politicalParty">
              <SelectWithOverlay
                onChange={onDonationFieldChange('politicalParty')}
                options={donationFormData.politicalParty.options}
                value={donationFormData.politicalParty.selectedValue}
                selectedItemColor={colorResolver(donationFormData.politicalParty.selectedValue)}
                isSelectFullScreenHeight
                dropdownType="text"
                testId="italy-donation.select.politicalParty"
              />
            </DonationFormField>
          </ScrollView>
          <Container
            shadow={['sticky2', 'none']}
            bgColor="greytones.white"
            h={['85', 'auto']}
            px={['24', 0]}
          >
            <Button
              variant="primary"
              title={getTranslationText('italy-donation.footer.button')}
              onPress={onNext}
              width={['xl', 'm']}
              my={['16', '70']}
              isLoading={isLoading}
              alignSelf={['center', 'flex-start']}
              {...testID('italy-donation.footer.button')}
            />
          </Container>
        </Box>
      </Section>
    </>
  );
};
