import { answerFor } from '@taxfix/quizmaster/dist/q-and-a/answersHelper';
import { Answer, Id, Responses } from '@taxfix/quizmaster/dist/types';

import {
  ADDRESS,
  DATE_OF_BIRTH,
  FIRST_NAME,
  LAST_NAME,
  TAX_ID_NR,
  JOINT_ASSESSMENT,
  PARTNER_DATE_OF_BIRTH,
  PARTNER_FIRST_NAME,
  PARTNER_LAST_NAME,
  PARTNER_TAX_ID_NR,
  TAX_AUTHORITY,
  WHAT_IS_YOUR_MARITAL_STATUS,
  ANSWER_VALUE_SINGLE,
} from 'src/taxfix-business-logic/constants/question-answer';

import { Address } from '../../types/geo';

class DataExtractor {
  constructor(responses: Responses) {
    this.responses = responses;
  }

  responses: Responses;

  fetch(id: Id) {
    return answerFor(id, this.responses);
  }

  // @ts-ignore
  get address(): Address | null | undefined {
    const place = this.fetch(ADDRESS.answerID);
    return place != null ? place.address : null;
  }

  // @ts-ignore
  get addressWithCountryCode(): Record<string, any> | null | undefined {
    const place = this.fetch(ADDRESS.answerID);
    return place != null ? { ...place.address, countryCode: place.countryCode } : null;
  }

  // @ts-ignore
  get dateOfBirth(): Answer | null | undefined {
    return this.fetch(DATE_OF_BIRTH.answerID);
  }

  // @ts-ignore
  get firstName(): string | null | undefined {
    return this.fetch(FIRST_NAME.answerID);
  }

  // @ts-ignore
  get lastName(): string | null | undefined {
    return this.fetch(LAST_NAME.answerID);
  }

  // @ts-ignore
  get taxId(): number | null | undefined {
    return this.fetch(TAX_ID_NR.answerID);
  }

  // @ts-ignore
  get taxAuthority(): Record<string, any> | null | undefined {
    return this.fetch(TAX_AUTHORITY.answerID);
  }

  // @ts-ignore
  get jointAssessment(): boolean | null | undefined {
    /*
      We have to check here for MARITAL_STATUS with JA
      because it's not possible to get only the activeAnwers from
      a submitted question store.
      JA could have a stale answer as TRUE case a
      user starts with a JA submission, move back and changes the
      MARITAL_STATUS to single.
    */
    return (
      this.fetch(JOINT_ASSESSMENT.answerID) &&
      this.fetch(WHAT_IS_YOUR_MARITAL_STATUS.answerID) !== ANSWER_VALUE_SINGLE
    );
  }

  // @ts-ignore
  get fullName(): string | null | undefined {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    return null;
  }

  // @ts-ignore
  get partnerDateOfBirth(): Answer | null | undefined {
    return this.fetch(PARTNER_DATE_OF_BIRTH.answerID);
  }

  // @ts-ignore
  get partnerFirstName(): string | null | undefined {
    return this.fetch(PARTNER_FIRST_NAME.answerID);
  }

  // @ts-ignore
  get partnerLastName(): string | null | undefined {
    return this.fetch(PARTNER_LAST_NAME.answerID);
  }

  // @ts-ignore
  get partnerTaxId(): number | null | undefined {
    return this.fetch(PARTNER_TAX_ID_NR.answerID);
  }
}

export default DataExtractor;
