import { UserResponse, Responses } from '@taxfix/quizmaster/dist/types';
import { CountryCodes } from '@taxfix/types';

type SyncMeta = {
  answeredAt: Date;
  isSynced: boolean;
};
export type SyncMetaMap = Record<string, SyncMeta>;
export type AnswerWithSyncMeta = UserResponse & SyncMeta;
export type AnswerWithAnsweredAt = UserResponse & {
  answeredAt: Date;
};
export type RetrievedAnswer = UserResponse & {
  answeredAt: string;
  version?: string;
  countryCode: CountryCodes;
};
export type AnswersByCountryAndYear = Record<CountryCodes, Record<string, RetrievedAnswer[]>>;

const fillMissingSyncMeta = (syncMetaMap: SyncMetaMap = {}, allAnswers: Responses): SyncMetaMap => {
  const missingSyncMetaMap: SyncMetaMap = {};
  Object.values(allAnswers)
    .filter((answer: any) => {
      // filter old answer data which has remained by unknown reaosn.
      const isDeprecatedAnswer = answer.answer && answer.answer.value != null;
      if (isDeprecatedAnswer) return false;
      // skip if this answer has syncMeta already
      const syncMeta = syncMetaMap[`${answer.year}.${answer.answerID}`];
      if (syncMeta != null) return false;
      return true;
    })
    .forEach((answer: any) => {
      missingSyncMetaMap[`${answer.year}.${answer.answerID}`] = {
        answeredAt: new Date('2000-01-01'),
        isSynced: false,
      };
    });
  return missingSyncMetaMap;
};

const createSyncMetaMap = (answersToSync: AnswerWithAnsweredAt[], isSynced: boolean): SyncMetaMap =>
  answersToSync.reduce(
    (maps, answer) => ({
      ...maps,
      [`${answer.year}.${answer.answerID}`]: {
        isSynced,
        answeredAt: answer.answeredAt,
      },
    }),
    {},
  );

const getAnswersCount = (answerTree: AnswersByCountryAndYear): number => {
  let count = 0;
  Object.keys(answerTree).forEach((country) => {
    Object.keys((answerTree as any)[country]).forEach((year) => {
      count += (answerTree as any)[country][year].length;
    });
  });
  return count;
};

export { fillMissingSyncMeta, createSyncMetaMap, getAnswersCount };
