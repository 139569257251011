import React from 'react';

import { Policy } from 'src/hocs/with-legal-conditions';

import { CheckBox } from './checkbox/checkbox';
import { LinkText, BodySecondaryText, Box } from './core';
import AcceptTerms from './core/AcceptTerms';

type Props = {
  termsAccepted: boolean;
  handleTermsChange: () => void;
  isLoading: boolean;
  onTermsClicked?: (termsClicked: Policy) => void;
  translationKey: string;
  selectedCountry: string;
  style?: Record<string, any>;
  disabled?: boolean;
  textDecorationLine?: string;
};

const AcceptTermsWithCheckBox = (props: Props) => {
  const {
    termsAccepted,
    handleTermsChange,
    isLoading,
    onTermsClicked: handleTermsClicked,
    translationKey,
    selectedCountry,
    style,
    disabled,
    textDecorationLine,
  } = props;
  return (
    <Box bottom={2} direction="row" right={1}>
      <CheckBox
        checked={termsAccepted}
        onToggle={handleTermsChange}
        disabled={isLoading || disabled}
        testId="accept.terms.conditions"
      />
      <Box left={1} flex>
        <AcceptTerms
          onTerms={handleTermsClicked as any}
          linkComponent={LinkText}
          textComponent={BodySecondaryText}
          translationKey={translationKey}
          country={selectedCountry}
          style={style}
          textDecorationLine={textDecorationLine}
        />
      </Box>
    </Box>
  );
};

export default AcceptTermsWithCheckBox;
