import React, { Component } from 'react';
import { LayoutAnimation } from 'react-native';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { withQuizmasterLight, QuizmasterLight } from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds } from 'src/common/constants-it';

import { FeedbackModalComponent } from '../../components/FeedbackModal/FeedbackModal';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

import { FeedbackInquiry } from './FeedbackInquiry';
import { FeedbackResult } from './FeedbackResult';
import {
  FeedbackInquiryType,
  FeedbackResultType,
  FeedbackModalType,
  feedbackInquiryTypes,
  feedbackResultTypes,
} from './FeedbackModal.types';

type OwnProps = {
  year: number;
  onClose?: () => void;
  inquiryType: FeedbackInquiryType;
};

type Props = OwnProps & {
  quizmasterLight: QuizmasterLight;
};

type State = {
  currentModal: FeedbackModalType;
  showModal: boolean;
};

const CustomLayoutLinear = {
  duration: 200,
  create: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
};

class FeedbackModalContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentModal: props.inquiryType,
      showModal: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      LayoutAnimation.configureNext(CustomLayoutLinear);
      this.setState({
        showModal: true,
      });
    }, 100);
  }

  getCurrentModal = () => {
    const { year, onClose } = this.props;
    const { currentModal } = this.state;

    switch (currentModal) {
      case feedbackInquiryTypes.FEELING:
      case feedbackInquiryTypes.NPS:
        return (
          <FeedbackInquiry // @ts-ignore
            inquiryType={currentModal}
            year={year}
            onResult={this.handleResult}
            onDismiss={this.handleDismiss}
          />
        );

      case feedbackResultTypes.HAPPY:
      case feedbackResultTypes.DISAPPOINTED:
      case feedbackResultTypes.PROMOTER:
      case feedbackResultTypes.PASSIVE:
      case feedbackResultTypes.DETRACTOR:
        return (
          <FeedbackResult
            resultType={currentModal}
            year={year}
            onDismiss={this.handleDismiss}
            onClose={onClose}
          />
        );

      default:
        return null;
    }
  };

  handleDismiss = () => {
    const { year, onClose } = this.props;
    this.props.quizmasterLight[flagsQuestionIds.afterSubmissionFeedbackDisplayed].saveAnswer(true);
    Analytics.log(AnalyticsEvent.dismissFeedbackModalPressed, {
      year,
    });
    this.setState({
      showModal: false,
    });
    if (onClose) onClose();
  };

  handleResult = (nextModal: FeedbackResultType) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    this.props.quizmasterLight[flagsQuestionIds.afterSubmissionFeedbackDisplayed].saveAnswer(true);
    this.setState({
      currentModal: nextModal,
    });
  };

  render() {
    if (!this.state.showModal) {
      return null;
    }

    return <FeedbackModalComponent>{this.getCurrentModal()}</FeedbackModalComponent>;
  }
}

export const FeedbackModal = compose<React.ComponentType<OwnProps>>(
  injectIntl,
  withQuizmasterLight([flagsQuestionIds.afterSubmissionFeedbackDisplayed]),
)(FeedbackModalContainer);
