import React from 'react';
import { useBreakpointValue } from 'native-base';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';

import {
  Container,
  IdentificationUploadTemplate,
  NavButton,
  Text,
} from 'src/taxfix-components/src';
import { selectors as selectorsPrefill } from 'src/stores/modules/prefill';
import { PrefillStates } from 'src/types/prefills';
import { ScreenName } from 'src/types/screen-name';
import { useItalyIntl } from 'src/_italy/_hooks';
import { navigationActions } from 'src/routes/intl/it/config-it';

import { ErrorBanner, Footer, Preview, UploadInfoBanner, UploadWrapper } from '../components';
import { AllowedDocTypes, UploadComponentProps } from '../types';

export type Props = UploadComponentProps & {
  documentType: AllowedDocTypes;
};

export const UploadComponent: React.FC<Props> = ({
  files,
  errorMessage,
  overlayButtons,
  documentType,
  isUploadScreen,
  isLoading,
  onReuploadPress,
  onReceiveAssets,
  onContinuePress,
  onFilesRejected,
}) => {
  const { getTranslationText } = useItalyIntl();

  const isPassport = documentType === AllowedDocTypes.PASSPORT;
  const screenName = isPassport ? ScreenName.PassportUpload : ScreenName.DrivingLicenseUpload;
  const titleLabelId = `identity-upload.${isPassport ? 'passport' : 'driving-license'}.title`;
  const descriptionLabelId = useBreakpointValue({
    base: `identity-upload.${isPassport ? 'passport' : 'driving-license'}.description-small-screen`,
    md: `identity-upload.${isPassport ? 'passport' : 'driving-license'}.description-large-screen`,
  });

  const prefillState = useSelector(selectorsPrefill.getStatus);
  const identificationRejected = prefillState === PrefillStates.Rejected;

  const continueButtonText =
    isUploadScreen || errorMessage
      ? 'identity-upload.footer.upload-document'
      : identificationRejected
      ? 'identity-upload.footer.id-rejected'
      : 'identity-upload.footer.continue';

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Container flex={1}>
      {errorMessage && (
        <Container width="100%" position="absolute" zIndex={1} mt="20px">
          <ErrorBanner errorMessage={errorMessage} />
        </Container>
      )}
      {!isSmallScreen && (
        <NavButton
          text={getTranslationText('identity-upload.navigation.back')}
          onPress={navigationActions.back}
        />
      )}
      <Container
        width="100%"
        maxW="630px"
        flex={1}
        pt={['20px', '46px']}
        alignSelf="center"
        paddingX={['25px', '0px']}
      >
        <ScrollView style={{ flex: 1 }}>
          {isUploadScreen ? (
            <IdentificationUploadTemplate
              paddingBottom="45px"
              title={getTranslationText(titleLabelId)}
              description={
                <Text
                  variant="bodyMBook"
                  color="greytones.textLight"
                  value={getTranslationText(descriptionLabelId)}
                />
              }
              mainContent={
                <UploadWrapper
                  imageName={isPassport ? 'passportFrontSkeleton' : 'drivingLicenseSkeleton'}
                  onFilesSelected={onReceiveAssets}
                  onFilesRejected={onFilesRejected}
                />
              }
              bottomElement={<UploadInfoBanner />}
            />
          ) : (
            <Preview files={files} />
          )}
        </ScrollView>
      </Container>

      <Footer
        continueButtonText={continueButtonText}
        continueButtonDisabled={isLoading}
        onContinuePress={!errorMessage ? onContinuePress : onReuploadPress}
        reuploadButtonText="identity-upload.footer.upload-again"
        reuploadButtonDisabled={isLoading}
        onReuploadPress={isUploadScreen || errorMessage ? undefined : onReuploadPress}
        onReceiveAssets={onReceiveAssets}
        onFilesRejected={onFilesRejected}
        overlayButtons={overlayButtons}
      />
    </Container>
  );
};
