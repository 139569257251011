import { $Values } from 'utility-types';
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import Config from 'react-native-config';

export const SET_CONFIG = 'devTools/SET_CONFIG';
export const ConfigKeys = Object.freeze({
  PackageId: 'PackageId',
  TaxSystem: 'TaxSystem',
  DeviceId: 'DeviceId',
  LastSessionId: 'LastSessionId',
  IsEnglishDisabled: 'IsEnglishDisabled',
  ForceInTaxSeason: 'ForceInTaxSeason',
});
export const TaxSystems = Object.freeze({
  NoOverride: 0,
  Legacy: 1,
  TaxEngine: 2,
  Router: 3,
});
export type TaxSystem = $Values<typeof TaxSystems> | null;
type ConfigKey = $Values<typeof ConfigKeys>;
export type State = {
  config: Record<ConfigKey, any>;
};
type SetConfigAction = {
  type: typeof SET_CONFIG;
  payload: Record<ConfigKey, any>;
};
type Action = SetConfigAction;

const setConfig = (key: ConfigKey, value: any) => ({
  type: SET_CONFIG,
  payload: {
    [key]: value,
  },
});

export const actions = {
  setConfig,
};
export const initial: State = {
  config: {} as unknown as Record<ConfigKey, any>,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, config: { ...state.config, ...action.payload } };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
// selectors
export type RootState = {
  devTools: State;
};

const getConfig = (state: RootState, key: ConfigKey): any => state.devTools.config[key];

const getConfigV2 =
  (key: ConfigKey) =>
  (state: RootState): any =>
    state.devTools.config[key];

const isEnglishDisabled = (state: RootState): boolean =>
  !!state.devTools.config[ConfigKeys.IsEnglishDisabled];

const isTaxSystemRouterEnabledForDev = (state: RootState): boolean =>
  state.devTools.config[ConfigKeys.TaxSystem] === TaxSystems.Router;

export const selectors = {
  getConfig,
  getConfigV2,
  isEnglishDisabled,
  isTaxSystemRouterEnabledForDev,
};
export const persistFilter =
  Config.SHOW_DEBUG_SCREEN === 'true'
    ? createBlacklistFilter('devTools', [])
    : createWhitelistFilter('devTools', []);
