/* eslint-disable no-undef */

/* eslint-disable global-require */

/* eslint-disable import/first */

/* global document window */
import 'react-native-gesture-handler';
// flowlint untyped-import:off
import { AppRegistry } from 'react-native';
import Config from 'react-native-config';
import firebase from 'firebase/app';

// flowlint untyped-import:error
import { css as webCss } from '../assets/css';

import { BootstrapRoot } from './containers/bootstrap-root';

function start() {
  // @ts-expect-error
  firebase.initializeApp(__FIREBASE_APP_CONFIG__);
  AppRegistry.registerComponent('Home', () => BootstrapRoot);

  // Add web specific CSS
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(webCss));
  (document.head || {}).appendChild(style);

  AppRegistry.runApplication('Home', {
    rootTag: document.getElementById('root'),
  });
}

if (Config.CONFIRMATION_PASSWORD) {
  let ready = false;
  while (!ready) {
    const password = window.prompt('Password');
    if (password === Config.CONFIRMATION_PASSWORD) {
      ready = true;
    }
  }
  start();
} else {
  start();
}
