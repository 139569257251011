import React from 'react';

import { useNavigation } from 'src/hooks/navigation-hook';

import { ProductBundleDetailsComponent } from './product-bundle-details-component';

export const ProductBundleDetailsContainer = (): JSX.Element => {
  const {
    navigation,
    navigationParams: { showPremiumProduct },
  } = useNavigation();

  return (
    <ProductBundleDetailsComponent
      showPremiumProduct={showPremiumProduct}
      onBackButtonPress={navigation.canGoBack() ? navigation.goBack : undefined}
    />
  );
};
