import { Dispatch } from 'redux';
import objectHash from 'object-hash';
import sortBy from 'lodash/sortBy';
import { Calculations } from '@taxfix/income-tax-calculator';
import { Cache, Responses, TreeNode, IncomeTaxValue } from '@taxfix/quizmaster/dist/types';

import QuestionStore from 'src/stores-legacy/QuestionStore';
import * as incomeTaxCalculatorSDK from 'src/utils/de/income-tax-calculator-sdk';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { transformTaxValues } from 'src/services/income-tax-calculator';

import {
  Action,
  ActionTypes,
  UpdateCalculationsLoadingAction,
  UpdateIncomeFieldsAction,
  UpdateTaxCalculationsAction,
} from './types';
import { currentIncomeFieldHashSelector, currentIncomeFieldsSelector } from './selectors';
import { RootState } from './reducers';

export const updateCalculationsLoading = (
  taxYear: number,
  isCalculationsLoading: boolean,
): UpdateCalculationsLoadingAction => ({
  type: ActionTypes.UPDATE_TAX_CALCULATIONS_STATUS,
  taxYear,
  isCalculationsLoading: isCalculationsLoading,
});

export const updateTaxCalculations = (
  taxYear: number,
  incomeFieldHash: string,
  incomeFields: IncomeTaxValue[],
  calculations: Calculations,
): UpdateTaxCalculationsAction => ({
  type: ActionTypes.UPDATE_TAX_CALCULATIONS,
  taxYear,
  incomeFieldHash,
  incomeFields,
  calculations,
});

export const updateIncomeTaxFields = (
  taxYear: number,
  incomeFields: IncomeTaxValue[],
): UpdateIncomeFieldsAction => ({
  type: ActionTypes.UPDATE_INCOME_FIELDS,
  taxYear,
  incomeFields,
});

const getIncomeFields = (
  taxYear: number,
  tree: TreeNode,
  cache: Cache,
  activeResponseJS: Responses,
): IncomeTaxValue[] => {
  if (taxYear && tree && cache && activeResponseJS) {
    const incomeFields = transformTaxValues(taxYear, tree, cache, activeResponseJS);
    return sortBy(incomeFields, ['nr', 'lfdNr', 'index']);
  }
  return [];
};

export const updateIncomeFields =
  (taxYear: number, tree: TreeNode, cache: Cache, activeResponseJS: Responses) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      if (taxYear && tree && cache && activeResponseJS) {
        const incomeFields = getIncomeFields(taxYear, tree, cache, activeResponseJS);
        dispatch(updateIncomeTaxFields(taxYear, incomeFields));
      }
    } catch (err) {
      logger.info(`Error updating income fields: ${err}`);
    }
  };

const getCalculationResult =
  (questionStore: QuestionStore) =>
  async (dispatch: Dispatch<Action>, getState: () => RootState): Promise<void> => {
    const { year: taxYear } = questionStore;
    const rootState = getState();

    const incomeFields = currentIncomeFieldsSelector(rootState, taxYear);
    const prevIncomeFieldHash = currentIncomeFieldHashSelector(rootState, taxYear);

    try {
      if (taxYear && incomeFields.length) {
        const incomeFieldHash = objectHash(incomeFields);

        if (prevIncomeFieldHash !== incomeFieldHash) {
          /**
           * Set calculation loading. Useful for loading bar
           */
          dispatch(updateCalculationsLoading(taxYear, true));

          const calculations = <Calculations>(
            await incomeTaxCalculatorSDK.getCalculationResult(taxYear, incomeFields, true)
          );

          /**
           * Set calculations to be used by the quizmaster calculator
           */
          dispatch(updateTaxCalculations(taxYear, incomeFieldHash, incomeFields, calculations));
        }
      }
    } catch (e) {
      // TODO: check what to do in case of error.
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      dispatch(updateCalculationsLoading(taxYear, false));
    }
  };

export const actions = {
  getCalculationResult,
  updateCalculationsLoading,
};
