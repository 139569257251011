import { useTheme } from 'native-base';
import React from 'react';

import { Card } from '../../general/Card';
import { ContactLink } from '../../general/ContactLink';
import { HeaderTaxAdvisor } from '../../general/HeaderTaxAdvisor';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { Props } from './types';

export const ContactCard: React.FC<Props> = ({
  informationTaxAdvisor,
  handleSendMessage,
  handleScheduleCall,
  ...otherProps
}) => {
  const { name, job, content, photo } = informationTaxAdvisor;
  const { colors } = useTheme();

  return (
    <Card
      variant="sticky1"
      backgroundColor={colors.greytones.greyBlue}
      maxWidth="300px"
      {...otherProps}
    >
      <Container backgroundColor={colors.greytones.white} borderRadius={1} padding={2}>
        <HeaderTaxAdvisor photo={photo} name={name} job={job} justifyContent="flex-start" />
        <Text variant="bodySBook" color={colors.greytones.textDefault}>
          {content}
        </Text>
      </Container>
      <ContactLink handleLink={handleSendMessage} linkMessage="Send message" />
      <ContactLink handleLink={handleScheduleCall} linkMessage="Schedule call" borderInTheTop />
    </Card>
  );
};
