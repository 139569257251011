import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';
import { useSelector } from 'react-redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { useChatPopup } from 'src/_italy/_hooks';

type Props = ListRowComponentPropsType<any> & WithNavigation;

const SupportCenter = (props: Props) => {
  const { showIcon = false, textVariation = '' } = props;
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const { handleOpenChatPopup } = useChatPopup();
  const handleSelectItem = (item: MenuItemData) => {
    trackMenuItem(item);
    handleOpenChatPopup();
  };

  return props.children({
    hide: false,
    data: {
      iconName: showIcon
        ? selectedCountry === CountryCodes.IT
          ? 'itChatSupport'
          : 'menuItems.support'
        : undefined,
      translationKey: `${menuItem.helpCenter}${textVariation}`,
      testId: menuItem.helpCenter,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const SupportCenterMenu = compose(withNavigation)(SupportCenter);
