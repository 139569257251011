import * as React from 'react';
import { Edge, SafeAreaView } from 'react-native-safe-area-context';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { EmitterSubscription, Keyboard, Platform } from 'react-native';

type Props = {
  keyboardAware: boolean;
  children?: React.ReactNode;
};
type State = {
  keyboardVisible: boolean;
};

class KeyboardAwareSafeAreaView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      keyboardVisible: false,
    };
    this.keyboardWillShowListener = null;
    this.keyboardWillHideListener = null;
  }

  componentDidMount() {
    if (this.props.keyboardAware && Platform.OS === 'ios') {
      this.keyboardWillShowListener = Keyboard.addListener(
        'keyboardWillShow',
        this.keyboardWillShow,
      );
      this.keyboardWillHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this.keyboardWillHide,
      );
    }
  }

  componentWillUnmount() {
    if (this.keyboardWillShowListener) this.keyboardWillShowListener.remove();
    if (this.keyboardWillHideListener) this.keyboardWillHideListener.remove();
  }

  keyboardWillShowListener?: EmitterSubscription | null;

  keyboardWillHideListener?: EmitterSubscription | null;

  keyboardWillShow = () => {
    this.setState({
      keyboardVisible: true,
    });
  };

  keyboardWillHide = () => {
    this.setState({
      keyboardVisible: false,
    });
  };

  render() {
    let edges: Edge[] = ['right', 'left'];
    if (!this.state.keyboardVisible) {
      edges = [...edges, 'bottom'];
    }
    return (
      <SafeAreaView
        style={
          Platform.OS !== 'web'
            ? {
                flex: 1,
              }
            : {}
        }
        edges={edges}
      >
        {this.props.children}
      </SafeAreaView>
    );
  }
}

const withSafeAreaView = (WrappedComponent: React.ComponentType<any>, isKeyboardAware: boolean) => {
  const Enhance = (props: any) => (
    <KeyboardAwareSafeAreaView keyboardAware={isKeyboardAware}>
      <WrappedComponent {...props} />
    </KeyboardAwareSafeAreaView>
  );

  hoistNonReactStatic(Enhance, WrappedComponent);
  return Enhance;
};

export default withSafeAreaView;
