import React, { useEffect } from 'react';
import { Linking, StyleSheet } from 'react-native';

import Overlay from 'src/components/overlay';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

import {
  BodySecondaryText,
  Box,
  Button,
  DisplayTitleText,
  IconButton,
  theme,
  useLargeScreenQuery,
} from '../../components/core';
import LinkableText from '../../components/auth/LinkableText';
import { ContactSupportRenderProps } from '../../containers/contact-support-container';

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
  container: {
    backgroundColor: theme.color.lightFill,
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 13,
    margin: 25,
    maxWidth: 600,
  },
  closeButton: {
    alignSelf: 'flex-end',
    position: 'absolute',
    height: 22.5,
    width: 22.5,
    top: 10,
    right: 10,
  },
  image: {
    marginBottom: 20,
    alignSelf: 'center',
  },
  text: {
    textAlign: 'center',
  },
  button: {
    width: 273,
  },
});

type Props = ContactSupportRenderProps & {
  onClose: () => void;
  taxYear?: number;
};

const DigicafOccupationUnsupportedOverlay: React.FC<Props> = ({
  handleEmail,
  onClose,
  taxYear,
}) => {
  const isLargeScreen = useLargeScreenQuery();
  const textMargin = isLargeScreen ? 10 : 2;

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'DigicafOccupationUnsupportedOverlay',
    });
  });

  return (
    <Overlay
      onCloseComplete={onClose}
      backgroundColor={theme.color.overlay}
      style={styles.overlay}
      appearFromTop
    >
      {() => (
        <Box top={2} right={2} bottom={5} left={2} style={styles.container}>
          <Box right={2} top={2} bottom={3}>
            <IconButton
              testId="unsupportedOverlay::close"
              iconKey="cross"
              onPress={onClose}
              tintColor={theme.color.greyFillIcon}
              style={styles.closeButton}
            />
          </Box>

          <Box alignHorizontally="center" bottom={4} left={textMargin} right={textMargin}>
            <DisplayTitleText
              style={styles.text}
              id="it.digital-caf.qf.way.unsupported-modal.title"
            />
          </Box>

          <Box left={textMargin} right={textMargin} alignHorizontally="center">
            <LinkableText
              style={styles.text}
              translationKey="it.digital-caf.qf.way.unsupported-modal.paragraph"
              onPress={(url) => Linking.openURL(url)}
              textComponent={BodySecondaryText}
              linkComponent={BodySecondaryText}
              linkStyle={{
                color: theme.color.primary,
              }}
              translationValues={{ taxYear }}
            />
            <Box alignHorizontally="center" top={4}>
              <Button
                type="secondary"
                onPress={handleEmail}
                translationKey="it.digital-caf.qf.way.unsupported-modal.email-button"
                style={styles.button}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Overlay>
  );
};

export { DigicafOccupationUnsupportedOverlay };
