import React, { useState } from 'react';

import { Button, Container } from 'src/taxfix-components/src';
import { CheckboxWithText } from 'src/components/checkbox/checkbox-with-text';
import { SmallSecondaryText } from 'src/components/core';

type Props = {
  onSign: () => void;
  buttonActiveKey: string;
  buttonDisabledKey: string;
  checkboxKey: string;
};

const Footer: React.FC<Props> = ({ onSign, buttonActiveKey, buttonDisabledKey, checkboxKey }) => {
  const [isSignEnabled, setIsSignEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPress = async () => {
    setIsLoading(true);
    await onSign();
    setIsLoading(false);
  };

  return (
    <Container
      flexDirection={{ base: 'column', md: 'row' }}
      width="100%"
      justifyContent={{ base: 'center', md: 'space-around' }}
      alignItems="center"
      borderColor="greytones.graphicsLight"
      borderTopWidth="xs"
      padding={2}
      backgroundColor="white"
    >
      <CheckboxWithText
        isChecked={isSignEnabled}
        onChange={() => setIsSignEnabled(!isSignEnabled)}
        testId="self-sign-document.screen.confirmation.checkbox"
      >
        <SmallSecondaryText id={checkboxKey} />
      </CheckboxWithText>
      <Button
        width={{ base: 'xl', md: '1/4' }}
        title={isSignEnabled ? buttonActiveKey : buttonDisabledKey}
        onPress={onPress}
        variant="primary"
        disabled={!isSignEnabled}
        isLoading={isLoading}
        m={0}
      />
    </Container>
  );
};

export { Footer };
