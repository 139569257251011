import { PayDataField } from '../types/payslip';

import { DISALLOWED_QUESTIONS_IDS } from './data-rollover-disallowed-questions';

export type QuestionType = {
  questionID: string;
};

export type QuestionAndAnswerIDPairType = {
  answerID: string;
} & QuestionType;

const PLACE_OF_WORK: QuestionType = {
  questionID: 'c1e633f9c6b549755525',
};

export const DO_YOU_HAVE_CHILDREN: QuestionAndAnswerIDPairType = {
  questionID: '1479996010318',
  answerID: '1479996010318',
};

export const WHAT_DO_YOU_DO_FOR_LIFE: QuestionAndAnswerIDPairType = {
  questionID: '1479987855574',
  answerID: '1479987855574',
};

export const WHAT_ARE_YOU_DOING: QuestionAndAnswerIDPairType = {
  questionID: 'dc05ca0b6d3550193583',
  answerID: 'dc05ca0b6d3550193583',
};

export const HAVE_YOU_FILED_BEFORE: QuestionAndAnswerIDPairType = {
  questionID: '340519aeb11162c49daa',
  answerID: '340519aeb11162c49daa',
};

export const SILENT_HAS_FILED_BEFORE: QuestionAndAnswerIDPairType = {
  questionID: '892e47e99fc222afa3ef',
  answerID: '892e47e99fc222afa3ef',
};

export const ANSWER_VALUE_EMPLOYEE: QuestionAndAnswerIDPairType['answerID'] = 'Salary';

export const ANSWER_VALUE_STUDENT: QuestionAndAnswerIDPairType['answerID'] = 'Studying';

export const ANSWER_VALUE_MARRIED: QuestionAndAnswerIDPairType['answerID'] = 'MARRIED';

export const ANSWER_VALUE_SINGLE: QuestionAndAnswerIDPairType['answerID'] = 'SINGLE';

export const PAYSLIP_TAXCLASS_SIX: PayDataField = '6';

export const PAYSLIP_BRUTTO_ARBEITSLOHN_ANSWER_KEY: PayDataField = '3';

export const WITH_WHOM_DO_YOU_LIFE: QuestionAndAnswerIDPairType = {
  questionID: 'e46b29eedb264f381113',
  answerID: 'e46b29eedb264f381113',
};

export const WHAT_IS_YOUR_MARITAL_STATUS: QuestionAndAnswerIDPairType = {
  questionID: '1479986195761',
  answerID: '1479986195761',
};

export const ARE_YOU_FORMALLY_MARRIED: QuestionAndAnswerIDPairType = {
  questionID: 'b1f0d94631b69e6f53ac',
  answerID: 'b1f0d94631b69e6f53ac',
};

export const DID_YOU_LIVE_TOGETHER: QuestionAndAnswerIDPairType = {
  questionID: 'ed87edc815b81c508da3',
  answerID: 'ed87edc815b81c508da3',
};

export const WHEN_DID_YOU_GET_MARRIED: QuestionAndAnswerIDPairType = {
  questionID: '73493d9e5d167d0ccce1',
  answerID: '73493d9e5d167d0ccce1',
};

export const WHEN_DID_YOU_NOT_LIVE_TOGETHER: QuestionAndAnswerIDPairType = {
  questionID: '9402f711bb63d875caab',
  answerID: '9402f711bb63d875caab',
};

export const WHEN_ARE_YOU_WIDOWED: QuestionAndAnswerIDPairType = {
  questionID: '1479986347046',
  answerID: '1479986347046',
};

export const WHEN_ARE_YOU_DIVORCED: QuestionAndAnswerIDPairType = {
  questionID: '11f1bd8f56d6ba3cbc71',
  answerID: '11f1bd8f56d6ba3cbc71',
};

export const STUDENT_INCOME: QuestionAndAnswerIDPairType = {
  questionID: 'e09aa67a41cd4ffef3f1',
  answerID: 'e09aa67a41cd4ffef3f1',
};

export const DOES_ANY_OF_THIS_APPLY_TO_YOU: QuestionAndAnswerIDPairType = {
  questionID: '3d1aaf6a184e6c35d2ac',
  answerID: '3d1aaf6a184e6c35d2ac',
};

export const DID_YOU_GET_ANY_OTHER_INCOME: QuestionAndAnswerIDPairType = {
  questionID: '1479993451680',
  answerID: '1479993451680',
};

export const FIRST_NAME: QuestionAndAnswerIDPairType = {
  questionID: 'bac8b30ede534e8df1c4',
  answerID: 'bac8b30ede534e8df1c4',
};

export const FIRST_NAME_LEGACY: QuestionAndAnswerIDPairType = {
  questionID: 'bac8b30ede534e8df1c4',
  answerID: 'bac8b30ede534e8df1c4[0]',
};

export const FIRST_NAME_FR: QuestionType = {
  questionID: 'e_eccab85d-9bd4-43ff-9cdb-dacb867bb622',
};

export const LAST_NAME: QuestionAndAnswerIDPairType = {
  questionID: '850092601854854186bb',
  answerID: '850092601854854186bb',
};

export const DATE_OF_BIRTH: QuestionAndAnswerIDPairType = {
  questionID: 'ffc2fa53e4af92f66be5',
  answerID: 'ffc2fa53e4af92f66be5',
};

export const ADDRESS: QuestionAndAnswerIDPairType = {
  questionID: '1480340133017',
  answerID: '1480340133017',
};

export const GENDER: QuestionAndAnswerIDPairType = {
  questionID: '55b91aa4e85d3f7181f4',
  answerID: '55b91aa4e85d3f7181f4',
};

export const ANNUAL_PAYSLIP: QuestionAndAnswerIDPairType = {
  questionID: '137d5cffc13f736cf5b8',
  answerID: '137d5cffc13f736cf5b8',
};

export const DID_RECEIVE_MORE_THAN_ONE_PAYSLIP: QuestionAndAnswerIDPairType = {
  questionID: '01b62b91e5bb4224495e',
  answerID: '01b62b91e5bb4224495e',
};

export const HOW_MANY_PAYSLIPS_DO_YOU_HAVE: QuestionAndAnswerIDPairType = {
  questionID: 'f3fde7b9f6a54e4dab52',
  answerID: 'f3fde7b9f6a54e4dab52',
};

export const WHAT_METHOD_PAYSLIP: QuestionAndAnswerIDPairType = {
  questionID: 'd888c3ea02a5810c4964',
  answerID: 'd888c3ea02a5810c4964',
};

export const PAYSLIP_DATA: QuestionAndAnswerIDPairType = {
  questionID: 'd888c3ea02a5810c4964',
  answerID: 'd888c3ea02a5810c4964',
};

export const TAX_AUTHORITY: QuestionAndAnswerIDPairType = {
  questionID: '0cd709738e027d6aa555',
  answerID: '0cd709738e027d6aa555',
};

export const STEUER_NUMBER: QuestionAndAnswerIDPairType = {
  questionID: 'bb335d9005c29b0937ea',
  answerID: 'bb335d9005c29b0937ea',
};

export const JOINT_ASSESSMENT: QuestionAndAnswerIDPairType = {
  questionID: '1479987101276',
  answerID: '1479987101276',
};

export const TAX_ID_NR: QuestionAndAnswerIDPairType = {
  questionID: 'de2e82c668cbc3dce89e',
  answerID: 'de2e82c668cbc3dce89e',
};

export const BANK_IBAN: QuestionAndAnswerIDPairType = {
  questionID: '48695d98a564699ce93b',
  answerID: '48695d98a564699ce93b',
};

export const BANK_NAME: QuestionAndAnswerIDPairType = {
  questionID: '5924c5411d71c52bb0b6',
  answerID: '5924c5411d71c52bb0b6',
};

export const BANK_ACCOUNT_HOLDER: QuestionAndAnswerIDPairType = {
  questionID: '31efeaee887c8c67ae7f',
  answerID: '31efeaee887c8c67ae7f',
};

export const PARTNER_FIRST_NAME: QuestionAndAnswerIDPairType = {
  questionID: 'e259271c36bd790f3a16',
  answerID: 'e259271c36bd790f3a16',
};

export const PARTNER_FIRST_NAME_LEGACY: QuestionAndAnswerIDPairType = {
  questionID: 'bac8b30ede534e8df1c4',
  answerID: 'bac8b30ede534e8df1c4[1]',
};

export const PARTNER_LAST_NAME: QuestionAndAnswerIDPairType = {
  questionID: '6a4f3e2b6b2ccefae68d',
  answerID: '6a4f3e2b6b2ccefae68d',
};

export const PARTNER_DATE_OF_BIRTH: QuestionAndAnswerIDPairType = {
  questionID: '6d415f5754298dcfbe88',
  answerID: '6d415f5754298dcfbe88',
};

export const PARTNER_TAX_ID_NR: QuestionAndAnswerIDPairType = {
  questionID: 'c545d21176f3e703cf90',
  answerID: 'c545d21176f3e703cf90',
};

export const PARTNER_GENDER: QuestionAndAnswerIDPairType = {
  questionID: 'a2bcd3ede0efe4b24ecc',
  answerID: 'a2bcd3ede0efe4b24ecc',
};

export const PARTNER_ADDRESS: QuestionAndAnswerIDPairType = {
  questionID: '92a74de71d57a5c123b0',
  answerID: '92a74de71d57a5c123b0[1]',
};

export const HAVE_YOU_EVER_BEEN_A_MEMBER_OF_A_CHURCH: QuestionAndAnswerIDPairType = {
  questionID: 'defa527fd08f99dfc0a7',
  answerID: 'defa527fd08f99dfc0a7',
};

export const WERE_YOU_A_MEMBER_OF_A_CHURCH_IN_YEAR: QuestionAndAnswerIDPairType = {
  questionID: '51b1a0e05134ee9314a3',
  answerID: '51b1a0e05134ee9314a3',
};

export const WHICH_CHURCH_WERE_YOU_A_MEMBER_OF_IN_YEAR: QuestionAndAnswerIDPairType = {
  questionID: '43443134001be1d25cab',
  answerID: '43443134001be1d25cab',
};

export const HAVE_YOU_RECEIVED_REVENUE_COMPENSATING_PAY: QuestionAndAnswerIDPairType = {
  questionID: '7e04a09caf917e0dd66f',
  answerID: '7e04a09caf917e0dd66f',
};

export const DID_YOU_HAVE_TO_PAY_BACK_ANY_WAGE_REPLACEMENT_PAYMENTS: QuestionAndAnswerIDPairType = {
  questionID: '399304b1f70ba46ce2fc',
  answerID: '399304b1f70ba46ce2fc',
};

export const HOW_MUCH_UNEMPLOYMENT_PAY_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: '17d39f4457847855202d',
  answerID: '17d39f4457847855202d',
};

export const HOW_MUCH_INSOLVENCY_PAY_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: '1dc84bdd962f438ca30b',
  answerID: '1dc84bdd962f438ca30b',
};

export const HOW_MUCH_SICK_PAY_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: '83db27ab5679b2f444ff',
  answerID: '83db27ab5679b2f444ff',
};

export const HOW_MUCH_MATERNITY_PAY_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: 'a84d656550e578620616',
  answerID: 'a84d656550e578620616',
};

export const HOW_MUCH_ARBEITSLOSENHILFE_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: 'fc15c6f4ac7b6045133d',
  answerID: 'fc15c6f4ac7b6045133d',
};

export const HOW_MUCH_PAY_RELATED_TO_INFEKTIONSSCHUTZGESETZ_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType =
  {
    questionID: 'c9eed07e0b0adc0a0f57',
    answerID: 'c9eed07e0b0adc0a0f57',
  };

export const HOW_MUCH_PAY_RELATED_TO_BUNDESVERSORGUNGSGESETZ_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType =
  {
    questionID: '6c0490db52781a3dde11',
    answerID: '6c0490db52781a3dde11',
  };

export const HOW_MUCH_PARENTAL_PAY_HAVE_YOU_RECEIVED: QuestionAndAnswerIDPairType = {
  questionID: '1085b12beab37ce4e1e9',
  answerID: '1085b12beab37ce4e1e9',
};

export const WHAT_KIND_OF_WAGE_REPLACEMENT_PAY_DID_YOU_RECEIVE: QuestionAndAnswerIDPairType = {
  questionID: 'd607b5036da08bd40e10',
  answerID: 'd607b5036da08bd40e10',
};

export const HOW_MUCH_OTHER_WAGE_REPLACEMENT_PAY_HAVE_YOU_RECEIVED_IN_TOTAL: QuestionAndAnswerIDPairType =
  {
    questionID: '0a10882ea100b0e51b3f',
    answerID: '0a10882ea100b0e51b3f',
  };

export const HOW_MUCH_WAGE_REPLACEMENT_PAYMENTS_DID_YOU_PAY_BACK_IN_TOTAL: QuestionAndAnswerIDPairType =
  {
    questionID: 'c5c154f16fe64a90d4ab',
    answerID: 'c5c154f16fe64a90d4ab',
  };

export const WHAT_KIND_OF_WAGE_REPLACEMENT_PAY_DID_YOU_PAY_BACK: QuestionAndAnswerIDPairType = {
  questionID: '50117d4ff5e56cf6fad7',
  answerID: '50117d4ff5e56cf6fad7',
};

export const PENSIONS_AND_LIFE_INSURANCE: QuestionAndAnswerIDPairType = {
  questionID: 'dbb2357170fbfcf3b9eb',
  answerID: 'dbb2357170fbfcf3b9eb',
};

export const TOTAL_RIESTER_CONTRIBUTIONS: QuestionAndAnswerIDPairType = {
  questionID: '16c6df7e9cc808af8bea',
  answerID: '16c6df7e9cc808af8bea',
};

export const TOTAL_RURUP_CONTRIBUTIONS: QuestionAndAnswerIDPairType = {
  questionID: '06e55fcb8a1daea9823c',
  answerID: '06e55fcb8a1daea9823c',
};

export const SHORT_WORK: QuestionAndAnswerIDPairType = {
  questionID: '109fe66e8e8dfc43d3ee',
  answerID: '109fe66e8e8dfc43d3ee',
};

export const WAGE_REPLACEMENT_BENEFITS: QuestionAndAnswerIDPairType = {
  questionID: 'fecbb36018715dbf1b52',
  answerID: 'fecbb36018715dbf1b52',
};

export const PENSION_TYPE_FOR_YEAR: QuestionAndAnswerIDPairType = {
  questionID: 'c0ccfeed310ddbcd5c95',
  answerID: 'c0ccfeed310ddbcd5c95[0]',
};

export const FILING_EXPERIENCE: QuestionAndAnswerIDPairType = {
  answerID: '0b2760ca29fe76044512',
  questionID: '0b2760ca29fe76044512',
};

export const DIFM_COMMENTS_TO_TAX_ADVISOR: QuestionAndAnswerIDPairType = {
  answerID: 'b2c6584aa978b51623f2',
  questionID: 'b2c6584aa978b51623f2',
};

export const DISABLE_ADDRESS_CO_QUESTION_IDS = [PLACE_OF_WORK.questionID];

export const FILING_PARTNER_ANSWERS_IDS = [
  PARTNER_LAST_NAME.answerID,
  PARTNER_DATE_OF_BIRTH.answerID,
  PARTNER_TAX_ID_NR.answerID,
  PARTNER_GENDER.answerID,
];

export const PAYSLIP_QUESTION_IDS = [
  ANNUAL_PAYSLIP.answerID,
  DID_RECEIVE_MORE_THAN_ONE_PAYSLIP.answerID,
  HOW_MANY_PAYSLIPS_DO_YOU_HAVE.answerID,
  PAYSLIP_DATA.answerID,
];

export const UNSUPPORTED_ROLLOVER_QUESTIONS: string[] = Object.values(DISALLOWED_QUESTIONS_IDS);
