import React, { createRef, ReactNode, useState, useCallback } from 'react';
import {
  RouteProp,
  NavigationContainerRef,
  NavigationProp as ReactNavigationProp,
  NavigationState as ReactNavigationState,
  StackActionType,
  CommonNavigationAction,
  StackActions,
  LinkingOptions,
  NavigationContainer as ReactNavigationContainer,
  DefaultTheme,
} from '@react-navigation/native';
import { useReduxDevToolsExtension } from '@react-navigation/devtools';
import { StackNavigationProp } from '@react-navigation/stack';

import { Loading } from 'src/components/loading';
import useMobile from 'src/hooks/useMobile';

import { NavigateAction } from '../routes/navigate';

import { useNavigationTracking, TrackingMap } from './hooks/navigation-tracking-hook';
import { useNavigationStatusBar } from './hooks/navigation-status-bar-hook';
import { useErrorTracking } from './hooks/error-tracking-hook';
import { instrumentations } from './sentry/sentry-error-tracking';

export type {
  ReactNavigationProp as NavigationProp,
  ReactNavigationState as NavigationState,
  RouteProp,
  NavigateAction,
};

export type ReactNavigation<N, P extends Record<any, any>> = {
  navigation: StackNavigationProp<any>;
  route: RouteProp<P, N> & { state?: ReactNavigationState<P> };
};

type Action = StackActionType | CommonNavigationAction;

type NavigationParams = Record<string, any>;

type ResetAction = {
  index: number;
  actions: NavigateAction<any>[];
};

type SafeResetAction = {
  routes: NavigateAction[];
};

const navigationRef = createRef<NavigationContainerRef<Record<string, any>>>();

function dispatch(action: Action) {
  if (navigationRef.current) {
    navigationRef.current.dispatch(action);
  }
}

function navigate({ name, params }: NavigateAction): void {
  const { key, ...rest } = params || {};

  if (navigationRef.current) {
    navigationRef.current.navigate({
      name,
      key,
      params: rest,
    });
  }
}

function back(): void {
  if (navigationRef.current) {
    navigationRef.current.goBack();
  }
}

function push(name: string, params?: NavigationParams): void {
  dispatch(StackActions.push(name, params));
}

// Renaming 'actions' to 'routes' to keep retro-compatibility with v1 format.
function reset({ index, actions: routes }: ResetAction): void {
  if (navigationRef.current) {
    navigationRef.current.reset({
      index,
      routes,
    });
  }
}

function replace(name: string, params?: NavigationParams): void {
  dispatch(StackActions.replace(name, params));
}

function setParams(params: NavigationParams): void {
  if (navigationRef.current) {
    navigationRef.current.setParams(params);
  }
}

function popToTop(): void {
  dispatch(StackActions.popToTop());
}

function addListener(event: string, callback: () => any): () => void {
  if (navigationRef.current) {
    return navigationRef.current.addListener(event as any, callback);
  }

  return () => {};
}

function canGoBack(): boolean {
  if (navigationRef.current) {
    return navigationRef.current.canGoBack();
  }

  return false;
}

const navigation = {
  navigate,
  back,
  reset,
  replace,
  setParams,
  popToTop,
  addListener,
  canGoBack,
  push,
};

export type NavigationActions = typeof navigation;

const useTheme = () => {
  return DefaultTheme;
};

type Props<LinkingProp extends {} = ReactNavigation.RootParamList> = {
  children: ReactNode;
  linking?: LinkingOptions<LinkingProp> | undefined;
  trackingMap?: TrackingMap;
};

export const NavigationContainer = ({ children, linking, trackingMap }: Props): JSX.Element => {
  const screenTracking = useNavigationTracking({ trackingMap });
  const errorTracking = useErrorTracking();
  const statusBarStyling = useNavigationStatusBar();
  useReduxDevToolsExtension(navigationRef);
  const { isMobile } = useMobile();

  const theme = useTheme();

  const [navigationReady, setNavigationReady] = useState(false);
  /* constructs a callback to be called when the ReactNavigationContainer get mounted */
  const handleNavigationLoaded = useCallback(() => {
    errorTracking();
    setNavigationReady(true);

    // Register the navigation container with the instrumentation - only for native apps
    instrumentations?.routing?.registerNavigationContainer(navigationRef);
  }, [setNavigationReady]);

  return (
    <ReactNavigationContainer
      theme={theme}
      onStateChange={(currentState) => {
        errorTracking(currentState);
        screenTracking(currentState);
        statusBarStyling(currentState);
      }}
      onReady={handleNavigationLoaded}
      ref={navigationRef}
      linking={linking}
      documentTitle={{
        ...(!isMobile
          ? {
              formatter: (options) => options?.title || 'Taxfix',
            }
          : {}),
      }}
    >
      {navigationReady ? children : <Loading />}
    </ReactNavigationContainer>
  );
};

export function resetRoot({ routes }: SafeResetAction): void {
  if (navigationRef.current) {
    navigationRef.current.resetRoot({
      routes,
      index: 0,
    });
  }
}

export { navigation };
