import { useBreakpointValue } from 'native-base';
import React from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';

import { Container, NavButton } from 'src/taxfix-components/src';
import { selectors as selectorsPrefill } from 'src/stores/modules/prefill';
import { PrefillStates } from 'src/types/prefills';
import { navigationActions } from 'src/routes/intl/it/config-it';

import { useItalyIntl } from '../../_hooks';
import { ErrorBanner, Footer, Preview } from '../components';
import { DocumentSide, RefViewShotElement, UploadComponentProps } from '../types';

import { UploadWrapper } from './upload-wrapper';

type Props = UploadComponentProps & {
  refViewShot: RefViewShotElement;
  uploadSide: DocumentSide;
};

export const IdCardUploadComponent: React.FC<Props> = ({
  refViewShot,
  uploadSide,
  files,
  errorMessage,
  isUploadScreen,
  isLoading,
  overlayButtons,
  onReceiveAssets,
  onReuploadPress,
  onContinuePress,
  onFilesRejected,
}) => {
  const { getTranslationText } = useItalyIntl();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const prefillState = useSelector(selectorsPrefill.getStatus);
  const identificationRejected = prefillState === PrefillStates.Rejected;

  const continueButtonText =
    isUploadScreen || errorMessage
      ? 'identity-upload.footer.upload-document'
      : identificationRejected
      ? 'identity-upload.footer.id-rejected'
      : 'identity-upload.footer.continue';

  return (
    <>
      {errorMessage && (
        <Container width="100%" position="absolute" zIndex={1} mt="20px">
          <ErrorBanner errorMessage={errorMessage} />
        </Container>
      )}
      {!isSmallScreen && (
        <NavButton
          text={getTranslationText('identity-upload.navigation.back')}
          onPress={navigationActions.back}
        />
      )}
      <Container
        width="100%"
        maxW="630px"
        flex={1}
        pt={['20px', '46px']}
        alignSelf="center"
        paddingX={['25px', '0px']}
      >
        <ScrollView style={{ flex: 1 }}>
          {isUploadScreen ? (
            <UploadWrapper
              side={uploadSide}
              onFilesSelected={onReceiveAssets}
              onFilesRejected={onFilesRejected}
            />
          ) : (
            <Preview files={files} refViewShot={refViewShot} />
          )}
        </ScrollView>
      </Container>
      <Footer
        continueButtonText={continueButtonText}
        continueButtonDisabled={isLoading}
        onContinuePress={!errorMessage ? onContinuePress : onReuploadPress}
        reuploadButtonText="identity-upload.footer.upload-again"
        reuploadButtonDisabled={isLoading}
        onReuploadPress={isUploadScreen || errorMessage ? undefined : onReuploadPress}
        onReceiveAssets={onReceiveAssets}
        onFilesRejected={onFilesRejected}
        overlayButtons={overlayButtons}
      />
    </>
  );
};
