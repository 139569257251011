import { Buffer } from 'buffer';

import Config from 'react-native-config';
import { Platform } from 'react-native';
import Device from 'react-native-device-info';
import RNFB from 'react-native-blob-util';
import { Documents } from '@taxfix/taxfix-sdk';
import { GetDocumentsRequest } from '@taxfix/taxfix-sdk/dist/documents/get';
import { Document } from '@taxfix/taxfix-sdk/dist/documents/types';

import { CameraImageResult } from '../../containers/scanbot-camera';
import { createFormData } from '../../utils/form-data';

export const getDocumentName = (type: string, isSigned = false) =>
  `documents.titles.${type}${isSigned ? '.signed' : ''}`;

const BASE_URL = Config.API_BASE_URL;
const PLATFORM = Platform.OS;
type DefaultProps = {
  token: string;
  onUploadListener?: (event?: any) => any;
};
export type ScanPayslipOptions = DefaultProps & {
  data: {
    file: string;
    // base64 of the image
    year: number;
    isPDF?: boolean;
  };
};
export const DocumentAllowedFileTypes = {
  PDF: 'application/pdf',
  JPG: 'image/jpeg',
  PNG: 'image/png',
};
type DocumentFileTypes = 'application/pdf' | 'image/jpeg' | 'image/png';
export type PDFResult = {
  uri: string;
  type: string;
  name: string;
  size: number;
};
export type UploadDocumentOptions = DefaultProps & {
  payload: {
    image?: CameraImageResult;
    pdf?: PDFResult;
    fileType: DocumentFileTypes;
  };
  year: number;
  countryCode: string;
  type: string;
};
type CloneDocumentOptions = DefaultProps & {
  data: {
    id: number;
    year: number;
    type: string;
  };
};

const getFormData = async (options: UploadDocumentOptions) => {
  // @ts-ignore
  const { year, payload, countryCode, type: documentType } = options;
  const version = Device.getVersion();
  const { image, pdf, fileType: type }: any = payload;
  const isImage = Boolean(image) && Object.keys(image).length > 0;
  const { uri, name }: any = isImage ? image : pdf;
  const formData = await createFormData({
    year: String(year),
    countryCode,
    type: documentType,
    platform: PLATFORM,
    platformVersion: version,
    upload: {
      uri,
      name,
      type,
    },
  });
  return formData;
};

const handleError = (err: any) => {
  const { response } = err;
  const {
    data: { message },
    status,
  } = response;
  return {
    message,
    status,
  };
};

export const scanPayslip = async (options: ScanPayslipOptions): Promise<any> => {
  const { token, data, onUploadListener } = options;

  try {
    const result = await Documents.scanPayslip({
      baseURL: BASE_URL,
      tokenOrUser: token,
      // @ts-ignore
      data,
      onUploadListener,
    });
    return result;
  } catch (error) {
    throw handleError(error);
  }
};
export const uploadDocument = async (options: UploadDocumentOptions) => {
  const { token, onUploadListener } = options;
  const data: any = await getFormData(options);

  try {
    const result = await Documents.create(BASE_URL, token, data, {
      onUploadProgress: onUploadListener,
    });
    return result;
  } catch (error) {
    throw handleError(error);
  }
};
export const cloneDocument = (options: CloneDocumentOptions) => {
  const { token, data } = options;

  try {
    // @ts-ignore
    return Documents.clone(BASE_URL, token, data);
  } catch (error) {
    throw handleError(error);
  }
};
export const getDocuments = (token: string, options: GetDocumentsRequest) => {
  try {
    return Documents.get(BASE_URL, token, options);
  } catch (error) {
    throw handleError(error);
  }
};

export const downloadDocument = async (document: Document, token: string): Promise<string> => {
  try {
    const response = await Documents.getOne(BASE_URL, token, {
      id: document.id,
      acceptHeader: document.metadata.contentType as any,
    });
    const fileRaw = response.data as ArrayBuffer;
    const fileData = new Uint8Array(fileRaw);
    const fileBase64 = Buffer.from(fileData).toString('base64');
    const fileLocalPath = `${RNFB.fs.dirs.DocumentDir}/tmp_${document.metadata.originalName}`;

    await RNFB.fs.writeFile(fileLocalPath, fileBase64, 'base64');

    return fileLocalPath;
  } catch (error) {
    throw handleError(error);
  }
};
