/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { Container, IconButton, Text, TextLink, icons } from 'src/taxfix-components/src';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { parseMarkdown } from 'src/services/markdown-parser';
import { ScreenName } from 'src/types/screen-name';

import { TextHtml, theme } from '../components/core';
import Overlay from '../components/overlay';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { isWeb } from '../utils/platform';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
  header: {
    backgroundColor: theme.color.lightFill,
    width: '100%',
    justifyContent: 'flex-start',
  },
});

export type DocumentUploadInfoOverlaySection = {
  title?: string;
  icon?: string;
  content: string;
};

type Props = {
  onClose: () => void;
  sections: Array<DocumentUploadInfoOverlaySection>;
  canDelete?: boolean;
};

export const DocumentUploadInfoOverlay = ({ onClose: onCloseComplete }: Props): JSX.Element => {
  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'DocumentUploadInfoOverlay',
    });
  }, []);

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const supportLinkText = getTranslationText('it.overlays.document.upload.guidance.footer.link');
  const handleOnPressSupport = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ITRequiredDocumentsInfoGuidance,
      buttonName: supportLinkText,
    });
    handleOpenChatPopup();
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isWeb ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isWeb ? 'right' : 'bottom'}
    >
      {({ onClose }: any) => (
        <>
          <ScrollView
            style={{ display: 'flex' }}
            contentContainerStyle={{
              paddingLeft: 64,
              paddingRight: 44,
              padding: 30,
              minHeight: isWeb ? '100%' : undefined,
            }}
          >
            <Container
              style={styles.header}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Container position="absolute" marginLeft={-30} top={0}>
                <IconButton path={icons.close} onPress={onClose} variant="grey" />
              </Container>
              <Text
                variant="titleMBold"
                value={getTranslationText(
                  'it.overlays.document.upload.info.reviewed_documents.title',
                )}
              />
            </Container>
            <View onStartShouldSetResponder={() => true}>
              <Container marginBottom={0} marginTop={30} flexDirection={'column'}>
                <TextHtml
                  id={String(
                    parseMarkdown(
                      getTranslationText(
                        'it.overlays.document.upload.info.reviewed_documents.body',
                      ),
                    ),
                  )}
                  fontFamily={theme.fontFamily.book}
                ></TextHtml>
              </Container>
            </View>
            <Container flexDirection="column" justifyContent="flex-end" flexGrow={1}>
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.overlays.document.upload.guidance.footer.title')}
              />
              <TextLink
                variant="titleXSMedium"
                color={'brand.brandGreen'}
                text={supportLinkText}
                onPress={handleOnPressSupport}
              />
            </Container>
          </ScrollView>
        </>
      )}
    </Overlay>
  );
};
