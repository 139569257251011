import { Pressable, useBreakpointValue } from 'native-base';
import React from 'react';

import {
  Button,
  Container,
  Icon,
  InProgressIcon,
  StepsIcon,
  Text,
  icons,
} from 'src/taxfix-components/src';

import { Props, StateIconType } from './types';

const getProgressIcon = (type: StateIconType): React.ReactElement | null => {
  switch (type) {
    case 'success':
      return <Icon path={icons.checkMarkSolid} size="24px" color="brand.brandGreen" />;
    case 'progress':
      return <InProgressIcon size="24px" />;
    case 'warning':
      return <Icon path={icons.finished} size="24px" color="secondaryCategory.pinkFull" />;
    default:
      return null;
  }
};

/**
 * Connect with the different screens of the submission process
 */

export const StatusStepItem: React.FC<Props> = ({
  stepText,
  onPress,
  buttonTitle,
  showButton = false,
  borderInTheBottom = false,
  isIconDisabled = true,
  stateIconType,
  showChevronIcon = true,
  buttonVariant = 'primary',
  stepIcon,
  buttonTestID,
  ...otherProps
}): JSX.Element => {
  const width = useBreakpointValue({ base: '100%', md: '520px' });
  const paddingRight = useBreakpointValue({ base: '24px', md: '32px' });
  const maxWText = useBreakpointValue({ base: '134px', md: '100%' });

  return (
    <Pressable onPress={onPress} disabled={!onPress} {...otherProps}>
      <Container
        borderColor="greytones.graphicsLight"
        borderTopWidth="xs"
        borderBottomWidth={borderInTheBottom ? 'xs' : undefined}
        justifyContent="center"
        height="88px"
        maxW={width}
        p="12px"
        paddingLeft="24px"
        paddingRight={paddingRight}
      >
        <Container flexDirection="row" justifyContent="space-between" alignItems="center">
          <Container flexDirection="row" justifyContent="flex-start" alignItems="center">
            <StepsIcon type={stepIcon} size={16} disabled={isIconDisabled} />
            <Text
              variant="titleXSMedium"
              value={stepText}
              color="greytones.textDefault"
              maxW={maxWText}
              marginLeft="16px"
            />
          </Container>
          <Container flexDirection="row" justifyContent="flex-end">
            {!showButton && getProgressIcon(stateIconType)}
            {showChevronIcon && !showButton && (
              <Icon
                path={icons.chevronRight}
                size="24px"
                color="greytones.graphicsDark"
                marginLeft="16px"
              />
            )}
            {showButton && (
              <Button
                variant={buttonVariant}
                title={buttonTitle}
                width="103px"
                onPress={onPress}
                testID={buttonTestID}
              />
            )}
          </Container>
        </Container>
      </Container>
    </Pressable>
  );
};
