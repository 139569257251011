import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from 'react-native-config';
import { useSelector } from 'react-redux';

import { ZendeskService } from 'src/biz-logic/zendesk/zendesk-service.web';
import { useAppStateContext } from 'src/hooks/useAppStateContext';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';

const ZENDESK_KEY = Config.ZENDESK_WEB_WIDGET_CHANNEL_KEY;

export const zendeskService = new ZendeskService({
  channelKey: ZENDESK_KEY,
  logger: logger,
});

export const ZendeskServiceInitializer = (): React.ReactElement => {
  const [isInitialised, setIsInitialised] = useState(false);
  const userId = useSelector(userAuthSelectors.getUserId) as number;
  const accessToken = useSelector(userAuthSelectors.getAccessToken) as string;
  const zeSnippet = document.getElementById('ze-snippet');

  const { locked } = useAppStateContext();

  useEffect(() => {
    if (zeSnippet) {
      zendeskService.init();
      setIsInitialised(true);
    }
  }, [zeSnippet]);

  useEffect(() => {
    if (isInitialised && !userId) {
      zendeskService.logout();
      return;
    }
    if (isInitialised && !locked && userId && userId !== zendeskService.getLoggedInZendeskUser()) {
      zendeskService.login(Config.API_BASE_URL, accessToken, userId);
    }
  }, [userId, isInitialised, locked, accessToken]);

  const scriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${Config.ZENDESK_WEB_WIDGET_CHANNEL_KEY}`;

  return (
    <Helmet>
      <script id="ze-snippet" src={scriptSrc}></script>
    </Helmet>
  );
};
