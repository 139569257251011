import { useTheme } from 'native-base';
import React from 'react';

import { Image } from '../../general/Image';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { Props, TriangleProps } from './types';

const Triangle: React.FC<TriangleProps> = ({ ...otherProps }): JSX.Element => {
  return (
    <Container
      position="absolute"
      left="-2%"
      width={0}
      height={0}
      backgroundColor="transparent"
      borderStyle="solid"
      borderTopWidth={6}
      borderRightWidth={6}
      borderBottomWidth={6}
      borderLeftWidth={0}
      borderTopColor="transparent"
      borderBottomColor="transparent"
      borderLeftColor="transparent"
      {...otherProps}
    />
  );
};

/**
 * A pop up in order to inform the user about next steps
 */

export const ContextualPopUpWeb: React.FC<Props> = ({
  subtitle,
  title,
  description,
  showPhoto = true,
  isTriangleUp,
  source,
  ...otherProps
}): JSX.Element => {
  const { colors, borderWidths, borderStyles } = useTheme();
  return (
    <Container
      py={3}
      px={2}
      _web={{
        width: 280,
      }}
      borderRadius={15}
      borderTopWidth={borderWidths.l}
      bg={colors.secondaryFeedback.greenLight}
      borderTopColor={colors.brand.brandGreen}
      {...otherProps}
    >
      <Triangle bottom={isTriangleUp ? '76%' : '18%'} borderRightColor={colors.brand.brandGreen} />
      <Triangle
        bottom={isTriangleUp ? '74%' : '16%'}
        borderRightColor={colors.secondaryFeedback.greenLight}
      />
      {showPhoto && (
        <Container
          position="absolute"
          right={0}
          top={-24}
          borderRadius={[5]}
          borderWidth={borderWidths.l}
          borderStyle={borderStyles.solid}
          borderColor={colors.brand.brandGreen}
        >
          <Image path={source} size="64px" borderRadius={[5]} />
        </Container>
      )}

      <Text variant="linkS" color={colors.greytones.textDefault} value={subtitle} mb="8px" />
      <Text variant="titleMMedium" value={title} mb="32px" />
      <Container>
        <Text variant="bodySBook" color={colors.greytones.textLight} value={description} />
      </Container>
    </Container>
  );
};
