import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { LanguageCode } from '../types';
import { LanguageOption, getLanguageOptions } from '../services/language-option';

import { DisplayTitleText, Box, theme } from './core';
import Overlay from './overlay';
import Icon from './core/Icon';
import { RadioOption } from './radio-option';
import InternalButton from './core/Button/_InternalButton';

type Props = {
  titleTranslationKey: string;
  selectedLanguage: LanguageCode;
  selectedCountry: CountryCodes;
  onSelect: (locale: LanguageCode) => any;
  onClose: () => any;
  appearFromTop?: boolean;
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.color.lightFill,
    borderRadius: 13,
    maxWidth: 300,
    alignSelf: 'center',
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.border,
  },
  closeIcon: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  radioContainer: {
    paddingHorizontal: 26,
    paddingVertical: 18,
  },
  radioWithBorder: {
    borderBottomWidth: 1,
    borderColor: theme.color.border,
  },
});

const Alert = ({
  titleTranslationKey,
  selectedLanguage,
  selectedCountry,
  onSelect,
  onClose,
}: Props) => {
  const languageOptions = getLanguageOptions(selectedCountry);
  return (
    <TouchableOpacity onPress={() => {}} activeOpacity={1}>
      <Box style={styles.container}>
        <Box direction="row" left={2} right={2} bottom={2} top={2} style={styles.headerContainer}>
          <DisplayTitleText id={titleTranslationKey} />
          <TouchableOpacity onPress={onClose} style={styles.closeIcon}>
            <Icon name="cross" tintColor={theme.color.darkFill} />
          </TouchableOpacity>
        </Box>
        <Box>
          {languageOptions.map((option: LanguageOption, idx: number) => {
            const withBorder = languageOptions.length - 1 !== idx;
            const appliedStyles = [
              styles.radioContainer,
              withBorder ? styles.radioWithBorder : null,
            ];

            const onButtonPress = () => {
              onSelect(option.value);
              onClose();
            };

            return (
              <InternalButton
                key={idx}
                style={appliedStyles as any}
                onPress={onButtonPress}
                testId={`test.button.alert-overlay.${idx + 1}`}
              >
                <RadioOption
                  expandContent
                  label={option.translationKey}
                  checked={selectedLanguage === option.value}
                  onPress={onButtonPress}
                />
              </InternalButton>
            );
          })}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const LanguageSelectionOverlay = ({
  onClose: onCloseComplete,
  titleTranslationKey,
  selectedLanguage,
  selectedCountry,
  onSelect,
}: Props) => (
  <Overlay
    onCloseComplete={onCloseComplete}
    backgroundColor={theme.color.overlay}
    style={{
      justifyContent: 'center',
    }}
  >
    {({ onClose }: any) => (
      <Alert
        titleTranslationKey={titleTranslationKey}
        selectedLanguage={selectedLanguage}
        selectedCountry={selectedCountry}
        onSelect={onSelect}
        onClose={onClose}
      />
    )}
  </Overlay>
);

export { LanguageSelectionOverlay };
