import React from 'react';

import { testID } from 'src/common/testID';
import { Row } from 'src/components/core/List/Row';
import { ListRowPropsType } from 'src/components/core/List/types';

export const ListRow = ({
  data,
  testId,
  rowStyle,
  ellipsizeMode,
  numberOfLines,
  onItemSelected,
}: ListRowPropsType<any>) => {
  const { onPress, ...rest } = data;

  return (
    <Row
      {...rest}
      rowStyle={rowStyle}
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
      onPress={onItemSelected ? () => onItemSelected(data) : onPress}
      {...testID(testId ? `${testId}.item` : 'test.list.item')}
    />
  );
};
