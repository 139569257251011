import React from 'react';
import { useBreakpointValue } from 'native-base';

import { Text, IdentificationUploadTemplate } from 'src/taxfix-components/src';

import { IdCardUploadedContentAreaProps } from '../types';
import { useItalyIntl } from '../../_hooks';
import { UploadInfoBanner } from '../components';

import { UploadedContentArea } from './uploaded-content-area';

export const UploadWrapper: React.FC<IdCardUploadedContentAreaProps> = ({
  side,
  onFilesSelected,
  onFilesRejected,
}) => {
  const { getTranslationText } = useItalyIntl();
  const descriptionID = useBreakpointValue({
    base: getTranslationText(`identity-upload.id-card.${side}-side-upload.mobile-description`),
    md: getTranslationText(`identity-upload.id-card.${side}-side-upload.desktop-description`),
  });

  return (
    <IdentificationUploadTemplate
      title={getTranslationText(`identity-upload.id-card.${side}-side-upload.title`)}
      description={<Text variant="bodyMBook" color="greytones.textLight" value={descriptionID} />}
      mainContent={
        <UploadedContentArea
          side={side}
          onFilesSelected={onFilesSelected}
          onFilesRejected={onFilesRejected}
        />
      }
      bottomElement={<UploadInfoBanner />}
      paddingBottom="45px"
    />
  );
};
