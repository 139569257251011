import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Container, Text, Image } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { theme, Icon } from '../../components/core';
import Overlay from '../../components/overlay';

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingGif: {
    width: 200,
    marginVertical: -70,
  },
});

type Props = {
  messages?: string[];
  logOverlayShown?: () => void;
  messageInterval?: number;
};

export const LoadingOverlayWithLogo: React.FC<Props> = ({
  messages = [],
  logOverlayShown,
  messageInterval,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const { getTranslationText } = useItalyIntl();

  useEffect(() => {
    logOverlayShown?.();
  }, [logOverlayShown]);

  useEffect(() => {
    if (messages.length > 1 && messageInterval) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, messageInterval);

      return () => clearInterval(interval);
    }
  }, [messages.length, messageInterval]);

  return (
    <Overlay
      backgroundColor={theme.color.overlay}
      style={{
        justifyContent: 'center',
      }}
      appearFromTop
      disableBackgroundTouch
    >
      {() => (
        <View style={styles.mainContainer}>
          <Container
            bg="greytones.white"
            borderRadius="12px"
            width={['300px', '580px']}
            height={['400px', '580px']}
            paddingY={0}
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            <Image name="logo" width="115px" height="32px" />

            <Container>
              <Icon name="fancyLoading" style={styles.loadingGif} />
            </Container>
            {messages.length > 0 && (
              <Text
                value={getTranslationText(messages[currentMessageIndex])}
                variant={isSmallScreen ? 'titleSBold' : 'titleMBold'}
                color="brand.brandGreen"
                textAlign="center"
                maxW={isSmallScreen ? '265px' : '295px'}
              />
            )}
          </Container>
        </View>
      )}
    </Overlay>
  );
};
