import { DebugMenuOptions, MenuOption } from './types';

export function buildDebugCWBIUrlOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handleDebugCWBIUrl = () => {
    navigationActions.toDebugCWBIUrl('screen');
  };

  return {
    text: 'Debug CWBI URL',
    onPress: handleDebugCWBIUrl,
    showArrow: true,
  };
}
