import * as React from 'react';
import Config from 'react-native-config';
import { StackNavigationOptions } from '@react-navigation/stack';

import { Document } from 'src/types/documents';
import { IconButton } from 'src/components/core';

import navigate, { Action, Navigation, Tracking, NavigationParams } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { RequiredDocumentsPreviewContainer } from '../../../screens/containers/required-documents/required-documents-preview';
import HeaderButton from '../../../containers/header-button';
import CloseButton from '../../../containers/close-button';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

type ActionParams = {
  document?: Document;
  id: number;
  onNativeShare?(): void;
};

type Props = Navigation<
  'RequiredDocumentsPreview',
  {
    RequiredDocumentsPreview: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate(
  'RequiredDocumentsPreview',
  (state: Record<string, any>, routeParams: Record<string, any>): Tracking => ({
    name: AnalyticsEvent.docUploadInitiated,
    params: {
      documentType: routeParams.prefillDocumentType,
      photoType: routeParams.photoType,
      docSubType: routeParams.prefillDocumentSubType,
      uploadType: routeParams.uploadType,
    },
  }),
);

export const RequiredDocumentsPreviewRoute = ({ route }: Props): JSX.Element => {
  const { document, id, onNativeShare } = route.params;

  const HeaderLeft = () => (
    <HeaderButton left>
      <CloseButton />
    </HeaderButton>
  );
  const HeaderRight = () => (
    <HeaderButton right>
      <IconButton onPress={() => onNativeShare && onNativeShare()} iconKey="icons.icon-group-5" />
    </HeaderButton>
  );

  let overrideOptions: StackNavigationOptions = {
    headerBackTitle: undefined,
    headerLeft: HeaderLeft,
  };

  if (onNativeShare) {
    overrideOptions = { ...overrideOptions, headerRight: HeaderRight };
  }

  const headerTitle = document?.fileName ?? 'it.digital-caf.required-documents-preview.nav.title';

  useDefaultNavigationOptions(
    { id: headerTitle },
    {
      ...overrideOptions,
    },
  );

  return (
    <RequiredDocumentsPreviewContainer
      document={
        document ?? {
          uri: `${Config.API_BASE_URL}/api/documents/${id}/thumbnail`,
          contentType: 'image/jpeg',
        }
      }
    />
  );
};
