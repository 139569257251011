import { IBoxProps, IIconProps } from 'native-base';

import { icons } from '../../../Icons';

export enum ToastBannerLevel {
  Reminder = 'Reminder',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}
export interface OwnProps {
  text: string;
  linkText?: string;
  iconName: keyof typeof icons;
  iconSize?: IIconProps['size'];
  iconColor?: string;
  contentColor?: string;
  onLinkPress?: () => void;
  level?: ToastBannerLevel;
  isHtmlContent?: boolean;
}

export type Props = OwnProps & IBoxProps;
