import Device, { getVersion } from 'react-native-device-info';
import Config from 'react-native-config';

import { showAlert } from '../../../../services/alert';

import { MenuOption } from './types';

export function buildBetaBuildInfoOption(): MenuOption {
  const handleShowBetaBuildInfo = async () => {
    const info = `APP VERSION: ${getVersion()}\nBUILD NO: ${await Device.getBuildNumber()}\nAPI URL: ${
      Config.API_BASE_URL
    }`;
    showAlert({
      titleText: 'Beta build info',
      subtitleText: info,
    });
  };

  return {
    text: 'Show Beta Build Info',
    onPress: handleShowBetaBuildInfo,
    showArrow: true,
  };
}
