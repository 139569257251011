import * as React from 'react';
import debounce from 'lodash/debounce';

import { Suggestion } from '../types/geo';

type Props = {
  searchTerm: string;
  fetchSuggestions: (searchTerm: string) => Promise<Suggestion[]>;
  render: (suggestions: Suggestion[], error?: string) => React.ReactNode;
  isPlaceSelected?: boolean;
};
type State = {
  suggestionsResult: Suggestion[];
  errorCode?: string;
};
const emtpySuggestions: Suggestion[] = [];
const DEBOUNCE_TIME = 800;

class PlacesAutocomplete extends React.Component<Props, State> {
  state = {
    suggestionsResult: emtpySuggestions,
    errorCode: undefined,
  };

  searchTimer: number | null = null;

  search = debounce(async () => {
    const { searchTerm, isPlaceSelected } = this.props;

    if (isPlaceSelected) {
      return;
    }

    try {
      const suggestionsResult: Suggestion[] = await this.props.fetchSuggestions(searchTerm);
      this.setState({
        errorCode: undefined,
        suggestionsResult,
      });
    } catch (error) {
      this.setState({
        errorCode: error.message,
        suggestionsResult: [],
      });
    }
  }, DEBOUNCE_TIME);

  componentDidMount() {
    if (this.props.searchTerm.length >= 3) {
      this.search();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { searchTerm } = this.props;

    if (searchTerm !== prevProps.searchTerm) {
      if (searchTerm.length < 3 && prevState.errorCode) {
        this.resetErrorCode();
      } else {
        this.search();
      }
    }
  }

  resetErrorCode = () =>
    this.setState({
      errorCode: undefined,
      suggestionsResult: emtpySuggestions,
    });

  render() {
    return this.props.render(this.state.suggestionsResult, this.state.errorCode);
  }
}

export default PlacesAutocomplete;
