import { CountryCodes } from '@taxfix/types';

import { supportedLanguagesByCountry } from '../services/country-and-year';
import { NativeModules } from '../biz-logic/nativeModules';
import { LanguageCode } from '../types';
import { appLanguageFor } from '../i18n';

import base from './base.json';

export type AppMessages = typeof base;

export const appMessages = (selectedLanguage: string): AppMessages => {
  switch (selectedLanguage) {
    case 'en': {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const en = require('./en.json');
      return { ...base, ...en };
    }

    case 'it': {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const it = require('./it.json');
      return { ...base, ...it };
    }

    default:
      return { ...base };
  }
};

export const supportedLanguages = (
  countryCode: CountryCodes,
  filterOut: LanguageCode[] = [],
): LanguageCode[] => {
  return supportedLanguagesByCountry[countryCode].filter(
    (language: LanguageCode) => !filterOut.includes(language),
  );
};

export const getDeviceLocale = (): string => {
  const {
    LocaleInfo: { deviceLocale: locale },
  } = NativeModules;
  return locale;
};

export const getAppLanguage = (
  countryCode: CountryCodes,
  filterOut: LanguageCode[] = [],
): LanguageCode => {
  const {
    LocaleInfo: { preferredLanguages },
  } = NativeModules;
  return appLanguageFor(supportedLanguages(countryCode, filterOut), preferredLanguages);
};
