import * as React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import Camera, { CameraImageResult } from './camera';

export type ActionParams = {
  onCapture: (image: CameraImageResult) => void;
  onBarCodeRead?: (code: string) => void;
  onCancel: () => void;
  overlay?: React.ReactNode;
  asBase64?: boolean;
  onTryAgain?: () => void;
};

type Props = Navigation<
  Routes.Camera,
  {
    [Routes.Camera]: NavigationParams<ActionParams>;
  }
>;

const CameraScreen: React.FC<Props> = ({ route }) => {
  const { onCapture, onCancel, overlay, asBase64, onBarCodeRead, onTryAgain } = route.params;

  useNavigationOptions({
    header: () => null,
  });

  return (
    <Camera
      onCapture={onCapture}
      onCancel={onCancel}
      overlay={overlay}
      onBarCodeRead={onBarCodeRead}
      asBase64={asBase64}
      onTryAgain={onTryAgain}
    />
  );
};

export const { action: toCamera, screen: cameraScreen } = getRoute<ActionParams>({
  routeName: Routes.Camera,
  screenComponent: CameraScreen,
});

export default CameraScreen;
