import React from 'react';
import { Keyboard } from 'react-native';
import { KeyboardType } from 'react-native/Libraries/Components/TextInput/TextInput';
import { PlausibilityErrors, TreeNode } from '@taxfix/quizmaster/dist/types';

import { InputValidator } from 'src/components/answers/shared/validators/lengthValidator';
import { trimValue } from 'src/services/utils';
import { Validation } from 'src/components/answers/shared/validators/types';
import { UniversalConfirmableProps } from 'src/components/answers/types';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import AnswerForm from '../components/AnswerForm';

type Props = UniversalConfirmableProps & {
  onPressInfo?: (warning: PlausibilityErrors) => void;
  emptyValue?: any;
  required?: boolean | null | undefined;
  alignment?: 'vertical' | 'horizontal';
  InputComponent: any;
  min?: number;
  max?: number;
  validator?: InputValidator;
  warnings?: PlausibilityErrors[];
  color?: string;
  autoFocus?: boolean;
  onRefInput?: (ref: any) => void;
  precision?: 0 | 1 | 2;
  formatter?: any;
  keyboardType?: KeyboardType;
  height?: any;
  inputStyles?: any;
  editing?: boolean;
  question: TreeNode;
};

type Value = any;

type State = {
  errors: Validation[];
};

const ConfirmableInput: React.VFC<Props> = (props) => {
  const defaultProps = {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  };

  const initialState = {
    errors: [],
  };

  const [state, setState] = React.useState<State>(initialState);
  const { getTranslationText } = useItalyIntl();

  const validate = (value: Value) => {
    const { validator, min, max } = props;
    const safeMin = min === null ? defaultProps.min : min;
    const safeMax = max === null ? defaultProps.max : max;
    let errors = [];

    if (typeof validator === 'function') {
      errors = validator(
        {
          min: safeMin,
          max: safeMax,
        },
        value,
      );
    }

    return errors;
  };

  const handleChange = (value: Value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleSubmit = (value: Value) => {
    const { question } = props;
    const trimmedValue = trimValue(value);
    const errors = validate(trimmedValue);

    if (errors.length === 0) {
      Keyboard.dismiss();
      props.onAnswer(question, trimmedValue);
    } else {
      setState({
        errors,
      });
    }
  };

  const errorMessage = React.useMemo(() => {
    const [error] = state.errors;
    if (!error) return undefined;
    const { translationKey, limit, chars } = error;

    return getTranslationText(translationKey, { number: limit, chars });
  }, [getTranslationText, state.errors]);

  const {
    question,
    emptyValue,
    InputComponent,
    alignment = 'horizontal',
    value,
    autoFocus,
    onRefInput,
    precision = undefined,
    formatter,
    keyboardType,
  } = props;

  const inputComponentProps = {
    question,
    emptyValue,
    InputComponent,
    alignment,
    value,
    precision,
    keyboardType,
    onAnswer: null,
    formatter,
  };

  const { questionId } = question;
  const fieldLabel = getTranslationText(`it.answers.${questionId}.label`);
  const buttonLabel = getTranslationText(emptyValue ? 'answers.leave-empty' : 'answers.next');
  const form = { label: buttonLabel, onConfirm: () => handleSubmit(emptyValue ?? value) };

  return (
    <AnswerForm {...form}>
      <InputComponent
        onRefInput={onRefInput}
        autoFocus={autoFocus}
        label={fieldLabel}
        {...inputComponentProps}
        value={value}
        onChange={handleChange}
        testId="answers.input"
        isInvalid={!!errorMessage}
        errorMessage={errorMessage}
      />
    </AnswerForm>
  );
};

export default ConfirmableInput;
