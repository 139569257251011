import { CountryCodes } from '@taxfix/types';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { loadHotjar } from 'src/_italy/third-party-trackers';

import { selectors as settingsSelectors } from '../stores/modules/settings';

export const HotjarLauncher = (): null => {
  const countryCode = useSelector(settingsSelectors.selectedCountry);
  React.useEffect(() => {
    if (countryCode === CountryCodes.IT) {
      loadHotjar();
    }
  }, [countryCode]);

  return null;
};
