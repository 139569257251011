import React from 'react';
import { StyleSheet, View } from 'react-native';

import { theme, BodyTitleText } from '../core';
import { StyleProp } from '../types';

const styles = StyleSheet.create({
  container: {},
  text: {
    color: theme.color.errorTextDark,
  },
});
// 'EmailAddressInUse' | 'EmailAddressInvalid' are legacy;
export type ErrorKey =
  | 'email_invalid_or_in_use'
  | 'email_invalid'
  | 'EmailAddressInUse'
  | 'EmailAddressInvalid'
  | 'ConnectionFailed'
  | 'OtcValidationFailed'
  | 'OtcResendFailed';
type Props = {
  errorKey: ErrorKey | any;
  style?: StyleProp;
};
export const ErrorKeys = {
  EmailAddressInUse: 'email_invalid_or_in_use',
  EmailAddressInUseLegacy: 'EmailAddressInUse',
  EmailAddressInvalid: 'email_invalid',
  EmailAddressInvalidLegacy: 'EmailAddressInvalid',
  ConnectionFailed: 'ConnectionFailed',
  OtcValidationFailed: 'OtcValidationFailed',
  OtcResendFailed: 'OtcResendFailed',
};

export const translationKeyForError = (errorKey: ErrorKey) => {
  switch (errorKey) {
    case ErrorKeys.EmailAddressInUse:
    case ErrorKeys.EmailAddressInUseLegacy:
      return 'account.error.email-address-in-use';

    case ErrorKeys.EmailAddressInvalid:
    case ErrorKeys.EmailAddressInvalidLegacy:
      return 'account.error.email-address-invalid';

    case ErrorKeys.ConnectionFailed:
      return 'account.error.connection-failed.lead';

    case ErrorKeys.OtcValidationFailed:
      return 'account.otc-verification.failed';

    case ErrorKeys.OtcResendFailed:
      return 'account.otc-verification.resend-failed';

    default:
      return errorKey;
  }
};

const ErrorMessage = ({ errorKey, style }: Props) => (
  <View style={styles.container}>
    <BodyTitleText id={translationKeyForError(errorKey)} style={[styles.text, style]} />
  </View>
);

export default ErrorMessage;
