import * as React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  SectionList,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { IntlShape } from 'react-intl';
import { debounce } from 'lodash';

import { BodyPrimaryText, Box, Button, TextInput, theme } from '../../components/core';

type State = {
  exporting: boolean;
  error: null | string;
  exportFilePath: null | string;
};
export type Data = {
  id: string;
  answer: string;
  syncMeta: string;
  question: string;
  index: number;
};

export type Section = {
  year: string;
  show: boolean;
  reverse: boolean;
  data: Data[];
  hasAnswers: boolean;
};

const styles = StyleSheet.create({
  textInputContainer: {
    margin: 4,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.color.greyFillIcon,
    backgroundColor: 'white',
  },
  textInput: {
    paddingHorizontal: 8,
    marginVertical: 8,
  },
});

type Props = {
  onRemoveResponse: (year: string, id: string) => void;
  onRemoveAllResponses: (yearToDel?: string) => void;
  onExportAllResponses: (yearToExport?: string) => Promise<string>;
  onImport: () => void;
  toggleSection: (year: string) => void;
  reverseSection: (year: string) => void;
  handleAnswerPressed: (string: string) => void;
  handleSearchTermChange: (year: string, searchTerm: string) => void;
  sections: Section[];
  isLoading: boolean;
  intl: IntlShape;
};

class DebugResponses extends React.Component<Props, State> {
  state = {
    exporting: false,
    error: null,
    exportFilePath: null,
  };

  handleExport = async () => {
    const { onExportAllResponses } = this.props;
    this.setState(
      {
        exporting: true,
        error: null,
      },
      async () => {
        try {
          const exportFilePath = await onExportAllResponses();
          this.setState({
            exporting: false,
            exportFilePath,
          });
        } catch (error: any) {
          this.setState({
            exporting: false,
            error: error.toString(),
          });
        }
      },
    );
  };

  renderItem = ({ item, section }: { item: Data; section: Section }) => {
    if (!section.show) return null;
    const { onRemoveResponse } = this.props;
    const { year } = section;
    const { id, question, answer, syncMeta } = item;

    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 7,
          borderBottomWidth: 1,
          borderBottomColor: '#ddd',
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this.props.handleAnswerPressed(JSON.stringify({ id, question, answer }));
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text
              style={{
                color: 'gray',
              }}
            >
              {id}
            </Text>
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={{
                color: question ? 'black' : 'purple',
              }}
            >
              {question || '(null question)'}
            </Text>
            <Text
              style={{
                fontWeight: 'bold',
                color: answer ? 'black' : 'red',
              }}
            >
              {answer || '(null answer)'}
            </Text>
            <Text
              style={{
                color: syncMeta ? 'gray' : 'red',
                fontSize: 8,
              }}
            >
              {syncMeta || '(null syncMeta)'}
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            alignSelf: 'center',
            width: 60,
          }}
          onPress={() => {
            onRemoveResponse(year, id);
          }}
        >
          <Text
            style={{
              marginHorizontal: 15,
              color: 'red',
            }}
          >
            Del
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const { onRemoveAllResponses, onImport, isLoading, sections, toggleSection, reverseSection } =
      this.props;
    const { exporting, error, exportFilePath } = this.state;
    return (
      <Box flex={1}>
        {!isLoading && (
          <Box flex={1}>
            <SectionList
              style={{
                flex: 1,
              }}
              renderSectionHeader={({ section: { year, show, reverse, hasAnswers } }) => (
                <Box>
                  <Box
                    style={{
                      width: '100%',
                      height: 30,
                      backgroundColor: '#ddd',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 20,
                        left: 15,
                        alignSelf: 'center',
                      }}
                    >
                      {year}
                    </Text>
                    <TouchableOpacity
                      style={{
                        alignSelf: 'center',
                      }}
                      onPress={() => toggleSection(year)}
                    >
                      <Text
                        style={{
                          marginHorizontal: 15,
                          color: 'red',
                        }}
                      >
                        {show ? 'Hide' : 'Show'}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        alignSelf: 'center',
                      }}
                      onPress={() => reverseSection(year)}
                    >
                      <Text
                        style={{
                          marginHorizontal: 15,
                          color: 'red',
                        }}
                      >
                        {reverse ? 'Unreverse' : 'Reverse'}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        alignSelf: 'center',
                      }}
                      onPress={() => {
                        onRemoveAllResponses(year);
                      }}
                    >
                      <Text
                        style={{
                          marginHorizontal: 15,
                          color: 'red',
                        }}
                      >
                        Del Year
                      </Text>
                    </TouchableOpacity>
                  </Box>
                  {show && hasAnswers && (
                    <Box style={styles.textInputContainer}>
                      <TextInput
                        style={styles.textInput}
                        onChangeText={debounce(
                          (value: string) => this.props.handleSearchTermChange(year, value),
                          250,
                        )}
                        placeholder="Enter id, summary or answer"
                        autoCapitalize="none"
                      />
                    </Box>
                  )}
                </Box>
              )}
              renderSectionFooter={({ section: { hasAnswers, show, data, year } }) => {
                if (!show) return null;

                let footerText = '';
                if (!hasAnswers) footerText = `No answers for ${year}`;
                if (hasAnswers && !data.length) footerText = 'No answer for this filter';
                if (footerText) {
                  return (
                    <BodyPrimaryText
                      style={{
                        alignSelf: 'center',
                        marginVertical: 10,
                      }}
                    >
                      {footerText}
                    </BodyPrimaryText>
                  );
                }
                return null;
              }}
              renderItem={this.renderItem}
              keyExtractor={(item) => item.id}
              sections={sections}
              stickySectionHeadersEnabled
            />
            <Box
              alignVertically="end"
              alignHorizontally="center"
              top={2}
              left={2}
              right={2}
              bottom={2}
            >
              {error !== null && (
                <Box alignVertically="end" alignHorizontally="center" bottom={2}>
                  <Text>{error}</Text>
                </Box>
              )}
              {exportFilePath !== null && (
                <Box alignVertically="end" alignHorizontally="center" bottom={2}>
                  <Text>{exportFilePath}</Text>
                </Box>
              )}
              <Box direction="row" alignVertically="end" alignHorizontally="center">
                <Box top={1} bottom={1} left={1} right={1}>
                  <Button
                    fillColor="red"
                    onPress={() => {
                      onRemoveAllResponses();
                    }}
                    disabled={exporting}
                  >
                    <Text
                      style={{
                        color: 'white',
                      }}
                    >
                      Delete All
                    </Text>
                  </Button>
                </Box>
                <Box top={1} bottom={1} left={1} right={1}>
                  <Button onPress={this.handleExport} loading={exporting}>
                    <Text>Export All</Text>
                  </Button>
                </Box>
                <Box top={1} bottom={1} left={1} right={1}>
                  <Button onPress={onImport} disabled={exporting}>
                    <Text>Import</Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box flex={1} alignVertically="center">
            <ActivityIndicator />
          </Box>
        )}
      </Box>
    );
  }
}

export default DebugResponses;
