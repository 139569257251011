import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpointValue } from 'native-base';
import { Option } from '@taxfix/quizmaster/dist/types';

import { NavButton, Container } from 'src/taxfix-components/src';
import { isWeb } from 'src/utils/platform';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { actions as overlayActions } from '../../stores/modules/overlay';
import { SimpleQFLayout } from '../../components/simple-qf-layout';
import {
  AnswerOption,
  ItalyDigicafProfileOccupationComponent,
} from '../components/italy-digicaf-profile-occupation';
import { OccupationAnswersKeys, wayQuestionIds } from '../../common/constants-it';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';

type Props = {
  quizmasterLight: QuizmasterLight;
  onNext?: () => void;
  progress: number;
  isNewUser: boolean;
};

const ContainerNotConnected: React.FC<Props> = ({
  quizmasterLight,
  onNext,
  progress = 1 / 4,
  isNewUser,
}) => {
  const { properties, answer } = quizmasterLight[wayQuestionIds.occupation];
  const storedAnswer = answer || [];
  const { options = [] } = properties;
  const dispatch = useDispatch();
  const unsupportedOptions = [OccupationAnswersKeys.foreignIncome];

  const mapOptions = ({ id: value, translationKey }: Option): AnswerOption => ({
    value,
    label: `answer::${translationKey}`,
    isSelected: storedAnswer.includes(value),
  });

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const [occupationOptions, setOccupationOptions] = useState<AnswerOption[]>(
    options.map(mapOptions),
  );
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();

  const { navigationActions } = useNavigation();
  const { getTranslationText } = useItalyIntl();

  const handlePressContinue = () => {
    const { saveAnswer } = quizmasterLight[wayQuestionIds.occupation];
    const selectedOptions = occupationOptions
      .filter((option) => option.isSelected)
      .map((option) => option.value);
    saveAnswer(selectedOptions);
    if (onNext) {
      onNext();
    } else {
      navigationActions.toProfilePersonalData('screen', {
        progress: 1 / 2,
        onNext: () => {
          navigationActions.toSignup('screen', {
            progress: 2 / 2,
          });
        },
      });
    }
  };

  const handlePressOption = (pressedIndex: number) => {
    const pressedOption = occupationOptions[pressedIndex];
    const isVatHolder = pressedOption.value === OccupationAnswersKeys.vatHolder;

    if (
      !isVatHolder &&
      occupationOptions.find(
        (option) => option.value === OccupationAnswersKeys.vatHolder && option.isSelected,
      )
    ) {
      // do nothing, when vatHolder is selected we don't allow selecting other options
      return;
    }

    setOccupationOptions((prevOccupationOptions) => {
      // when vatHolder is selected, de-select all others and return early
      if (isVatHolder) {
        return prevOccupationOptions.map((option, index) => {
          return index === pressedIndex
            ? { ...option, isSelected: !option.isSelected }
            : { ...option, isSelected: false };
        });
      }

      return prevOccupationOptions.map((option, index) => {
        return index === pressedIndex ? { ...option, isSelected: !option.isSelected } : option;
      });
    });

    const { value, isSelected } = occupationOptions[pressedIndex];
    const isUnsupportedOption = unsupportedOptions.some((option) => option === value);

    if (isUnsupportedOption && !isSelected) {
      Analytics.log(AnalyticsEvent.buttonPressed, {
        screenName: 'ProfileWay',
        buttonName: value,
      });

      dispatch(
        overlayActions.show('DigicafOccupationUnsupportedOverlay', {
          onClose: () => overlayActions.hide(),
          taxYear: selectedYear,
        }),
      );
    }
  };

  const isAnyOptionSelected: boolean = occupationOptions.some((option) => option.isSelected);
  const isAnyUnsupportedOptionSelected: boolean = occupationOptions.some(
    ({ value, isSelected }) => isSelected && unsupportedOptions.some((option) => option === value),
  );
  const isContinueDisabled: boolean = !isAnyOptionSelected || isAnyUnsupportedOptionSelected;
  const translationValues = {
    taxYear: selectedYear,
  };

  return (
    <>
      {isWeb && !isSmallScreen && !isNewUser && (
        <Container top={0} zIndex={1} position="sticky">
          <NavButton
            text={getTranslationText('it.digital-caf.qf.way.occupation.web.back-button.text')}
            onPress={() => navigationActions.toDashboard('screen')}
          />
        </Container>
      )}
      <SimpleQFLayout
        hideProgressBar={!isNewUser}
        flowProgress={progress}
        headerTranslationKey="it.digital-caf.qf.way.header"
        subheaderTranslationKey="it.digital-caf.qf.way.subheader"
        subheaderTranslationValues={translationValues}
        isContinueDisabled={isContinueDisabled}
        onPressContinue={handlePressContinue}
        continueButtonTranslationKey="it.digital-caf.qf.way.continue-button"
        size="wide"
        centered={!isNewUser}
        isBackButtonHidden={!isNewUser}
      >
        <ItalyDigicafProfileOccupationComponent
          options={occupationOptions}
          onPressOption={handlePressOption}
        />
      </SimpleQFLayout>
    </>
  );
};

export const ItalyDigiCafProfileOccupation = withQuizmasterLight([wayQuestionIds.occupation])(
  ContainerNotConnected as React.FC,
);
