import { IQuestionStore } from '../QuestionStore.types';

import trackYearStatus from './trackYearStatus';
import trackCategoryStatus from './trackCategoryStatus';
import trackTodo from './trackToDo';

export const questionEffects = (store: IQuestionStore) => {
  trackYearStatus(store);
  trackCategoryStatus(store);
  trackTodo(store);
};
