import * as React from 'react';
import { noop } from 'lodash';

export type AccountContextState = {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
};

export const defaultState: AccountContextState = {
  isLoading: false,
  setLoading: noop,
};
export const AccountContext = React.createContext<AccountContextState>(defaultState);

export const withAccountContext = (Component: React.ComponentType<any>) =>
  function Enhanced(props: Record<string, unknown>): JSX.Element {
    return (
      <AccountContext.Consumer>
        {(context) => <Component {...context} {...props} />}
      </AccountContext.Consumer>
    );
  };
export type WithAccountContextType = AccountContextState;
