import NetInfo from '@react-native-community/netinfo';

type NetInfoStateType =
  | 'unknown'
  | 'none'
  | 'cellular'
  | 'wifi'
  | 'bluetooth'
  | 'ethernet'
  | 'wimax'
  | 'vpn'
  | 'other';
type NetInfoCellularGeneration = '2g' | '3g' | '4g';
export type NetInfoState = {
  type: NetInfoStateType;
  isConnected: boolean;
  isInternetReachable: boolean | null | undefined;
  details: {
    cellularGeneration?: NetInfoCellularGeneration | null | undefined;
    isConnectionExpensive: boolean;
  };
};
export type ConnectionInfoAnalyticsParams = {
  deviceHasConnection: boolean;
  connectionType?: NetInfoStateType;
  effectiveConnectionType?: NetInfoCellularGeneration;
};
type NetInfoSubscription = () => void;
let unsubscribe: NetInfoSubscription | null | undefined;
type OnUpdate = (netInfoState: NetInfoState) => void;

const handleConnectionInfoChange = (onUpdate: OnUpdate) => (netInfoState: NetInfoState) => {
  onUpdate(netInfoState);
};

export default class InternetConnectionInfo {
  static addListener(onUpdate: OnUpdate): void {
    InternetConnectionInfo.removeListener();
    unsubscribe = NetInfo.addEventListener(handleConnectionInfoChange(onUpdate) as any);
  }

  static removeListener(): void {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }
  }
}
