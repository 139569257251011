import React, { useCallback } from 'react';
import { compose } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { Box, Button, theme } from '../components/core';
import { UtilProps, withUtil } from '../hocs/with-utils';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import contactSupport from '../utils/contactSupport';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

const mapStateToProps = (state: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
});

const connector = connect(mapStateToProps);

const DISALLOWED_COUNTRY = [CountryCodes.DE];

type Props = {
  util: UtilProps;
  questionId?: string;
} & ConnectedProps<typeof connector>;

const UnConnectedInformationContactSupportButton = withUtil(
  ({ util, questionId, selectedCountry }: Props) => {
    if (DISALLOWED_COUNTRY.includes(selectedCountry)) {
      return null;
    }

    const handlePressContactSupport = useCallback(async () => {
      if (questionId) {
        Analytics.log(AnalyticsEvent.supportButtonClicked, {
          questionId,
          screenName: 'Information',
        });
      }
      contactSupport(util.defaultContactSupportMessages);
    }, [questionId, util]);
    return (
      <Box
        top={2}
        right={2}
        left={2}
        bottom={2}
        style={{
          backgroundColor: theme.color.lightText,
        }}
      >
        <Button onPress={handlePressContactSupport} testId="account.footer.contact-support-button">
          {util.intl.formatMessage({
            id: 'account.footer.contact-support-button',
          })}
        </Button>
      </Box>
    );
  },
);

export const InformationContactSupportButton = compose<any>(connector)(
  UnConnectedInformationContactSupportButton,
);

export default InformationContactSupportButton;
