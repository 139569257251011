import React, { useEffect } from 'react';
import { HeaderBackButton } from '@react-navigation/elements';
import { useBreakpointValue } from 'native-base';

import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useToastBannerToggler } from 'src/components/toast-banner';
import { Banner } from 'src/components/toast';
import { theme } from 'src/components/core';
import { Loading } from 'src/components/loading';

import { useFetch } from './useFetchDocument';
import { SelfSignDocument as SelfSignDocumentComponent } from './self-sign-document-component';

const ERROR_DURATION = 4000;

type ActionParams = {
  submissionId: string;
};

type Props = Navigation<
  ScreenName.SelfSignDocument,
  {
    SelfSignDocument: NavigationParams<ActionParams>;
  }
>;

const toSelfSignDocument: Action = navigate(ScreenName.SelfSignDocument, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.SelfSignDocument,
  },
});

const SelfSignDocument: React.FC<Props> = () => {
  const { isError, setIsError, isLoading, document, onSign } = useFetch();
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { showBanner } = useToastBannerToggler();

  const onBack = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={onBack} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.self-sign-document.header.title' },
    {
      headerLeft: headerLeftComponent,
    },
  );

  useEffect(() => {
    if (isError) {
      showBanner({
        contentView: <Banner titleId="it.self-sign-document.error" />,
        backgroundColor: theme.color.errorBackground,
        duration: ERROR_DURATION,
      });
      setIsError(false);
    }
  }, [isError, setIsError, showBanner]);

  if (isLoading) return <Loading />;

  return (
    <SelfSignDocumentComponent
      pdfUri={document}
      navButton={
        isLargeScreen && {
          text: 'it.navigation.back-to-home',
          onPress: onBack,
        }
      }
      onSign={onSign}
    />
  );
};

export { SelfSignDocument, toSelfSignDocument };
