import Config from 'react-native-config';
import { CountryCodes } from '@taxfix/types';

import { supportedCountries } from './utils';

type Params = {
  selectedCountry: CountryCodes | null | undefined;
  hasSelectedCountry: boolean;
  preferredLanguages: string[];
};
export const isCountrySupported = (country: string | null | undefined) =>
  supportedCountries.includes(country as any);
export const getDefaultCountry = async ({
  selectedCountry,
  hasSelectedCountry,
}: Params): Promise<string> => {
  const defaultCountry = Config.TAX_COUNTRY;

  if (!defaultCountry) {
    throw new Error('No tax country has been defined');
  }

  if (hasSelectedCountry && selectedCountry) {
    return isCountrySupported(selectedCountry) ? selectedCountry : defaultCountry;
  }

  return defaultCountry;
};
