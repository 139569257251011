import React from 'react';

import { IconButton, icons } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * A navigation text and arrow for controlling navigation on Web
 */

export const NavButton: React.FC<Props> = ({ onPress, text, ...otherProps }): JSX.Element => {
  return (
    <IconButton
      flexDirection="row"
      alignSelf="flex-start"
      paddingTop={1}
      paddingLeft={1}
      size={3}
      color="brand.brandGreen"
      path={icons.arrowLeft}
      text={text}
      textVariant="titleXSBold"
      textProps={{ color: 'greytones.textDefault', marginLeft: 1 }}
      onPress={onPress}
      {...otherProps}
    />
  );
};
