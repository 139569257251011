import { State } from 'src/stores/store/initial';
import { StatusConfig } from 'src/stores/modules/status';
import { getAnswer } from 'src/stores-legacy/helpers';
import { ProductBundleValues, flagsQuestionIds } from 'src/common/constants-it';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { getTaxSeasonInfo } from 'src/services/tax-season';

import { statusConfig as statusConfigPreseason } from './config/config-preseason';
import { statusConfig as statusConfigShortFlow } from './config/config-short-flow';
import { statusConfig as statusConfigCompleteResubmissionFlow } from './config/config-status-complete-resubmission-flow';
import { statusConfig as statusConfigPaymentBeforeQF } from './config/config-status-payment-qf-flow';
import { OverrideStatusConfigs } from './config/types';

export const resolveStatusConfig = (
  state: State,
  overrideConfig?: OverrideStatusConfigs,
): StatusConfig => {
  const selectedProductBundle = getAnswer(state, flagsQuestionIds.productBundleSelection);
  const year = settingsSelectors.selectedYear(state) || getDefaultTaxYear();
  const isResubmission = submissionSelectors.isResubmissionByYear(state, year);
  const isPreseason = getTaxSeasonInfo(state).isPreSeason;

  if (isPreseason) {
    return statusConfigPreseason;
  }

  if (selectedProductBundle === ProductBundleValues.instant) {
    return statusConfigShortFlow;
  }

  if (isResubmission && selectedProductBundle !== ProductBundleValues.instant) {
    return statusConfigCompleteResubmissionFlow;
  }

  return statusConfigPaymentBeforeQF;
};
