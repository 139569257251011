import React from 'react';
import { ActivityIndicator, StyleSheet, View, ViewStyle } from 'react-native';

import { Box, theme, Image, BodyPrimaryText } from './core';
import { SpinningImage } from './spinning-image';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface LoadingProps {
  style?: ViewStyle;
  testId?: string;
  loadingColor?: string;
}

export const Loading = ({
  style = {},
  testId = '',
  loadingColor = '#32C850',
}: LoadingProps): JSX.Element => (
  <View style={[styles.container, style]} testID={testId}>
    <ActivityIndicator color={loadingColor} />
  </View>
);
export const FullscreenLoader = (): JSX.Element => (
  <Box
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.color.lightFill,
    }}
  >
    <Loading />
  </Box>
);
type TaxfixLoadingProps = {
  titleId?: string;
  subTitleId?: string;
  textColorId?: string;
};
export const TaxfixLoading = ({
  titleId = 'loading.default',
  subTitleId,
  textColorId = 'primary',
}: TaxfixLoadingProps): JSX.Element => (
  <View style={styles.container}>
    <SpinningImage duration={1000}>
      <Image
        style={{
          tintColor: theme.color.primary,
          width: 30,
          height: 30,
        }}
        name="payslip.taxfix-loading"
      />
    </SpinningImage>
    <Box alignHorizontally="center" top={1}>
      <BodyPrimaryText
        style={{
          color: (theme.color as any)[textColorId],
        }}
        id={titleId}
      />
      {subTitleId && (
        <Box top={0.5}>
          <BodyPrimaryText
            style={{
              color: (theme.color as any)[textColorId],
            }}
            id={subTitleId}
          />
        </Box>
      )}
    </Box>
  </View>
);
