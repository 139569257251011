import * as React from 'react';

import LinkableText from '../auth/LinkableText';

import { QuestionText } from './Text';

type Props = {
  onTerms: (arg0: any) => void;
  translationKey?: string;
  textComponent?: React.ComponentType<any>;
  linkComponent?: React.ComponentType<any>;
  textDecorationLine?: string;
  // All other props
  [x: string]: any;
};

function AcceptTerms({
  onTerms,
  translationKey = 'account.accept-terms.terms',
  textComponent,
  linkComponent,
  textDecorationLine = 'underline',
  ...props
}: Props) {
  return (
    <LinkableText
      translationKey={translationKey}
      onPress={onTerms}
      textComponent={textComponent || QuestionText}
      linkComponent={linkComponent || QuestionText}
      linkStyle={{
        textDecorationLine,
      }}
      testId="test.linkable-text.accept-terms"
      {...props}
    />
  );
}

export default AcceptTerms;
