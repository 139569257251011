import React from 'react';

import { italyTheme } from '../../../theme';
import { Icon } from '../Icon';
import { Text } from '../Text';

import { StyledLabel } from './styles';
import { LabelProps, LabelVariants } from './types';

/**
 * this item represents a caption for an item in a user interface
 */

const getLabelVariant = (variant: LabelVariants) => {
  switch (variant) {
    case 'green':
      return {
        backgroundColor: italyTheme.colors.secondaryFeedback.greenLight,
        color: italyTheme.colors.secondaryFeedback.greenDark,
      };
    case 'orange':
      return {
        backgroundColor: italyTheme.colors.secondaryCategory.orangeHalf,
        color: italyTheme.colors.secondaryCategory.orangeFull,
      };
    case 'red':
      return {
        backgroundColor: italyTheme.colors.secondaryCategory.salmonHalf,
        color: italyTheme.colors.secondaryFeedback.errorRed,
      };
    default:
      return {
        backgroundColor: italyTheme.colors.greytones.background,
        color: italyTheme.colors.greytones.graphicsDark,
      };
  }
};

export const Label = ({
  message,
  textVariant = 'bodySBook',
  iconPath,
  showTextMarginLeft = true,
  ...otherProps
}: LabelProps): JSX.Element => {
  const { backgroundColor, color } = getLabelVariant(otherProps.variant);
  return (
    <StyledLabel backgroundColor={backgroundColor} {...otherProps}>
      {iconPath && <Icon path={iconPath} color={color} size={4} />}
      <Text
        variant={textVariant}
        alignSelf="center"
        color={color}
        value={message}
        marginLeft={showTextMarginLeft ? '5px' : '0px'}
      />
    </StyledLabel>
  );
};
