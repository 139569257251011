import React from 'react';

import { LinkText } from 'src/components/core';

import { Options } from '../../containers/yes-no';

const DEFAULT_OPTIONS_TEXT_IDS = {
  [Options.YES_KEY]: 'answers.yes',
  [Options.NO_KEY]: 'answers.no',
};

type OptionsTextIds = {
  [Options.YES_KEY]: string;
  [Options.NO_KEY]: string;
};

type Props = {
  value: boolean;
  optionsTextIds: OptionsTextIds;
};

export const YesNoSummary = ({
  value,
  optionsTextIds = DEFAULT_OPTIONS_TEXT_IDS,
}: Props): JSX.Element => {
  return (
    <LinkText
      id={value === true ? optionsTextIds.yes : optionsTextIds.no}
      style={{
        textAlign: 'right',
      }}
    />
  );
};
