import { getNavigationActions } from '../../../../routes/config-util';

import { DebugMenuOptions, MenuOption } from './types';

export function buildOnboardingOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handlePressOnboarding = () => {
    navigationActions.reset({
      index: 0,
      actions: [getNavigationActions().toOnboardingSplash('screen')],
    });
  };

  return {
    text: 'Onboarding',
    onPress: handlePressOnboarding,
    showArrow: true,
  };
}
