import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useBreakpointValue, useTheme } from 'native-base';
import HTML from 'react-native-render-html';

import { Container, Text, TextLink, IconButton, icons } from 'src/taxfix-components/src';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { parseMarkdown } from 'src/services/markdown-parser';
import { ScreenName } from 'src/types/screen-name';
import { theme } from 'src/components/core';
import Overlay from 'src/components/overlay';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
    justifyContent: 'space-between',
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
  header: {
    backgroundColor: theme.color.lightFill,
    width: '100%',
    justifyContent: 'flex-start',
  },
});

type Props = {
  onClose: () => void;
  contentKey: string;
  titleKey: string;
};

export const IdentityUploadOverlay = ({
  onClose: onOverlayClose,
  titleKey,
  contentKey,
}: Props): JSX.Element => {
  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'IdentityUploadOverlay',
    });
  }, []);

  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const { colors } = useTheme();
  const supportLinkText = getTranslationText('it.overlays.document.upload.guidance.footer.link');
  const handleOnPressSupport = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.PrefillDocumentUpload,
      buttonName: supportLinkText,
    });
    handleOpenChatPopup();
  };

  const contentFontStyle = {
    fontFamily: 'CircularStd-Book',
    color: colors.greytones.textDefault,
    fontSize: 16,
    lineHeight: 20,
    padding: 0,
  };

  const htmlStyles = {
    h2: {
      fontFamily: 'CircularStd-Bold',
      color: colors.greytones.textDefault,
      marginTop: 20,
      lineHeight: 20,
      fontSize: 16,
    },

    p: {
      ...contentFontStyle,
      marginBottom: 10,
    },
    ul: {
      marginBottom: 3,
      padding: 0,
    },
    li: {
      ...contentFontStyle,
    },
  };

  return (
    <Overlay
      onCloseComplete={onOverlayClose}
      style={isLargeScreen ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isLargeScreen ? 'right' : 'bottom'}
    >
      {({ onClose }: Pick<Props, 'onClose'>) => (
        <ScrollView
          style={{
            display: 'flex',
          }}
          contentContainerStyle={{
            justifyContent: 'space-between',
            alignContent: 'flex-end',
            padding: isLargeScreen ? 30 : 20,
            paddingLeft: isLargeScreen ? 64 : 40,
            paddingRight: isLargeScreen ? 44 : 20,
            minHeight: isLargeScreen ? '100%' : undefined,
          }}
        >
          <Container style={styles.header} flexDirection="column">
            <Container alignSelf="flex-start">
              <Container position="absolute" marginLeft={-30} top={0}>
                <IconButton path={icons.close} onPress={onClose} size="12px" />
              </Container>
              <Text variant="titleMBold" value={getTranslationText(titleKey)} />
            </Container>
            <View onStartShouldSetResponder={() => true}>
              <Container marginBottom={0} marginTop={30} flexDirection="column">
                <HTML
                  html={parseMarkdown(getTranslationText(contentKey))}
                  tagsStyles={htmlStyles}
                />
              </Container>
            </View>
          </Container>
          <Container flexDirection="column">
            <Text
              variant="titleXSMedium"
              value={getTranslationText('it.overlays.document.upload.guidance.footer.title')}
            />
            <TextLink
              variant="titleXSMedium"
              color="brand.brandGreen"
              text={supportLinkText}
              onPress={handleOnPressSupport}
            />
          </Container>
        </ScrollView>
      )}
    </Overlay>
  );
};
