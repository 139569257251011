import { Avatar, Box, HStack, useTheme } from 'native-base';
import React from 'react';

import { CertifiedIconSolid } from '../../general/MultipathIcons/CertifiedIconSolid';

import { Props } from './types';

/**
 * A group of avatars
 */

export const AvatarGroup: React.FC<Props> = ({
  avatarSources,
  avatarProps = {},
  certified = true,
  certifiedIconSize = 5,
}): JSX.Element => {
  const { colors } = useTheme();
  const {
    size = 16,
    marginLeft = '-32px',
    borderWidth = 4,
    borderColor = colors.greytones.white,
  } = avatarProps;

  return (
    <HStack>
      <Box>
        <Avatar.Group size={size}>
          {avatarSources.map((avatarSource, index) => (
            <Avatar
              //@ts-ignore
              source={avatarSource}
              marginLeft={index === 0 ? '0px' : marginLeft}
              borderWidth={borderWidth}
              borderColor={borderColor}
              borderRadius={5}
              zIndex={index}
              key={index.toString()}
            />
          ))}
        </Avatar.Group>
        {certified && (
          <Box
            position="absolute"
            right={`${borderWidth / 2}px`}
            bottom={`${borderWidth / 2}px`}
            zIndex={avatarSources.length}
          >
            <CertifiedIconSolid fillColor={colors.brand.brandGreen} size={certifiedIconSize} />
          </Box>
        )}
      </Box>
    </HStack>
  );
};
