import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import { getAnswer } from 'src/stores-legacy/helpers';
import { flagsQuestionIds, questionFlowCategories } from 'src/common/constants-it';
import { StatusMiddleware, StatusStepRedirect } from 'src/stores/modules/status';

export const checkSPIDConsentSigned: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const SPIDConsentSigned = getAnswer(getState(), flagsQuestionIds.SPIDConsentXMLSigned);
  if (SPIDConsentSigned) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const redirectSPIDConsentSign: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toQuizMaster('screen', {
        root: questionFlowCategories.taxId,
        editing: false,
        backNavigationText: 'it.tax-id-question-flow.come-back-button.text',
        onNext: () => getBoundNavigationsActions().toSPIDConsentSign('screen'),
      }),
    ],
  });
};
