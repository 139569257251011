import { NavigationProp } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';

type RouteKey = string;
type RouteOptions = Record<string, any>;
type RouteNavigationOptions = Record<RouteKey, RouteOptions>;
const routeOptions: RouteNavigationOptions = {};

const getCurrentRouteKey = (navigation: NavigationProp<any>) => {
  const state = navigation.getState();
  const stateKey = state.key;
  const routeKey = state.routes[state.index].key;
  return `${stateKey}#${routeKey}`;
};

const getAllRouteKeys = (navigation: NavigationProp<any>) => {
  const state = navigation.getState();
  const stateKey = state.key;
  return state.routes.map((route: any) => `${stateKey}#${route.key}`);
};

export const persistRouteOptions = (
  navigation: NavigationProp<any>,
  options: StackNavigationOptions,
): RouteKey => {
  const routeKey = getCurrentRouteKey(navigation);
  routeOptions[routeKey] = { ...routeOptions[routeKey], ...options };
  return routeKey;
};
export const deleteObsoleteRouteOptions = (navigation: NavigationProp<any>, routeKey: RouteKey) => {
  let navigationObject = navigation;

  do {
    if (getAllRouteKeys(navigationObject).includes(routeKey)) {
      return; // route is still in the navigation, do not remove it
    }

    navigationObject = navigationObject.getParent();
  } while (navigationObject);

  delete routeOptions[routeKey];
};
export const getCurrentRouteOptions = (navigation: NavigationProp<any>): RouteOptions => {
  const routeKey = getCurrentRouteKey(navigation);
  return routeOptions[routeKey];
};
