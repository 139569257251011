/* global fetch:false */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from 'react-native-config';

import { actions, selectors } from 'src/stores/modules/braze';

import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import Analytics from '../biz-logic/analytics';
import retryPromise from '../utils/retryPromise';

export const BrazeLauncher = () => {
  const localBrazeId = useSelector(selectors.selectBrazeId);
  const userId = useSelector(userAuthSelectors.getUserId);
  const dispatch = useDispatch();
  const fetchBrazeId = useCallback(async (deviceId: string) => {
    const response = await fetch(Config.BRAZE_IDENTIFICATION_API, {
      method: 'POST',
      body: JSON.stringify({
        device_id: deviceId,
      }),
      headers: {
        Authorization: `Bearer ${Config.BRAZE_IDENTIFICATION_TOKEN}`,
        'Content-type': 'application/json',
      },
    });
    const { braze_id: brazeId } = await response.json();
    return brazeId;
  }, []);
  const exchangeDeviceToBrazeId = useCallback(async () => {
    try {
      const deviceId = await Analytics.getBrazeDeviceId();
      const brazeId = await retryPromise(() => fetchBrazeId(deviceId));
      dispatch(actions.setBrazeIdAction(brazeId));
    } catch (e) {
      // TODO: add retry method in the next iteration
    }
  }, [dispatch, fetchBrazeId]);
  useEffect(() => {
    if (localBrazeId) {
      const segmentCustomOptions = {
        context: {
          integrations: {
            Braze: {
              braze_id: localBrazeId,
            },
          },
        },
      };
      if (userId)
        Analytics.identifyRegisteredUserWithoutAccessToken({ userId }, {}, segmentCustomOptions);
      else Analytics.identifyAnonymousUser({}, segmentCustomOptions);
    }
  }, [localBrazeId]);
  useEffect(() => {
    exchangeDeviceToBrazeId();
  }, [exchangeDeviceToBrazeId]);
  return null;
};
