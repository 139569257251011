import { CountryCodes } from '@taxfix/types';

import { MenuItem, MenuConfig, MenuTypes } from 'src/screens/account-menu/types';
import { ChangePinMenu } from 'src/screens/account-menu/menuItems/ChangePin';
import { TermsAndConditionMenu } from 'src/screens/account-menu/menuItems/TermsAndCondition';
import { PrivacyMenu } from 'src/screens/account-menu/menuItems/Privacy';
import { ImprintMenu } from 'src/screens/account-menu/menuItems/Imprint';
import { EditProfileMenu } from 'src/screens/account-menu/menuItems/EditProfile';
import { SubmissionDownloadMenu } from 'src/screens/account-menu/menuItems/SubmissionDownload';
import { CommercialListAMenu } from 'src/screens/account-menu/menuItems/CommercialListA';
import { LogoutMenu } from 'src/screens/account-menu/menuItems/Logout';
import { SupportCenterMenu } from 'src/screens/account-menu/menuItems/SupportCenter';
import { PrivacySettingsItalyMenu } from 'src/_italy/privacy-setting/menu-item';
import { CloseAccountMenuItaly } from 'src/_italy/close-account-setting/menu-item';
import { isWeb } from 'src/utils/platform';

const italyAccountItems: Array<MenuItem> = [
  { menu: EditProfileMenu, visible: true },
  { menu: SubmissionDownloadMenu, visible: true },
  { menu: ChangePinMenu, visible: true },
  { menu: PrivacySettingsItalyMenu, visible: true },
  { menu: LogoutMenu, visible: isWeb },
  { menu: CloseAccountMenuItaly, visible: true },
]
  .filter((mi) => mi.visible)
  .map((mi) => mi.menu);

const ITALY_MENU_CONFIG: Array<MenuConfig> = [
  {
    type: MenuTypes.LIST,
    items: italyAccountItems,
    heading: 'it.account.menu.account-section.title',
  },
  {
    type: MenuTypes.LIST,
    items: [SupportCenterMenu],
    heading: 'it.account.menu.help-section.title',
    hidden: isWeb,
  },
  {
    type: MenuTypes.LIST,
    items: [TermsAndConditionMenu, CommercialListAMenu, PrivacyMenu, ImprintMenu] as MenuItem[],
    heading: 'it.account.menu.help-section.legal',
  },
];

export const getMenuConfigByCountry = (): Array<MenuConfig> => {
  return ITALY_MENU_CONFIG.filter((cfg) => !cfg?.hidden);
};

export const getImprintConfigByCountry = (
  selectedCountry: CountryCodes,
): {
  enabled: boolean;
} => ({
  enabled: selectedCountry !== CountryCodes.IT,
});
