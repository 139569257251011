import { compare } from 'semver';

export const checkShouldForceUpdate = (appVersion: string | null, minimumAppVersion: string) => {
  try {
    const comparisonInt = compare(appVersion, minimumAppVersion);

    if (comparisonInt === -1) {
      return true;
    }

    return false;
  } catch (error) {
    // If something fails, we play safe and return false
    return false;
  }
};
