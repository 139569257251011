import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { Sidebar, MenuItem } from '../../components/sidebar';

type Step = 'occupation' | 'personal-information' | 'create-account';

interface TranslationMap {
  [key: string]: string;
}

type Props = {
  step: Step;
};

const steps = ['occupation', 'personal-information', 'create-account'];
const translationMap: TranslationMap = {
  occupation: 'web.sidebar.italy-way.step.occupation',
  'personal-information': 'web.sidebar.italy-way.step.personal-information',
  'create-account': 'web.sidebar.italy-way.step.create-account',
};

export const ItalyWAYSidebar = (props: Props): JSX.Element => {
  const navigation: any = useNavigation();
  const { step: activeStep } = props;
  return (
    <Sidebar>
      {steps.map((step) => {
        const activeStepIndex = steps.indexOf(activeStep);
        const stepIndex = steps.indexOf(step);
        const isCompleted = activeStepIndex > stepIndex;
        const isActive = activeStep === step;
        const isDisabled = activeStepIndex < stepIndex;
        const handlePress = isCompleted
          ? () => navigation.pop(activeStepIndex - stepIndex)
          : undefined;
        return (
          <MenuItem
            key={step}
            translationId={translationMap[step]}
            iconName={
              isActive
                ? 'sidebar-svg.item-current'
                : isCompleted
                ? 'sidebar-svg.item-completed'
                : undefined
            }
            isActive={isActive}
            isDisabled={isDisabled}
            onPress={handlePress}
          />
        );
      })}
    </Sidebar>
  );
};
