import React, { ComponentType } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { get } from 'lodash';
import Config from 'react-native-config';
import Device from 'react-native-device-info';
import { Payment as PaymentSDK, Voucher } from '@taxfix/payment-sdk';
import { CreateRequest } from '@taxfix/payment-sdk/dist/payment/create';
import { CountryCodes } from '@taxfix/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import {
  withReferralVoucher,
  WithReferralVoucherProps,
} from 'src/screens/hocs/with-referral-voucher';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds, ProductBundleValues } from 'src/common/constants-it';
import { State as RootState } from 'src/stores/store/initial';
import {
  clearPaymentUrlParams,
  StripeRedirectStatus,
} from 'src/screens/payment/payment-route.common';

import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import {
  PaymentConfig,
  selectors as remoteConfigFirebaseSelectors,
} from '../../stores/modules/remote-config-firebase';
import { getPaymentFromPaymentId } from '../../services/submissions';
import { CouponResult, PaymentCustomer, Product } from '../../types/payment';
import formatNumber from '../../i18n/formatNumber';
import docs from '../../../assets/docs';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { getStripePublishableKey } from '../../services/payment';

import {
  ConfirmPaymentCallback,
  CustomerData,
  FormCustomerFields,
  PaymentFormProps,
} from './payment.types';

const TAXFIX_BACKEND_ERROR = 'API::ERROR';

type Props = {
  PaymentForm: ComponentType<PaymentFormProps>;
  intl: IntlShape;
  locale: string;
  product?: Product;
  taxId?: string;
  countryCode: CountryCodes;
  customer?: PaymentCustomer;
  existingCoupon?: CouponResult;
  year: number;
  accessToken: string;
  showChatBubble: boolean;
  nextNavigationAction: (params?: any) => void;
  triggerE2E: boolean;
  showTaxYearEndBanner: boolean;
  formCustomerFields?: FormCustomerFields;
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  disableVouchers?: boolean;
  quizmasterLight: QuizmasterLight;
  paymentConfig: PaymentConfig;
  redirectStatus?: StripeRedirectStatus;
  instantProductVouchers: Record<string, string>;
} & WithNavigation &
  WithReferralVoucherProps;

type State = {
  error: Error | null | undefined;
  couponResult: CouponResult | null | undefined;
  paymentId?: number | any;
  isCTADisabled: boolean;
};

const mapStateToProps = (state: RootState) => {
  const countryCode = settingsSelectors.selectedCountry(state);
  return {
    year: settingsSelectors.selectedYear(state),
    locale: settingsSelectors.selectedLocale(state),
    accessToken: userAuthSelectors.getAccessToken(state),
    paymentConfig: remoteConfigFirebaseSelectors.getItalyProductsPaymentConfig(state),
    instantProductVouchers: remoteConfigFirebaseSelectors.getInstantProductVouchers(state),
    countryCode,
  };
};

const assignAddressState = (customer: PaymentCustomer | null | undefined) => {
  const customerWithAddressState: PaymentCustomer & { addressState?: string } = { ...customer };

  if (customer && customer.state) {
    customerWithAddressState.addressState = customer.state;
    delete customerWithAddressState.state;
  }

  return customerWithAddressState;
};

class PaymentCreditCard extends React.Component<Props, State> {
  state: State = {
    paymentId: undefined,
    error: null,
    couponResult: null,
    isCTADisabled: false,
  };

  componentDidMount = () => {
    const {
      referralVoucherCode,
      existingCoupon,
      product: { paymentId } = {},
      redirectStatus,
    } = this.props;
    if (existingCoupon)
      this.setState({
        couponResult: existingCoupon,
      });
    else if (referralVoucherCode) {
      this.applyRefferalVoucherIfAvailable();
    }
    if (paymentId)
      this.setState({
        paymentId,
      });
    if (redirectStatus === StripeRedirectStatus.succeeded) {
      clearPaymentUrlParams();
      this.handleFinished(PaymentSDK.PaymentType.PayPal);
    }
    if (redirectStatus === StripeRedirectStatus.failed) {
      this.setState({ error: new Error('Stripe redirect failed') });
      clearPaymentUrlParams();
      this.handleFailed('stripe_payment_failed', PaymentSDK.PaymentType.PayPal);
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!prevProps.triggerE2E && this.props.triggerE2E) {
      this.handleEditCoupon();
    }

    const { referralVoucherCode } = this.props;
    if (!prevState.couponResult && this.state.couponResult && this.props.triggerE2E) {
      this.handleOnSubmit();
    } else if (!prevProps.referralVoucherCode && referralVoucherCode) {
      this.applyRefferalVoucherIfAvailable();
    }
  };

  applyRefferalVoucherIfAvailable = () => {
    const { disableVouchers, referralVoucherCode } = this.props;
    if (!disableVouchers && referralVoucherCode) {
      const codeToApply = this.isInstantProductSelected()
        ? this.props.instantProductVouchers[referralVoucherCode] || ''
        : referralVoucherCode;

      codeToApply && this.handleCouponApply(codeToApply);
    }
  };

  applyDefaultVoucher = async () => {
    const { quizmasterLight, product } = this.props;
    const isInstantProductBundle =
      quizmasterLight[flagsQuestionIds.productBundleSelection]?.answer ===
      ProductBundleValues.instant;
    const { instant, guided } = this.props.paymentConfig.default;

    if (product) {
      const coupon = await Voucher.apply(Config.API_BASE_URL, this.props.accessToken, {
        code: isInstantProductBundle ? instant.voucher : guided.voucher,
        productId: product.id,
        metadata: {
          order: {
            productId: product.id,
          },
        },
      });
      return coupon;
    }
  };

  handleCouponApply = async (referralVoucherCode: string) => {
    this.setState(
      {
        couponResult: undefined,
      },
      async () => {
        try {
          if (this.props.product?.id) {
            const couponResult = await Voucher.apply(Config.API_BASE_URL, this.props.accessToken, {
              code: referralVoucherCode,
              productId: this.props.product.id,
              metadata: {
                order: {
                  productId: this.props.product.id,
                },
              },
            });

            Analytics.log(AnalyticsEvent.validateCouponSuccess, {
              year: this.props.year,
              paymentType: 'direct-debit',
              couponCode: referralVoucherCode,
            });

            if (referralVoucherCode === this.props.referralVoucherCode) {
              Analytics.log(AnalyticsEvent.referralVoucherApplySuccess, {
                code: referralVoucherCode,
              });
            }

            this.setState({
              couponResult,
            });
          }
        } catch (error) {
          const couponInputError = get(error, 'response.data.code');
          const errorMessage = get(error, 'response.data.message');
          Analytics.log(AnalyticsEvent.validateCouponFailed, {
            errorType: couponInputError,
            errorMessage,
            year: this.props.year,
            paymentType: 'direct-debit',
            couponCode: referralVoucherCode,
          });
          const status = get(error, 'response.status');

          if (
            status === 400 &&
            this.props.referralVoucherCode &&
            referralVoucherCode === this.props.referralVoucherCode
          ) {
            this.props.onRemoveReferralVoucher();
            Analytics.log(AnalyticsEvent.referralVoucherApplyError, {
              error: get(error, 'message'),
              code: referralVoucherCode,
            });
          }
        }
      },
    );
  };

  get coupon() {
    const { couponResult } = this.state;
    return couponResult && couponResult.code
      ? {
          code: couponResult.code,
          metadata: {
            order: this.props.product && {
              productId: this.props.product.id,
            },
          },
        }
      : undefined;
  }

  createPayment = async (
    customer: PaymentCustomer | null | undefined,
    paymentMethod: PaymentSDK.PaymentType = PaymentSDK.PaymentType.Card,
  ) => {
    logger.debug('Create new payment');
    const { product, taxId, countryCode, year, accessToken } = this.props;
    const { couponResult } = this.state;
    let defaultVoucher;

    if (!product) {
      throw new Error('cannot proceed payment, no product present');
    }

    if (!couponResult) {
      defaultVoucher = await this.applyDefaultVoucher();
    }

    const voucherCode =
      (couponResult && couponResult.code) || (defaultVoucher && defaultVoucher.code);

    try {
      const { id } = await PaymentSDK.create(Config.API_BASE_URL, accessToken, {
        taxId,
        customer: assignAddressState(customer),
        type: paymentMethod,
        productId: product.id,
        coupon: this.coupon || defaultVoucher,
        countryCode,
      } as CreateRequest);
      Analytics.log(AnalyticsEvent.paymentCreatedClient, {
        year,
        paymentId: id,
        taxCountry: countryCode,
        productId: product.id,
        amount: this.currentProductPrice(),
        currency: product.currency,
        voucherCode,
      });
      this.setState({
        paymentId: id,
      });
    } catch (error) {
      Analytics.log(AnalyticsEvent.paymentCreateErrorClient, {
        year,
        taxCountry: countryCode,
        productId: product.id,
        amount: this.currentProductPrice(),
        currency: product.currency,
        voucherCode,
        errorMessage: (error as Error).message,
      });
      throw error;
    }
  };

  updatePayment = async (customer: PaymentCustomer | null | undefined) => {
    logger.debug('Update existing payment');
    const { product, countryCode, year, accessToken } = this.props;
    const { couponResult, paymentId } = this.state;
    let defaultVoucher;

    if (!couponResult) {
      defaultVoucher = await this.applyDefaultVoucher();
    }

    const voucherCode =
      (couponResult && couponResult.code) || (defaultVoucher && defaultVoucher.code);

    try {
      await PaymentSDK.update(
        Config.API_BASE_URL,
        accessToken,
        paymentId as any,
        {
          customer: assignAddressState(customer),
          coupon: this.coupon || defaultVoucher,
        } as any,
      );
      Analytics.log(AnalyticsEvent.paymentUpdatedClient, {
        paymentId,
        year,
        taxCountry: countryCode,
        productId: product && product.id,
        amount: this.currentProductPrice(),
        currency: product && product.currency,
        voucherCode,
      });
    } catch (error) {
      Analytics.log(AnalyticsEvent.paymentUpdateErrorClient, {
        paymentId,
        year,
        taxCountry: countryCode,
        productId: product && product.id,
        amount: this.currentProductPrice(),
        currency: product && product.currency,
        voucherCode,
        errorMessage: (error as Error).message,
      });
      throw error;
    }
  };

  paymentExists = () => this.state.paymentId != null;

  handleOnSubmit = async (
    confirmPayment?: ConfirmPaymentCallback,
    formCustomerData?: CustomerData,
    paymentMethod: PaymentSDK.PaymentType = PaymentSDK.PaymentType.Card,
  ) => {
    if (this.state.isCTADisabled) return;
    const { nextNavigationAction, accessToken } = this.props;
    const doesPaymentExists = this.paymentExists();
    if (!doesPaymentExists) {
      Analytics.log(AnalyticsEvent.paymentInitiated, {
        paymentType: paymentMethod,
      });
    }

    this.setState(
      {
        isCTADisabled: true,
        error: null,
      },
      async () => {
        try {
          // merge customer data from props with the ones in the payment form (if any)
          let customerData = this.props.customer;

          if (this.props.formCustomerFields && formCustomerData) {
            customerData = {
              ...customerData,
              ...this.props.formCustomerFields.onProcessData(formCustomerData),
            };
          }

          if (doesPaymentExists) await this.updatePayment(customerData);
          else await this.createPayment(customerData, paymentMethod);
          const { paymentId } = this.state;
          if (!paymentId) throw new Error('failed to retrieve payment id');
          const payment = await getPaymentFromPaymentId(accessToken, paymentId);

          if (payment.amountCents === 0) {
            nextNavigationAction({
              paymentId: payment.id,
              paymentAmount: payment.amountCents,
            });
          } else if (confirmPayment && payment.clientSecret) {
            await confirmPayment(payment.clientSecret);
          }
        } catch (err) {
          logger.error(err as Error, {
            message: 'Create or Update payment failed',
          });

          // TODO: This a hack and should be rethinked
          // We should not be calling confirm payment as we failed on creating the payment
          // We need to communicate with the stripe webview, confirmPayment is the only way we have from here to do so
          const isPaypal = paymentMethod === PaymentSDK.PaymentType.PayPal;

          if (isPaypal) {
            confirmPayment && confirmPayment(TAXFIX_BACKEND_ERROR);
          }

          this.setState({
            error: isPaypal ? new Error(TAXFIX_BACKEND_ERROR) : (err as Error),
          });
        } finally {
          this.setState({
            isCTADisabled: false,
          });
        }
      },
    );
  };

  handleFinished = async (paymentMethod: PaymentSDK.PaymentType = PaymentSDK.PaymentType.Card) => {
    const { nextNavigationAction, year, accessToken, product } = this.props;
    const paymentId = this.state.paymentId || product?.paymentId;

    try {
      if (!product) {
        throw new Error('Could not handle finish, because there is no product in the state');
      }

      if (!paymentId) {
        throw new Error('Could not handle finish, because there is no payment in the state');
      }

      try {
        await PaymentSDK.update(Config.API_BASE_URL, accessToken, paymentId, {
          clientFinished: true,
        });
      } catch (error) {
        // Sometimes, if webhook is faster than client and payment is already
        // processed before we indicate client finished, we will have payment_has_been_processed
        // conflict error so we can just continue.
        // This was added to bypass delays in processing payments on the back-end side and avoid
        // blocking users that had already paid.
        if (error?.response?.data?.message !== 'payment_has_been_processed') {
          throw error;
        }
      }

      Analytics.log(AnalyticsEvent.paymentSuccess, {
        productId: product.id,
        paid_amount: this.currentProductPrice(),
        revenue: this.currentProductPrice(),
        currency: product.currency,
        year,
        paymentType: paymentMethod,
      });
    } catch (error) {
      logger.error(error as Error, {
        message: 'Could not update the payment',
      });
      this.setState({
        error: error as Error,
      });
      return;
    }

    nextNavigationAction({
      paymentId,
      paymentAmount: this.currentProductPrice(),
    });
  };

  handleFailed = (
    errorCode: string,
    paymentMethod: PaymentSDK.PaymentType = PaymentSDK.PaymentType.Card,
  ) => {
    Analytics.log(AnalyticsEvent.paymentFailed, {
      paymentType: paymentMethod,
      errorType: 'ValidationError',
      errorMessage: errorCode,
    });
  };

  handleInitializationError = async () => {
    const userAgent = await Device.getUserAgent();
    Analytics.log(AnalyticsEvent.paymentFailed, {
      paymentType: PaymentSDK.PaymentType.Card,
      errorType: 'InitializationError',
      errorMessage: `webview failed to render::${userAgent}`,
    });
  };

  handleShowDetails = (type: string) => {
    const { locale } = this.props;
    let content;
    if (type === 'privacy') content = docs.privacy(locale);
    if (type === 'terms') content = docs.terms(locale);
    if (content == null) return;
    this.props.navigationActions.toInformation('modal', {
      content,
    });
    Analytics.log(AnalyticsEvent.paymentTermsClicked);
  };

  handleEditCoupon = () => {
    const { product } = this.props;

    if (this.coupon === undefined) {
      this.props.navigationActions.toVoucherInput('modal', {
        product,
        onCouponResult: (result: any) => {
          this.setState({
            couponResult: result,
          });
        },
      });
    } else
      this.setState({
        couponResult: null,
      });
  };

  isInstantProductSelected = () =>
    this.props.quizmasterLight[flagsQuestionIds.productBundleSelection].answer ===
    ProductBundleValues.instant;

  currentProductPrice = () => {
    const { product, paymentConfig } = this.props;
    const { couponResult } = this.state;
    if (!product) return null;
    const { amount } = product;

    if (couponResult) {
      // ATM coupon result works only for:
      // - vouchers = 100% discount
      // - vouchers < 100% discount when guided filing selected
      return (amount - couponResult.amountCents) / 100;
    } else if (this.isInstantProductSelected()) {
      return paymentConfig.default.instant.discountPrice;
    } else {
      return paymentConfig.default.guided.discountPrice;
    }
  };

  currentProductPriceCents = (amount: number | null) => {
    if (!amount) return null;
    const twoDecimalAmount = parseFloat(amount.toFixed(2));
    return Math.round(twoDecimalAmount * 100);
  };

  getFormattedOriginalPrice = () => {
    const { paymentConfig, intl, product } = this.props;
    const originalPrice = this.isInstantProductSelected()
      ? paymentConfig.default.instant.fullPrice
      : paymentConfig.default.guided.fullPrice;

    return formatNumber(intl, {
      formatStyle: 'currency',
      useGrouping: true,
      currency: product?.currency || 'EUR',
      value: originalPrice,
    });
  };

  getFormattedPriceToPay = () => {
    const { intl, product } = this.props;
    const price = this.currentProductPrice();
    return product && price !== null
      ? formatNumber(intl, {
          formatStyle: 'currency',
          useGrouping: true,
          currency: product.currency,
          value: price,
        })
      : null;
  };

  getErrorMessageId = () => {
    const { error } = this.state;

    if (error?.message.includes(TAXFIX_BACKEND_ERROR)) {
      return 'payment.paymentError.paypal';
    }

    return 'payment.paymentError';
  };

  render() {
    const {
      intl,
      locale,
      countryCode,
      showTaxYearEndBanner,
      accessToken,
      formCustomerFields,
      PaymentForm,
    } = this.props;
    const { error } = this.state;
    const hasError = error != null;
    const errorMessageId = this.getErrorMessageId();

    return (
      <PaymentForm
        title={this.props.title}
        subtitle={this.props.subtitle}
        onSubmit={this.handleOnSubmit}
        errorMessage={intl.formatMessage({ id: errorMessageId })}
        showError={hasError}
        locale={locale}
        stripePublishableKey={getStripePublishableKey(countryCode)}
        onFinished={this.handleFinished}
        onFailed={this.handleFailed}
        onInitializationError={this.handleInitializationError}
        onShowDetails={this.handleShowDetails}
        showChatBubble={false}
        onEditCoupon={this.handleEditCoupon}
        hasCoupon={Boolean(this.coupon)}
        showTaxYearEndBanner={showTaxYearEndBanner}
        accessToken={accessToken}
        customerFields={formCustomerFields?.fields}
        formattedPrice={this.getFormattedPriceToPay()}
        priceInCents={this.currentProductPriceCents(this.currentProductPrice())}
        formattedOriginalPrice={this.getFormattedOriginalPrice()}
        intl={intl}
        countryCode={countryCode}
      >
        {this.props.children}
      </PaymentForm>
    );
  }
}

export const PaymentCreditCardContainer = compose<any>(
  connect(mapStateToProps, null),
  injectIntl,
  withNavigation,
  withReferralVoucher,
  withQuizmasterLight([flagsQuestionIds.productBundleSelection]),
)(PaymentCreditCard);
