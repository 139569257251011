/*
 * WARNING! if you change this file update the version of the document
 * in the file assets/docs/index.js
 */
export default `<style>
li {
  list-style-type: none;
  margin-left: 7px;
}
</style>
<p>Aggiornato al 27 Settembre 2023</p>
<p><br /></p>
<p>CONTRATTO DI INCARICO PROFESSIONALE</p>
<h1>Con il presente contratto (il Contratto),</h1>
<p>1. il Cliente</p>
<p>NOMINA</p>
<p>
2. Taxfix Italia società tra professionisti a responsabilità limitata (Taxfix
Italia STPRL) stp rappresentata dai dott. Commercialisti Alessandra Birolo e Stephan Grossmann,
con sede in Corso Giacomo Matteotti, 10, 20121 Milano MI C/O Orrick,
Herrington &amp; Sutcliffe (Europe), LLP, Codice fiscale e partita Iva
11571560967 (il Commercialista e, unitamente al Cliente, le Parti) quale suo
consulente fiscale per l’elaborazione e l’invio in via telematica all’Agenzia
delle Entrate della sua dichiarazione dei redditi, ai sensi del
Decreto del Presidente della Repubblica del 22 luglio 1998 n. 322, riferita ai
redditi 2022, come specificato all'articolo 1 qui di seguito.
</p>
<p><br /></p>
<h1>§ 1 I SERVIZI</h1>
<ul>
<li>
  <p>
    1.1 Con il presente Contratto, il Commercialista si impegna a svolgere in
    favore del Cliente i seguenti servizi di assistenza fiscale ordinaria:
  </p>
  <ul>
    <li>
      <p>
        a. elaborare il Modello Redditi Persone Fisiche del Cliente riferito ai redditi 2022,
        sulla base:
      </p>
      <p>
        i. dei dati risultanti dalla dichiarazione dei redditi precompilata
        del Cliente (qualora delega fosse stata conferita dal Cliente al
        Commercialista), disponibile sul sito web Entratel dell'Agenzia delle
        Entrate, come confermati o modificati dal Cliente;
      </p>
      <p>
        ii. dei dati e della documentazione condivisi dal Cliente, tramite le
        piattaforme Taxfix (App o WebApp, di seguito anche “il software
        Taxfix”), come di seguito definita, ivi incluso:
      </p>
      <ul>
        <li>
          la Certificazione Unica;
        </li>
        <li>
          i documenti relativi ad altri redditi riferiti all'anno precedente;
        </li>
        <li>
          la documentazione relativa alle spese deducibili e detraibili sostenute dal
          Cliente.
        </li>
      </ul>
    </li>
    <li>
      <p>
        b. apporre il Visto di Conformità al Modello Redditi, ai sensi del decreto
        legislativo n. 241 del 9 luglio 1997 (il Visto di Conformità), ove richiesto dalla normativa vigente;
      </p>
    </li>
    <li><p>c. inviare il Modello Redditi all’Agenzia delle Entrate;</p></li>
    <li>
      <p>
        d. rilasciare copia del Modello Redditi definitivo con relativi allegati,
        per archivio del Cliente (i Servizi).
      </p>
    </li>
  </ul>
</li>
<li>
  <p>
    1.2 Il presente Contratto non comprende la prestazione di altri servizi a favore del
    Cliente, oltre ai Servizi descritti al precedente articolo 1.1.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 2 – LE APP E WEBAPP TAXFIX</h1>
<ul>
<li>
  <p>
    2.1 Per la prestazione dei Servizi, le Parti faranno uso del
    softwareTaxfix.
  </p>
</li>
<li>
  <p>
    2.2 Il software Taxfix contiene funzioni dedicate al Cliente, che sono
    disponibili attraverso una specifica applicazione, da scaricarsi e
    installarsi sul dispositivo mobile del Cliente (la App Taxfix) oppure
    accessibili tramite desktop (la WebApp Taxfix) e altre separate funzioni
    che sono riservate al Commercialista.
  </p>
</li>
<li>
  <p>
    2.3 Il Cliente dovrà trasmettere al Commercialista tutti i dati ed i
    documenti necessari per l'elaborazione della dichiarazione dei redditi attraverso l'App o
    WebApp Taxfix e il Commercialista dovrà utilizzare il software Taxfix al
    fine di:
  </p>
  <ul>
    <li>
      <p>
        a. visualizzare ed esaminare i dati e i documenti condivisi dal
        Cliente;
      </p>
    </li>
    <li>
      <p>
        b. laddove il Cliente abbia conferito al Commercialista delega
        all’accesso dalla sua dichiarazione dei redditi precompilata, rendere
        disponibili al Cliente, tramite l'App o WebApp Taxfix, i dati
        risultanti, per conferma o modifica dei dati stessi;
      </p>
    </li>
    <li><p>c. elaborare il Modello Redditi del Cliente.</p></li>
  </ul>
</li>
<li>
  <p>
    2.4 La sottoscrizione del Modello Redditi avverrà (ed in particolare dei relativi allegati 730-1 e 730-2) e
    del mandato conferito al Commercialista per accedere alla dichiarazione dei redditi
    precompilata del Cliente (qualora scelto dal Cliente), avverranno mediante l’utilizzo di
    Firma Elettronica Avanzata, in conformità a quanto previsto dal D.lgs n. 82 del 2005 e dal
    DPCM del 22 febbraio 2013, come meglio descritto all’Allegato A al presente Contratto.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 3 – PIATTAFORMA DI COMUNICAZIONE</h1>
<p>
  3.1 Qualsiasi comunicazione tra il Cliente ed il Commercialista in relazione
  all’esecuzione dei Servizi dovrà avvenire attraverso la piattaforma di
  comunicazione digitale Taxfix, accessibile attraverso l'App o la WebApp Taxfix
  per il Cliente e attraverso il software Taxfix per il Commercialista.
</p>
<p><br /></p>
<h1>§ 4 – ESECUZIONE DEI SERVIZI</h1>
<ul>
<li>
  <p>
    4.1 Il Commercialista si impegna a svolgere l'incarico conferito dal
    Cliente nel rispetto delle disposizioni applicabili del Codice Civile e
    delle norme deontologiche emanate dal Consiglio Nazionale dei Dottori
    Commercialisti e degli Esperti Contabili.
  </p>
</li>
<li>
  <p>
    4.2 Il Commercialista può farsi assistere, sotto il suo controllo e la sua
    responsabilità, da altri professionisti qualificati.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 5 – IMPEGNI DEL COMMERCIALISTA</h1>
<ul>
<li>
  <p>
    5.1 Il Commercialista si impegna a mantenere il segreto professionale ai
    sensi della legge applicabile e ad utilizzare i dati e le informazioni
    ricevute dal Cliente ai soli fini dell’esecuzione dei Servizi.
  </p>
</li>
<li>
  <p>
    5.2 Il Commercialista si impegna a garantire che i propri collaboratori e
    dipendenti siano vincolati dagli stessi obblighi di riservatezza nei
    confronti del Cliente.
  </p>
</li>
</ul>

<p><br /></p>
<h1>§ 6 – IMPEGNI DEL CLIENTE</h1>
<ul>
<li>
  <p>6.1 Il Cliente si impegna a:</p>
  <ul>
    <li>
      <p>
        a. condividere sull’App o WebApp Taxfix tutti i dati e la documentazione rilevanti per l’elaborazione
        del Modello Redditi entro e non oltre il 30 Novembre 2023 (il Termine Ultimo), scadenza del termine
        previsto dalla legge applicabile per il deposito del Modello Redditi; in ogni caso, il Commercialista
        non sarà obbligato a prestare i Servizi, nel caso in cui:
      </p>
      <p>
        (i) il Cliente non abbia condiviso alcuna documentazione sull’App o
        WebApp Taxfix entro la scadenza del Termine Ultimo; o
      </p>
      <p>
        (ii) la documentazione condiviso entro tale Termine Ultimo sia incompleta ai
        fini dell’espletamento dei Servizi;
      </p>
    </li>
    <li>
      <p>b. fornire al Commercialista:</p>
      <p>i. informazioni veritiere concernenti la sua identità;</p>
      <p>
        ii. dati, informazioni e documenti veritieri, completi ed esaustivi
        riferiti al suo reddito, alle spese sostenute e, in generale, alla sua
        posizione fiscale;
      </p>
    </li>
    <li>
      <p>
        c. comunicare tempestivamente al Commercialista eventuali modifiche al
        suo indirizzo, numero di telefono, indirizzo e-mail e qualsiasi altra
        modifica pertinente alla sua posizione fiscale;
      </p>
    </li>
    <li>
      <p>
        d. rivedere e confermare il risultato dalla bozza del Modello Redditi
        predisposta dal Commercialista prima dell’invio all’Agenzia delle
        Entrate da parte di quest’ultimo.
      </p>
    </li>
  </ul>
</li>
<li>
  <p>6.2 Il Cliente si impegna inoltre ad agevolare il lavoro del Commercialista fornendo,
  entro 30 giorni dal primo invio di dati e documentazione da parte del
  Cliente stesso (il Termine Specifico), qualsivoglia informazione e documentazione
  complementare richiesta dal Commercialista in quanto necessaria ai fini dell’espletamento
  dei Servizi e dell’invio del Modello Redditi all’Agenzia delle Entrate. Passato il Termine Specifico,
  il Commercialista non sará tenuto a finalizzare l’invio della dichiarazione all’Agenzia delle
  Entrate, né a rimborsare al Cliente il corrispettivo per l’esecuzione dei Servizi, salvo quanto previsto all’articolo 13 del presente Contratto.
</p>
</li>
</ul>

<p><br /></p>
<h1>§ 7 – COMPENSO</h1>
<ul>
<li>
  <p>
    7.1  Le Parti convengono che il corrispettivo dovuto da parte del Cliente al Commercialista possa variare 
    in base alla scelta fatta dal Cliente all’interno del Software Taxfix in merito alla modalità di esecuzione 
    dei Servizi, e nello specifico che il Compenso sia pari a:
    <ul>
      <li> 
        - euro 39,99 IVA inclusa, qualora il Cliente abbia scelto la “dichiarazione istantanea” come modalità per 
        l’elaborazione e l’invio della sua dichiarazione dei redditi;
      </li>
      <li>
        - euro 59,99 IVA inclusa, qualora il Cliente abbia scelto “dichiarazione advanced” come modalità per 
        l’elaborazione e l’invio della sua dichiarazione dei redditi.
      </li>
    </ul>
  </p>
  <p>
    Premesso che il Commercialista si impegna a svolgere i medesimi servizi di assistenza descritti in precedenza 
    (§ 1) in entrambe le modalità di assistenza appena descritte, questa diversa offerta è pensata per offrire al 
    Cliente un servizio adattato alle proprie esigenze. <br> E dunque: 
    <ul>
      <li>
        - la scelta della “dichiarazione istantanea”, consente al Cliente di non dover esibire la documentazione relativa agli oneri indicati nella 
        dichiarazione precompilata forniti da soggetti terzi all’Agenzia delle Entrate. A fronte di questa semplificazione offerta al Cliente, il Commercialista invierà telematicamente 
        il Modello Redditi all’Agenzia delle Entrate indicando il “codice 1” nella sezione “Impegno alla presentazione telematica” all’interno del Frontespizio, e dunque confermando che 
        la dichiarazione è stata predisposta dal contribuente;
      </li>
      <li>
        - la scelta della “dichiarazione advanced” prevede invece per il Cliente l’obbligo di condivisione di tutti 
        i dati e la documentazione rilevanti per l’elaborazione del Modello Redditi e la possibilità di riportare in 
        dichiarazione oneri non indicati nella dichiarazione precompilata condivisa dal Cliente.
      </li>
    </ul>
  </p>
  <p>
    Il Commercialista, nello svolgimento della propria attività professionale, sarà in ogni caso responsabile di 
    informare debitamente il Cliente qualora integrazioni documentali fossero necessarie per la corretta elaborazione della dichiarazione, 
    indipendentemente dalla scelta fatta dal Cliente.
  </p>
  <p>
    Il Commercialista in ogni momento si riserva la possibilità di applicare promozioni e/o scontistiche, che saranno 
    in ogni caso debitamente e chiaramente esposte al Cliente in fase di pagamento del Compenso.
  </p>
</li>
<li>
  <p>
    7.2 Il Compenso dovrà essere corrisposto dal Cliente al Commercialista al
    momento dell’invio al Commercialista, tramite l’App o la WebApp Taxfix,
    dei dati e della documentazione necessaria per l’elaborazione della dichiarazione, 
    in quel momento disponibili al Cliente. E' data la possibilitá al Cliente di concludere
    il pagamento del Compenso anche prima di fornire la documentazione completa,
    che potrá successivamente essere integrata dal Cliente.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 8 – DURATA</h1>
<p>
Il presente Contratto entrerà in vigore dalla data della sua sottoscrizione,
mediante consenso da parte del Cliente tramite App o Webapp, e resterà valido ed efficace sino al completamento
dei Servizi, data nella quale il Contratto cesserà automaticamente i propri
effetti, senza necessità di alcuna ulteriore comunicazione. L’eventuale
rinnovo del presente Contratto dovrà essere oggetto di specifico accordo tra
le Parti.
</p>
<p><br /></p>
<h1>§ 9 – DICHIARAZIONI E GARANZIE DEL COMMERCIALISTA</h1>
<p>Il Commercialista dichiara e garantisce:</p>
  <p>
    a. di essere una Societá Tra Professionisti a responsabilità limitata (STPRL), rappresentata da due dottori Commercialisti
    qualificati, iscritti rispettivamente all'albo dei Dottori Commercialisti e degli Esperti
    Contabili di Torino, al numero 4258, e all'albo dei Dottori Commercialisti e degli Esperti
    Contabili di Bolzano, al numero A/0698, autorizzati dalle competenti autorità fiscali
    all’apposizione dei Visti di Conformità ed i quali, direttamente o tramite propri ausiliari che
    opereranno sotto la loro supervisione e responsabilità, adempiranno le obbligazioni derivanti
    dal Contratto;
  </p>
  <p>
    b. di svolgere i Servizi in buona fede e con la dovuta diligenza
    professionale.
  </p>
<p>
Il Commercialista non presta alcuna ulteriore garanzia, né espressa né
implicita.
</p>
<p><br /></p>
<h1>§ 10 – RESPONSABILITÀ DEL COMMERCIALISTA</h1>
<ul>
<li>
  <p>
    10.1 Il Commercialista non sarà responsabile nei confronti del Cliente per
    eventuali danni, costi, spese, sanzioni o penali derivanti da eventuale
    incompletezza, inesattezza o falsità della documentazione fornita dal
    Cliente e sulla base della quale il Commercialista ha eseguito i Servizi.
  </p>
</li>
<li>
  <p>
    10.2 Il Commercialista non potrà essere ritenuto responsabile nei
    confronti del Cliente per eventuali danni, perdite, costi o spese
    derivanti da o in qualsiasi modo connesse al presente Contratto o ai
    Servizi (i Danni) per un importo complessivo superiore al doppio del
    Compenso pagato ai sensi del presente Contratto dal Cliente al
    Commercialista, salvo quando, e nella misura in cui, sia stato accertato
    in via definitiva dall'autorità competente che il Danno sia dovuto a dolo
    o colpa grave, ai sensi della leggeapplicabile.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 11 - PROTEZIONE DEI DATI PERSONALI</h1>
<ul>
<li>
  <p>
    11.1 Nel presente articolo, per "Disciplina in materia di protezione dei
    Dati personali" s'intendono:
  </p>
  <p>
    (i) il Regolamento dell'UE 2016/679 relativo alla protezione delle persone
    fisiche con riguardo al trattamento dei Dati personali, nonché della
    libera circolazione di tali Dati (“Regolamento”); e
  </p>
  <p>
    (ii) ogni ulteriore legge, atto avente forza di legge e/o regolamento in
    materia di protezione dei Dati personali applicabile.
  </p>
</li>
<li>
  <p>
    11.2 I termini di seguito elencati avranno il significato agli stessi
    attribuito nel presente articolo e nella Disciplina in materia di
    protezione dei Dati personali:
  </p>
  <p>
    a. "Dati personali": qualsiasi informazione riguardante una persona fisica
    identificata o identificabile. I Dati personali che rispettivamente il
    Commercialista e il Cliente trattano sono ricompresi nelle seguenti
    categorie: Dati comuni, Dati particolari (come di seguito definiti);
  </p>
  <p>
    b. "Dati particolari": Dati personali che rivelino l’origine razziale o
    etnica, le opinioni politiche, le convinzioni religiose o filosofiche,
    l’appartenenza sindacale; dati genetici; dati relativi alla salute, alla
    vita sessuale, all'orientamento sessuale;
  </p>
  <p>
    c. "Persona interessata": una persona fisica identificata o
    identificabile. Gli interessati i cui Dati personali sono trattati dal
    Commercialista e dal Cliente sono ricompresi nelle seguenti categorie:
    Cliente/rappresentante del Cliente/soggetti diversi dal Cliente e dal suo
    rappresentante;
  </p>
  <p>
    d. "Titolare del trattamento": il Commercialista e il Cliente che,
    singolarmente o insieme ad altri, determinano le finalità e i mezzi del
    trattamento di Dati personali; quando le finalità e i mezzi di tale
    trattamento sono determinati dal diritto dell’Unione o degli Stati membri,
    il Titolare del trattamento o i criteri specifici applicabili alla sua
    designazione possono essere stabiliti dal diritto dell’Unione o degli
    Stati membri;
  </p>
  <p>
    e. "Responsabile del trattamento": il soggetto che tratta Dati personali
    per conto del Titolare del trattamento;
  </p>
  <p>
    f. "Trattamento": qualsiasi operazione o insieme di operazioni, compiute
    con o senza l’ausilio di processi automatizzati e applicate a Dati
    personali o insiemi di Dati personali, come la raccolta, la registrazione,
    l’organizzazione, la strutturazione, la conservazione, l’adattamento o la
    modifica, l’estrazione, la consultazione, l’uso, la comunicazione mediante
    trasmissione, diffusione o qualsiasi altra forma di messa a disposizione,
    il raffronto o l’interconnessione, la limitazione, la cancellazione o la
    distruzione.
  </p>
</li>
<li>
  <p>
    11.3 Sia il Cliente che il Commercialista saranno da considerarsi Titolari
    del trattamento in relazione ai Dati personali divulgati al Commercialista
    da o per conto del Cliente.
  </p>
</li>
<li>
  <p>
    11.4 Sia il Cliente che il Commercialista adempiranno gli obblighi
    previsti per il Titolare del trattamento ai sensi della Disciplina in
    materia di protezione dei Dati personali in relazione ai Dati personali da
    ciascuno rispettivamente trattati nell'ambito del Contratto e dei Servizi.
  </p>
</li>
<li>
  <p>
    11.5 Il Cliente s'impegna a trasmettere al Commercialista i Dati personali
    lecitamente, secondo quanto previsto dalla Disciplina in materia di
    protezione dei Dati personali, per le finalità descritte nel presente
    articolo. Il Cliente si impegna a manlevare e tenere indenne il
    Commercialista da qualsiasi pretesa avanzata da Persone interessate in
    relazione all'eventuale violazione del presente articolo da parte del
    Cliente.
  </p>
</li>
<li>
  <p>
    11.6 Il Cliente si impegna a mettere in atto misure tecniche e
    organizzative appropriate per garantire un livello di sicurezza adeguato
    al rischio.
  </p>
</li>
<li>
  <p>
    11.7 Il Commercialista tratterà i Dati personali come ragionevolmente
    necessario ai fini della prestazione dei Servizi, degli adempimenti che su
    di esso gravano in virtù di leggi, regolamenti e/o comunicazioni da parte
    di autorità competenti ovvero per altre opportune finalità interne. Il
    Commercialista potrà comunicare i Dati personali a terzi (ivi inclusi
    eventuali collaboratori), ad autorità di regolazione ovvero ad altri
    soggetti, anche con sede in Paesi al di fuori dell’Unione europea, a
    condizione che tale comunicazione:
  </p>
  <p>(i) risulti coerente con le finalità indicate nel presente articolo e</p>
  <p>
    (ii) rispetti la Disciplina in materia di protezione dei Dati personali
    applicabile.
  </p>
</li>
<li>
  <p>
    11.8 Le Parti convengono che, in caso di raccolta presso la Persona
    interessata di Dati personali che la riguardano, il Cliente si impegna a
    fornire alla Persona interessata, nel momento in cui ottiene i Dati
    personali, le informazioni indicate dall’articolo 13 del Regolamento.
  </p>
</li>
</ul>
<p><br /></p>
<h1>§ 12 – CLAUSOLA RISOLUTIVA ESPRESSA</h1>
<ul>
<li>
  <p>
    12.1 Il Commercialista potrà risolvere immediatamente il presente Contratto mediante
    comunicazione scritta al Cliente (anche via email), ai sensi dell'art. 1456 del Codice Civile, in
    caso di violazione da parte del Cliente di una qualsiasi delle obbligazioni previste dagli articoli 6
    (Impegni del Cliente) e 7 (Compenso) del presente Contratto.
  </p>
</li>
<li>
  <p>
    12.2 In caso di risoluzione del presente Contratto causata da violazioni da parte del Cliente, come
    previste dall'articolo 12.1, il Commercialista non sará tenuto a rimborsare al Cliente il Compenso.
  </p>
</li>
</ul>

<p><br /></p>
<h1>§ 13 – DIRITTO DI RECESSO</h1>
<p>
13.1 Ai sensi dell’articolo 52 del Codice del Consumo, il Cliente ha
diritto di recedere dal presente Contratto, dando un preavviso scritto di 14
(quattordici) giorni dalla data di conclusione del contratto al Commercialista.
Per esercitare il diritto di recesso dal contratto di licenza d’uso, 
l'Utente dovrà comunicare al Commercialista la sua intenzione di recedere da tale 
contratto mediante una dichiarazione scritta inviata con lettera raccomandata, 
con PEC o per posta elettronica (e-mail: support@taxfix.it). A propria scelta, 
l'Utente potrà utilizzare il modulo fornito nell'Allegato A delle Condizioni Generali 
di servizio.

Qualora l’Utente decida di esercitare il diritto di recesso fuori dal termine perentorio 
(di 14 giorni) previsto dal Codice del Consumo, il Commercialista potrà procedere ad un 
rimborso parziale del corrispettivo pagato dall’Utente a copertura dei costi sostenuti 
dal Commercialista (o anche dai suoi collaboratori e/o dipendenti) per l’attività professionale 
svolta fino a quel momento. Questo potrà avvenire, ad esempio, ogni qual volta il Commercialista 
abbia posto in essere un’attività di verifica dei dati e della documentazione di supporto 
condivisa dall’Utente sull'App o WebApp Taxfix e abbia già contattato l’Utente per procedere 
all’opportuna integrazione. 
Fuori dai casi di recesso descritti in precedenza, qualora il Commercialista abbia già elaborato e 
condiviso il Modello Redditi con l’Utente per verifica finale precedente all’invio telematico, potrebbe non 
essere previsto un rimborso.
Il rimborso sarà effettuato con lo stesso mezzo di pagamento utilizzato dall’Utente per il pagamento 
originale.
</p>
<p><br /></p>
<h1>§ 14 – ASSICURAZIONE</h1>
<p>
Il Commercialista dichiara di essere assicurato, dalla data odierna e per
l'intera durata del presente Contratto, per la responsabilità civile contro i
rischi professionali, con l’assicurazione Generali Italia S.p.A., polizza
410738304, massimale euro 3.000.000 per sinistro per anno.
</p>
<p><br /></p>
<h1>§ 15 – COMUNICAZIONI</h1>
<p>
Ogni comunicazione o avviso in relazione al presente Contratto, che non possa
avvenire tramite la piattaforma di comunicazione resa disponibile da Taxfix,
dovrà essere fatta per iscritto, anticipata via e-mail, mediante lettera
raccomandata indirizzata come segue: all’attenzione di
</p>
<p>
Taxfix Italia STPRL, Corso Giacomo Matteotti, 10, 20121 Milano MI C/O Orrick,
Herrington &amp; Sutcliffe (Europe), LLP, CF e PI 11571560967, PEC
taxfixitalia@legalmail.it in persona dei legali rappresentanti Alessandra Birolo e Stephan
Grossmann.
</p>
<p><br /></p>
<h1>§ 16 – DISCIPLINA APPLICABILE</h1>
<p>
Per tutto quanto non espressamente previsto nel presente Contratto, le Parti
rinviano a quanto previsto dagli articoli 2229 e seguenti del Codice Civile e
a qualsiasi altra legge e regolamento applicabile.
</p>
<p><br /></p>
<h1>§ 17 – LEGGE APPLICABILE E FORO ESCLUSIVO</h1>
<ul>
<li>
  <p>17.1 Legge applicabile.</p>
  <p>
    La validità, l'interpretazione e l'esecuzione del presente Contratto sono
    regolate dalle leggi della Repubblica Italiana.
  </p>
</li>
<li>
  <p>17.2 Foro esclusivo.</p>
  <p>
    Qualsiasi controversia derivante da o connessa al presente Contratto o
    alla sua esecuzione sarà devoluta alla giurisdizione esclusiva del
    Tribunale di Milano.
  </p>
</li>
</ul>

<p><br /></p>
<p>
Ai sensi dell’art. 1341 del Codice Civile, nonché del combinato disposto degli
articoli 33 e 34 del Codice del Consumo, il Cliente dichiara di accettare
specificamente le seguenti disposizioni del presente Contratto: 10.1 con
riferimento all’esclusione di responsabilità del Commercialista in caso di
danni derivanti da incompletezza, inesattezza o falsità della documentazione
fornita dal Cliente; 10.2 con riferimento alla limitazione di responsabilità
del Commercialista per eventuali danni derivanti o connessi con il presente
Contratto o con i Servizi resi; 12 (Clausola risolutiva espressa); 17.2 (Foro
esclusivo).
</p>
<p><br /></p>
<p><br /></p>
<p>ALLEGATO A</p>
<p><br /></p>
<p>SERVIZIO DI FIRMA ELETTRONICA AVANZATA EROGATO DAL COMMERCIALISTA</p>
<p>NOTA INFORMATIVA</p>
<p><br /></p>
<h1>§ 1 – INFORMAZIONI GENERALI</h1>
<p>
Taxfix utilizza un’innovativa soluzione informatica che consente al Cliente di 
sottoscrivere elettronicamente la documentazione fiscale necessaria per la preparazione da parte del 
Commercialista del modello 730, ovvero, gli annessi 730-1 e 730-2 al modello 730, 
del Modello Redditi e, laddove richiesta, anche per la delega per l’accesso alla dichiarazione precompilata.
La sottoscrizione dei documenti avviene mediante l’utilizzo di Firma
Elettronica Avanzata (di seguito anche solo FEA), in
conformità a quanto previsto dal D.lgs 7 marzo 2005, n. 82 (“Codice
dell’Amministrazione Digitale”). Come noto, la definizione di firma
elettronica avanzata è tecnologicamente neutra e la norma non richiede l’uso
di una determinata tecnologia. Nella prassi, pertanto, esistono diversi tipi
di firme elettroniche avanzate, descrivibili in base ad una molteplicità di
criteri, fra i quali il metodo utilizzato, la finalità e le proprietà.
Pertanto la definizione di Firma Elettronica Avanzata contenuta nel CAD deve
essere, in particolare, integrata dalle disposizioni di cui al DPCM 22
febbraio 2013, contenente le “Regole in materia di generazione, apposizione e
verifica delle firme elettroniche avanzate, qualificate, digitali, ai sensi
degli articoli 20, comma 3, 24, comma 4, 28, comma 3, 32, comma 3, lettera b),
35, comma 2, 36, comma 2 e 71” (di seguito richiamate più semplicemente
“Regole tecniche”), pubblicate nella Gazzetta Ufficiale del 21 maggio 2013,
Serie generale, n. 117. Appare evidente, infatti, come la definizione di Firma
Elettronica Avanzata offerta dal CAD sia indifferente alla tecnologia
utilizzata ma ciò che diventa fondamentale è il soddisfacimento di una serie
di caratteristiche, a prescindere dalle modalità tecniche per garantirle. Le
informazioni riportate di seguito, relative alle caratteristiche del servizio
di FEA ed alle tecnologie su cui questo si basa, sono disponibili sul sito
internet www.taxfix.it.
</p>
<p><br /></p>
<p>Definizioni:</p>
<p>
Il Commercialista è l’erogatore della soluzione di Firma Elettronica Avanzata
(così come da definizione contenuta nel DPCM 22-02-2013 “Regole tecniche in
materia di generazione, apposizione e verifica delle firme elettroniche
avanzate, qualificate e digitali”, all’articolo 55, comma 2, lettera a). Il
Commercialista ha deciso di adottare un processo FEA garantendo quanto
previsto dalla normativa, in particolare:
</p>
<p>a. L’identificazione del firmatario del documento</p>
<p>
Il firmatario (anche "Cliente" di seguito) viene identificato tramite la
verifica dei dati forniti al momento della registrazione all’App o WebApp
Taxfix e dei dati e documenti forniti successivamente all’attivazione del
proprio account Taxfix da parte del Cliente e all’autenticazione all’App o
WebApp Taxfix. Il Cliente, per poter utilizzare il servizio, deve registrarsi
e attivare un account Taxfix utilizzando un indirizzo e-mail e un codice PIN
segreto scelto dal Cliente. L’attivazione dell’account avviene tramite l’invio
di una e-mail automatica da parte di Taxfix con un link di verifica univoco su
cui il Cliente dovrà cliccare. L’autenticazione (o login) avviene tramite
inserimento all’interno dell’App o WebApp Taxfix dell’indirizzo e-mail e del
codice PIN scelto dal Cliente. Dopo avere effettuato l’autenticazione
all’interno dell’App o WebApp Taxfix, il Cliente dovrà fornire una scansione
di un documento d’identità munito di fotografia (passaporto o carta
d’identità) in corso di validità, il proprio nome, cognome e codice fiscale.
L’identificazione avviene tramite la verifica della validità del documento
d’identità e tramite la verifica della corrispondenza di nome, cognome, data
di nascita, luogo di nascita e genere (dati presenti nel codice fiscale
fornito dal Cliente) con i dati presenti nel documento di identità. Solo nei
casi di delega all’accesso della dichiarazione precompilata conferita al
Commercialista, ai Clienti che hanno presentato la dichiarazione dei redditi
nell’anno precedente verrà anche richiesto il caricamento di una scansione
della dichiarazione dei redditi dell’anno precedente.
</p>
<p>b. La connessione univoca della firma al firmatario</p>
<p>
La connessione univoca della firma al firmatario (Cliente) è garantita dalla
necessità per il Cliente di essere autenticato all’interno dell’App o WebApp
Taxfix durante la sottoscrizione dei documenti fiscali tramite FEA. Per
escludere la possibilità di utilizzo dell’account del Cliente da parte di
persone non autorizzate, l’App o WebApp Taxfix richiede l’inserimento del
codice PIN ogni qualvolta l’app viene chiusa dal Cliente o dopo 5 minuti di
inutilizzo dell’app stessa.
</p>
<p>
c. Il controllo esclusivo del firmatario del sistema di generazione della
firma
</p>
<p>
Ogni qualvolta al Cliente viene richiesto di firmare un documento informatico
con FEA, nell’ambito dei rapporti contrattuali con il Commercialista, egli
potrà farlo, sotto il suo esclusivo controllo e volontà, operando e
confermando la sua azione. I dati ed eventi, acquisiti dai sistemi e
dall’infrastruttura informatica su canali sicuri, in conformità con quanto
prescritto dalla normativa vigente in materia, hanno le seguenti
caratteristiche: assicurano l’autenticazione del Cliente, attestano
l’esercizio del controllo esclusivo del Cliente, essendo egli l’unico soggetto
ad aver potuto fornire il codice PIN di autenticazione in virtù del fatto che
il codice PIN è segreto ed è scelto dal Cliente ed univocamente associato al
suo indirizzo e-mail. Essendo le credenziali di accesso nel suo esclusivo
possesso, controllo e disponibilità garantiscono l’esercizio della
manifestazione della volontà del Cliente.
</p>
<p>
d. La possibilità di verificare che il documento informatico sottoscritto non
abbia subito modifiche dopo l’apposizione della firma
</p>
<p>
L'integrità del documento è presidiata dalla procedura di inserimento dei dati
del firmatario all’interno del file XML del Contratto, attraverso la creazione
di una firma conforme allo standard europeo denominato XADES, nonché dalla
successiva apposizione, a sigillo di ogni firma apposta sul documento dal
firmatario, di un certificato rilasciato da IN.TE.S.A. S.p.A.. L’applicazione
prevede, inoltre, l’inserimento del riferimento temporale alla sottoscrizione
del Contratto. Le tecnologie di Firma Elettronica utilizzate includono le
impronte informatiche (hash) del contenuto soggetto a sottoscrizione. Il
controllo della corrispondenza tra un'impronta ricalcolata e quella
"sigillata" all'interno delle firme permette di verificare che il documento
sottoscritto non abbia subito modifiche dopo l'apposizione della firma.
</p>
<p>
e. Possibilità per il firmatario di ottenere evidenza di quanto sottoscritto.
</p>
<p>
Prima di apporre la propria firma il Cliente può visualizzare il contenuto in
tutte le sue parti all’interno dell’App o WebApp Taxfix. Il firmatario può
ricevere copia di quanto sottoscritto tramite richiesta al Commercialista
scrivendo una e-mail a support@taxfix.it.
</p>
<p>
f. L’individuazione del soggetto che eroga soluzioni di Firma Elettronica
Avanzata
</p>
<p>
Il Commercialista è il soggetto erogatore della soluzione di Firma Elettronica
Avanzata.
</p>
<p>
g. Assenza nell'oggetto della sottoscrizione di qualunque elemento idoneo a
modificarne gli atti, i fatti e i dati in esso rappresentati
</p>
<p>
I documenti oggetto di sottoscrizione possiedono un formato atto a garantire
l'assenza, nell'oggetto della sottoscrizione, di qualunque elemento idoneo a
modificare gli atti, i fatti e i dati in essi rappresentati.
</p>
<p>
h. La connessione univoca della firma al documento sottoscritto I dati della
firma vengono inseriti nel documento in una struttura che li unisce
indissolubilmente all’impronta informatica del documento sottoscritto. Questa
struttura è protetta con opportuna tecnica crittografica, al fine di
preservare la firma da ogni possibilità di estrazione o duplicazione. I
documenti che il Cliente sottoscrive con la FEA sono documenti informatici
che:
</p>
<p>
sul piano tecnico soddisfano i requisiti di sicurezza definiti dalla normativa
vigente;
</p>
<p>
sul piano giuridico hanno lo stesso valore dei documenti cartacei sottoscritti
con firma autografa.
</p>
<p><br /></p>
<h1>
§ 2 – MODALITÀ DI FUNZIONAMENTO DEL SERVIZIO DI FIRMA ELETTRONICA AVANZATA
</h1>
<p>Il servizio di FEA adottato dal Commercialista consente al Cliente:</p>
<p>
<span>○</span> di visualizzare e controllare i Dati personali, i dati
contrattuali e tutte le dichiarazioni fornite in formato elettronico
all’interno dell’App o WebApp Taxfix;
</p>
<p>
<span>○</span> procedere con la sottoscrizione utilizzando gli appositi campi
“firma” predisposti;
</p>
<p><span>○</span> una volta completata la firma:</p>
<p>accettare la sottoscrizione, premendo il bottone “Conferma e invia”;</p>
<p>
annullare l’operazione di firma. Al termine delle operazioni di firma il
documento assume caratteristiche tecniche ed informatiche che ne garantiscono
integrità e non modificabilità.
</p>
<p>
I documenti firmati saranno consultabili tramite specifica richiesta al
Commercialista o a Taxfix scrivendo una e-mail a support@taxfix.it.
</p>
<p><br /></p>
<h1>
§ 3 – CONSERVAZIONE E COPIA DELL'ADESIONE AL SERVIZIO (ART. 57, COMMA 1
LETTERA B, DPCM 22 FEBBRAIO 2013)
</h1>
<p>
Copia della presente Nota Informativa e della dichiarazione di adesione al
Servizio medesimo da parte del Cliente sarà conservata dal Commercialista,
secondo quanto previsto dalla vigente normativa, garantendo, durante tutto
l'arco temporale di conservazione, la disponibilità, l'integrità, la
leggibilità e l'autenticità della stessa. Il Commercialista o Taxfix
trasmetterà gratuitamente al Cliente, dietro richiesta da parte di
quest’ultimo tramite e-mail a support@taxfix.it, una copia delle Condizioni
Generali di Utilizzo del Servizio e della dichiarazione di adesione al
Servizio medesimo.
</p>
<p><br /></p>
<h1>§ 4 – INFORMAZIONI RIGUARDANTI LA COPERTURA ASSICURATIVA</h1>
<p>
Il Commercialista ha stipulato, in ottemperanza alle previsioni legislative
contenute nel DPCM 22-02- 2013, art. 57, comma 2, una polizza assicurativa
(polizza 410738304, massimale euro 3.000.000 per sinistro per anno) rilasciata
da primaria assicurazione Generali Italia S.p.A. abilitata ad esercitare nel
campo dei rischi industriali, per la responsabilità civile da danno a terzi
eventualmente derivante dalla fornitura del servizio di FEA.
</p>
<p><br /></p>
`;
