import React from 'react';
import Dropzone from 'react-dropzone';

import { Props } from './types';

/**
 * Dropzone wrapper for components
 * It's a WEB only component since react-dropzone lib works only on WEB
 */
export const DropzoneWrapperWeb: React.FC<Props> = React.forwardRef(
  ({ style = {}, children, ...dropzoneProps }, ref) => {
    return (
      <Dropzone ref={ref} {...dropzoneProps}>
        {({ getRootProps, getInputProps, ...rest }) => {
          return (
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {children({ getRootProps, getInputProps, ...rest })}
            </div>
          );
        }}
      </Dropzone>
    );
  },
);
