import React from 'react';
import { ScrollView } from 'react-native';
import { useBreakpointValue } from 'native-base';

import {
  Container,
  NavButton,
  RadioGroup,
  Text,
  Image,
  Button,
  NBTheme,
} from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { InformationButton } from 'src/containers/information-button';
import { theme } from 'src/components/core';

import { ComponentProps } from './types';

export const IdentificationDocumentUploadComponent: React.FC<ComponentProps> = ({
  backNavigationText,
  infoButton,
  selectedIdType,
  uploadOptions,
  handleUploadOptionSelected,
  buttons,
  onPressBackNavigation,
}) => {
  const { getTranslationText } = useItalyIntl();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const titleVariant = useBreakpointValue({ base: 'titleSMedium', md: 'titleLBold' });
  const descriptionVariant = useBreakpointValue({ base: 'bodyMBook', md: 'subtitleMBook' });

  const buttonProps = selectedIdType ? buttons.buttonContinue : buttons.buttonRemind;

  return (
    <Container flex={1}>
      {!isSmallScreen && onPressBackNavigation && (
        <NavButton text={backNavigationText} onPress={onPressBackNavigation} />
      )}
      <Container
        width="100%"
        maxW="630px"
        flex={1}
        pt={['20px', '46px']}
        alignSelf="center"
        paddingX={['25px', 0]}
      >
        <ScrollView style={{ flex: 1 }}>
          <Container
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            mb={['50px', '41px']}
          >
            <Text variant={titleVariant}>{getTranslationText('identity-upload.web.title')}</Text>
            {infoButton && (
              <InformationButton
                onPress={infoButton.handlePressed}
                color={NBTheme.colors.brand.brandGreen}
                shouldShowTooltip={infoButton.shouldShowTooltip}
                onTooltipOpened={infoButton.handleTooltipOpened}
                onTooltipClosed={infoButton.handleTooltipClosed}
              />
            )}
          </Container>
          <Image
            name="passport"
            w={[212, 331]}
            h={[122, 190]}
            resizeMode="contain"
            alignSelf="center"
          />

          <Text variant={descriptionVariant} color="greytones.textLight" mt={['20px', '42px']}>
            {getTranslationText('identity-upload.id-select.lead')}
          </Text>
          <RadioGroup
            mt={[24, 21]}
            options={uploadOptions}
            onChange={handleUploadOptionSelected}
            hasBorderInTheBottom
          />
        </ScrollView>
      </Container>

      <Container
        //---we decided to use the theme shadow because it solves the shadow for the footer for every platform
        style={theme.styles.dropShadow}
        backgroundColor="greytones.white"
        height={['76px', '80px']}
        paddingX={['20px', '0px']}
        justifyContent="center"
        width="100%"
      >
        {buttonProps ? (
          <Button
            variant={buttonProps.variant}
            title={buttonProps.title}
            testID={buttonProps.testId}
            onPress={buttonProps.onPress}
            maxWidth={isSmallScreen ? '100%' : '210px'}
            marginX={['0px', '12px']}
          />
        ) : null}
      </Container>
    </Container>
  );
};
