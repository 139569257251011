import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { SVGProps } from '../types';

/**
 * StepsIcon ExpertReview with several paths, greyscale
 * Temporary component
 */

export const ExpertReviewIconGrey: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <G fill="none">
      <Path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#F6F6F6"
      />
      <Path
        d="M43.9999 20.8361V30.6312C43.997 38.065 39.1201 44.6175 32 46.7541C24.8761 44.6177 19.998 38.0603 20 30.623V20.8279C21.9809 20.8279 23.5893 19.2269 23.5984 17.2459H40.4016C40.4061 19.2301 42.0158 20.8361 43.9999 20.8361Z"
        fill="#E1E1E1"
      />
      <Path
        d="M38.5162 19.6396H25.4957C24.8891 21.0251 23.7829 22.1313 22.3974 22.7379V30.6313C22.3681 36.7508 26.2243 42.215 31.9998 44.2379C37.7753 42.215 41.6315 36.7508 41.6023 30.6313V22.7379C40.2227 22.1268 39.1218 21.0215 38.5162 19.6396V19.6396Z"
        fill="#F6F6F6"
      />
      <Path
        d="M32.0001 26.0001C34.6506 26.0001 36.7993 28.1488 36.7993 30.7993C36.7993 33.4498 34.6506 35.5985 32.0001 35.5985C29.3496 35.5985 27.2009 33.4498 27.2009 30.7993C27.2032 28.1497 29.3505 26.0024 32.0001 26.0001Z"
        fill="#949494"
      />
      <Path
        d="M30.6229 30.9753C30.4714 30.8238 30.2505 30.7646 30.0435 30.8201C29.8365 30.8755 29.6748 31.0372 29.6193 31.2442C29.5639 31.4513 29.623 31.6721 29.7746 31.8237L30.9754 33.0245C31.0877 33.1374 31.2404 33.2008 31.3996 33.2008C31.5588 33.2008 31.7115 33.1374 31.8238 33.0245L34.8238 30.0245C35.0566 29.7897 35.0566 29.411 34.8238 29.1761C34.712 29.0622 34.5591 28.9981 34.3996 28.9981C34.24 28.9981 34.0872 29.0622 33.9754 29.1761L31.3975 31.7499L30.6229 30.9753Z"
        fill="#F6F6F6"
      />
    </G>
  </Icon>
);
