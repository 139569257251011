import React from 'react';

import SummaryText from './summary-text';

type LinkableProps = {
  linkable?: boolean;
  color?: string;
};

function Empty({ linkable, color }: LinkableProps) {
  return <SummaryText linkable={linkable} color={color} />;
}

export default Empty;
