import incomeTaxCalculator from '@taxfix/income-tax-calculator';
import { getReasonsForPerson } from '@taxfix/de-income-tax-sdk';
import { ResultType } from '@taxfix/submission-voucher';

export { ResultType };

export type MandatoryType = {
  mandatory: boolean;
  reasons: string[];
};

export type RefundReason = {
  amount: string;
  reason: string;
  personIndex: number;
};

export type RefundReasons = RefundReason[];

export type Calculations = incomeTaxCalculator.Calculations | undefined;

type GetReasonForPersonFunc = (personIndex: number) => RefundReason[];

export class BaseTaxCalculator implements TaxCalculator {
  value: number;

  taxLoss: number;

  rawValue: number;

  isTaxLoss: boolean;

  resultType: ResultType;

  mandatory: MandatoryType;

  calculations?: Calculations;

  getReasonsForPerson: GetReasonForPersonFunc;

  getFastlaneReasonsForPerson: GetReasonForPersonFunc;

  protected enhanceRefundReasons?: (personIndex: number, reason: RefundReasons) => RefundReasons;

  constructor() {
    this.value = 0;
    this.taxLoss = 0;
    this.rawValue = 0;
    this.isTaxLoss = false;
    this.resultType = ResultType.Even;
    this.mandatory = {
      mandatory: false,
      reasons: [],
    };
    this.getReasonsForPerson = this.getReasonsForPersonFunc();
    this.getFastlaneReasonsForPerson = () => [];
  }

  get mandatoryReason(): string {
    return this.mandatory.reasons.length ? this.mandatory.reasons[0] : '';
  }

  get refundAmount(): number {
    switch (this.resultType) {
      case ResultType.Shortfall:
        return -this.value;
      case ResultType.Refund:
      case ResultType.Even:
        return this.value;

      default:
        /**
         * Not sure why we do this, but for some reason,
         * we set the result to whatever the tax loss
         * is when there is a tax loss and there is
         * no refund
         */
        return this.isTaxLoss && this.value === 0 ? this.taxLoss : 0;
    }
  }

  private getReasonsForPersonFunc = () => (personIndex: number) => {
    if (!this.calculations) {
      return [];
    }

    const reasons = getReasonsForPerson(this.calculations)(personIndex);

    if (this.enhanceRefundReasons) {
      return this.enhanceRefundReasons(personIndex, reasons);
    }

    return reasons;
  };

  protected setCalculations = (calculations?: incomeTaxCalculator.Calculations): void => {
    if (calculations) {
      this.calculations = calculations;
      this.isTaxLoss = Boolean(calculations.isTaxLoss);
      this.resultType = calculations.taxType as ResultType;
      if (calculations.tax) {
        this.rawValue = calculations.tax;
        this.value = Math.abs(calculations.tax);
      }

      if (calculations.taxLoss) {
        this.taxLoss = Math.abs(calculations.taxLoss.overall);
      }
    }
  };

  protected setMandatory = (mandatory: MandatoryType): void => {
    this.mandatory = mandatory;
  };
}

export type TaxCalculator = Omit<BaseTaxCalculator, 'getReasonsForPersonFunc'>;
