import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CouponResult, PaymentCustomer, Product } from 'src/types/payment';

type State = {
  product?: Product;
  customer?: PaymentCustomer;
  couponResult?: CouponResult;
};

type RootState = {
  payment: State;
};

export const initial: State = {};

const paymentSlice = createSlice({
  name: 'payment',
  initialState: initial,
  reducers: {
    setProduct(state, action: PayloadAction<Product>) {
      state.product = action.payload;
    },
    setCustomer(state, action: PayloadAction<PaymentCustomer>) {
      state.customer = action.payload;
    },
    setCouponResult(state, action: PayloadAction<CouponResult | undefined>) {
      state.couponResult = action.payload;
    },
  },
});

const getProduct = (state: RootState): Product | undefined => state.payment.product;
const getCustomer = (state: RootState): PaymentCustomer | undefined => state.payment.customer;
const getCouponResult = (state: RootState): CouponResult | undefined => state.payment.couponResult;

export const selectors = {
  getProduct,
  getCustomer,
  getCouponResult,
};

export const reducer = paymentSlice.reducer;
export const actions = paymentSlice.actions;
