// @ts-nocheck

import Config from 'react-native-config';

const formatGAValue = (value) => {
  if (!value || value < 0) return 0;
  return Math.round(value);
};

export const GTM = {
  init: () => {
    // Init dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    // Load GTM
    const n = document.createElement('script');
    n.type = 'text/javascript';
    n.async = !0;
    n.src = `https://www.googletagmanager.com/gtm.js?id=${Config.GTM_KEY}&l=dataLayer`;
    n.id = 'gtm-script';
    const a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(n, a);
    // listen cookie consent
    window.addEventListener('updateCookieConsent', () => {
      window.dataLayer.push({ event: 'updateCookieConsent' });
    });
  },
  track: (eventName: string, properties: Record<string, any>) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventName, ...properties });

    // Send event to GA, which is inside GTM
    window.dataLayer.push({
      event: 'gtm_ga_event',
      category: properties.category || 'All',
      action: eventName,
      label: properties.label,
      value: formatGAValue(properties.value || properties.revenue),
    });
  },
  trackBundleProduct: (eventName: string, properties: Record<string, any>) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventName, ...properties });

    // Send event to GA, which is inside GTM
    window.dataLayer.push({
      event: 'gtm_ga_event',
      category: properties.category,
      action: eventName,
      label: properties.label,
      value: properties.value,
    });
  },
};

GTM.init();
