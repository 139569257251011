import { HeaderBackButton } from '@react-navigation/elements';
import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { CUDocumentResultContainer } from './cu-document-result-container';

export const toCUDocumentResultScreen: Action = navigate(ScreenName.CUDocumentResult, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.CUDocumentResult,
  },
});

export const CUDocumentResultScreen: React.FC = () => {
  const { getNavigationActions, safeResetNavigation } = useNavigation();

  const handleOnBackButtonPress = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={handleOnBackButtonPress} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.cu-document-result.screen.header.title' },
    {
      headerLeft: headerLeftComponent,
    },
  );

  return <CUDocumentResultContainer />;
};
