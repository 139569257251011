import { Step, Screen, DocumentSide } from './types';

export const ID_UPLOAD_ALLOWED_FILE_TYPES = {
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
};

export const allowedFileTypes = Object.values(ID_UPLOAD_ALLOWED_FILE_TYPES);

export const NUMBER_OF_ALLOWED_FILES = 1;

export const ID_CARD_STEPS: Array<Step> = [
  {
    type: Screen.UPLOAD,
    side: DocumentSide.FRONT,
  },
  {
    type: Screen.PREVIEW,
    side: DocumentSide.FRONT,
  },
  {
    type: Screen.UPLOAD,
    side: DocumentSide.REAR,
  },
  {
    type: Screen.PREVIEW,
    side: DocumentSide.REAR,
  },
];
