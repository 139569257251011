/*
 * WARNING! if you change this file update the version of the document
 * in the file assets/docs/index.js
 */
export default `
<p>Aggiornato al 15 Marzo 2024</p>

<h1>CONDIZIONI GENERALI DI SERVIZIO</h1>

<h1>§ 1 DISPOSIZIONI GENERALI</h1>
<p>
  1.1 Taxfix Italia società tra professionisti a responsabilità limitata (di seguito
  anche “<b>Taxfix Italia</b>”) è una società di diritto italiano che utilizza un software
   per l'invio delle dichiarazioni dei redditi di contribuenti italiani (il “<b>Provider</b>”).
</p>
<p>
  1.2 Le presenti condizioni generali di servizio (di seguito le “<b
    >Condizioni Generali</b
  >”) regolano l'utilizzo delle piattaforme Taxfix (la “<b>App Taxfix</b>” e
  la “<b>WebApp Taxfix</b>” o anche di seguito, congiuntamente, “i
  <b>Software Taxfix</b>” o, disgiuntamente, “il <b>Software Taxfix</b>”) e si applicano a tutti coloro che scaricano e/o
  utilizzano i Software Taxfix per accedere ai servizi offerti da consulenti
  fiscali professionisti collaboratori del Provider, in particolare con
  riferimento all'elaborazione di dichiarazioni dei redditi (i
  “<b>Commercialisti</b>”).
</p>
<p>
  1.3 Scaricando e installando l'App Taxfix su dispositivo mobile o
  accedendo alla WebApp Taxfix da pc, l'utente (l’“<b>Utente</b>”)
  accetta espressamente le presenti Condizioni Generali.
</p>
<p>
  1.4 Taxfix si riserva il diritto di modificare le Condizioni Generali in
  qualsiasi momento a seguito dell'introduzione di nuove leggi o
  regolamenti, dandone immediata comunicazione scritta all’Utente,
  all’indirizzo e-mail fornito dall’Utente per la registrazione ai Software
  Taxfix.
</p>
<p>
  L'Utente potrà in ogni caso esercitare il diritto di recesso dal rapporto
  ai sensi del successivo articolo 12. In caso di domande relative alle
  Condizioni Generali, l'Utente può contattare il Provider via e-mail
  all'indirizzo mail support@taxfix.it.
</p>

<h1>§ 2 – I SOFTWARE TAXFIX</h1>
<p>
  2.1 Gli Utenti a cui sono rivolti i Software Taxfix sono i contribuenti
  italiani che sono ammessi a presentare il modello di dichiarazione dei
  redditi “<b>Modello 730</b>”.
</p>
<p>
  2.2 Il Provider tuttavia non è responsabile di valutare la concreta
  ammissibilità dell'Utente all'invio del Modello 730. Pertanto, accettando
  le Condizioni Generali, l'Utente espressamente riconosce e dichiara di
  essere ammesso all’invio del Modello 730.
</p>
<p>
  2.3 Dopo aver scaricato l'App Taxfix o aver effettuato l'accesso alla
  WebApp Taxfix, l'Utente dovrà registrarsi all'interno della piattaforma
  digitale e aprire un account. Successivamente, i Software Taxfix
  permetteranno all’Utente di caricare tutti i dati e i documenti richiesti
  al fine di essere identificati da parte del Provider.
</p>
<p>
  2.4 L'identità dell'Utente è verificata dal Provider tramite un sistema di
  identificazione elettronica.
</p>
<p>
  2.5 Tramite i Software Taxfix, l'Utente può ottenere l'elaborazione del
  proprio Modello 730 e l'invio dello stesso all'Agenzia delle Entrate:
</p>
<p>
  i. caricando tramite i Software Taxfix i dati e la relativa documentazione
  fiscale;
</p>
<p>
  ii. compilando, attraverso i Software Taxfix, un questionario relativo ai
  redditi e alle spese dell'Utente riferiti all'anno fiscale precedente;
</p>
<p>
  iii. laddove sia stata conferita delega al Commercialista, confermando o
  modificando i dati risultanti dal modello 730 precompilato scaricato dal
  sito web di Entratel e reso disponibile all'Utente tramite i Software
  Taxfix;
</p>
<p>
  iiii. laddove l’Utente abbia espressamente accettato di accedere direttamente al proprio
  Cassetto Fiscale - tramite interfaccia messa a disposizione nei Software Taxfix -,
  condividendo con Taxfix Italia l’identificata rilevante documentazione ivi disponibile e il
  modello 730 precompilato (insieme “<b>Informazioni Fiscali</b>”).
</p>
<p>
  2.5.1 Dettagli sulla procedura di accesso diretto al Cassetto Fiscale, tramite interfaccia messa
  a disposizione nei Software Taxfix.
  All’Utente e’ richiesto all’interno dei Software Taxfix di:
  <ul>
    <li>
      dare esplicito consenso ad effettuare l’autenticazione tramite SPID o CIE all’interno
      dell’interfaccia integrata nei Software Taxfix, al fine di accedere al proprio Cassetto
      Fiscale,
    </li>
    <li>
      dare esplicito consenso alla condivisione con Taxfix Italia dei documenti scaricati dal
      Cassetto Fiscale.
    </li>
  </ul>
</p>
<p>
  L’accesso diretto dell’Utente al proprio Cassetto Fiscale nonché il download delle
  informazioni fiscali ivi presenti è resa possibile tramite CodiceWeb Banking Innovation srl
  (di seguito anche “<b>CWBI</b>”), società provider di servizi riconosciuta dall’AgID come soggetto
  aggregatore privato. Nello specifico, CWBI mette a disposizione tramite i Software Taxfix:
  <ul>
    <li>
      la piattaforma di autenticazione tramite SPID o CIE,
    </li>
    <li>
      il meccanismo della “tokenizzanione” delle credenziali SPID o CIE, e 
    </li>
    <li>
      un modulo API di collegamento al portale dell’Agenzia delle Entrate, in particolare al
      Cassetto Fiscale.
    </li>
  </ul>
</p>
<p>
  Tramite il meccanismo della “tokenizzanione”, CWBI fornisce a Taxfix in forma
  anonimizzata le credenziali SPID o CIE dell’Utente, utilizzabili da Taxfix in una sessione
  unica di 2 ore per il collegamento tramite il modulo API al Cassetto Fiscale dell’Utente, al
  fine di scaricare le informazioni fiscali ivi disponibili, come esplicitamente e preliminarmente
  mostrate all’Utente nei Software Taxfix, e raccolto esplicito consenso. Allo scadere delle 2
  ore dall’autenticazione, la sessione unica si chiude, le credenziali anonimizzate non sono più
  utilizzabili ne’ da Taxfix ne’ da CWBI e le stesse saranno cancellate definitivamente.
  CWBI è responsabile esterno del trattamento dei dati e garantisce che il trattamento dei dati
  raccolti dal loro servizio è volto unicamente alle finalità connesse, strumentali e necessarie
  alla fornitura dello stesso, e i dati raccolti durante il funzionamento del sito sono conservati
  per il tempo strettamente necessario a svolgere le attività precisate. I dati raccolti da CWBI
  mediante il servizio sono cifrati con chiave AES a 256-bit, garantendo quindi il massimo
  livello di sicurezza disponibile sul mercato. Alla scadenza i dati saranno cancellati o 
  anonimizzati sia da Taxfix che da CWBI, a meno che non sussistano ulteriori finalità
  normative per la conservazione degli stessi.
</p>
<p>
  2.6 I Software Taxfix sono piattaforme online, quindi l'utilizzo di esse
  richiede una connessione Internet. La connessione Internet non è inclusa
  nei servizi forniti dal Provider, quindi qualsiasi spesa relativa ai
  servizi di dati mobili non è oggetto delle Condizioni Generali e dovrà
  essere oggetto di un accordo separato tra l'Utente e il suo gestore di
  telefonia mobile o fissa.
</p>

<h1>§ 3 – DEFINIZIONI</h1>
<p>
  <b>App Taxfix</b>: significa l'applicazione per dispositivi mobili che
  deve essere scaricata dall'Utente dagli app store disponibili su iOS e
  Android e installata sul rispettivo dispositivo.
</p>
<p>
  <b>WebApp Taxfix</b>: significa la piattaforma digitale accessibile
  mediante la rete internet in modalità “Software-as-a-Service” (SaaS),
  tramite una dashboard.
</p>
<p>
  <b>Cassetto Fiscale</b>: è il servizio dell’Agenzia delle Entrate che consente la consultazione delle
  proprie informazioni fiscali, come, a titolo di esempio, dichiarazioni fiscali, versamenti
  effettuati tramite modello F24, dati anagrafici. Per accedere al Cassetto fiscale è necessario
  essere in possesso di una identità definita nell’ambito del Sistema pubblico di Identità
  Digitale (SPID, CIE o CNS).
</p>
<p>
  <b>Codice Civile</b>: significa il Regio decreto del 16 marzo 1942 – XX,
  n. 262.
</p>
<p>
  <b>Codice del Consumo</b>: significa il decreto legislativo n. 206 del 6
  settembre 2005.
</p>
<p>
  <b>Commercialista</b>: significa qualsivoglia professionista dottore
  commercialista collaboratore del Provider, nominato dall'Utente, attraverso i Software Taxfix,
  per la predisposizione del proprio Modello 730 e per l'invio dello stesso
  all'Agenzia delle Entrate preposta.
</p>
<p>
  <b>Condizioni Generali</b>: significa le presenti condizioni generali di
  servizio, come di volta in volta modificate dal Provider.
</p>
<p>
  <b>Contratto di Servizi</b>: significa il contratto relativo ai Servizi di
  Consulenza Fiscale a pagamento offerti dal Provider per il tramite del
  Commercialista (il “<b>Contratto di Incarico Professionale</b>”).
</p>
<p>
  <b>Corrispettivo del Servizio</b>: significa il corrispettivo, cosí come definito all’interno
  del “Contratto di Incarico Professionale”, che l'Utente è tenuto a
  corrispondere a fronte del Contratto di Servizi.
</p>
<p><b>Parte</b>: significa sia il Provider che l’Utente.</p>
<p>
  <b>Periodo di Recesso</b>: ha il significato attribuito a tale termine al
  successivo articolo 12.1.
</p>
<p>
  <b>Piattaforma</b>: ha il significato attribuito a tale termine al
  successivo articolo 4.6.
</p>
<p>
  <b>Provider</b>: significa Taxfix Italia societá tra
  professionisti a responsabilità limitata, con sede legale in Corso Giacomo
  Matteotti, 10, 20121 Milano MI C/O Studio Legale Orrick, Herrington &amp;
  Sutcliffe (Europe), LLP.
</p>
<p><b>Servizio</b> significa il servizio erogato dal Provider.</p>
<p>
  <b>Servizi di Consulenza Fiscale</b>: significa i seguenti servizi svolti
  dal Commercialista a favore dell'Utente:
</p>
<p>(i) compilazione del Modello 730;</p>
<p>(ii) apposizione del Visto di Conformità;</p>
<p>(iii) invio del Modello 730 all’Agenzia delle Entrate;</p>
<p>
  (iv) conferma all'Utente, tramite i Software Taxfix, dell’avvenuto invio del Modello 730.
</p>
<p>
  <b>Tokenizzazione</b>: identifica un servizio per cui dati vengono sostituiti da token, ovvero da
  codici numerici o alfanumerici.
</p>
<p>
  <b>Utente</b>: significa colui che scarica/utilizza i, e si registra
  all'interno dei, Software Taxfix.
</p>
<p>
  <b>Visto di Conformità</b>: significa il visto di conformità ai sensi del
  decreto legislativo n. 241 del 9 luglio 1997.
</p>

<h1>§ 4 – CONCLUSIONE DEI CONTRATTI</h1>
<p><u>Licenza d’uso dei Software Taxfix</u>.</p>
<p>
  4.1 La licenza per l’uso dei Software Taxfix è concessa gratuitamente,
  costituendo tali Software Taxfix unicamente lo strumento tecnico
  utilizzato per la richiesta di prestazione dei Servizi di Consulenza
  Fiscale.
</p>
<p>
  4.2 La licenza è concessa per il periodo definito dal successivo articolo
  10.1 e decorre dalla data di accettazione delle Condizioni Generali.
</p>
<p>
  4.3 L'Utente non è autorizzato a trasferire, concedere in sublicenza,
  modificare, copiare, riprodurre, convertire, decodificare, disassemblare o
  porre in essere qualsiasi tentativo di ricavare o alterare i Software
  Taxfix.
</p>
<p>
  4.4 I Software Taxfix raccolgono i dati e i documenti necessari per
  l’elaborazione del Modello 730 dell'Utente. La raccolta dei dati è
  ottenuta tramite domande predeterminate cui l'Utente risponde.
</p>
<p>
  4.5 I dati e documenti condivisi dall'Utente sui Software Taxfix vengono
  memorizzati in un archivio digitale tramite un provider certificato di
  archiviazione, in conformità alle disposizioni applicabili del decreto
  legislativo 7 marzo 2005, n. 82 (Codice dell’Amministrazione Digitale) e
  sono messi a disposizione del Commercialista, il quale:
</p>
<p>
  i. qualora abbia ricevuto delega o espressa e inequivocabile accettazione alla
  condivisione delle informazioni presenti nel cassetto fiscale dell’Utente, accede
  al modello 730 precompilato dell'Utente e rende i dati precompilati disponibili per
  l'Utente tramite i Software Taxfix;
</p>
<p>
  ii. scarica i documenti e i dati condivisi dall'Utente sui Software Taxfix;
</p>
<p>iii. elabora il Modello 730 per l’Utente;</p>
<p>iv. appone il Visto di Conformità al Modello 730;</p>
<p>v. invia il Modello 730 all’Agenzia delle Entrate preposta.</p>
<p><u>Accesso alla Piattaforma di comunicazione Taxfix</u>.</p>
<p>
  4.6 I Software Taxfix contengono anche una piattaforma di comunicazione
  digitale intesa per tutte le comunicazioni tra l'Utente e il
  Commercialista in relazione ai Servizi di Consulenza Fiscale (la
  “<b>Piattaforma</b>”).
</p>
<p>
  Fanno parte della Piattaforma diversi canali di comunicazione (chat, email, etc.)
  come via via messi a disposizione dal Provider per il tramite di parti terze.
</p>
<p>
  In particolare, attraverso la Piattaforma, il Commercialista può
  contattare l'Utente al fine di chiedere chiarimenti in merito alla
  documentazione e ai dati fiscali caricati dall'Utente sui Software Taxfix,
  e chiedere ogni ulteriore informazione rilevante e necessaria per
  l’elaborazione del Modello 730 dell’Utente.
</p>
<p>
  <u
    >Contratto a disciplina dei Servizi di Consulenza Fiscale e conferimento
    del mandato al Professionista</u
  >.
</p>
<p>
  4.7 Attraverso i Software Taxfix, l'Utente stipula, altresì, con il
  Provider il relativo contratto di servizi concernente i Servizi di
  Consulenza Fiscale a pagamento forniti dal Commercialista attraverso i
  Software Taxfix. Tale stipula, volta a disciplinare i termini
  dell'incarico professionale conferito al Provider, si perfeziona
  attraverso la barratura dell’apposita casella presente nell'App Taxfix o
  nella WebApp Taxfix ad opera dell’Utente.
</p>
<p>
  4.8 A fronte della prestazione dei Servizi di Consulenza Fiscale, l'Utente
  è tenuto al pagamento, prima di dare conferma selezionando il relativo
  tasto “Conferma e Invia”, del Corrispettivo del Servizio, secondo le
  modalità di pagamento di cui al successivo articolo 7.
</p>
<p>
  4.9 Ogni ulteriore termine e condizione regolanti l’incarico professionale
  conferito al Commercialista sono contenute nel relativo contratto a
  disciplina dei Servizi di Consulenza Fiscale (il “<b>Contratto di Incarico Professionale</b>”).
</p>
<p><u>Elaborazione del Modello 730</u>.</p>
<p>
  4.10 Il Provider è una società tra professionisti, avente quali soci
  dottori commercialisti abilitati alla professione con comprovata
  esperienza nella consulenza fiscale a contribuenti italiani, ed è pertanto
  autorizzato dall’Agenzia delle Entrate a:
</p>
<p>
  (i) apporre il Visto di Conformità, tramite i propri Commercialisti, su
  modelli 730;
</p>
<p>(ii) inviare modelli 730 alla medesima Agenzia delle Entrate.</p>
<p>
  4.11 In particolare, con la stipula del contratto a disciplina dei Servizi di
  Consulenza Fiscale, il Provider si impegna a svolgere le seguenti attività
  a favore dell'Utente:
</p>
<p>
  i. qualora ne ricevesse delega, accedere al modello 730 precompilato
  dell'Utente, sulla base di uno specifico mandato che l'Utente conferisce
  al Commercialista attraverso i Software Taxfix;
</p>
<p>
  ii. laddove delegato, scaricare i dati risultanti dal modello 730
  precompilato, che vengono messi a disposizione dell'Utente tramite i
  Software Taxfix, per conferma e/o modifica dei dati stessi;
</p>
<p>
  iii. scaricare ed esaminare i dati e la documentazione condivisi dall'Utente,
  ivi compresi i dati e documenti, tra i quali il modello di dichiarazione precompilata,
  disponibili sul cassetto fiscale dell’Utente e condivisi con Taxfix Italia, previa espressa
  accettazione degli opportuni consensi, tramite accesso diretto al portale dell’Agenzia delle
  Entrate con le proprie credenziali SPID;
</p>
<p>
  iv. elaborare il Modello 730 dell’Utente ed apporre il Visto di
  Conformità;
</p>
<p>
  v. inviare il Modello 730 all’Agenzia delle Entrate tramite “Entratel”, a
  fronte del pagamento del Corrispettivo del Servizio da parte dell’Utente.
</p>
<p>
  4.12 L'avvenuto invio del Modello 730 all’Agenzia delle Entrate è
  confermato all'Utente attraverso i Software Taxfix utilizzati oppure via
  e-mail, all’indirizzo fornito dall’Utente alla registrazione.
</p>

<h1>§ 5 – IMPEGNI DELL'UTENTE</h1>
<p>
  <u>Impegni di carattere generale.</u>
</p>
<p>
  5.1 L'Utente si impegna a trasmettere sotto la propria responsabilità, al
  Provider, attraverso i Software Taxfix:
</p>
<p>i. informazioni veritiere concernenti la sua identità; e</p>
<p>
  ii. dati, informazioni e documenti veritieri, completi ed esaurienti
  riferiti ai suoi redditi, alle spese e, in generale, alla sua posizione
  fiscale.
</p>
<p>
  5.2 L'Utente si impegna a comunicare al Provider qualsiasi modifica del
  proprio indirizzo, numero di telefono o fax, indirizzo e-mail, ecc.
</p>
<p>
  5.3 L'Utente che si registra nel Software Taxfix si impegna a
  sottoscrivere il Modello 730 (ed in particolare i modelli 730-1 e 730-2)
  con firma elettronica avanzata.
</p>
<p><u>Obbligo dell’Utente di verificare e confermare i dati</u></p>
<p>
  5.4 L'Utente è tenuto a verificare e confermare i dati raccolti attraverso
  i Software Taxfix prima della finalizzazione del Modello 730 e dell’invio
  dello stesso all'Agenzia delle Entrate.
</p>
<p>
  5.5 Un’ulteriore conferma sui
  propri dati fiscali, laddove necessario, potrá essere richiesta all’Utente
  tramite e-mail, all’indirizzo fornito dall’Utente per la registrazione ai
  Software Taxfix.
</p>
<p>
  5.6 L'Utente non dovrà utilizzare processi automatizzati come agenti, bot,
  script, spider o processi simili per accedere, copiare, raccogliere,
  trasmettere o monitorare i servizi, i dati di registrazione, i dati
  dell'Utente o qualsiasi altro dato dell'applicazione. L'Utente inoltre non
  potrà utilizzare tali processi per testare o monitorare la sicurezza del
  servizio. È vietato violare i meccanismi di autenticazione o altre
  applicazioni necessarie alla funzionalità del servizio; è vietato anche
  l'utilizzo di software, meccanismi o altri codici che possano
  compromettere o siano destinati a compromettere l'utilizzo dei servizi o
  il regolare funzionamento dell'applicazione.
</p>

<h1>§ 6 – OBBLIGO DI RISERVATEZZA DEL PROVIDER</h1>
<p>
  6.1 Il Provider garantisce che tutti i dati e i documenti riferiti
  all'Utente, compresi i dati risultanti dal Modello 730 saranno trattati
  con la massima riservatezza e conservati in conformità alla normativa
  applicabile, italiana ed europea, sulla protezione dei dati personali e
  non potranno essere consultati o modificati da soggetti non autorizzati.
</p>
<p>
  6.2 Il Provider si impegna a trattare i dati dell’Utente, ivi inclusi i
  dati risultanti dal Modello 730, in conformità alle previsioni contenute
  nell’Allegato 3 del Provvedimento dell’Agenzia delle Entrate del 14 gennaio 2022.
</p>

<h1>§ 7 – TERMINI DI PAGAMENTO</h1>
<p>
  7.1 Il Corrispettivo del Servizio, come definito nel “Contratto di Incarico Professionale”,
  dovrà essere pagato dall'Utente prima di dare conferma selezionando il relativo tasto
  "Conferma e Invia" presente nei Software Taxfix, con carta di credito o di
  debito.
</p>
<p>
  7.2 Il Corrispettivo del Servizio pagato dall'Utente è ricevuto dal
  Provider, attraverso i Software Taxfix.
</p>
<p>
  7.3 Entro 30 giorni dal pagamento del Corrispettivo del Servizio, l'Utente
  potrà scaricare la relativa fattura elettronica (che viene emessa dal
  Provider) attraverso il sistema di interscambio elettronico.
</p>

<h1>§ 8 – RESPONSABILITÀ DI TAXFIX</h1>
<p>
  8.1 In nessun caso il Provider potrà essere ritenuto responsabile per
  eventuali danni, costi, spese (incluse ragionevoli spese legali e spese
  processuali), multe e altre perdite direttamente subite o sostenute
  dall'Utente in relazione a:
</p>
<p>
  i. alla veridicità completezza e correttezza delle informazioni in
  qualsiasi modo dallo stesso fornite;
</p>
<p>ii. l'uso improprio dei Software Taxfix da parte dell'Utente;</p>
<p>
  iii. eventuali malfunzionamenti dei Software Taxfix, salvi i casi di dolo
  o colpa grave, ai sensi dell'articolo 1229 del Codice Civile ad opera del
  Provider.
</p>

<h1>§ 9 – OBBLIGHI DI INDENNIZZO DELL’UTENTE</h1>
<p>
  9.1 Fatti salvi i rimedi previsti dalla legge applicabile, l'Utente si
  impegna a manlevare e tenere indenne il Provider da ogni e qualsiasi
  danno, perdita, costo, spesa (incluse ragionevoli spese legali e spese
  processuali), multe e altre perdite direttamente subite o sostenute dal
  Provider in conseguenza di o in relazione a:
</p>
<p>
  i. eventuali dati, informazioni o documenti inesatti, incompleti, falsi o
  fuorvianti trasmessi o caricati dall'Utente nei Software Taxfix, inclusi i
  suoi dati personali e la carta di identità;
</p>
<p>
  ii. l'utilizzo dei Software Taxfix da parte dell'Utente e la violazione
  delle regole che ne disciplinano l'utilizzo;
</p>
<p>
  iii. la violazione di qualsiasi diritto di proprietà intellettuale del
  Provider, inclusa la violazione del precedente articolo 4.3;
</p>
<p>iv. la violazione da parte dell’Utente di qualsiasi diritto di terzi.</p>

<h1>§ 10 - DURATA E SCIOGLIMENTO DEI CONTRATTI</h1>
<p>
  10.1 La licenza d’uso è concessa per un periodo di tempo indeterminato.
  Ciascuna Parte può recedere dal rapporto in qualsiasi momento con un
  preavviso scritto di 2 (due) settimane.
</p>
<p>
  10.2 Al momento dello scioglimento del contratto di licenza d’uso,
  l'Utente dovrà interrompere immediatamente l’utilizzo dei Software Taxfix
  e dovrà disinstallare e cancellare i Software Taxfix dai propri
  dispositivi. Il Provider avrà, altresì, il diritto di disabilitare
  immediatamente l'Utente dall'uso dei Software Taxfix.
</p>
<p>
  10.3 Lo scioglimento del contratto di licenza d’uso determina anche
  l'immediata risoluzione del contratto a disciplina dei Servizi di
  Consulenza Fiscale, fermi restando gli obblighi di pagamento dell'Utente
  riferiti ai Servizi di Consulenza Fiscale già svolti dal Provider.
</p>
<p>
  10.4 Il contratto a disciplina dei Servizi di Consulenza Fiscale, fatto
  salvo il disposto del precedente articolo 10.3, è concluso per un periodo
  di 1 (uno) anno, senza alcun tacito rinnovo.
</p>

<h1>§ 11 – CLAUSOLA RISOLUTIVA ESPRESSA</h1>
<p>
  11.1 Il Provider avrà diritto di risolvere immediatamente il contratto di
  licenza d’uso mediante comunicazione scritta all’Utente, ai sensi
  dell'art. 1456 del Codice Civile, in caso di violazione da parte
  dell’Utente di una qualsiasi delle obbligazioni previste dagli articoli 5
  (Impegni dell’Utente), 7 (Termini di Pagamento) e 4.3.
</p>

<h1>§ 12 – DIRITTO DI RECESSO</h1>
<p>
  12.1 Ai sensi dell'articolo 52 del Codice del Consumo, l'Utente ha diritto
  di recedere dal contratto di licenza d’uso (disciplinato dal presente contratto)
  e dal contratto a disciplina dei Servizi di Consulenza Fiscale (il “<b>Contratto di Incarico Professionale</b>”),
  entro 14 giorni dalla conclusione del relativo contratto (“<b>Periodo di Recesso</b>”).
</p>
<p>
  12.2 Con riferimento al contratto di licenza, il Periodo di Recesso è pari
  a 14 giorni dal giorno in cui l'Utente ha scaricato e installato l'App
  Taxfix su dispositivo mobile o ha avuto accesso alla WebApp
  Taxfix.
</p>
<p>
  12.3 Con riferimento al contratto a disciplina dei Servizi di Consulenza
  Fiscale, il Periodo di Recesso è pari a 14 giorni dal giorno in cui
  l'Utente stipula tale contratto, barrando, nell'App Taxfix o WebApp
  Taxfix, la casella "Ho letto e accetto le Condizioni generali di servizi
  di Taxfix e la Politica sulla Privacy e acconsento al trattamento dei miei
  dati fiscali sensibili.".
</p>
<p>
  12.4 Durante il Periodo di Recesso, il diritto di recesso dal contratto a
  disciplina dei Servizi di Consulenza Fiscale è comunque escluso nel caso
  in cui il Provider abbia già prestato la propria attività a favore
  dell'Utente e l'Utente abbia dato istruzioni al Provider di inviare il
  Modello 730 all'Agenzia delle Entrate, premendo il pulsante "Conferma e
  Invia".
</p>
<p>
  12.5 Per esercitare il diritto di recesso dal contratto di licenza d’uso,
  l'Utente dovrà comunicare al Provider la sua intenzione di recedere da
  tale contratto mediante una dichiarazione scritta inviata con lettera
  raccomandata o per posta elettronica (e-mail: <a href="mailto:support@taxfix.it”">support@taxfix.it</a>). 
  A propria scelta, l'Utente potrà utilizzare il modulo fornito nell'Allegato
  A delle presenti Condizioni Generali.
</p>
<p>
  12.6 Per esercitare il diritto di recesso dal contratto a disciplina dei
  Servizi di Consulenza Fiscale, l’Utente dovrà comunicare al Provider la
  sua intenzione di recedere da tale contratto mediante una dichiarazione
  scritta inviata con lettera raccomandata o per posta elettronica (e-mail:
  <a href="mailto:support@taxfix.it”">support@taxfix.it</a>). 
  A propria scelta, l'Utente potrà utilizzare il modulo
  fornito nell'Allegato A delle presenti Condizioni Generali.
</p>
<p>
  12.7 Il Periodo di Recesso si considera rispettato se la comunicazione di
  recesso ai sensi dei precedenti articoli 12.5 e 12.6 è inviata dall'Utente
  prima della scadenza del Periodo di Recesso.
</p>
<p>
  12.8 Se l'Utente recede dal contratto di licenza d’uso dovrà
  immediatamente, e comunque non oltre 3 (tre) giorni, disinstallare ed
  eliminare l'App Taxfix dal proprio dispositivo mobile o terminare l’uso
  della WebApp Taxfix.
</p>
<p>
  12.9 Nel caso in cui l'Utente receda dal contratto a disciplina dei
  Servizi di Consulenza Fiscale, e fatto salvo il precedente articolo 12.4,
  il provider rimborserà immediatamente il Corrispettivo del Servizio, se
  già pagato dall'Utente, entro 14 giorni dal giorno di ricevimento della
  comunicazione di recesso da parte del Provider. In nessun caso il rimborso
  sarà soggetto al pagamento di un corrispettivo.
</p>
<p>
  12.10 Il rimborso sarà accreditato mediante lo stesso metodo di pagamento
  utilizzato per la transazione originale, salvo diverso accordo tra le
  parti.
</p>

<h1>§ 13 – TUTELA DEI DATI PERSONALI</h1>
<p>
  13.1 L'informativa sulla privacy del Provider è disponibile al sito web
  https://taxfix.it/informativa-sul-trattamento-dei-dati-personali/.
</p>

<h1>§ 14 – COPYRIGHT E PROPRIETÀ INTELLETTUALE</h1>
<p>
  14.1 Il Provider è titolare di tutti i diritti, tra cui i diritti di
  proprietà intellettuale, nonché dei diritti di copyright, dei Software
  Taxfix e dei marchi in essi utilizzati. Pertanto, l'Utente riconosce
  espressamente tali diritti del Provider.
</p>
<p>
  14.2 I contenuti pubblicati sul sito web del Provider e sull'App Taxfix e
  WebApp Taxfix (logo Taxfix, immagini, video, grafica e testi) sono
  protetti da copyright.
</p>

<h1>§ 15 – LEGGE APPLICABILE E FORO ESCLUSIVO</h1>
<p>
  15.1 Le Condizioni Generali sono regolate dalle leggi della Repubblica
  Italiana.
</p>
<p>
  15.2 Ogni controversia tra il Provider e l'Utente, che non possa essere
  risolta di comune accordo, anche attraverso una procedura di risoluzione
  alternativa delle controversie (ADR), ai sensi dell'articolo 141 del
  Codice del Consumo, e che concerne l'interpretazione, l’esecuzione delle
  obbligazioni, la violazione, la risoluzione o l’applicazione delle
  Condizioni Generali o del contratto di licenza d’uso o del contratto a
  disciplina dei Servizi di Consulenza Fiscale, sarà sottoposta alla
  giurisdizione esclusiva del Tribunale di Milano.
</p>
<p>
  b. Una piattaforma ADR online (piattaforma ODR) è disponibile per l'Utente
  al sito web https://webgate.ec.europa.eu/odr, ai sensi del Regolamento EU
  N. 524/2013.
</p>

<h1>§ 16 – LINGUA</h1>
<p>
  16.1 Le Condizioni Generali sono redatte in lingua italiana. Una
  traduzione in inglese è disponibile per gli Utenti esclusivamente a scopo
  di cortesia. È possibile ricevere la versione inglese contattando
  l’assistenza clienti all’indirizzo e-mail support@taxfix.it. In caso di
  divergenza tra la versione italiana e quella inglese, prevarrà la versione
  italiana.
</p>

<h1>§ 17 – NULLITÀ PARZIALE</h1>
<p>
  17.1 L'invalidità o l'inefficacia di alcuna delle pattuizioni contenute
  nelle presenti Condizioni Generali non inficerà, nella misura consentita
  dalla legge, la validità e l'efficacia delle restanti pattuizioni
  contrattuali.
</p>
<br />
<br />
<p>ALLEGATO A</p>
<p>
  (Se l'Utente intende recedere dal contratto di licenza d’uso o dal
  contratto a disciplina dei Servizi di Consulenza Fiscale, potrà utilizzare
  il seguente modulo, inviandone una copia compilata al Provider).
</p>
<br />
<p><b>email: support@taxfix.it</b></p>
<p><b>PEC: taxfixitalia@legalmail.it</b></p>
<br />
<p>A:</p>
<p>Taxfix Italia società tra professionisti a responsabilità limitata</p>
<p><b>Corso Giacomo Matteotti, 10,</b></p>
<p><b>20121 Milano MI</b></p>
<p><b>C/O Orrick, Herrington &amp; Sutcliffe (Europe), LLP</b></p>
<br />

<p>
  <b>
    Il sottoscritto dichiara di recedere dal Contratto di Licenza / dal
    Contratto di Servizi stipulato in data:
  </b>
</p>
<br />
<p><b>Nome dell’Utente:</b></p>
<p><b>Indirizzo dell’Utente:</b></p>
<p>
  <b> 
    Firma dell’Utente (unicamente se la comunicazione è inviata via posta
    raccomandata):
  </b>
</p>
<p><b>Data:</b></p>
`;
