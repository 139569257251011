import { Validation } from './types';

type Props = {
  min: number;
  max: number;
};
export type InputValidator = (lengthProps: Props, value: any) => Validation[];

const validator = (
  { min, max }: Props,
  value: number,
  translationKeyLabel: string,
): Validation[] => {
  const failures = [];
  const lessThanMin = value < min;
  const moreThanMax = value > max;

  if (lessThanMin || value === undefined || value === null) {
    failures.push({
      limit: min,
      translationKey: `answers.confirmable-input.invalid.${translationKeyLabel}.min`,
    });
  }

  if (moreThanMax) {
    failures.push({
      limit: max,
      translationKey: `answers.confirmable-input.invalid.${translationKeyLabel}.max`,
    });
  }

  return failures;
};

const numberLengthValidator: InputValidator = ({ min, max }: Props, value: number): Validation[] =>
  validator(
    {
      min,
      max,
    },
    value,
    'number',
  );

const stringLengthValidator: InputValidator = ({ min, max }: Props, value: string): Validation[] =>
  validator(
    {
      min,
      max,
    },
    value.length,
    'text',
  );

export { numberLengthValidator, stringLengthValidator };
