import font from './font.css';
import variables from './variables.css';

export default `
${font}
${variables}

body {
  font-family: var(--font-book), 'Helvetica Neue', Arial, sans-serif;
}

* {
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-size: 16px;
  font-family: var(--font-book);
  color: var(--color-primary-text);
}

ul {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 15px;
}

ol {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 15px;
}

ul,
ol {
  margin: 1.5em 0;
  background-color: rgba(0,0,0,.05);
  border-radius: .5em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
}

ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: outside;
}

ul ol {
  list-style-type: lower-latin;
  list-style-position: outside;
}

ol ol {
  counter-reset: list;
  list-style-type: lower-latin;
  list-style-position: outside;
}

ol ol > li {
  list-style: none;
  position: relative;
}

ol ol > li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ') ';
  position: absolute;
  left: -1.4em;
}

img {
  max-width: 100%;
  margin: 15px 0;
}

li {
  margin-bottom: 10px;
  font-family: var(--font-book);
  word-wrap: break-word;
}

h1 {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--color-primary-text);
}

h2, h3 {
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--font-bold);
  color: var(--color-primary-text);
  letter-spacing: 1px;
}

p,
ol,
ul,
li {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-family: var(--font-book);
  color: var(--color-primary-text);
}

address {
  margin-left: 15px;
}

section {
  border: 1px solid var(--color-primary);
  padding: 10px;
}

.button {
  color: var(--color-primary);
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  background-color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  text-decoration: none;
  justify-content: center;
}

.wrapper {
  text-align: center;
  padding: 20px;
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
p {
  margin: 0.5em 0;
}

h2,
h3 {
  margin-top: 30px;
}

a {
  font-family: var(--font-medium);
  color: var(--color-primary);
}

em,
i {
  font-style: italic;
  font-family: var(--font-italic);
}

b,
strong {
  font-family: var(--font-bold);
  font-style: bold;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-style: solid;
  border-width: 2px;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disable-main-styling {
  padding: 0;
  marging: 0;
  background: none;
}
`;
