import React, { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { theme, Box, titleTextStyle } from '../../../components/core';
import FormField, { Error } from '../../../components/form-field-extended';

type Props = {
  labelId: string;
  children: ReactNode;
  error?: Error;
};
const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: theme.color.border,
  },
  fieldBoxWithoutError: {
    borderBottomWidth: 0,
  },
  errorStyle: {
    paddingBottom: 10,
  },
});
export const DonationFormField = ({ labelId, children, error }: Props) => {
  return (
    <Box left={2} right={2} top={1} style={styles.container}>
      <FormField
        label={{
          key: labelId,
        }}
        labelStyle={[
          titleTextStyle,
          {
            color: theme.color.primaryText,
          },
        ]}
        labelTextTransform="uppercase"
        fieldBoxStyle={error ? undefined : styles.fieldBoxWithoutError}
        errorStyle={styles.errorStyle}
        error={error}
      >
        {() => children}
      </FormField>
    </Box>
  );
};
