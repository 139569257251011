export type NumericKeyboardTypes = 'numeric' | 'phone-pad';
export type RNKeyboardType =
  | 'default'
  | 'email-address'
  | 'numeric'
  | 'phone-pad'
  | 'ascii-capable'
  | 'numbers-and-punctuation'
  | 'url'
  | 'number-pad'
  | 'name-phone-pad'
  | 'decimal-pad'
  | 'twitter'
  | 'web-search'
  | 'visible-password';
export type AutoCapitalize = 'none' | 'sentences' | 'words' | 'characters';
const Keyboards = {
  WithDecimal: 'numeric',
  WithoutDecimal: 'phone-pad',
};
export default Keyboards;
