import React from 'react';

import { externalLinks } from '../../common/constants-it';

import { FooterLink } from './footer-link';

export const FooterLinksItaly = () => (
  <>
    <FooterLink
      translationId="web.sidebar.common.privacy-policy"
      externalLink={externalLinks.privacyPolicy}
    />
    <FooterLink translationId="web.sidebar.common.imprint" externalLink={externalLinks.imprint} />
  </>
);
