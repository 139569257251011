import { reaction } from 'mobx';

import { IQuestionStore } from '../QuestionStore.types';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

export default (store: IQuestionStore) => {
  reaction(
    () => store.status,
    () => {
      Analytics.log(AnalyticsEvent.taxYearStatus, {
        year: store.year,
        state: store.status,
      });
    },
    {
      compareStructural: true,
    } as any,
  );
};
