import React from 'react';

import { Container, Icon, Text, icons } from 'src/taxfix-components/src';

import { Props } from './types';

export const ListItem: React.FC<Props> = ({
  variant = 'subtitleMBook',
  text,
  color = 'secondaryFeedback.greenDark',
  iconPath = icons.arrowRightLong,
  iconSize = 25,
  iconColor = 'secondaryFeedback.greenDark',
  ...rest
}): JSX.Element => {
  return (
    // @ts-ignore
    <Container flexDirection="row" flexWrap="no-wrap" {...rest}>
      <Icon mr={12} flexShrink={0} color={iconColor} size={iconSize} path={iconPath} />
      <Text variant={variant} value={text} color={color} />
    </Container>
  );
};

export default ListItem;
