import * as React from 'react';
import { Animated, Dimensions, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { PressEvent } from 'react-native/Libraries/Types/CoreEventTypes';

import { Icon, theme, Box, BodyPrimaryTextStyle } from '../core';

import { HighlightCoordinate, OverlayHighlightDefault, highlightElement } from './default';
import { getTooltipStyle } from './with-caption/coordinate-calculator';

const style = StyleSheet.create({
  caption: {
    color: 'white',
  },
});
const { width, height } = Dimensions.get('window');
const tooltip = {
  padding: 20,
  text: {
    dX: 115,
    dY: 60,
  },
  image: {
    dX: 25,
    dY_bottom: 25,
    dY_top: 80,
    width: 90,
    height: 60,
  },
};
type Translation = {
  key: string;
  values?: Record<string, string>;
};
type Props = {
  coordinate: HighlightCoordinate;
  radius?: number;
  fadeDuration?: number;
  children: React.ReactNode;
  caption: Translation;
  onClose?: any;
};
type State = {
  opacity: Animated.Value;
};

class OverlayHighlightWithCaption extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      opacity:
        props.fadeDuration !== undefined && props.fadeDuration > 0
          ? new Animated.Value(0)
          : new Animated.Value(1),
    };
  }

  componentDidMount = () => {
    const { fadeDuration = 0 } = this.props;

    if (fadeDuration > 0) {
      Animated.timing(this.state.opacity, {
        toValue: 1,
        duration: fadeDuration,
        useNativeDriver: true,
      }).start();
    }
  };

  handlePress = (event: PressEvent) => {
    const { fadeDuration = 0, coordinate, radius = highlightElement.RADIUS, onClose } = this.props;
    const { locationX, locationY } = event.nativeEvent;
    const dX = coordinate.x - locationX;
    const dY = coordinate.y - locationY;
    const pressedHighlight = Math.sqrt(dX * dX + dY * dY) <= radius;

    if (fadeDuration > 0) {
      Animated.timing(this.state.opacity, {
        toValue: 0,
        duration: fadeDuration,
        useNativeDriver: true,
      }).start(onClose ? onClose(pressedHighlight) : undefined);
    } else if (onClose) {
      onClose(pressedHighlight);
    }
  };

  render() {
    const {
      coordinate = {
        x: 0,
        y: 0,
      },
      radius,
      caption,
      children = null,
    } = this.props;
    const tooltipStyle = getTooltipStyle({
      screenWidth: width,
      screenHeight: height,
      x: coordinate.x,
      y: coordinate.y,
      imageDX: tooltip.image.dX,
      imageDYtop: tooltip.image.dY_top,
      imageDYbottom: tooltip.image.dY_bottom,
      imageWidth: tooltip.image.width,
      imageHeight: tooltip.image.height,
      textDX: tooltip.text.dX,
      textDY: tooltip.text.dY,
      padding: tooltip.padding,
    });
    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}
      >
        <TouchableWithoutFeedback onPress={this.handlePress}>
          <Animated.View
            style={{
              flex: 1,
              opacity: this.state.opacity,
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <OverlayHighlightDefault
                backgroundColor={theme.color.overlayDark}
                coordinate={{
                  x: coordinate.x,
                  y: coordinate.y,
                }}
                radius={radius}
              />
            </Box>
            <Box flex={1}>
              <>
                <Icon
                  name={`tooltipArrow.${tooltipStyle.image.position}`}
                  style={{
                    position: 'absolute',
                    ...tooltipStyle.image.style,
                  }}
                />
                <BodyPrimaryTextStyle
                  id={caption.key}
                  testId={caption.key}
                  values={caption.values}
                  style={[
                    style.caption,
                    {
                      position: 'absolute',
                      ...tooltipStyle.text.style,
                    },
                  ]}
                />
              </>
              {/* @ts-ignore */}
              <SafeAreaView flex={1}>{children}</SafeAreaView>
            </Box>
          </Animated.View>
        </TouchableWithoutFeedback>
      </Box>
    );
  }
}

export { OverlayHighlightWithCaption };
