/*
 * WARNING! if you change this file update the version of the document
 * in the file assets/docs/index.js
 */
export default `<style>
li {
  margin-left: 7px;
}
ul {
  background-color: unset;
}
</style>
<h2>Autodichiarazione ai fini antiriciclaggio</h1>
<ul>
  <li>
    Ai sensi dell’art. 22 del D.Lgs. 231/07, dichiari che i tuoi dati personali forniti a Taxfix tramite l’app/webapp (in particolare: nome, cognome, data di nascita, residenza, indirizzo email e numero di telefono) sono veritieri e ne autorizzi l’utilizzo ai fini del corretto adempimento degli obblighi di adeguata verifica Antiriciclaggio , assumendo tutte le responsabilità di natura civile, amministrativa e penale connesse al rilascio di detta dichiarazione. Dichiari, altresì, di essere a conoscenza che la mancata comunicazione di dati e informazioni necessarie ai fini dell’adeguata verifica Antiriciclaggio ovvero l’indicazione di dati falsi o informazioni non veritiere è punita con reclusione da sei mesi a tre anni e con la multa da 10.000 a 30.000 euro.
  </li>
  <li>
    Ai sensi dell’art.18, comma 1, lettera c), D.Lgs. 231/2007, dichiari che lo scopo e la natura della prestazione professionale richiesta sono la predisposizione e l’invio del modello 730/2023 redatto in osservanza degli obblighi fiscali e che tale attività non è richiesta per un soggetto persona fisica/giuridica diversa dal dichiarante.
  </li>
  <li>
    Ai sensi dell’art. 1, comma 2 lett. dd), D.Lgs. 231/07, dichiari di non costituire Persona Politicamente Esposta.
  </li>
  <li>
    Ai sensi dell’art. 19 comma 1, D.Lgs. 231/07, acconsenti che Taxfix proceda all’identificazione del Cliente utilizzando il documento di identita’ condiviso e i dati personali presenti sui documenti fiscali da te condivisi.
  </li>
</ul>
`;
