import { CountryCodes } from '@taxfix/types';

import { getNavigationActions } from 'src/routes/config-util';
import { StatusStepRedirect } from 'src/stores/modules/status';
import { actions as settingsActions } from 'src/stores/modules/settings';

export const redirectToNotifications: StatusStepRedirect = (state, dispatch) => (navigation) => {
  const userLang = state.settings.data.selectedLocale?.toUpperCase();

  navigation.reset({
    index: 0,
    actions: [
      getNavigationActions().toNotifications('screen', {
        image: userLang === CountryCodes.IT ? 'notificationsOnIt' : 'notificationsOnEn',
        floatingButton: true,
        navigateForward: () => {
          dispatch(settingsActions.updateAskedForNotificationPermissions(true));
          navigation.reset({
            index: 0,
            actions: [getNavigationActions().toStatus('screen')],
          });
        },
      }),
    ],
  });
};
