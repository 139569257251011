import React, { ReactElement, ComponentType } from 'react';
import { inject, useObserver } from 'mobx-react';

import { QuestionStore, useQuestionStore } from '../stores/hooks/mobx-stores-hook';
import { getDefaultTaxYear } from '../services/country-and-year/utils';
/* inject uses the legacy api from mobx-react and this is kept only for compatibility.
 * Please, avoid to use this HOC for new implementations.
 */

export type WithQuestionStore = {
  questionStore: QuestionStore;
};

export default inject((stores: any, { year }) => {
  if (!year) throw new Error('The year prop has not been passed');
  return {
    questionStore: stores.questionStores[year] || stores.questionStores[getDefaultTaxYear()],
  };
});
type OriginalProps = {
  year: number;
  [prop: string]: any;
};
export type { QuestionStore };

/* This HOC uses the mobx-react hooks for fetch the store and observe it.
 * This is preferable over the legacy version.
 */
export const withQuestionStore = (
  WrappedComponent: ComponentType<OriginalProps>,
): ComponentType<OriginalProps> => {
  const WithQuestionStoreWrapper = (
    props: OriginalProps,
  ): ReactElement<typeof WrappedComponent> => {
    if (!props.year) throw new Error('The year prop has not been passed');
    const questionStore = useQuestionStore(props.year);
    return useObserver(() => <WrappedComponent questionStore={questionStore} {...props} />);
  };

  return WithQuestionStoreWrapper;
};
