import React from 'react';
import { Box, Stack, useBreakpointValue } from 'native-base';
import { TouchableWithoutFeedback } from 'react-native';

import { useItalyIntl } from 'src/_italy/_hooks';
import { getImageAsset } from 'src/assets/image-assets';
import { AvatarGroup, Card, Container, Image, Text } from 'src/taxfix-components/src';
import { isIOS } from 'src/utils/platform';

import { CardButton } from './card-button';
import { ListItem } from './list-item';
import { RecommendationBadge } from './recommendation-badge';

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

const getImageComponent = (imageComponentKey: string) =>
  ({
    instantDeclaration: (
      <Image name="cellphoneConfirmTap" width="56px" height="56px" resizeMode="contain" />
    ),
    guidedDeclaration: (
      <Box marginTop="7px">
        <AvatarGroup
          avatarSources={[getImageAsset('commercialista.commercialistav2')]}
          avatarProps={{ size: '56px', borderWidth: 0 }}
        />
      </Box>
    ),
    guidedDeclarationExperiment: (
      <Image name="advancedSupport" width="56px" height="56px" resizeMode="contain" />
    ),
    premiumDeclaration: (
      <Image name="premiumVideoCall" width="56px" height="56px" resizeMode="contain" />
    ),
  }[imageComponentKey]);

export type ProductBundleCardProps = {
  title: string;
  subtitle?: string;
  price?: string;
  descriptionItems: string[];
  imageComponentKey: string;
  buttonLabel?: string;
  onPressCard?: () => void;
  recommended?: boolean;
  isSingleCard?: boolean;
  withTouchableSurface?: boolean;
};

export const ProductBundleCard: React.FC<ProductBundleCardProps> = ({
  title,
  subtitle,
  price,
  imageComponentKey,
  onPressCard,
  descriptionItems,
  buttonLabel,
  recommended,
  isSingleCard,
  withTouchableSurface = true,
}) => {
  const { getTranslationText } = useItalyIntl();

  const cardsHorizontalMargin = useBreakpointValue({ base: '24px', md: '0px' });
  const cardsFlex = useBreakpointValue({ md: 1 });
  const titleFont = useBreakpointValue({ base: 'titleXSBold', md: 'titleSBold' });

  const cardContent = (
    <Card
      flex={cardsFlex}
      mx={cardsHorizontalMargin}
      p={1}
      pb={isSingleCard ? '32px' : '20px'}
      backgroundColor="greytones.white"
      borderRadius="24px"
      borderColor="greytones.surface"
      borderStyle="solid"
      borderWidth="1px"
      cursor="pointer"
      alignSelf={{ md: 'flex-start' }}
      variant={isIOS ? 'elevation1' : 'elevation2'}
    >
      <Stack space={4}>
        <Container
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          ml="20px"
          marginBottom={{ md: '16px' }}
        >
          <Stack flex={1} space="4px">
            <Text variant={titleFont} value={getTranslationText(title)} color="greytones.title" />
            {price && (
              <Text variant="bodyMBook" value={getTranslationText(price)} color="greytones.title" />
            )}
            {subtitle && (
              <Text
                variant="bodySBook"
                value={getTranslationText(subtitle)}
                color="greytones.textLight"
              />
            )}
            {recommended && (
              <RecommendationBadge
                text={getTranslationText('it.product-bundle-screen.cards.recommendation-label')}
              />
            )}
          </Stack>
          {getImageComponent(imageComponentKey)}
        </Container>
        <Stack space="8px">
          {descriptionItems.map((descriptionItem) => (
            <ListItem
              key={descriptionItem}
              text={getTranslationText(descriptionItem, { bold: getBoldText })}
            />
          ))}
        </Stack>
        {buttonLabel && (
          <CardButton buttonLabel={getTranslationText(buttonLabel)} onPress={onPressCard} />
        )}
      </Stack>
    </Card>
  );

  if (withTouchableSurface) {
    return <TouchableWithoutFeedback onPress={onPressCard}>{cardContent}</TouchableWithoutFeedback>;
  }

  return cardContent;
};
