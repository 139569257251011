//TODO: moving this component to `taxfix-components`
import React from 'react';
import { useTheme, Divider, useBreakpointValue } from 'native-base';
import { TouchableOpacity, StyleSheet } from 'react-native';

import { Icon, icons, Container, Text } from 'src/taxfix-components/src';

import { TabItemProps } from './types';

const getTabIcon = (iconKey: string, isActive: boolean): string[] => {
  switch (iconKey) {
    case 'italy-tab-guide-icon':
      return isActive ? icons.guidanceSolid : icons.guidance;
    case 'italy-tab-commercialista-icon':
      return isActive ? icons.shieldCheckSolid : icons.shieldCheckOutlined;
    case 'italy-tab-account-icon':
      return icons.account;
    case 'italy-tab-tax-guide-icon':
      return isActive ? icons.guidanceSolid : icons.guidance;
  }
  return isActive ? icons.homeSolid : icons.home;
};

const styles = StyleSheet.create({
  tab: { alignContent: 'center', justifyContent: 'center' },
  webIcon: {
    padding: 5,
    height: 104,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabText: {
    textAlign: 'center',
    fontSize: 12,
    paddingVertical: 5,
  },
});

//TODO: We need to move TabItem component to taxfix-components
export const TabItem: React.FC<TabItemProps> = ({
  text,
  iconKey,
  isActive = false,
  isLargeScreen = false,
  isLastTab = false,
  onPress,
}) => {
  const { colors } = useTheme();
  const color = isActive ? colors.brand.brandGreen : colors.greytones.graphicsDark;
  const textVariant = useBreakpointValue({ base: 'bodyXSMedium', md: 'titleXXSMedium' });
  const iconPath = getTabIcon(iconKey, isActive);

  const borderstyle = {
    borderLeftColor: colors.brand.brandGreen,
    borderLeftWidth: 5,
  };

  return (
    <Container style={{ ...styles.tab, flex: isLargeScreen ? -1 : 1 }}>
      {isLargeScreen && <Divider my="8px" />}
      <TouchableOpacity
        onPress={onPress}
        style={[styles.webIcon, isLargeScreen && isActive && borderstyle]}
      >
        <Icon size={6} path={iconPath} color={color} />
        <Text value={text} color={color} textAlign="center" variant={textVariant} py={0} />
      </TouchableOpacity>
      {isLastTab && isLargeScreen && <Divider my="8px" />}
    </Container>
  );
};
