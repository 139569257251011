import React from 'react';
import { TextInput } from 'react-native';
import { KeyboardType } from 'react-native/Libraries/Components/TextInput/TextInput';
import type { NumberFormatOptions } from '@formatjs/ecma402-abstract';

import { testID } from 'src/common/testID';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { Box, theme } from 'src/components/core';
import { StyleProp } from 'src/components/types';
import Keyboards from 'src/components/answers/shared/Keyboards';

type Props = WithNavigation & {
  prefix?: string;
  suffix?: string;
  allowNegative?: boolean;
  onBlur?: (event: any) => any;
  onFocus?: (event: any) => any;
  onChange?: (event: any) => any;
  onChangeText?: (value: string) => any;
  onChangeValue?: (value: string) => any;
  value?: string | null | undefined;
  locale?: string;
  options?: NumberFormatOptions;
  Component?: any;
  keyboardType?: KeyboardType;
  autoFocus?: boolean;
  color?: string;
  editable?: boolean;
  placeholder?: string;
  testId?: string;
  maximumNumberOfDigits?: number;
  allowedMaximum?: number;
  style?: StyleProp | any;
  underlineColorAndroid?: any;
  icon?: (onPress: () => void) => React.ReactElement;
};
type State = {
  editing: boolean;
  value?: string | null | undefined;
};

class NumberInput extends React.Component<Props, State> {
  validationRegExp: any | null | undefined = null;

  outputSeparator = '.';

  localeSeparator: string = this.props.locale
    ? (0.1).toLocaleString(this.props.locale).replace(/\d/g, '')
    : '.';

  inputRef: TextInput | any;

  /**
   * Number is converted to scientific notation if >= 1e+21
   * Which is why we have set this as the allowed maximum to avoid
   * scientific notation
   *
   * @type {number}
   */
  allowedMaximum = 1e21;

  focusTimeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      editing: props.autoFocus ?? true,
      value: props.value,
    };
  }

  componentDidMount() {
    this.createValidationRegexp();
  }

  componentDidUpdate(prevProps: Props) {
    const { autoFocus, navigationState } = this.props;

    if (navigationState && prevProps.autoFocus !== autoFocus && autoFocus) {
      const { index, routes } = navigationState;

      if (routes[index]?.name) {
        const screenName = routes[index].name;
        if (screenName === 'screen/Questions') {
          this.focus();
        }
      }
    }
  }

  componentWillUnmount(): void {
    if (this.focusTimeout) {
      clearTimeout(this.focusTimeout);
    }
  }

  getValueObject = () => {
    const { value } = this.state;
    return value && this.isNumber(value)
      ? {
          value,
          local: this.toLocal(value),
          input: this.toInput(value),
        }
      : {
          value,
          local: '',
          input: value || '',
        };
  };

  createValidationRegexp = () => {
    const format = new Intl.NumberFormat(this.props.locale, this.props.options);
    const { maximumFractionDigits } = format.resolvedOptions();
    const templateRegex =
      '^\\-{0,:negatives:}((\\d*)|(\\d+\\:separator:\\d{0,:precision:}){0,:fractions:})$';
    const compiledRegex = templateRegex
      .replace(':negatives:', this.props.allowNegative === true ? '1' : '0')
      .replace(':fractions:', maximumFractionDigits === 0 ? '0' : '1')
      .replace(':precision:', !maximumFractionDigits ? '0' : maximumFractionDigits.toString())
      .replace(':separator:', this.localeSeparator);
    const regexp = new RegExp(compiledRegex);
    this.validationRegExp = regexp;
  };

  isNumber = (value: string) => !Number.isNaN(Number(value));

  isValidInput = (input: string) => {
    if (!input.length) {
      return true;
    }

    const allowedMaximum = this.props.allowedMaximum || this.allowedMaximum;

    if (Number(input) >= allowedMaximum) {
      return false;
    }

    if (!this.validationRegExp) {
      return false;
    }

    try {
      const res = this.validationRegExp.test(input);
      return res;
    } catch (error) {
      return false;
    }
  };

  replace = (value: string, separatorA: string, separatorB: string) =>
    value.split(separatorA, 2).join(separatorB);

  toInput = (value: string) => this.replace(value, this.outputSeparator, this.localeSeparator);

  toValue = (input: string) => this.replace(input, this.localeSeparator, this.outputSeparator);

  toLocal = (value: string) => {
    if (value === '') return '';
    let number = Number(value);

    if (this.props.options) {
      if (this.props.options.style) {
        if (this.props.options.style === 'percent') {
          number /= 100;
        }
      }
    }

    const string = new Intl.NumberFormat(this.props.locale, this.props.options).format(number);
    const prefix = this.props.prefix || '';
    const suffix = this.props.suffix || '';
    return [prefix, string, suffix].join('');
  };

  handleBlur = (event: any) => {
    this.setState(
      {
        editing: false,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
    );
  };

  handleFocus = (event: any) => {
    this.setState(
      {
        editing: true,
      },
      () => {
        if (this.props.onFocus) {
          this.props.onFocus(event);
        }
      },
    );
  };

  handleChange = (text: string) => {
    let input = text.replace('.', this.localeSeparator);
    input = input.replace(',', this.localeSeparator);
    // should allow fixing, when the starting value is not valid
    const currentValue = this.getValueObject().value || '';
    const isCurrentValueValid = this.isValidInput(currentValue);
    const isMakingCorrection =
      !isCurrentValueValid && this.isNumber(input) && currentValue.length > input.length;

    if (!this.isValidInput(input) && !isMakingCorrection) {
      return;
    }

    const value = this.toValue(input);
    // We can't avoid using state because we supports decimal input.
    // If the type of props.value is 'string' type, there wouldn't be problem.
    // but since it's number type there is no way to pass "1." down.
    this.setState({
      value,
    });
    this.handleChangeText(input);
    this.handleChangeValue(value);
  };

  handleChangeText = (input: string) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(input);
    }
  };

  handleChangeValue = (value: string) => {
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
  };

  focus = (): void => {
    if (this.inputRef) {
      this.focusTimeout = setTimeout(() => {
        this.inputRef.focus();
      }, 200);
    }
  };

  handleInputRef = (ref: TextInput | any): void => {
    if (ref) {
      this.inputRef = ref;
    }
  };

  render() {
    const {
      suffix,
      prefix,
      Component,
      onChangeText,
      onChangeValue,
      options,
      locale,
      value,
      autoFocus = true,
      color = theme.color.primary,
      editable = true,
      placeholder = (0.0).toLocaleString(locale, options),
      testId,
      maximumNumberOfDigits,
      style: passedStyle = {},
      keyboardType = Keyboards.WithDecimal,
      icon,
      ...props
    } = this.props;
    const { local, input } = this.getValueObject() || {};

    if (!Component) {
      return (
        <Box flex direction="row">
          <Box flex={1}>
            <TextInput
              keyboardType={keyboardType}
              placeholder={placeholder}
              ref={this.handleInputRef}
              autoFocus={autoFocus}
              value={this.state.editing ? input : local}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onChangeText={this.handleChange}
              selectionColor={color}
              editable={editable}
              maxLength={maximumNumberOfDigits}
              style={passedStyle}
              {...testID(testId)}
            />
          </Box>
          {icon && icon(() => this.inputRef?.focus())}
        </Box>
      );
    }

    return (
      <Component
        autoCompleteType="off"
        keyboardType={keyboardType}
        placeholder={placeholder}
        {...props}
        ref={this.handleInputRef}
        autoFocus={autoFocus}
        value={this.state.editing ? input : local}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChangeText={this.handleChange}
        selectionColor={color}
        editable={editable}
        maxLength={maximumNumberOfDigits}
        {...testID(testId)}
      />
    );
  }
}

export default withNavigation(NumberInput);
