import { prefillQuestionIds, questionFlowCategories } from 'src/common/constants-it';
import { getNavigationActions } from 'src/routes/config-util';
import { getAnswer } from 'src/stores-legacy/helpers';

import { StatusMiddleware, StatusStepRedirect } from '../../../stores/modules/status';

export const checkPreseasonQF: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const preseasonTaxId = getAnswer(getState(), prefillQuestionIds.preseasonTaxId);

  if (preseasonTaxId) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const redirectPreseasonQF: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toQuizMaster('screen', {
        root: questionFlowCategories.preseasonTaxId,
        editing: false,
        backNavigationText: 'web.navigation.italy.back',
        onNext: () => {
          navigation.reset({
            index: 0,
            actions: [getNavigationActions().toStatus('screen')],
          });
        },
      }),
    ],
  });
};
