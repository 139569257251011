/* eslint-disable global-require */
import { CountryCodes } from '@taxfix/types';

import { theme } from '../components/core';

import { headerStyles } from './header';

export const defaultScreenOptions = {
  cardStyle: {
    backgroundColor: theme.color.lightFill,
    flex: 1,
  },
  title: 'Taxfix',
  ...headerStyles,
};
export const defaultNestedScreenOptions = {
  ...defaultScreenOptions,
  headerStatusBarHeight: 0,
  headerShown: false,
};
export const defaultRootNestedScreenOptions = {
  footerShown: false,
};

export const getCountryDefaultScreenOptions = (country: CountryCodes) => {
  switch (country) {
    default:
      return defaultScreenOptions;
  }
};

export const getCountryConfigs = (country: string | null | undefined) => {
  const { navigationActionsWithHjTracking, screens } = require('./intl/it/config-it');

  return {
    navigationActions: navigationActionsWithHjTracking,
    screens,
  };
};
