import { CountryCodes } from '@taxfix/types';

export const SET_REFEREE_METADATA = 'referral-data/SET_REFEREE_METADATA';
export const DELETE_REFEREE_METADATA = 'referral-data/DELETE_REFEREE_METADATA';
export const REFEREE_CREATE_SUCCESS = 'referral-data/REFEREE_CREATE_SUCCESS';
export const DISABLE_FETCH_REFERRAL_VOUCHER = 'referral-data/DISABLE_FETCH_REFERRAL_VOUCHER';
export type RefereeMetadata = {
  referralCampaignName: string;
  referralInviteSentAt: string;
  referrerUserId: number;
  countryCode: CountryCodes;
};
export type State = {
  hasCreatedReferee: boolean;
  refereeMetadata: RefereeMetadata | null | undefined;
  isFetchReferralVoucherDisabled: boolean;
};
export const initial: State = {
  hasCreatedReferee: false,
  refereeMetadata: null,
  isFetchReferralVoucherDisabled: false,
};
type SetRefereeMetadataAction = {
  type: typeof SET_REFEREE_METADATA;
  payload: {
    refereeMetadata: RefereeMetadata;
  };
};
type DeleteRefereeMetadata = {
  type: typeof DELETE_REFEREE_METADATA;
};
type RefereeCreateSuccess = {
  type: typeof REFEREE_CREATE_SUCCESS;
};
type DisableFetchReferralVoucher = {
  type: typeof DISABLE_FETCH_REFERRAL_VOUCHER;
};
type Action =
  | SetRefereeMetadataAction
  | DeleteRefereeMetadata
  | RefereeCreateSuccess
  | DisableFetchReferralVoucher;

const setRefereeMetadata = (refereeMetadata: RefereeMetadata) => ({
  type: SET_REFEREE_METADATA,
  payload: {
    refereeMetadata,
  },
});

const deleteRefereeMetadata = () => ({
  type: DELETE_REFEREE_METADATA,
});

const refereeCreateSuccess = () => ({
  type: REFEREE_CREATE_SUCCESS,
});

const disableFetchReferralVoucher = () => ({
  type: DISABLE_FETCH_REFERRAL_VOUCHER,
});

export const actions = {
  setRefereeMetadata,
  deleteRefereeMetadata,
  refereeCreateSuccess,
  disableFetchReferralVoucher,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SET_REFEREE_METADATA:
      return { ...state, refereeMetadata: action.payload.refereeMetadata };

    case DELETE_REFEREE_METADATA:
      return { ...state, refereeMetadata: null };

    case REFEREE_CREATE_SUCCESS:
      return { ...state, hasCreatedReferee: true };

    case DISABLE_FETCH_REFERRAL_VOUCHER:
      return { ...state, isFetchReferralVoucherDisabled: true };

    default:
      return state;
  }
};
type RootState = {
  referralData: State;
};

const getRefereeMetadata = (state: RootState): RefereeMetadata | null | undefined =>
  state.referralData.refereeMetadata;

const hasCreatedReferee = (state: RootState): boolean => state.referralData.hasCreatedReferee;

const isFetchReferralVoucherDisabled = (state: RootState): boolean =>
  state.referralData.isFetchReferralVoucherDisabled;

export const selectors = {
  getRefereeMetadata,
  hasCreatedReferee,
  isFetchReferralVoucherDisabled,
};
