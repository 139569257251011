import React from 'react';

import NumberInput from '../../../components/number-input';
import { bodyPrimaryTextStyle, theme } from '../../../components/core';

type Props = {
  value: string;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  testId?: any;
};
export const NonProfitCodeInput = ({ value, onChange, onFocus, onBlur }: Props) => (
  <NumberInput
    onFocus={onFocus}
    onBlur={onBlur}
    onChangeValue={onChange}
    value={value}
    options={{
      maximumFractionDigits: 0,
      useGrouping: false,
    }}
    underlineColorAndroid="transparent"
    placeholder="00000000000"
    maximumNumberOfDigits={11}
    style={[
      bodyPrimaryTextStyle,
      {
        color: theme.color.disabledGray,
      },
    ]}
  />
);
