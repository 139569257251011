// TODO delete when we are able to work with multicolor icons
import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { Props } from './types';

/**
 * The Certidied Icon with several paths
 * Temporary component
 */

export const CertifiedIconSolid: React.FC<Props> = ({
  fillColor = '#000',
  size = '100%',
  ...rest
}): JSX.Element => (
  <Icon viewBox="0 0 20 20" size={size} {...rest}>
    <G fill="none">
      <Path
        d="M19.4441 9.96591C18.8505 9.35672 18.7181 8.42893 19.1175 7.67553C19.4027 7.13834 19.4223 6.49734 19.1715 5.94315C18.9205 5.38875 18.4271 4.98436 17.8377 4.84956C17.0112 4.66076 16.4026 3.95237 16.3348 3.10077C16.2866 2.49338 15.9594 1.94318 15.451 1.61379C14.9424 1.28439 14.3108 1.21299 13.7428 1.42099C12.946 1.71258 12.0544 1.44839 11.5408 0.768993C11.1742 0.284598 10.6042 0 9.99997 0C9.39558 0 8.82578 0.284597 8.45918 0.769193C7.94558 1.44839 7.05358 1.71258 6.25739 1.42099C5.68919 1.21299 5.05719 1.28439 4.54899 1.61379C4.04039 1.94318 3.71339 2.49318 3.66499 3.10077C3.59719 3.95237 2.9884 4.66056 2.1622 4.84956C1.5726 4.98436 1.0792 5.38875 0.828203 5.94335C0.577004 6.49754 0.597003 7.13834 0.882203 7.67573C1.2818 8.42913 1.1492 9.35672 0.555404 9.96611C0.131805 10.4009 -0.0663944 11.0099 0.0198053 11.6133C0.105805 12.2165 0.466004 12.7445 0.994002 13.0415C1.734 13.4575 2.1202 14.3099 1.9474 15.1461C1.8242 15.7425 1.9842 16.3629 2.38 16.8239C2.7758 17.2844 3.36219 17.5324 3.96559 17.4942C4.81139 17.4406 5.59319 17.9474 5.89599 18.7454C6.11239 19.3146 6.57958 19.749 7.15958 19.9208C7.73938 20.0926 8.36578 19.9814 8.85278 19.6204C9.53558 19.1146 10.4652 19.1146 11.1476 19.6204C11.635 19.9814 12.2608 20.0926 12.841 19.9208C13.4208 19.749 13.8882 19.3146 14.1044 18.7454C14.4074 17.9474 15.1894 17.4408 16.035 17.4942C16.6382 17.5324 17.2244 17.2844 17.6201 16.8239C18.0161 16.3629 18.1759 15.7427 18.0529 15.1461C17.8801 14.3097 18.2659 13.4575 19.0061 13.0415C19.5343 12.7447 19.8947 12.2165 19.9803 11.6133C20.0661 11.0097 19.8681 10.4007 19.4441 9.96591ZM8.34138 14.9123L4.08939 10.6237L5.89979 8.79812L8.34138 11.2605L13.9782 5.57715L15.7898 7.40214L8.34138 14.9123Z"
        fill={fillColor}
      />
      <Path
        d="M8.34134 14.9123L4.08936 10.6237L5.89975 8.79812L8.34134 11.2605L13.9781 5.57715L15.7897 7.40213L8.34134 14.9123Z"
        fill="white"
      />
    </G>
  </Icon>
);
