import React from 'react';
import { StyleSheet } from 'react-native';

import { WEB_SECTION_WIDE_WIDTH } from 'src/utils/constants';

import { isNative } from '../utils/platform';

import {
  SectionSize,
  BodySecondaryText,
  Box,
  DisplayTitleText,
  ProgressBar,
  Section,
  theme,
} from './core';
import { InformationButton } from './information-button';
import { FoldableFooter } from './footer';
import { useSmallScreenQuery } from './core/MediaQuery';
import { FooterOptions } from './footer.web';

const styles = StyleSheet.create({
  screen: {
    backgroundColor: theme.color.lightFill,
  },
});

type Props = {
  children?: React.ReactNode;
  flowProgress?: any;
  hideProgressBar?: boolean;
  headerTranslationKey: string;
  headerTranslationValues?: Record<string, any>;
  subheaderTranslationKey?: string;
  subheaderTranslationValues?: Record<string, any>;
  onPressInfo?: () => void;
  isContinueDisabled?: boolean;
  continueButtonTranslationKey?: string;
  onPressContinue: () => void;
  size?: SectionSize;
  centered?: boolean;
  isBackButtonHidden?: boolean;
  footerOptions?: FooterOptions;
};

const renderInfoIcon = (onPressInfo: any) => {
  if (onPressInfo) {
    return (
      <Box alignVertically="start">
        <InformationButton color={theme.color.primary} symbol="i" onPress={onPressInfo} />
      </Box>
    );
  }

  return null;
};

const SimpleQFLayout: React.FC<Props> = ({
  children,
  flowProgress = 0,
  hideProgressBar = false,
  headerTranslationKey,
  headerTranslationValues = {},
  subheaderTranslationKey,
  subheaderTranslationValues = {},
  onPressInfo,
  isContinueDisabled = false,
  continueButtonTranslationKey = 'answers.continue',
  onPressContinue,
  size = 'narrow',
  centered = false,
  isBackButtonHidden = false,
  footerOptions,
}: Props) => {
  const isSmallScreen = useSmallScreenQuery();
  return (
    <Box flex style={styles.screen}>
      {!hideProgressBar && <ProgressBar progress={parseFloat(flowProgress)} />}
      <Section size={size} centered={centered}>
        <Box
          flex
          top={isSmallScreen || isNative ? 2 : 9}
          left={isSmallScreen || isNative ? 2 : 0}
          right={isSmallScreen || isNative ? 2 : 0}
          style={{ maxWidth: WEB_SECTION_WIDE_WIDTH }}
        >
          {subheaderTranslationKey ? (
            <>
              <DisplayTitleText id={headerTranslationKey} values={headerTranslationValues} />
              <Box direction="row" top={1}>
                <Box flex>
                  <BodySecondaryText
                    id={subheaderTranslationKey}
                    values={subheaderTranslationValues}
                  />
                </Box>
                {renderInfoIcon(onPressInfo)}
              </Box>
            </>
          ) : (
            <Box direction="row">
              <Box flex>
                <DisplayTitleText id={headerTranslationKey} values={headerTranslationValues} />
              </Box>
              {renderInfoIcon(onPressInfo)}
            </Box>
          )}
          <Box flex>{children}</Box>
        </Box>
      </Section>
      <FoldableFooter
        isDisabled={isContinueDisabled}
        translationKey={continueButtonTranslationKey}
        onPress={onPressContinue}
        applyContentSeparation
        testId="answers.submit"
        isBackButtonHidden={isBackButtonHidden}
        footerOptions={footerOptions}
      />
    </Box>
  );
};

export { SimpleQFLayout };
