import { CountryCodes } from '@taxfix/types';

import { getNavigationActions } from '../../../../routes/config-util';
import { questionFlowCategories } from '../../../../common/constants-it';

import { DebugMenuOptions, MenuOption } from './types';

export function buildItalyQuizmasterOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions, userEmail, selectedCountry } = props;

  if (selectedCountry !== CountryCodes.IT) {
    return null;
  }

  const handleItalyQuizMaster = () => {
    const params = {
      onConfirm: async () => {
        navigationActions.reset({
          index: 1,
          actions: [
            getNavigationActions().toHome('screen'),
            getNavigationActions().toQuizMaster('screen', {
              root: questionFlowCategories.dependants,
              editing: false,
              onNext: () => navigationActions.toHome('screen'),
            }),
          ],
        });
      },
    };

    if (userEmail) {
      navigationActions.toLock('screen', params);
    } else {
      navigationActions.toLogin('screen', params);
    }
  };

  return {
    text: 'Italy In-season Question Flow',
    onPress: handleItalyQuizMaster,
    showArrow: true,
  };
}
