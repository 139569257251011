import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import {
  withInfoButtonTooltip,
  WithInfoButtonTooltipProps,
} from 'src/hocs/with-info-button-tooltip';
import { withNavigation, WithNavigation } from 'src/hocs/with-navigation';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import { selectors as prefillSelectors } from 'src/stores/modules/prefill';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { State as RootState } from 'src/stores/store/initial';

import { ButtonsContentProps, TrackingDocSubTypes, State } from './types';
import { IdentificationDocumentUploadComponent } from './component';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  prefillStatus: prefillSelectors.getStatus(state),
  isPreseason: getTaxSeasonInfo(state).isPreSeason,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = WithInfoButtonTooltipProps &
  WithNavigation &
  ReduxProps & {
    intl: IntlShape;
    overlayActions: typeof OverlayActions;
    backNavigationText: string;
    backNavigationRoute?(): void;
    onNext?(): void;
  };

class IdentificationDocumentUpload extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { selectedIdType: null };
  }

  uploadOptions = [
    {
      label: this.props.intl.formatMessage({ id: 'prefill.upload-documents.button.idCard' }),
      value: TrackingDocSubTypes.IdCard,
    },
    {
      label: this.props.intl.formatMessage({ id: 'prefill.upload-documents.button.passport' }),
      value: TrackingDocSubTypes.Passport,
    },
    {
      label: this.props.intl.formatMessage({ id: 'prefill.upload-documents.button.drivingId' }),
      value: TrackingDocSubTypes.DrivingId,
    },
  ];

  setIdType = (selectedIdType: TrackingDocSubTypes) => {
    this.setState({
      selectedIdType: selectedIdType,
    });
  };

  setNavigationActionDependingOnIdType = () => {
    const { navigationActions, onNext } = this.props;
    switch (this.state.selectedIdType) {
      case TrackingDocSubTypes.IdCard:
        return navigationActions.toIdCardUpload('screen', {
          onNext,
        });
      case TrackingDocSubTypes.Passport:
        return navigationActions.toPassportUpload('screen', {
          onNext,
        });
      case TrackingDocSubTypes.DrivingId:
        return navigationActions.toDrivingLicenseUpload('screen', {
          onNext,
        });
      default:
        return;
    }
  };

  openIDSelectInformation = () => {
    const { overlayActions } = this.props;
    overlayActions.show('IdentityUploadOverlay', {
      titleKey: 'identification-upload-documents.id-select.info-button.title',
      contentKey: 'identification-upload-documents.id-select.info-button.content',
    });
  };

  getButtonsContentProps = (): ButtonsContentProps => {
    const { hasSeenInfoButtonTooltip, onInfoButtonTooltipOpened, onInfoButtonTooltipClosed } =
      this.props;

    const buttonContinue = {
      title: this.props.intl.formatMessage({
        id: 'identity-upload.button.continue',
      }),
      testId: 'button::continue::upload',
      variant: 'primary',
      onPress: () => {
        Analytics.log(AnalyticsEvent.documentTypeChosen, {
          documentType: this.state.selectedIdType,
        });
        this.setNavigationActionDependingOnIdType();
      },
    } as const;

    return {
      infoButton: {
        handlePressed: this.openIDSelectInformation,
        shouldShowTooltip: !hasSeenInfoButtonTooltip,
        handleTooltipOpened: onInfoButtonTooltipOpened,
        handleTooltipClosed: onInfoButtonTooltipClosed(this.openIDSelectInformation),
      },
      buttons: { buttonContinue },
    };
  };

  render() {
    const { backNavigationText, navigationActions, backNavigationRoute, navigation } = this.props;

    return (
      <IdentificationDocumentUploadComponent
        backNavigationText={backNavigationText}
        uploadOptions={this.uploadOptions}
        onPressBackNavigation={
          backNavigationRoute ?? (navigation.canGoBack() ? navigationActions.back : undefined)
        }
        handleUploadOptionSelected={this.setIdType}
        selectedIdType={this.state.selectedIdType}
        {...this.getButtonsContentProps()}
      />
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IdentificationDocumentUploadContainer = compose<any>(
  withNavigation,
  injectIntl,
  withInfoButtonTooltip('prefill-id-select'),
  connector,
)(IdentificationDocumentUpload);
