import React, { useContext } from 'react';

import { localeInfoShape } from './types';

const getDisplayName = (Component: React.ElementType | any): string => {
  if (Component.displayName && typeof Component.displayName === 'string') {
    return Component.displayName;
  }

  if (Component.name && typeof Component.name === 'string') {
    return Component.name;
  }

  return 'Component';
};

type InjectLocaleProps = any;

interface ContextInterface {
  localeInfo: typeof localeInfoShape;
}

const injectLocaleInfo = (WrappedComponent: React.ElementType) => {
  const InjectLocale = (props: InjectLocaleProps): any => {
    const InjectLocaleContext = React.createContext<ContextInterface>({
      localeInfo: localeInfoShape,
    });
    const { localeInfo } = useContext<ContextInterface>(InjectLocaleContext);

    return <WrappedComponent {...props} localeInfo={localeInfo} />;
  };
  InjectLocale.displayName = `InjectLocale(${getDisplayName(WrappedComponent)})`;
  return InjectLocale;
};

export default injectLocaleInfo;
