import { ValidationErrors, ValidationResult, Error } from './types';

// TCO: https://flow.org/en/docs/types/functions/#toc-callable-objects
type ValidatorFunction = {
  (arg0: any): boolean;
  errors: Error[];
};

/**
 * The fact that it starts with "answers.address.detail." doesn't make sense.
 * TODO: make translations generic (ex. "ajv.validation.error.minLength" or
 * "schema.validation.error.minLength").
 */
const keywordToTranslation: any = {
  minLength: 'answers.address.detail.error.minLength',
  maxLength: 'answers.address.detail.error.maxLength',
  pattern: 'answers.address.detail.error.pattern',
  type: 'answers.address.detail.error.type',
  required: 'answers.error.required',
};

// legacyValidators are the ValidatorFunctions defined in @taxfix/answer-types
const withObjectPayload =
  (legacyValidator: ValidatorFunction) =>
  (input: any): ValidationResult => {
    legacyValidator(input);
    const errors = legacyValidator.errors || [];
    return {
      isValid: errors.length === 0,
      errors,
    };
  };

const mapDefaultValidationErrors = (errors: any): ValidationErrors =>
  errors.reduce((prevValue: any, error: any) => {
    const fieldKey = error.dataPath.split('/').pop();
    // eslint-disable-next-line no-param-reassign
    prevValue[fieldKey] = {
      errorKey: error.keyword,
      ...error.params,
    };
    return prevValue;
  }, {});

const mapDefaultValidationErrorsIT = (errors: Error[]): ValidationErrors =>
  errors.reduce((prevValue: Error, error: Error) => {
    const fieldKey = error.dataPath.split('/').pop();

    // we don't want to override already existing fieldKey error values
    // specific for postalCode - when validating it can have up to 3 errors at the same time (unlike other fields)
    // - type
    // - minLength
    // - pattern
    // in the order like above, if we override type or minLength with pattern we do not show the correct error message

    if (prevValue[fieldKey]) {
      return prevValue;
    } else {
      prevValue[fieldKey] = {
        errorKey: error.keyword,
        ...error.params,
      };
    }
    return prevValue;
  }, {});

/**
 * More generic approach on how to validate answer-types.
 */
export type ValidateLegacyResult = {
  isValid: boolean;
  errors: Record<
    string,
    {
      key: string;
      values?: Record<string, any>;
    }
  >;
};

const validateLegacyAnswerType =
  (legacyValidator: ValidatorFunction) =>
  (data: any): ValidateLegacyResult => {
    const validationResult = withObjectPayload(legacyValidator)(data);

    if (validationResult.isValid) {
      return {
        isValid: true,
        errors: {},
      };
    }

    const mappedErrors = mapDefaultValidationErrors(validationResult.errors);

    return {
      isValid: false,
      errors: Object.keys(mappedErrors).reduce((acc, key) => {
        const error = mappedErrors[key] as any;
        const { errorKey, ...rest } = error;
        const values: any = {};
        if (rest && rest.limit) values.number = rest.limit;

        if (error.missingProperty) {
          return {
            ...acc,
            [error.missingProperty]: {
              key: keywordToTranslation[error.errorKey],
              values,
            },
          };
        }

        return {
          ...acc,
          [key]: {
            key: keywordToTranslation[error.errorKey],
            values,
          },
        };
      }, {}),
    };
  };

export {
  withObjectPayload,
  mapDefaultValidationErrors,
  validateLegacyAnswerType,
  mapDefaultValidationErrorsIT,
};
