import { IPressableProps, Pressable } from 'native-base';
import * as React from 'react';

import { Text, Image } from 'src/taxfix-components/src';

import { StyledImageProps, StyledTextProps } from './types';

export const StyledText = ({ children, enable, ...rest }: StyledTextProps) => {
  return (
    <Text borderRadius={1} opacity={enable ? 1 : 0.2} {...rest}>
      {children}
    </Text>
  );
};

export const StyledImage = ({ enable, ...rest }: StyledImageProps) => {
  return <Image opacity={enable ? 1 : 0.2} {...rest} />;
};
export const StyledPressable = ({ ...rest }: IPressableProps) => {
  return (
    <Pressable
      style={{
        shadowColor: 'rgba(0, 0, 0, 1)',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3,
        backgroundColor: 'white',
        elevation: 4,
      }}
      {...rest}
    />
  );
};
