import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { useBreakpointValue } from 'native-base';

import { Container } from 'src/taxfix-components/src';
import { Place } from 'src/types/geo';
import { AddressDetailsConfig } from 'src/types/address';
import { isWeb } from 'src/utils/platform';
import { ItalySearch } from 'src/_italy/quizmaster/address-question/address-search';

import DetailAddressViewContainer from '../../../screens/containers/detail-address-view';
import { getPlace } from '../../../services/google-maps-geocode';
import PreviousPlaces from '../../../screens/containers/previous-places';
import { Geocoder } from '../../geocoder';
import PlacesAutocomplete from '../../places-autocomplete';
import { selectors as settingsSelectors } from '../../../stores/modules/settings';
import { fetchSuggestions, placesSearchResultType } from '../../../services/google-maps-places';
import {
  orderAddressBasedOnConfig,
  parseStreetTypeFromAddress,
} from '../../../services/address-in-italy';
import { Footer } from '../../../components/footer';

import { buildEmptyPlace } from './universal-address-search';

type Props = {
  color?: string;
  onAnswer: (params: Place) => void;
  isGlobal?: boolean;
  detailsConfig: AddressDetailsConfig;
  defaultSearchTerm?: string;
  resultType?: placesSearchResultType;
  hidePreviousPlaces?: boolean;
};

type PlaceDetails = {
  place: Place;
  isValid: boolean;
};

const emptyPlace = buildEmptyPlace(CountryCodes.IT);

export const SearchInItalyContainer = ({
  onAnswer,
  color,
  detailsConfig,
  defaultSearchTerm = '',
  resultType,
  hidePreviousPlaces = false,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm);
  const [error, setError] = useState<string>('');
  const [placeDetails, setPlaceDetails] = useState<PlaceDetails | null | undefined>();
  const language = useSelector(settingsSelectors.selectedLocale) as string;
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const handleChange = (text: string) => {
    setSearchTerm(text);
    setError('');
  };

  const continueToDetails = (rawPlace?: Place) => {
    const place = rawPlace
      ? {
          ...rawPlace,
          address: orderAddressBasedOnConfig(
            {
              ...rawPlace.address,
              streetType: parseStreetTypeFromAddress(rawPlace.address?.street),
            },
            detailsConfig.fields,
          ),
        }
      : emptyPlace;

    setPlaceDetails({
      place,
      isValid: false,
    });
  };

  const handlePressResult = (place: Place) => {
    continueToDetails(place);
  };

  const handlePressManual = () => {
    continueToDetails();
  };

  const handlePressResultGeocode = (geocodeOnSelect: any) => (suggestion: any) => {
    geocodeOnSelect(suggestion.name).catch(setError);
  };

  const handlePressSubmitOnDetails = () => (placeDetails ? onAnswer(placeDetails.place) : null);

  const handleGoBackFromDetails = () => setPlaceDetails(null);

  return placeDetails ? (
    <Container>
      <DetailAddressViewContainer
        addressValidator={detailsConfig.addressValidator}
        place={placeDetails.place}
        fields={detailsConfig.fields}
        onChange={(isValid: any, place: any) => {
          setPlaceDetails({
            place,
            isValid,
          });
        }}
        isValidAddress={placeDetails.isValid}
        handleOnContinePress={handlePressSubmitOnDetails}
      />
      {!isSmallScreen && (
        <Footer
          translationKey="answers.submit"
          isDisabled={!placeDetails.isValid}
          onPress={handlePressSubmitOnDetails}
          onGoBack={() => {
            handleGoBackFromDetails();
          }}
        />
      )}
    </Container>
  ) : (
    <Container>
      <PreviousPlaces
        searchTerm={searchTerm}
        render={(previousPlacesResults: { places: Place[] }) => (
          <PlacesAutocomplete
            searchTerm={searchTerm}
            fetchSuggestions={fetchSuggestions({
              language,
              countryCode: CountryCodes.IT,
              resultType,
            })}
            render={(suggestionResult, errorKey) => {
              const searchError = errorKey || error;
              return (
                <Geocoder
                  onSelect={handlePressResult}
                  country={CountryCodes.IT}
                  language={language}
                  getPlace={getPlace}
                  render={(geocodeOnSelect) => (
                    <ItalySearch
                      color={color}
                      previousPlaces={hidePreviousPlaces ? [] : previousPlacesResults.places}
                      searchResults={suggestionResult}
                      onChange={handleChange}
                      onPressManual={handlePressManual}
                      onPressPrevious={handlePressResult}
                      onPressResult={handlePressResultGeocode(geocodeOnSelect)}
                      value={searchTerm}
                      error={searchError}
                    />
                  )}
                />
              );
            }}
          />
        )}
      />
      {isWeb && <Footer />}
    </Container>
  );
};
