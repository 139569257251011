import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { InstantFilingNextStepsContainer } from './container';

export const toInstantFilingNextStepsScreen: Action = navigate(ScreenName.InstantFilingNextSteps, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.InstantFilingNextSteps,
  },
});

export const InstantFilingNextStepsScreenRoute = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'it.instant-filing-next-steps.nav.title' });

  return <InstantFilingNextStepsContainer />;
};
