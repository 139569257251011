import { $Keys, $Values } from 'utility-types';

import { ErrorKeyword, ValidationResult } from 'src/components/answers/shared/validators/types';

import { Place } from './geo';

export const AddressDetailsKeys = Object.freeze({
  Addition: 'addition',
  Street: 'street',
  StreetType: 'streetType',
  Number: 'number',
  NumberExtension: 'numberExtension',
  PostalCode: 'postalCode',
  City: 'city',
  Country: 'country',
  State: 'state',
});

export type AddressDetailsKey = $Keys<typeof AddressDetailsKeys>;

export type AddressDetailsValue = $Values<typeof AddressDetailsKeys>;

export type AddressDetailsFieldWithValue = {
  id: AddressDetailsValue;
  editable: boolean;
  translationKey: string;
  value: string;
  errors?: Record<ErrorKeyword, string>;
};

export type AddressDetailsField = {
  id: AddressDetailsValue;
  editable: boolean;
  errors?: Record<ErrorKeyword, string>;
};

export type AddressDetailsConfig = {
  addressValidator: (place: Place) => ValidationResult;
  fields: AddressDetailsField[];
  standalone?: boolean;
};
