import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { getRoute } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { DeleteAccount } from '../component/delete-account';

const DeleteAccountScreenIT = () => {
  return <DeleteAccount />;
};

export const { action: toDeleteAccountIT, screen: deleteAccountScreenIT } = getRoute({
  routeName: ScreenName.ItalyDeleteAccountScreen,
  tracking: {
    name: AnalyticsEvent.sectionShown,
    params: {
      sectionName: ScreenName.ItalyDeleteAccountScreen,
    },
  },
  screenComponent: DeleteAccountScreenIT,
});

export { DeleteAccountScreenIT };
