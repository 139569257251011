import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const PersonalInfoIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#E1F8E6" />
    <Path
      d="M26.502 38.8535C27.0359 39.1705 27.8144 39.3258 28.409 39.5118C28.7259 39.6111 29.7155 39.6713 29.8526 39.968C29.9909 40.2681 29.4856 40.6029 29.6926 40.869C29.8811 41.1114 30.4764 40.9131 30.73 40.914C31.7489 40.9179 31.2722 41.1788 31.7032 41.8958C32.1894 42.7042 33.7698 42.3934 34.5665 42.5376C34.5732 43.2272 35.0591 43.442 35.6556 43.5907"
      stroke="#3B3B3B"
      stroke-width="0.745887"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6764 31.5553L27.8931 36.4181L29.609 37.3994L44.758 33.2568L45.6764 31.5553Z"
      fill="white"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6061 46.6945L18.5974 46.903L18.3932 17.3054L45.4019 17.0967L45.6061 46.6945Z"
      fill="#F2F2F2"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6061 46.6945L18.5974 46.903L18.3932 17.3054L45.4019 17.0967L45.6061 46.6945Z"
      fill="white"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6061 46.6945L18.5974 46.903L18.3932 17.3054L45.4019 17.0967L45.6061 46.6945Z"
      stroke="#F6F6F6"
      stroke-width="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.9554 41.5345L21.1766 41.7028L21.0261 19.8963L42.8049 19.728L42.9554 41.5345Z"
      fill="white"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.233 29.9767C29.2432 31.4751 30.4119 32.6809 31.8433 32.6699C33.2745 32.6587 34.4262 31.4353 34.4161 29.9366C34.4055 28.4383 33.2371 27.2326 31.8058 27.2436C30.3746 27.2546 29.2229 28.4782 29.233 29.9767Z"
      fill="#FBD144"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.233 29.9767C29.2432 31.4751 30.4119 32.6809 31.8433 32.6699C33.2745 32.6587 34.4262 31.4353 34.4161 29.9366C34.4055 28.4383 33.2371 27.2326 31.8058 27.2436C30.3746 27.2546 29.2229 28.4782 29.233 29.9767Z"
      stroke="#3B3B3B"
      stroke-width="0.621573"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1091 30.3221C30.1091 30.3221 31.3216 29.9613 31.8751 28.2306C31.8751 28.2306 32.2215 29.7069 33.652 30.3264L34.3999 30.7428L34.4831 29.0656C34.4831 29.0656 33.9644 27.0123 31.8037 26.9147C31.8037 26.9147 29.5762 26.9286 29.2483 28.7867L29.2429 30.5395L30.1091 30.3221Z"
      fill="#8C6239"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1091 30.3221C30.1091 30.3221 31.3216 29.9613 31.8751 28.2306C31.8751 28.2306 32.2215 29.7069 33.652 30.3264L34.3999 30.7428L34.4831 29.0656C34.4831 29.0656 33.9644 27.0123 31.8037 26.9147C31.8037 26.9147 29.5762 26.9286 29.2483 28.7867L29.2429 30.5395L30.1091 30.3221Z"
      stroke="#3B3B3B"
      stroke-width="0.621573"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      d="M31.4223 31.0203C31.4239 31.2685 31.6261 31.468 31.8738 31.466C32.1214 31.4643 32.3205 31.2616 32.3187 31.0134"
      stroke="#3B3B3B"
      stroke-width="0.621573"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0475 23.0712C23.0546 24.1231 23.9122 24.9694 24.9628 24.9612C26.0134 24.9533 26.8593 24.0939 26.852 23.0418C26.8449 21.99 25.9871 21.1437 24.9365 21.1517C23.886 21.1601 23.04 22.0194 23.0475 23.0712Z"
      fill="white"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1629 32.5084C30.1629 32.5084 28.3168 32.5226 28.3413 36.0869L29.3992 36.57L29.7909 35.5415L29.4126 41.5199L34.802 41.4372L34.0616 35.5082L34.2394 36.0416L35.7409 35.5369C35.7409 35.5369 34.9888 32.9502 33.4683 32.3669C33.4683 32.3669 31.0849 33.2534 30.1629 32.5084Z"
      fill="#FCEBE6"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1634 32.5084C30.1634 32.5084 28.3174 32.5226 28.3418 36.0869L29.3998 36.57L29.7915 35.5416L29.4131 41.5199L34.8026 41.4372L34.0621 35.5083L34.24 36.0416L35.7415 35.5369C35.7415 35.5369 34.6677 33.0918 33.1472 32.5084C33.1472 32.5084 31.0855 33.2534 30.1634 32.5084Z"
      stroke="#3B3B3B"
      stroke-width="0.621573"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.4687 35.8675L35.9687 41.3675L34.9688 41.3675L34.6567 36.1029L35.4687 35.8675Z"
      fill="#FBD144"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.4692 35.8671L35.9692 41.3671L34.9692 41.3671L34.4691 36.1025L35.4692 35.8671Z"
      stroke="#3B3B3B"
      stroke-width="0.621573"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.4688 36.3672L27.9687 41.3672L28.9687 41.3672L29.4687 36.8672L28.4688 36.3672Z"
      fill="#FBD144"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.4688 36.3672L27.9687 41.3672L28.9687 41.3672L29.4687 36.8672L28.4688 36.3672Z"
      stroke="#3B3B3B"
      stroke-width="0.745887"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.2953 39.607L26.4893 36.5273L26.2953 39.607Z"
      fill="#FBD144"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.9554 41.5345L21.1766 41.7028L21.0261 19.8963L42.8049 19.728L42.9554 41.5345Z"
      stroke="#32C850"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </Icon>
);
