import { extendTheme } from 'native-base';
import { DefaultTheme } from 'styled-components';

import { ButtonComponentVariants } from '../../components/general/Button';
import { TextComponentVariants } from '../../components/general/Text';

import { colors } from './colors/colors';
import { Colors } from './colors/colorsTypes';
import { getFontConfig, NBFonts } from './fonts/font';
import { FontFamily, FontSize, FontWeight, LetterSpacing, LineHeight } from './fonts/fontTypes';

export interface Themes {
  colors: Colors;
  fontSize: FontSize;
  fontFamily: FontFamily;
  lineHeight: LineHeight;
  fontWeight: FontWeight;
  letterSpacing: LetterSpacing;
  borders: {
    xs: number;
    s: number;
    m: number;
    l: number;
    xl: number;
  };
  spacing: number[];
  sizes: {
    s: string;
    m: string;
    l: string;
    xl: string;
  };
  boxShadow: {
    elevation1: string;
    elevation2: string;
    elevation3: string;
    elevation4: string;
    sticky1: string;
    sticky2: string;
    folder: string;
  };
}

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Themes {}
}

export const theme: DefaultTheme = {
  colors,
  spacing: [8, 16, 24, 32, 40, 48],
  ...getFontConfig(),
  //TODO: Define better sizes and borders after alignment with designers and include it in the theme in a better way

  sizes: {
    s: '25%',
    m: '50%',
    l: '75%',
    xl: '100%',
  },
  borders: {
    xs: 1,
    s: 1.5,
    m: 3,
    l: 4,
    xl: 5, //not defined in design but used in layout
  },
  boxShadow: {
    elevation1: '2px 4px 8px rgba(0, 0, 0, 0.08)',
    elevation2: '2px 4px 15px rgba(0, 0, 0, 0.12)',
    elevation3: '2px 8px 24px rgba(0, 0, 0, 0.16)',
    elevation4: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    sticky1: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    sticky2: '2px 0px 16px rgba(0, 0, 0, 0.12)',
    folder: '0px 4px 150px rgba(17, 94, 33, 0.1)',
  },
};

export type Theme = typeof theme;
export type ThemeProps = { theme: Theme };

export const NBTheme = extendTheme({
  colors,
  space: [8, 16, 24, 32, 40, 48],
  borderWidths: {
    xs: 1,
    s: 1.5,
    m: 3,
    l: 4,
    xl: 5, //not defined in design but used in layout
  },
  sizes: {
    s: '25%',
    m: '50%',
    l: '75%',
    xl: '100%',
  },
  shadows: {
    // elevation1: '2px 4px 8px rgba(0, 0, 0, 0.08)',
    // elevation2: '2px 4px 15px rgba(0, 0, 0, 0.12)',
    // elevation3: '2px 8px 24px rgba(0, 0, 0, 0.16)',
    // elevation4: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    // sticky1: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    // sticky2: '2px 0px 16px rgba(0, 0, 0, 0.12)',
    // folder: '0px 4px 150px rgba(17, 94, 33, 0.1)',

    //TODO: create rest of shadows
    //Please review it once again
    elevation1: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 2,
        height: 4,
      },
      shadowOpacity: 0.08,
      shadowRadius: 8,
      elevation: 3,
    },
    elevation2: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 2,
        height: 4,
      },
      shadowOpacity: 0.12,
      shadowRadius: 15,
      elevation: 3,
    },
    elevation3: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 2,
        height: 8,
      },
      shadowOpacity: 0.16,
      shadowRadius: 24,
      elevation: 3,
    },
    elevation4: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.15,
      shadowRadius: 4,
      elevation: 4,
    },
    sticky1: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: 4,
      elevation: 3,
    },
    sticky2: {
      shadowColor: '#000000',
      shadowOffset: {
        width: 2,
        height: 0,
      },
      shadowOpacity: 0.12,
      shadowRadius: 16,
      elevation: 3,
    },
    folder: {
      shadowColor: '#115e21',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.1,
      shadowRadius: 150,
      elevation: 3,
    },
  },

  //TODO: add borderStyles
  borderStyles: {
    solid: 'solid',
    dashed: 'dashed',
  },
  //Added radii for border radius
  radii: [8, 16, 24, 32, 40, 48],
  ...NBFonts,
  components: {
    FormControlLabel: {
      baseStyle: (props: Record<string, any>) => {
        const { isInvalid } = props;
        return {
          p: 0,
          pl: '0px',
          mb: '0px',
          _text: {
            color: !isInvalid ? 'greytones.graphicsDark' : 'secondaryCategory.pinkFull',
            fontSize: 'xs',
            fontWeight: 'book',
          },
        };
      },
    },
    FormControlErrorMessage: {
      baseStyle: {
        p: 0,
        pl: '0px',
        mt: '0px',
        _text: {
          fontSize: 'xs',
          color: 'secondaryCategory.pinkFull',
          fontWeight: 'book',
        },
      },
    },
    Input: {
      baseStyle: {
        p: 0,
        fontSize: 'xs',
        _text: {
          fontWeight: 'book',
        },
      },
      defaultProps: {
        variant: 'underlined',
        placeholderTextColor: 'greytones.graphicsDark',
        borderRadius: '0px',
      },
      variants: {
        underlined: {
          borderRadius: '0px',
          bg: 'greytones.background',
          borderBottomWidth: '2',
          _focus: {
            borderColor: 'greytones.textDefault',
            _invalid: {
              borderColor: 'secondaryCategory.pinkFull',
            },
          },
          _invalid: {
            borderColor: 'secondaryCategory.pinkFull',
            bg: 'secondaryCategory.salmonHalf',
          },
        },
      },
    },
    ...TextComponentVariants,
    ...ButtonComponentVariants,
  },
});

export type ItalyThemeType = typeof NBTheme;

declare module 'native-base' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ICustomTheme extends ItalyThemeType {}
}
