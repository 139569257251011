import * as React from 'react';

import { useSkipCodePushUpdate } from 'src/hooks/skip-code-push-hook';

import { Routes } from '../../routes/routes';
import { NavigationParams, Navigation, getRoute } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { NavigationParams as ActionParams } from './otc-validation.types';
import { OtcValidationConnectedContainer as OtcValidationContainer } from './otc-validation-container';

type Props = Navigation<
  Routes.OtcValidation,
  {
    [Routes.OtcValidation]: NavigationParams<ActionParams>;
  }
>;

const OtcValidationScreen: React.FC<Props> = ({ route }) => {
  const { emailAddress, userId, onConfirm } = route.params;
  useDefaultNavigationOptions({ id: 'account.otc-verification.header' });

  // This is necessary to avoid a code push update
  // to be installed while users navigate to their
  // email client to get the OTC code and comeback to the app.
  // This to avoid users to have to login again and receive another OTC code.
  useSkipCodePushUpdate();

  return (
    <OtcValidationContainer emailAddress={emailAddress} userId={userId} onConfirm={onConfirm} />
  );
};

export const { action: toOtcValidation, screen: otcValidationScreen } = getRoute<ActionParams>({
  routeName: Routes.OtcValidation,
  screenComponent: OtcValidationScreen,
});

export default OtcValidationScreen;
