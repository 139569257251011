/* eslint-disable react/no-did-mount-set-state */
import * as React from 'react';
import { connect } from 'react-redux';
import { getVersion } from 'react-native-device-info';

import { isAuthorizedDevice } from 'src/services/device';

import { RootContainer } from '../containers/root';
import { checkShouldForceUpdate } from '../services/force-update';
import { AppBlockingScreenContainer } from '../screens/app-blocking-screen/app-blocking-screen-container';
import { selectors as remoteConfigFirebaseSelectors } from '../stores/modules/remote-config-firebase';

import { Loading } from './loading';

type Props = {
  children: any;
  minimumAppVersion: string;
  isMaintenanceEnabled: boolean;
};
type State = {
  appVersion: string | null | undefined;
  isLoading: boolean;
  isDeviceAuthorized: boolean;
};

const mapStateToProps = (state: any) => ({
  minimumAppVersion: remoteConfigFirebaseSelectors.getMinimumVersion(state),
  isMaintenanceEnabled: remoteConfigFirebaseSelectors.isMaintenanceEnabled(state),
});

class AppInitChecksComponent extends React.Component<Props, State> {
  state = {
    appVersion: null,
    isLoading: true,
    isDeviceAuthorized: true,
  };

  async componentDidMount() {
    const isDeviceAuthorized = await isAuthorizedDevice();
    this.setState({
      appVersion: getVersion(),
      isLoading: false,
      isDeviceAuthorized,
    });
  }

  render() {
    const { appVersion, isLoading, isDeviceAuthorized } = this.state;
    const { children, minimumAppVersion, isMaintenanceEnabled } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (!isDeviceAuthorized) {
      return (
        // @ts-ignore
        <RootContainer>
          <AppBlockingScreenContainer variant="unauthorized" />
        </RootContainer>
      );
    }

    if (appVersion && minimumAppVersion && checkShouldForceUpdate(appVersion, minimumAppVersion)) {
      return (
        // @ts-ignore
        <RootContainer>
          <AppBlockingScreenContainer variant="forceUpdate" />
        </RootContainer>
      );
    }

    if (isMaintenanceEnabled) {
      return (
        // @ts-ignore
        <RootContainer>
          <AppBlockingScreenContainer variant="maintenance" />
        </RootContainer>
      );
    }

    return children;
  }
}
// @ts-ignore
export const AppInitChecks = connect(mapStateToProps)(AppInitChecksComponent);
