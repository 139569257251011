import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useIntl } from 'react-intl';
import { useTheme } from 'native-base';

import { Section } from 'src/components/core';
import { isAndroidWeb } from 'src/utils/platform';

type URI = {
  uri: string;
};
type Props = {
  source: URI;
  fileName?: string;
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  downloadButton: {
    fontFamily: 'CircularStd-Book',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    color: 'white',
    borderRadius: 8,
  },
});

export function PdfContainer({ source: { uri }, fileName }: Props) {
  const intl = useIntl();
  const { colors } = useTheme();

  return (
    <Section size="wide" centered style={{ height: '100%' }}>
      <View style={styles.container}>
        {isAndroidWeb ? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <a
              href={uri}
              download={fileName}
              style={{
                ...styles.downloadButton,
                padding: '0 12px',
                backgroundColor: colors.brand.brandGreen,
                textDecoration: 'none',
              }}
            >
              {intl.formatMessage({
                id: 'web.pdf.download',
              })}
            </a>
          </View>
        ) : (
          <iframe title="PDF" src={uri} width="100%" height="100%" />
        )}
      </View>
    </Section>
  );
}
