import { getHexColorFromTheme, lightenDarkenColor } from './utils';

const SECONDARY_INACTIVE_BORDER_WIDTH = 's';

const defaultStyle = {
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: 0,
};

const getPrimaryStyles =
  (color: string, whiteButton = false) =>
  ({ theme, disabled }: any) => {
    const hexColor = getHexColorFromTheme(color, theme.colors);
    const textColor = disabled
      ? 'greytones.graphicsDark'
      : !whiteButton
      ? 'greytones.white'
      : 'brand.brandGreen';

    return {
      backgroundColor: disabled ? 'greytones.graphicsLight' : color,
      _text: {
        color: textColor,
      },
      _hover: {
        backgroundColor: lightenDarkenColor(hexColor, -8),
      },
      _pressed: {
        backgroundColor: lightenDarkenColor(hexColor, -16),
      },
    };
  };

const getSecondaryStyles =
  (color: string, whiteButton = false) =>
  ({ theme, disabled }: any) => {
    const hexColor = getHexColorFromTheme(color, theme.colors);
    const disabledColor = whiteButton ? 'greytones.graphicsDark' : 'greytones.graphicsLight';

    return {
      borderWidth: SECONDARY_INACTIVE_BORDER_WIDTH,
      borderColor: disabled ? disabledColor : color,
      _text: {
        color: disabled ? disabledColor : color,
      },
      _hover: {
        backgroundColor: color,
        borderColor: 'transparent',
        _text: {
          color: whiteButton ? 'brand.brandGreen' : 'greytones.white',
        },
      },
      _pressed: {
        backgroundColor: lightenDarkenColor(hexColor, -8),
        _text: {
          color: whiteButton ? 'brand.brandGreen' : 'greytones.white',
        },
      },
    };
  };

const getTertiaryStyles =
  (color: string, whiteButton = false) =>
  ({ disabled }: any) => {
    const disabledColor = whiteButton ? 'greytones.graphicsDark' : 'greytones.graphicsLight';

    return {
      _text: {
        color: disabled ? disabledColor : color,
      },
      _hover: {
        backgroundColor: whiteButton ? 'transparencyLight.light04' : 'transparencyDark.dark04',
      },
      _pressed: {
        backgroundColor: whiteButton ? 'transparencyLight.light08' : 'transparencyDark.dark08',
      },
    };
  };

export const ButtonComponentVariants = {
  Button: {
    baseStyle: {
      ...defaultStyle,
    },

    variants: {
      primary: getPrimaryStyles('brand.brandGreen'),
      primaryInactive: {
        backgroundColor: 'greytones.graphicsLight',
        _text: {
          color: 'greytones.white',
        },
      },
      primaryWhite: getPrimaryStyles('greytones.white', true),
      primaryAlarm: getPrimaryStyles('secondaryCategory.pinkFull'),
      secondary: getSecondaryStyles('brand.brandGreen'),
      secondaryInactive: {
        borderWidth: SECONDARY_INACTIVE_BORDER_WIDTH,
        borderColor: 'greytones.graphicsLight',
        _text: {
          color: 'greytones.graphicsLight',
        },
      },
      secondaryWhite: getSecondaryStyles('greytones.white', true),
      tertiary: getTertiaryStyles('brand.brandGreen'),
      tertiaryWhite: getTertiaryStyles('greytones.white', true),
      tertiaryAlarm: getTertiaryStyles('secondaryCategory.pinkFull'),
    },
  },
};
