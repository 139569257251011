import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { openCamera, openGallery } from '../../services/camera-upload-documents';
import { actions as OverlayActions } from '../../stores/modules/overlay';
import { useNavigation } from '../../hooks/navigation-hook';
import { NativeFileResponse, UploadOverlayButtons } from '../id-document-upload/types';

import { useItalyIntl } from './use-italy-intl';

type Props = {
  onReceiveMobileAssets(response: NativeFileResponse | NativeFileResponse[] | null): void;
  onOpenFileBrowser(): void;
};

export const useOverlayButtons = ({
  onReceiveMobileAssets,
  onOpenFileBrowser,
}: Props): UploadOverlayButtons => {
  const dispatch = useDispatch();
  const overlayActions = React.useMemo(
    () => bindActionCreators(OverlayActions, dispatch),
    [dispatch],
  );
  const { navigationActions } = useNavigation();

  const { intl } = useItalyIntl();

  return [
    {
      translationKey: 'requiredDocument.lead.capturePhoto',
      onPress: () => {
        overlayActions.hide();
        openCamera(navigationActions, (response) => onReceiveMobileAssets(response));
      },
    },
    {
      translationKey: 'requiredDocument.lead.uploadDocument',
      onPress: onOpenFileBrowser,
    },
    {
      translationKey: 'requiredDocument.lead.photoGallery',
      onPress: () => {
        openGallery(
          intl,
          (response) => {
            onReceiveMobileAssets(response);
          },
          null,
          false,
        );
      },
    },
  ];
};
