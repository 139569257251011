import { Responses } from '@taxfix/quizmaster/dist/types';

import {
  WHAT_ARE_YOU_DOING,
  DID_YOU_GET_ANY_OTHER_INCOME,
  WHAT_DO_YOU_DO_FOR_LIFE,
} from 'src/taxfix-business-logic/constants/question-answer';

//script to migrate answers due to editor-data changes
export function migrateResponses(responses: Responses): void {
  const USER_INDEX = '[0]';
  const PARTNER_INDEX = '[1]';

  const EMPLOYEE_OPTION = { old: 'EMPLOYEE', new: 'Salary' };
  const MINIJOB_OPTION = { old: 'MINIJOB', new: 'Salary' };
  const STUDENT_OPTION = { old: 'STUDENT', new: 'Studying' };
  const IN_TRAINING_OPTION = { old: 'IN_TRAINING', new: 'Training' };
  const PARENTAL_LEAVE_OPTION = { old: 'Parental/maternity leave', new: 'Parental_leave' };
  const NO_OPTION = 'No';

  const fixWhatDidYouDoForLife = (index: string) => {
    const whatDoYouDoForLifeAnswer =
      responses[`${WHAT_DO_YOU_DO_FOR_LIFE.answerID + index}`]?.answer || [];
    const hasEmployee = whatDoYouDoForLifeAnswer.includes(EMPLOYEE_OPTION.old);
    const hasMinijob = whatDoYouDoForLifeAnswer.includes(MINIJOB_OPTION.old);

    const otherIncomeResponse = responses[`${DID_YOU_GET_ANY_OTHER_INCOME.answerID + index}`];
    const otherIncomeAnswer = otherIncomeResponse?.answer;

    if (otherIncomeAnswer && (hasEmployee || hasMinijob)) {
      const noOptionIndex = otherIncomeAnswer.indexOf(NO_OPTION);
      if (noOptionIndex > -1) {
        otherIncomeAnswer.splice(noOptionIndex, 1);
      }
      if (!otherIncomeAnswer.includes(EMPLOYEE_OPTION.new)) {
        otherIncomeAnswer.push(EMPLOYEE_OPTION.new);
      }
    }
  };

  const fixDidAnyOfThisApplyInYear = (index: string) => {
    const whatDoYouDoForLifeAnswer =
      responses[`${WHAT_DO_YOU_DO_FOR_LIFE.answerID + index}`]?.answer || [];
    const didAnyOfThisApplyResponse = responses[`${WHAT_ARE_YOU_DOING.answerID + index}`];

    if (!didAnyOfThisApplyResponse?.answer) {
      return;
    }

    const isStudent = whatDoYouDoForLifeAnswer.includes(STUDENT_OPTION.old);
    const isInTraining = whatDoYouDoForLifeAnswer.includes(IN_TRAINING_OPTION.old);
    const isParentalLeave = whatDoYouDoForLifeAnswer.includes(PARENTAL_LEAVE_OPTION.old);

    if (isStudent && !didAnyOfThisApplyResponse.answer.includes(STUDENT_OPTION.new)) {
      didAnyOfThisApplyResponse.answer.push(STUDENT_OPTION.new);
    }

    if (isInTraining && !didAnyOfThisApplyResponse.answer.includes(IN_TRAINING_OPTION.new)) {
      didAnyOfThisApplyResponse.answer.push(IN_TRAINING_OPTION.new);
    }

    if (isParentalLeave && !didAnyOfThisApplyResponse.answer.includes(PARENTAL_LEAVE_OPTION.new)) {
      didAnyOfThisApplyResponse.answer.push(PARENTAL_LEAVE_OPTION.new);
    }

    if (didAnyOfThisApplyResponse.answer.length) {
      const noOptionIndex = didAnyOfThisApplyResponse.answer.indexOf(NO_OPTION.toLocaleLowerCase());
      if (noOptionIndex > -1 && didAnyOfThisApplyResponse.answer.length > 1) {
        didAnyOfThisApplyResponse.answer.splice(noOptionIndex, 1);
      }
    }
  };
  const cleanup = (index: string) => {
    const whatDoYouDoForLife = responses[`${WHAT_DO_YOU_DO_FOR_LIFE.answerID + index}`];
    if (whatDoYouDoForLife && whatDoYouDoForLife.answer) {
      whatDoYouDoForLife.answer = undefined;
    }
  };

  [USER_INDEX, PARTNER_INDEX].forEach((index) => {
    fixWhatDidYouDoForLife(index);
    fixDidAnyOfThisApplyInYear(index);
    cleanup(index);
  });
}
