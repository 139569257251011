import * as id from 'src/taxfix-business-logic/constants/question-answer';

export const payloadToId = {
  user: {
    place: id.ADDRESS,
    firstName: id.FIRST_NAME,
    lastName: id.LAST_NAME,
    dateOfBirth: id.DATE_OF_BIRTH,
    gender: id.GENDER,
    taxIdNumber: id.TAX_ID_NR,
    taxOffice: id.TAX_AUTHORITY,
    steuerNumber: id.STEUER_NUMBER,
  },
  partner: {
    firstName: id.PARTNER_FIRST_NAME,
    lastName: id.PARTNER_LAST_NAME,
    dateOfBirth: id.PARTNER_DATE_OF_BIRTH,
    gender: id.PARTNER_GENDER,
    taxIdNumber: id.PARTNER_TAX_ID_NR,
  },
  bankDetails: {
    iban: id.BANK_IBAN,
    bankName: id.BANK_NAME,
    accountHolder: id.BANK_ACCOUNT_HOLDER,
  },
};
