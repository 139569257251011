import React from 'react';

import { Button, Container, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const IntroCard: React.FC<Props> = ({
  title,
  subTitle,
  content,
  imageSource = 'premiumTierBanner',
  onButtonPress,
  buttonLabel,
  ...otherProps
}): JSX.Element => (
  <Container flexDirection={['column-reverse', 'row']} {...otherProps}>
    <Container flexShrink={1}>
      <Text variant="title2XLBold" mb={20} value={title} />
      <Text variant="titleMBold" mb={35} value={content} />
      <Text variant="titleLBold" mb={40} value={subTitle} />
      {buttonLabel && (
        <Button
          variant="primary"
          fontSize="xs"
          title={buttonLabel}
          onPress={onButtonPress}
          maxW={['full', 210]}
          alignSelf="flex-start"
        />
      )}
    </Container>
    {imageSource && (
      <Image
        name={imageSource}
        minW={413}
        minH={358}
        flexShrink={[1, 0]}
        ml={[0, 95]}
        alignSelf={['center', 'right']}
      />
    )}
  </Container>
);
