import React from 'react';

import { CommonResultComponent } from './CommonResult';

type Props = {
  onDismiss: () => void;
  onOpenMail: () => any;
};

export const ResultDetractorComponent = ({ onDismiss, onOpenMail }: Props): JSX.Element => {
  return (
    <CommonResultComponent
      icon="disappointed"
      title="feedback-modal.detractor.title"
      subtitle="feedback-modal.detractor.subtitle"
      ctaTranslationKey="feedback-modal.detractor.cta"
      onCtaPress={onOpenMail}
      onDismiss={onDismiss}
    />
  );
};
