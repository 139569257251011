import { CountryCodes } from '@taxfix/types';

import { Suggestion } from '../types/geo';

import { APIErrors } from './utils';
import { GoogleMaps } from './google/maps/client';
import { PlaceAutocompleteParams } from './google/maps/types';

export enum placesSearchResultType {
  address = 'address',
  city = '(cities)',
}

export type PlacesApiType = Array<string>;
export type PlacesApiTerm = {
  offset: number;
  value: string;
};
export type PlacesApiPrediction = {
  description: string;
  terms: PlacesApiTerm[];
  types: PlacesApiType;
};
export type PlacesApiResponse = {
  predictions: PlacesApiPrediction[];
  status: string;
};
const termsWithEmptyResult: Array<string> = [];

const filterResults = (prediction: PlacesApiPrediction): boolean => {
  // eslint-disable-next-line prefer-destructuring
  const types: PlacesApiType = prediction.types;
  return !(
    types.includes('transit_station') ||
    types.includes('country') ||
    types.includes('establishment') ||
    types.includes('point_of_interest')
  );
};

const isAlreadyEmpty = (input: any): boolean =>
  !!(termsWithEmptyResult.length && termsWithEmptyResult.includes(input));

type FetchSuggestionsParams = {
  language: string;
  resultType?: placesSearchResultType;
  countryCode?: CountryCodes;
};
type CallApiParams = FetchSuggestionsParams & { searchTerm: string };

export const callApi = async ({
  searchTerm,
  language,
  resultType = placesSearchResultType.address,
  countryCode,
}: CallApiParams): Promise<PlacesApiPrediction[]> => {
  if (!searchTerm || isAlreadyEmpty(searchTerm)) {
    throw new Error(APIErrors.ZeroResults);
  }

  let responseJson: PlacesApiResponse;

  try {
    const params: PlaceAutocompleteParams = {
      input: searchTerm,
      types: resultType,
      language,
    };
    if (countryCode) params.components = `country:${countryCode}`;
    responseJson = (await GoogleMaps.placeAutocomplete(params)) as PlacesApiResponse;
  } catch (error) {
    throw new Error(APIErrors.NetworkError);
  }

  if (responseJson.status === 'ZERO_RESULTS') {
    termsWithEmptyResult.push(searchTerm);
    throw new Error(responseJson.status);
  }

  if (responseJson.status !== 'OK') {
    throw new Error(responseJson.status);
  }

  const filteredResults: PlacesApiPrediction[] = responseJson.predictions.filter(filterResults);

  if (!filteredResults.length) {
    termsWithEmptyResult.push(searchTerm);
    throw new Error(APIErrors.ZeroResults);
  }

  return filteredResults;
};

export const parseResultIntoSuggestion = (apiResult: PlacesApiPrediction): Suggestion => ({
  name: apiResult.description,
});

export const fetchSuggestions =
  ({ language, resultType, countryCode }: FetchSuggestionsParams) =>
  async (searchTerm: string): Promise<Suggestion[]> => {
    const results: PlacesApiPrediction[] = await callApi({
      searchTerm,
      language,
      resultType,
      countryCode,
    });
    const suggestions: Suggestion[] = results.map(parseResultIntoSuggestion);
    return suggestions;
  };
