import messaging from '@react-native-firebase/messaging';

import { logger } from 'src/taxfix-business-logic/utils/logger';

type DeviceToken = string;

export type PermissionsAndDeviceToken = {
  token: DeviceToken | null | undefined;
  error: Error | null | undefined;
};
const permissions = {
  NOT_DETERMINED: -1,
  DENIED: 0,
  AUTHORIZED: 1,
  PROVISIONAL: 2,
};

class PushNotifications {
  constructor() {
    this.requestPushTokenAndPermissions = this.requestPushTokenAndPermissions.bind(this);
  }

  async isPermissionNotDetermined(): Promise<boolean> {
    const hasPermission = await messaging().hasPermission();
    return hasPermission === permissions.NOT_DETERMINED;
  }

  async getToken(): Promise<string | null> {
    try {
      const token = await messaging().getToken();
      return token;
    } catch (err) {
      logger.error(err, {
        message: 'Error on getting token',
      });
      return null;
    }
  }

  onTokenRefresh(listener: (token: string) => any): void {
    messaging().onTokenRefresh(listener);
  }

  async requestPushTokenAndPermissions(): Promise<PermissionsAndDeviceToken> {
    let token = null;
    let error = null;
    const isPermissionNotDetermined = await this.isPermissionNotDetermined();

    if (isPermissionNotDetermined) {
      await messaging().requestPermission();
    }

    const hasPermissionUpdated = await messaging().hasPermission();

    if (hasPermissionUpdated === permissions.AUTHORIZED) {
      try {
        await messaging().registerDeviceForRemoteMessages();
        token = await messaging().getToken();
      } catch (err: any) {
        logger.error(err, {
          message: 'PushNotifications: error',
        });
        error = err;
      }
    }

    return {
      token,
      error,
    };
  }
}

let notificationInstance: PushNotifications | null = null;

const getManager = () => {
  if (notificationInstance === null) {
    notificationInstance = new PushNotifications();
  }

  return notificationInstance;
};

export default getManager;
