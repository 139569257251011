import React, { Component } from 'react';
import { connect } from 'react-redux';
import Config from 'react-native-config';

import { TaxfixLoading } from '../components/loading';
import { BodySecondaryText, Box } from '../components/core';
import { FetchLog, selectors as statusSelectors } from '../stores/modules/status';

type Props = {
  fetchingStack: FetchLog[];
  titleId: string;
  subTitleId: string;
};
type State = {
  debugRefreshTimestamp: number;
};

class LoadingDebuggingUndecorated extends Component<Props, State> {
  debugTimeout: any;

  componentWillUnmount = () => {
    clearTimeout(this.debugTimeout);
  };

  render() {
    const { fetchingStack, titleId, subTitleId } = this.props;

    if (Config.SHOW_DEBUG_SCREEN === 'true') {
      // only to force refresh at least every 100ms when fetching
      this.debugTimeout = setTimeout(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({
          debugRefreshTimestamp: new Date().getTime(),
        });
      }, 100);
      const reversedLog = fetchingStack.concat().reverse();
      const debugStack = reversedLog.map((entry, index) => {
        const diff =
          index > 0
            ? reversedLog[index - 1].timestamp - entry.timestamp
            : new Date().getTime() - entry.timestamp;
        // console.log(`${entry.msg}::${diff}ms`);
        return <BodySecondaryText key={index}>{`${entry.msg}::${diff}ms`}</BodySecondaryText>;
      });
      return (
        <>
          <TaxfixLoading titleId={titleId} subTitleId={subTitleId} />
          <Box
            top={5}
            style={{
              width: '100%',
            }}
          >
            {debugStack}
          </Box>
        </>
      );
    }

    return <TaxfixLoading titleId={titleId} subTitleId={subTitleId} />;
  }
}

const mapStateToProps = (state: any) => ({
  fetchingStack: statusSelectors.getFetchingStack(state),
});

export const LoadingDebugging = connect(mapStateToProps)(LoadingDebuggingUndecorated);
