import React from 'react';

import SummaryText from './summary-text';

type TextProps = {
  value: string;
  properties?: Record<string, any>;
  linkable?: boolean;
  color?: string;
};

function Text({ value, properties = {}, linkable, color }: TextProps) {
  if (properties.emptyValue != null && value === properties.emptyValue) {
    return (
      <SummaryText
        style={{
          textAlign: 'right',
        }}
        numberOfLines={1}
        ellipsizeMode="middle"
        id="summaries.default.left-empty.text"
        linkable={linkable}
        color={color}
      />
    );
  }

  return (
    <SummaryText
      style={{
        textAlign: 'right',
      }}
      numberOfLines={1}
      ellipsizeMode="middle"
      linkable={linkable}
      color={color}
    >
      {value.toString()}
    </SummaryText>
  );
}

export default Text;
