import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import {
  DynamicLinkType,
  DynamicLink,
  FirebaseDynamicLink,
} from '../stores/modules/dynamic-link-types';
import {
  State as DynamicLinkStore,
  actions as dynamicLinkActionCreators,
} from '../stores/modules/dynamic-link';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { QuestionStores } from '../stores-legacy/QuestionStores';
import initial from '../stores/store/initial';

export type DynamiclinkRenderProps = {
  onInitialFetch: () => Promise<DynamicLink | null | undefined>;
  onDynamicLink: () => Promise<boolean>;
};
type Props = {
  questionStores: QuestionStores;
  dynamicLink: DynamicLinkStore;
  dynamicLinkActions: typeof dynamicLinkActionCreators;
  children: (props: DynamiclinkRenderProps) => React.ReactNode;
};

const mapStateToProps = (state: typeof initial) => ({
  dynamicLink: state.dynamicLink,
});

const mapDispatchToProps = (dispatch: any) => ({
  dynamicLinkActions: bindActionCreators(dynamicLinkActionCreators, dispatch),
});

class UndecoratedDynamicLinkContainer extends React.Component<Props> {
  handleInitialFetch: () => Promise<DynamicLink | null | undefined> = async () => {
    return new Promise((resolve, reject) => {
      const dynamicLink = this.saveDynamicLink('initial')({
        url: window.location.href,
      });
      resolve(dynamicLink);
    });
  };

  saveDynamicLink =
    (type: DynamicLinkType) =>
    ({ url: link }: FirebaseDynamicLink): DynamicLink | null | undefined => {
      let dynamicLink = null;

      try {
        const data = {
          questionStores: this.props.questionStores,
          link,
          type,
        };
        // typing workaround for return type of redux-thunk action
        dynamicLink = this.props.dynamicLinkActions.createAndSaveDynamicLink(
          data,
        ) as any as DynamicLink;
      } catch (e) {
        this.props.dynamicLinkActions.dismissDynamicLink();
        Analytics.log(AnalyticsEvent.AppOpenedFromDynamicLinkError, {
          type,
          link,
          error: e.message,
        });
      }

      return dynamicLink;
    };

  handleDynamicLink = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  };

  render() {
    return this.props.children({
      onInitialFetch: this.handleInitialFetch,
      onDynamicLink: this.handleDynamicLink,
    });
  }
}

const DynamicLinkContainer = compose<any>(
  inject('questionStores'),
  connect(mapStateToProps, mapDispatchToProps),
  observer,
)(UndecoratedDynamicLinkContainer);
export { DynamicLinkContainer };
