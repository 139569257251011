import React, { useCallback, useEffect, useState } from 'react';
import { GestureResponderEvent } from 'react-native';
import Config from 'react-native-config';
import { Box } from 'native-base';
import { useIntl } from 'react-intl';
import {
  CardElement,
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeCardElementChangeEvent,
  StripeExpressCheckoutElementClickEvent,
  StripeElementsOptions,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElementConfirmEvent,
  loadStripe,
} from '@stripe/stripe-js';
import { Payment as PaymentSDK } from '@taxfix/payment-sdk';

import { Text, Container } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks';
import { Loading } from 'src/components/loading';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import { StripePaymentError, StripePaymentErrorMessages } from '../errors';
import { PaymentFormLayout } from '../payment-form-layout';
import {
  PaymentError,
  sendAnalyticsOnAddressValidationError,
  sendAnalyticsOnPaymentFailure,
} from '../utils';

import { Locale, StripePaymentFormProps } from './types';

type PaymentFormProps = {
  onSubmit: StripePaymentFormProps['onSubmit'];
  onFinish: StripePaymentFormProps['onFinish'];
  onApplyVoucher: StripePaymentFormProps['onApplyVoucher'];
  onRemoveVoucher: StripePaymentFormProps['onRemoveVoucher'];
  validateForm: StripePaymentFormProps['validateForm'];
  productPrice: number;
  showStripeElements: boolean;
  scrollToTop: () => void;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  onFinish,
  onApplyVoucher,
  onRemoveVoucher,
  validateForm,
  productPrice,
  showStripeElements,
  scrollToTop,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetailsCompleted, setCardDetailsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidationFailed, setIsValidationFailed] = useState(false);
  const [error, setError] = useState('');
  const { getTranslationText } = useItalyIntl();

  // We don't need to wait for Stripe element component to be loaded on screen if price is 0
  useEffect(() => {
    if (productPrice == 0 && isLoading) {
      setIsLoading(false);
    }
  }, [productPrice, isLoading]);

  const onChange = useCallback(
    (e: StripeCardElementChangeEvent) => {
      if (error) setError('');
      if (e.complete && !cardDetailsCompleted) {
        setCardDetailsCompleted(true);
      } else if (!e.complete && cardDetailsCompleted) {
        setCardDetailsCompleted(false);
      }
    },
    [cardDetailsCompleted, error],
  );

  const confirmCardPayment = async (clientSecret: string, paymentId: number) => {
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement('card') as any,
      },
    });
    if (error) {
      sendAnalyticsOnPaymentFailure(
        PaymentSDK.PaymentType.PayPal,
        error.message || 'stripe_payment_failed',
        `${error.code}: ${error.decline_code}`,
      );
      setError('payment.paymentError');
      logger.warn(
        new StripePaymentError(`${StripePaymentErrorMessages.default} ${error.message}`),
        {
          error,
          paymentMethod: 'Card',
        },
      );
    } else {
      onFinish(paymentId, PaymentSDK.PaymentType.Card);
    }
  };

  const handleSubmit = async (e: GestureResponderEvent) => {
    e.preventDefault();
    if (!elements || !stripe) {
      return;
    }
    if (!validateForm()) {
      sendAnalyticsOnAddressValidationError();
      setIsValidationFailed(true);
      return;
    }
    try {
      setIsSubmitting(true);
      await onSubmit(confirmCardPayment, PaymentSDK.PaymentType.Card);
    } catch (error: any) {
      setError(
        error?.message === PaymentError.InvalidMethod
          ? 'it.payment.screen.payment-method.error'
          : 'payment.paymentError',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onPayPalConfirm = async (clientSecret: string) => {
    if (!stripe || !elements) {
      setError('payment.paymentError');
      return;
    }
    const isLocalhost = window.location.hostname === 'localhost';
    const redirectUrl = isLocalhost ? 'http://localhost:3000' : Config.PAYPAL_REDIRECT_BASE_URL;
    const { error } = await stripe.confirmPayPalPayment(clientSecret, { return_url: redirectUrl });
    if (error) {
      sendAnalyticsOnPaymentFailure(
        PaymentSDK.PaymentType.PayPal,
        error.message || 'stripe_payment_failed',
        `${error.code}: ${error.decline_code}`,
      );
      setError('payment.paymentError');
      logger.warn(
        new StripePaymentError(`${StripePaymentErrorMessages.default} ${error.message}`),
        {
          error,
          paymentMethod: 'PayPal',
        },
      );
    }
  };

  const expressCheckoutOptions = {
    buttonType: {
      paypal: 'pay',
    },
    buttonTheme: {
      paypal: 'blue',
    },
    wallets: {
      applePay: 'never',
      googlePay: 'never',
    },
    buttonHeight: 55,
  } as StripeExpressCheckoutElementOptions;

  const handlePaypalSubmit = async (e: StripeExpressCheckoutElementConfirmEvent) => {
    if (!elements || !stripe) {
      setError('payment.paymentError');
      return;
    }

    try {
      await onSubmit(onPayPalConfirm, PaymentSDK.PaymentType.PayPal);
    } catch (error) {
      setError('payment.paymentError');
    }
  };

  const handlePressCheckoutElement = ({
    resolve,
  }: StripeExpressCheckoutElementClickEvent): void => {
    if (!validateForm()) {
      sendAnalyticsOnAddressValidationError();
      setIsValidationFailed(true);
      return;
    }

    const options = {
      emailRequired: true,
    };
    resolve(options);
  };

  useEffect(() => {
    if (isValidationFailed && validateForm()) {
      setIsValidationFailed(false);
    }
  }, [isValidationFailed, validateForm]);

  useEffect(() => {
    if (error) {
      scrollToTop();
    }
  }, [error, scrollToTop]);

  return (
    <>
      {isLoading && <Loading style={{ backgroundColor: 'white', zIndex: 1, height: '80%' }} />}
      <Container display={isLoading ? 'none' : 'flex'}>
        <PaymentFormLayout
          handleSubmit={handleSubmit}
          isCardSubmitDisabled={!cardDetailsCompleted && !!productPrice}
          errorMessage={error}
          isSubmitting={isSubmitting}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
          isValidationFailed={isValidationFailed}
        >
          {({ isDisablePayment }) => (
            <Container mb={24} opacity={isDisablePayment ? '0.4' : 1}>
              {showStripeElements && (
                <>
                  <Text
                    variant="titleSBold"
                    textAlign="left"
                    marginBottom={20}
                    value={getTranslationText('it.payment.screen.payment-method.title')}
                  />
                  <ExpressCheckoutElement
                    onConfirm={handlePaypalSubmit}
                    onClick={handlePressCheckoutElement}
                    options={expressCheckoutOptions}
                    onReady={() => setIsLoading(false)}
                  />
                  <Container
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    flexDirection="row"
                    mt={12}
                  >
                    <Container
                      borderBottomColor="#0F0C0F1A"
                      borderBottomWidth={1}
                      width="40px"
                      mr={20}
                    />
                    <Text
                      variant="bodySBook"
                      color="greytones.textLight"
                      value={getTranslationText('it.payment.methods.option.title')}
                      py={10}
                    />
                    <Container
                      borderBottomColor="#0F0C0F1A"
                      borderBottomWidth={1}
                      width="40px"
                      ml={20}
                    />
                  </Container>
                  <Text
                    variant="bodySBook"
                    color="greytones.textLight"
                    value={getTranslationText('it.payment.methods.card.title')}
                    py={10}
                  />
                  <Container borderBottomColor="#E2DFE2" borderBottomWidth={1} py={10}>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                      }}
                      onChange={onChange}
                    />
                  </Container>
                </>
              )}
            </Container>
          )}
        </PaymentFormLayout>
      </Container>
    </>
  );
};

const StripePaymentForm: React.FC<StripePaymentFormProps> = ({
  stripePublishableKey,
  onSubmit,
  onFinish,
  onApplyVoucher,
  onRemoveVoucher,
  validateForm,
  productPrice,
  scrollToTop,
}) => {
  const { locale } = useIntl();
  const stripePromise = loadStripe(stripePublishableKey, {
    locale: locale as Locale,
  });

  const DEFAULT_AMOUNT_FOR_STRIPE_PAYMENT = 50; // https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
  const amount = Number((productPrice * 100).toFixed());
  const options = {
    amount: amount || DEFAULT_AMOUNT_FOR_STRIPE_PAYMENT,
    // stripe will throw error if the amount is 0,
    // also this value is only to show in the paypal popup. The amount will charged always depenmds on the payment intent.
    // And this component won't render the payment options If the amount is 0
    mode: 'payment',
    currency: 'eur',
    locale: locale as Locale,
  } as StripeElementsOptions;

  return (
    <Box height="95%" justifyContent="space-between">
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm
          onSubmit={onSubmit}
          onFinish={onFinish}
          productPrice={productPrice}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
          validateForm={validateForm}
          showStripeElements={productPrice != 0}
          scrollToTop={scrollToTop}
        />
      </Elements>
    </Box>
  );
};

export default StripePaymentForm;
