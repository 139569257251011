import React from 'react';
import { View, Linking, TouchableOpacity } from 'react-native';

import {
  BigDisplayTitleText,
  DisplayPrimaryText,
  BodySecondaryText,
  Image,
} from 'src/components/core';

import { testID } from '../../common/testID';

import styles from './styles';

export type CountryIconStyle = 'iconFR' | 'iconIT' | 'iconDE';
export type Props = {
  title: string;
  subTitle: string;
  description: string;
  downloadButtonImg: string;
  downloadButtonUrl: string;
  countryIcon?: string;
  countryIconStyle: CountryIconStyle;
};

export const DownloadApp: React.FC<Props> = ({
  title,
  subTitle,
  description,
  downloadButtonImg,
  downloadButtonUrl,
  countryIcon,
  countryIconStyle,
}) => {
  return (
    <View style={styles.screenContainer}>
      <View style={styles.content}>
        <BigDisplayTitleText style={styles.textSection} id={title} />
        <DisplayPrimaryText style={styles.textSection} id={subTitle} />
        <BodySecondaryText style={styles.textSection} id={description} />
        <TouchableOpacity
          style={styles.downloadButton}
          onPress={() => Linking.openURL(downloadButtonUrl)}
          {...testID('downloadAppButton')}
        >
          <Image name={downloadButtonImg} />
        </TouchableOpacity>
      </View>
      <View style={styles.bgImagesContainer}>
        <Image name="download-app.ellipse" style={styles.bgImage} />
        {countryIcon && (
          <Image
            name={countryIcon}
            style={[styles.bgImage, styles[countryIconStyle]]}
            testId="countryIcon"
          />
        )}
      </View>
    </View>
  );
};
