import { StatusMiddleware, StatusStepNavigationData } from 'src/stores/modules/status';

// @ts-ignore
export const getNavigationConfigWithParams: (
  configNavigation: StatusStepNavigationData | null | undefined,
  params: Record<string, any>,
) => StatusStepNavigationData = (configNavigation, params) => {
  if (!configNavigation) throw new Error('configNavigation is not defined');
  return {
    ...configNavigation,
    link: { ...configNavigation.link, params: { ...configNavigation.link?.params, ...params } },
  };
};

export const markComplete: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  next();
};
export const forceBreak: (arg0: string) => StatusMiddleware =
  (section) => (dispatch, getState, config, breakAction) => {
    dispatch(breakAction(undefined, config.section?.[section]));
  };
