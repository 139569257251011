import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * Displays one side of an uploaded document
 */

export const OneSideDocUploadContent: React.FC<Props> = ({
  title,
  image,
  ...otherProps
}): JSX.Element => {
  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  return (
    <Container maxW="100%" width="100%" alignItems="center" {...otherProps}>
      {isLargeScreen && <Text variant="titleXSBold" color="greytones.textDefault" {...title} />}
      <Image resizeMode="contain" alignSelf="center" {...image} />
    </Container>
  );
};
