import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { Button, Container, Text } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

type Props = {
  handleOnYes: () => void;
  handleOnNo: () => void;
  providerName: string;
};
const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export const UnsupportedSPIDOverlay: React.FC<Props> = ({
  handleOnYes,
  handleOnNo,
  providerName,
}) => {
  const { getTranslationText } = useItalyIntl();

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'UnsupportedSPIDOverlay',
      SPIDProvider: providerName,
    });
  }, []);

  const onPressYes = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'UnsupportedSPIDOverlay',
      buttonName: 'yesButton',
    });

    handleOnYes();
  };

  const onPressNo = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'UnsupportedSPIDOverlay',
      buttonName: 'noButton',
    });

    handleOnNo();
  };

  return (
    <Overlay disableBackgroundTouch style={styles.overlay} appearFromTop>
      {() => (
        <Container
          borderRadius={1}
          maxWidth="600px"
          maxHeight="600px"
          backgroundColor="white"
          p={[2, 5]}
          m={1}
        >
          <Text
            value={getTranslationText('it.unsupported.spid.popup.title', { providerName })}
            variant="titleMBold"
            textAlign="center"
            mt={4}
            mx={1}
          />

          <Text
            value={getTranslationText('it.unsupported.spid.popup.description')}
            variant="bodyMBook"
            textAlign="center"
            color="greytones.textLight"
            mt={2}
            mx={2}
          />

          <Button
            variant="primary"
            title={getTranslationText('it.unsupported.spid.popup.button.yes-title')}
            onPress={onPressYes}
            width={['xl', 'l']}
            mt={3}
          />

          <Button
            variant="tertiary"
            title={getTranslationText('it.unsupported.spid.popup.button.no-title')}
            onPress={onPressNo}
            width={['xl', 'l']}
            mt={1}
          />
        </Container>
      )}
    </Overlay>
  );
};
