import { DocumentPickerResponse } from 'react-native-document-picker';
import { Dispatch } from 'redux';
import omit from 'lodash/omit';
import { createSelector } from 'reselect';
import { Documents, CountryCodes } from '@taxfix/types';

import { CameraImageResult } from '../../containers/scanbot-camera';
import {
  PDFResult,
  uploadDocument,
  DocumentAllowedFileTypes,
  cloneDocument,
  getDocuments,
} from '../../services/documents';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

export const USER_IDENTIFIED = 'identifications/userIdentified';
export const FETCH_IDENTIFICATIONS_SUCCESS = 'identifications/fetchIdentificationsSuccess';
export const IDENTIFICATION_REMOVED = 'identifications/identificationRemoved';

type DocumentMetadata = {
  contentType: string;
  originalName: string;
  size: number;
};

export type Payslip = Identification & {
  path: string;
  type: Documents.Types;
  metadata: DocumentMetadata;
};

export type Identification = {
  id: number;
  year: number;
  state?: Documents.States;
  rejectionReasons?: Documents.RejectionReasons[];
  path?: string;
};
type State = Record<number, Identification> & {
  payslips: Record<number, Payslip>;
};
export type RootState = {
  identifications: State;
};
type UploadIdentificationProps = {
  image?: CameraImageResult;
  pdf?: PDFResult | DocumentPickerResponse;
  year: number;
  accessToken: string;
  countryCode: CountryCodes;
  type?: Documents.NonReceiptTypes;
};
export const initial: State = {
  payslips: {},
};
export interface Event {
  type:
    | typeof USER_IDENTIFIED
    | typeof IDENTIFICATION_REMOVED
    | typeof FETCH_IDENTIFICATIONS_SUCCESS;
  payload: Identification;
}

const createAction = (payload: Event['payload']): Event => ({
  type: USER_IDENTIFIED,
  payload: { ...payload },
});

const uploadIdentification =
  (props: UploadIdentificationProps) =>
  async (dispatch: (...args: Array<any>) => any): Promise<void> => {
    const { pdf, image, year, countryCode, accessToken, type } = props;
    const fileType = pdf ? DocumentAllowedFileTypes.PDF : DocumentAllowedFileTypes.JPG;
    const documentType = type || Documents.NonReceiptTypes.Id;
    const { id } = await uploadDocument({
      payload: {
        pdf,
        image,
        fileType,
      } as any,
      token: accessToken,
      type: documentType,
      year,
      countryCode,
    });
    dispatch(
      createAction({
        id,
        year,
      } as any),
    );
  };

const cloneIdentification =
  ({ year, accessToken, documentId }: { year: number; accessToken: string; documentId: number }) =>
  async (dispatch: (...args: Array<any>) => any): Promise<void> => {
    const data = {
      id: documentId,
      year,
      type: Documents.NonReceiptTypes.Address,
    };

    try {
      const { id } = await cloneDocument({
        token: accessToken,
        data,
      });
      const result = {
        id,
        year,
      };
      dispatch(createAction(result));
      Analytics.log(AnalyticsEvent.identificationCloneDocumentSucceed);
    } catch (err) {
      Analytics.log(AnalyticsEvent.identificationCloneDocumentFailed, {
        error: err.message,
      });
    }
  };

const getIdentifications =
  (accessToken: string, userId?: number) =>
  async (dispatch: (...args: Array<any>) => any): Promise<void> => {
    try {
      if (!userId) throw new Error('You must provide a userId');

      const { data }: { data: Identification[] } = await getDocuments(accessToken, {
        userId,
        states: [Documents.States.Approved, Documents.States.Created] as any,
        types: [Documents.NonReceiptTypes.Address, Documents.NonReceiptTypes.Id],
      });
      const result = data.reduce((acc, document: any) => {
        return {
          ...acc,
          [document.year]: {
            id: document.id,
            year: document.year,
            state: document.state,
            rejectionReasons: document.rejectionReasons,
            path: document.path,
          },
        };
      }, {});
      dispatch({
        type: FETCH_IDENTIFICATIONS_SUCCESS,
        payload: result,
      });
    } catch (err) {
      Analytics.log(AnalyticsEvent.dataFetchFailed, {
        error: err.message,
        serviceName: 'documents',
        requestDetails: 'getDocuments',
      });
    }
  };

const getRejectedIdentifications =
  (userId: number, accessToken: string) =>
  async (dispatch: Dispatch<Event>): Promise<void> => {
    try {
      const { data }: { data: any } = await getDocuments(accessToken, {
        userId,
        states: [Documents.States.Rejected] as any,
        types: [Documents.NonReceiptTypes.Address, Documents.NonReceiptTypes.Id],
      });
      const result = data.reduce((acc: any, document: any) => {
        const previousDocument = acc[document.year];

        if (previousDocument && previousDocument.updatedAt > new Date(document.updatedAt)) {
          return { ...acc };
        }

        return {
          ...acc,
          [document.year]: {
            id: document.id,
            year: document.year,
            state: document.state,
            rejectionReasons: JSON.parse(document.rejectReasons),
            updatedAt: new Date(document.updatedAt),
          },
        };
      }, {} as Identification);
      dispatch({
        type: FETCH_IDENTIFICATIONS_SUCCESS,
        payload: result,
      });
    } catch (err) {
      Analytics.log(AnalyticsEvent.dataFetchFailed, {
        error: err.message,
        serviceName: 'documents',
        requestDetails: 'getDocuments',
      });
    }
  };

const removeIdentificationByYear =
  (identification: { id: number; year: number }) =>
  (dispatch: Dispatch<Event>): void => {
    dispatch({
      type: IDENTIFICATION_REMOVED,
      payload: { ...identification },
    });
  };

const addIdentification =
  (identification: Identification) =>
  (dispatch: Dispatch<Event>): void => {
    dispatch(createAction(identification));
  };

export const actions = {
  uploadIdentification,
  cloneIdentification,
  getIdentifications,
  removeIdentificationByYear,
  addIdentification,
  getRejectedIdentifications,
};

export const reducer = (state: State = initial, event: Event): State => {
  switch (event.type as any) {
    case USER_IDENTIFIED:
      return {
        ...state,
        ...{
          [event.payload.year]: event.payload,
        },
      };

    case IDENTIFICATION_REMOVED:
      return { ...omit(state, event.payload.year) };

    case FETCH_IDENTIFICATIONS_SUCCESS:
      return { ...state, ...event.payload };

    default:
      return state;
  }
};

const getIdentificationByYear =
  (year: number) =>
  (state: RootState): Identification =>
    state.identifications[year];

const isIdentificationRejectedByYear: (
  state: RootState,
  year: number,
  reason?: Documents.RejectionReasons,
) => boolean = createSelector(
  (state: RootState, year: number) => getIdentificationByYear(year)(state),
  (state: RootState, year: number, reason?: Documents.RejectionReasons) => reason,
  (identification, reason) => {
    const { rejectionReasons } = identification ?? {};
    const reasonsList = rejectionReasons ?? [];
    return reason ? reasonsList.includes(reason) : false;
  },
);

export const selectors = {
  getIdentificationByYear,
  isIdentificationRejectedByYear,
};
