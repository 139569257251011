import { View, useTheme } from 'native-base';
import React from 'react';
import { Pressable } from 'react-native';

import { icons } from 'src/taxfix-components/src';

import { Icon } from '../Icon';

import { ArticleListItemSummary, ArticleListItemText } from './article-list-item-text';

export type ArticleListItemData = {
  id: number | string;
  url?: string;
  title: string;
  summary?: string | null;
  highlight?: {
    title: string | null;
    summary: string | null;
    body: string | null;
  };
};

export type ArticleListItemProps = ArticleListItemData & {
  onPress: (id: number | string) => void;
  searchTerm?: string;
};

export const ArticleListItem = ({
  id,
  title,
  summary,
  onPress,
  searchTerm,
}: ArticleListItemProps) => {
  const handlePress = () => {
    onPress(id);
  };
  const { colors } = useTheme();
  return (
    <Pressable
      style={({ pressed }) => [{ backgroundColor: pressed ? '#D5D5D5' : '#FFF' }]}
      onPress={handlePress}
    >
      <View
        borderColor={colors.greytones.graphicsLight}
        borderBottomWidth={1}
        flex={1}
        flexDirection="row"
        paddingY="8px"
        justifyContent="space-between"
        alignItems="center"
      >
        <View maxWidth="5/6">
          <ArticleListItemText highlight={searchTerm}>{title}</ArticleListItemText>
          {summary ? (
            <ArticleListItemSummary highlight={searchTerm}>{summary}</ArticleListItemSummary>
          ) : (
            <></>
          )}
        </View>
        <View>
          <Icon color={colors.greytones.textLight} size="16px" path={icons.chevronRight} />
        </View>
      </View>
    </Pressable>
  );
};
