// TODO delete when we are able to work with multicolor icons
import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { Props } from './types';

/**
 * The Check Mark Icon with several paths
 * Temporary component
 */

export const CheckMarkSolid: React.FC<Props> = ({
  fillColor = '#000',
  size = '100%',
}): JSX.Element => (
  <Icon viewBox="0 0 24 24" size={size}>
    <G fill="none">
      <Path
        d="M10.5968 18.3871C10.3065 18.6774 9.77421 18.6774 9.48389 18.3871L4.45163 13.3548C4.16131 13.0645 4.16131 12.5323 4.45163 12.2419L5.56453 11.1774C5.85485 10.8387 6.33873 10.8387 6.62905 11.1774L10.0645 14.5645L17.3226 7.30645C17.6129 6.96774 18.0968 6.96774 18.3871 7.30645L19.5 8.37097C19.7903 8.66129 19.7903 9.19355 19.5 9.48387L10.5968 18.3871Z"
        fill="white"
      />
      <Path
        d="M24 12C24 5.41935 18.5806 0 12 0C5.37097 0 0 5.41935 0 12C0 18.629 5.37097 24 12 24C18.5806 24 24 18.629 24 12ZM10.5968 18.3871C10.3065 18.6774 9.77419 18.6774 9.48387 18.3871L4.45161 13.3548C4.16129 13.0645 4.16129 12.5323 4.45161 12.2419L5.56452 11.1774C5.85484 10.8387 6.33871 10.8387 6.62903 11.1774L10.0645 14.5645L17.3226 7.30645C17.6129 6.96774 18.0968 6.96774 18.3871 7.30645L19.5 8.37097C19.7903 8.66129 19.7903 9.19355 19.5 9.48387L10.5968 18.3871Z"
        fill={fillColor}
      />
    </G>
  </Icon>
);
