import React from 'react';

import { Container, STEP_STATUS } from 'src/taxfix-components/src';

import { Step } from '../../general/Step';
import { StepCommonProps } from '../../general/Step/types';

import { ConnectorWrapperProps, Props } from './types';

/**
 * Shows Steps with connector
 */

export const ConnectorWrapper: React.FC<ConnectorWrapperProps> = ({
  showConnector,
  children,
  borderStyle,
  borderColor = 'greytones.graphicsLight',
  direction,
  stepStatus = STEP_STATUS.NOT_STARTED,
}) => {
  const isVertical = direction === 'vertical';

  return (
    <Container
      position="relative"
      minHeight="auto"
      flex={isVertical ? 0 : 1}
      alignItems={isVertical ? 'flex-start' : 'center'}
    >
      {showConnector && (
        <Container
          flexDirection={isVertical ? 'column' : 'row'}
          height={isVertical ? '100%' : undefined}
          width={isVertical ? undefined : '100%'}
          position="absolute"
          left={isVertical ? undefined : '50%'}
          top="12px"
        >
          <Container
            height={isVertical ? '50%' : undefined}
            width={isVertical ? undefined : '50%'}
            left={
              isVertical || [STEP_STATUS.IN_PROGRESS, STEP_STATUS.COMPLETED].includes(stepStatus)
                ? 11
                : undefined
            }
            borderWidth="xs"
            borderColor={
              [STEP_STATUS.IN_PROGRESS, STEP_STATUS.COMPLETED].includes(stepStatus)
                ? 'brand.brandGreen'
                : borderColor
            }
            borderStyle={borderStyle ?? 'dashed'}
          />
          <Container
            height={isVertical ? '50%' : undefined}
            width={isVertical ? undefined : '50%'}
            left={isVertical ? 11 : undefined}
            borderWidth="xs"
            borderColor={stepStatus === STEP_STATUS.COMPLETED ? 'brand.brandGreen' : borderColor}
            borderStyle={borderStyle ?? 'dashed'}
          />
        </Container>
      )}
      {children}
    </Container>
  );
};

export const Stepper: React.FC<Props> = ({
  steps,
  showConnector = true,
  numberStyles,
  testID,
  borderStyle,
  borderColor,
  direction = 'horizontal',
  stepsCommonStyleProps,
  ...otherStepperProps
}) => {
  return (
    <Container
      alignItems="flex-start"
      justifyContent="space-between"
      flexDirection={direction === 'vertical' ? 'column' : 'row'}
      testID={testID}
      {...otherStepperProps}
    >
      {steps.map((step, index) => {
        const canShowConnector = showConnector && index < steps.length - 1;

        return (
          <ConnectorWrapper
            direction={direction}
            showConnector={canShowConnector}
            key={index}
            borderStyle={borderStyle}
            borderColor={borderColor}
            stepStatus={step.status}
          >
            <Step
              number={index + 1}
              numberStyles={numberStyles}
              direction={direction}
              {...(stepsCommonStyleProps as StepCommonProps)}
              {...step}
            />
          </ConnectorWrapper>
        );
      })}
    </Container>
  );
};
