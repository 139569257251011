import {
  useNavigationState,
  useRoute,
  useIsFocused,
  useNavigation as useReactNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import {
  getNavigationActions,
  NavigationActions,
  getBoundNavigationsActions,
  safeResetNavigation,
} from '../routes/config-util';
import { NavigationState, NavigateAction } from '../services/navigation-service';
import { NavigationParams } from '../routes/navigate';

export type NavigationHook = {
  getNavigationActions: typeof getNavigationActions;
  navigationActions: NavigationActions;
  navigation: StackNavigationProp<any>;
  navigationState: NavigationState;
  navigationParams: NavigationParams<Record<string, any>>;
  isFocused: boolean;
  currentRouteName: string;
  safeResetNavigation: (actions: NavigateAction<any>[]) => void;
};

export const useNavigation = (): NavigationHook => {
  const navigationActions = getBoundNavigationsActions();
  const navigationState = useNavigationState((state) => state);
  const { params, name } = useRoute();
  const navigation = useReactNavigation<StackNavigationProp<any>>();
  const isFocused = useIsFocused();
  return {
    currentRouteName: name,
    getNavigationActions,
    navigationActions,
    navigationState,
    // @ts-ignore
    navigationParams: params,
    // @ts-ignore
    navigation,
    isFocused,
    safeResetNavigation,
  };
};
