import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { privacyStyles } from '../../../../assets/templates/custom-styles/privacy';
import docs from '../../../../assets/docs';

const mapStateToProps = (stores: any) => ({
  selectedLocale: settingsSelectors.selectedLocale(stores),
  selectedCountry: settingsSelectors.selectedCountry(stores),
});

const connector = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ListRowComponentPropsType<any> & WithNavigation & ReduxProps;

const Privacy = (props: Props) => {
  const { showIcon = false, selectedCountry, selectedLocale } = props;
  const extraPayload = [CountryCodes.IT].includes(selectedCountry)
    ? {}
    : {
        contentStyles: privacyStyles,
      };

  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions } = props;
    const { payload } = item;
    trackMenuItem(item);

    if (payload) {
      (navigationActions as any).toPrivacyPolicy('screen', {
        content: payload.content,
        contentStyles: payload.contentStyles,
      });
    }
  };

  return (props as any).children({
    hide: false,
    data: {
      iconName: showIcon && selectedCountry !== CountryCodes.IT ? 'menuItems.privacy' : undefined,
      translationKey: menuItem.privacy,
      testId: menuItem.privacy,
      payload: {
        content: docs.privacy(selectedLocale),
        ...extraPayload,
      },
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const PrivacyMenu = compose(withNavigation, connector)(Privacy);
