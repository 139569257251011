import * as React from 'react';
import { BackHandler } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as OverlayActions } from '../stores/modules/overlay';

type Overlay = {
  name: string;
  component: React.ComponentType<any>;
};
// Please note this type defines only the params
// that have a special behaviour.
// You can still pass whatever other param you need.
type OverlayParams = {
  disableAndroidBackPress?: boolean;
  showOnLocked?: boolean;
  onClose?: (...args: Array<any>) => void;
};
type Props = {
  activeOverlay?: Overlay;
  overlayParams: OverlayParams;
  overlayActions: typeof OverlayActions;
  screens: Array<Overlay>;
  locked: boolean;
};

const mapStateToProps = (state: any, props: Props) => ({
  activeOverlay: props.screens.find((screen) => screen.name === state.overlay.activeName),
  overlayParams: state.overlay.params,
});

const mapDispatchToProps = (dispatch: any) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

class OverlayNavigatorContainer extends React.Component<Props> {
  componentDidUpdate(prevProps: any) {
    if (!prevProps.activeOverlay && this.props.activeOverlay) {
      // Overlay is being shown, add back handler
      BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }

    if (prevProps.activeOverlay && !this.props.activeOverlay) {
      // Overlay is being hide, remove back handler
      BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
  }

  handleBackPress = () => {
    const { overlayParams } = this.props;

    if (!overlayParams.disableAndroidBackPress) {
      this.handleOnClose();
    }

    return true;
  };

  handleOnClose = (...args: any[]) => {
    const { overlayParams, overlayActions } = this.props;

    if (overlayParams.onClose) {
      overlayParams.onClose(...args);
    }

    overlayActions.hide();
  };

  render() {
    const { activeOverlay, overlayParams, locked } = this.props;
    const { showOnLocked = false, ...componentParams } = overlayParams;
    const show = locked ? showOnLocked : !locked;
    const Component = activeOverlay && activeOverlay.component;
    return Component && show ? (
      <Component {...componentParams} onClose={this.handleOnClose} />
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayNavigatorContainer);
