import React from 'react';
import { TouchableOpacity } from 'react-native';

import { testID } from '../../common/testID';

import { CheckboxIcon } from './checkbox-icon';

type Props = {
  checked: boolean;
  disabled?: boolean;
  color?: string;
  uncheckedColor?: string;
  onToggle: () => void;
  testId?: string;
};
export const CheckBox: React.FC<Props> = ({
  checked,
  onToggle,
  disabled = false,
  testId,
  color,
  uncheckedColor,
}) => {
  if (disabled) {
    return (
      <CheckboxIcon
        isChecked={checked}
        isDisabled={disabled}
        color={color}
        uncheckedColor={uncheckedColor}
      />
    );
  }

  return (
    <TouchableOpacity onPress={onToggle} {...testID(testId)}>
      <CheckboxIcon
        isChecked={checked}
        isDisabled={false}
        color={color}
        uncheckedColor={uncheckedColor}
      />
    </TouchableOpacity>
  );
};
