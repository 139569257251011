import { $Values } from 'utility-types';
import Config from 'react-native-config';
import { Documents as DocumentsSDK } from '@taxfix/taxfix-sdk';
import * as SubmissionSDK from '@taxfix/submissions-sdk';
import { Payment as PaymentSDK, Product as ProductSDK } from '@taxfix/payment-sdk';
import { GetAddressMatchResponse } from '@taxfix/submissions-sdk/dist/address/match';
import { GetAllResponse as PaymentGetAllResponse } from '@taxfix/payment-sdk/dist/payment/get-all';
import { CreateRequest as CreateSubmissionRequest } from '@taxfix/submissions-sdk/dist/create/create';
import { StatusResponse } from '@taxfix/payment-sdk/dist/product/get-status';
import { CountryCodes, Documents, TaxAssessments } from '@taxfix/types';
import {
  States as SubmissionStates,
  SubmissionWithId,
  SubmissionWithIdAndIdentificationState,
} from '@taxfix/submissions-types';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { DELETED_BY_USER_MESSAGE, SubmissionStatus } from '../utils/constants';
import { Payment, Product, ProductVariation } from '../types/payment';
import { logErrorMessages } from '../common/log-error-messages';

export const DraftSubmissionStates: any = Object.freeze({
  Initial: 'Initial',
  DonationCreated: 'DonationCreated',
  ConsentCreated: 'ConsentCreated',
  DonationAndConsentCreated: 'DonationAndConsentCreated',
});
export type DraftSubmissionState = $Values<typeof DraftSubmissionStates>;
export const DraftAndSentSubmissionStates = Object.freeze({
  Initial: 'Initial',
  DonationCreated: 'DonationCreated',
  ConsentCreated: 'ConsentCreated',
  DonationAndConsentCreated: 'DonationAndConsentCreated',
  ...SubmissionStates,
});
export type DraftAndSentSubmissionState = $Values<typeof DraftAndSentSubmissionStates>;
export type GetAllSubmissions = (
  accessToken: string,
  userId: number,
  year: number | null | undefined,
  countryCode: CountryCodes,
  additionalProjections?: string[],
) => Promise<SubmissionWithId[]>;
export type CreateRequestDefaultParams = {
  platform: string;
  platformVersion: string;
};
export type SubmissionAppData = {
  firstName: string;
  lastName: string;
  sex: string;
  birthdate: string;
  // YYYY-mm-dd
  birthplace: string;
  birthplaceProvince: string;
  taxId: string;
  employerName: string;
  employerTaxId: string;
  fiscalCityName: string;
  fiscalCityCode?: string;
  fiscalProvince?: string;
};
export type Options = {
  accessToken: string;
  apiBaseUrl: string;
};
export type ExtraPaymentParams = Record<string, never>;
type GetPaymentListParam = {
  accessToken: string;
  userId: number;
  year: number;
  countryCode: CountryCodes;
  productVariation?: string;
};

export const defaultSubmissionsProjection = [
  'id',
  'year',
  'userId',
  'deleted',
  'updated',
  'created',
  'identificationId',
  'isIdentified',
  'paymentId',
  'attempt',
  'isReviewRequired',
  'deletionReasonMessage',
  'state',
];

// @ts-ignore
export const getAllSubmissionsForUserAndYear: GetAllSubmissions = async (
  accessToken,
  userId,
  year,
  countryCode,
  additionalProjections = [],
) => {
  const { data } = await SubmissionSDK.getAll(Config.API_BASE_URL, accessToken, {
    userId,
    countryCode,
    // @ts-ignore
    year,
    // @ts-ignore
    projection: [
      ...defaultSubmissionsProjection,
      'identificationDocumentState',
      'calculationResult',
      'submittedAt',
      ...additionalProjections,
    ],
  });
  return data || [];
};

export const checkIsResubmission: (
  activeSubmission: SubmissionWithId | null | undefined,
  deletedSubmissions: SubmissionWithId[],
) => boolean = (activeSubmission, deletedSubmissions) =>
  !activeSubmission && deletedSubmissions.length > 0;

export const getPaymentIdFromSubmissions: (
  submissions: SubmissionWithId[],
) => number | null | undefined = (submissions) => {
  const submissionWithPayment = submissions.find((submission) => submission.paymentId);
  return submissionWithPayment ? submissionWithPayment.paymentId : null;
};

export const getPaymentListForProduct = async (
  userId: number,
  accessToken: string,
  product: Product,
): Promise<PaymentGetAllResponse> => {
  const paymentList = await PaymentSDK.getAll(Config.API_BASE_URL, accessToken, {
    productId: product.id,
    userId,
  });
  return paymentList as unknown as Promise<PaymentGetAllResponse>; // TODO: Fix type in the PaymentAPI
};

export const getPaymentListForUser = (
  userId: number,
  accessToken: string,
): Promise<PaymentGetAllResponse> =>
  PaymentSDK.getAll(Config.API_BASE_URL, accessToken, {
    userId,
  }) as unknown as Promise<PaymentGetAllResponse>; // TODO: Fix type in the PaymentAPI

export const getPaymentFromPaymentId = async (
  accessToken: string,
  paymentId: number,
): Promise<Payment> => {
  const payment = await PaymentSDK.get(Config.API_BASE_URL, accessToken, {
    id: paymentId,
  });
  if (!payment) throw new Error(`couldn't get payment for id ${paymentId}`);
  // @ts-ignore
  return payment;
};

export const getProduct = async (
  accessToken: string,
  year: number,
  countryCode: CountryCodes,
  variation?: ProductVariation | null | undefined,
): Promise<Product> => {
  const productList = await ProductSDK.resolve(Config.API_BASE_URL, accessToken, {
    year,
    category: 'submission',
    countryCode,
    // @ts-ignore
    variation,
  });

  if (productList.length === 0) {
    throw new Error('service should return only one product, but it returned none');
  }

  if (productList.length > 1) {
    throw new Error('service should return only product, but it returned several ones');
  }

  return productList[0];
};

const defaultGetPaymentList = async ({
  userId,
  accessToken,
  year,
  countryCode,
  productVariation,
}: GetPaymentListParam): Promise<PaymentGetAllResponse> =>
  getPaymentListForProduct(
    userId,
    accessToken,
    await getProduct(accessToken, year, countryCode, productVariation),
  );

export const getPaymentListForCountry = async (
  params: GetPaymentListParam,
): Promise<PaymentGetAllResponse> => {
  switch (params.countryCode) {
    default:
      return defaultGetPaymentList(params);
  }
};

const resolveDraftSubmissionStatusForItaly = async (
  accessToken: string,
  userId: number,
  year: number,
) => {
  const { data }: { data: Array<{ type: Documents.Types }> } = await DocumentsSDK.get(
    Config.API_BASE_URL,
    accessToken,
    {
      userId,
      year,
      countryCode: CountryCodes.IT,
      isSigned: true,
      types: [Documents.NonReceiptTypes.Donation, Documents.NonReceiptTypes.SubmissionContent],
      states: [Documents.States.Created],
    },
  );
  const activeDonation = data.find(
    (document) => document.type === Documents.NonReceiptTypes.Donation,
  );
  const activeConsent = data.find(
    (document) => document.type === Documents.NonReceiptTypes.SubmissionContent,
  );

  if (activeConsent && activeDonation) {
    // This is just a shortcut and won't scale, let's refactor it!
    // TODO: create github issue
    return DraftSubmissionStates.DonationAndConsentCreated;
  }

  if (activeConsent) {
    return DraftSubmissionStates.ConsentCreated;
  }

  if (activeDonation) {
    return DraftSubmissionStates.DonationCreated;
  }

  return DraftSubmissionStates.Initial;
};

export const resolveDraftSubmissionStatusForCountry = async (
  country: CountryCodes,
  year: number,
  accessToken: string,
  userId: number,
) => {
  if (country === CountryCodes.IT) {
    const status = await resolveDraftSubmissionStatusForItaly(accessToken, userId, year);
    return status;
  }

  return DraftSubmissionStates.Initial;
};

export const fetchPaymentsStatus = async (accessToken: string): Promise<StatusResponse> => {
  try {
    // @ts-ignore
    const statusByYear = await ProductSDK.getStatus(Config.API_BASE_URL, accessToken);
    return statusByYear;
  } catch (err) {
    return [];
  }
};

export type CreateSubmissionRequestWithUserId = Omit<
  CreateSubmissionRequest,
  'year' | 'countryCode'
> & {
  userId: number;
};

const createSubmissionWithDocument = async ({
  year,
  accessToken,
  data,
  docType,
}: {
  year: number;
  accessToken: string;
  data: CreateSubmissionRequestWithUserId;
  docType: Documents.NonReceiptTypes;
}) => {
  const { userId, paymentId, ...requestData } = data;
  const identificationDocumentRequestPayload = {
    year,
    countryCode: CountryCodes.IT,
    types: [docType],
    states: [Documents.States.Approved, Documents.States.Created],
    userId,
  };
  const identificationDocumentResponse = await DocumentsSDK.get(
    Config.API_BASE_URL,
    accessToken,
    identificationDocumentRequestPayload,
  );

  if (!paymentId) {
    throw new Error('Payment id is missing');
  }

  if (identificationDocumentResponse.total >= 1) {
    await SubmissionSDK.create(
      Config.API_BASE_URL,
      accessToken,
      { ...requestData, paymentId, year, countryCode: CountryCodes.IT },
      {
        timeout: Number(Config.SUBMISSION_TIMEOUT) || 30000,
      },
    );
  } else {
    logger.error('Submission error', {
      message: logErrorMessages.idDocFetchError,
    });

    throw new Error(logErrorMessages.idDocFetchError);
  }
};

const createSubmissionWithIdentification =
  (year: number) =>
  async (accessToken: string, data: CreateSubmissionRequestWithUserId): Promise<void> => {
    await createSubmissionWithDocument({
      year,
      accessToken,
      data,
      docType: Documents.NonReceiptTypes.Id,
    });
  };

export const createSubmission = createSubmissionWithIdentification;

export const getAddressMatch = (
  accessToken: string,
  countryCode: CountryCodes,
  year: number,
): Promise<GetAddressMatchResponse> => {
  const data = {
    countryCode,
    year,
  };
  return SubmissionSDK.getAddressMatch(Config.API_BASE_URL, accessToken, data);
};

export const getAssessmentForSubmission = async (
  accessToken: string,
  submissionId: number,
): Promise<TaxAssessments.TaxAssessmentData | null> => {
  try {
    // @ts-ignore
    return await SubmissionSDK.getTaxAssessment(Config.API_BASE_URL, accessToken, {
      id: submissionId,
    });
  } catch (e) {
    return null;
  }
};

export const getSubmissionStatus = (
  submission: SubmissionWithIdAndIdentificationState,
): SubmissionStatus => {
  // If submission was paused by the user, we prompt them to continue
  if (submission.deletionReasonMessage === DELETED_BY_USER_MESSAGE) {
    if (submission.identificationDocumentState === Documents.States.Rejected) {
      return SubmissionStatus.editAddress;
    }
    return SubmissionStatus.reset;
  }

  if (submission.state === SubmissionStates.Deleted) {
    return SubmissionStatus.deleted;
  }

  if (
    submission.identificationDocumentState === Documents.States.Rejected &&
    submission.state !== SubmissionStates.Submitted
  ) {
    return SubmissionStatus.reIdentify;
  }

  if (
    [
      SubmissionStates.Ready,
      SubmissionStates.Submitted,
      SubmissionStates.SubmitInProgress,
    ].includes(submission.state)
  ) {
    return SubmissionStatus.submitted;
  }

  return SubmissionStatus.received;
};

export const getExistingSubmissionYears = (
  submissions: SubmissionWithId[],
): Record<SubmissionWithId['year'], SubmissionStatus> => {
  // sort the submissions to get the latest submission status
  // for the year (in case of more than 1 attempts).
  submissions.sort((a, b) => Number(new Date(a.created)) - Number(new Date(b.created)));
  // @ts-ignore
  return submissions.reduce(
    (map, submission) => ({ ...map, [submission.year]: getSubmissionStatus(submission) }),
    {},
  );
};
