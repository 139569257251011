import React from 'react';
import { Documents } from '@taxfix/taxfix-sdk';
import { ScrollView, useBreakpointValue } from 'native-base';

import { Container, Grid, Image, NavButton, Text } from 'src/taxfix-components/src';
import { InformationButton } from 'src/components/information-button';
import { theme } from 'src/components/core';

type RenderArgs = {
  item: any;
  index: any;
};

type PrefillDocumentListScreenProps = {
  prefillDocumentsToRender: Documents.types.Document[];
  translationKeys: Record<string, string>;
  renderDocumentListItems: ({ item, index }: RenderArgs) => React.ReactElement;
  handleOnBackButtonPress(): void;
  onPressInfoButton(): void;
};

export const PrefillDocumentsResultListScreen: React.FC<PrefillDocumentListScreenProps> = ({
  prefillDocumentsToRender,
  translationKeys,
  renderDocumentListItems,
  handleOnBackButtonPress,
  onPressInfoButton,
}) => {
  const numberColumnsGrid = useBreakpointValue({ base: 1, md: 2 });
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { title, backTitle } = translationKeys;

  return (
    <Container paddingX="24px">
      {isLargeScreen && <NavButton text={backTitle} onPress={handleOnBackButtonPress} />}
      <ScrollView
        contentContainerStyle={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container
          marginTop={['24px', '24px', '40px']}
          marginBottom={['47px', '47px', '24px']}
          width="100%"
          maxW="800px"
        >
          <Text
            mb="9px"
            variant="titleXSMedium"
            value={title}
            color="greytones.textDefault"
            width="100%"
          />
          <InformationButton
            color={theme.color.primary}
            size="xsmall"
            withText
            informationText={translationKeys.infoButton}
            onPress={onPressInfoButton}
          />
        </Container>
        <Grid
          data={prefillDocumentsToRender}
          key={Date.now()}
          columns={numberColumnsGrid}
          justifyItems="center"
          width="100%"
          maxW="800px"
          renderItemBoxProps={{
            flexBasis: '140',
          }}
          renderItem={renderDocumentListItems}
        />
        {translationKeys.experimentBannerTitle && translationKeys.experimentBannerText && (
          <Container width="100%" maxW="800px">
            <Container
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              mt={{ base: '8px', md: '40px' }}
              mb="48px"
              maxWidth="380px"
              padding="20px"
              backgroundColor="greytones.background"
              borderRadius="15px"
            >
              <Container flex={1} mr={{ base: '4px', md: '20px' }}>
                <Text
                  value={translationKeys.experimentBannerTitle}
                  variant={{ base: 'titleXXSBold', md: 'titleXSBold' }}
                  color="greytones.textDefault"
                  marginBottom="8px"
                />
                <Text
                  value={translationKeys.experimentBannerText}
                  variant="bodySBook"
                  color="greytones.textLight"
                />
              </Container>
              <Image name="piggyBank" width="64px" height="64px" alt="" />
            </Container>
          </Container>
        )}
      </ScrollView>
    </Container>
  );
};
