import * as React from 'react';
import { StyleSheet } from 'react-native';

import { theme, Overlay } from '../core';

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.overlay,
  },
  dismissButton: {
    position: 'absolute',
    top: 35,
    left: -6,
  },
  clickableOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});
type Props = {
  children: React.ReactNode;
};
export const FeedbackModalComponent = ({ children }: Props) => {
  return <Overlay style={styles.container}>{children}</Overlay>;
};
