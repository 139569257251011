import * as React from 'react';

import { DocumentUploadMode } from 'src/common/constants-it';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import { RequiredDocumentsContainer } from '../../../screens/containers/digital-caf-documents/required-documents';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

export type ActionParams = {
  onNext: () => void;
  screenMode: DocumentUploadMode;
  shouldGoToPreviousScreen: boolean;
};
type Props = Navigation<
  'RequiredDocumentsDigitalCaf',
  {
    RequiredDocumentsDigitalCaf: NavigationParams<ActionParams>;
  }
>;
export const navigateTo: Action<ActionParams> = navigate('RequiredDocumentsDigitalCaf');

export const RequiredDocumentsDigitalCafRoute = ({ route }: Props): JSX.Element => {
  const { onNext, screenMode, shouldGoToPreviousScreen = false } = route.params;

  React.useEffect(() => {
    Analytics.log(AnalyticsEvent.sectionUploadReceipts, {
      screenMode,
    });
  }, []);

  const headerTitle =
    screenMode === DocumentUploadMode.INCOME_STEP
      ? 'it.digital-caf.required-documents.nav.title.income'
      : 'it.digital-caf.required-documents.nav.title.expense';

  useDefaultNavigationOptions({ id: headerTitle });

  return (
    <RequiredDocumentsContainer
      onNext={onNext}
      screenMode={screenMode}
      shouldGoToPreviousScreen={shouldGoToPreviousScreen}
    />
  );
};
