import { getNavigationActions } from '../../../../routes/config-util';

import { DebugMenuOptions, MenuOption } from './types';

export function buildApplicationOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handlePressApplication = () => {
    navigationActions.reset({
      index: 0,
      actions: [getNavigationActions().toUniversalStatus('screen')],
    });
  };

  return {
    text: 'Application',
    onPress: handlePressApplication,
    showArrow: true,
  };
}
