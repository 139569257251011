import React from 'react';

import { Container } from '../../layout/Container';

import { StyledLabelProps } from './types';

export const StyledLabel = ({ backgroundColor, ...rest }: StyledLabelProps) => {
  return (
    <Container
      borderRadius={1}
      width="111px"
      height={6}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      {...rest}
    />
  );
};
