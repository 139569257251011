import Config from 'react-native-config';
import { Prefill } from '@taxfix/italy-sdk';

import { getQuestionStoreByYearAndCountry, updateAnswer } from 'src/stores-legacy/helpers';
import { StatusMiddleware } from 'src/stores/modules/status';
import { questionFlowCategories } from 'src/common/constants-it';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { logger } from 'src/taxfix-business-logic/utils/logger';

const isLoopAnswer = (key: string): boolean => key.includes('[');

// middleware which populates QF received from /prefill-data
export const prefillQF: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const accessToken = userAuthSelectors.getAccessToken(getState());
    const userId = userAuthSelectors.getUserId(getState());
    const taxYear = settingsSelectors.selectedYear(getState()) ?? getDefaultTaxYear();

    if (!userId || !accessToken) {
      throw new Error('prefills api needs userId and accessToken');
    }

    const questionStore = getQuestionStoreByYearAndCountry(getState());
    const livingSituationCategory = questionStore.getCategory(
      questionFlowCategories.livingSituation,
    );
    const residenceAddressCategory = questionStore.getCategory(
      questionFlowCategories.residenceAddress,
    );
    const propertyCategory = questionStore.getCategory(questionFlowCategories.property);
    const depententsCategory = questionStore.getCategory(questionFlowCategories.dependants);

    if (
      !depententsCategory ||
      !livingSituationCategory ||
      !residenceAddressCategory ||
      !propertyCategory
    ) {
      throw new Error('question flow category missing');
    }

    const isQFInProgress =
      livingSituationCategory.progress > 0 ||
      residenceAddressCategory.progress > 0 ||
      propertyCategory.progress > 0 ||
      depententsCategory.progress > 0;

    // both PREFILLED and QF questions are answered all at once ONLY once
    // if users are already in progress in the journey and/or
    // their answers were already prefilled we don't populate the answers anymore
    if (isQFInProgress) {
      next();
    } else {
      const prefillData = await Prefill.getPrefillData(
        Config.API_BASE_URL,
        accessToken,
        userId,
        taxYear,
      );
      const loopResponses = [];

      for (const [key, value] of Object.entries(prefillData)) {
        if (isLoopAnswer(key)) {
          loopResponses.push({ questionId: key, value });
        } else {
          if (questionStore.nodes[value.node.questionId] && value.answer !== undefined) {
            await updateAnswer(key, value.answer, getState());
          }
        }
      }

      for (const response of loopResponses) {
        if (
          questionStore.nodes[response.value.node.questionId] &&
          response.value.answer !== undefined
        ) {
          await updateAnswer(response.questionId, response.value.answer, getState());
        }
      }

      next();
    }
  } catch (e) {
    logger.warn(e as Error, { message: 'prefillQF: error during prefill process' });
    next();
  }
};
