import { Column, Row, useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * Displays both uploaded sides of a document
 */

export const TwoSidesDocUploadContent: React.FC<Props> = ({
  title,
  leftImage,
  rightImage,
  ...otherProps
}): JSX.Element => {
  const padding = useBreakpointValue({ base: 0, md: '25px' });
  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  return (
    <Container padding={padding} maxW="100%" width="100%" {...otherProps}>
      {isLargeScreen && (
        <Text variant="titleXSBold" color="greytones.textDefault" alignSelf="center" {...title} />
      )}
      <Row mt="37px" width="100%" justifyContent="space-between">
        <Column alignItems="center" justifyContent="flex-end">
          <Image resizeMode="contain" {...leftImage} />
          <Text
            noOfLines={2}
            variant="titleXXSMedium"
            color="greytones.textDefault"
            value={leftImage.caption}
            textAlign="center"
          />
        </Column>
        <Column alignItems="center" justifyContent="flex-end">
          <Image resizeMode="contain" {...rightImage} />
          <Text
            noOfLines={2}
            variant="titleXXSMedium"
            color="greytones.textDefault"
            value={rightImage.caption}
            textAlign="center"
          />
        </Column>
      </Row>
    </Container>
  );
};
