/* eslint-disable no-unused-vars */
import '@taxfix/quizmaster/dist/types';
import * as RNMail from './web/mailer';
import { Analyticsify } from './web/analyticsify';

export const NativeModules = {
  Analyticsify,
  ResponsesBridge: {
    answer: async () => {},
    deleteResponsesWithAnswerIDsAndYears: async () => {},
    fetchAll: async () => ({}),
    skip: async () => {},
  },
  AccountBridge: {
    showOpenSource: () => {},
  },
  LocaleInfo: {
    preferredLanguages: ['en'],
    deviceLocale: 'en',
  },
  RNMail,
};
