import { ComponentType } from 'react';
import { FlatListProps, TextStyle } from 'react-native';

import { EllipsizeMode } from 'src/components/types';

export const SEPARATOR_SIZE = 1;

type RowStyleType = {
  row?: {
    minHeight?: number;
  };
  text?: TextStyle;
  arrow?: {
    tintColor?: string;
  };
};
export type ListRowComponentExtraPropsType = {
  showIcon?: boolean;
  showInfo?: boolean;
  textVariation?: string;
};
// internal props
export type RowPropsType = {
  id?: any;
  text?: string;
  testId?: string;
  iconName?: string;
  tintColor?: string;
  showArrow?: boolean;
  rightIcon?: string | null | undefined;
  numberOfLines?: number;
  translationKey?: string;
  rowStyle?: RowStyleType;
  translationValues?: Record<string, any>;
  infoTranslationKey?: string;
  onPress?: (item: any) => void;
  ellipsizeMode?: EllipsizeMode;
  infoTranslationValues?: Record<string, any>;
  showNotification?: boolean;
};
export type ListRowData<Payload> = {
  id?: any;
  testId?: string;
  text?: string;
  payload?: Payload;
  iconName?: string;
  tintColor?: string;
  showArrow?: boolean;
  translationKey?: string;
  onPress?: (item: any) => void;
  showNotification?: boolean;
};
export type ListRowPropsType<Payload> = {
  testId?: string;
  numberOfLines?: number;
  rowStyle?: RowStyleType;
  data: ListRowData<Payload>;
  ellipsizeMode?: EllipsizeMode;
  onItemSelected?: (item: ListRowData<Payload>) => void;
};
export type ListRowComponentChildrenPropsType<Payload> = {
  hide: boolean;
  data: ListRowData<Payload>;
  handleSelectItem: (item: ListRowData<Payload>) => void;
};
export type ListRowComponentPropsType<Payload> = ListRowComponentExtraPropsType & {
  children: (props: ListRowComponentChildrenPropsType<Payload>) => any;
};
export type ListRowComponentType<Payload> = ComponentType<ListRowComponentPropsType<Payload>>;
export type FlatListItemType<Payload> = ListRowComponentType<Payload> | ListRowData<Payload>;
type ListRowDataItemType<Payload> = Array<FlatListItemType<Payload>>;
export type ListProps<Payload> = {
  testId?: string;
  numberOfLines?: number;
  rowStyle?: RowStyleType;
  onEndReachedThreshold?: number;
  ellipsizeMode?: EllipsizeMode;
  removeClippedSubviews?: boolean;
  items: ListRowDataItemType<Payload>;
  onEndReached?: (() => void) | null | undefined;
  onItemSelected?: (item: ListRowData<Payload>) => void;
  listRowComponentProps?: ListRowComponentExtraPropsType;
  ListFooterComponent?: FlatListProps<Payload>['ListFooterComponent'];
  ListHeaderComponent?: FlatListProps<Payload>['ListHeaderComponent'];
  contentContainerStyle?: FlatListProps<Payload>['contentContainerStyle'];
};
