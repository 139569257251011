// TODO delete when we are able to work with multicolor icons
import React from 'react';

import {
  ERIcon,
  ERIconGrey,
  ExpenseIcon,
  ExpenseIconGrey,
  IdentificationIcon,
  IdentificationIconGrey,
  IncomeIcon,
  IncomeIconGrey,
  PaymentIcon,
  PaymentIconGrey,
  PersonalInfoIcon,
} from './Icons';
import { IconProps, Props } from './types';

// StepsIcon multicolor - coloured
const getStepsIcon = ({ type, size = '100%' }: IconProps): React.ReactElement | null => {
  switch (type) {
    case 'PersonalInfo':
      return <PersonalInfoIcon size={size} />;
    case 'Income':
      return <IncomeIcon size={size} />;
    case 'Expense':
      return <ExpenseIcon size={size} />;
    case 'Identification':
      return <IdentificationIcon size={size} />;
    case 'ER':
      return <ERIcon size={size} />;
    case 'Payment':
      return <PaymentIcon size={size} />;
    default:
      return null;
  }
};

// StepsIcon multicolor - greyscale
const getStepsIconDisabled = ({ type, size = '100%' }: IconProps): React.ReactElement | null => {
  switch (type) {
    case 'PersonalInfo':
      return <PersonalInfoIcon size={size} />;
    case 'Income':
      return <IncomeIconGrey size={size} />;
    case 'Expense':
      return <ExpenseIconGrey size={size} />;
    case 'Identification':
      return <IdentificationIconGrey size={size} />;
    case 'ER':
      return <ERIconGrey size={size} />;
    case 'Payment':
      return <PaymentIconGrey size={size} />;
    default:
      return null;
  }
};

export const StepsIcon = ({
  type,
  size = '100%',
  disabled = false,
}: Props): React.ReactElement | null => {
  return disabled ? getStepsIconDisabled({ type, size }) : getStepsIcon({ type, size });
};
