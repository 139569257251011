import React from 'react';
import { Text, useTheme } from 'native-base';

const BoldText = ({ children }: { children: string }) => {
  const { colors } = useTheme();
  return (
    <Text fontWeight="700" fontSize="16px" color={colors.greytones.title}>
      {children}
    </Text>
  );
};

const findHighlightWords = (item: string, searchTags: string[]) => {
  const modifiedItem = item.replace(/[^\w\s]/gi, '').toLowerCase();
  return searchTags.filter((keyword) => modifiedItem.includes(keyword));
};

const boldHighlightedWords = (item: string, searchTags: string[]) => {
  const highlightedWords = findHighlightWords(item, searchTags);

  return highlightedWords.length > 0 ? (
    <BoldText>{`${item} `}</BoldText>
  ) : (
    <Text>{`${item} `}</Text>
  );
};

const renderBoldText = (str: string, boldText?: string) => {
  if (!boldText) {
    return str;
  }

  const newStr = str.replace(/(<([^>]+)>)/gi, '');
  const searchTags = boldText.toLowerCase().split(' ');

  return newStr.split(' ').map((item) => boldHighlightedWords(item, searchTags));
};

type ArticleListItemTextProps = {
  children: string;
  highlight?: string;
};

export const ArticleListItemText = ({ children, highlight }: ArticleListItemTextProps) => {
  const { colors } = useTheme();
  return (
    <Text
      fontWeight={highlight ? '400' : '700'}
      fontSize="16px"
      textAlign="left"
      color={colors.greytones.textDefault}
    >
      {renderBoldText(children, highlight)}
    </Text>
  );
};

export const ArticleListItemSummary = ({ children, highlight }: ArticleListItemTextProps) => {
  const { colors } = useTheme();
  return (
    <Text
      fontWeight="400"
      fontSize="16px"
      textAlign="left"
      mt="8px"
      color={colors.greytones.textLight}
    >
      {renderBoldText(children, highlight)}
    </Text>
  );
};
