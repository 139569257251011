import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Icon, icons, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { testID } from '../common/testID';

import { Box, theme } from './core';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 115,
    height: 40,
    paddingHorizontal: 12,
    backgroundColor: theme.color.primary,
    borderRadius: 8,
  },
});

type Props = {
  onPress: () => void;
};

const AddDocumentButton: React.FC<Props> = ({ onPress }) => {
  const testId = 'add-document-button';
  const buttonText = 'it.digital-caf.required-documents.add-files-intial.button';
  const { getTranslationText } = useItalyIntl();
  return (
    <Box alignHorizontally="start" alignVertically="start" testId={testId}>
      <TouchableOpacity style={styles.container} onPress={onPress} {...testID(testId)}>
        <Icon path={icons.upload} size={5} color="white" />
        <Text variant="titleXSMedium" value={getTranslationText(buttonText)} color="white" />
      </TouchableOpacity>
    </Box>
  );
};

export { AddDocumentButton };
