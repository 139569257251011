import * as React from 'react';
import Config from 'react-native-config';
import { connect } from 'react-redux';

import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { ThumbnailButton as Thumbnail } from '../../components/thumbnail-button';

type Props = {
  accessToken: string;
  uri?: string;
  id: number;
  onPress: () => void;
  testId?: any;
};

const ThumbnailButtonContainer = ({ uri, id, accessToken, onPress }: Props) => (
  <Thumbnail
    uri={uri || `${Config.API_BASE_URL}/api/documents/${id}/thumbnail`}
    token={accessToken}
    onPress={onPress}
  />
);

const mapStateToProps = (stores: any) => ({
  accessToken: userAuthSelectors.getAccessToken(stores),
});

const ThumbnailButton = connect(mapStateToProps)(ThumbnailButtonContainer);
export { ThumbnailButton };
