import React from 'react';
import { ScrollView, useTheme } from 'native-base';
import { SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';

import { useItalyIntl } from 'src/_italy/_hooks';
import { LinkText, Section, useLargeScreenQuery } from 'src/components/core';
import {
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
} from 'src/components/notification-banner-legacy';
import { Container, Footer, NavButton, Text } from 'src/taxfix-components/src';
import AcceptTerms from 'src/components/core/AcceptTerms';
import { getDefaultTaxYear } from 'src/services/country-and-year';

import { selectors as settingsSelectors } from '../../stores/modules/settings';

type Props = {
  onOpenDocList(): void;
  onShowSPIDConsentDocument(): void;
  onSignSPIDConsent(): Promise<void>;
  onNoSpid(): void;
  onPressBackButton(): void;
  isSignError: boolean;
  isLoading: boolean;
  previewRequestedDocsList: string[];
};

export const SPIDConsentSign = ({
  onOpenDocList,
  onShowSPIDConsentDocument,
  onSignSPIDConsent,
  onNoSpid,
  onPressBackButton,
  isSignError,
  isLoading,
  previewRequestedDocsList,
}: Props): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();

  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();

  const titleVariant = isLargeScreen ? 'titleLBold' : 'titleSBold';
  const permissionTextFontSize = isLargeScreen ? 14 : 12;

  const sectionsStyles = {
    borderRadius: '15px',
    padding: '16px',
    borderWidth: '1px',
    borderColor: colors.greytones.graphicsLight,
    minHeight: '76px',
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {isSignError && (
        <Container position="absolute" zIndex={3} width="100%">
          <NotificationBannerLegacy
            notificationType={NotificationBannerLegacyType.DocumentSigningError}
          />
        </Container>
      )}
      <Container flex={1}>
        {isLargeScreen && (
          <NavButton
            text={getTranslationText('it.spid-consent-sign.back-button.text')}
            onPress={onPressBackButton}
          />
        )}
        <Container flex={1} paddingX={24} paddingTop={24}>
          <Section size="wide" centered style={{ height: '100%' }}>
            <ScrollView flex={1} contentContainerStyle={{ flexGrow: 1 }} overflow="hidden">
              <Container>
                <Text
                  variant={titleVariant}
                  value={getTranslationText('it.spid-consent-sign.title')}
                  marginBottom="8px"
                />
                <Text
                  variant="bodyMBook"
                  value={getTranslationText('it.spid-consent-sign.subtitle')}
                  color={colors.greytones.textLight}
                  marginBottom={['24px', '48px']}
                />
                <Container {...sectionsStyles} marginBottom="12px">
                  <Text
                    variant="bodySBook"
                    value={getTranslationText('it.spid-consent-sign.document-list.title')}
                    color={colors.greytones.graphicsDark}
                    marginBottom="4px"
                  />
                  {previewRequestedDocsList.map((documentTranslation) => (
                    <Text
                      key={documentTranslation}
                      variant="bodyMBook"
                    >{`•  ${documentTranslation}`}</Text>
                  ))}
                  <LinkText
                    id="it.spid-consent-sign.document-list.show-more-button.title"
                    style={{ fontSize: 14, marginLeft: 16, marginTop: 4 }}
                    onPress={onOpenDocList}
                  />
                </Container>
                <Container {...sectionsStyles}>
                  <Text
                    value={getTranslationText(
                      'it.spid-consent-sign.document-list.purpose-of-the-request.title',
                    )}
                    variant="bodyMBook"
                    color={colors.greytones.graphicsDark}
                    marginBottom="4px"
                  />
                  <Text
                    value={getTranslationText(
                      'it.spid-consent-sign.document-list.purpose-of-the-request.description',
                      {
                        year: selectedYear + 1,
                      },
                    )}
                    variant="bodyMBook"
                  />
                </Container>
              </Container>
            </ScrollView>
            <Container marginBottom={['20px', '40px']} marginTop="8px">
              <AcceptTerms
                onTerms={onShowSPIDConsentDocument}
                linkComponent={LinkText}
                textComponent={Text}
                translationKey="it.spid-consent-sign.permission-text"
                textDecorationLine="none"
                linkStyle={{ fontSize: permissionTextFontSize }}
                style={{
                  fontSize: permissionTextFontSize,
                  color: colors.greytones.textLight,
                  width: '100%',
                }}
              />
            </Container>
          </Section>
        </Container>
        <Footer
          primaryButtonTitle={getTranslationText('it.spid-consent-sign.cta-button.text')}
          onPressPrimary={onSignSPIDConsent}
          isLoading={isLoading}
          borderTopWidth={isLargeScreen ? 'xs' : {}}
          secondaryButtonProps={{ variant: 'tertiary', width: isLargeScreen ? 'auto' : 'full' }}
          secondaryButtonTitle={getTranslationText('it.spid-consent-sign.nospid-cta-button.text')}
          onPressSecondary={onNoSpid}
        />
      </Container>
    </SafeAreaView>
  );
};
