/* eslint-disable no-case-declarations */
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { RootState as SettingsRootState, selectors as settingsSelectors } from './settings';

type RootAppState = SettingsRootState;
export type CheckoutFlow = {
  refundType: string | undefined;
};
export type State = {
  checkoutFlows: { [year in string | number]?: CheckoutFlow };
};

type RootState = {
  checkoutFlow: State;
};
export const SET_CHECKOUT_FLOW = 'checkoutFlow/SET_CHECKOUT_FLOW';

type SetCheckoutFlowAction = {
  type: typeof SET_CHECKOUT_FLOW;
  checkoutFlow: CheckoutFlow;
  year: number | string;
};

type Action = SetCheckoutFlowAction;

const setCheckoutFlow =
  (checkoutFlow: CheckoutFlow) =>
  (dispatch: Dispatch<SetCheckoutFlowAction>, getState: () => RootAppState) => {
    const year = settingsSelectors.selectedYear(getState());
    dispatch({
      type: SET_CHECKOUT_FLOW,
      checkoutFlow,
      year: year as number | string,
    });
  };
export const actions = {
  setCheckoutFlow,
};

export const initial: State = {
  checkoutFlows: {},
};

export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SET_CHECKOUT_FLOW:
      return {
        ...state,
        checkoutFlows: {
          ...state.checkoutFlows,
          [action.year]: { ...action.checkoutFlow, refundType: action.checkoutFlow.refundType },
        },
      };

    default:
      return state;
  }
};

// selectors
const getCheckoutFlows = (state: RootState): { [year in string | number]?: CheckoutFlow } => {
  return state.checkoutFlow.checkoutFlows;
};

const getRefundType = createSelector(
  [settingsSelectors.selectedYear, getCheckoutFlows],
  (year, checkoutFlows): CheckoutFlow | undefined => {
    //@ts-ignore
    return checkoutFlows[year!]?.refundType;
  },
);

export const selectors = {
  getRefundType,
};
