import React from 'react';
import { TextInputProps, KeyboardTypeOptions } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';

import { testID } from '../common/testID';

import { bodyPrimaryTextStyle, theme } from './core';

type Props = {
  value: string;
  placeholder?: string;
  onChangeText: (value: string, isValid: boolean) => any;
  onBlur?: () => any;
  onFocus?: () => any;
  testId?: string;
  options: {
    mask: string;
    validator?: (value: string) => boolean;
  };
  keyboardType?: KeyboardTypeOptions;
  editable?: boolean;
  pointerEvents?: TextInputProps['pointerEvents'];
  autoFocus?: TextInputProps['autoFocus'];
  onSubmitEditing?: TextInputProps['onSubmitEditing'];
  shouldFocus?: boolean;
};
type State = {
  value: string | null | undefined;
};
export class UpperCaseMaskInput extends React.Component<Props, State> {
  state = {
    value: this.props.value,
  };

  maskedInputRef?: TextInputMask;

  textInputRef?: TextInputMask;

  componentDidUpdate(prevProps: Props) {
    const { shouldFocus } = this.props;

    if (prevProps.shouldFocus !== shouldFocus && shouldFocus && this.textInputRef) {
      (this.textInputRef as any).focus();
    }
  }

  onRawValue = (value: string) => value.replace(/\s/g, '');

  handleMaskedInputRef = (ref: TextInputMask | null | undefined) => {
    if (ref) {
      this.maskedInputRef = ref;
    }
  };

  handleTextInputRef = (ref: TextInputMask | null | undefined) => {
    if (ref) {
      this.textInputRef = ref;
    }
  };

  handleChangeText = (text: string) => {
    if (!this.maskedInputRef) return;
    const inputValue = text.toUpperCase();
    this.setState(
      {
        value: inputValue,
      },
      () => {
        const value = (this.maskedInputRef as any).getRawValue();
        const valid = (this.maskedInputRef as any).isValid();
        this.props.onChangeText(value.toUpperCase(), text.length === 0 ? true : valid);
      },
    );
  };

  render() {
    const {
      options,
      keyboardType,
      onFocus,
      onBlur,
      placeholder,
      editable,
      pointerEvents,
      autoFocus,
      onSubmitEditing,
    } = this.props;
    return (
      <TextInputMask
        ref={this.handleMaskedInputRef}
        refInput={this.handleTextInputRef}
        value={this.state.value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChangeText={this.handleChangeText}
        placeholder={placeholder}
        type="custom"
        style={[
          bodyPrimaryTextStyle,
          {
            flex: 1,
          },
        ]}
        placeholderTextColor={theme.color.fill}
        underlineColorAndroid="transparent"
        selectionColor={theme.color.primary}
        {...testID(this.props.testId)}
        options={{ ...options, getRawValue: this.onRawValue }}
        keyboardType={keyboardType}
        maxLength={options.mask.length}
        editable={editable}
        pointerEvents={pointerEvents}
        autoFocus={autoFocus}
        onSubmitEditing={onSubmitEditing}
      />
    );
  }
}
