import { DebugMenuOptions, MenuOption } from './types';

export function buildSVGIconsBrowser(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handleSVGIconsBrowser = () => {
    navigationActions.toSVGIconsBrowser('screen');
  };

  return {
    text: 'SVG Icons browser',
    onPress: handleSVGIconsBrowser,
    showArrow: true,
  };
}
