import * as React from 'react';
import { StyleSheet } from 'react-native';

import {
  Box,
  DisplayTitleText,
  BodySecondaryText,
  Button,
  Image,
  theme,
} from '../../components/core';

type Props = {
  image: string;
  titleTextId?: string;
  bodyTextId?: string;
  buttonText?: string;
  onPress?: () => void;
};
const styles = StyleSheet.create({
  screenContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  screenLayout: {
    flex: 1,
    backgroundColor: theme.color.background,
  },
  imageContainer: {
    flex: 2,
    justifyContent: 'flex-end',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    top: -20,
  },
  header: {
    textAlign: 'center',
    color: theme.color.secondaryText,
  },
  body: {
    textAlign: 'center',
    marginTop: 10,
    padding: 10,
  },
  buttonSpacer: {
    height: 50,
  },
});

const renderBottom = (buttonText: any, onPress: any) => {
  if (buttonText && onPress) {
    return <Button type="secondary" translationKey={buttonText} onPress={onPress} />;
  }

  return <Box style={styles.buttonSpacer} />;
};

const AppBlockingScreen = (props: Props) => {
  const { image, titleTextId, bodyTextId, buttonText, onPress } = props;
  return (
    <Box style={styles.screenContainer}>
      <Box style={styles.screenLayout} top={3} right={3} bottom={3} left={3} alignVertically="end">
        <Box style={styles.imageContainer}>
          <Image name={image} />
        </Box>
        <Box style={styles.textContainer}>
          <DisplayTitleText id={titleTextId} style={styles.header} />
          <BodySecondaryText id={bodyTextId} style={styles.body} />
        </Box>
        {renderBottom(buttonText, onPress)}
      </Box>
    </Box>
  );
};

export { AppBlockingScreen };
