import React, { useCallback } from 'react';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useChatPopup } from 'src/_italy/_hooks';
import { SupportButton } from 'src/taxfix-components/src/components/general/SupportButton';
import { isNative } from 'src/utils/platform';
import { useUnreadMessages } from 'src/biz-logic/zendesk/use-unread-messages';
import { zendeskService } from 'src/services/zendesk-service-initializer';

type Props = {
  screenName: string;
  iconPath?: string[];
};

export const SupportButtonContainer: React.FC<Props> = ({ screenName, iconPath }) => {
  const { handleOpenChatPopup, supportMode } = useChatPopup();
  const { messages } = useUnreadMessages(zendeskService);

  const handleButtonPress = useCallback(() => {
    handleOpenChatPopup();
    Analytics.log(AnalyticsEvent.supportButtonClicked, {
      screenName,
      supportMode,
    });
  }, [screenName, handleOpenChatPopup, supportMode]);

  return (
    <SupportButton
      onPress={handleButtonPress}
      marginRight={isNative ? '12px' : '0px'}
      iconPath={iconPath}
      showBadge
      newMessages={messages}
    />
  );
};
