import * as React from 'react';

import { NotificationsContainer } from 'src/screens/notifications/notifications-container';
import { useSkipCodePushUpdate } from 'src/hooks/skip-code-push-hook';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

export type ActionParams = {
  navigateForward: () => void;
  image?: string;
  floatingButton?: boolean;
  title?: string;
  content?: string;
  primaryButtonText?: string;
};

type Props = Navigation<
  Routes.Notifications,
  { [Routes.Notifications]: NavigationParams<ActionParams> }
>;

const NotificationsScreen: React.FC<Props> = ({ route }) => {
  const {
    navigateForward,
    image,
    floatingButton = false,
    title,
    content,
    primaryButtonText,
  } = route.params;

  useNavigationOptions({
    header: () => null,
  });

  // We have to skip code push update
  // because if users opt to receive push notifications
  // on iOS it'll display a native prompt to allow notifications
  // and this makes the update to be installed and the app displays a loading state.
  useSkipCodePushUpdate();

  return (
    <NotificationsContainer
      navigateForward={navigateForward}
      image={image}
      floatingButton={floatingButton}
      title={title}
      content={content}
      primaryButtonText={primaryButtonText}
    />
  );
};

export const { action: toNotifications, screen: notificationsScreen } = getRoute<ActionParams>({
  routeName: Routes.Notifications,
  tracking: AnalyticsEvent.sectionNotifications,
  screenComponent: NotificationsScreen,
});

export default NotificationsScreen;
