import { CountryCodes } from '@taxfix/types';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { getAnswers } from 'src/stores/hooks/answers-stores-hook';
import { getStore } from 'src/stores/util';
import { isWeb } from 'src/utils/platform';
import { getSanitizedInput } from 'src/validations/italy-input-fields';

import { ItalyPaymentType } from './stripe/types';

type BillingAddress = {
  street?: string;
  zipcode?: string;
  city?: string;
  countryCode?: string;
  state?: string | null | undefined;
};

const getBillingAddress = (): BillingAddress => {
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const {
    form730: {
      '730/ResidenzaAnagrafica/Indirizzo': street,
      '730/ResidenzaAnagrafica/NumCivico': number,
      '730/ResidenzaAnagrafica/Comune': city,
      '730/ResidenzaAnagrafica/Cap': zipcode,
      '730/ResidenzaAnagrafica/Provincia': provincia,
    },
  } = getAnswers(questionStore);

  return {
    street: getSanitizedInput(`${street} ${number}`),
    zipcode,
    city,
    countryCode: CountryCodes.IT,
    state: provincia,
  };
};

const clearPaymentUrlParams = (): void => {
  if (isWeb) {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

enum PaymentError {
  InvalidMethod = 'InvalidMethod',
}

const sendAnalyticsOnPaymentFailure = (
  paymentMethod: ItalyPaymentType,
  errorMessage: string,
  errorType: string,
): void => {
  Analytics.log(AnalyticsEvent.paymentFailed, {
    paymentType: paymentMethod,
    errorType,
    errorMessage,
  });
};

const sendAnalyticsOnAddressValidationError = () => {
  Analytics.log(AnalyticsEvent.popupShown, {
    popupName: 'BillingAddressError',
  });
};

export {
  clearPaymentUrlParams,
  getBillingAddress,
  PaymentError,
  sendAnalyticsOnPaymentFailure,
  sendAnalyticsOnAddressValidationError,
};
