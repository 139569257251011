/* eslint react/jsx-no-bind: [0] */
import * as React from 'react';

import { InputField } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import AnswerForm from './AnswerForm';

type Props = {
  label?: string;
  value: string;
  errors: {
    translationKey: string;
  }[];
  onChange: (value: string) => void;
  onConfirm: (value: string) => void;
  testId: string;
  isReady?: boolean;
  placeholder?: string;
};

export const InputWithValidation: React.FC<Props> = ({
  label,
  value,
  errors,
  onChange,
  onConfirm,
  testId,
  isReady = true,
  placeholder = '',
}) => {
  const [error] = errors;
  const { getTranslationText } = useItalyIntl();

  const form = {
    disabled: !isReady,
    onConfirm: () => onConfirm(value),
  };

  return (
    <AnswerForm {...form}>
      <InputField
        testID={testId}
        label={label}
        value={value}
        onChangeText={onChange}
        isInvalid={!!error}
        errorMessage={!!error && getTranslationText(error?.translationKey)}
        placeholder={placeholder}
      />
    </AnswerForm>
  );
};
