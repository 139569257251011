import { useTheme } from 'native-base';
import React from 'react';

import { Card, CertifiedIconSolid, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const SuccessCard: React.FC<Props> = ({
  imageSize = 67,
  title,
  subTitle,
  ...rest
}): JSX.Element => {
  const { colors } = useTheme();

  return (
    <Card variant="folder" alignItems="center" borderRadius="none" {...rest}>
      <CertifiedIconSolid fillColor={colors.brand.brandGreen} size={imageSize} mb={68} />
      <Text variant="titleXLBold" value={title} mb={24} />
      <Text variant="subtitleLMedium" color={colors.greytones.textLight} value={subTitle} />
    </Card>
  );
};
