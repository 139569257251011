import _ from 'lodash';

import { Place } from '../../types/geo';

export const CREATE_PLACES = 'CREATE_PLACES';
type CreatePlacesAction = {
  type: typeof CREATE_PLACES;
  payload: {
    places: Place[];
  };
};
export const rememberPlaces = (places: Place[]): CreatePlacesAction => ({
  type: CREATE_PLACES,
  payload: {
    places,
  },
});
type Action = CreatePlacesAction;
export type Actions = {
  rememberPlaces: typeof rememberPlaces;
};
export const actions = {
  rememberPlaces,
};
type State = {
  readonly places: ReadonlyArray<Place>;
};
export const initial: State = {
  places: [],
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case CREATE_PLACES: {
      const newAddresses = [...action.payload.places, ...state.places];

      const places = _.uniqBy(newAddresses, (address) => address.displayName);

      return {
        places,
      };
    }

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
