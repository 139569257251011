import React, { useCallback } from 'react';
import { ScrollView, Platform } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { validatePhoneNumber } from 'src/services/validations';

import { SimpleQFLayout } from '../../components/simple-qf-layout';
import FormField from '../../components/form-field-extended';
import { Box, TextInput, titleTextStyle, theme } from '../../components/core';
import { UpperCaseMaskInput } from '../../components/upper-case-mask-input';
import { validate as validateTaxId } from '../../services/tax-id-number';
import {
  isEmptyInput,
  nameValidator,
  getSanitizedInput,
  getNameFieldErrorKeys,
} from '../../validations/italy-input-fields';
import { PDField, PDFields } from '../../stores/modules/user-profile';

const validateNameField = (value: string, field: string): ErrorMessage => {
  const isEmpty = isEmptyInput(value);
  const validationResult = nameValidator(value);
  if (!validationResult.isValid || isEmpty) {
    return {
      ...getNameFieldErrorKeys(isEmpty, field),
      values: { chars: validationResult.validationErrors },
    };
  } else {
    return null;
  }
};

type ErrorMessage = { key: string; values?: { chars?: string }; testId?: string } | null;
type PDFieldHelper = {
  validate: (value: string, countryCode: CountryCodes) => ErrorMessage;
  headerTranslationKey: string;
  subheaderTranslationKey: string;
  inputLabel: string;
};

const PDFieldHelpers: Record<string, PDFieldHelper> = {
  [PDFields.firstName]: {
    validate: (value: string) => {
      return validateNameField(value, PDFields.firstName);
    },
    headerTranslationKey: 'account.profile.edit.header1',
    subheaderTranslationKey: 'account.profile.edit.subheader1',
    inputLabel: 'account.profile.screen.input1.label',
  },
  [PDFields.lastName]: {
    validate: (value: string) => {
      return validateNameField(value, PDFields.lastName);
    },
    headerTranslationKey: 'account.profile.edit.header2',
    subheaderTranslationKey: 'account.profile.edit.subheader2',
    inputLabel: 'account.profile.screen.input2.label',
  },
  [PDFields.phoneNumber]: {
    validate: (value: string) => {
      if (value && !validatePhoneNumber(value)) {
        return {
          key: 'it.digital-caf.qf.profile.phone-number.screen.select.phone-number.invalid',
        };
      }

      return null;
    },
    headerTranslationKey: 'account.profile.edit.header3',
    subheaderTranslationKey: 'account.profile.edit.subheader3',
    inputLabel: 'account.profile.screen.input3.label',
  },
  [PDFields.taxId]: {
    validate: (value: string, countryCode: CountryCodes) => {
      const emptyError = isEmptyInput(value);
      if (emptyError)
        return {
          key: 'it.personal-data.field-required',
        };

      if (!validateTaxId(value, countryCode)) {
        return {
          key: 'answers.tax-id.invalid',
        };
      }

      return null;
    },
    headerTranslationKey: 'account.profile.edit.header4',
    subheaderTranslationKey: 'account.profile.edit.subheader4',
    inputLabel: 'account.profile.screen.input4.label',
  },
};
type Props = {
  value: string;
  onSubmit: (arg0: string) => void;
  PDFieldName: PDField;
  selectedCountry: CountryCodes;
};
const inputTitleStyle = [
  titleTextStyle,
  {
    color: theme.color.primaryText,
  },
];
export const ProfileEditComponent = ({ value, onSubmit, PDFieldName, selectedCountry }: Props) => {
  const { headerTranslationKey, subheaderTranslationKey, inputLabel, validate } =
    PDFieldHelpers[PDFieldName];
  const [newValue, setNewValue] = React.useState<string>(value);
  const [error, setError] = React.useState<ErrorMessage>(null);

  const handleSubmit = useCallback<() => void>(() => {
    setError(null);
    const validationResult = validate(newValue, selectedCountry);
    if (validationResult) {
      setError(validationResult);
    } else {
      onSubmit(newValue);
    }
  }, [newValue, onSubmit, validate, selectedCountry]);

  const handleChange = useCallback(
    (input: string) => {
      if (error) setError(null);
      setNewValue(getSanitizedInput(input));
    },
    [error],
  );
  return (
    <SimpleQFLayout
      headerTranslationKey={headerTranslationKey}
      subheaderTranslationKey={subheaderTranslationKey}
      hideProgressBar
      onPressContinue={handleSubmit}
    >
      <Box flex top={3}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <FormField
            label={{
              key: inputLabel,
            }}
            error={error ? { ...error, level: 'Error' } : undefined}
            labelStyle={inputTitleStyle}
            labelTextTransform="uppercase"
          >
            {({ onBlur, onFocus }) => {
              if (PDFieldName === PDFields.firstName || PDFieldName === PDFields.lastName) {
                return (
                  <TextInput
                    value={newValue}
                    onChangeText={handleChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    style={{
                      flex: 1,
                    }}
                    maxLength={86}
                    autoFocus
                    keyboardType="web-search"
                    onSubmitEditing={handleSubmit}
                  />
                );
              }

              if (PDFieldName === PDFields.taxId) {
                return (
                  <UpperCaseMaskInput
                    options={{
                      mask: 'SSS SSS SSSSS SSSSS',
                    }}
                    keyboardType={Platform.OS === 'android' ? 'visible-password' : 'web-search'} // workaround for some Android's auto input
                    value={newValue}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChangeText={handleChange}
                    testId="text::yourEmployer::taxId"
                    autoFocus
                    onSubmitEditing={handleSubmit}
                  />
                );
              }

              return (
                <TextInput
                  value={newValue}
                  onChangeText={handleChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  style={{
                    flex: 1,
                  }}
                  autoCautoCompleteType="tel"
                  keyboardType="phone-pad"
                  autoFocus
                  onSubmitEditing={handleSubmit}
                />
              );
            }}
          </FormField>
        </ScrollView>
      </Box>
    </SimpleQFLayout>
  );
};
