export type InfoSection = {
  id: number;
  bulletPoints: number[];
};

export const moreInfoOverlaySections: InfoSection[] = [
  {
    id: 1,
    bulletPoints: [1, 2, 3, 4],
  },
  {
    id: 2,
    bulletPoints: [1, 2, 3],
  },
  {
    id: 3,
    bulletPoints: [1, 2],
  },
];
