import queryString from 'query-string';

const encodeQuery = (query: Record<string, any>): string => queryString.stringify(query);

const parseUrl = (link: string): string => queryString.parseUrl(link).url;

const parseQuery = (link: string, shouldDecode = true): Record<string, any> =>
  queryString.parseUrl(link, {
    decode: shouldDecode,
  }).query;

export { encodeQuery, parseUrl, parseQuery };
