import { $PropertyType, $Shape } from 'utility-types';
import { Dispatch } from 'redux';

import { Options, saveLegacyData, removeLegacyData } from '../utils/submission';

export const UPDATE_PERSONAL = 'partner/UPDATE_PERSONAL';
export const UPDATE_PERSONAL_SUCCESS = 'partner/UPDATE_PERSONAL_SUCCESS';
export const UPDATE_PERSONAL_ERROR = 'partner/UPDATE_PERSONAL_ERROR';
export const REMOVE_PERSONAL = 'partner/REMOVE_PERSONAL';
export const REMOVE_PERSONAL_SUCCESS = 'partner/REMOVE_PERSONAL_SUCCESS';
export const REMOVE_PERSONAL_ERROR = 'partner/REMOVE_PERSONAL_ERROR';
export type State = {
  isLoading: boolean;
  data: {
    personal: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      taxIdNumber: string;
      gender: '' | 'male' | 'female';
    };
  };
};
type UpdatePersonalAction = {
  type: typeof UPDATE_PERSONAL;
  payload: $Shape<$PropertyType<$PropertyType<State, 'data'>, 'personal'>>;
};
type RemovePersonalAction = {
  type: typeof REMOVE_PERSONAL;
};
type Action = UpdatePersonalAction | RemovePersonalAction;

const updatePersonal =
  (payload: $PropertyType<UpdatePersonalAction, 'payload'>) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_PERSONAL,
    });
    dispatch({
      type: UPDATE_PERSONAL_SUCCESS,
      payload,
    });
  };

const updatePersonalLegacy =
  (payload: $PropertyType<UpdatePersonalAction, 'payload'>, options: Options) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_PERSONAL,
    });

    try {
      await saveLegacyData(payload, { ...options, store: 'partner' });
      dispatch({
        type: UPDATE_PERSONAL_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PERSONAL_ERROR,
      });
    }
  };

const removePersonalLegacy = (options: Options) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: REMOVE_PERSONAL,
  });

  try {
    await removeLegacyData({ ...options, store: 'partner' });
    dispatch({
      type: REMOVE_PERSONAL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_PERSONAL_ERROR,
    });
    throw error;
  }
};

export const actions = {
  updatePersonal,
  updatePersonalLegacy,
  removePersonalLegacy,
};
export const initial: State = {
  isLoading: false,
  data: {
    personal: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      taxIdNumber: '',
      gender: '',
    },
  },
};
export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type) {
    case UPDATE_PERSONAL:
    case REMOVE_PERSONAL:
      return { ...state, isLoading: true };

    case UPDATE_PERSONAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, personal: { ...state.data.personal, ...action.payload } },
      };

    case UPDATE_PERSONAL_ERROR:
    case REMOVE_PERSONAL_ERROR:
      return { ...state, isLoading: false };

    case REMOVE_PERSONAL_SUCCESS:
      return { ...state, isLoading: false, data: initial.data };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
