import React from 'react';

import { IconColor, LinkButton } from 'src/components/link-button';

import navigate, { Action } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import {
  RenderSubmissionDownloadItem,
  SubmissionsDownloadContainer,
} from '../../../screens/containers/submissions-download';

export const toSubmissionsDownload: Action = navigate('SubmissionsDownload', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'SubmissionsDownload',
  },
});

const content = {
  title: 'account.it.submissions.download.title',
  subtitle: 'account.it.submissions.download.subTitle',
  noSubmissionsTitle: 'account.it.submissions.download.title-empty',
  noSubmissionsBody: 'account.it.submissions.download.body-empty',
};
const link = {
  linkTranslationId: 'account.it.submissions.download.button',
  icon: 'duotone.icon-document',
  iconBackgroundColor: 'green' as IconColor,
};

export const SubmissionsDownload = (): JSX.Element => {
  const renderSubmissionDownloadItem = ({
    submission,
    fetchAndOpenPDF,
  }: RenderSubmissionDownloadItem) => (
    <LinkButton
      link={{
        ...link,
        linkTranslationValues: {
          taxYear: submission.year,
          filingYear: submission.year + 1,
        },
      }}
      onPress={() => fetchAndOpenPDF(submission)}
    />
  );

  return (
    <SubmissionsDownloadContainer
      content={content}
      renderSubmissionDownloadItem={renderSubmissionDownloadItem}
    />
  );
};
