import * as React from 'react';
import { Linking, Keyboard, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import template from '../../assets/templates/information';
import { LocalAssetsRootPath, getLocalAssetPath } from '../utils/get-local-asset-path';
import { isWeb } from '../utils/platform';

const { iframeResize } = (isWeb && require('iframe-resizer')) || {};

type Props = React.ComponentProps<typeof WebView> & {
  content: string | any;
  contentStyles?: string;
  onLoadEnd?: () => any;
  customStyles?: any;
};
export const build = (content: string, contentStyles = ''): string => {
  let html = template.replace('##CONTENT##', content);
  html = html.replace('##CUSTOM_STYLES##', contentStyles);
  return html;
};

const handleShouldStartLoadWithRequest = (request: any) => {
  if (request && request.url && (Platform.OS === 'android' || request.navigationType === 'click')) {
    Linking.canOpenURL(request.url)
      .then(Linking.openURL(request.url) as any)
      .catch(() => {});
    return false;
  }

  return true;
};

const ASSET_PREFIX = encodeURI('^^^TAXFIX_');
const ASSET_POSTFIX = encodeURI('^^^');
const imagePlaceholderRegex = `${ASSET_PREFIX}([\\w_\\-.]+?)${ASSET_POSTFIX}`;
const imageResourceRegex = new RegExp(imagePlaceholderRegex);
const allImagePlaceholdersRegex = new RegExp(imagePlaceholderRegex, 'g');

const getReplacementForImagePlaceholder = (imagePlaceholder: string): string => {
  const imageHolder = imagePlaceholder.match(imageResourceRegex);
  const imageAssetIdentifier = (imageHolder && imageHolder[1]) || '';
  return getLocalAssetPath(imageAssetIdentifier);
};

const replacePlaceholders = (originalContent: string) => {
  return originalContent.replace(allImagePlaceholdersRegex, getReplacementForImagePlaceholder);
};

class HtmlContent extends React.Component<Props, any> {
  componentDidMount(): void {
    Keyboard.dismiss();
  }

  webviewRef: any = React.createRef<WebView>();

  handleLoadEnd = () => {
    const { onLoadEnd } = this.props;

    const isOldIE = isWeb && navigator.userAgent.indexOf('MSIE') !== -1; // Detect IE10 and below
    if (iframeResize && this.webviewRef.current) {
      iframeResize(
        {
          checkOrigin: false,
          heightCalculationMethod: isOldIE ? 'max' : 'lowestElement',
        },
        this.webviewRef.current.frameRef,
      );
    }

    if (onLoadEnd) onLoadEnd();
  };

  render() {
    const { content, contentStyles, customStyles = {}, scrollEnabled = true } = this.props;
    const html = build(replacePlaceholders(content), contentStyles);
    return (
      <WebView
        allowFileAccess
        allowUniversalAccessFromFileURLs
        originWhitelist={['*']}
        /* The opacity 0.99 is a work around for a bug on webview component
      reported since 18.03.19 on github: https://github.com/react-native-community/react-native-webview/issues/429
      This avoids android app to crash when user navigates back from a screen that implements webview. */
        style={{
          ...customStyles,
          backgroundColor: 'transparent',
          ...Platform.select({
            android: {
              opacity: 0.99,
            },
          }),
        }}
        source={{
          html,
          baseUrl: LocalAssetsRootPath,
        }}
        scrollEnabled={scrollEnabled}
        onLoadEnd={this.handleLoadEnd}
        onShouldStartLoadWithRequest={handleShouldStartLoadWithRequest}
        ref={this.webviewRef}
      />
    );
  }
}

export { HtmlContent };
export default HtmlContent;
