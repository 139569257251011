import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { WithScreenSizes, Box, Section, withScreenSizes } from '../../components/core';
import HtmlContent from '../../components/html-content';
import { FullscreenLoader } from '../../components/loading';
import { parseMarkdown } from '../../services/markdown-parser';
import { isWeb } from '../../utils/platform';
import { PdfContainer } from '../../containers/pdf';

import { NavigationParams } from './information.types';

type Props = NavigationParams &
  WithScreenSizes & {
    children?: React.ReactNode;
    intl: IntlShape;
    renderSupportButton?: () => React.ReactNode;
  };
type State = {
  loading: boolean;
};

class InformationContainerUndecorated extends React.Component<Props, State> {
  state = {
    loading: true,
  };

  loadingTimeout: undefined | ReturnType<typeof setTimeout>;

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout as any);
  }

  handleLoadEnd = () => {
    this.loadingTimeout = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 700);
  };

  render() {
    const {
      webSize = 'wide',
      content: rawContent,
      isMarkdown,
      translationKey,
      translationValues = {},
      intl,
      renderSupportButton = () => null,
      isLargeScreen,
    } = this.props;
    if (typeof rawContent !== 'string' && rawContent?.isPDFUri) {
      return (
        <PdfContainer
          onError={(error) => logger.log(error)}
          source={{
            uri: rawContent.uri,
          }}
        />
      );
    }
    let content = '';

    if (rawContent) {
      content = rawContent as string;
    }

    if (translationKey) {
      content = intl.formatMessage(
        {
          id: translationKey,
        },
        translationValues,
      );
    }

    content = isMarkdown ? parseMarkdown(content) : content;
    return (
      <Section size={webSize} centered>
        <Box top={isLargeScreen ? 9 : 0} bottom={isWeb ? 4 : 0} flex>
          <Box flex>
            <HtmlContent
              content={content}
              contentStyles={this.props.contentStyles}
              onLoadEnd={this.handleLoadEnd}
            />
          </Box>
          {this.props.children}
          {renderSupportButton()}
          {this.state.loading && <FullscreenLoader />}
        </Box>
      </Section>
    );
  }
}

export const InformationContainer = withScreenSizes(injectIntl(InformationContainerUndecorated));
