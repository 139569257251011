import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import { SupportButtonContainer } from 'src/containers/support-button/support-button-container';

import { persistRouteOptions, deleteObsoleteRouteOptions } from '../../services/navigation-options';
import CloseButton from '../../containers/close-button';
import HeaderButton from '../../containers/header-button';
import { isWeb } from '../../utils/platform';
import { getScreenOptions } from '../../utils/getScreenOptions';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { extractScreenName } from '../utils';

type CustomNavigationOptions = StackNavigationOptions & {
  headerOverlay?: React.ReactNode;
  setNewOptions?: boolean;
  showSupportButton?: boolean;
};

const createDefaultNavigationOptions = (
  defaultOptions: CustomNavigationOptions,
): StackNavigationOptions => {
  const {
    // @ts-ignore
    params: { isModal },
  } = useRoute();

  const options = { ...defaultOptions };

  if (isModal && !options.headerLeft) {
    const HeaderLeft = () => (
      <HeaderButton left>
        <CloseButton />
      </HeaderButton>
    );
    options.headerLeft = HeaderLeft;
  }

  return options;
};

export const useNavigationOptions = (options: CustomNavigationOptions): void => {
  const navigation = useNavigation();
  const { setNewOptions = true } = options;
  React.useLayoutEffect(() => {
    setNewOptions && navigation.setOptions(getScreenOptions(options));
    const routeKey = persistRouteOptions(navigation, options);
    return () => {
      deleteObsoleteRouteOptions(navigation, routeKey);
    };
  }, [navigation, options]);
};

export type HeaderTitleTranslation = {
  id: string;
  values?: Record<string, string | number>;
};

export const useDefaultNavigationOptions = (
  headerTitleTranslation?: HeaderTitleTranslation,
  overrideOptions: StackNavigationOptions = {},
): void => {
  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);
  const intl = useIntl();
  const navigation = useNavigation();
  const navigationState = navigation.getState();
  const screenName = extractScreenName(navigationState.routes[navigationState.index].name);

  const headerTitle = headerTitleTranslation
    ? intl.formatMessage(
        {
          id: headerTitleTranslation.id,
        },
        {
          ...headerTitleTranslation.values,
        },
      )
    : '';

  const headerRight = () => {
    return isAuthenticated ? <SupportButtonContainer screenName={screenName} /> : null;
  };

  const options = createDefaultNavigationOptions({
    headerRight: overrideOptions?.headerRight ?? headerRight,
    headerTitle,
  });

  useNavigationOptions({ ...options, ...overrideOptions });
};

export const useWebNavigationOptions = (options: any): void => {
  // Because the platform can't change during runtime it's
  // safe to put it as a condition in front of a hook call
  if (!isWeb) return;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useNavigationOptions(options);
};
