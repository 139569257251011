import { flagsMigration } from 'src/screens/status-screen/middlewares/flags-migration';
import {
  checkNotificationsPermissions,
  shouldSkipAskPermission,
} from 'src/screens/status-screen/middlewares/notifications';
import { profileMigration } from 'src/screens/status-screen/middlewares/profile-migration';
import { redirectToNotifications } from 'src/screens/status-screen/middlewares/redirect';
import { checkAndUpdateUserData } from 'src/screens/status-screen/middlewares/user-profile';
import { StatusConfig, StatusStates } from 'src/stores/modules/status';

import { checkPreseasonCUResult, redirectPreseasonCUResult } from '../middlewares/cu-documents';
import { checkPreseasonQF, redirectPreseasonQF } from '../middlewares/preseason-question-flow';
import { QFChangeMigration } from '../middlewares/qf-change-migration';
import { checkPreseasonSPID, redirectPreseasonSPID } from '../middlewares/spid-login';

export const statusConfig: StatusConfig = {
  title: {
    id: 'status.it.title',
  },
  subtitle: {
    id: 'status.it.title',
  },
  steps: [
    {
      id: StatusStates.StoresMigrations,
      middlewares: [profileMigration, flagsMigration, QFChangeMigration],
    },
    {
      id: StatusStates.EnableNotifications,
      skip: [shouldSkipAskPermission],
      middlewares: [checkNotificationsPermissions],
      redirect: redirectToNotifications,
    },
    {
      id: StatusStates.CheckAndUpdateUserData,
      middlewares: [checkAndUpdateUserData],
    },
    {
      id: StatusStates.PreseasonQuestionFlow,
      middlewares: [checkPreseasonQF],
      redirect: redirectPreseasonQF,
    },
    {
      id: StatusStates.PreseasonSPIDLogin,
      middlewares: [checkPreseasonSPID],
      redirect: redirectPreseasonSPID,
    },
    {
      id: StatusStates.PreseasonCUDocument,
      middlewares: [checkPreseasonCUResult],
      redirect: redirectPreseasonCUResult,
    },
  ],
};
