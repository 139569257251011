import * as React from 'react';

import { ScreenName } from 'src/types/screen-name';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';

import { SVGIconsBrowserContainer } from './svg-icons-browser-screen';

export const toSVGIconsBrowser: Action = navigate(ScreenName.SVGIconsBrowser, {
  name: AnalyticsEvent.sectionShown,
  params: { sectionName: ScreenName.SVGIconsBrowser },
});

export const SVGIconsBrowser = (): JSX.Element => {
  useDefaultNavigationOptions(undefined);

  return <SVGIconsBrowserContainer />;
};
