import {
  Platform,
  LayoutAnimation,
  UIManager,
  StyleSheet,
  LayoutAnimationConfig,
} from 'react-native';

const androidDropShadow = {
  backgroundColor: 'white',
  elevation: 10,
};
export const webDropShadow = {
  boxShadow: '0 -1px 2px rgba(0,0,0,.2)',
};
const iOSDropShadow = {
  backgroundColor: 'white',
  shadowColor: '#000000',
  shadowRadius: 2,
  shadowOpacity: 0.2,
};
let easing: LayoutAnimationConfig = {
  duration: 350,
  create: {
    type: LayoutAnimation.Types.easeIn,
  },
  update: {
    type: LayoutAnimation.Types.easeOut,
  },
};

if (Platform.OS === 'android') {
  UIManager.setLayoutAnimationEnabledExperimental(true);
  easing = LayoutAnimation.Presets.easeInEaseOut;
}

const easeInEaseOutOnNextRenderCycle = (): void => {
  LayoutAnimation.configureNext(easing);
};

export const theme = {
  easeInEaseOutOnNextRenderCycle,
  styles: StyleSheet.create({
    dropShadow: {
      // @ts-ignore
      ...Platform.select({
        ios: {
          ...iOSDropShadow,
          shadowOffset: {
            width: 0,
            height: -1,
          },
        },
        android: androidDropShadow,
        // @ts-ignore
        web: webDropShadow,
      }),
    },
    materialDropShadow: {
      ...Platform.select({
        ios: {
          // @ts-ignore
          borderBottomWidth: 1,
          borderBottomColor: '#E8E8E8',
        },
        android: androidDropShadow,
      }),
    },
  }),
  color: {
    lightError: '#FFEDEA',
    primary: '#32C850',
    primaryOpacity20: 'rgba(50, 200, 80, 0.2)',
    secondary: '#E3F6EB',
    primaryText: '#3B3B3B',
    secondaryText: '#9B9B9B',
    greytonesText: '#707070',
    greyFillIcon: '#949494',
    lightGrey: '#F6F6F6',
    lightText: '#FFFFFF',
    disabledText: '#D8D8D8',
    fill: '#D5D5D5',
    lightFill: '#FFFFFF',
    darkFill: '#979797',
    border: '#E8E8E8',
    neutralBackground: '#E8E8E8',
    primaryBackgroundText: '#165D24',
    primaryBackgroundLight: '#CBDCCE',
    background: '#F8F8F8',
    backgroundGray: '#F3F3F3',
    errorTextLight: '#FF5C31',
    errorBorderLight: '#FF5C31',
    errorBackground: '#FF5C31',
    errorTextDark: '#871200',
    errorBorderDark: '#871200',
    warningBackgroundLight: '#FEF2E3',
    warningBackground: '#FF9B00',
    warningBackgroundPressed: '#E07C10',
    warningBannerBackground: '#FFFFFF',
    warningText: '#FFB20E',
    debtTextLight: '#FF5C31',
    debtBorderLight: '#FF5C31',
    debtBackground: '#FF5C31',
    debtTextDark: '#871200',
    debtBorderDark: '#871200',
    blueText: '#00BCF4',
    blueFill: '#CCF2FD',
    darkBlueIcon: '#059AFD',
    darkBlueIconBackground: '#CDEBFF',
    orangeText: '#F9A646',
    orangeFill: '#FEF2E4',
    orangeFillPressed: '#F0E1D1',
    orangeBackground: '#FEF2E3',
    overlay: 'rgba(0,0,0,0.6)',
    overlayDark: 'rgba(0,0,0,0.85)',
    overlayWhite: 'rgba(255,255,255,0.7)',
    black: '#000',
    positiveBackground: '#E3F6EB',
    partner: '#6B98FF',
    partnerBackground: 'rgba(107, 152, 255, 0.1)',
    partnerSecondary: '#E7EEFE',
    partnerBackgroundText: '#203681',
    dependent: '#935FC1',
    dependentSecondary: '#EBE2F4',
    dependentBackgroundText: '#3E1567',
    disabledGray: '#BDBDBD',
    fairPink: '#FFEAEA',
    orange: '#FF5C31',
    botticelli: '#D5E5EC',
    blueDianne: '#264653',
    transparent: 'transparent',
    blueBackground: '#6995FF',
    salmonBackground: '#FF9497',
    yellowBackground: '#FFD300',
    yellowBackgroundLight: '#FFF6CC',
    secondaryAccentGreenLight: '#E1F8E6',
    secondaryFeedbackWarningRed: '#FF5B23',
    instantRefundWarningAlert: '#E6DAF1',
    backgroundDark: '#222222',
    backgroundDarkTransparent: 'rgba(34, 34, 34, 0.24)',
    backgroundBlueTransparent: '#CDEBFF66',
    backgroundGreenLight: '#F1FFF4',
    disabledButtonBackground: '#E1E1E1',
    backgroundLight: '#F6F6F6',
    greenBackground: '#CCF4E1',
    greenBackgroundLight: '#E5FDDB',
    blueMatisse: '#1D64A7',
    blueBadgeBackground: '#E4EEFB',
    feedbackPositiveDark: '#115E21',
    feedbackWarning: '#E87400',
    feedbackError: '#D60A3D',
    feedbackErrorLight: '#FCEBE6',
    lightBorder: '#DDDDDD',
    seaGreen: '#00C769',
    reminderWarningText: '#E87400',
    reminderWarningBackground: '#FEEDDA',
    progressBarColor: 'rgba(255,255,255,0.5)',
    progressBarUnfilledColor: 'rgba(255,255,255,0.1)',
    greytonesTitle: '#111111',
    pinkFull: '#FF4773',
    aliceBlue: '#EDF5FB',
    errorBackgroundItaly: '#FFDAE3',
    secondaryBackgroundLight: '#E9F5FF',
  },
  zIndex: {
    negative: -1,
    neutral: 0,
    positive: 1,
  },
  fontFamily: {
    bold: 'CircularStd-Bold',
    medium: 'CircularStd-Medium',
    book: 'CircularStd-Book',
  },
  fontSizes: {
    xs: 16,
    s: 20,
    m: 24,
    l: 28,
    x: 30,
  },
};

export type Theme = typeof theme;
