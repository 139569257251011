export class InvalidLocalConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidLocalConfigError';
  }
}

export class FirebaseRemoteConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FirebaseRemoteConfigError';
  }
}
