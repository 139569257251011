import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { CountryCodes } from '@taxfix/types';

import localizedDocs from '../../../assets/docs';
import { privacyStyles } from '../../../assets/templates/custom-styles/privacy';
import Analytics, { AnalyticsEvent, EventName } from '../../biz-logic/analytics';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import {
  LegalAcceptanceType,
  actions as userLegalActions,
  selectors as userLegalSelectors,
} from '../../stores/modules/user-legal';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';

import { LegalChange } from './legal-change';

export type LegalChangeData = {
  tac: {
    isUpdated: boolean;
    currentVersion: string;
    acceptedVersion?: string | null;
  };
  privacy: {
    isUpdated: boolean;
    currentVersion: string;
    acceptedVersion?: string | null;
  };
};
type Props = {
  onConfirm: () => Promise<void>;
  intl: IntlShape;
  data: LegalChangeData;
  selectedCountry: CountryCodes;
  isLoading: boolean;
  error: Error | null | undefined;
  bindedUserLegalActions: typeof userLegalActions;
  accessToken: string;
} & WithNavigation;

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
  isLoading: userLegalSelectors.isLoading(stores),
  error: userLegalSelectors.isError(stores),
  accessToken: userAuthSelectors.getAccessToken(stores),
});

const mapDispatchToProps = (dispatch: any) => ({
  bindedUserLegalActions: bindActionCreators(userLegalActions, dispatch),
});

export class LegalChangeContainer extends React.Component<Props> {
  handleConfirm = async () => {
    const { selectedCountry, accessToken, data } = this.props;

    if (data.tac.isUpdated) {
      await this.updateLegal('tac', data.tac, accessToken, selectedCountry);
    }

    if (data.privacy.isUpdated) {
      await this.updateLegal('privacy', data.privacy, accessToken, selectedCountry);
    }

    await this.props.onConfirm();
  };

  handleFullTac = () => {
    this.props.navigationActions.toInformation('modal', {
      content: localizedDocs.terms(this.props.intl.locale),
      isHeaderShown: false,
    });
  };

  handleFullPrivacy = () => {
    this.props.navigationActions.toInformation('modal', {
      content: localizedDocs.privacy(this.props.intl.locale),
      isHeaderShown: false,
      contentStyles: privacyStyles,
    });
  };

  updateLegal = async (
    legalType: LegalAcceptanceType,
    legalData: Record<string, any>,
    accessToken: string,
    selectedCountry: CountryCodes,
  ) => {
    try {
      await this.props.bindedUserLegalActions.createUserLegalAction(
        accessToken,
        selectedCountry,
        legalType,
        legalData.currentVersion,
      );
      Analytics.log(AnalyticsEvent[`${legalType}ChangeUpdateSuccessful` as EventName], {
        oldTacVersion: legalData.acceptedVersion,
        newTacVersion: legalData.currentVersion,
      });
    } catch (error) {
      Analytics.log(AnalyticsEvent[`${legalType}ChangeUpdateFailed` as EventName], {
        oldTacVersion: legalData.acceptedVersion,
        newTacVersion: legalData.currentVersion,
        errorMessage: error,
      });
    }
  };

  render() {
    return (
      <LegalChange
        error={this.props.error}
        loading={this.props.isLoading}
        onConfirm={this.handleConfirm}
        onFullTac={this.handleFullTac}
        onFullPrivacy={this.handleFullPrivacy}
      />
    );
  }
}
export default compose<any>(
  injectIntl,
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps),
)(LegalChangeContainer);
