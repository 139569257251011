import { View, useTheme } from 'native-base';
import React from 'react';

import { Text } from 'src/taxfix-components/src';
import { supportCenterIcons } from 'src/taxfix-components/src/Icons';

import { RateIcon, RateIconProps } from './rate-icon';

export type QuestionScreenProps = {
  onRateSelected: (rate: number) => void;
  ratingTitle: string;
};

export const QuestionScreen = ({ onRateSelected, ratingTitle }: QuestionScreenProps) => {
  const { colors } = useTheme();
  const rateIconsConfig = [
    {
      rateValue: 2,
      icon: supportCenterIcons.sadFace,
    },
    {
      rateValue: 1,
      icon: supportCenterIcons.neutralFace,
    },
    {
      rateValue: 0,
      icon: supportCenterIcons.happyFace,
    },
  ];

  const getRateIcon = (iconConfig: Partial<RateIconProps>) => {
    return RateIcon({ ...iconConfig, onRateSelected });
  };
  return (
    <>
      <Text fontSize="14px" color={colors.greytones.textDefault}>
        {ratingTitle}
      </Text>
      <View flexDirection="row" alignItems="center" marginTop="8px">
        {rateIconsConfig.map((iconConfig) => getRateIcon(iconConfig))}
      </View>
    </>
  );
};
