import React from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { Platforms } from '@taxfix/types';

import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import { idToTranslationKeyDownloadApp } from 'src/i18n/country-info';
import { DownloadApp, CountryIconStyle } from 'src/screens/download-app/download-app-view';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { SharedAppCountries } from 'src/services/country-and-year';

export const DownloadAppContainer: React.FC = () => {
  const selectedCountry = useSelector(settingsSelectors.selectedCountry) as SharedAppCountries;
  const appCountrySplitConfig = useSelector(firebaseSelectors.getAppCountrySplitConfig);

  const { countryIcon, downloadApp } = appCountrySplitConfig[selectedCountry];
  const defaultDownloadButtonImage =
    Platform.OS === Platforms.android
      ? 'download-app.play-store-base'
      : 'download-app.app-store-base';
  const { img = defaultDownloadButtonImage, url } = downloadApp[Platform.OS];
  const { title, subTitle, description } =
    idToTranslationKeyDownloadApp[selectedCountry as SharedAppCountries];
  const countryIconStyle: CountryIconStyle = `icon${selectedCountry}`;

  return (
    <DownloadApp
      title={title}
      subTitle={subTitle}
      description={description}
      downloadButtonImg={img}
      downloadButtonUrl={url}
      countryIcon={countryIcon}
      countryIconStyle={countryIconStyle}
    />
  );
};
