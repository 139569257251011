import * as React from 'react';

import { UploadProgressOverlay } from '../components/upload-progress-overlay';
import { OnPhotoTakenSuccess } from '../services/camera-upload-documents';

type UploadProgressCallback = (progressEvent: ProgressEvent) => void;
type Props = {
  file: any;
  uploadFile: OnPhotoTakenSuccess;
  onClose: () => void;
};
type State = {
  progress: number;
};

class UploadProgressOverlayContainer extends React.Component<Props, State> {
  state = {
    progress: 0,
  };

  componentDidMount() {
    const { file, uploadFile, onClose } = this.props;
    uploadFile(file, this.onUploadProgress, onClose);
  }

  onUploadProgress: UploadProgressCallback = (progressEvent) => {
    const { total, loaded } = progressEvent;
    const progress = Math.trunc((loaded * 100) / total);
    this.setState(
      {
        progress,
      },
      () => {
        if (progress === 100) {
          setTimeout(() => {
            this.props.onClose();
          }, 1000);
        }
      },
    );
  };

  render() {
    const { progress } = this.state;
    return (
      <UploadProgressOverlay
        titleId="upload.progress.overlay.title"
        progressId="upload.progress.overlay.progress"
        translationValues={{
          progress,
        }}
      />
    );
  }
}

export { UploadProgressOverlayContainer };
