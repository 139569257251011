import React from 'react';
import { FileRejection } from 'react-dropzone';
import { StyleSheet, View } from 'react-native';

import { Container } from 'src/taxfix-components/src';
import { SmallText, theme, TinySecondaryText, useLargeScreenQuery } from 'src/components/core';
import { MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB } from 'src/utils/constants';
import { isNative } from 'src/utils/platform';

import { Option } from '../../components/input/button-overlay';
import { UploadDocumentButton } from '../../components/upload-document-button';

import { FileDropZoneWeb } from './file-drop-zone-web.web';

type Props = {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  buttonProps: { onPress?: () => void; buttonOptions?: Option[]; color?: string };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 250,
    width: 194,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.color.disabledText,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
  },
  primaryText: {
    color: theme.color.greytonesText,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  secondaryText: { marginBottom: 42, textAlign: 'center', color: theme.color.greyFillIcon },
});

const mobileStyles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
    marginTop: 32,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.color.disabledText,
    borderRadius: 20,
  },
  primaryText: styles.primaryText,
  secondaryText: {
    marginBottom: 16,
    textAlign: 'center',
    color: theme.color.greyFillIcon,
  },
});

/**
 * Common file upload component for web and native
 * @param onDrop this function is needed for the web
 * @param buttonProps this object is needed for the native components
 */

export const FileUploadCommon: React.FC<Props> = ({ buttonProps, onDrop }) => {
  const isLargeScreen = useLargeScreenQuery();
  const allowedTypesTranslationKey = 'it.digital-caf.required-documents.web.dropzone.allowed-types';
  const {
    container: containerStyles,
    primaryText: primaryTextStyles,
    secondaryText: secondaryTextStyle,
  } = isLargeScreen ? styles : mobileStyles;

  //for web browsers, on a PC or Mac or mobile-web
  if (!isNative) {
    return <FileDropZoneWeb onDrop={onDrop} />;
  }

  // for mobile app - tablets
  if (isLargeScreen) {
    return (
      <View style={containerStyles}>
        <SmallText
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={primaryTextStyles}
          id="it.digital-caf.required-documents.web.dropzone.text"
        />
        <TinySecondaryText
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={secondaryTextStyle}
          id={allowedTypesTranslationKey}
          testId={allowedTypesTranslationKey}
          values={{
            mb: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
          }}
        />
        <UploadDocumentButton {...buttonProps} />
      </View>
    );
  }

  // for mobile app - small screen
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <View style={containerStyles}>
      <Container mt={16} mb={16}>
        <UploadDocumentButton {...buttonProps} />
      </Container>
      <TinySecondaryText
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={secondaryTextStyle}
        testId={allowedTypesTranslationKey}
        id={allowedTypesTranslationKey}
        values={{
          mb: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
        }}
      />
    </View>
  );
};
