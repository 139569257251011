import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { ErrorType, logger } from 'src/taxfix-business-logic/utils/logger';

import { FullscreenLoader } from '../../components/loading';
import {
  Payment,
  PaymentState,
  PaymentStates,
  Product,
  ProductVariation,
} from '../../types/payment';
import { Box } from '../../components/core';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import initial from '../../stores/store/initial';
import { getPaymentFromPaymentId } from '../../services/submissions';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { RetryableErrorBanner } from '../../containers/retryable-error-banner';

import { ProductResolver, StripeRedirectStatus } from './payment-route.common';

const updatableStates: Partial<PaymentState>[] = [
  PaymentStates.Created,
  PaymentStates.Error,
  PaymentStates.Cancelled,
];

type Props = {
  year: number;
  countryCode: CountryCodes;
  children: (arg0: { product?: Product }) => React.ReactNode;
  accessToken: string;
  nextNavigationAction: (params?: any) => void;
  productVariation: ProductVariation | undefined;
  productResolver: ProductResolver;
  redirectStatus?: StripeRedirectStatus;
} & WithNavigation;

const mapStateToProps = (state: typeof initial) => ({
  year: settingsSelectors.selectedYear(state),
  countryCode: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
});

type State = {
  product?: Product;
  payment?: Payment;
  showError?: boolean;
  isLoading: boolean;
};

class ProductInfo extends React.Component<Props, State> {
  state = {
    product: undefined,
    payment: undefined,
    showError: false,
    isLoading: true,
  };

  componentDidMount() {
    this.getProduct();
  }

  getProduct = async () => {
    const {
      year,
      countryCode,
      nextNavigationAction,
      accessToken,
      productVariation,
      productResolver,
      redirectStatus,
    } = this.props;
    let product: Product;
    let payment;
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          if (!year) {
            throw new Error('cannot fetch product without year');
          }

          const resolvedProduct = await productResolver(
            accessToken,
            year,
            countryCode,
            productVariation,
          );

          if (resolvedProduct) {
            product = resolvedProduct;
          } else {
            throw new Error('Product resolver not returning a product');
          }
        } catch (error) {
          logger.error(error as ErrorType, {
            message: 'Fetching product failed',
          });
          this.setState({
            showError: true,
            isLoading: false,
          });
          return;
        }

        // If product is free, bypass payment step
        if (product.amount === 0) {
          nextNavigationAction();
          return;
        }

        if (!product.paymentId) {
          this.setState({
            product,
            showError: false,
            isLoading: false,
          });
          return;
        }

        // In case payment exists, we need to fetch details and check state,
        // so we can bypass payment screens if it already processed
        try {
          payment = await getPaymentFromPaymentId(accessToken, product.paymentId);

          // we need to pass product and payment to the payment screen,
          // so we are able to send paymentSuccess event for PayPal
          if (
            payment.state === PaymentStates.Completed &&
            redirectStatus === StripeRedirectStatus.succeeded
          ) {
            this.setState({
              product,
              payment,
              showError: false,
              isLoading: false,
            });
            nextNavigationAction({
              paymentId: payment.id,
              paymentAmount: payment.amountCents / 100,
            });
            return;
          }

          if (!updatableStates.includes(payment.state)) {
            logger.debug('Existing payment has been processed already', payment.state);
            this.setState({
              showError: false,
              isLoading: false,
            });
            nextNavigationAction({
              paymentId: payment.id,
              paymentAmount: payment.amountCents / 100,
            });
            return;
          }

          logger.debug('Fetched existing payment', payment);
          this.setState({
            product,
            payment,
            showError: false,
            isLoading: false,
          });
        } catch (error) {
          logger.error(error as ErrorType, {
            message: `Payment ${product.paymentId} exists, but fetching details failed`,
          });
          this.setState({
            product,
            showError: true,
            isLoading: false,
          });
        }
      },
    );
  };

  handleRetry = () => {
    this.getProduct();
  };

  render() {
    const { product, payment, showError, isLoading } = this.state;
    return (
      <Box flex>
        {isLoading && <FullscreenLoader />}
        <RetryableErrorBanner onRetry={this.handleRetry} isVisible={showError} />
        {!isLoading &&
          !showError &&
          this.props.children({
            product,
            // @ts-ignore
            payment,
          })}
      </Box>
    );
  }
}

export const ProductInfoContainer = compose<any>(
  connect(mapStateToProps),
  withNavigation,
)(ProductInfo);
