import React from 'react';
import { isEmpty } from 'lodash';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { StackNavigationProp } from '@react-navigation/stack';
import { CountryCodes } from '@taxfix/types';

import { useWebNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { BackButton } from 'src/components/back-button';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { FooterProps } from './footer.types';
import { Box, Button, Section, useLargeScreenQuery, theme, BodySecondaryText } from './core';

const styles = StyleSheet.create({
  buttonAlignStart: {
    alignItems: 'flex-start',
  },
  buttonAlignEnd: {
    alignItems: 'flex-end',
  },
  stretchedButton: {
    width: '100%',
  },
});

type InnerProps = FooterProps & {
  canGoBack?: boolean;
  hasSidebar?: boolean;
  footerOptions?: FooterOptions;
};

export type FooterOptions = Record<string, unknown> & {
  centerContent?: boolean;
  centerButton?: boolean;
};

export const FooterComponent = ({
  translationKey,
  onPress,
  children,
  isLoading = false,
  isDisabled = false,
  testId,
  text,
  values,
  canGoBack,
  onGoBack,
  applySpaceBottom,
  applyWebContentSeparation,
  type = 'default',
  backButtonTranslationKey,
  hasSidebar = false,
  outlinedButton,
  additionalInfo,
  footerOptions,
}: InnerProps): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();
  const buttonWidth = isLargeScreen ? { width: 150 } : {};
  const footerStyle =
    type === 'stretched' ? [] : isLargeScreen ? [styles.buttonAlignStart] : [styles.buttonAlignEnd];

  const footer = (
    <Box
      top={2}
      bottom={applySpaceBottom ? 4 : 2}
      left={2}
      right={2}
      style={footerStyle}
      direction="row"
    >
      <Box style={{ width: '100%' }}>
        {!isEmpty(children) && (
          <Box direction="row" bottom={applySpaceBottom ? 0 : 2} alignVertically="center">
            {children}
          </Box>
        )}
        <Box
          direction={isLargeScreen ? 'row' : 'column'}
          alignVertically={footerOptions?.centerContent ? 'center' : 'start'}
          alignHorizontally={footerOptions?.centerButton ? 'center' : 'start'}
        >
          {additionalInfo && !canGoBack && (
            <Box
              right={isLargeScreen ? 2 : 0}
              bottom={isLargeScreen ? 0 : 2}
              alignVertically="center"
            >
              <BodySecondaryText id={additionalInfo} />
            </Box>
          )}
          {canGoBack && onGoBack && type !== 'stretched' && (
            <Box right={2}>
              <BackButton
                onPress={onGoBack}
                translationKey={backButtonTranslationKey}
                style={buttonWidth}
              />
            </Box>
          )}
          {onPress &&
            (outlinedButton ? (
              <Button
                style={type === 'stretched' ? styles.stretchedButton : buttonWidth}
                onPress={onPress}
                translationKey={translationKey}
                floating
                loading={isLoading}
                disabled={isDisabled}
                testId={testId}
                values={values}
                fillColor={theme.color.lightFill}
                textColor={theme.color.primary}
                borderColor={theme.color.primary}
              >
                {text}
              </Button>
            ) : (
              <Button
                style={type === 'stretched' ? styles.stretchedButton : buttonWidth}
                onPress={onPress}
                translationKey={translationKey}
                floating
                loading={isLoading}
                disabled={isDisabled}
                testId={testId}
                values={values}
              >
                {text}
              </Button>
            ))}
        </Box>
      </Box>
    </Box>
  );
  const contentSeparator = applyWebContentSeparation ? (
    <Box
      style={[
        {
          backgroundColor: theme.color.border,
          height: 1,
          opacity: 0.8,
        },
        theme.styles.dropShadow,
      ]}
    />
  ) : null;
  return (
    <View>
      {contentSeparator}
      <Section size={type === 'stretched' || hasSidebar ? 'narrow' : 'wide'} centered={!hasSidebar}>
        {footer}
      </Section>
    </View>
  );
};
export const renderWebFooter = (navigation: StackNavigationProp<{}>, props: InnerProps = {}) => {
  const canGoBack =
    !props.isBackButtonHidden && (Boolean(props.onGoBack) || navigation.canGoBack());
  const handleGoBack = props.onGoBack || navigation.goBack;
  return <FooterComponent {...props} canGoBack={canGoBack} onGoBack={handleGoBack} />;
};
export const Footer = (props: FooterProps) => {
  const countryCode = useSelector(settingsSelectors.selectedCountry);
  const isBackButtonHidden = countryCode === CountryCodes.IT;

  useWebNavigationOptions({
    footer: (navigation: any, hasSidebar: boolean) =>
      renderWebFooter(navigation, { ...props, hasSidebar, isBackButtonHidden }),
  });
  return null;
};
export const OverlayFooter = (props: FooterProps) => (
  <FooterComponent {...props} canGoBack={Boolean(props.onGoBack)} />
);
// This is to match mobile api
export const FoldableFooter = Footer;
