import React, { ComponentType } from 'react';

import { ErrorFallbackComponent as ErrorFallbackComponentIT } from 'src/components/error-fallback';

import { RootContainer } from './root';

export const ErrorBoundaryFallbackContainer: ComponentType = () => {
  return (
    //@ts-ignore
    <RootContainer>
      <ErrorFallbackComponentIT />
    </RootContainer>
  );
};
