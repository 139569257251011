import React from 'react';
import { Path, Svg } from 'react-native-svg';

//TODO: Import this image from ui-library
const LogoIcon = () => (
  <Svg width="120" height="49" fill="none" viewBox="0 0 120 49">
    <Path
      fill="#32C850"
      fillRule="evenodd"
      d="M52.049 47.697l-6.7-5.805 23.319-26.793 6.892 5.805L52.05 47.697zm-.96-32.152c3.22 0 5.796 2.488 5.796 5.678 0 3.126-2.577 5.677-5.797 5.677-3.092 0-5.668-2.551-5.668-5.677a5.663 5.663 0 015.668-5.678zm18.734 31.64c3.22 0 5.797-2.55 5.797-5.676 0-3.19-2.577-5.678-5.797-5.678a5.663 5.663 0 00-5.669 5.678c0 3.125 2.577 5.677 5.669 5.677z"
      clipRule="evenodd"
    />
  </Svg>
);

export default LogoIcon;
