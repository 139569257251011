import * as React from 'react';
import { injectIntl } from 'react-intl';
import type { NumberFormatOptions } from '@formatjs/ecma402-abstract';

import { LocaleInfo, injectLocaleInfo } from 'src/i18n/locale-info';
import { LanguageCode } from 'src/types';

import NumberInput from './number-input';

type Props = {
  onBlur?: (event: any) => any;
  onFocus?: (event: any) => any;
  onChange?: (event: any) => any;
  onChangeText?: (value: string) => any;
  onChangeValue?: (value: string) => any;
  value?: string | null | undefined;
  locale?: LanguageCode;
  options?: NumberFormatOptions;
  Component?: any;
  color?: string;
  testId?: string;
  localeInfo: LocaleInfo;
  autoFocus?: boolean;
  icon?: (onPress: () => void) => React.ReactElement;
};

class NumberInputContainer extends React.PureComponent<Props> {
  inputRef: typeof NumberInput | null | undefined;

  setInputRef = (ref: typeof NumberInput | null | undefined) => {
    this.inputRef = ref;
  };

  focus = () => {
    if (this.inputRef && this.inputRef.focus) {
      this.inputRef.focus();
    }
  };

  render() {
    const { localeInfo, locale, ...rest } = this.props;
    const localization = locale || localeInfo.locale;
    return <NumberInput locale={localization} ref={this.setInputRef} {...rest} />;
  }
}

export default injectIntl(injectLocaleInfo(NumberInputContainer));
