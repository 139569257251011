import { NavigationState, Route } from '@react-navigation/native';

export const getCurrentRoute = (
  navigationState?: NavigationState,
): Route<any> | Record<any, any> => {
  if (!navigationState) {
    return {};
  }

  const route = navigationState.routes[navigationState.index];

  // dive into nested navigators
  if (route.state) {
    return getCurrentRoute(route.state as any);
  }

  return route;
};
