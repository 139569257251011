import React from 'react';

import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { ScreenName } from 'src/types/screen-name';

import navigate, { Action, Navigation, NavigationParams } from '../../../routes/navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { RouteParams } from '../types';

import { IdCardUploadContainerDecorated } from './container';

export const toIdCardUpload: Action = navigate(ScreenName.IdCardUpload, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.IdCardUpload,
  },
});

type Props = Navigation<
  'PrefillDocumentUploadReview',
  {
    PrefillDocumentUploadReview: NavigationParams<RouteParams>;
  }
>;

export const IdCardUpload: React.FC<Props> = ({ route }) => {
  const { onNext } = route.params;

  useDefaultNavigationOptions({ id: 'identity-upload.id-card.nav.title' });

  return <IdCardUploadContainerDecorated onNext={onNext} />;
};
