import { getZendeskToken } from '@taxfix/operations-sdk/dist/support';

import { ContextType, ErrorType } from 'src/taxfix-business-logic/utils/logger';
import { isMobileWeb } from 'src/utils/platform';

import { IZendeskService, IZendeskServiceConfig } from './types';

export class ZendeskService implements IZendeskService {
  private isInitalized = false;
  private isLoggingIn = false;
  private logError: (error: ErrorType, params: ContextType) => void;
  private currentZendeskUser: number | undefined;

  constructor(zendeskServiceConfig: IZendeskServiceConfig) {
    const { logger } = zendeskServiceConfig;
    this.logError = (error, params) => {
      logger.error(error, { ...params });
    };
  }

  init(): Promise<void> {
    if (this.isInitalized) {
      return Promise.resolve();
    }
    this.isInitalized = true;
    return Promise.resolve();
  }

  async login(baseURL: string, accessToken: string, userId?: number): Promise<void> {
    if (!this.isInitalized || this.isLoggingIn) {
      return Promise.resolve();
    }
    this.isLoggingIn = true;
    try {
      if (!accessToken) {
        this.isLoggingIn = false;
        return Promise.resolve();
      }
      const { token } = await getZendeskToken(baseURL, accessToken);

      if (!token) {
        throw new Error('Support API returned no token');
      }

      window['zE']('messenger', 'loginUser', function (callback: (token: string) => void) {
        callback(token);
      });
      window['zE']('messenger:set', 'locale', 'it');
      this.currentZendeskUser = userId;
    } catch (error) {
      this.logError(error as Error, { message: 'Error while  getting zendesk service token' });
    } finally {
      this.isLoggingIn = false;
    }
  }

  async logout(): Promise<void> {
    if (!this.isInitalized || !this.currentZendeskUser) {
      return Promise.resolve();
    }
    window['zE']('messenger', 'logoutUser');

    this.currentZendeskUser = undefined;
  }

  getLoggedInZendeskUser(): number | undefined {
    return this.currentZendeskUser ? this.currentZendeskUser : undefined;
  }

  async openChat(): Promise<void> {
    if (!this.isInitalized || !this.currentZendeskUser) {
      return Promise.resolve();
    }
    window['zE']('messenger', 'open');
  }

  async getUnreadMessages(): Promise<number> {
    if (!this.isInitalized) {
      return Promise.resolve(0);
    }
    let messages = 0;
    window['zE']('messenger:on', 'unreadMessages', function (count: number) {
      messages = count;
    });
    return messages;
  }

  onUnreadMessagesChanged(
    listener: (messages: number) => void,
    options: { openChat: boolean },
  ): { removeSuscription: () => void } {
    const { openChat } = options;
    if (!this.isInitalized) {
      return { removeSuscription: () => {} };
    }

    window['zE']('messenger:on', 'unreadMessages', function (count: number) {
      listener(count);
      if (!isMobileWeb && openChat && count) {
        window['zE']('messenger', 'open');
      }
    });
    return { removeSuscription: () => {} };
  }
}
