import { getQuestionStoreByYearAndCountry } from '../../stores-legacy/helpers';
import { getStore } from '../../stores/util';
import {
  getNodesByIds,
  getQuizmasterLight,
  QuizmasterLight,
} from '../../utils/with-quizmaster-light';

export const useQuizmasterLight = (nodeIds: string[]): QuizmasterLight => {
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const nodes = getNodesByIds(questionStore, nodeIds);
  return getQuizmasterLight(questionStore, nodes);
};
