import { CountryCodes } from '@taxfix/types';
import { useTheme } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Container } from 'src/taxfix-components/src';
import { AnswerComponentProps } from 'src/components/answers/types';
import { Footer } from 'src/components/footer';
import PlacesAutocomplete from 'src/containers/places-autocomplete';
import { fetchSuggestions, placesSearchResultType } from 'src/services/google-maps-places';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { Suggestion } from 'src/types/geo';
import { isWeb } from 'src/utils/platform';
import { ItalySearch } from 'src/_italy/quizmaster/address-question/address-search';

type Props = AnswerComponentProps;

export const ItalyCitySearch: React.FC<Props> = ({ year, question, value, onAnswer }) => {
  const [searchTerm, setSearchTerm] = useState(value ?? '');
  const language = useSelector(settingsSelectors.selectedLocale) ?? 'en';
  const theme = useTheme();

  const extractCityNameFromSuggestion = (result: Suggestion) => {
    return result.name.split(',')[0];
  };

  const handlePressResult = (result: Suggestion) => {
    const city = extractCityNameFromSuggestion(result);
    onAnswer(question, city);
  };

  return (
    <Container>
      <PlacesAutocomplete
        searchTerm={searchTerm}
        fetchSuggestions={fetchSuggestions({
          language,
          countryCode: CountryCodes.IT,
          resultType: placesSearchResultType.city,
        })}
        render={(suggestionResults) => {
          return (
            <ItalySearch
              value={searchTerm}
              color={theme.colors.brand.brandGreen}
              searchResults={suggestionResults}
              onChange={setSearchTerm}
              onPressResult={handlePressResult}
              searchFor="city"
            />
          );
        }}
      />
      {isWeb && <Footer />}
    </Container>
  );
};
