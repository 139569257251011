import {
  CWBIRequestDocumentTypes,
  PrefillDocumentsJsonTypes,
  PrefillDocumentsPdfTypes,
  ProductBundleValues,
} from 'src/common/constants-it';

export const defaultConfig = {
  taxSeason: {
    IT: {
      '2018': {
        startDateSoft: new Date('2019-01-01T00:00:00.000Z'),
        startDateHard: new Date('2019-01-01T00:00:00.000Z'),
        endDateSoft: new Date('2019-07-14T16:00:00.000Z'),
        endDateHard: new Date('2020-07-22T10:00:00.000Z'),
        enabled: false,
        metadata: {
          trafficBlocked: false,
        },
      },
      '2019': {
        startDateSoft: new Date('2020-02-15T00:00:00.000Z'),
        startDateHard: new Date('2020-06-02T00:00:00.000Z'),
        endDateSoft: new Date('2020-09-24T16:00:00.000Z'),
        endDateHard: new Date('2020-09-27T21:59:00.000Z'),
        enabled: false,
        metadata: {
          trafficBlocked: false,
        },
      },
      '2020': {
        startDateSoft: new Date('2020-11-24T00:00:00.000Z'),
        startDateHard: new Date('2021-02-28T00:00:00.000Z'),
        endDateSoft: new Date('2021-09-19T00:00:00.000Z'),
        endDateHard: new Date('2021-09-26T00:00:00.000Z'),
        enabled: false,
        metadata: {
          trafficBlocked: false,
        },
      },
      '2021': {
        startDateSoft: new Date('2022-02-16T00:00:00.000Z'),
        startDateHard: new Date('2022-05-15T09:00:00.000Z'),
        endDateSoft: new Date('2022-12-27T22:59:00.000Z'),
        endDateHard: new Date('2022-12-28T22:59:00.000Z'),
        enabled: false,
      },
      '2022': {
        startDateSoft: new Date('2023-02-10T23:00:00.000Z'),
        startDateHard: new Date('2023-06-29T00:00:00.000Z'),
        endDateSoft: new Date('2023-08-31T22:59:00.000Z'),
        endDateHard: new Date('2023-11-10T12:00:00.000Z'),
        enabled: false,
      },
      '2023': {
        startDateSoft: new Date('2023-12-01T23:00:00.000Z'),
        startDateHard: new Date('2023-12-21T10:00:00.000Z'),
        endDateSoft: new Date('2024-09-01T00:00:00.000Z'),
        endDateHard: new Date('2024-09-30T21:59:00.000Z'),
        enabled: true,
      },
    },
  },
  cwbiFolderRequest: {
    documentList: ['MOD_CUK'],
  },
  maintenance: {
    IT: false,
  },
  minimumVersion: {
    DE: {
      ios: '1.107.0',
      android: '1.107.0',
      web: '2.0.0',
    },
    IT: {
      ios: '1.114.3',
      android: '1.114.3',
      web: '2.0.0',
    },
  },
  blacklistDynamicLink: {
    values: [],
  },
  customerSupport: {
    DE: {
      articleViewEnabled: false,
      articleRatingEnabled: false,
      searchEnabled: false,
      emailActiveMethod: 0,
      FAQS_TEST: {
        enable: false,
        articles: [],
      },
      chatSupport: {
        mobEnabled: true,
        webEnabled: true,
        timeZone: 'Europe/Berlin',
        workingHours: [
          {
            start: -1,
            end: -1,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 11,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: -1,
            end: -1,
          },
        ],
      },
      phoneSupport: {
        mobEnabled: false,
        webEnabled: true,
        timeZone: 'Europe/Berlin',
        phoneNumber: '+49 30 25559635',
        contactWebUrl: '',
        messages: {
          en: 'You can reach our support by phone Mondays to Thursdays from 10:00 am to 7:00 pm and Fridays between 10:00 am and 5:00 pm.',
          de: 'Telefonisch erreichst du unseren Support von Montag bis Donnerstag zwischen 10:00 Uhr und 19:00 Uhr sowie Freitags zwischen 10:00 Uhr und 17:00 Uhr.',
        },
        workingHours: [
          {
            start: -1,
            end: -1,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: -1,
            end: -1,
          },
        ],
      },
      whatsappSupport: {
        mobEnabled: false,
        webEnabled: false,
        whatsappBusinessUrl: 'https://wa.me/390473980110',
      },
      helpCenterButtonOrder: ['FAQ', 'email', 'chat'],
    },
    IT: {
      emailActiveMethod: 0,
      FAQS_TEST: {
        enable: false,
        articles: [],
      },
      chatSupport: {
        mobEnabled: false,
        webEnabled: false,
      },
      phoneSupport: {
        mobEnabled: false,
        webEnabled: false,
        timeZone: 'Europe/Berlin',
        phoneNumber: '+39 0473 980110',
        contactWebUrl: 'https://taxfix.it/contatti',
        messages: {
          en: 'Phone Service Hours:\nMonday-Thursday 10:00-19:00\nFriday 10:00-17:00',
          it: 'Orari servizio telefonico:\nLunedì-Giovedì 10:00-19:00\nVenerdì 10:00-17:00',
        },
        workingHours: [
          {
            start: -1,
            end: -1,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 19,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: -1,
            end: -1,
          },
        ],
      },
      whatsappSupport: {
        mobEnabled: true,
        webEnabled: true,
        whatsappBusinessUrl: 'https://wa.me/390473980110',
      },
    },
    ES: {
      chatSupport: {
        mobEnabled: true,
        webEnabled: true,
        timeZone: 'Europe/Madrid',
        workingHours: [
          {
            start: -1,
            end: -1,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 11,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: 10,
            end: 17,
          },
          {
            start: -1,
            end: -1,
          },
        ],
        cardIndex: 1,
      },
      phoneSupport: {
        mobEnabled: true,
        webEnabled: true,
        messages: {
          en: 'Available Mon-Fri,\n9am - 9pm',
          es: 'Disponible Lun-Vie,\n9am - 9pm',
        },
        phoneNumber: '+34911232858',
        cardIndex: 2,
      },
      emailSupport: {
        mobEnabled: true,
        webEnabled: true,
        email: {
          en: 'support@taxfix.es',
          es: 'hola@taxfix.es',
        },
        subjectLine: {
          en: 'Taxfix support request',
          es: 'Solicitud de ayuda de Taxfix',
        },
        cardIndex: 3,
      },
      faqSupport: {
        mobEnabled: true,
        webEnabled: true,
        url: {
          en: 'https://taxfix.com/es-es/faq',
          es: 'https://taxfix.com/es-es/faq',
        },
        cardIndex: 0,
      },
    },
  },
  mapsKey: 'QUl6YVN5QW9uSmJodWJZcnpHN1A1ZWo1U2VROEZCSTZBYlVPNkhv',
  skipLegalChange: {
    DE: true,
    IT: true,
  },
  appCountrySplit: {
    DE: { enabled: false, downloadApp: {} },
    IT: {
      enabled: false,
      downloadApp: {
        ios: { url: 'https://app.adjust.com/od0we22' },
      },
    },
  },
  italySkipMandatoryDocuments: {
    enabled: false,
  },
  italyTaxExpertsLinks: {
    alessandra: 'https://www.linkedin.com/in/alessandra-birolo-7b940924/',
    stephan: 'https://www.linkedin.com/in/stephan-grossmann-504989134/',
  },
  italyEndOfSeasonBanner: {
    enabled: false,
    articleLink:
      'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/scadenza-730-2024-date-e-termini?s=scadenze&x=0&y=0',
  },
  italyTaxGuideArticles: {
    basics: {
      getStarted: 'https://taxfix.it/come-funziona-taxfix-guida',
      documents730:
        'https://taxfix.it/guide-e-consigli/documenti-per-il-730/altri-documenti/documenti-730-quali-e-dove/',
      deductions: 'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/730-credito-debito',
    },
    goodToKnow: {
      howToReadTaxReturn:
        'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/spese-detraibili-e-deducibili-730',
      news: 'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/dichiarazione-redditi-2024-novita',
      mandatoryTaxReturn:
        'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/chi-deve-fare-il-730',
      deadlines:
        'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/scadenza-730-2024-date-e-termini',
    },
    curiosities: {
      bonus100euro: 'https://taxfix.it/guide-e-consigli/guide-al-730/tasse/bonus-cento-euro',
      musicBonus: 'https://taxfix.it/guide-e-consigli/guide-al-730/le-basi/730-precompilato',
      irpefReform: 'https://taxfix.it/guide-e-consigli/guide-al-730/tasse/irpef-2024',
      housingBonus:
        'https://taxfix.it/guide-e-consigli/guide-al-730/bonus-e-agevolazioni/bonus-mobili',
    },
  },
  dataDeletionRequestItaly: {
    enabled: false,
  },
  cwbiPrivacyPolicyUrlIdentifier: { value: 'privacy_policy' },
  italySelfCertificateEnabled: false,
  italyProductsPaymentConfig: {
    default: {
      [ProductBundleValues.instant]: {
        voucher: '',
        fullPrice: 9.9,
        discountPrice: 9.9,
        isFree: false,
      },
      [ProductBundleValues.guided]: {
        voucher: '',
        fullPrice: 49.9,
        discountPrice: 49.9,
        isFree: false,
      },
    },
    SPIDTimeout: {
      [ProductBundleValues.guided]: {
        voucher: '',
        fullPrice: 49.9,
        discountPrice: 49.9,
        isFree: false,
      },
    },
  },
  italyInstantProductVouchers: {
    AMICI50T: 'AMICI50T-instant',
    AMICI25TAX: 'AMICI25TAX-instant',
    '730FACILE5': '730FACILE5-instant',
    TAXFIXERS20: 'TAXFIXERS20-instant',
    QONTO50: 'QONTO50-instant',
    'ONEDAY-rx0': 'ONEDAY-rx0-instant',
    TAXFIXONEDAY20: 'TAXFIXONEDAY20-instant',
    STMicroelectronics35: 'STMicroelectronics35-instant',
    ESTAX10: 'ESTAX10-instant',
    IOSAMICI20: 'IOSAMICI20-instant',
    SPETAX15: 'SPETAX15-instant',
  },
  italyModelloRedditiSignEnabled: false,
  italyIsDeclarationTypeUNI: false,
  enableItalyPaypal: { enabled: false },
  italyCwbiMockServerConfig: {
    mockServerEnabled: false,
    whitelistedTaxIds: [],
    mockServerURLIframeEvent: '',
  },
  italyPaymentScreen2024Enabled: true,
  italyCWBIDocumentsDownloadConfig: {
    syncDownload: [
      {
        cwbiDocType: CWBIRequestDocumentTypes.MOD_CUK,
        taxfixPdfType: PrefillDocumentsPdfTypes.CU_PDF,
        taxfixJsonType: PrefillDocumentsJsonTypes.CU_JSON,
        fromYear: 2024,
        toYear: 2024,
        userEmailRequired: false,
        translation: 'CU del 2024',
      },
      {
        cwbiDocType: CWBIRequestDocumentTypes.MOD_730_PRE,
        taxfixPdfType: PrefillDocumentsPdfTypes.MOD_730_PRE_PDF,
        taxfixJsonType: PrefillDocumentsJsonTypes.MOD_730_PRE_JSON,
        fromYear: 2024,
        toYear: 2024,
        userEmailRequired: true,
        translation: 'Precompilata del 2024',
      },
      {
        cwbiDocType: CWBIRequestDocumentTypes.DATI_SINTESI_PRE,
        taxfixJsonType: PrefillDocumentsJsonTypes.DATI_SINTESI_PRE_JSON,
        userEmailRequired: true,
        translation: 'Foglio informativo',
      },
    ],
    asyncDownload: [
      {
        cwbiDocType: CWBIRequestDocumentTypes.VISURA_CATASTALE_ANALITICA,
        taxfixPdfType: PrefillDocumentsPdfTypes.VISURA_CATASTALE_ANALITICA_PDF,
        taxfixJsonType: PrefillDocumentsJsonTypes.VISURA_CATASTALE_ANALITICA_JSON,
        fromYear: 2023,
        toYear: 2023,
        userEmailRequired: false,
        translation: 'Visura catastale',
      },
      {
        cwbiDocType: CWBIRequestDocumentTypes.SPESA_SANITARIA,
        taxfixJsonType: PrefillDocumentsJsonTypes.SPESA_SANITARIA_JSON,
        fromYear: 2023,
        toYear: 2023,
        userEmailRequired: false,
        translation: 'Spese sanitarie del 2023',
      },
      {
        cwbiDocType: CWBIRequestDocumentTypes.MOD_730,
        taxfixPdfType: PrefillDocumentsPdfTypes.MOD_730_PDF,
        taxfixJsonType: PrefillDocumentsJsonTypes.MOD_730_JSON,
        fromYear: 2023,
        toYear: 2023,
        userEmailRequired: true,
        translation: 'Dichiarazione dei redditi 2023',
      },
      {
        cwbiDocType: CWBIRequestDocumentTypes.MOD_UNI,
        taxfixPdfType: PrefillDocumentsPdfTypes.MOD_UNI_PDF,
        taxfixJsonType: PrefillDocumentsJsonTypes.MOD_UNI_JSON,
        fromYear: 2023,
        toYear: 2023,
        userEmailRequired: true,
        translation: 'Dichiarazione dei redditi 2023',
      },
    ],
  },
  italyDashboardInfoBanner: {
    enabled: false,
    text: '',
  },
  italyPaymentScreenBannerEnabled: false,
  italySPIDUrlErrorTimeout: 60000,
  italyDownloadPrefillDocumentsErrorTimeout: {
    timeout: 95000,
    enabled: true,
  },
  italyNoSPIDExperienceThresholds: {
    initialLoadTimeout: 60000,
    providerSelectionTimeout: 90000,
    loginWithProviderTimeout: 120000,
  },
  italyLoadingOverlayConfig: {
    messages: [],
    messageInterval: 5000,
  },
  italyCWBIBaseURL: 'https://taxfix.cassettofiscale.com',
  enabledCodePush: { IT: true },

  translations: {
    /* added only for type compatability and should stay empty */
  },
  italyFreelancerExperience: {
    enabled: true,
    surveyUrl: 'https://tally.so/r/n0vkKN',
    originUrl: 'https://tally.so',
    events: ['Tally.FormSubmitted'],
  },
};

// https://console.firebase.google.com/u/1/project/taxfix-147413/config
// Firebase Remote Config - Keys
export enum configKeys {
  cwbiFolderRequest = 'cwbiFolderRequest',
  cwbiPrivacyPolicyUrlIdentifier = 'cwbiPrivacyPolicyUrlIdentifier',
  taxSeason = 'taxSeason',
  maintenance = 'maintenance',
  minimumVersion = 'minimumVersion',
  translations = 'translations',
  blacklistDynamicLink = 'blacklistDynamicLink',
  enabledCodePush = 'enabledCodePush',
  mapsKey = 'mapsKey',
  skipLegalChange = 'skipLegalChange',
  appCountrySplit = 'appCountrySplit',
  customerSupport = 'customerSupport',
  italySkipMandatoryDocuments = 'italySkipMandatoryDocuments',
  italyEndOfSeasonBanner = 'italyEndOfSeasonBanner',
  italyTaxExpertsLinks = 'italyTaxExpertsLinks',
  italyTaxGuideArticles = 'italyTaxGuideArticles',
  dataDeletionRequestItaly = 'dataDeletionRequestItaly',
  italySelfCertificateEnabled = 'italySelfCertificateEnabled',
  italyProductsPaymentConfig = 'italyProductsPaymentConfig',
  italyInstantProductVouchers = 'italyInstantProductVouchers',
  italyModelloRedditiSignEnabled = 'italyModelloRedditiSignEnabled',
  italyIsDeclarationTypeUNI = 'italyIsDeclarationTypeUNI',
  enableItalyPaypal = 'enableItalyPaypal',
  italyCwbiMockServerConfig = 'italyCwbiMockServerConfig',
  italyPaymentScreen2024Enabled = 'italyPaymentScreen2024Enabled',
  italyCWBIDocumentsDownloadConfig = 'italyCWBIDocumentsDownloadConfig',
  italyDashboardInfoBanner = 'italyDashboardInfoBanner',
  italyPaymentScreenBannerEnabled = 'italyPaymentScreenBannerEnabled',
  italySPIDUrlErrorTimeout = 'italySPIDUrlErrorTimeout',
  italyDownloadPrefillDocumentsErrorTimeout = 'italyDownloadPrefillDocumentsErrorTimeout',
  italyLoadingOverlayConfig = 'italyLoadingOverlayConfig',
  italyNoSPIDExperienceThresholds = 'italyNoSPIDExperienceThresholds',
  italyCWBIBaseURL = 'italyCWBIBaseURL',
  italyFreelancerExperience = 'italyFreelancerExperience',
}

export type ConfigKey = configKeys;
export const keys = Object.values(configKeys);
