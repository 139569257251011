import React from 'react';
import { useBreakpointValue } from 'native-base';

import { InputField, Container } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { Place, Suggestion } from 'src/types/geo';
import AddressList from 'src/components/answer/address-list';
import { placesSearchResultType } from 'src/services/google-maps-places';
import { testID } from 'src/common/testID';

type SearchForOptions = keyof typeof placesSearchResultType;

type Props = {
  value?: string;
  // NOTE: `any` is here because the format depends on fields
  previousPlaces?: Array<any>;
  searchResults: Array<any>;
  onChange: (text: string) => void;
  onPressPrevious?: (place: Place) => void;
  onPressResult: (suggestion: Suggestion) => void;
  onPressManual?: () => void;
  error?: string;
  color?: string;
  feedback?: React.ReactElement;
  minCharacters?: number;
  searchFor?: SearchForOptions;
};

export const ItalySearch = ({
  value = '',
  previousPlaces = [],
  searchResults = [],
  error,
  color,
  feedback,
  minCharacters = 3,
  searchFor = placesSearchResultType[placesSearchResultType.address],
  ...props
}: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  const label = getTranslationText(
    searchFor === placesSearchResultType[placesSearchResultType.address]
      ? 'answers.address.address'
      : 'answers.city.label',
  );
  const placeholder = getTranslationText(
    searchFor === placesSearchResultType[placesSearchResultType.address]
      ? 'answers.address.placeholder'
      : 'answers.city.placeholder',
  );

  const paddingX = useBreakpointValue({ base: '20px', md: 0 });
  return (
    <Container flex={1} paddingX={paddingX}>
      <Container>
        <InputField
          label={label}
          isInvalid={!!error}
          placeholder={placeholder}
          mb={1}
          value={value}
          onChangeText={props.onChange}
          autoCorrect={false}
          editable
          autoFocus
          maxLength={1000}
          selectionColor={color}
          {...testID('answer::address:input')}
        />
      </Container>
      {value.length >= minCharacters || previousPlaces.length || searchResults.length ? (
        <Container>
          <AddressList
            color={color}
            previousPlaces={previousPlaces}
            searchResults={searchResults}
            onPressPreviousPlace={props.onPressPrevious}
            onPressSearchResult={props.onPressResult}
            onPressManual={props.onPressManual}
            error={error}
          />
        </Container>
      ) : (
        feedback
      )}
    </Container>
  );
};
