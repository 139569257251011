export enum Transition {
  MoveLinear,
  Move,
  FadeInOut,
}
export enum Position {
  Top,
  Bottom,
  Center,
}

type BannerProps = {
  backgroundColor?: string;
  contentView: React.ReactNode | null;
  duration?: number;
  onPress?: () => void;
  position?: Position;
  transitions?: Transition[];
};

export type BannerConfig = BannerProps & {
  disableHideOnPress?: boolean;
};
export type ToastBannerProps = BannerProps & {
  hideRequested: boolean;
  onPostHide: (isMounted: boolean) => void;
};

export type WithToastBannerTogglerProps = {
  showBanner: (configArg: BannerConfig) => void;
  hideBanner: () => void;
};
