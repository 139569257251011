import React from 'react';

import { Container, NavButton, Text } from 'src/taxfix-components/src';
import { PdfContainer } from 'src/containers/pdf';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import { Footer } from './footer';

type TranslationKeys = {
  backNavigationText: string;
  title: string;
  subtitle: string;
  footerCheckboxKey: string;
  footerButtonDisabledKey: string;
  footerButtonActiveKey: string;
};

type Props = {
  isLargeScreen: boolean;
  navigationBack: () => void;
  translationKeys: TranslationKeys;
  pdfUri: string;
  onSign: () => void;
};

export const ModelloRedditiSignComponent: React.FC<Props> = ({
  isLargeScreen,
  navigationBack,
  translationKeys,
  pdfUri,
  onSign,
}) => {
  const {
    backNavigationText,
    title,
    subtitle,
    footerCheckboxKey,
    footerButtonDisabledKey,
    footerButtonActiveKey,
  } = translationKeys;
  const titleVariant = isLargeScreen ? 'titleMBold' : 'titleSBold';
  const subtitleVariant = isLargeScreen ? 'bodyMBook' : 'bodySBook';
  const borderWidth = isLargeScreen ? 0 : 1;
  return (
    <Container flex={1}>
      {isLargeScreen && <NavButton onPress={navigationBack} text={backNavigationText} />}
      <Container alignItems="center" justifyContent="center" flex={4} paddingX={[20, 0]}>
        <Container alignItems={'flex-start'} paddingTop={[80]}>
          <Text variant={titleVariant} value={title} paddingBottom="12px" />
          <Text
            variant={subtitleVariant}
            value={subtitle}
            color="greytones.graphicsDark"
            paddingBottom={[0, '40px']}
          />
        </Container>
        <Container height={'100%'} width={'100%'} borderWidth={borderWidth} borderColor={'#707070'}>
          <PdfContainer
            style={{ width: '100%' }}
            source={{ uri: pdfUri }}
            onError={(e) => {
              logger.warn(e as Error, {
                message: `Error on loading pdf Modello Redditi screen`,
              });
            }}
          />
        </Container>
      </Container>
      <Container justifyContent={'flex-end'} flex={1}>
        <Footer
          onSign={onSign}
          buttonActiveKey={footerButtonActiveKey}
          buttonDisabledKey={footerButtonDisabledKey}
          checkboxKey={footerCheckboxKey}
        />
      </Container>
    </Container>
  );
};
