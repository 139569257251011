import React from 'react';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useItalyIntl } from 'src/_italy/_hooks';

import { SpidTimeoutNextStepsScreen } from './spid-timeout-next-steps-screen';
import { getStepsContent } from './steps';

export const toSpidTimeoutNextSteps: Action = navigate(ScreenName.SpidTimeoutNextSteps, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.SpidTimeoutNextSteps,
  },
});

export const SpidTimeoutNextSteps = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { getNavigationActions, navigation, navigationActions, safeResetNavigation } =
    useNavigation();

  const steps = React.useMemo(() => getStepsContent(getTranslationText), [getTranslationText]);

  const handleOnBackButtonPress = () =>
    navigation.canGoBack()
      ? navigationActions.back()
      : safeResetNavigation([getNavigationActions().toDashboard('screen')]);

  const handleOnContinuePress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SpidTimeoutNextSteps,
      buttonName: 'Continue',
    });
    navigationActions.toItalyPayment('screen');
  };

  useDefaultNavigationOptions(
    { id: 'it.spid-timeout-next-steps-screen.nav.title' },
    {
      headerBackTitleVisible: false,
    },
  );

  return (
    <SpidTimeoutNextStepsScreen
      getTranslationText={getTranslationText}
      onBackButtonPress={handleOnBackButtonPress}
      onPressPrimaryButton={handleOnContinuePress}
      steps={steps}
    />
  );
};
