import React, { ComponentType, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useSelector } from 'react-redux';
import { Answer, TreeNode, ResponseProperties } from '@taxfix/quizmaster/dist/types';
import { CountryCodes } from '@taxfix/types';

import { QuestionStore, useStores } from '../stores/hooks/mobx-stores-hook';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as internetConnectionSelectors } from '../stores/modules/internet-connection';
import { ConnectionInfoAnalyticsParams } from '../services/internetconnection';

type OwnProps = {
  questionStore: QuestionStore;
};
type OriginalProps = Record<string, any>;
export type QuizmasterLight = Record<
  string,
  {
    properties: ResponseProperties;
    answer: Answer;
    saveAnswer: (arg0: Answer) => void;
    deleteResponse: () => void;
  }
>;

export const getNodesByIds = (questionStore: QuestionStore, questionIds: string[]): TreeNode[] => {
  const { list } = questionStore;
  return list.filter(({ questionId }) => questionIds.includes(questionId));
};

export const getQuizmasterLight = (
  questionStore: QuestionStore,
  nodes: TreeNode[],
  trackingParams?: ConnectionInfoAnalyticsParams,
): QuizmasterLight => {
  const { responsesJS } = questionStore;
  const defaultAcc: QuizmasterLight = {};
  return nodes.reduce((acc, node) => {
    const { id } = node;
    const { properties } = node.response || {};
    const answer = responsesJS?.[id]?.answer;
    acc[id] = {
      properties: properties || {},
      answer,
      saveAnswer: async (value: Answer) => {
        return await questionStore.saveAnswer(node, value, trackingParams);
      },
      deleteResponse: () => {
        questionStore.deleteResponse(id);
      },
    };
    return acc;
  }, defaultAcc);
};

export const withQuizmasterLight =
  (questionIds: string[], countryCode: CountryCodes = CountryCodes.IT) =>
  (WrappedComponent: ComponentType<OriginalProps>): ComponentType<OriginalProps> => {
    const WithQuizmasterLightWrapper = (
      props: OwnProps & OriginalProps,
    ): ReactElement<typeof WrappedComponent> => {
      if (!(questionIds && questionIds.length > 0)) {
        throw new Error('questionIds have not been passed');
      }

      const selectedCountry = useSelector(settingsSelectors.selectedCountry);
      const shouldRun = selectedCountry === countryCode;
      const trackingParams = useSelector(internetConnectionSelectors.getTrackingParams);
      const year = useSelector(settingsSelectors.selectedYear);
      const { questionStores } = useStores();

      if (!shouldRun) {
        return <WrappedComponent {...props} />;
      }

      const questionStore = questionStores[year!];
      const nodes: TreeNode[] = getNodesByIds(questionStore!, questionIds);
      const quizmasterLight = getQuizmasterLight(questionStore!, nodes, trackingParams);
      return <WrappedComponent {...props} quizmasterLight={quizmasterLight} />;
    };

    return observer(WithQuizmasterLightWrapper) as any;
  };
