import * as React from 'react';

import { StepperProps, Text } from 'src/taxfix-components/src';

const TOTAL_STEPS = 4;
const ACTIVE_STEP_INDEX = 0;

const textKey = (index: number) => `it.spid-timeout-next-steps-screen.step-${index + 1}.text`;
const labelKey = (index: number) => `it.spid-timeout-next-steps-screen.step-${index + 1}.label`;

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

export const getStepsContent = (
  getTranslationText: (id: string, values?: any) => string,
): StepperProps['steps'] => {
  const steps: StepperProps['steps'] = [];

  for (let index = 0; index < TOTAL_STEPS; index++) {
    const isActiveStep = index === ACTIVE_STEP_INDEX;

    const stepContent: StepperProps['steps'][number] = {
      active: isActiveStep,
      titleFont: isActiveStep ? 'titleXSBold' : 'titleXSBook',
      titleText: getTranslationText(textKey(index)),
      labelFont: 'bodySBook',
      labelText: getTranslationText(labelKey(index), {
        bold: getBoldText,
      }),
    };

    steps.push(stepContent);
  }

  return steps;
};
