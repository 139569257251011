/* global window */

type MailOptions = {
  subject: string;
  body: string;
  isHTML?: boolean;
  recipients?: string[];
  ccRecipients?: string[];
  bccRecipients?: string[];
  attachment?: {
    path: string;
    type: string;
    name?: string;
  };
};

type ErrorCallback = (error: string, event?: string) => void;

export const mail = (options: MailOptions, onError: ErrorCallback) => {
  try {
    const { recipients, ccRecipients, bccRecipients, subject, body } = options;
    const queryParts = [
      ccRecipients ? `cc=${ccRecipients.join(',')}` : null,
      bccRecipients ? `bcc=${bccRecipients.join(',')}` : null,
      subject ? `subject=${window.encodeURIComponent(subject)}` : null,
      body ? `body=${window.encodeURIComponent(body)}` : null,
    ].filter((v) => v != null);
    const mailtoUrl =
      'mailto:' + (recipients || []).join(',') + (queryParts ? '?' + queryParts.join('&') : '');
    window.open(mailtoUrl, '_blank');
  } catch (error) {
    onError('Error happened while trying to send email.');
  }
};
