import { useBreakpointValue } from 'native-base';
import * as React from 'react';
import { useIntl as useIntlReact } from 'react-intl';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';

import {
  Container,
  NavButton,
  StatusFooterWeb,
  StepsIconType,
  Text,
} from 'src/taxfix-components/src';
import { testID } from 'src/common/testID';
import { ProductFetchContainer } from 'src/containers/product-fetch';
import { useNavigation } from 'src/hooks/navigation-hook';
import { StatusStepsListItaly } from 'src/screens/status-screen/status-steps-list-italy';
import { getProduct } from 'src/services/submissions';
import {
  StatusConfig,
  StatusStepBanner,
  StatusStepNavigation,
  StatusStepSectionNavigation,
  StatusStepSectionWithIndex,
} from 'src/stores/modules/status';
import { Product } from 'src/types/payment';
import { ScreenName } from 'src/types/screen-name';
import { isMobileWeb, isWeb } from 'src/utils/platform';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import {
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
  NotificationBannerTypeKey,
} from '../../components/notification-banner-legacy';
import { EmailConfirmationBannerContainer } from '../../containers/email-confirmation-banner';
import formatNumber from '../../i18n/formatNumber';
import { selectors as submissionSelectors } from '../../stores/modules/submission';

type BannerProps = {
  onPressTryAgain?: () => any;
  error: string | null | undefined | any;
  banner: StatusStepBanner | null | undefined;
  bannerSize?: 'small' | 'large';
};

type ProductFetchContainerProps = {
  product: Product;
};

function BannerComponent({ onPressTryAgain, error, banner, bannerSize }: BannerProps) {
  if (error) {
    return (
      <NotificationBannerLegacy
        notificationType={NotificationBannerLegacyType.StatusScreenFetchFailed}
        isError
        onAction={onPressTryAgain}
        values={{
          error,
        }}
      />
    );
  }

  if (banner && banner.type === 'email-new-user') {
    return <EmailConfirmationBannerContainer isNewUser shouldPoll bannerSize={bannerSize} />;
  }

  if (banner && banner.type === 'email') {
    return <EmailConfirmationBannerContainer shouldPoll bannerSize={bannerSize} />;
  }

  if (banner && Object.keys(NotificationBannerLegacyType).includes(banner.type)) {
    return (
      <NotificationBannerLegacy
        notificationType={banner.type as any as NotificationBannerTypeKey}
        values={banner.values || {}}
        {...(banner.onAction ? { onAction: banner.onAction } : {})}
      />
    );
  }

  return null;
}

type ItalyStatusScreenProps = {
  statusConfig: StatusConfig;
  onPress: () => void;
  onSectionView: (navigation: StatusStepSectionNavigation) => any;
  sections: StatusStepSectionWithIndex[];
  stepNavigation: StatusStepNavigation | null | undefined;
  stepIndex: number;
  showChatBubble: boolean;
  secondaryBanner?: StatusStepBanner | any;
} & BannerProps;

export const ItalyStatusScreen = ({
  onPress,
  onPressTryAgain,
  onSectionView,
  sections,
  stepIndex,
  stepNavigation,
  error,
  banner,
  showChatBubble,
  secondaryBanner,
  statusConfig,
}: ItalyStatusScreenProps): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();
  const isResubmission = useSelector(submissionSelectors.isResubmission); // TODO: change this to use `isResubmissionByYear` selector instead
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const paddingText = useBreakpointValue({ base: '20px', md: '0px' });
  const marginBottomText = useBreakpointValue({ base: '24px', md: '33px' });
  const title = statusConfig.title?.id ?? 'status.it.title';
  const intl = useIntlReact();

  const currentSection = sections.find((item) => item.index === stepIndex);
  const webFooterButtonTitle =
    typeof stepNavigation !== 'function'
      ? stepNavigation?.link?.params?.italyWebFooterButtonTitleId ?? 'status.it.button.title'
      : 'status.it.button.title';

  return (
    <Container flex={1}>
      <Container flexDir="row" width="100%">
        {!isSmallScreen && (
          <NavButton
            text={getTranslationText('status.it.comeback.text')}
            onPress={() => navigationActions.toDashboard('screen')}
          />
        )}
        <Container flex={1}>
          {secondaryBanner && (
            <BannerComponent banner={secondaryBanner} bannerSize="small" error={null} />
          )}
          <BannerComponent onPressTryAgain={onPressTryAgain} error={error} banner={banner} />
        </Container>
      </Container>

      <ScrollView {...testID('status-screen-scrollView')}>
        <ProductFetchContainer productResolver={getProduct}>
          {({ product }: ProductFetchContainerProps) => {
            const formattedProductPrice = formatNumber(intl, {
              formatStyle: 'currency',
              useGrouping: true,
              value: product.amount / 100,
            });

            return (
              <>
                <Container maxW="520px" alignSelf="center">
                  <Text
                    value={getTranslationText(title)}
                    variant="titleMBold"
                    marginBottom="12px"
                    marginTop="24px"
                    paddingX={paddingText}
                  />

                  {statusConfig.subtitle && (
                    <Text
                      value={getTranslationText(
                        currentSection?.subtitleOverride ?? statusConfig.subtitle.id,
                      )}
                      variant="bodyMBook"
                      color="greytones.graphicsDark"
                      marginBottom={marginBottomText}
                      paddingX={paddingText}
                    />
                  )}
                  <StatusStepsListItaly
                    sections={sections}
                    stepIndex={stepIndex}
                    onSectionView={onSectionView}
                    price={formattedProductPrice}
                  />
                </Container>
                {!isSmallScreen && typeof stepNavigation !== 'function' && !isResubmission && (
                  <Container
                    marginTop="16px"
                    maxWidth="520px"
                    width="100%"
                    justifySelf="flex-start"
                    alignSelf="center"
                    paddingRight="24px"
                  >
                    <Container alignItems="flex-end">
                      <StatusFooterWeb
                        iconType={currentSection?.icon as StepsIconType}
                        label={getTranslationText('status.it.steps-footer-label')}
                        step={getTranslationText(currentSection?.title?.id ?? 'next step', {
                          price: formattedProductPrice,
                        })}
                        buttonTitle={getTranslationText(webFooterButtonTitle)}
                        onButtonPress={onPress}
                        buttonVariant={currentSection?.warning ? 'primaryAlarm' : 'primary'}
                      />
                    </Container>
                  </Container>
                )}
              </>
            );
          }}
        </ProductFetchContainer>
      </ScrollView>

      {typeof stepNavigation === 'function' &&
        // @ts-ignore
        stepNavigation()}
    </Container>
  );
};
