import { createBlacklistFilter } from 'redux-persist-transform-filter';

import { persistFilter as submission } from '../modules/submission';
import { persistFilter as settings } from '../modules/settings';
import { persistFilter as devTools } from '../modules/dev-tools';
import { persistFilter as codepush } from '../modules/code-push';
import { persistFilter as referralHistory } from '../modules/referral-history';
import { persistFilter as user } from '../modules/user';
import { persistFilter as requiredDocuments } from '../modules/required-documents';
import { persistFilter as userProfile } from '../modules/user-profile';

export const persistWhitelist = [
  'places',
  'identifications',
  'userConsents',
  'user',
  'partner',
  'bankDetails',
  'syncAnswers',
  'settings',
  'submission',
  'devTools',
  'prefillDE',
  'referralData',
  'codepush',
  'referralHistory',
  'typeform',
  'requiredDocuments',
  'userProfile',
  'expertReview',
  'instantRefund',
  'checkoutFlow',
  'plausibilityChecks',
  'braze',
  'difm',
  'spainUnsupportedSurvey',
];
export const securePersistWhitelist = ['userAuth'];

const commonSubsetBlacklist = (moduleName: string) =>
  createBlacklistFilter(moduleName, ['isLoading', 'error']);

export const transformFilters = [
  ...persistWhitelist.map(commonSubsetBlacklist),
  submission,
  settings,
  devTools,
  codepush,
  referralHistory,
  user,
  requiredDocuments,
  userProfile,
];
