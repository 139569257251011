import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { Props } from './types';

/**
 * The In Process Icon with several paths
 * Temporary component
 */

export const InProgressIcon: React.FC<Props> = ({ size = '100%', ...otherProps }): JSX.Element => (
  <Icon viewBox="0 0 20 20" size={size} {...otherProps}>
    <G fill="none">
      <Path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10Z"
        fill="#E1E1E1"
      />
      <Path
        d="M10 0C11.3132 1.566e-08 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10L17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3V0Z"
        fill="#32C850"
      />
    </G>
  </Icon>
);
