import { getTaxSeasonInfo } from 'src/services/tax-season';
import {
  StatusMiddleware,
  StatusPostBreakProcessFunction,
  selectors as statusSelectors,
  UPDATE_STATUS,
} from 'src/stores/modules/status';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';

export const statusMiddleware: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  if (userAuthSelectors.isEmailAddressConfirmed(getState())) {
    next();
  } else dispatch(breakAction());
};
export const emailConfirmedDisableStep: StatusPostBreakProcessFunction = async (
  dispatch,
  getState,
) => {
  if (!userAuthSelectors.isEmailAddressConfirmed(getState())) {
    const navigation = statusSelectors.getNavigation(getState());
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        navigation: { ...navigation, disabled: true },
        banner: {
          type: 'email',
        },
      },
    });
  }
};
export const checkEmailConfirmed =
  (type: string): StatusPostBreakProcessFunction =>
  async (dispatch, getState) => {
    const { betweenSoftHardEnd } = getTaxSeasonInfo(getState());

    if (!userAuthSelectors.isEmailAddressConfirmed(getState())) {
      const bannerKey = betweenSoftHardEnd ? 'secondaryBanner' : 'banner';
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          [bannerKey]: {
            type,
          },
        },
      });
    }
  };
