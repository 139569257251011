export const UPDATE_END_CARD_PROGRESS = 'UPDATE_END_CARD_PROGRESS';
export const UPDATE_MANDATORY_FILER_STATUS = 'UPDATE_MANDATORY_FILER_STATUS';

export type MandatoryFilerStatusPayload = {
  year: number;
  questionId?: string;
  isAlertShown?: boolean;
  isPayslipLastQuestionAnswered?: boolean;
};

type UpdateEndCardProgressAction = {
  type: typeof UPDATE_END_CARD_PROGRESS;
  payload: {
    year: number;
    progress?: number;
  };
};

type UpdateMandatoryFilerStatus = {
  type: typeof UPDATE_MANDATORY_FILER_STATUS;
  payload: MandatoryFilerStatusPayload;
};

type Action = UpdateEndCardProgressAction | UpdateMandatoryFilerStatus;

export const updateEndCardProgress = (
  progress: number,
  year: number,
): UpdateEndCardProgressAction => ({
  type: UPDATE_END_CARD_PROGRESS,
  payload: {
    progress,
    year,
  },
});

export const updateMandatoryFilerStatus = (
  payload: MandatoryFilerStatusPayload,
): UpdateMandatoryFilerStatus => ({
  type: UPDATE_MANDATORY_FILER_STATUS,
  payload,
});

export type Actions = {
  updateEndCardProgress: typeof updateEndCardProgress;
  updateMandatoryFilerStatus: typeof updateMandatoryFilerStatus;
};

export type Flow = {
  endCard: {
    year?: {
      progress: number;
    };
  };
  mandatoryFilerStatus: {
    year?: Omit<MandatoryFilerStatusPayload, 'year'>;
  };
};

type RootState = {
  flow: Flow;
};

export const initial: Flow = {
  endCard: {},
  mandatoryFilerStatus: {},
};

export const reducer = (state: Flow = initial, action: Action): Flow => {
  switch (action.type) {
    case UPDATE_END_CARD_PROGRESS: {
      return {
        ...state,
        endCard: {
          ...state.endCard,
          [action.payload.year]: {
            progress: action.payload.progress,
          },
        },
      };
    }

    case UPDATE_MANDATORY_FILER_STATUS: {
      const { year, ...payload } = action.payload;
      return {
        ...state,
        mandatoryFilerStatus: {
          ...state.mandatoryFilerStatus,
          [year]: {
            ...(state.mandatoryFilerStatus as any)[year],
            ...payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

const isMandatoryFilerAlertShown = (state: RootState, year: number): boolean | null | undefined =>
  (state.flow.mandatoryFilerStatus as any)[year]?.isAlertShown;

const isPayslipLastQuestionAnswered = (
  state: RootState,
  year: number,
): boolean | null | undefined =>
  (state.flow.mandatoryFilerStatus as any)[year]?.isPayslipLastQuestionAnswered;

export const actions: Actions = {
  updateEndCardProgress,
  updateMandatoryFilerStatus,
};

export const selectors = {
  isMandatoryFilerAlertShown,
  isPayslipLastQuestionAnswered,
};
