import { Answer, TreeNode } from '@taxfix/quizmaster/dist/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { State } from '../stores/store/initial';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as internetConnectionSelectors } from '../stores/modules/internet-connection';

import { getQuestionStores } from './util';

export function getQuestionStoreByYearAndCountry(state: State) {
  const selectedCountry = settingsSelectors.selectedCountry(state);
  const year = settingsSelectors.selectedYear(state);

  if (year == null) {
    throw new Error('Selected year is required');
  }

  const questionStores = getQuestionStores(selectedCountry);
  const questionStore = questionStores[year];
  if (questionStore.needRebuild) questionStore.runCleanRebuild();

  if (!questionStore) {
    throw new Error(`Question store is not found for ${selectedCountry} on ${year}.`);
  }

  return questionStore;
}
// To update answer in questionStore
export const updateAnswer = async (questionId: string, value: Answer, state: State) => {
  try {
    const trackingParams = internetConnectionSelectors.getTrackingParams(state);
    const questionStore = await getQuestionStoreByYearAndCountry(state);
    const { list } = await questionStore;
    const node: TreeNode = await list.find((n: any) => {
      return questionId === n.id;
    });
    await questionStore.saveAnswer(node, value, trackingParams);
  } catch (e) {
    logger.warn(e as Error, { message: 'prefillQF: error during prefill process' });
  }
};
// To get answer
export const getAnswer = (state: any, questionId: string): Answer => {
  const questionStore = getQuestionStoreByYearAndCountry(state);
  const { responsesJS } = questionStore;
  const answer = responsesJS?.[questionId]?.answer;
  return answer;
};
