import { Dispatch } from 'redux';
import { Platform } from 'react-native';

import { dynamicLinkParamKeys } from '../dynamic-link-types';
import { actions as codePushActions } from '../../stores/modules/code-push';
import { DynamicLinkCallbacks } from '../../stores/modules/dynamic-link-types';
import { parseQuery } from '../dynamic-link-util';

type Data = {
  dispatch: Dispatch<any>;
  link: string;
};

const getRedirectToCodePush = ({ dispatch, link }: Data): DynamicLinkCallbacks => {
  const query: any = parseQuery(link);
  const queryKey = Platform.select({
    ios: dynamicLinkParamKeys.deploymentKeyIOS,
    android: dynamicLinkParamKeys.deploymentKeyAndroid,
  });
  const deploymentKey: string = query[queryKey || ''];
  // Simply overwrite code push deployment key.
  if (deploymentKey) dispatch(codePushActions.saveCustomKey(deploymentKey));
  return {
    onBeforeRedirect: null,
    onRedirect: null,
    onPostRedirect: null,
  };
};

export { getRedirectToCodePush };
