import React from 'react';
import { View } from 'native-base';

import { SupportCard, SupportCardProps } from 'src/taxfix-components/src';

export type SupportListProps = {
  supportCards: SupportCardProps[];
};

export function SupportList({ supportCards }: SupportListProps) {
  return (
    <View flexDirection="column" flex={1}>
      {supportCards.map((supportCard) => (
        <SupportCard {...supportCard} />
      ))}
    </View>
  );
}
