import React from 'react';
import { Pressable, ScrollView } from 'react-native';
import { Stack, useBreakpointValue } from 'native-base';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { Container, Icon, icons, Text } from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';
import { setItem, getItem } from 'src/utils/asyncStorage';

const ASYNC_STORAGE_KEY = 'testimonialsScrollUnblocked';

const Rating = (): JSX.Element => {
  return (
    <Stack direction="row" space="6px">
      {Array.from({ length: 5 }, (_, index) => (
        <Icon key={index} path={icons.faStar} size="12px" color="secondaryCategory.orangeFull" />
      ))}
    </Stack>
  );
};

const Cards = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  return (
    <Stack direction="row" space={6} alignItems="flex-start" mr="24px" pb={{ base: '0px', lg: 2 }}>
      {Array.from({ length: 4 }, (_, index) => (
        <Stack
          key={index}
          space="16px"
          width="294px"
          padding="24px"
          backgroundColor="greytones.greyBlue"
          borderRadius="15px"
        >
          <Rating />
          <Container paddingLeft="6px" borderLeftWidth="1px" borderColor="greytones.graphicsDark">
            <Text
              variant="bodySBold"
              value={getTranslationText(`it.testimonials.${index + 1}.title`)}
            />
            <Text
              variant="bodySBook"
              color="greytones.textLight"
              value={getTranslationText(`it.testimonials.${index + 1}.text`)}
            />
          </Container>
          <Text
            variant="titleXXSMedium"
            value={getTranslationText(`it.testimonials.${index + 1}.author`)}
          />
        </Stack>
      ))}
    </Stack>
  );
};

type ViewMoreButtonProps = {
  handleOnPress: () => void;
  screenName: ScreenName.Dashboard | ScreenName.Welcome;
};

const viewMoreButtonStyles = {
  [ScreenName.Dashboard]: {
    containerBackgroundColor: 'transparencyLight.light80',
  },
  [ScreenName.Welcome]: {
    containerBackgroundColor: 'brand.transparentBackground',
  },
};

const ViewMoreButton = ({ handleOnPress, screenName }: ViewMoreButtonProps) => {
  return (
    <Container
      position="absolute"
      right="0px"
      width="48px"
      height="100%"
      backgroundColor={viewMoreButtonStyles[screenName].containerBackgroundColor}
    >
      <Pressable
        onPress={handleOnPress}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}
      >
        <Icon
          path={icons.chevronRight}
          size="20px"
          color="secondaryFeedback.greenDark"
          alignSelf="center"
        />
      </Pressable>
    </Container>
  );
};

type TestimonialsProps = {
  screenName: ScreenName.Dashboard | ScreenName.Welcome;
};

export const Testimonials: React.FC<TestimonialsProps> = ({ screenName }: TestimonialsProps) => {
  const [allowScrolling, setAllowScrolling] = React.useState(false);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  const onViewMorePress = async () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      sectionName: screenName,
      buttonName: 'viewMoreTestimonials',
    });

    await setItem(`${ASYNC_STORAGE_KEY}${screenName}`, 'true');
    setAllowScrolling(true);

    scrollViewRef.current?.scrollTo({ x: 318, y: 0, animated: true });
  };

  React.useEffect(() => {
    const checkScrollUnblocked = async () => {
      const scrollUnblocked = await getItem(`${ASYNC_STORAGE_KEY}${screenName}`);

      if (scrollUnblocked) {
        setAllowScrolling(true);
      }
    };

    checkScrollUnblocked();
  }, [screenName]);

  return (
    <Container position="relative">
      <ScrollView
        ref={scrollViewRef}
        snapToInterval={318}
        scrollEnabled={allowScrolling}
        showsHorizontalScrollIndicator={isLargeScreen}
        horizontal
      >
        <Cards />
      </ScrollView>

      {!allowScrolling && (
        <ViewMoreButton handleOnPress={onViewMorePress} screenName={screenName} />
      )}
    </Container>
  );
};
