import React from 'react';
import { CountryCodes } from '@taxfix/types';

import { getAnswer } from 'src/stores-legacy/helpers';

import {
  StatusStepNavigationData,
  StatusStepNavigationComponent,
  StatusStepSection,
  StatusStep,
} from '../stores/modules/status';
import initial from '../stores/store/initial';
import { selectors as SubmissionSelectors } from '../stores/modules/submission';
import { selectors as UserAuthSelectors } from '../stores/modules/user-auth';
import { userProfileSelectors } from '../stores/modules/user-profile';
import { SubmitButtonWithCheckBox } from '../screens/status-screen/submit-button-with-consent-component';
import { PaymentCustomer } from '../types/payment';
import { getQuestionStores } from '../stores-legacy/util';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { wayQuestionIds, flagsQuestionIds, prefillQuestionIds } from '../common/constants-it';
import { getNodesByIds, getQuizmasterLight } from '../utils/with-quizmaster-light';

import { handleRawValue } from './validations';
import { DraftSubmissionStates } from './submissions';
import { getDefaultTaxYear } from './country-and-year';

export type ResolveDraftSubmissionNavigationType = (
  storeState: typeof initial,
  config: StatusStep,
) => {
  customNavigation?:
    | (StatusStepNavigationData | null | undefined)
    | (StatusStepNavigationComponent | null | undefined);
  customSection?: StatusStepSection | null | undefined;
};

export type AsyncResolveDraftSubmissionNavigationType = (
  storeState: typeof initial,
  config: StatusStep,
) => Promise<{
  customNavigation?:
    | (StatusStepNavigationData | null | undefined)
    | (StatusStepNavigationComponent | null | undefined);
  customSection?: StatusStepSection | null | undefined;
}>;

export const getPaymentNavigationParams = (
  storeState: typeof initial,
  nextNavigationAction: (params?: any) => void,
) => {
  const year = settingsSelectors.selectedYear(storeState) ?? getDefaultTaxYear();
  const questionStores = getQuestionStores(CountryCodes.IT);
  const questionStore = questionStores[year];
  const nodes = getNodesByIds(questionStore, [
    wayQuestionIds.firstName,
    wayQuestionIds.lastName,
    prefillQuestionIds.taxId,
  ]);
  const quizmasterLight = getQuizmasterLight(questionStore, nodes);
  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const taxId = quizmasterLight[prefillQuestionIds.taxId].answer;
  const taxIdValidated = handleRawValue(taxId).toUpperCase();
  const { billingAddress } = userProfileSelectors.getUserProfile(storeState);
  const customer: PaymentCustomer = {
    firstName,
    lastName,
    email: UserAuthSelectors.getEmailAddress(storeState),
    ...billingAddress,
  };
  return {
    taxId: taxIdValidated,
    customer,
    nextNavigationAction,
  };
};

export const resolveDraftSubmissionNavigationForItaly: ResolveDraftSubmissionNavigationType = (
  storeState,
  config,
) => {
  const submissionState = SubmissionSelectors.getLatestSubmissionStatus(storeState);
  const selectedYear = settingsSelectors.selectedYear(storeState);

  const isIdUploadSkipped = getAnswer(storeState, flagsQuestionIds.prefillUploadSkipped);

  const isConfirmationAnsweredOnResubmission = getAnswer(
    storeState,
    flagsQuestionIds.isConfirmationAnsweredOnResubmission,
  );

  const onResubmissionConsentGiven = getAnswer(
    storeState,
    flagsQuestionIds.onResubmissionConsentGiven,
  );

  if (!selectedYear) {
    throw new Error('tax year is not defined');
  }

  const isResubmission = SubmissionSelectors.isResubmissionByYear(storeState, selectedYear);

  if ((isResubmission && !isConfirmationAnsweredOnResubmission) || isIdUploadSkipped) {
    return {
      customSection: config.section?.resubmission,
    };
  }

  const consentGivenCustomSection = onResubmissionConsentGiven
    ? { ...config.section?.resubmission, stepDisabled: false }
    : config.section?.resubmission;

  if (isResubmission && isConfirmationAnsweredOnResubmission && !isIdUploadSkipped) {
    const SubmitButtonWithCheckbox = (
      <SubmitButtonWithCheckBox translationKey="status.it.submit.resubmission.submit-button" />
    );
    return {
      customSection: consentGivenCustomSection,
      customNavigation: () => SubmitButtonWithCheckbox,
    };
  }

  if (DraftSubmissionStates[submissionState]) {
    return {
      customNavigation: config.navigation?.draftSubmission,
      customSection: config.section?.draftSubmission,
    };
  }

  return {
    customNavigation: config.navigation?.pending,
    customSection: config.section?.pending,
  };
};
