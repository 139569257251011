export const migration = (state: Record<string, any>) => {
  const newState = {
    ...state,
    instantRefund: {
      ...state.instantRefund,
      fiftyPayoutExperimentSeen: false,
    },
  };

  return newState as Record<string, any>;
};
