import incomeTaxCalculator, { Calculations } from '@taxfix/income-tax-calculator';
import { IncomeTaxValue } from '@taxfix/quizmaster/dist/types';

async function getCalculationResult(
  year: number,
  fields: IncomeTaxValue[],
  full: boolean,
): Promise<Calculations | number> {
  const taxCalculator = incomeTaxCalculator.getCalculatorForYear(year);
  const calculations = taxCalculator.calculateIncomeTax(fields, full);
  return JSON.parse(JSON.stringify(calculations));
}

export { getCalculationResult };
