import React from 'react';
import { VStack } from 'native-base';

import { Icon, icons, InProgressIcon, Text } from 'src/taxfix-components/src';

import { Container } from '../../layout/Container';

import { NumberStylesProps, StepIconType, StepProps } from './types';

/**
 * Shows an icon with a label
 */

const getProgressIcon = (
  type: StepIconType,
  active = false,
  numberStyles: NumberStylesProps,
  number: number,
): React.ReactElement => {
  const { numberBackgroundColor, numberColor, numberFont } = numberStyles;
  switch (type) {
    case 'success':
      return (
        <Container
          width="24px"
          height="24px"
          justifyContent="center"
          alignItems="center"
          borderRadius="12px"
          margin="auto"
          backgroundColor="brand.brandGreen"
        >
          <Icon size="16px" path={icons.checkmarkSymbol} color="white" />
        </Container>
      );
    case 'progress':
      return <InProgressIcon size="24px" />;
    case 'disabled':
      return <Icon size="24px" path={icons.disabled} color="greytones.graphicsLight" />;
    case 'warning':
      return <Icon path={icons.finished} size="24px" color="secondaryCategory.pinkFull" />;
    case 'start':
      return (
        <Container
          width="24px"
          height="24px"
          justifyContent="center"
          alignItems="center"
          borderRadius="12px"
          margin="auto"
          backgroundColor={active ? 'brand.brandGreen' : numberBackgroundColor}
        >
          <Text
            value={String(number)}
            variant={numberFont}
            color={active ? 'white' : numberColor}
          />
        </Container>
      );
  }
};

export const Step: React.FC<StepProps> = ({
  stepIcon = 'start',
  labelText,
  titleText,
  labelFont = 'bodyXXSBook',
  titleFont = 'titleXSMedium',
  direction = 'horizontal',
  number = 1,
  active = false,
  numberStyles = {
    numberBackgroundColor: 'greytones.graphicsLight',
    numberColor: 'greytones.textLight',
    numberFont: 'bodyXSBook',
  },
  commonTitleStyles,
  ...otherStepProps
}) => {
  const isVertical = direction === 'vertical';
  return (
    <Container
      alignItems={isVertical ? 'flex-start' : 'center'}
      flexDirection={isVertical ? 'row' : 'column'}
      mb={isVertical ? '20px' : '0px'}
      {...otherStepProps}
    >
      <Container mb={isVertical ? '0px' : '10px'}>
        {getProgressIcon(stepIcon, active, numberStyles, number)}
      </Container>
      <VStack ml={isVertical ? 15 : '0px'} flexShrink={1}>
        {titleText && (
          <Text
            variant={titleFont}
            color={active ? 'greytones.backgroundDark' : 'greytones.textDefault'}
            value={titleText}
            textAlign={isVertical ? undefined : 'center'}
            {...commonTitleStyles}
          />
        )}
        {labelText && <Text variant={labelFont} value={labelText} color="greytones.textLight" />}
      </VStack>
    </Container>
  );
};
