import React from 'react';

import { InputField } from 'src/taxfix-components/src';

type Props = {
  onChange: (value: string) => void;
  testId?: string;
  autoCorrect?: boolean;
};

function determineAutoCorrect(isAutoCorrect?: boolean) {
  switch (isAutoCorrect) {
    case false:
      return false;

    default:
      return true;
  }
}

const FreeText = (props: Props) => {
  const { onChange, testId, autoCorrect, ...custom } = props;
  return (
    <InputField
      onChangeText={onChange}
      testID={testId}
      autoCorrect={determineAutoCorrect(autoCorrect)}
      {...custom}
    />
  );
};

export default FreeText;
