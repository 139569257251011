import React, { ComponentType, ReactElement } from 'react';

import { Logout, useLogout } from '../hooks/logout-hook';

export type WithLogout = {
  onLogout: (options?: Logout) => Promise<void>;
};
type OriginalProps = Record<string, any>;
export const withLogout = (
  WrappedComponent: ComponentType<OriginalProps>,
): ComponentType<OriginalProps> => {
  const WithLogoutWrapper = (props: OriginalProps): ReactElement<typeof WrappedComponent> => {
    const logout = useLogout();
    return <WrappedComponent testId="with.logout.wrapped.component" onLogout={logout} {...props} />;
  };

  return WithLogoutWrapper;
};
