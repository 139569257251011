import React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { PhoneNumberItalyContainer } from '../../../screens/containers/phone-number-italy';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

type ActionParams = {
  onNext: () => void;
  titleTranslationKey?: string;
};

type Props = Navigation<
  'PhoneNumber',
  {
    PhoneNumber: NavigationParams<ActionParams>;
  }
>;

export const toPhoneNumber: Action<ActionParams> = navigate('PhoneNumber', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ProfilePhoneNumber',
  },
});

export const PhoneNumberItaly = ({ route }: Props): JSX.Element => {
  const { onNext, titleTranslationKey = 'it.phone-number-italy.header.title' } = route.params;
  const footerOptions = {
    centerButton: true,
  };

  useDefaultNavigationOptions(
    { id: titleTranslationKey },
    {
      headerBackTitle: '',
    },
  );

  return <PhoneNumberItalyContainer onNext={onNext} footerOptions={footerOptions} />;
};
