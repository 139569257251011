import React from 'react';
import { View } from 'react-native';

import { TextButton, theme } from 'src/components/core';
import { MenuItem } from 'src/screens/account-menu/types';

type AccountFooterListType = {
  menuItems: Array<MenuItem>;
};
export const AccountFooterList = ({ menuItems }: AccountFooterListType) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}
  >
    {menuItems.map((menuItem: MenuItem, footerIndex: number) => {
      const HandlerComponent = menuItem;
      return (
        <HandlerComponent>
          {({ hide, data, handleSelectItem }: any) => {
            if (hide) {
              return null;
            }

            return (
              <View
                key={footerIndex}
                style={{
                  marginBottom: 24,
                  marginRight: 24,
                }}
              >
                <TextButton
                  translationKey={data.translationKey}
                  onPress={() => handleSelectItem(data)}
                  textStyle={{
                    fontFamily: theme.fontFamily.book,
                    color: theme.color.greytonesText,
                  }}
                />
              </View>
            );
          }}
        </HandlerComponent>
      );
    })}
  </View>
);
