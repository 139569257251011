import { StackNavigationOptions } from '@react-navigation/stack';

import { isE2E } from './platform';

export function getScreenOptions(
  originalScreenOptions: StackNavigationOptions,
): StackNavigationOptions {
  const decoratedDefaultScreenOptions = { ...originalScreenOptions };
  if (isE2E) {
    decoratedDefaultScreenOptions.animationEnabled = false;
    decoratedDefaultScreenOptions.headerBackTestID = 'header-back';
  }

  return decoratedDefaultScreenOptions;
}
