import React, { ComponentType } from 'react';
import { WebViewNavigation } from 'react-native-webview';

import { Container } from 'src/taxfix-components/src';
import { WebViewComponent } from 'src/screens/components/webview';
import { Loading } from 'src/components/loading';

type IFrameSPIDLoginComponentProps = {
  SPIDLoginUrl?: string;
  onError: () => void;
  onShouldStartLoadWithRequest: (event: WebViewNavigation) => boolean;
  isLoading: boolean;
  onMessage: (event: { nativeEvent: { data: string } }) => void;
};

export const IFrameSPIDLoginComponent: ComponentType<IFrameSPIDLoginComponentProps> = ({
  SPIDLoginUrl,
  onError,
  onShouldStartLoadWithRequest,
  isLoading,
  onMessage,
}) => {
  if (isLoading) return <Loading />;

  return (
    <>
      <Container flex={1} height="100%">
        {!!SPIDLoginUrl && (
          <WebViewComponent
            url={SPIDLoginUrl}
            onError={onError}
            onMessage={onMessage}
            onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          />
        )}
      </Container>
    </>
  );
};
