import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { Svg, Circle } from 'react-native-svg';

import { Box, theme, BodyPrimaryText } from './core';
import ExpandContent from './core/Button/ExpandContent';

type RadioOptionProps = {
  label: string;
  checked: boolean;
  expandContent?: boolean;
  onPress: () => any;
  color?: string;
  disabled?: boolean;
};
type RadioIconProps = {
  checked: boolean;
  color?: string;
};
export const RadioIcon = ({ checked, color = theme.color.primary }: RadioIconProps) => {
  const stroke = checked ? color : theme.color.border;
  const fill = checked ? color : 'white';
  return (
    <Svg height="26" width="26">
      <Circle cx="13" cy="13" r="12" stroke={stroke} fill="white" strokeWidth="2" />
      <Circle cx="13" cy="13" r="8" fill={fill} />
    </Svg>
  );
};
export const RadioOption = ({
  label,
  checked,
  expandContent,
  onPress,
  color,
  disabled = false,
}: RadioOptionProps) => {
  const labelStyles = {
    flex: 1,
    marginLeft: 14,
  };

  const OptionBox = () => (
    <Box direction="row" alignVertically="center" alignHorizontally="start">
      <RadioIcon checked={checked} color={color} />
      <BodyPrimaryText id={label} testId={label} style={labelStyles} />
    </Box>
  );

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      {expandContent ? (
        <ExpandContent layoutChange={() => {}}>
          <OptionBox />
        </ExpandContent>
      ) : (
        <OptionBox />
      )}
    </TouchableOpacity>
  );
};
