import React from 'react';

import navigate, { Action } from 'src/routes/navigate';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';

import { useItalyIntl } from '../_hooks/use-italy-intl';

import { SPIDConsent } from './components/spid-consent';

export const toSPIDConsentScreen: Action = navigate(ScreenName.ItalySPIDConsentScreen, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ItalySPIDConsentScreen,
  },
});

export const SPIDConsentScreen = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  useDefaultNavigationOptions(undefined, {
    headerBackTitle: getTranslationText('account.title'),
  });

  return <SPIDConsent />;
};
