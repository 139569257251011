import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { CameraImageResult } from '../android-camera-upload/camera';

import { ImagePreviewComponent } from './image-preview';

export type Props = {
  intl: IntlShape;
  image: CameraImageResult;
  onTryAgain: () => any;
  onProceed: () => any;
};

// TODO: Handle more than one image
const ImagePreview = (props: Props) => {
  const { intl, onProceed, onTryAgain, image } = props;
  const tryAgainButtonString = intl.formatMessage({
    id: 'camera.try-again',
  });
  const uploadButtonString = intl.formatMessage({
    id: 'camera.upload',
  });
  return (
    <ImagePreviewComponent
      tryAgainButtonString={tryAgainButtonString}
      uploadButtonString={uploadButtonString}
      image={image}
      onTryAgain={onTryAgain}
      onProceed={onProceed}
    />
  );
};

export const ImagePreviewContainer = injectIntl(ImagePreview);
