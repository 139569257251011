import * as React from 'react';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { AnalyticsEvent } from 'src/biz-logic/analytics';

import { IdentificationDocumentUploadContainer } from './container';

type ActionParams = {
  backNavigationRoute?(): void;
  onNext?(): void;
};

type Props = Navigation<
  ScreenName.PrefillDocumentUpload,
  {
    PrefillDocumentUpload: NavigationParams<ActionParams>;
  }
>;

export const toPrefillDocumentUpload: Action = navigate(ScreenName.PrefillDocumentUpload, {
  name: AnalyticsEvent.sectionIdUpload,
});

export const PrefillDocumentUploadRoute = ({ route }: Props): JSX.Element => {
  const { backNavigationRoute, onNext } = route.params;
  const { getTranslationText } = useItalyIntl();

  useDefaultNavigationOptions({ id: 'it.identification-document-upload.nav.title' });

  const getBackNavigationText = backNavigationRoute
    ? getTranslationText('web.sidebar.italy.back-to-home')
    : getTranslationText('it.page.navigation.back');

  return (
    <IdentificationDocumentUploadContainer
      backNavigationText={getBackNavigationText}
      backNavigationRoute={backNavigationRoute}
      onNext={onNext}
    />
  );
};
