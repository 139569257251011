import { Platform } from 'react-native';
import { Token } from '@taxfix/taxfix-sdk';
import Config from 'react-native-config';

import { StatusMiddleware, StatusSkipFunction } from 'src/stores/modules/status';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import getNotificationsManager from 'src/utils/push-notifications';

export const checkNotificationsPermissions: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  if (Platform.OS === 'ios') {
    dispatch(breakAction());
    return;
  }

  if (Platform.OS === 'android') {
    const accessToken = userAuthSelectors.getAccessToken(getState());
    const pushToken = settingsSelectors.selectPushToken(getState());
    const PushNotifications = getNotificationsManager();
    PushNotifications.requestPushTokenAndPermissions()
      .then((result: any) => {
        const resultToken = result.token;

        if (resultToken) {
          if (pushToken !== resultToken) {
            Token.updatePushToken(Config.API_BASE_URL, accessToken, {
              pushToken: resultToken,
            })
              .then(() => {
                dispatch(settingsActions.setPushToken(resultToken));
                dispatch(settingsActions.updateAskedForNotificationPermissions(true));
              })
              .catch((err) => {
                dispatch(failureAction(`statusMiddlewareNotification: ${err.message}`));
              });
          }
        }
      })
      .catch((err: any) => {
        dispatch(failureAction(`statusMiddlewareNotification: ${err.message}`));
      });
  }

  next();
};
export const shouldSkipAskPermission: StatusSkipFunction = async (getState) => {
  const state = getState();
  const shouldSkip =
    Platform.OS === 'web' || settingsSelectors.askedForNotificationsPermissions(state);
  return shouldSkip;
};
