import { useCallback, useEffect } from 'react';
import { BackHandler } from 'react-native';

type Config = {
  onBackButtonPress: () => void;
  shouldNavigateBack: boolean;
};
export const useBackButtonHandler = ({ onBackButtonPress, shouldNavigateBack = true }: Config) => {
  const handleBackButtonPress = useCallback(() => {
    onBackButtonPress();
    return !shouldNavigateBack;
  }, [shouldNavigateBack, onBackButtonPress]);
  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonPress);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBackButtonPress);
    };
  }, [handleBackButtonPress]);
};
