import * as React from 'react';

import { IconButton, theme } from '../components/core';
import { WithNavigation, withNavigation } from '../hocs/with-navigation';

type Props = {
  color: string;
  onPress?: () => void;
  cancelGoBack?: boolean;
} & WithNavigation;

const CloseButton = ({ navigationActions, color, onPress, cancelGoBack }: Props) => (
  <IconButton
    iconKey="cross"
    onPress={() => {
      if (onPress) {
        onPress();
      }

      if (!cancelGoBack) {
        navigationActions.back();
      }
    }}
    tintColor={color || theme.color.primary}
    testId="test.button.nav.close"
  />
);

export default withNavigation(CloseButton);
