import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { OverlayContent, Text } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

type Props = {
  handleOnContinue: () => void;
};
const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export const CompletePrefillTestOverlay: React.FC<Props> = ({ handleOnContinue }) => {
  const { getTranslationText } = useItalyIntl();

  const getBoldText = (text: string) => (
    <Text variant="bodySBook" fontWeight="bold" value={text} color="greytones.textLight" />
  );

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'CompletePrefillTestOverlay',
    });
  }, []);

  const onPressContinue = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'CompletePrefillTestOverlay',
      buttonName: 'contineButton',
    });

    handleOnContinue();
  };

  const titleText = getTranslationText('it.complete.prefill.test.popup.title');
  const descriptionText = getTranslationText('it.complete.prefill.test.popup.description', {
    bold: getBoldText,
  });
  const buttonTitleText = getTranslationText('it.complete.prefill.test.popup.button-title');

  return (
    <Overlay style={styles.overlay} appearFromTop disableBackgroundTouch>
      {() => (
        <OverlayContent
          handleMainButtonOnPress={onPressContinue}
          imageName="celebratingHands"
          imageSize={{
            width: '366px',
            height: '142px',
          }}
          title={titleText}
          description={descriptionText}
          buttonTitle={buttonTitleText}
          mainButtonTestId="early-bird-overlay-button"
        />
      )}
    </Overlay>
  );
};
