import { Pressable, useBreakpointValue, ZStack } from 'native-base';
import React from 'react';

import { Container, IconButton, icons, Label, Text } from 'src/taxfix-components/src';

import { Image } from '../../general/Image';

import { Props } from './types';

/**
 * This is a card that looks like a folder
 */

export const Folder: React.FC<Props> = ({
  numberOfDocuments,
  startNowLabel,
  iconName,
  iconSize,
  titleText,
  titleElement,
  subTitleText,
  subtitleElement,
  openFolderText,
  onPress,
  onDelete,
  ...otherProps
}): JSX.Element => {
  // common
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const canShowStartNowLabel = useBreakpointValue({ base: false, md: true });
  const canShowOpenFolderText = useBreakpointValue({ base: false, lg: true });
  const hasDocuments = numberOfDocuments > 0;
  // flap
  const flapWidth = useBreakpointValue({ base: '60px', md: '50%' });
  const flapHeight = useBreakpointValue({ base: '16px', md: '26px' });
  const flapTop = useBreakpointValue({ base: '-11px', md: '-20px', lg: '-24px' });
  // folder
  const folderWidth = useBreakpointValue({
    base: '154px',
    md: '220px',
    lg: '280px',
  });
  const folderHeight = useBreakpointValue({
    base: '146px',
    md: '219px',
    lg: '280px',
  });
  const folderPaddingTop = useBreakpointValue({ base: '20px', md: '16px', lg: '20px' });
  const folderPaddingX = useBreakpointValue({ base: '8px', md: '12px' });
  const folderPaddingBottom = useBreakpointValue({ base: '13px', md: '22px', lg: '28px' });
  const folderMarginTop = useBreakpointValue({ base: '11px', md: '20px', lg: '24px' });
  // delete icon
  const deleteIconSize = useBreakpointValue({ base: '25px', md: '27px' });
  const deleteIconTop = useBreakpointValue({ base: '11px', md: '18px' });
  const deleteIconRight = useBreakpointValue({ base: '11px', md: '20px' });
  // we need elevation to see the delete icon on Android
  const deleteIconElevation = {
    elevation: 7,
  };
  // label
  const labelTextVariant = useBreakpointValue({ base: 'overlineS', lg: 'overlineM' });
  const labelWidth = useBreakpointValue({ base: '84px', lg: '96px' });
  const labelHeight = useBreakpointValue({ base: '20px', lg: '24px' });
  const labelMarginBottom = useBreakpointValue({ base: '16px', md: '20px' });
  const showStartNowLabel = !hasDocuments && canShowStartNowLabel;
  // image
  const imageMarginTop = useBreakpointValue({ base: '36px', md: '44px' });
  const imageMarginBottom = useBreakpointValue({ base: '10px', md: '16px', lg: '20px' });
  const iconSizeInternal = useBreakpointValue({ base: '34px', md: '42px' });
  const iconMarginTop = showStartNowLabel ? '0px' : imageMarginTop;
  // title
  const titleTextVariant = useBreakpointValue({
    base: 'bodyXSBold',
    md: 'titleXSBold',
    lg: 'titleSBold',
  });
  // subtitle
  const subtitleTextVariant = useBreakpointValue({ base: 'overlineS', lg: 'overlineM' });
  const subtitleTextColor = useBreakpointValue({
    base: 'transparencyDark.dark30',
    md: 'greytones.graphicsDark',
  });
  const showSubtitleElement = !subtitleElement || !isSmallScreen;
  const shownSubtitleElement = subtitleElement ?? (
    <Text
      variant={subtitleTextVariant}
      value={subTitleText}
      color={subtitleTextColor}
      textAlign="center"
    />
  );

  return (
    <Pressable
      width={folderWidth}
      height={folderHeight}
      marginTop={folderMarginTop}
      onPress={onPress}
      {...otherProps}
    >
      <ZStack w="100%" h="100%" alignItems="flex-start" reversed>
        {/* flap element */}
        <Container
          position="absolute"
          width={flapWidth}
          height={flapHeight}
          bg="greytones.white"
          borderTopRadius="20px"
          shadow="elevation2"
          top={flapTop}
          zIndex={-1}
        />
        <Container
          position="absolute"
          width={flapWidth}
          height={flapHeight}
          bg="greytones.white"
          borderTopRadius="20px"
          top={flapTop}
        />
        {/* delete button */}
        {onDelete && (
          <Container position="relative" width="100%" style={deleteIconElevation}>
            <IconButton
              position="absolute"
              width={deleteIconSize}
              height={deleteIconSize}
              background="greytones.greyBlue"
              path={icons.bin}
              variant="grey"
              size="13px"
              borderRadius="6px"
              onPress={onDelete}
              top={deleteIconTop}
              right={deleteIconRight}
            />
          </Container>
        )}
        {/* folder content */}
        <Container
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="100%"
          paddingTop={folderPaddingTop}
          paddingX={folderPaddingX}
          paddingBottom={folderPaddingBottom}
          bg="greytones.white"
          borderRadius="20px"
          borderTopLeftRadius="0px"
          shadow="elevation2"
        >
          <Container alignItems="center" flex={1} justifyContent="flex-start">
            {/* label */}
            {showStartNowLabel && (
              <Container marginBottom={labelMarginBottom}>
                {startNowLabel ?? (
                  <Label
                    variant="default"
                    width={labelWidth}
                    height={labelHeight}
                    showTextMarginLeft={false}
                    message="START NOW"
                    textVariant={labelTextVariant}
                  />
                )}
              </Container>
            )}
            {/* image */}
            <Container
              marginTop={!isSmallScreen ? iconMarginTop : '0px'}
              marginBottom={imageMarginBottom}
            >
              <Image name={iconName} size={iconSize ?? iconSizeInternal} />
            </Container>
            {/* title */}
            {titleElement ?? (
              <Text variant={titleTextVariant} value={titleText} noOfLines={2} textAlign="center" />
            )}
          </Container>
          <Container alignItems="center" justifyContent="flex-end">
            {/* subtitle */}
            {showSubtitleElement && shownSubtitleElement}
            {/* open folder text */}
            {canShowOpenFolderText && (
              <Text
                variant="titleXSMedium"
                value={openFolderText}
                color="brand.brandGreen"
                marginTop="24px"
              />
            )}
          </Container>
        </Container>
      </ZStack>
    </Pressable>
  );
};
