import React from 'react';
import { TouchableHighlight, View } from 'react-native';

import Icon from 'src/components/core/Icon';
import { BodyPrimaryText } from 'src/components/core/Text';
import { theme } from 'src/components/core/theme';
import { isWeb } from 'src/utils/platform';
import { testID } from 'src/common/testID';
import { styles } from 'src/components/core/List/styles';
import { SEPARATOR_SIZE, RowPropsType } from 'src/components/core/List/types';

const NATIVE_HEIGHT = 49;
const WEB_HEIGHT = 50;

export const Row = ({
  text,
  testId,
  onPress,
  iconName,
  tintColor,
  rowStyle,
  rightIcon,
  ellipsizeMode,
  numberOfLines,
  translationKey,
  translationValues,
  showArrow = false,
  infoTranslationKey,
  infoTranslationValues,
  showNotification = false,
}: RowPropsType) => {
  const infoTextStyle = infoTranslationKey ? styles.textWithInfo : styles.textNoIcon;
  const textStyle = iconName == null ? infoTextStyle : null;
  const DEFAULT_MIN_HEIGHT = isWeb ? WEB_HEIGHT : NATIVE_HEIGHT;
  const MIN_HEIGHT: number | undefined = rowStyle?.row?.minHeight || DEFAULT_MIN_HEIGHT;

  const content = (
    <View style={[styles.inner, { minHeight: MIN_HEIGHT - SEPARATOR_SIZE }]}>
      <View style={[styles.icon]}>
        {iconName && <Icon width={30} height={30} name={iconName} tintColor={tintColor} />}
        {showNotification && <View style={styles.notification} />}
      </View>
      <View style={[styles.label]}>
        <View style={[textStyle]}>
          {translationKey ? (
            <BodyPrimaryText
              id={translationKey}
              values={translationValues}
              ellipsizeMode={ellipsizeMode}
              numberOfLines={numberOfLines}
              style={rowStyle?.text}
            />
          ) : (
            <BodyPrimaryText
              ellipsizeMode={ellipsizeMode}
              numberOfLines={numberOfLines}
              style={rowStyle?.text}
            >
              {text}
            </BodyPrimaryText>
          )}
        </View>
        <View style={styles.arrow}>
          {rightIcon}
          {showArrow === true && (
            <Icon
              name="mono.icon-nav-arrow-right"
              tintColor={rowStyle?.arrow?.tintColor || theme.color.fill}
            />
          )}
          {infoTranslationKey && (
            <BodyPrimaryText
              id={infoTranslationKey}
              values={infoTranslationValues}
              ellipsizeMode={ellipsizeMode}
              numberOfLines={numberOfLines}
              color={theme.color.primary}
              style={styles.infoText}
            />
          )}
        </View>
      </View>
    </View>
  );

  return (
    <View style={[styles.row, { minHeight: MIN_HEIGHT, borderTopWidth: SEPARATOR_SIZE }]}>
      {onPress ? (
        <TouchableHighlight onPress={onPress} underlayColor={theme.color.fill} {...testID(testId)}>
          {content}
        </TouchableHighlight>
      ) : (
        content
      )}
    </View>
  );
};
