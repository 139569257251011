import { CountryCodes } from '@taxfix/types';

import { checkTrackingAvailability } from 'src/utils/tracking';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';

import { FirebaseUserPropertyKeys, setFirebaseUserProperties } from '../firebase-analytics';
import {
  actions as settingsActions,
  selectors as settingsSelectors,
} from '../../stores/modules/settings';
import { getStore } from '../../stores/util';

import { selectCurrentTaxYearForCountry } from './select-current-tax-year';

export const selectCountry = (country: CountryCodes) => {
  const store = getStore();
  if (!store) throw new Error('redux store is not available');
  store.dispatch(settingsActions.selectCountry(country));
  setFirebaseUserProperties({
    [FirebaseUserPropertyKeys.TaxCountry]: country,
  });
  checkTrackingAvailability({
    selectedCountry: country,
    accessToken: userAuthSelectors.getAccessToken(store.getState()),
  });
};
// `country` parameter should be used ONLY when it has been selected from supported countries list
export const selectYearForCountry = (country?: CountryCodes) => {
  const store = getStore();
  if (!store) throw new Error('redux store is not available');
  const selectedCountry = country || settingsSelectors.selectedCountry(store.getState());
  selectCurrentTaxYearForCountry(selectedCountry);
};
export const selectCountryAndYear = (country: CountryCodes) => {
  selectCountry(country);
  selectYearForCountry();
};
