import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import initial from '../stores/store/initial';
import {
  selectors as settingsSelectors,
  actions as settingsActionCreators,
} from '../stores/modules/settings';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

type Props = {
  selectedCountry: CountryCodes;
  updateCountrySpecific: typeof settingsActionCreators.updateCountrySpecific;
  hasSeenInfoButtonTooltip: boolean;
};
export type OnInfoButtonTooltipOpened = () => void;
export type OnInfoButtonTooltipClosed = (
  onInfoButtonPressed: () => void,
) => (pressedInfoButton: boolean) => void;
export type WithInfoButtonTooltipProps = {
  hasSeenInfoButtonTooltip: boolean;
  onInfoButtonTooltipOpened: OnInfoButtonTooltipOpened;
  onInfoButtonTooltipClosed: OnInfoButtonTooltipClosed;
};
export const withInfoButtonTooltip =
  (infoButtonId: string) => (WrappedComponent: React.ComponentType<any>) => {
    const SETTING_NAME = `hasSeenInfoButtonTooltip.${infoButtonId}`;

    class Enhancer extends React.PureComponent<any & Props> {
      handleInfoButtonTooltipOpened = () => {
        Analytics.log(AnalyticsEvent.infoButtonTooltipOpened, {
          infoButtonId,
        });
      };

      handleInfoButtonTooltipClosed =
        (onInfoButtonPressed: () => void) => (pressedInfoButton: boolean) => {
          const { updateCountrySpecific, selectedCountry } = this.props;
          updateCountrySpecific(selectedCountry, SETTING_NAME, true);
          Analytics.log(AnalyticsEvent.infoButtonTooltipClosed, {
            infoButtonId,
            closeMethod: pressedInfoButton ? 'target-tap' : 'screen-tap',
          });
          if (pressedInfoButton) onInfoButtonPressed();
        };

      render() {
        const { hasSeenInfoButtonTooltip, ...rest } = this.props;
        return (
          <WrappedComponent
            hasSeenInfoButtonTooltip={hasSeenInfoButtonTooltip}
            onInfoButtonTooltipOpened={this.handleInfoButtonTooltipOpened}
            onInfoButtonTooltipClosed={this.handleInfoButtonTooltipClosed}
            {...rest}
          />
        );
      }
    }

    const mapStateToProps = (state: typeof initial) => ({
      selectedCountry: settingsSelectors.selectedCountry(state),
      hasSeenInfoButtonTooltip:
        settingsSelectors.selectCurrentCountrySpecifics(state)[SETTING_NAME],
    });

    const mapDispatchToProps = (dispatch: any) => ({
      updateCountrySpecific: bindActionCreators(
        settingsActionCreators.updateCountrySpecific,
        dispatch,
      ),
    });

    return connect(mapStateToProps, mapDispatchToProps)(Enhancer);
  };
