import * as React from 'react';

import navigate, { Action } from '../../routes/navigate';
import { getBoundNavigationsActions } from '../../routes/config-util';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { DebugResponsesContainer } from './debug-responses-container';

export const navigateTo: Action = navigate('DebugResponses');

const DebugResponsesScreen = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'Debug Responses' });

  const handleOnImport = () => {
    const navigationActions = getBoundNavigationsActions();
    navigationActions.toImportResponses('screen', {
      onContinue: () => {
        navigationActions.back();
      },
    });
  };

  return <DebugResponsesContainer onImport={handleOnImport} />;
};

export default DebugResponsesScreen;
