import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import Overlay from '../Overlay';
import { theme } from '../theme';

import Modal, { ModalProps } from './Modal';

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type ModalWithOverlayProps = ModalProps & {
  onOverlayPress: () => void;
  justifyContent?: JustifyContent;
  backgroundColor?: string;
};

const getStyles = (
  justifyContent: JustifyContent | undefined,
  backgroundColor: string | undefined,
) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    overlay: {
      flex: 1,
      justifyContent: justifyContent || 'center',
      backgroundColor: backgroundColor || theme.color.overlay,
    },
    touchablePrevent: {
      alignSelf:
        justifyContent === 'flex-end' ||
        justifyContent === 'flex-start' ||
        justifyContent === 'center'
          ? justifyContent
          : undefined,
    },
  });

const ModalWithOverlay = ({
  onOverlayPress,
  children,
  justifyContent,
  backgroundColor,
  ...props
}: ModalWithOverlayProps): JSX.Element => {
  const styles = getStyles(justifyContent, backgroundColor);

  return (
    <Modal {...props}>
      <TouchableOpacity style={styles.container} onPress={onOverlayPress} activeOpacity={1}>
        <Overlay style={styles.overlay}>
          <TouchableOpacity
            onPress={(e) => e.preventDefault()}
            activeOpacity={1}
            style={styles.touchablePrevent}
          >
            {children}
          </TouchableOpacity>
        </Overlay>
      </TouchableOpacity>
    </Modal>
  );
};
export default ModalWithOverlay;
