import React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../hooks/navigation-options-hook';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ItalyDigiCafProfileOccupation } from '../../../screens/containers/italy-digicaf-profile-occupation';
import { ItalyWAYSidebar } from '../../../containers/sidebar/italy-way-sidebar';

type ActionParams = {
  isNewUser: boolean;
  onNext: () => void;
  progress: number;
  titleTranslationKey?: string;
};
type Props = Navigation<
  'ProfileOccupation',
  {
    ProfileOccupation: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate('ProfileOccupation', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ProfileWay',
  },
});

export const ProfileOccupation = ({ route }: Props): JSX.Element => {
  const { onNext, progress, titleTranslationKey, isNewUser = true } = route.params;

  useWebNavigationOptions({
    sidebar: isNewUser ? () => <ItalyWAYSidebar step="occupation" /> : undefined,
  });

  useDefaultNavigationOptions(
    { id: titleTranslationKey ?? 'it.occupation.header.title' },
    {
      headerBackTitle: '',
    },
  );

  return (
    <ItalyDigiCafProfileOccupation onNext={onNext} progress={progress} isNewUser={isNewUser} />
  );
};
