import { CountryCodes } from '@taxfix/types';

import { ConfigKeys } from '../../../../stores/modules/dev-tools';

import { DebugMenuOptions, MenuOption } from './types';

export function buildDisableEnglishOption(props: DebugMenuOptions): MenuOption {
  const { devToolsIsEnglishDisabled, devToolsActions, selectedCountry } = props;

  if (selectedCountry === CountryCodes.DE) {
    return null;
  }

  const handleDisableEnglish = () => {
    devToolsActions.setConfig(ConfigKeys.IsEnglishDisabled, !devToolsIsEnglishDisabled);
  };

  return {
    text: devToolsIsEnglishDisabled ? 'Enable English' : 'Disable English',
    onPress: handleDisableEnglish,
    showArrow: true,
  };
}
