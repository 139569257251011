import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { BodySecondaryText, LinkText } from '../core';

type Props = {
  intl: IntlShape;
  translationKey: string;
  translationValues?: any;
  onPress?: (arg0: any) => void;
  linkComponent?: React.ComponentType<any>;
  textComponent?: React.ComponentType<any>;
  linkStyle?: any;
  textStyle?: any;
  testId?: any;
  style?: any;
  disabled?: boolean;
};
type LinkObject = {
  linkText: string;
  onPressParamter: string;
};
type Link = LinkObject | string;
const extractLinks = /\[([^\]]*)\]\(([^)]*)\)/g;
export function getTextWithLinks(text: string) {
  const arr = [];
  let result = null;
  let prevIndex = 0;

  // eslint-disable-next-line no-cond-assign
  while ((result = extractLinks.exec(text)) !== null) {
    // @ts-ignore
    const [match, linkText, onPressParamter] = result;
    // @ts-ignore
    const { index } = result;

    if (index !== prevIndex) {
      arr.push(text.substring(prevIndex, index));
    }

    arr.push({
      linkText,
      onPressParamter,
    });
    prevIndex = index + match.length;
  }

  if (prevIndex !== text.length) {
    arr.push(text.substring(prevIndex));
  }

  return arr;
}
export const WrappedLinkableText = ({
  intl,
  translationKey,
  translationValues = {},
  onPress,
  textComponent,
  linkComponent,
  linkStyle,
  textStyle,
  disabled = false,
  ...props
}: Props) => {
  const message = intl.formatMessage(
    {
      id: translationKey,
    },
    translationValues,
  );
  const texts = getTextWithLinks(message);
  const TextComponent = textComponent || BodySecondaryText;
  const LinkComponent = linkComponent || LinkText;
  return (
    <TextComponent {...props}>
      {texts.map((text: Link, index) => {
        if (typeof text === 'string') {
          return (
            <TextComponent key={index} style={textStyle}>
              {text}
            </TextComponent>
          );
        }

        if (text.linkText && text.onPressParamter) {
          // @ts-ignore
          const callback = () => (disabled ? () => {} : onPress(text.onPressParamter));

          return (
            <LinkComponent key={index} onPress={callback} style={linkStyle}>
              {text.linkText}
            </LinkComponent>
          );
        }

        return null;
      })}
    </TextComponent>
  );
};
export default injectIntl(WrappedLinkableText);
