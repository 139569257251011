/* global window */
export const initIframeResizer = () => {
  const post = (data: any) => {
    // Android webview seems to be unhappy if we use postMessage stored in own variable
    // see https://github.com/react-native-community/react-native-webview/issues/323#issuecomment-511824940
    if (window.ReactNativeWebView.postMessage)
      window.ReactNativeWebView.postMessage(JSON.stringify(data)); // eslint-disable-line no-undef
  };

  post({
    type: 'init-iframe-resizer',
  });
};
