import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';

type Props = ListRowComponentPropsType<any> & WithNavigation;

const EditProfile = (props: Props) => {
  const { showIcon = false } = props;
  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions } = props;
    trackMenuItem(item);
    navigationActions.toProfileDetails('screen');
  };

  return props.children({
    hide: false,
    data: {
      iconName: showIcon ? 'menuItems.account' : undefined,
      translationKey: menuItem.editProfile,
      testId: menuItem.editProfile,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const EditProfileMenu = compose(withNavigation)(EditProfile);
