import React from 'react';

import { Box } from '../core';
import { Footer } from '../footer';

import { OverlayHighlightWithCaption } from './with-caption';
import { HighlightCoordinate } from './default';

type Props = {
  coordinate: HighlightCoordinate;
  caption: {
    key: string;
    values?: Record<string, string>;
  };
  button: {
    key: string;
  };
  onClose: () => void;
};
export const OverlayHighlightWithCaptionAndButton = ({
  coordinate,
  onClose,
  caption,
  button,
}: Props) => (
  <OverlayHighlightWithCaption
    coordinate={{
      x: coordinate.x,
      y: coordinate.y,
    }}
    caption={caption}
  >
    <Box flex alignVertically="end">
      <Box>
        <Footer onPress={onClose} translationKey={button.key} testId="referral.got-it" />
      </Box>
    </Box>
  </OverlayHighlightWithCaption>
);
