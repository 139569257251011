import { NavigateAction, navigation, resetRoot } from '../services/navigation-service';

import { NavigationActions } from './navigation-actions';
import { ActionArgs } from './navigate';

export type ScreenMode = 'screen' | 'modal' | 'url';

export type { NavigationActions } from './navigation-actions';

let screenActions: NavigationActions = {} as NavigationActions;
let boundScreenActions = {} as NavigationActions;

export const setNavigationActions = (_screenActions: NavigationActions): void => {
  screenActions = _screenActions;
};

export const getNavigationActions = (): NavigationActions => screenActions;

export const getBoundNavigationsActions = (): NavigationActions => boundScreenActions;

export const bindNavigationActions = (): void => {
  const actions = getNavigationActions();
  boundScreenActions = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/ban-types
    Object.entries(actions).map(([key, action]: [string, Function]) => {
      if (key in navigation) {
        return [key, action];
      }

      return [key, (...args: ActionArgs) => navigation.navigate(action(...args))];
    }),
  ) as NavigationActions;
};

export const safeResetNavigation = (navigationStack: NavigateAction[]): void => {
  if (navigationStack.length) {
    if (navigationStack.some((route) => route.name.startsWith('modal'))) {
      throw new Error("An Element in the stack shouldn't be a modal.");
    } else {
      resetRoot({
        routes: [
          {
            name: 'modal/Home',
            // https://reactnavigation.org/docs/configuring-links/#handling-nested-navigators
            // @ts-ignore
            state: {
              routes: navigationStack,
            },
          },
        ],
      });
    }
  } else {
    resetRoot({
      routes: [],
    });
  }
};
