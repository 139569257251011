import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { AllowedDocTypes, RouteParams } from '../types';
import { UploadContainerDecorated } from '../components/upload-container';

export const toDrivingLicenseUpload: Action = navigate(ScreenName.DrivingLicenseUpload, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.DrivingLicenseUpload,
  },
});

type Props = Navigation<
  'PrefillDocumentUploadReview',
  {
    PrefillDocumentUploadReview: NavigationParams<RouteParams>;
  }
>;

export const DrivingLicenseUpload: React.FC<Props> = ({ route }) => {
  const { onNext } = route.params;

  useDefaultNavigationOptions({ id: 'it.driving-license-upload.nav.title' });

  return (
    <UploadContainerDecorated documentType={AllowedDocTypes.DRIVING_LICENSE} onNext={onNext} />
  );
};
