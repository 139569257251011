import React, { FC } from 'react';

import { CheckMarkSolid, Container, Text } from 'src/taxfix-components/src';
import { theme } from 'src/components/core';
import { ProductBundleValues } from 'src/common/constants-it';

import { useItalyIntl } from '../../_hooks';

import { InfoSection } from './sections';

type Products = ProductBundleValues.guided | ProductBundleValues.instant;

type InfoSectionProps = InfoSection & {
  product: Products;
  fullPrice?: number;
};

const getBold = (text: string) => <Text variant="subtitleMMedium" fontWeight="bold" value={text} />;

export const InfoSectionItem: FC<InfoSectionProps> = ({ bulletPoints, id, product, fullPrice }) => {
  const { getTranslationText } = useItalyIntl();
  const backgroundColor =
    product === ProductBundleValues.guided
      ? theme.color.greenBackground
      : theme.color.darkBlueIconBackground;
  const isSectionPair = id % 2 === 0;

  return (
    <Container
      padding={24}
      backgroundColor={isSectionPair ? theme.color.background : backgroundColor}
    >
      <Text variant="titleSBold" marginY={16}>
        {getTranslationText(`it.product-bundle-screen.more-info.section.${id}.title`)}
      </Text>
      {bulletPoints.map((bullet) => {
        return (
          <Container key={bullet} display="flex" flexDir="row" alignItems="flex-start" marginY={12}>
            <CheckMarkSolid size="20px" fillColor={theme.color.primary} />
            <Text variant="bodyMBook" marginLeft={10} flex={1}>
              {getTranslationText(
                `it.product-bundle-screen.more-info-${product}.section.${id}.bullet.${bullet}`,
                { bold: getBold, fullPrice },
              )}
            </Text>
          </Container>
        );
      })}
    </Container>
  );
};
