import { HStack } from 'native-base';
import React from 'react';

import { Button, Container, StepsIcon, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * StatusFooterWeb component contains StepsIcon, label, step and Button
 */

export const StatusFooterWeb: React.FC<Props> = ({
  iconType,
  label = 'prossima',
  step,
  buttonTitle = 'Continua',
  buttonVariant = 'primary',
  onButtonPress,
  ...otherProps
}): JSX.Element => {
  return (
    <HStack alignItems="center" {...otherProps}>
      <Container marginRight={1}>
        <StepsIcon type={iconType} size={16} />
      </Container>
      <Container marginRight={3}>
        <Text value={label} variant="bodyXSBook" color="greytones.textLight" />
        <Text value={step} variant="titleXSMedium" />
      </Container>
      <Button variant={buttonVariant} title={buttonTitle} width={100} onPress={onButtonPress} />
    </HStack>
  );
};
