import { useState, useEffect } from 'react';
import { CountryCodes } from '@taxfix/types';
import { useSelector } from 'react-redux';

import { getProduct } from 'src/services/submissions';
import { ProductBundleValues } from 'src/common/constants-it';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { getDefaultTaxYear } from 'src/services/country-and-year';

export type ProductBundlesPrices = { instant: number; guided: number };

export const useProductPrices = (
  instantProductPrice: number,
  guidedProductPrice: number,
): ProductBundlesPrices => {
  const year = useSelector(settingsSelectors.selectedYear) ?? getDefaultTaxYear();
  const accessToken = useSelector(userAuthSelectors.getAccessToken);

  const [productBundlesPrices, setProductBundlesPrices] = useState({
    instant: instantProductPrice,
    guided: guidedProductPrice,
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const instantProduct = await getProduct(
          accessToken,
          year,
          CountryCodes.IT,
          ProductBundleValues.instant,
        );
        const guidedProduct = await getProduct(
          accessToken,
          year,
          CountryCodes.IT,
          ProductBundleValues.guided,
        );
        setProductBundlesPrices({
          instant: instantProduct.amount / 100,
          guided: guidedProduct.amount / 100,
        });
      } catch (err) {
        // do nothing, swallow the error
      }
    };

    fetchProducts();
  }, [accessToken, year, instantProductPrice, guidedProductPrice]);

  return productBundlesPrices;
};
