import { Prefill as PrefillTypes } from '@taxfix/italy-types';

const PrefillResultTaxScenario = PrefillTypes.PrefillResultScenarios;

export const useForceAdvance = (taxScenario: PrefillTypes.PrefillResultScenarios): boolean => {
  switch (taxScenario) {
    case PrefillResultTaxScenario.PositiveNoOptimizationMandatoryCedolare:
    case PrefillResultTaxScenario.PositiveOptimizationPossibleMandatoryCedolare:
    case PrefillResultTaxScenario.NegativeNoOptimizationMandatoryCedolare:
    case PrefillResultTaxScenario.NegativeNoOptimizationMandatoryCedolareNoDays:
    case PrefillResultTaxScenario.NegativeNoOptimizationMandatoryNoCedolareNoDays:
    case PrefillResultTaxScenario.NegativeOptimizationPossibleMandatoryCedolare:
    case PrefillResultTaxScenario.NegativeOptimizationPossibleMandatoryCedolareNoDays:
    case PrefillResultTaxScenario.NegativeOptimizationPossibleMandatoryNoCedolareNoDays:
    case PrefillResultTaxScenario.NAMandatory:
      return true;
    default:
      return false;
  }
};
