import { Box, useTheme } from 'native-base';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getImageAsset } from 'src/assets/image-assets';
import { WithOnboardingVersionProps } from 'src/hocs/with-onboarding-version';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { AvatarGroup, Button, Container, Image, Text } from 'src/taxfix-components/src';
import { isWeb } from 'src/utils/platform';
import { transparencyTracker } from 'src/utils/transparency-tracker';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { useLargeScreenQuery } from '../../components/core';
import { ScreenName } from '../../types/screen-name';

type Props = {
  onPress: () => void;
  isLoading?: boolean;
  banner?: ReactNode;
} & WithOnboardingVersionProps;

type onBoardingProps = {
  image: string;
  header: string;
  subHeader: string;
  button: string;
  metric: string;
};

export const onboardingPropsPreseason: onBoardingProps = {
  image: 'peopleAchievement',
  header: 'it.onboarding-screen-preseason.header-default',
  subHeader: 'it.onboarding-screen-preseason.sub-header-default',
  button: 'it.onboarding-screen-preseason.cta-button',
  metric: 'it.onboarding-screen-preseason.onboarding-metric',
};

export const onboardingPropsInSeason: onBoardingProps = {
  image: 'onBoardingHand',
  header: 'it.onboarding-screen.header-default',
  subHeader: 'it.onboarding-screen.sub-header-default',
  button: 'it.onboarding-screen.cta-button',
  metric: 'it.onboarding-screen.onboarding-metric',
};

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

export const ItalyOnboarding: React.FC<Props> = ({ onPress, banner }) => {
  const { colors } = useTheme();
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();
  const isPreseason = useSelector(getTaxSeasonInfo).isPreSeason;
  const onboardingProps = isPreseason ? onboardingPropsPreseason : onboardingPropsInSeason;
  const { image, header, subHeader, button, metric } = onboardingProps;

  const handleOnPressMainAction = async () => {
    const handleOnPress = () => {
      Analytics.log(AnalyticsEvent.buttonPressed, {
        screenName: ScreenName.Onboarding,
        buttonName: 'Register now',
      });
      onPress();
    };
    await transparencyTracker(handleOnPress);
  };

  const cardExpertsPhotos = [
    'commercialista.commercialistaLaura2',
    'commercialista.commercialistaStephan',
    'commercialista.commercialistaAlessandra2',
  ].map(getImageAsset);

  return (
    <Box flex={1}>
      {banner}
      <Box flex={1} justifyContent={isLargeScreen ? 'center' : 'space-between'} alignItems="center">
        <Box marginY={['auto', 'auto', '0']} marginX="20px">
          <Box marginX="auto" marginBottom={[58, 35]}>
            <Image name={image} width={[233, 320]} height={[154, 212]} />
          </Box>
          <Text
            variant={isLargeScreen ? 'titleXLBold' : 'titleMBold'}
            value={getTranslationText(header)}
            textAlign="center"
            marginBottom={['14px', '16px']}
          />
          <Box maxW="526px">
            <Text
              color={colors.greytones.textDefault}
              fontSize={isLargeScreen ? '16px' : '14px'}
              textAlign="center"
              lineHeight="20px"
              value={getTranslationText(subHeader, { bold: getBoldText })}
            />
          </Box>
        </Box>
        <Box
          flexDir="row"
          alignItems="center"
          justifyContent={['space-between', 'center']}
          marginBottom={[35, 0]}
          paddingTop={isWeb ? 66 : null}
          w="full"
          paddingX={24}
          flexWrap="wrap"
        >
          {/* the color #F6F3F6 does not exist in the theme */}
          <Box
            width={isLargeScreen ? '175px' : '165px'}
            height="36px"
            backgroundColor="#F6F3F6"
            borderRadius={99}
            padding="4px"
            flexDir="row"
            alignItems="center"
            marginRight={[0, 20]}
          >
            <Box marginRight="6px">
              <AvatarGroup
                avatarSources={cardExpertsPhotos}
                certified={false}
                avatarProps={{ size: '24px', marginLeft: '-10px', borderWidth: 2 }}
              />
            </Box>
            <Box flex={1}>
              <Text
                color={colors.greytones.textLight}
                fontSize="11px"
                lineBreakMode="tail"
                lineHeight="12px"
                value={getTranslationText('it.onboarding-screen.avatar.text')}
                padding="2px"
              />
            </Box>
          </Box>
          <Box
            width={isLargeScreen ? '175px' : '150px'}
            height="36px"
            padding="4px"
            flexDir="row"
            alignItems="center"
          >
            <Box marginRight="6px">
              <Image name="dollarBag" resizeMode="contain" width="20px" height="20px" />
            </Box>
            <Box flex={1}>
              <Text
                color={colors.greytones.backgroundDark}
                fontSize="12px"
                lineBreakMode="tail"
                lineHeight="12px"
                value={getTranslationText(metric)}
                padding="2px"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Container
        alignItems="center"
        paddingY="16px"
        paddingX="24px"
        backgroundColor="white"
        borderTopWidth="1px"
        borderTopColor={colors.greytones.graphicsLight}
      >
        <Button
          variant="primary"
          width="100%"
          maxW={isLargeScreen ? 137 : '100%'}
          onPress={handleOnPressMainAction}
          title={getTranslationText(button)}
        />
      </Container>
    </Box>
  );
};
