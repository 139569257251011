import { IBoxProps, useBreakpointValue } from 'native-base';
import React from 'react';

import { Card } from 'src/taxfix-components/src';
/**
 * InformationBanner with greenLight background and text as content
 */

export const InformationBanner: React.FC<IBoxProps> = ({
  children,
  ...otherProps
}): JSX.Element => {
  const paddingYInformationBanner = useBreakpointValue({ base: '8px', md: '24px' });
  return (
    <Card
      variant="sticky1"
      backgroundColor="secondaryFeedback.greenLight"
      paddingX="24px"
      paddingY={paddingYInformationBanner}
      {...otherProps}
    >
      {children}
    </Card>
  );
};
