import React from 'react';
import { StackNavigationOptions } from '@react-navigation/stack';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';

import { FooterOptions } from 'src/components/footer.web';

import { ReactNavigation } from '../services/navigation-service';
import { AnalyticsEvent, Params as AnalyticsParams } from '../biz-logic/analytics';

import { NavigationActions, ScreenMode } from './config-util';

export type Tracking = {
  name: string;
  params?: AnalyticsParams;
};

export type TrackingUnion =
  | ((state: Record<string, any>, params: Record<string, any>) => Tracking)
  | Tracking
  | AnalyticsEvent;

export type NavigationParams<ActionParams> = ActionParams & {
  key?: string;
  isModal?: boolean;
  tracking?: TrackingUnion;
};

type NavigationModalParams<ActionParams> = {
  key?: string;
  screen: string;
  params: NavigationParams<ActionParams>;
};

export type NavigateAction<ActionParams = {}> = {
  name: string;
  params: NavigationParams<ActionParams> | NavigationModalParams<ActionParams>;
};

export type ActionArgs<ActionParams = unknown> = [
  mode: ScreenMode,
  params?: NavigationParams<ActionParams>,
];

export type Action<ActionParams = unknown> = (
  ...args: ActionArgs<ActionParams>
) => NavigateAction<ActionParams>;

export type Navigation<N extends keyof P, P extends Record<string, unknown>> = ReactNavigation<
  N,
  P
>;

export type CustomHeaderRightProps = { tintColor?: string; parentTab?: ParentTabs };

export type ScreenOptions = StackNavigationOptions &
  BottomTabNavigationOptions & {
    // WEB STACK NAVIGATOR OPTIONS
    footerShown?: boolean;
    isFullHeight?: boolean;
    columnLayout?: boolean;
    footerOptions?: FooterOptions;
    // CUSTOM SCREEN OPTIONS
    headerRight?: (props: CustomHeaderRightProps) => React.ReactNode;
  };

export type ParentTabs = 'tax-returns' | 'todos' | 'my-expert' | 'account' | 'auth';

export type ScreenParams = {
  isModal?: boolean;
  hideSafeArea?: boolean;
  parentTab?: ParentTabs;
};

export type RouteProps = {
  routeName: string;
  tracking?: TrackingUnion;
  screenParams?: ScreenParams;
  screenOptions?: ScreenOptions;
  screenComponent: React.ReactNode | unknown;
  screenNavigationOptions?: Record<string, any>;
};

export type ScreenConfig = {
  screen: React.ReactNode | any;
  params?: ScreenParams;
  options?: ScreenOptions;
  navigationOptions?: Record<string, any>;
};

export type ScreenType = [routeName: string, screenConfig: ScreenConfig];

export type StackType = {
  stack: any;
  screens: ScreenType[];
  params?: ScreenParams;
  options?: ScreenOptions;
};

export type RouteScreenType = [routeName: string, screenConfig: ScreenConfig | StackType];

export type RouteScreenProps<ActionParams> = {
  action: Action<ActionParams>;
  screen: ScreenType;
};

export type CountryRoutesConfigType = {
  navigationActions: NavigationActions;
  screens: RouteScreenType[];
};

const getParams = <ActionParams>(
  params: ActionParams,
  isModal: boolean,
  tracking: TrackingUnion | undefined,
  name: string,
) => {
  const defaultParams: NavigationParams<ActionParams> = { ...params, isModal, tracking };

  // This is necessary to propagate the params to nested routes on modal.
  if (isModal) {
    const modalParams: NavigationModalParams<ActionParams> = {
      screen: `screen/${name}`,
      params: defaultParams,
    };

    return modalParams;
  }

  return defaultParams;
};

const navigate =
  <ActionParams>(name: string, tracking?: TrackingUnion): Action<ActionParams> =>
  (mode, params) => {
    const actionParams = params || ({} as ActionParams);
    return {
      name: `${mode}/${name}`,
      params: getParams<ActionParams>(actionParams, mode === 'modal', tracking, name),
    };
  };

export const getRoute = <ActionParams>({
  routeName,
  tracking,
  screenParams,
  screenOptions,
  screenNavigationOptions,
  screenComponent: ScreenComponent,
}: RouteProps): RouteScreenProps<ActionParams> => {
  return {
    action: navigate<ActionParams>(routeName, tracking),
    screen: [
      routeName,
      {
        screen: ScreenComponent,
        params: screenParams,
        options: screenOptions,
        navigationOptions: screenNavigationOptions,
      },
    ],
  };
};

export default navigate;
