import { Prefill } from '@taxfix/italy-types';

import { theme } from 'src/components/core';

import { OptimizationVersion } from './result-optimization/result-optimization-types';
import { ScreenState } from './types';

export const FORCED_ADVANCED_BUNDLE = 'forcedAdvanced';

const positiveNoOptimizationCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.positiveNoOptimization.title',
  cardBgColor: theme.color.secondaryAccentGreenLight,
  cardDescription: 'prefill-result-screen.result-card.positiveNoOptimization.description',
  linkTranslationKey: 'prefill-result-screen.result-card.positiveNoOptimization.link',
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.positiveNoOptimization.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.positiveNoOptimization.description',
  buttonTranslationKey: 'prefill-result-screen.positiveNoOptimization.button',
  showConfetti: true,
  showEstimatedResult: true,
};

const positiveOptimizationPossibleCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.positiveOptimizationPossible.title',
  cardBgColor: theme.color.secondaryAccentGreenLight,
  cardDescription: 'prefill-result-screen.result-card.positiveOptimizationPossible.description',
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.positiveOptimizationPossible.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.positiveOptimizationPossible.description',
  buttonTranslationKey: 'prefill-result-screen.positiveOptimizationPossible.button',
  taxExpertCardLinkText: 'prefill-result-screen.positiveOptimizationPossible.taxExpertCardLinkText',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showConfetti: true,
  showEstimatedResult: true,
};

const negativeNoOptimizationMandatoryNoCedolareCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.title',
  cardBgColor: theme.color.darkBlueIconBackground,
  cardDescription:
    'prefill-result-screen.result-card.negativeNoOptimizationMandatoryNoCedolare.description',
  taxExpertCardTitle:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.description',
  buttonTranslationKey: 'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.button',
  taxExpertCardLinkText:
    'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.taxExpertCardLinkText',
  taxExpertCardWarning:
    'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.taxExpertWarningText',
  taxExpertAverageRefundChipText:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.averageRefundChip',
  resultOptimizationScreenVersion: OptimizationVersion.NoOptimization,
  showEstimatedResult: true,
};

const negativeOptimizationPossibleMandatoryNoCedolareCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.title',
  cardBgColor: theme.color.darkBlueIconBackground,
  cardDescription:
    'prefill-result-screen.result-card.negativeOptimizationPossibleMandatoryNoCedolare.description',
  taxExpertCardTitle:
    'prefill-result-screen.tax-expert-card.negativeOptimizationPossibleMandatoryNoCedolare.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.negativeOptimizationPossibleMandatoryNoCedolare.description',
  buttonTranslationKey:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.button',
  taxExpertCardLinkText:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.taxExpertCardLinkText',
  taxExpertCardWarning:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.taxExpertWarningText',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showEstimatedResult: true,
};

const resultZeroNoOptimizationContent: ScreenState = {
  screenTitle: 'prefill-result-screen.resultZeroNoOptimization.title',
  cardBgColor: theme.color.secondaryAccentGreenLight,
  linkTranslationKey: 'prefill-result-screen.result-card.resultZeroNoOptimization.link',
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.resultZeroNoOptimization.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.resultZeroNoOptimization.description',
  buttonTranslationKey: 'prefill-result-screen.resultZeroNoOptimization.button',
  showConfetti: true,
  showEstimatedResult: false,
};

const resultZeroOptimizationPossibleContent: ScreenState = {
  screenTitle: 'prefill-result-screen.resultZeroOptimizationPossible.title',
  cardBgColor: theme.color.secondaryAccentGreenLight,
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.resultZeroOptimizationPossible.title',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.resultZeroOptimizationPossible.description',
  buttonTranslationKey: 'prefill-result-screen.resultZeroOptimizationPossible.button',
  taxExpertCardLinkText:
    'prefill-result-screen.resultZeroOptimizationPossible.taxExpertCardLinkText',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showConfetti: true,
  showEstimatedResult: false,
};

const NAMandatoryContent: ScreenState = {
  screenTitle: 'prefill-result-screen.nAMandatory.title',
  cardBgColor: theme.color.darkBlueIconBackground,
  cardDescription: 'prefill-result-screen.result-card.nAMandatory.description',
  //! this link is only showed if there are documents
  linkTranslationKey: 'prefill-result-screen.result-card.nAMandatory.link',
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.nAMandatory.title',
  taxExpertCardDescription: 'prefill-result-screen.tax-expert-card.nAMandatory.description',
  taxExpertAverageRefundChipText:
    'prefill-result-screen.tax-expert-card.nAMandatory.averageRefundChip',
  buttonTranslationKey: 'prefill-result-screen.nAMandatory.button',
  showEstimatedResult: false,
};

export const prefillResultScenarios: Record<Prefill.PrefillResultScenarios, ScreenState> = {
  [Prefill.PrefillResultScenarios.PositiveNoOptimization]: {
    ...positiveNoOptimizationCommonContent,
  },
  [Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryNoCedolare]: {
    ...positiveNoOptimizationCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.positiveNoOptimizationMandatoryNoCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryCedolare]: {
    ...positiveNoOptimizationCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.positiveNoOptimizationMandatoryCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.PositiveOptimizationPossible]: {
    ...positiveOptimizationPossibleCommonContent,
  },
  [Prefill.PrefillResultScenarios.PositiveOptimizationPossibleMandatoryNoCedolare]: {
    ...positiveOptimizationPossibleCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.positiveOptimizationPossibleMandatoryNoCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.PositiveOptimizationPossibleMandatoryCedolare]: {
    ...positiveOptimizationPossibleCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.positiveOptimizationPossibleMandatoryCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolare]: {
    ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
  },
  [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolare]: {
    ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.negativeNoOptimizationMandatoryCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolareNoDays]: {
    ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.negativeNoOptimizationMandatoryCedolareNoDays.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolareNoDays]: {
    ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
  },
  [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryNoCedolare]: {
    ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
  },
  [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryCedolare]: {
    ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.negativeOptimizationPossibleMandatoryCedolare.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryCedolareNoDays]: {
    ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
    taxExpertCardWarning:
      'prefill-result-screen.negativeOptimizationPossibleMandatoryCedolareNoDays.taxExpertWarningText',
  },
  [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryNoCedolareNoDays]: {
    ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
  },

  [Prefill.PrefillResultScenarios.ResultZeroNoOptimization]: {
    ...resultZeroNoOptimizationContent,
  },
  [Prefill.PrefillResultScenarios.ResultZeroOptimizationPossible]: {
    ...resultZeroOptimizationPossibleContent,
  },
  [Prefill.PrefillResultScenarios.NAMandatory]: {
    ...NAMandatoryContent,
  },
};
