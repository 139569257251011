import type { SeverityLevel as SentrySeverityLevel } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Config from 'react-native-config';

import { UniversalSentryOptions, SentryError, SentryContext, SentryTags } from './types';

type SeverityLevel = Exclude<SentrySeverityLevel, 'critical' | 'fatal'>;

export const SENTRY_SEVERITY_LEVEL: Record<number, SeverityLevel> = {
  '1': Sentry.Severity.Info,
  '2': Sentry.Severity.Warning,
  '3': Sentry.Severity.Error,
};

export class SentryErrorTracking {
  static init(options: UniversalSentryOptions) {
    Sentry.init({
      ...options,
      release: Config.SENTRY_RELEASE,
      dist: Config.SENTRY_DIST,
      integrations: [new Integrations.BrowserTracing()],
      // 20% sample rate for performance tracing in production
      tracesSampleRate: Config.SHOW_DEBUG_SCREEN == 'true' ? 1 : 0.2,
    });
  }

  static setUser(user: Record<string, any>) {
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }

  static updateGlobalScope(tags: Record<string, any>) {
    Sentry.configureScope((scope) => {
      scope.setTags({ ...tags });
    });
  }

  static setScope(callback: () => void, tags: Record<string, any> = {}, severity = 0) {
    Sentry.withScope((scope) => {
      if (severity) scope.setLevel(SENTRY_SEVERITY_LEVEL[severity] as Sentry.Severity);
      scope.setTags({ ...tags });
      callback();
    });
  }

  static recordError(
    error: SentryError,
    context: SentryContext = {},
    tags: SentryTags = {},
    severity: number,
  ) {
    this.setScope(
      () => {
        Sentry.setExtras(context);
        Sentry.captureException(error);
      },
      tags,
      severity,
    );
  }

  static triggerCrash() {
    // noop
  }
}

export const SentryErrorBoundary = Sentry.ErrorBoundary;
