import { CountryCodes } from '@taxfix/types';

export const migration = (state: Record<string, any>) => {
  const newState = { ...state };

  const { selectedCountry } = state.settings.data;
  if (selectedCountry === CountryCodes.FR) {
    newState.settings.data.selectedCountry = CountryCodes.DE;
    newState.settings.data.selectedYear = undefined;
  }

  return newState;
};
