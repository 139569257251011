import React from 'react';
import { ScrollView, useBreakpointValue, useTheme } from 'native-base';
import { useWindowDimensions } from 'react-native';

import { Container, Text, PrimaryArticle, SecondaryArticle } from 'src/taxfix-components/src';

import { useShowSideTabs } from '../_hooks/use-screen-size';
import { TabNames } from '../../common/constants-it';
import { ItalyTabs, ITALY_TABS_HEIGHT } from '../../routes/italy-navigation/italy-tabs';
import { useItalyIntl } from '../_hooks/use-italy-intl';

import { TaxGuideArticle } from './tax-guide-container';

type TitleProps = {
  title: string;
  subtitle: string;
};

type TaxGuideComponentProps = {
  basicsArticles: TaxGuideArticle[];
  goodToKnowArticles: TaxGuideArticle[];
  curiositiesArticles: TaxGuideArticle[];
};

const TaxGuideTitleSection = ({ title, subtitle }: TitleProps) => {
  const {
    colors: {
      greytones: { graphicsDark },
    },
  } = useTheme();

  const containerAlignment = useBreakpointValue({
    base: 'flex-start',
    md: 'center',
    lg: 'flex-start',
  });
  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  const subtitleVariant = useBreakpointValue({
    base: 'bodyMBook',
    md: 'bodyMBook',
    lg: 'bodyLBook',
  });

  const subtitleMarginBottom = useBreakpointValue({ base: '4px', md: '12px', lg: '12px' });
  const subtitleWidth = useBreakpointValue({ base: 'auto', md: '378px', lg: 'auto' });
  const subtitleTextAlignment = useBreakpointValue({ base: 'left', md: 'center', lg: 'left' });

  return (
    <Container alignItems={containerAlignment}>
      <Text variant={titleVariant} marginBottom="6px">
        {title}
      </Text>
      <Text
        variant={subtitleVariant}
        color={graphicsDark}
        marginBottom={subtitleMarginBottom}
        width={subtitleWidth}
        textAlign={subtitleTextAlignment}
      >
        {subtitle}
      </Text>
    </Container>
  );
};

export const TaxGuideComponent: React.FC<TaxGuideComponentProps> = ({
  basicsArticles,
  goodToKnowArticles,
  curiositiesArticles,
}) => {
  const showSideTabs = useShowSideTabs();
  const { getTranslationText } = useItalyIntl();
  const mainContainerAlignment = useBreakpointValue({
    base: 'flex-start',
    md: 'center',
    lg: 'flex-start',
  });
  const largeScreenLeftMargin = useBreakpointValue({ lg: '60px' });
  const mainContainerWidth = useBreakpointValue({ base: '90%', md: '704px', lg: '880px' });
  const mainContainerHorizontalMargin = useBreakpointValue({ base: '20px', md: '24px' });
  const sectionMarginBottom = useBreakpointValue({ base: '64px', md: '54px', lg: '88px' });
  const isNarrowScreen = useBreakpointValue({ base: true, md: false });

  // TODO improve article components in taxfix/taxfix-components
  // temporary solution to override values in the library
  const primaryArticleMaxWidth = useBreakpointValue({ base: '100%', md: '221px', lg: '280px' });
  const articleContainerWidth = useBreakpointValue({ base: '100%', md: 'auto' });
  const secondaryArticleWidth = useBreakpointValue({ base: '100%', md: '342px', lg: '430px' });

  const { height } = useWindowDimensions();
  return (
    <Container h={height - ITALY_TABS_HEIGHT} overflow="hidden">
      <ScrollView paddingTop="45px" marginLeft={largeScreenLeftMargin}>
        <Container
          alignItems={mainContainerAlignment}
          width={mainContainerWidth}
          marginBottom="70px"
          marginX={mainContainerHorizontalMargin}
        >
          <Container marginBottom={sectionMarginBottom}>
            <TaxGuideTitleSection
              title={getTranslationText('it.tax-guide.basics.title')}
              subtitle={getTranslationText('it.tax-guide.basics.subtitle')}
            />
            <Container flexDir={isNarrowScreen ? 'column' : 'row'} maxW={articleContainerWidth}>
              {basicsArticles.map((item, index): React.ReactElement => {
                const isLastElement = index === basicsArticles.length - 1;
                const marginRight = isNarrowScreen || isLastElement ? '0px' : '20px';

                return (
                  <PrimaryArticle
                    key={item.image}
                    image={item.image}
                    title={getTranslationText(item.title)}
                    onPress={item.onPress}
                    marginRight={marginRight}
                    bgColor={item.bgColor as string}
                    maxW={primaryArticleMaxWidth}
                    width="100%"
                  />
                );
              })}
            </Container>
          </Container>

          <Container marginBottom={sectionMarginBottom}>
            <TaxGuideTitleSection
              title={getTranslationText('it.tax-guide.good-to-know.title')}
              subtitle={getTranslationText('it.tax-guide.good-to-know.subtitle')}
            />

            <Container
              flexDir={isNarrowScreen ? 'column' : 'row'}
              flexWrap={isNarrowScreen ? 'nowrap' : 'wrap'}
              maxW={articleContainerWidth}
            >
              {goodToKnowArticles.map((item, index): React.ReactElement => {
                const isEvenElement = index % 2 === 0;
                const marginRight = isEvenElement && !isNarrowScreen ? '20px' : '0px';

                return (
                  <SecondaryArticle
                    key={index}
                    image={item.image}
                    title={getTranslationText(item.title)}
                    onPress={item.onPress}
                    marginRight={marginRight}
                    w={secondaryArticleWidth}
                  />
                );
              })}
            </Container>
          </Container>

          <Container>
            <TaxGuideTitleSection
              title={getTranslationText('it.tax-guide.curiosities.title')}
              subtitle={getTranslationText('it.tax-guide.curiosities.subtitle')}
            />

            <Container
              flexDir={isNarrowScreen ? 'column' : 'row'}
              flexWrap={isNarrowScreen ? 'nowrap' : 'wrap'}
              maxW={articleContainerWidth}
            >
              {curiositiesArticles.map((item, index): React.ReactElement => {
                const isEvenElement = index % 2 === 0;
                const marginRight = isEvenElement && !isNarrowScreen ? '20px' : '0px';

                return (
                  <SecondaryArticle
                    key={index}
                    image={item.image}
                    title={getTranslationText(item.title)}
                    onPress={item.onPress}
                    marginRight={marginRight}
                    w={secondaryArticleWidth}
                  />
                );
              })}
            </Container>
          </Container>
        </Container>
      </ScrollView>
      {!showSideTabs && <ItalyTabs highlighedTab={TabNames.TaxGuide} />}
    </Container>
  );
};
