import { TextStyle, ImageStyle } from 'react-native';

export type TooltipPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
type Props = {
  x: number;
  y: number;
  imageDX: number;
  imageDYtop: number;
  imageDYbottom: number;
  imageWidth: number;
  imageHeight: number;
  textDX: number;
  textDY: number;
  padding: number;
  screenWidth: number;
  screenHeight: number;
};

const getPosition = ({ x, y, screenWidth, screenHeight }: any): TooltipPosition => {
  const halfScreenWidth = screenWidth / 2;
  const halfScreenHeight = screenHeight / 2;
  let vertical = 'bottom';
  let horizontal = 'right';

  if (x > halfScreenWidth) {
    horizontal = 'left';
  }

  if (y > halfScreenHeight) {
    vertical = 'top';
  }

  return `${vertical}-${horizontal}` as TooltipPosition;
};

type TooltipStyle = {
  image: { style: ImageStyle; position: TooltipPosition };
  text: { style: TextStyle };
};

export const getTooltipStyle = ({
  x,
  y: originalY,
  imageDX,
  imageDYtop,
  imageDYbottom,
  textDX,
  textDY,
  screenWidth,
  screenHeight,
  padding,
  imageWidth = 0,
  imageHeight = 0,
}: Props): TooltipStyle => {
  const y = originalY;
  const position = getPosition({
    x,
    y: originalY,
    screenWidth,
    screenHeight,
  });
  let imageLeft = 0;
  let imageTop = 0;
  let textLeft = 0;
  let textTop = 0;
  let textWidth = 0;
  let textAlign: TextStyle['textAlign'] = 'left';

  switch (position) {
    case 'top-right':
      imageLeft = x + imageDX;
      imageTop = y - imageDYtop;
      textLeft = x + textDX;
      textTop = y - textDY;
      textWidth = screenWidth - (x + textDX) - padding;
      textAlign = 'left';
      break;

    case 'bottom-right':
      imageLeft = x + imageDX;
      imageTop = y + imageDYbottom;
      textLeft = x + textDX;
      textTop = y + textDY;
      textWidth = screenWidth - (x + textDX) - padding;
      textAlign = 'left';
      break;

    case 'top-left':
      imageLeft = x - imageDX - imageWidth;
      imageTop = y - imageDYtop;
      textLeft = padding;
      textTop = y - textDY;
      textWidth = x - textDX - padding;
      textAlign = 'right';
      break;

    case 'bottom-left':
      imageLeft = x - imageDX - imageWidth;
      imageTop = y + imageDYbottom;
      textLeft = padding;
      textTop = y + textDY;
      textWidth = x - textDX - padding;
      textAlign = 'right';
      break;

    default:
      throw new Error(`Unknown tooltip position: ${position}`);
  }

  return {
    image: {
      style: {
        left: imageLeft,
        top: imageTop,
        width: imageWidth,
        height: imageHeight,
      },
      position,
    },
    text: {
      style: {
        left: textLeft,
        top: textTop,
        width: textWidth,
        textAlign,
      },
    },
  };
};
