import React, { memo } from 'react';
import { Platform, TextStyle } from 'react-native';
import { IntlShape, injectIntl } from 'react-intl';
import HTML from 'react-native-render-html';

import { theme } from './theme';
import { TextProps } from './Text';

type TextHtmlProps = {
  intl: IntlShape;
  color?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: TextStyle['fontWeight'];
  textAlign?: 'left' | 'center' | 'right';
  dontUseIntl?: boolean;
} & TextProps;

const TextHtmlComponent = ({
  id,
  color = theme.color.primaryText,
  fontFamily = theme.fontFamily.medium,
  fontSize = 16,
  textAlign = 'left',
  style,
  values,
  intl,
  dontUseIntl,
  fontWeight,
}: TextHtmlProps) => {
  // When there's a variable inside a tag, react-intl will parse the tag as a context
  // function (e.g <b> {customVariableHere}</b>) and will cause the formatting
  // to fail and fallback to the locale key.
  // We need to provides a formatter that will transform react-intl context mapping
  // into its HTML representation
  const formatters = {
    b: (text: any) => {
      return `<b>${text}</b>`;
    },
  };
  let htmlText = dontUseIntl
    ? id
    : intl?.formatMessage(
        {
          id,
          defaultMessage: id,
          testId: id,
        } as any,
        { ...values, ...formatters },
      );

  if (Platform.OS === 'android' && textAlign !== 'left') {
    // workaround for textAlign not working on android
    // https://github.com/meliorence/react-native-render-html/issues/347
    htmlText = `<span style="text-align: ${textAlign}">${htmlText}</span>`;
  }

  return (
    <HTML
      source={{
        html: htmlText,
      }}
      containerStyle={style}
      baseFontStyle={{
        color,
        fontSize,
        fontFamily,
        textAlign,
        fontWeight,
      }}
    />
  );
};

//The reason he need this modified version of the component is because in some cases we
//are sending the HTML directly and we dont need it to be parsed. The problem that
//was occurring in this case is that when using this component within a Modal it doesn't have
//access to the IntlProvider and hence throws an error (Could be because Modals are ususally
//portalled outside the context of the main application).
const TextHtmlWithoutIntlComponent = (props: TextHtmlProps) => (
  <TextHtmlComponent {...props} dontUseIntl={true} />
);

export const TextHtml = injectIntl(memo(TextHtmlComponent));
export const TextHtmlWithoutIntl = memo(TextHtmlWithoutIntlComponent);
