// Copy isCommercialistaAssigned, documentsOnboardingSeen, afterSubmissionFeedbackDisplayed, isConfirmed to quizmaster for ITA users
// Redux store remains unchanged
import { CountryCodes } from '@taxfix/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getQuestionStores } from 'src/stores-legacy/util';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds } from 'src/common/constants-it';
import { StatusMiddleware } from 'src/stores/modules/status';
import { selectors as requiredDocumentsSelectors } from 'src/stores/modules/required-documents';

export const flagsMigration: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const selectedYear = settingsSelectors.selectedYear(getState());
    const questionStores = getQuestionStores(CountryCodes.IT);
    const questionStore = questionStores[selectedYear!];

    if (!questionStore) {
      return next();
    }

    // @ts-ignore
    const questionIds: string[] = [...Object.values(flagsQuestionIds)];
    const nodes = getNodesByIds(questionStore, questionIds);
    const quizmasterLight = getQuizmasterLight(questionStore, nodes);

    const { documentsOnboardingSeen } = settingsSelectors.selectCurrentCountryYearSpecifics(
      getState(),
    );

    if (
      quizmasterLight[flagsQuestionIds.flowStarted].answer == null &&
      documentsOnboardingSeen != null
    ) {
      quizmasterLight[flagsQuestionIds.flowStarted].saveAnswer(documentsOnboardingSeen);
    }

    const afterSubmissionFeedbackDisplayed = settingsSelectors.getAfterSubmissionFeedbackDisplayed(
      getState(),
    );

    if (
      quizmasterLight[flagsQuestionIds.afterSubmissionFeedbackDisplayed].answer == null &&
      afterSubmissionFeedbackDisplayed != null
    ) {
      quizmasterLight[flagsQuestionIds.afterSubmissionFeedbackDisplayed].saveAnswer(
        afterSubmissionFeedbackDisplayed,
      );
    }

    const isConfirmed = requiredDocumentsSelectors.isConfirmed(getState());

    if (
      quizmasterLight[flagsQuestionIds.documentsUploadConsent].answer == null &&
      isConfirmed != null
    ) {
      quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(isConfirmed);
    }
  } catch (error) {
    logger.log('Error in flags migration', error);
    return next();
  }

  return next();
};
