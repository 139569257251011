import React, { ComponentType, ReactNode } from 'react';
import { CountryCodes } from '@taxfix/types';
import { useDispatch, useSelector } from 'react-redux';

import { FormCustomerFields, PaymentFormProps } from 'src/screens/payment/payment.types';
import { prefillQuestionIds, wayQuestionIds } from 'src/common/constants-it';
import { selectors as UserAuthSelectors } from 'src/stores/modules/user-auth';
import { handleRawValue } from 'src/services/validations';
import { useNavigation } from 'src/hooks/navigation-hook';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import { useQuizmasterLight } from 'src/_italy/_hooks';
import { getAnswers } from 'src/stores/hooks/answers-stores-hook';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { getStore } from 'src/stores/util';
import { getSanitizedInput } from 'src/validations/italy-input-fields';

import { PaymentCustomer, Product, ProductVariation } from '../../types/payment';
import { getProduct } from '../../services/submissions';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { PaymentCreditCardContainer } from './payment-container';
import { ProductInfoContainer } from './product-info';

export type ProductResolver = (
  accessToken: string,
  year: number,
  countryCode: CountryCodes,
  variation?: ProductVariation | null | undefined,
) => Promise<Product>;

export enum StripeRedirectStatus {
  failed = 'failed',
  succeeded = 'succeeded',
}

export type PaymentProps = {
  taxId?: string;
  customer?: PaymentCustomer;
  header?: string;
  title?: string;
  subtitle?: string;
  productVariation?: string;
  nextNavigationAction?: (params?: any) => void;
  formCustomerFields?: FormCustomerFields;
  enableWebviewE2E?: boolean;
  productResolver?: ProductResolver;
  children?: ReactNode;
  redirectStatus?: StripeRedirectStatus;
};
type Props = PaymentProps & {
  PaymentForm: ComponentType<PaymentFormProps>;
};

export const clearPaymentUrlParams = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

// TODO: we leave the props taxId and customer because typescript/lint warning regarding the use of this route by German legacy code.
// Once this legacy code is deleted, we can remove them
const PaymentRouteCommonUndecorated = ({
  taxId,
  customer,
  header = 'payment.navigation.title',
  title = 'payment.title',
  subtitle = 'payment.subtitle',
  productVariation,
  nextNavigationAction,
  formCustomerFields,
  PaymentForm,
  children,
  enableWebviewE2E,
  productResolver = getProduct,
  redirectStatus,
}: Props): JSX.Element => {
  const [triggerE2E, setTriggerE2E] = React.useState(false);
  const dispatch = useDispatch();
  const { getNavigationActions, safeResetNavigation } = useNavigation();

  const nodesIds = [wayQuestionIds.firstName, wayQuestionIds.lastName, prefillQuestionIds.taxId];
  const quizmasterLight = useQuizmasterLight(nodesIds);
  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const taxIdAnswer = quizmasterLight[prefillQuestionIds.taxId].answer;
  const taxIdSanitised = handleRawValue(taxIdAnswer).toUpperCase();
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const {
    form730: {
      '730/ResidenzaAnagrafica/Indirizzo': street,
      '730/ResidenzaAnagrafica/NumCivico': number,
      '730/ResidenzaAnagrafica/Comune': city,
      '730/ResidenzaAnagrafica/Cap': zipcode,
      '730/ResidenzaAnagrafica/Provincia': provincia,
    },
  } = getAnswers(questionStore);

  const billingAddress = {
    street: getSanitizedInput(`${street} ${number}`),
    zipcode,
    city,
    countryCode: CountryCodes.IT,
    state: provincia,
  };

  const customerDefault: PaymentCustomer = {
    firstName,
    lastName,
    email: useSelector(UserAuthSelectors.getEmailAddress),
    ...billingAddress,
  };

  const nextNavigationActionDefault = () => {
    dispatch(
      OverlayActions.show('SuccessOverlay', {
        icon: 'interface-icons-svg.icon-checkmark-white',
        titleId: 'it.success-overlay.payment.title',
        descriptionId: 'it.success-overlay.payment.description',
        buttonId: 'it.success-overlay.payment.button',
        exitDirection: 'top',
        onNext: () => {
          dispatch(OverlayActions.hide());
          safeResetNavigation([
            getNavigationActions().toDashboard('screen'),
            getNavigationActions().toStatus('screen'),
          ]);
        },
      }),
    );
  };

  useDefaultNavigationOptions(
    { id: header },
    {
      headerBackTitleVisible: false,
    },
  );

  return (
    <ProductInfoContainer
      productVariation={productVariation}
      nextNavigationAction={nextNavigationAction ?? nextNavigationActionDefault}
      productResolver={productResolver}
      redirectStatus={redirectStatus}
    >
      {({ product, payment }: any) => (
        <PaymentCreditCardContainer
          title={title}
          subtitle={subtitle}
          product={product}
          taxId={taxIdSanitised}
          customer={customerDefault}
          nextNavigationAction={nextNavigationAction ?? nextNavigationActionDefault}
          redirectStatus={redirectStatus}
          existingCoupon={
            payment
              ? {
                  amountCents: payment.couponAmountCents,
                  code: payment.couponCode,
                }
              : null
          }
          triggerE2E={triggerE2E}
          formCustomerFields={formCustomerFields}
          PaymentForm={PaymentForm}
        >
          {children}
        </PaymentCreditCardContainer>
      )}
    </ProductInfoContainer>
  );
};

export const PaymentRouteCommon = PaymentRouteCommonUndecorated;
