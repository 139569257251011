import { Dispatch } from 'redux';
import { UserConsents } from '@taxfix/types';
import { User } from '@taxfix/taxfix-sdk';
import Config from 'react-native-config';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

import { selectors as authSelectors } from './user-auth';

export const GET_USER_CONSENT_REQUEST = 'userConsents/GET_USER_CONSENT_REQUEST';
export const GET_USER_CONSENT_SUCCESS = 'userConsents/GET_USER_CONSENT_SUCCESS';
export const GET_USER_CONSENT_FAILED = 'userConsents/GET_USER_CONSENT_FAILED';
export const CREATE_USER_CONSENT_REQUEST = 'userConsents/CREATE_USER_CONSENT_REQUEST';
export const CREATE_USER_CONSENT_SUCCESS = 'userConsents/CREATE_USER_CONSENT_SUCCESS';
export const CREATE_USER_CONSENT_FAILED = 'userConsents/CREATE_USER_CONSENT_FAILED';

export enum Actions {
  Fetch = 'fetch',
  Create = 'create',
}

type Errors = {
  [k in Actions]?: string;
};

type LoadingStates = {
  [k in Actions]?: boolean;
};

type State = {
  [k in UserConsents.Type]: {
    consentAccepted: boolean;
    loadingStates: LoadingStates;
    errors: Errors;
  };
};

export type RootState = {
  userConsents: State;
};

const initialLoadingStates = Object.values(Actions).reduce(
  (acc, action) => ({ ...acc, [action]: false }),
  {},
);
const initialErrors = Object.values(Actions).reduce(
  (acc, action) => ({ ...acc, [action]: undefined }),
  {},
);

export const initial: State = Object.values(UserConsents.Type).reduce(
  (acc, consentType) => ({
    ...acc,
    [consentType]: {
      consentAccepted: false,
      loadingStates: initialLoadingStates,
      errors: initialErrors,
    },
  }),
  {},
) as State;

export interface Event {
  type:
    | typeof GET_USER_CONSENT_REQUEST
    | typeof GET_USER_CONSENT_SUCCESS
    | typeof GET_USER_CONSENT_FAILED
    | typeof CREATE_USER_CONSENT_REQUEST
    | typeof CREATE_USER_CONSENT_SUCCESS
    | typeof CREATE_USER_CONSENT_FAILED;
  consentType: UserConsents.Type;
  consentAccepted?: boolean;
  error?: string;
}

// generic actions
const createUserConsentRecord =
  (consentType: UserConsents.Type, newConsentState: UserConsents.State) =>
  async (dispatch: Dispatch<Event>, getState: () => any): Promise<void> => {
    const rootState = getState();
    dispatch({ consentType, type: CREATE_USER_CONSENT_REQUEST });
    const accessToken = authSelectors.getAccessToken(rootState);
    const consentAccepted = newConsentState === UserConsents.State.Approved;
    try {
      await User.createCurrentUserConsent(Config.API_BASE_URL, accessToken, {
        consentType,
        source: UserConsents.Source.MobileApp,
        state: newConsentState,
      });
      dispatch({ consentType, consentAccepted, type: CREATE_USER_CONSENT_SUCCESS });
      Analytics.log(AnalyticsEvent.createUserConsentRecordSuccess, {
        consentType,
        consentAccepted,
      });
    } catch (error: any) {
      dispatch({ consentType, type: CREATE_USER_CONSENT_FAILED, error: error.message });
      Analytics.log(AnalyticsEvent.createUserConsentRecordFailed, {
        consentType,
        consentAccepted,
        error: error.message,
      });
    }
  };

const getUserConsent =
  (consentType: UserConsents.Type) =>
  async (dispatch: Dispatch<Event>, getState: () => any): Promise<void> => {
    const rootState = getState();
    dispatch({ consentType, type: GET_USER_CONSENT_REQUEST });
    const accessToken = authSelectors.getAccessToken(rootState);
    try {
      const userConsent = await User.getCurrentUserConsent(Config.API_BASE_URL, accessToken, {
        consentType,
      });

      const consentAccepted = userConsent?.state === UserConsents.State.Approved;

      dispatch({ consentType, consentAccepted, type: GET_USER_CONSENT_SUCCESS });
    } catch (error: any) {
      dispatch({ consentType, type: GET_USER_CONSENT_FAILED, error: error.message });
      Analytics.log(AnalyticsEvent.getUserConsentRecordFailed, {
        consentType,
        error: error.message,
      });
    }
  };

// helpers for getting consentType specific actions
const getUserConsentPerType = (consentType: UserConsents.Type) => () => getUserConsent(consentType);
const createUserConsentRecordPerType =
  (consentType: UserConsents.Type) => (newConsentState: UserConsents.State) =>
    createUserConsentRecord(consentType, newConsentState);

export const actions = {
  getUserConsent,
  createUserConsentRecord,
  getSensitiveDataConsent: getUserConsentPerType(UserConsents.Type.SensitiveData),
  createSensitiveDataConsentRecord: createUserConsentRecordPerType(UserConsents.Type.SensitiveData),
  getExplicitPIIDataConsent: getUserConsentPerType(UserConsents.Type.ExplicitPIIData),
  createExplicitPIIDataConsentRecord: createUserConsentRecordPerType(
    UserConsents.Type.ExplicitPIIData,
  ),
};

export const reducer = (
  state: State = initial,
  { consentType, type, consentAccepted, error = '' }: Event,
): State => {
  switch (type) {
    case CREATE_USER_CONSENT_REQUEST:
      return {
        ...state,
        [consentType]: {
          ...state[consentType],
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Create]: true,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Create]: undefined,
          },
        },
      };

    case CREATE_USER_CONSENT_SUCCESS:
      return {
        ...state,
        [consentType]: {
          ...state[consentType],
          consentAccepted: !!consentAccepted,
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Create]: false,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Create]: undefined,
          },
        },
      };

    case CREATE_USER_CONSENT_FAILED:
      return {
        ...state,
        [consentType]: {
          ...state[consentType],
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Create]: false,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Create]: error,
          },
        },
      };

    case GET_USER_CONSENT_REQUEST:
      return {
        ...state,
        [consentType]: {
          ...state[consentType],
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Fetch]: true,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Fetch]: undefined,
          },
        },
      };

    case GET_USER_CONSENT_SUCCESS:
      return {
        ...state,
        [consentType]: {
          consentAccepted: !!consentAccepted,
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Fetch]: false,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Fetch]: undefined,
          },
        },
      };

    case GET_USER_CONSENT_FAILED:
      return {
        ...state,
        [consentType]: {
          ...state[consentType],
          loadingStates: {
            ...state[consentType].loadingStates,
            [Actions.Fetch]: false,
          },
          errors: {
            ...state[consentType].errors,
            [Actions.Fetch]: error,
          },
        },
      };

    default:
      return state;
  }
};

// generic selectors
const isUserConsentAccepted = (state: RootState, consentType: UserConsents.Type): boolean =>
  state.userConsents[consentType]?.consentAccepted;
const isUserConsentLoading = (
  state: RootState,
  consentType: UserConsents.Type,
  action: Actions,
): boolean => state.userConsents[consentType]?.loadingStates?.[action] || false;
const userConsentError = (
  state: RootState,
  consentType: UserConsents.Type,
  action: Actions,
): string | undefined => state.userConsents[consentType]?.errors?.[action];

// helpers for getting consentType specific selectors
const isUserConsentLoadingPerType =
  (consentType: UserConsents.Type) =>
  (state: RootState, action: Actions): boolean =>
    isUserConsentLoading(state, consentType, action);
const isUserConsentAcceptedPerType =
  (consentType: UserConsents.Type) =>
  (state: RootState): boolean =>
    isUserConsentAccepted(state, consentType);
const userConsentErrorPerType =
  (consentType: UserConsents.Type) =>
  (state: RootState, action: Actions): string | undefined =>
    userConsentError(state, consentType, action);

export const selectors = {
  isUserConsentLoading,
  isUserConsentAccepted,
  userConsentError,
  isSensitiveDataConsentLoading: isUserConsentLoadingPerType(UserConsents.Type.SensitiveData),
  isSensitiveDataConsentAccepted: isUserConsentAcceptedPerType(UserConsents.Type.SensitiveData),
  sensitiveDataError: userConsentErrorPerType(UserConsents.Type.SensitiveData),
};
