import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { Document } from '@taxfix/taxfix-sdk/dist/documents/types';
import { CountryCodes, Documents, Years } from '@taxfix/types';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { downloadDocument, getDocuments } from 'src/services/documents';

export type Payslip = {
  id: number;
  year: Years;
  countryCode: CountryCodes;
  remotePath: string;
  localPath?: string;
  metadata: Document['metadata'];
  thumbnail?: string;
};

type State = Partial<Record<Years, Payslip>>;

type RootState = {
  payslips: State;
};

export enum PayslipActionTypes {
  FETCH_PAYSLIPS = 'payslips/fetchPayslips',
  FETCH_PAYSLIP_FOR_YEAR = 'payslips/fetchPayslipForYear',
  DOWNLOAD_PAYSLIP = 'payslips/downloadPayslip',
}

export const initial: State = {};

// async actions
const getActivePayslips = createAsyncThunk(
  PayslipActionTypes.FETCH_PAYSLIPS,
  async ({ accessToken }: { accessToken: string }) => {
    try {
      const { data } = await getDocuments(accessToken, {
        states: [Documents.States.Created] as any,
        types: [Documents.NonReceiptTypes.Payslip],
      });
      return data;
    } catch (err) {
      Analytics.log(AnalyticsEvent.dataFetchFailed, {
        error: (err as Error).message,
        serviceName: 'documents',
        requestDetails: 'getDocuments',
      });
      throw err;
    }
  },
);

const fetchPayslipForYear = createAsyncThunk(
  PayslipActionTypes.FETCH_PAYSLIP_FOR_YEAR,
  async ({ accessToken, year }: { accessToken: string; year: Years }) => {
    try {
      const { data } = await getDocuments(accessToken, {
        states: [Documents.States.Created] as any,
        types: [Documents.NonReceiptTypes.Payslip],
        year,
      });
      return data;
    } catch (err) {
      Analytics.log(AnalyticsEvent.dataFetchFailed, {
        error: (err as Error).message,
        serviceName: 'documents',
        requestDetails: 'getDocuments',
      });
      throw err;
    }
  },
);

const downloadPayslip = createAsyncThunk(
  PayslipActionTypes.DOWNLOAD_PAYSLIP,
  async ({ accessToken, year }: { accessToken: string; year: Years }, thunkAPI) => {
    try {
      const { payslips } = thunkAPI.getState() as RootState;
      const payslip = payslips[year];

      if (!payslip) {
        throw new Error(`Payslip for year ${year} not found`);
      }

      const localPath = await downloadDocument(payslip as unknown as Document, accessToken);
      return { year, localPath };
    } catch (err) {
      Analytics.log(AnalyticsEvent.dataFetchFailed, {
        error: (err as Error).message,
        serviceName: 'documents',
        requestDetails: 'downloadDocument',
      });
      throw err;
    }
  },
);

const payslipsSlice = createSlice({
  name: 'payslips',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivePayslips.fulfilled, (state, action) => {
      const newState = { ...state } as State;
      return newState;
    });

    builder.addCase(getActivePayslips.rejected, () => {
      return {};
    });

    builder.addCase(fetchPayslipForYear.fulfilled, (state, { payload: payslips }) => {
      const targetPayslip = payslips[0];

      if (!targetPayslip) {
        return state;
      }
    });

    builder.addCase(fetchPayslipForYear.rejected, (state, action) => {
      const { year } = action.meta.arg;
      state[year] = {} as Payslip;
    });

    builder.addCase(downloadPayslip.fulfilled, (state, action) => {
      const payslip = state[action.payload.year];

      if (!payslip) {
        return state;
      }
      payslip.localPath = action.payload.localPath;
    });

    builder.addCase(downloadPayslip.rejected, (state, action) => {
      const payslip = state[action.meta.arg.year];

      if (!payslip) {
        return state;
      }
      payslip.localPath = undefined;
    });
  },
});

const getPayslips = (state: RootState): State | undefined => state.payslips;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getPayslipByYear = (year: Years) =>
  createSelector(getPayslips, (payslips) => payslips?.[year]);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getPayslipLocalPath = (year: Years) =>
  createSelector(getPayslipByYear(year), (payslip) => payslip?.localPath ?? '');

export const actions = {
  ...payslipsSlice.actions,
  getActivePayslips,
  fetchPayslipForYear,
  downloadPayslip,
};

export const selectors = {
  getPayslips,
  getPayslipByYear,
  getPayslipLocalPath,
};

export const reducer = payslipsSlice.reducer;
