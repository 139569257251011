import React, { Component } from 'react';
import { Platform, Linking } from 'react-native';
import * as StoreReview from 'react-native-store-review';
import Config from 'react-native-config';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { UtilProps, withUtil } from '../../hocs/with-utils';
import contactSupport from '../../utils/contactSupport';
import { ResultHappyComponent } from '../../components/FeedbackModal/ResultHappy';
import { ResultDisappointedComponent } from '../../components/FeedbackModal/ResultDisappointed';
import { ResultPromoterComponent } from '../../components/FeedbackModal/ResultPromoter';
import { ResultPassiveComponent } from '../../components/FeedbackModal/ResultPassive';
import { ResultDetractorComponent } from '../../components/FeedbackModal/ResultDetractor';
import { showAlert } from '../../services/alert';

import { FeedbackResultType, feedbackResultTypes } from './FeedbackModal.types';

const ANDROID_PLAY_STORE_LINK = `market://details?id=${Config.GOOGLE_PLAY_ID}`;
const IOS_APP_STORE_LINK = `itms://itunes.apple.com/us/app/apple-store/id${Config.APP_STORE_ID}?mt=8`;
const WEB_TRUSTPILOT_LINK = 'https://www.trustpilot.com/review/taxfix.it';
type Props = {
  resultType: FeedbackResultType;
  year: number;
  onDismiss: () => any;
  onClose: () => any;
  util: UtilProps;
};

class FeedbackResultContainer extends Component<Props> {
  nativeStoreReviewSupported: boolean;

  constructor(props: Props) {
    super(props);
    // The API is only available on iOS 10.3 or later
    this.nativeStoreReviewSupported = Platform.OS === 'ios' && StoreReview.isAvailable;
  }

  componentDidMount = () => {
    const { resultType } = this.props;

    if (resultType === feedbackResultTypes.HAPPY) {
      this.logEvent(AnalyticsEvent.feedbackModalHappyInitiated);
    } else if (resultType === feedbackResultTypes.DISAPPOINTED) {
      this.logEvent(AnalyticsEvent.feedbackDisappointedInitiated);
    }

    if (this.shouldRequestNativeStoreReview()) {
      this.requestNativeStoreReview();
    }
  };

  shouldRequestNativeStoreReview = () => {
    const { resultType } = this.props;
    return (
      (resultType === feedbackResultTypes.HAPPY || resultType === feedbackResultTypes.PROMOTER) &&
      this.nativeStoreReviewSupported
    );
  };

  requestNativeStoreReview = () => {
    this.logEvent(AnalyticsEvent.nativeStoreReviewInitiated);
    StoreReview.requestReview();
    setTimeout(this.props.onClose, 500);
  };

  handleOpenStore = async () => {
    const { util, onDismiss } = this.props;
    this.logEvent(AnalyticsEvent.openStoreInitiated);
    const link = Platform.select({
      ios: IOS_APP_STORE_LINK,
      android: ANDROID_PLAY_STORE_LINK,
      web: WEB_TRUSTPILOT_LINK,
    });
    const supported = await Linking.canOpenURL(link as string);

    if (supported) {
      await Linking.openURL(link as string);
      onDismiss();
    } else {
      const ERROR_MESSAGE = util.intl.formatMessage({
        id: 'feedback-modal.store.error-message',
      });
      showAlert({
        titleText: ERROR_MESSAGE,
        cancelable: false,
        buttons: [
          {
            text: 'OK',
            onPress: onDismiss,
          },
        ],
      });
    }
  };

  handleOpenMail = async () => {
    const { util, onClose } = this.props;
    const { intl, supportEmail } = util;
    this.logEvent(AnalyticsEvent.openEmaillInitiated);
    const emailMessage = {
      address: supportEmail,
      subject: intl.formatMessage({
        id: 'feedback-modal.email.subject',
      }),
      body: intl.formatMessage({
        id: 'feedback-modal.email.body',
      }),
      errorTitle: intl.formatMessage({
        id: 'feedback-modal.email.error-title',
      }),
      errorMessage: intl.formatMessage(
        {
          id: 'feedback-modal.email.error-message',
        },
        {
          supportEmail,
        },
      ),
    };

    try {
      await contactSupport(emailMessage);
    } finally {
      setTimeout(() => onClose(), 1000);
    }
  };

  logEvent(event: AnalyticsEvent) {
    const { year } = this.props;
    Analytics.log(event, {
      year,
    });
  }

  render() {
    if (this.shouldRequestNativeStoreReview()) {
      return null;
    }

    const { resultType, onDismiss } = this.props;

    switch (resultType) {
      case feedbackResultTypes.HAPPY:
        return <ResultHappyComponent onOpenStore={this.handleOpenStore} onDismiss={onDismiss} />;

      case feedbackResultTypes.PROMOTER:
        return <ResultPromoterComponent onOpenStore={this.handleOpenStore} onDismiss={onDismiss} />;

      case feedbackResultTypes.DISAPPOINTED:
        return (
          <ResultDisappointedComponent onOpenMail={this.handleOpenMail} onDismiss={onDismiss} />
        );

      case feedbackResultTypes.PASSIVE:
        return <ResultPassiveComponent onOpenMail={this.handleOpenMail} onDismiss={onDismiss} />;

      case feedbackResultTypes.DETRACTOR:
        return <ResultDetractorComponent onOpenMail={this.handleOpenMail} onDismiss={onDismiss} />;

      default:
        return null;
    }
  }
}

export const FeedbackResult = withUtil(FeedbackResultContainer);
