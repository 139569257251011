import { CountryCodes } from '@taxfix/types';
import { Dispatch } from 'redux';

import { flagsQuestionIds, questionFlowCategories } from 'src/common/constants-it';
import {
  NavigationActions,
  getBoundNavigationsActions,
  getNavigationActions,
} from 'src/routes/config-util';
import { getQuestionStoreByYearAndCountry, getAnswer } from 'src/stores-legacy/helpers';
import { getAnswers } from 'src/stores/hooks/answers-stores-hook';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { StatusMiddleware, StatusStepRedirect } from 'src/stores/modules/status';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { getStore } from 'src/stores/util';
import { getSanitizedInput } from 'src/validations/italy-input-fields';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';

export const checkPayment: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  if (submissionSelectors.isPaidSubmission(getState())) {
    next();
  } else {
    dispatch(breakAction());
  }
};

const getBillingAddress = () => {
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const {
    form730: {
      '730/ResidenzaAnagrafica/Indirizzo': street,
      '730/ResidenzaAnagrafica/NumCivico': number,
      '730/ResidenzaAnagrafica/Comune': city,
      '730/ResidenzaAnagrafica/Cap': zipcode,
      '730/ResidenzaAnagrafica/Provincia': provincia,
    },
  } = getAnswers(questionStore);

  return {
    street: getSanitizedInput(`${street} ${number}`),
    zipcode,
    city,
    countryCode: CountryCodes.IT,
    state: provincia,
  };
};

const getPaymentNavigationActions = (navigation: NavigationActions, dispatch: Dispatch) => {
  getBoundNavigationsActions().toItalyPayment('screen', {
    customer: {
      ...getBillingAddress(),
    },
    nextNavigationAction: () => {
      dispatch(
        overlayActions.show('SuccessOverlay', {
          icon: 'interface-icons-svg.icon-checkmark-white',
          titleId: 'it.success-overlay.payment.title',
          descriptionId: 'it.success-overlay.payment.description',
          buttonId: 'it.success-overlay.payment.button',
          exitDirection: 'top',
          onNext: () => {
            dispatch(overlayActions.hide());
            navigation.reset({
              index: 1,
              actions: [
                getNavigationActions().toDashboard('screen'),
                getNavigationActions().toStatus('screen'),
              ],
            });
          },
        }),
      );
    },
  });
};

// Instant flow
export const redirectContactAndPayment: StatusStepRedirect = (state, dispatch) => (navigation) => {
  const isNewPaymentScreenEnabled = firebaseSelectors.isNewPaymentScreenEnabled(state);
  const actions = isNewPaymentScreenEnabled
    ? [
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toInstantFilingNextStepsScreen('screen', {
          onNext: () => getBoundNavigationsActions().toItalyPayment('screen'),
        }),
      ]
    : [
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toInstantFilingNextStepsScreen('screen', {
          onNext: () =>
            getBoundNavigationsActions().toQuizMaster('screen', {
              root: questionFlowCategories.billingAddress,
              editing: false,
              backNavigationText: 'web.navigation.italy.back',
              onNext: () => getPaymentNavigationActions(navigation, dispatch),
            }),
        }),
      ];

  navigation.reset({
    index: 1,
    actions,
  });
};

// Guided Flow
export const redirectContactAndPaymentBeforeQF: StatusStepRedirect =
  (state, dispatch) => (navigation) => {
    const isSPIDTimeoutFlow = getAnswer(state, flagsQuestionIds.SPIDTimeoutFlow);

    const actions = [
      getNavigationActions().toDashboard('screen'),
      isSPIDTimeoutFlow
        ? getNavigationActions().toSpidTimeoutNextSteps('screen')
        : getNavigationActions().toItalyPayment('screen'),
    ];

    navigation.reset({
      index: 1,
      actions,
    });
  };
