import { getItem, setItem } from 'src/utils/asyncStorage';

import { useNavigation } from './navigation-hook';

export const useStartOnboardingHandler = (): { handleStartOnboarding: () => Promise<void> } => {
  const { navigationActions } = useNavigation();

  const handleStartOnboarding = async () => {
    let showOnboardingPopup = false;

    // Don't show it to user who is in the Referral Program.
    if (!(await getItem('hasSeenTaxYearOnboardingPopup'))) {
      await setItem('hasSeenTaxYearOnboardingPopup', true);
      showOnboardingPopup = true;
    }

    navigationActions.toUniversalOnboarding('screen', {
      showOnboardingPopup,
    });
  };

  return { handleStartOnboarding };
};
