import { $Values } from 'utility-types';
import { Payment, Product } from '@taxfix/payment-sdk';

export type Product = {
  id: string;
  paymentId?: number;
  amount: number;
  amountType: Product.ProductAmountType;
  currency: string;
};

export type DisplayProduct = Product & { displayPriceCents: number };

export type Coupon = {
  code: string;
  amount: number;
  currency: string;
};
export type DiscountType = 'UNIT' | 'AMOUNT' | 'PERCENT' | 'FIXED';
export type Discount = {
  type: DiscountType;
  amount_off?: number;
  percent_off?: number;
  unit_type?: 'submission';
};
export type GeneratedCoupon = {
  code: string;
  discount: Discount;
};
export type CouponResult = {
  amountCents: number;
  code: string;
};
export const PaymentStates = Object.freeze({
  Created: 'created',
  Processing: 'processing',
  Completed: 'completed',
  Error: 'error',
  Refunded: 'refunded',
  PaidOut: 'paidOut',
  Cancelled: 'cancelled',
});
export type PaymentState = $Values<typeof PaymentStates>;

export type Payment = {
  id: number;
  userId: number;
  productId: string;
  amountCents: number;
  productPriceCents: number;
  currency: string;
  clientSecret?: string;
  data: {
    mandateId?: string;
    customerId?: string;
    bankAccountId?: string;
    paymentId?: string;
    refundId?: string;
  };
  provider: Payment.Provider;
  state: PaymentState;
  type: Payment.PaymentType;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};
export type PaymentStatus = {
  productId: string;
  state: string;
};
export type PaymentStatusByYear = Record<string, PaymentStatus>;
export type PaymentCustomer = {
  lastName?: string;
  firstName?: string;
  street?: string;
  number?: string;
  numberExtension?: string;
  addressAddition?: string;
  zipcode?: string;
  state?: string | null | undefined;
  city?: string;
  countryCode?: string;
  email?: string;
  country?: string;
};
export type ProductVariation = string;
