import React, { useState } from 'react';
import { Animated, Easing } from 'react-native';

type ContextProps = {
  isRolloverAnimationInitial: boolean;
  moveToBottom: any | null;
  fadeOutBottomComponent: any | null;
  resetRolloverAnimation: () => void;
  triggerRolloverAnimation: () => void;
  setMoveToBottomValue: (value: number) => void;
  setFadeOutBottomComponentValue: (value: number) => void;
  setIsRolloverAnimationInitial: (value: boolean) => void;
};

export type WithRolloverAnimationContext = {
  rolloverAnimationContext: ContextProps;
};

type RolloverAnimationProviderProps = {
  children: React.ReactNode;
  value?: any;
};

export const RolloverAnimationContext = React.createContext<any>({
  isRolloverAnimationInitial: true,
  moveToBottom: null,
  fadeOutBottomComponent: null,
  resetRolloverAnimation: () => {},
  triggerRolloverAnimation: () => {},
  setMoveToBottomValue: () => {},
  setFadeOutBottomComponentValue: () => {},
});

export const RolloverAnimationProvider = ({
  children,
}: RolloverAnimationProviderProps): JSX.Element => {
  const [isRolloverAnimationInitial, setIsRolloverAnimationInitial] = useState(true);

  const moveToBottomValueRef = React.useRef(new Animated.Value(0)).current;

  const fadeOutBottomComponentValueRef = React.useRef(new Animated.Value(1)).current;

  const setMoveToBottomValue = React.useCallback(
    (value: number) => {
      moveToBottomValueRef.setValue(value);
    },
    [moveToBottomValueRef],
  );

  const setFadeOutBottomComponentValue = React.useCallback(
    (value: number) => {
      fadeOutBottomComponentValueRef.setValue(value);
    },
    [fadeOutBottomComponentValueRef],
  );

  const triggerRolloverAnimation = () => {
    Animated.parallel([
      Animated.timing(fadeOutBottomComponentValueRef, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(moveToBottomValueRef, {
        toValue: 65,
        duration: 300,
        easing: Easing.bezier(0.5, 0, 0.5, 1),
        useNativeDriver: true,
      }),
    ]).start();
  };

  const resetRolloverAnimation = React.useCallback(() => {
    setIsRolloverAnimationInitial(true);
    setMoveToBottomValue(0);
    setFadeOutBottomComponentValue(1);
  }, [setMoveToBottomValue, setFadeOutBottomComponentValue]);

  return (
    <RolloverAnimationContext.Provider
      value={{
        resetRolloverAnimation,
        setMoveToBottomValue,
        setFadeOutBottomComponentValue,
        moveToBottom: moveToBottomValueRef,
        isRolloverAnimationInitial: isRolloverAnimationInitial,
        fadeOutBottomComponent: fadeOutBottomComponentValueRef,
        setIsRolloverAnimationInitial: setIsRolloverAnimationInitial,
        triggerRolloverAnimation: triggerRolloverAnimation,
      }}
    >
      {children}
    </RolloverAnimationContext.Provider>
  );
};

export function withRolloverAnimationContext<
  T extends WithRolloverAnimationContext = WithRolloverAnimationContext,
>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithRolloverAnimationContext = (
    props: Omit<T, keyof WithRolloverAnimationContext>,
  ) => {
    const rolloverAnimationContext = React.useContext(RolloverAnimationContext);

    return (
      // @ts-ignore
      <WrappedComponent rolloverAnimationContext={rolloverAnimationContext} {...(props as T)} />
    );
  };

  ComponentWithRolloverAnimationContext.displayName = `withRolloverAnimationContext(${displayName})`;

  return ComponentWithRolloverAnimationContext;
}
