import {
  validateAddress,
  validateIban,
  validateBankAccount,
  validateDistance,
} from 'src/utils/answerType';

export const addressValidator = validateAddress;

export const ibanValidator = validateIban;

export const bankAccountValidator = validateBankAccount;

export const distanceSearchValidator = validateDistance;
