import React from 'react';
import { compose } from 'redux';
import { User } from '@taxfix/taxfix-sdk';
import Config from 'react-native-config';

import withLogin, { WithLogin } from '../../hocs/with-login';
import { ErrorKey, ErrorKeys } from '../../components/auth/ErrorMessage';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';

import { NavigationParams } from './otc-validation.types';
import OtcValidation from './otc-validation';

type Props = NavigationParams & {
  isLoading: boolean;
} & WithNavigation &
  WithLogin;
type State = {
  isLoadingOtc: boolean;
  errorKey: ErrorKey | null | undefined | any;
  messageKey: string | null | undefined;
  otcCodeValue: string;
};

class OtcValidationContainer extends React.Component<Props, State> {
  state = {
    isLoadingOtc: false,
    errorKey: null,
    messageKey: null,
    otcCodeValue: '',
  };

  handleChange = (otcCodeValue: string) => {
    this.setState({
      otcCodeValue,
      errorKey: null,
      messageKey: null,
    });
  };

  handleConfirm = async () => {
    try {
      const { otcCodeValue: otcCode } = this.state;
      const { emailAddress, userId, handleOtcLogin } = this.props;
      await handleOtcLogin({
        email: emailAddress,
        userId,
        otcCode: Number(otcCode),
      });
    } catch (error) {
      this.setState({
        errorKey: ErrorKeys.OtcValidationFailed,
        messageKey: null,
      });
    }
  };

  handleOtcResend = () => {
    this.setState(
      {
        isLoadingOtc: true,
      },
      async () => {
        try {
          const { userId } = this.props;
          await User.resendOtc(Config.API_BASE_URL, {
            userId,
          });
          Analytics.log(AnalyticsEvent.otcEntryRequested);
          this.setState({
            errorKey: null,
            messageKey: 'account.otc-verification.resend-completed',
            isLoadingOtc: false,
          });
        } catch (error) {
          this.setState({
            errorKey: ErrorKeys.OtcResendFailed,
            messageKey: null,
            isLoadingOtc: false,
          });
        }
      },
    );
  };

  render() {
    const { isLoading } = this.props;
    const { isLoadingOtc, errorKey, messageKey, otcCodeValue } = this.state;
    return (
      <OtcValidation
        errorKey={errorKey}
        messageKey={messageKey}
        isLoading={isLoading || isLoadingOtc}
        onConfirm={this.handleConfirm}
        onOtcResend={this.handleOtcResend}
        otcCodeValue={otcCodeValue}
        onChange={this.handleChange}
      />
    );
  }
}

const OtcValidationConnectedContainer = compose<any>(
  withNavigation,
  withLogin,
)(OtcValidationContainer);
export { OtcValidationContainer, OtcValidationConnectedContainer };
