import React, { FC, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Container, Text } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { IconButton, theme, useSmallScreenQuery } from 'src/components/core';
import { StyleProp } from 'src/components/types';
import { ProductBundleValues } from 'src/common/constants-it';

import { useItalyIntl } from '../../_hooks';

import { InfoSectionItem } from './info-section';
import { moreInfoOverlaySections } from './sections';

const getResponsiveStyles = (overlayStyle: StyleProp) =>
  StyleSheet.create({
    overlayMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: theme.color.overlay,
      width: '100%',
    },
    overlayDesktop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.color.overlay,
      width: '100%',
    },
    pressableContainerStyle: {
      borderRadius: 20,
      overflowY: 'visible',
    },
    contentContainerStyle: {
      ...overlayStyle,
    },
    closeButton: {
      width: 22,
      margin: 0,
    },
  });

type Products = ProductBundleValues.guided | ProductBundleValues.instant;

type OverlayProps = {
  onClose: () => void;
  product: Products;
  fullPrice?: number;
};

export const MoreInfoOverlay: FC<OverlayProps> = ({ onClose, product, fullPrice }) => {
  const { getTranslationText } = useItalyIntl();
  const overlayWidth = useBreakpointValue({ base: '100%', md: '65%', xl: '50%' });
  const overlayMaxH = useBreakpointValue({ base: '90%', lg: '100%' });
  const isMobile = useSmallScreenQuery();

  const styles = useMemo(
    () => getResponsiveStyles({ width: overlayWidth, maxHeight: overlayMaxH }),
    [overlayWidth, overlayMaxH],
  );
  return (
    <Overlay
      onCloseComplete={onClose}
      pressableContainerStyle={styles.pressableContainerStyle}
      contentContainerStyle={styles.contentContainerStyle}
      style={isMobile ? styles.overlayMobile : styles.overlayDesktop}
      position="bottom"
    >
      {({ onClose: closeWithAnimation }: { onClose: () => void }) => {
        return (
          <Container display="flex" flexDir="column" bgColor={theme.color.lightFill} width="full">
            <Container
              display="flex"
              flexDir="row"
              w="full"
              alignItems="center"
              marginY={16}
              marginX={20}
            >
              <Text variant="titleMBold" textAlign="center" w="80%">
                {getTranslationText(`it.product-bundle-screen.more-info-${product}.title`)}
              </Text>
              <IconButton
                onPress={closeWithAnimation}
                iconKey="cross"
                style={styles.closeButton}
                tintColor={theme.color.greyFillIcon}
              />
            </Container>
            {moreInfoOverlaySections.map(({ bulletPoints, id }) => {
              return (
                <InfoSectionItem
                  key={id}
                  id={id}
                  bulletPoints={bulletPoints}
                  product={product}
                  fullPrice={fullPrice}
                />
              );
            })}
          </Container>
        );
      }}
    </Overlay>
  );
};
