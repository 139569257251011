import { useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { icons } from '../../../Icons';
import { Button } from '../../general/Button';
import { Card } from '../../general/Card';
import { Icon } from '../../general/Icon';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';
import { CommercialistaBio } from '../CommercialistaBio';

import { TaxExpertsCardProps } from './types';

export const TaxExpertsCard: React.FC<TaxExpertsCardProps> = ({
  allCommercialistasDetails,
  mainCardTitle,
  mainButtonTitle,
  footerTitle,
  mainButtonOnPress,
  mainButtonTestId,
  ...rest
}) => {
  const { colors, sizes } = useTheme();

  const mainContainerWidth = useBreakpointValue({ base: 'auto', md: 'flex-start' });
  const expertsSectionDir = useBreakpointValue({ base: 'column', md: 'row' });
  const buttonMarginTop = useBreakpointValue({ base: 3, md: '64px' });
  const buttonSize = useBreakpointValue({ base: sizes.xl, md: sizes.m });

  return (
    <Card
      backgroundColor={colors.greytones.white}
      padding={1}
      alignItems="center"
      variant="elevation2"
      alignSelf={mainContainerWidth}
      {...rest}
    >
      <Container
        backgroundColor={colors.greytones.white}
        margin={0}
        flexDirection="row"
        alignSelf="flex-start"
      >
        <Icon path={icons.certified} color={colors.brand.brandGreen} size={6} />

        <Text
          color={colors.greytones.graphicsDark}
          marginLeft={0}
          value={mainCardTitle}
          variant="bodySBook"
          alignSelf="center"
        />
      </Container>

      <Container flexDirection={expertsSectionDir} alignItems="center" marginTop={2}>
        {allCommercialistasDetails.map((commercialista) => (
          <CommercialistaBio commercialistaDetails={commercialista} />
        ))}
      </Container>

      <Button
        variant="primary"
        title={mainButtonTitle}
        width={buttonSize}
        marginTop={buttonMarginTop}
        onPress={mainButtonOnPress}
        testID={mainButtonTestId}
        textAlign="center"
        alignContent="center"
        alignItems="center"
      />

      <Text
        color={colors.greytones.graphicsDark}
        marginTop={1}
        marginBottom={3}
        value={footerTitle}
        variant="bodyXSBook"
      />
    </Card>
  );
};
