import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import { getNavigationActions } from '../../../routes/config-util';
import { resolveNavigationForItaly } from '../../../services/prefill-navigation-resolver';
import { selectors as PrefillSelectors } from '../../../stores/modules/prefill';
import { selectors as StatusSelectors, StatusStates } from '../../../stores/modules/status';
import { selectors as userAuthSelectors } from '../../../stores/modules/user-auth';
import initial from '../../../stores/store/initial';

type Props = {
  firstCategoryAnsweredQuestions: any;
} & WithNavigation;
export type NavigateToNext = (year: number, resetRoute: boolean) => Promise<void>;

const mapStateToProps = (state: typeof initial) => {
  const selectedYear = settingsSelectors.selectedYear(state) || getDefaultTaxYear();
  const isResubmission = submissionSelectors.isResubmissionByYear(state, selectedYear);
  const statusState = isResubmission ? StatusStates.Identification : StatusStates.Submit;

  const prefillStatusStepConfig = StatusSelectors.getStatusStepConfigById(statusState)(
    state as any,
  );

  return {
    prefillStatus: PrefillSelectors.getStatus(state),
    prefillRejectedReasons: PrefillSelectors.getRejectedReasons(state),
    prefillConfig: prefillStatusStepConfig ? prefillStatusStepConfig.navigation : null,
    accessToken: userAuthSelectors.getAccessToken(state),
    userId: userAuthSelectors.getUserId(state),
  };
};

export const withPrefillRoute = (WrappedComponent: any) => {
  class Enhancer extends React.PureComponent<any & Props> {
    navigateToNext: NavigateToNext = async (year: number, resetRoute: boolean) => {
      const {
        prefillConfig,
        prefillStatus,
        prefillRejectedReasons,
        navigationActions,
        accessToken,
        userId,
      } = this.props;
      const resolvedNavigation = await resolveNavigationForItaly(
        prefillConfig,
        prefillStatus,
        prefillRejectedReasons,
        year,
        accessToken,
        userId,
      );

      if (resolvedNavigation && resolvedNavigation.link && resolvedNavigation.link.action) {
        const { link } = resolvedNavigation;

        if (resetRoute) {
          navigationActions.reset({
            index: 1,
            actions: [
              getNavigationActions().toStatus('screen'),
              getNavigationActions()[link.action](link.config, link.params),
            ],
          });
        } else {
          navigationActions[link.action](link.config, link.params);
        }
      } else {
        navigationActions.reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      }
    };

    render() {
      return <WrappedComponent {...this.props} navigateToNext={this.navigateToNext} />;
    }
  }

  return compose(withNavigation, connect(mapStateToProps, null))(Enhancer);
};
