import React from 'react';
import { ScrollView } from 'react-native';

import { ChatPopupComponent } from 'src/components/chat-popup-overlay-italy';
import { useLargeScreenQuery } from 'src/components/core';
import { Container, NavButton } from 'src/taxfix-components/src';
import { useNavigation } from 'src/hooks/navigation-hook';
import { getNavigationActions, safeResetNavigation } from 'src/routes/config-util';

import { useItalyIntl } from '../_hooks';

export const ItalyChatContainer: React.FC = () => {
  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();
  const { navigation } = useNavigation();

  const handleBackButtonPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    }
  };
  return (
    <Container overflow="hidden">
      {isLargeScreen && (
        <NavButton
          text={getTranslationText('web.navigation.italy.back')}
          onPress={handleBackButtonPress}
        />
      )}
      <ScrollView>
        <ChatPopupComponent />
      </ScrollView>
    </Container>
  );
};
