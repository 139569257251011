import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CountryCodes } from '@taxfix/types';

import { BodySecondaryText, LinkText, Button, DisplayTitleText, Box } from '../../components/core';
import {
  actions as userLegalActions,
  selectors as userLegalSelectors,
} from '../../stores/modules/user-legal';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { docs } from '../../../assets/docs';
import { NotificationBannerLegacy } from '../../components/notification-banner-legacy';

import { AcceptTerms } from './accept-terms';

type Props = {
  onAccept: () => Promise<void>;
  country: CountryCodes;
  isLoading: boolean;
  error: Error | null | undefined;
  createUserLegalAction: typeof userLegalActions.createUserLegalAction;
  accessToken: string;
};

const mapStateToProps = (stores: any) => ({
  isLoading: userLegalSelectors.isLoading(stores),
  error: userLegalSelectors.isError(stores),
  accessToken: userAuthSelectors.getAccessToken(stores),
});

const mapDispatchToProps = (dispatch: any) => ({
  createUserLegalAction: bindActionCreators(userLegalActions.createUserLegalAction, dispatch),
});

export class TermsNotConnected extends React.Component<Props> {
  handleOnPress = async () => {
    const { country, onAccept, accessToken } = this.props;
    const version = docs().terms[country]?.version || '';

    try {
      await this.props.createUserLegalAction(accessToken, country, 'tac', version);
      await onAccept();
    } catch (error) {
      // Nothing to do here
    }
  };

  render() {
    const { country, error } = this.props;
    const titleTranslationKey = 'account.info.terms.it';
    const translationKey = 'account.accept-terms.terms.it';

    return (
      <Box flex>
        {error && <NotificationBannerLegacy notificationType="TaCAcceptError" />}
        <Box left={2} right={2} flex={1} bottom={2} top={2}>
          <Box flex>
            <DisplayTitleText id={titleTranslationKey} />
            <Box bottom={1.5} />
            <AcceptTerms
              textComponent={BodySecondaryText}
              linkComponent={LinkText}
              country={country}
              translationKey={translationKey}
            />
          </Box>
          <Button
            translationKey="terms.accept"
            floating
            onPress={this.handleOnPress}
            testId="accept.policies.button"
          />
        </Box>
      </Box>
    );
  }
}
export const Terms = connect(mapStateToProps, mapDispatchToProps)(TermsNotConnected);
