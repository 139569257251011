import { $PropertyType, $Shape } from 'utility-types';
import { Dispatch } from 'redux';
import { createFilter } from 'redux-persist-transform-filter';
import { createSelector } from 'reselect';

import { Place } from '../../types/geo';
import { Options, saveLegacyData } from '../utils/submission';

export const UPDATE_ACCEPTED_COUNTRY_TERMS = 'UPDATE_ACCEPTED_COUNTRY_TERMS';
export const UPDATE_BIO_AUTH_PERMISSION = 'UPDATE_BIO_AUTH_PERMISSION';
export const RESET_BIO_AUTH_PERMISSION = 'RESET_BIO_AUTH_PERMISSION';
export const UPDATE_ONBOARDING = 'UPDATE_ONBOARDING';
export const UPDATE_PERSONAL = 'user/UPDATE_PERSONAL';
export const UPDATE_PERSONAL_SUCCESS = 'user/UPDATE_PERSONAL_SUCCESS';
export const UPDATE_PERSONAL_ERROR = 'user/UPDATE_PERSONAL_ERROR';
export const USER_SET_LOADING = 'user/SET_LOADING';

type TaxOffice = {
  id: string;
  name: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
};
type OnboardingData = {
  currentYear?: number;
  pin?: string;
  email?: string;
  name?: string;
  tac?: boolean;
  privacy?: boolean;
  acceptedPromotionalEmails?: boolean;
  optInTracking?: boolean;
  idToken?: string;
};

type UserPersonalData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: '' | 'male' | 'female';
  taxOffice?: TaxOffice;
  place?: Place;
  taxIdNumber: string;
  steuerNumber?: string;
};

export type State = {
  isBioAuthEnabled: boolean | null;
  // TODO: move to "data"
  isLoading: boolean;
  onboarding: OnboardingData;
  data: {
    personal: UserPersonalData;
  };
};
type UpdateBioAuthPermissionAction = {
  type: typeof UPDATE_BIO_AUTH_PERMISSION;
  payload: {
    enabled: boolean;
  };
};
type UpdateOnboardingAction = {
  type: typeof UPDATE_ONBOARDING;
  payload: OnboardingData;
};
type ResetBioAuthPermissionAction = {
  type: typeof RESET_BIO_AUTH_PERMISSION;
};
type UpdatePersonalAction = {
  type: typeof UPDATE_PERSONAL;
  payload: $Shape<$PropertyType<$PropertyType<State, 'data'>, 'personal'>>;
};
type PayloadPropertyType = $PropertyType<UpdatePersonalAction, 'payload'>;
type SetLoadingAction = {
  type: typeof USER_SET_LOADING;
  payload: boolean;
};

type Action =
  | UpdateBioAuthPermissionAction
  | ResetBioAuthPermissionAction
  | UpdateOnboardingAction
  | UpdatePersonalAction
  | SetLoadingAction;

const updateBioAuthPermission = (enabled: boolean): UpdateBioAuthPermissionAction => ({
  type: UPDATE_BIO_AUTH_PERMISSION,
  payload: {
    enabled,
  },
});

const updateOnboarding = (onboardingData: OnboardingData): UpdateOnboardingAction => ({
  type: UPDATE_ONBOARDING,
  payload: { ...onboardingData },
});

const resetBioAuthPermission = (): ResetBioAuthPermissionAction => ({
  type: RESET_BIO_AUTH_PERMISSION,
});

const updatePersonal = (payload: PayloadPropertyType) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: UPDATE_PERSONAL,
  });
  dispatch({
    type: UPDATE_PERSONAL_SUCCESS,
    payload,
  });
};

const updatePersonalLegacy =
  (payload: PayloadPropertyType, options: Options) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_PERSONAL,
    });

    try {
      await saveLegacyData(payload, { ...options, store: 'user' });
      dispatch({
        type: UPDATE_PERSONAL_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PERSONAL_ERROR,
      });
    }
  };

const updateLoading = (loading: boolean): SetLoadingAction => ({
  type: USER_SET_LOADING,
  payload: loading,
});

export const actions = {
  updateBioAuthPermission,
  resetBioAuthPermission,
  updatePersonal,
  updatePersonalLegacy,
  updateOnboarding,
  updateLoading,
};
export const initial: State = {
  isBioAuthEnabled: null,
  isLoading: false,
  data: {
    personal: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      taxOffice: undefined,
      place: undefined,
      taxIdNumber: '',
      gender: '',
      steuerNumber: undefined,
    },
  },
  onboarding: {},
};
export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type) {
    case UPDATE_BIO_AUTH_PERMISSION:
      return { ...state, isBioAuthEnabled: action.payload.enabled };

    case RESET_BIO_AUTH_PERMISSION:
      return { ...state, isBioAuthEnabled: null };

    case UPDATE_PERSONAL:
      return { ...state, isLoading: true };

    case UPDATE_PERSONAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, personal: { ...state.data.personal, ...action.payload } },
      };

    case UPDATE_PERSONAL_ERROR:
      return { ...state, isLoading: false };

    case UPDATE_ONBOARDING:
      return { ...state, onboarding: { ...state.onboarding, ...action.payload } };

    case USER_SET_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};
type RootState = {
  user: State;
};

const getUser = (state: RootState): State => state.user;

const getOnboardingPin = (state: RootState): string | null | undefined =>
  state.user.onboarding?.pin || null;

const getOnboardingEmail = (state: RootState): string | null | undefined =>
  state.user.onboarding?.email || null;

const getOnboardingName = (state: RootState): string | undefined =>
  state.user.onboarding?.name || undefined;

const getOnboardingIdToken = (state: RootState): string | undefined =>
  state.user.onboarding?.idToken || undefined;

const getOnboardingCurrentYear = (state: RootState): number | null | undefined =>
  state.user.onboarding?.currentYear || null;

const getOnboardingTac = (state: RootState): boolean => state.user.onboarding?.tac || false;

const getOnboardingPrivacy = (state: RootState): boolean => state.user.onboarding?.privacy || false;

const getOnboardingPromotionalEmails = (state: RootState): boolean | null | undefined =>
  state.user.onboarding?.acceptedPromotionalEmails;

const getOnboardingOptIn = (state: RootState): boolean | null | undefined =>
  state.user.onboarding?.optInTracking ?? null;

/**
 * opt-in tracking is an experiment on the signup flow.
 * users not exposed to the experiment we need to keep the same behaviour
 * users exposed to the experiment need to answer the opt in question on the sign up flow
 *
 * Possible values:
 * undefined --> user was not exposed to the question
 * null --> user is exposed to the question and don't answer yet
 * true --> user was exposed to the question and opted in
 * false --> user was exposed to the question and opted out
 */

// TODO: review if we need this selector when tackling signup
const getOnboardingOptInTracking: (state: RootState) => boolean | null | undefined = createSelector(
  () => false,
  getOnboardingOptIn,
  (isExperimentEnabled, optInTrackinDecision) => {
    if (!isExperimentEnabled) {
      return undefined;
    }

    return optInTrackinDecision;
  },
);

const getTaxOfficeId = (state: RootState): string | null | undefined =>
  state.user?.data?.personal.taxOffice?.id;

const getUserPersonal = (state: RootState): UserPersonalData => state.user?.data?.personal;

const isBioAuthEnabled = createSelector(getUser, (user) => user.isBioAuthEnabled);

const getFirstName = createSelector(
  getUserPersonal,
  getOnboardingName,
  (personal, onboardingName) =>
    personal.firstName || onboardingName?.substring(0, onboardingName?.indexOf(' ')) || '',
);

const getLastName = createSelector(
  getUserPersonal,
  getOnboardingName,
  getFirstName,
  (personal, onboardingName, firstName = '') =>
    personal.lastName || onboardingName?.replace(firstName, '') || '',
);

const getLoading = createSelector(getUser, (user) => user.isLoading);

export const UserSelectors = {
  getOnboardingPin,
  getOnboardingEmail,
  getOnboardingName,
  getOnboardingCurrentYear,
  getOnboardingTac,
  getOnboardingPrivacy,
  getOnboardingPromotionalEmails,
  getOnboardingOptInTracking,
  getTaxOfficeId,
  getUserPersonal,
  isBioAuthEnabled,
  getOnboardingIdToken,
  getFirstName,
  getLoading,
  getLastName,
};
export const persistFilter = createFilter('user', [
  'onboarding.email',
  'onboarding.name',
  'data',
  'isBioAuthEnabled',
]);
