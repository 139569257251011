import React, { useEffect } from 'react';
import Config from 'react-native-config';
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { Token } from '@taxfix/taxfix-sdk';
import { UserConsents } from '@taxfix/types';

import getNotificationsManager from '../../utils/push-notifications';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import {
  selectors as userConsentsSelectors,
  actions as userConsentsActions,
} from '../../stores/modules/user-consents';
import { actions, selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { ScreenName } from '../../types/screen-name';
import { ButtonName } from '../../types/button-name';

import Notifications from './Notifications';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPushToken: bindActionCreators(actions.setPushToken, dispatch),
  updateNotificationModalStatus: bindActionCreators(
    actions.updateNotificationModalStatus,
    dispatch,
  ),
  createSensitiveDataConsentRecord: bindActionCreators(
    userConsentsActions.createSensitiveDataConsentRecord,
    dispatch,
  ),
  getSensitiveDataConsent: bindActionCreators(
    userConsentsActions.getSensitiveDataConsent,
    dispatch,
  ),
});

const mapStateToProps = (stores: any) => ({
  accessToken: userAuthSelectors.getAccessToken(stores),
  pushToken: settingsSelectors.selectPushToken(stores),
  userLang: settingsSelectors.selectedLocale(stores),
  sensitiveDataConsent: userConsentsSelectors.isSensitiveDataConsentAccepted(stores),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & {
  navigateForward: () => any;
  image?: string;
  title?: string;
  content?: string;
  primaryButtonText?: string;
  floatingButton?: boolean;
  accessToken: string;
  pushToken?: string;
  setPushToken: (arg0: any) => void;
  createSensitiveDataConsentRecord: (newState: UserConsents.State) => void;
  getSensitiveDataConsent: () => void;
} & WithNavigation;

const PushNotifications = getNotificationsManager();

const UndecoratedNotificationsContainer = ({
  image,
  title,
  content,
  pushToken,
  accessToken,
  setPushToken,
  getSensitiveDataConsent,
  floatingButton,
  navigateForward,
  primaryButtonText,
  updateNotificationModalStatus,
}: Props) => {
  useEffect(getSensitiveDataConsent, [getSensitiveDataConsent]);

  const handleSensitiveDataConsent = () => {
    navigateForward();
  };

  const goToNextScreenBasedOnCountryCode = () => {
    handleSensitiveDataConsent();
  };

  const handlePressPrimaryButton = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      buttonName: ButtonName.ActivateNotification,
      screenName: ScreenName.Notifications,
    });
    Analytics.log(AnalyticsEvent.sectionNotificationAccepted);
    PushNotifications.requestPushTokenAndPermissions()
      .then((result: any) => {
        if (result.token !== null && pushToken !== result.token) {
          Token.updatePushToken(Config.API_BASE_URL, accessToken, {
            pushToken: result.token,
          })
            .then(() => {
              setPushToken(result.token);
            })
            .catch((err) => {});
        }
      })
      .then(() => {
        updateNotificationModalStatus(true);
        goToNextScreenBasedOnCountryCode();
      });
  };

  const handlePressSecondaryButton = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      buttonName: ButtonName.MaybeLater,
      screenName: ScreenName.Notifications,
    });
    Analytics.log(AnalyticsEvent.sectionNotificationNotAccepted);
    updateNotificationModalStatus(true);
    goToNextScreenBasedOnCountryCode();
  };

  return (
    <Notifications
      image={image}
      floatingButton={floatingButton}
      onPressPrimaryButton={handlePressPrimaryButton}
      onPressSecondaryButton={handlePressSecondaryButton}
      title={title}
      content={content}
      primaryButtonText={primaryButtonText}
    />
  );
};

export const NotificationsContainer = compose<any>(
  withNavigation,
  connector,
)(UndecoratedNotificationsContainer);
