import React from 'react';
import { View, Platform } from 'react-native';

import { Container } from 'src/taxfix-components/src';

import { Button, Box, theme } from '../core';

const styles = {
  buttons: {
    paddingBottom: 14,
  },
  centeredText: {
    textAlign: 'center',
  },
};
type LayoutProps = {
  onPressPrimaryButton?: () => void;
  onPressSecondaryButton?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  children?: React.ReactNode;
  floatingButton?: boolean;
  applyContentSeparation?: boolean;
  renderPrimaryButton?: () => React.ReactElement;
  renderSecondaryButton?: () => React.ReactElement;
};

const ButtonBox = ({ floatingButton, applyContentSeparation, children }: any) => (
  <Box
    style={
      floatingButton && applyContentSeparation
        ? [
            {
              backgroundColor: theme.color.lightText,
            },
            theme.styles.dropShadow,
          ]
        : []
    }
  >
    <View style={floatingButton && styles.buttons}>{children}</View>
  </Box>
);

const Buttons = ({
  primaryButton,
  secondaryButton,
  floatingButton,
  applyContentSeparation,
}: any) => {
  if (primaryButton && secondaryButton) {
    return (
      <ButtonBox floatingButton={floatingButton} applyContentSeparation={applyContentSeparation}>
        {primaryButton}
        {secondaryButton}
      </ButtonBox>
    );
  }

  if (secondaryButton) {
    return (
      <ButtonBox floatingButton={floatingButton} applyContentSeparation={applyContentSeparation}>
        {secondaryButton}
      </ButtonBox>
    );
  }

  if (primaryButton) {
    return (
      <ButtonBox floatingButton={floatingButton} applyContentSeparation={applyContentSeparation}>
        {primaryButton}
      </ButtonBox>
    );
  }

  return null;
};

const Layout: React.FC<LayoutProps> = ({
  onPressPrimaryButton = () => {},
  onPressSecondaryButton = () => {},
  primaryButtonText,
  secondaryButtonText,
  floatingButton,
  applyContentSeparation,
  children,
  renderPrimaryButton,
  renderSecondaryButton,
}) => {
  let primaryButton;
  let secondaryButton;
  const marginBottomStyle = Platform.OS === 'android' ? { marginBottom: 24 } : {};

  if (primaryButtonText) {
    primaryButton = (
      <Button
        type="primary"
        floating={floatingButton}
        onPress={onPressPrimaryButton}
        translationKey={primaryButtonText}
        testId="primaryButton"
        style={marginBottomStyle}
      />
    );
  } else if (renderPrimaryButton) {
    primaryButton = renderPrimaryButton();
  }

  if (secondaryButtonText) {
    secondaryButton = (
      <Button
        type="secondary"
        floating={false}
        onPress={onPressSecondaryButton}
        translationKey={secondaryButtonText}
        testId="secondaryButton"
      />
    );
  } else if (renderSecondaryButton) {
    secondaryButton = renderSecondaryButton();
  }

  return (
    <Container flexGrow={1} left={2.4} right={2.4} padding={1}>
      <Container flexGrow={1}>{children}</Container>

      <Buttons
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        floatingButton={floatingButton}
        applyContentSeparation={applyContentSeparation}
      />
    </Container>
  );
};

export default Layout;
