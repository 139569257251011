import React from 'react';
import { View } from 'react-native';
import {
  NavigationHelpersContext,
  useNavigationBuilder,
  StackRouter,
  createNavigatorFactory,
} from '@react-navigation/native';

import DefaultWebNavigator from './stack-navigator-shared.web';

const WebNavigator = ({ initialRouteName, children, screenOptions }: any) => {
  const { state, navigation, descriptors } = useNavigationBuilder(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  });
  const { options, render } = descriptors[state.routes[state.index].key];

  const defaultProps = {
    options,
    render,
    navigation,
    state,
  };

  return (
    <NavigationHelpersContext.Provider value={navigation}>
      <View style={screenOptions.cardStyle}>
        <DefaultWebNavigator {...defaultProps} />
      </View>
    </NavigationHelpersContext.Provider>
  );
};

export const createStackNavigator = createNavigatorFactory(WebNavigator);
