import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Button, Card, Container, EncircledArrow, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * Shows green box on the screen to highlight the current step
 */

export const ExpertReviewCard: React.FC<Props> = ({
  title,
  description,
  button,
  showConnectingLine = false,
  ...otherProps
}): JSX.Element => {
  const cardWidth = useBreakpointValue({ base: '339px', md: '370px' });

  return (
    <Card
      variant="elevation4"
      width="100%"
      maxWidth={cardWidth}
      backgroundColor="secondaryFeedback.greenLight"
      paddingTop="24px"
      paddingLeft="47px"
      paddingRight="20px"
      paddingBottom="20px"
      position="relative"
      {...otherProps}
    >
      <Container position="absolute" left="10px" top="0px" alignItems="center">
        {showConnectingLine ? (
          <Container h="24px" w="2px" bgColor="brand.brandGreen" />
        ) : (
          <Container h="24px" />
        )}
        <EncircledArrow size={22} />
      </Container>
      <Container>
        <Text variant="titleXSMedium" value={title} marginBottom="8px" />
        <Text variant="bodySBook" value={description} marginBottom="16px" />
        {button && (
          <Button
            variant={button.variant}
            title={button.title}
            testID={button.testID}
            onPress={button.onPress}
            alignSelf="flex-start"
            width="auto"
            px="20px"
            marginBottom="0px"
          />
        )}
      </Container>
    </Card>
  );
};
