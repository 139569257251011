import React from 'react';
import { Box, Input } from 'native-base';

import { Container, Grid, Icon, icons, NavButton, Text } from 'src/taxfix-components/src';
import { useLargeScreenQuery } from 'src/components/core';

type SVGIconsBrowserComponentProps = {
  goBack(): void;
};

export const SVGIconsBrowserComponent = ({
  goBack,
}: SVGIconsBrowserComponentProps): JSX.Element => {
  const iconList = Object.keys(icons);
  const iconsList: any = icons;
  const [search, setSearch] = React.useState('');
  const isLargeScreen = useLargeScreenQuery();

  const filteredIcons = iconList.filter((icon) =>
    icon.toLowerCase().includes(search.toLowerCase()),
  );

  const handleSearch = (text: string) => {
    setSearch(text);
  };

  return (
    <>
      {isLargeScreen && (
        <Container>
          <NavButton text="Back" onPress={goBack} />
        </Container>
      )}
      <Box justifyContent="center">
        {/* Input to filter the icons */}
        <Input mx={80} my={40} onChangeText={handleSearch} placeholder="Search Icon name" />
        <Grid
          data={filteredIcons}
          key={Date.now()}
          columns={4}
          keyExtractor={(item) => item}
          renderItem={({ item }: any): React.ReactElement => (
            <Box justifyContent="center" alignItems="center" m={10}>
              <Icon path={iconsList[item]} size={22} />
              <Text mt={2} fontSize={12}>
                {item}
              </Text>
            </Box>
          )}
        />
      </Box>
    </>
  );
};
