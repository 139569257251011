import React from 'react';
import { TouchableOpacity } from 'react-native';

import { LinkText, SmallLinkText } from './Text';

type Props = {
  onPress: () => void;
  variant?: 'normal' | 'small';
  children?: any;
  style?: any;
  testId?: any;
  id?: any;
  color?: any;
};

const Link = ({ onPress, variant = 'normal', ...props }: Props) => {
  const Text = variant === 'normal' ? LinkText : SmallLinkText;
  return (
    <TouchableOpacity onPress={onPress}>
      <Text {...props} />
    </TouchableOpacity>
  );
};

export default Link;
