import React from 'react';

import { DropzoneWrapperWeb } from 'src/taxfix-components/src';
import { isMobileWeb } from 'src/utils/platform';

import { allowedFileTypes, NUMBER_OF_ALLOWED_FILES } from '../constants';
import { IdCardUploadedContentAreaProps } from '../types';

import { UploadContent } from './upload-content';

export const UploadedContentArea: React.FC<IdCardUploadedContentAreaProps> = ({
  side,
  onFilesSelected,
  onFilesRejected,
}) => {
  return (
    <DropzoneWrapperWeb
      onDropAccepted={onFilesSelected}
      onDropRejected={onFilesRejected}
      accept={allowedFileTypes}
      multiple={false}
      maxFiles={NUMBER_OF_ALLOWED_FILES}
      noClick={isMobileWeb}
      noDrag={isMobileWeb}
      style={{ width: '100%', cursor: isMobileWeb ? 'auto' : 'pointer' }}
    >
      {() => <UploadContent side={side} />}
    </DropzoneWrapperWeb>
  );
};
