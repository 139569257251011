import { ITextProps } from 'native-base';

import { Variants as TextVariants } from '../../../theme/it/fonts/fontTypes';

export const ListOfTextOptions = [
  'titleXLBold',
  'titleLBold',
  'titleMBold',
  'titleSBold',
  'titleXSBold',
  'titleXXSBold',
  'titleXSBook',
  'titleMBook',
  'titleMMedium',
  'titleSMedium',
  'titleXSMedium',
  'titleXXSMedium',
  'subtitleMBook',
  'subtitleLMedium',
  'subtitleMMedium',
  'bodyXSBold',
  'bodySBold',
  'bodyLBook',
  'bodyMBook',
  'bodySBook',
  'bodyXSBook',
  'bodyXSMedium',
  'bodyXXSBook',
  'overlineM',
  'overlineS',
  'linkM',
  'linkS',
  'linkXS',
];

export type TextProps = ITextProps & {
  variant?: TextVariants | ITextProps['variant'];
  children?: string;
  value?: string | React.ReactNode;
};
