import React from 'react';
import Dropzone from 'react-dropzone';

import { Button } from 'src/taxfix-components/src';

import { MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION, MAX_FILES_BATCH } from '../../utils/constants';
import { useLargeScreenQuery } from '../../components/core';
import { DocumentAllowedFileTypes } from '../../services/documents';

import { FileUploadWeb } from './file-upload-web';

const acceptFileTypes = Object.values(DocumentAllowedFileTypes);

export enum UploadButtonVariant {
  Primary = 'primary',
  DropZone = 'dropZone',
}

type FileError = {
  code: string;
  message: string;
};
type FileRejection = {
  file: File;
  errors: FileError[];
};
type Props = {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  variant?: UploadButtonVariant;
  uploadButtonTitle?: string;
};

/**
 * This is only used for the web, for the dropzone
 * @param onDrop function to handle onDrop
 */
export const FileDropZoneWeb: React.FC<Props> = ({
  onDrop,
  uploadButtonTitle,
  variant = UploadButtonVariant.DropZone,
}) => {
  const isLargeScreen = useLargeScreenQuery();
  return (
    <Dropzone
      onDrop={onDrop}
      accept={acceptFileTypes}
      maxSize={MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION}
      maxFiles={MAX_FILES_BATCH}
      noClick={isLargeScreen}
    >
      {({ getRootProps, getInputProps, open }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {variant === UploadButtonVariant.Primary ? (
              <Button variant="primary" title={uploadButtonTitle} onPress={open} />
            ) : (
              <FileUploadWeb onPress={open} />
            )}
          </div>
        );
      }}
    </Dropzone>
  );
};
