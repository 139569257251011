import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Box,
  Icon,
  DisplayTitleText as TitleText,
  Section,
  Button,
  BodyPrimaryText,
  theme,
} from 'src/components/core';

import { isWeb } from '../utils/platform';

const codePush = !isWeb && require('react-native-code-push');

const styles = StyleSheet.create({
  iconBox: {
    height: 47,
    width: 47,
    backgroundColor: theme.color.secondaryAccentGreenLight,
    borderRadius: 23.5,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  icon: { height: 26.89, width: 26.89 },
  subtitleText: { textAlign: 'left', paddingLeft: 16 },
  boxContainer: {
    margin: 30,
    justifyContent: 'space-between',
  },
  image: {
    height: 124,
    marginBottom: 20,
  },
  textLeft: {
    textAlign: 'left',
    marginBottom: 20,
  },
  bodyText: {
    marginTop: 6,
    textAlign: 'center',
  },
  boxButton: {
    width: '100%',
  },
  button: { width: '100%' },
});

export const ErrorFallbackComponent = (): JSX.Element => {
  const handleOnPress = () => (isWeb ? window.location.reload() : codePush.restartApp());

  return (
    <View style={styles.container}>
      <Section size="narrow" centered>
        <Box alignHorizontally="center" alignVertically="center" style={styles.boxContainer}>
          <Icon name="error-svg.error-404" style={styles.image} resizeMode="contain" />
          <TitleText id="error.boundary.title" style={styles.textLeft} />
          <BodyPrimaryText style={styles.bodyText} id="error.boundary.description" />
          <Box top={3} direction="row" style={styles.boxButton}>
            <Button
              style={styles.button}
              onPress={handleOnPress}
              translationKey="error.boundary.button"
            />
          </Box>
        </Box>
      </Section>
    </View>
  );
};
