import Config from 'react-native-config';

const dynamicLinkRoutes = {
  userTesting: `${Config.DYNAMIC_LINK_URL_WEBAPP}/dlink/usertesting`,
  codepush: `${Config.DYNAMIC_LINK_URL_WEBAPP}/dlink/codepush`,
  openApp: `${Config.DYNAMIC_LINK_URL_WEBAPP}/dlink/openapp`,
};

const dynamicLinkParamKeys = {
  voucherCode: 'voucherCode',
  discountPercentOff: 'discountPercentOff',
  taxYear: 'taxYear',
  categoryId: 'categoryId',
  questionId: 'questionId',
  email: 'email',
  deviceId: 'deviceId',
  adgroup: 'adgroup',
  customCampaignId: 'customCampaignId',
  taxCountry: 'taxCountry',
  taxPackage: 'taxPackage',
  deploymentKeyIOS: 'deploymentKeyIOS',
  deploymentKeyAndroid: 'deploymentKeyAndroid',
  referrerUserId: 'referrerUserId',
  referralInviteSentAt: 'referralInviteSentAt',
  referralCampaignName: 'referralCampaignName',
  revision: 'revision',
  randomNumber: 'randomNumber',
  token: 'token',
};

export { dynamicLinkRoutes, dynamicLinkParamKeys };
