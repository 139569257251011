import React from 'react';
import { StyleSheet } from 'react-native';

import { OnboardingSplashReferral } from '../screens/components/onboarding-splash-referral';

import Overlay from './overlay';
import { Box, theme } from './core';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});
type Props = {
  onClose: () => void;
  discount: string;
};
export const OnboardingSplashReferralOverlay = ({ onClose: onCloseComplete, discount }: Props) => (
  <Overlay
    disableBackgroundTouch
    onCloseComplete={onCloseComplete}
    style={{
      backgroundColor: theme.color.primary,
    }}
  >
    {({ onClose }: any) => (
      <Box style={styles.container}>
        <OnboardingSplashReferral onStartOnboardingPress={onClose} discount={discount} />
      </Box>
    )}
  </Overlay>
);
