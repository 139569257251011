import get from 'lodash/get';
import { Platform } from 'react-native';
import packagedAssets from '@taxfix/image-assets/requires';

export function getImageAsset(name: string) {
  const asset = get(packagedAssets, name, null);

  if (asset && Platform.OS === 'web') {
    return asset.default;
  }

  return asset;
}
