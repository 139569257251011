import * as React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute } from '../../routes/navigate';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { Home } from './home-container';

const HomeScreen = (): JSX.Element => {
  useNavigationOptions({
    header: () => null,
    headerShown: false,
    showSupportButton: false,
  });
  return <Home />;
};

export const { action: toHome, screen: homeScreen } = getRoute({
  routeName: Routes.Home,
  screenComponent: HomeScreen,
  screenNavigationOptions: {
    isPublic: true,
  },
});

export default HomeScreen;
