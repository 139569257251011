/* global __DEV__ */
/* eslint-disable no-console */
import { InteractionManager } from 'react-native';
import { logger as reactNativeLogger } from 'react-native-logs';

import { logger, updateLogger, LoggerInstance } from 'src/taxfix-business-logic/utils/logger';

import { ErrorTracking } from '../services/error-tracking';

const getStackTrace = () => {
  let stack = new Error().stack?.split(/\n/);

  if (stack && stack[0].indexOf('Error') === 0) {
    stack = stack.slice(1);
  }

  if (stack && stack.length > 1) {
    return stack[2].trim();
  }

  return '';
};

const customTransport = (msgArr: any, level: any, options: any) => {
  if (!__DEV__) {
    return;
  }

  const clientColors = [
    'color: white;font-weight:bold',
    'color: dodgerblue;font-weight:bold',
    'color: orange;font-weight:bold;',
    'color: indianred;font-weight:bold;',
  ];
  const stackTrace = msgArr.pop();
  let dateTxt = `${new Date().toLocaleString()} | `;
  let levelTxt = `${level.text.toUpperCase()} | `;
  if (options && options.hideDate) dateTxt = '';
  if (options && options.hideLevel) levelTxt = '';
  msgArr.forEach((msg: any) => {
    let stringMsg: string;

    if (typeof msg === 'string') {
      stringMsg = msg;
    } else if (typeof msg === 'function') {
      stringMsg = '[function]';
    } else {
      stringMsg = JSON.stringify(msg);
    }

    if (stringMsg) {
      const output = `%c${dateTxt}${levelTxt}${stringMsg}`;
      console.log(output, clientColors[level.severity] || '');
    }
  });
  console.log(`%c${dateTxt}${levelTxt}${stackTrace}`, clientColors[level.severity] || '');
};

const errorTrackingTransport = (msgArr: any, level: any, options: any) => {
  if (level.severity < 2 || !logger.errorTrackingInstance) {
    return;
  }

  if (msgArr.length) {
    const error = msgArr[0];
    const context = msgArr[1];
    logger.errorTrackingInstance.recordError(error, context, {}, level.severity);
  } else logger.errorTrackingInstance.recordError('wrongly formatted error', {}, {}, 3);
};

const getLogger = (options: any) => {
  return reactNativeLogger.createLogger(options);
};

export const createLogger = () => {
  const logLevels: Array<keyof LoggerInstance> = ['log', 'debug', 'info', 'warn', 'error'];

  updateLogger({ errorTrackingInstance: new ErrorTracking() });

  const baseLogger = getLogger({
    transport: (msg: any, level: any, options: any) => {
      InteractionManager.runAfterInteractions(() => {
        customTransport(msg, level, options);
        errorTrackingTransport(msg, level, options);
      });
    },
  });

  const logger: LoggerInstance = {
    log: () => {},
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  };

  logLevels.forEach((level) => {
    const logLevel = level === 'log' ? 'debug' : level;

    logger[level] = (...params: any) => {
      params.push(getStackTrace());
      baseLogger.log.call(baseLogger, logLevel, params);
    };
  });

  updateLogger(logger);
};
