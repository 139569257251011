import { getNavigationActions } from '../../../../routes/config-util';

import { DebugMenuOptions, MenuOption } from './types';

export function buildAccountOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handlePressAccount = () => {
    navigationActions.toLogin('modal', {
      onConfirm: () => {
        navigationActions.back();
        navigationActions.reset({
          index: 0,
          actions: [
            getNavigationActions().toTaxYearsAuthenticatedOverview('modal'),
            getNavigationActions().toAccount('modal'),
          ],
        });
      },
    });
  };

  return {
    text: 'Account',
    onPress: handlePressAccount,
    showArrow: true,
  };
}
