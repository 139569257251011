import * as React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { ImportResponsesContainer } from './import-responses-container';

export type ActionParams = {
  onContinue: () => void;
};
type Props = Navigation<
  'ImportResponses',
  {
    ImportResponses: NavigationParams<ActionParams>;
  }
>;
export const navigateTo: Action<ActionParams> = navigate('ImportResponses');

const ImportResponsesScreen: React.FC<Props> = ({ route }) => {
  const { onContinue } = route.params;
  useDefaultNavigationOptions({ id: 'Import Responses' });

  return <ImportResponsesContainer onContinue={onContinue} />;
};

export default ImportResponsesScreen;
