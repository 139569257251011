import * as React from 'react';
import _ from 'lodash';
import { IntlShape } from 'react-intl';

import { Place, Suggestion } from '../../../../types/geo';
import SectionList from '../../../section-list';
import SectionHeader from '../../../section-list-header';
import AddressListItem from '../../../../screens/components/distance-search/components/list-element';

type Props = {
  intl: IntlShape;
  previousPlaces: Place[];
  searchResults: Suggestion[];
  onPressPreviousPlace?: (place: Place) => void;
  onPressSearchResult: (suggestion: Suggestion) => void;
  testId?: string;
};

const SectionHeaderWrapper = (section: any) =>
  _.get(section, 'section.title') ? <SectionHeader {...section} /> : null;

const List: React.FC<Props> = ({
  intl,
  previousPlaces,
  searchResults,
  onPressPreviousPlace,
  onPressSearchResult,
  testId,
}) => (
  <SectionList
    stickySectionHeadersEnabled={false}
    sections={[
      {
        // @ts-ignore
        title:
          previousPlaces.length <= 0 || searchResults.length > 0
            ? ''
            : intl.formatMessage({
                id: 'answers.distance.recent.header',
              }),
        data: previousPlaces,
        keyExtractor: (item: any) => item.displayName,
        renderItem: ({ item }: any) => (
          <AddressListItem
            iconName="mono.icon-nav-location-previous"
            address={item.displayName}
            onPress={() => {
              onPressPreviousPlace?.(item);
            }}
          />
        ),
      },
      {
        // @ts-ignore
        data: searchResults,
        // @ts-ignore
        title: '',
      },
    ]}
    keyboardShouldPersistTaps="handled"
    keyExtractor={(item: any) => item.name}
    renderSectionHeader={SectionHeaderWrapper}
    testId={testId ? `${testId}.scroll` : undefined}
    renderItem={({ item }: any) => (
      <AddressListItem
        testId="answer::search:result"
        address={item.name}
        iconName={item.iconName}
        iconColor={item.iconColor}
        onPress={() => {
          if (item.name) {
            onPressSearchResult(item);
          }
        }}
      />
    )}
  />
);

export default List;
