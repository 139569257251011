import * as React from 'react';

import { Box, theme, Image, BodyPrimaryText, LinkText } from './core';
import { SpinningImage } from './spinning-image';
import Overlay from './overlay';

type Props = {
  titleId: string;
  progressId?: string;
  translationValues?: Record<string, number | string>;
};

const UploadProgressOverlay = ({ titleId, progressId, translationValues = {} }: Props) => (
  <Overlay
    backgroundColor={theme.color.overlay}
    disableBackgroundTouch
    style={{
      justifyContent: 'center',
    }}
  >
    {() => (
      <Box alignHorizontally="center" alignVertically="center" left={2} right={2}>
        <Box>
          <SpinningImage>
            <Image name="payslip.taxfix-loading" />
          </SpinningImage>
        </Box>
        <Box top={2}>
          <Box alignHorizontally="center">
            <BodyPrimaryText
              style={{
                color: theme.color.lightFill,
              }}
              id={titleId}
            />
            {progressId && translationValues && (
              <LinkText
                style={{
                  textAlign: 'center',
                }}
                values={translationValues}
                id={progressId}
              />
            )}
          </Box>
        </Box>
      </Box>
    )}
  </Overlay>
);

export { UploadProgressOverlay };
