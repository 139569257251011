import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ScrollView as NBScrollView, useBreakpointValue, useTheme } from 'native-base';
import HTML from 'react-native-render-html';

import { Container, TextLink, Text, IconButton, icons } from 'src/taxfix-components/src';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { theme } from 'src/components/core';
import Overlay from 'src/components/overlay';
import { ScreenName } from 'src/types/screen-name';
import { KeysAndValues } from 'src/utils/translationValuesWithDefaults';

import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

enum TranslationValuesStrings {
  current_residence_city = '{current_residence_city}',
  tax_year = '{tax_year}',
  next_tax_year = '{next_tax_year}',
}

type Props = {
  onClose(): void;
  content: string;
  translationValues?: KeysAndValues;
};

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
});

export const QuestionITGuidanceOverlay = ({
  onClose: onCloseComplete,
  content,
  translationValues,
}: Props): JSX.Element => {
  const [htmlContent, setHtmlContent] = React.useState<string>(content);
  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const { colors } = useTheme();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const paddingLeftScrollView = useBreakpointValue({ base: 20, md: 64 });
  const paddingRightScrollView = useBreakpointValue({ base: 20, md: 44 });
  const supportLinkText = getTranslationText('it.overlays.season-question-flow.footer.link');

  const mapTranslationValues: Record<string, string | number> = {
    [TranslationValuesStrings.current_residence_city]:
      translationValues?.current_residence_city ?? ' ',
    [TranslationValuesStrings.tax_year]: translationValues?.tax_year ?? ' ',
    [TranslationValuesStrings.next_tax_year]: translationValues?.next_tax_year ?? ' ',
  };

  const handleOnPressSupport = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ITQuestions,
      buttonName: supportLinkText,
    });
    handleOpenChatPopup();
  };

  React.useEffect(() => {
    const formattedHtmlContent = htmlContent.replace(
      /{current_residence_city}|{tax_year}|{next_tax_year}/gi,
      (matched: string) => {
        return mapTranslationValues[matched] ? mapTranslationValues[matched].toString() : '';
      },
    );
    setHtmlContent(formattedHtmlContent);
  }, []);

  const titlesFontStyle = {
    fontFamily: 'CircularStd-Bold',
    marginTop: 20,
    marginBottom: 20,
    lineHeight: 24,
    color: colors.greytones.textDefault,
  };

  const contentFontStyle = { fontFamily: 'CircularStd-Book', fontSize: 16, lineHeight: 20 };

  const htmlStyles = {
    h1: {
      ...titlesFontStyle,
      fontSize: 24,
      lineHeight: 30,
    },
    h2: {
      ...titlesFontStyle,
      fontSize: 20,
    },
    h3: {
      fontFamily: 'CircularStd-Bold',
      lineHeight: 24,
      fontSize: 12,
      letterSpacing: 1,
      color: colors.greytones.graphicsDark,
      textTransform: 'uppercase',
      marginTop: 16,
      marginBottom: 12,
      padding: 0,
    },
    blockquote: {
      backgroundColor: colors.greytones.background,
      borderRadius: 8,
      marginBottom: 12,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    p: {
      ...contentFontStyle,
      color: colors.greytones.title,
      marginBottom: 10,
      padding: 0,
    },
    span: {
      fontFamily: 'CircularStd-Bold',
    },
    ul: {
      marginBottom: 3,
      color: colors.greytones.title,
      padding: 0,
    },
    li: {
      ...contentFontStyle,
      padding: 0,
    },
    a: {
      ...contentFontStyle,
      color: colors.brand.brandGreen,
    },
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isLargeScreen ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isLargeScreen ? 'right' : 'bottom'}
    >
      {({ onClose }: { onClose: () => void }) => (
        <>
          <IconButton
            color={colors.brand.brandGreen}
            padding="30px"
            alignSelf="flex-start"
            path={icons.close}
            onPress={onClose}
            size="18px"
          />

          <NBScrollView
            height="100%"
            paddingRight={paddingLeftScrollView}
            paddingLeft={paddingRightScrollView}
            display="flex"
          >
            <View onStartShouldSetResponder={() => true}>
              <HTML source={{ html: htmlContent }} tagsStyles={htmlStyles} />
            </View>
            <Container flexDirection="column" marginY={isLargeScreen ? 30 : 50}>
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.overlays.season-question-flow.footer.title')}
              />
              <TextLink
                variant="titleXSMedium"
                color={'brand.brandGreen'}
                text={supportLinkText}
                onPress={handleOnPressSupport}
              />
            </Container>
          </NBScrollView>
        </>
      )}
    </Overlay>
  );
};
