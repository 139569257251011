// add countrySpecifics in settings.data
import _ from 'lodash';

import { FirebaseUserPropertyKeys } from '../../services/firebase-analytics';
import { generateFirebaseRandomNumber } from '../modules/settings-util';

export const migration = (state: Record<string, any>) => {
  return _.set(state, 'settings.data.firebaseUserProperties', {
    [FirebaseUserPropertyKeys.RandomNumber]: generateFirebaseRandomNumber(),
  });
};
