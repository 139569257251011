import React from 'react';
import { ScrollView, useBreakpointValue, Box, useTheme } from 'native-base';
import { StyleSheet } from 'react-native';
import HTML from 'react-native-render-html';

import { Container, NavButton } from 'src/taxfix-components/src';
import { useLargeScreenQuery } from 'src/components/core';

import { useItalyIntl } from '../_hooks/use-italy-intl';

const styles = StyleSheet.create({
  container: { minHeight: 600 },
  containerLargeScreen: { minHeight: 300 },
});

type Props = {
  content: string;
  shouldShowBackButton: boolean;
  backNavigationAction(): void;
  onLinkPress(href: string): void;
};

export const ItalyGDPRConsentComponent: React.FC<Props> = ({
  content,
  shouldShowBackButton,
  backNavigationAction,
  onLinkPress,
}) => {
  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();

  const isLargeScreen = useLargeScreenQuery();
  const screenWidth = useBreakpointValue({ base: 'full', md: '628px' });
  const backNavigationText = getTranslationText('web.navigation.italy.back');

  const textStyles = {
    fontFamily: 'CircularStd-Book',
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: 24,
    color: colors.greytones.textDefault,
  };

  const contentStyles = {
    h1: {
      ...textStyles,
      fontSize: 24,
    },
    b: {
      fontFamily: 'CircularStd-Bold',
    },
    p: {
      ...textStyles,
    },
    ul: {
      margin: 0,
      marginTop: 5,
      padding: 0,
      ...textStyles,
    },
    li: {
      marginBottom: 5,
      padding: 0,
      ...textStyles,
    },
    a: {
      ...textStyles,
      color: colors.brand.brandGreen,
    },
  };

  const classStyles = {
    ['privacy-policy-link']: {
      textDecoration: 'none',
      textDecorationLine: 'none',
    },
  };

  return (
    <>
      {shouldShowBackButton && (
        <NavButton onPress={backNavigationAction} text={backNavigationText} />
      )}
      <Container flex={1} alignSelf="center" width={screenWidth} mx={0.5} mb={2}>
        <ScrollView p={1} testID="gdpr-scrollview">
          <Box style={isLargeScreen ? styles.containerLargeScreen : styles.container}>
            <HTML
              tagsStyles={contentStyles}
              classesStyles={classStyles}
              source={{
                html: content,
              }}
              onLinkPress={(event, href) => {
                onLinkPress(href);
              }}
            />
          </Box>
        </ScrollView>
      </Container>
    </>
  );
};
