import * as React from 'react';
import { StyleSheet, TouchableHighlight, View } from 'react-native';
import { TreeNode, Answer, Response } from '@taxfix/quizmaster/dist/types';

import { StyleProp } from 'src/components/types';

import {
  theme,
  Card,
  Icon,
  BodyPrimaryText,
  BodySecondaryText,
  BodyTitleText,
} from '../../../../components/core';
import { translationKeys } from '../../../../i18n';
import { resolveDefaultSummaryFromType } from '../answer-type-resolver';
import {
  SummaryGroup as SummaryGroupProps,
  SummaryItem as SummaryItemProps,
} from '../../../../biz-logic/Summaries';
import { KeysAndValues } from '../../../../utils/translationValuesWithDefaults';

type Props = {
  group: SummaryGroupProps;
  onEdit?: (question: TreeNode) => void;
};

type TitleRowProps = {
  translationKey: string;
  inset: boolean;
  border: boolean;
  translationValues: KeysAndValues;
};

type GenericTitleRowProps = TitleRowProps & {
  TextComponent: typeof BodyTitleText | typeof BodyPrimaryText;
};

type RowProps = {
  id: string;
  translationKey: string;
  response?: Response;
  answer: Answer;
  position: number;
  translationValues: KeysAndValues;
  onPress?: (evt: Record<string, any>) => void;
};

const rowStyles = StyleSheet.create({
  container: {
    minHeight: 52,
  },
  inner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 8,
  },
  innerInset: {
    marginLeft: 20,
    paddingLeft: 0,
  },
  innerBorder: {
    borderTopColor: theme.color.border,
    borderTopWidth: 1,
  },
  centerAlign: {
    alignItems: 'center',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    minHeight: 20,
    flexGrow: 1,
    maxWidth: '60%',
    paddingTop: 15,
    paddingRight: 10,
  },
  value: {
    minHeight: 20,
    flexShrink: 1,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    minHeight: 20,
    flex: 1,
    paddingTop: 15,
  },
  titlePadded: {
    paddingTop: 19,
  },
  arrow: {
    alignSelf: 'center',
  },
  separator: {
    height: 1,
    borderTopWidth: 1,
    borderTopColor: theme.color.border,
  },
  prefillLogo: {
    marginRight: 8,
  },
});

type QuestionProps = {
  id: string;
  values?: KeysAndValues;
  title: boolean;
};

const Question = ({ id, values, title = false /*style*/ }: QuestionProps) => {
  const TextComponent = title ? BodyTitleText : BodySecondaryText;
  return (
    <TextComponent
      id={translationKeys(id).short}
      values={values}
      numberOfLines={1}
      ellipsizeMode="tail"
    />
  );
};

type AnswerProps = {
  id: string;
  value: Answer;
  response?: Response;
  linkable?: boolean;
};

const AnswerComponent = ({ id, value, response, linkable }: AnswerProps) => {
  const type = (response && response.type) || '';
  const SummaryComponent = resolveDefaultSummaryFromType(type, id);
  const properties = (response && response.properties) || {};
  return <SummaryComponent value={value} properties={properties} linkable={linkable} />;
};

const GenericTitleRow = ({
  translationKey,
  inset,
  border,
  TextComponent,
  translationValues,
}: GenericTitleRowProps) => {
  const styling = [
    rowStyles.inner,
    inset ? rowStyles.innerInset : null,
    border ? rowStyles.innerBorder : null,
  ];
  const innerStyles = [
    rowStyles.title,
    TextComponent === BodyTitleText ? rowStyles.titlePadded : null,
  ];
  return (
    <View style={[rowStyles.container]}>
      <View style={styling}>
        <View style={innerStyles}>
          <TextComponent id={translationKey} values={translationValues} />
        </View>
      </View>
    </View>
  );
};

const TitleRow = (props: TitleRowProps) => (
  <GenericTitleRow {...props} TextComponent={BodyTitleText} />
);

const Row = ({
  id,
  translationKey,
  response,
  answer,
  position,
  translationValues,
  onPress,
}: RowProps) => {
  const styling: StyleProp = [rowStyles.inner];
  const separator = [];
  const isTitle = position === 0;
  const question = <Question id={translationKey} values={translationValues} title={isTitle} />;

  if (position === 1) {
    styling.push(rowStyles.innerBorder);
  }

  if (position > 1) {
    styling.push(rowStyles.innerInset);
    separator.push(rowStyles.separator);
  }

  return (
    <TouchableHighlight onPress={onPress} underlayColor={theme.color.fill}>
      <View style={rowStyles.container}>
        <View style={separator} />
        <View style={styling}>
          <View style={rowStyles.content}>
            <View style={rowStyles.label}>{question}</View>
            <View style={rowStyles.value}>
              <AnswerComponent
                id={id}
                response={response}
                value={answer}
                linkable={onPress != null}
              />
            </View>
          </View>
          {onPress ? (
            <View style={rowStyles.arrow}>
              <Icon
                name="mono.icon-nav-arrow-right"
                tintColor={theme.color.primary}
                testId={`test:answered:question:${translationKey}`}
              />
            </View>
          ) : (
            <View
              style={{
                width: 10,
              }}
            />
          )}
        </View>
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  answersContainer: {
    flexDirection: 'row',
  },
  answerLabel: {
    flex: 1,
  },
});

export const SummaryGroup = ({ group, onEdit }: Props): JSX.Element => {
  const items = group.items || [];
  const hasItems = items.length > 0;
  const hasAnswer = hasItems && items.some((node) => node.answer != null);

  if (!hasAnswer) {
    return <></>; // Ensure nothing is rendered
  }

  const itemsWithAnswers = items.filter(
    (item) => item.answer != null && item.question.response != null,
  );
  let title: React.ReactElement<TitleRowProps> | null = null;

  if (group && group.node && (group.node.loop || itemsWithAnswers.length > 1)) {
    title = (
      <TitleRow
        key={group.node.id}
        translationKey={translationKeys(group.node.id).short}
        inset={false}
        border={false}
        translationValues={group.translationValues}
      />
    );
  }

  return (
    <View style={styles.container}>
      <Card backgroundColor={theme.color.lightFill} borderColor={theme.color.border}>
        {title}
        {itemsWithAnswers.map((item: SummaryItemProps, index: number): React.ReactNode => {
          return (
            <Row
              key={item.id}
              id={item.id}
              translationKey={item.id}
              response={item.question.response}
              answer={item.answer}
              position={title ? index + 1 : index}
              translationValues={item.translationValues}
              onPress={onEdit ? () => onEdit(item.question) : undefined}
            />
          );
        })}
      </Card>
    </View>
  );
};
