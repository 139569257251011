import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { View } from 'react-native';

import Overlay from './overlay';
import { ScrollView, Box, theme, IconButton, DisplayTitleText, BodySecondaryText } from './core';
import { StyleProp } from './types';

type Props = {
  title: MessageDescriptor;
  description: MessageDescriptor;
  onClose: () => void;
  bodyComponent?: React.ComponentType<any>;
  position?: 'top' | 'bottom' | 'left' | 'right';
  contentContainerStyle?: StyleProp;
};

const setResponder = (): boolean => true;

const InfoOverlay = ({
  title,
  description,
  onClose: onCloseComplete,
  bodyComponent: Component,
  position,
  contentContainerStyle,
}: Props): JSX.Element => {
  return (
    <Overlay bottomFiller onCloseComplete={onCloseComplete} position={position}>
      {({ onClose }: any) => (
        <ScrollView
          alwaysBounceVertical={false}
          stickyHeaderIndices={[0]}
          contentContainerStyle={contentContainerStyle}
        >
          <Box
            style={{
              height: 58,
              backgroundColor: theme.color.lightFill,
            }}
            direction="row"
          >
            <Box left={2.2}>
              <IconButton
                iconKey="cross"
                onPress={onClose}
                tintColor={theme.color.primary}
                testId="infoOverlay::close"
              />
            </Box>
          </Box>
          <Box>
            {Component ? (
              <View onStartShouldSetResponder={setResponder}>
                <Component />
              </View>
            ) : (
              <View onStartShouldSetResponder={setResponder}>
                <Box
                  top={2}
                  right={2}
                  bottom={3}
                  left={2}
                  style={{
                    backgroundColor: theme.color.lightFill,
                  }}
                >
                  <Box bottom={1.5}>
                    <DisplayTitleText id={title.id as string} />
                  </Box>
                  <Box bottom={2}>
                    <BodySecondaryText id={description.id as string} />
                  </Box>
                </Box>
              </View>
            )}
          </Box>
        </ScrollView>
      )}
    </Overlay>
  );
};

export default InfoOverlay;
