import * as React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ItalyDonationContainer } from '../../../screens/containers/italy-donation';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

export type ActionParams = {
  backNavigationText?: string;
};

type Props = Navigation<
  'ItalyDonation',
  {
    ItalyDonation: NavigationParams<ActionParams>;
  }
>;

export const toItalyDonation: Action<ActionParams> = navigate('ItalyDonation', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ItalyDonation',
  },
});

export const ItalyDonationRoute = ({ route }: Props): JSX.Element => {
  const { backNavigationText } = route.params;

  useDefaultNavigationOptions({ id: 'it.donations-screen.nav.title' });

  return <ItalyDonationContainer backNavigationText={backNavigationText} />;
};
