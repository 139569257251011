import * as React from 'react';
import { useBreakpointValue } from 'native-base';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { useIntl } from 'react-intl';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import type { State as RootState } from 'src/stores/store/initial';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import {
  HeaderTitleTranslation,
  useDefaultNavigationOptions,
} from '../../hooks/navigation-options-hook';
import { withQuestionStore } from '../../../utils/withQuestionStore';
import { selectors as settingsSelectors } from '../../../stores/modules/settings';
import Summaries from '../../../biz-logic/Summaries';
import { translationKeys } from '../../../i18n';
import { QuestionsContainer } from '../../../_italy/quizmaster/containers/questions-it';

const mapStateToProps = (state: RootState) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
  year: settingsSelectors.selectedYear(state),
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type ActionParams = {
  root: string;
  editing: boolean;
  onResponseWithTransitions: any | null | undefined;
  backNavigationText?: string;
  hideSummary: boolean;
  onNext: () => void;
};

type Props = Navigation<
  'QuizMaster',
  {
    QuizMaster: NavigationParams<ActionParams>;
  }
> &
  ReduxProps;

export const navigateTo: Action<ActionParams> = navigate('QuizMaster', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'QuizMaster',
  },
});

export const QuizMasterRouteUndecorated = ({ route, questionStore, year }: Props | any) => {
  const {
    root,
    editing = false,
    onResponseWithTransitions,
    onNext,
    backNavigationText = 'web.italy.navigation.back-to-declaration',
    hideSummary = false,
    skipFinalSummary = false,
  } = route.params;
  const intl = useIntl();
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  // This is the analog to the construct on a class component
  // We need to find a better place to rebuild since this will block the screen
  React.useState(() => {
    questionStore.rebuildIfResponsesUpdated();
    return true;
  });

  const getNavigationTitle = (): HeaderTitleTranslation => {
    let title: any = '';
    const quizmaster = questionStore.quizmaster(root, !!editing, year);
    const { id, loopContext } = quizmaster.tree;
    let translationValues = {};
    const currentQuestion = quizmaster.questions.find((question: any) => question.id === id);

    if (currentQuestion && editing) {
      // When editing a question, we need to get translation values from quizmaster the same way
      // we do for summaries.
      const questions = {
        [id]: currentQuestion,
      };
      translationValues = Summaries.createTranslationValues(
        questions,
        id,
        questionStore.responses,
        questionStore.cache,
        questionStore.year,
      );
    }

    const category = intl.formatMessage(
      {
        id: translationKeys(id).short,
      },
      {
        count: loopContext ? loopContext.index + 1 : null,
        ...translationValues,
        tax_year: year,
      },
    );
    const context = {
      category,
    };
    title = {
      key: 'questions.title',
      context,
    };
    title = {
      id: title.key,
      values: title.context,
    };
    return title;
  };

  useDefaultNavigationOptions(getNavigationTitle(), {
    // This prevents some questions having 'Back' and some others getting category name.
    headerBackTitle: '',
    headerTruncatedBackTitle: '',
  });

  return (
    <QuestionsContainer
      editing={editing}
      onResponseWithTransitions={onResponseWithTransitions}
      root={root}
      showSupportedAlerts={false}
      currentScreenKey={route.key}
      hideIntroCard
      onNext={onNext}
      isLargeScreen={isLargeScreen}
      backNavigationText={getTranslationText(backNavigationText)}
      hideSummary={hideSummary}
      skipFinalSummary={skipFinalSummary}
    />
  );
};

export const QuizMasterRoute = compose(connector, withQuestionStore)(QuizMasterRouteUndecorated);
