import React, { useEffect } from 'react';
import Config from 'react-native-config';
import { EppoSdk } from '@taxfix/eppo';

import Analytics from 'src/biz-logic/analytics';
import { logger } from 'src/taxfix-business-logic/utils/logger';

type TrackMethod = (eventName: string, properties: Record<string, string>) => void;

export const EppoWrapper: React.FC = () => {
  useEffect(() => {
    if (!EppoSdk.isEnabled) return;

    // we do not block the app while initialising Eppo
    // if we need to run any AB test on the first screen we have to block the app until Eppo is initialised
    // https://taxfix.atlassian.net/browse/ITA-3540 - tech debt
    EppoSdk.init(Config.EPPO_API_KEY, Analytics.log as TrackMethod).catch((err) =>
      logger.warn(err, { message: 'initEppo failed' }),
    );
  }, []);

  return null;
};
