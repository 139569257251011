import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import initial from '../stores/store/initial';
import { selectors as internetConnectionSelectors } from '../stores/modules/internet-connection';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import { syncUploadAnswersForSelectedCountry } from '../services/sync-answers';

const mapStateToProps = (state: typeof initial) => ({
  isConnected: internetConnectionSelectors.isConnected(state),
  isAuthenticated: userAuthSelectors.isAuthenticated(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type Props = {} & ReduxProps;

class SyncAnswers extends React.Component<Props, any> {
  async componentDidUpdate(prevProps: Props) {
    if (!prevProps.isConnected && this.props.isConnected && this.props.isAuthenticated) {
      try {
        await syncUploadAnswersForSelectedCountry();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('sync-upload failed - error:', e);
      }
    }
  }

  render() {
    return null;
  }
}

const SyncAnswersContainer = connector(SyncAnswers);
export { SyncAnswersContainer };
