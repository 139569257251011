import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const codePush = Platform.OS !== 'web' && require('react-native-code-push');

import {
  actions as codePushActions,
  selectors as codePushSelectors,
} from 'src/stores/modules/code-push';

export const useSkipCodePushUpdate = (): void => {
  const dispatch = useDispatch();
  const setSkipInstallUpdate = useCallback(
    (skipInstallUpdate: boolean) => {
      dispatch(codePushActions.setSkipInstallUpdate(skipInstallUpdate));
    },
    [dispatch],
  );
  const skipInstallUpdate = useSelector(codePushSelectors.skipInstallUpdate);

  useEffect(() => {
    if (!skipInstallUpdate && codePush) {
      setSkipInstallUpdate(true);
      codePush.disallowRestart();
    }

    return () => {
      if (skipInstallUpdate) {
        setSkipInstallUpdate(false);
      }
    };
  }, [setSkipInstallUpdate, skipInstallUpdate]);
};
