import React from 'react';
import { Badge as NbBadge, Pressable, useBreakpointValue } from 'native-base';

import { useItalyIntl } from '../../../../../_italy/_hooks';
import { Container } from '../../layout/Container';
import { Icon } from '../Icon';
import { Text } from '../Text';

import { Props } from './types';

const Badge: React.FC<{
  newMessages: number;
  isHeaderView: boolean;
}> = ({ newMessages, isHeaderView }) => (
  <NbBadge
    justifyContent="center"
    alignItems="center"
    minWidth="16px"
    height="16px"
    backgroundColor="secondaryCategory.skyFull"
    paddingRight={isHeaderView ? '3px' : '4px'}
    paddingLeft={isHeaderView ? '3px' : '4px'}
    borderRadius="16px"
    borderWidth="0px"
    position="absolute"
    top="-3px"
    {...(isHeaderView ? { right: '-3px' } : { left: '-3px' })}
  >
    <Text variant="bodyXSMedium" color="greytones.white">
      {String(newMessages)}
    </Text>
  </NbBadge>
);

export const SupportButton: React.FC<Props> = ({
  iconPath,
  buttonText,
  showBadge = false,
  newMessages,
  disabled = false,
  onPress,
  ...otherProps
}) => {
  const { getTranslationText } = useItalyIntl();
  const text = buttonText ?? getTranslationText('it.support.button.default.text');
  const isHeaderView = useBreakpointValue({ base: true, xl: false });

  return (
    <Pressable onPress={onPress} disabled={disabled}>
      <Container
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        minWidth={!isHeaderView ? '135px' : '93px'}
        // @ts-ignore
        style={!isHeaderView ? { position: 'fixed', right: 40, bottom: 120 } : {}}
        height={isHeaderView ? '36px' : '48px'}
        backgroundColor="secondaryFeedback.greenLight"
        paddingX={isHeaderView ? 16 : 20}
        borderRadius={0}
        shadow={isHeaderView ? undefined : 'elevation2'}
        position="relative"
        {...otherProps}
      >
        {showBadge && !!newMessages && (
          <Badge newMessages={newMessages} isHeaderView={isHeaderView} />
        )}
        {iconPath && <Icon path={iconPath} color="brand.brandGreen" size={5} marginRight={0} />}
        <Text
          variant={isHeaderView ? 'titleXXSMedium' : 'titleXSMedium'}
          color="brand.brandGreen"
          value={text}
        />
      </Container>
    </Pressable>
  );
};
