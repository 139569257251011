import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { Text } from '../components/core';

export type Props = {
  value: Date;
};
type FlowFriendlyComponentProps = Props & {
  Component?: React.ComponentType<any>;
};
type WrappedProps = FlowFriendlyComponentProps & {
  intl: IntlShape;
};

const WrappedComponent = function IntlText(props: WrappedProps) {
  const { intl, value, Component = Text } = props;
  const intlOpts = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;
  return <Component {...props}>{intl.formatDate(value, intlOpts)}</Component>;
};

const FlowFriendlyComponent = injectIntl(WrappedComponent);
export default (props: FlowFriendlyComponentProps) => <FlowFriendlyComponent {...props} />;
