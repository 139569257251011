import React, { PureComponent, ReactNode } from 'react';
import { PanResponderInstance } from 'react-native/Libraries/Interaction/PanResponder';
import {
  Animated,
  Dimensions,
  Easing,
  PanResponder,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { InformationButton } from 'src/containers/information-button';
import { isWeb } from 'src/utils/platform';
import { KeysAndValues } from 'src/utils/translationValuesWithDefaults';
import { testID } from 'src/common/testID';
import {
  OnInfoButtonTooltipClosed,
  OnInfoButtonTooltipOpened,
} from 'src/hocs/with-info-button-tooltip';

import {
  BodySecondaryText,
  Box,
  Card,
  Icon,
  Image,
  QuestionText,
  SmallTitleText,
  theme,
  TitleText,
} from './core';

const { width } = Dimensions.get('window');

export type Props = {
  children?: React.ReactElement;
  onSkip?: () => void;
  cardColor?: string;
  answerType?: string;
  isLoading?: boolean;
  skippable?: boolean;
  type?: 'normal' | 'chat';
  isGovPrefilled?: boolean;
  onInformation?: false | (() => void);
  primaryTextColor?: string;
  loopQuestionText?: string;
  secondaryTextColor?: string;
  onHandleStartSkip?: () => void;
  inlineInformation?: ReactNode;
  hasSeenSwipeTutorial?: boolean;
  questionTranslationKey?: string;
  informationPositionTop?: boolean;
  translationValues?: KeysAndValues;
  loopQuestionTranslationKey?: string;
  shouldShowInfoButtonTooltip?: boolean;
  onInfoButtonTooltipOpened?: OnInfoButtonTooltipOpened;
  onInfoButtonTooltipClosed?: OnInfoButtonTooltipClosed;
  onAnimationStateChange?: (isAnimating: boolean) => void;
  customCardStyle?: Record<string, unknown>;
  customCardInnerStyle?: StyleProp<ViewStyle>;
  questionTransformed?: string;
  subtitleTransformed?: string;
  informationPositionWithText?: boolean;
  informationText?: string;
};

type SkipState = 'showIcon' | 'fadeOutIcon' | 'showHelperText';

type State = {
  skipState: SkipState;
};

const questionStyle = {
  normal: StyleSheet.create({
    card: {
      flex: 0,
      minHeight: 193,
      justifyContent: 'flex-end',
    },
    cardInner: {
      flex: 1,
      padding: 10,
      justifyContent: 'space-between',
    },
    textContainerNative: {
      paddingBottom: 14,
      justifyContent: 'flex-end',
    },
    textContainerWeb: {
      justifyContent: 'flex-end',
    },
  }),
  chat: StyleSheet.create({
    card: {
      flex: 0,
      alignSelf: 'flex-start',
      justifyContent: 'flex-end',
    },
    cardInner: {
      flex: 0,
    },
    textContainerNative: {
      padding: 24,
      justifyContent: 'flex-end',
    },
    textContainerWeb: {
      padding: 24,
      justifyContent: 'flex-end',
    },
  }),
};

const styles = StyleSheet.create({
  skip: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
  },
  skipInner: {
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  skipIcon: {
    width: 20,
    height: 20,
  },
  govPrefilledContainer: {
    left: 14,
    top: 5,
    height: 25,
  },
  govPrefilledTitle: {
    left: 5,
    color: theme.color.primaryBackgroundText,
  },
});

// Distance in px the user should drag before we
// becomes the responder for the pan. This is so this
// component works well within a ScrollView.
const MOVE_THRESHOLD_PX = 5;

// If the card is released at 50% (0.5) of the width of
// the card then this is accepted as a "skip"
const TRIGGER_THRESHOLD = 0.5;

// Stop tracking the pan is the user is less than 50px
// into the drag and something else (e.g. the ScrollView)
// wants to respond to touches.
const CANCELLATION_THRESHOLD_PX = 50;

// When -50 is hit on the X-axis, then opacity should be zero
// and scale should be 0.6
const SKIP_ICONW_OFFSET = -50;

// The space between the card and the skip icon and text
const SKIP_ICON_PADDING = 15;

// The duration of the animation that shows user how to skip a question
const TUTORIAL_SKIP_ANIMATION_DURATION = 500;

// The delay of the animation that shows user how to skip a question
const TUTORIAL_SKIP_ANIMATION_DELAY = 1000;

// The delay of the animation that shows user how to skip a question if its been displayed before
const TUTORIAL_SKIP_ANIMATION_DELAY_LONG = 30000;

class QuestionCard extends PureComponent<Props, State> {
  state: State = {
    skipState: 'showIcon',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const finishAnimation = (evt: any, gestureState: any) => {
      const shouldSkip = Math.abs(gestureState.dx) > width * TRIGGER_THRESHOLD;
      const animateTo = shouldSkip ? -width : 0;

      Animated.timing(this.panAnimation, {
        toValue: animateTo,
        duration: 200,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start(() => {
        if (shouldSkip) {
          this.startFadeInOutAnimation();
        }
      });

      if (this.props.onAnimationStateChange) {
        this.props.onAnimationStateChange(false);
      }
    };

    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => false,
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        return Math.abs(gestureState.dx) > MOVE_THRESHOLD_PX;
      },
      onPanResponderGrant: () => {
        if (this.props.onAnimationStateChange) {
          this.props.onAnimationStateChange(true);
        }
      },
      // Wraps Animated.event to allow side-effects when tracking the pan
      onPanResponderMove: Animated.event(
        [
          null,
          {
            dx: this.panAnimation,
          },
        ],
        // PanResponder can't work with native Animated.event
        // https://github.com/facebook/react-native/issues/13377
        { useNativeDriver: false },
      ),
      onPanResponderRelease: finishAnimation,
      onPanResponderTerminationRequest: (evt, gestureState) => {
        return Math.abs(gestureState.dx) < CANCELLATION_THRESHOLD_PX;
      },
      onPanResponderTerminate: finishAnimation,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { isLoading } = this.props;
    if (isLoading !== prevProps.isLoading) {
      if (prevProps.isLoading !== false) {
        this.reset();
      } else {
        // stop reservated tutorial
        this.panAnimation.stopAnimation();
      }
    }
  }

  getDelayForTutorialAnimation = (): number => {
    return this.props.hasSeenSwipeTutorial
      ? TUTORIAL_SKIP_ANIMATION_DELAY_LONG
      : TUTORIAL_SKIP_ANIMATION_DELAY;
  };

  panResponder: PanResponderInstance;

  panAnimation: Animated.AnimatedValue = new Animated.Value(0);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  skipIconXAnimation: Animated.AnimatedAddition = Animated.add(
    Animated.multiply(this.panAnimation, 0.5),
    20,
  );

  fadeOutHelpTextAnimation: Animated.AnimatedValue = new Animated.Value(1);

  getTextStyle = (color: string): TextStyle => {
    const { skipState } = this.state;
    const style: TextStyle = {
      textAlign: 'center',
      backgroundColor: 'transparent',
    };

    if (skipState === 'showHelperText') {
      return { ...style, width: '80%' };
    }

    return { ...style, color };
  };

  reset = (): void => {
    // reset skipped card opacity
    this.panAnimation.setValue(0);
    this.fadeOutHelpTextAnimation.setValue(1);
    this.setState({
      skipState: 'showIcon',
    });
    this.showSkipTutorial();
  };

  showSkipTutorial = (): void => {
    if (!this.props.skippable) {
      return;
    }

    Animated.sequence([
      Animated.timing(this.panAnimation, {
        delay: this.getDelayForTutorialAnimation(),
        toValue: -(width / 1.5),
        duration: TUTORIAL_SKIP_ANIMATION_DURATION,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: false,
      }),
      Animated.timing(this.panAnimation, {
        delay: TUTORIAL_SKIP_ANIMATION_DELAY,
        toValue: 0,
        duration: 600,
        easing: Easing.out(Easing.elastic(1.2)),
        useNativeDriver: false,
      }),
    ]).start();
  };

  handleSkip = (): void => {
    if (!this.props.onSkip) {
      return;
    }

    this.props.onSkip();
  };

  startFadeInHelpText = (): void => {
    Animated.sequence([
      Animated.timing(this.fadeOutHelpTextAnimation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }),
      Animated.timing(this.fadeOutHelpTextAnimation, {
        delay: 950,
        toValue: 0,
        easing: Easing.linear,
        duration: 200,
        useNativeDriver: false,
      }),
    ]).start(this.handleSkip);
  };

  startFadeInOutAnimation = (): void => {
    if (this.props.onHandleStartSkip) {
      this.props.onHandleStartSkip();
    }

    this.setState(
      {
        skipState: 'fadeOutIcon',
      },
      () => {
        Animated.timing(this.fadeOutHelpTextAnimation, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }).start(() => {
          this.setState(
            {
              skipState: 'showHelperText',
            },
            this.startFadeInHelpText,
          );
        });
      },
    );
  };

  getLoopContext = (): React.ReactNode => {
    const {
      loopQuestionText,
      translationValues = {},
      loopQuestionTranslationKey,
      secondaryTextColor = theme.color.primaryBackgroundText,
    } = this.props;

    if (loopQuestionTranslationKey) {
      return (
        <TitleText
          style={{
            color: secondaryTextColor,
          }}
          id={loopQuestionTranslationKey}
          values={translationValues}
        />
      );
    }

    if (loopQuestionText) {
      return (
        <TitleText
          style={{
            color: secondaryTextColor,
          }}
        >
          {loopQuestionText}
        </TitleText>
      );
    }

    return null;
  };

  getInformation = (): React.ReactNode => {
    const {
      onInformation,
      onInfoButtonTooltipOpened,
      onInfoButtonTooltipClosed,
      shouldShowInfoButtonTooltip,
      secondaryTextColor = theme.color.primaryBackgroundText,
      informationText = undefined,
      informationPositionWithText,
    } = this.props;
    if (!onInformation) {
      return null;
    }

    return (
      <Box direction="row">
        <InformationButton
          color={secondaryTextColor}
          onPress={onInformation}
          shouldShowTooltip={shouldShowInfoButtonTooltip as boolean}
          onTooltipOpened={onInfoButtonTooltipOpened}
          onTooltipClosed={
            onInfoButtonTooltipClosed ? onInfoButtonTooltipClosed(onInformation) : undefined
          }
          size={informationPositionWithText ? 'small' : 'large'}
          withText={informationPositionWithText}
          informationText={informationText}
        />
      </Box>
    );
  };

  getQuestionText = (): React.ReactNode => {
    const {
      children,
      translationValues = {},
      questionTranslationKey,
      informationPositionTop = true,
      primaryTextColor = theme.color.lightText,
      questionTransformed,
      subtitleTransformed,
      informationPositionWithText = false,
    } = this.props;

    const information = this.getInformation();
    const questionText = questionTranslationKey ? (
      questionTransformed && subtitleTransformed ? (
        <>
          <QuestionText
            style={{
              color: primaryTextColor,
              marginBottom: 16,
            }}
          >
            {questionTransformed}
          </QuestionText>
          <BodySecondaryText
            style={{
              color: theme.color.greytonesText,
            }}
          >
            {subtitleTransformed}
          </BodySecondaryText>
        </>
      ) : (
        <QuestionText
          id={questionTranslationKey}
          values={translationValues}
          style={{
            color: primaryTextColor,
          }}
        />
      )
    ) : (
      children
    );

    if (informationPositionTop || informationPositionWithText) {
      return questionText;
    }

    return (
      <>
        <Box direction="row">
          <Box flex>{questionText}</Box>
          {information && (
            <Box left={0.5} top={0.2}>
              {information}
            </Box>
          )}
        </Box>
      </>
    );
  };

  getSkipCard = (): React.ReactNode => {
    const { skipState } = this.state;
    const { skippable, cardColor = theme.color.primary } = this.props;

    const showIconOpacity = this.panAnimation.interpolate({
      inputRange: [SKIP_ICONW_OFFSET, 0],
      outputRange: [1, 0],
    });

    const skipAnimatedStyles = {
      opacity: skipState === 'showIcon' ? showIconOpacity : this.fadeOutHelpTextAnimation,
      paddingLeft: SKIP_ICON_PADDING,
      transform: [{ translateX: this.skipIconXAnimation }],
    };

    if (!skippable) {
      return null;
    }

    if (skipState !== 'showHelperText') {
      return (
        <Animated.View pointerEvents="none" style={[styles.skip, skipAnimatedStyles]}>
          <Icon name="category-icons.icon-nav-skip" tintColor={cardColor} />
          <BodySecondaryText id="answers.skip" style={this.getTextStyle(cardColor)} />
        </Animated.View>
      );
    }

    return (
      <Animated.View pointerEvents="none" style={[styles.skip, { top: 10, left: 0 }]}>
        <Icon name="category-icons.icon-nav-skipped" />
        <BodySecondaryText id="answers.skip.feedback" style={this.getTextStyle(cardColor)} />
      </Animated.View>
    );
  };

  getGovPrefilledCard = (): React.ReactNode => {
    const { isGovPrefilled } = this.props;

    if (!isGovPrefilled) {
      return null;
    }

    return (
      <Box direction="row" alignVertically="center" style={styles.govPrefilledContainer}>
        <Image name="prefill.icon-gov-prefilled-dark" />
        <SmallTitleText
          style={styles.govPrefilledTitle}
          id="prefill.question-card.goverment-prefilled"
        />
      </Box>
    );
  };

  renderContent(): JSX.Element {
    const { type = 'normal' } = this.props;
    const { textContainerWeb, textContainerNative } = questionStyle[type];
    const defaultContentStyle = isWeb ? textContainerWeb : textContainerNative;

    return (
      <View style={defaultContentStyle}>
        {this.getLoopContext()}
        {this.getQuestionText()}
      </View>
    );
  }

  render() {
    const {
      skippable,
      answerType,
      type = 'normal',
      inlineInformation,
      informationPositionTop = true,
      informationPositionWithText = false,
      cardColor = theme.color.primary,
      customCardStyle = {},
      customCardInnerStyle = {},
    } = this.props;

    const { card: cardStyle, cardInner: cardInnerStyle } = questionStyle[type];

    const isSkipAble = skippable === true;

    const panningTransform = {
      transform: [
        {
          translateX: this.panAnimation.interpolate({
            inputRange: [-width, 0],
            outputRange: [-width, 0],
            extrapolateRight: 'clamp',
          }),
        },
      ],
    };

    return (
      <View {...(isSkipAble ? { ...this.panResponder.panHandlers } : {})}>
        <Animated.View style={panningTransform}>
          <Card
            style={[cardStyle, customCardStyle]}
            backgroundColor={cardColor}
            borderColor="transparent"
          >
            <View style={[cardInnerStyle, customCardInnerStyle]} {...testID(answerType)}>
              <Box direction="row">
                <Box expand>{this.getGovPrefilledCard()}</Box>
                {informationPositionTop && this.getInformation()}
              </Box>
              {this.renderContent()}
              {informationPositionWithText && this.getInformation()}
            </View>
          </Card>
        </Animated.View>
        {inlineInformation}
        {this.getSkipCard()}
      </View>
    );
  }
}

export default QuestionCard;
