import React from 'react';

import { BodySecondaryText } from '../../components/core';
import CredentialScrollAware from '../../components/auth/CredentialScrollAware';

import { EmailPassword, LoginError, LoginErrorKey } from './Login.types';

export const translationKeyForError = (errorKey: LoginError): string => {
  switch (errorKey) {
    case LoginError.EmailInvalid:
    case LoginError.PasswordMissing:
    case LoginError.UserUnknown:
    case LoginError.LoginFailed:
    case LoginError.UserBlocked:
    case LoginError.LoginSsoError:
      return errorKey;
    default:
      return LoginError.ConnectionFailed;
  }
};
type Props = {
  emailAddress: string;
  password: string;
  confirmDisabled: boolean;
  loading: boolean;
  errorKey?: LoginErrorKey;
  onChange: (value: EmailPassword) => void;
  onConfirm: (value: EmailPassword) => void;
  onForgot: () => void;
  isFromMWebSignup?: boolean;
  variants?: any;
  renderSso: Record<string, unknown>;
};

export const Login = ({
  emailAddress,
  password,
  confirmDisabled,
  loading,
  onForgot,
  errorKey,
  isFromMWebSignup,
  variants,
  onChange,
  onConfirm,
  renderSso = {},
}: Props): JSX.Element => {
  const handlePasswordChange = (password: string): void => {
    onChange({
      emailAddress,
      password,
    });
  };

  const handleEmailAddressChange = (emailAddress: string): void => {
    onChange({
      password,
      emailAddress,
    });
  };

  const handleConfirm = (): void => {
    onConfirm({
      emailAddress,
      password,
    });
  };

  let fromMWebSignupProps = {};

  if (isFromMWebSignup) {
    fromMWebSignupProps = {
      confirmKey: 'account.login.personalised.continue',
      titleKey: 'account.login.personalised.title',
      leadKey: 'account.login.personalised.lead',
      emailLabelKey: 'account.login.personalised.email',
      pinLabelKey: 'account.login.personalised.pin',
      leadTextComponent: BodySecondaryText,
      autoFocusEmail: false,
      autoFocusPin: true,
    };
  }

  return (
    <CredentialScrollAware
      scrollable
      confirmKey="account.lock.confirm"
      onConfirm={handleConfirm}
      disabled={confirmDisabled}
      loading={loading}
      imageName={null}
      errorKey={errorKey ? translationKeyForError(errorKey) : null}
      email={emailAddress}
      onEmailChange={handleEmailAddressChange}
      pinType="enter"
      pin={password}
      onPinChange={handlePasswordChange}
      onPinForgot={onForgot}
      autoFocusEmail
      centered
      {...variants}
      {...fromMWebSignupProps}
      {...renderSso}
    />
  );
};
