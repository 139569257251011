import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

import { initial as settings } from '../modules/settings';
import { initial as identifications } from '../modules/identifications';
import { initial as userConsents } from '../modules/user-consents';
import { initial as places } from '../modules/places';
import { initial as overlay } from '../modules/overlay';
import { initial as user } from '../modules/user';
import { initial as partner } from '../modules/partner';
import { initial as bankDetails } from '../modules/bank-details';
import { initial as syncAnswers } from '../modules/sync-answers';
import { initial as internetConnection } from '../modules/internet-connection';
import { initial as submission } from '../modules/submission';
import { initial as prefill } from '../modules/prefill';
import { initial as status } from '../modules/status';
import { initial as requiredDocuments } from '../modules/required-documents';
import { initial as remoteConfigFirebase } from '../modules/remote-config-firebase';
import { initial as migration } from '../modules/migration';
import { initial as devTools } from '../modules/dev-tools';
import { initial as flow } from '../modules/flow-legacy';
import { initial as referralData } from '../modules/referral-data';
import { initial as dynamicLink } from '../modules/dynamic-link';
import { initial as userAuth } from '../modules/user-auth';
import { initial as userProfile } from '../modules/user-profile';
import { initial as codepush } from '../modules/code-push';
import { initial as referralHistory } from '../modules/referral-history';
import { initial as checkoutFlow } from '../modules/checkout-flow';
import { initial as lock } from '../modules/lock';
import { initial as taxSystem } from '../modules/tax-system';
import { initial as payslips } from '../modules/payslips';
import { initial as questions } from '../modules/questions';
import { initial as payment } from '../modules/payment';

const initialState = {
  settings,
  identifications,
  userConsents,
  places,
  overlay,
  user,
  partner,
  bankDetails,
  syncAnswers,
  internetConnection,
  submission,
  prefill,
  status,
  requiredDocuments,
  remoteConfigFirebase,
  migration,
  devTools,
  flow,
  referralData,
  dynamicLink,
  userAuth,
  codepush,
  referralHistory,
  userProfile,
  lock,
  taxSystem,
  checkoutFlow,
  payslips,
  questions,
  payment,
};

export type State = typeof initialState;
export type AppDispatch = ThunkDispatch<State, any, AnyAction>;
export default initialState;
