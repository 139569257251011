import React from 'react';

import navigate, { Action } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ProfileDetailsContainer } from '../../../screens/containers/profile-details';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

export const toProfileDetails: Action = navigate('ProfileDetails', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ProfileDetails',
  },
});

export const ProfileDetails = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'account.profile.screen.header' });

  return <ProfileDetailsContainer />;
};
