import React from 'react';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useLargeScreenQuery } from 'src/components/core';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { ButtonName } from 'src/types/button-name';
import {
  flagsQuestionIds,
  questionFlowCategories,
  TailoredExperienceStatus,
} from 'src/common/constants-it';
import { useQuizmasterLight } from 'src/_italy/_hooks';

import { TailoredExperienceFinal } from './tailored-experience-final-component';

export const toTailoredExperienceFinal: Action = navigate(ScreenName.TailoredExperienceFinal, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.TailoredExperienceFinal,
  },
});

export const TailoredExperienceFinalScreen = (): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();
  const { getNavigationActions, navigationActions, safeResetNavigation } = useNavigation();
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.tailoredExperienceStatus]);

  useDefaultNavigationOptions(
    isLargeScreen ? { id: 'tailored-experience-intro.nav.title' } : undefined,
    {
      headerBackTitleVisible: false,
    },
  );

  const onBack = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const onContinue = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.TailoredExperienceFinal,
      buttonName: ButtonName.Continue,
    });
    quizmasterLight[flagsQuestionIds.tailoredExperienceStatus].saveAnswer(
      TailoredExperienceStatus.Completed,
    );
    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toStatus('screen'),
    ]);
  };

  return <TailoredExperienceFinal onBack={onBack} onContinue={onContinue} />;
};
