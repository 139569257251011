import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBreakpointValue } from 'native-base';

import { Container, Folder, Label } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { translationInfoForDocuments, incomeDocumentCategories } from 'src/common/constants-it';

import { KeysAndValues } from '../../../utils/translationValuesWithDefaults';
import { selectors as submissionSelectors } from '../../../stores/modules/submission';
import { selectors as settingsSelectors } from '../../../stores/modules/settings';

import documentCategoryIconMap from './document-category-icon-map';

export type Document = {
  translationKey: string;
  translationValues?: KeysAndValues | undefined;
  handleDocumentPressed?: () => void;
  documentCount: number;
  folderContent?: any;
  categories?: Array<string>;
  categoryKey?: string;
};
export type DocumentCategory = {
  translationKey: string;
  descriptionTranslationKey?: string;
  translationValues?: KeysAndValues;
  action?: {
    translationKey: string;
    handleAction: () => any;
  };
  items: Document[];
};
type Props = {
  documents: Document[];
  cleanFolder?: (categories: Array<string>, title: string) => void;
  isLastCUCategory?: boolean;
};

export const DocumentsList = ({ documents, cleanFolder, isLastCUCategory }: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const year = useSelector(settingsSelectors.selectedYear) as number;
  const isResubmission = useSelector((state) =>
    submissionSelectors.isResubmissionByYear(state as any, year),
  );
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  // container
  const paddingRightContainer = useBreakpointValue({ base: '8px', md: '0px' });
  const paddingLeftContainer = useBreakpointValue({ base: '24px', md: '20px', lg: '0px' });
  const marginTopContainer = useBreakpointValue({ base: '28px', md: '40px', lg: '68px' });
  const marginBottomContainer = useBreakpointValue({ base: '32px', md: '28px', lg: '20px' });
  // folder
  const marginBottomFolder = useBreakpointValue({ base: '16px', md: '24px', lg: '40px' });
  const labelTextVariant = useBreakpointValue({ base: 'overlineS', lg: 'overlineM' });
  const labelWidth = useBreakpointValue({ base: '84px', lg: '96px' });
  const labelHeight = useBreakpointValue({ base: '20px', lg: '24px' });
  const dedupedDocuments = useMemo(() => {
    const seen = new Set<string>();
    return documents.filter((doc) => {
      if (doc.categories) {
        if (doc.categories.length == 0) {
          return true; // This case is for allowing "ADD_OTHER_EXPENSES" category key
        } else {
          if (seen.has(doc.categories[0])) {
            return false;
          } else {
            seen.add(doc.categories[0]);
            return true;
          }
        }
      } else {
        return true;
      }
    });
  }, [documents]);

  return (
    <Container
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="flex-start"
      marginTop={marginTopContainer}
      marginBottom={marginBottomContainer}
      paddingRight={paddingRightContainer}
      paddingLeft={paddingLeftContainer}
    >
      {dedupedDocuments.map((document: Document, index) => {
        const {
          translationValues,
          translationKey,
          documentCount,
          handleDocumentPressed,
          folderContent = null,
          categories,
          categoryKey = 'DEFAULT',
        } = document;
        const title = getTranslationText(translationKey, {
          CUYear: translationValues ? translationValues['CUYear'] : '',
          taxYear: translationValues ? translationValues['taxYear'] : '',
        });
        const subtitleTextEnd = isSmallScreen
          ? getTranslationText('it.folder.documents.uploaded.short')
          : getTranslationText('it.folder.documents.uploaded.long');
        const iconName = documentCategoryIconMap[categoryKey] || documentCategoryIconMap['DEFAULT'];
        const hasQuestionConfig =
          Boolean(categories) &&
          categories &&
          Boolean(
            translationInfoForDocuments[categories[0]] &&
              translationInfoForDocuments[categories[0]].questionConfig,
          );
        const shouldHideDeleteOption =
          isLastCUCategory && incomeDocumentCategories.includes(categoryKey);

        let handleDelete;
        if (
          cleanFolder &&
          categories &&
          !shouldHideDeleteOption &&
          hasQuestionConfig &&
          !isResubmission
        ) {
          handleDelete = () => cleanFolder(categories, title);
        } else if (cleanFolder && categories && isResubmission && documentCount < 1) {
          handleDelete = () => cleanFolder(categories, title);
        }

        return (
          <Folder
            key={index}
            testID={translationKey}
            titleText={title}
            numberOfDocuments={documentCount}
            onPress={handleDocumentPressed}
            iconName={iconName}
            subTitleText={`${documentCount} ${subtitleTextEnd}`}
            onDelete={handleDelete}
            openFolderText={getTranslationText('it.digital-caf.required-documents.open-folder')}
            titleElement={folderContent?.titleElement ?? null}
            subtitleElement={folderContent?.subtitleElement ?? null}
            startNowLabel={
              <Label
                variant="default"
                message={getTranslationText('it.folder.label')}
                width={labelWidth}
                height={labelHeight}
                showTextMarginLeft={false}
                textVariant={labelTextVariant}
              />
            }
            marginRight={isSmallScreen ? '16px' : '20px'}
            marginBottom={marginBottomFolder}
          />
        );
      })}
    </Container>
  );
};
