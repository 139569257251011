import * as React from 'react';
import Config from 'react-native-config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { vouchers as Vouchers, Referrals } from '@taxfix/voucher-sdk';
import { CountryCodes } from '@taxfix/types';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { Sleep } from '../../utils/async';
import { Loading } from '../../components/loading';
import {
  RefereeMetadata,
  actions as referralDataActionCreators,
  selectors as referralDataSelectors,
} from '../../stores/modules/referral-data';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { appDataFunction, configKey } from '../../services/firebase-functions';
import initial from '../../stores/store/initial';

export type WithReferralVoucherProps = {
  referralVoucherCode?: string;
  onRemoveReferralVoucher: () => void;
};
type Props = {
  referralDataActions: any;
  refereeMetadata: RefereeMetadata | null | undefined;
  hasCreatedReferee: boolean;
  disableVouchers: boolean;
  accessToken: string;
  selectedCountry: CountryCodes;
};
type State = {
  referralVoucherCode?: string;
  isLoading: boolean;
};

const mapStateToProps = (state: typeof initial) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
  refereeMetadata: referralDataSelectors.getRefereeMetadata(state),
  hasCreatedReferee: referralDataSelectors.hasCreatedReferee(state),
  isFetchReferralVoucherDisabled: referralDataSelectors.isFetchReferralVoucherDisabled(state),
  accessToken: userAuthSelectors.getAccessToken(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  referralDataActions: bindActionCreators(referralDataActionCreators, dispatch),
});

export const withReferralVoucher = (WrappedComponent: any) => {
  class WithReferralVoucher extends React.PureComponent<any & Props, State> {
    state: State = {
      referralVoucherCode: '',
      isLoading: false,
    };

    async componentDidMount() {
      const { disableVouchers } = this.props;

      if (!disableVouchers) {
        this.processReferralVoucher();
      }
    }

    checkIfUserHasReferralVoucher = async () => {
      try {
        const voucher = await Vouchers.getReferralVoucher(
          Config.API_BASE_URL,
          this.props.accessToken,
        );
        this.setState({
          referralVoucherCode: voucher.code,
        });
        Analytics.log(AnalyticsEvent.referralVoucherFetchSuccess, {
          code: voucher.code,
        });
      } catch (e) {
        Analytics.log(AnalyticsEvent.referralVoucherFetchFailed, {
          errorMessage: String(e),
        });
      }
    };

    processReferralVoucher = async () => {
      let isReferralVoucherReady = false;

      if (!this.props.isFetchReferralVoucherDisabled) {
        this.setState({
          isLoading: true,
        });
        isReferralVoucherReady = await appDataFunction.getData({
          ...this.firebaseFunctionCommonParams(),
          defaultValue: null,
        });
      }

      if (!this.props.refereeMetadata && isReferralVoucherReady === false) {
        this.props.referralDataActions.disableFetchReferralVoucher();
      }
      await this.checkIfUserHasReferralVoucher();
      if (this.props.refereeMetadata || isReferralVoucherReady) {
        try {
          this.setState({
            isLoading: true,
          });

          // If createReferee failed after signup we try to call again in here. Due to a slight delay
          // on Voucherify our own api might not be able to get the voucher immediately after this call,
          // that is why there is a Sleep to cover this.
          if (!this.props.hasCreatedReferee) {
            await Referrals.createReferee(
              Config.API_BASE_URL,
              this.props.accessToken,
              this.props.refereeMetadata,
            );
            referralDataActionCreators.refereeCreateSuccess();
            appDataFunction.updateData({
              ...this.firebaseFunctionCommonParams(),
              updatedValue: true,
            });
            await Sleep(2000);
          }
          await this.checkIfUserHasReferralVoucher();
        } catch (error) {
          Analytics.log(AnalyticsEvent.referralVoucherFetchFailed, {
            errorMessage: String(error),
          });
        }
      }

      this.setState({
        isLoading: false,
      });
    };

    firebaseFunctionCommonParams = () => ({
      country: this.props.selectedCountry,
      accessToken: this.props.accessToken,
      key: configKey.isReferralVoucherReady,
    });

    handleRemoveReferralVoucher = () => {
      appDataFunction.updateData({ ...this.firebaseFunctionCommonParams(), updatedValue: false });
      this.props.referralDataActions.deleteRefereeMetadata();
      this.props.referralDataActions.disableFetchReferralVoucher();
    };

    render() {
      const { ...rest } = this.props;
      const { referralVoucherCode, isLoading } = this.state;
      if (isLoading) return <Loading />;
      return (
        <WrappedComponent
          {...rest}
          referralVoucherCode={referralVoucherCode}
          onRemoveReferralVoucher={this.handleRemoveReferralVoucher}
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithReferralVoucher);
};
