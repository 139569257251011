import React from 'react';

import { theme, BodyPrimaryText } from '../../components/core';
import { isEmailLike as isValidEmail } from '../../utils/credentials';
import { isWeb } from '../../utils/platform';
import { NativeError } from '../../utils/types';
import EmailAddress from '../../components/auth/EmailAddress';
import { Layout } from '../../components/auth/Layout';

type RequestPasswordResetErrorKey = 'Failed' | 'EmailAddressInvalid' | 'ConnectionFailed';
type RequestPasswordResetErrorType = Record<string, RequestPasswordResetErrorKey>;

type Props = {
  initialEmailAddress: string;
  onConfirm: (arg0: { emailAddress: string }) => Promise<any>;
  centered?: boolean;
};
type State = {
  emailAddress: string;
  state: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';
  errorKey: RequestPasswordResetErrorKey | null | undefined;
};

class RequestPasswordReset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailAddress: isWeb ? '' : props.initialEmailAddress,
      state: 'INITIAL',
      errorKey: null,
    };
  }

  handleConfirm = () => {
    const { emailAddress } = this.state;

    if (!isValidEmail(emailAddress)) {
      return;
    }

    this.setState({
      state: 'LOADING',
    });
    this.props
      .onConfirm({
        emailAddress,
      })
      .then(() =>
        this.setState({
          state: 'SUCCESS',
          errorKey: null,
        }),
      )
      .catch((nativeError: NativeError) => {
        const error: RequestPasswordResetErrorType = nativeError.userInfo
          ? nativeError.userInfo
          : {
              name: nativeError.code,
            };
        this.setState({
          state: 'ERROR',
          errorKey: error.name,
        });
      });
  };

  handleChange = (emailAddress: string) =>
    this.setState({
      emailAddress,
    });

  renderMessage() {
    const { state } = this.state;

    if (state === 'SUCCESS') {
      return (
        <BodyPrimaryText
          id="account.request-password-reset.success"
          testId="account.request-password-reset.success"
          style={{
            color: theme.color.primary,
          }}
        />
      );
    }

    if (state === 'ERROR' && this.state.errorKey !== 'ConnectionFailed') {
      const id = this.state.errorKey === 'EmailAddressInvalid' ? 'email-address-invalid' : 'error';
      return (
        <BodyPrimaryText
          id={`account.request-password-reset.${id}`}
          style={{
            color: theme.color.errorTextLight,
          }}
        />
      );
    }

    return null;
  }

  render() {
    const errorKey = undefined;
    const { emailAddress, state } = this.state;
    const { centered } = this.props;
    const disabled = !isValidEmail(emailAddress) || state === 'SUCCESS';
    return (
      <Layout
        titleKey="account.request-password-reset.title"
        leadKey="account.request-password-reset.lead"
        confirmKey="account.request-password-reset.confirm"
        errorKey={this.state.errorKey === 'ConnectionFailed' ? 'ConnectionFailed' : null}
        disabled={disabled}
        imageName={null}
        loading={state === 'LOADING'}
        onConfirm={this.handleConfirm}
        centered={centered}
        footerType="default"
      >
        <EmailAddress
          autoFocus={false}
          errorKey={errorKey != null ? errorKey : undefined}
          value={emailAddress}
          onChange={this.handleChange}
          testId="account.request-password-reset.email"
        />
        {this.renderMessage()}
      </Layout>
    );
  }
}

export default RequestPasswordReset;
