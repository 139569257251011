import React from 'react';
import { useSelector } from 'react-redux';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { LoginButton } from '../../../containers/login-button';
import {
  ItalyOnboardingContainer,
  ToastBannerConfig,
} from '../../../screens/containers/italy-onboarding';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';
import { selectors as userAuthSelectors } from '../../../stores/modules/user-auth';
import { ScreenName } from '../../../types/screen-name';

type ActionParams = {
  toastConfig?: ToastBannerConfig;
};

type Props = Navigation<
  'Onboarding',
  {
    Onboarding: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate(
  ScreenName.Onboarding,
  AnalyticsEvent.sectionOnboarding,
);

export const ItalyOnboarding = ({ route }: Props): JSX.Element => {
  const { toastConfig } = route.params;
  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);

  const headerLeft = () =>
    isAuthenticated ? undefined : <LoginButton translationId="login.title" />;

  useDefaultNavigationOptions(undefined, {
    headerLeft,
  });

  return <ItalyOnboardingContainer toastConfig={toastConfig} />;
};
