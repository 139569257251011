/* global __DEV__ */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
// @flow
import { Text, LogBox } from 'react-native';

import { startPerformanceTrace } from 'src/services/firebase-performance';
import { PerformanceLabels } from 'src/services/firebase-performance.labels';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import { createLogger } from './src/utils/logger';

if (__DEV__) {
  require('./src/setupConfigEnvs');
}

// each country needs to implement the relevant trace stop
// using stopPerformanceTraceWithLabel(PerformanceLabels.appLaunch,{taxCountry,isAuthenticated})
startPerformanceTrace(PerformanceLabels.appLaunch);

createLogger();

logger.log('Initialising bundle');

// eslint-disable-next-line
logger.log(`Hermes enabled: ${!!global.HermesInternal}`);

// TODO: Disables font scaling globally for all ReactNative Text
//       components. This is a stop-gap measure until we fix
//       the app for all users.
// @ts-ignore
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

LogBox.ignoreAllLogs(true);
console.reportErrorsAsExceptions = false;

require('./src/app');
