import RNDocumentPicker, { Extension, MimeType, UTI } from 'react-native-document-picker';

export type FilePicked = {
  uri: string;
  type: string;
  size: number;
  name: string;
};

const FileSizeError = new Error('File size exceeded.');
const FileTypeError = new Error('File type is not matching.');
const ProtectedPDFError = new Error('PDF is password protected');

const checkSize = (size: number, sizeLimit: number) => {
  if (size > sizeLimit) throw FileSizeError;
};

const checkType = (fileType: string, type: string) => {
  if (type === RNDocumentPicker.types.pdf) {
    if (fileType !== 'application/pdf') throw FileTypeError;
  }
};

async function pickFile(fileType: Extension | MimeType | UTI, fileSizeLimit?: number) {
  try {
    const file = await RNDocumentPicker.pick({
      type: [fileType] as MimeType[],
    });
    checkType(file.type, fileType as string);
    if (typeof fileSizeLimit === 'number') {
      checkSize(file.size, fileSizeLimit);
    }
    const decodedUri = decodeURI(file.uri);
    return { ...file, uri: decodedUri };
  } catch (error) {
    if (!RNDocumentPicker.isCancel(error)) throw error;
    return null;
  }
}

async function pickFiles(fileTypes: MimeType[], fileSizeLimit?: number): Promise<FilePicked[]> {
  try {
    const files: FilePicked[] = await RNDocumentPicker.pickMultiple({
      type: fileTypes,
    });
    return files.map((file) => {
      if (typeof fileSizeLimit === 'number') {
        checkSize(file.size, fileSizeLimit);
      }
      const decodedUri = decodeURI(file.uri);
      return { ...file, uri: decodedUri };
    });
  } catch (error) {
    if (!RNDocumentPicker.isCancel(error)) throw error;
    return [];
  }
}

export { FileSizeError, FileTypeError, ProtectedPDFError, pickFile, pickFiles };
