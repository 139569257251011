import React from 'react';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useItalyIntl, useQuizmasterLight } from 'src/_italy/_hooks';
import { flagsQuestionIds, ProductBundleValues } from 'src/common/constants-it';

import { SpidTimeoutScreen } from './spid-timeout-screen';

export const toSpidTimeout: Action = navigate(ScreenName.SpidTimeout, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.SpidTimeout,
  },
});

export const SpidTimeout = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { getNavigationActions, navigation, navigationActions, safeResetNavigation } =
    useNavigation();

  const quizmasterLight = useQuizmasterLight([
    flagsQuestionIds.SPIDTimeoutFlow,
    flagsQuestionIds.productBundleSelection,
  ]);

  const { saveAnswer: saveAnswerSPIDTimeoutFlow } =
    quizmasterLight[flagsQuestionIds.SPIDTimeoutFlow];
  const { saveAnswer: saveAnswerProductBundle } =
    quizmasterLight[flagsQuestionIds.productBundleSelection];

  const onBack = () =>
    navigation.canGoBack()
      ? navigationActions.back()
      : safeResetNavigation([getNavigationActions().toDashboard('screen')]);

  const handleOnContinuePress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SpidTimeout,
      buttonName: 'continueWithManualFlow',
    });
    saveAnswerSPIDTimeoutFlow(true);
    saveAnswerProductBundle(ProductBundleValues.guided);
    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toSpidTimeoutNextSteps('screen'),
    ]);
  };

  const handleTryLaterButtonPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SpidTimeout,
      buttonName: 'tryAgainLater',
    });
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  useDefaultNavigationOptions(
    { id: 'it.spid-timeout-screen.nav.title' },
    {
      headerBackTitleVisible: false,
    },
  );

  return (
    <SpidTimeoutScreen
      getTranslationText={getTranslationText}
      onBackButtonPress={onBack}
      onPressPrimary={handleOnContinuePress}
      onPressSecondary={handleTryLaterButtonPress}
    />
  );
};
