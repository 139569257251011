import { IntlShape } from 'react-intl';

// Meant to (globally) expose intl object for usage outside React components context.
export class GlobalIntl {
  static instance: IntlShape | null = null;

  static initialise(instance: IntlShape) {
    GlobalIntl.instance = instance;
  }

  static getIntlInstance(): IntlShape {
    return GlobalIntl.instance as IntlShape;
  }
}
