import React from 'react';
import { ITextProps, Button as NBButton } from 'native-base';

import { mediumStyle } from '../Text/styles';

import { ButtonProps, VerticalPaddingSizes } from './types';

const getVerticalPaddings = (verticalPaddingSize: VerticalPaddingSizes) => {
  switch (verticalPaddingSize) {
    case 's':
      return { py: 0 };
    case 'm':
      return { py: 1 };
    case 'l':
      return { py: 2 };
  }
};

export const getTextStyles = (style: ITextProps = {}): ITextProps => ({
  fontFamily: 'brand',
  fontSize: 'xs',
  lineHeight: 'medium',
  ...mediumStyle,
  ...style,
});

export const Button: React.FC<ButtonProps> = ({
  variant,
  title,
  width = 'xl',
  verticalPaddingSize = 'm',
  ...rest
}) => {
  return (
    <NBButton
      variant={variant}
      width={width}
      {...getVerticalPaddings(verticalPaddingSize)}
      px={0}
      _text={getTextStyles()}
      {...rest}
    >
      {title}
    </NBButton>
  );
};

export { ButtonComponentVariants } from './styles';
