import React from 'react';
import curry from 'lodash/curry';
import { StyleSheet, ScrollView } from 'react-native';
import { Set } from 'react-powerplug';
import countries from 'i18n-iso-countries';
import { useIntl } from 'react-intl';
import { CountryCodes } from '@taxfix/types';

import { LanguageCode } from 'src/types/index';
import { isWeb } from 'src/utils/platform';
import {
  AddressDetailsFieldWithValue,
  AddressDetailsKeys,
  AddressDetailsValue,
} from 'src/types/address';

import FormField from '../../components/form-field-extended';
import { Box, theme, TextInput } from '../../components/core';
import SelectWithOverlay from '../../containers/input/select-with-overlay';
import {
  ValidationErrors,
  ErrorKeyword,
  ErrorKeywords,
} from '../../components/answers/shared/validators/types';
import { IT_POSTAL_CODE_LENGTH, provinceList } from '../../common/constants-it';

export const styles = StyleSheet.create({
  borderBottom: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.color.border,
  },
  borderRight: {
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: theme.color.border,
  },
  fieldBox: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

type CustomStyles = Record<string, any>;

const getStyle = (customStyles: CustomStyles = {}, element: keyof typeof styles) =>
  StyleSheet.compose(styles[element], customStyles[element]);

type Props = {
  // NOTE: address is `any` because the format depends on fields
  onChange: (address: any) => void;
  errors?: ValidationErrors;
  fields: AddressDetailsFieldWithValue[];
  language: string;
  customStyles?: CustomStyles;
  countryCode?: CountryCodes;
};

const orEmpty = (str: string | null | undefined) => str || '';

export const getErrorTranslationKey = (
  errorKey: ErrorKeyword,
  addressFieldKey: AddressDetailsValue,
) => {
  switch (errorKey) {
    case ErrorKeywords.REQUIRED:
    case ErrorKeywords.MIN_LENGTH:
      return 'answers.address.detail.error.minLength';

    case ErrorKeywords.MAX_LENGTH:
      return 'answers.address.detail.error.maxLength';

    case ErrorKeywords.PATTERN:
      return addressFieldKey === AddressDetailsKeys.Number
        ? 'answers.address.detail.error.pattern'
        : 'answers.confirmable-input.invalid.text.unsupported-characters-short';

    case ErrorKeywords.DIGITS_ONLY:
      return 'answers.address.detail.error.only-digits';

    case ErrorKeywords.NOT_VALID_CHARS:
      return 'answers.address.detail.error.not-valid-digits';

    default:
      return 'answers.address.detail.error.type';
  }
};

function DetailAddressView({
  onChange,
  errors = {},
  fields,
  language = 'en',
  customStyles,
  countryCode,
}: Props): JSX.Element {
  const intl = useIntl();
  const countryList = React.useMemo(() => {
    const formattedLanguage = language === LanguageCode.EN_GB ? LanguageCode.EN : language;
    return Object.entries(countries.getNames(formattedLanguage)).map(([key, value]) => {
      return {
        value: key,
        label: value,
      };
    });
  }, [language]);
  const stateList = React.useMemo(() => {
    switch (countryCode) {
      case CountryCodes.IT:
        return provinceList.map((province) => ({
          value: province.code,
          label: intl.formatMessage({
            id: province.translationKey,
          }),
        }));

      default:
        return [];
    }
  }, [countryCode, intl]);
  const initValues = fields.reduce((acc: any, field) => {
    acc[field.id] = field.value;
    return acc;
  }, {});
  const customErrors = fields.reduce((acc: any, field) => {
    acc[field.id] = field.errors || {};
    return acc;
  }, {});
  return (
    <Set onChange={onChange} initial={initValues}>
      {({ set, get }: any) => {
        const EditableField = (
          fieldKey: AddressDetailsValue,
          translationKey: string,
          editable: boolean,
        ) => {
          const error = errors[fieldKey]
            ? {
                key:
                  customErrors[fieldKey][errors[fieldKey].errorKey] ||
                  getErrorTranslationKey(errors[fieldKey].errorKey, fieldKey),
                values: {
                  number: errors[fieldKey].limit,
                  chars: errors[fieldKey].chars,
                },
                level: 'Error',
              }
            : undefined;

          switch (fieldKey) {
            case AddressDetailsKeys.Country:
              return (
                <FormField
                  label={{
                    key: translationKey,
                  }}
                  // @ts-ignore
                  error={error}
                >
                  {() => (
                    <SelectWithOverlay
                      value={orEmpty(get(fieldKey))}
                      // @ts-ignore
                      onChange={curry(set)(fieldKey)}
                      options={countryList}
                      disabled={!editable}
                    />
                  )}
                </FormField>
              );

            case AddressDetailsKeys.State:
              return (
                <FormField
                  label={{
                    key: translationKey,
                  }}
                  error={error}
                >
                  {() => (
                    <SelectWithOverlay
                      value={orEmpty(get(fieldKey))}
                      // @ts-ignore
                      onChange={curry(set)(fieldKey)}
                      options={stateList}
                      disabled={!editable}
                      testId={translationKey}
                      isSelectFullScreenHeight={isWeb}
                    />
                  )}
                </FormField>
              );

            default: {
              // Autofocus on the first field
              const autoFocus = fieldKey === fields[0].id;
              const maxLength =
                fieldKey === AddressDetailsKeys.PostalCode && countryCode === CountryCodes.IT
                  ? IT_POSTAL_CODE_LENGTH
                  : 125;
              return (
                <FormField
                  label={{
                    key: translationKey,
                  }}
                  error={error}
                >
                  {({ onBlur, onFocus }) => (
                    <TextInput
                      value={orEmpty(get(fieldKey))}
                      onChangeText={curry(set)(fieldKey)}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      maxLength={maxLength}
                      style={
                        !editable && {
                          color: theme.color.secondaryText,
                        }
                      }
                      editable={editable}
                      testId={translationKey}
                      autoFocus={autoFocus}
                    />
                  )}
                </FormField>
              );
            }
          }
        };

        return (
          <ScrollView keyboardShouldPersistTaps="handled">
            <Box top={2} bottom={2}>
              {fields.map(({ id, editable, translationKey }) => (
                <Box key={id} flex={1} style={getStyle(customStyles, 'fieldBox')} bottom={2}>
                  {EditableField(id, translationKey, editable)}
                </Box>
              ))}
            </Box>
          </ScrollView>
        );
      }}
    </Set>
  );
}

export default DetailAddressView;
