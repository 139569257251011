import { useRef } from 'react';
import { Platform, StatusBar, Appearance } from 'react-native';
import { NavigationState, Route } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { getCurrentRoute } from './navigation-common-hook.utils';
// using strategy described
// in https://medium.com/simform-engineering/react-native-statusbar-theme-based-on-screen-cad263e6c8bc
export const useNavigationStatusBar = () => {
  const countryCode = useSelector(settingsSelectors.selectedCountry);

  const prevState = useRef<Route<any, any> | Record<any, any>>();
  return (currentState?: NavigationState) => {
    const currentScreen = getCurrentRoute(currentState);
    const prevScreenName = prevState.current?.name;
    const currentScreenName = currentScreen.name;

    if (prevScreenName !== currentScreenName) {
      const { params } = currentScreen;

      if (countryCode === CountryCodes.ES) {
        StatusBar.setBackgroundColor('rgba(0, 98, 70, 0.95)');
        StatusBar.setBarStyle(Platform.OS === 'ios' ? 'dark-content' : 'light-content');
      } else {
        if (params?.statusBar) {
          StatusBar.setBarStyle(params.statusBar);
        } else {
          StatusBar.setBarStyle(
            Appearance.getColorScheme() === 'dark' ? 'light-content' : 'dark-content',
          );
        }

        if (params?.statusBarBackgroundColor) {
          StatusBar.setBackgroundColor(params.statusBarBackgroundColor);
        }
      }

      prevState.current = currentScreen;
    }
  };
};
