import * as React from 'react';

import { Container } from '../../layout/Container';

import { StyledCardProps } from './types';

export const StyledCard: React.FC<StyledCardProps> = ({ children, variant, ...rest }) => {
  return (
    <Container borderRadius={1} shadow={variant} {...rest}>
      {children}
    </Container>
  );
};
