// This relies on ./data.json JSON extracted from `cldr-*`
// packages installed within the repo
//
// This is generated by `scripts/extract-locale-info`
import Provider from './Provider';
import injectLocaleInfo from './injectLocaleInfo';
import { localeInfoShape } from './types';

export type { LocaleInfo } from './types';
export { localeInfoShape, injectLocaleInfo, Provider as default };
