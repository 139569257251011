import React from 'react';
import { findNodeHandle, ScrollView } from 'react-native';

export default function scrollToElement(
  baseComponent:
    | (React.Component<any, any> & {
        scrollViewRef: ScrollView | null | undefined;
      })
    | any,
  element: React.Component<any, any>,
  offset = 100,
) {
  setTimeout(() => {
    if (baseComponent && baseComponent.scrollViewRef) {
      const scrollResponder = (baseComponent.scrollViewRef as any).getScrollResponder();

      if (scrollResponder) {
        scrollResponder.scrollResponderScrollNativeHandleToKeyboard(
          findNodeHandle(element),
          offset, // additionalOffset
          true,
        );
      }
    }
  }, 50);
}
