import * as React from 'react';
import Config from 'react-native-config';
import { User } from '@taxfix/taxfix-sdk';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { SignupResponse } from '@taxfix/taxfix-sdk/dist/user/signup';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { UserSelectors } from 'src/stores/modules/user';
import { actions as userAuthActions, Provider } from 'src/stores/modules/user-auth';
import { syncUploadAnswersForSelectedCountry } from 'src/services/sync-answers';
import { getUniqueDeviceID } from 'src/services/device';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { getAppLanguage } from 'src/lang';
import { State as RootState } from 'src/stores/store/initial';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

import { docs } from '../../../assets/docs';
import { getDefaultTaxYear } from '../../services/country-and-year';

type UseHandleSignup = {
  handleConfirmFailure: (error: unknown) => void;
  handleConfirmSuccess: (accessToken: string) => Promise<void>;
};

export type SignUpFormPayload = {
  email: string;
  pwd: string;
  way: Record<string, unknown>;
  tac: string;
  privacy: string;
  name?: string;
  acceptedPromotionalEmails?: boolean;
};

export type HandleSignupType = {
  onSuccess: ((accessToken?: string) => void) | UseHandleSignup['handleConfirmSuccess'];
  onError: UseHandleSignup['handleConfirmFailure'];
};

export type UseRegisterProps = {};

export type UseRegister = {
  handleSignup: (arg0: HandleSignupType) => Promise<void>;
};

const mapStateToProps = (state: RootState) => ({
  email:
    UserSelectors.getOnboardingEmail(state) ||
    settingsSelectors.selectCurrentCountrySpecifics(state)?.emailForNotifications,
  name: UserSelectors.getOnboardingName(state),
  personalData: UserSelectors.getUserPersonal(state),
  pwd: UserSelectors.getOnboardingPin(state),
  idToken: UserSelectors.getOnboardingIdToken(state),
  acceptedPromotionalEmails: UserSelectors.getOnboardingPromotionalEmails(state),
  year: settingsSelectors.selectedYear(state) || getDefaultTaxYear(),
});

class SignUpError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SignupError'; // "Signup" is the screen name from Routes.Signup
  }
}

export const useRegister = (): UseRegister => {
  const dispatch = useDispatch();
  const deviceId = React.useRef('');
  const { year, email, personalData, pwd, acceptedPromotionalEmails } =
    useSelector(mapStateToProps);

  const tac = docs().terms.IT?.version || '';
  const privacy = docs().privacy.IT?.version || '';

  const actions = React.useMemo(
    () =>
      bindActionCreators(
        {
          updateUserFromResponse: userAuthActions.updateUserFromResponse,
          updateProvider: userAuthActions.updateProvider,
        },
        dispatch,
      ),
    [dispatch],
  );

  React.useEffect(() => {
    getUniqueDeviceID().then((id) => {
      deviceId.current = id;
    });
  }, []);

  const callSignup = async (
    { onSuccess, onError }: HandleSignupType,
    signupHandler: (locale: string) => Promise<SignupResponse>,
    provider: Provider,
  ) => {
    const locale = getAppLanguage(CountryCodes.IT);

    try {
      const registerResponse = await signupHandler(locale);

      actions.updateProvider(provider);
      actions.updateUserFromResponse(registerResponse, email, registerResponse.accessToken);

      await Analytics.identifyRegisteredUser(
        { userId: registerResponse.id, accessToken: registerResponse.accessToken },
        {
          email,
        },
      );

      // added await because this function can throw which will lead to an
      // unhandled rejection error if await is missing
      await syncUploadAnswersForSelectedCountry();

      Analytics.log(AnalyticsEvent.signUpSuccess, {
        userId: registerResponse.id,
        identityProvider: provider,
      });

      onSuccess(registerResponse.accessToken);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'ConnectionFailed';

      Analytics.log(AnalyticsEvent.signUpFailed, {
        errorMessage,
        year,
        identityProvider: provider,
      });

      onError(error);
      logger.warn(new SignUpError(`Signup failed with the message: ${errorMessage}`), {
        provider,
        error,
      });

      if (!error.response && !error.request) {
        logger.error(new SignUpError(`Signup with failed in the useRegister hook`), {
          provider,
          error,
        });
      }
    }
  };

  const handleSignup = async (handlers: HandleSignupType) => {
    if (!pwd || !email) {
      throw new SignUpError('Pin code or email is missing for the useRegister hook');
    }

    /*
    Italy asks for the marketing email consent only in signup last screen
    and not selecting it makes the acceptedPromotionalEmails property go as undefined
    and the register iam-api implementation checks for null and not undefined. Because of this
    the marketing emails toggle shows true in privacy settings even though user does not select the checkbox.
    Handling this as explicit boolean conversion for italy here as a fix.
    How does germany handle ? Germany has an extra screen to get this consent after registration
    and notification screen and hence an explicit true/false is set over there.
    */
    const acceptedPromotionalEmailsSanitized = Boolean(acceptedPromotionalEmails);

    const signupParams = {
      // Only use first name for signup to keep db consistent.
      name: personalData.firstName,
      email,
      pwd,
      privacy,
      tac,
      way: {} as any, // due to the type any[] used in taxfix-sdk it doesn't accept the Responses type
      isWeb: false,
      acceptedPromotionalEmails: acceptedPromotionalEmailsSanitized,
      countryCode: CountryCodes.IT,
      deviceId: deviceId.current,
    };
    const signupHandler = (locale: string) =>
      User.signup(Config.API_BASE_URL, signupParams, locale);
    await callSignup(handlers, signupHandler, Provider.email);
  };

  return { handleSignup };
};
