import { Pressable, useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { icons } from '../../../Icons/Icons';
import { Card } from '../../general/Card';
import { Icon } from '../../general/Icon';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { Props } from './types';

export const ExpertTipCard: React.FC<Props> = ({
  headerText,
  subHeaderText,
  handleOnPress,
  ...rest
}) => {
  const { colors } = useTheme();
  const cardWidth = useBreakpointValue({ base: 'auto', md: '280px' });

  return (
    <Card
      p={2}
      variant="sticky1"
      width={cardWidth}
      backgroundColor={colors.greytones.greyBlue}
      {...rest}
    >
      <Pressable onPress={handleOnPress}>
        <Text variant="titleXSMedium" value={headerText} />

        <Container mt={1} justifyContent="space-between" alignItems="center" flexDirection="row">
          <Text
            variant="titleXSMedium"
            value={subHeaderText}
            color={colors.greytones.graphicsDark}
          />
          <Icon path={icons.chevronRight} size={3} color={colors.greytones.textDefault} />
        </Container>
      </Pressable>
    </Card>
  );
};
