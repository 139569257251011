import React, { useCallback, useEffect } from 'react';

import { Transition, useToastBannerToggler } from 'src/components/toast-banner';
import { Toast } from 'src/components/toast';

import {
  Box,
  BigDisplayTitleText,
  BodySecondaryText,
  Section,
  Button,
  theme,
} from '../../components/core';
import { isMobile, isNative } from '../../utils/platform';
import { useNavigation } from '../../hooks/navigation-hook';
import { ToastBannerConfig } from '../containers/italy-onboarding';

const isMobileScreen = isMobile || isNative;

type Props = {
  toastConfig: ToastBannerConfig;
};
export const LogoutSuccess = ({ toastConfig }: Props): JSX.Element => {
  const { navigationActions } = useNavigation();
  const { showBanner } = useToastBannerToggler();

  const handleButtonClick = useCallback(() => {
    navigationActions.toLogin('screen');
  }, [navigationActions]);
  const padding = isNative ? 2 : 0;

  const showToastBanner = useCallback((toastConfig: ToastBannerConfig) => {
    showBanner({
      contentView: (
        <Box top={2} left={2} right={2}>
          <Toast
            titleId={toastConfig.titleId}
            iconName={toastConfig.iconName}
            tintColor={toastConfig.tintColor}
            iconColor={toastConfig.iconColor}
            bgColor={toastConfig.bgColor}
          />
        </Box>
      ),
      transitions: [Transition.FadeInOut],
      disableHideOnPress: true,
    });
  }, []);

  useEffect(() => {
    if (toastConfig) {
      showToastBanner(toastConfig);
    }
  }, [toastConfig, showToastBanner]);

  return (
    <Section size="narrow" centered>
      <Box flex top={5} left={padding} right={padding} alignVertically="center">
        <Box
          bottom={4}
          alignHorizontally="center"
          style={{
            borderBottomWidth: 1,
            borderBottomColor: theme.color.fill,
          }}
        >
          {/* @ts-ignore */}
          <BigDisplayTitleText id="logout-success.title" values="userName" />
        </Box>
        <Box
          top={4}
          bottom={4}
          alignHorizontally="center"
          direction="row"
          alignVertically="center"
          style={{
            justifyContent: 'space-around',
          }}
        >
          <BodySecondaryText
            id="logout-success.subtitle"
            style={{
              paddingRight: 10,
            }}
          />
          <Button
            onPress={handleButtonClick}
            style={
              !isMobileScreen && {
                width: '40%',
              }
            }
            translationKey="logout-success.button"
            testId="logOutSuccess.mainaction"
          />
        </Box>
      </Box>
    </Section>
  );
};
