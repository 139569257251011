import React, { useState } from 'react';

import { Button, Container } from 'src/taxfix-components/src';
import { CheckboxWithText } from 'src/components/checkbox/checkbox-with-text';
import { SmallSecondaryText } from 'src/components/core';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

type Props = {
  onSign: () => void;
};

const Footer: React.FC<Props> = ({ onSign }) => {
  const [isSignEnabled, setIsSignEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getTranslationText } = useItalyIntl();

  const onPress = async () => {
    setIsLoading(true);
    await onSign();
    setIsLoading(false);
  };

  return (
    <Container
      flexDirection={{ base: 'column', md: 'row' }}
      width="100%"
      justifyContent={{ base: 'center', md: 'space-around' }}
      alignItems="center"
      borderColor="greytones.graphicsLight"
      borderTopWidth="xs"
      p={0}
    >
      <CheckboxWithText
        isChecked={isSignEnabled}
        onChange={() => setIsSignEnabled(!isSignEnabled)}
        testId="self-sign-document.screen.confirmation.checkbox"
      >
        <SmallSecondaryText id={getTranslationText('it.self-sign-document.checkbox.text')} />
      </CheckboxWithText>
      <Button
        width={{ base: 'xl', md: '1/4' }}
        title={getTranslationText('it.self-sign-document.button.title')}
        onPress={onPress}
        variant="primary"
        disabled={!isSignEnabled}
        isLoading={isLoading}
        m={0}
      />
    </Container>
  );
};

export { Footer };
