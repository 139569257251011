import * as React from 'react';
import isNil from 'lodash/isNil';
import { ScrollView, StyleSheet } from 'react-native';
import { Pressable } from 'native-base';

import { Container } from 'src/taxfix-components/src';
import { TextButton, Box } from 'src/components/core';
import { testID } from 'src/common/testID';

import { DropdownMenuInputsProps, Option } from './types';

const styles = StyleSheet.create({
  containerHeight: {
    maxHeight: 270,
  },
  buttonContentWrapper: {
    marginHorizontal: 16,
  },
  selectedStyle: {
    backgroundColor: '#E1F8E6',
    color: ' #32C850',
  },
});

export const getIsSelected = (option: Option, selected?: Option): boolean => {
  let isSelected = false;

  if (!isNil(selected)) {
    if (selected === option) {
      isSelected = true;
    } else if (option.id != null && selected === option.id) {
      isSelected = true;
    } else if (option.value != null && selected === option.value) {
      isSelected = true;
    }
  }

  return isSelected;
};

const renderOptions = (
  options: Option[] = [],
  handleOptionPress: (option: Option) => void,
  selectedValue?: Option,
): JSX.Element => {
  const align = options.length === 1 ? 'center' : 'left';
  return (
    <Container>
      {options.map((option, index) => {
        const isSelected = getIsSelected(option, selectedValue);
        const textButtonStyle = isSelected ? styles.selectedStyle : null;
        const textStyle = isSelected ? styles.selectedStyle.color : '#707070';

        return (
          <Box
            flex
            key={index}
            style={[textButtonStyle, { minHeight: 48, justifyContent: 'center' }]}
          >
            <Pressable flex={1} _hover={{ backgroundColor: 'greytones.background' }}>
              <Box
                flex
                direction="row"
                alignVertically="center"
                style={styles.buttonContentWrapper}
              >
                <Box flex>
                  <TextButton
                    key={option.id || option.translationKey}
                    values={option.values || {}}
                    align={align}
                    translationKey={option.translationKey || undefined}
                    textColor={textStyle}
                    onPress={() => handleOptionPress(option)}
                    testId={`answer::${option.label || option.id || ''}`}
                    disabled={option.disabled}
                  >
                    {option.id == null ? option.label : undefined}
                  </TextButton>
                </Box>
              </Box>
            </Pressable>
          </Box>
        );
      })}
    </Container>
  );
};

const DropdownMenuInputs = ({
  options,
  onAnswer,
  selectedValue,
}: DropdownMenuInputsProps): JSX.Element => {
  const handleOptionPress = (option: Option) => {
    const { id, value = '' } = option;
    const answer = id ?? value;
    onAnswer(answer);
  };

  return (
    <ScrollView
      nestedScrollEnabled
      style={styles.containerHeight}
      {...testID('test.scroll.single')}
    >
      {renderOptions(options, handleOptionPress, selectedValue)}
    </ScrollView>
  );
};

export { DropdownMenuInputs };
