import { Pressable, useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

import { isNative } from '../../../../../utils/platform';

import { PrimaryArticleProps } from './types';

/**
 * element representing primary links to the blog articles
 */

export const PrimaryArticle: React.FC<PrimaryArticleProps> = ({
  title,
  image,
  bgColor,
  onPress,
  ...otherProps
}): JSX.Element => {
  // otherwise the app crashes on Native devices
  const zeroPixels = isNative ? 0 : '0px';

  const containerMaxWidth = useBreakpointValue({ base: '326px', md: '221px', lg: '280px' });
  const containerHeight = useBreakpointValue({ base: '120px', md: '200px' });
  const containerMarginTop = useBreakpointValue({ base: '24px', md: '32px' });
  const containerElevation = {
    shadow: 'elevation2',
  };
  const flexDir = useBreakpointValue({ base: 'row', md: 'column' });

  const titleFont = useBreakpointValue({ base: 'titleXSMedium', md: 'subtitleMMedium' });
  const titlePaddingX = useBreakpointValue({ base: '12px', md: '14.7px', lg: '18.6px' });
  const titleMaxW = useBreakpointValue({ base: '193px', md: '100%' });
  const titleMarginTop = useBreakpointValue({ base: '12px', md: '20px' });

  const imageContainerMaxWidth = useBreakpointValue({ base: '104px', md: '280px' });
  const imageHeight = useBreakpointValue({ base: '120px', md: '106px' });
  const imageBottomAlignment = useBreakpointValue({
    base: zeroPixels,
    md: '-11px',
    lg: zeroPixels,
  });

  return (
    <Pressable
      flexDirection={flexDir}
      justifyContent="space-between"
      maxWidth={containerMaxWidth}
      height={containerHeight}
      marginTop={containerMarginTop}
      onPress={onPress}
      backgroundColor={bgColor}
      borderRadius={1}
      overflow="hidden"
      _hover={containerElevation}
      {...otherProps}
    >
      <Text
        variant={titleFont}
        value={title}
        paddingX={titlePaddingX}
        maxW={titleMaxW}
        marginTop={titleMarginTop}
      />
      <Container
        position="relative"
        maxWidth={imageContainerMaxWidth}
        width="100%"
        height={imageHeight}
      >
        <Image
          position="absolute"
          width="100%"
          height={imageHeight}
          name={image}
          resizeMode="contain"
          bottom={imageBottomAlignment}
          right={zeroPixels}
        />
      </Container>
    </Pressable>
  );
};
