import { createSelector } from 'reselect';

import { Configs } from '../../utils/remote-config-helpers';
import { State as Settings } from '../modules/settings';

export type State = {
  configs: Configs | null | undefined;
  dirty: boolean;
};
export type RootState = {
  remoteConfigFirebase: State;
  settings: Settings;
};

const getRemoteConfig = (state: RootState): State => state.remoteConfigFirebase;

const getBrazeWhitelist: (state: RootState) => Array<string> = createSelector(
  getRemoteConfig,
  ({ configs }: any) => configs?.brazeWhitelist,
);
export { getBrazeWhitelist };
