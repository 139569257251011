import React from 'react';

import { isWeb } from 'src/utils/platform';
import translationKeyForOption from 'src/components/answers/translationKeyForOption';

import { AnswerComponentProps } from '../../../components/answers/types';

import { Option } from './types';
import { SingleSelectComponent } from './component';

type Props = AnswerComponentProps & {
  options: Option[];
  onChange: (value: any) => void;
};

export const ItalySingleSelect = ({
  value,
  options,
  onAnswer,
  onChange,
  question,
}: Props): JSX.Element => {
  const handleOnAnswer = (optionId: string) => {
    onAnswer(question, optionId);
  };

  const [selectOptions, setSelectOptions] = React.useState<Option[]>(options);

  const getSortedOptions = () => {
    if (options && value) {
      const selectedOptionIndex = options.findIndex((option) => option.id === value);
      const selectedOption = options[selectedOptionIndex];
      if (selectedOptionIndex > 0) {
        let newOptions = [...options];
        newOptions.splice(selectedOptionIndex, 1);
        newOptions = [selectedOption, ...newOptions];
        return newOptions;
      }
    }
    return options;
  };

  React.useEffect(() => {
    const selectOptions = getSortedOptions().map((option) => ({
      ...option,
      translationKey: translationKeyForOption(option.translationKey || ''),
    }));

    setSelectOptions(selectOptions);
  }, []);

  return (
    <SingleSelectComponent
      isFullHeight={isWeb}
      selectedValue={value}
      options={selectOptions}
      onChange={onChange}
      onAnswer={handleOnAnswer}
    />
  );
};
