import { Dispatch } from 'redux';
import { createFilter } from 'redux-persist-transform-filter';
import { createSelector } from 'reselect';
import { CountryCodes } from '@taxfix/types';
import { Token } from '@taxfix/taxfix-sdk';
import Config from 'react-native-config';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import getNotificationsManager, { PermissionsAndDeviceToken } from 'src/utils/push-notifications';

import { FirebaseUserPropertyKeys } from '../../services/firebase-analytics';
import Analytics from '../../biz-logic/analytics';

import { generateFirebaseRandomNumber } from './settings-util';
import { selectors as AuthSelectors } from './user-auth';

export const SELECT_DEFAULT_COUNTRY = 'settings/SELECT_DEFAULT_COUNTRY';
export const SELECT_DEFAULT_COUNTRY_SUCCESS = 'settings/SELECT_DEFAULT_COUNTRY_SUCCESS';
export const SELECT_DEFAULT_COUNTRY_ERROR = 'settings/SELECT_DEFAULT_COUNTRY_ERROR';
export const SELECT_COUNTRY = 'settings/SELECT_COUNTRY';
export const NOTIFICATIONS_PERMISSION = 'settings/NOTIFICATIONS_PERMISSION';
export const NOTIFICATION_MODAL_STATUS = 'settings/NOTIFICATION_MODAL_STATUS';
export const SELECT_YEAR = 'settings/SELECT_YEAR';
export const UPDATE_EMAIL_FOR_NOTIFICATIONS = 'settings/UPDATE_EMAIL_FOR_NOTIFICATIONS';
export const UPDATE_CALCULATION_CONFIRMED = 'settings/UPDATE_CALCULATION_CONFIRMED';
export const SELECT_LOCALE = 'settings/SELECT_LOCALE';
export const UPDATE_COUNTRY_SPECIFICS_SETTINGS = 'settings/UPDATE_COUNTRY_SPECIFICS_SETTINGS';
export const UPDATE_COUNTRY_YEAR_SPECIFICS_SETTINGS =
  'settings/UPDATE_COUNTRY_YEAR_SPECIFICS_SETTINGS';
export const FORCE_RANDOM_NUMBER = 'settings/FORCE_RANDOM_NUMBER';
export const SET_ISQFVISITED = 'settings/SET_ISQFVISITED';
export const countrySpecificProps = {
  isCommercialistaAssigned: 'isCommercialistaAssigned',
  areQuizAnswersUpdated: 'areQuizAnswersUpdated',
};
type Year = number;
type Value = boolean | number | string;
export const SET_PUSH_TOKEN = 'settings/SET_PUSH_TOKEN';
export type CountrySpecifics = {
  emailForNotifications?: string;
  calculationConfirmed?: boolean;
  afterSubmissionFeedbackDisplayed?: Record<string, boolean>;
  afterSubmissionVoucherPopupDisplayed?: Record<string, boolean>;
  postRegistrationSavePhoneNumberDisplayed?: boolean;
  areQuizAnswersUpdated?: boolean;
  [key: string]: any;
};
export type CountryYearSpecifics = Partial<
  Record<CountryCodes, Record<Year, Record<string, Value>>>
>;
export type State = {
  data: {
    selectedCountry: CountryCodes | null | undefined;
    hasSelectedCountry: boolean;
    seenNotificationsModal?: boolean;
    askedForNotificationsPermissions?: boolean;
    selectedYear: number | null | undefined;
    countrySpecifics: Partial<Record<CountryCodes, CountrySpecifics>>;
    pushToken: string | null | undefined;
    countryYearSpecifics: CountryYearSpecifics;
    selectedLocale: string | undefined;
    firebaseUserProperties: Record<string, any>;
    isQFVisited: boolean;
  };
};
type SelectCountryAction = {
  type: typeof SELECT_COUNTRY;
  country: CountryCodes;
};
type SelectYear = {
  type: typeof SELECT_YEAR;
  year: number | null | undefined;
};
type AskedForNotificationPermissionsAction = {
  type: typeof NOTIFICATIONS_PERMISSION;
  asked: boolean;
};
type NotificationModalStatusAction = {
  type: typeof NOTIFICATION_MODAL_STATUS;
  status: boolean;
};
type UpdateEmailForNotifications = {
  type: typeof UPDATE_EMAIL_FOR_NOTIFICATIONS;
  email: string;
  countryCode: CountryCodes;
};
type UpdateCalculationConfirmed = {
  type: typeof UPDATE_CALCULATION_CONFIRMED;
  confirmed: boolean;
  countryCode: CountryCodes;
};
type UpdateCountrySpecifics = {
  type: typeof UPDATE_COUNTRY_SPECIFICS_SETTINGS;
  countryCode: CountryCodes;
  countrySpecifics: CountrySpecifics;
};
type UpdateCountryYearSpecifics = {
  type: typeof UPDATE_COUNTRY_YEAR_SPECIFICS_SETTINGS;
  countryCode: CountryCodes;
  year: Year;
  key: string;
  value: Value;
};
type SelectLocale = {
  type: typeof SELECT_LOCALE;
  locale: string;
};
type ForceRandomNumber = {
  type: typeof FORCE_RANDOM_NUMBER;
  randomNumber: number;
};
type SetPushToken = {
  type: typeof SET_PUSH_TOKEN;
  pushToken?: string | null;
};
type SetIsQFVisited = {
  type: typeof SET_ISQFVISITED;
  isQFVisited: boolean;
};
type Action =
  | SetPushToken
  | SelectCountryAction
  | SelectYear
  | AskedForNotificationPermissionsAction
  | NotificationModalStatusAction
  | UpdateEmailForNotifications
  | UpdateCalculationConfirmed
  | UpdateCountrySpecifics
  | UpdateCountryYearSpecifics
  | ForceRandomNumber
  | SelectLocale;

const selectYear = (year: number | null | undefined): SelectYear => {
  return {
    type: SELECT_YEAR,
    year,
  };
};

const selectCountry = (country: CountryCodes): SelectCountryAction => ({
  type: SELECT_COUNTRY,
  country,
});

const updateAskedForNotificationPermissions =
  (asked: boolean) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: NOTIFICATIONS_PERMISSION,
      asked,
    });
  };

const updateNotificationModalStatus =
  (status: boolean) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: NOTIFICATION_MODAL_STATUS,
      status,
    });
  };

const updateEmailForNotifications = (
  email: string,
  countryCode: CountryCodes,
): UpdateEmailForNotifications => {
  return {
    type: UPDATE_EMAIL_FOR_NOTIFICATIONS,
    email,
    countryCode,
  };
};

const updateCountrySpecific = (
  countryCode: CountryCodes,
  key: string,
  value: Value,
): UpdateCountrySpecifics => ({
  type: UPDATE_COUNTRY_SPECIFICS_SETTINGS,
  countryCode,
  countrySpecifics: {
    [key]: value,
  },
});

const updateCountryYearSpecific = (
  countryCode: CountryCodes,
  year: Year,
  key: string,
  value: Value,
): UpdateCountryYearSpecifics => ({
  type: UPDATE_COUNTRY_YEAR_SPECIFICS_SETTINGS,
  countryCode,
  year,
  key,
  value,
});

const updateCountrySpecifics = (
  countryCode: CountryCodes,
  countrySpecifics: CountrySpecifics,
): UpdateCountrySpecifics => ({
  type: UPDATE_COUNTRY_SPECIFICS_SETTINGS,
  countryCode,
  countrySpecifics,
});

const updateCalculationConfirmed = (
  confirmed: boolean,
  countryCode: CountryCodes,
): UpdateCalculationConfirmed => {
  return {
    type: UPDATE_CALCULATION_CONFIRMED,
    confirmed,
    countryCode,
  };
};

const selectLocale =
  (locale: string, triggerIdentify = true) =>
  async (dispatch: Dispatch<Action>, getState: () => any) => {
    dispatch({
      type: SELECT_LOCALE,
      locale,
    });
    const state = getState();
    const userId = AuthSelectors.getUserId(state);
    const accessToken = AuthSelectors.getAccessToken(state);

    if (triggerIdentify && userId) {
      await Analytics.identifyRegisteredUser({ userId, accessToken });
    }
  };

const setPushToken = (pushToken?: string | null): SetPushToken => {
  return {
    type: SET_PUSH_TOKEN,
    pushToken,
  };
};

// This selector is placed here because it is used in the requestPushToken action
const selectPushToken = (state: RootState): string | null | undefined =>
  state.settings.data.pushToken;

const requestPushToken =
  () =>
  (dispatch: Dispatch<Action>, getState: () => any): void => {
    const PushNotifications = getNotificationsManager();
    const accessToken = AuthSelectors.getAccessToken(getState());
    const pushToken = selectPushToken(getState());
    PushNotifications.requestPushTokenAndPermissions().then((result: PermissionsAndDeviceToken) => {
      if (result.token) {
        if (pushToken !== result.token) {
          Token.updatePushToken(Config.API_BASE_URL, accessToken, {
            pushToken: result.token,
          })
            .then(() => {
              dispatch(setPushToken(result.token));
            })
            .catch((err) => {
              logger.error(err, { message: '[requestPushToken]: Error requesting push token' });
            });
        }
      }
    });
  };

const forceRandomNumber = (randomNumber: number) => ({
  type: FORCE_RANDOM_NUMBER,
  randomNumber,
});

const setIsQFVisited = (isQFVisited: boolean): SetIsQFVisited => {
  return {
    type: SET_ISQFVISITED,
    isQFVisited,
  };
};

export const actions = {
  selectCountry,
  selectYear,
  updateAskedForNotificationPermissions,
  updateNotificationModalStatus,
  updateEmailForNotifications,
  updateCalculationConfirmed,
  selectLocale,
  updateCountrySpecific,
  updateCountrySpecifics,
  setPushToken,
  updateCountryYearSpecific,
  forceRandomNumber,
  requestPushToken,
  setIsQFVisited,
};

export const initial: State = {
  data: {
    selectedCountry: CountryCodes.IT,
    hasSelectedCountry: false,
    selectedYear: null,
    askedForNotificationsPermissions: false,
    seenNotificationsModal: false,
    countrySpecifics: {},
    countryYearSpecifics: {},
    selectedLocale: undefined,
    pushToken: null,
    firebaseUserProperties: {
      [FirebaseUserPropertyKeys.RandomNumber]: generateFirebaseRandomNumber(),
    },
    isQFVisited: false,
  },
};
export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type) {
    case SELECT_COUNTRY:
      return {
        ...state,
        data: { ...state.data, selectedCountry: action.country, hasSelectedCountry: true },
      };

    case SET_PUSH_TOKEN:
      return { ...state, data: { ...state.data, pushToken: action.pushToken } };

    case SELECT_YEAR:
      return { ...state, data: { ...state.data, selectedYear: action.year } };

    case NOTIFICATIONS_PERMISSION:
      return { ...state, data: { ...state.data, askedForNotificationsPermissions: action.asked } };

    case NOTIFICATION_MODAL_STATUS:
      return { ...state, data: { ...state.data, seenNotificationsModal: action.status } };

    case UPDATE_EMAIL_FOR_NOTIFICATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          countrySpecifics: {
            ...state.data.countrySpecifics,
            [action.countryCode]: {
              emailForNotifications: action.email,
            },
          },
        },
      };

    case UPDATE_COUNTRY_SPECIFICS_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          countrySpecifics: {
            ...state.data.countrySpecifics,
            [action.countryCode]: {
              // @ts-ignore
              ...state.data.countrySpecifics[action.countryCode],
              ...action.countrySpecifics,
            },
          },
        },
      };

    case UPDATE_COUNTRY_YEAR_SPECIFICS_SETTINGS: {
      const { countryCode, year, key, value } = action;
      return {
        ...state,
        data: {
          ...state.data,
          countryYearSpecifics: {
            ...state.data.countryYearSpecifics,
            [countryCode]: {
              // @ts-ignore
              ...state.data.countryYearSpecifics[countryCode],
              // @ts-ignore
              [year]: { ...state.data.countryYearSpecifics[countryCode]?.[year], [key]: value },
            },
          },
        },
      };
    }

    case UPDATE_CALCULATION_CONFIRMED:
      return {
        ...state,
        data: {
          ...state.data,
          countrySpecifics: {
            ...state.data.countrySpecifics,
            [action.countryCode]: {
              // @ts-ignore
              ...state.data.countrySpecifics[action.countryCode],
              calculationConfirmed: action.confirmed,
            },
          },
        },
      };

    case SELECT_LOCALE:
      return { ...state, data: { ...state.data, selectedLocale: action.locale } };

    case FORCE_RANDOM_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          firebaseUserProperties: {
            ...state.data.firebaseUserProperties,
            [FirebaseUserPropertyKeys.RandomNumber]: action.randomNumber,
          },
        },
      };

    case SET_ISQFVISITED:
      return {
        ...state,
        data: {
          ...state.data,
          isQFVisited: action.isQFVisited,
        },
      };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
// selectors
export type RootState = {
  settings: State;
};

const selectedCountry = (state: RootState) => CountryCodes.IT;

const hasSelectedCountry = (state: RootState): boolean => state.settings.data.hasSelectedCountry;

const selectedYear = (state: RootState): number | null | undefined =>
  state.settings.data.selectedYear;

const askedForNotificationsPermissions = (state: RootState): boolean =>
  !!state.settings.data.askedForNotificationsPermissions;

const notificationsModalStatus = (state: RootState): boolean =>
  Boolean(state.settings.data.seenNotificationsModal);

const selectCurrentCountrySpecifics = (state: RootState): CountrySpecifics => {
  const countryCode = selectedCountry(state);
  return state.settings.data.countrySpecifics[countryCode] || {};
};

const selectCurrentCountryYearSpecifics = (state: RootState): Record<string, Value> => {
  const countryCode = selectedCountry(state);
  const year = selectedYear(state);
  if (year == null) return {};
  return state.settings.data.countryYearSpecifics?.[countryCode]?.[year] || {};
};

const getAfterSubmissionFeedbackDisplayed: (state: RootState) => boolean | null | undefined =
  createSelector(
    selectCurrentCountrySpecifics,
    selectedYear,
    (countrySpecifics: CountrySpecifics, year: number | null | undefined) => {
      // @ts-ignore
      return countrySpecifics.afterSubmissionFeedbackDisplayed?.[year] || null;
    },
  );
const getAfterSubmissionVoucherPopupDisplayed: (state: RootState) => Record<string, boolean> =
  createSelector(selectCurrentCountrySpecifics, (countrySpecifics: CountrySpecifics) => {
    return countrySpecifics.afterSubmissionVoucherPopupDisplayed || {};
  });

const selectedLocale = (state: RootState): string | undefined => state.settings.data.selectedLocale;

const getFBRandomNumber = (state: RootState): number =>
  state.settings.data.firebaseUserProperties.RandomNumber;

const requestIdentification = createSelector(
  selectCurrentCountryYearSpecifics,
  (countrySpecifics: CountrySpecifics) => {
    return countrySpecifics.requestIdentification ?? true;
  },
);

const selectIsQFVisited = (state: RootState): boolean => state.settings.data.isQFVisited;

export const selectors = {
  selectedCountry,
  selectedYear,
  askedForNotificationsPermissions,
  notificationsModalStatus,
  selectCurrentCountrySpecifics,
  hasSelectedCountry,
  selectedLocale,
  getFBRandomNumber,
  getAfterSubmissionFeedbackDisplayed,
  getAfterSubmissionVoucherPopupDisplayed,
  selectCurrentCountryYearSpecifics,
  selectPushToken,
  requestIdentification,
  selectIsQFVisited,
};

export const persistFilter = createFilter('settings', [
  'data.askedForNotificationsPermissions',
  'data.selectedCountry',
  'data.hasSelectedCountry',
  'data.countrySpecifics',
  'data.countryYearSpecifics',
  'data.pushToken',
  `data.firebaseUserProperties.${FirebaseUserPropertyKeys.RandomNumber}`,
  'data.selectedLocale',
]);
// NOTE: in order to prevent setting the same values on each store update
// the `errorTracking` exported must be a reselect selector
export const errorTracking = createSelector(selectedYear, selectedCountry, (year, country) => ({
  selectedYear: year,
  selectedCountry: country,
}));
