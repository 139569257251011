import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { Place } from 'src/types/geo';
import { AddressDetailsKeys } from 'src/types/address';
import { specificAnswerTypesQuestionIds } from 'src/common/constants-it';
import { theme } from 'src/components/core';

import actions from '../../stores/store/actions';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { AnswerComponentProps } from '../../components/answers/types';
import { addressValidator } from '../../components/answers/shared/validators/defaultValidators';
import { addressValidatorIT } from '../../components/answers/shared/validators/address-validator-it';
import { useNavigation } from '../../hooks/navigation-hook';
import { isWeb } from '../../utils/platform';

import { SearchInItalyContainer } from './Address/search-in-italy';

export type Props = AnswerComponentProps & {
  isGlobal?: boolean;
  // this will ignore "CountryCode" parameter for a search
  isItalian?: boolean;
  // will display a preview with a green highlight
  showPreview?: boolean;
};

const sharedFields = [
  {
    id: AddressDetailsKeys.Street,
    editable: true,
  },
  {
    id: AddressDetailsKeys.Number,
    editable: true,
  },
  {
    id: AddressDetailsKeys.PostalCode,
    editable: true,
  },
  {
    id: AddressDetailsKeys.City,
    editable: true,
  },
];

const getDEFields = (isGlobal = false) => [
  {
    id: AddressDetailsKeys.Addition,
    editable: true,
  },
  ...sharedFields,
  {
    id: AddressDetailsKeys.Country,
    editable: isGlobal,
  },
];

const getITFields = (isGlobal = false) => [
  ...sharedFields,
  {
    id: AddressDetailsKeys.State,
    editable: true,
  },
  {
    id: AddressDetailsKeys.Country,
    editable: isGlobal,
  },
];

const getFields = (isGlobal = false, isItalian = false) => {
  return isItalian ? getITFields(isGlobal) : getDEFields(isGlobal);
};

const AddressContainer = ({
  onAnswer,
  question,
  year,
  isGlobal,
  value,
  isItalian = false,
  showPreview = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { navigationActions } = useNavigation();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);

  const handleOnAnswer = (address: Place) => {
    dispatch(actions.places.rememberPlaces([address]));

    /*
    When user add or change the address we save this state to request identification.
    The goal is avoid the scenario where a user submits a document and decides to edit
    the address before the submission.
    */
    dispatch(
      actions.settings.updateCountryYearSpecific(
        selectedCountry,
        year,
        'requestIdentification',
        true,
      ),
    );
    onAnswer(question, address);
  };

  const getAddressSearchParams = (color: string) => ({
    color,
    detailsConfig: {
      addressValidator: isItalian ? addressValidatorIT : addressValidator,
      fields: getFields(isGlobal, isItalian),
    },
    onAnswer: (address: Place) => {
      handleOnAnswer(address);

      if (!isWeb && selectedCountry !== CountryCodes.IT) {
        navigationActions.back();
      }
    },
    year,
  });

  const hidePreviousPlaces = question.id === specificAnswerTypesQuestionIds.employerAddress;
  const userColors = {
    color: theme.color.primary,
    colorSecondary: theme.color.secondary,
    colorBackgroundText: theme.color.primaryBackgroundText,
  };
  return (
    <SearchInItalyContainer
      {...getAddressSearchParams(userColors.color)}
      defaultSearchTerm={value ? value.displayName : ''}
      hidePreviousPlaces={hidePreviousPlaces}
    />
  );
};

export const AddressRollover = (props: Props): JSX.Element => {
  return <AddressContainer {...props} showPreview />;
};

export const GlobalAddressRollover = (props: Props): JSX.Element => {
  return <AddressRollover {...props} isGlobal />;
};

export default AddressContainer;
