import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useNavigation } from 'src/hooks/navigation-hook';

import policyContent from '../../assets/docs';
import { privacyStyles } from '../../assets/templates/custom-styles/privacy';
import { State } from '../stores/store/initial';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

export enum Policy {
  terms = 'terms',
  privacy = 'privacy',
  commercialista = 'commercialista',
  spid = 'spid',
  gdpr = 'gdpr',
  aml = 'aml',
}

const termEvents = {
  terms: AnalyticsEvent.termsAndConditionsPressed,
  privacy: AnalyticsEvent.privacyPolicyPressed,
  commercialista: AnalyticsEvent.commercilistaPolicyPressed,
  elster: AnalyticsEvent.elsterInformationPressed,
  spid: AnalyticsEvent.spidConsentPressed,
  gdpr: AnalyticsEvent.gdppConsentPressed,
  aml: AnalyticsEvent.amlConsentPressed,
};

export type WithLegalConditions = {
  handleTermsClicked: (policy: Policy) => void;
};

type WithLegalConditionWrapperType<T> = (
  props: Omit<T, keyof WithLegalConditions>,
) => React.ReactElement<T>;

export function withLegalConditions<T extends WithLegalConditions = WithLegalConditions>(
  WrappedComponent: React.ComponentType<T>,
): WithLegalConditionWrapperType<T & WithLegalConditions> {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Enhancer = (props: Omit<T, keyof WithLegalConditions>) => {
    const intl = useIntl();
    const { navigationActions } = useNavigation();
    const { country, year } = useSelector((state: State) => ({
      country: settingsSelectors.selectedCountry(state),
      year: settingsSelectors.selectedYear(state),
    }));

    const handleTermsClicked = (policy: Policy) => {
      if (policy === 'spid') {
        navigationActions.toSPIDConsentScreen('modal');
        Analytics.log(termEvents[policy], {
          year,
        });
      }
      if (policy === Policy.gdpr) {
        navigationActions.toItalyGDPRConsent('modal');
        Analytics.log(termEvents[policy], {
          year,
        });
      }
      if (
        policy === Policy.terms ||
        policy === Policy.commercialista ||
        policy === Policy.privacy ||
        policy === Policy.aml
      ) {
        const content = policyContent[policy];
        if (content != null) {
          Analytics.log(termEvents[policy], {
            year,
          });

          if (
            policy === Policy.terms ||
            policy === Policy.commercialista ||
            policy === Policy.aml
          ) {
            navigationActions.toInformation('modal', {
              content: content(intl.locale, country),
            });
          }

          if (policy === Policy.privacy) {
            navigationActions.toPrivacyPolicy('modal', {
              content: content(intl.locale, country),
              contentStyles: privacyStyles,
            });
          }
        }
      }
    };

    return <WrappedComponent {...(props as T)} handleTermsClicked={handleTermsClicked} />;
  };

  Enhancer.displayName = `withLegalConditions(${displayName})`;

  return Enhancer;
}
