import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Config from 'react-native-config';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '@taxfix/taxfix-sdk';

import HtmlContent from 'src/components/html-content';
import { parseMarkdown } from 'src/services/markdown-parser';
import ErrorMessage from 'src/components/auth/ErrorMessage';
import { ButtonType } from 'src/components/alert-overlay';
import {
  BodyPrimaryText,
  Box,
  Button,
  DisplayTitleText,
  Icon,
  SmallSecondaryText,
  theme,
} from 'src/components/core';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useLogout } from 'src/hooks/logout-hook';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as remoteConfigSelectors } from 'src/stores/modules/remote-config-firebase';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import NotificationBanner from 'src/components/notification-banner';
import { ScreenName } from 'src/types/screen-name';

import { useItalyIntl } from '../../_hooks/use-italy-intl';

const styles = StyleSheet.create({
  alertBanner: {
    backgroundColor: theme.color.errorBackgroundItaly,
    color: theme.color.secondaryText,
    borderRadius: 10,
  },
  statusIcon: {
    width: 25,
    height: 25,
  },
});

const contentStyles = `
<style type="text/css">
* {
  font-size: 16px;
  }
#content {
  margin: 0px;
}
ul {
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
}
ul ul {
  list-style-type: disc;
}
li{
  margin-bottom: 5px;
}
</style>
`;

export const DeleteAccount = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<string>();
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const emailAddress = useSelector(userAuthSelectors.getEmailAddress);
  const isDataDeletionRequestEnabled = useSelector(
    remoteConfigSelectors.isItalyDataDeletionRequestEnabled,
  );
  const dispatch = useDispatch();
  const logout = useLogout();
  const { getTranslationText } = useItalyIntl();

  const handleCloseAccount = async () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ItalyPrivacySettings,
      buttonName: 'deleteDataAndCloseAccount',
    });
    setLoading(true);
    dispatch(overlayActions.hide());
    try {
      if (!isDataDeletionRequestEnabled || !emailAddress) {
        throw new Error('Delete data request not enabled/possible');
      }
      await User.requestDataDeletion(Config.API_BASE_URL, accessToken, {
        email: emailAddress as string,
      });
      await User.deleteMe(Config.API_BASE_URL, accessToken).then(() => {
        Analytics.log(AnalyticsEvent.accountDeletedPrivacySettings);
        logout({
          toastConfig: {
            titleId: 'it.account.delete-account.success',
            iconName: 'attention',
            tintColor: theme.color.secondaryFeedbackWarningRed,
          },
          isAccountClosure: true,
        });
      });
    } catch (error) {
      setLoading(false);
      setErrorKey('it.account.delete-account.fail');
    }
  };

  const showDeleteAccountConfirmationPopup = () => {
    Analytics.log(AnalyticsEvent.accountDeletionPrivacySettingsStepShown, {
      stepName: 'deletion',
    });
    setErrorKey(undefined);
    dispatch(
      overlayActions.show('AlertOverlay', {
        titleTranslationKey: 'it.account.delete-account.confirmation.title',
        leadTranslationKey: 'it.account.delete-account.confirmation.lead',
        buttons: [
          {
            translationKey: 'it.account.delete-account.confirmation.delete',
            type: ButtonType.warning,
            onPress: () => handleCloseAccount(),
            style: {
              backgroundColor: theme.color.pinkFull,
            },
          },
          {
            translationKey: 'it.account.delete-account.confirmation.cancel',
            type: ButtonType.secondary,
            onPress: () => {
              Analytics.log(AnalyticsEvent.buttonPressed, {
                screenName: ScreenName.ItalyPrivacySettings,
                buttonName: 'cancel',
              });
              dispatch(overlayActions.hide());
              setLoading(false);
            },
            disableCloseOnPress: true,
            style: {
              color: theme.color.pinkFull,
            },
          },
        ],
        appearFromTop: true,
      }),
    );
  };

  return (
    <>
      {errorKey && (
        <NotificationBanner errorType="Error">
          <ErrorMessage
            errorKey={errorKey}
            style={
              {
                color: 'white',
              } as any
            }
          />
        </NotificationBanner>
      )}
      <Box flex>
        <Box left={2.4} right={2.4} bottom={0.8} top={1.6}>
          <DisplayTitleText id="it.account.delete-account.information.title" />
        </Box>
        <Box left={2.4} right={2.4} bottom={3}>
          <Box
            style={{
              minHeight: 180,
            }}
            key={'it.account.delete-account.information.first-text'}
            bottom={1.6}
          >
            <HtmlContent
              contentStyles={contentStyles}
              content={String(
                parseMarkdown(
                  getTranslationText('it.account.delete-account.information.first-text'),
                ),
              )}
            />
          </Box>
          <Box key={'it.account.delete-account.information.second-text'}>
            <BodyPrimaryText id={'it.account.delete-account.information.second-text'} />
          </Box>
        </Box>
        <Box left={2} right={2}>
          <Box style={styles.alertBanner} left={2} right={2}>
            <Box direction="row" top={1.4} bottom={1.4} right={2.5}>
              <Box right={1}>
                <Icon name="attention" style={styles.statusIcon} tintColor={theme.color.pinkFull} />
              </Box>
              <SmallSecondaryText
                id="it.account.delete-account.information.note"
                color={theme.color.pinkFull}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box left={2} right={2} bottom={2}>
        <Button
          fillColor={theme.color.pinkFull}
          translationKey="it.account.delete-account.information.proceed-deletion"
          floating
          loading={loading}
          testId="it.account.delete-account.information.proceed-deletion"
          onPress={() => showDeleteAccountConfirmationPopup()}
        />
      </Box>
    </>
  );
};
