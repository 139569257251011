import * as React from 'react';

import { Container, ContainerProps, Image, Text } from 'src/taxfix-components/src';

type ListItemProps = {
  text: string;
} & ContainerProps;

export const ListItem = ({ text, ...props }: ListItemProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        name="checkMarkThin"
        resizeMode="contain"
        position="absolute"
        width="16px"
        height="16px"
      />
      <Text marginLeft="22px" value={text} variant={{ base: 'bodySBook', md: 'bodyMBook' }} />
    </Container>
  );
};
