import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { ACCOUNT_CLOSURE_BANNER_TIMEOUT } from 'src/common/constants-it';
import { ToastProps } from 'src/components/toast';

import { actions as rootActions } from '../stores/modules/root';
import {
  actions as userAuthActions,
  selectors as userAuthSelectors,
} from '../stores/modules/user-auth';
import { clear } from '../utils/asyncStorage';
import { clearAllAnswers } from '../stores-legacy';
import { getNavigationActions, safeResetNavigation } from '../routes/config-util';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectCountryAndYear } from '../services/country-and-year';
import Analytics from '../biz-logic/analytics';
import { usersAPIClient } from '../services/users';

import { useAppStateContext } from './useAppStateContext';

type ToastConfig = Pick<ToastProps, 'iconName' | 'tintColor' | 'titleId'>;

export type Logout = {
  isAccountClosure?: boolean;
  toastConfig?: ToastConfig;
};

export const useLogout: () => (logout: Logout) => void = () => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      resetAppState: rootActions.resetAppState,
      resetUser: userAuthActions.reset,
    },
    dispatch,
  );
  const { onAppStateReset } = useAppStateContext();

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  return async (props?: Logout) => {
    safeResetNavigation([
      getNavigationActions().toLogoutSuccess('screen', {
        toastConfig: props?.toastConfig,
      }),
    ]);
    // do backend logout
    usersAPIClient
      .logoutCurrentUser({ auth: { tokenType: 'Token', accessToken: accessToken } })
      .catch((error) => {
        logger.warn('logout_error', { error });
      });

    // reset tracking
    Analytics.reset();

    // clean redux stores
    actions.resetUser();
    await clear();
    actions.resetAppState();
    // clean question stores
    clearAllAnswers();

    onAppStateReset && onAppStateReset();

    // if it's an account closure / deletion on WEB we wait for 7 seconds on the logout success screen
    // automatically redirects the user to the first app screen
    if (props?.isAccountClosure) {
      await new Promise((resolve) => setTimeout(resolve, ACCOUNT_CLOSURE_BANNER_TIMEOUT));
    }
    selectCountryAndYear(selectedCountry);
  };
};
