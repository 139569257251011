import { Buffer } from 'buffer';

import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import Config from 'react-native-config';
import { DocumentsAPI, DocumentsTypes } from '@taxfix/documents-sdk';
import { CountryCodes } from '@taxfix/types';
import { SubmissionReviewStatusIT } from '@taxfix/submissions-types';
import { CertifiedStorage } from '@taxfix/italy-sdk';

import { useNavigation } from 'src/hooks/navigation-hook';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { getQuestionStores } from 'src/stores-legacy/util';
import { prefillQuestionIds, wayQuestionIds } from 'src/common/constants-it';
import { getDefaultTaxYear } from 'src/services/country-and-year/utils';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { ButtonName } from 'src/types/button-name';
import {
  UpdateStatusReviewCreditorDebit,
  actions as submissionActions,
  selectors as submissionSelectors,
} from 'src/stores/modules/submission';
import { selectors as prefillSelectors } from 'src/stores/modules/prefill';

type Params = {
  submissionId: number;
};

const useFetch = (): {
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  document: string;
  onSign: () => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [document, setDocument] = useState<string>('');
  const [xmlDocumentId, setXmlDocumentId] = useState<number>();
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId) as number;
  const firstNameProfile = useSelector(prefillSelectors.firstName);
  const lastNameProfile = useSelector(prefillSelectors.lastName);
  const taxIdProfile = useSelector(prefillSelectors.taxId);
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const submissionReviewStatus = useSelector(submissionSelectors.getSubmissionReviewStatus);

  const dispatch = useDispatch();

  const { getNavigationActions, safeResetNavigation } = useNavigation();

  const questionStores = getQuestionStores(CountryCodes.IT);
  const questionStore = questionStores[selectedYear];
  const questionIds: string[] = Object.values({ ...wayQuestionIds, ...prefillQuestionIds });
  const nodes = getNodesByIds(questionStore, questionIds);
  const quizmasterLight = getQuizmasterLight(questionStore, nodes);
  const firstName = quizmasterLight[wayQuestionIds.firstName]?.answer || firstNameProfile;
  const lastName = quizmasterLight[wayQuestionIds.lastName]?.answer || lastNameProfile;
  const taxId = quizmasterLight[prefillQuestionIds.taxId]?.answer || taxIdProfile;

  const route = useRoute();
  const { submissionId } = route.params as Params;

  const successResponseMsg = 'Successfully signed modello redditi document.';

  useEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);
      try {
        const modelloRedditiDocs = await DocumentsAPI.v1.get(Config.API_BASE_URL, accessToken, {
          userId,
          types: [
            DocumentsTypes.v1.Documents.NonReceiptTypes.ModelloRedditiPDF,
            DocumentsTypes.v1.Documents.NonReceiptTypes.ModelloRedditiXML,
          ],
          year: selectedYear,
        });

        if (modelloRedditiDocs && modelloRedditiDocs.data.length > 0) {
          const modelloRedditiXMLId = modelloRedditiDocs.data.find(
            (doc) => doc.type === DocumentsTypes.v1.Documents.NonReceiptTypes.ModelloRedditiXML,
          )?.id;
          setXmlDocumentId(modelloRedditiXMLId);

          const modelloRedditiPDFId = modelloRedditiDocs.data.find(
            (doc) => doc.type === DocumentsTypes.v1.Documents.NonReceiptTypes.ModelloRedditiPDF,
          )?.id;

          const response = await DocumentsAPI.v1.getOne(Config.API_BASE_URL, accessToken, {
            id: Number(modelloRedditiPDFId),
            getFileContents: true,
            acceptHeader: 'application/pdf',
          });

          const fileRaw = response.data as ArrayBuffer;
          const fileData = new Uint8Array(fileRaw);
          const fileBase64 = Buffer.from(fileData).toString('base64');

          setDocument(`data:application/pdf;base64,${fileBase64}`);
        } else {
          setIsError(true);
        }

        setIsLoading(false);
      } catch (e) {
        logger.error(e as Error, { userId });
      }
    };
    fetchDocument();
  }, [accessToken, userId]);

  const onSign = async () => {
    try {
      Analytics.log(AnalyticsEvent.buttonPressed, {
        buttonName: ButtonName.ModelloRedditiSign,
        screenName: ScreenName.ModelloRedditiSign,
      });

      const creditOrDebit: () => UpdateStatusReviewCreditorDebit = () => {
        switch (submissionReviewStatus) {
          case SubmissionReviewStatusIT.MrFirmaCredito:
            return SubmissionReviewStatusIT.MrCredito;
          case SubmissionReviewStatusIT.MrFirmaDebito:
            return SubmissionReviewStatusIT.MrDebito;
        }
      };

      const response =
        xmlDocumentId &&
        firstName &&
        lastName &&
        taxId &&
        (await CertifiedStorage.signModelloRedditi(Config.API_BASE_URL, accessToken, {
          documentId: xmlDocumentId,
          submissionId,
          submissionReviewStatus: Number(submissionReviewStatus),
          userData: {
            firstName,
            lastName,
            taxId,
            userId,
          },
        }));

      if (response?.message === successResponseMsg) {
        // Positive response of sign => Update statusReview in redux
        dispatch(submissionActions.updateStatusReview(creditOrDebit()));

        Analytics.log(AnalyticsEvent.reviewStatusChanged, {
          submissionReviewStatus: creditOrDebit(),
          agentId: null,
          submissionId,
        });

        safeResetNavigation([getNavigationActions().toDashboard('screen')]);
      } else {
        setIsError(true);
      }
    } catch (e) {
      setIsError(true);
      logger.error(e as Error, { xmlDocumentId });
    }
  };

  return { isError, setIsError, isLoading, document, onSign };
};

export { useFetch };
