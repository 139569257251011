import { $Keys } from 'utility-types';
import React from 'react';
import { ScrollView } from 'react-native';
import { MessageDescriptor } from 'react-intl';
import { CountryCodes } from '@taxfix/types';

import { NavButton } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import AcceptTermsWithCheckBox from 'src/components/AcceptTermsWithCheckBox';
import { Policy } from 'src/hocs/with-legal-conditions';

import {
  theme,
  Box,
  BodySecondaryText,
  BodyTitleText,
  DisplayTitleText,
  TitleText,
  Section,
  useLargeScreenQuery,
} from '../../components/core';
import { FoldableFooter } from '../../components/footer';
import NotificationBanner from '../../components/notification-banner';
import { EmailConfirmationBannerContainer } from '../../containers/email-confirmation-banner';
import { IntlDate } from '../../i18n';
import { isWeb } from '../../utils/platform';

import { ConsentText } from './italy-submission/consent-text';

export type SelectFieldData = {
  selectedValue: string;
};

type Props = {
  firstName: string;
  lastName: string;
  taxId: string;
  consentDate: Date;
  taxYear: number;
  onConsentTextExpand: () => void;
  isCommercialistaConfirmed: boolean;
  isAmlConsentConfirmed: boolean;
  isConfirmEnabled: boolean;
  onConfirmCommercialista: (checked: boolean) => void;
  onConfirmAml: (checked: boolean) => void;
  onSubmit: () => void;
  isLoading: boolean;
  documentCreationError?: MessageDescriptor;
  onBackButtonPress(): void;
  handleTermsClicked: (policy: Policy) => void;
};
export const ItalySubmissionComponent = ({
  firstName,
  lastName,
  taxId,
  consentDate,
  taxYear,
  onConsentTextExpand,
  isCommercialistaConfirmed,
  isAmlConsentConfirmed,
  isConfirmEnabled,
  onConfirmCommercialista,
  onConfirmAml,
  onSubmit,
  isLoading,
  documentCreationError,
  onBackButtonPress,
  handleTermsClicked,
}: Props): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();
  const buttonTranslationKey =
    isCommercialistaConfirmed && isAmlConsentConfirmed
      ? 'italy-submission-commercialista.button-active'
      : 'italy-submission-commercialista.button-disabled';
  return (
    <Box flex>
      {isLargeScreen && (
        <NavButton
          text={getTranslationText('it.web.navigation.back-to-employer')}
          onPress={onBackButtonPress}
        />
      )}

      <EmailConfirmationBannerContainer isError shouldPoll />

      {documentCreationError && (
        <NotificationBanner errorType="Error">
          <BodyTitleText
            id={documentCreationError.id}
            style={{
              color: theme.color.lightText,
            }}
          />
        </NotificationBanner>
      )}

      <Section size="wide" centered>
        <ScrollView>
          <Box left={2} right={2}>
            <Box top={2}>
              <Box bottom={1.5}>
                <DisplayTitleText id="italy-submission.title" />
              </Box>
              <BodySecondaryText id="italy-submission.subtitle" />
            </Box>
            <Box top={3}>
              <Box bottom={1}>
                <TitleText id="consent.details.title" />
              </Box>
              <Box bottom={3}>
                <BodySecondaryText>{`${firstName} ${lastName}`}</BodySecondaryText>
                <BodySecondaryText>{`${taxId}`}</BodySecondaryText>
              </Box>
              <Box bottom={1}>
                <TitleText id="consent.today.title" />
              </Box>
              <Box bottom={3}>
                <IntlDate value={consentDate} Component={BodySecondaryText} />
              </Box>
              <Box bottom={1}>
                <TitleText id="consent.consent.title" />
              </Box>
              <ConsentText
                trackShowMore={onConsentTextExpand}
                consentReferenceDate={consentDate}
                taxYear={taxYear}
              />
            </Box>
            <AcceptTermsWithCheckBox
              key={Policy.commercialista}
              handleTermsChange={() => onConfirmCommercialista(!isCommercialistaConfirmed)}
              onTermsClicked={() => handleTermsClicked(Policy.commercialista)}
              termsAccepted={isCommercialistaConfirmed}
              isLoading={isLoading}
              translationKey={'italy-submission-commercialista.checkbox.text'}
              selectedCountry={CountryCodes.IT}
              disabled={!isConfirmEnabled}
              textDecorationLine={'none'}
            />
            <AcceptTermsWithCheckBox
              key={Policy.aml}
              handleTermsChange={() => onConfirmAml(!isAmlConsentConfirmed)}
              onTermsClicked={() => handleTermsClicked(Policy.aml)}
              termsAccepted={isAmlConsentConfirmed}
              isLoading={isLoading}
              translationKey={'italy-submission-aml.checkbox.text'}
              selectedCountry={CountryCodes.IT}
              disabled={!isConfirmEnabled}
              textDecorationLine={'none'}
            />
          </Box>
        </ScrollView>
      </Section>

      <Box>
        <FoldableFooter
          translationKey={buttonTranslationKey}
          onPress={onSubmit}
          applyContentSeparation={!isWeb}
          isLoading={isLoading}
          isDisabled={!isCommercialistaConfirmed || !isAmlConsentConfirmed}
          testId="italy-submission.footer.button"
          applyWebContentSeparation={isWeb}
          isBackButtonHidden={isCommercialistaConfirmed}
        />
      </Box>
    </Box>
  );
};
