/*
NOTE:
Firebase Dynamic Link is only working on Production (App Store, Google Play)
Dynamic link requires the appID that is registered in Store.
But we have different appID/packageName for each env.
You can test it by chaning appID/packageName and build locally, but make sure you test it on real device.
*/
import { Platform } from 'react-native';
import Config from 'react-native-config';

import { selectors as remoteConfigSelectors } from '../stores/modules/remote-config-firebase';
import {
  DynamicLink,
  DynamicLinkCallbacks,
  RedirectRequest,
} from '../stores/modules/dynamic-link-types';

import { dynamicLinkRoutes, dynamicLinkParamKeys } from './dynamic-link-types';
import { encodeQuery, parseUrl } from './dynamic-link-util';
import { getRedirectToUserTesting } from './redirects/user-testing';
import { getRedirectToCodePush } from './redirects/code-push';

const dynamicLinks =
  Platform.OS !== 'web' && require('@react-native-firebase/dynamic-links').default;

const messaging = Platform.OS !== 'web' && require('@react-native-firebase/messaging').default;

type Params = Record<string, (string | number) | null | undefined>;
type AdjustParams = { [key in 'campaign' | 'adgroup' | 'label']?: string | null | undefined };

const generateDynamicLink = async (
  route: string,
  params: Params,
  adjustTrackerURL: {
    ios: string;
    android: string;
  },
  adjustParams: AdjustParams,
): Promise<string | null | undefined> => {
  if (!dynamicLinks) return `${route}?${encodeQuery(params)}`;
  const adjustParamsStr = encodeQuery(adjustParams);
  const link = {
    link: `${route}?${encodeQuery(params)}`,
    domainUriPrefix: Config.DYNAMIC_LINK_URL_FIREBASE_DYNAMIC_LINK,
    ios: {
      appStoreId: Config.APP_STORE_ID,
      bundleId: Config.DYNAMIC_LINK_IOS_BUNDLE_ID,
      fallbackUrl: `${adjustTrackerURL.ios}?${adjustParamsStr}`,
    },
    android: {
      packageName: Config.GOOGLE_PLAY_ID,
      fallbackUrl: `${adjustTrackerURL.android}?${adjustParamsStr}`,
    },
    navigation: {
      forcedRedirectEnabled: true,
    },
  };

  try {
    return await dynamicLinks().buildShortLink(link, 'SHORT'); // eslint-disable-next-line no-empty
  } catch (e) {}

  return null;
};

const getInitialDynamicLink = async (): Promise<string | null | undefined> => {
  if (!dynamicLinks || !messaging) return null;

  try {
    let url = null;
    const message = await messaging().getInitialNotification();

    if (message?.data?.url) {
      url = message.data.url;
    } else {
      const dynamicLink = await dynamicLinks().getInitialLink();
      url = dynamicLink.url;
    }

    return url; // eslint-disable-next-line no-empty
  } catch (e) {}

  return null;
};

const createDynamicLinkWithRedirect = (data: RedirectRequest): DynamicLink | null => {
  const { onDismissDynamicLink, link, type, getState } = data;
  const url = parseUrl(link);
  let redirectCallbacks: DynamicLinkCallbacks = {
    onBeforeRedirect: null,
    onRedirect: null,
    onPostRedirect: null,
  };
  const blackList = remoteConfigSelectors.getBlacklistDynamicLink(getState());
  if (blackList.includes(url)) throw new Error('url-blacklisted');

  const dynamicLink = {
    link,
    type,
    requiresAuth: false,
    redirectCallbacks,
  };

  /* example of redirection handler
  const getRedirectToAwesomePage = (data) => {
    // basic check
    if (e.g country is not DE) throw new Error("no-supported-country");
    if (data.type !== 'initial') throw new Error("only-initial-deep-link-supported")
     const onBeforeRedirect = () => {
      // this one is triggered after auth, so you can access more stuff to check
      if (e.g has submission) throw new Error("no-submission-exist");
    }
    const onRedirect = () => {
      // You need to put your navigation.reset logic that will replace our default navigation.reset on app-launching.
      // If you put the logic here, it will replace our default navigation.reset logic in <AppStateContainer>'s componentDidMount.
      // There is also a dynamic-link that doesn't need this. (e.g popup-only)
      dispatch(getNavigationActions().reset({...}));
    }
    const onPostRedirect = () => {
      // stuff that is not related to with navigation.reset. e.g modal navigation, popup
      Alert.alert("Redirection success!");
    }
    return { onBeforeRedirect, onRedirect, onPostRedirect};
  }
   switch (url) {
    case dynamicLinkRoutes.awesomePageDeeplink:
      redirectCallbacks = getRedirectToAwesomePage(data); break;
  }
  */
  switch (url) {
    case dynamicLinkRoutes.userTesting:
      redirectCallbacks = getRedirectToUserTesting(data);
      break;

    case dynamicLinkRoutes.codepush:
      redirectCallbacks = getRedirectToCodePush(data);
      break;

    default:
      throw new Error('no-path-found');
  }

  return {
    ...dynamicLink,
    redirectCallbacks: {
      ...redirectCallbacks,
      onPostRedirect: () => {
        if (redirectCallbacks.onPostRedirect) redirectCallbacks.onPostRedirect();
        onDismissDynamicLink();
      },
    },
  };
};

const getSimpleAppOpenningDynamicLink = () => {
  if (Config.DYNAMIC_LINK_FIREBASE_APP_OPEN_SHORT) {
    return (
      Config.DYNAMIC_LINK_URL_FIREBASE_DYNAMIC_LINK +
      '/' +
      Config.DYNAMIC_LINK_FIREBASE_APP_OPEN_SHORT
    );
  } else return Config.DYNAMIC_LINK_URL_WEBAPP;
};

export {
  generateDynamicLink,
  getInitialDynamicLink,
  dynamicLinkRoutes,
  dynamicLinkParamKeys,
  createDynamicLinkWithRedirect,
  getSimpleAppOpenningDynamicLink,
};
