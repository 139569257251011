import React, { useRef, useCallback } from 'react';
import { InteractionManager, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PressEvent } from 'react-native/Libraries/Types/CoreEventTypes';

import {
  actions as OverlayActions,
  selectors as overlaySelectors,
} from '../stores/modules/overlay';
import { InformationButton as InformationButtonComponent } from '../components/information-button';
import { theme } from '../components/core';

type Props = {
  overlayActions: typeof OverlayActions;
  activeOverlayName: string;
  onPress: (evt?: PressEvent) => void;
  shouldShowTooltip: boolean;
  onTooltipOpened?: () => void;
  onTooltipClosed?: (pressedHighlight: boolean) => void;
  color?: string;
  size?: 'small' | 'large';
  withText?: boolean;
  informationText?: string;
};

const mapStateToProps = (state: any) => ({
  activeOverlayName: overlaySelectors.getActiveOverlayName(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const InformationButtonContainer = ({
  overlayActions,
  activeOverlayName,
  onPress,
  shouldShowTooltip,
  onTooltipOpened,
  onTooltipClosed,
  color = theme.color.primaryBackgroundText,
  size = 'large',
  withText,
  informationText,
}: Props) => {
  const buttonRef = useRef<any>();
  useFocusEffect(
    useCallback(() => {
      let task: any;

      // todo disabled on Web for the time being, fix with https://taxfix.atlassian.net/browse/ITA-992
      if (shouldShowTooltip && Platform.OS !== 'web') {
        task = InteractionManager.runAfterInteractions(() => {
          const button: any = buttonRef.current;

          if (button) {
            button.measure((fx: any, fy: any, width: any, height: any, px: any, py: any) => {
              overlayActions.show('HighlightOverlay', {
                coordinate: {
                  x: px + width / 2,
                  y: py + height / 2,
                },
                radius: width,
                caption: {
                  key: 'overlay.info-button.caption',
                },
                onClose: onTooltipClosed,
                fadeDuration: 650,
              });
              if (onTooltipOpened) onTooltipOpened();
            });
          }
        });
      }

      return () => {
        if (task) task.cancel();

        if (activeOverlayName === 'HighlightOverlay') {
          overlayActions.hide();
        }
      };
    }, [shouldShowTooltip]),
  );
  return (
    <InformationButtonComponent
      onPress={onPress}
      color={color}
      size={size}
      ref={buttonRef}
      withText={withText}
      informationText={informationText}
    />
  );
};

const InformationButton = connect(mapStateToProps, mapDispatchToProps)(InformationButtonContainer);
export { InformationButton };
