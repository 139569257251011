import { useTheme } from 'native-base';
import React from 'react';

import { Container, Icon, Text, icons } from 'src/taxfix-components/src';

import { ThreeStepBoxProps } from './types';

export const ThreeStepBox: React.FC<ThreeStepBoxProps> = ({
  title,
  text1,
  text2,
  text3,
  ...rest
}) => {
  const { colors } = useTheme();

  const getStep = (infoText = '', showIcon = false, stepNumber = '1') => (
    <>
      <Container flexDir="column" alignItems="center" flex={1} minHeight={160}>
        <Container
          width={30}
          borderRadius="90px"
          bgColor={colors.secondaryFeedback.greenDark}
          textAlign="center"
          mb={20}
        >
          <Text variant="titleMMedium" value={stepNumber} color={colors.white} />
        </Container>
        <Text value={infoText} fontSize={22} fontWeight={450} lineHeight={27} textAlign="center" />
      </Container>
      {showIcon && <Icon path={icons.chevronRight} size={4} mx={30} alignSelf="flex-start" />}
    </>
  );

  return (
    <Container
      pt={61}
      pb={60}
      px={108}
      backgroundColor={colors.greytones.background}
      borderRadius={20}
      {...rest}
    >
      <Text
        fontSize={40}
        fontWeight={700}
        lineHeight={38}
        value={title}
        mb={65}
        alignSelf="center"
      />
      <Container flexDir="row" alignItems="center">
        {getStep(text1, true, '1')}
        {getStep(text2, true, '2')}
        {getStep(text3, false, '3')}
      </Container>
    </Container>
  );
};
