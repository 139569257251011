import React, { ComponentType, ReactElement } from 'react';

import { UseAnswers, useAnswersFromStore } from '../stores/hooks/answers-stores-hook';

type OriginalProps = {
  year: number;
  [prop: string]: any;
};
export type { UseAnswers };
// This HOC is needed in order to use useAnswersFromStore with class components
export const withAnswersStore = (
  WrappedComponent: ComponentType<OriginalProps>,
): ComponentType<OriginalProps> => {
  const WithQuestionStoreWrapper = (
    props: OriginalProps,
  ): ReactElement<typeof WrappedComponent> => {
    const answers: UseAnswers = useAnswersFromStore();
    return <WrappedComponent {...answers} {...props} />;
  };

  return WithQuestionStoreWrapper;
};
