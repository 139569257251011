import { CountryCodes } from '@taxfix/types';

import { getStore } from '../../stores/util';
import { actions as settingsActions } from '../../stores/modules/settings';

import { getCurrentTaxYearForCountry } from './get-current-tax-year';

export const selectCurrentTaxYearForCountry = (countryCode: CountryCodes) => {
  const store = getStore();
  if (!store) throw new Error('redux store is not available');
  const selectedYear = getCurrentTaxYearForCountry(countryCode);
  if (selectedYear) store.dispatch(settingsActions.selectYear(selectedYear));
};
