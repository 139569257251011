import * as React from 'react';
import { StyleSheet, ActivityIndicator } from 'react-native';

import { theme } from '../theme';
import { LinkText, SmallLinkText } from '../Text';
import Box from '../Box';
import { StyleProp } from '../../types';

import InternalButton, { InternalButtonPropsWithStyle } from './_InternalButton';

export type TextButtonProps = InternalButtonPropsWithStyle & {
  align?: 'left' | 'center' | 'right';
  size?: 'small' | 'default';
  translationKey?: string;
  textColor?: string;
  loading?: boolean;
  textStyle?: StyleProp;
  values?: Record<string, any>;
  testId?: string;
  // truncate the text with an ellipsis. Default 0: no lines restriction will be applied
  numberOfLines?: number;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: 5,
  },
  buttonText: {
    textAlign: 'center',
  },
  buttonTextDisabled: {
    color: theme.color.border,
  },
});

const alignmentStyle = (align: any) => ({
  textAlign: align,
});

const TextButton = ({
  translationKey,
  values = {},
  numberOfLines = 0,
  align = 'center',
  size = 'default',
  textColor,
  style,
  loading = false,
  textStyle,
  ...props
}: TextButtonProps): JSX.Element => {
  let children = null;
  const styling: any = [
    styles.buttonText,
    alignmentStyle(align),
    textColor
      ? {
          color: textColor,
        }
      : null,
    props.disabled || loading ? styles.buttonTextDisabled : null,
    textStyle,
  ];
  const TextComponent = size === 'small' ? SmallLinkText : LinkText;

  if (translationKey != null) {
    children = (
      <TextComponent
        numberOfLines={numberOfLines}
        id={translationKey}
        values={values}
        style={styling}
        testId={translationKey}
      />
    );
  } else {
    children = (
      <TextComponent numberOfLines={numberOfLines} style={styling}>
        {props.children}
      </TextComponent>
    );
  }

  if (loading) {
    children = (
      <Box flex alignVertically="center" alignHorizontally="center">
        <ActivityIndicator animating color="black" />
      </Box>
    );
  }

  return (
    <InternalButton
      {...props}
      style={StyleSheet.flatten([styles.container, style])}
      disabled={props.disabled || loading}
      testId={props.testId}
    >
      {children}
    </InternalButton>
  );
};

export default TextButton;
