import * as React from 'react';
import { Keyboard } from 'react-native';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { Option } from '../../components/input/button-overlay';
import { actions as OverlayActions } from '../../stores/modules/overlay';

type HookProps = {
  options: Option[];
  onChange?: (value: string) => void;
  color?: string;
  isSelectFullScreenHeight?: boolean;
  headerOverlay?: React.ElementType;
  footerOverlay?: React.ElementType;
  fullWidthOnWeb?: boolean;
};

export const useOverlayButtonsShow = (props: HookProps): (() => void) => {
  const dispatch = useDispatch();
  const overlayActions = React.useMemo(
    () => bindActionCreators(OverlayActions, dispatch),
    [dispatch],
  );
  return () => {
    Keyboard.dismiss();
    overlayActions.show('ButtonOverlay', {
      Header: props.headerOverlay,
      Footer: props.footerOverlay,
      color: props.color,
      options: props.options,
      onSelect: props.onChange,
      isSelectFullScreenHeight: props.isSelectFullScreenHeight,
      fullWidthOnWeb: props.fullWidthOnWeb,
    });
  };
};
