import { $Keys } from 'utility-types';
import _ from 'lodash';
import * as React from 'react';
import type { NumberFormatOptions } from '@formatjs/ecma402-abstract';

import { InputField } from 'src/taxfix-components/src';

import NumberInput from './number-input-container';

const avilableFormatters = {
  decimal: 'decimal',
  percent: 'percent',
  currency: 'currency',
};
export type NumberInputFormatter = $Keys<typeof avilableFormatters>;
type Props = {
  value?: number | null | undefined;
  onBlur?: (event: any) => any;
  onFocus?: (event: any) => any;
  onChange: (value: any) => any;
  formatter?: NumberInputFormatter;
  precision?: 0 | 1 | 2;
  Component?: any;
  style?: any;
  onRefInput?: (ref: any) => void;
  color?: string;
  autoFocus?: any;
  returnKeyType?: any;
  onSubmitEditing?: any;
  keyboardType?: any;
};

const toString = (value: string | (number | null | undefined)): string => {
  if (!_.isNil(value)) {
    return (value as any as number).toString();
  }

  return '';
};

export const parseNumberInputFormatter = (formatter: NumberInputFormatter): NumberInputFormatter =>
  Object.keys(avilableFormatters).includes(formatter) ? formatter : 'decimal';

class WrapperNumberInput extends React.Component<Props> {
  handleChangeValue = (value: string) => this.props.onChange(value ? Number(value) : null);

  render() {
    const {
      value = '',
      onBlur,
      onFocus,
      onChange,
      formatter = 'decimal',
      precision,
      Component = InputField,
      style,
      onRefInput,
      ...props
    } = this.props;
    const parsedFormatter = parseNumberInputFormatter(formatter);
    const options: NumberFormatOptions = {
      style: parsedFormatter,
    };

    if (options.style === 'currency') {
      options.currency = 'EUR';
    }

    if (precision != null) {
      options.maximumFractionDigits = precision;
      options.minimumFractionDigits = 0;
    }

    return (
      <NumberInput
        ref={onRefInput}
        value={toString(value)}
        onBlur={onBlur}
        onFocus={onFocus}
        underlineColorAndroid="transparent"
        allowNegative={false}
        {...props}
        onChangeValue={this.handleChangeValue}
        options={options}
        Component={Component}
      />
    );
  }
}

export default WrapperNumberInput;
