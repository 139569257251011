import * as React from 'react';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { DigitalCafSubCategoryContainer } from '../../../screens/containers/digital-caf-documents/digital-caf-sub-category';
import { DigitalCafSubCategory, DocumentUploadMode } from '../../../common/constants-it';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

export type ActionParams = {
  category: DigitalCafSubCategory;
  customLinkHandler: (arg0: string) => void;
  screenMode: DocumentUploadMode;
};

type Props = Navigation<
  'DigitalCafSubCategory',
  {
    DigitalCafSubCategory: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate('DigitalCafSubCategory');

export const DigitalCafSubCategoryRoute: React.FC<Props> = ({ route }) => {
  const { category, customLinkHandler, screenMode } = route.params;

  const headerTitle =
    screenMode === DocumentUploadMode.INCOME_STEP
      ? 'it.digital-caf.required-documents.nav.title.income.other-income'
      : 'it.digital-caf.required-documents.nav.title.expense.other-expense';

  useDefaultNavigationOptions({ id: headerTitle });

  return (
    <DigitalCafSubCategoryContainer
      category={category}
      customLinkHandler={customLinkHandler}
      screenMode={screenMode}
    />
  );
};
