import { FlatList } from 'native-base';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Container, Text, icons, IconButton, Icon } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks';
import { DocumentsExpensesUploadedList } from 'src/screens/containers/required-documents/required-documents-upload';

type TranslationKeys = {
  title?: string;
  subtitle?: string;
};
type PrefilledExpensesListHeaderProps = {
  translationKeys: TranslationKeys;
  open?: boolean;
};

type PrefilledExpensesListProps = {
  translationKeysOpen: TranslationKeys;
  translationKeysClosed: TranslationKeys;
  expensesList: DocumentsExpensesUploadedList[];
  onDeleteExpenseItem?: (categoryKey: string, item: DocumentsExpensesUploadedList) => void;
  categoryKey: string;
};
const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#E1E1E1',
    paddingLeft: 20,
    paddingRight: 30,
    paddingTop: 12,
    paddingBottom: 12,
  },
  containerOpen: {
    backgroundColor: '#F6F6F6',
    paddingBottom: 0,
  },
  flatList: {
    paddingTop: 10,
  },
});

const PrefilledExpensesListHeader: React.FC<PrefilledExpensesListHeaderProps> = ({
  translationKeys,
  open,
}) => {
  return (
    <Container flexDir="row" justifyContent="space-between" padding={'0px'}>
      <Container flexDir={'column'} padding={'0px'} flex={1}>
        <Text variant="titleXXSMedium" fontWeight={500} alignSelf="flex-start">
          {translationKeys.title}
        </Text>
        <Text
          variant="bodyXSBook"
          color="greytones.graphicsDark"
          fontWeight={450}
          alignSelf="flex-start"
          paddingTop="4px"
          maxWidth={'580px'}
          flex={1}
        >
          {translationKeys.subtitle}
        </Text>
      </Container>
      <Icon
        color="greytones.graphicsDark"
        marginLeft={1}
        paddingTop={'0px'}
        paddingRight={'5px'}
        alignSelf="flex-start"
        path={open ? icons.chevronUp : icons.chevronDown}
        size="16px"
      />
    </Container>
  );
};

export const PrefilledExpensesList: React.FC<PrefilledExpensesListProps> = ({
  translationKeysOpen,
  translationKeysClosed,
  expensesList,
  onDeleteExpenseItem,
  categoryKey,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const onPress = () => setOpen(!open);
  const { getTranslationText } = useItalyIntl();
  const { formatNumber } = useIntl();

  const translationKeysHeader = open ? translationKeysOpen : translationKeysClosed;

  const PrefilledExpensesListItems: React.FC<any> = ({ item, index }) => {
    const isLastItem = expensesList.length - 1 === index;

    const getDateAndIssuedBy = (item: DocumentsExpensesUploadedList) => {
      if (item.date && item.place) {
        return `${item.date} / ${item.place}`;
      } else if (item.place) {
        return item.place;
      } else if (item.date) {
        return item.date;
      } else {
        return getTranslationText('it.documents-uploaded.prefilled-expenses-list.total-amount');
      }
    };

    const amountFormated = formatNumber(item.amount, {
      currency: 'EUR',
      style: 'currency',
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      <Container
        flexDir="row"
        justifyContent="space-between"
        borderBottomWidth={isLastItem ? 0 : 1}
        borderBottomColor="greytones.graphicsLight"
        paddingY={16}
        paddingLeft={16}
        paddingRight={onDeleteExpenseItem ? 0 : 16}
      >
        <Text
          variant="titleXXSMedium"
          color="greytones.textLight"
          fontWeight={500}
          alignSelf="flex-start"
          flex={2}
        >
          {getDateAndIssuedBy(item)}
        </Text>
        <Text
          variant="bodyXSBook"
          color="greytones.textLight"
          fontWeight={450}
          flex={onDeleteExpenseItem ? 1 : 0}
        >
          {amountFormated}
        </Text>
        {onDeleteExpenseItem && (
          <IconButton
            variant="grey"
            onPress={() => onDeleteExpenseItem(categoryKey, item)}
            paddingTop={'0px'}
            paddingRight={'5px'}
            path={icons.bin}
            size="16px"
            justifyContent={'flex-end'}
            flex={1}
          />
        )}
      </Container>
    );
  };

  return (
    <Container style={[styles.container, open && styles.containerOpen]}>
      <TouchableOpacity onPress={onPress}>
        <PrefilledExpensesListHeader translationKeys={translationKeysHeader} open={open} />
      </TouchableOpacity>
      {open && (
        <FlatList
          data={expensesList}
          renderItem={PrefilledExpensesListItems}
          style={styles.flatList}
        />
      )}
    </Container>
  );
};
