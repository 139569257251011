import style from '../common/style.css';
import { isWeb } from '../../../src/utils/platform';

export default `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width; initial-scale=1.0;" />

    ${
      isWeb
        ? '<script type="text/javascript" src="/static/js/iframe-resizer/iframeResizer.contentWindow.min.js"></script>'
        : ''
    }

    <style type="text/css">
      ${style}

      #content {
        margin: 20px;
      }
    </style>
    ##CUSTOM_STYLES##
  </head>
  <body>
    <div id="content">
      ##CONTENT##
    </div>
  </body>
</html>
`;
