export const SHOW = 'overlay/SHOW';
export const HIDE = 'overlay/HIDE';
export const UPDATE = 'overlay/UPDATE';
export const HIDE_EOS_OVERLAY = 'overlay/HIDE_EOS_OVERLAY';

type ShowAction = {
  type: typeof SHOW;
  payload: {
    activeName: string;
    params: any;
  };
};
type HideAction = {
  type: typeof HIDE;
};
type UpdateAction = {
  type: typeof UPDATE;
  payload: {
    params: any;
  };
};
type HideEoSOverlay = {
  type: typeof HIDE_EOS_OVERLAY;
};

type Action = ShowAction | HideAction | UpdateAction | HideEoSOverlay;

const show = (activeName: string, params: any = {}): ShowAction => ({
  type: SHOW,
  payload: {
    activeName,
    params,
  },
});

const hide = (): HideAction => ({
  type: HIDE,
});

const update = (params: any): UpdateAction => ({
  type: UPDATE,
  payload: {
    params,
  },
});

const hideEoSOverlay = (): HideEoSOverlay => ({
  type: HIDE_EOS_OVERLAY,
});

export const actions = {
  show,
  hide,
  update,
  hideEoSOverlay,
};

type State = {
  readonly activeName: string;
  readonly params: Record<string, any>;
  hasDismissedEosOverlay: boolean;
};

export const initial: State = {
  activeName: '',
  params: {},
  hasDismissedEosOverlay: false,
};

export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        activeName: action.payload.activeName,
        params: action.payload.params,
      };

    case UPDATE:
      return { ...state, params: { ...state.params, ...action.payload.params } };

    case HIDE:
      return initial;

    case HIDE_EOS_OVERLAY:
      return {
        ...initial,
        hasDismissedEosOverlay: true,
      };

    default:
      return state;
  }
};

type RootState = {
  overlay: State;
};

const getActiveOverlayName = (state: RootState) => state.overlay.activeName;

const getHasDismissedEoSOverlay = (state: RootState): boolean =>
  state.overlay.hasDismissedEosOverlay;

export const selectors = {
  getActiveOverlayName,
  getHasDismissedEoSOverlay,
};
