import * as React from 'react';

import { useLogin, UseLogin, UseLoginProps } from 'src/hooks/auth/login-hook';

export type WithLogin = UseLogin;

type WithLoginWrapperType<T> = (props: Omit<T, keyof WithLogin>) => React.ReactElement<T>;

type Props<T> = UseLoginProps & T;

function withLogin<T extends WithLogin = WithLogin>(
  WrappedComponent: React.ComponentType<T>,
): WithLoginWrapperType<T & WithLogin> {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithLoginWrapper = (props: Omit<T, keyof WithLogin>) => {
    const { isLoading, errorKey, handleLogin, handleOtcLogin, handleSsoLogin, handleError } =
      useLogin(props as Props<T>);

    return (
      <WrappedComponent
        {...(props as T)}
        isLoading={isLoading}
        errorKey={errorKey}
        handleLogin={handleLogin}
        handleOtcLogin={handleOtcLogin}
        handleSsoLogin={handleSsoLogin}
        handleError={handleError}
      />
    );
  };

  WithLoginWrapper.displayName = `withLogin(${displayName})`;

  return WithLoginWrapper;
}

export default withLogin;
