import { validate } from 'email-validator';

// Does the user's input look vaguely like an email if you squint?
const isEmailLike = (input: string) => input !== '' && validate(input);

const passwordLength = 4;

const hasCredentials = (
  emailAddress: string | null | undefined,
  password: string | null | undefined,
) =>
  emailAddress != null &&
  isEmailLike(emailAddress) &&
  password != null &&
  password.length === passwordLength;

const maskEmail = (email?: string): string => {
  if (!email) return '';
  const [prefix, suffix] = email.split('@');
  const maskedPrefix = prefix
    .split('')
    .map((char, i) => {
      if (prefix.length < 4) return char;
      if (prefix.length > 3 && prefix.length < 6 && i < 2) return char;
      if (prefix.length > 5 && i < 4) return char;
      return '*';
    })
    .join('');
  return `${maskedPrefix}@${suffix}`;
};

export { hasCredentials, isEmailLike, passwordLength, maskEmail };
