const defaultStyle = { fontFamily: 'brand', color: 'greytones.textDefault' };

export const boldStyle = {
  fontWeight: 700,
};

export const mediumStyle = {
  fontWeight: 500,
};

export const bookStyle = {
  fontWeight: 450,
};

export const TextComponentVariants = {
  Text: {
    baseStyle: {
      ...defaultStyle,
      ...bookStyle,
      fontSize: 'xl',
      lineHeight: 'tight',
    },
    variants: {
      //Bold
      title2XLBold: {
        fontSize: '2xl',
        lineHeight: 'extremelyDistant',
        ...boldStyle,
      },
      titleXLBold: {
        fontSize: 'xl',
        lineHeight: 'extraDistant',
        ...boldStyle,
      },
      titleLBold: {
        fontSize: 'l',
        lineHeight: 'mediumDistant',
        ...boldStyle,
      },
      titleMBold: {
        fontSize: 'm',
        lineHeight: 'shortDistant',
        ...boldStyle,
      },
      titleSBold: {
        fontSize: 's',
        lineHeight: 'distant',
        ...boldStyle,
      },
      titleXSBold: {
        fontSize: 'xs',
        lineHeight: 'medium',
        ...boldStyle,
      },
      titleXXSBold: {
        fontSize: 'xxs',
        lineHeight: 'tight',
        ...boldStyle,
      },

      //Book
      titleMBook: {
        fontSize: 'm',
        lineHeight: 'intermediateDistant',
        ...bookStyle,
      },
      titleXSBook: {
        fontSize: 'xs',
        lineHeight: 'medium',
        ...bookStyle,
      },

      //Medium
      titleMMedium: {
        fontSize: 'm',
        lineHeight: 'shortDistant',
        ...mediumStyle,
      },
      titleSMedium: {
        fontSize: 's',
        lineHeight: 'distant',
        ...mediumStyle,
      },
      titleXSMedium: {
        fontSize: 'xs',
        lineHeight: 'medium',
        ...mediumStyle,
      },
      titleXXSMedium: {
        fontSize: 'xxs',
        lineHeight: 'tight',
        ...mediumStyle,
      },

      //Subtitle
      subtitleLMedium: {
        fontSize: 'sm',
        lineHeight: 'tightDistant',
        ...mediumStyle,
      },
      subtitleMBook: {
        fontSize: 'ms',
        lineHeight: 'distant',
        ...bookStyle,
      },
      subtitleMMedium: {
        fontSize: 'ms',
        lineHeight: 'distant',
        ...mediumStyle,
      },

      //Body
      bodyLBook: {
        fontSize: 's',
        lineHeight: 'distant',
        ...bookStyle,
      },
      bodyMBook: {
        fontSize: 'xs',
        lineHeight: 'medium',
        ...bookStyle,
      },
      bodySBook: {
        fontSize: 'xxs',
        lineHeight: 'short',
        ...bookStyle,
      },
      bodySBold: {
        fontSize: 'xxs',
        lineHeight: 'short',
        ...boldStyle,
      },
      bodyXSBook: {
        fontSize: 'b_xs',
        lineHeight: 'tight',
        ...bookStyle,
      },
      bodyXXSBook: {
        fontSize: 'b_xxs',
        lineHeight: 'extraTight',
        ...bookStyle,
      },
      bodyXXXSMedium: {
        fontSize: 'b_xxxs',
        lineHeight: 'extraTight',
        ...mediumStyle,
      },
      bodyXSMedium: {
        fontSize: 'b_xs',
        lineHeight: 'tight',
        ...mediumStyle,
      },
      bodyXSBold: {
        fontSize: 'b_xs',
        lineHeight: 'tight',
        ...boldStyle,
      },

      //Overline
      overlineM: {
        fontSize: 'b_xs',
        lineHeight: 'tight',
        letterSpacing: 'normal',
        color: 'greytones.graphicsDark',
        textTransform: 'uppercase',
        ...boldStyle,
      },
      overlineS: {
        fontSize: 'b_xxxs',
        lineHeight: 'extraTight',
        letterSpacing: 'normal',
        color: 'greytones.graphicsDark',
        textTransform: 'uppercase',
        ...boldStyle,
      },

      //link
      linkM: {
        fontSize: 'xs',
        lineHeight: 'medium',
        color: 'brand.brandGreen',
        ...mediumStyle,
      },
      linkS: {
        fontSize: 'xxs',
        lineHeight: 'short',
        color: 'brand.brandGreen',
        ...mediumStyle,
      },
      linkXS: {
        fontSize: 'b_xs',
        lineHeight: 'tight',
        color: 'brand.brandGreen',
        ...mediumStyle,
      },
    },
  },
};
