import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '../core';

import { CheckBox } from './checkbox';

type Props = {
  isChecked: boolean;
  onChange: (confirmed: boolean) => void;
  children: React.ReactNode;
  disabled?: boolean;
  testId?: string;
};
const styles = StyleSheet.create({
  linkableWrapper: {
    flex: 1,
    paddingBottom: 12,
  },
  link: {
    fontSize: 12,
  },
});

const CheckboxWithText = ({ isChecked, onChange, children, disabled = false, testId }: Props) => {
  return (
    <Box direction="row">
      <CheckBox
        testId={testId}
        checked={isChecked}
        onToggle={() => onChange(!isChecked)}
        disabled={disabled}
      />
      <Box style={styles.linkableWrapper}>{children}</Box>
    </Box>
  );
};

export { CheckboxWithText };
