import React from 'react';
import { Icon as NbIcon } from 'native-base';
import { Path } from 'react-native-svg';

import { IconProps } from './types';

export const Icon = ({
  size = 16,
  viewBox = '0 0 1024 1024',
  path,
  background,
  color = 'greytones.textDefault',
  ...otherProps
}: IconProps) => {
  return (
    <NbIcon size={size} background={background} viewBox={viewBox} {...otherProps}>
      {path && path.map((p, i) => <Path key={i} d={p} fill={color} />)}
    </NbIcon>
  );
};
