// Copy firstName, lastName, occupation, employer details to quizmaster for ITA users
// Redux store remains unchanged
import { CountryCodes } from '@taxfix/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { userProfileSelectors } from 'src/stores/modules/user-profile';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getQuestionStores } from 'src/stores-legacy/util';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { wayQuestionIds } from 'src/common/constants-it';
import { StatusMiddleware } from 'src/stores/modules/status';

export const profileMigration: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const selectedYear = settingsSelectors.selectedYear(getState());
    const { firstName, lastName, phoneNumber } = userProfileSelectors.getVersionedUserProfile(
      getState(),
    );
    const questionStores = getQuestionStores(CountryCodes.IT);
    const questionStore = selectedYear ? questionStores[selectedYear] : undefined;

    if (!questionStore) {
      return next();
    }

    // @ts-ignore
    const questionIds: string[] = [...Object.values(wayQuestionIds)];
    const nodes = getNodesByIds(questionStore, questionIds);
    const quizmasterLight = getQuizmasterLight(questionStore, nodes);

    if (!quizmasterLight[wayQuestionIds.firstName].answer && firstName.value) {
      quizmasterLight[wayQuestionIds.firstName].saveAnswer(firstName.value);
    }

    if (!quizmasterLight[wayQuestionIds.lastName].answer && lastName.value) {
      quizmasterLight[wayQuestionIds.lastName].saveAnswer(lastName.value);
    }

    if (quizmasterLight[wayQuestionIds.phoneNumber].answer == null && phoneNumber.value) {
      quizmasterLight[wayQuestionIds.phoneNumber].saveAnswer(phoneNumber.value);
    }
  } catch (error) {
    logger.log('Error in profile migration', error);
    return next();
  }

  return next();
};
