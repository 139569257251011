import { Platform, Linking } from 'react-native';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { CountryCodes } from '@taxfix/types';

import { NativeModules } from '../biz-logic/nativeModules';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { NavigationActions } from '../routes/config-util';
import { showAlert } from '../services/alert';

import { PostSubmissionScreenVariant, PostSubmissionScreenVariants } from './constants';

export type ContactSupportParams = {
  address: string;
  subject: string;
  errorTitle: string;
  errorMessage: string;
  body?: string;
};

type SendEmailCustomHandler = Pick<ContactSupportParams, 'address' | 'subject' | 'body'>;

const sendEncodedBody = async ({ address, subject, body }: SendEmailCustomHandler) => {
  const url = `mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body || '',
  )}`;
  const canOpen = await Linking.canOpenURL(url);

  if (!canOpen) {
    throw new Error('Provided URL can not be handled');
  }
  return Linking.openURL(url);
};

const sendEmailNoBody = async ({ address, subject, body }: SendEmailCustomHandler) => {
  const url = `mailto:${address}?subject=${encodeURIComponent(subject)}`;

  const canOpen = await Linking.canOpenURL(url);

  if (!canOpen) {
    throw new Error('Provided URL can not be handled');
  }
  return Linking.openURL(url);
};

const sendEmailCustomHandler = async ({ address, subject, body }: SendEmailCustomHandler) => {
  let url = `mailto:${address}`;
  const query = queryString.stringify({
    subject: subject,
    body: body,
  });
  const canOpen = await Linking.canOpenURL(url);

  if (!canOpen) {
    throw new Error('Provided URL can not be handled');
  }

  if (query.length) {
    url += `?${query}`;
  }

  return Linking.openURL(url);
};

const sendEmail = async (params: SendEmailCustomHandler, emailOption: number) => {
  switch (emailOption) {
    case 0:
      return sendEmailCustomHandler(params);
    case 1:
      return sendEmailNoBody(params);
    case 2:
      return sendEncodedBody(params);
  }
};

const getSupportEmail = (selectedCountry: CountryCodes): string =>
  `support@taxfix.${selectedCountry.toLocaleLowerCase()}`;

const getSupportPhoneLink = (phoneNumber: string): string => {
  if (Platform.OS === 'android' || Platform.OS === 'web') {
    return `tel:${phoneNumber}`;
  }

  return `telprompt:${phoneNumber}`;
};

const contactSupport = (
  { address, subject, errorTitle, errorMessage, body = '' }: ContactSupportParams,
  emailOption = 0,
): Promise<any | void> => {
  Analytics.log(AnalyticsEvent.requestSupportInitiated);
  return new Promise((resolve, reject) => {
    NativeModules.RNMail.mail(
      {
        subject,
        recipients: [address],
        body, // attachment: {
        //   path: '',  // The absolute path of the file from which to read data.
        //   type: '',   // Mime Type: jpg, png, doc, ppt, html, pdf
        //   name: '',   // Optional: Custom filename for attachment
        // }
      },
      (err: string) => {
        // @TODO: FRA-1870/AlecP  fallback logic if RNMail fails
        if (err) {
          sendEmail(
            {
              address,
              subject,
              body,
            },
            emailOption,
          ).catch(() => {
            showAlert({
              titleText: errorTitle,
              subtitleText: errorMessage,
            });
            return reject(err);
          });
        }
        // @ts-ignore
        return resolve();
      },
    );
  });
};

function convertTimezoneHourToUtc(hour: number, timeZone: string): number {
  return moment().set('hour', hour).tz(timeZone, true).utc().get('hour');
}

function getIsWorkingNow(
  workingHours: {
    start: number;
    end: number;
  }[],
  timeZone: string,
): boolean {
  const day: number = new Date().getUTCDay();
  const hour: number = new Date().getUTCHours();
  const { start, end } = workingHours[day];
  // We convert the CS working hours to UTC, based on CS operating office time zone,
  // and then compare with the current time. This way, the working hours do not need
  // to be modified when DST begins or ends.
  const startHour = start >= 0 ? convertTimezoneHourToUtc(start, timeZone) : start;
  const endHour = end >= 0 ? convertTimezoneHourToUtc(end, timeZone) : end;
  return !(hour < startHour || hour >= endHour);
}

export default contactSupport;
export { getSupportEmail, getIsWorkingNow, getSupportPhoneLink };
