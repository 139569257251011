import * as React from 'react';

import { ScreenName } from 'src/types/screen-name';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';

import { DebugCWBIUrlContainer } from './debug-cwbi-url-screen';

export const toDebugCWBIUrl: Action = navigate(ScreenName.DebugCWBIUrl, {
  name: AnalyticsEvent.sectionShown,
  params: { sectionName: ScreenName.DebugCWBIUrl },
});

export const DebugCWBIUrl = (): JSX.Element => {
  useDefaultNavigationOptions(undefined);

  return <DebugCWBIUrlContainer />;
};
