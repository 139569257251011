import * as React from 'react';
import { Keyboard } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Option } from '../../components/input/button-overlay';
import { actions as OverlayActions } from '../../stores/modules/overlay';

type Props = {
  overlayActions: typeof OverlayActions;
  options: Option[];
  onChange?: (value: string) => void;
  color?: string;
  children: (arg0: { handlePress: () => void }) => void;
  isSelectFullScreenHeight?: boolean;
  headerOverlay?: React.ElementType;
  footerOverlay?: React.ElementType;
  fullWidthOnWeb?: boolean;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

// ATTENTION: consider using HOOK useOverlayButtonsShow instead of HOC
class ButtonWithOverlayContainer extends React.Component<Props> {
  handleButtonPress = () => {
    const {
      overlayActions,
      options,
      onChange,
      color,
      isSelectFullScreenHeight,
      headerOverlay,
      footerOverlay,
      fullWidthOnWeb,
    } = this.props;
    Keyboard.dismiss();
    overlayActions.show('ButtonOverlay', {
      Header: headerOverlay,
      Footer: footerOverlay,
      color,
      options,
      onSelect: onChange,
      isSelectFullScreenHeight,
      fullWidthOnWeb,
    });
  };

  render() {
    return (
      <>
        {this.props.children({
          handlePress: this.handleButtonPress,
        })}
      </>
    );
  }
}

const ButtonWithOverlay = connect(null, mapDispatchToProps)(ButtonWithOverlayContainer);
export { ButtonWithOverlay };
