import { $Keys } from 'utility-types';
import { Answer, TreeNode } from '@taxfix/quizmaster/dist/types';
import _ from 'lodash';

import { QuestionAndAnswerIDPairType } from 'src/taxfix-business-logic/constants/question-answer';

import { payloadToId } from './payload-to-id';

export type Options = {
  year: string;
};
export type Store = {
  store: $Keys<typeof payloadToId>;
};
// @ts-ignore
export type Payload<FieldName> = Record<FieldName, Answer>;

export const saveLegacyData = async (
  payload: { [key: string]: unknown } = {},
  options: Options & Store,
): Promise<void> => {
  try {
    const mappedIDs: { [key: string]: QuestionAndAnswerIDPairType } = _.get(
      payloadToId,
      options.store,
    );

    if (!mappedIDs) throw new Error('store isnt defined');

    const answersToSave = _.map(payload, (answer, key) => {
      const mappedId = mappedIDs[key];

      if (!mappedId) {
        throw new Error(`Unable to find answer object store: "${options.store}" key: "${key}"`);
      }

      const { questionID: questionId, answerID: answerId } = mappedId;

      if (!questionId) {
        throw new Error(`Unable to find questionId store: "${options.store}" key: "${key}"`);
      }

      if (!answerId) {
        throw new Error(`Unable to find answerId store: "${options.store}" key: "${key}"`);
      }

      const node = {
        id: answerId,
        questionId,
      } as TreeNode;

      return {
        node,
        answer,
      };
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 0);
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
export const removeLegacyData = async (options: Options & Store): Promise<void> => {
  try {
    const mappedIDs = _.get(payloadToId, options.store);

    if (!mappedIDs) throw new Error('store isnt defined');

    const answersToRemove = _.map(mappedIDs, (ids: any) => ids.answerID);

    return new Promise((resolve) => {
      resolve();
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
