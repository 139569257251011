import { EppoSdk } from '@taxfix/eppo';

import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import {
  StatusMiddleware,
  StatusSkipFunction,
  StatusStepRedirect,
} from 'src/stores/modules/status';
import {
  prefillQuestionIds,
  flagsQuestionIds,
  TailoredExperienceStatus,
  questionFlowCategories,
} from 'src/common/constants-it';
import { getAnswer, updateAnswer } from 'src/stores-legacy/helpers';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { NavigateAction } from 'src/routes/navigate';

export const checkTailoredExperienceStatus: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const state = getState();
  const tailoredExperienceStatus = getAnswer(state, flagsQuestionIds.tailoredExperienceStatus);

  if (tailoredExperienceStatus === TailoredExperienceStatus.Completed) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const shouldSkipTailoredExperience: StatusSkipFunction = async (getState) => {
  const state = getState();
  const taxId = getAnswer(state, prefillQuestionIds.taxId); // To skip this flow for old users
  const tailoredExperienceStatus = getAnswer(state, flagsQuestionIds.tailoredExperienceStatus);
  const userId = userAuthSelectors.getUserId(state);

  if (taxId || !userId || tailoredExperienceStatus === TailoredExperienceStatus.Skipped) {
    return true;
  }

  return true; // should update this to false or remove the whole flow after the analysis of the experiment results
};

export const redirectTailoredExperience: StatusStepRedirect = (state, dispatch) => (navigation) => {
  const tailoredExperienceStatus = getAnswer(state, flagsQuestionIds.tailoredExperienceStatus);
  const navigationMap: Record<string, NavigateAction<any>> = {
    [TailoredExperienceStatus.Started]: getNavigationActions().toQuizMaster('screen', {
      root: questionFlowCategories.tailoredExperience,
      editing: false,
      skipFinalSummary: true,
      backNavigationText: 'it.tax-id-question-flow.come-back-button.text',
      onNext: () => {
        updateAnswer(
          flagsQuestionIds.tailoredExperienceStatus,
          TailoredExperienceStatus.FinishedQF,
          state,
        );
        getBoundNavigationsActions().toTailoredExperienceFinal('screen');
      },
    }),
    [TailoredExperienceStatus.FinishedQF]:
      getNavigationActions().toTailoredExperienceFinal('screen'),
  };

  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      navigationMap[tailoredExperienceStatus] ??
        getNavigationActions().toTailoredExperienceIntro('screen'),
    ],
  });
};
