import * as React from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { selectors as firebaseSelectors } from '../../stores/modules/remote-config-firebase';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { isNative, isWeb } from '../../utils/platform';
import { showAlert } from '../../services/alert';

type WhatsappSupportProps = {
  openWhatsappSupport: () => void;
  isWhatsappSupportEnabled: boolean;
};

export const useWhatsappSupport = (): WhatsappSupportProps => {
  const countryCode = useSelector(settingsSelectors.selectedCountry);
  const { webEnabled, mobEnabled, whatsappBusinessUrl } = useSelector((state) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    firebaseSelectors.getWhatsappSupportByCountry(state, countryCode),
  );
  const isWhatsappSupportEnabled = (isWeb && webEnabled) || (isNative && mobEnabled);
  const { phoneNumber } = useSelector((state) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    firebaseSelectors.getPhoneSupportByCountry(state, countryCode),
  );
  const { getTranslationText } = useItalyIntl();
  const openWhatsappSupport = React.useCallback(() => {
    Analytics.log(AnalyticsEvent.b_inapp_ContactSupportPressWhatsapp);
    Linking.canOpenURL(whatsappBusinessUrl)
      .then((supported) => {
        if (!supported) {
          const ALERT_TITLE = getTranslationText(
            'account.contact-support.whatsapp-unavailable.message-title',
          );
          const ALERT_MESSAGE = getTranslationText(
            'account.contact-support.whatsapp-unavailable.message-small',
            {
              phone: phoneNumber,
            },
          );
          showAlert({
            titleText: ALERT_TITLE,
            subtitleText: ALERT_MESSAGE,
          });
          return;
        }

        Linking.openURL(whatsappBusinessUrl);
      }) // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, [whatsappBusinessUrl, getTranslationText, phoneNumber]);

  return {
    openWhatsappSupport,
    isWhatsappSupportEnabled,
  };
};
