import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { Props } from './types';

export const LinkedInIconSolid: React.FC<Props> = ({
  fillColor = '#000',
  size = '100%',
}): JSX.Element => (
  <Icon viewBox="0 0 18 18" size={size}>
    <G fill="none">
      <Path
        d="M16.3125 0H1.6875C0.759375 0 0 0.759375 0 1.6875V16.3125C0 17.2406 0.759375 18 1.6875 18H16.3125C17.2406 18 18 17.2406 18 16.3125V1.6875C18 0.759375 17.2406 0 16.3125 0Z"
        fill="white"
      />
      <Path
        d="M16.3125 0H1.6875C0.759375 0 0 0.759375 0 1.6875V16.3125C0 17.2406 0.759375 18 1.6875 18H16.3125C17.2406 18 18 17.2406 18 16.3125V1.6875C18 0.759375 17.2406 0 16.3125 0ZM6.75 14.625H4.5V6.75H6.75V14.625ZM5.625 5.625C5.00273 5.625 4.5 5.12227 4.5 4.5C4.5 3.87773 5.00273 3.375 5.625 3.375C6.24727 3.375 6.75 3.87773 6.75 4.5C6.75 5.12227 6.24727 5.625 5.625 5.625ZM14.625 14.625H12.375V10.125C12.375 9.50273 11.8723 9 11.25 9C10.6277 9 10.125 9.50273 10.125 10.125V14.625H7.875V6.75H10.125V8.1457C10.5891 7.50938 11.2992 6.75 12.0938 6.75C13.493 6.75 14.625 8.00859 14.625 9.5625V14.625Z"
        fill={fillColor}
      />
    </G>
  </Icon>
);
