import { useIntl } from 'react-intl';

export const useNumberFormatter = (): ((num: number) => string) => {
  const { formatNumber } = useIntl();

  const getFormattedNumber = (num: number) =>
    `${formatNumber(num, {
      currency: 'EUR',
      style: 'currency',
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

  return getFormattedNumber;
};
