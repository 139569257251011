import { CountryCodes } from '@taxfix/types';

// App is working fine because App uses the bundle but Test does not.
let badwayQuestionStores: any;
export const setQuestionStores = (questionStoresIT: any) => {
  badwayQuestionStores = {
    [CountryCodes.IT]: questionStoresIT,
  };
};

export const getQuestionStores = (countryCode: CountryCodes) => {
  const store = badwayQuestionStores[countryCode];

  if (store) {
    return store;
  }

  throw new Error(`Question store is not available for ${countryCode}`);
};

export const getQuestionStoreForYear = (countryCode: CountryCodes, year: number) => {
  const questionStores = getQuestionStores(countryCode);
  const questionStore = questionStores[year];

  if (!questionStore) {
    throw new Error(`Question store for year ${year} is not available for ${countryCode}`);
  }

  return questionStore;
};
