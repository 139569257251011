import * as React from 'react';

import { numberLengthValidator } from 'src/components/answers/shared/validators/lengthValidator';

import ConfirmableInput from '../confirmable-input';

import NumberInput from './wrapper-number-input';

export const InputNumber = (props: any): JSX.Element => {
  return (
    <ConfirmableInput
      {...props}
      InputComponent={NumberInput}
      validator={props.validator || numberLengthValidator}
    />
  );
};

export default InputNumber;
