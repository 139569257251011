import _ from 'lodash';
import { IntlShape } from 'react-intl';
import { Platform } from 'react-native';

import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

const Keychain = Platform.OS !== 'web' && require('react-native-keychain');

type UserData = {
  emailAddress?: string | null;
  password?: string | null;
  isBioAuthEnabled?: boolean;
  updateBioAuthPermission?: (...args: Array<any>) => any;
  resetBioAuthPermission?: (...args: Array<any>) => any;
};
type UpdatePermissionsParams = {
  userStore: UserData;
  enableState: boolean;
  promptMsg: string;
  errorMsg: string;
};

/**
 * Helper functions for configuring the biometric authorization feature on iOS
 */
// keychain auth configuration
const getKeychainAuthOpts = (promptMsg: string): Record<string, any> => ({
  accessControl: Keychain ? Keychain.ACCESS_CONTROL.BIOMETRY_ANY : undefined,
  accessible: Keychain ? Keychain.ACCESSIBLE.WHEN_UNLOCKED : undefined,
  authenticationPrompt: promptMsg,
  authenticateType: Keychain ? Keychain.AUTHENTICATION_TYPE.BIOMETRICS : undefined,
});

// extract localized messages
const getLocalizedMessages = (intl: IntlShape) => ({
  bioAuthPromptMessage: intl.formatMessage({
    id: 'account.biometric.verification.prompt',
  }),
  errorSavingMsg: intl.formatMessage({
    id: 'account.biometric.saving.error',
  }),
});

// save user login and password to a keychain entry secured by biometric authorization,
// and updates the bio auth permission in the UserStore
const updatePermissions = async (
  params: UpdatePermissionsParams,
  resolve: (...args: Array<any>) => any,
) => {
  const { userStore, enableState, promptMsg, errorMsg } = params;
  const { updateBioAuthPermission, emailAddress, password } = userStore;
  let stateToSetup = enableState;

  if (updateBioAuthPermission && emailAddress && password) {
    if (stateToSetup) {
      try {
        const result = Keychain
          ? await Keychain.setGenericPassword(
              emailAddress,
              password,
              getKeychainAuthOpts(promptMsg),
            )
          : undefined;

        if (!result) {
          throw new Error(errorMsg);
        }
      } catch (err) {
        // if Keychain.setGenericPassword fails, then we should set useBioAuth permission to false
        // eslint-disable-next-line no-console
        console.warn(err);
        Analytics.log(AnalyticsEvent.bioAuthenticationError, {
          errorMessage: 'Keychain set credentials failed',
        });
        stateToSetup = false;
      }
    }

    if (stateToSetup) {
      Analytics.log(AnalyticsEvent.bioAuthenticationOptIn);
    } else {
      Analytics.log(AnalyticsEvent.bioAuthenticationOptOut);
    }

    updateBioAuthPermission(stateToSetup);
  }

  resolve();
};

const getSupportedBiometry = async () => {
  try {
    return Platform.OS === 'ios' && Keychain ? await Keychain.getSupportedBiometryType() : null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return null;
  }
};

const LogAnalyticsBioAuthLogin = (success: boolean) => {
  if (success) {
    Analytics.log(AnalyticsEvent.bioAuthenticationLoginSuccess);
  } else {
    Analytics.log(AnalyticsEvent.bioAuthenticationLoginFailed);
  }
};

const enableBioAuth = (userStore: UserData, intl: IntlShape): Promise<void> => {
  if (_.isNil(userStore) || _.isNil(intl) || Platform.OS === 'web') {
    return Promise.resolve();
  }

  const messages = getLocalizedMessages(intl);
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const biometryTypeSupported = await getSupportedBiometry();

    if (!_.isNil(biometryTypeSupported) && !_.isNil(userStore)) {
      updatePermissions(
        {
          userStore,
          enableState: true,
          promptMsg: messages.bioAuthPromptMessage,
          errorMsg: messages.errorSavingMsg,
        },
        resolve,
      );
    } else {
      resolve();
    }
  });
};

const getPassUsingBioAuth = async (
  userStore: UserData,
  intl: IntlShape,
): Promise<string | null> => {
  if (_.isNil(userStore) || _.isNil(intl) || Platform.OS === 'web') {
    return Promise.resolve(null);
  }

  const messages = getLocalizedMessages(intl);
  const authOptions = getKeychainAuthOpts(messages.bioAuthPromptMessage);
  const biometryTypeSupported = await getSupportedBiometry();

  if (!_.isNil(biometryTypeSupported) && userStore.isBioAuthEnabled) {
    try {
      // (proper fix soon: https://github.com/oblador/react-native-keychain/issues/214)
      // @ts-ignore
      const credentials = await Keychain.getGenericPassword(authOptions);
      LogAnalyticsBioAuthLogin(Boolean(credentials));
      return credentials ? credentials.password : null;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
      Analytics.log(AnalyticsEvent.bioAuthenticationError, {
        errorMessage: 'Keychain get credentials failed',
      });
      LogAnalyticsBioAuthLogin(false);
      return null;
    }
  } else {
    return null;
  }
};

const resetBioAuthState = (userStore: UserData) => {
  if (!_.isNil(userStore) && Platform.OS !== 'web') {
    const { resetBioAuthPermission, isBioAuthEnabled } = userStore;

    if (Platform.OS === 'ios' && isBioAuthEnabled && resetBioAuthPermission) {
      resetBioAuthPermission();
      if (Keychain) Keychain.resetGenericPassword();
    }
  }
};

export { enableBioAuth, getPassUsingBioAuth, resetBioAuthState };
