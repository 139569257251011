import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Linking } from 'react-native';
import { EppoSdk } from '@taxfix/eppo';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import {
  ProductBundleValues,
  flagsQuestionIds,
  YT_TUTORIAL_URL,
  eppoExperiment,
  SelectedProductBundleValues,
  PremiumProductBundle,
} from 'src/common/constants-it';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import {
  selectors as paymentSelectors,
  actions as paymentActions,
} from 'src/stores/modules/payment';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { isWeb } from 'src/utils/platform';
import { GTM } from 'src/biz-logic/analytics/gtm';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { ButtonName } from 'src/types/button-name';
import { Text } from 'src/taxfix-components/src';
import { useNumberFormatter } from 'src/utils/useNumberFormatter';

import { useItalyIntl, useQuizmasterLight } from '../_hooks';

import { ProductBundleComponent } from './product-bundle-component';
import { useProductBundleRecommendation } from './use-product-bundle-recommendation';
import { useProductPrices } from './use-product-prices';
import { SingleProductBundle } from './single-product-bundle-component';

enum ProductBundleScreenMode {
  prefillStart = 'prefillStart',
}

const runAnalytics = (
  selectedProductBundle: SelectedProductBundleValues,
  recommendedProductBundle: ProductBundleValues,
): void => {
  Analytics.log(AnalyticsEvent.buttonPressed, {
    buttonName: selectedProductBundle,
    screenName: ScreenName.ProductBundle,
    bundleRecommendation: recommendedProductBundle,
  });
  if (isWeb) {
    GTM.trackBundleProduct('buttonPressed', {
      category: ProductBundleScreenMode.prefillStart,
      value: selectedProductBundle,
    });
  }
  return;
};

export const ProductBundleContainer = (): JSX.Element => {
  const { instant: instantConfig, guided: guidedConfig } = useSelector(
    firebaseSelectors.getItalyProductsPaymentConfig,
  ).default;
  const {
    basics: { getStarted },
  } = useSelector(firebaseSelectors.getItalyTaxGuideArticles);
  const couponResult = useSelector(paymentSelectors.getCouponResult);
  const userId = useSelector(userAuthSelectors.getUserId);
  const productBundlesPrices = useProductPrices(instantConfig.fullPrice, guidedConfig.fullPrice);
  const recommendedProductBundle = useProductBundleRecommendation();
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
  const previousProductSelection = quizmasterLight[flagsQuestionIds.productBundleSelection]?.answer;

  const { getTranslationText } = useItalyIntl();
  const getFormattedNumber = useNumberFormatter();

  const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

  const {
    getNavigationActions,
    navigation,
    navigationActions,
    safeResetNavigation,
    navigationParams,
  } = useNavigation();
  const dispatch = useDispatch();
  const { isSingleGuidedBundle, isNoSpidFlow } = navigationParams;

  const checkAndRemoveCoupon = (selectedProductBundle: SelectedProductBundleValues) => {
    if (couponResult) {
      if (previousProductSelection !== selectedProductBundle) {
        dispatch(paymentActions.setCouponResult(undefined));
      }
    }
  };

  const onSelectPremium = () => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'premiumUnavailable',
      screenName: ScreenName.ProductBundle,
    });

    dispatch(
      overlayActions.show('PopupOverlay', {
        title: getTranslationText('it.product-bundle-screen.premium-popup.title'),
        description: getTranslationText('it.product-bundle-screen.premium-popup.description', {
          bold: getBoldText,
        }),
        onClose: () => {
          Analytics.log(AnalyticsEvent.buttonPressed, {
            buttonName: ButtonName.Return,
            screenName: 'premiumUnavailable',
          });
        },
        buttons: [
          {
            variant: 'primary',
            title: getTranslationText('it.product-bundle-screen.premium-popup.button.title', {
              priceFormated: getFormattedNumber(productBundlesPrices.guided),
            }),
            onPress: async () => {
              Analytics.log(AnalyticsEvent.buttonPressed, {
                buttonName: ButtonName.ContinueWithAdvanced,
                screenName: 'premiumUnavailable',
              });

              await quizmasterLight[flagsQuestionIds.productBundleSelection].saveAnswer(
                ProductBundleValues.guided,
              );
              navigationActions.toItalyPayment('screen');
              dispatch(overlayActions.hide());
            },
          },
        ],
      }),
    );
  };

  const onProductSelect = async (selectedProductBundle: SelectedProductBundleValues) => {
    runAnalytics(selectedProductBundle, recommendedProductBundle);
    const savePromises = [];
    checkAndRemoveCoupon(selectedProductBundle);
    if (selectedProductBundle === PremiumProductBundle.premium) {
      onSelectPremium();
      return;
    }
    savePromises.push(
      quizmasterLight[flagsQuestionIds.productBundleSelection].saveAnswer(selectedProductBundle),
    );
    await Promise.all(savePromises);
    if (selectedProductBundle === ProductBundleValues.instant) {
      navigationActions.toInstantFilingNextStepsScreen('screen', {
        onNext: () => navigationActions.toItalyPayment('screen'),
      });
    } else {
      navigationActions.toItalyPayment('screen');
    }
  };

  const onPressWatchVideo = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ProductBundle,
      buttonName: ButtonName.WatchVideo,
    });
    dispatch(
      overlayActions.show('TaxGuideOverlay', {
        url: YT_TUTORIAL_URL,
        onClose: () => {
          Analytics.log(AnalyticsEvent.buttonPressed, {
            screenName: ScreenName.ProductBundle,
            buttonName: ButtonName.Close,
          });
          dispatch(overlayActions.hide());
        },
        openLinkExternally: () => Linking.openURL(YT_TUTORIAL_URL),
      }),
    );
  };

  const onPressSingleProductCta = async () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ProductBundle,
      buttonName: ButtonName.Continue,
    });
    if (previousProductSelection !== ProductBundleValues.guided) {
      await quizmasterLight[flagsQuestionIds.productBundleSelection].saveAnswer(
        ProductBundleValues.guided,
      );
    }
    navigationActions.toItalyPayment('screen');
  };

  useEffect(() => {
    Analytics.log(AnalyticsEvent.sectionShown, {
      screenName: ScreenName.ProductBundle,
      bundleRecommendation: recommendedProductBundle,
    });
  }, [recommendedProductBundle]);

  const onPressBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    }
  };

  const showPremiumProductBundle = useMemo(() => {
    const { experimentKey, variants, defaultValue, targetAttributes } =
      eppoExperiment.premiumProductBundle;
    const assignment = EppoSdk.getAssignment(
      `${userId}`,
      experimentKey,
      variants,
      defaultValue,
      targetAttributes,
    );
    return assignment === 'true' ? true : false;
  }, [userId]);

  const onPressTutorialLink = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ProductBundle,
      buttonName: ButtonName.GoToTutorial,
    });
    dispatch(
      overlayActions.show('TaxGuideOverlay', {
        url: getStarted,
        onClose: () => {
          Analytics.log(AnalyticsEvent.buttonPressed, {
            screenName: ScreenName.ProductBundle,
            buttonName: ButtonName.Close,
          });
          dispatch(overlayActions.hide());
        },
        openLinkExternally: () => Linking.openURL(getStarted),
      }),
    );
  };

  const getDescription = () => {
    const getLink = (text: string) => (
      <Text value={text} underline={true} onPress={onPressTutorialLink} />
    );

    return getTranslationText('it.product-bundle-screen.experiment.description.select-plan', {
      link: getLink,
    });
  };

  return isSingleGuidedBundle ? (
    <SingleProductBundle
      isNoSpidFlow={isNoSpidFlow}
      productBundlesPrices={productBundlesPrices}
      recommendedProductBundle={recommendedProductBundle}
      onPressBack={onPressBack}
      onPressPrimary={onPressSingleProductCta}
      onPressSecondary={onPressWatchVideo}
    />
  ) : (
    <ProductBundleComponent
      onProductSelect={onProductSelect}
      onBackButtonPress={onPressBack}
      productBundlesPrices={productBundlesPrices}
      recommendedProductBundle={recommendedProductBundle}
      showPremiumProduct={showPremiumProductBundle}
      description={!showPremiumProductBundle && getDescription()}
    />
  );
};
