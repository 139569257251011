import React from 'react';
import { get } from 'lodash';
import { EventArg } from '@react-navigation/core';
import {
  createStackNavigator as createRootStackNavigator,
  StackNavigationOptions,
  TransitionPresets,
} from '@react-navigation/stack';
import { CountryCodes } from '@taxfix/types';

import { TabNames as ItalyTabNames } from 'src/common/constants-it';
import { isWeb } from 'src/utils/platform';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import withSafeAreaView from '../hocs/with-safe-area-view';
import { Box } from '../components/core';
import { getScreenOptions } from '../utils/getScreenOptions';
import { startPerformanceTrace } from '../services/firebase-performance';
import { PerformanceLabels } from '../services/firebase-performance.labels';

import { Header } from './header';
import { createStackNavigator as createScreenStackNavigator } from './stack-navigator';
import { CountryRoutesConfigType, ScreenConfig } from './navigate';
import { createItalyNavigator } from './italy-navigation';
import { italyCommercialistaTabScreens, italyTaxGuideTabScreens } from './intl/it/config-it';
import { getNavigationActions, safeResetNavigation } from './config-util';
import { itPublicRoutes } from './intl/it/routes';

const ScreenStackNavigator = createScreenStackNavigator();
const RootStackNavigator = createRootStackNavigator();

const ItalyTabNavigator = createItalyNavigator();
const ItalyDashboardStackNavigator = createRootStackNavigator();
const ItalyTaxGuideStackNavigator = createRootStackNavigator();
const ItalyCommercialistaStackNavigator = createRootStackNavigator();

const getUseKeyboardAwareSafeView = (config: ScreenConfig): boolean =>
  get(config, 'params.useKeyboardAwareSafeView', true);

const trackNavigationStart = (e: EventArg<'blur' | 'beforeRemove', any, any>) => {
  const target = e.target || '';
  const [origin] = target.replace('screen/', '').split('-');
  startPerformanceTrace(PerformanceLabels.screenNavigation, { origin });
};

type InitialRouteAndParams = {
  initialRouteName: string;
  initialParams: Record<string, unknown>;
};

const getInitialRouteAndParamsFromPath = (country?: CountryCodes): InitialRouteAndParams => {
  const defaultRouteAndParams = {
    initialRouteName: 'Home',
    initialParams: {},
  };
  if (!isWeb) return defaultRouteAndParams;

  // We go through all configured routes/paths pairs to try and resolve the initial route and params
  try {
    // First entry from "/" split is always an empty string
    const [_, screenUrl] = window.location.pathname.split('/');

    if (country === CountryCodes.IT) {
      if (itPublicRoutes[screenUrl]) {
        return {
          initialRouteName: itPublicRoutes[screenUrl],
          initialParams: {},
        };
      }
    }
  } catch (error) {
    logger.error(error as Error, {
      message: 'Error when trying to resolve initial route and params',
    });
  }

  return defaultRouteAndParams;
};

const buildStack = (
  screens: CountryRoutesConfigType['screens'],
  defaultScreenOptions: StackNavigationOptions,
  StackNavigator: any,
  country?: CountryCodes,
) =>
  screens.map(([routeName, screenConfig]) => {
    let Screen;

    if ('stack' in screenConfig) {
      const Stack = screenConfig.stack;

      // eslint-disable-next-line react/display-name
      Screen = () => (
        <Stack.Navigator screenOptions={defaultScreenOptions}>
          {buildStack(screenConfig.screens, defaultScreenOptions, Stack)}
        </Stack.Navigator>
      );
    } else if (screenConfig.screen) {
      const showScreenWithTestID = Boolean(isWeb || get(screenConfig, 'params.hideSafeArea'));
      const ScreenComponent = screenConfig.screen;

      const ScreenWithTestId = (props: any) => (
        <Box flex testId={`screen/${routeName}`}>
          <ScreenComponent {...props} />
        </Box>
      );

      Screen = showScreenWithTestID
        ? ScreenWithTestId
        : withSafeAreaView(ScreenWithTestId, getUseKeyboardAwareSafeView(screenConfig));
    }

    const HeaderComponent = screenConfig.options?.header || Header;
    const headerWithTestId = (props: any) => (
      <Box flex={isWeb} testId={`header/${routeName}`}>
        {/*// @ts-ignore*/}
        <HeaderComponent {...props} />
      </Box>
    );

    return (
      <StackNavigator.Screen
        key={routeName}
        name={`screen/${routeName}`}
        component={Screen}
        initialParams={screenConfig.params}
        options={{ ...screenConfig.options, header: headerWithTestId }}
        listeners={{
          blur: trackNavigationStart,
          beforeRemove: trackNavigationStart,
        }}
      />
    );
  });

export const buildMainStack = (
  screens: CountryRoutesConfigType['screens'],
  defaultScreenOptions: StackNavigationOptions,
  StackNavigator: typeof ScreenStackNavigator,
  country?: CountryCodes,
): [routeName: string, stack: React.ComponentType<any>][] => {
  const screenStacks = buildStack(screens, defaultScreenOptions, StackNavigator, country);

  return screens.map(([routeName]) => [
    routeName,
    () => (
      <StackNavigator.Navigator
        key={routeName}
        initialRouteName={`screen/${routeName}`}
        screenOptions={defaultScreenOptions}
      >
        {screenStacks}
      </StackNavigator.Navigator>
    ),
  ]);
};

// Added Italy tab stack
//TODO: add more stacks for different tabs (Guide, Commercialista and Chat)
const buildTabStack = (
  screens: CountryRoutesConfigType['screens'],
  defaultScreenOptions: StackNavigationOptions,
  Navigator: typeof ItalyDashboardStackNavigator,
) => {
  const screenOptions = getScreenOptions(defaultScreenOptions);
  const italyScreenOptions = { safeAreaInsets: { top: 0, bottom: 0 }, ...screenOptions };

  const stack = buildMainStack(screens, italyScreenOptions, ScreenStackNavigator);

  const tabStack = () => (
    <Navigator.Navigator
      screenOptions={getScreenOptions({
        gestureEnabled: false,
      })}
    >
      <Navigator.Group screenOptions={{ presentation: 'modal' }}>
        {stack.map(([routeName, Stack]) => (
          <Navigator.Screen
            options={{
              headerShown: false,
            }}
            key={`modal/${routeName}`}
            name={`modal/${routeName}`}
            component={Stack}
          />
        ))}
      </Navigator.Group>
    </Navigator.Navigator>
  );

  return tabStack;
};

export const buildItalyTabsStack = (
  screens: CountryRoutesConfigType['screens'],
  defaultScreenOptions: StackNavigationOptions,
) => {
  const Dashboard = buildTabStack(
    screens,
    getScreenOptions(defaultScreenOptions),
    ItalyDashboardStackNavigator,
  );

  const TaxGuide = buildTabStack(
    italyTaxGuideTabScreens,
    getScreenOptions(defaultScreenOptions),
    ItalyTaxGuideStackNavigator,
  );

  const Commercialista = buildTabStack(
    italyCommercialistaTabScreens,
    getScreenOptions(defaultScreenOptions),
    ItalyCommercialistaStackNavigator,
  );

  const Tabs = (
    <ItalyTabNavigator.Navigator
      screenOptions={getScreenOptions({
        gestureEnabled: false,
      })}
    >
      <ItalyTabNavigator.Screen
        key={ItalyTabNames.Dashboard}
        name={ItalyTabNames.Dashboard}
        component={Dashboard}
        initialParams={{
          iconName: 'italy-tab-dashboard-icon',
          translationKey: 'it.tab.dashboard',
          onResetStack: () => {
            safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
          },
        }}
      />
      <ItalyTabNavigator.Screen
        key={ItalyTabNames.TaxGuide}
        name={ItalyTabNames.TaxGuide}
        component={TaxGuide}
        initialParams={{
          iconName: 'italy-tab-tax-guide-icon',
          translationKey: 'it.tab.tax-guide',
        }}
      />
      <ItalyTabNavigator.Screen
        key={ItalyTabNames.Experts}
        name={ItalyTabNames.Experts}
        component={Commercialista}
        initialParams={{
          iconName: 'italy-tab-commercialista-icon',
          translationKey: 'it.tab.experts',
        }}
      />
    </ItalyTabNavigator.Navigator>
  );

  return () => Tabs;
};

export default (
  screens: CountryRoutesConfigType['screens'],
  defaultScreenOptions: StackNavigationOptions,
  country: CountryCodes,
): JSX.Element => {
  const mainStack = buildMainStack(
    screens,
    getScreenOptions(defaultScreenOptions),
    ScreenStackNavigator,
    country,
  );
  const { initialRouteName } = getInitialRouteAndParamsFromPath(country);

  // eslint-disable-next-line react/display-name
  const routes = mainStack.map(([routeName, Stack]) => (
    <RootStackNavigator.Screen
      options={{
        headerShown: false,
      }}
      key={`modal/${routeName}`}
      name={`modal/${routeName}`}
      component={Stack}
    />
  ));
  return (
    <RootStackNavigator.Navigator
      initialRouteName={`modal/${initialRouteName}`}
      screenOptions={getScreenOptions({ gestureEnabled: false })}
    >
      <RootStackNavigator.Group
        screenOptions={{ presentation: 'card', ...TransitionPresets.ModalSlideFromBottomIOS }}
      >
        {routes}
      </RootStackNavigator.Group>
    </RootStackNavigator.Navigator>
  );
};
