import { Platform } from 'react-native';
import { CountryCodes } from '@taxfix/types';

const analytics = Platform.OS !== 'web' && require('@react-native-firebase/analytics').default;

const FirebaseUserPropertyKeys = Object.freeze({
  RandomNumber: 'RandomNumber',
  TaxCountry: 'TaxCountry',
});
// react-native-firebase requires the value type as string only
type FirebaseUserProperties = {
  RandomNumber?: string;
  TaxCountry?: CountryCodes;
};

const setFirebaseUserProperties = (params: FirebaseUserProperties) => {
  if (analytics) analytics().setUserProperties(params);
};

export { FirebaseUserPropertyKeys, setFirebaseUserProperties };
