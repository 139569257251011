import { Box } from 'native-base';
import React from 'react';

import { Container, Image, ImageProps } from 'src/taxfix-components/src';

import { BigDisplayTitleText, BodyPrimaryText, Link, theme } from '../core';

const styles = {
  centeredText: {
    textAlign: 'center' as const,
  },
};
type LinkProps = {
  text?: string;
  onPress: () => void;
};

const PageLink = ({ text, onPress }: LinkProps) => {
  if (text) {
    return (
      <Box top={1.5}>
        <Link style={styles.centeredText} onPress={onPress} id={text} />
      </Box>
    );
  }

  return null;
};

type Props = {
  title?: string;
  content?: string;
  linkText?: string;
  onLinkPress?: () => void;
  imageProps: ImageProps;
  children?: React.ReactNode;
};

const Page: React.FC<Props> = ({
  title,
  content,
  imageProps,
  linkText,
  onLinkPress = () => {},
}) => {
  const { height = 180, width = 180 } = imageProps;

  return (
    <Container alignItems="center" justifyContent="center" paddingTop={120}>
      <Image {...imageProps} height={height} width={width} />
      <Box mb={24}>
        <BigDisplayTitleText style={{ ...styles.centeredText, fontSize: 24 }} id={title} />
      </Box>
      <Box mb={24} paddingX={24}>
        <BodyPrimaryText
          style={[styles.centeredText, { color: theme.color.greytonesText }]}
          id={content}
        />
      </Box>
      <PageLink text={linkText} onPress={onLinkPress} />
    </Container>
  );
};

export default Page;
