import React, { Component } from 'react';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { InquiryFeelingComponent } from '../../components/FeedbackModal/InquiryFeeling';
import { InquiryNPSComponent } from '../../components/FeedbackModal/InquiryNPS';

import {
  FeedbackInquiryType,
  FeedbackResultType,
  feedbackInquiryTypes,
  feedbackResultTypes,
} from './FeedbackModal.types';

type Props = {
  inquiryType: FeedbackInquiryType;
  year: number;
  onResult: (arg0: FeedbackResultType | any) => void;
  onDismiss: () => void;
};
export class FeedbackInquiry extends Component<Props> {
  componentDidMount() {
    const { inquiryType, year } = this.props;
    Analytics.log(AnalyticsEvent.feedbackModalInitiated, {
      year,
      modalType: inquiryType === feedbackInquiryTypes.NPS ? 'nps' : null,
    });
  }

  handleHappyPressed = () => this.props.onResult(feedbackResultTypes.HAPPY);

  handleDisappointedPressed = () => this.props.onResult(feedbackResultTypes.DISAPPOINTED);

  handleScoreGiven = (score: number) => {
    const { onResult } = this.props;
    Analytics.log(AnalyticsEvent.feedbackScoreGiven, {
      npsScore: score,
    });

    if (score >= 9) {
      onResult(feedbackResultTypes.PROMOTER);
    } else if (score >= 7) {
      onResult(feedbackResultTypes.PASSIVE);
    } else {
      onResult(feedbackResultTypes.DETRACTOR);
    }
  };

  render() {
    const { inquiryType, onDismiss } = this.props;

    switch (inquiryType) {
      case feedbackInquiryTypes.NPS:
        return <InquiryNPSComponent onScoreGiven={this.handleScoreGiven} onDismiss={onDismiss} />;

      case feedbackInquiryTypes.FEELING:
        return (
          <InquiryFeelingComponent
            onHappyPressed={this.handleHappyPressed}
            onDisappointedPressed={this.handleDisappointedPressed}
            onDismiss={onDismiss}
          />
        );

      default:
        return null;
    }
  }
}
