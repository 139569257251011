/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function setupReactotron(): any {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Reactotron.configure().use(reactotronRedux()).connect();
}

export default setupReactotron;
