import * as React from 'react';
import { ScrollView } from 'react-native';
import { compose } from 'redux';
import { useBreakpointValue } from 'native-base';

import { NavButton, Container } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { useNavigation } from 'src/hooks/navigation-hook';

import { SimpleQFLayout } from '../../components/simple-qf-layout';
import FormField from '../../components/form-field-extended';
import {
  isEmptyInput,
  nameValidator,
  getSanitizedInput,
  getNameFieldErrorKeys,
  NameFieldErrorKey,
} from '../../validations/italy-input-fields';
import { Box, TextInput, theme } from '../../components/core';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';
import { wayQuestionIds } from '../../common/constants-it';

type FormError = NameFieldErrorKey & { values: { chars: string | undefined } };

type FormErrors = {
  firstName?: FormError;
  lastName?: FormError;
};

type ErrorState = FormErrors | Record<string, never>;

type Props = {
  onNext: () => void;
  progress: number;
  quizmasterLight: QuizmasterLight;
  isNewUser: boolean;
  backButtonText?: string;
};

const inputTitleStyle = [
  {
    fontSize: 14,
    color: theme.color.greyFillIcon,
  },
];

const ItalyDigiCafProfilePersonalDataNotConnected = ({
  onNext,
  progress,
  quizmasterLight,
  isNewUser,
  backButtonText,
}: Props) => {
  const [firstName, setFirstName] = React.useState(
    quizmasterLight[wayQuestionIds.firstName].answer || '',
  );
  const [lastName, setLastName] = React.useState(
    quizmasterLight[wayQuestionIds.lastName].answer || '',
  );
  const [errors, setErrors] = React.useState<ErrorState>({});

  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const { getTranslationText } = useItalyIntl();
  const { navigation } = useNavigation();

  React.useEffect(() => {
    const sanitizedFirstName = getSanitizedInput(firstName);
    const sanitizedLastName = getSanitizedInput(lastName);
    setFirstName(sanitizedFirstName);
    setLastName(sanitizedLastName);
  }, [firstName, lastName]);

  const validate = React.useCallback(() => {
    const formErrors: ErrorState = {};
    const firstNameError = nameValidator(firstName);
    const isFirstNameEmpty = isEmptyInput(firstName);

    if (!firstNameError.isValid || isEmptyInput(firstName)) {
      formErrors.firstName = {
        ...getNameFieldErrorKeys(isFirstNameEmpty, 'first-name'),
        values: { chars: firstNameError.validationErrors },
      };
    }

    const lastNameError = nameValidator(lastName);
    const isLastNameEmpty = isEmptyInput(lastName);

    if (!lastNameError.isValid || isEmptyInput(lastName)) {
      formErrors.lastName = {
        ...getNameFieldErrorKeys(isLastNameEmpty, 'last-name'),
        values: { chars: lastNameError.validationErrors },
      };
    }

    return formErrors;
  }, [firstName, lastName]);

  const handleOnContinue = React.useCallback(() => {
    setErrors({});
    const formErrors = validate();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    quizmasterLight[wayQuestionIds.firstName].saveAnswer(firstName);
    quizmasterLight[wayQuestionIds.lastName].saveAnswer(lastName);

    if (onNext) {
      onNext();
    }
  }, [firstName, lastName, onNext, quizmasterLight, validate]);

  return (
    <>
      {!isSmallScreen && !isNewUser && navigation.canGoBack() && backButtonText && (
        <Container marginRight="10px">
          <NavButton
            text={getTranslationText(backButtonText)}
            onPress={() => navigation.goBack()}
          />
        </Container>
      )}
      <SimpleQFLayout
        hideProgressBar={!isNewUser}
        headerTranslationKey={
          isNewUser
            ? 'it.digital-caf.qf.profile.personal-data.screen.title.new-user'
            : 'it.digital-caf.qf.profile.personal-data.screen.title'
        }
        subheaderTranslationKey="it.digital-caf.qf.returning-user.personal-data.screen.subheader"
        flowProgress={progress}
        onPressContinue={handleOnContinue}
        continueButtonTranslationKey="it.digital-caf.qf.profile.personal-data.screen.continue-button"
        centered={!isNewUser}
        size={isNewUser ? 'narrow' : 'wide'}
        isBackButtonHidden={!isNewUser}
        isContinueDisabled={!firstName || !lastName}
      >
        <Box flex top={2}>
          <ScrollView keyboardShouldPersistTaps="handled">
            <Box bottom={3}>
              <FormField
                label={{
                  key: 'it.digital-caf.qf.profile.personal-data.screen.firstName',
                }}
                error={errors.firstName ? { ...errors.firstName, level: 'Error' } : undefined}
                labelStyle={inputTitleStyle}
                fieldBoxStyle={{
                  backgroundColor: theme.color.background,
                  maxWidth: 380,
                }}
              >
                {({ onBlur, onFocus }) => (
                  <TextInput
                    value={firstName}
                    onChangeText={setFirstName}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    style={{
                      flex: 1,
                    }}
                    testId="text::profilePersonalData::firstName"
                    maxLength={86}
                    autoFocus
                    tabIndex={0}
                  />
                )}
              </FormField>
            </Box>
            <Box bottom={3}>
              <FormField
                label={{
                  key: 'it.digital-caf.qf.profile.personal-data.screen.lastName',
                }}
                error={errors.lastName ? { ...errors.lastName, level: 'Error' } : undefined}
                labelStyle={inputTitleStyle}
                fieldBoxStyle={{
                  backgroundColor: theme.color.background,
                  maxWidth: 380,
                }}
              >
                {({ onBlur, onFocus }) => (
                  <TextInput
                    value={lastName}
                    onChangeText={setLastName}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    style={{
                      flex: 1,
                    }}
                    testId="text::profilePersonalData::lastName"
                    maxLength={86}
                    autoFocus={false}
                    tabIndex={0}
                  />
                )}
              </FormField>
            </Box>
          </ScrollView>
        </Box>
      </SimpleQFLayout>
    </>
  );
};

export const ItalyDigiCafProfilePersonalData = compose(
  withQuizmasterLight([wayQuestionIds.firstName, wayQuestionIds.lastName]),
)(ItalyDigiCafProfilePersonalDataNotConnected as React.FC);
