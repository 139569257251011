import { isAfter } from 'date-fns';

import {
  TaxSeason,
  UnsupportedCase,
  selectors as remoteConfigFirebaseSelectors,
} from '../stores/modules/remote-config-firebase';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as devToolsSelectors, ConfigKeys } from '../stores/modules/dev-tools';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';

import { getCurrentTaxYearForCountry } from './country-and-year';

export type ParsedTaxSeason = TaxSeason & {
  endDateSoft: Date;
  endDateHard: Date;
  beforeSoftEnd: boolean;
  afterSoftEnd: boolean;
  afterEndFiling: boolean;
  beforeHardEnd: boolean;
  afterHardEnd: boolean;
  betweenSoftHardEnd: boolean;
  betweenHardFilingEnd: boolean;
  afterDirectDebitEnd: boolean;
  afterFilingEnd: boolean;
  beforeStartDateSoft: boolean;
  isPreSeason: boolean;
  isOffSeason: boolean;
  isInSeason: boolean;
};

export const parseTaxSeason = (taxSeason: TaxSeason, forceInTaxSeason = false): ParsedTaxSeason => {
  const nowTs = new Date().getTime();
  const startDateSoft = new Date(taxSeason.startDateSoft);
  const startDateHard = new Date(taxSeason.startDateHard);
  const endDateSoft = new Date(taxSeason.endDateSoft);
  const endDateHard = new Date(taxSeason.endDateHard);
  const endFilingDate = new Date(taxSeason.endFilingDate || taxSeason.endDateHard);
  const endDateSoftTs = endDateSoft.getTime();
  const endDateHardTs = endDateHard.getTime();
  const startDateSoftTs = startDateSoft.getTime();
  const startDateHardTs = startDateHard.getTime();

  const endFilingDateTs = endFilingDate.getTime();

  let directDebitEndTs;
  if (taxSeason.directDebitEndDate != null) {
    const directDebitEndDate = new Date(taxSeason.directDebitEndDate);
    directDebitEndTs = directDebitEndDate.getTime();
  }

  let beforeSoftEnd = false;
  let afterSoftEnd = false;
  let beforeHardEnd = false;
  let afterHardEnd = false;
  let afterEndFiling = false;
  let betweenSoftHardEnd = false;
  let betweenHardFilingEnd = false;
  let afterDirectDebitEnd = false;
  let afterFilingEnd = false;
  let beforeStartDateSoft = false;
  let isPreSeason = false;
  let isOffSeason = false;
  let isInSeason = false;

  if (!forceInTaxSeason) {
    if (nowTs < endDateSoftTs) {
      beforeSoftEnd = true;
    }

    if (nowTs >= endDateSoftTs) {
      afterSoftEnd = true;
    }

    if (nowTs < endDateHardTs) {
      beforeHardEnd = true;
    }

    if (nowTs >= endDateHardTs) {
      afterHardEnd = true;
    }

    if (directDebitEndTs != null && nowTs >= directDebitEndTs) {
      afterDirectDebitEnd = true;
    }

    if (nowTs >= endFilingDateTs) {
      afterEndFiling = true;
    }

    if (nowTs < startDateSoftTs) {
      beforeStartDateSoft = true;
    }

    if (afterSoftEnd && beforeHardEnd) {
      betweenSoftHardEnd = true;
    }

    if (afterHardEnd && !afterEndFiling) {
      betweenHardFilingEnd = true;
    }

    if (nowTs > endFilingDateTs) {
      afterFilingEnd = true;
    }

    if (nowTs >= startDateSoftTs && nowTs < startDateHardTs) {
      isPreSeason = true;
    }

    if (afterHardEnd || beforeStartDateSoft) {
      isOffSeason = true;
    }

    if (nowTs >= startDateHardTs && nowTs < endDateHardTs) {
      isInSeason = true;
    }
  } else {
    beforeSoftEnd = true;
    beforeHardEnd = true;
  }

  return {
    ...taxSeason,
    endDateSoft,
    endDateHard,
    beforeSoftEnd,
    afterSoftEnd,
    beforeHardEnd,
    afterHardEnd,
    afterEndFiling,
    endFilingDate,
    betweenSoftHardEnd,
    betweenHardFilingEnd,
    beforeStartDateSoft,
    isPreSeason,
    isOffSeason,
    isInSeason,
    afterDirectDebitEnd,
    afterFilingEnd,
  };
};

export const getTaxSeasonConfig = (
  state: Record<string, any>,
  year?: number | null | undefined,
): TaxSeason => {
  const selectedCountry = settingsSelectors.selectedCountry(state as any);
  const taxYear =
    year ||
    settingsSelectors.selectedYear(state as any) ||
    getCurrentTaxYearForCountry(selectedCountry);

  if (taxYear == null) {
    throw new Error('tax year could not be determined');
  }

  const taxSeasons = remoteConfigFirebaseSelectors.getTaxSeasonByCountryCodes(
    state as any,
    selectedCountry,
  );
  const taxSeason: TaxSeason | null | undefined = taxSeasons?.[taxYear.toString()];

  if (!taxSeason) {
    throw new Error('no tax season config for year');
  }

  return taxSeason;
};
export const getTaxSeasonUnsupportedCases = (state: Record<string, any>): UnsupportedCase[] => {
  const taxSeason: TaxSeason = getTaxSeasonConfig(state);
  return taxSeason.metadata?.unsupportedCases || [];
};
export const getTaxSeasonAvailableProducts = (state: any): string[] => {
  const taxSeason: TaxSeason = getTaxSeasonConfig(state);
  return taxSeason.metadata?.availableProducts || [];
};
export const isTaxSeasonTrafficBlocked = (state: Record<string, any>): boolean => {
  const taxSeason: TaxSeason = getTaxSeasonConfig(state);
  return !!taxSeason.metadata?.trafficBlocked;
};
// no memoize because we compare with Date.now
export const getTaxSeasonInfo = (
  state: Record<string, any>,
  year?: number | null | undefined,
): ParsedTaxSeason => {
  const config = getTaxSeasonConfig(state, year);
  const forceInTaxSeason = devToolsSelectors.getConfig(state as any, ConfigKeys.ForceInTaxSeason);
  return parseTaxSeason(config, forceInTaxSeason);
};
// no memoize because we compare with Date.now
export const isBetweenSoftHardEnd = (state: Record<string, any>): boolean => {
  const { betweenSoftHardEnd } = getTaxSeasonInfo(state);
  return betweenSoftHardEnd;
};
// no memoize because we compare with Date.now
export const shouldShowTaxYearEndBanner = (
  state: Record<string, any>,
  year?: number | null | undefined,
): boolean => {
  const taxSeason: TaxSeason = getTaxSeasonConfig(state, year);
  const startDate = taxSeason?.metadata?.taxYearEndBannerStartDate;

  if (startDate) {
    return isAfter(new Date(), startDate);
  }

  return false;
};
// no memoize because we compare with Date.now
export const shouldShowTaxAuthorityDeadlineBanner = (
  state: Record<string, any>,
  year?: number | null | undefined,
): boolean => {
  const taxSeason: TaxSeason = getTaxSeasonConfig(state, year);
  const startDate = taxSeason?.metadata?.taxYearAuthorityDeadlineBannerStartDate;

  if (startDate) {
    return isAfter(new Date(), startDate);
  }

  return false;
};
// check if the user registered out or during the tax season
export const isUserRegisteredBeforeHardEndOfSeason = (state: Record<string, any>): boolean => {
  const registrationDate = userAuthSelectors.getUserActivatedAt(state as any);

  if (!registrationDate) {
    return false;
  }

  const { endDateHard } = getTaxSeasonInfo(state);
  return registrationDate.getTime() < endDateHard.getTime();
};
