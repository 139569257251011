import { Pressable } from 'native-base';
import React from 'react';

import { Container, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const RadioOptionButton: React.FC<Props> = ({
  option,
  isSelected,
  hasBorderBottom = false,
  onPress,
  ...otherProps
}): JSX.Element => {
  return (
    <Pressable onPress={() => onPress(option.value)}>
      <Container
        borderTopWidth={1}
        borderBottomWidth={hasBorderBottom ? 1 : 0}
        borderColor="greytones.graphicsLight"
        height={70}
        flexDirection="row"
        alignItems="center"
        paddingX={1}
        cursor="pointer"
        overflow="hidden"
        backgroundColor={isSelected ? 'secondaryFeedback.greenLight' : 'white'}
        {...otherProps}
      >
        <Container
          borderRadius={100}
          borderWidth={isSelected ? 1.5 : 1}
          width="25px"
          height="25px"
          borderColor={isSelected ? 'brand.brandGreen' : 'greytones.graphicsDark'}
          padding="4.5px"
          marginRight={1}
        >
          <Container
            backgroundColor={isSelected ? 'brand.brandGreen' : 'white'}
            width="full"
            height="full"
            borderWidth={0}
            borderRadius={100}
          />
        </Container>
        <Text
          flex={1}
          lineHeight="2xs"
          variant="titleXXSMedium"
          color="brand.brandGreen"
          selectable={false}
        >
          {option.label}
        </Text>
      </Container>
    </Pressable>
  );
};
