/* global fetch FileReader File window */
import { Platform } from 'react-native';
import Config from 'react-native-config';
// eslint-disable-next-line no-undef
const baseURL = `${Config.API_BASE_URL}/api`;
type PostWithProgressType = {
  token?: string;
  onUploadProgress: (arg0: number) => any;
  endpoint: string;
  body: string;
};

const fakeUploadProgress = (cb: (arg0: number) => void): any => {
  let percent = 10;
  return setInterval(() => {
    // eslint-disable-next-line
    const currentUploadPercent = Math.floor(Math.random() * (10 - 0 + 1)) + 0;
    percent += currentUploadPercent;
    cb(percent);
  }, 400);
};

const getJSON = (responseText: string) => {
  let result;

  try {
    result = JSON.parse(responseText);
  } catch (error) {
    // eslint-disable-next-line no-undef, no-console
    if (__DEV__) console.error('Something went wrong parsing result data', error);
    result = error;
  }

  return result;
};

function postWithProgress({
  token,
  onUploadProgress,
  endpoint,
  body,
}: PostWithProgressType): Promise<any> {
  return new Promise((result, reject) => {
    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();
    const url = `${baseURL}${endpoint}`;
    let fakeUploadProgressInterval = 0;

    const clearFakeUploadProgressInterval = (): void => {
      clearInterval(fakeUploadProgressInterval as any);
    };

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');

    if (token) {
      xhr.setRequestHeader('Authorization', `Token ${token}`);
    }

    xhr.onload = () => {
      clearFakeUploadProgressInterval();
      const { responseText, status } = xhr;
      const data = getJSON(responseText);
      // eslint-disable-next-line prefer-promise-reject-errors
      return status >= 400
        ? reject({
            status,
            ...data,
          })
        : result({
            status,
            data,
          });
    };

    xhr.onerror = (err) => {
      // eslint-disable-next-line no-console
      console.error('Something went wrong with the request', err);
      clearFakeUploadProgressInterval();
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: 400,
      });
    };

    xhr.upload.onprogress = function progress(event) {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        onUploadProgress(percent);
      }
    };

    if (Platform.OS === 'android') {
      fakeUploadProgressInterval = fakeUploadProgress(onUploadProgress);
    }

    xhr.send(body);
  });
}

function setHeaders(method: any, token: any, body: any) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Token ${token}` : null,
  };
  return {
    method,
    headers,
    body,
  };
}

function appUrl(url: any) {
  return baseURL + url;
}

async function handleResponse(response: any): Promise<Record<string, any> | void> {
  if (response.status === 201 || response.status === 204) return Promise.resolve();
  let responseJson = {};

  if (response.status >= 400) {
    return Promise.reject(response);
  }

  try {
    responseJson = await response.json();
  } catch (error) {
    return Promise.reject(new Error(error));
  }

  return Promise.resolve(responseJson);
}

async function get(url: string, authToken: string) {
  // @ts-ignore
  const response = await fetch(appUrl(url), setHeaders('GET', authToken));
  return handleResponse(response);
}

async function getExternal(url: string, optionsParam: {} = {}) {
  const defaultOptions = {
    method: 'GET',
  };
  const response = await fetch(url, { ...defaultOptions, ...optionsParam });
  return handleResponse(response);
}

const convertBlobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(blob);
  });

export const getAsBase64 = async (url: string, options: {}) => {
  return fetch(url, options)
    .then((response) => response.blob())
    .then(convertBlobToBase64);
};

const convertBase64ToBlob = (blob: any) => {
  const file = new File([blob], 'filename.jpeg');
  return window.URL.createObjectURL(file);
};

export const getAsBlob = (url: string): Promise<string> => {
  return fetch(url)
    .then((response) => response.blob())
    .then(convertBase64ToBlob);
};
export function readFileAsyncBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();

    reader.onload = () => {
      // When onload fires reader.result is a string. This if is needed to calm down flow.
      if (typeof reader.result === 'string') resolve(reader.result);
    };

    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsDataURL(file);
  });
}
export default {
  postWithProgress,
  get,
  getExternal,
  getAsBase64,
  getAsBlob,
  readFileAsyncBase64,
};
