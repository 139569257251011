import { Dispatch } from 'redux';
import { Documents as DocumentsSDK, Utils } from '@taxfix/taxfix-sdk';
import { Document } from '@taxfix/taxfix-sdk/dist/documents/types';
import Config from 'react-native-config';
import { createSelector } from 'reselect';
import { createFilter } from 'redux-persist-transform-filter';
import { CountryCodes, Documents } from '@taxfix/types';

import { uploadDocument } from '../../services/camera-upload-documents';
import {
  digitalCafDocuments,
  mandatoryDocuments,
  documentsCategories,
  expenseDocumentCategories,
} from '../../common/constants-it';

import { State as Settings, selectors as settingsSelectors } from './settings';

export const UPDATE_REQUIRED_DOCUMENTS = 'required-documents/UPDATE_REQUIRED_DOCUMENTS';
export const ADD_REQUIRED_DOCUMENTS = 'required-documents/ADD_REQUIRED_DOCUMENTS';
export const DEL_REQUIRED_DOCUMENTS = 'required-documents/DEL_REQUIRED_DOCUMENTS';
export const DEL_REQUIRED_DOCUMENTS_SINGLE = 'required-documents/DEL_REQUIRED_DOCUMENTS_SINGLE';
export const CONFIRM_REQUIRED_DOCUMENTS = 'required-documents/CONFIRM_REQUIRED_DOCUMENTS';
export const UPDATE_DOCUMENTS_REQUEST = 'required-documents/UPDATE_DOCUMENTS_REQUEST';
export const RESET_CONSENT_AND_CONFIRMATION = 'required-documents/RESET_CONSENT_AND_CONFIRMATION';

export type RequiredDocument = string;

interface DocumentWithAmountTE {
  id: RequiredDocument;
  amount: number;
}

export type DocumentWithAmount = Omit<DocumentWithAmountTE, 'id'> & {
  optional?: boolean;
  id: string;
};

export type DocumentBunch = {
  amount: number;
  items: Document[];
  optional?: boolean;
};
export type DocumentBunches = Record<RequiredDocument, DocumentBunch>;

export type State = {
  docBunches: DocumentBunches;
  isConfirmed: boolean;
  isUpdatingRequiredDocuments: boolean;
};
type AddAction = {
  type: typeof ADD_REQUIRED_DOCUMENTS;
  payload: {
    newDoc: Document;
  };
};
type DelAction = {
  type: typeof DEL_REQUIRED_DOCUMENTS;
  payload: {
    delDocType: RequiredDocument;
  };
};
type DelActionSingle = {
  type: typeof DEL_REQUIRED_DOCUMENTS_SINGLE;
  payload: {
    delDocId: number;
  };
};
type UpdateAction = {
  type: typeof UPDATE_REQUIRED_DOCUMENTS;
  payload: {
    docBunches: DocumentBunches;
  };
};
type ConfirmAction = {
  type: typeof CONFIRM_REQUIRED_DOCUMENTS;
  payload: {
    isConfirmed: boolean;
  };
};
type ResetConsentAndConfirmation = {
  type: typeof RESET_CONSENT_AND_CONFIRMATION;
};

type Action =
  | UpdateAction
  | ConfirmAction
  | AddAction
  | DelAction
  | DelActionSingle
  | ResetConsentAndConfirmation;
export const initial: State = {
  docBunches: {},
  isConfirmed: false,
  isUpdatingRequiredDocuments: false,
};
// selectors
export type RootState = {
  requiredDocuments: State;
  settings: Settings;
};

const getDocumentBunches = (state: RootState) => state.requiredDocuments.docBunches;

const checkDocument =
  (condition: (arg0: string) => boolean) =>
  (document: string): boolean => {
    const docCategory = documentsCategories.find(
      (category) =>
        category === document || (typeof category === 'object' && category.key === document),
    );

    if (!docCategory) {
      return false;
    }

    if (typeof docCategory === 'string') {
      return condition(docCategory);
    }

    return docCategory.items.some(condition);
  };

const mandatoryCondition = (category: string): boolean => mandatoryDocuments.includes(category);

const uploadedCondition =
  (uploadState: DocumentBunches) =>
  (category: string): boolean =>
    uploadState[category]?.items?.length > 0;

// selector temporarily unused - for now users need to upload at least 1 document to continue with consent
// and completion of document upload step - otherwise step is skipped - TBD
const hasUploadedMandatoryDocuments: (state: RootState) => (arg0: string[]) => boolean =
  createSelector(
    getDocumentBunches,
    (docBunches) => (userDocList: string[]) =>
      userDocList
        .filter(checkDocument(mandatoryCondition))
        .every(checkDocument(uploadedCondition(docBunches))),
  );

const hasUploadedEachTailoredCategory: (state: RootState) => (arg0: string[]) => boolean =
  createSelector(getDocumentBunches, (docBunches) => (userDocList: string[]) => {
    if (userDocList.length === 0) {
      return true;
    }
    return userDocList.every(checkDocument(uploadedCondition(docBunches)));
  });

const hasUploadedSomeTailoredCategories: (state: RootState) => (arg0: string[]) => boolean =
  createSelector(
    getDocumentBunches,
    (docBunches) => (userDocList: string[]) =>
      userDocList.some(checkDocument(uploadedCondition(docBunches))),
  );

const getUserExpenseFoldersWithDocuments: (
  state: RootState,
  tailoredCategories: string[],
) => string[] = createSelector(
  getDocumentBunches,
  (state: RootState, tailoredCategories: string[]) => tailoredCategories,
  (docBunches, tailoredCategories) => {
    // check that tailored cat is expense:
    const tailoredExpenseCategories = tailoredCategories.filter((category) =>
      expenseDocumentCategories.includes(category),
    );
    // find the folder with the docs inside
    const tailoredExpenseCatWithFiles = Object.keys(docBunches).reduce((acc: string[], docType) => {
      const doesCategoryHaveFiles = docBunches[docType].items.length > 0;
      if (!doesCategoryHaveFiles) {
        const docCategory = documentsCategories.find((cat) => {
          return cat.items.includes(docType);
        });

        if (docCategory && tailoredExpenseCategories.includes(docCategory.key)) {
          acc.push(docCategory.key);
        }
      }
      return acc;
    }, []);
    return tailoredExpenseCatWithFiles;
  },
);

const arrayOfDocBunches = (state: RootState): DocumentBunch[] =>
  Object.values(state.requiredDocuments.docBunches);

const hasEmptyFields: (state: RootState) => boolean = createSelector(
  arrayOfDocBunches,
  (bunchArr) =>
    !!bunchArr.find((docBunch: DocumentBunch) => !docBunch.optional && docBunch.items.length === 0),
);
const hasUploadedFields: (state: RootState) => boolean = createSelector(
  arrayOfDocBunches,
  (bunchArr) => !!bunchArr.find((docBunch: DocumentBunch) => docBunch.items.length !== 0),
);

const isUpdatingRequiredDocuments: (state: RootState) => boolean = (state: RootState) =>
  state.requiredDocuments.isUpdatingRequiredDocuments;

const isConfirmed: (state: RootState) => boolean = (state: RootState) =>
  state.requiredDocuments.isConfirmed;

const getUploadedDocumentsWithAmount: (state: RootState) => DocumentWithAmount[] = createSelector(
  arrayOfDocBunches,
  (bunchArray) =>
    bunchArray
      .filter((docBunch) => docBunch.items.length > 0)
      .map(({ items, amount }) => ({
        id: items[0].type,
        amount,
      })),
);

export const selectors = {
  getDocumentBunches,
  hasUploadedMandatoryDocuments,
  hasUploadedEachTailoredCategory,
  hasUploadedSomeTailoredCategories,
  hasEmptyFields,
  hasUploadedFields,
  isUpdatingRequiredDocuments,
  isConfirmed,
  getUploadedDocumentsWithAmount,
  getUserExpenseFoldersWithDocuments,
};

type DocumentsRetrieveResponse = {
  data: Document[];
  total: number;
};

const updateRequiredDocuments =
  (token: string, userId: number | null | undefined) =>
  async (dispatch: Dispatch<UpdateAction>, getState: () => any) => {
    dispatch({
      type: UPDATE_DOCUMENTS_REQUEST,
    } as any);
    const year = settingsSelectors.selectedYear(getState());
    const countryCode = settingsSelectors.selectedCountry(getState());
    const requiredDocumentsWithAmount = digitalCafDocuments.map((id) => ({
      id,
      amount: 0,
      optional: true,
    }));
    const docBunches: DocumentBunches = requiredDocumentsWithAmount.reduce(
      (result: DocumentBunches, requiredDoc: DocumentWithAmount) => ({
        ...result,
        [requiredDoc.id]: {
          amount:
            result[requiredDoc.id] && result[requiredDoc.id].amount
              ? result[requiredDoc.id].amount + requiredDoc.amount
              : requiredDoc.amount,
          items: [],
          optional: !!requiredDoc.optional,
        },
      }),
      {},
    );
    // empty document state filters for IT. We want to show all documents
    const documentStateFilters =
      countryCode === CountryCodes.IT ? [] : [Documents.States.Created, Documents.States.Approved];

    const requiredDocumentsData: DocumentsRetrieveResponse = await Utils.InfiniteMatch(
      (page) =>
        DocumentsSDK.get(Config.API_BASE_URL, token, {
          userId,
          countryCode,
          year,
          states: documentStateFilters,
          page,
        } as any) as any,
    );

    if (requiredDocumentsData.total !== 0) {
      requiredDocumentsData.data.forEach((doc: Document) => {
        if (docBunches[doc.type] != null) {
          docBunches[doc.type].items = [...docBunches[doc.type].items, doc];
        }
      });
    }

    dispatch({
      type: UPDATE_REQUIRED_DOCUMENTS,
      payload: {
        docBunches,
      },
    });
  };

const uploadRequiredDocuments =
  (
    token: string,
    userId: number,
    docType: Documents.NonReceiptTypes,
    uri: string,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
    pdf?: boolean,
    fileName?: string,
  ) =>
  async (dispatch: Dispatch<AddAction>, getState: () => any) => {
    const year = settingsSelectors.selectedYear(getState());
    const countryCode = settingsSelectors.selectedCountry(getState());

    if (year == null) {
      throw new Error('year is null');
    }

    const response = await uploadDocument(
      docType,
      uri,
      token,
      year,
      countryCode,
      onUploadProgress,
      pdf,
      false,
      fileName,
    );
    dispatch({
      type: ADD_REQUIRED_DOCUMENTS,
      payload: {
        newDoc: {
          id: response.id,
          type: docType,
        },
      },
    } as any);
  };

const deleteRequiredDocument =
  (token: string, docId: number) =>
  async (dispatch: Dispatch<DelActionSingle>, getState: () => any) => {
    const year = settingsSelectors.selectedYear(getState());
    const countryCode = settingsSelectors.selectedCountry(getState());
    const response = await DocumentsSDK.remove(Config.API_BASE_URL, token, {
      id: docId,
      year,
      countryCode,
    } as any);

    if (response.result) {
      dispatch({
        type: DEL_REQUIRED_DOCUMENTS_SINGLE,
        payload: {
          delDocId: docId,
        },
      });
    }
  };

const setIsConfirmed = (confirmed: boolean): ConfirmAction => ({
  type: CONFIRM_REQUIRED_DOCUMENTS,
  payload: {
    isConfirmed: confirmed,
  },
});

const resetConsentAndConfirmation = (): ResetConsentAndConfirmation => ({
  type: RESET_CONSENT_AND_CONFIRMATION,
});

export const actions = {
  updateRequiredDocuments,
  uploadRequiredDocuments,
  deleteRequiredDocument,
  setIsConfirmed,
  resetConsentAndConfirmation,
};
export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type as any) {
    case UPDATE_REQUIRED_DOCUMENTS:
      return {
        ...state,
        isUpdatingRequiredDocuments: false,
        ...action.payload,
      };

    case ADD_REQUIRED_DOCUMENTS: {
      const { newDoc } = action.payload;
      const bunchesToAdd = state.docBunches[newDoc.type];
      return {
        ...state,
        docBunches: {
          ...state.docBunches,
          [newDoc.type]: { ...bunchesToAdd, items: [newDoc, ...bunchesToAdd.items] },
        },
      };
    }

    case DEL_REQUIRED_DOCUMENTS: {
      const { delDocType } = action.payload;
      const bunchToDel = state.docBunches[delDocType];
      return {
        ...state,
        docBunches: { ...state.docBunches, [delDocType]: { ...bunchToDel, items: [] } },
        isConfirmed: false,
      };
    }

    case DEL_REQUIRED_DOCUMENTS_SINGLE: {
      const { delDocId } = action.payload;
      const docBunches = { ...state.docBunches };
      Object.keys(docBunches).forEach((docType) => {
        docBunches[docType].items = docBunches[docType].items.filter(
          (document) => document.id !== delDocId,
        );
      });
      return { ...state, docBunches: { ...docBunches }, isConfirmed: false };
    }

    case CONFIRM_REQUIRED_DOCUMENTS:
      return { ...state, ...action.payload };

    case UPDATE_DOCUMENTS_REQUEST:
      return { ...state, isUpdatingRequiredDocuments: true };

    case RESET_CONSENT_AND_CONFIRMATION:
      return { ...state, isConfirmed: false };

    default:
      return state;
  }
};
export const persistFilter = createFilter('requiredDocuments', ['isConfirmed']);
