import Config from 'react-native-config';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { Sleep } from 'src/utils/async';

/* global window */
type Traits = Record<string, unknown>;

class AnalyticsifyClass {
  isEnabled = false;

  init = () => {
    return new Promise((resolve) => {
      try {
        const { analytics } = window;
        analytics.load(Config.SEGMENT_WEB_KEY, { integrations: { Intercom: false } });
        analytics.page();

        analytics.ready(() => {
          this.isEnabled = true;
          resolve(true);
        });
      } catch (error) {
        logger.error(error as Error, { message: 'Unable to load Segment' });
      }
    });
  };

  identify = (traits?: Traits, options?: Traits) => {
    if (!this.isEnabled) return;
    window.analytics.identify(traits, options);
  };
  identifyWithUser = (userId: number, traits?: Traits, options?: Traits) => {
    if (!this.isEnabled) return;
    window.analytics.identify(userId, traits, options);
  };
  getAdvertisingId = async () => {
    throw Error('Advertising ID is not available on Web');
  };
  getAnonymousId = async () => {
    if (!this.isEnabled) return null;
    return window.analytics.user().anonymousId();
  };
  getBrazeDeviceId = async () => {
    if (!this.isEnabled) return null;
    return await new Promise((resolve) => window.appboy.getDeviceId(resolve));
  };
  track = async (
    event: string,
    properties: Record<string, unknown>,
    options: Record<string, unknown>,
  ) => {
    if (!window.analytics.initialized) {
      // wait for initialization of analytics lib
      const MIN_TIMEOUT_MS = 3000;
      await Sleep(MIN_TIMEOUT_MS);
    }

    if (!this.isEnabled) return;
    window.analytics.track(event, properties, options);
  };
  flush = () => {
    // not needed on Web
  };
  enable = async () => {
    if (!this.isEnabled) await this.init();
  };
  disable = () => {
    if (this.isEnabled) {
      this.isEnabled = false;
      this.reset();
    }
  };
  reset = () => {
    const { analytics, location } = window;
    if (analytics.initialized) {
      // Note: if segment is already loaded, we MUST reload the page to stop it. This is a segment limitation.
      analytics.reset();
      location.reload();
    }
  };
}

export const Analyticsify = new AnalyticsifyClass();
