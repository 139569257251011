import { StyleSheet } from 'react-native';

import { theme } from 'src/components/core/theme';

const bgImage: Record<string, string | number> = {
  position: 'absolute',
  bottom: 0,
  right: 0,
};

export const styleConstant = {
  span: 8, // Magic theme value to keep styles indents in proportion
  styles: {
    bgImage,
  },
};

export default StyleSheet.create({
  screenContainer: {
    position: 'relative',
    flex: 1,
  },
  content: {
    padding: styleConstant.span * 2.5,
    position: 'relative',
    zIndex: theme.zIndex.positive,
  },
  textSection: {
    marginBottom: styleConstant.span * 2.5,
  },
  downloadButton: {
    flexDirection: 'row',
  },
  bgImagesContainer: {
    ...styleConstant.styles.bgImage,
    zIndex: theme.zIndex.negative,
  },
  bgImage: {
    ...styleConstant.styles.bgImage,
  },
  iconDE: {},
  iconFR: {
    bottom: styleConstant.span * 1.25,
  },
  iconIT: {
    right: styleConstant.span * 1.25,
    bottom: styleConstant.span * 2,
  },
});
