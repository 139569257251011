import React from 'react';
import { useTheme } from 'native-base';

import { Text } from 'src/taxfix-components/src';

export type ThanksScreenProps = {
  thanksTitle: string;
};

export const ThanksScreen = ({ thanksTitle }: ThanksScreenProps) => {
  const { colors } = useTheme();
  return (
    <Text fontSize="14px" color={colors.greytones.textDefault}>
      {thanksTitle}
    </Text>
  );
};
