import * as React from 'react';
import {
  StyleSheet,
  Animated,
  TouchableOpacity,
  Easing,
  SafeAreaView,
  Pressable,
} from 'react-native';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';

import { testID } from '../common/testID';
import { isWeb } from '../utils/platform';

import { WithScreenSizes, theme, withScreenSizes } from './core';
import { StyleProp } from './types';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  leftContainer: {
    alignItems: 'flex-start',
  },
  rightContainer: {
    alignItems: 'flex-end',
  },
  borderRadiusRightSidePosition: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  background: {
    backgroundColor: theme.color.overlay,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  filler: {
    backgroundColor: theme.color.lightFill,
    width: '100%',
  },
  fillerSeparator: {
    borderTopColor: theme.color.border,
    borderTopWidth: 1,
  },
  fullHeight: {
    height: '100%',
  },
  maxHeight: {
    maxHeight: '100%',
    overflow: 'hidden',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  safeAreaContainer: {
    justifyContent: 'flex-end',
    flex: 1,
  },
  safeAreaSideContainer: {
    alignContent: 'flex-end',
    justifyContent: 'flex-start',
  },
  //TODO: clean this style when ITA has the info box overlay component or the content of the info box
  maxWidthSideContainer: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.color.lightFill,
  },
  centerContent: {
    justifyContent: 'center',
  },
});

export type Position = 'bottom' | 'left' | 'right' | 'center';
type Props = {
  onOpen?: () => any;
  onCloseComplete?: () => any;
  disableBackgroundTouch?: boolean;
  children: (arg0: { onClose: () => any }) => React.ReactNode;
  testId?: string;
  style?: StyleProp;
  pressableContainerStyle?: StyleProp;
  contentContainerStyle?: StyleProp;
  appearFromTop?: boolean;
  bottomFiller?: boolean;
  bottomFillerSeparator?: boolean;
  position?: Position;
  fullWidthOnWeb?: boolean;
} & WithScreenSizes;
type State = {
  animatedState: Record<string, any>;
};
export class OverlayComponent extends React.Component<Props, State> {
  state = {
    animatedState: new Animated.Value(this.props.appearFromTop ? 1 : -1),
  };

  componentDidMount() {
    const { onOpen } = this.props;
    if (onOpen) onOpen();
    this.animate(0);
  }

  onClose = () => {
    this.animate(-1, this.props.onCloseComplete);
  };

  handleBackgroundPress = () => {
    if (!this.props.disableBackgroundTouch) this.onClose();
  };

  animate = (toValue: number, callback?: () => any) => {
    const { animatedState } = this.state;
    Animated.timing(animatedState, {
      toValue,
      easing: Easing.bezier(0.24, 0.39, 0.46, 0.99),
      duration: 350,
      useNativeDriver: true,
    }).start(callback ? callback : undefined);
  };

  render() {
    const { animatedState } = this.state;
    const {
      children,
      testId,
      style,
      bottomFiller,
      bottomFillerSeparator,
      position = 'bottom',
      isLargeScreen,
      fullWidthOnWeb,
      pressableContainerStyle: pressableStyle,
      contentContainerStyle: containerStyle,
    } = this.props;
    const opacity = animatedState.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [0, 1, 0] as number[],
    });
    const transformTranslate = animatedState.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [100, 0, -100] as number[],
    });

    let additionalOverlayContainerStyle;
    switch (position) {
      case 'left':
        additionalOverlayContainerStyle = styles.leftContainer;
        break;
      case 'right':
        additionalOverlayContainerStyle = styles.rightContainer;
        break;
      case 'center':
        additionalOverlayContainerStyle = styles.centerContent;
        break;

      default:
        break;
    }
    const overlayContainerStyle = [
      styles.container,
      additionalOverlayContainerStyle,
      {
        opacity,
      },
    ];

    const isSideOverlay = position === 'left' || position === 'right';
    const safeAreaContainerStyle = [
      styles.safeAreaContainer,
      isSideOverlay ? styles.safeAreaSideContainer : undefined,
      isSideOverlay && isWeb ? styles.maxWidthSideContainer : undefined,
      isLargeScreen && position === 'right' ? styles.borderRadiusRightSidePosition : undefined,
      style,
    ];
    const pressableContainerStyle: any = [
      isSideOverlay ? styles.fullHeight : undefined, // Stylesheet does not accept cursor as valid property type
      isWeb
        ? [
            {
              cursor: 'default',
            },
          ]
        : undefined,
      isLargeScreen && !isSideOverlay && !fullWidthOnWeb ? styles.alignCenter : undefined,
      isLargeScreen && position === 'right' ? styles.borderRadiusRightSidePosition : undefined,
      styles.maxHeight,
      pressableStyle,
    ];
    const contentContainerStyle = isSideOverlay
      ? [
          {
            opacity,
            translateX: transformTranslate,
          },
          styles.fullHeight,
        ]
      : [
          {
            opacity,
            translateY: transformTranslate,
          },
          styles.maxHeight,
          containerStyle,
        ];
    return (
      <Pressable style={styles.background} onPress={this.handleBackgroundPress} {...testID(testId)}>
        <Animated.View style={overlayContainerStyle}>
          <SafeAreaView style={safeAreaContainerStyle}>
            <Animated.View style={contentContainerStyle}>
              {/* This Pressable is added around the content to disable the outer Pressable's onPress
                 event handler inside the content of the overlay and make that only fire when the
                 overlay's background is pressed. */}
              <Pressable style={pressableContainerStyle}>
                {children({
                  onClose: this.onClose,
                })}
              </Pressable>
            </Animated.View>
          </SafeAreaView>
          <SafeAreaInsetsContext.Consumer>
            {(insets) => {
              const { bottom: bottomInset }: any = insets;
              const hasInset = bottomInset > 0;
              return (
                hasInset &&
                bottomFiller && (
                  <Animated.View style={contentContainerStyle}>
                    <TouchableOpacity
                      activeOpacity={1}
                      style={[
                        styles.filler,
                        bottomFillerSeparator ? styles.fillerSeparator : {},
                        {
                          height: insets!.bottom,
                        },
                      ]}
                    />
                  </Animated.View>
                )
              );
            }}
          </SafeAreaInsetsContext.Consumer>
        </Animated.View>
      </Pressable>
    );
  }
}
export default withScreenSizes(OverlayComponent);
