import get from 'lodash/get';
import { Calculations } from '@taxfix/income-tax-calculator';
import mandatoryCheck from '@taxfix/mandatory-submission-check';
import { IncomeTaxValue, Responses, SingleResponse } from '@taxfix/quizmaster/dist/types';

import { PayrollData } from '../types/payslip';
import { mergeRanges, rangeSpansWholeYear } from '../utils/date';
import { getBruttoArbeitslohnThreshold } from '../constants';
import {
  PAYSLIP_BRUTTO_ARBEITSLOHN_ANSWER_KEY,
  PAYSLIP_DATA,
  PAYSLIP_TAXCLASS_SIX,
} from '../constants/question-answer';

import {
  BaseTaxCalculator,
  MandatoryType,
  RefundReasons,
  RefundReason as RefundReasonType,
} from './index';

interface QuizmasterTaxCalculatorConstructor {
  year: number;
  responses: Responses;
  incomeFields: IncomeTaxValue[];
  calculations: Calculations;
}

export const isPartner = (index: number): boolean => index === 1;

export class QuizmasterTaxCalculator extends BaseTaxCalculator {
  private incomeFields: IncomeTaxValue[];

  private readonly params: QuizmasterTaxCalculatorConstructor;

  constructor(params: QuizmasterTaxCalculatorConstructor) {
    super();
    this.params = params;
    this.incomeFields = this.params.incomeFields;
    this.setCalculations(this.params.calculations);
    this.setMandatory(this.getMandatoryType());
  }

  private getPayslipAnswer = (): SingleResponse<PayrollData>[] => {
    const { responses } = this.params;

    return Object.keys(responses)
      .filter((key) => key.includes(PAYSLIP_DATA.answerID))
      .map((key) => get(responses, key))
      .filter((payslip) => payslip && payslip.answer);
  };

  private getMandatoryType = (): MandatoryType => {
    const payslips = this.getPayslipAnswer();
    const taxYear = this.params.year;
    return mandatoryCheck.isMandatory(
      this.incomeFields,
      // TODO this will be fixed with the next release of ITC
      this.calculations as any,
      payslips,
      taxYear,
    );
  };

  protected enhanceRefundReasons = (personIndex: number, reasons: RefundReasons): RefundReasons => {
    // The following calculations only exist for the main person
    if (!isPartner(personIndex)) {
      // not employed year round
      const payslips = this.getPayslipAnswer();

      if (payslips.length > 0) {
        const payslipRanges = payslips.map((payslip) => payslip.answer.duration);
        const employedWholeYear = rangeSpansWholeYear(this.params.year, mergeRanges(payslipRanges));

        if (!employedWholeYear) {
          reasons.push({
            personIndex,
            reason: 'not-employed-year-round',
            amount: Number.MAX_SAFE_INTEGER.toString(), // so the card is displayed first in the list
          });
        }
      }
    }

    return reasons;
  };

  getFastlaneReasonsForPerson = (personIndex: number): RefundReasonType[] => {
    const { year } = this.params;
    let reasons: RefundReasonType[] = [];

    // The following calculations only exist for person A
    if (!isPartner(personIndex)) {
      const payslips = this.getPayslipAnswer();

      if (payslips.length === 0) {
        return reasons;
      }

      // 1. not employed year round
      const payslipRanges = payslips.map((payslip) => payslip.answer.duration);
      const employedWholeYear = rangeSpansWholeYear(this.params.year, mergeRanges(payslipRanges));

      if (!employedWholeYear) {
        reasons = [
          ...reasons,
          {
            personIndex,
            reason: 'not-employed-year-round',
            amount: '0',
          },
        ];
      }

      // 2. income variation
      const bruttoArbeitslohns = payslips.map(
        (payslip) => payslip.answer[PAYSLIP_BRUTTO_ARBEITSLOHN_ANSWER_KEY],
      );
      const varyingInput = new Set(bruttoArbeitslohns).size > 1;

      if (varyingInput) {
        reasons = [
          ...reasons,
          {
            personIndex,
            reason: 'varying-input',
            amount: '0',
          },
        ];
      }

      // TODO: 3. Married with different level of incomes not included in mvp
      // 4. Tax class 6 is chosen
      const taxClassSixChosen = payslips.some(
        ({ answer }) => answer.taxClass === PAYSLIP_TAXCLASS_SIX,
      );

      if (taxClassSixChosen) {
        reasons = [
          ...reasons,
          {
            personIndex,
            reason: 'tax-class-six',
            amount: '0',
          },
        ];
      }

      // 5. Brutto arbeitslohn is lower than the threshold
      const totalIncome = payslips.reduce((acc, payslip) => {
        const { answer } = payslip;
        const bruttoArbeitslohn = answer[PAYSLIP_BRUTTO_ARBEITSLOHN_ANSWER_KEY];
        return acc + bruttoArbeitslohn;
      }, 0);
      const threshold = getBruttoArbeitslohnThreshold(year);
      const lowIncome = totalIncome < threshold;

      if (lowIncome) {
        reasons = [
          ...reasons,
          {
            personIndex,
            reason: 'low-income',
            amount: '0',
          },
        ];
      }
    }

    return reasons;
  };
}
