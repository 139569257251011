import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { CountryCodes } from '@taxfix/types';

import QuestionStore from '../../stores-legacy/QuestionStore';
import { getStore as getReduxStore } from '../util';
import { selectors as settingsSelectors } from '../modules/settings';

type MobxStore = {
  questionStores: { [year in string | number]?: QuestionStore };
};

export type { QuestionStore };

export const useStores = (): MobxStore => {
  const mobxStores = useContext(MobXProviderContext);
  const reduxStore = getReduxStore();
  const selectedCountry = reduxStore && settingsSelectors.selectedCountry(reduxStore.getState());

  switch (selectedCountry) {
    case CountryCodes.IT:
      return {
        questionStores: mobxStores.questionStoresIT,
      };
    case CountryCodes.ES:
      return {
        questionStores: mobxStores.questionStoresES,
      };
    default:
      return {
        questionStores: mobxStores.questionStores,
      };
  }
};
export const useQuestionStore = (year: string | number): QuestionStore => {
  const { questionStores } = useStores();
  // @ts-ignore
  return questionStores[year];
};
