import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { ScrollView as NBScrollView, useBreakpointValue, useTheme } from 'native-base';
import HTML from 'react-native-render-html';
import { useDispatch } from 'react-redux';

import { Container, TextLink, Text, IconButton, icons } from 'src/taxfix-components/src';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { theme } from 'src/components/core';
import Overlay from 'src/components/overlay';
import { ScreenName } from 'src/types/screen-name';
import { KeysAndValues } from 'src/utils/translationValuesWithDefaults';
import { actions as overlayActions } from 'src/stores/modules/overlay';

import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

enum TranslationValuesStrings {
  CWBI_documents = '{CWBI_documents}',
}

type Props = {
  onClose(): void;
  content: string;
  translationValues?: KeysAndValues;
  navigateToSPIDConsent(): void;
};

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
});

export const PrefillDocumentsResultListInfoOverlay = ({
  onClose: onCloseComplete,
  content,
  translationValues,
  navigateToSPIDConsent,
}: Props): JSX.Element => {
  const [htmlContent, setHtmlContent] = React.useState<string>(content);

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const { colors } = useTheme();
  const dispatch = useDispatch();

  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const paddingLeftScrollView = useBreakpointValue({ base: 20, md: 64 });
  const paddingRightScrollView = useBreakpointValue({ base: 20, md: 44 });

  const supportLinkText = getTranslationText('it.prefill-documents-list.info-overlay.footer.link');
  const mapTranslationValues: Record<string, string | number> = {
    [TranslationValuesStrings.CWBI_documents]: translationValues?.CWBI_documents ?? ' ',
  };

  const handleOnPressSupport = () => {
    dispatch(overlayActions.hide());
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.PrefillDocumentsResultList,
      buttonName: supportLinkText,
    });
    handleOpenChatPopup();
  };

  React.useEffect(() => {
    const formattedHtmlContent = htmlContent.replace(/{CWBI_documents}/gi, (matched: string) => {
      return mapTranslationValues[matched] ? mapTranslationValues[matched].toString() : '';
    });
    setHtmlContent(formattedHtmlContent);
  }, []);

  const titlesFontStyle = {
    fontFamily: 'CircularStd-Bold',
    marginTop: 20,
    marginBottom: 20,
    lineHeight: 24,
    color: colors.greytones.textDefault,
  };

  const contentFontStyle = { fontFamily: 'CircularStd-Book', fontSize: 16, lineHeight: 20 };

  const htmlStyles = {
    h1: {
      ...titlesFontStyle,
      fontSize: 24,
      lineHeight: 30,
    },
    h2: {
      ...titlesFontStyle,
      fontSize: 20,
    },
    p: {
      ...contentFontStyle,
      color: colors.greytones.title,
      marginBottom: 10,
      padding: 0,
    },
    span: {
      fontFamily: 'CircularStd-Bold',
    },
    ul: {
      marginBottom: 3,
      color: colors.greytones.title,
      padding: 0,
    },
    li: {
      ...contentFontStyle,
      padding: 0,
    },
    a: {
      ...contentFontStyle,
      color: colors.brand.brandGreen,
    },
  };

  const classStyles = {
    ['spid-consent-link']: {
      textDecoration: 'none',
      textDecorationLine: 'none',
    },
  };

  const handleLinkPress = (url: string) => {
    if (url === 'spidConsent') {
      dispatch(overlayActions.hide());
      navigateToSPIDConsent();
    } else {
      Linking.openURL(url);
    }
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isLargeScreen ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isLargeScreen ? 'right' : 'bottom'}
    >
      {({ onClose }: { onClose: () => void }) => (
        <>
          <IconButton
            color={colors.brand.brandGreen}
            padding="30px"
            alignSelf="flex-start"
            path={icons.close}
            onPress={onClose}
            size="18px"
          />

          <NBScrollView
            height="100%"
            paddingRight={paddingLeftScrollView}
            paddingLeft={paddingRightScrollView}
            display="flex"
          >
            <View onStartShouldSetResponder={() => true}>
              <HTML
                source={{ html: htmlContent }}
                tagsStyles={htmlStyles}
                classesStyles={classStyles}
                onLinkPress={(event, href) => {
                  handleLinkPress(href);
                }}
              />
            </View>
            <Container flexDirection="column" marginY={isLargeScreen ? 30 : 50}>
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.prefill-documents-list.info-overlay.footer.title')}
              />
              <TextLink
                variant="titleXSMedium"
                color={'brand.brandGreen'}
                text={supportLinkText}
                onPress={handleOnPressSupport}
              />
            </Container>
          </NBScrollView>
        </>
      )}
    </Overlay>
  );
};
