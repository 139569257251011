export const getRoundedDateOfBirth = (dateOfBirth: string): string => {
  const [birthYear] = dateOfBirth.split('-');
  const maxDate = `${Math.ceil(+birthYear / 5) * 5}-01-01`;
  const minDate = `${Math.floor(+birthYear / 5) * 5}-01-01`;

  const timeAfterDate = new Date(maxDate).getTime() - new Date(dateOfBirth).getTime();
  const timeBeforeDate = new Date(dateOfBirth).getTime() - new Date(minDate).getTime();

  return timeBeforeDate < timeAfterDate ? minDate : maxDate;
};
