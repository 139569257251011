import * as React from 'react';
import { Pressable, IPressableProps } from 'native-base';

import { Text } from 'src/taxfix-components/src';

export type CardButtonProps = {
  buttonLabel: string;
  onPress: IPressableProps['onPress'];
} & IPressableProps;

export const CardButton = ({ buttonLabel, onPress, ...props }: CardButtonProps): JSX.Element => {
  return (
    <Pressable
      mt="auto"
      mr="auto"
      ml="20px"
      px="16px"
      py="8px"
      height="36px"
      background="secondaryFeedback.greenLight"
      borderRadius="8px"
      onPress={onPress}
      {...props}
    >
      <Text color="brand.brandGreen" value={buttonLabel} variant="linkS" />
    </Pressable>
  );
};
