import React from 'react';
import { useSelector } from 'react-redux';

import navigate, { Action } from 'src/routes/navigate';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { getTaxSeasonInfo } from 'src/services/tax-season';

import { IFrameSPIDLoginPreseason } from './iframe-spid-login-preseason-container';
import { IFrameSPIDLogin } from './iframe-spid-login-container';

export const toiFrameSPIDLoginScreen: Action = navigate(ScreenName.ItalyIFrameSPIDLogin, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ItalyIFrameSPIDLogin,
  },
});

export const IFrameSPIDLoginScreen = (): JSX.Element => {
  const isInSeason = useSelector(getTaxSeasonInfo).isInSeason;

  useDefaultNavigationOptions({ id: 'it.precompilato-screen-header.iframe-spid-login.title' });

  return isInSeason ? <IFrameSPIDLogin /> : <IFrameSPIDLoginPreseason />;
};
