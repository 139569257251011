import { Buffer } from 'buffer';

import { DocumentsAPI, DocumentsTypes } from '@taxfix/documents-sdk';
import { Years } from '@taxfix/types';
import Config from 'react-native-config';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { isWeb } from 'src/utils/platform';

const ShareFile = (!isWeb && require('react-native-share').default) || {};

export const getCUDocuments = async (
  token: string,
  userId?: number,
  year?: Years | undefined,
): Promise<DocumentsTypes.v1.Documents.Document[]> => {
  const url = Config.API_BASE_URL;
  const requestBody = {
    userId,
    year,
    types: [DocumentsTypes.v1.Documents.NonReceiptTypes.CUPreseasonPdf],
  };

  const { data: documents } = await DocumentsAPI.v1.get(url, token, requestBody);

  return documents;
};

export const onNativeShare = async (id: number, fileName: string, accessToken: string) => {
  try {
    const response = await DocumentsAPI.v1.getOne(Config.API_BASE_URL, accessToken, {
      id,
      getFileContents: true,
      acceptHeader: 'application/pdf',
    });

    const fileRaw = response.data as ArrayBuffer;
    const fileData = new Uint8Array(fileRaw);
    const fileBase64 = Buffer.from(fileData).toString('base64');

    await ShareFile.open({
      url: `data:application/pdf;base64,${fileBase64}`,
      filename: fileName,
    });
  } catch (error) {
    logger.warn(error as Error, {
      message: `Failed/Stopped share document ${id} action in CUPreseason document screen`,
    });
  }
};
