import React from 'react';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import {
  flagsQuestionIds,
  questionFlowCategories,
  TailoredExperienceStatus,
  wayQuestionIds,
} from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { ScreenName } from 'src/types/screen-name';
import { useItalyIntl, useQuizmasterLight } from 'src/_italy/_hooks';

import { Component } from './tailored-experience-intro-component';

export const Container = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { getNavigationActions, navigation, navigationActions, safeResetNavigation } =
    useNavigation();
  const quizmasterLight = useQuizmasterLight([
    wayQuestionIds.firstName,
    flagsQuestionIds.tailoredExperienceStatus,
  ]);

  const userFirstName = quizmasterLight[wayQuestionIds.firstName].answer ?? '';

  const handleOnBackButtonPress = () =>
    navigation.canGoBack()
      ? navigationActions.back()
      : safeResetNavigation([getNavigationActions().toDashboard('screen')]);

  const handleOnStartPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.TailoredExperienceIntro,
      buttonName: 'AnswerTailoredExperienceQuestions',
    });

    quizmasterLight[flagsQuestionIds.tailoredExperienceStatus].saveAnswer(
      TailoredExperienceStatus.Started,
    );

    navigationActions.toQuizMaster('screen', {
      root: questionFlowCategories.tailoredExperience,
      editing: false,
      skipFinalSummary: true,
      backNavigationText: 'it.tax-id-question-flow.come-back-button.text',
      onNext: () => {
        quizmasterLight[flagsQuestionIds.tailoredExperienceStatus].saveAnswer(
          TailoredExperienceStatus.FinishedQF,
        );
        navigationActions.toTailoredExperienceFinal('screen');
      },
    });
  };

  const handleOnSkipPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.TailoredExperienceIntro,
      buttonName: 'SkipTailoredExperienceQuestions',
    });

    quizmasterLight[flagsQuestionIds.tailoredExperienceStatus].saveAnswer(
      TailoredExperienceStatus.Skipped,
    );

    safeResetNavigation([
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toQuizMaster('screen', {
        root: questionFlowCategories.taxId,
        editing: false,
        backNavigationText: 'it.tax-id-question-flow.come-back-button.text',
        onNext: () => {
          navigationActions.toSPIDConsentSign('screen');
        },
      }),
    ]);
  };

  return (
    <Component
      firstName={userFirstName}
      getTranslationText={getTranslationText}
      onBackButtonPress={handleOnBackButtonPress}
      onStartPress={handleOnStartPress}
      onSkipPress={handleOnSkipPress}
    />
  );
};
