import React, { ComponentType } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useSelector } from 'react-redux';

import { Container, icons } from 'src/taxfix-components/src';
import { useAppStateContext } from 'src/hooks/useAppStateContext';
import { TabNames } from 'src/common/constants-it';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as remoteConfigSelectors } from 'src/stores/modules/remote-config-firebase';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';
import { SupportButtonContainer } from 'src/containers/support-button/support-button-container';

import { FooterOptions, renderWebFooter } from '../components/footer.web';
import { theme, useLargeScreenQuery } from '../components/core';
import {
  WEB_CONTAINER_WIDTH,
  WEB_SIDBEAR_WIDTH,
  WEB_CONTENT_WIDTH,
  WEB_HEADER_LARGE_HEIGHT,
  WEB_HEADER_SMALL_HEIGHT,
} from '../utils/constants';
import { isWeb } from '../utils/platform';

import { ItalyTabs } from './italy-navigation/italy-tabs';
import LogoIcon from './italy-navigation/logo';
import { extractScreenName } from './utils';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    // @ts-ignore
    overflow: isWeb ? 'auto' : 'scroll',
  },
  content: {
    width: '100%',
    height: '100%',
    maxWidth: WEB_CONTENT_WIDTH,
    flex: 1,
  },
  contentWithoutSidebar: {
    maxWidth: WEB_CONTAINER_WIDTH,
  },
  headerContainerLarge: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    maxHeight: WEB_HEADER_LARGE_HEIGHT,
  },
  headerContainerSmall: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    maxHeight: WEB_HEADER_SMALL_HEIGHT,
  },
  header: {
    flex: 1,
    maxWidth: WEB_CONTAINER_WIDTH,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.border,
  },
  headerWithoutBorder: {
    flex: 1,
    maxWidth: WEB_CONTAINER_WIDTH,
  },
  sidebar: {
    width: WEB_SIDBEAR_WIDTH,
    borderRightWidth: 1,
    borderRightColor: theme.color.border,
  },
  screenContainer: {
    // @ts-ignore
    overflow: 'auto',
  },
  screenFlexContainer: {
    flex: 1,
  },
});

export type ScreenOptions = {
  header?: any;
  headerTitle?: string;
  headerStyle?: Record<string, any>;
  footer?: any;
  sidebar?: any;
  headerShown?: boolean;
  footerShown?: boolean;
  sideTabsShown?: boolean;
  isFullHeight?: boolean;
  footerOptions?: FooterOptions;
  hideBorder?: boolean;
  showSupportButton?: boolean;
};

type DefaultWebNavigatorProps = {
  options: ScreenOptions;
  render: () => JSX.Element;
  navigation: any;
  state: any;
};

const DefaultWebNavigator: ComponentType<DefaultWebNavigatorProps> = ({
  options,
  render,
  navigation,
  state,
}) => {
  const {
    header,
    headerTitle,
    headerStyle = {},
    footer,
    sidebar,
    footerOptions = {},
    headerShown = true,
    footerShown = true,
    sideTabsShown = true,
    isFullHeight = false,
    hideBorder = false,
    showSupportButton = true,
  } = options;
  const shouldShowPerScreenSize = useShowSideTabs();
  const isLargeScreen = useLargeScreenQuery();
  const sidebarShown = Boolean(isLargeScreen && sidebar);
  const isAppLocked = useAppStateContext().locked;
  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);
  const screenName = extractScreenName(state.routes[state.index]?.name);

  const isNewPaymentScreen =
    useSelector(remoteConfigSelectors.isNewPaymentScreenEnabled) &&
    state.routes[state.index].name === 'screen/PaymentItaly';

  const { height } = useWindowDimensions();

  const Header = (
    <View style={isLargeScreen ? styles.headerContainerLarge : styles.headerContainerSmall}>
      <View style={hideBorder ? styles.headerWithoutBorder : styles.header}>
        {header({
          headerTitle,
          headerStyle,
          navigationState: state,
          navigation,
        })}
      </View>
    </View>
  );

  const Footer = footer
    ? footer(navigation, sidebarShown)
    : renderWebFooter(navigation, {
        hasSidebar: sidebarShown,
        footerOptions,
      });

  const getHighlighedItalyTab = (): TabNames => {
    const currentRouteName = state.routes[state.index].name;

    switch (currentRouteName) {
      case 'screen/TaxExpert':
        return TabNames.Experts;
      case 'screen/TaxGuide':
        return TabNames.TaxGuide;
      default:
        return TabNames.Dashboard;
    }
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
      }}
    >
      {!isAppLocked && isAuthenticated && shouldShowPerScreenSize && sideTabsShown && (
        <Container mr={shouldShowPerScreenSize ? 1 : '0px'} shadow="sticky2">
          <Container mt={2} position="absolute" top={0}>
            <LogoIcon />
          </Container>

          <Container height={height} justifyContent="center">
            <ItalyTabs highlighedTab={getHighlighedItalyTab()} />
          </Container>
        </Container>
      )}
      <View style={{ flex: 1, height, overflow: 'hidden' }}>
        {headerShown && Header}
        <View style={styles.contentContainer}>
          {sidebarShown && <View style={styles.sidebar}>{sidebar()}</View>}
          <View style={[styles.content, sidebarShown ? null : [styles.contentWithoutSidebar]]}>
            <View
              style={[
                styles.screenContainer,
                isFullHeight || isNewPaymentScreen ? styles.screenFlexContainer : null,
              ]}
            >
              {render()}
            </View>
            {showSupportButton && shouldShowPerScreenSize && isAuthenticated && (
              <SupportButtonContainer iconPath={icons.comments} screenName={screenName} />
            )}
            <View>{footerShown && !isNewPaymentScreen && Footer}</View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default DefaultWebNavigator;
