import React from 'react';
import { CountryCodes } from '@taxfix/types';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ProfileEditComponent } from '../../../screens/components/profile-edit';
import { PDField } from '../../../stores/modules/user-profile';

type ActionParams = {
  value: string;
  onSubmit: (arg0: string) => void;
  PDFieldName: PDField;
  selectedCountry: CountryCodes;
};
type Props = Navigation<
  'ProfileEdit',
  {
    ProfileEdit: NavigationParams<ActionParams>;
  }
>;
export const toProfileEdit: Action<ActionParams> = navigate('ProfileEdit', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ProfileEdit',
  },
});
export const ProfileEdit = ({ route }: Props) => {
  const { value, onSubmit, PDFieldName, selectedCountry } = route.params;
  return (
    <ProfileEditComponent
      value={value}
      onSubmit={onSubmit}
      PDFieldName={PDFieldName}
      selectedCountry={selectedCountry}
    />
  );
};
