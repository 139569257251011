import * as React from 'react';
import { CountryCodes } from '@taxfix/types';

import { bindNavigationActions, setNavigationActions } from './config-util';
import { getCountryConfigs, getCountryDefaultScreenOptions } from './config';
import buildStack from './build-stack';

type AppNavigatorProps = {
  country: CountryCodes;
};

export const AppNavigator = React.memo<AppNavigatorProps>(({ country }) => {
  const [appStack, setAppStack] = React.useState<JSX.Element | null>(null);

  React.useEffect(() => {
    if (country) {
      const { navigationActions, screens } = getCountryConfigs(country);
      setNavigationActions(navigationActions);
      bindNavigationActions();
      const screenOptions = getCountryDefaultScreenOptions(country);
      const getScreens = () => {
        switch (country) {
          default:
            return buildStack(screens, screenOptions, country);
        }
      };
      setAppStack(getScreens());
    }
  }, [country]);

  return appStack && <>{appStack}</>;
});

AppNavigator.displayName = 'AppNavigator';
