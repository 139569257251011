import { useDispatch, useSelector } from 'react-redux';
import { Documents } from '@taxfix/types';

import { useNavigation } from 'src/hooks/navigation-hook';
import { getDefaultTaxYear } from 'src/services/country-and-year/utils';
import { feedbackInquiryTypes } from 'src/containers/FeedbackModal/FeedbackModal.types';

import { actions as overlayActions } from '../../stores/modules/overlay';
import { selectors as settingsSelectors } from '../../stores/modules/settings';

import { useItalyIntl } from './use-italy-intl';

type Props = {
  navigateToStatus(): void;
  navigateToDocumentSelfSigning: (submissionId?: number) => void;
  openReadyForProcessOverlay(): void;
  openSignedMRReadyForProcessOverlay(): void;
  openReadyToSubmitOverlay(): void;
  navigateToIDUpload(): void;
  openNPSOverlay(): void;
  navigateToModelloRedditiSign: (submissionId: number) => void;
};

export const useDashboardNavigationActions = (): Props => {
  const dispatch = useDispatch();
  const { navigationActions } = useNavigation();
  const { getTranslationText } = useItalyIntl();
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();

  const openReadyForProcessOverlay = () => {
    dispatch(
      overlayActions.show('ItalyPostSubmissionOverlay', {
        title: getTranslationText('it.post-submission-overlay.ready-for-process.title'),
        description: getTranslationText('it.post-submission-overlay.ready-for-process.description'),
        ctaTitle: getTranslationText('it.post-submission-overlay.ready-for-process.button.title'),
        onCTAPress: () => dispatch(overlayActions.hide()),
        onClose: () => dispatch(overlayActions.hide()),
      }),
    );
  };

  const openSignedMRReadyForProcessOverlay = () => {
    dispatch(
      overlayActions.show('ItalyPostSubmissionOverlay', {
        title: getTranslationText('it.post-submission-overlay.mr-ready-for-process.title'),
        description: getTranslationText(
          'it.post-submission-overlay.mr-ready-for-process.description',
        ),
        ctaTitle: getTranslationText(
          'it.post-submission-overlay.mr-ready-for-process.button.title',
        ),
        onCTAPress: () => dispatch(overlayActions.hide()),
        onClose: () => dispatch(overlayActions.hide()),
      }),
    );
  };

  const openReadyToSubmitOverlay = () => {
    dispatch(
      overlayActions.show('ItalyPostSubmissionOverlay', {
        title: getTranslationText('it.post-submission-overlay.ready-to-submit.title'),
        description: getTranslationText('it.post-submission-overlay.ready-to-submit.description'),
        ctaTitle: getTranslationText('it.post-submission-overlay.ready-to-submit.button.title'),
        onCTAPress: () => dispatch(overlayActions.hide()),
        onClose: () => dispatch(overlayActions.hide()),
      }),
    );
  };

  const openNPSOverlay = () => {
    dispatch(
      overlayActions.show('Feedback', {
        inquiryType: feedbackInquiryTypes.NPS,
        year: selectedYear,
      }),
    );
  };

  const navigateToStatus = () => {
    // Flow started will be set after gdpr consent is accepted in GDPR screen
    navigationActions.toStatus('screen');
  };

  const navigateToIDUpload = () => {
    navigationActions.toPrefillDocumentUpload('screen', {
      prefillDocumentType: Documents.NonReceiptTypes.Id,
      onNext: () => navigationActions.toDashboard('screen'),
      backNavigationRoute: () => navigationActions.toDashboard('screen'),
      year: selectedYear,
    });
  };

  const navigateToDocumentSelfSigning = (submissionId?: number) => {
    navigationActions.toSelfSignDocument('screen', {
      submissionId,
    });
  };

  const navigateToModelloRedditiSign = (submissionId: number) => {
    navigationActions.toModelloRedditiSign('screen', {
      submissionId,
    });
  };

  return {
    navigateToStatus,
    navigateToDocumentSelfSigning,
    openReadyForProcessOverlay,
    openSignedMRReadyForProcessOverlay,
    openReadyToSubmitOverlay,
    navigateToIDUpload,
    openNPSOverlay,
    navigateToModelloRedditiSign,
  };
};
