import { Pressable, useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { Image } from '../../general/Image';
import { LinkedInIconSolid } from '../../general/MultipathIcons/LinkedInIconSolid';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { CommercialistaBioProps } from './types';

export const CommercialistaBio: React.FC<CommercialistaBioProps> = ({
  commercialistaDetails,
  ...otherProps
}) => {
  const { name, job, content, photo, onPress } = commercialistaDetails;
  const { colors, borderWidths } = useTheme();

  const mainContainerDir = useBreakpointValue({ base: 'row', md: 'column' });
  const mainContainerWidth = useBreakpointValue({ base: '100%', md: '200px' });
  const descriptionWidth = useBreakpointValue({ base: '50%', md: '100%' });

  return (
    <Container
      alignSelf="center"
      verticalAlign="center"
      alignContent="center"
      backgroundColor={colors.greytones.white}
      padding={2}
      width={mainContainerWidth}
      flexDirection={mainContainerDir}
      {...otherProps}
    >
      <Container width={descriptionWidth}>
        <Pressable width={62} onPress={onPress}>
          <Image path={photo} size={62} borderRadius={31} />
          <Container zIndex={1} position="absolute" right="0px" bottom="0px">
            <LinkedInIconSolid size="18px" fillColor={colors.greytones.graphicsDark} />
          </Container>
        </Pressable>
        <Text variant="titleXXSMedium" color={colors.greytones.textDefault} mt={1}>
          {name}
        </Text>
        <Text variant="bodyXSMedium" color={colors.greytones.textDefault}>
          {job}
        </Text>
      </Container>

      <Text
        width={descriptionWidth}
        alignSelf="flex-end"
        variant="bodyXSBook"
        color={colors.greytones.graphicsDark}
        borderLeftWidth={borderWidths.xs}
        borderLeftColor={colors.greytones.graphicsLight}
        mt={1}
        ml={0}
        pl={0}
      >
        {content}
      </Text>
    </Container>
  );
};
