import get from 'lodash/get';
import { Platform } from 'react-native';
import packagedAssets from '@taxfix/image-assets/requires';

import { italyImages } from './images';

const isWeb = Platform.OS === 'web';

export const getIconContents = (name?: string, path?: string) => {
  if (path) {
    return path;
  }
  if (name) {
    // get local images
    const icon = get(italyImages, name, null);
    if (icon) {
      return Platform.OS === 'web' ? icon.default : icon;
    }
  }

  return null;
};

export const rotationStyle = (rotation?: number) => {
  if (rotation) {
    return {
      transform: [
        {
          rotate: `${rotation}deg`,
        },
      ],
    };
  }

  return null;
};
/**
 * Helper function to get the image asset path, you can path this to the image component path prop
 *
 * Please be sure to test it if you will use it in the another library like mobile-repo
 */
export const getImageAsset = (name: string) => {
  const asset = get(packagedAssets, name, null);

  if (asset && isWeb) {
    return asset.default;
  }

  return null;
};
