import { FontSize, FontFamily, LetterSpacing, FontWeight, LineHeight } from './fontTypes';

const getFontSizes = (): FontSize => {
  return {
    '2xl': 'font-size: 44px',
    xl: 'font-size: 30px',
    l: 'font-size: 28px',
    m: 'font-size: 24px',
    sm: 'font-size: 22px',
    s: 'font-size: 20px',
    ms: 'font-size: 18px',
    xs: 'font-size: 16px',
    xxs: 'font-size: 14px',
    b_xs: 'font-size: 12px',
    b_xxs: 'font-size: 11px',
    b_xxxs: 'font-size: 10px',
  };
};

const getFontFamilies = (): FontFamily => {
  return {
    brand: 'font-family: CircularStd-Medium',
  };
};

const getLetterSpacing = (): LetterSpacing => {
  return {
    normal: 'letter-spacing: 1px',
  };
};

const getFontWeights = (): FontWeight => {
  return {
    bold: 'font-family: CircularStd-Bold; font-weight: 700',
    book: 'font-family: CircularStd-Book; font-weight: 450',
    medium: 'font-family: CircularStd-Medium; font-weight: 500',
  };
};

const getLineHeights = (): LineHeight => {
  return {
    extraTight: 'line-height: 13px',
    tight: 'line-height: 16px',
    short: 'line-height: 18px',
    medium: 'line-height: 20px',
    distant: 'line-height: 24px',
    tightDistant: 'line-height: 27px',
    shortDistant: 'line-height: 30px',
    intermediateDistant: 'line-height: 32px',
    mediumDistant: 'line-height: 34px',
    longDistant: 'line-height: 36px',
    extraDistant: 'line-height: 38px',
    extremelyDistant: 'line-height: 56px',
  };
};

export const getFontConfig = (): {
  fontSize: FontSize;
  fontFamily: FontFamily;
  lineHeight: LineHeight;
  fontWeight: FontWeight;
  letterSpacing: LetterSpacing;
} => {
  return {
    fontSize: getFontSizes(),
    fontFamily: getFontFamilies(),
    lineHeight: getLineHeights(),
    fontWeight: getFontWeights(),
    letterSpacing: getLetterSpacing(),
  };
};

export const NBFonts = {
  fontConfig: {
    brand: {
      500: {
        normal: 'CircularStd-Medium',
      },
      450: {
        normal: 'CircularStd-Book',
      },
      700: {
        normal: 'CircularStd-Bold',
      },
    },
  },
  fontSizes: {
    '2xl': '44px',
    xl: '30px',
    l: '28px',
    m: '24px',
    sm: '22px',
    s: '20px',
    ms: '18px',
    xs: '16px',
    xxs: '14px',
    b_xs: '12px',
    b_xxs: '11px',
    b_xxxs: '10px',
  },
  lineHeights: {
    extraTight: '13px',
    tight: '16px',
    short: '18px',
    medium: '20px',
    distant: '24px',
    tightDistant: '27px',
    shortDistant: '30px',
    intermediateDistant: '32px',
    mediumDistant: '34px',
    longDistant: '36px',
    extraDistant: '38px',
    extremelyDistant: '56px',
  },
  letterSpacings: {
    normal: '1px',
  },
  fonts: {
    brand: 'brand',
    brandBook: 'CircularStd-Book',
    brandBookItalic: 'CircularStd-Book-italic',
    brandMedium: 'CircularStd-Medium',
    brandBold: 'CircularStd-Bold',
  },
};
