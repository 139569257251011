import { theme } from '../../../src/components/core';

export default `
:root {
  --color-primary: ${theme.color.primary};
  --color-secondary: ${theme.color.secondary};
  --color-primary-text: ${theme.color.primaryText};
  --color-secondary-text: ${theme.color.secondaryText};
  --color-fill: ${theme.color.fill};
  --color-light-fill: ${theme.color.lightFill};
  --color-border: ${theme.color.border};
  --color-background: ${theme.color.background};
  --color-light-text: ${theme.color.lightText};
  --color-error-text-light: ${theme.color.errorTextLight};

  --font-bold: 'Circular-Bold';
  --font-book: 'Circular-Book';
  --font-medium: 'Circular-Medium';
  --font-italic: 'Circular-Book-italic';
}
`;
