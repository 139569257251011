import { Payment as PaymentSDK } from '@taxfix/payment-sdk';

export type Locale = 'it' | 'en';

export type StripePaymentFormProps = {
  stripePublishableKey: string;
  onSubmit: (
    confirmPayment: ConfirmPaymentCallback,
    paymentMethod?: ItalyPaymentType,
  ) => Promise<void>;
  onFinish: (paymentId: number, paymentMethod?: ItalyPaymentType) => Promise<void>;
  onApplyVoucher: (couponCode: string) => Promise<ApplyVoucherResponse>;
  onRemoveVoucher: () => void;
  productOriginalPrice: number;
  productPrice: number;
  validateForm: () => boolean;
  scrollToTop: () => void;
};

export const TAXFIX_BACKEND_ERROR = 'API::ERROR';

export type ConfirmPaymentCallback = (clientSecret: string, paymentId: number) => Promise<void>;

export enum StripeRedirectStatus {
  failed = 'failed',
  succeeded = 'succeeded',
}

export type ApplyVoucherResponse = {
  code: string;
  amountCents: number;
};

export type ItalyPaymentType = PaymentSDK.PaymentType.Card | PaymentSDK.PaymentType.PayPal;
