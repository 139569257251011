import * as React from 'react';
import { StyleSheet } from 'react-native';

import { LinkText } from '../Text';
import { theme } from '../theme';

import InternalButton, { InternalButtonPropsWithStyle } from './_InternalButton';

const style = StyleSheet.create({
  linkText: {
    borderRadius: 15,
    borderWidth: 1.5,
    paddingHorizontal: 15,
    paddingBottom: 5,
    paddingTop: 7,
    textAlign: 'center',
  },
});

type Props = {
  children: React.ReactNode;
  borderColor?: string;
} & InternalButtonPropsWithStyle;

export default function OutlineButton({
  children,
  borderColor = theme.color.primary,
  ...props
}: Props): React.ReactElement {
  return (
    <InternalButton {...props}>
      <LinkText style={{ ...style.linkText, borderColor }}>{children}</LinkText>
    </InternalButton>
  );
}
