//TODO: This is temp component and can be removed once we have our app separated
import React from 'react';
import { View, StyleSheet } from 'react-native';

import { theme } from 'src/components/core';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { TabNames } from 'src/common/constants-it';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';

import { getNavigationActions } from '../config-util';

import { TabItem } from './tab-item';
import { BottomTab } from './types';

export const ITALY_TABS_HEIGHT = 74;

const styles = StyleSheet.create({
  smallScreenTab: {
    backgroundColor: theme.color.lightFill,
    alignSelf: 'center',
    flexDirection: 'row',
    width: '100%',
    height: ITALY_TABS_HEIGHT,
    borderTopColor: theme.color.fill,
    borderTopWidth: 1,
  },
  largeScreenTab: {
    width: 120,
    flexDirection: 'column',
  },
});

type Props = {
  highlighedTab?: TabNames;
};

export const ItalyTabs: React.FC<Props> = ({ highlighedTab }) => {
  const showSideTabs = useShowSideTabs();
  const { getTranslationText } = useItalyIntl();

  const tabs: BottomTab[] = [
    {
      index: 0,
      tabName: TabNames.Dashboard,
      tabKey: TabNames.Dashboard,
      iconName: 'account',
      translationKey: 'it.tab.dashboard',
      isActive: highlighedTab === TabNames.Dashboard,
      onPress: () => {
        getNavigationActions().reset({
          index: 0,
          actions: [
            getNavigationActions().toDashboard('screen', {
              transitionAnimationEnabled: false,
            }),
          ],
        });
      },
    },
    {
      index: 0,
      tabName: TabNames.TaxGuide,
      tabKey: TabNames.TaxGuide,
      iconName: 'italy-tab-tax-guide-icon',
      translationKey: 'it.tab.tax-guide',
      isActive: highlighedTab === TabNames.TaxGuide,
      onPress: () => {
        getNavigationActions().reset({
          index: 0,
          actions: [getNavigationActions().toTaxGuide('screen')],
        });
      },
    },
    {
      index: 1,
      tabName: TabNames.Experts,
      tabKey: TabNames.Experts,
      iconName: 'italy-tab-commercialista-icon',
      translationKey: 'it.tab.experts',
      isActive: highlighedTab === TabNames.Experts,
      onPress: () => {
        getNavigationActions().reset({
          index: 0,
          actions: [getNavigationActions().toTaxExpert('screen')],
        });
      },
    },
    ...(showSideTabs
      ? []
      : [
          {
            index: 2,
            tabName: TabNames.Account,
            tabKey: TabNames.Account,
            translationKey: 'it.tab.account',
            iconName: 'italy-tab-account-icon',
            isActive: highlighedTab === TabNames.Account,
            onPress: () => {
              getNavigationActions().reset({
                index: 0,
                actions: [getNavigationActions().toAccount('screen')],
              });
            },
          },
        ]),
  ];

  return (
    <View style={showSideTabs ? styles.largeScreenTab : styles.smallScreenTab}>
      {tabs.map(({ tabKey, iconName, index, translationKey, onPress, isActive }: BottomTab) => {
        const isLastTab = index === tabs.length - 2;

        const handleOnTabPress = () => {
          onPress();
        };

        return (
          <TabItem
            key={tabKey}
            isActive={isActive}
            onPress={handleOnTabPress}
            iconKey={iconName}
            text={getTranslationText(translationKey)}
            isLargeScreen={showSideTabs}
            isLastTab={isLastTab}
          />
        );
      })}
    </View>
  );
};
