import * as React from 'react';

import { isIOS } from 'src/utils/platform';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

import { AppBlockingScreen } from './app-blocking-screen';
import { ForceUpdateScreen } from './force-update-screen';

type Props = {
  variant: 'forceUpdate' | 'maintenance' | 'unauthorized';
};

export class AppBlockingScreenContainer extends React.Component<Props> {
  componentDidMount() {
    const { variant } = this.props;
    const trackingEvent =
      variant === 'forceUpdate'
        ? AnalyticsEvent.sectionForceUpdate
        : AnalyticsEvent.sectionMaintenance;
    Analytics.log(trackingEvent);
  }

  render() {
    const { variant } = this.props;

    if (variant === 'forceUpdate') {
      return <ForceUpdateScreen />;
    }

    if (variant === 'unauthorized') {
      return (
        <AppBlockingScreen
          image="error.error-something-wrong"
          titleTextId={isIOS ? 'unauthorized.header.ios' : 'unauthorized.header.android'}
          bodyTextId={isIOS ? 'unauthorized.body.ios' : 'unauthorized.body.android'}
        />
      );
    }

    return (
      <AppBlockingScreen
        image="error.error-something-wrong"
        titleTextId="maintenance.header"
        bodyTextId="maintenance.body"
      />
    );
  }
}
