import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { Place, Suggestion } from '../../types/geo';

import List from './address-list/components/list';
import Error from './address-list/components/error';

type Props = {
  intl: IntlShape;
  previousPlaces: Place[];
  searchResults: Suggestion[];
  onPressPreviousPlace?: (place: Place) => void;
  onPressSearchResult: (suggestion: Suggestion) => void;
  onPressManual?: () => void;
  error?: string;
  color?: string;
};

const AddressList = ({
  intl,
  previousPlaces,
  searchResults,
  onPressPreviousPlace,
  onPressSearchResult,
  onPressManual,
  error,
  color,
}: Props) => {
  if (!error) {
    return (
      <List
        intl={intl}
        previousPlaces={previousPlaces}
        searchResults={searchResults}
        onPressPreviousPlace={onPressPreviousPlace}
        onPressSearchResult={onPressSearchResult}
      />
    );
  }

  return <Error onPressManual={onPressManual} error={error} color={color} />;
};

export default injectIntl(AddressList);
