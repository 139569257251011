import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { IntlShape, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { CountryCodes } from '@taxfix/types';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { Answer, TreeNode } from '@taxfix/quizmaster/dist/types';
import { isSupported, unsupportedAnswersIT } from '@taxfix/who-are-you';
import { UnsupportedItem } from '@taxfix/who-are-you/lib/types';
import { ITGroup } from '@taxfix/who-are-you/lib/italy';

import { Container, NavButton } from 'src/taxfix-components/src';
import { PAYSLIP_DATA } from 'src/taxfix-business-logic/constants/question-answer';
import { WEB_SECTION_WIDE_WIDTH } from 'src/utils/constants';
import { KeysAndValues } from 'src/utils/translationValuesWithDefaults';
import {
  correlatedPrefilledCategories,
  prefilledCategoryInfoContent,
} from 'src/common/constants-it';

import { Box, Button } from '../../../components/core';
import QuestionStore from '../../../stores-legacy/QuestionStore';
import QuestionMaster from '../components/question-master-it';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import { withQuestionStore } from '../../../utils/withQuestionStore';
import { Loading } from '../../../components/loading';
import { Sleep } from '../../../utils/async';
import initial from '../../../stores/store/initial';
import { selectors as internetConnectionSelectors } from '../../../stores/modules/internet-connection';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
  countrySpecificProps,
} from '../../../stores/modules/settings';
import {
  actions as flowActions,
  MandatoryFilerStatusPayload,
} from '../../../stores/modules/flow-legacy';
import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import { actions as OverlayActions } from '../../../stores/modules/overlay';
import { OuterProps } from '../../../containers/questions.types';
import { ProgressBar } from '../../../containers/progress-bar';

const mapStateToProps = (state: typeof initial) => ({
  trackingParams: internetConnectionSelectors.getTrackingParams(state),
  year: settingsSelectors.selectedYear(state),
  selectedCountry: settingsSelectors.selectedCountry(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateMandatoryFilerStatus: (payload: MandatoryFilerStatusPayload) =>
    dispatch(flowActions.updateMandatoryFilerStatus(payload)),
  answersUpdated: () =>
    dispatch(
      settingsActions.updateCountrySpecific(
        CountryCodes.IT,
        countrySpecificProps.areQuizAnswersUpdated,
        true,
      ),
    ),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = OuterProps &
  ReduxProps &
  WithNavigation & {
    overlayActions: typeof OverlayActions;
    fastLaneEligible: boolean;
    intl: IntlShape;
    questionStore: QuestionStore;
    hideIntroCard?: boolean;
    currentScreenKey: string;
    onResponseWithTransitions?: any;
    isEnableEngageMandatoryFilers: boolean;
    onNext: () => void;
    isLargeScreen: boolean;
    backNavigationText: string;
    hideSummary: boolean;
    skipFinalSummary: boolean;
  };

type State = {
  supported: boolean;
  isEndCardActive: boolean;
  unsupportedAnswers: UnsupportedItem<ITGroup>[];
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: 15,
  },
  quizContainer: {
    marginHorizontal: 'auto',
    width: '100%',
    flex: 1,
    maxWidth: WEB_SECTION_WIDE_WIDTH,
  },
});

class UndecoratedQuestionsContainer extends React.Component<Props, State> {
  state = {
    supported: isSupported(this.props.questionStore.activeResponseJS, this.props.selectedCountry),
    isEndCardActive: false,
    unsupportedAnswers: unsupportedAnswersIT(this.props.questionStore.activeResponseJS || []),
  };

  componentDidMount() {
    // This handler used to go into componentWillReceiveProps() but now
    // that we're using mobx we need to wrap it in an autorun() block
    // otherwise it will never run when responses change
    this.questionStoreUpdateHandler = autorun(() => {
      this.setState({
        supported: isSupported(
          this.props.questionStore.activeResponseJS,
          this.props.selectedCountry,
        ),
        unsupportedAnswers: unsupportedAnswersIT(this.props.questionStore.activeResponseJS || []),
      });
    });
  }

  componentWillUnmount() {
    if (this.questionStoreUpdateHandler) {
      this.questionStoreUpdateHandler();
      this.questionStoreUpdateHandler = null;
    }
  }

  questionStoreUpdateHandler?: (() => void) | null;

  onQuestions = (params: any) => {
    const { navigation } = this.props;
    // if we don't use the `push` method to navigate to the next screen with the same `name`
    // the screen is not added to the stack and the back button will not work properly
    // https://reactnavigation.org/docs/navigating#navigate-to-a-route-multiple-times
    navigation.push('screen/QuizMaster', params);
  };

  handleComplete = () => {
    this.props.onNext();
  };

  handleAnswer = async (node: TreeNode, answer: Answer) => {
    const {
      editing,
      navigation,
      updateMandatoryFilerStatus,
      year,
      trackingParams,
      answersUpdated,
    } = this.props;
    const isPayslipLastQuestionAnswered = PAYSLIP_DATA.questionID === node?.questionId;
    updateMandatoryFilerStatus({
      questionId: node?.questionId,
      isPayslipLastQuestionAnswered,
      year,
    });

    if (editing) {
      answersUpdated();

      /* android weird behaviour after edited answer on TextInput */
      if (Platform.OS === 'android') await Sleep(200);

      this.props.questionStore.saveAnswer(node, answer, trackingParams);

      navigation.goBack();
    } else {
      this.props.questionStore.saveAnswer(node, answer, trackingParams);
    }
  };

  handleEdit = (node: TreeNode, onResponseWithTransitions: any = null) => {
    Analytics.log(AnalyticsEvent.questionEdited, this.createAnalyticsParameters(node));
    this.onQuestions({
      key: node.id,
      root: node.id,
      editing: true,
      onResponseWithTransitions,
    });
  };

  handleInformation = (node: TreeNode, translationValues: KeysAndValues) => {
    Analytics.log(AnalyticsEvent.questionHelp, this.createAnalyticsParameters(node));
    const { information } = node;

    if (information == null) {
      return;
    }

    if (information.type == null || information.content == null) {
      return;
    }

    this.props.overlayActions.show('QuestionITGuidanceOverlay', {
      content: information.content,
      translationValues,
    });
  };

  handleSkip = (node: TreeNode) => {
    Analytics.log(AnalyticsEvent.questionSkipped, this.createAnalyticsParameters(node));
    this.props.questionStore.skipQuestion(node);
  };

  createAnalyticsParameters = (node: TreeNode) => {
    const { year } = this.props;

    return {
      questionId: node.questionId,
      year,
      partnerQuestion: false,
    };
  };

  getProgress = (): number => {
    const { questionStore, editing, root } = this.props;

    if (editing) {
      return 0;
    }

    if (this.state.unsupportedAnswers.length > 0) {
      return 1;
    }

    const category = questionStore.getCategory(root);

    if (category) {
      return category.progress;
    }

    return 0;
  };

  isPrefilledCategory = () => {
    const { root, questionStore } = this.props;
    if (correlatedPrefilledCategories[root]) {
      const prefillCategory = questionStore.getCategory(correlatedPrefilledCategories[root]);
      return prefillCategory && prefillCategory?.progress > 0;
    }
    return false;
  };

  getPrefilledContent = () =>
    this.isPrefilledCategory() ? prefilledCategoryInfoContent[this.props.root] : undefined;

  onMoreInfoPressed = () => {
    const { title, content } = prefilledCategoryInfoContent[this.props.root].infoOverlay;
    this.props.overlayActions.show('PrefilledQuestionFlowInfoOverlay', { title, content });
  };

  render() {
    const {
      backNavigationText,
      root,
      editing,
      isLargeScreen,
      navigation,
      questionStore,
      hideSummary,
      skipFinalSummary,
    } = this.props;
    const category = questionStore.getCategory(root);
    const isCategoryComplete = category?.isComplete;

    if (this.state.isEndCardActive) {
      return <Loading />;
    }
    const showPrefilledSummaryContent =
      this.isPrefilledCategory() && category && category.progress === 1;

    const ctaTranslationKey = showPrefilledSummaryContent
      ? 'it.question-flow.prefilled-categories.button.cta'
      : 'it.digital-caf.required-documents.footer-button.continue';

    return (
      <>
        {!editing && <ProgressBar progress={this.getProgress()} />}
        {isLargeScreen && navigation.canGoBack() && (
          <Container top={0} zIndex={1} position="absolute">
            <NavButton onPress={navigation.goBack} text={backNavigationText} />
          </Container>
        )}

        <Container style={styles.quizContainer} marginTop={isLargeScreen ? 16 : 0}>
          <Box style={styles.container}>
            <Box top={isLargeScreen ? 4 : 0} flex={1}>
              <QuestionMaster
                year={this.props.year}
                root={root}
                editing={editing}
                hideSummary={hideSummary}
                skipFinalSummary={skipFinalSummary}
                onAnswer={this.handleAnswer}
                onEdit={this.handleEdit}
                onInformation={this.handleInformation}
                onSkip={this.handleSkip}
                hideIntroCard={this.props.hideIntroCard}
                showPrefilledSummaryContent={showPrefilledSummaryContent}
                prefilledTranslationKeys={this.getPrefilledContent()}
                onMoreInfoPressed={this.onMoreInfoPressed}
                onComplete={this.handleComplete}
              />
            </Box>

            {isCategoryComplete && !editing && (
              <Box top={1} right={1} bottom={isLargeScreen ? 6 : 1} left={1}>
                <Button
                  type="primary"
                  floating={false}
                  onPress={this.handleComplete}
                  translationKey={ctaTranslationKey}
                  testId="who-are-you.continue-button"
                  style={styles.button}
                />
              </Box>
            )}
          </Box>
        </Container>
      </>
    );
  }
}

export const QuestionsContainer = compose<any>(
  connector,
  withQuestionStore,
  injectIntl,
  withNavigation,
  observer,
)(UndecoratedQuestionsContainer);
