import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { isMobileWeb } from '../utils/platform';

import { useSmallScreenQuery } from './core/MediaQuery';
import { SuccessOverlayContentProps, SuccessOverlayContent } from './success-overlay-content';
import { theme } from './core/theme';
import IconButton from './core/Button/IconButton';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(1, 1, 1, 0.8)',
  },
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    zIndex: 2,
    width: 580,
    height: 580,
    borderRadius: 12,
    backgroundColor: theme.color.primary,
    opacity: 1,
  },
  modalContainerSmall: {
    width: '100%',
    height: '100%',
    minHeight: 580,
    borderRadius: 0,
  },
  crossButton: {
    height: 91,
    alignItems: 'flex-end',
    marginRight: 35,
  },
  crossButtonSmall: {
    display: 'none',
  },
  contentContainer: {
    flex: 1,
    marginBottom: 60,
    alignItems: 'center',
  },
  contentContainerSmall: {
    marginBottom: 0,
  },
});
export const SuccessOverlay = ({
  contentComponent: ContentComponent,
  containerStyles = {},
  icon,
  iconStyles,
  titleId,
  descriptionId,
  buttonId,
  onNext,
  translationValues,
}: SuccessOverlayContentProps): JSX.Element => {
  const isSmallScreen = useSmallScreenQuery();

  // Hack to ensure the modal fills the page when the page scrolls beyond the (initial) viewport height.
  const overlayHeight = useMemo(() => {
    return document.body.scrollHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleId, descriptionId, onNext]);
  return (
    <View style={[styles.overlay, { height: overlayHeight }]}>
      <View style={styles.mainContainer}>
        <View
          style={
            isMobileWeb || isSmallScreen
              ? [styles.modalContainer, styles.modalContainerSmall]
              : [styles.modalContainer, containerStyles]
          }
        >
          {(ContentComponent && (
            <ContentComponent onNext={onNext} translationValues={translationValues} />
          )) || (
            <>
              <View
                style={
                  isMobileWeb ? [styles.crossButton, styles.crossButtonSmall] : styles.crossButton
                }
              >
                <IconButton iconKey="cross" onPress={onNext} tintColor={theme.color.lightFill} />
              </View>
              <View
                style={
                  isMobileWeb
                    ? [styles.contentContainer, styles.contentContainerSmall]
                    : styles.contentContainer
                }
              >
                <SuccessOverlayContent
                  icon={icon}
                  iconStyles={iconStyles}
                  titleId={titleId}
                  descriptionId={descriptionId}
                  buttonId={buttonId}
                  translationValues={translationValues}
                  onNext={onNext}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
};
