import * as React from 'react';
import { ScrollView } from 'react-native';

import { theme } from 'src/components/core';
import { UniversalConfirmableProps } from 'src/components/answers/types';
import { testID } from 'src/common/testID';
import AnswerForm from 'src/_italy/quizmaster/components/AnswerForm';

import { MultiSelectOption, MultiSelectOptionId, MultiSelectList } from './multi-select-list';

type Props = UniversalConfirmableProps & {
  value: MultiSelectOption[];
  // Empty value must be an option key such as 'NONE' and cannot be a null key
  emptyValue?: MultiSelectOptionId;
  ignoreEmptyValue?: boolean;
  required?: boolean;
  alignment?: 'vertical' | 'horizontal';
  options: MultiSelectOption[];
  color?: string;
  colorSecondary?: string;
  submitButtonTranslationKey?: string;
  submitButtonText?: string;
  withoutTruncation?: boolean;
};
type State = {
  currentValue: MultiSelectOptionId[];
};

// Removes props.emptyValue from props.value array
// so that currentValue array only contains non-empty values
const currentValueForProps = (props: Props) => {
  const { value, emptyValue } = props;
  return (value || []).filter((elem: any) => elem !== emptyValue);
};

class MultiSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentValue: this.props.ignoreEmptyValue
        ? this.props.value || []
        : currentValueForProps(props),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.ignoreEmptyValue) return;
    this.setState({
      currentValue: currentValueForProps(nextProps),
    });
  }

  handleChange = (value: any) => {
    const { emptyValue, onChange, ignoreEmptyValue } = this.props;
    const { currentValue } = this.state;

    const saveValueToState = (newValue: any) =>
      this.setState(
        {
          currentValue: newValue,
        },
        () => {
          if (onChange) onChange(newValue);
        },
      );

    if (!ignoreEmptyValue) {
      return saveValueToState(value);
    }

    const isEmptyChecked = emptyValue && currentValue.includes(emptyValue);

    if (isEmptyChecked) {
      return saveValueToState(value.filter((elem: any) => elem !== emptyValue));
    }

    if (value.includes(emptyValue)) {
      return saveValueToState([emptyValue]);
    }

    return saveValueToState(value);
  };

  handlePress = () => {
    const { question, emptyValue, ignoreEmptyValue, onAnswer } = this.props;
    const { currentValue } = this.state;

    if (ignoreEmptyValue) {
      onAnswer(question, currentValue);
    } else {
      onAnswer(question, currentValue.length ? currentValue : [emptyValue]);
    }
  };

  render() {
    const {
      options,
      emptyValue,
      color = theme.color.primary,
      colorSecondary = theme.color.secondary,
      withoutTruncation,
      ignoreEmptyValue,
    } = this.props;
    const { currentValue } = this.state;
    const inputComponentProps = { ...this.props, onAnswer: null };
    const isButtonDisabled = (!emptyValue || ignoreEmptyValue) && currentValue.length < 1;

    const form = {
      disabled: isButtonDisabled,
      onConfirm: this.handlePress,
    };

    return (
      <AnswerForm noGutters {...form}>
        <ScrollView nestedScrollEnabled {...testID('test.scroll.multi')}>
          <MultiSelectList
            autoFocus
            {...inputComponentProps}
            options={options}
            value={currentValue ?? []}
            color={color}
            withoutTruncation={withoutTruncation}
            colorSecondary={colorSecondary}
            onChange={this.handleChange}
          />
        </ScrollView>
      </AnswerForm>
    );
  }
}

export { MultiSelect };
