import React, { FC, useRef, useState } from 'react';
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native';

import { InputField } from 'src/taxfix-components/src';

import { DropdownMenuInputs } from './dropdown-menu-inputs';
import { DropdownMenuProps } from './types';

const styles = StyleSheet.create({
  dropdown: {
    maxHeight: 180,
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    shadowColor: '#000000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.12,
    shadowRadius: 15,
    elevation: 3,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
});

const DropdownMenu: FC<DropdownMenuProps> = ({
  options,
  selectedValue,
  onAnswer,
  labelInputField,
  testID,
  error,
  placeholder,
}) => {
  const DropdownButton = useRef<TouchableOpacity | null>(null);
  const [visible, setVisible] = useState(false);
  const [dropdownTopPositon, setDropdownTopPosition] = useState(0);
  const [dropdownRightPosition, setDropdownRightPosition] = useState(0);
  const [dropdownWidthPosition, setDropdownWidthPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setPositionOfTheDropdownMenu = (): void => {
    DropdownButton?.current?.measure(
      (_fx: number, _fy: number, w: number, h: number, px: number, py: number) => {
        setDropdownTopPosition(py + h + 5);
        setDropdownRightPosition(-px);
        setDropdownWidthPosition(w);
      },
    );
  };

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setPositionOfTheDropdownMenu();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const openDropdown = (): void => {
    setPositionOfTheDropdownMenu();
    setVisible(true);
  };

  const toggleDropdown = (): void => {
    visible ? setVisible(false) : openDropdown();
  };

  const renderDropdown = (): JSX.Element => {
    return (
      <Modal visible={visible} transparent animationType="none">
        <View style={[styles.overlay, { maxWidth: dropdownWidthPosition }]}>
          <View
            style={[styles.dropdown, { top: dropdownTopPositon, right: dropdownRightPosition }]}
          >
            <DropdownMenuInputs
              options={options}
              onAnswer={(answer: string) => {
                onAnswer(answer);
                setVisible(false);
              }}
              selectedValue={selectedValue}
            />
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <TouchableOpacity ref={DropdownButton} onPress={toggleDropdown}>
      <InputField
        label={labelInputField}
        isInvalid={!!error}
        fontWeight="bold"
        color="brand.brandGreen"
        value={selectedValue?.label}
        testID={testID}
        editable={false}
        placeholder={placeholder}
        isDropdown
        iconOpen
      />
      {renderDropdown()}
    </TouchableOpacity>
  );
};

export default DropdownMenu;
