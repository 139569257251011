import { Avatar, Pressable, useTheme } from 'native-base';
import React from 'react';

import { Container, Text } from 'src/taxfix-components/src';

import { LinkedInIconSolid } from '../../general/MultipathIcons/LinkedInIconSolid';

import { ExpertDetailsCardsProps } from './types';

/**
 * Show the Expert Profile and link to his/her LinkedIn
 */

export const ExpertProfileCard: React.FC<ExpertDetailsCardsProps> = ({
  expertDetails,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();
  const { name, job, experience, content, photo, onPress } = expertDetails;

  const {
    greytones: { graphicsDark, textDefault, white, greyBlue },
  } = colors;
  return (
    <Container
      alignContent="center"
      backgroundColor={greyBlue}
      borderRadius="15px"
      padding="24px"
      {...otherProps}
    >
      <Container flexDir="row" alignItems="center" marginBottom="21px">
        <Pressable width={62} onPress={onPress}>
          <Avatar
            //@ts-ignore
            source={photo}
            borderWidth="4px"
            borderColor={white}
            borderRadius={5}
            size="64px"
          />

          <Container zIndex={1} position="absolute" right="0px" bottom="0px">
            <LinkedInIconSolid size="18px" fillColor={graphicsDark} />
          </Container>
        </Pressable>
        <Container marginLeft="20px">
          <Text variant="titleXXSMedium" color={textDefault} value={name} />
          <Text variant="bodySBook" color={textDefault} value={job} />
          <Text variant="bodySBook" color={textDefault} value={experience} />
        </Container>
      </Container>
      <Container borderLeftWidth="1px" borderLeftColor={graphicsDark}>
        <Text
          alignSelf="flex-end"
          variant="bodySBook"
          color={graphicsDark}
          value={content}
          ml="6px"
        />
      </Container>
    </Container>
  );
};
