import * as React from 'react';

import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import navigate, { Action } from 'src/routes/navigate';

import { ItalyChatContainer } from './italy-chat-container';

export const toItalyChat: Action = navigate('ItalyChat', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'ItalyChat',
  },
});

export const ItalyChat: React.FC = () => {
  useDefaultNavigationOptions(
    { id: 'it.chat.nav.title' },
    {
      headerBackTitleVisible: false,
      headerRight: undefined,
    },
  );

  return <ItalyChatContainer />;
};
