import { LanguageCode } from 'src/types';

import uncountry from './uncountry';

// searches the available languages in the app for a language the user prefers.
// if nothing available, revert back to the default language
const appLanguageFor = (
  supportedLanguages: LanguageCode[],
  preferredLanguages: string[],
): LanguageCode => {
  if (supportedLanguages.length === 0) {
    throw new Error('at least 1 supported language is required');
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const preferredLanguage of preferredLanguages) {
    const truncated = uncountry(preferredLanguage);

    if ((supportedLanguages as string[]).includes(truncated)) {
      return truncated as LanguageCode;
    }
  }

  return supportedLanguages[0];
};

export default appLanguageFor;
