import { $PropertyType, $Shape } from 'utility-types';
import { Dispatch } from 'redux';

import { Options, saveLegacyData } from '../utils/submission';

export const UPDATE = 'bank-details/UPDATE';
export const UPDATE_SUCCESS = 'bank-details/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'bank-details/UPDATE_ERROR';
export type AccountHolderType = 'user' | 'partner' | 'both';
export type State = {
  isLoading: boolean;
  data: {
    accountHolder: AccountHolderType;
    iban: string;
    bankName: string;
  };
};
type UpdateAction = {
  type: typeof UPDATE;
  payload: $Shape<$PropertyType<State, 'data'>>;
};
type Action = UpdateAction;

const update =
  (payload: $PropertyType<UpdateAction, 'payload'>) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE,
    });
    dispatch({
      type: UPDATE_SUCCESS,
      payload,
    });
  };

const updateLegacy =
  (payload: $PropertyType<UpdateAction, 'payload'>, options: Options) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE,
    });

    try {
      await saveLegacyData(payload, { ...options, store: 'bankDetails' });
      dispatch({
        type: UPDATE_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ERROR,
      });
    }
  };

export const actions = {
  update,
  updateLegacy,
};
export const initial: State = {
  isLoading: false,
  data: {
    accountHolder: 'user',
    iban: '',
    bankName: '',
  },
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type as any) {
    case UPDATE:
      return { ...state, isLoading: true };

    case UPDATE_SUCCESS:
      return { ...state, isLoading: false, data: { ...state.data, ...action.payload } };

    case UPDATE_ERROR:
      return { ...state, isLoading: false };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
