import * as React from 'react';

import { Box, TextButton } from '../components/core';
import { WithNavigation, withNavigation } from '../hocs/with-navigation';

type Props = {
  translationId: string;
} & WithNavigation;

const LoginButtonNotConnected = ({ translationId, navigationActions }: Props) => {
  const handleLoginClick = () => {
    navigationActions.toLogin('screen');
  };

  return (
    <Box left={0.5}>
      <TextButton translationKey={translationId} onPress={handleLoginClick} />
    </Box>
  );
};

export const LoginButton = withNavigation(LoginButtonNotConnected);
