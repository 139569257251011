import { $Values } from 'utility-types';
import * as React from 'react';
import * as _ from 'lodash';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { NavigationActions, ScreenMode } from '../../routes/config-util';
import { Params, AnalyticsEvent } from '../../biz-logic/analytics';
import { TraceDecorator as Trace } from '../../services/firebase-performance';
import { startPerformanceTraceWithDefaultAttributes } from '../../services/firebase-performance.utils';
import { PerformanceLabels } from '../../services/firebase-performance.labels';
import { State as Store } from '../store/initial';
import { RoutesNavigationActions } from '../../routes/navigation-actions';

export const TIMEOUT_INTERVAL = 5 * 1000;
export const INIT_STATUS = 'status/INIT';
export const REQUEST_REFRESH_STATUS = 'status/REQUEST_REFRESH_STATUS';
export const UPDATE_STATUS = 'status/UPDATE_STATUS';
export const FETCH_STATUS = 'status/FETCH_STATUS';
export const FETCH_STATUS_SUCCESS = 'status/FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'status/FETCH_STATUS_FAILURE';
export const FETCH_STATUS_CANCEL = 'status/FETCH_STATUS_CANCEL';
export const FETCH_STATUS_LOG = 'status/FETCH_STATUS_LOG';
export const SET_ENHANCED_LOADING_CONFIG = 'status/SET_ENHANCED_LOADING_CONFIG';
export const PERFORMANCE_LABEL = PerformanceLabels.statusResolution;
export const StatusStates = Object.freeze({
  Email: 'email',
  Identification: 'identification',
  CheckRequiredDocuments: 'check-required-documents',
  Submit: 'submit',
  FetchSubmissions: 'fetch-submissions',
  UpdateSubmissionAndPaymentStatus: 'update-submission-and-payment-status',
  FetchPrefills: 'fetch-prefills',
  // TODO ITA-642 Prefill becomes Identification
  Complete: 'complete',
  EnableNotifications: 'enable-notifications',
  ExpenseDocUpload: 'expense-doc-upload',
  CheckAndUpdateUserData: 'check-and-update-user-data',
  SeasonQF: 'season-qf',
  StoresMigrations: 'stores-migrations',
  IncomeDocUpload: 'income-doc-upload',
  TaxIdQF: 'tax-id-qf',
  SPIDConsent: 'spid-consent',
  PrefillSPIDLogin: 'prefill-spid-login',
  IdDocument: 'id-document',
  Payment: 'payment',
  Submission: 'submission',
  PostSubmission: 'post-submission',
  ProductBundleSelection: 'product-bundle-selection',
  ResultScreen: 'result-screen',
  PreseasonQuestionFlow: 'preseason-question-flow',
  PreseasonSPIDLogin: 'preseason-spid-login',
  PreseasonCUDocument: 'preseason-cu-document',
  TailoredExperience: 'tailored-experience',
});

type Status = $Values<typeof StatusStates>;
export enum LinkOpenActions {
  WebView = 'webView',
  BrowserView = 'browserView',
}
export type NavigationLink = {
  action: keyof RoutesNavigationActions | string;
  config: ScreenMode;
  openAction?: LinkOpenActions;
  params?: Record<string, any>;
  tracking?: {
    eventName: AnalyticsEvent;
    params?: Record<any, any>;
  };
};
export type StatusStepSectionNavigation = {
  link: NavigationLink;
  id?: string;
};
export type Title = {
  id: string;
  values?: Record<string, string | null | undefined>;
};
export type Subtitle = {
  text: string;
  translationValues?: Record<string, (string | number | Date) | null | undefined>;
  bgColor: string;
  textColor: string;
  icon?: string;
  iconColor?: string;
  onIconClickHandler?: () => void;
};
export type SubtitleWithTranslationValues = Subtitle &
  Required<Pick<Subtitle, 'translationValues'>>;
export type Message = {
  id: string;
  values?: Record<string, (string | null | undefined) | (number | null | undefined)>;
};
export type StatusStepSectionTracking = {
  eventName: AnalyticsEvent;
  params?: {
    journeyStatus?: string;
  } & Params;
};
export type StatusStepSectionInfoButton = {
  color?: string;
  handleOnPress?: () => void;
  tracking?: StatusStepSectionTracking;
  content?: string;
  isMarkdown?: boolean;
  translationKey?: string;
  translationValues?: Record<string, string>;
};
export type StatusStepSection = {
  title?: Title;
  subtitle?: Subtitle[];
  subtitleOverride?: string;
  messages?: Message[];
  icon?: string;
  warning?: boolean;
  stepDisabled?: boolean;
  inProgress?: boolean;
  buttonDisabled?: boolean;
  tracking?: StatusStepSectionTracking;
  navigation?: StatusStepSectionNavigation;
  infoButton?: StatusStepSectionInfoButton;
  extraContent?: React.ReactNode;
  skipped?: boolean;
  isSummary?: boolean;
  completeStepOverrides?: {
    subtitle?: Subtitle[];
    messages?: Message[];
    icon?: string;
  };
};
export type StatusStepNavigationData = {
  title: string;
  link?: NavigationLink;
  disabled?: boolean;
  tracking?: StatusStepSectionTracking;
};
export type StatusStepNavigationComponent = React.FC<any>;
export type StatusStepNavigation = StatusStepNavigationData | StatusStepNavigationComponent;
export type StatusStepSectionWithIndex = StatusStepSection & {
  index: number;
  iconCompleted?: string;
  iconLocked?: string;
};
export type StatusStepBanner = {
  type: string;
  tracking?: StatusStepSectionTracking;
  values?: Record<string, any>;
  onAction?: () => void;
};
export type StatusStepRedirect = (
  store: Store,
  dispatch: Dispatch<any>,
) => (navigation: NavigationActions) => void;

export type ComputedRedirect = any;
export type StatusSkipFunction = (getState: () => any) => Promise<boolean>;
export type SectionResolverFunction = (
  getState: () => any,
  sections: Record<string, StatusStepSection>,
  index: number,
  stepIndex: number,
  customSection: StatusStepSection | null | undefined,
) => StatusStepSection | null | undefined;
export type AsyncSectionResolverFunction = (
  getState: () => any,
  sections: Record<string, StatusStepSection>,
  index: number,
  stepIndex: number,
  customSection: StatusStepSection | null | undefined,
) => Promise<StatusStepSection | null | undefined>;
export type StatusPostBreakProcessFunction = (
  dispatch: Dispatch<any>,
  getState: () => any,
) => Promise<void>;
export type StatusWatcher = (store: Record<string, any>) => boolean;
export type StatusStep = {
  id: Status;
  // eslint-disable-next-line no-use-before-define
  middlewares: StatusMiddleware[];
  postBreakProcessing?: StatusPostBreakProcessFunction[];
  section?: Record<string, StatusStepSection>;
  sectionResolver?: SectionResolverFunction | AsyncSectionResolverFunction;
  navigation?: Record<string, StatusStepNavigationData | null | undefined>;
  banner?: StatusStepBanner;
  secondaryBanner?: StatusStepBanner;
  skip?: StatusSkipFunction[];
  timeout?: number;
  redirect?: StatusStepRedirect;
  watcher?: StatusWatcher;
  chatBubble?: boolean;
};
export type StatusStepWithIndex = StatusStep & {
  index: number;
};
type ProgressSubtitleKeys = {
  fewSecondsLeft?: string;
  lessThanOneMin?: string;
  moreThanOneMin?: string;
};
export type EnhancedLoadingScreenConfig = {
  titleTranslationKey: string;
  progressSubtitleTranslationKeys?: ProgressSubtitleKeys;
  renderAfterLoadingFinished?: (arg0: { onClose: () => void }) => React.ReactNode;
};
export type EnhancedLoadingConfig = EnhancedLoadingScreenConfig & {
  enabled: boolean;
};
export type StatusConfig = {
  steps: StatusStep[];
  enhancedLoadingScreen?: EnhancedLoadingConfig;
  title?: Title;
  subtitle?: Title;
};
export type FetchLog = {
  msg: string;
  timestamp: number;
};
export type State = {
  config: StatusConfig | null | undefined;
  fetching: boolean;
  fetchingStack: FetchLog[];
  dirty: boolean;
  stepName: Status | null | undefined;
  stepIndex: number;
  error: string | null | undefined;
  sections: StatusStepSectionWithIndex[] | null | undefined;
  navigation: StatusStepNavigation | null | undefined;
  banner: StatusStepBanner | null | undefined;
  redirect: StatusStepRedirect | null | undefined;
  watcher: StatusWatcher | null | undefined;
  chatBubble: boolean;
  stepPerformanceTrace: Trace | null | undefined;
  overallPerformanceTrace: Trace | null | undefined;
  secondaryBanner: StatusStepBanner | null | undefined;
};
type Init = {
  type: typeof INIT_STATUS;
  payload: State;
};
type RequestRefresh = {
  type: typeof REQUEST_REFRESH_STATUS;
};
type FetchRequest = {
  type: typeof FETCH_STATUS;
  payload: {
    overallPerformanceTrace: Trace;
  };
};
type UpdateStatus = {
  type: typeof UPDATE_STATUS;
  payload: {
    stepName: Status;
    stepIndex: number;
    sections: StatusStepSectionWithIndex[];
    navigation: StatusStepNavigation;
    banner: StatusStepBanner | null | undefined;
    chatBubble: boolean;
    secondaryBanner: StatusStepBanner | null | undefined;
  };
};
type FetchSuccess = {
  type: typeof FETCH_STATUS_SUCCESS;
};
type FetchFailure = {
  type: typeof FETCH_STATUS_FAILURE;
  payload: {
    error: string;
  };
};
type SetEnhancedLoadingConfig = {
  type: typeof SET_ENHANCED_LOADING_CONFIG;
  payload: EnhancedLoadingConfig | null | undefined;
};
export type Action =
  | RequestRefresh
  | Init
  | UpdateStatus
  | FetchRequest
  | FetchSuccess
  | FetchFailure
  | SetEnhancedLoadingConfig;
export type StatusMiddleware = (
  dispatch: Dispatch<any>,
  getState: () => any,
  config: StatusStep,
  breakAction: (
    customNavigation?: StatusStepNavigation | null | undefined,
    customSection?: StatusStepSection | null | undefined,
  ) => any,
  failureAction: (error: string | Error, shouldLog?: boolean) => (d: any, gs: () => any) => void,
  next: () => any,
) => any;
export const initial: State = {
  config: null,
  fetching: false,
  fetchingStack: [],
  stepName: null,
  stepIndex: -1,
  error: null,
  sections: null,
  navigation: null,
  banner: null,
  redirect: null,
  dirty: true,
  watcher: null,
  // @ts-ignore
  timeout: null,
  chatBubble: false,
  stepPerformanceTrace: null,
  overallPerformanceTrace: null,
  secondaryBanner: null,
};

const configSectionReducer: (arg0: StatusStep[]) => StatusStepWithIndex[] = (
  steps: StatusStep[],
) => {
  return steps.reduce((ret, step, index) => {
    // @ts-ignore
    if (step.section) return ret.concat({ ...step, index });
    return ret;
  }, []);
};

// We need to return a deep copy of the config because when we resolve status,
// we use middlewares.shift() to iterate through the array of middlewares
const getMutableSteps = (steps: StatusStep[]) =>
  steps.map((step) => ({ ...step, middlewares: [...step.middlewares] }));

export type RootState = {
  status: State;
};

const getConfig = (stores: RootState): StatusConfig | null | undefined => stores.status.config;

const getStatusSteps: (stores: RootState) => StatusStep[] = createSelector(
  getConfig,
  (config) => config?.steps || [],
);

const getConfigSections = (stores: RootState) => configSectionReducer(getStatusSteps(stores));

const getStatusStepConfigById = (id: string) =>
  createSelector(getStatusSteps, (steps) => steps.find((step) => step.id === id) || null);

const getSections = (stores: RootState): StatusStepSectionWithIndex[] | null | undefined =>
  stores.status.sections;

const getStepIndex = (stores: RootState): number => stores.status.stepIndex;

const getCurrentStep = createSelector(
  getStatusSteps,
  getStepIndex,
  (steps, stepIndex) => steps[stepIndex] || null,
);
const getCurrentStepSection: (stores: RootState) => StatusStepSection | null | undefined =
  createSelector(getSections, getStepIndex, (sections, stepIndex) =>
    sections ? sections.find((section) => section.index === stepIndex) : null,
  );

const getBanner = (stores: RootState): StatusStepBanner | null | undefined => stores.status.banner;

const getSecondaryBanner = (stores: RootState): StatusStepBanner | null | undefined =>
  stores.status.secondaryBanner;

const getRedirect = (stores: RootState): StatusStepRedirect | null | undefined =>
  stores.status.redirect;

const getError = (stores: RootState): string | null | undefined => stores.status.error;

const CANCEL_ERROR = 'cancel';

const getErrorIfNotCanceled = (stores: RootState) => {
  const error = getError(stores);
  if (error && error === CANCEL_ERROR) return null;
  return error;
};

const getNavigation = (stores: RootState): StatusStepNavigation | null | undefined =>
  stores.status.navigation;

const shouldFetch = (stores: RootState): boolean => {
  const { fetching, dirty } = stores.status;
  return dirty && fetching === false;
};

const getWatchedValue = (stores: RootState) => {
  const { watcher } = stores.status;
  return watcher && watcher(stores);
};

const isFetching = (stores: RootState): boolean => stores.status.fetching;

const getFetchingStack = (stores: RootState): FetchLog[] => stores.status.fetchingStack;

const shouldShowChatBubble = (stores: RootState): boolean => stores.status.chatBubble;

const getLongestTimeout: (stores: RootState) => number = createSelector(getStatusSteps, (steps) =>
  Math.max(...steps.map(({ timeout = 0 }) => timeout), 0),
);

const getStepPerformanceTrace = (stores: RootState) => {
  return stores.status.stepPerformanceTrace;
};

const getOverallPerformanceTrace = (stores: RootState) => {
  return stores.status.overallPerformanceTrace;
};

const getLoadingScreenConfig: (stores: RootState) => EnhancedLoadingConfig | null | undefined =
  createSelector(getConfig, (config) => config?.enhancedLoadingScreen || null);
export const selectors = {
  shouldFetch,
  getSections,
  getStepIndex,
  getCurrentStep,
  getCurrentStepSection,
  getNavigation,
  getBanner,
  getSecondaryBanner,
  getRedirect,
  getError,
  getErrorIfNotCanceled,
  getStatusStepConfigById,
  isFetching,
  getFetchingStack,
  getWatchedValue,
  getConfig,
  shouldShowChatBubble,
  getLongestTimeout,
  getStatusSteps,
  getLoadingScreenConfig,
};

const stopOverallPerformanceTrace = (store: RootState, error?: string) => {
  const overallPerformanceTrace = getOverallPerformanceTrace(store);

  if (overallPerformanceTrace) {
    if (error) {
      overallPerformanceTrace.putAttribute('complete', 'no');
      overallPerformanceTrace.putAttribute('error', error);
    } else overallPerformanceTrace.putAttribute('complete', 'yes');

    overallPerformanceTrace.putMetric('stepIndex', getStepIndex(store));
    overallPerformanceTrace.stop();
  }
};

const fetchStatusSuccess = () => (dispatch: any, getState: () => any) => {
  stopOverallPerformanceTrace(getState());
  dispatch({
    type: FETCH_STATUS_SUCCESS,
  });
};

const fetchStatusFailure = (error: string) => (dispatch: any, getState: () => any) => {
  stopOverallPerformanceTrace(getState(), error);
  dispatch({
    type: FETCH_STATUS_FAILURE,
    payload: {
      error,
    },
  });
};

const cancelStatusResolution = () => (dispatch: any) => {
  dispatch(fetchStatusFailure(CANCEL_ERROR));
};

type Timeout =
  | {
      id: any;
      timestamp: number;
    }
  | null
  | undefined;
let timeout: Timeout = null;
export const clearTimeoutInterval = () => {
  if (timeout && timeout.id) {
    clearInterval(timeout.id);
    timeout = null;
  }
};

const createTimeoutInterval = (
  stepTimeout: number,
  stepId: Status,
  middlewareName: string,
  dispatch: Dispatch<any>,
) => {
  clearTimeoutInterval();
  const timeoutIntervalId = setInterval(() => {
    if (timeout && new Date().getTime() < timeout.timestamp + stepTimeout) {
      return;
    }

    clearTimeoutInterval();
    dispatch(fetchStatusFailure(`Timeout reached. Step: ${stepId} middleware: ${middlewareName}`));
  }, TIMEOUT_INTERVAL);
  timeout = {
    id: timeoutIntervalId,
    timestamp: new Date().getTime(),
  };
};

const initStatus = (config: StatusConfig) => async (dispatch: any) => {
  // compute sections
  const computedSections = configSectionReducer(config.steps).map((step) => {
    const { section, index } = step;
    if (!section) return null;
    return {
      index,
      ...section.todo,
    };
  });
  dispatch({
    type: INIT_STATUS,
    payload: { ...initial, config, sections: computedSections },
  });
};

const requestRefreshStatus = () => ({
  type: REQUEST_REFRESH_STATUS,
});

const fetchStatus = async (dispatch: any, getState: () => any) => {
  if (isFetching(getState())) return;
  clearTimeoutInterval();
  const overallPerformanceTrace = await startPerformanceTraceWithDefaultAttributes(getState())(
    PERFORMANCE_LABEL,
  );
  dispatch({
    type: FETCH_STATUS,
    payload: {
      overallPerformanceTrace,
    },
  });
  const configSteps = getStatusSteps(getState());
  // eslint-disable-next-line no-use-before-define
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  statusResolver(dispatch, getState, getMutableSteps(configSteps));
};

const middlewareBreakAction =
  (stepName: Status, stepIndex: number) =>
  (
    customNavigation?: StatusStepNavigation | null | undefined,
    customSection?: StatusStepSection | null | undefined,
  ) =>
  async (dispatch: any, getState: () => any) => {
    const steps = getStatusSteps(getState());
    // compute sections
    const sectionSteps = getConfigSections(getState());
    const computedSections = sectionSteps.map(async (step) => {
      const { section, sectionResolver, index } = step as any;

      if (sectionResolver) {
        const resolvedSection = await sectionResolver(
          getState,
          section,
          index,
          stepIndex,
          customSection,
        );
        if (resolvedSection) return resolvedSection;
      }

      if (!section) return null;
      if (index < stepIndex)
        return {
          index,
          ...section.complete,
        };
      if (index > stepIndex)
        return {
          index,
          ...section.todo,
        };
      return customSection
        ? {
            index,
            ...customSection,
          }
        : {
            index,
            ...section.new,
          };
    });
    // compute navigation
    const defaultStepNavigation = steps[stepIndex].navigation
      ? // @ts-ignore
        steps[stepIndex].navigation?.default
      : null;
    const computedNavigation =
      customNavigation === undefined ? defaultStepNavigation : customNavigation;
    // compute redirect
    const { redirect } = steps[stepIndex];
    const computedRedirect = redirect ? redirect(getState(), dispatch) : null;
    // compute banner
    const computedBanner = steps[stepIndex].banner;
    // compute secondary banner
    const computedSecondaryBanner = steps[stepIndex].secondaryBanner;
    // compute chat bubble
    const computedChatBubble = !!steps[stepIndex].chatBubble;
    // compute watcher
    const computedWatcher = steps[stepIndex].watcher;
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        stepName,
        stepIndex,
        sections: await Promise.all(computedSections),
        navigation: computedNavigation,
        banner: computedBanner,
        secondaryBanner: computedSecondaryBanner,
        chatBubble: computedChatBubble,
        redirect: computedRedirect,
        watcher: computedWatcher,
      },
    });
  };

const postProcessing = () => async (dispatch: any, getState: () => any) => {
  const config = getCurrentStep(getState());

  if (!config.postBreakProcessing || !config.postBreakProcessing.length) {
    return;
  }

  for (let i = 0; i < config.postBreakProcessing.length; i += 1) {
    const postProcessingFn = config.postBreakProcessing[i];
    // eslint-disable-next-line no-await-in-loop
    await postProcessingFn(dispatch, getState);
  }
};

const fetchStatusLog = (msg: string) => async (dispatch: any, getState: () => any) => {
  const prevStepPerformanceTrace = getStepPerformanceTrace(getState());
  const index =
    prevStepPerformanceTrace && prevStepPerformanceTrace.getAttribute('step') === msg
      ? prevStepPerformanceTrace.getMetric('index') + 1
      : 0;
  if (prevStepPerformanceTrace) prevStepPerformanceTrace.stop();
  const identifier = `${PERFORMANCE_LABEL}::${msg}::${index}`;
  const stepPerformanceTrace = await startPerformanceTraceWithDefaultAttributes(getState())(
    identifier,
    {
      step: msg,
    },
    {
      index,
    },
  );
  dispatch({
    type: FETCH_STATUS_LOG,
    payload: {
      fetchingInfo: {
        msg,
        timestamp: new Date().getTime(),
      },
      stepPerformanceTrace,
    },
  });
};

const shouldSkip = async (step: StatusStep, getState: () => any): Promise<boolean> => {
  const { skip } = step;

  if (skip && _.isArray(skip)) {
    const values = await Promise.all(skip.map((skipFunction) => skipFunction(getState)));
    return values.some(_.identity);
  }

  return false;
};

const statusResolver = async (
  dispatchParent: Dispatch<any>,
  getStateParent: () => any,
  steps: StatusStep[],
  stepIndex = 0,
): Promise<any> => {
  const [step, ...next] = steps;

  if (step) {
    const { middlewares } = step;

    try {
      if (await shouldSkip(step, getStateParent)) {
        return statusResolver(dispatchParent, getStateParent, next, stepIndex + 1);
      }
    } catch (error) {
      logger.error(error as Error);
      dispatchParent(fetchStatusFailure(`Failed to run skip: ${(error as Error).message}`));
      return undefined;
    }

    await dispatchParent(fetchStatusLog(step.id));
    const middleware: any = middlewares.shift();
    const nextStepIndex = middlewares.length === 0 ? stepIndex + 1 : stepIndex;
    const nextSteps = middlewares.length === 0 ? [...next] : [{ ...step, middlewares }, ...next];

    if (step.timeout != null) {
      createTimeoutInterval(step.timeout, step.id, middleware.name, dispatchParent);
    }

    return middleware(
      dispatchParent,
      getStateParent,
      step,
      (
          customNavigation?: Record<string, any> | null | undefined,
          customSection?: Record<string, any> | null | undefined,
        ) =>
        async (dispatch: Dispatch<any>) => {
          clearTimeoutInterval();

          if (getError(getStateParent())) {
            return;
          }

          await dispatch(
            middlewareBreakAction(step.id, stepIndex)(
              customNavigation as any,
              customSection as any,
            ),
          );

          try {
            await dispatch(postProcessing());
            dispatch(fetchStatusSuccess());
          } catch (error) {
            logger.error(error as Error, {
              stepId: step.id,
            });
            dispatch(fetchStatusFailure('Failed to run postProcessing'));
          }
        },
      (error: string | Error, shouldLog = true) => {
        clearTimeoutInterval();
        const errorObject: Error = typeof error === 'string' ? new Error(error) : error;

        if (shouldLog) {
          logger.error(errorObject, {
            stepId: step.id,
          });
        }

        return fetchStatusFailure(errorObject.message);
      },
      () => {
        clearTimeoutInterval();

        if (getError(getStateParent())) {
          return;
        }
        statusResolver(dispatchParent, getStateParent, nextSteps, nextStepIndex);
      },
    );
  }

  return undefined;
};

const setEnhancedLoadingConfig = (
  payload: EnhancedLoadingConfig | null | undefined,
): SetEnhancedLoadingConfig => ({
  type: SET_ENHANCED_LOADING_CONFIG,
  payload,
});

export const actions = {
  initStatus,
  requestRefreshStatus,
  fetchStatus,
  middlewareBreakAction,
  fetchStatusFailure,
  fetchStatusLog,
  cancelStatusResolution,
  setEnhancedLoadingConfig,
};
export const forTests = {
  statusResolver,
  StatusStates,
  CANCEL_ERROR,
};
export const reducer = (state: State = initial, action: any): State => {
  switch (action.type) {
    case INIT_STATUS:
      return { ...state, ...action.payload, dirty: true };

    case REQUEST_REFRESH_STATUS:
      return { ...state, dirty: true };

    case FETCH_STATUS:
      return {
        ...state,
        redirect: null,
        error: null,
        fetching: true,
        overallPerformanceTrace: action.payload.overallPerformanceTrace,
      };

    case UPDATE_STATUS:
      return { ...state, ...action.payload };

    case FETCH_STATUS_SUCCESS:
      return {
        ...state,
        fetchingStack: [],
        fetching: false,
        dirty: false,
        error: null,
        overallPerformanceTrace: null,
        stepPerformanceTrace: null,
      };

    case FETCH_STATUS_FAILURE:
      return {
        ...state,
        ...action.payload,
        stepIndex: -1,
        fetchingStack: [],
        fetching: false,
        dirty: false,
        overallPerformanceTrace: null,
        stepPerformanceTrace: null,
      };
    // @ts-ignore
    case FETCH_STATUS_LOG:
      return {
        ...state,
        fetchingStack: [...state.fetchingStack, action.payload.fetchingInfo],
        stepPerformanceTrace: action.payload.stepPerformanceTrace,
      };

    case SET_ENHANCED_LOADING_CONFIG:
      return {
        ...state,
        // @ts-ignore
        config: { ...state.config, enhancedLoadingScreen: { ...action.payload } },
      };

    default:
      return state;
  }
};
