import * as React from 'react';

import { IconButton, icons } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { WithNavigation, withNavigation } from '../hocs/with-navigation';

type Props = WithNavigation;

const AccountButton = ({ navigationActions }: Props) => {
  const { getTranslationText } = useItalyIntl();
  const handlePress = () => {
    navigationActions.toAccount('screen');
  };

  return (
    <IconButton
      path={icons.account}
      variant="brand"
      text={getTranslationText('web.header.account')}
      marginRight="14px"
      size={25}
      textVariant="bodyXSBook"
      onPress={handlePress}
    />
  );
};

export default withNavigation(AccountButton);
