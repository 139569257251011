import React, { FC } from 'react';

import { PdfContainer } from 'src/containers/pdf';

import { StyleProp } from './types';
import { ImageZoomableView } from './image-zoomable-view';

type URI = {
  uri: string;
};
type Props = {
  isPdf?: boolean;
  source: URI | any;
  style?: StyleProp;
  maxZoom?: number;
  minZoom?: number;
  zoomStep?: number;
  initialZoom?: number;
  onErrorPDF?: (error: Error) => void;
  scale?: number;
  singlePage?: boolean;
  fileName?: string;
};

const DocumentPreview: FC<Props> = ({ isPdf, onErrorPDF, source, fileName, style, ...rest }) => {
  if (isPdf) {
    return (
      <PdfContainer
        source={source}
        fileName={fileName}
        style={style}
        onError={onErrorPDF}
        {...rest}
      />
    );
  }

  return <ImageZoomableView source={source} style={style} {...rest} />;
};

export { DocumentPreview };
