import React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { DebugConfigViewer } from './debug-config-viewer';

export const FirebaseRemoteConfigRoute = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'Firebase Remote Config' });
  return <DebugConfigViewer />;
};

export const { action: toFirebaseRemoteConfig, screen: firebaseRemoteConfigScreen } = getRoute({
  routeName: Routes.FirebaseRemoteConfig,
  screenComponent: FirebaseRemoteConfigRoute,
});
