import { SharedAppCountries } from 'src/services/country-and-year/utils';

type CountryCodeValue = Record<string, string>;
type IdToTranslationKey = Record<SharedAppCountries, CountryCodeValue>;

export const idToTranslationKeyCountryOption: IdToTranslationKey = {
  IT: {
    country: 'country.italy',
    maintenance: 'maintenance.country.italy',
  },
  DE: {
    country: 'country.germany',
    maintenance: 'maintenance.country.germany',
  },
};

export const idToTranslationKeyDownloadApp: IdToTranslationKey = {
  IT: {
    title: 'download.title.italy',
    subTitle: 'download.sub-title.italy',
    description: 'download.description.italy',
  },
  DE: {
    title: 'download.title.germany',
    subTitle: 'download.sub-title.germany',
    description: 'download.description.germany',
  },
};
