import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { Container, Text, TextLink } from 'src/taxfix-components/src';
import { parseMarkdown } from 'src/services/markdown-parser';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import HtmlContent from 'src/components/html-content';

import { theme } from '../components/core';
import IconButton from '../components/core/Button/IconButton';
import Overlay from '../components/overlay';
import { isWeb } from '../utils/platform';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
    height: '100%',
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
  header: {
    backgroundColor: theme.color.lightFill,
    width: '100%',
    justifyContent: 'flex-start',
  },
});

type Props = {
  onClose: () => void;
  content: string;
  title: string;
  canDelete?: boolean;
};
const contentStyles = `
<style type="text/css">
* {
  font-size: 14px;
  }
#content {
  margin: 0px;
  padding-bottom: 30px;
  height:'100%';
}
ul {
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
}
ul ul {
  list-style-type: disc;
}
li{
  margin-bottom: 5px;
}
</style>
`;

export const PrefilledQuestionFlowInfoOverlay = ({
  onClose: onCloseComplete,
  content,
  title,
}: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const supportLinkText = getTranslationText('it.overlays.document.upload.guidance.footer.link');
  const handleOnPressSupport = () => {
    handleOpenChatPopup();
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isWeb ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isWeb ? 'right' : 'bottom'}
    >
      {({ onClose }: any) => (
        <>
          <ScrollView
            contentContainerStyle={{
              paddingLeft: 64,
              paddingRight: 44,
              padding: 30,
              minHeight: '90%',
            }}
          >
            <Container
              style={styles.header}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Container position="absolute" marginLeft={-30} top={0}>
                <IconButton iconKey="cross" onPress={onClose} tintColor={theme.color.primary} />
              </Container>
              <Text variant="titleMBold" value={getTranslationText(title)} />
            </Container>
            <View onStartShouldSetResponder={() => true}>
              <Container marginBottom={30} marginTop={30}>
                <HtmlContent
                  scrollEnabled={!isWeb}
                  customStyles={{ height: isWeb ? '100%' : 500 }}
                  contentStyles={contentStyles}
                  content={String(parseMarkdown(getTranslationText(content)))}
                />
              </Container>
            </View>
            <Container flexDirection="column" justifyContent="flex-end" flexGrow={1}>
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.overlays.document.upload.guidance.footer.title')}
              />
              <TextLink
                variant="titleXSMedium"
                color={'brand.brandGreen'}
                text={supportLinkText}
                onPress={handleOnPressSupport}
              />
            </Container>
          </ScrollView>
        </>
      )}
    </Overlay>
  );
};
