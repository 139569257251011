import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const IdentificationIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#E1F8E6" />
    <Rect x="12.0723" y="18.5" width="39.8571" height="27" rx="1.07527" fill="white" />
    <Rect x="14.6445" y="41.4287" width="34.5" height="1.92857" rx="0.430108" fill="#C8E7FC" />
    <Path
      d="M31.4475 28.7856L39.9288 28.8927"
      stroke="#6793FF"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M31.5083 32.0004L45.7146 32.1076"
      stroke="#6793FF"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M31.4475 35.4282L39.9288 35.5353"
      stroke="#6793FF"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M15.5326 21.7143L38.6443 21.8214"
      stroke="#1C3086"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M24.873 26.5654H26.6187C26.7509 26.5654 26.8778 26.618 26.9713 26.7115C27.0649 26.805 27.1174 26.9319 27.1174 27.0642V28.8098"
      stroke="#6793FF"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M24.873 37.5383H26.6187C26.7509 37.5383 26.8778 37.4858 26.9713 37.3922C27.0649 37.2987 27.1174 37.1718 27.1174 37.0396V35.2939"
      stroke="#6793FF"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3889 37.5383H16.6433C16.511 37.5383 16.3841 37.4858 16.2906 37.3922C16.1971 37.2987 16.1445 37.1718 16.1445 37.0396V35.2939"
      stroke="#6793FF"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3889 26.5654H16.6433C16.511 26.5654 16.3841 26.618 16.2906 26.7115C16.1971 26.805 16.1445 26.9319 16.1445 27.0642V28.8098"
      stroke="#6793FF"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M21.6298 33.0495C22.7316 33.0495 23.6247 32.1564 23.6247 31.0546C23.6247 29.9528 22.7316 29.0596 21.6298 29.0596C20.528 29.0596 19.6348 29.9528 19.6348 31.0546C19.6348 32.1564 20.528 33.0495 21.6298 33.0495Z"
      stroke="#C8E7FC"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3203 35.0437C18.6391 34.4422 19.1158 33.9391 19.699 33.5881C20.2822 33.2372 20.9501 33.0518 21.6307 33.0518C22.3114 33.0518 22.9792 33.2372 23.5625 33.5881C24.1457 33.9391 24.6224 34.4422 24.9412 35.0437"
      stroke="#C8E7FC"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Icon>
);
