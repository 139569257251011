import * as React from 'react';

import { Box } from '../components/core';

type Props = {
  left?: boolean;
  right?: boolean;
  children: React.ReactNode;
};

const HeaderButton = ({ left, right, children }: Props) => {
  const rightMargin = right ? 2 : 0;
  const leftMargin = left ? 2 : 0;
  return (
    <Box
      left={leftMargin}
      right={rightMargin}
      style={{
        height: 40,
      }}
      alignVertically="center"
      testId={left ? 'header-back' : ''}
    >
      {children}
    </Box>
  );
};

export default HeaderButton;
