import React from 'react';
import { Platform } from 'react-native';
import { IntlShape } from 'react-intl';

import { Box, Button, TitleText } from '../../components/core';
import { getBoundNavigationsActions } from '../../routes/config-util';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';

import { payslipTest } from './payslipBase64';

const RNFB = Platform.OS !== 'web' && require('react-native-blob-util').default;

const log = (...args: any[]) => {
  // eslint-disable-next-line
  if (!__DEV__) return;
  // eslint-disable-next-line
  console.log(...args);
};

export type ScanbotResult = {
  uri: string;
  id: string;
};

export type ScanbotUIProps = {
  numOfPages?: number;
  withAutoSnap?: boolean;
  imageFormat?: 'JPG' | 'PNG';
  onFinished: (results: ScanbotResult[]) => any;
  onCancel: () => void;
  intl: IntlShape;
};

export type ActionParams = {
  onFinished: (params: []) => void;
};

type Props = Navigation<
  Routes.ScanbotMock,
  { [Routes.ScanbotMock]: NavigationParams<ActionParams> }
>;

export const cleanup = async () => {
  log('Cleaning scanned pictures from Scanbot');
};

export const ScanbotContainer = (props: ScanbotUIProps) => {
  log('Starting Scanbot Document Scanner');
  const navigationActions = getBoundNavigationsActions();
  if (navigationActions.toScanbotMock) {
    navigationActions.toScanbotMock('modal', {
      onFinished: props.onFinished,
    });
  }
};

export const ScanbotMockScreen = ({ route, navigation }: Props) => {
  const { onFinished } = route.params;

  const handleOnFinished = async () => {
    if (!RNFB) return;
    const filePath = `${RNFB.fs.dirs.CacheDir}/payslipTest.jpg`;
    // We have to save the file in the simulator's internal storage
    // to be able to upload it to documents-api.
    await RNFB.fs.writeFile(filePath, payslipTest, 'base64');
    navigation.goBack();
    onFinished([
      {
        id: 'payslipTest',
        uri: `file://${filePath}`,
      },
    ] as any);
  };

  return (
    <Box flex>
      <Box top={2} bottom={2} alignHorizontally="center">
        <TitleText>Camera Mock</TitleText>
      </Box>
      <Box flex />
      <Box bottom={2} left={2} right={2}>
        <Button onPress={handleOnFinished} testId="test.button.takeSnapShot">
          Take Photo
        </Button>
      </Box>
    </Box>
  );
};

export const { action: toScanbotMock, screen: scanbotMockScreen } = getRoute<ActionParams>({
  routeName: Routes.ScanbotMock,
  screenComponent: ScanbotMockScreen,
});
