import { Platform } from 'react-native';
import { Dispatch } from 'redux';
import _ from 'lodash';
import { Prefill } from '@taxfix/italy-sdk';
import { Documents } from '@taxfix/types';
import Config from 'react-native-config';
import Device from 'react-native-device-info';

import { PrefillState, PrefillRejectedReason } from '../../types/prefills';

import { selectors as settingsSelectors, RootState as RootStateInBootstrap } from './settings';

export const UPDATE_PREFILL = 'prefill/UPDATE_PREFILL';
export const CREATE_PREFILL = 'prefill/CREATE_PREFLL';
export const CREATE_PREFILL_SUCCESS = 'prefill/CREATE_PREFLL_SUCCESS';
export const CREATE_PREFILL_ERROR = 'prefill/CREATE_PREFLL_ERROR';
export const FETCH_PREFILL = 'prefill/FETCH_PREFLL';
export const FETCH_PREFILL_SUCCESS = 'prefill/FETCH_PREFLL_SUCCESS';
export const FETCH_PREFILL_ERROR = 'prefill/FETCH_PREFLL_ERROR';
export const UPDATE_PREFILL_SWITCH = 'prefill/UPDATE_PREFILL_SWITCH';
export type PrefillPersonal = {
  firstName: string;
  lastName: string;
  taxId: string;
};
type RejectedReasonFromAPI = string | PrefillRejectedReason[];
export type State = PrefillPersonal & {
  id: number | null | undefined;
  state: PrefillState | null | undefined;
  rejectedReason: RejectedReasonFromAPI | null | undefined;
  createdAt: string;
  isLoading: boolean;
  error: Error | null | undefined;
  withPrefill: boolean;
};
type UpdateAction = {
  type: string;
  payload: State;
};
type CreateAction = {
  type: string;
};
type FetchAction = {
  type: string;
};
type UpdateSwitchAction = {
  type: typeof UPDATE_PREFILL_SWITCH;
  withPrefill: boolean;
};
type Action = UpdateAction | CreateAction | UpdateSwitchAction | any;
export const initial: State = {
  id: null,
  firstName: '',
  lastName: '',
  taxId: '',
  state: null,
  rejectedReason: [],
  createdAt: '',
  isLoading: false,
  error: null,
  withPrefill: false,
};
type MandateXMLContext = {
  title: string;
  lead: string;
  permissionText: string;
  date: string;
  year: number;
};
export type CreateRequest = MandateXMLContext & Documents.Italy.IntesaUserData;

const createPrefillRequest = () => ({
  type: CREATE_PREFILL,
});

const createPrefillError = (error: any): any => ({
  type: CREATE_PREFILL_ERROR,
  error,
});

const createPrefillSuccess = (prefill: any) => ({
  type: CREATE_PREFILL_SUCCESS,
  prefill,
});

const fetchPrefillRequest = (prefill?: any) => ({
  type: FETCH_PREFILL,
  prefill,
});

const fetchPrefillError = (error: any) => ({
  type: FETCH_PREFILL_ERROR,
  error,
});

const fetchPrefillSuccess = (prefill: any) => ({
  type: FETCH_PREFILL_SUCCESS,
  prefill,
});

const updatePrefillSwitch = (withPrefill: boolean) => ({
  type: UPDATE_PREFILL_SWITCH,
  withPrefill,
});

type CreatePrefillActionTypes = UpdateAction | CreateAction;

const createPrefill =
  (token: string, data: CreateRequest) =>
  async (dispatch: Dispatch<CreatePrefillActionTypes>, getState: () => RootStateInBootstrap) => {
    try {
      dispatch(createPrefillRequest());
      const response = await Prefill.create(Config.API_BASE_URL, token, {
        ...data,
        countryCode: settingsSelectors.selectedCountry(getState()),
        platform: Platform.OS as any,
        platformVersion: Device.getVersion(),
      });
      const prefill = {
        id: response?.id,
        firstName: data.firstName,
        lastName: data.lastName,
        taxId: data.taxId,
        createdAt: data.date,
        state: 'created',
        rejectedReason: null,
      };
      dispatch(createPrefillSuccess(prefill));
    } catch (error) {
      dispatch(createPrefillError(error));
      throw error;
    }
  };

type RetrieveLatestPrefillActionTypes = UpdateAction | FetchAction;

const retrieveLatestPrefill =
  (token: string, userId: number, year: number) =>
  async (
    dispatch: Dispatch<RetrieveLatestPrefillActionTypes>,
    getState: () => RootStateInBootstrap,
  ) => {
    try {
      dispatch(fetchPrefillRequest());
      const prefills: any = await Prefill.getAll(Config.API_BASE_URL, token, {
        userId,
        countryCode: settingsSelectors.selectedCountry(getState()),
        year,
        order: {
          createdAt: 'desc',
        },
        page: 0,
        pageSize: 1,
      });
      const prefill = !prefills.total ? initial : prefills.data[0];
      dispatch(fetchPrefillSuccess(prefill));
    } catch (error) {
      dispatch(fetchPrefillError(error));
    }
  };

export const actions = {
  createPrefill,
  retrieveLatestPrefill,
  updatePrefillSwitch,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case FETCH_PREFILL:
    case CREATE_PREFILL:
      return { ...state, isLoading: true, error: null };

    case FETCH_PREFILL_ERROR:
    case CREATE_PREFILL_ERROR:
      return { ...state, isLoading: false, error: action.error };

    case FETCH_PREFILL_SUCCESS:
    case CREATE_PREFILL_SUCCESS:
      return { ...state, isLoading: false, ...action.prefill };

    case UPDATE_PREFILL:
      return { ...state, ...action.payload };

    case UPDATE_PREFILL_SWITCH:
      return { ...state, withPrefill: action.withPrefill };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
// selectors
type RootState = {
  prefill: State;
};

const getStatus = (state: RootState): PrefillState | null | undefined => state.prefill.state;

const firstName = (state: RootState): string => state.prefill.firstName;

const lastName = (state: RootState): string => state.prefill.lastName;

const taxId = (state: RootState): string => state.prefill.taxId;

const getCreationDate = (state: RootState): string => state.prefill.createdAt;

const getId = (state: RootState): number | null | undefined => state.prefill.id;

const getRejectedReasons = (state: RootState): PrefillRejectedReason[] => {
  if (state.prefill.rejectedReason) {
    if (typeof state.prefill.rejectedReason === 'string') {
      return JSON.parse(state.prefill.rejectedReason);
    }

    return state.prefill.rejectedReason;
  }

  return [];
};

const getLoading = (state: RootState): boolean => state.prefill.isLoading;

const getError = (state: RootState): Error | null | undefined => state.prefill.error;

const isPrefillSwitchEnabled = (state: RootState): boolean => state.prefill.withPrefill;

export const selectors = {
  getStatus,
  firstName,
  lastName,
  taxId,
  getRejectedReasons,
  getCreationDate,
  getId,
  getLoading,
  getError,
  isPrefillSwitchEnabled,
};
