import { CountryCodes } from '@taxfix/types';

import { getStore } from '../../stores/util';
import { selectors as remoteConfigFirebaseSelectors } from '../../stores/modules/remote-config-firebase';

import { getDefaultTaxYear } from './utils';

const getTaxYearFromTaxSeason = (countryCode: CountryCodes): number | null | undefined => {
  const store = getStore();
  if (!store) throw new Error('redux store is not available');
  const nowTs = new Date().getTime();
  const taxSeasons = remoteConfigFirebaseSelectors.getTaxSeasonByCountryCodes(
    store.getState(),
    countryCode,
  );
  return Object.keys(taxSeasons || {})
    .map((taxYear) => parseInt(taxYear, 10))
    .sort((a, b) => a - b)
    .find((taxYear) => {
      const taxSeason = taxSeasons[taxYear.toString()];
      if (!taxSeason.enabled) return false;
      const startDateTs = new Date(taxSeason.startDateSoft).getTime();
      const endDateTs = new Date(taxSeason.endDateHard).getTime();
      return startDateTs <= nowTs && endDateTs > nowTs;
    });
};

const getTaxYearForIT = (): number => {
  return getTaxYearFromTaxSeason(CountryCodes.IT) || getDefaultTaxYear();
};

const getTaxYearForES = (): number => {
  return getTaxYearFromTaxSeason(CountryCodes.ES) || getDefaultTaxYear();
};

export const getCurrentTaxYearForCountry = (
  countryCode: CountryCodes,
): number | null | undefined => {
  switch (countryCode) {
    case CountryCodes.DE:
      return undefined;

    case CountryCodes.IT:
      return getTaxYearForIT();

    case CountryCodes.ES:
      return getTaxYearForES();

    default:
      throw new Error('unexpected country');
  }
};
