import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Prefill } from '@taxfix/italy-types';
import { Linking } from 'react-native';
import { EppoSdk } from '@taxfix/eppo';

import { Loading } from 'src/components/loading';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useProductBundleRecommendation } from 'src/_italy/product-bundle/use-product-bundle-recommendation';
import { isIOS } from 'src/utils/platform';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';

import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';
import {
  eppoExperiment,
  flagsQuestionIds,
  ProductBundleValues,
  RESULT_SCREEN_EXPERIMENT_VARIANTS,
} from '../../common/constants-it';
import { ScreenName } from '../../types/screen-name';
import { selectors as firebaseSelectors } from '../../stores/modules/remote-config-firebase';

import { useGetPrefillDocuments } from './useGetPrefillDocuments';
import { FORCED_ADVANCED_BUNDLE } from './constants';
import {
  getPrefillResult,
  isNegativeNoOptimization,
  isPrefillDocumentsResultListScenario,
} from './utils';
import { PrefillResultScreenComponent } from './prefill-documents-result-component';
import { useForceAdvance } from './use-force-advance';
import { PrefillResultScreenExperimentComponent } from './prefill-documents-result-component-experiment';

type OutterProps = {
  onNext?(): void;
  setBackgroundColor: (color: string) => void;
};

type Props = OutterProps & {
  quizmasterLight: QuizmasterLight;
};

const PrefillDocumentsResult: React.FC<Props> = ({
  quizmasterLight,
  onNext,
  setBackgroundColor,
}) => {
  const estimatedResultAsString: string =
    quizmasterLight[flagsQuestionIds.prefilledCreditResult].answer;
  const taxScenario: Prefill.PrefillResultScenarios =
    quizmasterLight[flagsQuestionIds.taxScenario].answer ??
    Prefill.PrefillResultScenarios.NAMandatory;
  const taxOptimizations: string | undefined =
    quizmasterLight[flagsQuestionIds.taxOptimizations]?.answer;
  const reasonWhyRefundIsZero: string | undefined =
    quizmasterLight[flagsQuestionIds.reasonWhyRefundIsZero]?.answer;

  const { getNavigationActions, safeResetNavigation, navigationActions } = useNavigation();
  const { loading, filteredPrefillDocuments, hasPrefillDocuments } = useGetPrefillDocuments();
  const isForcedAdvance = useForceAdvance(taxScenario);
  const recommendedProductBundle = useProductBundleRecommendation();
  const {
    screenContent,
    estimatedResult,
    optimizations,
    totalOptimization,
    screenContentExperiment,
  } = getPrefillResult(estimatedResultAsString, taxScenario, taxOptimizations);

  const userId = useSelector(userAuthSelectors.getUserId);

  const variant = useMemo(() => {
    const { experimentKey, variants, defaultValue, targetAttributes } = eppoExperiment.resultScreen;
    return EppoSdk.getAssignment(
      `${userId}`,
      experimentKey,
      variants,
      defaultValue,
      targetAttributes,
    );
  }, [userId]);

  const isVariantExperiment = variant === RESULT_SCREEN_EXPERIMENT_VARIANTS.Variant;

  const { basics } = useSelector(firebaseSelectors.getItalyTaxGuideArticles);
  const dispatch = useDispatch();

  const recommendedBundle = isForcedAdvance ? FORCED_ADVANCED_BUNDLE : recommendedProductBundle;

  const handleOnBackButtonPress = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const handleOnContinuePress = async () => {
    Analytics.log(AnalyticsEvent.resultScreenButtonClicked);
    if (onNext) {
      onNext();
      return;
    }

    if (!isForcedAdvance) {
      navigationActions.toProductBundle('screen');
    } else {
      await quizmasterLight[flagsQuestionIds.productBundleSelection].saveAnswer(
        ProductBundleValues.guided,
      );
      navigationActions.toProductBundle('screen', {
        isSingleGuidedBundle: true,
      });
    }
  };

  const handlePressSecondaryButtonFooter = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.PrefillDocumentsResult,
      buttonName: 'HowResultIsCalculated',
    });
    if (isPrefillDocumentsResultListScenario(taxScenario)) {
      navigationActions.toPrefillDocumentsResultList('screen', {
        prefillDocumentsList: filteredPrefillDocuments,
        resultScreenExperiment: isVariantExperiment,
      });
    } else {
      navigationActions.toResultOptimizationScreen('screen', {
        optimizationVersion: screenContent.resultOptimizationScreenVersion,
        optimizationResult: {
          total: totalOptimization,
          optimizations: optimizations,
        },
        primaryButtonAction: handleOnContinuePress,
        filteredPrefillDocuments,
        resultScreenExperiment: isVariantExperiment,
      });
    }
  };

  const handleOnLinkPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.PrefillDocumentsResult,
      buttonName: 'PrefillDocumentList',
    });
    navigationActions.toPrefillDocumentsResultList('screen', {
      prefillDocumentsList: filteredPrefillDocuments,
    });
  };

  const handleOnTaxExpertCardLinkPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SectionDeclarationResult,
      buttonName: 'ViewDetails',
    });
    if (isNegativeNoOptimization(taxScenario)) {
      navigationActions.toPrefillDocumentsResultList('screen', {
        prefillDocumentsList: filteredPrefillDocuments,
      });
    } else {
      navigationActions.toResultOptimizationScreen('screen', {
        optimizationVersion: screenContent.resultOptimizationScreenVersion,
        optimizationResult: {
          total: totalOptimization,
          optimizations: optimizations,
        },
        primaryButtonAction: handleOnContinuePress,
        filteredPrefillDocuments,
      });
    }
  };

  const onIdlePopupButtonPress = useCallback(() => {
    const url = basics.getStarted;

    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'resultScreenIdleHelpPopup',
      buttonName: 'Watch The Tutorial',
    });

    if (isIOS) {
      Linking.openURL(url);
    } else {
      dispatch(
        overlayActions.show('TaxGuideOverlay', {
          url,
          onClose: () => {
            Analytics.log(AnalyticsEvent.buttonPressed, {
              screenName: 'resultScreenIdleHelpTutorial',
              buttonName: 'Close',
            });
            dispatch(overlayActions.hide());
          },
          openLinkExternally: () => Linking.openURL(url),
        }),
      );
    }
  }, [dispatch, basics]);

  const onIdlePopupShown = () => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'resultScreenIdleHelp',
    });
  };

  const onIdlePopupClose = () => {
    dispatch(overlayActions.hide());
  };

  if (loading) return <Loading />;

  return isVariantExperiment ? (
    <PrefillResultScreenExperimentComponent
      taxScenario={taxScenario}
      recommendedBundle={recommendedBundle}
      estimatedResult={estimatedResult}
      totalOptimization={totalOptimization}
      reasonWhyRefundIsZero={reasonWhyRefundIsZero}
      hasPrefillDocuments={hasPrefillDocuments}
      prefillDocumentList={filteredPrefillDocuments}
      screenContent={screenContentExperiment}
      setBackgroundColor={setBackgroundColor}
      handleOnContinuePress={handleOnContinuePress}
      handleOnBackButtonPress={handleOnBackButtonPress}
      onIdlePopupButtonPress={onIdlePopupButtonPress}
      onIdlePopupShown={onIdlePopupShown}
      onIdlePopupClose={onIdlePopupClose}
      onSecondaryButtonFooterPress={handlePressSecondaryButtonFooter}
    />
  ) : (
    <PrefillResultScreenComponent
      taxScenario={taxScenario}
      recommendedBundle={recommendedBundle}
      estimatedResult={estimatedResult}
      totalOptimization={totalOptimization}
      reasonWhyRefundIsZero={reasonWhyRefundIsZero}
      hasPrefillDocuments={hasPrefillDocuments}
      prefillDocumentList={filteredPrefillDocuments}
      screenContent={screenContent}
      setBackgroundColor={setBackgroundColor}
      handleOnLinkPress={handleOnLinkPress}
      handleOnContinuePress={handleOnContinuePress}
      handleOnBackButtonPress={handleOnBackButtonPress}
      handleOnTaxExpertCardLinkPress={handleOnTaxExpertCardLinkPress}
      onIdlePopupButtonPress={onIdlePopupButtonPress}
      onIdlePopupShown={onIdlePopupShown}
      onIdlePopupClose={onIdlePopupClose}
    />
  );
};

export const PrefillDocumentsResultContainer = compose<React.ComponentType<OutterProps>>(
  withQuizmasterLight([
    flagsQuestionIds.prefilledCreditResult,
    flagsQuestionIds.reasonWhyRefundIsZero,
    flagsQuestionIds.productBundleSelection,
    flagsQuestionIds.taxScenario,
    flagsQuestionIds.taxOptimizations,
  ]),
)(PrefillDocumentsResult);
