import { Documents } from '@taxfix/types';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { FileRejection } from 'react-dropzone';
import { injectIntl, IntlShape } from 'react-intl';
import { Linking } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import { Asset } from 'react-native-image-picker';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

import { withToastBannerToggler, WithToastBannerTogglerProps } from 'src/components/toast-banner';
import LinkableText from 'src/components/auth/LinkableText';
import { logger, ErrorType } from 'src/taxfix-business-logic/utils/logger';
import { State as RootState } from 'src/stores/store/initial';
import { getDefaultTaxYear } from 'src/services/country-and-year';

import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import {
  DocumentGuidanceCategoryName,
  flagsQuestionIds,
  mandatoryDocuments,
  translationInfoForDocuments,
  inSeasonQFIds,
  IncomeAnswersKeys,
  incomeSilentQuestionIds,
  ExpenseAnswerKey,
  expenseListQuestionIds,
  expenseSilentQuestionIds,
  expenseListQuestions,
} from '../../../common/constants-it';
import { ButtonType } from '../../../components/alert-overlay';
import { BodyPrimaryText, BodySecondaryText, theme } from '../../../components/core';
import { Banner } from '../../../components/toast';
import { FileUploadJob } from '../../../containers/multiple-upload-progress-overlay';
import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import {
  getBannerOptionsForFileBrowserErrors,
  OnPhotoTakenSuccess,
  openCamera,
  openGallery,
} from '../../../services/camera-upload-documents';
import { FilePicked, pickFiles } from '../../../services/document-picker';
import { DocumentAllowedFileTypes } from '../../../services/documents';
import { actions as OverlayActions } from '../../../stores/modules/overlay';
import {
  actions as RequiredDocumentsActions,
  DocumentBunches,
  selectors as requiredDocumentsSelectors,
} from '../../../stores/modules/required-documents';
import { selectors as settingsSelectors } from '../../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../../stores/modules/user-auth';
import { selectors as submissionSelectors } from '../../../stores/modules/submission';
import { Sleep } from '../../../utils/async';
import {
  MAX_FILES_BATCH,
  MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION,
  MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
} from '../../../utils/constants';
import { readFileAsyncBase64 } from '../../../utils/fetch';
import { QuizmasterLight, withQuizmasterLight } from '../../../utils/with-quizmaster-light';
import { RequiredDocumentsUpload as RequiredDocumentsUploadComponent } from '../../components/required-documents/required-documents-upload';

const TrackingUploadType = Object.freeze({
  pdf: 'pdf',
  gallery: 'gallery',
  camera: 'camera',
});

export const TrackingDocTypesExtra = {
  receipt: 'receipt',
};

export const getNumberOfUploadedDocuments = (
  docBunches: DocumentBunches,
  requiredDocumentTypes: string[],
): number => {
  return requiredDocumentTypes.reduce(
    (total, docType) => total + (docBunches[docType] ? docBunches[docType].items.length : 0),
    0,
  );
};

export function modifyQuestions(params: {
  mode: 'ADD' | 'DELETE';
  documentType: string;
  quizmasterLight: QuizmasterLight;
  onComplete?: () => void;
}): void {
  const { mode, documentType, quizmasterLight, onComplete } = params;

  const documentConfig = translationInfoForDocuments[documentType];

  if (documentConfig && documentConfig.questionConfig && documentConfig.questionConfig.length > 0) {
    documentConfig.questionConfig.forEach(({ questionType, answerKey }) => {
      const { answer: currentAnswer, saveAnswer } = quizmasterLight[questionType];
      let newAnswer: Array<string> | boolean | string | null = null;
      if (mode === 'ADD') {
        if (typeof answerKey === 'undefined') {
          newAnswer = true;
        } else if (
          questionType === inSeasonQFIds.living_situation &&
          currentAnswer.includes(IncomeAnswersKeys.other)
        ) {
          newAnswer = [answerKey];
        } else if (answerKey === ExpenseAnswerKey.delete) {
          return;
        } else {
          const answers = Array.isArray(currentAnswer) ? currentAnswer : [currentAnswer];
          const uniqueAnswers = new Set(answers).add(answerKey);
          newAnswer = [...uniqueAnswers];
        }
      }

      if (mode === 'DELETE') {
        if (answerKey === ExpenseAnswerKey.delete) {
          newAnswer = ExpenseAnswerKey.delete;
        } else if (typeof answerKey === 'undefined') {
          newAnswer = false;
        } else if (questionType === inSeasonQFIds.living_situation && currentAnswer.length === 1) {
          newAnswer = [IncomeAnswersKeys.other];
        } else {
          const answers = Array.isArray(currentAnswer) ? currentAnswer : [currentAnswer];
          newAnswer = answers.filter((item) => item !== answerKey);
        }
      }

      saveAnswer(newAnswer);

      Analytics.log(AnalyticsEvent.documentFolderUpdate, {
        action: mode,
        documentType,
      });
    });

    onComplete?.();
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const year = settingsSelectors.selectedYear(state) || getDefaultTaxYear();
  const docBunches = requiredDocumentsSelectors.getDocumentBunches(state);
  const numberOfUploadedDocuments = getNumberOfUploadedDocuments(
    docBunches,
    props.requiredDocumentTypes,
  );
  return {
    docBunches,
    hasUploadedDocuments: numberOfUploadedDocuments !== 0,
    numberOfUploadedDocuments,
    accessToken: userAuthSelectors.getAccessToken(state),
    userId: userAuthSelectors.getUserId(state),
    taxYear: year,
    taxSeason: year + 1,
    CUYear: year + 1,
    isResubmission: submissionSelectors.isResubmissionByYear(state, year),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateRequiredDocuments: bindActionCreators(
    RequiredDocumentsActions.updateRequiredDocuments,
    dispatch,
  ),
  uploadRequiredDocuments: bindActionCreators(
    RequiredDocumentsActions.uploadRequiredDocuments,
    dispatch,
  ),
  deleteRequiredDocument: bindActionCreators(
    RequiredDocumentsActions.deleteRequiredDocument,
    dispatch,
  ),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type DocumentsExpensesUploadedList = {
  date?: string;
  place?: string;
  amount: number;
};

type OwnProps = {
  intl: IntlShape;
  requiredDocumentTypes: Documents.NonReceiptTypes[];
  categoryKey: DocumentGuidanceCategoryName;
  handleMoreInfoPressed: () => void;
  customLinkHandler?: (url: string, params?: any) => void;
  quizmasterLight: QuizmasterLight;
  embedded?: boolean;
  onDocumentCountChanged?: (count: number) => void;
  onBannersChanged?: (banners: State) => void;
  titleText: string;
  expensesList?: DocumentsExpensesUploadedList[];
};

type Props = WithNavigation & WithToastBannerTogglerProps & PropsFromRedux & OwnProps;

export type State = {
  errorBanner: string | null | undefined;
  notificationBanner: string | null | undefined;
  expensesList?: DocumentsExpensesUploadedList[];
};

class RequiredDocumentsUpload extends React.PureComponent<Props, State> {
  state = {
    errorBanner: null,
    notificationBanner: null,
    expensesList: this.props.expensesList,
  };

  componentDidMount() {
    this.trackEvent(AnalyticsEvent.receiptCategoryStarted);
    const { accessToken, userId, updateRequiredDocuments, docBunches } = this.props;
    if (isEmpty(docBunches)) {
      updateRequiredDocuments(accessToken, userId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.numberOfUploadedDocuments === this.props.numberOfUploadedDocuments) return;
    this.updateDocumentCount();
  }

  updateDocumentCount = () => {
    const {
      numberOfUploadedDocuments,
      onDocumentCountChanged,
      requiredDocumentTypes,
      quizmasterLight,
    } = this.props;
    if (numberOfUploadedDocuments > 0) {
      modifyQuestions({
        mode: 'ADD',
        documentType: requiredDocumentTypes[0],
        quizmasterLight,
      });
    }
    if (onDocumentCountChanged) {
      onDocumentCountChanged(numberOfUploadedDocuments);
    }
  };

  trackEvent = (eventName: AnalyticsEvent, params: Record<string, unknown> = {}) => {
    Analytics.log(eventName, {
      ...params,
      photoType: null,
      documentType: TrackingDocTypesExtra.receipt,
      docSubType: this.props.requiredDocumentTypes[0],
    });
  };

  handleOpenCamera = () => {
    this.props.overlayActions.hide();
    openCamera(this.props.navigationActions, this.handleCameraResponse);
    this.trackEvent(AnalyticsEvent.addReceiptClicked);
    this.trackEvent(AnalyticsEvent.docViewFinder, {
      uploadType: TrackingUploadType.camera,
      pageNum: (this.props.numberOfUploadedDocuments as number) + 1,
    });
  };

  handleOpenGallery = () => {
    openGallery(this.props.intl, this.handleGalleryResponse, this.props.overlayActions.hide, true);
    this.trackEvent(AnalyticsEvent.addReceiptClicked);
    this.trackEvent(AnalyticsEvent.docViewFinder, {
      uploadType: TrackingUploadType.gallery,
      pageNum: (this.props.numberOfUploadedDocuments as number) + 1,
    });
  };

  handleShowBanner = ({ color: backgroundColor, ...bannerConfig }: any) => {
    this.props.showBanner({
      contentView: <Banner {...bannerConfig} />,
      backgroundColor,
    });
  };

  handleOpenDocumentsMob = async () => {
    try {
      const filesData = await pickFiles(
        // @ts-ignore
        [DocumentPicker.types.pdf, DocumentPicker.types.images],
        MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION,
      );
      this.props.overlayActions.hide();
      if (filesData.length === 0) return;

      this.props.overlayActions.show('MultipleUploadProgressOverlay', {
        jobs: this.createJobsForMob(filesData),
        uploadFiles: this.executeUploads,
        disableAndroidBackPress: true,
        uploadType: TrackingUploadType.camera,
      });
    } catch (err) {
      const bannerOptions = getBannerOptionsForFileBrowserErrors(err);
      this.handleShowBanner({ ...bannerOptions, color: theme.color.errorTextLight });
    }

    this.trackEvent(AnalyticsEvent.addReceiptClicked);
    this.trackEvent(AnalyticsEvent.docViewFinder, {
      uploadType: TrackingUploadType.pdf,
      pageNum: (this.props.numberOfUploadedDocuments as number) + 1,
    });
  };

  handleCameraResponse: OnPhotoTakenSuccess = (response) => {
    if (response.uri != null) {
      this.trackEvent(AnalyticsEvent.docPhotoTaken, {
        uploadType: TrackingUploadType.camera,
        pageNum: (this.props.numberOfUploadedDocuments as number) + 1,
      });
      this.props.overlayActions.show('UploadProgressOverlay', {
        file: response,
        uploadFile: this.uploadDocument(TrackingUploadType.camera),
        disableAndroidBackPress: true,
      });
    }
  };

  handleGalleryResponse: OnPhotoTakenSuccess = (response) => {
    if (response.length === 0) {
      return;
    }

    this.trackEvent(AnalyticsEvent.docPhotoTaken, {
      uploadType: TrackingUploadType.gallery,
      pageNum: (this.props.numberOfUploadedDocuments as number) + 1,
    });

    this.props.overlayActions.show('MultipleUploadProgressOverlay', {
      jobs: this.createJobsForGalleryResponse(response),
      uploadFiles: this.executeUploads,
      disableAndroidBackPress: true,
      uploadType: TrackingUploadType.gallery,
    });
  };

  uploadDocument: (uploadType: string) => OnPhotoTakenSuccess =
    (uploadType) => (response, onUploadProgress, onError) => {
      const {
        accessToken,
        userId,
        uploadRequiredDocuments,
        requiredDocumentTypes,
        numberOfUploadedDocuments,
        updateRequiredDocuments,
        quizmasterLight,
      } = this.props;
      const pageNum = numberOfUploadedDocuments + 1;
      const banners: State = {
        ...this.state,
        errorBanner: null,
      };
      this.setState(banners, async () => {
        try {
          this.trackEvent(AnalyticsEvent.docUploadInitiated, {
            uploadType,
            pageNum,
          });
          const pdf = response.isPdf;
          await uploadRequiredDocuments(
            accessToken,
            userId as number,
            requiredDocumentTypes[0],
            response.uri,
            onUploadProgress,
            pdf,
          );
          await updateRequiredDocuments(accessToken, userId);
          this.trackEvent(AnalyticsEvent.docUploadSuccess, {
            uploadType,
            pageNum,
          });
          quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(false);
          this.updateBanners(banners);
        } catch (error) {
          if (onError) onError(error);
          const errorBanners = {
            ...this.state,
            errorBanner: this.props.intl.formatMessage({
              id: 'account.error.failed.title',
            }),
          };
          this.setState(errorBanners);
          this.updateBanners(errorBanners);
          this.trackEvent(AnalyticsEvent.docUploadFailed, {
            uploadType,
            error: error.message,
            pageNum,
          });
        }
      });
    };

  createJobsForWeb = (files: any[]): FileUploadJob[] => {
    const { accessToken, userId, uploadRequiredDocuments, requiredDocumentTypes } = this.props;
    const jobs: FileUploadJob[] = files.map((file) => ({
      fileName: file.name,
      upload: async () => {
        let uri;

        try {
          uri = await readFileAsyncBase64(file);
        } catch (error) {
          const traits = {
            fileName: file.name,
            fileSize: file.size,
          };
          Analytics.log(AnalyticsEvent.fileReadingError, traits);
          logger.error(error as ErrorType, {
            message: 'Document read failed',
            ...traits,
          });
          throw error;
        }

        const isPdf = file.type === DocumentAllowedFileTypes.PDF;
        await uploadRequiredDocuments(
          accessToken,
          userId as number,
          requiredDocumentTypes[0],
          uri,
          undefined,
          isPdf,
          file.name,
        );
      },
    }));
    return jobs;
  };

  createJobsForGalleryResponse = (photos: Asset[]) => {
    const { accessToken, userId, uploadRequiredDocuments, requiredDocumentTypes } = this.props;
    const jobs = photos.map((photo) => ({
      fileName: photo.fileName,
      upload: async () => {
        if (photo.uri) {
          await uploadRequiredDocuments(
            accessToken,
            userId as number,
            requiredDocumentTypes[0],
            photo.uri,
          );
        }
      },
    }));
    return jobs;
  };

  createJobsForMob = (files: FilePicked[]): FileUploadJob[] => {
    const { accessToken, userId, uploadRequiredDocuments, requiredDocumentTypes } = this.props;
    const jobs: FileUploadJob[] = files.map((file: FilePicked) => ({
      fileName: file.name,
      upload: async () => {
        const isPdf = file.type === DocumentAllowedFileTypes.PDF;
        if (file.uri) {
          await uploadRequiredDocuments(
            accessToken,
            userId as number,
            requiredDocumentTypes[0],
            file.uri,
            undefined,
            isPdf,
            file.name,
          );
        }
      },
    }));

    return jobs;
  };

  updateBanners = (banners: State) => {
    const { onBannersChanged, embedded } = this.props;
    if (onBannersChanged && embedded) {
      onBannersChanged(banners);
    }
  };

  executeUploads = (
    jobs: FileUploadJob[],
    onUploadProgress: (arg0: number) => void,
    onFinish: () => void,
    uploadType: string,
  ) => {
    const {
      accessToken,
      userId,
      numberOfUploadedDocuments,
      updateRequiredDocuments,
      quizmasterLight,
    } = this.props;
    const failedFileNames: string[] = [];
    let pageNum = numberOfUploadedDocuments;
    this.setState(
      {
        errorBanner: null,
        notificationBanner: null,
      },
      async () => {
        for (let i = 0; i < jobs.length; i += 1) {
          pageNum += 1;
          this.trackEvent(AnalyticsEvent.docUploadInitiated, {
            uploadType,
            pageNum,
          });

          try {
            // We want to run upload jobs in sequence
            // eslint-disable-next-line no-await-in-loop
            await jobs[i].upload();
            onUploadProgress(i + 1);
            this.trackEvent(AnalyticsEvent.docUploadSuccess, {
              uploadType,
              pageNum,
            });
          } catch (error) {
            failedFileNames.push(jobs[i].fileName);
            this.trackEvent(AnalyticsEvent.docUploadFailed, {
              uploadType,
              error: error.message,
              pageNum,
            });
          }
        }

        // Reset documents upload consent
        quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(false);

        const uploadedFilesNumber = jobs.length - failedFileNames.length;
        const banners = {
          notificationBanner:
            uploadedFilesNumber > 0
              ? this.props.intl.formatMessage(
                  {
                    id:
                      uploadedFilesNumber > 1
                        ? 'multiple.upload.success.banner'
                        : 'single.upload.success.banner',
                  },
                  {
                    number: uploadedFilesNumber,
                  },
                )
              : null,
          errorBanner:
            failedFileNames.length > 0
              ? this.props.intl.formatMessage(
                  {
                    id:
                      uploadedFilesNumber > 1
                        ? 'multiple.upload.failure.banner'
                        : 'single.upload.failure.banner',
                  },
                  {
                    number: failedFileNames.length,
                    files: failedFileNames
                      .map((fileName) =>
                        fileName.length > 25
                          ? `${fileName.slice(0, 9)}...${fileName.slice(-10)}`
                          : fileName,
                      )
                      .join('; '),
                  },
                )
              : null,
        };
        this.setState(banners, () => {
          this.updateBanners(banners);
        });
        onFinish();

        try {
          await updateRequiredDocuments(accessToken, userId);
        } catch (error) {
          logger.error(error as ErrorType, {
            message: 'Documents update failed',
          });
        }
      },
    );
  };

  handleFilesDropWeb = (acceptedFiles: any[], fileRejections: FileRejection[]) => {
    const shouldWait = this.state.errorBanner !== null;
    const banners: State = {
      errorBanner: null,
      notificationBanner: null,
    };
    this.setState(banners, async () => {
      // Wait before showing banner again
      if (shouldWait) await Sleep(1000);

      // Discard whole batch in case of wrong format, size or quantity
      if (fileRejections.length > 0) {
        const b: State = {
          ...this.state,
          errorBanner: this.props.intl.formatMessage(
            {
              id: 'upload.reject-all.banner',
            },
            {
              quantity: MAX_FILES_BATCH,
              mb: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
            },
          ),
        };
        this.setState(b, () => {
          this.updateBanners(b);
        });
        const rejectionReasons: Record<string, any> = {};
        fileRejections.forEach(({ errors }) => {
          errors.forEach(({ code }) => {
            if (!rejectionReasons[code]) rejectionReasons[code] = true;
          });
        });
        Analytics.log(AnalyticsEvent.filesRejectionReasons, {
          codes: Object.keys(rejectionReasons),
        });
        return;
      }

      this.props.overlayActions.show('MultipleUploadProgressOverlay', {
        jobs: this.createJobsForWeb(acceptedFiles),
        uploadFiles: this.executeUploads,
        disableAndroidBackPress: true,
        uploadType: TrackingUploadType.camera,
      });

      this.updateBanners(banners);
    });
  };

  handleDeleteDocuments = (documentId?: number, shouldNavigateBack = false) => {
    const banners: State = {
      errorBanner: null,
      notificationBanner: null,
    };
    this.setState(banners, () => {
      this.updateBanners(banners);
    });
    const { numberOfUploadedDocuments } = this.props;
    let translationValues: any = {
      count: numberOfUploadedDocuments,
    };
    let titleTranslationKey = 'required-document.upload.delete-alert.title';
    let leadTranslationKey = 'required-document.upload.delete-alert.lead';

    if (documentId || numberOfUploadedDocuments === 1) {
      titleTranslationKey = 'required-document.upload.delete-alert-single.title';
      leadTranslationKey = 'required-document.upload.delete-alert-single.lead';
      translationValues = {};
    }

    this.props.overlayActions.show('AlertOverlay', {
      titleTranslationKey,
      leadTranslationKey,
      translationValues,
      buttons: [
        {
          translationKey: 'required-document.upload.delete-alert.button.delete',
          onPress: () => {
            this.deleteDocuments(documentId);

            if (shouldNavigateBack) {
              this.props.navigationActions.back();
            }

            this.trackEvent(AnalyticsEvent.deleteReceiptConfirmed);
          },
          type: ButtonType.warning,
          style: {
            backgroundColor: theme.color.pinkFull,
            color: theme.color.lightText,
          },
        },
        {
          translationKey: 'answers.modal.cancel',
          type: ButtonType.secondary,
          onPress: () => this.trackEvent(AnalyticsEvent.deleteReceiptCancelled),
          style: {
            backgroundColor: theme.color.lightText,
            color: theme.color.pinkFull,
          },
        },
      ],
    });
    this.trackEvent(AnalyticsEvent.deleteReceiptClicked);
  };

  deleteDocuments = async (documentId?: number) => {
    const { accessToken, deleteRequiredDocument, quizmasterLight } = this.props;

    try {
      if (documentId) {
        await deleteRequiredDocument(accessToken, documentId);
      }
      quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(false);
    } catch (e) {
      const banners = {
        ...this.state,
        notificationBanner: this.props.intl.formatMessage({
          id: 'account.error.failed.title',
        }),
      };
      this.setState(banners, () => {
        this.updateBanners(banners);
      });
    }
  };

  onLinkPressed = (url: string) => {
    const { customLinkHandler, requiredDocumentTypes } = this.props;
    let params;
    const documentType = requiredDocumentTypes[0];
    if (
      url == 'DocumentUploadGuidance' &&
      translationInfoForDocuments[documentType] &&
      translationInfoForDocuments[documentType].sections
    ) {
      params = {
        sections: translationInfoForDocuments[documentType].sections,
        overlayTitleKey: translationInfoForDocuments[documentType].headerTitleKey,
        canDelete:
          !mandatoryDocuments.includes(documentType) &&
          Boolean(
            translationInfoForDocuments[documentType] &&
              translationInfoForDocuments[documentType].questionConfig,
          ),
      };
    }

    if (customLinkHandler) {
      customLinkHandler(url, params);
    } else {
      Linking.openURL(url);
    }
  };

  getDocBunch = () => {
    const { requiredDocumentTypes, docBunches } = this.props;
    const docBunch = docBunches[requiredDocumentTypes[0]];
    return {
      ...docBunch,
      items: requiredDocumentTypes.reduce((acc: any, docType) => {
        if (isEmpty(docBunches) || !docType) {
          return acc;
        }
        return [...acc, ...docBunches[docType].items];
      }, []),
    };
  };

  onDeleteExpenseItem = (categoryKey: string, item: DocumentsExpensesUploadedList) => {
    const deleteItem = () => {
      const categoryListId = expenseListQuestions[categoryKey];
      const { answer: stringAnswer, saveAnswer } = this.props.quizmasterLight[categoryListId];
      const answer = JSON.parse(stringAnswer);

      const newExpenseList = answer.filter((obj: DocumentsExpensesUploadedList) => {
        return obj.date !== item.date || obj.place !== item.place || obj.amount !== item.amount;
      });
      this.setState({ expensesList: newExpenseList });
      saveAnswer(JSON.stringify(newExpenseList));
      this.props.overlayActions.hide();
    };

    this.props.overlayActions.show('ExpenseItemDeleteOverlay', {
      onPressDelete: deleteItem,
      onPressClose: () => this.props.overlayActions.hide(),
      translationKeys: {
        title: 'it.prefilled-expenses-list.delete-overlay.title',
        description: 'it.prefilled-expenses-list.delete-overlay.description',
        CTAButton: 'it.prefilled-expenses-list.delete-overlay.button.cta',
        secondaryButton: 'it.prefilled-expenses-list.delete-overlay.button.secondary',
      },
      categoryKey: this.props.categoryKey,
    });
  };

  render() {
    const {
      taxYear,
      CUYear,
      requiredDocumentTypes,
      docBunches,
      embedded,
      titleText,
      isResubmission,
    } = this.props;
    if (isEmpty(docBunches)) return null;
    const docBunch = this.getDocBunch();

    const { leadTranslationKey } = translationInfoForDocuments[requiredDocumentTypes[0]];
    const prefilledExpenseSubtitleKey =
      translationInfoForDocuments[requiredDocumentTypes[0]].prefilledExpenseSubtitleKey;
    return (
      <RequiredDocumentsUploadComponent
        embedded={embedded}
        errorBanner={this.state.errorBanner}
        notificationBanner={this.state.notificationBanner}
        onOpenCamera={this.handleOpenCamera}
        onOpenGallery={this.handleOpenGallery}
        onOpenFileBrowser={this.handleOpenDocumentsMob}
        onDrop={this.handleFilesDropWeb}
        titleText={titleText}
        prefilledExpenseSubtitleKey={prefilledExpenseSubtitleKey}
        translationValues={{
          count: docBunch.items.length,
          taxYear,
          CUYear,
        }}
        leadTranslationKey={leadTranslationKey}
        bottomButtons={{
          left: {
            disabled: !this.props.hasUploadedDocuments,
            onPress: this.handleDeleteDocuments,
            titleKey: 'required-document.upload.button.delete-all-entries',
          },
          right: {
            disabled: false,
            onPress: this.props.navigationActions.back,
            titleKey: 'required-document.upload.button.done',
          },
        }}
        docBunch={docBunch}
        onDeleteDocuments={this.handleDeleteDocuments} // eslint-disable-next-line react/jsx-handler-names
        onPreview={this.props.navigationActions.toRequiredDocumentsPreview}
        categoryKey={this.props.categoryKey}
        customLinkHandler={this.onLinkPressed}
        expensesList={this.state.expensesList}
        onDeleteExpenseItem={!isResubmission ? this.onDeleteExpenseItem : undefined}
      >
        {/* this (might be) not needed anymore */}
        {this.props.handleMoreInfoPressed && (
          <LinkableText
            translationKey="required-document.upload.help.link"
            onPress={this.props.handleMoreInfoPressed}
            textComponent={BodySecondaryText}
            linkComponent={BodyPrimaryText}
            linkStyle={{
              color: theme.color.primary,
            }}
          />
        )}
      </RequiredDocumentsUploadComponent>
    );
  }
}

const RequiredDocumentsUploadContainer = compose<any>(
  withToastBannerToggler,
  withNavigation,
  injectIntl,
  withQuizmasterLight([
    flagsQuestionIds.documentsUploadConsent,
    inSeasonQFIds.living_situation,
    ...incomeSilentQuestionIds,
    ...expenseSilentQuestionIds,
    ...expenseListQuestionIds,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(RequiredDocumentsUpload);
export { RequiredDocumentsUploadContainer };
