/* global window */
export const contentLinks = () => {
  const log = (...message: any[]) => {
    // Anroid webview seems to be unhappy if we use postMessage stored in own variable
    // see https://github.com/react-native-community/react-native-webview/issues/323#issuecomment-511824940
    if (window.ReactNativeWebView.postMessage)
      window.ReactNativeWebView.postMessage(message.join(',')); // eslint-disable-line no-undef
  };

  const contentWithLinks = document.querySelectorAll('.content-with-links'); // eslint-disable-line no-undef

  contentWithLinks.forEach((content: any) => {
    // eslint-disable-next-line no-param-reassign
    content.innerHTML = content.innerText.replace(
      /\[([^[]+)\]\(([^)]+)\)/gm,
      `<a class="content-link" tabindex="-1" href="$2">$1</a>`,
    );
  });
  const links = document.querySelectorAll('.content-link'); // eslint-disable-line no-undef

  links.forEach((link: any) => {
    link.addEventListener('click', (event: any) => {
      event.preventDefault();
      log(event.target.getAttribute('href'));
    });
  });
};
