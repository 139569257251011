import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SMALL_SCREEN_MAX_WIDTH = 767;
export const LARGE_SCREEN_MIN_WIDTH = SMALL_SCREEN_MAX_WIDTH + 1;
type Props = {
  children: any;
};
export const useSmallScreenQuery = () => {
  return useMediaQuery({
    maxWidth: SMALL_SCREEN_MAX_WIDTH,
  });
};
export const useLargeScreenQuery = () => {
  return useMediaQuery({
    minWidth: LARGE_SCREEN_MIN_WIDTH,
  });
};
export const SmallScreen = ({ children }: Props) => {
  const isSmallScreen = useSmallScreenQuery();
  return isSmallScreen ? children : null;
};
export const LargeScreen = ({ children }: Props) => {
  const isLargeScreen = useLargeScreenQuery();
  return isLargeScreen ? children : null;
};
export type WithScreenSizes = {
  isSmallScreen: boolean;
  isLargeScreen: boolean;
};
export const withScreenSizes = (Component: any) => {
  return (props: any) => (
    <Component
      isSmallScreen={useSmallScreenQuery()}
      isLargeScreen={useLargeScreenQuery()}
      {...props}
    />
  );
};
