import React, { ComponentType } from 'react';
import { SafeAreaView } from 'react-native';

import { testID } from '../../../common/testID';
import { List, theme } from '../../../components/core';
import { ListProps } from '../../../components/core/List/types';

import { DebugMenuOptions } from './debug-menu-options/types';
import HomeDebugFooter from './home-debug-footer';
import HomeDebugHeader from './home-debug-header';
import { defaultOptions } from './debug-menu-options';

type Props = DebugMenuOptions;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type ListItems = ListProps<any>['items'];
export const HomeDebugMenu: ComponentType<Props> = (props) => {
  const { userId, userEmail, selectedYear, selectedCountry, devToolsPackageId } = props;

  // eslint-disable-next-line no-console
  const options = defaultOptions
    .map((builder) => builder(props))
    .filter((option) => option !== null)
    .map((option) => ({ ...option, testId: option?.text }));

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <List
        removeClippedSubviews={false}
        {...testID('scrollview.debug.screen')}
        items={options as ListItems}
        rowStyle={{
          text: {
            color: theme.color.primary,
          },
        }}
        ListHeaderComponent={
          <HomeDebugHeader
            userId={userId}
            userEmail={userEmail}
            selectedYear={selectedYear}
            selectedCountry={selectedCountry}
            devToolsPackageId={devToolsPackageId}
          />
        }
        ListFooterComponent={<HomeDebugFooter />}
        contentContainerStyle={{ padding: 20 }}
      />
    </SafeAreaView>
  );
};
