import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Button, Card, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * It is a block with some content and a button
 */

export const NoRefundBlock: React.FC<Props> = ({
  title,
  description,
  buttonLabel,
  onButtonPress,
  ...otherProps
}): JSX.Element => {
  const buttonWidth = useBreakpointValue({ base: 'm', md: 's' });
  const descriptionVariant = useBreakpointValue({ base: 'bodyXSBook', md: 'bodySBook' });
  const titleVariant = useBreakpointValue({ base: 'titleXSBold', md: 'titleSBold' });
  return (
    <Card
      variant="sticky1"
      width="100%"
      backgroundColor="secondaryFeedback.greenLight"
      justifyContent="center"
      alignItems="center"
      px={2}
      py={2}
      {...otherProps}
    >
      <Text variant={titleVariant} value={title} pb="4px" />
      <Text
        variant={descriptionVariant}
        value={description}
        textAlign="center"
        color="greytones.textLight"
        maxWidth="293px"
      />
      {!!buttonLabel && onButtonPress && (
        <Button
          title={buttonLabel}
          variant="primaryWhite"
          background="greytones.white"
          onPress={onButtonPress}
          mt={2}
          pl={2}
          pr={2}
          width={buttonWidth}
        />
      )}
    </Card>
  );
};
