import * as React from 'react';

import { AccountContainer } from 'src/screens/account-menu/account-container';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { getRoute } from 'src/routes/navigate';
import { Routes } from 'src/routes/routes';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';

const AccountScreen = (): JSX.Element => {
  useDefaultNavigationOptions(
    { id: 'account.title' },
    {
      headerLeft: undefined,
      animationEnabled: false,
    },
  );

  return <AccountContainer />;
};

export const { action: toAccount, screen: accountScreen } = getRoute({
  routeName: Routes.Account,
  tracking: AnalyticsEvent.sectionAccount,
  screenComponent: AccountScreen,
});

export default AccountScreen;
