import React from 'react';
import { View } from 'react-native';

import SummaryText from '../../../../components/answer/summary/components/summary-text';

const NUM_ITEMS_TO_DISPLAY = 3;

type ListProps = {
  value: string[] | string;
  properties: Record<string, any>;
  linkable?: boolean;
  color?: string;
};

type FilterProp = {
  id: string;
};

type MapProp = FilterProp & { translationKey: string };

export const ShortSummary: React.FC<ListProps> = ({
  value: selectedAnswers,
  properties,
  linkable,
  color,
}) => {
  const mappable = typeof selectedAnswers === 'string' ? [selectedAnswers] : selectedAnswers;
  const lines = mappable.length === 1 ? 2 : 1;
  const isMoreThanThreeOptions = mappable.length > NUM_ITEMS_TO_DISPLAY;
  const selected = properties.options.filter(({ id }: FilterProp) => mappable.includes(id));

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'flex-end',
      }}
    >
      {selected.slice(0, NUM_ITEMS_TO_DISPLAY).map(({ id, translationKey }: MapProp) => {
        if (translationKey == null) {
          return null;
        }

        return (
          <SummaryText
            style={{
              textAlign: 'right',
            }}
            numberOfLines={lines}
            ellipsizeMode="middle"
            id={`it.question-flow.prefilled-categories.summary.${translationKey}`}
            linkable={linkable}
            key={id}
            color={color}
          />
        );
      })}
      {isMoreThanThreeOptions && <SummaryText key="ellipsis" id="summaries.default.ellipsis" />}
    </View>
  );
};
