export type MigrationYearInfo = {
  useLegacy: boolean;
};
export type MigrationYears = Record<number, MigrationYearInfo>;
export type State = {
  years: MigrationYears;
};
export type RootState = {
  migration: State;
};
export const SAVE_YEAR_INFO = 'migration/SAVE_YEAR_INFO';
export const SAVE_YEARS_INFO = 'migration/SAVE_YEARS_INFO';
type SaveYearAction = {
  type: typeof SAVE_YEAR_INFO;
  payload: {
    year: number;
    useLegacy?: boolean;
  };
};
type SaveYearsAction = {
  type: typeof SAVE_YEARS_INFO;
  payload: {
    years: MigrationYears;
  };
};
type Action = SaveYearAction | SaveYearsAction;

const saveYearInfo = (year: number, useLegacy: boolean) => ({
  type: SAVE_YEAR_INFO,
  payload: {
    year,
    useLegacy,
  },
});

const saveYearsInfo = (years: MigrationYears) => ({
  type: SAVE_YEAR_INFO,
  payload: {
    years,
  },
});

export const actions = {
  saveYearInfo,
  saveYearsInfo,
};
export const initial: State = {
  years: {},
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SAVE_YEAR_INFO: {
      const { year, useLegacy } = action.payload;
      return {
        years: {
          ...state.years,
          [year]: {
            useLegacy,
          },
        } as any,
      };
    }

    case SAVE_YEARS_INFO: {
      const { years } = action.payload;
      return {
        years: { ...state.years, ...years },
      };
    }

    default:
      return state;
  }
};

// selectors
const getYearInfo = (state: RootState, year: number): MigrationYearInfo | null | undefined => {
  if (!state.migration.years[year]) {
    return null;
  }
  return state.migration.years[year];
};

const getYearsInfo = (state: RootState): MigrationYears | null | undefined => {
  return state.migration.years;
};

export const selectors = {
  getYearInfo,
  getYearsInfo,
};
