import React from 'react';
import { ScrollView } from 'native-base';
import { useDispatch } from 'react-redux';
import { Documents } from '@taxfix/taxfix-sdk';
import { Prefill } from '@taxfix/italy-types';
import { PrefillTypes } from '@taxfix/italy-sdk';

import { Accordion, Container, Footer, NavButton, Text } from 'src/taxfix-components/src';
import { InformationButton } from 'src/components/information-button';

import { Box, useLargeScreenQuery, theme } from '../../../components/core';
import { useItalyIntl } from '../../_hooks';
import { actions as overlayActions } from '../../../stores/modules/overlay';
import ResultDetailsNextSteps from '../../../components/result-details-next-steps';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import { ScreenName } from '../../../types/screen-name';
import { useNavigation } from '../../../hooks/navigation-hook';
import { useNumberFormatter } from '../../../utils/useNumberFormatter';

import { OptimizationConfig, OptimizationVersion } from './result-optimization-types';

type ContainerProps = {
  optimizationVersion: OptimizationVersion;
  optimizationResult: {
    total: number;
    optimizations: PrefillTypes.DownloadPrefillDocuments.Optimization[];
  };
  primaryButtonAction: () => void;
  onBackButtonPress?: () => void;
  filteredPrefillDocuments: Documents.types.Document[];
  resultScreenExperiment?: boolean;
};

const resultScreenExperimentKeys = {
  title: 'it.result-optimization-screen.possible-optimization-title',
  body: 'it.result-optimization-screen.experiment-content.possible-optimization-body',
};

const optimizationCases = {
  [OptimizationVersion.OptimizationPossible]: {
    title: 'it.result-optimization-screen.possible-optimization-title',
    body: 'it.result-optimization-screen.possible-optimization-body',
  },
  [OptimizationVersion.NoOptimization]: {
    title: 'it.result-optimization-screen.no-optimization-title',
    body: 'it.result-optimization-screen.no-optimization-body',
  },
};

const optimizationsConfig: Record<Prefill.Optimizations, OptimizationConfig> = {
  [Prefill.Optimizations.MissingWorkdays]: {
    description: 'it.result-optimization-screen.optimization-missing-workdays',
    order: 1,
  },
  [Prefill.Optimizations.MissingBenefits]: {
    description: 'it.result-optimization-screen.optimization-missing-benefits',
    order: 2,
  },
  [Prefill.Optimizations.RentDetraction]: {
    description: 'it.result-optimization-screen.optimization-rent-detraction',
    order: 3,
    comment: 'it.result-optimization-screen.accordion.comment.from',
  },
  [Prefill.Optimizations.ChildrenExpenses]: {
    description: 'it.result-optimization-screen.optimization-children-expenses',
    order: 4,
    comment: 'it.result-optimization-screen.accordion.comment.on-average',
  },
  [Prefill.Optimizations.InsuranceDetraction]: {
    description: 'it.result-optimization-screen.optimization-insurance-detraction',
    order: 5,
  },
  [Prefill.Optimizations.VeterinaryDetraction]: {
    description: 'it.result-optimization-screen.optimization-veterinary-detraction',
    order: 6,
  },
};

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

export const ResultOptimizationContainer = ({
  optimizationVersion,
  optimizationResult: { total, optimizations },
  primaryButtonAction,
  onBackButtonPress,
  filteredPrefillDocuments,
  resultScreenExperiment,
}: ContainerProps): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const getFormattedNumber = useNumberFormatter();
  const { navigationActions } = useNavigation();
  const isLargeScreen = useLargeScreenQuery();
  const dispatch = useDispatch();

  const onSeeDownloadedDocuments = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ResultOptimization,
      buttonName: 'PrefillDocumentList',
    });
    navigationActions.toPrefillDocumentsResultList('screen', {
      prefillDocumentsList: filteredPrefillDocuments,
      resultScreenExperiment: resultScreenExperiment,
    });
  };

  const handlePressInfoButton = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ResultOptimization,
      buttonName: 'Find out more',
    });
    dispatch(overlayActions.show('ResultOptimizationInfoOverlay'));
  };

  const hasPrefillDocuments = filteredPrefillDocuments.length > 0;

  const showOptimizations =
    optimizationVersion === OptimizationVersion.OptimizationPossible && !!optimizations.length;

  const showExperimentContent = showOptimizations && resultScreenExperiment;

  const { title, body } = showExperimentContent
    ? resultScreenExperimentKeys
    : optimizationCases[optimizationVersion];

  const formattedOptimizations = React.useMemo(() => {
    const mappedOptimizations = optimizations
      .map((optimization) => {
        const { name, value } = optimization;
        const { description, order, comment } = optimizationsConfig[name] ?? {};

        if (!name || !value || !description) return;

        return {
          description: getTranslationText(description),
          value: getFormattedNumber(value),
          order,
          ...(comment ? { comment: getTranslationText(comment) } : {}),
        };
      })
      .filter(Boolean) as Array<OptimizationConfig & { value: string }>;
    return mappedOptimizations.sort((a, b) => a.order - b.order);
  }, [optimizations, getTranslationText, getFormattedNumber]);

  return (
    <Container flex="1">
      {isLargeScreen && onBackButtonPress && (
        <Container top={0} zIndex={1} position="absolute">
          <NavButton
            onPress={onBackButtonPress}
            text={getTranslationText('it.product-bundle-screen.back-button.text')}
          />
        </Container>
      )}
      <ScrollView
        paddingTop="24px"
        width="100%"
        maxW="580px"
        alignSelf="center"
        contentContainerStyle={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box left={2} right={2} style={{ width: '100%' }}>
          <Text
            variant="titleSBold"
            color="greytones.backgroundDark"
            value={getTranslationText(title)}
            textAlign="left"
            width="100%"
            mb="12px"
          />
          <Text
            variant="bodyMBook"
            value={getTranslationText(body, { bold: getBoldText })}
            textAlign="left"
            width="100%"
            color="greytones.textLight"
          />
          <Container maxWidth="110px" marginTop="8px" marginBottom="16px">
            <InformationButton
              color={theme.color.primary}
              size="xsmall"
              withText
              informationText={getTranslationText('general.find-more.link-text')}
              onPress={handlePressInfoButton}
            />
          </Container>
        </Box>
        {showOptimizations && (
          <Box style={{ width: '100%' }}>
            <Text
              ml="20px"
              mr="20px"
              mt="24px"
              mb="24px"
              variant="titleXSBold"
              color="greytones.backgroundDark"
              value={getTranslationText(
                showExperimentContent
                  ? 'it.result-optimization-screen.experiment-content.optimization-title'
                  : 'it.result-optimization-screen.optimization-title',
              )}
              textAlign="left"
              width="100%"
            />
            <Accordion
              isOpen={true}
              title={getTranslationText(
                showExperimentContent
                  ? 'it.result-optimization-screen.experiment-content.accordion.title'
                  : 'it.result-optimization-screen.accordion.title',
              )}
              value={getFormattedNumber(total)}
              data={formattedOptimizations}
            />
          </Box>
        )}

        {!showExperimentContent && (
          <Box style={{ width: '100%' }} top={2} left={2} right={2} bottom={2.4}>
            <ResultDetailsNextSteps />
          </Box>
        )}
      </ScrollView>
      <Footer
        backgroundColor="white"
        px="20px"
        py="0px"
        primaryButtonTitle={getTranslationText('it.result-optimization-screen.primary-button.text')}
        secondaryButtonTitle={
          hasPrefillDocuments
            ? getTranslationText('it.result-optimization-screen.secondary-button.text')
            : undefined
        }
        secondaryButtonProps={{ variant: 'tertiary' }}
        onPressPrimary={primaryButtonAction}
        onPressSecondary={hasPrefillDocuments ? onSeeDownloadedDocuments : undefined}
      />
    </Container>
  );
};
