import React from 'react';

import { CommonResultComponent } from './CommonResult';

type Props = {
  onDismiss: () => void;
  onOpenMail: () => any;
};

export const ResultPassiveComponent = ({ onDismiss, onOpenMail }: Props): JSX.Element => {
  return (
    <CommonResultComponent
      icon="happy"
      title="feedback-modal.passive.title"
      subtitle="feedback-modal.passive.subtitle"
      ctaTranslationKey="feedback-modal.passive.cta"
      onCtaPress={onOpenMail}
      onDismiss={onDismiss}
    />
  );
};
