import { CountryCodes } from '@taxfix/types';

import { Coordinate, Address, Place } from '../types/geo';

import { APIErrors } from './utils';
import { GoogleMaps } from './google/maps/client';
import { GeocodeParams } from './google/maps/types';

export type GeocodeApiAddressComponent = {
  long_name: string;
  short_name: string;
  types: Array<string>;
};
export type GeocodeApiLocation = {
  lat: number;
  lng: number;
};
export type GeocodeApiLocationWeb = {
  lat: () => number;
  lng: () => number;
};
export type GeocodeApiGeometry<LocationType> = {
  location: LocationType;
};
export type GeocodeApiResultGeneric<LocationType> = {
  address_components: GeocodeApiAddressComponent[];
  formatted_address: string;
  types: Array<string>;
  geometry: GeocodeApiGeometry<LocationType>;
};
export type GeocodeApiResult = GeocodeApiResultGeneric<GeocodeApiLocation>;
export type GeocodeApiResultWeb = GeocodeApiResultGeneric<GeocodeApiLocationWeb>;
export type GeocodeApiResponseGeneric<ResultType> = {
  results: ResultType[];
  status: string;
};
export type GeocodeApiResponse = GeocodeApiResponseGeneric<GeocodeApiResult>;
export type GeocodeApiResponseWeb = GeocodeApiResponseGeneric<GeocodeApiResultWeb>;
export const callApi = async (
  address: string,
  country?: string,
  // @ts-ignore
  language?: string,
): Promise<GeocodeApiResult[]> => {
  let responseJson: GeocodeApiResponse | any;

  try {
    const params: GeocodeParams = {
      address,
      language,
    };
    if (country) params.components = `country:${country}`;
    responseJson = (await GoogleMaps.geocode(params)) as GeocodeApiResponse;
  } catch (error) {
    throw new Error(APIErrors.NetworkError);
  }

  if (responseJson.status !== 'OK') {
    throw new Error(responseJson.status);
  }

  return responseJson.results;
};
export const parseResultIntoCoordinate = (apiResult: GeocodeApiResult): Coordinate => {
  const coordinate: Coordinate = {
    latitude: -1,
    longitude: -1,
  };

  if (!apiResult) {
    return coordinate;
  }

  if (apiResult.geometry && apiResult.geometry.location) {
    coordinate.latitude = apiResult.geometry.location.lat || -1;
    coordinate.longitude = apiResult.geometry.location.lng || -1;
  }

  return coordinate;
};
export const parseResultIntoPlace = (apiResult: GeocodeApiResult): Place => {
  const address: Address = {
    street: '',
    number: '',
    numberExtension: '',
    addition: '',
    postalCode: '',
    city: '',
  };
  const coordinate: Coordinate = {
    latitude: -1,
    longitude: -1,
  };
  const place: Place = {
    displayName: '',
    coordinate,
    postalCode: '',
    state: '',
    countryCode: '',
    address,
  };
  place.displayName = apiResult.formatted_address || '';

  if (apiResult.address_components) {
    const addressComponents: GeocodeApiAddressComponent[] = apiResult.address_components as any;
    addressComponents.forEach((addressComponent: GeocodeApiAddressComponent) => {
      const longName = addressComponent.long_name || false;
      const shortName = addressComponent.short_name || false;
      const { types } = addressComponent;
      if (!types) return false;

      if (types.includes('street_number') && longName) {
        address.number = longName;
      }

      if (types.includes('route') && longName) {
        address.street = longName;
      }

      if (types.includes('locality') && types.includes('political') && longName) {
        address.city = longName;
      }

      if (
        types.includes('administrative_area_level_1') &&
        types.includes('political') &&
        longName
      ) {
        place.state = longName;
      }

      if (types.includes('country') && types.includes('political') && shortName) {
        place.countryCode = shortName;
      }

      if (types.includes('postal_code') && longName) {
        address.postalCode = longName;
        place.postalCode = longName;
      }

      return true;
    });
  }

  place.address = address;
  place.coordinate = parseResultIntoCoordinate(apiResult);
  return place;
};

const parseResultIntoPlaceItaly = (apiResult: GeocodeApiResult, place: Place) => {
  const newPlace = { ...place };

  if (apiResult.address_components) {
    apiResult.address_components.forEach((addressComponent: GeocodeApiAddressComponent) => {
      const { types } = addressComponent;
      const shortName = addressComponent.short_name || false;
      if (!types) return false;

      if (
        types.includes('administrative_area_level_2') &&
        types.includes('political') &&
        shortName
      ) {
        // Use administrative_area_level_2 as state for Italy
        newPlace.state = shortName;
      }

      return true;
    });
  }

  return newPlace;
};

export const getPlace = async (
  searchTerm: string,
  country?: string,
  // @ts-ignore
  language?: string,
): Promise<Place | null> => {
  try {
    const results: GeocodeApiResult[] = await callApi(searchTerm, country, language);

    if (!results.length) {
      return null;
    }

    const place = parseResultIntoPlace(results[0]);

    switch (country) {
      case CountryCodes.IT:
        return parseResultIntoPlaceItaly(results[0], place);

      default:
        return place;
    }
  } catch (error) {
    return Promise.reject(error.message);
  }
};
export const getRawResultAndCoordinate = async (
  searchTerm: string,
  country: string,
  language: string,
): Promise<{
  result: GeocodeApiResult;
  coordinate: Coordinate;
} | null> => {
  try {
    const results: GeocodeApiResult[] = await callApi(searchTerm, country, language);

    if (!results.length) {
      return null;
    }

    return {
      result: results[0],
      coordinate: parseResultIntoCoordinate(results[0]),
    };
  } catch (error) {
    return Promise.reject(error.message);
  }
};
