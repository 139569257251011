import { Pressable, useBreakpointValue } from 'native-base';
import React from 'react';

import { Collapsable, Container, Label, Text } from 'src/taxfix-components/src';

import { Image } from '../../general/Image';

import { Props } from './types';

/**
 * The third state of the 2022 tax year card
 */

export const TaxYearCardCompleted: React.FC<Props> = ({
  onPress,
  content,
  source,
  labelText,
  collapsableTitle,
  collapsableSubtitle,
  innerCursor,
  ...otherProps
}): JSX.Element => {
  const childHeight = useBreakpointValue({ base: '300px', md: '465px' });
  const collapsableHeight = useBreakpointValue({ base: '70px', md: '80px' });
  const photoHeight = useBreakpointValue({ base: '97px', md: '129px' });
  const photoWidth = useBreakpointValue({ base: '199px', md: '265px' });
  const marginBottomPhoto = useBreakpointValue({ base: 2, md: '31px' });
  const marginBottomText = useBreakpointValue({ base: 2, md: '16px' });
  const textFont = useBreakpointValue({ base: 'titleSBold', md: 'titleMBold' });
  const maxWidthText = useBreakpointValue({ base: '220px', md: '300px' });

  return (
    <Collapsable
      startOpen
      minHeight={collapsableHeight}
      title={collapsableTitle}
      subtitle={collapsableSubtitle}
      backgroundColor="greytones.white"
      maxW="580px"
      {...otherProps}
    >
      <Pressable onPress={onPress} cursor={innerCursor}>
        <Container
          height={childHeight}
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="brand.brandGreen"
          borderRadius={1}
        >
          <Container marginBottom={marginBottomPhoto}>
            <Image name={source} height={photoHeight} w={photoWidth} resizeMode="contain" />
          </Container>
          <Container width="100%" maxW={maxWidthText} marginBottom={marginBottomText}>
            <Text textAlign="center" variant={textFont} color="greytones.white">
              {content}
            </Text>
          </Container>
          <Label variant="green" message={labelText} color="secondaryFeedback.greenDark" />
        </Container>
      </Pressable>
    </Collapsable>
  );
};
