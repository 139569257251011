import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getDefaultTaxYear } from 'src/services/country-and-year';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import {
  DocumentGuidanceCategoryName,
  getTranslationKey,
  translationInfoForDocuments,
} from '../../../common/constants-it';
import {
  DocumentsExpensesUploadedList,
  RequiredDocumentsUploadContainer,
} from '../../../screens/containers/required-documents/required-documents-upload';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

type ActionParams = {
  categoryKey: DocumentGuidanceCategoryName;
  requiredDocumentTypes: string[];
  handleMoreInfoPressed?: () => void;
  customLinkHandler?: (url: string) => void;
  expensesList?: DocumentsExpensesUploadedList[];
};

type Props = Navigation<
  'RequiredDocumentsUpload',
  {
    RequiredDocumentsUpload: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate('RequiredDocumentsUpload');

const RequiredDocumentsUploadRoute: React.FC<Props> = ({ route }) => {
  const {
    requiredDocumentTypes,
    categoryKey,
    handleMoreInfoPressed,
    customLinkHandler,
    expensesList,
  } = route.params;

  const intl = useIntl();
  const taxYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const taxSeason = taxYear + 1;
  const CUYear = taxSeason;

  const headerTitle = intl.formatMessage(
    {
      id: getTranslationKey(
        translationInfoForDocuments,
        requiredDocumentTypes[0],
        'translationKey',
      ),
    },
    {
      CUYear,
      taxYear,
      taxSeason,
    },
  );

  useDefaultNavigationOptions({ id: headerTitle });

  return (
    <RequiredDocumentsUploadContainer
      handleMoreInfoPressed={handleMoreInfoPressed}
      requiredDocumentTypes={requiredDocumentTypes}
      categoryKey={categoryKey}
      customLinkHandler={customLinkHandler}
      titleText={headerTitle}
      expensesList={expensesList}
    />
  );
};

export default RequiredDocumentsUploadRoute;
