import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import docs from '../../../../assets/docs';

const mapStateToProps = (stores: any) => ({
  selectedLocale: settingsSelectors.selectedLocale(stores),
});

const connector = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ListRowComponentPropsType<any> & WithNavigation & ReduxProps;

const TermsAndCondition = (props: Props) => {
  const { selectedLocale } = props;

  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions } = props;
    const { payload } = item;
    trackMenuItem(item);

    if (payload) {
      (navigationActions as any).toInformation('screen', {
        content: payload.content,
      });
    }
  };

  return (props as any).children({
    hide: false,
    data: {
      translationKey: menuItem.terms,
      testId: menuItem.terms,
      payload: {
        content: docs.terms(selectedLocale),
      },
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const TermsAndConditionMenu = compose(withNavigation, connector)(TermsAndCondition);
