import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { getRoute } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { CloseAccount } from './close-account-view';

const CloseAccountScreenIT = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'it.account.close-account.header.title' });

  return <CloseAccount />;
};

export const { action: toCloseAccountIT, screen: closeAccountScreen } = getRoute({
  routeName: ScreenName.ItalyCloseAccount,
  tracking: {
    name: AnalyticsEvent.sectionShown,
    params: {
      sectionName: ScreenName.ItalyCloseAccount,
    },
  },
  screenComponent: CloseAccountScreenIT,
});

export { CloseAccountScreenIT };
