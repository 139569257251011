import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import {
  DisplayTitleText,
  Image,
  BodyPrimaryText,
  BodySecondaryText,
  theme,
  Box,
  Section,
  ProgressBar,
} from '../core';
import { NotificationBannerLegacy, NotificationBannerTypeKey } from '../notification-banner-legacy';
import { Footer } from '../footer';
import { FooterType } from '../footer.types';
import { StyleProp, StyleTextProp } from '../types';
import { ConnectionFailedType } from '../../screens/signup/signup-container.types';
import { InformationButton } from '../information-button';
import { useLargeScreenQuery } from '../core/MediaQuery';
import { LoginErrorValues } from '../../screens/login/Login.types';

export type KeyboardShouldPersistTaps = 'always' | 'never' | 'handled' | false | true;
export type SignupError = 'ConnectionFailed' | 'ValidationFailed' | 'GenericSignupError';
type Props = {
  align?: 'start' | 'end';
  scrollable?: boolean;
  imageId?: string;
  titleKey: string;
  titleValues?: Record<string, string>;
  leadKey?: string;
  leadValues?: Record<string, string>;
  confirmKey: string;
  imageName?: string | null | undefined;
  disabled?: boolean;
  loading?: boolean;
  onConfirm?: () => void;
  children?: React.ReactNode;
  leadStyle?: StyleProp;
  leadTextComponent?: typeof BodyPrimaryText | typeof BodySecondaryText;
  containerStyle?: StyleProp;
  scrollViewRef?: (ref: any) => void;
  errorKey?:
    | (ConnectionFailedType | null | undefined)
    | (SignupError | null | undefined)
    | (LoginErrorValues | null | undefined);
  titleStyle?: StyleTextProp;
  childContainerStyle?: StyleProp;
  keyboardShouldPersistTaps?: KeyboardShouldPersistTaps;
  onInfoClick?: () => void;
  showProgress?: boolean;
  progress?: number;
  centered?: boolean;
  footerType?: FooterType;
  secondaryButton?: () => React.ReactNode;
  sensitiveDataModalExperimentVariant?: string;
};
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  containerLargeScreen: {
    marginTop: 70,
  },
  imageContainer: {
    flex: 1,
    paddingTop: 20,
    alignSelf: 'center',
  },
  padding: {
    paddingHorizontal: 20,
  },
  titleText: {
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 15,
  },
  leadContainer: {
    paddingBottom: 30,
  },
  leadText: {},
  childContainer: {
    paddingBottom: 30,
  },
  buttonContainer: {},
  activityIndicator: {
    height: 52,
    justifyContent: 'space-around',
  },
  errorText: {
    paddingTop: 28,
    color: theme.color.errorTextDark,
  },
  errorContainer: {
    marginTop: 0,
    marginHorizontal: 20,
    marginBottom: 30,
  },
});
export const Layout = ({
  scrollable = false,
  titleKey,
  titleValues,
  leadKey,
  leadValues,
  confirmKey,
  disabled = false,
  loading = false,
  imageName = '',
  onConfirm,
  children,
  leadStyle = {},
  containerStyle = {},
  scrollViewRef = () => {},
  errorKey,
  titleStyle = {},
  childContainerStyle = {},
  keyboardShouldPersistTaps = 'always',
  leadTextComponent: LeadTextComponent = BodyPrimaryText,
  onInfoClick,
  showProgress = false,
  progress,
  centered = false,
  footerType = centered ? 'stretched' : 'default',
  secondaryButton,
  sensitiveDataModalExperimentVariant,
}: Props): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();

  const image =
    imageName != null ? (
      <View style={styles.imageContainer}>
        <Image name={imageName} resizeMode="contain" />
      </View>
    ) : null;
  const lead = leadKey ? (
    <View style={[styles.leadContainer]}>
      <LeadTextComponent
        id={leadKey}
        values={leadValues}
        style={[styles.leadText, styles.padding, leadStyle]}
      />
    </View>
  ) : null;
  const kids =
    children != null ? (
      <View style={[styles.childContainer, styles.padding, childContainerStyle]}>{children}</View>
    ) : null;

  return (
    <>
      {showProgress && <ProgressBar progress={progress} />}
      <Section
        size="narrow"
        centered={centered}
        style={isLargeScreen ? styles.containerLargeScreen : undefined}
      >
        <View
          style={[
            containerStyle,
            {
              flex: 1,
            },
          ]}
        >
          {errorKey && (
            <NotificationBannerLegacy notificationType={errorKey as NotificationBannerTypeKey} />
          )}
          <ScrollView
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            ref={scrollViewRef}
            scrollEnabled={scrollable}
            contentContainerStyle={[styles.container]}
            testID="scrollview.credentials"
          >
            <View
              style={{
                flex: 1,
              }}
            >
              {image}
              <Box alignHorizontally="start" alignVertically="center" direction="row">
                {sensitiveDataModalExperimentVariant !== 'VARIANT_C' && (
                  <DisplayTitleText
                    id={titleKey}
                    values={titleValues}
                    style={[styles.titleText, styles.padding, titleStyle]}
                  />
                )}
                {!!onInfoClick && (
                  <Box style={[styles.titleText, { ...titleStyle }]}>
                    <InformationButton
                      size="small"
                      symbol="i"
                      onPress={onInfoClick}
                      color={theme.color.primary}
                    />
                  </Box>
                )}
              </Box>
              {lead}
              {kids}
            </View>
          </ScrollView>
          {onConfirm && (
            <View style={styles.buttonContainer}>
              <Footer
                type={footerType}
                isLoading={loading}
                onPress={onConfirm}
                testId={confirmKey}
                isDisabled={disabled}
                translationKey={confirmKey}
                applyContentSeparation={scrollable}
                secondaryButton={secondaryButton}
              />
            </View>
          )}
        </View>
      </Section>
    </>
  );
};
