import React from 'react';

import { TextButton, Box } from '../core';

type ButtonTypes = {
  id: string;
  color: string;
  onPress: () => any;
  disabled?: boolean;
};

const Button = ({ id, color, onPress, disabled = false }: ButtonTypes) => (
  <Box
    style={{
      minHeight: 52,
    }}
  >
    <TextButton translationKey={id} textColor={color} onPress={onPress} disabled={disabled} />
  </Box>
);

export default Button;
