export const TOGGLE_DISABLE_LOCK_SCREEN = 'lock/TOGGLE_DISABLE_LOCK_SCREEN';
export type State = {
  hasLockedScreenDisabled: boolean;
};
type ToggleDisableLockScreen = {
  type: typeof TOGGLE_DISABLE_LOCK_SCREEN;
  disabled: boolean;
};
type Action = ToggleDisableLockScreen;

const toggleDisableLockScreen = (disabled: boolean): ToggleDisableLockScreen => ({
  type: TOGGLE_DISABLE_LOCK_SCREEN,
  disabled,
});

export const actions = {
  toggleDisableLockScreen,
};
export const initial: State = {
  hasLockedScreenDisabled: false,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case TOGGLE_DISABLE_LOCK_SCREEN:
      return { ...state, hasLockedScreenDisabled: action.disabled };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
// selectors
export type RootState = {
  lock: State;
};

const hasLockedScreenDisabled = (state: RootState): boolean => state.lock.hasLockedScreenDisabled;

export const selectors = {
  hasLockedScreenDisabled,
};
