import { StatusMiddleware, StatusStepRedirect } from 'src/stores/modules/status';
import { flagsQuestionIds } from 'src/common/constants-it';
import { getAnswer } from 'src/stores-legacy/helpers';
import { getNavigationActions } from 'src/routes/config-util';

export const checkProductBundleSelection: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const productBundleSelection = getAnswer(getState(), flagsQuestionIds.productBundleSelection);
  const firstBundleScreenSeen = getAnswer(getState(), flagsQuestionIds.confirmedBundleSelection);

  if (firstBundleScreenSeen && !productBundleSelection) {
    dispatch(breakAction());
  } else {
    next();
  }
};

export const redirectBundleSelection: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 0,
    actions: [getNavigationActions().toPrefillDocumentsResult('screen')],
  });
};
