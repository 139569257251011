import * as React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import PrivacyPolicyContainer from './privacy-policy';

export type ActionParams = {
  content: string;
  contentStyles?: string;
};

type Props = Navigation<
  Routes.PrivacyPolicy,
  { [Routes.PrivacyPolicy]: NavigationParams<ActionParams> }
>;

const PrivacyPolicyScreen = ({ route }: Props) => {
  const { content, contentStyles } = route.params;
  useDefaultNavigationOptions();
  return <PrivacyPolicyContainer content={content} contentStyles={contentStyles} />;
};

export const { action: toPrivacyPolicy, screen: privacyPolicyScreen } = getRoute<ActionParams>({
  routeName: Routes.PrivacyPolicy,
  screenComponent: PrivacyPolicyScreen,
});

export default PrivacyPolicyScreen;
