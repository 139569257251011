import { Pressable, useTheme } from 'native-base';
import React, { useState } from 'react';

import { icons } from '../../../Icons/Icons';
import { Container } from '../../layout/Container';
import { Card } from '../Card';
import { Icon } from '../Icon';
import { Text } from '../Text';

import { StyledCollapsableProps } from './types';

export const Collapsable: React.FC<StyledCollapsableProps> = ({
  variant = 'elevation2',
  title,
  children,
  subtitle,
  iconSize = 3,
  startOpen = false,
  titleFont = 'titleXSBold',
  subtitleFont = 'bodyMBook',
  horizontalPadding = 4,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const { colors } = useTheme();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card variant={variant} borderRadius={1} {...otherProps}>
      <Container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px={horizontalPadding}
        minHeight={otherProps.minHeight}
        borderRadius={1}
      >
        {typeof title === 'string' ? <Text variant={titleFont}>{title}</Text> : title}
        {subtitle && (
          <Text variant={subtitleFont} color={colors.greytones.graphicsDark}>
            {subtitle}
          </Text>
        )}
        <Pressable onPress={handleClick}>
          <Icon
            path={isOpen ? icons.chevronDown : icons.chevronUp}
            color={colors.greytones.textDefault}
            size={iconSize}
          />
        </Pressable>
      </Container>
      {isOpen && children}
    </Card>
  );
};
