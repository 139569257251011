import { CountryCodes } from '@taxfix/types';

import { LanguageCode } from '../types';
import { supportedLanguages } from '../lang';

export type LanguageOption = {
  translationKey: string;
  id?: LanguageCode;
  value: LanguageCode;
};
export const getLanguageOptions = (countryCode: CountryCodes): LanguageOption[] => {
  const countrySupportedLanguages = supportedLanguages(countryCode);
  return countrySupportedLanguages.map(
    (countrySupportedLanguage: LanguageCode): LanguageOption => ({
      translationKey: `language.${countrySupportedLanguage}`,
      id: countrySupportedLanguage,
      value: countrySupportedLanguage,
    }),
  );
};
