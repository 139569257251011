import React from 'react';
import get from 'lodash/get';
import { ColorValue, Image, ImageStyle, Platform, StyleSheet, View } from 'react-native';

import { getImageAsset } from '../../../assets/image-assets';
import { testID } from '../../../common/testID';
import { theme } from '../theme';
import { StyleProp } from '../../types';
import { ResizeModes } from '../Image';

import { icons } from './assets';

type Props = {
  name: string;
  width?: number;
  rotate?: number;
  testId?: string;
  height?: number;
  tintColor?: string;
  style?: ImageStyle;
  resizeMode?: ResizeModes;
};

type ImageSize = null | {
  width: number;
  height: number;
};

type SvgStyle = Omit<ImageStyle & { color?: ColorValue }, 'tintColor'>;

export const getIconContents = (name: string) => {
  // Try local icons
  const icon = get(icons, name, null);
  if (icon) return Platform.OS === 'web' ? icon.default : icon;

  // Try packaged icons
  const asset = getImageAsset(name);
  if (asset) return asset;

  return null;
};

const rotationStyle = (rotation?: number) => {
  if (rotation) {
    return {
      transform: [
        {
          rotate: `${rotation}deg`,
        },
      ],
    };
  }

  return null;
};

const tintColorStyle = (tintColor?: string) => {
  if (tintColor) {
    return { tintColor };
  }

  return null;
};

const getSvgStyle = (tintColor?: string, style?: ImageStyle) => {
  const { tintColor: styleTintcolor, ...rest } = StyleSheet.flatten(style) || {};
  const svgStyle: SvgStyle = { ...rest };

  if (tintColor) svgStyle.color = tintColor;

  if (styleTintcolor) svgStyle.color = styleTintcolor;

  return svgStyle;
};

const ImageIcon = ({ name, tintColor, rotate, style, resizeMode = 'center', testId }: Props) => {
  const source = getIconContents(name);
  const [size, setSize] = React.useState<ImageSize>(null);

  if (!size && source && Platform.OS === 'web') {
    Image.getSize(source, (width, height) =>
      setSize({
        width,
        height,
      }),
    );
  }

  return (
    <Image
      source={source}
      {...testID(testId)}
      resizeMode={resizeMode}
      style={[
        tintColorStyle(tintColor),
        rotationStyle(rotate),
        Platform.select({
          web: size,
        }),
        style,
      ]}
    />
  );
};

const placeholderStyles = StyleSheet.create({
  container: {
    width: 30,
    height: 30,
    marginBottom: 13,
    borderRadius: 100,
    borderColor: theme.color.primary,
    borderWidth: 1,
  },
});

const PlaceholderIcon = ({ style }: { style?: StyleProp }) => (
  <View style={[placeholderStyles.container, style]} />
);

const Icon = (props: Props) => {
  if (props.name === 'placeholder') {
    return <PlaceholderIcon {...props} />;
  }

  if (props.name.includes('-svg') && Platform.OS !== 'web') {
    const { default: Svg } = getIconContents(props.name) || {};
    // ISSUE: When the icon is SVG and it receives
    // the tintColor prop react-native-svg crashes.
    const { tintColor, style, ...rest } = props;
    const svgStyle = getSvgStyle(tintColor, style);

    if (Svg) return <Svg {...rest} style={svgStyle} />;
  }

  return <ImageIcon {...props} />;
};

export default Icon;

export { icons };
