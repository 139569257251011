import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as remoteConfigSelectors } from '../stores/modules/remote-config-firebase';
import { GoogleMaps } from '../services/google/maps/client';

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
  mapsKey: remoteConfigSelectors.getMapsKey(stores),
});

const withStoreConnection = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof withStoreConnection>;
type Props = {
  children: React.ReactNode;
} & ReduxProps;

class AppInitializerUndecorated extends React.Component<Props> {
  componentDidMount() {
    GoogleMaps.initialize({
      apiKey: this.props.mapsKey,
    });
  }

  render() {
    return this.props.children;
  }
}

export const AppInitializer = withStoreConnection(AppInitializerUndecorated);
