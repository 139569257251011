import React from 'react';

import { Props } from 'src/taxfix-components/src/components/layout/Container/types';

import { Container, Stepper, Text } from '../taxfix-components/src';
import { useItalyIntl } from '../_italy/_hooks';

const ResultDetailsNextSteps = (props: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  return (
    <Container backgroundColor="greytones.background" borderRadius="24px" padding="20px" {...props}>
      <Text
        color="greytones.textLight"
        variant="overlineM"
        value={getTranslationText('it.result-details.next-steps.header')}
        mb={2}
      />
      <Stepper
        direction="vertical"
        steps={[
          {
            active: true,
            titleText: getTranslationText('it.result-details.next-steps.step-1.title'),
            labelText: getTranslationText('it.result-details.next-steps.step-1.content'),
          },
          {
            titleText: getTranslationText('it.result-details.next-steps.step-2.title'),
            labelText: getTranslationText('it.result-details.next-steps.step-2.content'),
          },
          {
            titleText: getTranslationText('it.result-details.next-steps.step-3.title'),
            labelText: getTranslationText('it.result-details.next-steps.step-3.content'),
          },
        ]}
        borderStyle="solid"
      />
    </Container>
  );
};

export default ResultDetailsNextSteps;
