import { Years, Documents } from '@taxfix/types';

import { getAnswer, updateAnswer } from 'src/stores-legacy/helpers';
import { flagsQuestionIds } from 'src/common/constants-it';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenMode } from 'src/routes/config-util';
import {
  StatusMiddleware,
  StatusStepSection,
  StatusSkipFunction,
  SectionResolverFunction,
} from 'src/stores/modules/status';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  selectors as prefillSelectors,
  actions as prefillActions,
} from 'src/stores/modules/prefill';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { PrefillStates } from 'src/types/prefills';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { resolveNavigationForItaly } from 'src/services/prefill-navigation-resolver';

const isPreSeasonCheck = (getState: () => any): boolean => {
  const { isPreSeason } = getTaxSeasonInfo(getState());
  return isPreSeason;
};

export const checkIdentification: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const year = settingsSelectors.selectedYear(getState());

    if (!year) {
      throw new Error('prefill step middleware needs year');
    }

    const userId = userAuthSelectors.getUserId(getState());
    const accessToken = userAuthSelectors.getAccessToken(getState());
    const status = prefillSelectors.getStatus(getState());
    const rejectedReasons = prefillSelectors.getRejectedReasons(getState());

    const customNavigation = await (config.navigation
      ? resolveNavigationForItaly(
          config.navigation,
          status,
          rejectedReasons,
          year,
          accessToken,
          userId,
        )
      : null);

    if (!customNavigation) {
      // If no custom navigation was provided it means we're done
      next();
      return;
    }

    const isPreSeason = isPreSeasonCheck(getState);
    let customSection;

    const navigationDefault = {
      navigation: {
        link: {
          action: 'toPrefillDocumentUpload',
          config: 'screen' as ScreenMode,
          params: {
            prefillDocumentType: Documents.NonReceiptTypes.Id,
            year,
          },
        },
      },
    };

    switch (status) {
      // Status is null when prefill has not been created yet
      case null: {
        const sectionNew: StatusStepSection | null | undefined = config.section?.new;

        if (!sectionNew) {
          customSection = null;
        }

        const customSectionNavigation = config.section?.new.navigation || navigationDefault;
        customSection = {
          ...sectionNew,
          ...customSectionNavigation,
        };

        break;
      }

      case PrefillStates.Rejected: {
        const section: StatusStepSection | null | undefined = config.section?.rejected;

        if (!section) {
          customSection = null;
          break;
        }

        const customSectionNavigation = config.section?.rejected.navigation || navigationDefault;

        customSection = {
          ...section,
          ...customSectionNavigation,
        };
        break;
      }

      // After creating pre-fill (with ID) we're not blocking the user.
      case PrefillStates.Created:
      case PrefillStates.Reviewed:
      case PrefillStates.Processing:
      case PrefillStates.Successful: {
        next();
        return;
      }

      case PrefillStates.Failed:
      default: {
        // All above states as well as any other not known state
        // will be shown as waiting
        customSection = isPreSeason ? config.section?.waitingPreSeason : config.section?.waiting;
        break;
      }
    }

    dispatch(breakAction(customNavigation, customSection));
  } catch (e) {
    dispatch(failureAction(`error status prefill: ${(e as Error).message}`));
  }
};

export const fetchPrefills: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const accessToken = userAuthSelectors.getAccessToken(getState());
    const userId = userAuthSelectors.getUserId(getState());
    const year = settingsSelectors.selectedYear(getState());

    if (!accessToken || !userId || !year) {
      throw new Error('prefill api needs accessToken, userId and year');
    }

    await dispatch(prefillActions.retrieveLatestPrefill(accessToken, userId, year));
    const prefillStatus = prefillSelectors.getStatus(getState());
    const error = prefillSelectors.getError(getState());

    // we're making sure we set the skipped prefill flag to false in case
    // the prefill was created but the user was not able to continue normal ID upload flow
    // because of an error
    // https://taxfix.slack.com/archives/C01KQRRP5A8/p1652967181308599
    if (prefillStatus && prefillStatus !== PrefillStates.Rejected) {
      updateAnswer(flagsQuestionIds.prefillUploadSkipped, false, getState());
    }

    if (error) {
      throw new Error(error as any);
    }

    next();
  } catch (e) {
    dispatch(failureAction(`error fetching prefills: ${(e as Error).message}`));
  }
};

export const hasSkippedPrefillSection: StatusSkipFunction = async (getState) => {
  return getAnswer(getState(), flagsQuestionIds.prefillUploadSkipped);
};

export const addCompleteIdentificationUploadSubtitle: SectionResolverFunction = (
  getState,
  sections,
  index,
) => {
  const isSectionInSkipStatus = getAnswer(getState(), flagsQuestionIds.prefillUploadSkipped);
  if (isSectionInSkipStatus) {
    return {
      index,
      ...sections.todo,
      navigation: {
        id: 'prefill.upload-documents.button.upload',
        link: {
          action: 'toPrefillDocumentUpload',
          config: 'screen',
          params: {
            prefillDocumentType: Documents.NonReceiptTypes.Id,
            year: settingsSelectors.selectedYear(getState()) as Years,
          },
        },
        tracking: {
          eventName: AnalyticsEvent.prefillStarted,
        },
      },
      skipped: true,
      warning: prefillSelectors.getStatus(getState()) === PrefillStates.Rejected,
    };
  }
  return null;
};
