import * as React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { getNavigationActions } from '../../routes/config-util';
import { Loading } from '../../components/loading';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { selectors as firebaseSelectors } from '../../stores/modules/remote-config-firebase';
import {
  actions as userLegalActions,
  selectors as userLegalSelectors,
} from '../../stores/modules/user-legal';
import { docs } from '../../../assets/docs';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';

type Props = {
  selectedCountry: CountryCodes;
  acceptedTacVersion: string | null | undefined;
  acceptedPrivacyVersion: string | null | undefined;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
  userLegalActions: typeof userLegalActions;
  accessToken: string;
  skipLegalChange: boolean;
} & WithNavigation;

const mapDispatchToProps = (dispatch: any) => ({
  userLegalActions: bindActionCreators(userLegalActions, dispatch),
});

const mapStateToProps = (stores: any) => {
  const selectedCountry = settingsSelectors.selectedCountry(stores);
  return {
    isLoading: userLegalSelectors.isLoading(stores),
    selectedCountry,
    acceptedTacVersion: userLegalSelectors.getHighestTacByCountry(stores, selectedCountry),
    acceptedPrivacyVersion: userLegalSelectors.getHighestPrivacyByCountry(stores, selectedCountry),
    accessToken: userAuthSelectors.getAccessToken(stores),
    skipLegalChange: firebaseSelectors.shouldSkipLegalChange(stores),
  };
};

export class UserLegalCheckNotConnected extends React.Component<Props> {
  componentDidMount() {
    const { selectedCountry, accessToken } = this.props;
    this.props.userLegalActions.updateUserLegalAction(accessToken, selectedCountry);
  }

  componentDidUpdate(prevProps: Props) {
    const { isLoading } = this.props;
    const { isLoading: prevIsLoading } = prevProps;

    if (prevIsLoading && !isLoading) {
      this.checkUserLegal();
    }
  }

  checkUserLegal = async () => {
    const {
      selectedCountry,
      acceptedTacVersion,
      acceptedPrivacyVersion,
      onConfirm,
      navigationActions,
      skipLegalChange,
    } = this.props;
    const missingConsent = acceptedTacVersion === '0.0.0' || acceptedPrivacyVersion === '0.0.0';
    const currentTacVersion = docs().terms[selectedCountry]?.version || '';
    const currentPrivacyVersion = docs().privacy[selectedCountry]?.version || '';
    const isTacUpdated = currentTacVersion !== acceptedTacVersion;
    const isPrivacyUpdated = currentPrivacyVersion !== acceptedPrivacyVersion;
    const noDocumentUpdated = !isTacUpdated && !isPrivacyUpdated;

    if (missingConsent) {
      // This happens when the user switches to a new country where he didn't accept the
      // terms and conditions + privacy policy of that country at all
      navigationActions.reset({
        index: 0,
        actions: [
          getNavigationActions().toTerms('screen', {
            onAccept: onConfirm,
          }),
        ],
      });
    } else if (noDocumentUpdated || skipLegalChange) {
      await onConfirm();
    } else {
      navigationActions.reset({
        index: 0,
        actions: [
          getNavigationActions().toLegalChange('screen', {
            onConfirm,
            data: {
              tac: {
                isUpdated: isTacUpdated,
                currentVersion: currentTacVersion,
                acceptedVersion: acceptedTacVersion,
              },
              privacy: {
                isUpdated: isPrivacyUpdated,
                currentVersion: currentPrivacyVersion,
                acceptedVersion: acceptedPrivacyVersion,
              },
            },
          }),
        ],
      });
    }
  };

  render() {
    return <Loading />;
  }
}
export const UserLegalCheck = compose<any>(
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps),
)(UserLegalCheckNotConnected);
