import React from 'react';
import { Link } from 'native-base';

import { Text } from '../Text';
import { Icon } from '../Icon';

import { Props } from './types';

/**
 * text component with touchable functionality
 */

export const TextLink: React.FC<Props> = ({
  variant,
  text,
  color,
  isUnderlined,
  iconProps,
  ...rest
}): JSX.Element => {
  return (
    <Link {...rest}>
      <Text variant={variant} value={text} color={color} underline={isUnderlined} />
      {iconProps && <Icon {...iconProps} />}
    </Link>
  );
};

export default TextLink;
