import React from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '../core';

type Props = {
  children: React.ReactNode;
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    marginVertical: 45,
    marginHorizontal: 28,
  },
});
export const Sidebar = ({ children }: Props): JSX.Element => {
  return (
    <Box style={styles.container}>
      <Box>{children}</Box>
    </Box>
  );
};
