import { $Keys } from 'utility-types';
import * as React from 'react';
import Config from 'react-native-config';
import { User } from '@taxfix/taxfix-sdk';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CountryCodes } from '@taxfix/types';

import {
  BannerSize,
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
} from '../components/notification-banner-legacy';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import {
  actions as userAuthActions,
  selectors as userAuthSelectors,
} from '../stores/modules/user-auth';

type NotificationBannerType = $Keys<typeof NotificationBannerLegacyType>;
type Props = {
  isError?: boolean;
  onEmailSent?: () => void;
  shouldPoll?: boolean;
  selectedCountry: CountryCodes;
  markEmailAddressConfirmed: typeof userAuthActions.markEmailAddressConfirmed;
  isAuthenticated: boolean;
  isEmailConfirmed: boolean;
  accessToken: string;
  userId: number | null | undefined;
  userEmail: string | null | undefined;
  fallbackElement?: React.ReactElement<any>;
  isNewUser?: boolean;
  bannerSize?: BannerSize;
};
type State = {
  bannerType: NotificationBannerType;
};

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
  isAuthenticated: userAuthSelectors.isAuthenticated(stores),
  isEmailConfirmed: userAuthSelectors.isEmailAddressConfirmed(stores),
  accessToken: userAuthSelectors.getAccessToken(stores),
  userId: userAuthSelectors.getUserId(stores),
  userEmail: userAuthSelectors.getEmailAddress(stores),
});

const mapDispatchToProps = (dispatch: any) => ({
  markEmailAddressConfirmed: bindActionCreators(
    userAuthActions.markEmailAddressConfirmed,
    dispatch,
  ),
});

class UndecoratedEmailConfirmationBannerContainer extends React.Component<Props, State> {
  state: any = {
    bannerType: this.props.isNewUser
      ? NotificationBannerLegacyType.EmailConfirmation
      : NotificationBannerLegacyType.EmailConfirmationPending,
  };

  fetchInterval = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { shouldPoll, isAuthenticated, isEmailConfirmed, selectedCountry } = this.props;
    const intervalFreq = selectedCountry === CountryCodes.IT ? 6000 : 3000;

    if (shouldPoll && isAuthenticated && !isEmailConfirmed) {
      // @ts-ignore
      this.fetchInterval = setInterval(this.checkEmailConfirmation, intervalFreq);
    }
  }

  componentWillUnmount() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  checkEmailConfirmation = () => {
    const { accessToken, selectedCountry, markEmailAddressConfirmed } = this.props;
    User.current(Config.API_BASE_URL, accessToken, {
      countryCode: selectedCountry,
    }).then(
      (user) => {
        if (user.confirmed) {
          markEmailAddressConfirmed(new Date(user.confirmed));
          clearInterval(this.fetchInterval);
        } else if (this.state.bannerType === NotificationBannerLegacyType.ConnectionFailed) {
          this.setState({
            // @ts-ignore
            bannerType: NotificationBannerLegacyType.EmailConfirmationPending,
          });
        }
      },
      () => {
        this.setState({
          // @ts-ignore
          bannerType: NotificationBannerLegacyType.ConnectionFailed,
        });
      },
    );
  };

  handleEmailSent = () => {
    if (this.props.onEmailSent) {
      this.props.onEmailSent();
    }
  };

  handleRequest = () => {
    const { accessToken, userId, selectedCountry } = this.props;
    Analytics.log(AnalyticsEvent.emailResendInitiated);
    this.setState({
      // @ts-ignore
      bannerType: NotificationBannerLegacyType.Processing,
    });
    User.resendConfirmation(Config.API_BASE_URL, accessToken, {
      // @ts-ignore
      id: userId,
      taxCountry: selectedCountry,
    })
      .then(() =>
        this.setState(
          {
            // @ts-ignore
            bannerType: NotificationBannerLegacyType.EmailConfirmationRequested,
          },
          this.handleEmailSent,
        ),
      )
      .catch(() =>
        this.setState({
          // @ts-ignore
          bannerType: NotificationBannerLegacyType.EmailConfirmationPending,
        }),
      );
  };

  render() {
    const {
      isAuthenticated,
      isEmailConfirmed,
      userEmail,
      isError = false,
      fallbackElement = null,
      bannerSize,
    } = this.props;

    if (!isAuthenticated || isEmailConfirmed) {
      return fallbackElement;
    }

    const callback =
      this.state.bannerType === NotificationBannerLegacyType.EmailConfirmationPending ||
      this.state.bannerType === NotificationBannerLegacyType.EmailConfirmation
        ? {
            onAction: this.handleRequest,
          }
        : {};
    return (
      <NotificationBannerLegacy
        notificationType={this.state.bannerType}
        values={{
          email: userEmail,
        }}
        isError={isError}
        bannerSize={bannerSize}
        {...callback}
      />
    );
  }
}

export const EmailConfirmationBannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UndecoratedEmailConfirmationBannerContainer);
