import React from 'react';
import { StyleSheet, Pressable } from 'react-native';

import { Box, BodyPrimaryTextStyleBold, Image, theme } from '../core';

type Props = {
  translationId: string;
  iconName?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  onPress?: () => any;
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 12.5,
    paddingRight: 10,
  },
  iconContainer: {
    justifyContent: 'center',
    width: 20,
    height: 20,
    marginHorizontal: 10,
  },
  iconActive: {
    tintColor: theme.color.primary,
  },
  textWithoutIcon: {
    marginLeft: 40,
  },
  textActive: {
    color: theme.color.primary,
  },
  textDisabled: {
    color: theme.color.disabledText,
  },
});
export const MenuItem = ({
  translationId,
  iconName,
  isActive,
  isDisabled,
  children,
  onPress,
}: Props) => {
  return (
    <Pressable onPress={onPress} disabled={isDisabled} style={styles.container}>
      {({ pressed }) => (
        <>
          {iconName && (
            <Box style={styles.iconContainer}>
              <Image name={iconName} style={isActive || pressed ? styles.iconActive : undefined} />
            </Box>
          )}
          {children && <Box style={styles.iconContainer}>{children}</Box>}
          <BodyPrimaryTextStyleBold
            id={translationId}
            style={[
              iconName || children ? {} : styles.textWithoutIcon,
              isActive || pressed ? styles.textActive : {},
              isDisabled ? styles.textDisabled : {},
            ]}
          />
        </>
      )}
    </Pressable>
  );
};
