import { EmitterSubscription, NativeAppEventEmitter } from 'react-native';

import { NativeModules } from '../nativeModules';

type UpdateHandler = (emailAddress: string) => void;

const subscribe = (updateHandler: UpdateHandler): EmitterSubscription | undefined => {
  if (!NativeAppEventEmitter) return undefined;
  const subscription = NativeAppEventEmitter.addListener('User.EmailAddress', updateHandler);
  const native = NativeModules.UserEventEmitter;

  if (native) {
    native.ping();
  }

  return subscription;
};

const unsubscribe = (subscription: EmitterSubscription): void => {
  if (subscription) subscription.remove();
};

const ResponsesEventEmitter = {
  subscribe,
  unsubscribe,
};
export default ResponsesEventEmitter;
