import * as React from 'react';
import { ScrollView } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useBreakpointValue } from 'native-base';

import { Container, NavButton, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import { State } from '../../../stores/store/initial';
import {
  DigitalCafSubCategory,
  translationInfoForDocuments,
  getTranslationKey,
  digitalCafDocsInfoTranslationKeys,
  DocumentUploadMode,
} from '../../../common/constants-it';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import {
  DocumentBunches,
  selectors as requiredDocumentsSelectors,
} from '../../../stores/modules/required-documents';
import { selectors as settingsSelectors } from '../../../stores/modules/settings';
import { Document, DocumentsList } from '../../components/digital-caf-documents/documents-list';

type Props = {
  category: DigitalCafSubCategory;
  taxYear: number;
  taxSeason: number;
  docBunches: DocumentBunches;
  customLinkHandler: (arg0: string) => void;
  screenMode: DocumentUploadMode;
} & WithNavigation;

const mapStateToProps = (state: State) => ({
  taxYear: settingsSelectors.selectedYear(state),
  taxSeason: settingsSelectors.selectedYear(state)! + 1,
  docBunches: requiredDocumentsSelectors.getDocumentBunches(state),
});

const DigitalCafSubCategoryUndecorated = ({
  category,
  taxYear,
  taxSeason,
  docBunches,
  navigationActions,
  customLinkHandler,
  screenMode,
}: Props): JSX.Element => {
  const isShortScreen = useBreakpointValue({ base: true, md: false });
  const titleFontStyle = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  const paddingXContainer = useBreakpointValue({ base: '24px', md: '20px', lg: '0px' });

  const translationKeys =
    screenMode === DocumentUploadMode.INCOME_STEP
      ? {
          title: 'it.documents.upload.others.title.income',
          subtitle: 'it.documents.upload.others.subtitle.income',
        }
      : {
          title: 'it.documents.upload.others.title.expense',
          subtitle: 'it.documents.upload.others.subtitle.expense',
        };

  const { getTranslationText } = useItalyIntl();
  const toInformation = (translationKey: string) => () =>
    navigationActions.toInformation('modal', {
      isMarkdown: true,
      translationKey,
    });

  const onDocumentPressed = (docType: string) => () => {
    Analytics.log(AnalyticsEvent.receiptCategorySelected, {
      receiptCategory: docType,
    });
    navigationActions.toRequiredDocumentsUpload('screen', {
      categoryKey: category.key,
      requiredDocumentTypes: [docType],
      handleMoreInfoPressed: digitalCafDocsInfoTranslationKeys[docType]
        ? toInformation(digitalCafDocsInfoTranslationKeys[docType])
        : null,
      customLinkHandler,
    });
  };

  const getDocuments = (): Document[] =>
    category.items.reduce((result: Document[], docType) => {
      return result.concat({
        translationKey:
          getTranslationKey(translationInfoForDocuments, docType, 'translationKey') || '',
        translationValues: {
          CUYear: taxYear + 1,
          taxYear,
          taxSeason,
        },
        handleDocumentPressed: onDocumentPressed(docType),
        documentCount: docBunches[docType]?.items.length || 0,
        categoryKey: docType,
      });
    }, []);

  return (
    <Container flex={1} width="100%" justifyContent="center">
      {!isShortScreen && (
        <NavButton
          onPress={navigationActions.back}
          text={getTranslationText('it.back.documents.list')}
        />
      )}

      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          alignContent: 'center',
        }}
        style={{ width: '100%' }}
        keyboardShouldPersistTaps="handled"
      >
        <Container
          width="100%"
          alignSelf="center"
          maxW="900px"
          marginTop={isShortScreen ? '16px' : '40px'}
          paddingX={paddingXContainer}
        >
          <Text
            value={getTranslationText(translationKeys.title)}
            variant={titleFontStyle}
            marginBottom={isShortScreen ? '20px' : '12px'}
          />
          <Text
            value={getTranslationText(translationKeys.subtitle)}
            variant="bodyMBook"
            color="greytones.graphicsDark"
          />
        </Container>
        <Container width="100%" maxW="900px" alignSelf="center">
          <DocumentsList documents={getDocuments()} />
        </Container>
      </ScrollView>
    </Container>
  );
};

export const DigitalCafSubCategoryContainer = compose<any>(
  withNavigation,
  connect(mapStateToProps),
)(DigitalCafSubCategoryUndecorated);
