import React from 'react';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';

import {
  theme,
  TitleText,
  TextInput,
  Box,
  LinkText,
  BodySecondaryText,
} from '../../components/core';

const styles = StyleSheet.create({
  textInputContainer: {
    flex: 1,
    marginTop: 17,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: theme.color.primaryText,
  },
  textInput: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    marginBottom: 8,
    color: theme.color.primaryText,
  },
  inputError: {
    borderBottomColor: theme.color.errorBorderLight,
  },
  label: {
    color: theme.color.primaryText,
  },
});
type Props = {
  value: string | null | undefined;
  onChange: (value: string) => void;
  onOtcResend: () => void;
  isLoading: boolean;
  isValidationError: boolean;
};
export default class OtcCode extends React.Component<Props> {
  inputRef?: TextInput;

  handleFocus = () => {
    this.inputRef?.focus();
  };

  render() {
    const { value, onChange, onOtcResend, isLoading, isValidationError } = this.props;
    const { label, textInputContainer, inputError, textInput } = styles;
    const resendLink = isLoading ? (
      <BodySecondaryText
        style={{
          color: 'grey',
        }}
        id="account.otc-verification.resend"
      />
    ) : (
      <LinkText
        id="account.otc-verification.resend"
        onPress={onOtcResend}
        testId="account.otc-verification.resend"
      />
    );
    return (
      <TouchableWithoutFeedback onPress={this.handleFocus}>
        <View>
          <TitleText id="account.otc-verification.code.label" style={label} />
          <Box flex={1} direction="row" alignHorizontally="end">
            <View style={[textInputContainer, isValidationError ? inputError : {}]}>
              <TextInput
                ref={(ref) => {
                  this.inputRef = ref as any;
                }}
                value={value != null ? value : ''}
                style={textInput}
                keyboardType="numeric"
                autoCapitalize="none"
                autoCorrect={false}
                autoFocus
                selectionColor={theme.color.primary}
                onChangeText={onChange}
                onFocus={this.handleFocus}
              />
            </View>
            <Box alignVertically="end" bottom={1} left={1}>
              {resendLink}
            </Box>
          </Box>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
