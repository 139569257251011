import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { RequiredDocumentsPreviewComponent } from '../../components/required-documents/required-documents-preview';
import { selectors as userAuthSelectors } from '../../../stores/modules/user-auth';

type Document = {
  uri: string;
  contentType?: string;
};
type Props = {
  accessToken: string;
  document: Document;
};

const RequiredDocumentsPreview = (props: Props) => {
  const { document, accessToken } = props;
  return <RequiredDocumentsPreviewComponent document={document} token={accessToken} />;
};

const mapStateToProps = (stores: any) => ({
  accessToken: userAuthSelectors.getAccessToken(stores),
});

const RequiredDocumentsPreviewContainer = compose(connect(mapStateToProps))(
  RequiredDocumentsPreview,
);
export { RequiredDocumentsPreviewContainer };
