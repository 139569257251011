import React, { ComponentType, useState, useEffect } from 'react';
import { Keyboard } from 'react-native';

export function withHiddenOnKeyboard<OwnProps>(
  WrappedComponent: ComponentType<OwnProps>,
): ComponentType<OwnProps> {
  return function HiddenOnKeyboard(props: any) {
    const [isKeyboardVisible, setKeyboardVisible] = useState<boolean>(false);
    useEffect(() => {
      const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => {
        setKeyboardVisible(true);
      });
      const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardVisible(true);
      });
      const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardVisible(false);
      });
      return () => {
        keyboardWillShowListener.remove();
        keyboardDidShowListener.remove();
        keyboardDidHideListener.remove();
      };
    }, []);
    if (isKeyboardVisible) return null;
    return <WrappedComponent {...props} />;
  };
}
