import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import {
  BodyPrimaryText,
  BodyPrimaryTextStyleBold,
  Box,
  Button,
  LinkText,
  theme,
  TinySecondaryText,
} from 'src/components/core';
import { withHiddenOnKeyboard } from 'src/hocs/with-hidden-on-keyboard';
import { WithLegalConditions, withLegalConditions } from 'src/hocs/with-legal-conditions';
import { UseLogin } from 'src/hooks/auth/login-hook';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useSkipCodePushUpdate } from 'src/hooks/skip-code-push-hook';
import { useStartOnboardingHandler } from 'src/hooks/start-onboarding-handler-hook';
import { UserSelectors } from 'src/stores/modules/user';
import { Provider } from 'src/stores/modules/user-auth';
import { ScreenName } from 'src/types/screen-name';

import LinkableText from '../LinkableText';

type LoginProviders = {
  onSuccess: UseLogin['handleSsoLogin'];
  onError: UseLogin['handleError'];
  disabled?: boolean;
  showNoAccountOption?: boolean;
};

export const SsoProvidersLoginUndecored = ({
  onError,
  onSuccess,
  disabled,
  showNoAccountOption,
}: LoginProviders): JSX.Element => {
  const { handleStartOnboarding } = useStartOnboardingHandler();
  const { navigationActions } = useNavigation();
  const isLoading = useSelector(UserSelectors.getLoading);

  // We have to skip code push update
  // because SSO options sets the app state to inactive|background
  // and this makes the update to be installed during login.
  useSkipCodePushUpdate();

  const handleNoAccountPress = () => {
    navigationActions.back();
    handleStartOnboarding();
  };

  return (
    <Box>
      <Box top={2}>
        <Box bottom={1.6} direction="row" alignHorizontally="center" alignVertically="center">
          <Box flex style={{ height: 1, backgroundColor: theme.color.disabledButtonBackground }} />
          <Box flex alignHorizontally="center">
            <BodyPrimaryText id="account.login.sso-options" style={{ lineHeight: 15 }} />
          </Box>
          <Box
            flex
            style={{
              height: 1,
              backgroundColor: theme.color.disabledButtonBackground,
            }}
          />
        </Box>
      </Box>
      <Box direction="row" alignVertically="center" bottom={2.6}></Box>
      {showNoAccountOption && (
        <Box direction="row" alignHorizontally="center">
          <BodyPrimaryText id="account.login.no-account.text" />
          <LinkText
            id="account.login.no-account.link"
            testId="no-account.link.testId"
            onPress={handleNoAccountPress}
          />
        </Box>
      )}
    </Box>
  );
};

export const SsoProvidersLogin = withHiddenOnKeyboard(SsoProvidersLoginUndecored);

export type SignUpProviders = {
  onError: (error: any) => void;
  IRupstreamExperimentActive?: boolean;
};

export const SsoProvidersSignUpUndecored = ({
  onError,
  handleTermsClicked,
}: SignUpProviders & WithLegalConditions): React.ReactElement => {
  const { navigationActions } = useNavigation();
  const dispatch = useDispatch();
  const isLoading = useSelector(UserSelectors.getLoading);

  // We have to skip code push update
  // because SSO options sets the app state to inactive|background
  // and this makes the update to be installed during registration.
  useSkipCodePushUpdate();

  const handleSignupWithEmail = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SignUp,
      buttonName: Provider.email,
    });
    navigationActions.toSignupWithEmail('modal');
  };

  return (
    <Box>
      <Box flex left={1} right={1} direction="column" alignVertically="center" bottom={2.6}>
        <Button
          testId="signup.with.email"
          onPress={handleSignupWithEmail}
          style={{ marginBottom: 10 }}
          disabled={isLoading}
        >
          <BodyPrimaryTextStyleBold
            color={isLoading ? theme.color.greyFillIcon : theme.color.lightText}
            id="account.signup.with.email"
          />
        </Button>
        <LinkableText
          translationKey="account.signup.privacy.policy"
          onPress={handleTermsClicked}
          textComponent={TinySecondaryText}
          linkComponent={LinkText}
          style={{ textAlign: 'center', lineHeight: 16 }}
          linkStyle={{
            fontSize: 12,
          }}
          testId="test.linkable-text.terms"
        />
      </Box>
    </Box>
  );
};

export const SsoProvidersSignUp = withLegalConditions(SsoProvidersSignUpUndecored);
