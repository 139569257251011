import React from 'react';
import { useBreakpointValue, useTheme } from 'native-base';

import { Button, Container, DropzoneWrapperWeb } from 'src/taxfix-components/src';
import { theme } from 'src/components/core';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { allowedFileTypes, NUMBER_OF_ALLOWED_FILES } from '../constants';

import { FooterProps } from './footer';

export const Footer: React.FC<FooterProps> = ({
  reuploadButtonText,
  reuploadButtonVariant = 'secondary',
  onReuploadPress,
  reuploadButtonDisabled,
  continueButtonText,
  continueButtonVariant = 'primary',
  onContinuePress,
  continueButtonDisabled,
  onReceiveAssets,
  onFilesRejected,
  ...otherProps
}) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const footerMaxHeight = useBreakpointValue({ base: '147px', md: '80px' });
  const footerFlexDirection = useBreakpointValue({ base: 'column', md: 'row-reverse' });
  const buttonMarginX = useBreakpointValue({ base: '0px', md: '12px' });

  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();

  return (
    <Container
      style={theme.styles.dropShadow}
      maxHeight={footerMaxHeight}
      backgroundColor={colors.greytones.white}
      alignItems="center"
      justifyContent="center"
      {...otherProps}
    >
      <Container width={'100%'} maxW="900px" paddingX="24px">
        <DropzoneWrapperWeb
          onDropAccepted={onReceiveAssets}
          onDropRejected={onFilesRejected}
          accept={allowedFileTypes}
          maxFiles={NUMBER_OF_ALLOWED_FILES}
          multiple={false}
          noClick={true}
          noDrag={true}
        >
          {({ open }) => (
            <Container
              flexDirection={footerFlexDirection}
              alignItems="center"
              justifyContent="center"
              paddingY="16px"
            >
              {continueButtonText && onContinuePress && (
                <Button
                  variant={continueButtonVariant}
                  title={getTranslationText(continueButtonText)}
                  testID={continueButtonText}
                  onPress={onContinuePress(open)}
                  maxWidth={isSmallScreen ? '100%' : '210px'}
                  marginX={buttonMarginX}
                  disabled={continueButtonDisabled}
                  marginBottom={isSmallScreen && onReuploadPress ? '12px' : '0px'}
                />
              )}
              {reuploadButtonText && onReuploadPress && (
                <Button
                  variant={reuploadButtonVariant}
                  title={getTranslationText(reuploadButtonText)}
                  testID={reuploadButtonText}
                  onPress={onReuploadPress(open)}
                  maxWidth={isSmallScreen ? '100%' : '200px'}
                  marginX={buttonMarginX}
                  disabled={reuploadButtonDisabled}
                />
              )}
            </Container>
          )}
        </DropzoneWrapperWeb>
      </Container>
    </Container>
  );
};
