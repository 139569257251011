import { Alert } from 'react-native';

import { isWeb } from '../utils/platform';
import { getStore } from '../stores/util';
import { actions as overlayActions } from '../stores/modules/overlay';

type ShowAlertArgs = {
  titleText?: string;
  subtitleText?: string;
  cancelable?: boolean;
  buttons?: {
    text: string;
    type?: string;
    style?: 'default' | 'cancel' | 'destructive';
    onPress?: () => any;
  }[];
};
export const showAlert: (arg0: ShowAlertArgs) => void = ({
  titleText,
  subtitleText,
  cancelable = true,
  buttons,
}) => {
  if (isWeb) {
    const store = getStore();
    if (!store) throw new Error('redux store is not available');
    const defaultWebButton = {
      translationKey: 'alert.button.default',
    };
    const webButtons = buttons
      ? buttons.map(({ text, type, onPress }) => ({
          text,
          type,
          onPress,
        }))
      : [defaultWebButton];
    store.dispatch(
      overlayActions.show('AlertOverlay', {
        titleTranslationText: titleText,
        leadTranslationText: subtitleText,
        buttons: webButtons,
      }),
    );
  } else {
    const nativeButtons = buttons
      ? buttons.map(({ text, style, onPress }) => ({
          text,
          style,
          onPress,
        }))
      : undefined;
    Alert.alert(titleText as any, subtitleText, nativeButtons, {
      cancelable,
    });
  }
};
