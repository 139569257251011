import * as React from 'react';
import { Keyboard, Platform } from 'react-native';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { Notifications } from 'src/utils/constants';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import CloseButton from '../../containers/close-button';
import HeaderButton from '../../containers/header-button';
import { NavigationHook, useNavigation } from '../../hooks/navigation-hook';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { getNavigationActions, getBoundNavigationsActions } from '../../routes/config-util';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { LoginContainer } from './login-container';
import { LoginErrorKey } from './Login.types';

export type ActionParams = {
  onConfirm?: () => void;
  errorKey?: LoginErrorKey;
  email?: string;
  webDeviceId?: string;
  showNoAccountOption?: boolean;
};

type Props = Navigation<
  Routes.Login,
  {
    [Routes.Login]: NavigationParams<ActionParams>;
  }
>;

export const getDefaultLoginConfirmHandler =
  (navigation: NavigationHook, selectedCountry: CountryCodes) => () => {
    if (Platform.OS === 'ios') {
      const navigationParams =
        selectedCountry === CountryCodes.DE
          ? {
              navigateForward: () => {
                getBoundNavigationsActions().toUniversalStatus('screen');
              },
              image: Notifications.DE_BASELINE.image,
              title: Notifications.DE_BASELINE.title,
              content: Notifications.DE_BASELINE.content,
              primaryButtonText: Notifications.DE_BASELINE.primaryButtonText,
              floatingButton: true,
            }
          : {
              navigateForward: () => {
                getBoundNavigationsActions().toUniversalStatus('screen');
              },
            };
      navigation.safeResetNavigation([
        getNavigationActions().toUniversalNextRouteAfterOnboardingLogin('screen', navigationParams),
      ]);
    } else {
      navigation.safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
    }
  };

const LoginScreen = ({ route }: Props): JSX.Element => {
  const { onConfirm, errorKey, email, webDeviceId, isModal, showNoAccountOption } = route.params;
  const intl = useIntl();
  const navigation = useNavigation();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const handleDefaultConfirm = getDefaultLoginConfirmHandler(navigation, selectedCountry);
  useNavigationOptions({
    headerTitle: intl.formatMessage({
      id: 'login.title',
    }),
    ...(isModal
      ? {
          headerLeft: () => (
            <HeaderButton left>
              <CloseButton onPress={() => Keyboard.dismiss()} />
            </HeaderButton>
          ),
        }
      : {}),
  });
  return (
    <LoginContainer
      email={email}
      onConfirm={onConfirm || handleDefaultConfirm}
      errorKey={errorKey}
      webDeviceId={webDeviceId}
      showNoAccountOption={showNoAccountOption}
    />
  );
};

export const { action: toLogin, screen: loginScreen } = getRoute<ActionParams>({
  routeName: Routes.Login,
  tracking: AnalyticsEvent.sectionLogIn,
  screenComponent: LoginScreen,
});

export default LoginScreen;
