import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useLargeScreenQuery } from 'src/components/core';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import { Container } from './tailored-experience-intro-container';

export const toTailoredExperienceIntro: Action = navigate(ScreenName.TailoredExperienceIntro, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.TailoredExperienceIntro,
  },
});

export const TailoredExperienceIntro = (): JSX.Element => {
  const isLargeScreen = useLargeScreenQuery();

  useDefaultNavigationOptions(
    isLargeScreen ? { id: 'tailored-experience-intro.nav.title' } : undefined,
    {
      headerBackTitleVisible: false,
    },
  );

  return <Container />;
};
