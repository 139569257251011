import React from 'react';

import { Container, Text, Button, IconButton, icons } from 'src/taxfix-components/src';
import { ButtonProps } from 'src/taxfix-components/src/components/general/Button/types';
import { theme } from 'src/components/core/index';
import Overlay from 'src/components/overlay';

export type PopupOverlay = {
  title: string;
  description: string;
  onClose: () => void;
  buttons: ButtonProps[];
};

export const PopupOverlay: React.FC<PopupOverlay> = ({ title, description, onClose, buttons }) => {
  return (
    <Overlay
      backgroundColor={theme.color.overlay}
      style={{ justifyContent: 'center' }}
      appearFromTop
      onCloseComplete={onClose}
      justifyContent="center"
    >
      {() => (
        <Container alignItems="center" alignContent="center">
          <Container
            position="relative"
            bg="greytones.white"
            borderRadius="12px"
            width={{ base: '348px', md: '600px' }}
            height={{ base: '352px', md: '400px' }}
            paddingX={{ base: '24px', md: '56px' }}
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            <Container position="absolute" top="16px" right="16px">
              <IconButton variant="grey" path={icons.closeSupport} onPress={onClose} size="24px" />
            </Container>
            <Text
              value={title}
              variant={{ base: 'titleSBold', md: 'titleMBold' }}
              textAlign="center"
            />
            <Text
              value={description}
              variant={{ base: 'bodySBook', md: 'bodyMBook' }}
              textAlign="center"
              mt={[24, 24, 32]}
              mb="24px"
            />
            {buttons.map((btnProps: ButtonProps, index: number) => (
              <Button {...btnProps} key={index.toString()} title={btnProps.title} />
            ))}
          </Container>
        </Container>
      )}
    </Overlay>
  );
};
