import React from 'react';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';

import { theme, TitleText, TextInput, Box } from '../core';
import { testAccessibility } from '../../common/testID';
import { isWeb } from '../../utils/platform';

import ErrorMessage from './ErrorMessage';

const styles = StyleSheet.create({
  textInputContainer: {
    marginTop: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
  },
  textInputContainerWeb: {
    paddingHorizontal: 10,
  },
  textInput: {
    paddingTop: 7,
    paddingBottom: 0,
    paddingHorizontal: 0,
    marginBottom: 8,
  },
  active: {
    borderBottomColor: theme.color.primaryText,
    borderBottomWidth: 1,
  },
});

export type Variant = 'light-background' | 'dark-background' | 'italy';
type Props = {
  value?: string | null;
  errorKey?: string | null;
  hasError?: boolean;
  variant?: Variant;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmitEditing?: () => void;
  titleKey?: string;
  testId?: string;
};

const stylesForVariant = (variant: Variant, hasError: boolean) => {
  const col = theme.color;

  if (variant === 'dark-background' && !hasError) {
    return {
      label: {
        color: col.primaryBackgroundText,
      },
      input: {
        color: col.lightText,
      },
      border: {
        borderColor: col.primaryBackgroundText,
      },
      error: {},
    };
  }

  if (variant === 'dark-background' && hasError) {
    return {
      label: {
        color: col.errorTextDark,
      },
      input: {
        color: col.lightText,
      },
      border: {
        borderColor: col.errorBorderLight,
      },
      error: {
        color: col.errorTextDark,
      },
    };
  }

  if (variant === 'light-background' && !hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.border,
      },
      error: {},
    };
  }

  if (variant === 'light-background' && hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.errorBorderLight,
      },
      error: {
        color: col.errorTextLight,
      },
    };
  }

  if (variant === 'italy' && !hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.border,
        backgroundColor: col.background,
      },
      error: {},
    };
  }

  if (variant === 'italy' && hasError) {
    return {
      label: {
        color: col.primaryText,
      },
      input: {
        color: col.primaryText,
      },
      border: {
        borderColor: col.errorBorderLight,
        backgroundColor: col.background,
      },
      error: {
        color: col.errorTextLight,
      },
    };
  }

  return {};
};

type State = {
  focus: boolean;
};

class Name extends React.Component<Props, State> {
  inputRef?: TextInput;

  static defaultProps = {
    titleKey: 'account.firstName.label',
  };

  state = {
    focus: false,
  };

  handleFocus = () => {
    this.setState({
      focus: true,
    });
    this.inputRef?.focus();

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleOnBlur = () => {
    this.setState({
      focus: false,
    });
    this.inputRef?.blur();

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const {
      value = '',
      errorKey,
      hasError = false,
      variant = 'light-background',
      autoFocus = false,
      onChange,
      onSubmitEditing = () => {},
      titleKey,
      testId,
    } = this.props;
    const isErrorState = hasError === true || errorKey != null;
    const { border, error, input, label } = stylesForVariant(variant, isErrorState);
    const errorMessage =
      errorKey != null ? <ErrorMessage errorKey={errorKey} style={error} /> : null;
    const selectionColor =
      variant === 'light-background' ? theme.color.primary : theme.color.lightText;
    const inputComponent = (
      <View>
        <TitleText id={titleKey} style={label} />
        <View
          style={[
            styles.textInputContainer,
            border,
            this.state.focus ? styles.active : {},
            isWeb ? styles.textInputContainerWeb : {},
          ]}
        >
          <TextInput
            ref={(ref) => {
              this.inputRef = ref as any;
            }}
            value={value}
            style={[styles.textInput, input]}
            keyboardType="default"
            autoCorrect={false}
            autoFocus={autoFocus}
            selectionColor={selectionColor}
            onChangeText={onChange}
            onFocus={this.handleFocus}
            onSubmitEditing={onSubmitEditing}
            onBlur={this.handleOnBlur}
            testId={testId ?? 'test.text.account.name'}
            tabIndex={0}
          />
        </View>
        <Box bottom={2}>{errorMessage}</Box>
      </View>
    );
    return isWeb ? (
      inputComponent
    ) : (
      <TouchableWithoutFeedback onPress={this.handleFocus} {...testAccessibility()}>
        {inputComponent}
      </TouchableWithoutFeedback>
    );
  }
}

export default Name;
