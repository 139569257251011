import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Button } from '../../general/Button';
import { Container } from '../../layout/Container';

import { Props } from './types';

export const Footer: React.FC<Props> = ({
  primaryButtonTitle,
  secondaryButtonTitle,
  onPressPrimary,
  onPressSecondary,
  isPrimaryButtonDisabled = false,
  isSecondaryButtonDisabled = false,
  primaryButtonProps = {},
  secondaryButtonProps = {},
  isLoading = false,
  ...otherProps
}) => {
  const direction = useBreakpointValue({ base: 'column', lg: 'row-reverse' });
  const buttonSize = useBreakpointValue({ base: 'xl', lg: 'md' });
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  return (
    <Container
      flexDirection={direction}
      width="100%"
      justifyContent="center"
      borderColor="greytones.graphicsLight"
      borderTopWidth={isLargeScreen ? 'xs' : 0}
      p={0}
      {...otherProps}
    >
      <Button
        width={buttonSize}
        title={primaryButtonTitle}
        onPress={onPressPrimary}
        variant={isPrimaryButtonDisabled ? 'primaryInactive' : 'primary'}
        isDisabled={isPrimaryButtonDisabled}
        m={0}
        isLoading={isLoading}
        {...primaryButtonProps}
      />
      {onPressSecondary && secondaryButtonTitle && (
        <Button
          width={buttonSize}
          title={secondaryButtonTitle}
          onPress={onPressSecondary}
          variant="secondary"
          m={0}
          isDisabled={isSecondaryButtonDisabled}
          {...secondaryButtonProps}
        />
      )}
    </Container>
  );
};
