import Moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { extendMoment, DateRange as MomentDateRange } from 'moment-range';

const moment = extendMoment(Moment as any);

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DE_FORMAT = 'DD.MM.YYYY';

export type DateRange = {
  from: string;
  until: string;
};

export type MomentRange = any;

export const mergeRanges = (ranges: DateRange[]): MomentRange | null => {
  const momentRanges = ranges
    .map((range) => {
      const { from, until } = range;
      return moment.range(
        moment(from, DATE_FORMAT),
        moment(until, DATE_FORMAT).add(1, 'day'), // make end as exclusive
      );
    })
    .sort((a, b) => (a > b ? 1 : -1));

  const mergedMomentRange = momentRanges.reduce((merged, currentRange) => {
    if (isEmpty(merged)) {
      return currentRange;
    }
    return merged.add(currentRange, { adjacent: true }) as MomentDateRange; // returns MomentRange or null
  }, {} as MomentDateRange);
  if (mergedMomentRange) mergedMomentRange.end.subtract(1, 'day');
  return mergedMomentRange;
};

export const rangeSpansWholeYear = (year: number, range: MomentRange | null): boolean => {
  // moment-range does not handle null values
  if (!range) return false;

  const wholeYearRange = moment.range(
    moment(`${year}-01-01`, DATE_FORMAT),
    moment(`${year}-12-31`, DATE_FORMAT),
  );

  return wholeYearRange.isEqual(range);
};

export const weeksElapsedSinceDate = (date: Date): number => {
  const difference = moment
    .duration(moment().diff(moment(date)))
    .asWeeks()
    .toFixed(1);
  return parseFloat(difference);
};
