/* global __DEV__ */
import firebase from 'firebase/app';

import 'firebase/remote-config';
import {
  Configs,
  REMOTE_CONFIG_CACHE_DURATION,
  getDefaultConfig,
  checkLocalConfig,
} from 'src/utils/remote-config-helpers';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { FirebaseRemoteConfigError } from 'src/utils/remote-config-errors';

type RemoteConfigParameter = {
  _source: string;
  _value: string;
};
export const getRemoteConfig = async (): Promise<Configs> => {
  try {
    const remoteConfig: any = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = REMOTE_CONFIG_CACHE_DURATION;
    remoteConfig.settings.isDeveloperModeEnabled = !!__DEV__;
    await remoteConfig.fetchAndActivate();
    const parameters = remoteConfig.getAll();
    const preparedConfig = Object.entries(parameters).reduce((acc: any, [key, parameter]) => {
      const { _value: value } = parameter as unknown as RemoteConfigParameter;

      try {
        acc[key] = JSON.parse(value);
      } catch (e) {
        logger.error(e, {
          message: 'Firebase remote-config has an invalid JSON.',
          key,
          value,
        });
      }

      return acc;
    }, {});

    if (Object.keys(preparedConfig).length === 0) return getDefaultConfig();

    const localConfigJSON = require('../../remote-config/local.json');
    checkLocalConfig(localConfigJSON);

    return { ...preparedConfig, ...localConfigJSON };
  } catch (err) {
    logger.error(new FirebaseRemoteConfigError('Error getting remote config from firebase'));

    return getDefaultConfig();
  }
};
