import React from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { testID } from '../../common/testID';

import { theme } from './theme';
import { bodyPrimaryTextStyle } from './Text';

type Props = Record<string, any>; // TODO: Find a nicer way to extend this Component
//       Tried inheriting from RNTextInput and then
//       using React.cloneElement(super.render(), { overrideProps })
//       but this didn't apply styles correctly :-(

class TextInput extends React.Component<Props> {
  ref: RNTextInput | null | undefined;

  componentDidMount() {
    const { tabIndex } = this.props;

    if (this.ref && tabIndex != null) {
      this.ref.setNativeProps({
        tabindex: tabIndex,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { shouldFocus } = this.props;

    if (prevProps.shouldFocus !== shouldFocus && shouldFocus) {
      this.focus();
    }
  }

  focus() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  blur() {
    if (this.ref) {
      this.ref.blur();
    }
  }

  render() {
    const { style, color = theme.color.primary, testId, tabIndex, ...rest } = this.props;
    return (
      <RNTextInput
        underlineColorAndroid="transparent"
        selectionColor={color}
        ref={(ref) => {
          this.ref = ref;
        }}
        style={[bodyPrimaryTextStyle, style]}
        {...testID(testId)}
        {...rest}
      />
    );
  }
}

export default TextInput;
