import { Pressable, useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, IconButton, Image, Text, icons } from 'src/taxfix-components/src';

import { getImageAsset } from '../Image/utils';

import { Props, SmallScreenViewProps, WideScreenViewProps } from './types';

/**
 * navigation header component holding the route name (string) and navigation actions elements (React.Node)
 */

const SmallScreenView: React.FC<SmallScreenViewProps> = ({
  children,
  textArrow,
  iconSizeArrow = 4,
  onPressArrow,
  onPressHomeLogo,
  showHomeLogoOnSmallScreen,
  ...otherProps
}) => (
  <Container
    flex={1}
    flexDirection="row"
    justifyContent={onPressArrow ? 'center' : 'flex-start'}
    alignItems="center"
    minH="50px"
    borderBottomWidth="1px"
    borderBottomColor="greytones.graphicsLight"
    borderBottomStyle="solid"
    {...otherProps}
  >
    {onPressArrow && (
      <Container position="absolute" left={0}>
        <IconButton
          path={icons.chevronLeft}
          color="brand.brandGreen"
          onPress={onPressArrow}
          size={iconSizeArrow}
          text={textArrow}
          flexDirection="row"
          textVariant="titleXSBold"
          textProps={{ color: 'greytones.textDefault' }}
          iconProps={{ marginRight: '10px' }}
        />
      </Container>
    )}
    {showHomeLogoOnSmallScreen && (
      <Pressable onPress={onPressHomeLogo}>
        <Image path={getImageAsset('logo-svg.taxfix')} width="86px" height="24px" alt="menu-logo" />
      </Pressable>
    )}
    <Container position="absolute" right={0}>
      {children}
    </Container>
  </Container>
);

const WideScreenView: React.FC<WideScreenViewProps> = ({
  children,
  textArrow,
  routeName,
  iconComeBack = icons.arrowLeft,
  onPressArrow,
  onPressHomeButton,
  ...otherProps
}) => (
  <>
    <Container
      flexDirection="row"
      alignItems="center"
      justifyContent={routeName || onPressHomeButton ? 'space-between' : 'flex-end'}
      minH="75px"
      borderBottomWidth="1px"
      borderBottomColor="greytones.graphicsLight"
      borderBottomStyle="solid"
      {...otherProps}
    >
      {onPressHomeButton && (
        <Pressable onPress={onPressHomeButton}>
          <Image
            path={getImageAsset('logo-svg.taxfix')}
            width="86px"
            height="24px"
            alt="menu-logo"
          />
        </Pressable>
      )}

      {routeName && (
        <Container alignItems="center" flex={1}>
          <Text variant="titleXSMedium" value={routeName} color="greytones.textDefault" />
        </Container>
      )}
      <Container flexDirection="row">{children}</Container>
    </Container>
    {onPressArrow && (
      <IconButton
        path={iconComeBack}
        size={3}
        variant="brand"
        marginRight="8px"
        onPress={onPressArrow}
        text={textArrow}
        textVariant="titleXSBold"
        flexDirection="row"
        marginTop="12px"
        textProps={{ color: 'greytones.textDefault' }}
        iconProps={{ marginRight: '10px' }}
        alignSelf="flex-start"
      />
    )}
  </>
);

export const NavHeader: React.FC<Props> = ({
  children,
  routeName,
  iconSizeArrow,
  iconComeBack,
  textArrow,
  onPressArrow,
  onPressHomeButton,
  ...otherProps
}): JSX.Element => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return isSmallScreen ? (
    <SmallScreenView
      iconSizeArrow={iconSizeArrow}
      textArrow={textArrow}
      onPressArrow={onPressArrow}
      {...otherProps}
    >
      {children}
    </SmallScreenView>
  ) : (
    <WideScreenView
      textArrow={textArrow}
      routeName={routeName}
      iconComeBack={iconComeBack}
      onPressArrow={onPressArrow}
      onPressHomeButton={onPressHomeButton}
      {...otherProps}
    >
      {children}
    </WideScreenView>
  );
};
