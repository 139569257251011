import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

type Props = {
  onNext: (evt: Record<string, any>) => void;
  onPrevious: (evt: Record<string, any>) => void;
  onComplete: (evt: Record<string, any>) => void;
  current: number;
  total: number;
};
type State = {
  isOpen: boolean;
};
const styles = StyleSheet.create({
  progress: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  button: {
    flex: 1,
    margin: 10,
    padding: 10,
    backgroundColor: '#333',
    borderRadius: 5,
  },
  buttonText: {
    color: '#eee',
    textAlign: 'center',
  },
  closed: {
    width: 50,
    height: 50,
    position: 'absolute',
    right: 0,
    bottom: 0,
    opacity: 0.5,
    borderRadius: 100,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const Button = ({
  onPress,
  label,
}: {
  onPress: (evt: Record<string, any>) => void;
  label: string;
}) => (
  <TouchableOpacity onPress={onPress} style={styles.button}>
    <Text style={styles.buttonText}>{label}</Text>
  </TouchableOpacity>
);

export class QuestionNavigator extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  handleToggleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { onNext, onPrevious, onComplete, current, total } = this.props;

    if (this.state.isOpen) {
      return (
        <View>
          <Text style={styles.progress}>
            {current} of {total}
          </Text>

          <View style={styles.row}>
            <Button onPress={onComplete} label="Complete" />
            <Button onPress={onPrevious} label="Previous" />
            <Button onPress={onNext} label="Next" />
            <Button onPress={this.handleToggleOpen} label="Close" />
          </View>
        </View>
      );
    }

    return (
      <View style={styles.closed}>
        <Button onPress={this.handleToggleOpen} label="π" />
      </View>
    );
  }
}
