import React from 'react';

import { CommonResultComponent } from './CommonResult';

type Props = {
  onDismiss: () => void;
  onOpenMail: () => any;
};
export const ResultDisappointedComponent = ({ onDismiss, onOpenMail }: Props) => {
  return (
    <CommonResultComponent
      icon="disappointed"
      title="feedback-modal.disappointed.title"
      subtitle="feedback-modal.disappointed.subtitle"
      ctaTranslationKey="feedback-modal.disappointed.cta"
      onCtaPress={onOpenMail}
      onDismiss={onDismiss}
    />
  );
};
