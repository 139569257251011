import jwtDecode from 'jwt-decode';
import _ from 'lodash';

import { Provider } from 'src/stores/modules/user-auth';

export const getProvider = (idToken: string): Provider => {
  const decodedToken = jwtDecode(idToken);

  const provider = _.get(decodedToken, 'firebase.sign_in_provider', '') as string;

  switch (provider) {
    case 'apple.com':
      return Provider.apple;
    case 'google.com':
      return Provider.google;
    default:
      return Provider.email;
  }
};
