import * as React from 'react';
import { StyleSheet, TouchableOpacity, Linking, Platform } from 'react-native';

import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

import { Icon, Box, BodyPrimaryText, BodySecondaryText, theme, BodyTitleText } from './core';
import { StyleProp } from './types';

export type IconColor = 'green' | 'orange' | 'blue' | 'white' | 'transparent';
type LinkClickedEventParams = {
  /* year?: number,
   * taxCountry: 'DE' | 'FR' | 'IT'
   * `year` and `taxCountry` are added automatically (listed here for reference)
   */
  linkName?: string;
  externalURL?: string;
};
export type Link = {
  icon: string;
  iconColor?: IconColor;
  iconBackgroundColor?: IconColor;
  iconStyle?: StyleProp;
  iconRight?: string;
  iconRightColor?: IconColor;
  iconRightBackgroundColor?: IconColor;
  iconRightStyle?: StyleProp;
  url?: string;
  linkTranslationId: string;
  linkTranslationValues?: Record<string, string | number>;
  subTitleTranslationId?: string;
  subTitleTranslationValues?: Record<string, string | number>;
  trackingParams?: LinkClickedEventParams;
  isDisabled?: boolean;
  fontType?: string;
  containerStyle?: StyleProp;
};
type Props = {
  link: Link;
  onPress?: (link: Link) => void;
  iconColor?: string;
};

const getIconColor = (color: IconColor): string => {
  switch (color) {
    case 'orange':
      return theme.color.orangeText;

    case 'blue':
      return theme.color.darkBlueIcon;

    case 'white':
      return theme.color.lightFill;

    case 'green':
    default:
      return theme.color.primary;
  }
};

const getIconBackgroundColor = (color: IconColor): string => {
  switch (color) {
    case 'transparent':
      return 'transparent';

    case 'orange':
      return theme.color.orangeFill;

    case 'blue':
      return theme.color.darkBlueIconBackground;

    case 'white':
      return theme.color.lightFill;

    case 'green':
    default:
      return theme.color.positiveBackground;
  }
};

const getStyles = (
  iconBackgroundColor: IconColor,
  iconRightBackgroundColor: IconColor,
  isDisabled: boolean,
  iconStyle: StyleProp,
  iconRightStyle: StyleProp,
  containerStyle: StyleProp = {},
) =>
  StyleSheet.create({
    container: {
      borderColor: theme.color.fill,
      borderRadius: 2,
      borderWidth: 1,
      ...Platform.select({
        ios: { ...theme.styles.dropShadow },
        android: {},
      }),
      opacity: isDisabled ? 0.5 : 1,
      shadowColor: theme.color.secondaryText,
      ...containerStyle,
    },
    icon: {
      height: 60,
      width: 60,
      backgroundColor: getIconBackgroundColor(iconBackgroundColor),
      borderRadius: 30,
      ...iconStyle,
    },
    iconRight: {
      height: 60,
      backgroundColor: getIconBackgroundColor(iconRightBackgroundColor),
      ...iconRightStyle,
    },
  });

const LinkButton = ({
  link,
  link: {
    icon,
    iconColor,
    iconBackgroundColor = 'green',
    iconStyle = {},
    iconRight,
    iconRightColor,
    iconRightBackgroundColor = 'green',
    iconRightStyle = {},
    url,
    linkTranslationId,
    trackingParams = {},
    isDisabled = false,
    linkTranslationValues,
    subTitleTranslationId,
    subTitleTranslationValues,
    fontType,
    containerStyle = {},
  },
  onPress,
}: Props): JSX.Element => {
  const handleOnPress = () => {
    if (onPress) {
      onPress(link);
    } else {
      if (trackingParams) {
        Analytics.log(AnalyticsEvent.linkClicked, { ...trackingParams, externalUrl: url });
      }

      if (url) Linking.openURL(url);
    }
  };

  const styles = getStyles(
    iconBackgroundColor,
    iconRightBackgroundColor,
    isDisabled,
    iconStyle,
    iconRightStyle,
    containerStyle,
  );
  let titleComponent;

  switch (fontType) {
    case 'title':
      titleComponent = (
        <BodyTitleText
          id={linkTranslationId}
          values={linkTranslationValues}
          testId={linkTranslationId}
        />
      );
      break;

    default:
      titleComponent = <BodyPrimaryText id={linkTranslationId} values={linkTranslationValues} />;
  }

  return (
    <TouchableOpacity disabled={isDisabled} onPress={handleOnPress}>
      <Box flex alignVertically="center" style={styles.container}>
        <Box flex direction="row" top={1} bottom={1} left={1.5} right={2}>
          <Box style={styles.icon}>
            <Box flex alignVertically="center" alignHorizontally="center">
              <Icon name={icon} tintColor={iconColor ? getIconColor(iconColor) : ''} />
            </Box>
          </Box>
          <Box flex alignVertically="center" left={1.5}>
            {titleComponent}
            {subTitleTranslationId && (
              <BodySecondaryText id={subTitleTranslationId} values={subTitleTranslationValues} />
            )}
          </Box>
          {iconRight && (
            <Box style={styles.iconRight}>
              <Box flex alignVertically="center" alignHorizontally="center">
                <Icon
                  name={iconRight}
                  tintColor={iconRightColor ? getIconColor(iconRightColor) : ''}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export { LinkButton };
