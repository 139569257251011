import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DocumentPicker from 'react-native-document-picker';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { flagsQuestionIds } from 'src/common/constants-it';
import { WithPrefillCreation, withPrefillCreation } from 'src/hocs/with-prefill-creation';
import {
  NavigateToNext,
  withPrefillRoute,
} from 'src/screens/containers/prefill/with-prefill-route';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import { actions as PrefillActions } from 'src/stores/modules/prefill';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { useFilesUpload, useOverlayButtons, useQuizmasterLight } from 'src/_italy/_hooks';

import { pickFile } from '../../../services/document-picker';
import {
  AllowedDocTypes,
  AllowedImageTypes,
  FileResponses,
  NativeFileResponse,
  RouteParams,
  Screen,
} from '../types';
import { isFileValid } from '../utils';

import { UploadComponent } from './upload-component';

type Props = WithPrefillCreation &
  RouteParams & {
    navigateToNext: NavigateToNext;
    documentType: AllowedDocTypes;
  };

const UploadContainer: React.FC<Props> = ({
  createPrefill,
  onNext,
  navigateToNext,
  documentType,
}) => {
  const dispatch = useDispatch();
  const prefillActions = useMemo(() => bindActionCreators(PrefillActions, dispatch), [dispatch]);
  const overlayActions = useMemo(() => bindActionCreators(OverlayActions, dispatch), [dispatch]);

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId) as number;
  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;

  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.prefillUploadSkipped]);
  const { files, errorMessage, isLoading, onFilesSelected, onFilesRejected, onUploadToServer } =
    useFilesUpload({
      overlayActions,
      quizmasterLight,
      navigateToNext,
      createPrefill,
      onNext,
    });

  const [currentScreen, setCurrentScreen] = React.useState(Screen.UPLOAD);
  const [filePickingError, setFilePickingError] = React.useState<string | null>(null);
  const [isReuploadClicked, setIsReuploadClicked] = useState(false);

  const isUploadScreen = currentScreen === Screen.UPLOAD;

  useEffect(() => {
    // we're fetching the latest prefill before checking the status in handleUpload
    try {
      prefillActions.retrieveLatestPrefill(accessToken, userId, selectedYear);
    } catch (e) {
      // we swallow the error since the prefill.getAll call is not stable atm
      // we try to just populate the store before we call prefill.create
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReceiveAssets = useCallback(
    (files: FileResponses) => {
      Analytics.log(AnalyticsEvent.docUploadInitiated, { documentType: documentType });
      if (files.length === 0) {
        return;
      }
      onFilesSelected(files);

      if (!isReuploadClicked) {
        setCurrentScreen(Screen.PREVIEW);
      }
    },
    [isReuploadClicked, onFilesSelected],
  );

  const onReceiveMobileAssets = useCallback(
    (response: NativeFileResponse | NativeFileResponse[] | null) => {
      const result = Array.isArray(response) ? response : [response];
      const { hasError, isCanceled } = isFileValid(result);
      if (hasError) {
        setFilePickingError('identity-upload.errors.permissions-error');
        return;
      }

      if (isCanceled) {
        return; // do nothing
      }

      setFilePickingError('');
      onReceiveAssets(result as NativeFileResponse[]);
    },
    [onReceiveAssets],
  );

  const onOpenFileBrowser = useCallback(async () => {
    try {
      const filesData = await pickFile(DocumentPicker.types.images as AllowedImageTypes);
      overlayActions.hide();
      onReceiveMobileAssets(filesData);
    } catch (err) {
      setFilePickingError('identity-upload.errors.permissions-error');
    }
  }, [onReceiveMobileAssets, overlayActions]);

  const overlayButtons = useOverlayButtons({
    onReceiveMobileAssets,
    onOpenFileBrowser,
  });

  const onContinuePress = (onPlatformSpecificClick: () => void) => async () => {
    if (isUploadScreen) {
      setIsReuploadClicked(false);
      onPlatformSpecificClick();
      return;
    }

    if (files.length === 0) {
      return;
    }

    // - starting from here and down the chain our solution supports only
    // one file because there was no business requirement so far to
    // support multiple files
    // - our solution up the chain already supports multiple files
    const [uri] = files;
    await onUploadToServer(uri);
  };

  const onReuploadPress = (platformSpecificHandler: () => void) => () => {
    Analytics.log(AnalyticsEvent.docUploadAgain, { documentType: documentType });
    setIsReuploadClicked(true);
    platformSpecificHandler();
  };

  return (
    <UploadComponent
      files={files}
      errorMessage={filePickingError || errorMessage}
      overlayButtons={overlayButtons}
      documentType={documentType}
      isUploadScreen={isUploadScreen}
      isLoading={isLoading}
      onContinuePress={onContinuePress}
      onReuploadPress={onReuploadPress}
      onReceiveAssets={onReceiveAssets}
      onFilesRejected={onFilesRejected}
    />
  );
};

type DecoratedProps = React.FC<RouteParams & { documentType: AllowedDocTypes }>;

export const UploadContainerDecorated = compose<DecoratedProps>(
  withPrefillRoute,
  withPrefillCreation,
)(UploadContainer);
