import { DebugMenuOptions, MenuOption } from './types';

export function buildFirebaseRemoteConfigOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handleFirebaseRemoteConfig = () => {
    navigationActions.toFirebaseRemoteConfig('screen');
  };

  return {
    text: 'Firebase Remote Config',
    onPress: handleFirebaseRemoteConfig,
    showArrow: true,
  };
}
