import * as React from 'react';
import { BackHandler } from 'react-native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/core';

import { useNavigationOptions } from '../routes/hooks/navigation-options-hook';
import { getCurrentRouteOptions } from '../services/navigation-options';

export type BackNavigationControlMethods = {
  enableGoBack: () => void;
  disableGoBack: () => void;
};
export function useBackNavigationControl(): BackNavigationControlMethods {
  const navigation = useNavigation();
  const [options, setOptions] = React.useState<StackNavigationOptions>({});
  const [isGoBackDisabled, setGoBackDisabled] = React.useState<boolean>(false);
  const [prevHeaderLeft, setPrevHeaderLeft] = React.useState<React.ReactNode | typeof undefined>(
    undefined,
  );

  const onBackButtonPressAndroid = () => isGoBackDisabled;

  React.useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackButtonPressAndroid);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackButtonPressAndroid);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useNavigationOptions(options);

  const enableGoBack = () => {
    setGoBackDisabled(false);
    setOptions({
      gestureEnabled: true,
      headerLeft: prevHeaderLeft ? () => prevHeaderLeft : undefined,
    });
  };

  const disableGoBack = () => {
    const currentOptions = getCurrentRouteOptions(navigation);
    setPrevHeaderLeft(currentOptions.headerLeft);
    setGoBackDisabled(true);
    setOptions({
      gestureEnabled: false,
      headerLeft: () => null,
    });
  };

  return {
    enableGoBack,
    disableGoBack,
  };
}
