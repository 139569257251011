import React, { useCallback, useState, forwardRef } from 'react';
import { TextInputProps } from 'react-native';
import { Input, View, Pressable, useTheme } from 'native-base';

import { supportCenterIcons } from 'src/taxfix-components/src/Icons';

import { Icon } from '../Icon';

export type SearchProps = TextInputProps & {
  onSearch: (text: string) => void;
  onDeleteText: () => void;
};

export const Search = forwardRef(({ onSearch, onDeleteText, ...rest }: SearchProps, ref) => {
  const [searchText, setSearchText] = useState('');
  const { colors } = useTheme();
  const handleChange = useCallback(
    (text: string) => {
      setSearchText(text);
      onSearch(text);
    },
    [searchText],
  );

  const handleDelete = useCallback(() => {
    onDeleteText();
    setSearchText('');
  }, []);

  return (
    <View
      backgroundColor={colors.greytones.background}
      borderRadius="25px"
      flexDirection="row"
      alignItems="center"
    >
      <Icon
        marginLeft="20px"
        size="15px"
        path={[supportCenterIcons.search]}
        color={colors.greytones.graphicsDark}
      />
      <Input
        padding="10px"
        flex={1}
        ref={ref}
        borderWidth={0}
        value={searchText}
        selectionColor={colors.brand.brandGreen}
        onChangeText={handleChange}
        fontSize={14}
        {...rest}
      />
      {searchText !== '' && (
        <Pressable
          onPress={handleDelete}
          backgroundColor={colors.greytones.graphicsDark}
          height="20px"
          width="20px"
          borderRadius="10px"
          mr="20px"
          justifyContent="center"
          alignItems="center"
        >
          <Icon
            size="10px"
            alignSelf="center"
            path={[supportCenterIcons.delete]}
            tintColor={colors.greytones.graphicsDark}
            color={colors.greytones.graphicsLight}
          />
        </Pressable>
      )}
    </View>
  );
});
