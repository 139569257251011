import React from 'react';
import { TouchableOpacity } from 'react-native';

import { SmallPrimaryText, SmallSecondaryText, Box, Icon } from '../../../../components/core';
import { testAccessibility } from '../../../../common/testID';

type Props = {
  iconName: string;
  iconColor?: string;
  address: string;
  onPress: () => any;
  testId?: string;
};

const AddressListItem: React.FC<Props> = ({ iconName, iconColor, address, onPress, testId }) => {
  const parts = address
    .split(',', 4)
    .map((part) => part.trim())
    .filter(Boolean);
  let partA = parts.slice(0, 1).join(', ');
  let partB = parts.slice(1, 4).join(', ');

  if (parts.length <= 2) {
    [partA, partB] = parts;
  }

  return (
    <TouchableOpacity onPress={onPress} {...testAccessibility()}>
      <Box direction="row" top={1} left={0} right={0} bottom={1} alignVertically="center">
        <Box
          alignVertically="center"
          alignHorizontally="center"
          style={{
            width: 40,
            height: 40,
          }}
        >
          <Icon
            name={iconName}
            tintColor={iconColor}
            resizeMode="contain"
            style={{
              width: 24,
              height: 24,
            }}
          />
        </Box>
        <Box flex testId={testId}>
          {partA && <SmallPrimaryText numberOfLines={1}>{partA}</SmallPrimaryText>}
          {partB && <SmallSecondaryText numberOfLines={1}>{partB}</SmallSecondaryText>}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

AddressListItem.defaultProps = {
  iconName: 'mono.icon-nav-location-other',
};
export default AddressListItem;
