import { CountryCodes } from '@taxfix/types';

import { getNavigationActions } from '../../../../routes/config-util';
import { showAlert } from '../../../../services/alert';
import { ButtonType } from '../../../../components/alert-overlay';

import { DebugMenuOptions, MenuOption } from './types';

export function buildStripePaymentOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions, selectedCountry } = props;

  if (selectedCountry === CountryCodes.DE) {
    return null;
  }

  const handlePressPayment = () => {
    const customer = {
      lastName: 'Debug',
      firstName: 'Sir',
      street: 'Debugstr',
      number: '1',
      zipcode: '12345',
      city: 'Debug',
      countryCode: 'IT',
    };

    const getPaymentRoute = () => {
      if (selectedCountry === CountryCodes.IT) {
        return getNavigationActions().toItalyPayment;
      } else {
        return getNavigationActions().toPayment;
      }
    };

    const handleAlertButton = ({ amount, taxId }: { amount: number; taxId?: string }) => {
      const params = {
        onConfirm: async () => {
          const toPaymentRoute = getPaymentRoute();

          navigationActions.reset({
            index: 0,
            actions: [
              toPaymentRoute('screen', {
                taxId,
                taxResult: {
                  amount,
                  result: 'refund',
                  currency: 'EUR',
                  stale: false,
                  break_down: {},
                },
                customer,
                nextNavigationAction: () => {},
              }),
            ],
          });
        },
      };
      navigationActions.reset({
        index: 0,
        actions: [getNavigationActions().toLock('screen', params)],
      });
    };

    showAlert({
      titleText: 'Payment Options',
      subtitleText: 'choose',
      buttons: [
        {
          text: 'Required withTaxId 123',
          onPress: () =>
            handleAlertButton({
              amount: 1000,
              taxId: '123',
            }),
        },
        {
          text: 'Required',
          onPress: () =>
            handleAlertButton({
              amount: 1000,
            }),
        },
        {
          text: 'Free',
          onPress: () =>
            handleAlertButton({
              amount: 0,
            }),
        },
        {
          text: 'Cancel',
          type: ButtonType.secondary,
        },
      ],
    });
  };

  return {
    text: 'Payment(Stripe)',
    onPress: handlePressPayment,
    showArrow: true,
  };
}
