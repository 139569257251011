import { Pressable, useTheme } from 'native-base';
import React from 'react';

import { Container, Icon, Text, icons } from 'src/taxfix-components/src';

import { ContactLinkProps } from './types';

export const ContactLink: React.FC<ContactLinkProps> = ({
  handleLink,
  linkMessage,
  borderInTheTop,
  ...otherProps
}) => {
  const {
    colors: { greytones: color },
    borderWidths: bw,
  } = useTheme();

  return (
    <Pressable onPress={handleLink} {...otherProps}>
      <Container px={2}>
        <Container
          borderTopColor={borderInTheTop ? color.graphicsLight : undefined}
          borderTopWidth={borderInTheTop ? bw.xs : undefined}
          py={2}
        >
          <Container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Text variant="bodyMBook" value={linkMessage} color={color.textDefault} />
            <Icon path={icons.chevronRight} size={3} color={color.textDefault} />
          </Container>
        </Container>
      </Container>
    </Pressable>
  );
};
