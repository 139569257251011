import React, { useCallback, useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/core';
import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';
import Config from 'react-native-config';
import { SubmissionReviewStatusIT, States as SubmissionStates } from '@taxfix/submissions-types';
import { TreeNode } from '@taxfix/quizmaster/dist/types';
import { Prefill as PrefillTypes } from '@taxfix/italy-types';

import { useFetch } from 'src/_italy/modello-redditi-sign/useFetchDocument';
import { useNavigation } from 'src/hooks/navigation-hook';
import { FlowStage, CardSetting } from 'src/_italy/_hooks/use-dashboard';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { PrefillStates } from 'src/types/prefills';
import { ScreenName } from 'src/types/screen-name';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useDashboardNavigationActions } from 'src/_italy/_hooks/use-dashboard-navigation-resolver';
import type { AppDispatch, State as RootState } from 'src/stores/store/initial';
import { usePreseasonDashboardStage } from 'src/_italy/_hooks/use-dashboard-stage-preseason';
import { getNavigationActions, safeResetNavigation } from 'src/routes/config-util';

import { actions as requiredDocumentsActions } from '../../stores/modules/required-documents';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import {
  selectors as prefillSelectors,
  actions as prefillActions,
} from '../../stores/modules/prefill';
import { actions as overlayActions } from '../../stores/modules/overlay';
import { getDefaultTaxYear } from '../../services/country-and-year';
import { Dashboard as DashboardComponent } from '../components/dashboard/dashboard';
import {
  selectors as submissionSelectors,
  actions as submissionActions,
} from '../../stores/modules/submission';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import {
  QuizmasterLight,
  withQuizmasterLight,
  getQuizmasterLight,
  getNodesByIds,
} from '../../utils/with-quizmaster-light';
import {
  flagsQuestionIds,
  OccupationAnswersKeys,
  ProductBundleValues,
  wayQuestionIds,
} from '../../common/constants-it';
import { selectors as internetConnectionSelectors } from '../../stores/modules/internet-connection';
import { selectors as firebaseSelectors } from '../../stores/modules/remote-config-firebase';
import { getQuestionStores } from '../../stores-legacy/util';
import { useChatPopup } from '../../_italy/_hooks';
import {
  withSubmissionPDFDownload,
  WithSubmissionPDFDownloadProps,
} from '../../hocs/with-submission-pdf-download';

import { useFetchDownloadPrefillDocuments } from './use-download-prefills-documents';

type Props = WithSubmissionPDFDownloadProps & {
  quizmasterLight: QuizmasterLight;
};

const logButtonPress = (buttonName: string, sectionName: string) => {
  Analytics.log(AnalyticsEvent.buttonPressed, {
    screenName: ScreenName.ITDashboard,
    buttonName,
    sectionName,
  });
};

const DashboardUndecorated = ({ quizmasterLight, fetchAndOpenPDF, submissions }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { handleOpenChatPopup } = useChatPopup();
  const dispatch = useDispatch<AppDispatch>();

  const { navigationActions } = useNavigation();

  const {
    navigateToStatus,
    navigateToDocumentSelfSigning,
    openReadyForProcessOverlay,
    openSignedMRReadyForProcessOverlay,
    openReadyToSubmitOverlay,
    navigateToIDUpload,
    openNPSOverlay,
    navigateToModelloRedditiSign,
  } = useDashboardNavigationActions();

  const { getPreseasonStage } = usePreseasonDashboardStage();

  const { document: modelloRedditiDocument } = useFetch();

  const userFirstName = quizmasterLight[wayQuestionIds.firstName].answer ?? '';
  const userLastName = quizmasterLight[wayQuestionIds.lastName].answer ?? '';
  const isInstantBundleProduct =
    quizmasterLight[flagsQuestionIds.productBundleSelection]?.answer ===
    ProductBundleValues.instant;

  const taxSeasonInfo = useSelector(getTaxSeasonInfo);
  const { betweenSoftHardEnd } = taxSeasonInfo;
  const { isPreSeason } = taxSeasonInfo;

  const trackingParams = useSelector(internetConnectionSelectors.getTrackingParams);
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const isFlowStarted = quizmasterLight[flagsQuestionIds.flowStarted].answer;
  const userOccupation = quizmasterLight[wayQuestionIds.occupation].answer;

  const { enabled: endOfSeasonBannerEnabled, articleLink } = useSelector(
    firebaseSelectors.getItalyEndOfSeasonBanner,
  );
  const infoBannerConfig = useSelector(firebaseSelectors.getItalyDashboardInfoBanner);

  const userId = useSelector(userAuthSelectors.getUserId);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const isSelfCertificateEnabled = useSelector(firebaseSelectors.isSelfCertificateEnabled);
  const isModelloRedditiSignEnabled = useSelector(firebaseSelectors.isModelloRedditiSignEnabled);
  const enabledDocumentsPrefillsDownloadErrorTimeout = useSelector(
    firebaseSelectors.getItalyDownloadPrefillDocumentsErrorTimeout,
  ).enabled;

  const { downloadPrefillDocumentsStatus, isLoadingPrefillsDocCall } =
    useFetchDownloadPrefillDocuments(enabledDocumentsPrefillsDownloadErrorTimeout);

  // Freelancer Experience
  const freelancerExperienceEnabled = useSelector(
    firebaseSelectors.getItalyFreelancerExperience,
  ).enabled;

  const isFreelancerUser =
    userOccupation?.length === 1 && userOccupation?.[0] === OccupationAnswersKeys.vatHolder;

  const freelancerSurveyCompleted =
    quizmasterLight[flagsQuestionIds.freelancerSurveyCompleted].answer;

  const isFreelanceExperience = isFreelancerUser && freelancerExperienceEnabled;

  //ID error
  const prefillState = useSelector(prefillSelectors.getStatus);
  const identificationRejected = prefillState === PrefillStates.Rejected;

  // submissions
  const isResubmissionCurrentYear = useSelector((state: RootState) =>
    submissionSelectors.isResubmissionByYear(state, selectedYear),
  );
  const activeSubmissionsCurrentYear = useSelector((state: RootState) =>
    submissionSelectors.getActiveSubmissionsByYear(state, selectedYear),
  );
  const anySubmissionCurrentYear = useSelector((state: RootState) =>
    submissionSelectors.getSubmissionsByYear(state, selectedYear),
  );
  const submittedSubmissions = useSelector(submissionSelectors.getSubmittedSubmissions);
  const activeSubmission = useSelector(submissionSelectors.getActiveSubmission);
  const previousYearSubmissions = submittedSubmissions.filter(
    (submission) => submission.year !== selectedYear,
  );
  const submissionReviewStatus = useSelector(submissionSelectors.getSubmissionReviewStatus);

  const isSubmissionInValidatedOrValidationFailed = anySubmissionCurrentYear.some(
    (_submission) =>
      _submission.state === SubmissionStates.ValidationFailed ||
      _submission.state === SubmissionStates.Validated,
  );

  const isSubmissionValidationFailed =
    activeSubmission?.state === SubmissionStates.ValidationFailed;

  const isSubmissionInValidationFailed = anySubmissionCurrentYear.some(
    (_submission) => _submission.state === SubmissionStates.ValidationFailed,
  );

  const isModelloRedditiReadyForSubmission =
    submissionReviewStatus === SubmissionReviewStatusIT.MrCredito ||
    submissionReviewStatus === SubmissionReviewStatusIT.MrDebito;

  // When submission is (either ValidationFailed || Validated) & Review Status = SignSelfCertificate
  const selfSignPreSubmission =
    isSubmissionInValidatedOrValidationFailed &&
    submissionReviewStatus === SubmissionReviewStatusIT.SignSelfCertificate;

  // When submission is (either ValidationFailed || Validated) && Review Status = "Pronta per l'invio"(ReadyToSubmit)
  const selfSignPostSubmission =
    isSubmissionInValidatedOrValidationFailed &&
    submissionReviewStatus === SubmissionReviewStatusIT.ReadyToSubmit;

  const isModelloRedditiSubmission =
    isSubmissionValidationFailed &&
    (submissionReviewStatus === SubmissionReviewStatusIT.MrFirmaCredito ||
      submissionReviewStatus === SubmissionReviewStatusIT.MrFirmaDebito);

  const postModelloRedditiSubmission = anySubmissionCurrentYear.some(
    (_submission) =>
      isModelloRedditiReadyForSubmission && _submission.state === SubmissionStates.Submitted,
  );

  const fetchAndOpenMR = () => {
    navigationActions.toOpenPDF('modal', {
      uri: modelloRedditiDocument,
      year: selectedYear,
    });
  };

  const user = [userFirstName, userLastName];

  // Changed: Separate useEffect to avoid run lines not needed in re-renders.

  // When submission is ValidationFailed && Review Status = "Pronta per l'invio"(ReadyToSubmit) Credito/Debito
  const postModelloRedditiSignSubmission =
    isSubmissionInValidationFailed && isModelloRedditiReadyForSubmission;

  useEffect(() => {
    const questionStores = getQuestionStores(CountryCodes.IT);

    // check if it's returning user
    if (!userFirstName || !userLastName) {
      const questionIds: string[] = [...Object.values(wayQuestionIds)];

      const lastSeasonQuestionStore = questionStores[selectedYear - 1];
      const lastSeasonNodes: TreeNode[] = getNodesByIds(lastSeasonQuestionStore, questionIds);
      const lastSeasonQuizmasterLight = getQuizmasterLight(
        lastSeasonQuestionStore,
        lastSeasonNodes,
        trackingParams,
      );
      const lastSeasonFirstName = lastSeasonQuizmasterLight[wayQuestionIds.firstName].answer;
      const lastSeasonLastName = lastSeasonQuizmasterLight[wayQuestionIds.lastName].answer;
      // we migrate values to the new store only if previous year store has
      // the first and the last name answers, if not we leave the initial '' to prevent the error
      // ("Unexpected token u in JSON at position 0") - we cannot call saveAnswer with value undefined)
      // we ask the user later to update these values from the status screen
      if (lastSeasonQuestionStore && lastSeasonFirstName && lastSeasonLastName) {
        quizmasterLight[wayQuestionIds.firstName].saveAnswer(lastSeasonFirstName);
        quizmasterLight[wayQuestionIds.lastName].saveAnswer(lastSeasonLastName);
      }
    }
  }, [quizmasterLight, user, trackingParams]);

  useEffect(() => {
    if (userId) {
      dispatch(prefillActions.retrieveLatestPrefill(accessToken, userId, selectedYear));
      dispatch(requiredDocumentsActions.updateRequiredDocuments(accessToken, userId));
    }
    setLoading(false);
  }, [userId, accessToken, selectedYear]);

  useEffect(() => {
    if (!activeSubmission) return;

    const getSubmissionITReviewStatus = async () => {
      setLoading(true);
      try {
        await dispatch(
          submissionActions.getSubmissionReview({
            configUrl: Config.API_BASE_URL,
            accessToken,
            submissionId: activeSubmission.id,
          }),
        );
        setError(false);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getSubmissionITReviewStatus();
  }, [accessToken, activeSubmission]);

  useFocusEffect(
    useCallback(() => {
      if (
        !quizmasterLight[flagsQuestionIds.afterSubmissionFeedbackDisplayed]?.answer &&
        activeSubmission
      ) {
        openNPSOverlay();
      }
      setLoading(false);
    }, [activeSubmission, quizmasterLight]),
  );

  const getStage = (): CardSetting => {
    // submissions coming from "withSubmissionPDFDownload" hoc have the existing submissionResult
    const currentYearSubmissions = submittedSubmissions.filter(
      (submission) => submission.year === selectedYear,
    );

    if (isFreelanceExperience) {
      const stage = freelancerSurveyCompleted
        ? FlowStage.freelanceExperienceFinished
        : FlowStage.freelanceExperienceStart;
      const handleOnPressCTA = !freelancerSurveyCompleted
        ? () => {
            safeResetNavigation([
              getNavigationActions().toDashboard('screen'),
              getNavigationActions().toFreelancersSurveyScreen('screen'),
            ]);
          }
        : undefined;

      return {
        stage,
        handleOnPressCTA,
      };
    }

    if (isPreSeason) {
      return {
        stage: FlowStage.seasonStartSoon,
      };
    }

    if (isSelfCertificateEnabled && selfSignPreSubmission) {
      return {
        stage: FlowStage.pendingSelfSigningDocument,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.pendingSelfSigningDocument);
          navigateToDocumentSelfSigning(activeSubmission?.id);
        },
      };
    }

    if (isSelfCertificateEnabled && selfSignPostSubmission) {
      return {
        stage: FlowStage.signedSelfSigningDocument,
      };
    }

    if (activeSubmission && isModelloRedditiSubmission && isModelloRedditiSignEnabled) {
      return {
        stage: FlowStage.pendingModelloRedditiDocument,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.pendingModelloRedditiDocument);
          navigateToModelloRedditiSign(activeSubmission.id);
        },
      };
    }
    if (isModelloRedditiSignEnabled && postModelloRedditiSignSubmission) {
      return {
        stage: FlowStage.signedModelloRedditiDocument,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.signedModelloRedditiDocument);
          openSignedMRReadyForProcessOverlay();
        },
      };
    }

    if (isModelloRedditiSignEnabled && postModelloRedditiSubmission) {
      return {
        stage: FlowStage.submittedWithModelloRediti,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.submittedWithModelloRediti);
          fetchAndOpenMR();
        },
      };
    }

    if (currentYearSubmissions.length) {
      return {
        stage: FlowStage.latestSubmissionResult,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.latestSubmissionResult);
          fetchAndOpenPDF(submissions[0]);
        },
      };
    }

    if (
      identificationRejected &&
      (activeSubmissionsCurrentYear.length || (isResubmissionCurrentYear && isInstantBundleProduct))
    ) {
      return {
        stage: FlowStage.identificationRejected,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.identificationRejected);
          navigateToIDUpload();
        },
      };
    }

    if (isResubmissionCurrentYear && !isInstantBundleProduct && betweenSoftHardEnd) {
      return {
        stage: FlowStage.resubmission,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.resubmission);
          navigateToStatus();
        },
      };
    }

    // Submission status: validation failed + ready in Zucchetti
    if (
      activeSubmission?.state === SubmissionStates.ValidationFailed &&
      submissionReviewStatus === SubmissionReviewStatusIT.ReadyForProcess
    ) {
      return {
        stage: FlowStage.readyForProcess,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.readyForProcess);
          openReadyForProcessOverlay();
        },
      };
    }

    // Submission status: validated
    if (activeSubmission?.state === SubmissionStates.Validated) {
      return {
        stage: FlowStage.readyToSubmit,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, FlowStage.readyToSubmit);
          openReadyToSubmitOverlay();
        },
      };
    }

    if (anySubmissionCurrentYear.length) {
      return {
        stage: FlowStage.submissionCompleted,
      };
    }

    if (taxSeasonInfo.isOffSeason) {
      return {
        stage: FlowStage.outOfSeason,
      };
    }

    if (!isFlowStarted) {
      return {
        stage: FlowStage.startFlow,
        handleOnPressCTA: (buttonName: string) => {
          quizmasterLight[flagsQuestionIds.flowStarted].saveAnswer(true);
          logButtonPress(buttonName, FlowStage.startFlow);
          navigateToStatus();
        },
      };
    } else {
      if (downloadPrefillDocumentsStatus === PrefillTypes.PrefillResultStatus.InProgress) {
        return {
          stage: FlowStage.downloadTimeout,
        };
      }
      const stageName =
        downloadPrefillDocumentsStatus === PrefillTypes.PrefillResultStatus.Failed
          ? FlowStage.downloadTimeoutError
          : FlowStage.continueFlow;
      return {
        stage: stageName,
        handleOnPressCTA: (buttonName: string) => {
          logButtonPress(buttonName, stageName);
          navigateToStatus();
        },
      };
    }
  };

  const handleEOSBannerLinkPress: () => void = useCallback(() => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.Dashboard,
      buttonName: 'Find out more',
    });
    dispatch(
      overlayActions.show('TaxGuideOverlay', {
        url: articleLink,
        onClose: () => dispatch(overlayActions.hide()),
        openLinkExternally: () => Linking.openURL(articleLink),
      }),
    );
  }, [dispatch]);

  const handleTaxAdvisorButtonPress = (buttonName: string, stageName: string) => {
    logButtonPress(buttonName, stageName);
    handleOpenChatPopup();
  };

  const shouldShowEOSBanner =
    endOfSeasonBannerEnabled &&
    !activeSubmissionsCurrentYear.length &&
    betweenSoftHardEnd &&
    !isFreelanceExperience;

  const shouldShowStepper = !isFreelanceExperience;

  const shouldShowInfoBanner = Boolean(
    infoBannerConfig.enabled && !isFreelanceExperience && infoBannerConfig.text,
  );

  return (
    <DashboardComponent
      userName={userFirstName}
      fetchAndOpenPDF={fetchAndOpenPDF}
      previousYearSubmissions={previousYearSubmissions}
      mainCardSetting={getStage()}
      handleTaxAdvisorCardOnPress={handleTaxAdvisorButtonPress}
      preseasonMainCardSetting={getPreseasonStage()}
      isLoading={loading || isLoadingPrefillsDocCall}
      hasError={error}
      shouldShowEOSBanner={shouldShowEOSBanner}
      onEOSBannerLinkPress={handleEOSBannerLinkPress}
      shouldShowInfoBanner={shouldShowInfoBanner}
      infoBannerText={infoBannerConfig.text}
      shouldShowStepper={shouldShowStepper}
    />
  );
};

export const Dashboard = compose(
  withQuizmasterLight([
    wayQuestionIds.firstName,
    wayQuestionIds.lastName,
    flagsQuestionIds.flowStarted,
    flagsQuestionIds.productBundleSelection,
    flagsQuestionIds.afterSubmissionFeedbackDisplayed,
    flagsQuestionIds.freelancerSurveyCompleted,
    wayQuestionIds.occupation,
  ]),
  withSubmissionPDFDownload,
)(DashboardUndecorated);
