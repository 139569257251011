import React from 'react';

import { Container, Text, NavButton } from 'src/taxfix-components/src';
import { PdfContainer } from 'src/containers/pdf';
import { SmallSecondaryText } from 'src/components/core';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import { Footer } from './footer';

type Props = {
  navButton?: {
    text: string;
    onPress: () => void;
  };
  pdfUri: string;
  onSign: () => void;
};

const SelfSignDocument: React.FC<Props> = ({ navButton, pdfUri, onSign }) => {
  const { getTranslationText } = useItalyIntl();

  return (
    <>
      <Container alignContent="space-between" flex={1} overflow="hidden">
        {navButton && (
          <NavButton text={getTranslationText(navButton.text)} onPress={navButton.onPress} />
        )}
        <Container alignItems="center">
          <Container alignItems="flex-start" padding={20}>
            <Text
              textAlign="left"
              variant={
                {
                  base: 'titleSBold',
                  md: 'titleMBold',
                  lg: 'titleLBold',
                } as any // using any to overcome the @taxfix/taxfix-components type mismatch
              }
              value={getTranslationText('it.self-sign-document.screen.title')}
            />
            <SmallSecondaryText
              id={getTranslationText('it.self-sign-document.screen.description')}
            />
          </Container>
        </Container>
        <PdfContainer
          source={{ uri: pdfUri }}
          onError={(e) => {
            logger.warn(e as Error, {
              message: `Error on loading pdf on Sign sellf certificate screen`,
            });
          }}
        />
      </Container>
      <Footer onSign={onSign} />
    </>
  );
};

export { SelfSignDocument };
