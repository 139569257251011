/* global document */
import React, { Ref, ReactNode } from 'react';
import { ScrollView, Keyboard, Platform, EmitterSubscription } from 'react-native';

import { isWeb } from '../utils/platform';

type ScrollViewRef = Ref<typeof ScrollView>;
export type TriggerScroll = (elementId?: string) => void;
export type IsLoading = boolean;
export type SetIsLoading = (isloading: IsLoading) => void;
type Props = {
  animatedOnContentChange?: boolean;
  scrollEnabled?: boolean;
  children: (arg0: { setIsLoading: SetIsLoading; triggerScroll: TriggerScroll }) => ReactNode;
};
export class QuestionMasterScroll extends React.Component<Props> {
  keyboardDidShowListener?: EmitterSubscription;

  scrollViewRef: ScrollViewRef | null | undefined;

  scrollTimeoutID: any | null | undefined;

  isLoading: IsLoading = false;

  componentDidMount() {
    if (!isWeb) {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        // @ts-ignore
        this.handleScrollToElement,
      );
      this.performScroll();
    }
  }

  componentWillUnmount() {
    if (!isWeb) {
      this.keyboardDidShowListener?.remove();
    }

    clearTimeout(this.scrollTimeoutID);
  }

  performScroll: TriggerScroll = (elementID) => {
    if (this.scrollTimeoutID) {
      return;
    }

    this.scrollTimeoutID = setTimeout(
      () => {
        if (isWeb && elementID) {
          const element = document.getElementById(elementID);

          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        } else if (this.scrollViewRef) {
          (this.scrollViewRef as any).scrollToEnd({
            animated: this.props.animatedOnContentChange,
          });
        }

        this.scrollTimeoutID = null;
      },
      Platform.select({
        ios: 100,
        android: 300,
        web: 100,
      }),
    );
  };

  handleScrollToElement: any = (elementID: any) => {
    if (!this.scrollViewRef || this.isLoading || this.scrollTimeoutID) {
      return;
    }

    this.performScroll(elementID);
  };

  setScrollViewRef: any = (ref: ScrollViewRef) => {
    this.scrollViewRef = ref;
  };

  setIsLoading: SetIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  render() {
    return (
      <ScrollView
        keyboardShouldPersistTaps="always"
        onContentSizeChange={this.handleScrollToElement}
        scrollEnabled={this.props.scrollEnabled == null ? true : this.props.scrollEnabled}
        ref={this.setScrollViewRef}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        {this.props.children({
          setIsLoading: this.setIsLoading,
          triggerScroll: this.handleScrollToElement,
        })}
      </ScrollView>
    );
  }
}
