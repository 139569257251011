import { Dimensions } from 'react-native';

import { isWeb } from './platform';

const iphoneFiveS = 568;

function isSmallScreen(): boolean {
  const { height, width } = Dimensions.get('window');
  const calculatedHeight = height > width ? height : width;
  return calculatedHeight <= iphoneFiveS;
}

function isScreenWidthLessThan(targetWidth: number): boolean {
  const { width } = Dimensions.get('window');
  return width < targetWidth;
}

function isSmallMobileWebScreen(): boolean {
  const { width } = Dimensions.get('window');
  return isWeb && width <= iphoneFiveS;
}

export default {
  isSmallScreen,
  isSmallMobileWebScreen,
  isScreenWidthLessThan,
};
