import React from 'react';

import { ScreenName } from 'src/types/screen-name';

import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';
import navigate, { Action } from '../../routes/navigate';
import { AnalyticsEvent } from '../../biz-logic/analytics';

import { TaxGuideContainer } from './tax-guide-container';

export const toTaxGuide: Action = navigate(ScreenName.TaxGuide, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.TaxGuide,
  },
});

export const TaxGuide: React.FC = () => {
  useDefaultNavigationOptions(
    { id: 'it.tax-guide.nav.title' },
    {
      headerBackTitleVisible: false,
      animationEnabled: false,
    },
  );

  return <TaxGuideContainer />;
};
