import { IBoxProps, Pressable, VStack, useBreakpointValue } from 'native-base';
import React from 'react';
import { Linking, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { Container, Grid, Image, Text } from 'src/taxfix-components/src';
import { zendeskService } from 'src/services/zendesk-service-initializer';
import { useUnreadMessages } from 'src/biz-logic/zendesk/use-unread-messages';

import { selectors as remoteConfigFirebaseSelectors } from '../stores/modules/remote-config-firebase';
import { isAndroid, isNative, isWeb } from '../utils/platform';

import { ModalWithOverlay, theme } from './core';
import { ActionButton } from './core/Button/action-button';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 415,
    maxHeight: 525,
    marginBottom: 190,
    marginRight: 40,
  },
});

const FormattedPhoneNumber = () => {
  const { phoneNumber } = useSelector(remoteConfigFirebaseSelectors.getPhoneSupportByCountry);

  return (
    <Container flexDirection="row" paddingTop={isNative ? '6px' : '0px'}>
      <Text
        variant="bodyMBook"
        fontWeight="normal"
        fontFamily="brandBook"
        value={phoneNumber}
        whiteSpace="nowrap"
      />
    </Container>
  );
};

const MessageNotification = () => {
  const { getTranslationText } = useItalyIntl();
  const { messages } = useUnreadMessages(zendeskService, false);

  if (!messages) {
    return null;
  }

  const Icon = () => (
    <Text
      variant="bodyMBook"
      fontWeight="normal"
      fontFamily="brandBook"
      color={theme.color.darkBlueIcon}
      value="•"
      whiteSpace="nowrap"
    />
  );

  return (
    <Container flexDirection="row" paddingTop={isNative ? '6px' : '0px'}>
      <Text
        variant="bodyMBook"
        fontWeight="normal"
        fontFamily="brandBook"
        value={getTranslationText('it.support.chat.notification.text', { icon: Icon })}
        whiteSpace="nowrap"
      />
    </Container>
  );
};

type TileProps = {
  iconName: string;
  titleTranslationKey: string;
  onPress?: () => void;
} & IBoxProps;

const Tile = ({ iconName, titleTranslationKey, onPress, ...otherProps }: TileProps) => {
  return (
    <Pressable onPress={onPress} maxWidth={155} maxHeight={155} marginBottom={20}>
      <Container
        paddingTop={24}
        paddingLeft={20}
        paddingBottom={16}
        paddingRight={16}
        bg="greytones.greyBlue"
        height={155}
        width={155}
        justifyContent="space-between"
        flexDirection="column"
        display="flex"
        borderRadius={10}
        {...otherProps}
      >
        <Text variant="titleXSBold" value={titleTranslationKey} />
        <Container
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={36}
          width={36}
          borderRadius={10}
          background="greytones.white"
          alignSelf="flex-end"
        >
          <Image name={iconName} style={{ height: 18, width: 18 }} />
        </Container>
      </Container>
    </Pressable>
  );
};

type ChatPopupProps = {
  onClose?(): void;
};

export const ChatPopupComponent = ({ onClose }: ChatPopupProps): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const {
    phoneNumber: supportPhoneNumber,
    mobEnabled: phoneSupportEnabledMobile,
    webEnabled: phoneSupportEnabledWeb,
  } = useSelector(remoteConfigFirebaseSelectors.getPhoneSupportByCountry);

  const { mobEnabled: chatEnabledMobile, webEnabled: chatEnabledWeb } = useSelector(
    remoteConfigFirebaseSelectors.getChatSupportByCountry,
  );
  const isLiveChatEnabled = isNative ? chatEnabledMobile : chatEnabledWeb;
  const isPhoneSupportEnabled = isNative ? phoneSupportEnabledMobile : phoneSupportEnabledWeb;

  const taxSeasonInfo = useSelector(getTaxSeasonInfo);
  const { isPreSeason } = taxSeasonInfo;

  const handlePressPhone = () => {
    Analytics.log(AnalyticsEvent.b_inapp_ContactSupportPressCall);
    const scheme = isAndroid || isWeb ? 'tel:' : 'telprompt:';
    const phoneUrl = scheme + supportPhoneNumber.replace(/\s/g, '');

    Linking.canOpenURL(phoneUrl)
      .then((supported) => {
        if (supported) {
          Linking.openURL(phoneUrl);
        }
      })
      .catch(); // swallow error
  };

  const handlePressChat = () => {
    Analytics.log(AnalyticsEvent.contactSupportChatButtonPressed);
    zendeskService.openChat();
    if (onClose) onClose();
  };

  const mainContainerPadding = useBreakpointValue({ base: 25, md: 40 });

  const supportTitleText = getTranslationText('it.chat.support.title');
  const supportSubTitleText = getTranslationText(
    `it.chat.support.subtitle.${isPreSeason ? 'pre-season' : 'in-season'}`,
  );

  const tiles: Array<TileProps & { active: boolean }> = [
    {
      onPress: handlePressChat,
      iconName: 'comment',
      titleTranslationKey: getTranslationText('it.chat.support.tile.live-chat', {
        'message-notification': MessageNotification,
      }),
      active: isLiveChatEnabled,
    },
    {
      iconName: 'mobilePhone',
      titleTranslationKey: getTranslationText('it.chat.support.tile.call', {
        'phone-number': FormattedPhoneNumber,
      }),
      active: isPhoneSupportEnabled,
      onPress: handlePressPhone,
    },
  ];
  const tileData = tiles.filter((tile) => tile.active);

  return (
    <Container
      height="100%"
      width="100%"
      padding={mainContainerPadding}
      background="greytones.white"
      borderRadius={20}
      cursor="auto"
    >
      <VStack width="100%" space={5}>
        <Text variant="titleSBold" value={supportTitleText} />
        <Text variant="bodySBook" value={supportSubTitleText} />
        <Grid
          width="100%"
          data={tileData}
          height="75%"
          numColumns={2}
          renderItem={({ item: tile, index }: { item: TileProps; index: number }) => {
            return (
              <Tile
                marginRight={index % 2 === 0 ? 10 : 0}
                marginLeft={index % 2 === 0 ? 0 : 10}
                onPress={tile.onPress}
                iconName={tile.iconName}
                titleTranslationKey={tile.titleTranslationKey}
              />
            );
          }}
        />
      </VStack>
    </Container>
  );
};

type OverlayProps = {
  onClose: () => void;
};

export const ChatPopupOverlay: React.FC<OverlayProps> = ({ onClose }) => (
  <ModalWithOverlay
    onOverlayPress={onClose}
    visible
    hideHeader
    transparent
    animationType="fade"
    justifyContent="flex-end"
  >
    <Container style={styles.overlayWeb}>
      <ChatPopupComponent onClose={onClose} />
    </Container>
    <ActionButton mainButtonOnPress={onClose} />
  </ModalWithOverlay>
);
