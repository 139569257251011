import { useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { icons } from '../../../Icons/Icons';
import { Button } from '../../general/Button';
import { IconButton } from '../../general/IconButton';
import { Image } from '../../general/Image';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { Props } from './types';

export const OverlayContent: React.FC<Props> = ({
  handleOnClose,
  handleMainButtonOnPress,
  imageName,
  imageSize,
  title,
  titleVariant,
  description,
  descriptionVariant = 'bodyMBook',
  buttonTitle,
  buttonVariant = 'primary',
  closeIconTestId,
  mainButtonTestId,
  secondaryButtonTitle,
  handleSecondaryButtonOnPress,
  ...rest
}) => {
  const { colors, sizes } = useTheme();
  const mainContainerWidth = useBreakpointValue({ base: '335px', md: '600px' });
  const closeIconMargin = useBreakpointValue({ base: 2, md: 4 });
  const textMargin = useBreakpointValue({ base: '36px', md: '56px' });
  const titleVariantDefault = useBreakpointValue({ base: 'titleSBold', md: 'titleMBold' });

  return (
    <Container
      borderRadius={1}
      width={mainContainerWidth}
      backgroundColor={colors.greytones.white}
      {...rest}
    >
      {handleOnClose && (
        <IconButton
          path={icons.close}
          onPress={handleOnClose}
          alignSelf="flex-end"
          variant="grey"
          mt={closeIconMargin}
          mr={closeIconMargin}
          size={4}
          testID={closeIconTestId}
        />
      )}
      {!!imageName && !!imageSize && (
        <Image
          mt={2}
          alignSelf="center"
          name={imageName}
          width={imageSize?.width}
          height={imageSize?.height}
        />
      )}
      <Text
        variant={titleVariant ?? titleVariantDefault}
        mt={2}
        value={title}
        textAlign="center"
        px="20px"
      />
      <Text
        variant={descriptionVariant}
        textAlign="center"
        mt={2}
        px={textMargin}
        color={colors.greytones.textLight}
        value={description}
      />
      <Button
        variant={buttonVariant}
        mt={5}
        mb={secondaryButtonTitle ? 1 : 5}
        width={sizes.m}
        title={buttonTitle}
        onPress={handleMainButtonOnPress}
        testID={mainButtonTestId}
      />
      {!!secondaryButtonTitle && !!handleSecondaryButtonOnPress && (
        <Button
          variant="primaryWhite"
          width={sizes.m}
          mb={3}
          title={secondaryButtonTitle}
          onPress={handleSecondaryButtonOnPress}
        />
      )}
    </Container>
  );
};
