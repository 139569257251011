import { useTheme } from 'native-base';
import React from 'react';

import { Button, Container, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * Shows confirmation for the premium tier feature purchase
 */

export const ConfirmationCard: React.FC<Props> = ({
  title,
  description,
  buttonText,
  infoText,
  linkText,
  onPress,
  onLinkPress,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();

  return (
    <Container
      backgroundColor={colors.brand.darkGreen}
      flexDirection="column"
      alignItems="center"
      width={['100%', 600]}
      height={648}
      borderRadius={12}
      {...otherProps}
    >
      <Image name="confirmationPhone" minW={97} minH={168} mt={88} mb={36} />
      <Container px={[20, 111]} textAlign="center">
        <Text variant="titleXLBold" value={title} color={colors.white} mb={20} />
        <Text variant="bodyLBook" value={description} color={colors.white} />
      </Container>
      <Button
        variant="primaryWhite"
        title={buttonText}
        onPress={onPress}
        alignSelf="center"
        alignItems="center"
        width="270px"
        my="28px"
      />
      <Container flexDirection="row">
        {infoText && <Text variant="bodyMBook" value={infoText} color={colors.white} mr="4px" />}
        {linkText && (
          <Text
            variant="linkM"
            value={linkText}
            onPress={onLinkPress}
            underline={true}
            cursor="pointer"
          />
        )}
      </Container>
    </Container>
  );
};
