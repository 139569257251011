import { useEffect, useState } from 'react';

import { IZendeskService } from './types';

export const useUnreadMessages = (
  zendeskServiceInstance: IZendeskService,
  openChat = true,
): { messages: number } => {
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const init = async () => {
      const messages = await zendeskServiceInstance.getUnreadMessages();
      setUnreadMessages(messages);
    };

    init();
    const { removeSuscription } = zendeskServiceInstance.onUnreadMessagesChanged(
      (messages) => {
        setUnreadMessages(messages);
      },
      { openChat },
    );

    return () => removeSuscription();
  }, [zendeskServiceInstance]);

  return { messages: unreadMessages };
};
