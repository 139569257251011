import { buildAccountOption } from './account';
import { buildApplicationOption } from './application';
import { buildBetaBuildInfoOption } from './beta-build-info';
import { buildDebugCWBIUrlOption } from './debug-cwbi';
import { buildDisableEnglishOption } from './disable-english';
import { buildFirebaseRemoteConfigOption } from './firebase-remote-config';
import { buildSVGIconsBrowser } from './svg-icons-browser';
import { buildItalyQuizmasterOption } from './italy-quizmaster';
import { buildLockScreenOption } from './lock-screen';
import { buildLoginOption } from './login';
import { buildOnboardingOption } from './onboarding';
import { buildResetAppStateOption } from './reset-app-state';
import { buildResponsesOption } from './responses';
import {
  buildResultOptimizationNoOptimization,
  buildResultOptimizationPossible,
} from './result-optimization';
import { buildStripePaymentOption } from './stripe-payment';
import { buildTaxSystemOption } from './tax-system';

export const defaultOptions = [
  buildOnboardingOption,
  buildLockScreenOption,
  buildApplicationOption,
  buildAccountOption,
  buildLoginOption,
  buildResetAppStateOption,
  buildItalyQuizmasterOption,
  buildStripePaymentOption,
  buildResponsesOption,
  buildDebugCWBIUrlOption,
  buildSVGIconsBrowser,
  buildDisableEnglishOption,
  buildTaxSystemOption,
  buildBetaBuildInfoOption,
  buildFirebaseRemoteConfigOption,
  buildResultOptimizationPossible,
  buildResultOptimizationNoOptimization,
];
