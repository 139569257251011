import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { IntlShape, injectIntl } from 'react-intl';
import _ from 'lodash';
import { CountryCodes } from '@taxfix/types';
import { Keyboard, Pressable } from 'react-native';

import { ScreenName } from 'src/types/screen-name';
import { Provider } from 'src/stores/modules/user-auth';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { SsoProvidersLogin } from 'src/components/auth/sso-providers';

import { hasCredentials } from '../../utils/credentials';
import { isWeb } from '../../utils/platform';
import { actions as UserActions } from '../../stores/modules/user';
import withLogin, { WithLogin } from '../../hocs/with-login';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { selectors as settingsSelectors } from '../../stores/modules/settings';

import { Login } from './Login';
import { EmailPassword } from './Login.types';

type State = {
  emailAddress: string;
  password: string;
};

const mapDispatchToProps = (dispatch: any) => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

const mapStateToProps = (state: any) => ({
  isBioAuthEnabled: !_.isNil(state.user.isBioAuthEnabled) && Boolean(state.user.isBioAuthEnabled),
  selectedCountry: settingsSelectors.selectedCountry(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type LoginProps = {
  email?: string;
  onConfirm: () => void;
  webDeviceId?: string;
  showNoAccountOption?: boolean;
} & Pick<WithLogin, 'errorKey'>;

type Props = {
  intl: IntlShape;
} & WithNavigation &
  WithLogin &
  ReduxProps;

class UndecoratedLoginContainer extends React.Component<LoginProps & Props, State> {
  state = {
    emailAddress: this.props.email || '',
    password: __DEV__ ? '1234' : '', // eslint-disable-line no-undef
  };

  handleChange = (updated: EmailPassword) => {
    this.setState(updated);
  };

  handleConfirm = async (credentials: EmailPassword) => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.Login,
      buttonName: Provider.email,
    });
    const { handleLogin } = this.props;
    await handleLogin({
      email: credentials.emailAddress,
      password: credentials.password,
    });
  };

  handleForgot = () => {
    const { navigationActions } = this.props;
    const { emailAddress } = this.state;
    const params = {
      email: emailAddress,
    };
    navigationActions.toForgotPassword('screen', params);
  };

  getSsoConfig = () => {
    const { isLoading, handleSsoLogin, handleError, showNoAccountOption, selectedCountry } =
      this.props;
    const getSsoProviders = (): React.ReactNode => (
      <SsoProvidersLogin
        disabled={isLoading}
        onSuccess={handleSsoLogin}
        onError={handleError}
        showNoAccountOption={showNoAccountOption}
      />
    );

    return selectedCountry === CountryCodes.DE
      ? {
          titleKey: 'account.login.title',
          leadKey: 'account.login.lead',
          titleStyle: { fontSize: 24 },
          leadStyle: { fontSize: 16 },
          autoFocusEmail: false,
          secondaryButton: getSsoProviders,
        }
      : {};
  };

  render() {
    const { emailAddress, password } = this.state;
    const { errorKey, isLoading, selectedCountry } = this.props;
    const variants =
      selectedCountry === CountryCodes.IT
        ? {
            emailVariant: 'italy',
            pinVariant: 'italy',
            titleKey: 'it.account.login.title',
            leadKey: 'it.account.login.subtitle',
          }
        : {};
    return (
      <Pressable
        style={{ flex: 1 }}
        onPress={() => {
          if (!isWeb) {
            Keyboard.dismiss();
          }
        }}
      >
        <Login
          emailAddress={emailAddress}
          password={password}
          onChange={this.handleChange}
          onConfirm={this.handleConfirm}
          onForgot={this.handleForgot}
          confirmDisabled={!hasCredentials(this.state.emailAddress, this.state.password)}
          loading={isLoading}
          errorKey={errorKey != null ? errorKey : undefined}
          isFromMWebSignup={!!this.props.email}
          variants={variants}
          renderSso={this.getSsoConfig()}
        />
      </Pressable>
    );
  }
}

export const LoginContainer = compose<React.ComponentType<LoginProps>>(
  injectIntl,
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps),
  withLogin,
)(UndecoratedLoginContainer);
