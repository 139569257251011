import * as React from 'react';

import { SearchInItalyContainer } from '../../../containers/answers/Address/search-in-italy';
import CloseButton from '../../../containers/close-button';
import HeaderButton from '../../../containers/header-button';
import { Routes } from '../../routes';
import { getRoute, Navigation, NavigationParams } from '../../navigate';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../hooks/navigation-options-hook';
import { ItalySidebar } from '../../../containers/sidebar/italy-sidebar';
import { ActionParams } from '../../answers-address-search.types';

type Props = Navigation<
  Routes.AnswersAddressSearch,
  { [Routes.AnswersAddressSearch]: NavigationParams<ActionParams> }
>;

export const AnswersAddressSearchScreen: React.FC<Props> = ({ route }) => {
  const { onAnswer, color, detailsConfig } = route.params;

  const headerLeft = () => (
    <HeaderButton left>
      <CloseButton />
    </HeaderButton>
  );

  useDefaultNavigationOptions(undefined, {
    headerLeft,
  });

  const sidebar = () => <ItalySidebar />;

  useWebNavigationOptions({
    sidebar,
  });

  return <SearchInItalyContainer detailsConfig={detailsConfig} onAnswer={onAnswer} color={color} />;
};

export const { action: toAnswersAddressSearch, screen: answersAddressSearchScreen } = getRoute({
  routeName: Routes.AnswersAddressSearch,
  screenComponent: AnswersAddressSearchScreen,
});
