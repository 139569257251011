import React from 'react';
import { Modal as RNModal, StyleSheet, View, Platform } from 'react-native';

import { theme } from '../theme';
import { StyleProp } from '../../types';
import IconButton from '../Button/IconButton';
import Box from '../Box';

export type ModalProps = {
  visible: boolean;
  isKeyboardAvoiding?: boolean;
  onClose?: () => void;
  children?: any;
  style?: StyleProp;
  closeButtonStyle?: StyleProp;
  animationType?: 'slide' | 'fade' | 'none';
  transparent?: boolean;
  hideHeader?: boolean;
  presentationStyle?: 'fullScreen' | 'pageSheet' | 'formSheet' | 'overFullScreen';
  alignHorizontallyHeader?: 'center' | 'start' | 'end';
  color?: string;
  iconKey?: string;
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: 65,
  },
  closeButton:
    Platform.OS === 'ios'
      ? {
          marginLeft: 10,
          marginTop: 20,
        }
      : {
          marginLeft: 20,
        },
});

const Modal = (props: ModalProps) => {
  const {
    visible,
    children,
    style,
    closeButtonStyle,
    animationType = 'slide',
    transparent = false,
    hideHeader = false,
    presentationStyle,
    onClose = () => {},
    alignHorizontallyHeader = 'start',
    color,
    iconKey = 'cross',
  } = props;
  const content = (
    <View style={[styles.container, style]}>
      {!hideHeader && (
        <Box style={styles.header} alignHorizontally={alignHorizontallyHeader}>
          <IconButton
            style={[styles.closeButton, closeButtonStyle]}
            iconKey={iconKey}
            onPress={onClose}
            tintColor={color || theme.color.primary}
          />
        </Box>
      )}
      {children}
    </View>
  );
  return (
    <RNModal
      supportedOrientations={['portrait']}
      animationType={animationType}
      transparent={transparent}
      visible={visible}
      presentationStyle={presentationStyle}
    >
      {content}
    </RNModal>
  );
};

export default Modal;
