export const italyImages: Record<string, any> = {
  appleBook: require('../../../../assets/images/Apple Book.svg'),
  bagHouse: require('../../../../assets/images/Bag House.svg'),
  bagMoney: require('../../../../assets/images/Bag Money.svg'),
  bagPercent: require('../../../../assets/images/Bag Percent.svg'),
  bike: require('../../../../assets/images/Bike.svg'),
  bloodDonation: require('../../../../assets/images/Blood Donation.svg'),
  book: require('../../../../assets/images/Book.svg'),
  briefcase: require('../../../../assets/images/Briefcase.svg'),
  bus: require('../../../../assets/images/Bus.svg'),
  carTaxi: require('../../../../assets/images/Car - Taxi.svg'),
  cat: require('../../../../assets/images/Cat.svg'),
  coffin: require('../../../../assets/images/Coffin.svg'),
  coin: require('../../../../assets/images/Coin.svg'),
  coinsMediumSkin: require('../../../../assets/images/coinsMediumSkin.png'),
  documentFile: require('../../../../assets/images/Document - File.svg'),
  documentSpark: require('../../../../assets/images/Document-Spark.svg'),
  earlybird: require('../../../../assets/images/earlybird.svg'),
  energy: require('../../../../assets/images/energy.svg'),
  firstAidBox: require('../../../../assets/images/First Aid Box.svg'),
  gearWheel: require('../../../../assets/images/Gear Wheel.svg'),
  hammock: require('../../../../assets/images/Hammock.svg'),
  handBarChart: require('../../../../assets/images/Hand - Bar Chart.svg'),
  handHeart: require('../../../../assets/images/Hand - Heart.svg'),
  handPercent: require('../../../../assets/images/Hand - Percent.svg'),
  handShield: require('../../../../assets/images/Hand - Shield.svg'),
  hatStudent: require('../../../../assets/images/Hat - Student.svg'),
  heart: require('../../../../assets/images/Heart.svg'),
  house: require('../../../../assets/images/House.svg'),
  houseEuro: require('../../../../assets/images/House Euro.svg'),
  houseHome: require('../../../../assets/images/House - Home.svg'),
  houseKey: require('../../../../assets/images/House - Key.svg'),
  houseParagraph: require('../../../../assets/images/House - Paragraph.svg'),
  housePercent: require('../../../../assets/images/House - Percent.svg'),
  houses: require('../../../../assets/images/houses.png'),
  houseWalls: require('../../../../assets/images/House Walls.svg'),
  injection: require('../../../../assets/images/Injection.svg'),
  lightBulb: require('../../../../assets/images/Light Bulb.svg'),
  moneyHand: require('../../../../assets/images/Money - Hand.svg'),
  musicDarkSkin: require('../../../../assets/images/musicDarkSkin.png'),
  newsITA2022: require('../../../../assets/images/newsITA2022.png'),
  nurseHat: require('../../../../assets/images/Nurse Hat.svg'),
  oldTaxDeclarationImage: require('../../../../assets/images/oldTaxDeclarationImage.svg'),
  organKeys: require('../../../../assets/images/Organ Keys.svg'),
  paintRoller: require('../../../../assets/images/Paint Roller.svg'),
  peopleTaxExpert: require('../../../../assets/images/people-tax-expert.svg'),
  passport: require('../../../../assets/images/Passport.svg'),
  peopleAchievement: require('../../../../assets/images/peopleAchievement.svg'),
  phoneDarkSkin: require('../../../../assets/images/phoneDarkSkin.svg'),
  prefill: require('../../../../assets/images/prefill.svg'),
  prefill2: require('../../../../assets/images/prefill2.svg'),
  reading: require('../../../../assets/images/reading.png'),
  shapesAbstract: require('../../../../assets/images/shapesAbstract.png'),
  sofa: require('../../../../assets/images/Sofa.svg'),
  storage: require('../../../../assets/images/Storage.svg'),
  thankyou: require('../../../../assets/images/thank-you.svg'),
  wateringCan: require('../../../../assets/images/Watering Can.svg'),
  wheelchair: require('../../../../assets/images/Wheelchair.svg'),
  wood: require('../../../../assets/images/Wood.svg'),
  addMore: require('../../../../assets/images/Add more.svg'),
  searchFile: require('../../../../assets/images/Search file.svg'),
  celebratingHands: require('../../../../assets/images/Celebration.svg'),
  prefillOff: require('../../../../assets/images/Prefill-off.svg'),
  prefillOff2022: require('../../../../assets/images/Prefill-Off-2022.svg'),
  prefillOff2023: require('../../../../assets/images/Prefill-off-2023.svg'),
  openInBrowser: require('../../../../assets/images/open-in-browser.svg'),
  comment: require('../../../../assets/images/comment.svg'),
  email: require('../../../../assets/images/email.svg'),
  whatsapp: require('../../../../assets/images/whatsapp.svg'),
  mobilePhone: require('../../../../assets/images/mobile-phone.svg'),
  magicWand: require('../../../../assets/images/magic-wand.svg'),
  dollarBag: require('../../../../assets/images/dollar-bag.svg'),

  // Onboarding
  onboardingCU: require('../../../../assets/images/onboardingCU.svg'),
  onboardingAnxiety: require('../../../../assets/images/onboardingAnxiety.svg'),
  onboardingHandPhone: require('../../../../assets/images/onboardingHandPhone.svg'),
  onboardingRelax: require('../../../../assets/images/onboardingRelax.svg'),
  onboardingExperts: require('../../../../assets/images/onboardingExperts.svg'),
  trustpilot: require('../../../../assets/images/trustpilot.svg'),
  notificationsOnEn: require('../../../../assets/images/notificationsOnEn.svg'),
  notificationsOnIt: require('../../../../assets/images/notificationsOnIt.svg'),
  onBoardingHand: require('../../../../assets/illustrations/onboarding-hand.svg'),

  // Illustrations
  piggyBank: require('../../../../assets/illustrations/piggy-bank.svg'),
  premiumTierBanner: require('../../../../assets/illustrations/premiumTierBanner.png'),
  premiumTier: require('../../../../assets/illustrations/premiumTier.png'),
  confirmationPhone: require('../../../../assets/illustrations/confirmationPhone.svg'),
  calendar30Sep: require('../../../../assets/illustrations/calendar30Sep.svg'),
  signature: require('../../../../assets/images/Signature.svg'),
  downloadProcess: require('../../../../assets/illustrations/download-process.svg'),

  // Skeleton IDs
  drivingLicenseSkeleton: require('../../../../assets/illustrations/driving-license.svg'),
  passportFrontSkeleton: require('../../../../assets/illustrations/passport-front.svg'),
  passportRearSkeleton: require('../../../../assets/illustrations/passport-rear.svg'),
  eletronicIdFrontSkeleton: require('../../../../assets/illustrations/eletronic-id-front.svg'),
  eletronicIdRearSkeleton: require('../../../../assets/illustrations/eletronic-id-rear.svg'),
  paperIdFrontSkeleton: require('../../../../assets/illustrations/paper-id-front.svg'),
  paperIdRearSkeleton: require('../../../../assets/illustrations/paper-id-rear.svg'),

  outOfSeason: require('../../../../assets/illustrations/out-of-season.svg'),
  preseasonCalendar: require('../../../../assets/illustrations/preseason-calendar.svg'),

  // IDs
  idError: require('../../../../assets/illustrations/id-error.png'),
  passportId: require('../../../../assets/illustrations/passport.svg'),

  // Prefill
  SPID: require('../../../../assets/images/SPID.svg'),
  payslipManual: require('../../../../assets/images/PayslipManual.svg'),
  errorBarrier: require('../../../../assets/images/ErrorBarrier.svg'),
  handy: require('../../../../assets/images/handy.svg'),
  precompilato: require('../../../../assets/images/precompilato.svg'),
  cake: require('../../../../assets/images/cake.svg'),

  // Preseason
  calendar: require('../../../../assets/images/calendar.svg'),
  calendar2: require('../../../../assets/images/calendar2.svg'),
  certificazioneUnica: require('../../../../assets/images/CertificazioneUnica.svg'),
  handsCoins: require('../../../../assets/images/handsCoins.svg'),
  phoneDarkSkin2: require('../../../../assets/images/phoneDarkSkin2.svg'),
  newsITA2023: require('../../../../assets/images/newsITA2023.svg'),
  newsITA2024: require('../../../../assets/images/newsITA2024.svg'),
  personComputerPlant: require('../../../../assets/images/personComputerPlant.svg'),
  handWithCU: require('../../../../assets/images/handWithCU.svg'),
  geometricFigures: require('../../../../assets/images/geometricFigures.svg'),

  // Tax Guide - Primary Articles
  laptopAndHandWithPhoneMobile: require('../../../../assets/images/laptopAndHandWithPhoneMobile.svg'),
  laptopAndHandWithPhoneDesktop: require('../../../../assets/images/laptopAndHandWithPhoneDesktop.svg'),
  letterMobile: require('../../../../assets/images/letterMobile.svg'),
  letterDesktop: require('../../../../assets/images/letterDesktop.svg'),
  handsWithMoneyMobile: require('../../../../assets/images/handsWithMoneyMobile.svg'),
  handsWithMoneyDesktop: require('../../../../assets/images/handsWithMoneyDesktop.svg'),

  // Product Bundle
  cellphoneConfirmTap: require('../../../../assets/images/cellphone-confirm-tap.png'),
  handWithTaxfix: require('../../../../assets/images/HandWithTaxfix.svg'),
  taxExpert: require('../../../../assets/images/TaxExpert.svg'),
  checkMarkThin: require('../../../../assets/images/checkMarkThin.svg'),
  handWithSPID: require('../../../../assets/images/handWithSPID.svg'),
  faCheck: require('../../../../assets/images/fa-check.svg'),
  faNull: require('../../../../assets/images/fa-null.svg'),
  premiumVideoCall: require('../../../../assets/images/premiumVideoCall.png'),
  advancedSupport: require('../../../../assets/images/advancedSupport.png'),

  // StatusScreen
  personalInfoIcon: require('../../../../assets/images/Status-PersonalInfo.svg'),
  IncomeIcon: require('../../../../assets/images/Status-Income.svg'),
  IncomeGreyIcon: require('../../../../assets/images/Status-IncomeGrey.svg'),
  expenseIcon: require('../../../../assets/images/Status-Expense.svg'),
  expenseIconGrey: require('../../../../assets/images/Status-ExpenseGrey.svg'),
  IdentificationIcon: require('../../../../assets/images/Status-Identification.svg'),
  IdentificationGreyIcon: require('../../../../assets/images/Status-IdentificationGrey.svg'),
  ERIcon: require('../../../../assets/images/Status-ExpertReview.svg'),
  ERIconGrey: require('../../../../assets/images/Status-ExpertReviewGrey.svg'),

  // ResultScreen
  cuPreview: require('../../../../assets/images/CUPreview.svg'),
  mod730Preview: require('../../../../assets/images/Mod730Preview.svg'),
  visuraPreview: require('../../../../assets/images/VisuraPreview.svg'),

  // Tailored Experience
  taxExpertOnComputer: require('../../../../assets/illustrations/tax-expert.svg'),
  greenGuardBadge: require('../../../../assets/illustrations/green-guard-badge.svg'),

  logo: require('../../../../assets/images/logo.svg'),

  //No SPID flow
  robot: require('../../../../assets/illustrations/robot.svg'),
};
