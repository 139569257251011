import React from 'react';
import curry from 'lodash/curry';
import { ScrollView, useBreakpointValue } from 'native-base';
import { Set } from 'react-powerplug';
import { useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';

import { Container, InputField } from 'src/taxfix-components/src';
import {
  AddressDetailsFieldWithValue,
  AddressDetailsKeys,
  AddressDetailsValue,
} from 'src/types/address';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getErrorTranslationKey } from 'src/screens/components/detail-address-view';
import { LanguageCode } from 'src/types';
import { isWeb } from 'src/utils/platform';

import { theme } from '../../../components/core';
import SelectWithOverlay from '../../../containers/input/select-with-overlay';
import { ValidationErrors } from '../../../components/answers/shared/validators/types';
import { IT_POSTAL_CODE_LENGTH, provinceList } from '../../../common/constants-it';
import AnswerForm from '../components/AnswerForm';
import DropdownMenuInputField from '../../_components/dropdown-menu/dropdown-menu';

type Props = {
  // NOTE: address is `any` because the format depends on fields
  onChange: (address: any) => void;
  handleOnContinePress?: () => void;
  errors?: ValidationErrors;
  fields: AddressDetailsFieldWithValue[];
  isValidAddress?: boolean;
  language: string;
};

const orEmpty = (str: string | null | undefined) => str || '';

export const ItalyDetailAddressView = ({
  onChange,
  handleOnContinePress,
  errors = {},
  fields,
  isValidAddress,
  language = 'en',
}: Props): JSX.Element => {
  const intl = useIntl();

  const countryList = React.useMemo(() => {
    const formattedLanguage = language === LanguageCode.EN_GB ? LanguageCode.EN : language;
    return Object.entries(countries.getNames(formattedLanguage)).map(([key, value]) => {
      return {
        value: key,
        label: value,
      };
    });
  }, [language]);

  const stateList = provinceList.map((province) => ({
    value: province.code,
    label: intl.formatMessage({
      id: province.translationKey,
    }),
  }));
  const initValues = fields.reduce((acc: any, field) => {
    acc[field.id] = field.value;
    return acc;
  }, {});
  const customErrors = fields.reduce((acc: any, field) => {
    acc[field.id] = field.errors || {};
    return acc;
  }, {});

  const { getTranslationText } = useItalyIntl();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const marginLeftPostalCode = useBreakpointValue({ base: '17px', md: '20px' });

  return (
    <Set onChange={onChange} initial={initValues}>
      {({ set, get }: any) => {
        const EditableField = (
          fieldKey: AddressDetailsValue,
          translationKey: string,
          editable: boolean,
        ) => {
          const error = errors[fieldKey]
            ? {
                key:
                  customErrors[fieldKey][errors[fieldKey].errorKey] ||
                  getErrorTranslationKey(errors[fieldKey].errorKey, fieldKey),
                values: {
                  number: errors[fieldKey].limit,
                  chars: errors[fieldKey].chars,
                },
                level: 'Error',
              }
            : undefined;

          switch (fieldKey) {
            case AddressDetailsKeys.State: {
              const selectedValue = stateList.find(
                (state) => state.value === orEmpty(get(fieldKey)),
              );

              const setValueForFieldKey = curry(set)(fieldKey);

              return !isWeb ? (
                <Container key={fieldKey} marginY={1}>
                  <SelectWithOverlay
                    value={selectedValue?.label}
                    // @ts-ignore
                    onChange={setValueForFieldKey}
                    options={stateList}
                    testId={translationKey}
                    isSelectFullScreenHeight={!isSmallScreen}
                  >
                    <Container pointerEvents="none">
                      <InputField
                        label={getTranslationText(translationKey)}
                        isInvalid={!!error}
                        fontWeight="bold"
                        color="brand.brandGreen"
                        value={selectedValue?.label}
                        testID={translationKey}
                        editable={false}
                        isDropdown
                        iconOpen
                      />
                    </Container>
                  </SelectWithOverlay>
                </Container>
              ) : (
                <Container key={fieldKey} marginY={1}>
                  <DropdownMenuInputField
                    options={stateList}
                    selectedValue={selectedValue}
                    onAnswer={setValueForFieldKey}
                    labelInputField={getTranslationText(translationKey)}
                    testID={translationKey}
                    error={!!error}
                  />
                </Container>
              );
            }

            default: {
              // Autofocus on the first field
              const autoFocus = fieldKey === fields[0].id;
              const maxLength =
                fieldKey === AddressDetailsKeys.PostalCode ? IT_POSTAL_CODE_LENGTH : 125;

              const label = getTranslationText(translationKey);

              const value =
                fieldKey === AddressDetailsKeys.Country
                  ? countryList.find((country) => country.value === orEmpty(get(fieldKey)))?.label
                  : orEmpty(get(fieldKey));

              const errorMessage = error && getTranslationText(error.key, error.values);

              return (
                <Container key={fieldKey} marginY={1}>
                  <InputField
                    label={label}
                    isInvalid={!!error}
                    errorMessage={errorMessage}
                    value={value}
                    // @ts-ignore
                    onChangeText={editable && curry(set)(fieldKey)}
                    maxLength={maxLength}
                    style={
                      !editable && {
                        color: theme.color.secondaryText,
                      }
                    }
                    editable={editable}
                    testID={translationKey}
                    autoFocus={autoFocus}
                  />
                </Container>
              );
            }
          }
        };

        const addressFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.Street;
        });

        const numberFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.Number;
        });

        const postalCodeFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.PostalCode;
        });

        const cityFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.City;
        });

        const stateFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.State;
        });

        const countryFieldDetails = fields.find((field) => {
          return field.id === AddressDetailsKeys.Country;
        });

        const form = {
          disabled: !isValidAddress,
          onConfirm: handleOnContinePress!,
        };
        return (
          <AnswerForm fullscreen {...form}>
            <ScrollView keyboardShouldPersistTaps="handled">
              {addressFieldDetails &&
                EditableField(
                  addressFieldDetails.id,
                  addressFieldDetails.translationKey,
                  addressFieldDetails.editable,
                )}

              <Container flexDirection="row">
                <Container flex={1}>
                  {numberFieldDetails &&
                    EditableField(
                      numberFieldDetails.id,
                      numberFieldDetails.translationKey,
                      numberFieldDetails.editable,
                    )}
                </Container>
                <Container flex={1} marginLeft={marginLeftPostalCode}>
                  {postalCodeFieldDetails &&
                    EditableField(
                      postalCodeFieldDetails.id,
                      postalCodeFieldDetails.translationKey,
                      postalCodeFieldDetails.editable,
                    )}
                </Container>
              </Container>

              {cityFieldDetails &&
                EditableField(
                  cityFieldDetails.id,
                  cityFieldDetails.translationKey,
                  cityFieldDetails.editable,
                )}

              {stateFieldDetails &&
                EditableField(
                  stateFieldDetails.id,
                  stateFieldDetails.translationKey,
                  stateFieldDetails.editable,
                )}

              {countryFieldDetails &&
                EditableField(
                  countryFieldDetails.id,
                  countryFieldDetails.translationKey,
                  countryFieldDetails.editable,
                )}
            </ScrollView>
          </AnswerForm>
        );
      }}
    </Set>
  );
};
