import React from 'react';

import { isAndroid } from '../../utils/platform';

import { CommonResultComponent } from './CommonResult';

type Props = {
  onDismiss: () => void;
  onOpenStore: () => any;
};
export const ResultHappyComponent = ({ onDismiss, onOpenStore }: Props) => {
  // for maintenance purpose no string interpolation on translation key
  const subtitleCopyPlatform = isAndroid
    ? `feedback-modal.happy.subtitle.android`
    : `feedback-modal.happy.subtitle.ios`;
  return (
    <CommonResultComponent
      icon="happy"
      title="feedback-modal.happy.title"
      subtitle={subtitleCopyPlatform}
      ctaTranslationKey="feedback-modal.happy.cta"
      onCtaPress={onOpenStore}
      onDismiss={onDismiss}
    />
  );
};
