import React from 'react';

import navigate, { Action } from 'src/routes/navigate';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';

import { FeelancersSurveyContainer } from './freelancers-survey-container';

export const toFreelancersSurveyScreen: Action = navigate(ScreenName.FreelancersSurvey, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.FreelancersSurvey,
  },
});

export const FreelancersSurveyScreen = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'it.freelancer-survey.header.title' });

  return <FeelancersSurveyContainer />;
};
