import * as React from 'react';
import { useIntl } from 'react-intl';

import { OnboardingLink } from 'src/containers/header-links';
import { useSkipCodePushUpdate } from 'src/hooks/skip-code-push-hook';
import { isWeb } from 'src/utils/platform';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import {
  useNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';

import { NavigationParams as ActionParams } from './lock.types';
import { LockContainer } from './lock-container';

type Props = Navigation<
  Routes.Lock,
  {
    [Routes.Lock]: NavigationParams<ActionParams>;
  }
>;

const LockScreenComponent: React.FC<Props> = ({ route }) => {
  const { onConfirm, errorKey } = route.params;
  const intl = useIntl();

  const headerRight = isWeb ? () => <OnboardingLink /> : () => null;

  const headerOptions = {
    headerRight,
  };

  useNavigationOptions(headerOptions);

  useWebNavigationOptions({
    headerTitle: intl.formatMessage({
      id: 'it.header.title.tax-season',
    }),
  });
  // We have to skip code push update
  // because SSO options sets the app state to inactive|background
  // and this makes the update to be installed during login.
  useSkipCodePushUpdate();

  return <LockContainer onConfirm={onConfirm} errorKey={errorKey} />;
};

const LockScreen = LockScreenComponent;

export const { action: toLock, screen: lockScreen } = getRoute<ActionParams>({
  routeName: Routes.Lock,
  tracking: AnalyticsEvent.sectionLock,
  screenComponent: LockScreen,
});

export default LockScreen;
