import React from 'react';
import { connect } from 'react-redux';

import { useBackButtonHandler } from 'src/hooks/useBackButtonHandler';

import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { InformationContactSupportButton } from '../../containers/information-contact-support-button';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';
import navigate, { Action, Navigation, NavigationParams } from '../../routes/navigate';

import { InformationContainer } from './information-container';
import { NavigationParams as ActionParams } from './information.types';

type OwnProps = {
  isHeaderShown?: boolean;
  onCloseButtonPress?: () => void;
};
type NavigationProps = Navigation<
  'Information',
  {
    Information: NavigationParams<ActionParams & OwnProps>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate('Information');
type Props = NavigationProps;

const InformationScreen = ({ route }: Props) => {
  const {
    content,
    contentStyles,
    isMarkdown,
    translationKey,
    translationValues,
    questionId,
    webSize,
    isHeaderShown = true,
    onCloseButtonPress,
  } = route.params;

  useDefaultNavigationOptions();

  // onCloseButtonPress handler for Android back button.
  useBackButtonHandler({
    onBackButtonPress: onCloseButtonPress ? onCloseButtonPress : () => {},
    shouldNavigateBack: true,
  });

  useWebNavigationOptions({
    headerShown: isHeaderShown,
  });

  const renderSupportButton = () => {
    return <InformationContactSupportButton questionId={questionId} />;
  };

  return (
    <InformationContainer
      webSize={webSize}
      content={content}
      contentStyles={contentStyles}
      isMarkdown={isMarkdown}
      translationKey={translationKey}
      translationValues={translationValues}
      renderSupportButton={renderSupportButton}
    />
  );
};

const mapStateToProps = (state: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
});

export default connect(mapStateToProps)(InformationScreen);
