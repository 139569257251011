import { $PropertyType } from 'utility-types';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import { ANSWER_TYPES } from '../utils/constants';

import {
  TextTransform,
  theme,
  Box,
  Text,
  smallSecondaryTextStyle,
  SmallSecondaryText,
} from './core';
import { StyleTextProp, StyleProp } from './types';

const styles = StyleSheet.create({
  fieldBox: {
    minHeight: 40,
    borderBottomWidth: 1,
  },
});
export type Error = {
  key: string;
  values?: Record<string, any>;
  testId?: string;
  level?: 'Error' | 'Warning' | null;
};
type Props = {
  icon?: React.ReactNode;
  error?: Error | any;
  label?: {
    key: string;
    values?: Record<string, any>;
    maxLines?: number;
  };
  labelStyle?: StyleTextProp | any;
  labelTextTransform?: TextTransform;
  labelSecondary?: {
    key: string;
  };
  labelSecondaryStyle?: StyleTextProp;
  fieldBoxStyle?: StyleProp | any;
  errorStyle?: StyleProp | any;
  paddingBottom?: number;
  children: (arg0: { onBlur: () => any; onFocus: () => any }) => React.ReactNode;
  flex?: any;
  answerType?: string;
};
type State = {
  isFocused: boolean;
};

class FormField extends React.Component<Props, State> {
  state = {
    isFocused: false,
  };
  // eslint-disable-next-line
  getErrorColor = (errorLevel: $PropertyType<Error, 'level'>) => {
    const { answerType } = this.props;
    return errorLevel && errorLevel === 'Error' && answerType !== ANSWER_TYPES.DE_PAYSLIP
      ? theme.color.errorTextLight
      : theme.color.orangeText;
  };
  // eslint-disable-next-line
  getActiveLabelColor = () => {
    const { isFocused } = this.state;
    return isFocused ? theme.color.primaryText : theme.color.secondaryText;
  };
  // eslint-disable-next-line
  getActiveBorderColor = () => {
    const { error } = this.props;
    const { isFocused } = this.state;

    if (error && error.key) {
      return this.getErrorColor(error.level);
    }

    return isFocused ? theme.color.secondaryText : theme.color.border;
  };

  getErrorBackgroundColor = () => {
    const { error, answerType } = this.props;

    if (answerType === ANSWER_TYPES.DE_PAYSLIP && error?.key) {
      return { backgroundColor: theme.color.reminderWarningBackground };
    }
  };

  getErrorAdditionalStyles = () => {
    const { error, answerType } = this.props;
    if (answerType === ANSWER_TYPES.DE_PAYSLIP && error && error.key) {
      return {
        paddingLeft: 8,
        paddingTop: 12,
        paddingBottom: 8,
      };
    }
  };

  getBoxAdditionalStyles = () => {
    const { answerType } = this.props;
    if (answerType === ANSWER_TYPES.DE_PAYSLIP) {
      return {
        marginTop: 8,
        paddingRight: 8,
      };
    }
  };

  changeFocusState = (focusState: boolean) =>
    this.setState({
      isFocused: focusState,
    });

  render() {
    const {
      children,
      label,
      error,
      icon: Icon,
      labelStyle = smallSecondaryTextStyle,
      labelTextTransform,
      labelSecondary,
      labelSecondaryStyle,
      fieldBoxStyle,
      errorStyle,
    } = this.props;
    const activeTextColor = this.getActiveLabelColor();
    return (
      <Box flex={1}>
        <Box direction="row">
          {label && (
            <Text
              id={label.key}
              values={label.values}
              numberOfLines={label.maxLines ?? 2}
              ellipsizeMode="tail" // @ts-ignore (RN update 0.63.3)
              style={[
                {
                  color: activeTextColor,
                },
                labelStyle,
              ]}
              textTransform={labelTextTransform}
            />
          )}
          {labelSecondary && (
            <Text
              id={labelSecondary.key}
              numberOfLines={2}
              ellipsizeMode="tail"
              style={labelSecondaryStyle}
              textTransform={labelTextTransform}
            />
          )}
        </Box>
        <Box
          direction="row"
          alignVertically="center"
          style={[
            styles.fieldBox,
            {
              borderBottomColor: this.getActiveBorderColor(),
              ...this.getErrorBackgroundColor(),
              ...this.getErrorAdditionalStyles(),
              ...this.getBoxAdditionalStyles(),
            },
            fieldBoxStyle,
          ]}
        >
          <Box flex>
            {children({
              onBlur: () => this.changeFocusState(false),
              onFocus: () => this.changeFocusState(true),
            })}
          </Box>
          {Icon && <Box left={1}>{Icon}</Box>}
        </Box>
        {error && Boolean(error.key) && (
          <Box top={0.5}>
            <SmallSecondaryText
              style={[
                {
                  color: this.getErrorColor(error.level),
                },
                errorStyle,
              ]}
              id={error.key}
              values={error.values}
              testId={error.testId ? error.testId : error.key}
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default FormField;
