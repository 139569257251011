export type SentryError = string | Error;

export type SentryTags = Record<string, string>;

export type SentryContext = Record<string, unknown>;

export type UniversalSentryOptions = {
  dsn: string;
  environment: string;
  release?: string;
};

export enum SentryCustomTag {
  CaughtByErrorBoundary = 'caughtByErrorBoundary',
}

export enum SentryTagBooleanValue {
  Yes = 'yes',
  No = 'no',
}
