import { getNavigationActions } from '../../../../routes/config-util';

import { DebugMenuOptions, MenuOption } from './types';

export function buildLoginOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handleLogin = () => {
    const params = {
      onConfirm: () => {
        navigationActions.reset({
          index: 0,
          actions: [getNavigationActions().toUniversalStatus('screen')],
        });
      },
    };
    navigationActions.toLogin('screen', params);
  };

  return { text: 'Login', onPress: handleLogin, showArrow: true };
}
