import { useBreakpointValue } from 'native-base';
import React, { useMemo } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Container, Image, NBTheme } from 'src/taxfix-components/src';
import { IconButton } from 'src/components/core';
import Overlay from 'src/components/overlay';
import { StyleProp } from 'src/components/types';
import { WebViewComponent } from 'src/screens/components/webview';

import { Props } from './tax-guide-overlay';

const styles = (overlayStyle: StyleProp) =>
  StyleSheet.create({
    overlay: {
      justifyContent: 'center',
      alignSelf: 'center',
      ...overlayStyle,
    },
    pressableContainerStyle: {
      width: '100%',
      overflow: 'visible',
    },
    radialBorder: {
      borderRadius: 15,
    },
    topRadialBorder: {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    closeButton: {
      height: 22.5,
      width: 22.5,
      marginRight: 24,
      marginLeft: 'auto',
      flex: 0,
    },
    openExternallyButton: {
      width: 50,
      padding: 16,
    },
  });

//* NOTE: This component has separate implementations for mobile and web

export const TaxGuideOverlay: React.FC<Props> = ({ onClose, openLinkExternally, url }) => {
  const overlayWidth = useBreakpointValue({ base: '100%', md: '95%', xl: '90%' });
  const overlayHeaderHeight = useBreakpointValue({ base: '58px', md: '80px' });
  const overlayHeaderPadding = useBreakpointValue({ base: '0', md: '10px' });
  const overlayContentHeight = useBreakpointValue({ base: '100vh', md: '85vh', xl: '80vh' });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const style = useMemo(() => styles({ width: overlayWidth }), [overlayWidth]);

  return (
    <Overlay
      style={style.overlay}
      pressableContainerStyle={style.pressableContainerStyle}
      contentContainerStyle={style[isSmallScreen ? 'topRadialBorder' : 'radialBorder']}
      position={isSmallScreen ? 'center' : 'bottom'}
      onCloseComplete={onClose}
    >
      {({ onClose: closeWithAnimation }: { onClose: () => void }) => (
        <Container backgroundColor="white" height={overlayContentHeight}>
          {/* Header */}
          <Container
            height={overlayHeaderHeight}
            padding={overlayHeaderPadding}
            flexDirection="row"
            alignItems="center"
          >
            <Pressable onPress={openLinkExternally} style={style.openExternallyButton}>
              <Image name="openInBrowser" />
            </Pressable>
            <IconButton
              onPress={closeWithAnimation}
              iconKey="cross"
              style={style.closeButton}
              tintColor={NBTheme.colors.greytones.graphicsDark}
            />
          </Container>
          <Container width="100%" height="4px" backgroundColor="brand.brandGreen" />
          {/* Iframe Content */}
          <Container justifyContent="center" alignItems="center" flexGrow={1}>
            <WebViewComponent url={url} />
          </Container>
        </Container>
      )}
    </Overlay>
  );
};
