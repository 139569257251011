import { CountryCodes } from '@taxfix/types';

import { contentForIT } from '../ContentMagic';

import QuestionStore, { NodesType } from './QuestionStore';
import { setQuestionStores } from './util';

export type QuestionStores = Record<string, QuestionStore>;
export type QuestionStoresV2 = Record<number, QuestionStore>;
// We have to create question store instances of closed years
// to support users that have submitted for those years.

const questionStoresIT: QuestionStores = {
  '2020': new QuestionStore(
    2020,
    contentForIT.nodes,
    'root',
    contentForIT.version,
    NodesType.normal,
    CountryCodes.IT,
  ),
  '2021': new QuestionStore(
    2021,
    contentForIT.nodes,
    'root',
    contentForIT.version,
    NodesType.normal,
    CountryCodes.IT,
  ),
  '2022': new QuestionStore(
    2022,
    contentForIT.nodes,
    'root',
    contentForIT.version,
    NodesType.normal,
    CountryCodes.IT,
  ),
  '2023': new QuestionStore(
    2023,
    contentForIT.nodes,
    'root',
    contentForIT.version,
    NodesType.normal,
    CountryCodes.IT,
  ),
};

setQuestionStores(questionStoresIT);

const hydrateQuestionStores = async () => {
  Object.keys(questionStoresIT).forEach(async (year) => {
    await questionStoresIT[year].hydrate();
  });
};

const makeDeleteAllAnswers = (stores: Record<string, QuestionStore>) => (yearToDel?: string) => {
  if (yearToDel) {
    if (stores[yearToDel]) stores[yearToDel].deleteAllResponses();
  } else {
    Object.keys(stores).forEach((year) => {
      if (stores[year]) stores[year].deleteAllResponses();
    });
  }
};

const deleteAllAnswersIT = makeDeleteAllAnswers(questionStoresIT);

const clearAllAnswers = (): void => {
  deleteAllAnswersIT();
};

export { questionStoresIT, hydrateQuestionStores, deleteAllAnswersIT, clearAllAnswers };
