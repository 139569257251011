import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import Config from 'react-native-config';

import { WithToastBannerTogglerProps } from 'src/components/toast-banner';

import { Loading } from '../../components/loading';
import {
  actions as settingsActionCreators,
  selectors as settingsSelectors,
} from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import {
  selectors as devToolsSelectors,
  actions as devToolsActionCreators,
  ConfigKeys,
} from '../../stores/modules/dev-tools';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { stopPerformanceTraceWithLabel } from '../../services/firebase-performance';
import { PerformanceLabels } from '../../services/firebase-performance.labels';
import { WithLogout, withLogout } from '../../hocs/with-logout';
import { actions as OverlayActions } from '../../stores/modules/overlay';

import { HomeDebugMenu } from './home-debug/home-debug-menu';

const mapDispatchToProps = (dispatch: any) => ({
  devToolsActions: bindActionCreators(devToolsActionCreators, dispatch),
  settingsActions: bindActionCreators(settingsActionCreators, dispatch),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
  selectedYear: settingsSelectors.selectedYear(stores),
  devToolsTaxSystem: devToolsSelectors.getConfig(stores, ConfigKeys.TaxSystem),
  devToolsPackageId: devToolsSelectors.getConfig(stores, ConfigKeys.PackageId),
  devToolsDeviceId: devToolsSelectors.getConfig(stores, ConfigKeys.DeviceId),
  devToolsIsEnglishDisabled: devToolsSelectors.isEnglishDisabled(stores),
  userId: userAuthSelectors.getUserId(stores),
  userEmail: userAuthSelectors.getEmailAddress(stores),
  isAuthenticated: userAuthSelectors.isAuthenticated(stores),
  locale: settingsSelectors.selectedLocale(stores),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  detectedCountry: string;
} & WithToastBannerTogglerProps &
  WithNavigation &
  ReduxProps &
  WithLogout;

type State = {
  showDebugScreen: boolean;
};

class UndecoratedHome extends React.Component<Props, State> {
  state = {
    showDebugScreen: Config.SHOW_DEBUG_SCREEN === 'true',
  };

  componentDidMount() {
    if (this.state.showDebugScreen)
      stopPerformanceTraceWithLabel(PerformanceLabels.appLaunch, {
        taxCountry: this.props.selectedCountry,
        isAuthenticated: this.props.isAuthenticated ? 'yes' : 'no',
      });
  }

  render() {
    return this.state.showDebugScreen ? <HomeDebugMenu {...this.props} /> : <Loading />;
  }
}

export const Home = compose<any>(withLogout, withNavigation, connector)(UndecoratedHome);
