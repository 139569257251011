import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import Config from 'react-native-config';

import { NavButton } from 'src/taxfix-components/src';
import { Document } from 'src/types/documents';
import { navigationActions } from 'src/routes/intl/config-common';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { DocumentPreview } from '../../../components/document-preview';
import { theme, Section, useLargeScreenQuery } from '../../../components/core';
import { isNative, isWeb } from '../../../utils/platform';

const styles = StyleSheet.create({
  section: {
    height: '100%',
    paddingTop: 40,
  },
  sectionSmall: {
    paddingTop: 0,
  },
  container: {
    flex: 1,
  },
  containerSmall: {
    height: '100%',
  },
  containerLarge: {
    height: 674,
  },
  bottomBar: {
    height: 50,
  },
  bottomBarMobile: {
    borderTopWidth: 1,
    borderTopColor: theme.color.border,
  },
});

type Props = {
  document: Document;
  token: string;
};

const RequiredDocumentsPreviewComponent = ({
  document: { uri, contentType, fileName },
  token,
}: Props): JSX.Element => {
  const headers = {
    Authorization: `Token ${token}`,
    Accept: contentType || 'application/json',
  };
  const [source, setSource] = React.useState({
    uri: `${Config.API_BASE_URL}${uri}`,
    method: 'GET',
    headers,
  });
  const isPdf = contentType === 'application/pdf';
  const [loading, setLoading] = React.useState(true);
  const { getTranslationText } = useItalyIntl();

  React.useEffect(() => {
    if (!(isNative && isPdf)) {
      setLoading(true);
      fetch(`${Config.API_BASE_URL}${uri}`, {
        method: 'GET',
        headers,
      })
        .then((response) => response.blob())
        .then(async (blob: Blob) => {
          const url = URL.createObjectURL(blob);
          setSource({
            ...source,
            uri: url,
          });
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const isLargeScreen = useLargeScreenQuery();
  const backNavigationText = getTranslationText('web.navigation.italy.back');

  return (
    <>
      {isLargeScreen && <NavButton onPress={navigationActions.back} text={backNavigationText} />}
      <Section
        size="wide"
        centered
        style={[styles.section, !isLargeScreen ? styles.sectionSmall : undefined]}
      >
        <View
          style={
            isWeb
              ? isLargeScreen
                ? styles.containerLarge
                : styles.containerSmall
              : styles.container
          }
        >
          {!loading && <DocumentPreview source={source} isPdf={isPdf} fileName={fileName} />}
        </View>
      </Section>
    </>
  );
};

export { RequiredDocumentsPreviewComponent };
