import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useLargeScreenQuery } from 'src/components/core';
import navigate, { Action, Navigation, NavigationParams } from 'src/routes/navigate';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { useNavigation } from 'src/hooks/navigation-hook';
import { ScreenName } from 'src/types/screen-name';

import { ProductBundleDetailsContainer } from './product-bundle-details-container';

type ActionParams = {
  showPremiumProduct?: boolean;
};

type Props = Navigation<
  ScreenName.ProductBundleDetails,
  {
    ProductBundleDetails: NavigationParams<ActionParams>;
  }
>;

export const toProductBundleDetails: Action = navigate(ScreenName.ProductBundleDetails, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ProductBundleDetails,
  },
});

export const ProductBundleDetails: React.FC<Props> = () => {
  const isLargeScreen = useLargeScreenQuery();
  const {
    navigationParams: { showPremiumProduct },
  } = useNavigation();

  const pageTitle = showPremiumProduct
    ? 'it.product-bundle-details-screen.premium.nav.title'
    : 'it.product-bundle-details-screen.nav.title';

  useDefaultNavigationOptions(isLargeScreen ? { id: pageTitle } : undefined, {
    headerBackTitleVisible: false,
  });

  return <ProductBundleDetailsContainer />;
};
