import React from 'react';
import { Pressable, useTheme } from 'native-base';

import { Icon } from 'src/taxfix-components/src';

export type RateIconProps = {
  onRateSelected: (value: number) => void;
  rateValue: number;
  icon: string;
};

export const RateIcon = ({ onRateSelected, rateValue, icon }: any) => {
  const { colors } = useTheme();
  return (
    <Pressable
      onPress={() => onRateSelected(rateValue)}
      height="24px"
      width="24px"
      borderRadius="10px"
      marginRight="12px"
      marginLeft="12px"
      justifyContent="center"
      alignItems="center"
    >
      <Icon size="24px" path={[icon]} color={colors.brand.brandGreen} />
    </Pressable>
  );
};
