const invalidVoucher = [
  'resource_not_found',
  'voucher_not_active',
  'voucher_expired',
  'voucher_disabled',
  'quantity_exceeded',
];

const customerNotEligible = ['customer_not_elligible'];

export const instantFilingInvalidVoucher = 'instant_filing_invalid_voucher';

export enum CouponInputErrorsNamespace {
  ES = 'es',
  SUBMISSION = 'submission',
}

export enum CouponInputErrors {
  NOT_VALID_COUPON = 'NOT_VALID_COUPON',
  COUPON_ERROR = 'COUPON_ERROR',
  CUSTOMER_NOT_ELIGIBLE = 'CUSTOMER_NOT_ELIGIBLE',
  INSTANT_FILING_VOUCHER_NOT_VALID = 'INSTANT_FILING_VOUCHER_NOT_VALID',
}

export const CouponInputErrorMessage = {
  [CouponInputErrors.NOT_VALID_COUPON]: 'payment.coupon.not-valid',
  [CouponInputErrors.COUPON_ERROR]: 'payment.coupon.error',
  [CouponInputErrors.CUSTOMER_NOT_ELIGIBLE]: 'payment.coupon.customer-not-eligible',
  [CouponInputErrors.INSTANT_FILING_VOUCHER_NOT_VALID]: 'payment.coupon.instant-filing.not-valid',
};

export const getCouponInputError = (errorMessage: string): CouponInputErrors => {
  if (errorMessage === instantFilingInvalidVoucher)
    return CouponInputErrors.INSTANT_FILING_VOUCHER_NOT_VALID;
  if (invalidVoucher.includes(errorMessage)) return CouponInputErrors.NOT_VALID_COUPON;
  if (customerNotEligible.includes(errorMessage)) return CouponInputErrors.CUSTOMER_NOT_ELIGIBLE;
  return CouponInputErrors.COUPON_ERROR;
};

export const getCouponInputErrorMessageKey = (
  errorType: CouponInputErrors,
  namespace: CouponInputErrorsNamespace = CouponInputErrorsNamespace.SUBMISSION,
): string => `${namespace}.${CouponInputErrorMessage[errorType]}`;

export const getCouponInputErrorMessageKeyFromMessage = (
  errorMessage: string,
  namespace: CouponInputErrorsNamespace = CouponInputErrorsNamespace.SUBMISSION,
): string => getCouponInputErrorMessageKey(getCouponInputError(errorMessage), namespace);
