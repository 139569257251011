import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

type Props = ListRowComponentPropsType<any> & WithNavigation;

const getMenuItemTranslationKey = (country: CountryCodes) => {
  switch (country) {
    case CountryCodes.IT:
      return 'account.it.submissions.download';
    default:
      return menuItem.submissionsDownload;
  }
};

const SubmissionDownload = (props: Props) => {
  const { showIcon = false } = props;
  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions } = props;
    trackMenuItem(item);
    navigationActions.toSubmissionsDownload('screen');
  };

  const country = useSelector(settingsSelectors.selectedCountry);

  return props.children({
    hide: false,
    data: {
      iconName: showIcon ? 'menuItems.documents' : undefined,
      translationKey: getMenuItemTranslationKey(country),
      testId: menuItem.submissionsDownload,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const SubmissionDownloadMenu = compose(withNavigation)(SubmissionDownload);
