import * as React from 'react';
import { SectionList } from 'react-native';

import { testID } from '../common/testID';

import Separator from './separator';
import SectionListHeader from './section-list-header';

type Props = React.ComponentProps<typeof SectionList> & {
  testId?: string;
};

const List = ({ testId, ...rest }: Props) => (
  <SectionList
    ItemSeparatorComponent={Separator}
    renderSectionHeader={SectionListHeader}
    SectionSeparatorComponent={Separator}
    {...testID(testId)}
    {...rest}
  />
);

List.defaultProps = { ...(SectionList as any).defaultProps };
export default List;
