import { migration as migration0 } from './0-rename-bootstrap-into-settings';
import { migration as migration2 } from './2-add-country-specifics-into-settings';
import { migration as migration3 } from './3-add-firebase-userproperty-into-settings';
import { migration as migration4 } from './4-add-country-year-specifics-into-settings';
import { migration as migration5 } from './5-remove-unsupported-users';
import { migration as migration6 } from './6-user-profile-versioning';
import { migration as migration8 } from './8-keep-france-free-flow-for-existing-users';
import { migration as migration9 } from './9-remove-france-simulation';
import { migration as migration10 } from './10-update-50-payout-fake-door-test';
import { migration as migration11 } from './11-update-prefill-es-has-fetched-prefill-status';
import { migration as migration12 } from './12-invalidate-france-users';

export const migrations = {
  0: migration0,
  2: migration2,
  3: migration3,
  4: migration4,
  5: migration5,
  6: migration6,
  8: migration8,
  9: migration9,
  10: migration10,
  11: migration11,
  12: migration12,
};
