import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as SyncAnswersActions } from '../stores/modules/sync-answers';
import initial from '../stores/store/initial';

type Props = {
  syncAnswers: typeof initial.syncAnswers;
  syncAnswersActions: typeof SyncAnswersActions;
};

const mapStateToProps = (state: typeof initial) => ({
  syncAnswers: state.syncAnswers,
});

const mapDispatchToProps = (dispatch: any) => ({
  syncAnswersActions: bindActionCreators(SyncAnswersActions, dispatch),
});

export const withSyncAnswers = (WrappedComponent: any) => {
  class Enhancer extends React.PureComponent<any & Props> {
    render() {
      const { syncAnswers, syncAnswersActions, ...rest } = this.props;
      return (
        <WrappedComponent
          syncMetaMapAllCountries={syncAnswers}
          syncAnswersActions={syncAnswersActions}
          {...rest}
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Enhancer);
};
