import * as React from 'react';
import { ScrollView } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { useLargeScreenQuery } from 'src/components/core';
import { Container, Footer, Image, NavButton, Text } from 'src/taxfix-components/src';

type TailoredExperienceIntroProps = {
  firstName: string;
  getTranslationText: (id: string, values?: any) => string;
  onBackButtonPress: () => void;
  onStartPress: () => void;
  onSkipPress: () => void;
};

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

export const Component: React.FC<TailoredExperienceIntroProps> = ({
  firstName,
  getTranslationText,
  onBackButtonPress,
  onStartPress,
  onSkipPress,
}) => {
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
  });
  const pageContainerWidth = useBreakpointValue({ md: '580px' });
  const pageContainerMargin = useBreakpointValue({ base: '24px', md: '0px' });
  const footerCtaStyles = useBreakpointValue({
    base: { width: 'full' },
    md: { width: 'auto', px: '20px' },
  });

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <NavButton
          onPress={onBackButtonPress}
          text={getTranslationText('tailored-experience-intro.back-button.text')}
        />
      )}

      <ScrollView contentContainerStyle={{ height: '100%' }}>
        <Container
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          flex={1}
          width={pageContainerWidth}
          mx={pageContainerMargin}
        >
          <Image name="taxExpertOnComputer" width={[158, 207]} height={[158, 207]} />
          <Text
            mt="50px"
            mb="16px"
            color="greytones.backgroundDark"
            variant={titleVariant}
            value={getTranslationText('tailored-experience-intro.title', {
              firstName,
              lineBreak: isLargeScreen ? ' ' : '\n',
            })}
            textAlign="center"
          />
          <Text
            color="greytones.textDefault"
            variant="bodyMBook"
            value={getTranslationText('tailored-experience-intro.description', {
              bold: getBoldText,
            })}
            textAlign="center"
          />
        </Container>
      </ScrollView>

      <Footer
        primaryButtonTitle={getTranslationText('tailored-experience-intro.primary-cta.label')}
        primaryButtonProps={footerCtaStyles}
        onPressPrimary={onStartPress}
        secondaryButtonTitle={getTranslationText('tailored-experience-intro.secondary-cta.label')}
        secondaryButtonProps={{ ...footerCtaStyles, variant: 'tertiary', borderWidth: '0px' }}
        onPressSecondary={onSkipPress}
        mt="auto"
        px="24px"
        backgroundColor="white"
        borderTopWidth={0}
      />
    </>
  );
};
