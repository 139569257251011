import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { IntlShape, injectIntl } from 'react-intl';
import { CountryCodes } from '@taxfix/types';

import initial from '../stores/store/initial';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import { actions as prefillActions } from '../stores/modules/prefill';
import { formatDate } from '../i18n';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { QuizmasterLight, withQuizmasterLight } from '../utils/with-quizmaster-light';
import { flagsQuestionIds, prefillQuestionIds, wayQuestionIds } from '../common/constants-it';

type Props = {
  createPrefill: typeof prefillActions.createPrefill;
  selectedCountry: CountryCodes;
  selectedYear: number;
  accessToken: string;
  intl: IntlShape;
  quizmasterLight: QuizmasterLight;
};

const mapStateToProps = (state: typeof initial) => ({
  selectedYear: settingsSelectors.selectedYear(state),
  selectedCountry: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
  userId: userAuthSelectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  createPrefill: bindActionCreators(prefillActions.createPrefill, dispatch),
});

export type WithPrefillCreation = {
  createPrefill: () => Promise<void>;
};
export const withPrefillCreation = (WrappedComponent: any) => {
  const WithPrefillCreationUndecorated = ({
    accessToken,
    selectedCountry,
    selectedYear,
    createPrefill: createPrefillAction,
    intl,
    quizmasterLight,
    ...rest
  }: Props) => {
    const consentReferenceDate = new Date();

    const getLocalizedDate = () => formatDate(intl, consentReferenceDate);

    const createPrefill = async () => {
      const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
      const lastName = quizmasterLight[wayQuestionIds.lastName].answer;

      // when user selected filing with prefill data (prefill mandate)
      // we add the tax ID in prefill creation and we sign the prefill mandate document
      const isSubmissionWithPrefillMandate =
        quizmasterLight[flagsQuestionIds.prefillMandateSigned]?.answer;
      const taxId = quizmasterLight[prefillQuestionIds.taxId]?.answer;

      try {
        await createPrefillAction(accessToken, {
          taxId,
          firstName,
          lastName,
          // @ts-ignore
          countryCode: selectedCountry,
          year: selectedYear,
          title: intl.formatMessage({
            id: 'it-prefill.mandate-screen.consent.title',
          }),
          lead: intl.formatMessage(
            {
              id: 'it-prefill.mandate-screen.consent.text',
            },
            {
              date: getLocalizedDate(),
              taxYear: consentReferenceDate.getFullYear() - 1,
            },
          ),
          permissionText: intl.formatMessage({
            id: 'prefill.mandate.submit.confirm.data',
          }),
          date: getLocalizedDate(),
          skipSign: !isSubmissionWithPrefillMandate,
        });
        Analytics.log(AnalyticsEvent.prefillCreationSuccess);
      } catch (error) {
        Analytics.log(AnalyticsEvent.prefillCreationFailed, {
          error: error.message,
        });
        throw new Error(error);
      }
    };

    return <WrappedComponent createPrefill={createPrefill} {...rest} />;
  };

  return compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    withQuizmasterLight([
      wayQuestionIds.firstName,
      wayQuestionIds.lastName,
      prefillQuestionIds.taxId,
      flagsQuestionIds.prefillMandateSigned,
    ]),
  )(WithPrefillCreationUndecorated);
};
