import React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { View, Switch, StyleSheet } from 'react-native';
import { useIntl } from 'react-intl';
import { useBreakpointValue } from 'native-base';

import { NavButton } from 'src/taxfix-components/src';
import {
  Box,
  ScrollView,
  DisplayTitleText,
  BodySecondaryText,
  TitleText,
  Section,
} from 'src/components/core';
import { withNavigation, WithNavigation } from 'src/hocs/with-navigation';
import LinkableText from 'src/components/auth/LinkableText';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { usePrivacySettings } from 'src/hooks/privacy-settings-hook';

import policyContent from '../../../../assets/docs';
import { useItalyIntl } from '../../_hooks/use-italy-intl';

const mapStateToProps = (state: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
  pushToken: settingsSelectors.selectPushToken(state),
  email: userAuthSelectors.getEmailAddress(state),
  userId: userAuthSelectors.getUserId(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & WithNavigation;

const styles = StyleSheet.create({
  titleText: {
    marginBottom: 10,
  },
});

const PrivacySettings = (props: Props) => {
  const { marketingEmails, handleMarketingChange, togglingMarketingEmails } = usePrivacySettings();
  const intl = useIntl();
  const { getTranslationText } = useItalyIntl();
  const { selectedCountry, navigationActions } = props;
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const backNavigationText = getTranslationText('account.title');

  const handleLinkPress = (link: string) => {
    if (link === 'privacy') {
      const content = policyContent[link];
      navigationActions.toPrivacyPolicy('modal', {
        content: content(intl.locale, selectedCountry),
        contentStyles: '',
      });
    }
  };

  const handleDeleteAccount = () => {
    const { navigationActions } = props;
    navigationActions.toDeleteAccountIT('screen');
  };

  return (
    <Box flex>
      <Box bottom={2}>
        {!isSmallScreen && <NavButton onPress={navigationActions.back} text={backNavigationText} />}
      </Box>
      <Box alignHorizontally="center" flex>
        <Section size="wide">
          <ScrollView>
            <Box flex right={2} left={2} bottom={2}>
              <DisplayTitleText id="it.account.privacy-settings.title" style={[styles.titleText]} />
              <View>
                <BodySecondaryText>
                  <LinkableText
                    translationKey="it.account.privacy-settings.description"
                    onPress={handleLinkPress}
                  />
                </BodySecondaryText>
              </View>
              {/* Marketing emails */}
              <Box direction="row" top={4} alignVertically="center">
                <Box expand>
                  <TitleText id="it.account.privacy-settings.marketing-emails.title" />
                </Box>
                <Box left={1}>
                  <Switch
                    disabled={togglingMarketingEmails}
                    value={marketingEmails}
                    onValueChange={handleMarketingChange}
                    testID="it.account.privacy-settings.marketing-emails.switch"
                  />
                </Box>
              </Box>
              <Box right={6}>
                <BodySecondaryText id="it.account.privacy-settings.marketing-emails.text" />
              </Box>
            </Box>
          </ScrollView>
        </Section>
      </Box>
    </Box>
  );
};

export default compose<any>(withNavigation, connector)(PrivacySettings);
