// TODO remove this file when we adopt colours of the theme to NB and are able to use colorScheme prop

/**
 * Temporary function to provide Button's behaviour until we adopt the theme to NB Button
 *
 * @param color HEX colour to be transformed
 * @param amount Negative or positive number to darken or to lighten the colour
 * @returns Darkened or lightened colour
 */

export const lightenDarkenColor = (color: string, amount: number): string => {
  let usePound = false;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  let red = (num >> 16) + amount;

  if (red > 255) {
    red = 255;
  } else if (red < 0) {
    red = 0;
  }

  let blue = ((num >> 8) & 0x00ff) + amount;

  if (blue > 255) {
    blue = 255;
  } else if (blue < 0) {
    blue = 0;
  }

  let green = (num & 0x0000ff) + amount;

  if (green > 255) {
    green = 255;
  } else if (green < 0) {
    green = 0;
  }

  return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
};

// Temporary function to get HEX colour from the theme

export const getHexColorFromTheme = (color: string, themeColors: any): string => {
  const col: Array<string> = color.split('.');
  return themeColors[col[0]][col[1]];
};
