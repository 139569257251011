import React from 'react';

import { theme, BodyTitleText, LinkText, TextProps } from '../../../core';

type Props = {
  linkable?: boolean;
  color?: string;
} & TextProps;

function SummaryText({ linkable, color, ...props }: Props) {
  if (!linkable || color) {
    return (
      <BodyTitleText
        {...props}
        style={{
          color: color || theme.color.secondaryText,
        }}
      />
    );
  }

  return <LinkText {...props} />;
}

export default SummaryText;
