import { CountryCodes } from '@taxfix/types';

export type FormattableAddress = {
  street?: string;
  number?: string;
  numberExtension?: string | null | undefined;
  postalCode?: string;
  city?: string;
};

const formatAddressForGermany = ({ street, number, postalCode, city }: FormattableAddress) =>
  `${street || ''} ${number || ''}, ${postalCode || ''} ${city || ''}`;

export const formatAddressForCountry = (address: FormattableAddress, country: CountryCodes) => {
  switch (country) {
    default:
      return formatAddressForGermany(address);
  }
};
