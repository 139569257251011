/* global fetch:false FormData:false */
import { Platform } from 'react-native';

type FileDescriptor = {
  uri: string;
  name: string;
  type: string;
};
type FormFields = Record<string, string | FileDescriptor | boolean | number>;
export const createFormData = async (data: FormFields) => {
  const formData = new FormData();
  await Promise.all(
    Object.keys(data).map((fieldName) => {
      const fieldValue = data[fieldName];

      if (Platform.OS === 'web' && typeof fieldValue === 'object') {
        return fetch(fieldValue.uri)
          .then((res) => res.blob())
          .then((blob) => {
            formData.append(fieldName, blob, fieldValue.name);
          });
      }

      // @ts-ignore
      formData.append(fieldName, fieldValue);
      return Promise.resolve();
    }),
  );
  return formData;
};
