import React from 'react';
import { ScrollView, Keyboard, Platform, EmitterSubscription } from 'react-native';

import { Layout } from '../../components/auth/Layout';
import ErrorMessage, { ErrorKey } from '../../components/auth/ErrorMessage';
import { Box, theme, BodyTitleText } from '../../components/core';

import OtcCode from './otc-code';

type Props = {
  isLoading: boolean;
  errorKey: ErrorKey | null | undefined;
  messageKey: string | null | undefined;
  onConfirm: () => Promise<any>;
  onOtcResend: () => void;
  onChange: (value: string) => void;
  otcCodeValue: string;
};
const errorStyle = {
  color: theme.color.errorTextLight,
};
const messageStyle = {
  color: theme.color.primary,
};
export default class OtcValidation extends React.Component<Props> {
  componentDidMount() {
    this.keyboardListener = Keyboard.addListener(
      Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow',
      this.handleKeyboardShown,
    );
  }

  componentWillUnmount() {
    this.keyboardListener?.remove();
  }

  scrollViewRef: ScrollView | null | undefined;

  keyboardListener?: EmitterSubscription;

  setScrollViewRef = (ref: any) => {
    this.scrollViewRef = ref;
  };

  handleKeyboardShown = () => {
    if (this.scrollViewRef) {
      this.scrollViewRef.scrollToEnd({
        animated: true,
      });
    }
  };

  handleOnConfirm = () => {
    const { onConfirm } = this.props;
    onConfirm();
  };

  render() {
    const { errorKey, isLoading, onOtcResend, messageKey, onChange, otcCodeValue } = this.props;
    const message =
      errorKey != null ? (
        <ErrorMessage errorKey={errorKey} style={errorStyle} />
      ) : messageKey != null ? (
        <BodyTitleText id={messageKey} testId={messageKey} style={messageStyle} />
      ) : null;
    return (
      <Layout
        leadKey="account.otc-verification.lead"
        titleKey="account.otc-verification.title"
        imageName={null}
        confirmKey="account.otc-verification.confirm"
        onConfirm={this.handleOnConfirm}
        loading={isLoading}
        disabled={!otcCodeValue}
        scrollViewRef={this.setScrollViewRef}
        scrollable
      >
        <OtcCode
          value={otcCodeValue}
          onChange={onChange}
          isLoading={isLoading}
          onOtcResend={onOtcResend}
          isValidationError={errorKey != null}
        />
        <Box bottom={2}>{message}</Box>
      </Layout>
    );
  }
}
