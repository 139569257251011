import { getNavigationActions } from '../../../../routes/config-util';

import { DebugMenuOptions, MenuOption } from './types';

export function buildLockScreenOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handlePressLockScreen = () => {
    const params = {
      showHeader: true,
      onConfirm: async () => {
        navigationActions.reset({
          index: 0,
          actions: [getNavigationActions().toUniversalStatus('screen')],
        });
      },
    };
    navigationActions.toLock('screen', params);
  };

  return { text: 'Lock Screen', onPress: handlePressLockScreen, showArrow: true };
}
