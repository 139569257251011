import React from 'react';
import { Platform } from 'react-native';
import { Image as NbImage } from 'native-base';

import { ImageProps } from './types';
import { getIconContents } from './utils';

const WebImage = ({ name, path, size = 'xl', resizeMode = 'center', ...rest }: ImageProps) => {
  const source = getIconContents(name, path);

  return <NbImage source={source} resizeMode={resizeMode} size={size} {...rest} />;
};

/**
 * Our component for Image, adds few things on top of NB Image component
 * @param name a predefined string that maps to an img (use autocompletion)
 * @param path direct path to the image asset
 * @param source here you can pass {uri: URL address} object
 * @param ResizeModes specify a Resize mode
 */

export const Image = (props: ImageProps) => {
  if (Platform.OS !== 'web') {
    const { default: Svg } = getIconContents(props.name, props.path) || {};

    if (Svg) {
      return <Svg {...props} />;
    }
  }
  return <WebImage {...props} />;
};
