import * as React from 'react';
import { useRoute } from '@react-navigation/native';

import { Dashboard as DashboardContainer } from 'src/screens/containers/dashboard';

import navigate, { Action } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';
import { ScreenName } from '../../../types/screen-name';

type Params = {
  transitionAnimationEnabled: boolean;
};

export const toDashboard: Action = navigate(ScreenName.Dashboard, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.Dashboard,
  },
});

export const Dashboard: React.FC = () => {
  const route = useRoute();
  const { transitionAnimationEnabled = true } = route.params as Params;

  useDefaultNavigationOptions(
    { id: 'it.dashboard.nav.title' },
    {
      animationEnabled: transitionAnimationEnabled,
    },
  );

  // TODO remove ts-ignore
  // @ts-ignore
  return <DashboardContainer />;
};
