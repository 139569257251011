import React from 'react';
import { View, StyleSheet } from 'react-native';

import Image from '../core/Image';
import { theme } from '../core/theme';

const checkSign = 'category-icons.icon-category-questions-multiple-select';
const styles = StyleSheet.create({
  checkboxIcon: {
    width: 24,
    height: 24,
    borderWidth: 1.5,
    borderRadius: 5,
    justifyContent: 'center',
    marginRight: 10,
  },
  checkboxIconUnchecked: {
    borderColor: theme.color.fill,
  },
  checkboxIconDisabled: {
    backgroundColor: theme.color.disabledGray,
  },
});
type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  color?: string;
  uncheckedColor?: string;
  testId?: string;
};
export const CheckboxIcon = ({
  isChecked,
  isDisabled,
  color = theme.color.primary,
  uncheckedColor = 'transparent',
  testId,
}: Props) => {
  const style = [
    styles.checkboxIcon,
    isChecked
      ? {
          backgroundColor: color,
          borderWidth: 0,
        }
      : [
          styles.checkboxIconUnchecked,
          {
            backgroundColor: uncheckedColor,
          },
        ],
    isDisabled ? styles.checkboxIconDisabled : null,
  ];
  return (
    <View style={style} testID={testId}>
      {isChecked && <Image name={checkSign} />}
    </View>
  );
};
