import { useMediaQuery } from 'native-base';
import { Platform } from 'react-native';
import { hasNotch } from 'react-native-device-info';

import { isNative } from 'src/utils/platform';

const useMobile = (): {
  isMobile: boolean;
  isSmallScreen?: boolean;
  hasNotch: boolean;
  isiPhoneX: boolean;
  isXSmallScreen?: boolean;
} => {
  const _hasNotch = hasNotch ? hasNotch() : false;
  const isiPhoneX = isNative && Platform.OS === 'ios';
  const [isMobileScreen] = useMediaQuery({ maxWidth: 480 });
  const [isXSmallScreen] = useMediaQuery({ maxHeight: 600 });

  return {
    isMobile: isMobileScreen || isNative,
    isSmallScreen: isMobileScreen,
    hasNotch: _hasNotch,
    isiPhoneX,
    isXSmallScreen,
  };
};

export default useMobile;
