import React from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '../components/core';

import IntlComponent, { Props } from './IntlComponent';

const styles = StyleSheet.create({
  text: {
    fontSize: 24,
  },
});

const Title = ({ style, ...props }: { style: Record<string, any> }) => (
  <Text style={[styles.text, style] as any} {...props} />
);

export default (props: Props) => <IntlComponent {...props} Component={Title} />;
