import { flagsMigration } from 'src/screens/status-screen/middlewares/flags-migration';
import {
  checkNotificationsPermissions,
  shouldSkipAskPermission,
} from 'src/screens/status-screen/middlewares/notifications';
import { fetchPrefills } from 'src/screens/status-screen/middlewares/prefill';
import { profileMigration } from 'src/screens/status-screen/middlewares/profile-migration';
import { redirectToNotifications } from 'src/screens/status-screen/middlewares/redirect';
import {
  checkActiveSubmission,
  checkPostSubmission,
  redirectDashboard,
  redirectDonationAndSubmission,
  skipAlreadySubmitted,
  statusMiddlewareFetch,
} from 'src/screens/status-screen/middlewares/submission';
import { checkAndUpdateUserData } from 'src/screens/status-screen/middlewares/user-profile';
import { StatusConfig, StatusStates } from 'src/stores/modules/status';

import {
  checkIdDocument,
  redirectIDDocumentUpload,
  skipIfIDUploaded,
} from '../middlewares/identification';
import { checkPayment, redirectContactAndPayment } from '../middlewares/payment';
import { checkTaxIdQF, redirectTaxIdQF } from '../middlewares/tax-id-question-flow';
import { checkPrefillDocumentsDownloaded, redirectSPIDLogin } from '../middlewares/spid-login';
import { redirectResultScreen } from '../middlewares/result-screen';
import { checkSPIDConsentSigned, redirectSPIDConsentSign } from '../middlewares/spid-consent';

// INFO: this status config only leads user up to the Submission without showing the status screen
// no resubmission flow
export const statusConfig: StatusConfig = {
  title: {
    id: 'status.it.title',
  },
  subtitle: {
    id: 'status.it.subtitle',
  },
  steps: [
    {
      id: StatusStates.StoresMigrations,
      middlewares: [profileMigration, flagsMigration],
    },
    {
      id: StatusStates.FetchSubmissions,
      middlewares: [statusMiddlewareFetch],
    },
    {
      id: StatusStates.EnableNotifications,
      skip: [shouldSkipAskPermission],
      middlewares: [checkNotificationsPermissions],
      redirect: redirectToNotifications,
    },
    {
      id: StatusStates.CheckAndUpdateUserData,
      skip: [skipAlreadySubmitted],
      middlewares: [checkAndUpdateUserData],
    },
    {
      id: StatusStates.TaxIdQF,
      skip: [skipAlreadySubmitted],
      middlewares: [checkTaxIdQF],
      redirect: redirectTaxIdQF,
    },
    {
      id: StatusStates.SPIDConsent,
      skip: [skipAlreadySubmitted],
      middlewares: [checkSPIDConsentSigned],
      redirect: redirectSPIDConsentSign,
    },
    {
      id: StatusStates.FetchPrefills,
      middlewares: [fetchPrefills],
    },
    {
      id: StatusStates.PrefillSPIDLogin,
      skip: [skipAlreadySubmitted, skipIfIDUploaded],
      middlewares: [checkPrefillDocumentsDownloaded],
      redirect: redirectSPIDLogin,
    },
    {
      id: StatusStates.ResultScreen,
      skip: [skipAlreadySubmitted, skipIfIDUploaded],
      middlewares: [checkPayment],
      redirect: redirectResultScreen,
    },
    {
      id: StatusStates.Payment,
      skip: [skipAlreadySubmitted],
      middlewares: [checkPayment],
      redirect: redirectContactAndPayment,
    },
    {
      id: StatusStates.IdDocument,
      skip: [skipAlreadySubmitted],
      middlewares: [checkIdDocument],
      redirect: redirectIDDocumentUpload,
    },
    {
      id: StatusStates.Submission,
      skip: [skipAlreadySubmitted],
      middlewares: [checkActiveSubmission],
      redirect: redirectDonationAndSubmission,
    },
    {
      id: StatusStates.PostSubmission,
      middlewares: [checkPostSubmission],
      redirect: redirectDashboard,
    },
  ],
};
