export class StripePaymentError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'StripePaymentError';
  }
}

export const StripePaymentErrorMessages = {
  default: 'Stripe payment failed',
  failed: 'Create or Update payment failed',
  invalidMethod: 'Changing payment method is not allowed',
  updateFailed: 'Could not update the payment',
};
