import * as React from 'react';
import has from 'lodash/has';
import { checkPlausibility } from '@taxfix/quizmaster';
import { Answer, TreeNode, PlausibilityErrors } from '@taxfix/quizmaster/dist/types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Analytics, { AnalyticsEvent, EventName } from '../biz-logic/analytics';
import createCache from '../utils/createCache';
import QuestionStore from '../stores-legacy/QuestionStore';
import withQuestionStore from '../utils/withQuestionStore';
import { actions } from '../stores/modules/plausibility-checks';

type OnAnswer = (node: TreeNode, answer: Answer) => void;
type Props = {
  value: Answer;
  onAnswer: OnAnswer;
  questionStore: QuestionStore;
  children: (arg0: { handleOnAnswer: OnAnswer; warnings: PlausibilityErrors[] }) => React.ReactNode;
  year: number;
  addWarning: (year: number, questionId: string) => void;
  removeWarning: (year: number, questionId: string) => void;
};
type State = {
  checked: boolean;
  warnings: PlausibilityErrors[];
};

class PlausibilityCheck extends React.Component<Props, State> {
  state = {
    checked: false,
    warnings: [],
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        checked: false,
        warnings: [],
      });
    }
  }

  handleOnAnswer = (node: TreeNode, answer: Answer): any => {
    const warnings = this.doPlausibilityCheck(node, answer);
    const hasWarnings = warnings && warnings.length > 0;

    if (this.state.checked || (warnings && !warnings.length)) {
      if (!hasWarnings) {
        this.props.removeWarning(this.props.year, node.questionId);
      }

      this.props.onAnswer(node, answer);
      this.trackAnswered(node, this.state.warnings);
      return;
    }

    if (hasWarnings) {
      this.props.addWarning(this.props.year, node.questionId);
      this.trackShown(node, warnings);
      this.setState({
        checked: true,
        warnings,
      });
    }
  };

  createResponsesObject = (node: TreeNode, answer: Answer) => {
    const { questionStore } = this.props;
    const response = {
      [node.id]: {
        answer,
        answerID: node.id,
        questionID: node.questionId,
        skipped: false,
        year: questionStore.year,
      },
    };
    const responses = { ...questionStore.responses, ...response };
    return responses;
  };

  trackShown = (node: any, warnings: any) => {
    const { year } = this.props;
    this.trackPlausibilityCheck(`singleQuestionPlausibilityCheckShown`, year, node, warnings);
  };

  trackAnswered = (node: any, warnings: any) => {
    const { year } = this.props;
    this.trackPlausibilityCheck(`singleQuestionPlausibilityCheckAnswered`, year, node, warnings);
  };

  trackPlausibilityCheck = (eventName: EventName, year: any, node: any, warnings: any) => {
    if (!has(AnalyticsEvent, eventName) || !node.rules || node.rules.length === 0) {
      return;
    }

    Analytics.log(AnalyticsEvent[eventName], {
      year,
      questionId: node.questionId,
      errors: JSON.stringify(warnings),
    });
  };

  doPlausibilityCheck = (node: TreeNode, answer: Answer): PlausibilityErrors[] => {
    const { questionStore } = this.props;
    const responses = this.createResponsesObject(node, answer);
    const warningsResult: PlausibilityErrors[] = checkPlausibility(
      node,
      questionStore.refs,
      responses,
      createCache(),
      questionStore.year,
    );
    // Stupid flow type casting
    const warnings: PlausibilityErrors[] = warningsResult.filter(
      (warning) => warning.type !== 'multiple',
    );
    return warnings;
  };

  render() {
    const { handleOnAnswer } = this;
    const { warnings } = this.state;
    return this.props.children({
      handleOnAnswer,
      warnings,
    });
  }
}

const mapDispatchToProps = {
  addWarning: actions.addWarning,
  removeWarning: actions.removeWarning,
};

export default compose<
  React.ComponentType<Omit<Props, 'questionStore' | 'addWarning' | 'removeWarning'>>
>(
  withQuestionStore,
  connect(null, mapDispatchToProps),
)(PlausibilityCheck);
