import React, { ReactNode } from 'react';
import { compose, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';

import { resolveStatusConfig } from 'src/screens/status-screen/status-config-resolver';
import { OverrideStatusConfigs } from 'src/screens/status-screen/config/types';

import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { selectors as submissionSelectors } from '../../stores/modules/submission';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import {
  actions as getOverlayActions,
  selectors as overlaySelectors,
} from '../../stores/modules/overlay';
import {
  StatusStepNavigationData,
  StatusStepSectionNavigation,
  StatusStepSectionInfoButton,
  actions as statusActions,
  selectors as statusSelectors,
} from '../../stores/modules/status';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { TaxfixLoading } from '../../components/loading';
import { WithAppStateContextType, withAppStateContext } from '../../hocs/with-app-state-context';
import { AppStates } from '../../containers/app-state-context';

import { ItalyStatusScreen } from './it-status-screen-component';

type State = {
  debugRefreshTimestamp: number;
};

const mapStateToProps = (stores: any, ownProps: any) => ({
  shouldFetch: statusSelectors.shouldFetch(stores),
  sections: statusSelectors.getSections(stores),
  stepNavigation: statusSelectors.getNavigation(stores),
  stepIndex: statusSelectors.getStepIndex(stores),
  currentStepSection: statusSelectors.getCurrentStepSection(stores),
  banner: statusSelectors.getBanner(stores),
  secondaryBanner: statusSelectors.getSecondaryBanner(stores),
  redirect: statusSelectors.getRedirect(stores),
  error:
    ownProps.appStateContext.appState === AppStates.Active &&
    !ownProps.appStateContext.locked &&
    statusSelectors.getErrorIfNotCanceled(stores),
  canView: submissionSelectors.canViewPreviousSections(
    stores,
    settingsSelectors.selectedYear(stores) || 0,
  ),
  isFetching: statusSelectors.isFetching(stores),
  watchedValue: statusSelectors.getWatchedValue(stores),
  isEmailConfirmed: userAuthSelectors.isEmailAddressConfirmed(stores),
  showChatBubble: statusSelectors.shouldShowChatBubble(stores),
  overlayName: overlaySelectors.getActiveOverlayName(stores),
  timeout: statusSelectors.getLongestTimeout(stores),
  enhancedLoadingScreenConfig: statusSelectors.getLoadingScreenConfig(stores),
  statusConfig: resolveStatusConfig(stores, ownProps.overrideConfig),
  selectedCountry: settingsSelectors.selectedCountry(stores),
});

const mapDispatchToProps = (dispatch: any) => ({
  initStatus: (config: any) => dispatch(statusActions.initStatus(config)),
  requestRefreshStatus: () => dispatch(statusActions.requestRefreshStatus()),
  fetchStatus: () => dispatch(statusActions.fetchStatus),
  overlayActions: bindActionCreators(getOverlayActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = WithNavigation &
  WithAppStateContextType &
  ReduxProps & {
    overrideConfig?: OverrideStatusConfigs;
    renderSupportButton?: (indent: number) => ReactNode;
  };

class Status extends React.PureComponent<Props, State> {
  unsubscribe?: () => void;

  componentDidMount = () => {
    const { navigation, statusConfig } = this.props;
    this.props.initStatus(statusConfig);

    if (this.props.shouldFetch) {
      this.props.fetchStatus();
    }

    this.unsubscribe = navigation.addListener('focus', this.props.requestRefreshStatus);
  };

  componentDidUpdate(prevProps: Props) {
    const {
      shouldFetch,
      currentStepSection,
      error,
      banner,
      redirect,
      fetchStatus,
      requestRefreshStatus,
      watchedValue,
      isEmailConfirmed,
      isFocused,
      isFetching,
      overlayActions,
      timeout,
      enhancedLoadingScreenConfig,
    } = this.props;

    if (!prevProps.watchedValue && watchedValue) {
      requestRefreshStatus();
    }

    if (!prevProps.isEmailConfirmed && isEmailConfirmed) {
      requestRefreshStatus();
    }

    if (!prevProps.shouldFetch && shouldFetch) {
      fetchStatus();
    }

    if (currentStepSection && prevProps.currentStepSection !== currentStepSection) {
      if (currentStepSection.tracking) {
        Analytics.log(currentStepSection.tracking.eventName, currentStepSection.tracking.params);
      }
    }

    if (error && error !== prevProps.error) {
      Analytics.log(AnalyticsEvent.sectionJourneyFailed, {
        error,
      });
    }

    if (banner && banner !== prevProps.banner) {
      if (banner.tracking) {
        Analytics.log(banner.tracking.eventName, banner.tracking.params);
      }
    }

    if (isFocused && redirect && prevProps.redirect !== redirect) {
      // @ts-ignore
      redirect(this.props.navigationActions);
    }

    if (
      isFetching &&
      !isEmpty(enhancedLoadingScreenConfig) &&
      !isEqual(prevProps.enhancedLoadingScreenConfig, enhancedLoadingScreenConfig)
    ) {
      overlayActions.update({
        enhancedLoadingConfig: enhancedLoadingScreenConfig,
      });
    }

    if (isFocused) {
      if (isFetching && !prevProps.isFetching) {
        overlayActions.show('LoadingOverlay', {
          duration: timeout,
          enhancedLoadingConfig: enhancedLoadingScreenConfig || {},
        });
      }
    }
    if (!isFetching && prevProps.isFetching) {
      overlayActions.update({ shouldClose: true });
    }
  }

  componentWillUnmount = () => {
    const { overlayName, overlayActions } = this.props;

    // make sure LoadingOverlay is closed before navigation
    if (overlayName === 'LoadingOverlay') {
      overlayActions.update({
        shouldClose: true,
      });
    }

    this.unsubscribe?.();
  };

  handlePressAction = () => {
    const { stepNavigation } = this.props;
    const stepNavigationData = stepNavigation as any as StatusStepNavigationData;

    if (stepNavigationData && stepNavigationData.tracking) {
      Analytics.log(stepNavigationData.tracking.eventName, stepNavigationData.tracking.params);
    }

    if (stepNavigationData && stepNavigationData.link) {
      const { link } = stepNavigationData;
      this.props.navigationActions[link.action](link.config, link.params);
    }
  };

  handleViewAction = (navigation: StatusStepSectionNavigation) => {
    const { link } = navigation;
    this.props.navigationActions[link.action](link.config, link.params);
  };

  handlePressTryAgain = () => {
    Analytics.log(AnalyticsEvent.sectionJourneyTryAgainClicked);
    this.props.requestRefreshStatus();
  };

  handleInfoButton = (infoButton?: StatusStepSectionInfoButton) => {
    if (!infoButton) {
      return;
    }

    if (infoButton.tracking) {
      Analytics.log(infoButton.tracking.eventName, infoButton.tracking.params);
    }

    if (infoButton.handleOnPress) {
      infoButton.handleOnPress();
    } else {
      const { navigationActions } = this.props;
      const { content, isMarkdown, translationKey, translationValues } = infoButton;
      const params = {
        content,
        isMarkdown,
        translationKey,
        translationValues,
      };
      navigationActions.toInformation('modal', params);
    }
  };

  render() {
    const {
      sections,
      stepIndex,
      banner,
      secondaryBanner,
      error,
      stepNavigation,
      canView,
      isFetching,
      showChatBubble,
      renderSupportButton,
      statusConfig,
      selectedCountry,
    } = this.props;

    const supportButtonProps = renderSupportButton
      ? {
          renderSupportButton,
          showChatBubble: false,
        }
      : {
          showChatBubble,
        };

    if (isFetching) {
      return (
        <TaxfixLoading
          titleId="status.screen.loading.title"
          subTitleId="status.screen.loading.subtitle"
        />
      );
    }

    if (sections && !isFetching) {
      return (
        <ItalyStatusScreen
          statusConfig={statusConfig}
          onPress={this.handlePressAction}
          onPressTryAgain={this.handlePressTryAgain}
          onSectionView={this.handleViewAction}
          sections={sections}
          stepIndex={stepIndex}
          banner={banner}
          secondaryBanner={secondaryBanner}
          error={error}
          stepNavigation={stepNavigation}
          {...supportButtonProps}
        />
      );
    }

    return null;
  }
}

const StatusContainer = compose<any>(
  withAppStateContext,
  connect(mapStateToProps, mapDispatchToProps),
  withNavigation,
)(Status);
export { StatusContainer };
