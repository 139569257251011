import React from 'react';
import { View } from 'react-native';

import { StyleProp } from '../types';
import { testID } from '../../common/testID';

type WithPadding = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};
export type WithAlignment = {
  alignVertically?: 'start' | 'center' | 'end';
  alignHorizontally?: 'start' | 'center' | 'end';
};
type WithDirection = {
  direction?: 'row' | 'column';
};
type WithFlex = {
  flex?: boolean | number;
  shrink?: boolean | number;
  expand?: boolean | number;
  wrap?: boolean;
};
export type BoxProps = WithPadding &
  WithAlignment &
  WithFlex &
  WithDirection & {
    index?: any;
    children?: React.ReactNode;
    style?: any;
    testId?: string;
    touchable?: boolean;
    onLayout?: (arg0: {
      nativeEvent: {
        layout: {
          y: number;
          height: number;
        };
      };
    }) => void;
  };

const Box = (props: BoxProps) => {
  const { top = 0, left = 0, right = 0, bottom = 0, style: extraStyles } = props;
  let style: StyleProp = {
    paddingTop: top * 10,
    paddingLeft: left * 10,
    paddingRight: right * 10,
    paddingBottom: bottom * 10,
  };
  const { flex, shrink, expand, direction, wrap } = props;

  if (typeof flex === 'number') {
    style.flex = flex;
  } else if (flex === true) style.flex = 1;

  style = { ...style, flexDirection: direction };

  if (expand) {
    // @ts-ignore
    style = { ...style, flexGrow: expand + 0 };
  }

  if (shrink) {
    // @ts-ignore
    style = { ...style, flexShrink: shrink + 0 };
  }

  if (wrap) {
    style = { ...style, flexWrap: 'wrap' };
  }

  const { alignVertically, alignHorizontally } = props;
  let alignItems;

  switch (alignHorizontally) {
    case 'center':
      alignItems = 'center';
      break;

    case 'end':
      alignItems = 'flex-end';
      break;

    case 'start':
      alignItems = 'flex-start';
      break;
    // no default
  }

  let justifyContent: any;

  switch (alignVertically) {
    case 'center':
      justifyContent = 'center';
      break;

    case 'end':
      justifyContent = 'flex-end';
      break;

    case 'start':
      justifyContent = 'flex-start';
      break;
    // no default
  }

  if (style.flexDirection === 'row') {
    [justifyContent, alignItems] = [alignItems, justifyContent];
  }

  style = { ...style, alignItems, justifyContent };
  const { touchable = true } = props;
  return (
    <View
      style={[style, extraStyles]}
      {...testID(props.testId)}
      pointerEvents={touchable ? 'auto' : 'none'}
      onLayout={props.onLayout}
    >
      {props.children}
    </View>
  );
};

export default Box;
