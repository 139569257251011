import React from 'react';
import { useTheme } from 'native-base';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { Button } from 'src/components/core';
import { Testimonials } from 'src/components/testimonials';
import { Container, Text } from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';

const getBoldText = (text: string): JSX.Element => <Text fontWeight="bold" value={text} />;

export type WelcomeOverlayContentProps = {
  onNext: () => void;
  translationValues: {
    userName: string;
  };
};

export const WelcomeOverlayContent = ({
  onNext,
  translationValues,
}: WelcomeOverlayContentProps): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();

  return (
    <>
      <Container flex="1" justifyContent={['center', 'flex-end']} maxWidth="100%">
        <Text
          value={getTranslationText('it.welcome.success-overlay.title', {
            firstName: translationValues.userName,
          })}
          variant={['titleMBold', 'titleXLBold']}
          marginBottom={['4px', '8px']}
          textAlign="center"
          color="greytones.white"
        />
        <Text
          value={getTranslationText('it.welcome.success-overlay.subtitle', { bold: getBoldText })}
          variant={['bodySBook', 'bodyMBook']}
          marginBottom={['32px', '18px']}
          textAlign="center"
          color="greytones.white"
        />
        <Text
          value={getTranslationText('it.welcome.success-overlay.testimonials.caption')}
          variant="bodyXSMedium"
          marginBottom={['8px', '16px']}
          textAlign="center"
          color="greytones.white"
        />
        <Container marginLeft={['24px', '32px']}>
          <Testimonials screenName={ScreenName.Welcome} />
        </Container>
      </Container>
      <Container alignSelf="center" width={['full', '273px']} p={['24px', '32px']}>
        <Button
          fillColor={colors.greytones.white}
          textColor={colors.brand.brandGreen}
          onPress={onNext}
          translationKey={getTranslationText('it.welcome.success-overlay.cta')}
          testId="test.button.welcome-overlay.continue"
        />
      </Container>
    </>
  );
};
