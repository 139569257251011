import React from 'react';
import { FileRejection } from 'react-dropzone';

import { Button, Container } from 'src/taxfix-components/src';
import { isWeb } from 'src/utils/platform';
import {
  FileDropZoneWeb,
  UploadButtonVariant,
} from 'src/screens/components/file-drop-zone-web.web';
import { UploadDocumentButton } from 'src/components/upload-document-button';
import { Option } from 'src/components/input/button-overlay';

import { useItalyIntl } from '../_hooks/use-italy-intl';

type UploadButtonProps = {
  color?: string;
  options: Option[];
};

type Props = {
  hasDocumentsUploaded: boolean;
  addButtonProps: UploadButtonProps;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onCloseFolder: () => void;
};

export const DocumentFolderFooter = ({
  hasDocumentsUploaded,
  addButtonProps,
  onDrop,
  onCloseFolder,
}: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  const uploadButtonTitle = getTranslationText(
    hasDocumentsUploaded
      ? 'it.digital-caf.required-documents.upload.documents.footer-upload.more'
      : 'it.digital-caf.required-documents.upload.documents.footer.upload',
  );

  const closeButtonTitle = getTranslationText(
    hasDocumentsUploaded
      ? 'it.digital-caf.required-documents.upload.documents.footer-close'
      : 'it.digital-caf.required-documents.upload.documents.footer-close.later',
  );

  return (
    <>
      <Container shadow="sticky1" backgroundColor="white" paddingX={2} paddingTop={2}>
        {isWeb ? (
          <FileDropZoneWeb
            onDrop={onDrop}
            uploadButtonTitle={uploadButtonTitle}
            variant={UploadButtonVariant.Primary}
          />
        ) : (
          <UploadDocumentButton
            uploadButtonTitle={uploadButtonTitle}
            options={addButtonProps.options}
            variant={UploadButtonVariant.Primary}
          />
        )}

        <Button
          variant="tertiary"
          title={closeButtonTitle}
          onPress={onCloseFolder}
          paddingTop="20px"
        />
      </Container>
      <Container height="6px" bgColor="white" />
    </>
  );
};
