/**
 * List of questions Ids that are not allowed to be rollover with the "All question rollover" feature.
 * https://taxfix.atlassian.net/browse/DEM4-743
 */
export const DISALLOWED_QUESTIONS_IDS = {
  WHEN_HAVE_YOU_BOUGHT_THE_DESK: '14b292486e040c5b95e1',
  WHEN_HAVE_YOU_BOUGHT_THE_TOOL: '177c37bdb2f76909bc88',
  WHEN_HAVE_YOU_BOUGHT_THE_COMPUTER: '4090d3fd23bc051cbe65',
  WHEN_HAVE_YOU_SOLD: '45da376851980b03f3bd',
  WHEN_HAVE_YOU_BOUGHT_THE_TOOL_2: '599b9debe842b887fbb8',
  WHEN_HAVE_YOU_BOUGHT_THE_LAMP: '62d64d51d0cb08c49947',
  WHEN_HAVE_YOU_BOUGHT_THE_DESK_2: '67c4bf1fefcd1c9eee44',
  WHEN_HAVE_YOU_BOUGHT_THE_FILE_CABINET: '6a9e3f7dada5f07ae7ca',
  WHEN_HAVE_YOU_BOUGHT_THE_DESK_CHAIR: '7180a08f810fd3509290',
  WHEN_HAVE_YOU_BOUGHT_THE_DESK_CHAIR_2: '7f5a2649aa2f74a1a081',
  WHEN_HAVE_YOU_BOUGHT_THE_DESCRIPTION: '83d1bb1243a0d224d804',
  WHEN_HAVE_YOU_BOUGHT_THE_KIND: '8763a7801a4245adde4e',
  WHEN_HAVE_YOU_BOUGHT_THE_KIND_2: 'b8a1f81e7e2947e0d317',
  WHEN_HAVE_YOU_BOUGHT_YOUR_BRIEFCASE: 'bd6ff27834774fe1b977',
  WHEN_DID_YOU_MOVE_ABROAD_IN_TAX_YEAR: 'c3817caf3cc9475fd783',
  WHEN_HAVE_YOU_SOLD_THE_DOMAIN: 'd8e3a68c14ef9e7b08c4',
  WHEN_HAVE_YOU_SOLD_THE_INPUT: 'de4244e9e5daf855d01a',
  WHEN_HAVE_YOU_SOLD_THE_DOMAIN_2: 'e40d8ae6801232e1cf38',
  WHEN_HAVE_YOU_BOUGHT_THE_COMPUTER_2: 'ef346b2a9d31db626512',
  WHEN_DID_YOU_MOVE_TO_GERMANY_IN_TAX_YEAR: 'f45ea12268e33b5861c3',
  WHEN_HAVE_YOU_BOUGHT_THE_COMPUTER_ACCESSORY: 'fdf92d30a743152e15b1',
  WHEN_HAS_YOUR_CONFESSION_CHANGED: '230a1aaabddc855c22c6',
  WHEN_HAVE_YOU_BOUGHT_THE_MOBILE_PHONE: '4b9a48d27db0b3482406',
  ON_WHAT_DAY_DID_YOU_MOVE: '515dfdca7f45993f54cb',
  WHEN_DID_YOU_MOVE_OUT_OF_YOUR_SECOND_HOUSEHOLD_NO: 'c08d61ed0e7dcde33790',
  WHEN_HAVE_YOU_BOUGHT_THE_FILE_CABINET_2: 'c382d29938bee42613f5',
  WHAT_WAS_THE_COST_FOR_THE_AIR_CONDITION_ACCORDING_TO_THE_CERTIFICATE: '00ba65971f1e728e38f9',
  WHEN_DID_YOU_MOVE_OUT_YOUR_PROPERTY: '02be101dd1434421cac5',
  DID_YOU_STILL_LIVE_THERE_BY_THE_END_OF_THE_YEAR: '06bd468d3f73521a458c',
  WHEN_HAVE_YOU_BOUGHT_THE_INPUT_72DA7: '0811f603d00625084d00',
  HOW_MUCH_HAVE_YOU_SPENT_ON_PROPERTY_TRANSFER_TAX: '12a9625e078536dcb691',
  DID_YOU_SELL_MORE_THAN_ONE_PROPERTY: '173d81c951c7b666ce80',
  HAVE_YOU_OWNED_THE_DOMAIN_NO_LONGER_THAN_ONE_YEAR: '18d21f2bdc31fcd45230',
  AT_WHAT_PRICE_HAVE_YOU_BOUGHT_THE_PROPERTY: '19bc3c867e55e7b01267',
  WHAT_WAS_THE_COST_FOR_THE_THERMAL_INSULATION_OF_THE_WALL_ACCORDING_TO_THE_CERTIFICATE:
    '1db9eb595596f4955f38',
  WHEN_WAS_FIRST_NAME_BORN: '23e58401fae31cb9cc1f',
  DID_YOU_HAVE_ADDITIONAL_COSTS_FOR_ONE_OF_THE_FOLLOWING: '2444040f72f3065d9ce6',
  HOW_MUCH_WAS_THE_WORTH_OF_THE_ESTATE: '278136a8498c047813bf',
  HOW_MANY_SQUARE_METERS_HAS_YOUR_PROPERTY_HAD: '2a1e7d4b20e390343111',
  HOW_MUCH_HAVE_YOU_SPENT_ON_THE_TECHNICAL_REPORT: '2ac1b65e7a0b6614b0b6',
  WHAT_IS_YOUR_SHARE_OF_THE_PROPERTY: '2b35921a2c3f23fa5dff',
  HOW_MANY_SECOND_HOUSEHOLD_HAVE_YOU_HAD: '2b4f7ea5c074f5b36570',
  WHATS_THE_LAST_NAME_OF_FIRST_NAME: '2ef00bc3d06e75c2afa9',
  WHO_MOVED_DUE_TO_PROFESSIONAL_REASONS_IN_TAX_YEAR: '332c62d88e60ffef33ab',
  WHAT_COSTS_DID_YOU_HAVE_FOR_MOVING: '3a44c1c488cb3c2ab7cc',
  WHEN_DID_SOMEONE_ELSE_MOVE_INTO_YOUR_PROPERTY: '46cb391d9f4870f699d5',
  WHAT_WAS_THE_INITIAL_PURCHASE_PRICE_FOR_THE_INPUT_A864A: '48b04f24f1033e3ab0b0',
  WHEN_WAS_THE_PROPERTY_BUILT: '4a1fd5bbe6e257d27d9b',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_PROPERTY: '4bb771a58691b4eb3cd5',
  WHERE_DOES_FIRST_NAME_LIVE: '4c74c20a2d356ffbec81',
  HOW_MANY_PEOPLE_BESIDES_YOURSELF_ARE_OWNERS: '4cef974fdc862f420578',
  WHEN_WAS_THE_BUILDING_CONSTRUCTED: '4d555b37a1d58ef2599c',
  HAVE_YOU_OWNED_THE_INPUT_33AE7_LONGER_THAN_ONE_YEAR: '4f5bc73841a842962dca',
  WHEN_HAVE_YOU_SOLD_THE_PROPERTY: '531377565772e3d6c572',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_INPUT_6762C: '545999d0f60b45810171',
  HOW_MUCH_HAVE_YOU_SPENT_ON_BROKERS_FEE: '58ee66cbc98f73a09c7f',
  WHEN_HAVE_YOU_BOUGHT_THE_DOMAIN: '5c3851174f6ea9bb4fa7',
  WE_HAVE_CALCULATED_AMOUNT_DEPRECIATION_ROUNDED_NUMBER_CURRENCY_AS_DEPRECIATION_IS_THIS_CORRECT_2:
    '5d3ebd89d86894568512',
  HOW_MUCH_ANCILLARY_COSTS_HAVE_YOU_HAD: '609311859e238409b4e5',
  HAVE_YOU_OWNED_THE_DOMAIN_LONGER_THAN_ONE_YEAR: '614a715df8e96811b499',
  WHEN_DID_YOU_MOVE_OUT_OF_YOUR_PARENTS_HOME_FOR_THE_FIRST_TIME: '65f4dbd9425ffcb1d6d0',
  WHAT_IS_THE_ADDRESS_OF_THE_BUILDING_PART: '670c2bdfded317d4bf43',
  ON_HOW_MANY_SQUARE_METERS_DO_YOU_LIVE: '6743603350db60f41d58',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_INPUT_08943: '677b504f800baf2af08b',
  HAS_YOUR_PARTNER_MOVED_WITH_YOU: '68e52611c13f11ce1b50',
  WHAT_WAS_THE_COST_FOR_THE_RENEWAL_OF_THE_HEATING_SYSTEM_ACCORDING_TO_THE_CERTIFICATE:
    '6b0b8104ebde527b44b5',
  HOW_MUCH_HAVE_YOU_SPENT_ON_TRAVEL_FOR_FLAT_VIEWINGS: '6b1e2e9bdb17c8402bdf',
  WHEN_DID_YOU_MOVE_INTO_YOUR_PROPERTY: '6b2a8d464ba20be7a1d0',
  HAVE_YOU_OWNED_THE_INPUT_BC04A_LONGER_THAN_ONE_YEAR: '6c33bd7948ea7313250e',
  HAVE_YOU_HAD_MORE_THAN_ONE_SALE_OF_DOMAINS: '77acdcf4d1bbd360325d',
  HOW_MANY_DIFFERENT_SALES_OF_DOMAINS_HAVE_YOU_CARRIED_OUT: '7904581622e05b81787c',
  WHAT_WAS_THE_COST_FOR_THE_DIGITAL_SYSTEM_FOR_OPTIMISATION_ACCORDING_TO_THE_CERTIFICATE:
    '7e6b8f0ca348037b6f43',
  WHAT_WAS_THE_COST_FOR_THE_RENEWAL_OF_WINDOWS_AND_OR_EXTERNAL_DOOR_ACCORDING_TO_THE_CERTIFICATE:
    '80a4b1b5825dfb502842',
  HOW_MUCH_DID_YOU_BOTH_PAY_FOR_THE_FUNERAL_IN_TOTAL: '81b60e19f8518a667ebf',
  WHATS_THE_FIRST_NAME_OF_PERSON_NO_COUNT: '833ccb82534e1bde21d5',
  WHAT_OTHER_COSTS_HAVE_YOU_HAD_DUE_TO_THE_SALE_OF_PROPERTY: '851f99880d13d5ab979d',
  WHEN_WAS_THE_MEASURE_STARTED: '87ea4f55930ed2aacb94',
  WAS_THIS_PROPERTY_USED_FOR_YOUR_RESIDENTIAL_PURPOSE: '882e9ef51914b0fb6dea',
  DO_YOU_LIVE_TOGETHER_WITH_FIRST_NAME: '8b8e1f9e65a302555703',
  WHAT_WAS_THE_COST_FOR_THE_OPTIMISATION_OF_THE_HEATING_SYSTEM_ACCORDING_TO_THE_CERTIFICATE:
    '8c705a1e9e6af64fbb6a',
  WHO_OWNS_THE_BUILDING_PART_ON_WHICH_THE_ENERGY_MEASURES_HAVE_BEEN_CARRIED_OUT:
    '8fa5f207a10b6c6a8eb4',
  HOW_MANY_SQUARE_METERS_HAVE_BEEN_USED_BY_YOU: '905f2b3edb8f9ff1c305',
  WHAT_WAS_THE_INITIAL_PURCHASE_PRICE_FOR_THE_INPUT_7E5E2: '912ece3ddb058318f8d8',
  WHEN_HAVE_YOU_BOUGHT_THE_DOMAIN_2: '93779c2a833499aea0f4',
  HAVE_YOU_HAD_ANY_ANCILLARY_COSTS_RELATED_TO_THE_SALE: '9cbe7062966f04815e43',
  WHEN_HAVE_YOU_BOUGHT_THE_PROPERTY: '9fbd8cd92358f588144d',
  WHAT_IS_THE_CRYPTO_CURRENCY_OF_YOUR_SALE_NO_COUNT_CALLED: 'a106f932b2d46077b382',
  WHAT_IS_YOUR_SHARE_OF_THE_PROPERTY_2: 'a6872ec19a6860d6e30f',
  WHEN_DID_YOU_SIGN_THE_PURCHASE_CONTRACT_FOR_THE_BUILDING_PART: 'b23262f5331f30671963',
  DID_YOU_BOTH_INHERIT_DUE_TO_THE_LOSS: 'b72c0c81effa4dc27c8a',
  IN_WHAT_AMOUNT_WAS_THE_TAX_REDUCED_IN_PREVIOUS_TAX_YEAR: 'bb5b882c46ef83f04616',
  WHO_IS_CO_OWNER_BESIDES_YOU: 'bd8d87e5d17beb1b42da',
  WHAT_IS_THE_ITEM_NO_COUNT_HAVE_YOU_SOLD: 'c92a245a2b1090e549ef',
  WHEN_DID_SOMEONE_ELSE_MOVE_OUT_OF_YOUR_PROPERTY: 'c9d291ddbfded7bff838',
  HAVE_YOU_HAD_ANY_ANCILLARY_COSTS_RELATED_TO_THE_SALE_2: 'cfba2f0426cacfb33e97',
  HOW_MUCH_HAVE_YOU_SPENT_ON_NOTARY_COSTS: 'd61aa118a197943b877c',
  HOW_MUCH_ANCILLARY_COSTS_HAVE_YOU_HAD_2: 'e2318832e0149a45dbaf',
  WHEN_HAVE_YOU_BOUGHT_THE_INPUT_E7A40: 'e347b94521a9533acfd6',
  WHAT_IS_THE_TOTAL_LIVING_SPACE_OF_THE_BUILDING_PART: 'e945e73b09a15d7d29b8',
  WHAT_COSTS_DID_YOU_HAVE_FOR_MOVING_2: 'ec0ea0ad54c2a8bc65fc',
  WHICH_ENERGY_MEASURE_WAS_COMPLETED_IN_TAX_YEAR: 'ec46bcfc3ca23cf53f15',
  WHAT_WAS_THE_COST_FOR_THE_THERMAL_INSULATION_OF_THE_ROOF_ACCORDING_TO_THE_CERTIFICATE:
    'f209701737a167e8068b',
  WHAT_IS_THE_ADDRESS_OF_THE_PROPERTY: 'f5c02e4fd5d87a097c75',
  WHAT_WAS_THE_COST_FOR_THE_RENEWAL_OR_FIRST_TIME_INSTALL_OF_SOMMERLICHER_WÄRMESCHUTZ_ACCORDING_TO_THE_CERTIFICATE:
    'f71f94809d3b388b32df',
  DO_YOU_LIVE_IN_THE_WHOLE_SPACE: 'f74b441ef51a2c661669',
  HOW_MUCH_ANCILLARY_COSTS_HAVE_YOU_HAD_3: 'f7a1a9e8f1124187394c',
  HOW_MANY_DIFFERENT_SALES_OF_OTHER_TREASURES_HAVE_YOU_CARRIED_OUT: 'f803ce3aeae916a879bc',
  WHAT_WAS_THE_COST_FOR_THE_CERTIFICATE: 'f82f16fd016b5ccba4f3',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_DOMAIN: 'ff7f8258d24eee3c77ef',
  HAVE_YOU_HAD_ANY_ANCILLARY_COSTS_RELATED_TO_THE_SALE_3: '037518f1af80f1aa996c',
  DID_YOU_BOTH_OR_ONE_OF_YOU_RELOCATE_IN_TAX_YEAR: '03a9f8d7485aa36d1c17',
  HOW_WOULD_YOU_LIKE_TO_ADD_YOUR_SALE_RESULTS_FROM_CRYPTO_CURRENCY: '03bd410ab3729d924bc1',
  DID_YOU_INHERIT_DUE_TO_THE_LOSS: '03c965b7d3d7bc31be82',
  DID_YOU_GET_TAX_DEDUCTIONS_FOR_ENERGETIC_MEASURES_IN_PREVIOUS_TAX_YEAR: '0ac5cbd64dcadcc5cbc3',
  WHAT_KIND_OF_RESULT_DO_YOU_HAVE_IN_TAX_YEAR: '0b91edf178d17343fae0',
  DID_YOU_BOTH_OR_ONE_OF_YOU_INCUR_COSTS_FOR_A_FUNERAL: '0ca320752755ad40d27f',
  HOW_MANY_SQUARE_METERS_HAVE_BEEN_USED_FOR_OTHER_PURPOSES: '0e4f7c80032f877d8d67',
  WERE_THE_AMOUNTS_RECEIVED_HIGHER_THAN_THE_FUNERAL_COSTS: '0e75157589b9605ed451',
  DID_YOU_RELOCATE_IN_TAX_YEAR: '15ee0466827d11dd7a9c',
  HOW_MANY_PROPERTIES_HAVE_YOU_SOLD: '1702734c0e0952ae3f74',
  DID_YOU_RELOCATE_IN_TAX_YEAR_2: '1a44306090665233a33a',
  WHEN_DID_YOU_MOVE_INTO_YOUR_PROPERTY_2: '1b9055d55f83c43ec14b',
  ON_WHAT_DAY_DID_YOU_MOVE_IN_TAX_YEAR_FOR_RELOCATION_NO_COUNT: '1ce427981006e518268f',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_CRYPTO_CURRENCY_OF_SALE_NO_COUNT: '2366eedb19f9fd16e84e',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_PROPERTY_2: '2b0736f6a212b22fda7f',
  HOW_MANY_TIMES_DID_YOU_RELOCATE_DUE_TO_OCCUPATIONAL_REASONS_IN_TAX_YEAR: '2bdb102a2725a6fac375',
  FROM_WHEN_TO_WHEN_DID_YOU_LIVE_THERE: '2e2088ceabc3787a9676',
  DID_YOU_MOVE_OUT_OF_YOUR_OWN_FLAT: '2e5d9813a3f6450f994b',
  WHAT_KIND_OF_CRYPTO_CURRENCY_IS_YOUR_SALE_NO_COUNT: '2ec4aaf19a90373b97c5',
  WHEN_HAVE_YOU_BOUGHT_THE_PROPERTY_2: '31b14616ef7f9c065670',
  DID_YOU_MOVE_INTO_YOUR_OWN_FLAT: '33b9f0e36427f3db99b8',
  WERE_THE_COSTS_OF_THE_MEASURES_SUBSIDISED: '3c9731037960d0b0522c',
  WHEN_WAS_THE_PROPERTY_BUILT_2: '3d3a6a2623026a80cb1f',
  HOW_MUCH_HAVE_YOU_SPENT_ON_THE_MOVING_COMPANY: '3f9bcd8fd1867700b374',
  WHAT_WAS_THE_TOTAL_AMOUNT_OF_THE_VALUES_RECEIVED: '46b449f334655fe820cb',
  WHAT_OTHER_COSTS_HAVE_YOU_HAD_DUE_TO_THE_SALE_OF_PROPERTY_2: '48292e7804332074ccad',
  HOW_MUCH_HAVE_YOU_SPENT_ON_BANK_COSTS: '4c0b2bfe7c0f13717d29',
  HAVE_YOU_MOVED_FOR_PROFESSIONAL_REASONS_WITHIN_THE_LAST_5_YEARS: '4e68bed267c2cc0aee36',
  HOW_MUCH_ANCILLARY_COSTS_HAVE_YOU_HAD_4: '505ae29714064bab661b',
  HAS_AN_ENERGY_MEASURE_BEEN_CARRIED_OUT_IN_TAX_YEAR_IN_A_PROPERTY_YOU_LIVE_IN:
    '51b93ef804f01ed850d9',
  HOW_MUCH_DID_YOU_PAY_FOR_THE_FUNERAL: '52d4486d7a412dd82f9a',
  HOW_MUCH_ANCILLARY_COSTS_HAVE_YOU_HAD_5: '5361534a972d01176530',
  HAVE_YOU_HAD_ANY_ANCILLARY_COSTS_RELATED_TO_THE_SALE_4: '56f4f60abb66544830c4',
  WHERE_DID_YOU_MOVE_TO_IN_TAX_YEAR: '575824942e987ce0269d',
  WHY_DID_YOU_MOVE_TO_GERMANY_IN_TAX_YEAR: '5f54d39085b742bd20e1',
  DID_YOU_INCUR_COSTS_FOR_A_FUNERAL: '6a8e8f3a1d0076736275',
  WHEN_DID_YOU_MOVE_OUT_OF_YOUR_PROPERTY_2: '6bae824c085194de3bf0',
  HOW_MUCH_DEPRECIATION_HAVE_YOU_DECLARED: '6fe4e886f393478fad07',
  HAVE_YOU_BEEN_SELLING_ANY_OF_THESE_ASSETS: '763e8feaaee61e44363a',
  WHAT_WAS_THE_COST_FOR_THE_THERMAL_INSULATION_OF_THE_STOREY_CEILING_ACCORDING_TO_THE_CERTIFICATE:
    '7a1b2ea83a01f3d87d00',
  WHAT_WAS_THE_INITIAL_PURCHASE_PRICE_FOR_THE_DOMAIN: '7b32a8e1dd6e4524e173',
  WE_HAVE_CALCULATED_AMOUNT_DEPRECIATION_ROUNDED_NUMBER_CURRENCY_AS_DEPRECIATION_IS_THIS_CORRECT:
    '7b985ccb9c6425d460b0',
  WERE_THE_CRYPTOCURRENCIES_SOLD_IN_YOUR_POSSESSION_FOR_MORE_THAN_1_YEAR: '7e0d93522cc34b44e3ec',
  HOW_MUCH_HAVE_YOU_SPENT_ON_THE_ESTATE_AGENT: '7e72fdfd42086293bac2',
  HAVE_YOU_HAD_MORE_THAN_ONE_SALE_OF_OTHER_TREASURES: '81637cd7638f4a034050',
  DID_YOU_RECEIVE_ANY_CERTIFICATE_FOR_THIS: '837c119b7c1ee76e65bc',
  WHEN_DID_SOMEONE_ELSE_MOVE_OUT_OF_YOUR_PROPERTY_2: '8577bc2b0b838057a573',
  HOW_MUCH_HAVE_YOU_SPENT_ON_ADVERTS: '8591c2ca568e72880703',
  DID_YOU_MOVE_IN_AND_OUT_OF_YOUR_OWN_HOME: '883d8e6796ea28a87fbc',
  WAS_THE_SOLD_PROPERTY_USED_FOR_YOUR_RESIDENTIAL_PURPOSE_ONLY: '884e20e2bde2aaa0b373',
  WHAT_HAVE_YOU_SOLD: '8864543c0562c1ec92e1',
  HOW_DID_YOU_MOVE_TO_YOUR_NEW_HOME: '90363e49194960fee217',
  HOW_MANY_SQUARE_METERS_HAVE_BEEN_USED_FOR_OTHER_PURPOSES_2: '910c334b883aa0daef04',
  DID_YOU_ALREADY_HAVE_A_JOB_RELATED_MOVE_WITHIN_THE_LAST_5_YEARS: '9362619d7495401afdfc',
  WHAT_IS_THE_ADDRESS_OF_THE_PROPERTY_2: '968382010c2263859b8e',
  IS_THE_ADDRESS_OF_THE_BUILDING_THE_SAME_AS_YOUR_CURRENT_ADDRESS: '988ffd2f8519416c4f45',
  WHEN_HAVE_YOU_SOLD_THE_CRYPTO_CURRENCY_OF_SALE_NO_COUNT: '996ae969e19b9d70efa4',
  HOW_MUCH_HAVE_YOU_SPENT_ON_ADVERTS_2: 'aa9a78f75159ccc38a48',
  AT_WHAT_PRICE_HAVE_YOU_SOLD_THE_DOMAIN_2: 'ab170720bdc1e6aba2c6',
  WHAT_IS_YOUR_TOTAL_RESULT_IN_TAX_YEAR_FROM_CRYPTO_CURRENCY_SALES: 'ab1cc0bdb0c39e383b28',
  WHEN_HAVE_YOU_SOLD_THE_PROPERTY_NO_COUNT: 'b11a7fa28b2d34d3c63b',
  AT_WHAT_PRICE_HAVE_YOU_BOUGHT_THE_PROPERTY_2: 'b5cd7779d659479d3abd',
  WHEN_WAS_THE_MEASURE_STARTED_2: 'b96db12f0f31394e7366',
  WHAT_WAS_THE_COST_FOR_THE_ENERGY_CONSULTANT: 'c8babdd97897e53f2835',
  WHAT_KIND_OF_OTHER_COSTS_HAVE_ACCURED: 'c96b2a9aaeff20d51be1',
  DID_YOU_GET_TAX_DEDUCTIONS_FOR_ENERGETIC_MEASURES_IN_PREVIOUS_TAX_YEAR_2: 'cb71ad9d7b5585cfb0ef',
  WHAT_WAS_THE_INITIAL_PURCHASE_PRICE_OF_THE_CRYPTO_CURRENCY_FROM_SALE_NO_COUNT:
    'ccc4aacc9452cc3e3972',
  WERE_THE_COSTS_OF_THE_MEASURES_SUBSIDISED_BY_THE_STATE: 'd46dd1308956c939c35a',
  HAVE_YOU_HAD_ANY_ANCILLARY_COSTS_RELATED_TO_THE_SALE_5: 'd954e5eca91dc8ed143d',
  HOW_MANY_OTHER_PEOPLE_EXCLUDING_YOU_AND_YOUR_PARTNER_ARE_OWNERS: 'd970e9ecf89f5a7a16ee',
  WHEN_HAVE_YOU_BOUGHT_THE_CRYPTO_CURRENCY_OF_SALE_NO_COUNT: 'da5cffba1f4f24982fbf',
  HOW_MUCH_HAVE_YOU_SPENT_ON__THE_MOVING_COMPANY: 'dc7903ce37bbba1635d0',
  WHAT_WAS_THE_INITIAL_PURCHASE_PRICE_FOR_THE_DOMAIN_2: 'ddf54ab79f8f616abf32',
  WHEN_DID_SOMEONE_ELSE_MOVE_INTO_YOUR_PROPERTY_2: 'e27feef27074ebfeb3aa',
  HOW_MUCH_HAVE_YOU_SPENT_ON_DEBT_INTEREST: 'f5292df83cbbb6eb0ff8',
  WAS_THE_PERIOD_BETWEEN_PURCHASE_AND_SALE_OF_THE_PROPERTY_LESS_THAN_10_YEARS:
    'fbafcbccd6a0ad90b598',
  HOW_MANY_CRYPTO_CURRENCY_SALES_DID_YOU_MAKE_IN_TAX_YEAR: 'fcd8ed987c51df13b7ef',
  HOW_MUCH_DID_YOU_SPENT_ON_THE_MOVING_COMPANY:
    'question:relocation:how_much_did_you_spent_with_the_rental_car_or_moving_company',
  HOW_MUCH_DID_YOU_SPENT_ON_THE_RENTAL_CAR:
    'question:relocation:if_rented_car_moving_company_how_much_did_you_spent_on',
  HAVE_YOU_ANY_OTHER_COSTS_DUE_TO_RELOCATION: 'question:relocation:what_kind_of_cost_did_you_have',
  FROM_WHERE_TO_WHERE_DID_YOU_GO_BY_CAR: 'question:relocation:where_was_your_former_address',
};
