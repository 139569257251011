import * as React from 'react';
import * as SubmissionSDK from '@taxfix/submissions-sdk';
import Config from 'react-native-config';
import { Years } from '@taxfix/types';

import { getAnswer } from 'src/stores-legacy/helpers';
import { flagsQuestionIds } from 'src/common/constants-it';
import { selectors as remoteSelectors } from 'src/stores/modules/remote-config-firebase';
import {
  NavigationLink,
  StatusMiddleware,
  StatusPostBreakProcessFunction,
  StatusSkipFunction,
  SectionResolverFunction,
  UPDATE_STATUS,
  StatusStepRedirect,
} from 'src/stores/modules/status';
import {
  actions as SubmissionActions,
  selectors as SubmissionSelectors,
} from 'src/stores/modules/submission';
import { resolveDraftSubmissionNavigationForItaly } from 'src/services/draft-submission-navigation-resolver';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import { DownloadPdfButton } from 'src/screens/status-screen/download-pdf-button-component';
import { DraftAndSentSubmissionStates, ExtraPaymentParams } from 'src/services/submissions';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { pdfToBase64URI } from 'src/services/utils';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { State as RootState } from 'src/stores/store/initial';
import { DocumentConsentFooterWrapper } from 'src/services/documents-consent-footer';
import { getDefaultTaxYear } from 'src/services/country-and-year';

import { hasSkippedExpenseUploadSection } from './required-documents';
import { hasSkippedPrefillSection } from './prefill';
import { getNavigationConfigWithParams } from './utils';

type SubmissionResult = {
  submissionId: number;
  teleNumber?: string;
  metadata?: any;
  pdf?: string;
  error?: string;
  created?: Date;
  assessmentNumber?: string;
};

const getOnDownloadPdf =
  (dispatch: any, failureAction: any, submissionYear: number, submissionResult: SubmissionResult) =>
  () => {
    Analytics.log(AnalyticsEvent.downloadDeclarationPDFInitiated);
    const { pdf } = submissionResult;

    if (pdf) {
      getBoundNavigationsActions().toOpenPDF('modal', {
        uri: pdfToBase64URI(pdf),
        year: submissionYear,
      });
    } else {
      dispatch(failureAction(`PDF Download error`));
    }
  };

const getLatestSubmissionResult = async (
  state: any,
  submissionId: number,
): Promise<SubmissionResult> =>
  SubmissionSDK.getLatestResult(Config.API_BASE_URL, userAuthSelectors.getAccessToken(state), {
    submissionId,
  });

export const statusMiddlewareFetch: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const userId = userAuthSelectors.getUserId(getState());
    const accessToken = userAuthSelectors.getAccessToken(getState());

    if (!userId || !accessToken) {
      throw new Error('submission api needs user ID, user accessToken, and selected year');
    }

    await dispatch(SubmissionActions.fetchSubmissionsForSelectedYear(accessToken, userId));
    await dispatch(SubmissionActions.updatePaymentStatus(accessToken, userId));
    next();
  } catch (e) {
    dispatch(failureAction(e as Error));
  }
};

export const hasActiveSubmissions: StatusSkipFunction = async (getState) => {
  const state = getState();
  const year = settingsSelectors.selectedYear(state);

  if (!year) {
    return false;
  }

  const submissions = SubmissionSelectors.getActiveSubmissionsByYear(state, year);

  if (submissions.length > 0) {
    return true;
  }

  return false;
};

export const isLatestSubmissionSubmitted: StatusSkipFunction = async (getState) => {
  const state = getState();
  const year = settingsSelectors.selectedYear(state);

  if (!year) {
    return false;
  }

  const submission = SubmissionSelectors.getLatestSubmittedSubmission(state);
  return submission && submission.year === year;
};
type ResolveExtraPaymentParams = (storeState: RootState) => Promise<ExtraPaymentParams>;

export const updateLatestSubmissionStatusAndPayment: (
  arg0?: ResolveExtraPaymentParams | null | undefined,
) => StatusMiddleware =
  (resolveExtraPaymentParams) =>
  async (dispatch, getState, config, breakAction, failureAction, next) => {
    const storeState = getState();
    const userId = userAuthSelectors.getUserId(storeState);
    const accessToken = userAuthSelectors.getAccessToken(storeState);
    const extraPaymentParams = resolveExtraPaymentParams
      ? await resolveExtraPaymentParams(storeState)
      : undefined;

    try {
      if (!userId || !accessToken) {
        throw new Error('submission api needs user ID, user accessToken, and selected year');
      }

      await dispatch(
        SubmissionActions.updateLatestSubmissionStatus(accessToken, userId, extraPaymentParams),
      );
      next();
    } catch (e) {
      dispatch(failureAction(`Failed to update submission: ${(e as Error).message}`));
    }
  };
export const checkSkippedStepsForCompletion: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const year = settingsSelectors.selectedYear(getState());
  if (!year) {
    throw new Error('submission api needs user ID, user accessToken, and selected year');
  }
  // grab the answer to disable the section in case the user hasn't consented
  const hasGivenConsent = getAnswer(getState(), flagsQuestionIds.documentsUploadConsent);
  const isItalyskipMandatoryDocumentsEnabled =
    remoteSelectors.getItalySkipMandatoryDocumentsEnabled(getState());
  const isResubmission = SubmissionSelectors.isResubmissionByYear(getState(), year);

  try {
    const isExpensesUploadSkipped = await hasSkippedExpenseUploadSection(getState);
    const isIdUploadSkipped = await hasSkippedPrefillSection(getState);
    // isItalyMandatoryDocumentsEnabled feature flag to controll mandatory documents
    // but they have to upload the id
    const canSkipDocumentsUpload = isItalyskipMandatoryDocumentsEnabled || !isExpensesUploadSkipped;

    if (!isResubmission && (!canSkipDocumentsUpload || isIdUploadSkipped)) {
      const customSection = config.section?.new;
      if (!customSection) throw new Error('new section is not defined');
      customSection.stepDisabled = !hasGivenConsent;

      const customNavigation = getNavigationConfigWithParams(config.navigation?.reminder, {
        canSkipDocumentsUpload,
        isIdUploadSkipped,
        year: settingsSelectors.selectedYear(getState()) as Years,
      });
      customSection.navigation = {
        link: {
          ...(customNavigation.link as NavigationLink),
        },
      };

      dispatch(
        breakAction(
          () =>
            DocumentConsentFooterWrapper(
              customNavigation.link as NavigationLink,
              customNavigation.title,
              isResubmission,
            ),
          customSection,
        ),
      );
    } else {
      next();
    }
  } catch (e) {
    dispatch(failureAction(`Failed to check skipped sections: ${(e as Error).message}`));
  }
};

export const checkUserPayment: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const year = settingsSelectors.selectedYear(getState());
  if (!year) {
    throw new Error('submission api needs user ID, user accessToken, and selected year');
  }
  const isResubmission = SubmissionSelectors.isResubmissionByYear(getState(), year);

  if (SubmissionSelectors.isPaidSubmission(getState()) || isResubmission) {
    next();
  } else {
    try {
      const customNavigation = config.navigation?.payment;

      const customSection = {
        ...config.section?.new,
        navigation: {
          link: {
            ...(customNavigation?.link as NavigationLink),
          },
        },
      };

      dispatch(breakAction(customNavigation, customSection));
    } catch (e) {
      dispatch(failureAction(`Failed to check payment: ${(e as Error).message}`));
    }
  }
};

export const showDocumentConsentFooter: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
) => {
  const year = settingsSelectors.selectedYear(getState());
  const isResubmission = year && SubmissionSelectors.isResubmissionByYear(getState(), year);

  const navigationLink: NavigationLink = {
    action: 'toRequiredDocumentsDigitalCaf',
    config: 'screen',
    params: {
      onNext: () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      },
    },
  };
  const customSection = config.section?.new;

  const customNavigation = DocumentConsentFooterWrapper(
    navigationLink,
    'status.it.button.title',
    isResubmission,
  );

  dispatch(breakAction(() => customNavigation, customSection));
};

export const statusMiddlewareUpdateIT: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const status = SubmissionSelectors.getLatestSubmissionStatus(getState());

    if (status === DraftAndSentSubmissionStates.Submitted) {
      next();
    } else {
      const stores = getState();
      const { customNavigation, customSection } = resolveDraftSubmissionNavigationForItaly(
        stores,
        config,
      );
      dispatch(breakAction(customNavigation, customSection));
    }
  } catch (err) {
    dispatch(failureAction(`error status submission: ${(err as Error).message}`));
  }
};

export const statusMiddlewareCompleteIT: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
) => {
  // get the submission, construct download success callback
  const state = getState();
  const year = settingsSelectors.selectedYear(state);

  if (!year) {
    dispatch(failureAction('year not selected'));
    return;
  }

  const submission = SubmissionSelectors.getSubmittedSubmissionsByYear(state, year);

  if (!submission) {
    dispatch(failureAction('no submitted submission'));
    return;
  }

  try {
    const submissionResult = await getLatestSubmissionResult(getState(), submission.id);
    dispatch(
      breakAction(() => (
        <DownloadPdfButton
          onDownloadPdf={getOnDownloadPdf(
            dispatch,
            failureAction,
            submission.year,
            submissionResult,
          )}
        />
      )),
    );
  } catch (e) {
    failureAction(`Error retriving submission result: ${(e as Error).message}`);
  }
};

export const resubmissionInfoBanner: StatusPostBreakProcessFunction = async (
  dispatch,
  getState,
) => {
  const { betweenSoftHardEnd } = getTaxSeasonInfo(getState());
  const taxYear = settingsSelectors.selectedYear(getState());
  if (!taxYear) return;
  const isResubmission = SubmissionSelectors.isResubmissionByYear(getState(), taxYear);

  const isConfirmationAnsweredOnResubmission = getAnswer(
    getState(),
    flagsQuestionIds.isConfirmationAnsweredOnResubmission,
  );

  if (isResubmission && !isConfirmationAnsweredOnResubmission) {
    const bannerKey = betweenSoftHardEnd ? 'secondaryBanner' : 'banner';
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        [bannerKey]: {
          type: 'ResubmissionInfo',
        },
      },
    });
  }
};

export const skipIfResubmission: StatusSkipFunction = async (getState) => {
  const year = settingsSelectors.selectedYear(getState());
  if (!year) return true;
  const isResubmission = SubmissionSelectors.isResubmissionByYear(getState(), year);

  if (isResubmission) {
    return true;
  }

  return false;
};

export const resolveStepTitleInResubmission: SectionResolverFunction = (
  getState,
  sections,
  index,
  stepIndex,
) => {
  if (index <= stepIndex) return undefined;

  const isResubmission = SubmissionSelectors.isResubmission(getState());
  if (isResubmission) {
    return {
      ...sections.resubmission,
    };
  }
  return null;
};

export const checkActiveSubmission: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  if (!SubmissionSelectors.getActiveSubmission(getState())) {
    dispatch(breakAction());
  } else {
    next();
  }
};

export const redirectDonationAndSubmission: StatusStepRedirect =
  (state, dispatch) => (navigation) => {
    navigation.reset({
      index: 1,
      actions: [
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toItalyDonation('screen', {
          backNavigationText: 'it.donations.come-back-home.button.text',
        }),
      ],
    });
  };

export const checkPostSubmission: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  dispatch(breakAction());
};

export const redirectDashboard: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 0,
    actions: [getNavigationActions().toDashboard('screen')],
  });
};

export const skipAlreadySubmitted: StatusSkipFunction = async (getState) => {
  const curentYearSubmissions = SubmissionSelectors.getSubmissionsByYear(
    getState(),
    getDefaultTaxYear(),
  );

  return curentYearSubmissions.length > 0;
};
