import React from 'react';

import { TextInput } from '../core';
import { passwordLength } from '../../utils/credentials';

import { PinInputComponent } from './PinInputComponent';
import { Types, Variant } from './PinInputComponent.types';

export type Props = {
  variant?: Variant;
  type?: Types;
  value: string;
  autoFocus?: boolean;
  hasError?: boolean;
  errorKey?: string | null;
  onChange: (value: string) => void;
  onForgot?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  loading?: boolean;
  testId?: string;
  labelKey?: string;
};
type State = {
  isFocused: boolean;
  isObscured: boolean;
};

class PinInput extends React.Component<Props, State> {
  pinLength: number;

  textInput?: TextInput;

  static defaultProps = {
    variant: 'light-background',
    type: 'sign-up',
    autoFocus: false,
    hasError: false,
    errorKey: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: this.props.autoFocus || false,
      isObscured: true,
    };
    this.pinLength = passwordLength;
    (this as any).handleAction = this.handleAction.bind(this);
    (this as any).handleBlur = this.handleBlur.bind(this);
    (this as any).handleChange = this.handleChange.bind(this);
    (this as any).handleFocusTextInput = this.handleFocusTextInput.bind(this);
    (this as any).isValidPin = this.isValidPin.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.textInput) {
      if (this.props.autoFocus && !prevProps.autoFocus) {
        this.textInput.focus();
      }

      if (!this.props.autoFocus && prevProps.autoFocus) {
        this.textInput.blur();
      }
    }
  }

  setRef = (input: any) => {
    this.textInput = input;
  };

  isValidPin(input: string) {
    return (input === '' || /^[\d]+$/.test(input)) && input.length <= this.pinLength;
  }

  handleChange(text: string) {
    if (this.isValidPin(text)) {
      this.props.onChange(text);
    } else {
      this.props.onChange(this.props.value);
    }
  }

  handleAction() {
    if (this.props.type === 'sign-up') {
      this.setState((prevState) => ({
        isObscured: !prevState.isObscured,
      }));
    } else if (this.props.type === 'enter') {
      if (this.props.onForgot != null) {
        this.props.onForgot();
      }
    }
  }

  handleBlur() {
    this.setState({
      isFocused: false,
    });
  }

  handleFocusTextInput() {
    if (this.textInput) {
      this.textInput.focus();

      if (this.props.onFocus) {
        this.props.onFocus();
      }

      this.setState({
        isFocused: true,
      });
    }
  }

  render() {
    const { labelKey, value, type, variant, autoFocus, hasError, errorKey, loading, testId } =
      this.props;
    const { isFocused } = this.state;
    const titleKey = type === 'sign-up' ? 'account.pin.choose-label' : 'account.pin.enter-label';
    let actionKey;

    if (type === 'sign-up') {
      actionKey = this.state.isObscured ? 'account.pin.show' : 'account.pin.hide';
    } else {
      actionKey = 'account.pin.forgot';
    }

    return (
      <PinInputComponent
        value={value}
        variant={variant}
        autoFocus={autoFocus}
        hasError={hasError}
        errorKey={errorKey}
        isFocused={isFocused}
        isObscured={this.state.isObscured}
        actionKey={actionKey}
        titleKey={labelKey || titleKey}
        testId={testId}
        loading={loading}
        textInputRef={this.setRef}
        pinLength={this.pinLength}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleFocusTextInput={this.handleFocusTextInput}
        handleAction={this.handleAction}
      />
    );
  }
}

export default PinInput;
