import * as React from 'react';

import { AppStateContextState, AppStateContext } from '../containers/app-state-context';

export const withAppStateContext = (Component: React.ComponentType<any>) =>
  function Enhanced(props: any) {
    return (
      <AppStateContext.Consumer>
        {(context) => <Component appStateContext={context} {...props} />}
      </AppStateContext.Consumer>
    );
  };
export type WithAppStateContextType = {
  appStateContext: AppStateContextState;
};
