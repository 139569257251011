import { $Keys } from 'utility-types';

import { ListRowData, ListRowComponentType } from 'src/components/core/List/types';

export type Payload = {
  content: string;
  contentStyles?: string;
};
export type MenuItemData = ListRowData<Payload>;
export type MenuItem = ListRowComponentType<Payload>;
export const menuItem = {
  faq: 'account.info.faq',
  logout: 'account.logout',
  support: 'account.support',
  helpCenter: 'account.help-center',
  terms: 'account.info.terms',
  referral: 'account.referral',
  revoke: 'account.info.revoke',
  privacy: 'account.info.privacy',
  privacySettings: 'account.info.privacy-settings',
  imprint: 'account.info.imprint',
  pinChange: 'account.pin.change',
  editProfile: 'account.profile.edit',
  licenses: 'account.info.licenses',
  emailChange: 'account.email.change',
  diagnostic: 'account.send.diagnostic',
  countryChange: 'account.country.change',
  prefillGermany: 'account.info.de.pre-fill',
  languageChange: 'account.language.change',
  debugResponses: 'account.debug.responses',
  closeAccount: 'account.info.close-account',
  commercialista: 'account.info.commercialista',
  submissionsDownload: 'account.submissions.download',
  documents: 'account.documents',
};
export enum MenuTypes {
  LIST,
  FOOTER,
}
export type MenuType = $Keys<typeof MenuTypes>;
export type MenuConfig = {
  type: MenuTypes;
  heading?: string;
  items: Array<MenuItem>;
  hidden?: boolean;
};
