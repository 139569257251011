import { $Keys } from 'utility-types';
import React from 'react';

import { Box } from '../../core';

import InputValidationBar from './InputValidationBar';

type Props = {
  type?: $Keys<typeof InputValidationBar.Type>;
  translationKey: string;
  values?: Record<string, any>;
  testId?: string;
  onPressInfo?: (arg0: string) => void;
};
export const ValidationError = ({
  type = InputValidationBar.Type.Error,
  translationKey,
  values,
  testId,
  onPressInfo,
}: Props) => (
  <Box>
    <InputValidationBar
      type={type}
      translationKey={translationKey}
      values={values}
      testId={testId}
      onPressInfo={onPressInfo}
    />
  </Box>
);
