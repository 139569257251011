/* eslint-disable no-console */
export type ErrorType = string | Error;

export type ContextType = Record<
  string,
  string | string[] | number | boolean | Date | undefined | unknown
>;

export type LoggerWithContext = (error: ErrorType, context?: ContextType) => void;

type Logger = (...args: Array<any>) => void;

export type LoggerInstance = {
  log: Logger;
  debug: Logger;
  info: LoggerWithContext;
  warn: LoggerWithContext;
  error: LoggerWithContext;
  errorTrackingInstance?: any;
};

// eslint-disable-next-line import/no-mutable-exports
let logger: LoggerInstance = {
  log: () => {},
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
  errorTrackingInstance: undefined,
};

const createDefaultLogger = (_IS_DEV_: boolean): void => {
  if (_IS_DEV_) {
    logger = {
      ...logger,
      log: (...args: Array<any>) => console.log(...args),
      debug: (...args: Array<any>) => console.debug(...args),
      info: (error: ErrorType, context?: ContextType) => console.info(error, context || ''),
      warn: (error: ErrorType, context?: ContextType) => console.warn(error, context || ''),
      error: (error: ErrorType, context?: ContextType) => console.error(error, context || ''),
    };
  }
};

const updateLogger = (loggerInstance: Partial<LoggerInstance>): void => {
  logger = { ...logger, ...loggerInstance };
};

export { logger, createDefaultLogger, updateLogger };
