import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Documents } from '@taxfix/taxfix-sdk';
import { DocumentsTypes } from '@taxfix/documents-sdk';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { ErrorType, logger } from 'src/taxfix-business-logic/utils/logger';
import { logErrorMessages } from 'src/common/log-error-messages';

import { getPrefillDocuments, getPrefillDocumentTypes } from './utils';

const ERROR_PDF_NAME = 'errore.pdf';

export const useGetPrefillDocuments = () => {
  // by default should be true, so we don't display wrong - noResultDefault - screen state
  const [loading, setLoading] = useState(true);
  const [prefillDocuments, setPrefillDocuments] = useState<Documents.types.Document[]>([]);

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const year = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const userId = useSelector(userAuthSelectors.getUserId);
  const requestedDocumentsList = useSelector(firebaseSelectors.getItalyCWBIDocumentsDownloadConfig);

  useEffect(() => {
    setLoading(true);

    const handleGetPrefillDocuments = async () => {
      try {
        const prefillDocumentTypes = getPrefillDocumentTypes(
          requestedDocumentsList,
        ) as DocumentsTypes.v1.Documents.NonReceiptTypes[];

        const documents = await getPrefillDocuments(
          prefillDocumentTypes,
          accessToken,
          userId,
          year,
        );

        setPrefillDocuments(documents);
        setLoading(false);
      } catch (error) {
        setPrefillDocuments([]);
        setLoading(false);
        logger.warn(error as ErrorType, {
          message: logErrorMessages.prefillDocumentsRequestError,
        });
      }
    };
    handleGetPrefillDocuments();
  }, [accessToken, userId, year, requestedDocumentsList]);

  const filteredPrefillDocuments = prefillDocuments.filter((prefillDocument) => {
    const metadata = prefillDocument.metadata as any;
    let parsedMetadata: undefined | Documents.types.Document['metadata'];
    // Safely parse metadata as JSON
    if (metadata) {
      try {
        parsedMetadata = JSON.parse(metadata);
      } catch (error) {
        // swallow the error;
      }
    }
    return metadata === undefined || parsedMetadata?.originalName !== ERROR_PDF_NAME;
  });

  const hasPrefillDocuments = filteredPrefillDocuments.length > 0;

  return {
    loading,
    prefillDocuments,
    filteredPrefillDocuments,
    hasPrefillDocuments,
  };
};
