import { useState } from 'react';
import { useSelector } from 'react-redux';
import { indexFromId } from '@taxfix/quizmaster/dist/reference';

import { Answer, QuestionAndAnswer, getQuestionAndAnswers } from 'src/services/quiz-master-store';

import QuestionStore from '../../stores-legacy/QuestionStore';
import { extractAnswers } from '../../services/answers-extractor-italy';
import {
  expensePrefilledSilentQuestionIds,
  mapSilentQuestionCategories,
} from '../../common/constants-it';
import { selectors as settingsSelectors } from '../modules/settings';

import { useQuestionStore } from './mobx-stores-hook';

export type UseAnswers = {
  answers: Answer[];
  sortedAnswers: Answer[];
  tailoredCategories: string[];
  form730: Record<string, any>;
  questionAndAnswers: QuestionAndAnswer[];
};

const getTailoredCategories = (answers: Answer[]): string[] =>
  answers.reduce((acc, answer) => {
    if (answer) {
      answer.outputs.forEach(({ output, value }) => {
        // we need to eliminate silent questions that come from prefill
        // otherwise we have doubled categories for folders which have prefilled expenses
        if (
          value === true &&
          output.startsWith('cat_') &&
          !expensePrefilledSilentQuestionIds.includes(answer.questionID)
        ) {
          // @ts-ignore
          acc.push(output);
        }
      });
    }

    return acc;
  }, []);

const mappingRelatives730: any = {
  '730/Familiari/PrimoFiglio/CodiceFiscale': '730/Familiari/Familiari/$index/CodiceFiscale',
  '730/Familiari/PrimoFiglio/Mesi': '730/Familiari/Familiari/$index/Mesi',
  '730/Familiari/PrimoFiglio/PercDetraz': '730/Familiari/Familiari/$index/PercDetraz',
  '730/Familiari/PrimoFiglio/Tipo': '730/Familiari/Familiari/$index/Tipo',
};
export const getForm730 = (answers: Answer[]): Record<string, any> => {
  const form730: any = answers.reduce((acc: any, answer) => {
    if (answer) {
      const [index = 0] = indexFromId(answer.questionID) || [];
      answer.outputs.forEach(({ output, value }) => {
        // Select 730 outputs
        if (output.startsWith('730_')) {
          const path730 = output.split('730_')[1];

          // Value of looped key or child key is always an array
          if (path730.includes('$index') || Object.keys(mappingRelatives730).includes(path730)) {
            if (!acc[path730]) {
              acc[path730] = [];
            }

            acc[path730][index] = value;
          } else {
            // All other keys

            // there are questions with same outputs in personal details QF and prefill QF:
            // - residence address (personal details) || billing address (prefill)
            // - taxId
            // we need to make sure that we do not override existing outputs with NULL from another QF
            if (acc[path730]) {
              return;
            } else {
              acc[path730] = value;
            }
          }
        }
      });
    }

    return acc;
  }, {});
  Object.keys(mappingRelatives730).forEach((key) => {
    const source = form730[key];
    const destination = form730[mappingRelatives730[key]];

    if (Array.isArray(source)) {
      if (source.length > 1) {
        form730[mappingRelatives730[key]] = [...(destination || []), ...source.slice(1)];
      }

      // eslint-disable-next-line prefer-destructuring
      form730[key] = source[0];
    }
  });
  return form730;
};

export const getAnswers = (questionStore: QuestionStore): UseAnswers => {
  questionStore.runCleanRebuild();
  const { list, responsesJS, cache, year } = questionStore;
  const { sortedAnswers, answers } = extractAnswers(list, responsesJS, cache, year);
  const tailoredCategories = getTailoredCategories(answers);
  const form730 = getForm730(sortedAnswers);
  const questionAndAnswers = getQuestionAndAnswers(responsesJS);
  return {
    answers,
    sortedAnswers,
    form730,
    questionAndAnswers,
    tailoredCategories: [...tailoredCategories].map(mapSilentQuestionCategories),
  };
};
export const useAnswersFromStore = (): UseAnswers => {
  const year = useSelector(settingsSelectors.selectedYear);
  const questionStore = useQuestionStore(year as any);
  const [answers] = useState<UseAnswers>(getAnswers(questionStore));
  return answers;
};
// get an answer from a specific year
export const getAnswerFromYear = (year: number, questionId: string, questionStore: any): Answer => {
  const { list, responsesJS, cache } = questionStore;
  const { answers } = extractAnswers(list, responsesJS, cache, year);
  const answer = answers.filter((ans) => ans.questionID === questionId);

  return answer[0];
};
