import React from 'react';

import { Container, Icon, icons, Text } from 'src/taxfix-components/src';
import { MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB } from 'src/utils/constants';
import { useItalyIntl } from 'src/_italy/_hooks';

type Props = {
  errorMessage: string;
};

export const ErrorBanner: React.FC<Props> = ({ errorMessage }) => {
  const { getTranslationText } = useItalyIntl();

  return (
    <Container
      mb="10px"
      paddingX="40px"
      paddingY="16px"
      backgroundColor="secondaryFeedback.errorRed"
      width={['95%', '95%', '80%']}
      marginX="auto"
      borderRadius="10px"
      flexDirection="row"
      alignItems="center"
    >
      <Icon path={icons.exclamationMark} color="lightText" size="20px" mr="12px" />
      <Text
        variant="bodyMBook"
        color="lightText"
        value={getTranslationText(errorMessage, {
          maxSize: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
        })}
      />
    </Container>
  );
};
