import * as React from 'react';

import { Place } from '../types/geo';

type PlaceResult = Promise<Place | null>;
type Props = {
  country?: string;
  language: string;
  onSelect: (value: any) => void;
  render: (fn: (searchTerm: string) => PlaceResult) => React.ReactNode;
  getPlace: (searchTerm: string, country?: string, language?: string) => PlaceResult;
};

export const Geocoder = ({ onSelect, country, language, getPlace, render }: Props): any => {
  // @ts-ignore;
  return render((searchTerm: string) => {
    return getPlace(searchTerm, country, language).then(onSelect);
  });
};
