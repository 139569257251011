import { createSelector } from 'reselect';
import { SignupResponse } from '@taxfix/taxfix-sdk/dist/user/signup';
import { CurrentUserResponse } from '@taxfix/taxfix-sdk/dist/user/current';
import { LoginResponse } from '@taxfix/taxfix-sdk/dist/user/login';

export const MARK_EMAIL_ADDRESS_CONFIRMED = 'userAuth/MARK_EMAIL_ADDRESS_CONFIRMED';
export const ADD_SUBMISSION_TO_USER = 'userAuth/ADD_SUBMISSION_TO_USER';
export const RESET = 'userAuth/RESET';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_USER = 'userAuth/UPDATE_USER';

export type Submission = {
  id: number;
  resultId: string;
  userId: number;
  year: number;
  validationResult: number;
  created: string;
  updated: string;
  state: string;
  type: string;
  identificationId: number;
};
export enum Provider {
  apple = 'apple',
  google = 'google',
  email = 'email',
}
export type User = {
  userId: number;
  emailAddress?: string;
  accessToken?: string;
  activatedAt: Date | null | undefined;
  confirmedAt: Date | null | undefined;
  submissions: Submission[];
  name?: string;
  provider?: Provider;
};
type UpdateUserPayload = Partial<User>;
export type BaseUserResponse = SignupResponse | CurrentUserResponse | LoginResponse;
export type State = {
  user: UpdateUserPayload | null | undefined;
};
type UpdateUserAction = {
  type: typeof UPDATE_USER;
  payload: {
    user: UpdateUserPayload;
  };
};
type MarkEmailAddressConfirmedAction = {
  type: typeof MARK_EMAIL_ADDRESS_CONFIRMED;
  payload: {
    confirmedAt: Date;
  };
};
type UpdateProviderAction = {
  type: typeof UPDATE_PROVIDER;
  payload: { provider: Provider };
};
type ResetAction = {
  type: typeof RESET;
};

type Action =
  | UpdateProviderAction
  | UpdateUserAction
  | MarkEmailAddressConfirmedAction
  | ResetAction;

const updateUserFromResponse = (
  userResponse: BaseUserResponse,
  emailAddress?: string,
  accessToken?: string,
): UpdateUserAction => {
  const { activated, confirmed, submissions, id, name } = userResponse;
  const confirmedAt = confirmed ? new Date(confirmed) : null;
  const user: UpdateUserPayload = {
    userId: id,
    activatedAt: new Date(activated),
    confirmedAt,
    submissions: submissions as Submission[],
    name,
  };
  if (emailAddress) user.emailAddress = emailAddress;
  if (accessToken) user.accessToken = accessToken;
  return {
    type: UPDATE_USER,
    payload: {
      user,
    },
  };
};

const updateUserName = (name: string): UpdateUserAction => {
  return {
    type: UPDATE_USER,
    payload: {
      user: {
        name,
      },
    },
  };
};

const updateUserEmail = (emailAddress: string): UpdateUserAction => {
  return {
    type: UPDATE_USER,
    payload: {
      user: {
        emailAddress,
      },
    },
  };
};

const markEmailAddressConfirmed = (confirmedAt: Date): MarkEmailAddressConfirmedAction => ({
  type: MARK_EMAIL_ADDRESS_CONFIRMED,
  payload: {
    confirmedAt,
  },
});

const updateProvider = (provider: Provider): UpdateProviderAction => ({
  type: UPDATE_PROVIDER,
  payload: { provider },
});

const reset = (): ResetAction => ({
  type: RESET,
});

export const actions = {
  updateUserFromResponse,
  updateUserName,
  updateUserEmail,
  markEmailAddressConfirmed,
  reset,
  updateProvider,
};
export const initial: State = {
  user: null,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload.user } };

    case MARK_EMAIL_ADDRESS_CONFIRMED:
      return { ...state, user: { ...state.user, confirmedAt: action.payload.confirmedAt } };

    case UPDATE_PROVIDER:
      return { ...state, user: { ...state.user, provider: action.payload.provider } } as any;

    case RESET:
      return { ...initial };

    default:
      return state;
  }
};
export type RootState = {
  userAuth: State;
};

const getUser = (state: RootState) => state.userAuth.user;

const getProvider = createSelector(getUser, (user) => user?.provider || Provider.email);
const getAccessToken = createSelector(getUser, (user) => user?.accessToken || '');
const getEmailAddress = createSelector(getUser, (user) => user?.emailAddress);
const getUserId = createSelector(getUser, (user) => user?.userId);
const getUserActivatedAt = createSelector(getUser, (user) => user?.activatedAt);
const getName = createSelector(getUser, (user) => user?.name);
const isAuthenticated = createSelector(getUser, (user) => !!user?.userId);
const hasEmailAddress = createSelector(getUser, (user) => !!user?.emailAddress);
const isEmailAddressConfirmed = createSelector(getUser, (user) => !!user?.confirmedAt);
const getUserConfirmedAt = createSelector(getUser, (user) => user?.confirmedAt);

export const selectors = {
  getAccessToken,
  getProvider,
  getEmailAddress,
  getUserId,
  getUserActivatedAt,
  getName,
  isAuthenticated,
  hasEmailAddress,
  isEmailAddressConfirmed,
  getUserConfirmedAt,
};
