import React from 'react';

import { IntlNumber } from '../../../../i18n';
import { theme } from '../../../core';

import SummaryText from './summary-text';

type Props = {
  value: number;
  properties: Record<string, any>;
  linkable?: boolean;
  color?: string;
};

function NumberSummary({ value, properties = {}, linkable, color = theme.color.primary }: Props) {
  if (properties.emptyValue != null && value === properties.emptyValue) {
    return (
      <SummaryText
        id="summaries.default.left-empty.number"
        style={{
          textAlign: 'right',
        }}
        linkable={linkable}
        color={color}
      />
    );
  }

  return (
    <IntlNumber
      Component={SummaryText}
      style={{
        textAlign: 'right',
      }}
      formatStyle={properties.formatter}
      value={value}
      // @ts-ignore
      linkable={linkable}
      color={color}
    />
  );
}

export default NumberSummary;
