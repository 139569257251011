import { Platform } from 'react-native';

type testID1 = {
  accessible: boolean;
  testID?: string;
  accessibilityLabel?: string;
};
export function testID(id?: string): testID1 {
  return Platform.OS === 'android'
    ? {
        accessible: true,
        testID: id,
      }
    : {
        accessible: false,
        testID: id,
      };
}
export function testAccessibility() {
  return Platform.OS !== 'android'
    ? {
        accessible: false,
      }
    : null;
}
