export const SET_BRAZE_ID = 'braze/SET_BRAZE_ID';
type State = {
  brazeId: string | null;
};
type RootState = {
  braze: State;
};
type SetBrazeIdAction = {
  type: typeof SET_BRAZE_ID;
  payload: string;
};
type Action = SetBrazeIdAction;

const setBrazeIdAction = (brazeId: string): SetBrazeIdAction => ({
  type: SET_BRAZE_ID,
  payload: brazeId,
});

export const actions = {
  setBrazeIdAction,
};
export const initial: State = {
  brazeId: null,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SET_BRAZE_ID: {
      const brazeId = action.payload;
      return { ...state, brazeId };
    }

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};

// selectors
const selectBrazeId = (state: RootState): string | null => state.braze.brazeId;

export const selectors = {
  selectBrazeId,
};
