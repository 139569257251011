import React, { createContext, useContext } from 'react';
import { useIntl } from 'react-intl';

import formatNumber from 'src/i18n/formatNumber';
import { flagsQuestionIds, prefillQuestionIds, ProductBundleValues } from 'src/common/constants-it';
import { useQuizmasterLight } from 'src/_italy/_hooks';

type PaymentContextProviderProps = {
  children: React.ReactNode;
  productOriginalPrice: number;
  productPrice: number;
  paypalError: string | null;
};

type PaymentContextType = {
  selectedProductBundle: ProductBundleValues.instant | ProductBundleValues.guided;
  productOriginalPrice: number;
  productPrice: number;
  formattedOriginalPrice: string;
  formattedPrice: string;
  billingAddress: string;
  paypalError: string | null;
};

const PaymentContext = createContext<PaymentContextType | null>(null);

const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  children,
  productOriginalPrice,
  productPrice,
  paypalError,
}) => {
  const intl = useIntl();
  const quizmasterLight = useQuizmasterLight([
    flagsQuestionIds.productBundleSelection,
    prefillQuestionIds.billingAddress,
  ]);
  const selectedProductBundle = quizmasterLight[flagsQuestionIds.productBundleSelection].answer;

  const billingAddress = quizmasterLight[prefillQuestionIds.billingAddress].answer?.displayName;

  const formatPrice = (price: number) =>
    formatNumber(intl, {
      formatStyle: 'currency',
      value: price,
    });

  return (
    <PaymentContext.Provider
      value={{
        selectedProductBundle,
        productOriginalPrice,
        productPrice,
        billingAddress,
        formattedOriginalPrice: formatPrice(productOriginalPrice),
        formattedPrice: formatPrice(productPrice),
        paypalError,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

const usePaymentContext = (): PaymentContextType => {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePaymentContext must be used within a PaymentContextProvider');
  }
  return context;
};

export { PaymentContextProvider, usePaymentContext };
