import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useBreakpointValue, useTheme } from 'native-base';

import { Container, Text } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { IconButton, useSmallScreenQuery } from 'src/components/core';
import { StyleProp } from 'src/components/types';
import { useCWBIDocumentsConfig } from 'src/hooks/use-cwbi-documents-config';

import { useItalyIntl } from '../../_italy/_hooks';

const getResponsiveStyles = (overlayStyle: StyleProp, backgroundColor: StyleProp) =>
  StyleSheet.create({
    overlayMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      ...backgroundColor,
    },
    overlayDesktop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ...backgroundColor,
    },
    pressableContainerStyle: {
      borderRadius: 20,
      overflowY: 'visible',
    },
    contentContainerStyle: {
      ...overlayStyle,
    },
    closeButton: {
      marginLeft: 16,
    },
  });

type OverlayProps = {
  onClose: () => void;
};

export const SPIDConsentDocumentsInfo: React.FC<OverlayProps> = ({ onClose }) => {
  const { completeRequestedDocsList } = useCWBIDocumentsConfig();
  const { getTranslationText } = useItalyIntl();
  const overlayMaxH = useBreakpointValue({ base: '90%', lg: '100%' });
  const isMobile = useSmallScreenQuery();
  const { colors } = useTheme();

  const styles = useMemo(
    () =>
      getResponsiveStyles(
        { maxHeight: overlayMaxH },
        { backgroundColor: colors.overlay(colors.greytones.backgroundDark, 0.4) },
      ),
    [overlayMaxH, colors],
  );

  return (
    <Overlay
      onCloseComplete={onClose}
      pressableContainerStyle={styles.pressableContainerStyle}
      contentContainerStyle={styles.contentContainerStyle}
      style={isMobile ? styles.overlayMobile : styles.overlayDesktop}
      position="bottom"
    >
      {({ onClose: closeWithAnimation }: { onClose: () => void }) => {
        return (
          <Container bgColor={colors.greytones.white} maxWidth="600px" padding="40px">
            <Container flexDir="row" marginBottom="20px" justifyContent="space-between">
              <Text variant={isMobile ? 'titleSBold' : 'titleMBold'} width="100%">
                {getTranslationText('it.spid-consent-documents-info-overlay.title')}
              </Text>
              <IconButton
                onPress={closeWithAnimation}
                iconKey="cross"
                style={styles.closeButton}
                tintColor={colors.greytones.graphicsDark}
              />
            </Container>
            <Container marginBottom="28px">
              <Text
                value={getTranslationText(
                  'it.spid-consent-documents-info-overlay.doc-list.subtitle',
                )}
                variant="bodyMBook"
                marginBottom="16px"
              />
              {completeRequestedDocsList.map((document, index) => (
                <Text value={`• ${document}`} variant="bodyMBook" key={`${index}-${document}`} />
              ))}
            </Container>
            <Container>
              <Text
                value={getTranslationText(
                  'it.spid-consent-documents-info-overlay.purpose.subtitle',
                )}
                variant="titleXSBold"
                marginBottom="16px"
              />

              <Text
                value={getTranslationText(
                  'it.spid-consent-documents-info-overlay.purpose.list-items',
                )}
                variant="bodyMBook"
              />
            </Container>
          </Container>
        );
      }}
    </Overlay>
  );
};
