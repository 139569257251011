import { Documents as DocumentsSDK } from '@taxfix/taxfix-sdk';
import { CountryCodes, Documents } from '@taxfix/types';
import Config from 'react-native-config';

import { getNavigationConfigWithParams } from 'src/screens/status-screen/middlewares/utils';

import { StatusStepNavigationData } from '../stores/modules/status';
import {
  PrefillState,
  PrefillRejectedReason,
  PrefillStates,
  PrefillRejectedReasons,
} from '../types/prefills';

export const resolveNavigationForItaly = async (
  config: Record<string, StatusStepNavigationData | null | undefined>,
  prefillStatus: PrefillState | null | undefined,
  rejectedReasons: PrefillRejectedReason[],
  year: number,
  accessToken: string,
  userId: number | undefined,
): Promise<StatusStepNavigationData | null | undefined> => {
  const countryCode: CountryCodes = CountryCodes.IT;
  const resultIdDocument = await DocumentsSDK.get(Config.API_BASE_URL, accessToken, {
    userId,
    page: 0,
    pageSize: 1,
    order: {
      createdAt: 'desc',
    },
    countryCode,
    types: [Documents.NonReceiptTypes.Id],
    year,
  });

  // If the user has no ID uploaded or pre-fill for some reason is not yet created,
  // take user to the prefill screen.
  if (resultIdDocument.total === 0 || prefillStatus === null) {
    return getNavigationConfigWithParams(config.default, {
      prefillDocumentType: Documents.NonReceiptTypes.Id,
      year,
    });
  }

  // Note: the condition about PrefillStates.Rejected is more restrict than Document.State.Rejected
  if (prefillStatus === PrefillStates.Rejected) {
    return getNavigationConfigWithParams(config.rejectedInfo, {
      year,
      // As of https://taxfix.atlassian.net/browse/ITA-642 we're only uploading ID to the prefill
      prefillDocumentType: Documents.NonReceiptTypes.Id,
    });
  }
  // We need to consider also Documents.States.Rejected in case PrefillStates is not changed into Rejected
  // This is an edge case we need to consider
  if (resultIdDocument.data[0].state === Documents.States.Rejected) {
    return getNavigationConfigWithParams(config.rejectedDocument, {
      prefillDocumentType: Documents.NonReceiptTypes.Id,
      year,
    });
  }

  // All done, user is not blocked.
  return null;
};

// looks wrong but it will be needed for the (future) tool that will list active translation keys
export const explicitLocaleRejectReasonsResolver = (code: PrefillRejectedReason) => {
  switch (code) {
    case PrefillRejectedReasons.IdNameInconsistent:
      return {
        id: 'status.prefill.section.rejected.reason.id-name-inconsistent',
      };

    case PrefillRejectedReasons.IdPictureMissing:
      return {
        id: 'status.prefill.section.rejected.reason.id-picture-missing',
      };

    case PrefillRejectedReasons.IdWrongType:
      return {
        id: 'status.prefill.section.rejected.reason.id-wrong-type',
      };

    case PrefillRejectedReasons.IdExpired:
      return {
        id: 'status.prefill.section.rejected.reason.id-expired',
      };

    case PrefillRejectedReasons.IdUnreadable:
      return {
        id: 'status.prefill.section.rejected.reason.id-unreadable',
      };

    case PrefillRejectedReasons.IdPartial:
      return {
        id: 'status.prefill.section.rejected.reason.id-partial',
      };

    case PrefillRejectedReasons.PastDeclarationWrongType:
      return {
        id: 'status.prefill.section.rejected.reason.past-declaration-wrong-type',
      };

    case PrefillRejectedReasons.PastDeclarationWrongPage:
      return {
        id: 'status.prefill.section.rejected.reason.past-declaration-wrong-page',
      };

    case PrefillRejectedReasons.PastDeclarationUnreadable:
      return {
        id: 'status.prefill.section.rejected.reason.past-declaration-unreadable',
      };

    case PrefillRejectedReasons.PastDeclarationPartial:
      return {
        id: 'status.prefill.section.rejected.reason.past-declaration-partial',
      };

    case PrefillRejectedReasons.PastDeclarationInconsistent:
      return {
        id: 'status.prefill.section.rejected.reason.past-declaration-inconsistent',
      };

    case PrefillRejectedReasons.TaxIdInvalid:
      return {
        id: 'status.prefill.section.rejected.reason.tax-id-invalid',
      };

    case PrefillRejectedReasons.PrefillNotPredisposed:
    default:
      return {
        id: '',
      };
  }
};
