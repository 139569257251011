import React from 'react';
import _ from 'lodash';
import { PermissionsAndroid, Platform } from 'react-native';
import RNFetchBlob from 'react-native-blob-util';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import { questionStoresIT } from '../../stores-legacy';
import { selectors as settingsSelectors } from '../../stores/modules/settings';

import ImportResponses from './import-responses';

const mapStateToProps = (state: any) => {
  const selectedCountry = settingsSelectors.selectedCountry(state);
  const getStores = () => questionStoresIT;

  return {
    selectedCountry,
    questionStores: getStores(),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = {
  onContinue: () => void;
} & ReduxProps;
type State = {
  isLoading: boolean;
  isComplete: boolean;
  importedCount: number;
  fileList: string[];
  error: string | null | undefined;
};

class ImportResponsesUndecoratedContainer extends React.PureComponent<Props, State> {
  state = {
    isLoading: false,
    isComplete: false,
    importedCount: 0,
    fileList: [],
    error: null,
  };

  componentDidMount() {
    this.getFileList();
  }

  getFilePath = () => {
    const { dirs } = RNFetchBlob.fs;

    if (Platform.OS === 'ios') {
      return `${dirs.DocumentDir}/Inbox/`;
    }

    return dirs.DownloadDir;
  };

  getFileList = async () => {
    try {
      const path = this.getFilePath();

      if (Platform.OS === 'android') {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
          {
            title: 'Taxfix import txfx files',
            message: 'Taxfix App needs access to your storage to import txfx files.',
            buttonPositive: 'OK',
          },
        );

        if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
          throw new Error('Storage permission denied');
        }
      }

      const files = await RNFetchBlob.fs.ls(path);

      if (Platform.OS === 'android') {
        files.reverse();
      }

      this.setState({
        error: null,
        fileList: files.reverse(),
      });
    } catch (error) {
      this.setState({
        error: error.toString(),
      });
    }
  };

  handleImportResponses = (fileName: string) => {
    this.setState(
      {
        isLoading: true,
        isComplete: false,
        error: null,
      },
      async () => {
        try {
          const importedCount = await this.importResponses(fileName);
          this.setState({
            isLoading: false,
            isComplete: true,
            importedCount,
          });
        } catch (error) {
          this.setState({
            isLoading: false,
            isComplete: false,
            error: error.toString(),
          });
        }
      },
    );
  };

  handleDeleteFile = async (file: string) => {
    const path = this.getFilePath();
    await RNFetchBlob.fs.unlink(path + file);
    this.getFileList();
  };

  importResponses = async (fileName: string) => {
    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        {
          title: 'Taxfix import txfx files',
          message: 'Taxfix App needs access to your storage to import txfx files.',
          buttonPositive: 'OK',
        },
      );

      if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
        throw new Error('Storage permission denied');
      }
    }

    const { dirs } = RNFetchBlob.fs;
    let path = dirs.DownloadDir;

    if (Platform.OS === 'ios') {
      path = `${dirs.DocumentDir}/Inbox/`;
    }

    const filePath = `${path}/${fileName}`;
    let fileData: any = {};

    try {
      const fileContent: string = (await RNFetchBlob.fs.readFile(
        filePath,
        'utf8',
      )) as any as string;
      fileData = JSON.parse(fileContent);
    } catch (error) {
      throw new Error('Unable to read file');
    }

    if (!fileData.responses) {
      throw new Error('File does not contain responses');
    }

    let importedResponsesCount = 0;
    const promises: Promise<any>[] = [];

    const years = _.keys(this.props.questionStores);

    years.forEach((year) => {
      const responses = fileData.responses.filter(
        (response: any) => response.year.toString() === year,
      );

      if (!_.isEmpty(responses)) {
        promises.push(
          this.props.questionStores[year].importResponses(responses).then(() => {
            importedResponsesCount += responses.length;
          }),
        );
      }
    });
    return Promise.all(promises).then(() => importedResponsesCount);
  };

  render() {
    const { onContinue } = this.props;
    const { isLoading, isComplete, importedCount, fileList, error } = this.state;
    return (
      <ImportResponses
        onImportResponses={this.handleImportResponses}
        onDeleteFile={this.handleDeleteFile}
        onContinue={onContinue}
        isLoading={isLoading}
        isComplete={isComplete}
        importedCount={importedCount}
        error={error}
        fileList={fileList}
      />
    );
  }
}

export const ImportResponsesContainer = compose(connector)(ImportResponsesUndecoratedContainer);
