import * as React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';

import {
  ModalWithOverlay,
  theme,
  BodyPrimaryText,
  Icon,
  IconButton,
  Box,
  TextButton,
  DisplayTitleText,
} from './core';

type Props = {
  referralLink: string;
  voucherReduction: string;
  onCopy: () => void;
  onClose: () => void;
};

const styles = StyleSheet.create({
  modalContainer: {
    maxWidth: 460,
    padding: 20,
    backgroundColor: theme.color.lightFill,
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  contentContainer: {
    margin: 30,
    justifyContent: 'space-between',
  },
  image: {
    height: 157,
    marginBottom: 20,
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 22,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  linkInfoText: {
    paddingVertical: 15,
  },
  linkContainer: {
    backgroundColor: theme.color.backgroundLight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkArea: {
    alignSelf: 'stretch',
    maxWidth: 360,
  },
  linkTextContainer: {
    width: '75%',
    paddingHorizontal: 20,
  },
  link: {
    paddingLeft: 16,
    paddingVertical: 20,
  },
  copyButton: {
    paddingRight: 16,
    paddingVertical: 20,
  },
});
export const ReferralLinkPopup = ({
  referralLink,
  onClose,
  onCopy,
  voucherReduction,
}: Props): JSX.Element => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleCopy = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.select();
    document.execCommand('copy');
    onCopy();
  };

  return (
    <ModalWithOverlay
      visible
      hideHeader
      transparent
      animationType="fade"
      justifyContent="center"
      onOverlayPress={onClose}
    >
      <View style={styles.modalContainer}>
        <IconButton
          style={styles.closeButton}
          iconKey="cross"
          tintColor={theme.color.secondaryText}
          onPress={onClose}
        />
        <Box alignHorizontally="center" style={styles.contentContainer}>
          <Icon name="referral.purpose-oriented-screen" style={styles.image} resizeMode="contain" />
          <DisplayTitleText
            id="it-referral-link-popup.title"
            style={[styles.textCenter, styles.title]}
          />
          <BodyPrimaryText id="it-referral-link-popup.description" style={styles.textCenter} />
          <Box style={styles.linkArea}>
            <DisplayTitleText id="it-referral-link-popup.link-info" style={styles.linkInfoText} />
            <Box style={styles.linkContainer}>
              <Box style={styles.linkTextContainer}>
                <TextInput
                  // @ts-ignore
                  ref={inputRef}
                  value={referralLink}
                  editable={false}
                  spellCheck={false}
                  numberOfLines={1}
                  style={styles.link}
                />
              </Box>
              <TextButton
                align="right"
                translationKey="it-referral-link-popup.copy-button"
                onPress={handleCopy}
                style={styles.copyButton}
              />
            </Box>
          </Box>
        </Box>
      </View>
    </ModalWithOverlay>
  );
};
