import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { SVGProps } from '../types';

/**
 * StepsIcon CU with several paths, coloured
 * Temporary component
 */

export const CUIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <G fill="none">
      <Path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#E1F8E6"
      />
      <Path d="M32.4836 15.0001H19V50H32.4836V15.0001Z" fill="white" />
      <Path d="M46 15H32.5164V49.9999H46V15Z" fill="white" />
      <Path d="M39.0491 35.7272H19.99V36.7313H39.0491V35.7272Z" fill="#C8E7FC" />
      <Path d="M39.0491 37.5683H19.99V38.5724H39.0491V37.5683Z" fill="#C8E7FC" />
      <Path d="M39.0491 39.3134H19.99V40.3175H39.0491V39.3134Z" fill="#C8E7FC" />
      <Path d="M39.0491 41.1545H19.99V42.1586H39.0491V41.1545Z" fill="#C8E7FC" />
      <Path
        d="M21.8745 16.1956H20.7758C20.3418 16.1956 19.99 16.5611 19.99 17.0118V18.1529C19.99 18.6037 20.3418 18.9691 20.7758 18.9691H21.8745C22.3085 18.9691 22.6603 18.6037 22.6603 18.1529V17.0118C22.6603 16.5611 22.3085 16.1956 21.8745 16.1956Z"
        fill="#6793FF"
      />
      <Path
        d="M43.2709 17.0038C43.259 17.1055 43.2213 17.1841 43.1579 17.2396C43.0949 17.2946 43.011 17.3222 42.9062 17.3222C42.7926 17.3222 42.7014 17.2814 42.6327 17.1999C42.5645 17.1185 42.5303 17.0095 42.5303 16.873V16.7805C42.5303 16.6911 42.5462 16.6125 42.5779 16.5447C42.6101 16.4768 42.6554 16.4249 42.714 16.3888C42.7726 16.3522 42.8404 16.3339 42.9174 16.3339C43.0196 16.3339 43.1015 16.3626 43.1632 16.4198C43.2248 16.4766 43.2607 16.5554 43.2709 16.6563H43.1434C43.1323 16.5797 43.1083 16.5242 43.0714 16.4898C43.0348 16.4555 42.9835 16.4383 42.9174 16.4383C42.8364 16.4383 42.7728 16.4683 42.7265 16.5281C42.6807 16.588 42.6578 16.6732 42.6578 16.7838V16.8769C42.6578 16.9813 42.6796 17.0643 42.7232 17.126C42.7668 17.1876 42.8278 17.2184 42.9062 17.2184C42.9767 17.2184 43.0306 17.2026 43.0681 17.1709C43.1059 17.1387 43.131 17.083 43.1434 17.0038H43.2709ZM44.1217 16.3471V17.0011C44.1212 17.0918 44.0926 17.166 44.0358 17.2237C43.9794 17.2814 43.9028 17.3138 43.8059 17.3208L43.7722 17.3222C43.667 17.3222 43.5831 17.2938 43.5205 17.2369C43.458 17.1801 43.4263 17.102 43.4254 17.0024V16.3471H43.5509V16.9985C43.5509 17.0681 43.5701 17.1222 43.6084 17.161C43.6467 17.1993 43.7013 17.2184 43.7722 17.2184C43.844 17.2184 43.8988 17.1993 43.9367 17.161C43.975 17.1227 43.9942 17.0687 43.9942 16.9991V16.3471H44.1217Z"
        fill="black"
      />
      <Path d="M44.8959 20.2119H19.99V20.572H44.8959V20.2119Z" fill="#6793FF" />
      <Path d="M44.8959 24.9453H19.99V25.3053H44.8959V24.9453Z" fill="#1C3086" />
      <Path d="M44.8959 29.6791H19.99V30.7987H44.8959V29.6791Z" fill="#6793FF" />
      <Path d="M44.8959 33.2414H19.99V34.3609H44.8959V33.2414Z" fill="#1C3086" />
      <Path d="M44.8956 35.0099H39.8318V36.3962H44.8956V35.0099Z" fill="#C8E7FC" />
      <Path d="M44.8956 36.7079H39.8318V38.0943H44.8956V36.7079Z" fill="#C8E7FC" />
      <Path d="M44.8956 38.4292H39.8318V39.8156H44.8956V38.4292Z" fill="#C8E7FC" />
      <Path d="M44.8956 40.1266H39.8318V41.5129H44.8956V40.1266Z" fill="#C8E7FC" />
      <Path d="M44.8489 41.8481H39.7852V43.2345H44.8489V41.8481Z" fill="#C8E7FC" />
      <Path d="M44.8489 43.5453H39.7852V44.9316H44.8489V43.5453Z" fill="#C8E7FC" />
      <Path d="M44.8489 45.2661H39.7852V46.6525H44.8489V45.2661Z" fill="#C8E7FC" />
      <Path d="M44.8489 46.9637H39.7852V48.3501H44.8489V46.9637Z" fill="#C8E7FC" />
      <Path d="M31.3613 21.3116H20.0364V22.4591H31.3613V21.3116Z" fill="#D1D1D1" />
      <Path d="M21.6655 46.5571H20.312V47.8653H21.6655V46.5571Z" fill="#D1D1D1" />
      <Path d="M23.4145 46.5571H22.061V47.8653H23.4145V46.5571Z" fill="#D1D1D1" />
      <Path d="M25.1406 46.5571H23.7871V47.8653H25.1406V46.5571Z" fill="#D1D1D1" />
    </G>
  </Icon>
);
