import React from 'react';

import { useNavigation } from 'src/hooks/navigation-hook';

import { SVGIconsBrowserComponent } from './svg-icons-browser-component';

export const SVGIconsBrowserContainer: React.FC = () => {
  const { navigation } = useNavigation();

  const handleGoBack = () => {
    navigation.goBack();
  };

  return <SVGIconsBrowserComponent goBack={handleGoBack} />;
};
