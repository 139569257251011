import React from 'react';
import { StyleSheet, View } from 'react-native';
import compact from 'lodash/compact';

import { theme } from '../core';
import { isWeb } from '../../utils/platform';

const styles = StyleSheet.create({
  containerFlexible: {
    flex: 1,
  },
  containerVertical: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  containerHorizontal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  childVertical: {
    minHeight: 50,
    paddingHorizontal: 16,
  },
  childHorizontal: {
    flex: 0,
    minHeight: 50,
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  childHorizontalWeb: {
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  childFlexible: {
    flex: 1,
  },
  borderH: {
    borderLeftColor: theme.color.border,
    borderLeftWidth: 1,
  },
  borderV: {
    borderTopColor: theme.color.border,
    borderTopWidth: 1,
  },
});
type Props = {
  alignment?: 'vertical' | 'horizontal';
  flexible?: boolean;
  children?: React.ReactNode;
  hideBorder?: boolean;
  style?: any; // Flow: ??? Styles/Stylesheet don't work :(
  childStyle?: any; // Flow:
};

// `props.children` is an opaque data structure that may
// contain nulls, we need to convert to an array and
// remove falsey values before rendering it
const compactedChildrenArray = (children: any) => compact(React.Children.toArray(children));

const ButtonBar = ({
  children,
  flexible = true,
  alignment = 'horizontal',
  style = {},
  childStyle = {},
  hideBorder = false,
}: Props) => {
  const isVertical = alignment === 'vertical';
  const borderStyles = isVertical ? styles.borderV : styles.borderH;
  const containerStyles = [
    isVertical ? styles.containerVertical : styles.containerHorizontal,
    flexible ? styles.containerFlexible : null,
    style,
  ];
  const childStyles = [
    isVertical
      ? styles.childVertical
      : isWeb
      ? [styles.childHorizontal, styles.childHorizontalWeb]
      : styles.childHorizontal,
    flexible ? styles.childFlexible : null,
    childStyle,
  ];
  return (
    <View style={containerStyles}>
      {compactedChildrenArray(children).map((child, index) => (
        <View key={index} style={[childStyles, index > 0 && !hideBorder ? borderStyles : null]}>
          {child}
        </View>
      ))}
    </View>
  );
};

export default ButtonBar;
