/*
Steps to generate paths
1. Export icons as SVGs from figma
2. Visit https://icomoon.io/app and import the SVGs
3. Select all SVGs that you want to convert and Select the option to Download as json
4. Once the JSON is downloaded, run the script "generate_json_from_icomoon.js" like below
  node ./scripts/generate_json_from_icomoon <path to json file>
5. Copy the contents of the output and add to the below object
6. Visit storybook to check the Icon story and verify
 */

export const supportCenterIcons: Record<string, string> = {
  delete:
    'M637.333 512l288.004-285.333c15.991-16 15.991-45.333 0-61.333l-66.671-66.667c-16-16-45.333-16-61.333 0l-285.333 288-288-288c-16-16-45.333-16-61.333 0l-66.667 66.667c-16 16-16 45.333 0 61.333l288 285.333-288 288c-16 16-16 45.333 0 61.338l66.667 66.662c16 16 45.333 16 61.333 0l288-288 285.333 288c16 16 45.333 16 61.333 0l66.671-66.662c15.991-16.004 15.991-45.338 0-61.338l-288.004-288z',
  search:
    'M443.029 783.514c-197.551 0-357.696-156.292-357.696-349.090s160.145-349.090 357.696-349.090c197.547 0 357.692 156.293 357.692 349.090 0 84.459-31.36 165.764-87.791 229.086l213.978 208.828c11.465 11.098 14.912 27.861 8.721 42.415-6.195 14.554-20.791 24.004-36.937 23.915-10.526-0.043-20.604-4.16-28.019-11.443l-216.337-211.132c-60.928 43.537-134.857 67.422-211.307 67.422zM443.029 162.909c-153.651 0-278.208 121.561-278.208 271.514 0 149.952 124.557 271.514 278.208 271.514 153.647 0 278.204-121.562 278.204-271.514 0-72.010-29.308-141.071-81.485-191.99-52.173-50.919-122.935-79.525-196.719-79.525z',
  sadFace:
    'M512 39.619c-260.952 0-472.381 211.429-472.381 472.381 0 260.954 211.429 472.381 472.381 472.381 260.954 0 472.381-211.427 472.381-472.381 0-260.952-211.427-472.381-472.381-472.381zM512 892.952c-211.429 0-380.952-169.526-380.952-380.952 0-209.524 169.524-380.952 380.952-380.952 209.525 0 380.952 171.428 380.952 380.952 0 211.427-171.427 380.952-380.952 380.952zM359.619 481.524c32.381 0 60.952-26.667 60.952-60.952 0-32.381-28.572-60.952-60.952-60.952-34.285 0-60.952 28.572-60.952 60.952 0 34.285 26.667 60.952 60.952 60.952zM664.381 359.619c-34.284 0-60.952 28.572-60.952 60.952 0 34.285 26.668 60.952 60.952 60.952 32.383 0 60.952-26.667 60.952-60.952 0-32.381-28.57-60.952-60.952-60.952zM512 603.429c-78.095 0-148.572 34.284-198.095 93.335-17.143 19.046-13.333 47.616 5.714 64.761 19.047 15.238 47.619 13.332 64.762-5.715 30.476-38.093 78.096-60.952 127.619-60.952 47.621 0 95.237 22.86 125.713 60.952 17.145 17.145 45.714 22.86 64.761 5.715 19.051-17.145 22.86-45.714 5.715-64.761-47.616-59.051-119.998-93.335-196.189-93.335z',
  neutralFace:
    'M512 39.619c-260.952 0-472.381 211.429-472.381 472.381 0 260.954 211.429 472.381 472.381 472.381 260.954 0 472.381-211.427 472.381-472.381 0-260.952-211.427-472.381-472.381-472.381zM512 892.952c-211.429 0-380.952-169.526-380.952-380.952 0-209.524 169.524-380.952 380.952-380.952 209.525 0 380.952 171.428 380.952 380.952 0 211.427-171.427 380.952-380.952 380.952zM359.619 481.524c32.381 0 60.952-26.667 60.952-60.952 0-32.381-28.572-60.952-60.952-60.952-34.285 0-60.952 28.572-60.952 60.952 0 34.285 26.667 60.952 60.952 60.952zM664.381 359.619c-34.284 0-60.952 28.572-60.952 60.952 0 34.285 26.668 60.952 60.952 60.952 32.383 0 60.952-26.667 60.952-60.952 0-32.381-28.57-60.952-60.952-60.952zM679.619 633.905h-335.238c-26.667 0-45.714 20.953-45.714 45.714 0 26.668 19.047 45.714 45.714 45.714h335.238c24.761 0 45.714-19.046 45.714-45.714 0-24.761-20.953-45.714-45.714-45.714z',
  happyFace:
    'M512 39.619c-260.952 0-472.381 211.429-472.381 472.381 0 260.954 211.429 472.381 472.381 472.381 260.954 0 472.381-211.427 472.381-472.381 0-260.952-211.427-472.381-472.381-472.381zM512 892.952c-211.429 0-380.952-169.526-380.952-380.952 0-209.524 169.524-380.952 380.952-380.952 209.525 0 380.952 171.428 380.952 380.952 0 211.427-171.427 380.952-380.952 380.952zM359.619 481.524c32.381 0 60.952-26.667 60.952-60.952 0-32.381-28.572-60.952-60.952-60.952-34.285 0-60.952 28.572-60.952 60.952 0 34.285 26.667 60.952 60.952 60.952zM664.381 481.524c32.383 0 60.952-26.667 60.952-60.952 0-32.381-28.57-60.952-60.952-60.952-34.284 0-60.952 28.572-60.952 60.952 0 34.285 26.668 60.952 60.952 60.952zM671.998 620.573c-39.999 47.616-99.045 74.284-159.998 74.284-62.857 0-121.905-26.668-160-74.284-17.143-19.051-45.714-20.953-64.762-5.715s-22.857 45.714-5.714 64.761c57.143 68.574 140.952 106.667 230.476 106.667 87.62 0 171.427-38.093 228.571-106.667 17.145-19.046 15.238-49.523-5.715-64.761-19.046-15.238-47.616-13.336-62.859 5.715z',
};
