import React, { useEffect } from 'react';
import { ScrollView, useBreakpointValue } from 'native-base';
import { Documents } from '@taxfix/taxfix-sdk';
import { Prefill } from '@taxfix/italy-types';

import { ProductBundleValues } from 'src/common/constants-it';
import {
  Container,
  NavButton,
  Text,
  Footer,
  StepIconType,
  Stepper,
  StepperProps,
  STEP_STATUS,
} from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

import { IdleHelpPopupProps } from '../../screens/components/result-screen-idle-help-popup';

import {
  ResultCardLargeScreen,
  ResultCardMobile,
  TaxExpertCard,
} from './components/prefill-documents-result-components';
import { FORCED_ADVANCED_BUNDLE } from './constants';
import { mapCreditResultForAnalytics } from './utils';
import { useIdlePopup } from './useIdlePopup';
import { ScreenState } from './types';

type PrefillResultComponentProps = {
  taxScenario: Prefill.PrefillResultScenarios;
  recommendedBundle: typeof FORCED_ADVANCED_BUNDLE | ProductBundleValues;
  estimatedResult: number;
  totalOptimization: number;
  reasonWhyRefundIsZero: string | undefined;
  hasPrefillDocuments: boolean;
  prefillDocumentList: Documents.types.Document[];
  screenContent: ScreenState;
  setBackgroundColor: (color: string) => void;
  handleOnLinkPress: () => void;
  handleOnContinuePress: () => Promise<void>;
  handleOnBackButtonPress: () => void;
  handleOnTaxExpertCardLinkPress: () => void;
  onIdlePopupButtonPress: () => void;
  onIdlePopupShown: () => void;
  onIdlePopupClose: () => void;
};

export const PrefillResultScreenComponent: React.FC<PrefillResultComponentProps> = ({
  taxScenario,
  recommendedBundle,
  estimatedResult,
  totalOptimization,
  reasonWhyRefundIsZero,
  hasPrefillDocuments,
  prefillDocumentList,
  screenContent,
  setBackgroundColor,
  handleOnLinkPress,
  handleOnContinuePress,
  handleOnBackButtonPress,
  handleOnTaxExpertCardLinkPress,
  onIdlePopupButtonPress,
  onIdlePopupShown,
  onIdlePopupClose,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const {
    screenTitle,
    cardBgColor,
    cardDescription,
    linkTranslationKey,
    taxExpertCardTitle,
    taxExpertCardDescription,
    buttonTranslationKey,
    taxExpertCardWarning,
    taxExpertCardLinkText,
    taxExpertAverageRefundChipText,
    showConfetti,
    showEstimatedResult,
  } = screenContent;
  const showResult = Boolean(estimatedResult) && showEstimatedResult;
  const popupParams: IdleHelpPopupProps = {
    onButtonPress: onIdlePopupButtonPress,
    onLoadCallback: onIdlePopupShown,
    onClose: onIdlePopupClose,
  };

  useEffect(() => {
    if (!isLargeScreen) {
      setBackgroundColor(cardBgColor);
    }
  }, []);

  useEffect(() => {
    Analytics.log(AnalyticsEvent.sectionDeclarationResult, {
      documentsAmount: prefillDocumentList.length,
      documentList: prefillDocumentList.map((document) => document.type),
      resultType: mapCreditResultForAnalytics(estimatedResult),
      taxReturned: isNaN(estimatedResult) ? null : estimatedResult,
      resultScenario: taxScenario,
      bundleRecommendation: recommendedBundle,
      reasonWhyRefundIsZero,
    });
  }, []);

  useIdlePopup(popupParams);

  const steps: StepperProps['steps'] = [
    {
      stepIcon: 'success' as StepIconType,
      titleText: getTranslationText('prefill-result-screen.stepper.step1'),
      status: STEP_STATUS.IN_PROGRESS,
      active: true,
      titleFont: 'bodyXSBook',
    },
    {
      active: false,
      titleText: getTranslationText(
        recommendedBundle === FORCED_ADVANCED_BUNDLE
          ? 'prefill-result-screen.stepper.step2.forcedAdvanced'
          : 'prefill-result-screen.stepper.step2',
      ),
      titleFont: 'bodyXSBook',
    },
    {
      active: false,
      titleText: getTranslationText('prefill-result-screen.stepper.step3'),
      titleFont: 'bodyXSBook',
    },
    {
      active: false,
      titleText: getTranslationText('prefill-result-screen.stepper.step4'),
      titleFont: 'bodyXSBook',
    },
  ];

  return (
    <Container flex={1} width="100%">
      <Container width="100%" flex={1} backgroundColor={!isLargeScreen ? cardBgColor : 'white'}>
        {isLargeScreen && (
          <NavButton
            text={getTranslationText('it.navigation.back-to-home')}
            onPress={handleOnBackButtonPress}
          />
        )}
        <ScrollView
          flex={1}
          contentContainerStyle={{
            paddingTop: 24,
          }}
          width="100%"
          alignSelf="center"
        >
          <Container
            justifyContent="center"
            alignItems="center"
            paddingX="16px"
            mb={isLargeScreen ? '32px' : '24px'}
          >
            <Stepper
              steps={steps}
              borderStyle="solid"
              numberStyles={{
                numberBackgroundColor: isLargeScreen ? 'greytones.graphicsLight' : 'white',
                numberColor: 'greytones.textLight',
                numberFont: 'bodyXSBook',
              }}
              borderColor={isLargeScreen ? 'greytones.graphicsLight' : 'white'}
              style={{ width: '100%', maxWidth: 680 }}
            />
          </Container>
          {taxExpertCardWarning && (
            <Text
              variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
              value={getTranslationText(taxExpertCardWarning, {
                bold: (text: string) => <Text fontWeight="bold" value={text} />,
              })}
              bg="secondaryCategory.orangeHalf"
              alignSelf="center"
              color="secondaryFeedback.errorRed"
              textAlign="center"
              width="100%"
              paddingY="4px"
              maxW={isLargeScreen ? '580px' : undefined}
              mb={isLargeScreen ? '32px' : '16px'}
            />
          )}
          <Container alignSelf="center" alignItems="center" width="100%">
            {showResult && isLargeScreen && (
              <Text
                paddingBottom={['24px', '32px']}
                variant={isLargeScreen ? 'titleLBold' : 'titleSBold'}
                value={getTranslationText(screenTitle)}
                textAlign="center"
                width="100%"
              />
            )}
            <Container paddingBottom={['0px', '0px', '32px']} width={'100%'} alignItems="center">
              {isLargeScreen ? (
                <ResultCardLargeScreen
                  onPressLink={handleOnLinkPress}
                  showResult={showResult}
                  estimatedResult={estimatedResult}
                  title={screenTitle}
                  description={cardDescription}
                  link={linkTranslationKey}
                  hasPrefillDocuments={hasPrefillDocuments}
                  bgColor={cardBgColor}
                  showConfetti={showConfetti}
                />
              ) : (
                <ResultCardMobile
                  showConfetti={showConfetti}
                  onPressLink={handleOnLinkPress}
                  showResult={showResult}
                  estimatedResult={estimatedResult}
                  title={screenTitle}
                  description={cardDescription}
                  link={linkTranslationKey}
                  hasPrefillDocuments={hasPrefillDocuments}
                />
              )}
            </Container>
            <Container paddingX="24px" width="100%" alignItems="center">
              <TaxExpertCard
                isLargeScreen={isLargeScreen}
                taxExpertCardWarning={taxExpertCardWarning}
                taxExpertCardTitle={taxExpertCardTitle}
                taxExpertCardDescription={taxExpertCardDescription}
                averageRefundChipText={taxExpertAverageRefundChipText}
                onPressLink={handleOnTaxExpertCardLinkPress}
                linkText={taxExpertCardLinkText}
                optimizationValue={totalOptimization}
              />
            </Container>
          </Container>
        </ScrollView>
      </Container>
      <Footer
        backgroundColor={!isLargeScreen ? cardBgColor : 'white'}
        px="14px"
        primaryButtonTitle={getTranslationText(buttonTranslationKey)}
        onPressPrimary={handleOnContinuePress}
        borderTopWidth={0}
      />
    </Container>
  );
};
