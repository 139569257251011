import { NativeModules } from '../biz-logic/nativeModules';

export function getLocalizedMonthName(
  date: string | Date,
  locale: string,
  format: 'long' | 'short' = 'long',
) {
  return new Date(date).toLocaleString(locale, {
    month: format,
  });
}
type DateConfig = {
  showMinute?: boolean;
};
export const getLocalizedDateAndTime = (date: Date, { showMinute }: DateConfig = {}) => {
  const {
    LocaleInfo: { deviceLocale: locale },
  } = NativeModules;
  return {
    year: new Intl.DateTimeFormat(locale, {
      year: 'numeric',
    }).format(date),
    date: new Intl.DateTimeFormat(locale, {
      month: 'numeric',
      day: 'numeric',
    }).format(date),
    time: new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: showMinute ? 'numeric' : undefined,
    }).format(date),
  };
};
