import { useTheme } from 'native-base';
import React from 'react';

import { Button, Card, Container, ListItem, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const CardsGroup: React.FC<Props> = ({ cardsList, ...rest }): JSX.Element => {
  const { colors } = useTheme();

  return (
    <Container flexDirection={['column', 'row']} flexWrap="wrap" {...rest}>
      {cardsList.map(
        (
          {
            title,
            subTitle,
            buttonLabel,
            content,
            itemsList = [],
            onPressHandler = () => {},
            backgroundColor,
          },
          i,
        ) => (
          <Card
            key={`card-${title}-${i}`}
            p={[35, 35, 35, 70]}
            backgroundColor={backgroundColor}
            variant="folder"
            color={colors.secondaryFeedback.greenDark}
            w={['full', '50%']}
            borderRadius="none"
          >
            {title && (
              <Text
                variant="titleXLBold"
                color={colors.secondaryFeedback.greenDark}
                value={title}
                mb={20}
              />
            )}
            {subTitle && (
              <Text
                variant="titleMBook"
                color={colors.secondaryFeedback.greenDark}
                value={subTitle}
                mb={40}
              />
            )}
            {content && (
              <Text
                variant="subtitleMMedium"
                color={colors.secondaryFeedback.greenDark}
                value={content}
                mb={20}
              />
            )}
            {itemsList?.map((itemLabel: string | React.ReactNode, itemIndex: number) => (
              <ListItem
                key={`list-item-${itemLabel}`}
                text={itemLabel}
                mb={itemsList.length - 1 === itemIndex ? '0px' : 45}
              />
            ))}
            {buttonLabel && (
              <Button
                variant="primary"
                fontSize="xs"
                maxW={['full', 210]}
                alignSelf="flex-start"
                title={buttonLabel}
                onPress={onPressHandler}
              />
            )}
          </Card>
        ),
      )}
    </Container>
  );
};
