import React, { useState, useCallback, useEffect } from 'react';

import { UploadProgressOverlay } from '../components/upload-progress-overlay';

export type FileUploadJob = {
  upload: () => Promise<void>;
  fileName: string;
};

type Props = {
  jobs: FileUploadJob[];
  uploadFiles: (
    arg0: FileUploadJob[],
    arg1: (arg0: number) => void,
    arg2: () => void,
    arg3: string,
  ) => void;
  onClose: () => void;
  uploadType: string;
};

export const MultipleUploadProgressOverlayContainer = ({
  jobs,
  uploadFiles,
  onClose,
  uploadType,
}: Props): JSX.Element => {
  const [progress, setProgress] = useState<number>(0);
  const isSingleFile = jobs.length === 1;

  const onUploadProgress = useCallback(
    (completed: number) => {
      setProgress(completed);
    },
    [setProgress],
  );

  useEffect(() => {
    uploadFiles(jobs, onUploadProgress, onClose, uploadType);
  }, []);

  return (
    <UploadProgressOverlay
      titleId={
        isSingleFile
          ? 'single.upload.progress.overlay.title'
          : 'multiple.upload.progress.overlay.title'
      }
      progressId={
        isSingleFile
          ? 'single.upload.progress.overlay.progress'
          : 'multiple.upload.progress.overlay.progress'
      }
      translationValues={{
        progress,
        total: jobs.length,
      }}
    />
  );
};
