import React, { useState, useCallback } from 'react';

import { UtilProps, withUtil } from '../hocs/with-utils';
import Box from '../components/core/Box';
import { theme } from '../components/core/theme';
import { BodyTitleText, LinkText, DisabledLinkText } from '../components/core/Text';
import NotificationBanner from '../components/notification-banner';

type Props = {
  onRetry: () => void;
  errorMessageId?: string;
  isRetryEnabled?: boolean;
  maxRetryCount?: number;
  util: UtilProps;
  isVisible?: boolean;
};

const RetryableErrorBannerContainer = ({
  util,
  onRetry,
  errorMessageId = 'notification.retryable-error.message.error',
  isRetryEnabled = true,
  maxRetryCount = 1,
  isVisible,
}: Props) => {
  const [retryCount, setRetryCount] = useState(0);
  const isRetryable = retryCount < maxRetryCount;
  const handleRetryPressed = useCallback(() => {
    setRetryCount(retryCount + 1);
    onRetry();
  }, [onRetry]);
  return isVisible ? (
    <NotificationBanner errorType="Error">
      <Box direction="row" alignVertically="center">
        <Box flex>
          <BodyTitleText
            id={
              isRetryable ? errorMessageId : 'notification.retryable-error.message.contact-support'
            }
            style={{
              color: theme.color.lightText,
            }}
          />
        </Box>
        {isRetryable ? (
          isRetryEnabled ? (
            <LinkText
              id="notification.retryable-error.button.retry"
              onPress={handleRetryPressed}
              style={{
                color: theme.color.errorTextDark,
              }}
            />
          ) : (
            <DisabledLinkText id="notification.retryable-error.button.retry" />
          )
        ) : (
          <LinkText
            style={{
              color: theme.color.errorTextDark,
            }}
          >
            {util.supportEmail}
          </LinkText>
        )}
      </Box>
    </NotificationBanner>
  ) : null;
};

export const RetryableErrorBanner = withUtil(RetryableErrorBannerContainer);
