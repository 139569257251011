import React from 'react';
import { useSelector } from 'react-redux';
import { useBreakpointValue } from 'native-base';
import { HeaderBackButton } from '@react-navigation/elements';

import { NavButton } from 'src/taxfix-components/src';
import { useNavigation } from 'src/hooks/navigation-hook';
import { PaymentCreditCardWebview } from 'src/screens/payment/payment-webview';
import { PaymentCreditCardComponentNative } from 'src/screens/payment/payment-view';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { isAndroid } from 'src/utils/platform';
import { selectors as remoteConfigSelectors } from 'src/stores/modules/remote-config-firebase';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { getAnswer } from 'src/stores-legacy/helpers';
import { flagsQuestionIds } from 'src/common/constants-it';

import navigate, { Action, Navigation, NavigationParams, Tracking } from '../../routes/navigate';
import { AnalyticsEvent } from '../../biz-logic/analytics';

import { PaymentProps, PaymentRouteCommon } from './payment-route.common';
import { PaymentContainer } from './payment-with-address/payment-container';

export type ActionParams = PaymentProps & {
  isPrefillFiling?: boolean;
  redirectStatus?: string;
};

type Props = Navigation<
  'PaymentItaly',
  {
    PaymentItaly: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action<ActionParams> = navigate(
  'PaymentItaly',
  (state: Record<string, any>, routeParams: Record<string, any>): Tracking => {
    const selectedProductBundle = getAnswer(state, flagsQuestionIds.productBundleSelection);

    return {
      name: AnalyticsEvent.sectionPayment,
      params: {
        bundleSelected: selectedProductBundle,
      },
    };
  },
);

const PaymentItalyOldScreen = ({ route }: Props): JSX.Element => {
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { getTranslationText } = useItalyIntl();
  const { navigationActions, navigation } = useNavigation();
  const isPaypalEnabled = useSelector(remoteConfigSelectors.isPaypalEnabled).enabled;
  const isPaypalEnableForAndroid = isAndroid && isPaypalEnabled;

  const { isPrefillFiling } = route.params;

  const handleBackNavigationPress = () => {
    if (navigation.canGoBack()) {
      navigationActions.back();
    } else {
      navigationActions.toStatus('screen');
    }
  };

  return (
    <>
      {isLargeScreen && (
        <NavButton
          text={getTranslationText(
            isPrefillFiling
              ? 'account.create.header.back'
              : 'it.web.navigation.back-to-data-review',
          )}
          onPress={handleBackNavigationPress}
        />
      )}

      <PaymentRouteCommon
        {...route.params}
        title="it.payment.title"
        subtitle="it.payment.subtitle"
        enableWebviewE2E
        PaymentForm={
          isPaypalEnableForAndroid ? PaymentCreditCardComponentNative : PaymentCreditCardWebview
        }
      />
    </>
  );
};

const PaymentRouteWithBillingAddress: React.FC<Props> = (props) => {
  const { navigationActions, navigation } = useNavigation();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  const onBack = () => {
    if (navigation.canGoBack()) {
      navigationActions.back();
    } else {
      navigationActions.toDashboard('screen');
    }
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={onBack} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.payments.header.title' },
    {
      headerLeft: headerLeftComponent,
      headerBackTitle: 'account.create.header.back',
    },
  );

  return (
    <PaymentContainer
      backButton={
        isLargeScreen && {
          text: 'account.create.header.back',
          onPress: onBack,
        }
      }
    />
  );
};

export const PaymentItaly: React.FC<Props> = (props) => {
  const isNewPaymentScreenEnabled = useSelector(remoteConfigSelectors.isNewPaymentScreenEnabled);

  if (isNewPaymentScreenEnabled) {
    return <PaymentRouteWithBillingAddress {...props} />;
  }
  return <PaymentItalyOldScreen {...props} />;
};
