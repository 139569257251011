import React from 'react';

import navigate, { Action } from 'src/routes/navigate';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';

import { SPIDConsentSignContainer } from './spid-consent-sign-container';

export const toSPIDConsentSign: Action = navigate(ScreenName.SPIDConsentSign, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.SPIDConsentSign,
  },
});

export const SPIDConsentSign = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'it.spid-consent-sign.header.title' });

  return <SPIDConsentSignContainer />;
};
