/* global __DEV__ */
import { $Keys } from 'utility-types';
import { Alert } from 'react-native';
import Config from 'react-native-config';
import { CountryCodes } from '@taxfix/types';

import { ConfigKey, keys, defaultConfig } from 'src/stores/utils/remote-config-firebase';
import { InvalidLocalConfigError } from 'src/utils/remote-config-errors';

export const localRemoteConfigAllowed = Config.ALLOW_LOCAL_REMOTE_CONFIG === 'true';
// 1 hour for production and beta, force a cache refresh for development build
export const REMOTE_CONFIG_CACHE_DURATION = __DEV__ ? 0 : 60 * 60;
const platforms = {
  ios: 'iOS',
  android: 'Android',
  web: 'Web',
};
export type Configs = Record<ConfigKey, any>;
export type Platform = $Keys<typeof platforms>;
export type MaintenanceConfig = Record<CountryCodes, boolean>;
export type MinimumVersionConfig = Record<CountryCodes, Record<Platform, string>>;

export const checkLocalConfig = (localConfigJSON: Record<ConfigKey, any>) => {
  try {
    const invalidKeys: string[] = Object.keys(localConfigJSON).filter(
      // eslint-disable-next-line no-prototype-builtins
      (localKey) => !keys.includes(localKey as ConfigKey),
    );

    if (invalidKeys.length > 0) {
      // eslint-disable-next-line no-console
      console.warn(
        new InvalidLocalConfigError(
          "Some local keys are not valid as they don't exist in the list of configKeys. Please add them on the list first.",
        ),
        { invalidKeys },
      );
    }
  } catch (error) {
    Alert.alert('Local remote-config problems', error.message);
  }
};

export function getDefaultConfig(): Configs {
  if (!localRemoteConfigAllowed) return defaultConfig as Configs;

  const localConfigJSON = require('../../remote-config/local.json');
  checkLocalConfig(localConfigJSON);
  return { ...defaultConfig, ...localConfigJSON };
}
