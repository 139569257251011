import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Linking } from 'react-native';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { useBreakpointValue } from 'native-base';

import { Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';

import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import {
  selectors as requiredDocumentsSelectors,
  actions as RequiredDocumentsActions,
} from '../../../stores/modules/required-documents';
import { actions as OverlayActions } from '../../../stores/modules/overlay';
import { selectors as userAuthSelectors } from '../../../stores/modules/user-auth';
import { selectors as submissionSelectors } from '../../../stores/modules/submission';
import { State } from '../../../stores/store/initial';
import { RequiredDocuments as RequiredDocumentsComponent } from '../../components/required-documents/required-documents';
import { AlertOverlayProps, ButtonType } from '../../../components/alert-overlay';
import {
  DigitalCafSubCategory,
  DigitalCafDocument,
  translationInfoForDocuments,
  getTranslationKey,
  documentsCategories,
  flagsQuestionIds,
  wayQuestionIds,
  mandatoryDocuments,
  incomeDocumentCategories,
  DocumentUploadMode,
  incomeSilentQuestionIds,
  expenseSilentQuestionIds,
  inSeasonQFIds,
  CWBIDocumentTypes,
  expenseListQuestionIds,
  expenseListQuestions,
  ExpenseAnswerKey,
} from '../../../common/constants-it';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import {
  selectors as settingsSelectors,
  actions as settingsActionCreators,
  countrySpecificProps,
} from '../../../stores/modules/settings';
import {
  Document,
  DocumentCategory,
  DocumentsList,
} from '../../components/digital-caf-documents/documents-list';
import { NotificationBannerLegacyType } from '../../../components/notification-banner-legacy';
import { getAnswers } from '../../../stores/hooks/answers-stores-hook';
import { getNavigationActions, getBoundNavigationsActions } from '../../../routes/config-util';
import { QuizmasterLight, withQuizmasterLight } from '../../../utils/with-quizmaster-light';
import { theme, useLargeScreenQuery } from '../../../components/core';
import { modifyQuestions } from '../required-documents/required-documents-upload';

const PREPARING_DOC_LIST_TIMEOUT = 4000;

export const TrackingDocTypesExtra = {
  receipt: 'receipt',
};
const mapStateToProps = (state: State, props: OwnProps) => {
  const selectedYear = settingsSelectors.selectedYear(state) || getDefaultTaxYear();
  const questionStore = getQuestionStoreByYearAndCountry(state);
  const { tailoredCategories } = getAnswers(questionStore);
  const tailoredIncomeCategories = tailoredCategories.filter((category) =>
    incomeDocumentCategories.includes(category),
  );
  const tailoredExpenseCategories = tailoredCategories.filter(
    (category) => !incomeDocumentCategories.includes(category),
  );
  return {
    docBunches: requiredDocumentsSelectors.getDocumentBunches(state),
    isUpdatingRequiredDocuments: requiredDocumentsSelectors.isUpdatingRequiredDocuments(state),
    areTailoredDocCategoriesComplete: requiredDocumentsSelectors.hasUploadedEachTailoredCategory(
      state,
    )(
      props.screenMode === DocumentUploadMode.INCOME_STEP
        ? tailoredIncomeCategories
        : tailoredExpenseCategories,
    ),
    hasUploadedSomeRequiredDocs: requiredDocumentsSelectors.hasUploadedSomeTailoredCategories(
      state,
    )(
      props.screenMode === DocumentUploadMode.INCOME_STEP
        ? tailoredIncomeCategories
        : tailoredExpenseCategories,
    ),
    selectedCountry: settingsSelectors.selectedCountry(state),
    selectedYear,
    userId: userAuthSelectors.getUserId(state),
    accessToken: userAuthSelectors.getAccessToken(state),
    areAnswersUpdated:
      settingsSelectors.selectCurrentCountrySpecifics(state)[
        countrySpecificProps.areQuizAnswersUpdated
      ] || false,
    isResubmission: submissionSelectors.isResubmissionByYear(state, selectedYear),
    tailoredCategories:
      props.screenMode === DocumentUploadMode.INCOME_STEP
        ? tailoredIncomeCategories
        : tailoredExpenseCategories,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateRequiredDocuments: bindActionCreators(
    RequiredDocumentsActions.updateRequiredDocuments,
    dispatch,
  ),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
  updateCountryYearSpecific: bindActionCreators(
    settingsActionCreators.updateCountryYearSpecific,
    dispatch,
  ),
  resetAnswersUpdatedFlag: () =>
    dispatch(
      settingsActionCreators.updateCountrySpecific(
        CountryCodes.IT,
        countrySpecificProps.areQuizAnswersUpdated,
        false,
      ),
    ),
  deleteRequiredDocument: bindActionCreators(
    RequiredDocumentsActions.deleteRequiredDocument,
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  onNext: (goBack?: () => void) => void;
  quizmasterLight: QuizmasterLight;
  screenMode: DocumentUploadMode;
  shouldGoToPreviousScreen: boolean;
};
type Props = OwnProps & ReduxProps & WithNavigation;

const trackDeletingDocumentsEvent = (eventName: string, docTypes: Array<string>) => {
  Analytics.log(eventName as AnalyticsEvent, {
    photoType: null,
    documentType: TrackingDocTypesExtra.receipt,
    docSubType: docTypes[0],
  });
};

const RequiredDocuments = ({
  selectedYear,
  navigationActions,
  navigation,
  overlayActions,
  docBunches,
  accessToken,
  userId,
  updateRequiredDocuments,
  onNext,
  isUpdatingRequiredDocuments,
  areAnswersUpdated,
  resetAnswersUpdatedFlag,
  quizmasterLight,
  isResubmission,
  screenMode,
  tailoredCategories,
  areTailoredDocCategoriesComplete,
  hasUploadedSomeRequiredDocs,
  deleteRequiredDocument,
  shouldGoToPreviousScreen = false,
}: Props) => {
  const { answer: firstName } = quizmasterLight[wayQuestionIds.firstName];
  const [errorBannerShown, showErrorBanner] = useState<boolean>(false);

  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();
  const otherFolderTitleVariant = useBreakpointValue({
    base: 'titleXXSMedium',
    md: 'titleSMedium',
  });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const logLoadingOverlayShow = useCallback(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'SettingUpDocList',
      isPrefillFiling: false,
    });
  }, []);

  useEffect(() => {
    if (screenMode === DocumentUploadMode.EXPENSES_STEP) {
      quizmasterLight[flagsQuestionIds.expenseUploadScreenSeen].saveAnswer(true);
    }

    if (areAnswersUpdated) {
      overlayActions.show('LoadingOverlayWithLogo', {
        messages: ['it.digital-caf.required-documents.tailoring-overlay.text'],
        logOverlayShown: logLoadingOverlayShow,
      });
      setTimeout(() => {
        overlayActions.hide();
        resetAnswersUpdatedFlag();
      }, PREPARING_DOC_LIST_TIMEOUT);
    }
  }, [
    resetAnswersUpdatedFlag,
    areAnswersUpdated,
    overlayActions,
    logLoadingOverlayShow,
    screenMode,
  ]);

  const deleteDocumentsUploaded = useCallback(
    async (documentType: string) => {
      const docsIds = docBunches[documentType].items.map((item) => item.id);
      await Promise.all(
        docsIds.map(async (docId) => {
          await deleteRequiredDocument(accessToken, docId);
        }),
      );
    },
    [deleteRequiredDocument, accessToken, docBunches],
  );

  const deleteDocuments = useCallback(
    async (documentTypes: Array<string>) => {
      try {
        await Promise.all(
          documentTypes.map(async (documentType: any) => {
            const protectedTypes = Object.values(CWBIDocumentTypes);
            if (!protectedTypes.includes(documentType)) {
              await deleteDocumentsUploaded(documentType);
            }
          }),
        );
        quizmasterLight[flagsQuestionIds.documentsUploadConsent].saveAnswer(false);
      } catch (e) {
        showErrorBanner(true);
      }
    },
    [deleteDocumentsUploaded, quizmasterLight],
  );

  const handleQuestionAnswerChange = useCallback(
    (documentTypes: Array<string>) => {
      if (documentTypes && Array.isArray(documentTypes)) {
        try {
          modifyQuestions({
            mode: 'DELETE',
            documentType: documentTypes[0],
            quizmasterLight,
            onComplete: () => {
              getBoundNavigationsActions().reset({
                index: 1,
                actions: [
                  getNavigationActions().toStatus('screen'),
                  getNavigationActions().toRequiredDocumentsDigitalCaf('screen', {
                    onNext: () => {
                      getBoundNavigationsActions().reset({
                        index: 0,
                        actions: [getNavigationActions().toStatus('screen')],
                      });
                    },
                    screenMode,
                  }),
                ],
              });
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('DEBUG-STATUS modifyQuestions error', e);
        }
      }
    },
    [quizmasterLight, screenMode],
  );

  const cleanFolder = useCallback(
    (documentTypes: Array<string>, folderName: string) => {
      showErrorBanner(false);

      const translationValues: any = { folderName };
      const titleTranslationKey = 'it.digital-caf.required-documents.delete-alert.title';
      const subtitleTranslationKey = 'it.digital-caf.required-documents.delete-alert.subtitle';

      trackDeletingDocumentsEvent(AnalyticsEvent.deleteReceiptClicked, documentTypes);
      const overlayProps: AlertOverlayProps = {
        topIcon: {
          name: 'deleteFolder',
          style: {
            width: 100,
            height: 100,
          },
        },
        onClose: () => {},
        titleTranslationKey,
        leadTranslationKey: subtitleTranslationKey,
        translationValues,
        buttons: [
          {
            translationKey: 'required-document.upload.delete-alert.button.delete',
            onPress: () => {
              (async () => {
                await deleteDocuments(documentTypes);
                trackDeletingDocumentsEvent(AnalyticsEvent.deleteReceiptConfirmed, documentTypes);
                handleQuestionAnswerChange(documentTypes);
              })();
            },
            type: ButtonType.warning,
            style: {
              backgroundColor: theme.color.pinkFull,
              color: theme.color.lightText,
              width: isLargeScreen ? 279 : '100%',
            },
          },
          {
            translationKey: 'answers.modal.cancel',
            type: ButtonType.secondary,
            style: {
              backgroundColor: theme.color.lightText,
              color: theme.color.pinkFull,
            },
            onPress: () =>
              trackDeletingDocumentsEvent(AnalyticsEvent.deleteReceiptCancelled, documentTypes),
          },
        ],
      };
      overlayActions.show('AlertOverlay', overlayProps);
    },
    [overlayActions, deleteDocuments, handleQuestionAnswerChange, isLargeScreen],
  );

  const customLinkHandler = useCallback(
    (url: string, params?: any) => {
      switch (url) {
        case 'DocumentUploadGuidance':
          overlayActions.show('DocumentUploadGuidanceOverlay', params);
          break;

        default:
          Linking.openURL(url);
          break;
      }
    },
    [navigationActions, overlayActions],
  );

  const getExpenseList = useCallback(
    (categoryKey: string) => {
      const expenseListQuestionId = expenseListQuestions[categoryKey];
      if (!expenseListQuestionId) return [];

      const expenseListResponse = quizmasterLight[expenseListQuestionId]?.answer;
      const expensesList =
        expenseListResponse && expenseListResponse !== ExpenseAnswerKey.delete
          ? JSON.parse(expenseListResponse)
          : [];

      return Array.isArray(expensesList) ? expensesList : [expensesList];
    },
    [quizmasterLight],
  );

  const toDocumentsUpload = useCallback(
    (docTypes: string[], categoryKey: string) => {
      navigationActions.toRequiredDocumentsUpload('screen', {
        categoryKey,
        requiredDocumentTypes: docTypes,
        customLinkHandler,
        expensesList: getExpenseList(categoryKey),
      });
      Analytics.log(AnalyticsEvent.receiptCategorySelected, {
        receiptCategory: docTypes,
      });
    },
    [customLinkHandler, getExpenseList, navigationActions],
  );

  const tailoredPrep = useMemo(() => {
    const getDocumentRenderProps = (
      categoryKey: string,
      docOrCategory: DigitalCafSubCategory | DigitalCafDocument,
    ): Document | null | undefined => {
      const translationValues = {
        CUYear: selectedYear + 1,
        taxYear: selectedYear,
      };
      if (typeof docOrCategory === 'string' && docBunches[docOrCategory]) {
        return {
          handleDocumentPressed: () => toDocumentsUpload([docOrCategory], categoryKey),
          translationKey: getTranslationKey(
            translationInfoForDocuments,
            docOrCategory,
            'translationKey',
          ),
          translationValues,
          documentCount: docBunches[docOrCategory].items.length,
          isMandatory: mandatoryDocuments.includes(docOrCategory),
          categories: [docOrCategory],
          categoryKey: docOrCategory,
        } as Document;
      }

      if (typeof docOrCategory === 'object' && docOrCategory.key && docOrCategory.items) {
        return {
          handleDocumentPressed: () => toDocumentsUpload(docOrCategory.items, categoryKey),
          translationKey: getTranslationKey(
            translationInfoForDocuments,
            docOrCategory.items[0],
            'translationKey',
          ),
          translationValues,
          isMandatory: mandatoryDocuments.includes(docOrCategory.items[0]),
          documentCount: docOrCategory.items.reduce(
            (result: number, doc: DigitalCafDocument) =>
              result + (docBunches[doc]?.items.length || 0),
            0,
          ),
          categories: docOrCategory.items,
          categoryKey: docOrCategory.key,
        } as Document;
      }

      return null;
    };

    const tailored: Document[] = [];

    documentsCategories.forEach((doc: DigitalCafSubCategory | DigitalCafDocument) => {
      let item = null;
      const categoryKey = typeof doc !== 'string' ? doc.key : doc;
      if (tailoredCategories.includes(categoryKey)) {
        item = getDocumentRenderProps(categoryKey, doc);

        if (item) tailored.push(item);
      }
    });
    return tailored;
  }, [selectedYear, docBunches, toDocumentsUpload, tailoredCategories]);

  const handleUpdateReceipts = async () => {
    try {
      await updateRequiredDocuments(accessToken, userId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('DEBUG-STATUS retrieve required-documents error', e);
    }
  };

  const updateReceiptsAndConsentIfNeeded = async () => {
    await handleUpdateReceipts();
  };

  React.useEffect(() => {
    updateReceiptsAndConsentIfNeeded(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemindLater = () => {
    Analytics.log(AnalyticsEvent.requiredDocumentsRemindLaterPressed, {
      screenName: 'required-documents',
    });
    const { incomeUploadSkipped, expensesUploadSkipped } = flagsQuestionIds;

    quizmasterLight[
      screenMode === DocumentUploadMode.INCOME_STEP ? incomeUploadSkipped : expensesUploadSkipped
    ].saveAnswer(true);

    if (onNext) {
      onNext();
    } else {
      navigationActions.reset({
        index: 1,
        actions: [getNavigationActions().toStatus('screen')],
      });
    }
  };

  const handleContinue = () => {
    if (areTailoredDocCategoriesComplete) {
      Analytics.log(AnalyticsEvent.receiptConfirmedClicked);
      const { expensesUploadSkipped, incomeUploadSkipped } = flagsQuestionIds;
      quizmasterLight[
        screenMode === DocumentUploadMode.INCOME_STEP ? incomeUploadSkipped : expensesUploadSkipped
      ].saveAnswer(false);
      onNext && onNext();
    } else {
      handleRemindLater();
    }
  };

  const handleConfirmationCTA = () => {
    if (isResubmission && screenMode !== DocumentUploadMode.INCOME_STEP) {
      const { areDocumentConfirmedOnResubmission, isConfirmationAnsweredOnResubmission } =
        flagsQuestionIds;
      overlayActions.show('AlertOverlay', {
        titleTranslationKey: 'it.digital-caf.required-documents.resubmission.confirm-popup.title',
        leadTranslationKey:
          'it.digital-caf.required-documents.resubmission.confirm-popup.description',
        buttons: [
          {
            translationKey:
              'it.digital-caf.required-documents.resubmission.confirm-popup.button.primary',
            type: ButtonType.confirm,
            onPress: () => {
              quizmasterLight[areDocumentConfirmedOnResubmission].saveAnswer(true);
              quizmasterLight[isConfirmationAnsweredOnResubmission].saveAnswer(true);
              handleContinue();
            },
          },
          {
            translationKey:
              'it.digital-caf.required-documents.resubmission.confirm-popup.button.secondary',
            type: ButtonType.secondary,
            onPress: () => {
              quizmasterLight[areDocumentConfirmedOnResubmission].saveAnswer(false);
              quizmasterLight[isConfirmationAnsweredOnResubmission].saveAnswer(true);
              handleRemindLater();
            },
          },
        ],
        appearFromTop: true,
      });
    } else {
      handleContinue();
    }
  };

  const getAddFolder = (): Document => {
    const translationKey =
      screenMode === DocumentUploadMode.INCOME_STEP
        ? 'it.digital-caf.required-documents.income.folder.add-another-document.title'
        : 'it.digital-caf.required-documents.expense.folder.add-another-document.title';

    const currentStepDocumentsBunch =
      screenMode === DocumentUploadMode.INCOME_STEP
        ? documentsCategories.filter((category) => incomeDocumentCategories.includes(category.key))
        : documentsCategories.filter(
            (category) => !incomeDocumentCategories.includes(category.key),
          );
    return {
      translationKey,
      documentCount: 1,
      handleDocumentPressed: () => {
        let docItems: any = [];
        currentStepDocumentsBunch.forEach(
          (doc: DigitalCafSubCategory | DigitalCafDocument | any) => {
            if (!tailoredCategories.includes(doc.key || doc)) {
              docItems = docItems.concat(typeof doc !== 'string' ? doc.items[0] : [doc]);
            }
          },
        );
        navigationActions.toDigitalCafSubCategory('screen', {
          category: {
            key: 'cat_others',
            items: docItems,
          },
          customLinkHandler,
          screenMode,
        });
      },
      folderContent: {
        titleElement: (
          <Text
            color="greytones.graphicsDark"
            marginBottom="4px"
            marginX="8px"
            noOfLines={2}
            textAlign="center"
            variant={otherFolderTitleVariant}
            value={getTranslationText(translationKey)}
          />
        ),
        subtitleElement: <Text textAlign="center" value="" />,
      },
      categories: [],
      categoryKey: 'cat_others',
    };
  };

  const navigateToPreviousScreen = () => {
    if (navigation.canGoBack()) navigation.goBack();
  };

  const renderSummary = (category: DocumentCategory) => {
    const addExpenseFolder = getAddFolder();
    const dataWithOtherFolders = category.items.concat(addExpenseFolder);
    return (
      <DocumentsList
        key={category.translationKey}
        documents={dataWithOtherFolders}
        cleanFolder={cleanFolder}
      />
    );
  };

  const { ResubmissionInfo: ResubmissionInfoBanner } = NotificationBannerLegacyType;

  const banner = isResubmission ? { bannerType: ResubmissionInfoBanner } : null;

  const titleTranslationKey =
    screenMode === DocumentUploadMode.INCOME_STEP
      ? 'it.digital-caf.required-documents.income-upload.title'
      : 'it.digital-caf.required-documents.title';

  const subtitleTranslationKey =
    screenMode === DocumentUploadMode.INCOME_STEP
      ? 'it.digital-caf.required-documents.cu-upload.subtitle'
      : 'it.digital-caf.required-documents.subtitle';

  const footerInfoText = useMemo(() => {
    if (isResubmission && screenMode === DocumentUploadMode.EXPENSES_STEP) {
      return 'it.digital-caf.required-documents.footer.info.resubmission';
    }
    return hasUploadedSomeRequiredDocs
      ? 'it.digital-caf.required-documents.footer.info.some-or-all-docs-uploaded'
      : 'it.digital-caf.required-documents.footer.info';
  }, [isResubmission, screenMode, hasUploadedSomeRequiredDocs]);

  const mobileFooterButtonTranslationKey = useMemo(() => {
    if (isResubmission && screenMode === DocumentUploadMode.EXPENSES_STEP) {
      return 'it.digital-caf.required-documents.mobile.footer.next-btn.some-or-all-docs-uploaded';
    }
    if (hasUploadedSomeRequiredDocs) {
      return 'it.digital-caf.required-documents.mobile.footer.next-btn.some-or-all-docs-uploaded';
    } else {
      return 'it.digital-caf.required-documents.mobile.footer.next-btn.first-time';
    }
  }, [isResubmission, screenMode, hasUploadedSomeRequiredDocs]);

  const footerButtonTranslationKey = isSmallScreen
    ? mobileFooterButtonTranslationKey
    : 'it.digital-caf.required-documents.footer-button.continue';

  return (
    <RequiredDocumentsComponent
      banner={banner}
      showConnectionErrorBanner={errorBannerShown}
      onCTAPress={handleConfirmationCTA}
      firstName={firstName}
      data={[
        {
          translationKey: 'it.digital-caf.required-documents.category.mandatory',
          items: tailoredPrep,
        },
      ]}
      isLoading={isUpdatingRequiredDocuments}
      titleTranslationKey={titleTranslationKey}
      subtitleTranslationKey={subtitleTranslationKey}
      footerButtonTranslationKey={footerButtonTranslationKey}
      footerAdditionalInfoText={footerInfoText}
      renderSummary={renderSummary}
      onBackButtonPress={shouldGoToPreviousScreen ? () => navigateToPreviousScreen : undefined}
    />
  );
};

export const RequiredDocumentsContainer = compose<any>(
  withNavigation,
  withQuizmasterLight([
    flagsQuestionIds.documentsUploadConsent,
    flagsQuestionIds.expensesUploadSkipped,
    flagsQuestionIds.incomeUploadSkipped,
    flagsQuestionIds.areDocumentConfirmedOnResubmission,
    flagsQuestionIds.isConfirmationAnsweredOnResubmission,
    flagsQuestionIds.expenseUploadScreenSeen,
    wayQuestionIds.firstName,
    wayQuestionIds.occupation,
    inSeasonQFIds.living_situation,
    ...incomeSilentQuestionIds,
    ...expenseSilentQuestionIds,
    ...expenseListQuestionIds,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(RequiredDocuments);
