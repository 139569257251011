import { createSelector } from 'reselect';

import { NetInfoState, ConnectionInfoAnalyticsParams } from '../../services/internetconnection';

export const UPDATE_CONNECTION_INFO = 'internet-connection/UPDATE_CONNECTION_INFO';
export type State = {
  connectionInfo: NetInfoState | null | undefined;
};
type UpdatedConnectionInfo = {
  type: typeof UPDATE_CONNECTION_INFO;
  payload: NetInfoState;
};
type Action = UpdatedConnectionInfo;

const updateConnectionInfo = (netInfoState: NetInfoState) => ({
  type: UPDATE_CONNECTION_INFO,
  payload: netInfoState,
});

export const actions = {
  updateConnectionInfo,
};
export const initial: State = {
  connectionInfo: null,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case UPDATE_CONNECTION_INFO:
      return { ...state, connectionInfo: action.payload };

    default:
      action.type as never; // eslint-disable-line

      return state;
  }
};
// selectors
export type RootState = {
  internetConnection: State;
};

const getConnectionInfo = (state: RootState): NetInfoState | null | undefined =>
  state.internetConnection.connectionInfo;

const isConnected: (state: RootState) => boolean = createSelector(
  getConnectionInfo,
  (connectionInfo) => connectionInfo?.isConnected || false,
);
// @ts-ignore
const getTrackingParams: (state: RootState) => ConnectionInfoAnalyticsParams = createSelector(
  getConnectionInfo,
  (connectionInfo) => {
    if (connectionInfo?.isConnected) {
      return {
        deviceHasConnection: true,
        connectionType: connectionInfo.type || '',
        effectiveConnectionType: connectionInfo.details.cellularGeneration || '',
      };
    }

    return {
      deviceHasConnection: false,
    };
  },
);
export const selectors = {
  isConnected,
  getTrackingParams,
};
