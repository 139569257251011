import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { OptionId } from '@taxfix/quizmaster/dist/types';

import translationKeyForOption from 'src/components/answers/translationKeyForOption';
import { Box, theme, CheckBoxButton } from 'src/components/core';

export type MultiSelectOptionId = OptionId;
export type MultiSelectOption = {
  id?: MultiSelectOptionId;
  image?: string;
  translationKey?: string;
  keyForCheckBox?: string | number;
  value?: string | number;
  label?: string;
};
type Props = {
  onChange: (value: MultiSelectOptionId[]) => void;
  options: MultiSelectOption[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value?: OptionId[];
  color?: string;
  colorSecondary?: string;
  withoutTruncation?: boolean;
  autoFocus?: boolean;
};
type SelectedItemMap = Record<
  MultiSelectOptionId,
  {
    id: any;
    isSelected: boolean;
  }
>;
type State = {
  selectedItems: SelectedItemMap;
};

const selectedItemsFromValue = (value: MultiSelectOptionId[]): SelectedItemMap =>
  value.reduce(
    (previous, option) => ({
      ...previous,
      [option]: {
        id: option,
        isSelected: true,
      },
    }),
    {},
  );

class MultiSelectList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItems: selectedItemsFromValue(props.value ?? []),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState({
      selectedItems: selectedItemsFromValue(props.value ?? []),
    });
  }

  handleItemPress = (id: string) => {
    this.setState((prevState) => {
      const itemState = prevState.selectedItems[id];
      const selectedItems: SelectedItemMap = {
        ...prevState.selectedItems,
        [id]:
          itemState == null
            ? {
                id,
                isSelected: true,
              }
            : {
                id,
                isSelected: !itemState.isSelected,
              },
      };
      return {
        selectedItems,
      };
    }, this.fireChange);
  };

  fireChange = () => {
    const items = this.state.selectedItems;
    const selected = Object.keys(items)
      .filter((key) => items[key].isSelected === true)
      .map((key) => items[key].id);
    this.props.onChange(selected);
  };

  render() {
    const {
      options,
      color = theme.color.primary,
      colorSecondary = theme.color.secondary,
      withoutTruncation,
    } = this.props;
    return (
      <Box flex>
        {options.map((option, index) => (
          <CheckBoxButton
            key={`${option.keyForCheckBox}${index}`}
            translationKey={
              option.translationKey ? translationKeyForOption(option.translationKey) : undefined
            }
            selected={
              option.keyForCheckBox != null &&
              this.state.selectedItems[option.keyForCheckBox] != null
                ? this.state.selectedItems[option.keyForCheckBox].isSelected
                : false
            }
            iconKey={option.image}
            index={index}
            color={color}
            colorSecondary={colorSecondary}
            label={option.id != null ? undefined : option.label}
            withoutTruncation={withoutTruncation}
            testId={`answer::${option.label || option.id || ''}`}
            onPress={() =>
              this.handleItemPress((option.id != null ? option.id : option.value) as string)
            }
          />
        ))}
      </Box>
    );
  }
}

export { MultiSelectList };
