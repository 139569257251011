import { Prefill } from '@taxfix/italy-types';

import { flagsQuestionIds } from '../../common/constants-it';
import { getPrefillResult, PrefillResult } from '../prefill-documents-result/utils';

import { useQuizmasterLight } from './use-quizmaster-light';

export const usePrefillResult = (): PrefillResult => {
  const quizmasterLight = useQuizmasterLight([
    flagsQuestionIds.taxScenario,
    flagsQuestionIds.taxOptimizations,
    flagsQuestionIds.prefilledCreditResult,
  ]);

  const estimatedResultAsString: string =
    quizmasterLight[flagsQuestionIds.prefilledCreditResult].answer;
  const taxScenario: Prefill.PrefillResultScenarios =
    quizmasterLight[flagsQuestionIds.taxScenario].answer ??
    Prefill.PrefillResultScenarios.NAMandatory;
  const taxOptimizations: string | undefined =
    quizmasterLight[flagsQuestionIds.taxOptimizations]?.answer;

  return getPrefillResult(estimatedResultAsString, taxScenario, taxOptimizations);
};
