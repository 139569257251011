import React, { ReactNode } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { IntlShape, injectIntl } from 'react-intl';
import _ from 'lodash';
import { CountryCodes } from '@taxfix/types';

import { State as RootState } from 'src/stores/store/initial';
import { transparencyTracker } from 'src/utils/transparency-tracker';

import { hasCredentials } from '../../utils/credentials';
import { Provider, selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { getPassUsingBioAuth } from '../../utils/biometricAuth';
import withLogin, { WithLogin } from '../../hocs/with-login';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { stopPerformanceTraceWithLabel } from '../../services/firebase-performance';
import { PerformanceLabels } from '../../services/firebase-performance.labels';
import { getDefaultTaxYear } from '../../services/country-and-year/utils';
import { getQuestionStoreForYear } from '../../stores-legacy/util';
import { wayQuestionIds } from '../../common/constants-it';

import { Lock } from './Lock';
import { NavigationParams } from './lock.types';

type State = {
  password: string;
  autoFocusPin: boolean;
};

const mapStateToProps = (state: RootState) => {
  const selectedCountry = settingsSelectors.selectedCountry(state);
  const selectedYear = settingsSelectors.selectedYear(state);
  let firstName = '';

  // TODO: this does not belong here and it should be removed
  // This container should have a prop for translation values.
  if (selectedCountry === CountryCodes.IT) {
    try {
      // This will throw an exception for countries that do not QuizMaster
      const questionStore = getQuestionStoreForYear(
        selectedCountry,
        selectedYear || getDefaultTaxYear(),
      );
      firstName = questionStore.responsesJS?.[wayQuestionIds.firstName]?.answer;
    } catch (error) {
      // swallow error
    }
  }

  return {
    isBioAuthEnabled: !_.isNil(state.user.isBioAuthEnabled) && Boolean(state.user.isBioAuthEnabled),
    isBioAuthSetup: !_.isNil(state.user.isBioAuthEnabled),
    userEmail: userAuthSelectors.getEmailAddress(state) || '',
    firstName,
    selectedCountry,
    authProvider: userAuthSelectors.getProvider(state),
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  renderSupportButton?: () => ReactNode;
} & NavigationParams;

type Props = OwnProps & {
  intl: IntlShape;
} & ReduxProps &
  WithNavigation &
  WithLogin;

class UndecoratedLockContainer extends React.Component<Props, State> {
  static defaultProps = {
    renderSupportButton: () => null,
  };

  state = {
    password: __DEV__ ? '1234' : '',
    // eslint-disable-line no-undef,
    autoFocusPin: true,
  };

  async componentDidMount() {
    const handleUserAuth = async () => {
      if (!this.isSsoLogin()) {
        // get password from keychain using biometric auth
        const userData = {
          isBioAuthEnabled: this.props.isBioAuthEnabled,
        };
        const { selectedCountry } = this.props;
        getPassUsingBioAuth(userData, this.props.intl).then((password) => {
          if (password !== null) {
            this.setState(
              {
                password,
              },
              this.handleConfirm,
            );
          }
        });
      }
      stopPerformanceTraceWithLabel(PerformanceLabels.appLaunch, {
        taxCountry: this.props.selectedCountry,
        isAuthenticated: 'yes',
      });
    };
    await transparencyTracker(handleUserAuth);
  }

  isSsoLogin = () => {
    const { authProvider } = this.props;
    return authProvider === Provider.apple || authProvider === Provider.google;
  };

  handlePasswordChange = ({ password }: { password: string }) =>
    this.setState({
      password,
    });

  handleForgot = () => {
    const { navigationActions, userEmail } = this.props;
    navigationActions.toForgotPassword('screen', {
      email: userEmail,
    });
  };

  handleConfirm = () => {
    const { handleLogin, userEmail } = this.props;
    handleLogin({
      email: userEmail,
      password: this.state.password,
    });
  };

  render() {
    const {
      selectedCountry,
      errorKey,
      isLoading,
      userEmail,
      firstName,
      renderSupportButton,
      authProvider,
      handleError,
    } = this.props;
    const { password, autoFocusPin } = this.state;
    const variants =
      selectedCountry === CountryCodes.IT
        ? {
            emailVariant: 'italy',
            pinVariant: 'italy',
            titleKey: 'it.account.lock.title',
            leadKey: 'it.account.lock.subtitle',
            titleValues: {
              firstName,
            },
            isLockEmailHidden: true,
          }
        : {};
    const hasError = errorKey != null;
    const { handleSsoLogin } = this.props;
    return (
      <>
        <Lock
          emailAddress={userEmail}
          password={password}
          onChange={this.handlePasswordChange}
          onConfirm={this.handleConfirm}
          onForgot={this.handleForgot}
          confirmDisabled={!hasCredentials(userEmail, password)}
          loading={isLoading}
          errorKey={hasError ? errorKey : undefined}
          autoFocusPin={autoFocusPin}
          variants={variants}
        />
        {hasError && renderSupportButton && renderSupportButton()}
      </>
    );
  }
}

export const LockContainer = compose<React.ComponentType<OwnProps>>(
  withLogin,
  injectIntl,
  withNavigation,
  connector,
)(UndecoratedLockContainer);
