import { Platform } from 'react-native';
import get from 'lodash/get';

import { localResources } from './local-resources';

const root =
  Platform.OS === 'web'
    ? '/assets'
    : Platform.OS === 'android'
    ? 'file:///android_asset'
    : require('react-native-fs').MainBundlePath;

export const LocalAssetsRootPath = `${root}/local-assets/`;
export const getLocalAssetPath = (identifier: string): string =>
  get(localResources, identifier, '');
