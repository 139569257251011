import React from 'react';

import { Container, IconButton, icons } from 'src/taxfix-components/src';

type Props = {
  onPress: () => void;
};

export const DocDeleteButton = ({ onPress }: Props): JSX.Element => (
  <Container position="absolute" zIndex={10} top="8px" right={2} borderRadius={8}>
    <IconButton
      background="greytones.greyBlue"
      borderRadius="6px"
      variant="grey"
      height="32px"
      width="32px"
      position="absolute"
      path={icons.bin}
      size={4}
      onPress={onPress}
    />
  </Container>
);
