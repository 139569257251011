import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import { Container, Icon, icons, Text, TextLink } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks';
import { ProductBundleValues, questionFlowCategories } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { navigation } from 'src/services/navigation-service';

import { usePaymentContext } from './payment.context';

const styles = StyleSheet.create({
  hoverable: {
    display: 'flex',
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
});

type BillingAddressProps = {
  address: string;
  isValidaionFailed: boolean;
};

const BillingAddress: React.FC<BillingAddressProps> = ({ address, isValidaionFailed }) => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();

  const handleOnPress = () => {
    navigation.push('screen/QuizMaster', {
      root: questionFlowCategories.billingAddress,
      editing: true,
      hideSummary: true,
      backNavigationText: 'it.payment.billing.address.back-navigation',
      onNext: () => navigationActions.toItalyPayment('screen'),
    });
  };

  return (
    <Container flexDir="row" py="16px" justifyContent="space-between">
      <Container flexDir="row">
        <Container pt="5px">
          <Icon
            path={icons.location}
            size="20px"
            color={isValidaionFailed ? 'secondaryCategory.pinkFull' : 'greytones.textLight'}
          />
        </Container>
        <Container px="12px">
          <Text
            variant="titleXSBook"
            color={isValidaionFailed ? 'secondaryCategory.pinkFull' : 'greytones.title'}
            value={getTranslationText('it.payment.billing.address.title')}
          />
          <Text
            variant="bodyXSBook"
            color={isValidaionFailed ? 'secondaryCategory.pinkFull' : 'greytones.textLight'}
            value={address ?? getTranslationText('it.payment.billing.address.label')}
            maxW={{ base: '216px', md: '100%' }}
          />
        </Container>
      </Container>
      <Container alignItems="flex-end">
        <TextLink
          variant="linkM"
          isUnderlined
          onPress={handleOnPress}
          text={getTranslationText(
            address ? 'it.payment.billing.address.edit' : 'it.payment.billing.address.add',
          )}
          style={styles.hoverable}
        />
      </Container>
    </Container>
  );
};

type ProductBundleDetailsProps = {
  product: ProductBundleValues;
  originalPrice: string;
  price: string;
};

const ProductBundleDetails: React.FC<ProductBundleDetailsProps> = ({
  product,
  originalPrice,
  price,
}) => {
  const { getTranslationText } = useItalyIntl();
  const contentMap: Record<ProductBundleValues, { title: string; description?: string }> = {
    [ProductBundleValues.instant]: {
      title: 'it.payment.screen.instant.title',
      description: 'it.payment.screen.instant.description',
    },
    [ProductBundleValues.guided]: {
      title: 'it.payment.screen.guided.title',
      description: 'it.payment.screen.guided.description',
    },
  };

  return (
    <Container flexDir="row" py="16px" justifyContent="space-between">
      <Container flexDir="row">
        <Container pt="5px">
          <Icon path={icons.productBundle} size="16px" color="greytones.textLight" />
        </Container>
        <Container px="12px">
          <Text
            variant="titleXSBook"
            color="greytones.title"
            value={getTranslationText(contentMap[product].title)}
          />
          {!!contentMap[product].description && (
            <Text
              variant="bodyXSBook"
              color="greytones.textLight"
              value={getTranslationText(contentMap[product].description as string)}
              maxW={{ base: '216px', md: '100%' }}
            />
          )}
        </Container>
      </Container>
      <Container alignItems="flex-end">
        {originalPrice !== price && (
          <Text
            variant="bodySBook"
            color="greytones.textLight"
            value={originalPrice}
            strikeThrough
          />
        )}
        <Text variant="bodyMBook" color="greytones.textLight" value={price} />
      </Container>
    </Container>
  );
};

type BillingDetailsProps = {
  billingAddress: string;
  isValidaionFailed: boolean;
};

const BillingDetails: React.FC<BillingDetailsProps> = ({ billingAddress, isValidaionFailed }) => {
  const { getTranslationText } = useItalyIntl();
  const { selectedProductBundle, formattedOriginalPrice, formattedPrice } = usePaymentContext();

  return (
    <Container>
      <Text variant="subtitleMMedium" color="greytones.title">
        {getTranslationText('it.billing-details.title')}
      </Text>

      <ProductBundleDetails
        product={selectedProductBundle}
        originalPrice={formattedOriginalPrice}
        price={formattedPrice}
      />
      <BillingAddress address={billingAddress} isValidaionFailed={isValidaionFailed} />
    </Container>
  );
};

export { BillingDetails };
