import { Box } from 'native-base';
import React from 'react';

import { Props } from './types';

/**
 * This is a View Container to build layouts. It accepts flex, layout and spacing props from Styled System.
 */
export const Container: React.FC<Props> = ({ children, ...otherProps }) => (
  <Box {...otherProps}>{children}</Box>
);
