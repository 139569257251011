import { Pressable, View, useTheme } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';

import { Text, Image } from 'src/taxfix-components/src';

export type NeedHelpScreenProps = {
  needHelpTitle: string;
  sendMessageText: string;
  onNeedHelp: () => void;
};

export const NeedHelpScreen = ({
  needHelpTitle,
  sendMessageText,
  onNeedHelp,
}: NeedHelpScreenProps) => {
  const { colors } = useTheme();
  const styles = StyleSheet.create({
    title: {
      fontSize: 14,
      color: colors.greytones.textDefault,
    },
    buttonContainer: {
      height: 32,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 8,
      borderRadius: 16,
      marginRight: 12,
      marginLeft: 12,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    image: {
      size: 24,
      width: 24,
      height: 24,
    },
    message: {
      fontSize: 14,
      marginLeft: 3,
      color: colors.brand.brandGreen,
      fontWeight: '500',
    },
    button: {
      boxSize: 'content-box',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  });
  return (
    <>
      <Text style={styles.title}>{needHelpTitle}</Text>
      <View style={styles.buttonContainer}>
        <Image style={styles.image} name="vector" />
        <Text style={styles.message}>{sendMessageText}</Text>
        <Pressable onPress={onNeedHelp} style={styles.button} />
      </View>
    </>
  );
};
