import { Buffer } from 'buffer';

export const APIErrors = Object.freeze({
  NetworkError: 'NETWORK_ERROR',
  ZeroResults: 'ZERO_RESULTS',
  UnknownError: 'UNKNOWN_ERROR',
  OverQueryLimit: 'OVER_QUERY_LIMIT',
  RequestDenied: 'REQUEST_DENIED',
  InvalidRequest: 'INVALID_REQUEST',
});
export function safeParseJSON<T>(json: string, fallbackValue: any = {}): T {
  try {
    return JSON.parse(json);
  } catch (e) {
    return fallbackValue;
  }
}
export const pdfToBase64URI = (pdf: string): string => {
  const base64pdf = Buffer.from(pdf, 'binary').toString('base64');
  return `data:application/pdf;base64,${base64pdf}`;
};
export const pdfArrayBufferToBase64URI = (pdfArrayBuffer: ArrayBuffer): string => {
  const base64pdf = Buffer.from(pdfArrayBuffer).toString('base64');
  return `data:application/pdf;base64,${base64pdf}`;
};
export const trimValue = (value: any) => {
  if (typeof value === 'string') return value.trim();
  return value;
};
