import React from 'react';
import { useBreakpointValue } from 'native-base';

import { TwoSidesDocUploadContent } from 'src/taxfix-components/src';

import { useItalyIntl } from '../../_hooks';
import { DocumentSide } from '../types';

type UploadContentProps = {
  side: DocumentSide;
};

export const UploadContent: React.FC<UploadContentProps> = ({ side }) => {
  const { getTranslationText } = useItalyIntl();
  const leftImageWidth = useBreakpointValue({ base: '124px', md: '283px' });
  const leftImageHeight = useBreakpointValue({ base: '100px', md: '227px' });
  const rightImageWidth = useBreakpointValue({ base: '109px', md: '248px' });
  const rightImageHeight = useBreakpointValue({ base: '74px', md: '168px' });
  const marginBottom = useBreakpointValue({ base: '39', md: 0 });

  const isFrontSide = side === DocumentSide.FRONT;

  return (
    <TwoSidesDocUploadContent
      title={{ value: getTranslationText('identity-upload.id-card.dropzone.title') }}
      leftImage={{
        name: isFrontSide ? 'paperIdFrontSkeleton' : 'paperIdRearSkeleton',
        caption: getTranslationText(
          `identity-upload.id-card.dropzone.${side}-paper-document.capture`,
        ),
        width: leftImageWidth,
        height: leftImageHeight,
        mb: '17px',
      }}
      rightImage={{
        name: isFrontSide ? 'eletronicIdFrontSkeleton' : 'eletronicIdRearSkeleton',
        caption: getTranslationText(
          `identity-upload.id-card.dropzone.${side}-electronic-document.capture`,
        ),
        width: rightImageWidth,
        height: rightImageHeight,
        mb: '17px',
      }}
      marginBottom={marginBottom}
    />
  );
};
