import React, { Component, ComponentType } from 'react';
import { Platform } from 'react-native';
import { compose, bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { IntlShape, injectIntl } from 'react-intl';
import { getVersion } from 'react-native-device-info';
import Config from 'react-native-config';
import { Platforms } from '@taxfix/types';
import { DocumentsTypes } from '@taxfix/documents-sdk';
import { ConsentRequest } from '@taxfix/submissions-sdk/dist/consent/consent';

import { ErrorType, logger } from 'src/taxfix-business-logic/utils/logger';

import {
  actions as SubmissionActions,
  selectors as SubmissionSelectors,
} from '../stores/modules/submission';
import { selectors as requiredDocumentsSelectors } from '../stores/modules/required-documents';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { actions as OverlayActions } from '../stores/modules/overlay';
import initial from '../stores/store/initial';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { formatDate } from '../i18n';
import {
  translationInfoForDocuments,
  getTranslationKey,
  wayQuestionIds,
  prefillQuestionIds,
  flagsQuestionIds,
} from '../common/constants-it';
import { logErrorMessages } from '../common/log-error-messages';
import { DraftSubmissionStates } from '../services/submissions';
import { QuizmasterLight, withQuizmasterLight } from '../utils/with-quizmaster-light';

type State = {
  consentDate: Date;
};

const mapStateToProps = (state: typeof initial) => {
  const submissionStatus = SubmissionSelectors.getLatestSubmissionStatus(state);
  const isConsentGiven =
    submissionStatus === DraftSubmissionStates.ConsentCreated ||
    submissionStatus === DraftSubmissionStates.DonationAndConsentCreated;
  return {
    taxYear: settingsSelectors.selectedYear(state),
    requiredDocumentsWithAmount: requiredDocumentsSelectors.getUploadedDocumentsWithAmount(state),
    accessToken: userAuthSelectors.getAccessToken(state),
    userId: userAuthSelectors.getUserId(state),
    isConsentGiven,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submissionActions: bindActionCreators(SubmissionActions, dispatch),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps & {
  intl: IntlShape;
  quizmasterLight: QuizmasterLight;
};
export type SubmissionConsent = {
  createSubmissionConsentDocument: () => Promise<void>;
  createAmlConsentDocument: () => Promise<void>;
  createSpidConsentDocument: () => Promise<void>;
  consentDate: Date;
  taxYear: number;
  isConsentGiven: boolean;
};
export function withSubmissionConsent(WrappedComponent: ComponentType<SubmissionConsent>) {
  class Enhancer extends Component<Props, State> {
    state = {
      consentDate: new Date(),
    };

    createSubmissionConsentDocument = async () => {
      const {
        intl,
        requiredDocumentsWithAmount,
        submissionActions,
        accessToken,
        userId,
        quizmasterLight,
        taxYear,
      } = this.props;
      const { consentDate } = this.state;
      const localizedDate = formatDate(intl, consentDate);
      const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
      const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
      const taxId = quizmasterLight[prefillQuestionIds.taxId].answer;

      try {
        await submissionActions.createConsent(
          {
            firstName,
            lastName,
            taxId,
            docs: requiredDocumentsWithAmount.map((doc) => ({
              docName: intl.formatMessage(
                {
                  id: getTranslationKey(translationInfoForDocuments, doc.id, 'translationKey'),
                },
                {
                  // @ts-ignore
                  taxSeason: taxYear + 1,
                  taxYear,
                  // @ts-ignore
                  CUYear: taxYear + 1,
                },
              ),
              amount: doc.amount || undefined,
            })),
            documentsConsentText: intl.formatMessage({
              id: 'required-document.review.legal',
            }),
            date: localizedDate,
            platform: Platform.OS as Platforms,
            platformVersion: getVersion(),
            title: intl.formatMessage({
              id: 'italy-submission.consent.toggle.title',
            }),
            lead: intl.formatMessage({
              id: 'italy-submission.consent.toggle.subtitle',
            }),
            permissionText: intl.formatMessage({
              id: 'italy-submission.consent.toggle.subtitle',
            }),
            consentText: intl.formatMessage(
              {
                id: 'consent.consent.text',
              },
              {
                date: localizedDate,
                // @ts-ignore
                taxSeason: taxYear + 1,
                taxYear,
              },
            ),
            consentType: DocumentsTypes.v1.Documents.NonReceiptTypes
              .SubmissionContent as ConsentRequest['consentType'],
          },
          {
            accessToken,
            apiBaseUrl: Config.API_BASE_URL,
          },
        );
        Analytics.log(AnalyticsEvent.consentSuccess);
        await submissionActions.updateLatestSubmissionStatus(accessToken, userId as number);
      } catch (err) {
        submissionActions.createError({
          id: 'consent.submit.error',
        });
        logger.error(err as ErrorType, {
          message: logErrorMessages.submissionConsentDocError,
        });
        Analytics.log(AnalyticsEvent.consentFailed);
        throw err;
      }
    };

    createAmlConsentDocument = async () => {
      const { intl, submissionActions, accessToken, userId, quizmasterLight } = this.props;
      const { consentDate } = this.state;
      const localizedDate = formatDate(intl, consentDate);
      const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
      const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
      const taxId = quizmasterLight[prefillQuestionIds.taxId].answer;

      try {
        await submissionActions.createConsent(
          {
            firstName,
            lastName,
            taxId,
            docs: [],
            documentsConsentText: '',
            date: localizedDate,
            platform: Platform.OS as Platforms,
            platformVersion: getVersion(),
            title: intl.formatMessage({
              id: 'italy-submission.aml-consent.title',
            }),
            lead: intl.formatMessage({
              id: 'italy-submission.aml-consent.subtitle',
            }),
            permissionText: intl.formatMessage({
              id: 'italy-submission.aml-consent.subtitle',
            }),
            consentText: intl.formatMessage({
              id: 'italy-submission.aml-consent.consent-text',
            }),
            consentType: DocumentsTypes.v1.Documents.NonReceiptTypes
              .AML as ConsentRequest['consentType'],
          },
          {
            accessToken,
            apiBaseUrl: Config.API_BASE_URL,
          },
        );
        Analytics.log(AnalyticsEvent.amlConsentSuccess);
        await quizmasterLight[flagsQuestionIds.amlConsent].saveAnswer(true);
        await submissionActions.updateLatestSubmissionStatus(accessToken, userId as number);
      } catch (err) {
        submissionActions.createError({
          id: 'consent.submit.error',
        });
        logger.error(err as ErrorType, {
          message: logErrorMessages.amlConsentDocError,
        });
        Analytics.log(AnalyticsEvent.consentFailed);
        throw err;
      }
    };

    createSpidConsentDocument = async () => {
      const { intl, submissionActions, accessToken, quizmasterLight } = this.props;
      const { consentDate } = this.state;
      const localizedDate = formatDate(intl, consentDate);
      const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
      const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
      const taxId = quizmasterLight[prefillQuestionIds.taxId].answer;

      try {
        await submissionActions.createConsent(
          {
            firstName,
            lastName,
            taxId,
            docs: [],
            documentsConsentText: '',
            date: localizedDate,
            platform: Platform.OS as Platforms,
            platformVersion: getVersion(),
            title: intl.formatMessage({
              id: 'italy-submission.spid-consent.title',
            }),
            lead: intl.formatMessage({
              id: 'italy-submission.spid-consent.lead',
            }),
            permissionText: intl.formatMessage({
              id: 'italy-submission.spid-consent.permission-text',
            }),
            consentText: intl.formatMessage({
              id: 'italy-submission.spid-consent.consent-text',
            }),
            consentType: DocumentsTypes.v1.Documents.NonReceiptTypes
              .SpidConsentXml as ConsentRequest['consentType'],
          },
          {
            accessToken,
            apiBaseUrl: Config.API_BASE_URL,
          },
        );
        Analytics.log(AnalyticsEvent.spidConsentSuccess);
        await quizmasterLight[flagsQuestionIds.SPIDConsentXMLSigned].saveAnswer(true);
      } catch (err) {
        submissionActions.createError({
          id: 'consent.submit.error',
        });
        logger.error(err as ErrorType, {
          message: logErrorMessages.spidConsentDocError,
        });
        Analytics.log(AnalyticsEvent.consentFailed);
        throw err;
      }
    };

    render() {
      const { isConsentGiven, ...ownProps } = this.props;
      const { consentDate } = this.state;
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <WrappedComponent
          {...ownProps}
          createSubmissionConsentDocument={this.createSubmissionConsentDocument}
          createAmlConsentDocument={this.createAmlConsentDocument}
          createSpidConsentDocument={this.createSpidConsentDocument}
          consentDate={consentDate}
          isConsentGiven={isConsentGiven}
        />
      );
    }
  }

  return compose(
    connector,
    injectIntl,
    withQuizmasterLight([
      wayQuestionIds.firstName,
      wayQuestionIds.lastName,
      prefillQuestionIds.taxId,
      flagsQuestionIds.amlConsent,
      flagsQuestionIds.SPIDConsentXMLSigned,
    ]),
  )(Enhancer);
}
