import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Container } from 'src/taxfix-components/src';

import { Text } from '../../general/Text';

import { Props } from './types';

/**
 * this is a template to use in the Identification documents upload screens
 */

export const IdentificationUploadTemplate: React.FC<Props> = ({
  title,
  description = null,
  mainContent,
  bottomElement = null,
  ...otherProps
}): JSX.Element => {
  const titleVariant = useBreakpointValue({ base: 'titleSMedium', md: 'titleLBold' });
  const titleMarginBottom = useBreakpointValue({ base: '10px', md: '20px' });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Container {...otherProps}>
      <Text
        variant={titleVariant}
        color="greytones.backgroundDark"
        value={title}
        marginBottom={titleMarginBottom}
      />
      {description}
      {isSmallScreen ? (
        <Container alignSelf="center" alignItems="center" width="100%">
          {mainContent}
        </Container>
      ) : (
        <Container
          mt="20px"
          mb="30px"
          padding="0px"
          alignSelf="center"
          alignItems="center"
          borderColor="greytones.graphicsDark"
          borderWidth="1px"
          borderStyle="dashed"
          borderRadius="20px"
          width="100%"
        >
          {mainContent}
        </Container>
      )}
      {bottomElement}
    </Container>
  );
};
