import Config from 'react-native-config';
import { CountryCodes } from '@taxfix/types';

import { LanguageCode } from 'src/types';

const supportedIntlConfigKey = 'SUPPORTED_INTL';

if (!Config[supportedIntlConfigKey]) {
  throw new Error(
    `Supported languages are not specified in the environment. Missing config key: ${supportedIntlConfigKey}`,
  );
}

export const supportedLanguagesByCountry: Record<CountryCodes, LanguageCode[]> = JSON.parse(
  Config[supportedIntlConfigKey],
);

export const supportedCountries = Object.keys(supportedLanguagesByCountry);
export const isLegacyMultiCountryApp = () => {
  return !Config.TAX_COUNTRY || Config.TAX_COUNTRY === CountryCodes.DE;
};

export type SharedAppCountries = CountryCodes.IT | CountryCodes.DE;
export const sharedAppSupportedCountries: string[] = supportedCountries.filter(
  (countryCode) => ![CountryCodes.ES, CountryCodes.FR].includes(countryCode as CountryCodes),
);
export const getDefaultTaxYear = (): number => new Date().getFullYear() - 1;
export const getDefaultNextTaxYear = (): number => getDefaultTaxYear() + 1;
