import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { isWeb } from '../utils/platform';

const identifyRegisteredUser = async (userId: number, email: string | undefined) => {
  await Analytics.identifyRegisteredUserWithoutAccessToken(
    { userId },
    {
      email,
    },
  );
};

const identifyAnonymousUser = async (emailForNotifications: string | undefined) => {
  if (emailForNotifications) {
    await Analytics.identifyAnonymousUser({ email: emailForNotifications });
  } else {
    const anonymousId = await Analytics.getAnonymousId();
    await Analytics.identifyAnonymousUser({
      email: anonymousId,
      anonymousId,
    });
  }
};

export const AppLandingTracker = (): null => {
  const [isAppOpenTracked, setAppOpenTracked] = React.useState(false);

  const userId = useSelector(userAuthSelectors.getUserId);
  const userEmail = useSelector(userAuthSelectors.getEmailAddress);
  const emailForNotifications = useSelector(
    settingsSelectors.selectCurrentCountrySpecifics,
  )?.emailForNotifications;
  React.useEffect(() => {
    if (userId) identifyRegisteredUser(userId, userEmail);
    else identifyAnonymousUser(emailForNotifications);

    if (!isAppOpenTracked) {
      Analytics.log(isWeb ? AnalyticsEvent.appLanding : AnalyticsEvent.appOpen);
      setAppOpenTracked(true);
    }
  }, []);

  return null;
};
