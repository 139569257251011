import React, { useEffect, useState } from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

import { getAsBase64 } from '../utils/fetch';

import { theme, useLargeScreenQuery } from './core';

const styles = StyleSheet.create({
  container: {
    borderColor: theme.color.border,
    width: 85,
  },
  image: {
    width: '100%',
    borderRadius: 10,
  },
  smallImage: {
    height: 88,
    maxWidth: 80,
  },
  largeImage: {
    height: 108,
  },
});

type Props = {
  uri: string;
  token: string;
  onPress: () => void;
};
export const ThumbnailButton: React.FC<Props> = ({ uri, token, onPress }: Props) => {
  const [source, setSource] = useState<{
    uri: string;
    method?: string;
    headers?: any;
  }>({
    uri,
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  useEffect(() => {
    // for PDFs for example
    if (Platform.OS === 'web' && !uri.startsWith('data:')) {
      const { method, headers } = source;
      getAsBase64(uri, { method, headers }).then((base64Uri) => {
        setSource({
          ...source,
          uri: base64Uri as string,
        });
      });
    }
    // do not include "source" in the dependency array - it results into infinite loading
    // https://taxfix.atlassian.net/browse/ITA-4171
  }, [uri]);

  const isLargeScreen = useLargeScreenQuery();
  return (
    <TouchableOpacity activeOpacity={0.7} onPress={onPress}>
      <View style={styles.container}>
        <Image
          source={source}
          style={[styles.image, isLargeScreen ? styles.largeImage : styles.smallImage]}
          resizeMode="contain"
        />
      </View>
    </TouchableOpacity>
  );
};
