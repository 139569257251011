import { HeaderBackButton } from '@react-navigation/elements';
import React, { useState } from 'react';
import { useBreakpointValue } from 'native-base';

import { useNavigation } from 'src/hooks/navigation-hook';
import { ScreenName } from 'src/types/screen-name';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';
import navigate, { Action, Navigation, NavigationParams } from '../../routes/navigate';

import { PrefillDocumentsResultContainer } from './prefill-documents-result-container';

type ActionParams = {
  onNext?: () => void;
};

type Props = Navigation<
  ScreenName.PrefillDocumentsResult,
  { PrefillDocumentsResult: NavigationParams<ActionParams> }
>;

export const toPrefillDocumentsResult: Action = navigate(ScreenName.PrefillDocumentsResult, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.PrefillDocumentsResult,
    showSupportButton: true,
  },
});

export const PrefillDocumentsResult: React.FC<Props> = ({ route }) => {
  const { onNext } = route.params;
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [backgroundColor, setBackgroundColor] = useState('white');

  const handleOnBackButtonPress = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={handleOnBackButtonPress} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.prefill-documents-result.nav.title' },
    {
      headerLeft: headerLeftComponent,
      headerStyle: { backgroundColor },
    },
  );

  useWebNavigationOptions({
    hideBorder: true,
    setNewOptions: isSmallScreen,
    headerStyle: isSmallScreen ? { backgroundColor } : {},
  });

  return (
    <PrefillDocumentsResultContainer onNext={onNext} setBackgroundColor={setBackgroundColor} />
  );
};
