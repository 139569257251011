/* eslint-disable global-require, quote-props */
import React from 'react';
import { Image as RNImage, Platform, StyleSheet, View } from 'react-native';
import get from 'lodash/get';

import { useImageSizeStyle } from '../../../hooks/image-size-hook';
import { getImageAsset } from '../../../assets/image-assets';
import { theme } from '../theme';
import { StyleProp } from '../../types';
import { testID } from '../../../common/testID';

export const localImages = {
  logo: {
    source: {
      uri: 'Logo',
    },
    dimensions: {
      width: 58,
      height: 58,
    },
  },
  submissionAssessmentConfetti: {
    source: require('../images/submission-assessment-confetti.png'),
  },
  onboardingPushNotificationsV2: {
    source: require('../images/onboarding-push-notifications-v2.png'),
  },
  postSubmissionNotification: {
    source: require('../images/post-submission-notification.png'),
  },
  payslip: {
    'scan-instructions': {
      de: {
        source: require('../images/payslip.scan-instructions.de.png'),
      },
      en: {
        source: require('../images/payslip.scan-instructions.en.png'),
      },
    },
    'completion-happy-moment': {
      source: require('../images/payslip-completion-happy-moment.png'),
    },
  },
  gradient: {
    source: require('../images/gradient.png'),
  },
  'upload-first-doc.upload-first-doc-local': {
    source: require('../images/doc-upload-2.png'),
  },
  notificationConsent: {
    calendar: {
      source: require('../images/notification-calendar.png'),
    },
  },
  payslipError: {
    corrupted: {
      source: require('../images/error_ice_cream.png'),
    },
    connectionLost: {
      source: require('../images/error_lighthouse.png'),
    },
  },
  ukraineHeart: {
    source: require('../images/ukraine-heart.png'),
  },
};
const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  flex: {
    flex: 1,
  },
  imageView: {
    width: 205,
    height: 205,
    backgroundColor: theme.color.fill,
  },
  useAvailableSpace: {
    width: '100%',
    height: '100%',
  },
});
export type ResizeModes = 'cover' | 'contain' | 'stretch' | 'repeat' | 'center';
type Props = {
  name?: string;
  style?: StyleProp;
  resizeMode?: ResizeModes;
  testId?: string;
  useAvailableSpace?: boolean;
  maxHeight?: number;
  maxWidth?: number;
};

const getImageContents = (name: any) => {
  // Try local images
  const local = get(localImages, name, null);

  if (local != null) {
    return Platform.OS === 'web' ? { source: local.source.default } : local;
  }

  // Try packaged image
  const asset = getImageAsset(name);

  if (asset != null) {
    return {
      source: asset,
      dimensions: null,
    };
  }

  return null;
};

const Image = ({
  name,
  style,
  resizeMode,
  testId,
  useAvailableSpace,
  maxHeight,
  maxWidth,
}: Props) => {
  const spec = name != null ? getImageContents(name) : null;
  const imageSizeStyle = useImageSizeStyle(spec ? spec.source : null, {
    maxHeight,
    maxWidth,
  });
  const view =
    spec != null ? (
      <RNImage
        source={spec.source}
        style={[
          spec.dimensions,
          resizeMode != null ? styles.flex : null,
          useAvailableSpace ? styles.useAvailableSpace : null,
          imageSizeStyle,
          style,
        ]}
        resizeMode={resizeMode}
        {...testID(testId)}
      />
    ) : (
      <View style={styles.imageView} />
    );
  return (
    <View style={[styles.container, useAvailableSpace ? styles.useAvailableSpace : null]}>
      {view}
    </View>
  );
};

export default Image;
