import React from 'react';
import { StyleSheet } from 'react-native';

import { Box, ScrollView, theme, useSmallScreenQuery } from '../../components/core';
import { CheckboxRow } from '../../components/checkbox/checkbox-row';
import { testID } from '../../common/testID';
import { WEB_SECTION_WIDE_WIDTH } from '../../utils/constants';

export type AnswerOption = {
  value: string;
  label: string;
  isSelected: boolean;
};

type Props = {
  options: AnswerOption[];
  onPressOption: (arg0: number) => void;
};

const styles = StyleSheet.create({
  buttonText: {
    color: theme.color.primary,
  },
  columnsContainerLarge: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  columnsContainer: {
    flexDirection: 'column',
  },
  option: {
    minWidth: WEB_SECTION_WIDE_WIDTH / 2,
    maxWidth: WEB_SECTION_WIDE_WIDTH / 2,
    paddingLeft: 10,
  },
});

export const ItalyDigicafProfileOccupationComponent = ({
  options,
  onPressOption,
}: Props): JSX.Element => {
  const isSmallScreen = useSmallScreenQuery();
  return (
    <Box top={2} flex>
      <ScrollView {...testID('scrollview.occupation.screen')}>
        <Box style={isSmallScreen ? styles.columnsContainer : styles.columnsContainerLarge}>
          <Box direction="column">
            {options.map((option, index) => (
              <CheckboxRow
                key={index}
                isChecked={options[index].isSelected}
                withDarkText
                translationKey={option.label}
                onChange={() => onPressOption(index)}
                testId={option.label}
                style={styles.option}
              />
            ))}
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};
