import { $Keys } from 'utility-types';
import { createSelector } from 'reselect';
import { createFilter } from 'redux-persist-transform-filter';

import { ThunkAction } from '../types';

import { selectors as settingsSelectors } from './settings';

const UPDATE_FIRST_NAME = 'userProfile/UPDATE_FIRST_NAME';
const UPDATE_LAST_NAME = 'userProfile/UPDATE_LAST_NAME';
const UPDATE_TAX_ID = 'userProfile/UPDATE_TAX_ID';
const UPDATE_OCCUPATION = 'userProfile/UPDATE_OCCUPATION';
const UPDATE_EMPLOYER = 'userProfile/UPDATE_EMPLOYER';
const UPDATE_PHONE_NUMBER = 'userProfile/UPDATE_PHONE_NUMBER';
const UPDATE_BILLING_ADDRESS = 'userProfile/UPDATE_BILLING_ADDRESS';
// Fields that are available for editing on personal details screen
export const PDFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  taxId: 'taxId',
};
export type PDField = $Keys<typeof PDFields>;
type FieldVersioned<T> = {
  value: T;
  version: number | null | undefined;
  isOutdated: boolean;
};
export type EmployerType = {
  hasEmployer: boolean;
  name: string;
  taxId: string;
};
export type EmployerVersionedType = FieldVersioned<EmployerType>;
export type BillingAddress = {
  street: string;
  city: string;
  zipcode: string;
  state?: string | null | undefined;
  countryCode: string;
};
export type BillingAddressVersionedType = FieldVersioned<BillingAddress>;
export type UserProfile = {
  firstName: string;
  lastName: string;
  taxId: string;
  occupation: string[];
  employer: EmployerType | null | undefined;
  phoneNumber: string;
  billingAddress: BillingAddress;
};
type DataVersions = Partial<Record<keyof UserProfile, number | null | undefined>>;
type UserProfileVersioned = {
  firstName: FieldVersioned<UserProfile['firstName']>;
  lastName: FieldVersioned<UserProfile['lastName']>;
  taxId: FieldVersioned<UserProfile['taxId']>;
  occupation: FieldVersioned<UserProfile['occupation']>;
  employer: EmployerVersionedType;
  phoneNumber: FieldVersioned<UserProfile['phoneNumber']>;
  billingAddress: BillingAddressVersionedType;
};
type PayloadWithVersion<T> = {
  value: T;
  version: number;
};
type UpdateFirstNameAction = {
  type: typeof UPDATE_FIRST_NAME;
  payload: PayloadWithVersion<string>;
};
type UpdateLastNameAction = {
  type: typeof UPDATE_LAST_NAME;
  payload: PayloadWithVersion<string>;
};
type UpdateTaxIdAction = {
  type: typeof UPDATE_TAX_ID;
  payload: PayloadWithVersion<string>;
};
type UpdateOccupationAction = {
  type: typeof UPDATE_OCCUPATION;
  payload: PayloadWithVersion<string[]>;
};
type UpdateEmployerAction = {
  type: typeof UPDATE_EMPLOYER;
  payload: PayloadWithVersion<EmployerType>;
};
type UpdatePhoneNumberAction = {
  type: typeof UPDATE_PHONE_NUMBER;
  payload: PayloadWithVersion<string>;
};
type UpdateBillingAddressAction = {
  type: typeof UPDATE_BILLING_ADDRESS;
  payload: PayloadWithVersion<BillingAddress>;
};
type Action =
  | UpdateFirstNameAction
  | UpdateLastNameAction
  | UpdateTaxIdAction
  | UpdateOccupationAction
  | UpdateEmployerAction
  | UpdatePhoneNumberAction
  | UpdateBillingAddressAction;

const getDataVersion = (state: any) => {
  // use the currently selected year as the data version as it stays constant during the season
  const selectedYear = settingsSelectors.selectedYear(state);

  if (!selectedYear) {
    throw new Error('Cannot update UserProfile fields without the year being selected');
  }

  return selectedYear;
};

export type UpdateFirstName = (value: string) => ThunkAction<UpdateFirstNameAction>;

const updateFirstName: UpdateFirstName = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_FIRST_NAME,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdateLastName = (value: string) => ThunkAction<UpdateLastNameAction>;

const updateLastName: UpdateLastName = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_LAST_NAME,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdateTaxId = (value: string) => ThunkAction<UpdateTaxIdAction>;

const updateTaxId: UpdateTaxId = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_TAX_ID,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdateOccupation = (value: string[]) => ThunkAction<UpdateOccupationAction>;

const updateOccupation: UpdateOccupation = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_OCCUPATION,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdateEmployer = (value: EmployerType) => ThunkAction<UpdateEmployerAction>;

const updateEmployer: UpdateEmployer = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_EMPLOYER,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdatePhoneNumber = (value: string) => ThunkAction<UpdatePhoneNumberAction>;

const updatePhoneNumber: UpdatePhoneNumber = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_PHONE_NUMBER,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export type UpdateBillingAddress = (
  value: BillingAddress,
) => ThunkAction<UpdateBillingAddressAction>;

const updateBillingAddress: UpdateBillingAddress = (value) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_BILLING_ADDRESS,
    payload: {
      value,
      version: getDataVersion(getState()),
    },
  });
};

export const userProfileActions = {
  updateFirstName,
  updateLastName,
  updateTaxId,
  updateOccupation,
  updateEmployer,
  updatePhoneNumber,
  updateBillingAddress,
};
export type State = {
  data: UserProfile;
  dataVersion: DataVersions;
};
export const initial: State = {
  data: {
    firstName: '',
    lastName: '',
    taxId: '',
    occupation: [],
    employer: undefined,
    phoneNumber: '',
    billingAddress: {
      street: '',
      city: '',
      zipcode: '',
      state: '',
      countryCode: '',
    },
  },
  dataVersion: {},
};
// Get the list of UserProfile fields from the initial state, because it contains
// all of them, even the undefined ones.
const userProfileFields = Object.keys(initial.data);
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case UPDATE_FIRST_NAME: {
      return {
        ...state,
        data: { ...state.data, firstName: action.payload.value },
        dataVersion: { ...state.dataVersion, firstName: action.payload.version },
      };
    }

    case UPDATE_LAST_NAME: {
      return {
        ...state,
        data: { ...state.data, lastName: action.payload.value },
        dataVersion: { ...state.dataVersion, lastName: action.payload.version },
      };
    }

    case UPDATE_TAX_ID: {
      return {
        ...state,
        data: { ...state.data, taxId: action.payload.value },
        dataVersion: { ...state.dataVersion, taxId: action.payload.version },
      };
    }

    case UPDATE_OCCUPATION: {
      return {
        ...state,
        data: { ...state.data, occupation: action.payload.value },
        dataVersion: { ...state.dataVersion, occupation: action.payload.version },
      };
    }

    case UPDATE_EMPLOYER: {
      return {
        ...state,
        data: { ...state.data, employer: action.payload.value },
        dataVersion: { ...state.dataVersion, employer: action.payload.version },
      };
    }

    case UPDATE_PHONE_NUMBER: {
      return {
        ...state,
        data: { ...state.data, phoneNumber: action.payload.value },
        dataVersion: { ...state.dataVersion, phoneNumber: action.payload.version },
      };
    }

    case UPDATE_BILLING_ADDRESS: {
      return {
        ...state,
        data: { ...state.data, billingAddress: action.payload.value },
        dataVersion: { ...state.dataVersion, billingAddress: action.payload.version },
      };
    }

    default:
      return state;
  }
};
type RootState = {
  userProfile: State;
};

const getUserProfile = (state: RootState): UserProfile => state.userProfile.data;

const getFieldVersions = (state: RootState): DataVersions => state.userProfile.dataVersion;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getVersionedUserProfile: (state: RootState) => UserProfileVersioned = createSelector(
  getUserProfile,
  getFieldVersions,
  settingsSelectors.selectedYear,
  (userProfile: any, fieldVersions: any, selectedYear: any) => {
    return userProfileFields.reduce((acc: any, field) => {
      acc[field] = {
        value: userProfile[field],
        version: fieldVersions[field],
        isOutdated: fieldVersions[field] != null && fieldVersions[field] < selectedYear,
      };
      return acc;
    }, {});
  },
);
export const userProfileSelectors = {
  getVersionedUserProfile,
  getUserProfile,
};
export const persistFilter = createFilter('userProfile');
