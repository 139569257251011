import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { theme } from '../theme';
import Box from '../Box';
import { LinkText } from '../Text';

import InternalButton, { InternalButtonPropsWithStyle } from './_InternalButton';

export type BlockButtonProps = InternalButtonPropsWithStyle & {
  loading?: boolean;
  floating?: boolean;
  translationKey?: string;
  values?: Record<string, unknown>;
  type?: 'primary' | 'secondary' | 'warning';
  textColor?: string;
  disabledTextColor?: string;
  fillColor?: string;
  borderColor?: string;
  loadingColor?: string;
  typeColor?: string;
  testId?: string;
  style?: any;
  id?: string;
  hasIcon?: boolean;
};
const styles: any = StyleSheet.create({
  // container
  container: {
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  primaryContainer: {
    minHeight: 52,
    backgroundColor: theme.color.primary,
  },
  secondaryContainer: {
    minHeight: 50,
  },
  warningContainer: {
    minHeight: 50,
  },
  // container - floating
  primaryFloatingContainer: {
    borderRadius: 10,
  },
  secondaryFloatingContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.color.primary,
  },
  warningFloatingContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.color.orangeText,
  },
  // container - disabled
  primaryContainerDisabled: {
    backgroundColor: theme.color.disabledGray,
  },
  secondaryContainerDisabled: {
    borderWidth: 1,
    borderColor: theme.color.disabledGray,
  },
  warningContainerDisabled: {
    borderWidth: 1,
    borderColor: theme.color.disabledGray,
  },
  // button text
  buttonText: {
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  primaryText: {
    color: theme.color.lightText,
  },
  secondaryText: {
    color: theme.color.primary,
  },
  warningText: {
    color: theme.color.orangeText,
  },
  // button text - disabled
  primaryTextDisabled: {
    color: theme.color.lightText,
  },
  secondaryTextDisabled: {
    color: theme.color.disabledGray,
  },
  warningTextDisabled: {
    color: theme.color.disabledGray,
  },
});

const BlockButton = ({
  translationKey,
  loading = false,
  floating = true,
  type = 'primary',
  textColor,
  disabledTextColor,
  fillColor,
  borderColor,
  values,
  style,
  loadingColor,
  typeColor,
  testId,
  ...props
}: BlockButtonProps): JSX.Element => {
  let children;
  const containerStyles = [
    styles.container, // @ts-ignoreTaxfix
    floating === true ? styles[`${type}FloatingContainer`] : null, // @ts-ignoreTaxfix
    styles[`${type}Container`],
    typeColor != null
      ? type === 'primary'
        ? {
            backgroundColor: typeColor,
          }
        : {
            borderColor: typeColor,
          }
      : null,
    fillColor != null
      ? {
          backgroundColor: fillColor,
        }
      : null,
    borderColor != null
      ? {
          borderWidth: 1,
          borderColor,
        }
      : null, // @ts-ignoreTaxfix
    props.disabled === true || loading ? styles[`${type}ContainerDisabled`] : null,
    style,
  ];
  const textStyles = [
    styles.buttonText,
    styles[`${type}Text`],
    typeColor && type === 'secondary'
      ? {
          color: typeColor,
        }
      : null,
    textColor
      ? {
          color: textColor,
        }
      : null,
    props.disabled === true
      ? disabledTextColor
        ? {
            color: disabledTextColor,
          }
        : styles[`${type}TextDisabled`]
      : null,
  ];

  if (translationKey != null) {
    children = <LinkText id={translationKey} style={textStyles} values={values} />;
  } else if (props.hasIcon) {
    children = (
      <Box flex alignVertically="center" alignHorizontally="center">
        {props.children}
      </Box>
    );
  } else {
    children = <LinkText style={textStyles}>{props.children}</LinkText>;
  }

  if (loading) {
    children = (
      <Box flex alignVertically="center" alignHorizontally="center">
        <ActivityIndicator animating color={loadingColor || 'white'} />
      </Box>
    );
  }

  return (
    <InternalButton
      disabled={props.disabled || loading}
      onPress={props.onPress}
      style={containerStyles}
      testId={testId}
    >
      {children}
    </InternalButton>
  );
};

export default BlockButton;
