import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Button, Container, Text } from 'src/taxfix-components/src';

import { getTextStyles } from '../../general/Button';

import { Props } from './types';

export const EstimatedRefund: React.FC<Props> = ({
  title,
  refundAmount,
  description,
  buttonLabel,
  onPressMainButton,
  isPositive,
  ...containerProps
}): JSX.Element => {
  const descriptionVariant = useBreakpointValue({ base: 'bodyXSBook', md: 'bodySBook' });

  return (
    <Container
      width="100%"
      alignItems="center"
      backgroundColor={isPositive ? 'secondaryFeedback.greenLight' : 'secondaryCategory.salmonHalf'}
      borderRadius={24}
      padding={24}
      maxWidth={580}
      {...containerProps}
    >
      <Text color="greytones.textDefault" value={title} variant="bodyXXXSMedium" />
      <Text
        fontFamily="brand"
        fontWeight={500}
        fontSize="xl"
        color={isPositive ? 'brand.brandGreen' : 'secondaryFeedback.errorRedDark'}
        marginY="8px"
        lineHeight="xs"
        value={refundAmount}
      />

      <Text color="greytones.textLight" variant={descriptionVariant} value={description} />

      {!!buttonLabel && onPressMainButton && (
        <Button
          variant="primaryWhite"
          maxWidth={122}
          _text={getTextStyles({
            color: isPositive ? 'brand.brandGreen' : 'secondaryFeedback.errorRedDark',
          })}
          marginTop="24px"
          title={buttonLabel}
          onPress={onPressMainButton}
        />
      )}
    </Container>
  );
};
