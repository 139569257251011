import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';

const mapStateToProps = (stores: any) => ({
  userEmail: userAuthSelectors.getEmailAddress(stores),
});

const connector = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ListRowComponentPropsType<any> & WithNavigation & ReduxProps;

const ChangePin = (props: Props) => {
  const { showIcon = false, textVariation = '' } = props;

  const handleSelectItem = (item: MenuItemData) => {
    const { navigationActions, userEmail } = props;
    trackMenuItem(item);
    (navigationActions as any).toForgotPassword('screen', {
      email: userEmail || '',
      showSidebar: true,
    });
  };

  return (props as any).children({
    hide: false,
    data: {
      iconName: showIcon ? 'menuItems.lock' : undefined,
      translationKey: `${menuItem.pinChange}${textVariation}`,
      testId: menuItem.pinChange,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const ChangePinMenu = compose(withNavigation, connector)(ChangePin);
