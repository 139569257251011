import React, { Component } from 'react';
import { Animated, View, Platform, LayoutAnimation, Easing } from 'react-native';

import { Image } from '../core';

const springAnimationProperties = {
  type: 'spring',
  springDamping: 0.7,
  property: 'opacity',
};
const customSpring = {
  duration: 1200,
  create: springAnimationProperties,
  update: springAnimationProperties,
  delete: springAnimationProperties,
};
type Props = {
  showTransition?: boolean;
  onAnswer: (node: any, answer: any) => any;
  onSkip?: () => void;
  onFinish?: () => void;
  children: (arg0: {
    onAnswer: (node: any, answer: any) => any;
    onSkip?: () => any;
    isLoading: boolean;
  }) => any;
  delay?: number;
  lastQuestion?: boolean;
  shouldMoveToNextQuestion?: boolean;
};
type State = {
  isLoading: boolean;
};

class Question extends Component<Props, State> {
  state = {
    isLoading: this.props.showTransition || false,
  };

  androidCardHeight = 0;

  getDefaultDelay = this.props.delay || 400;

  androidOnAnswerAnimation = new Animated.Value(0);

  slide = new Animated.Value(-100);

  static defaultProps = {
    onAnswer: () => {},
  };

  componentDidMount() {
    this.slideInOut();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.shouldMoveToNextQuestion && !prevProps.shouldMoveToNextQuestion) {
      this.transitionToNextQuestion();
    }
  }

  setLoading = (isLoading: boolean, onComplete?: () => void) => {
    this.setState(
      {
        isLoading,
      },
      onComplete ? onComplete : undefined,
    );
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;

    if (onFinish) {
      onFinish();
    }
  };

  transitionToNextQuestion = async () => {
    this.setLoading(true, this.afterSave);
  };

  setAnimationForNextCard = () => {
    if (this.props.showTransition) {
      if (Platform.OS !== 'android') {
        // @ts-ignore
        LayoutAnimation.configureNext(customSpring, this.handleOnFinish);
      } else {
        /*
          NOTE: LayoutAnimation is not working properly on Android. It's probably RN's bug.
          The problem is that it doesn't calculate the final height during the animation,
          so it's impossible to force scrolling to bottom before the LayoutAnimation is done.
          In bottom-bound-scrollview.js, it works only if I set delay long enough.
          But it doesn't look good.
          Thus, I just used Animated.timing for appearing animation on Android.
        */
        this.androidCardAppearAnimation();
      }
    }
  };

  androidHandleLayout = (e: any) => {
    this.androidCardHeight = e.nativeEvent.layout.height;
  };

  androidCardAppearAnimation = () => {
    if (this.props.showTransition) {
      this.androidOnAnswerAnimation.setValue(this.androidCardHeight);
      Animated.timing(this.androidOnAnswerAnimation, {
        toValue: 0,
        duration: this.androidCardHeight * 1.1 + 200,
        easing: Easing.elastic(0.8),
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          this.handleOnFinish();
        }
      });
    }
  };

  afterSave = () => {
    this.slideIn(async () => {
      const timeLogicBegin = new Date().getTime();
      const logicDuration = new Date().getTime() - timeLogicBegin;
      this.slideOut(Math.max(0, this.getDefaultDelay - logicDuration));
    });
  };

  handleResponse = async (onResponse: any) => {
    const { lastQuestion = false, showTransition } = this.props;

    if (!lastQuestion && showTransition) {
      onResponse();
      this.setLoading(true, this.afterSave);
    } else {
      onResponse();
    }
  };

  slideInOut = () => {
    this.slideIn(() => this.slideOut(this.getDefaultDelay));
  };

  slideIn = async (complete?: any) => {
    const { showTransition = true } = this.props;

    if (showTransition) {
      this.slide.setValue(-100);
      Animated.timing(this.slide, {
        delay: 200,
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        if (complete) complete();
      });
    }
  };

  slideOut = async (delay: number) => {
    this.slide.stopAnimation();
    Animated.timing(this.slide, {
      delay,
      toValue: -300,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      this.setAnimationForNextCard();
      this.setState({
        isLoading: false,
      });
    });
  };

  render() {
    const { isLoading } = this.state;
    const opacity = this.slide.interpolate({
      inputRange: [-300, -50, 0],
      outputRange: [0, 0, 1] as number[],
    });
    const cardProps = Platform.select({
      ios: {
        style: isLoading && {
          height: 0,
          opacity: 0.05,
          top: 200,
        },
      },
      android: {
        style: [
          isLoading && {
            position: 'absolute',
            opacity: 0,
            top: 100,
          },
          {
            transform: [
              {
                translateY: this.androidOnAnswerAnimation,
              },
            ],
          },
        ],
        onLayout: this.androidHandleLayout,
      },
      web: {
        style: isLoading && {
          height: 0,
          opacity: 0,
          top: 10,
        },
      },
    });
    return (
      <View>
        {this.state.isLoading && (
          <Animated.View
            style={{
              transform: [
                {
                  translateX: this.slide,
                },
              ],
              opacity,
            }}
          >
            <View
              style={{
                left: 22,
                bottom: 3,
                marginVertical: 12,
                width: 45,
              }}
            >
              <Image
                style={{
                  width: 45,
                  height: 15,
                }}
                name="gif.question-loading"
              />
            </View>
          </Animated.View>
        )}
        {/* @ts-ignore */}
        <Animated.View {...cardProps}>
          {this.props.children({
            onAnswer: (node, answer) => {
              this.handleResponse(() => this.props.onAnswer(node, answer));
            },
            onSkip: () => this.handleResponse(this.props.onSkip),
            isLoading: this.state.isLoading,
          })}
        </Animated.View>
      </View>
    );
  }
}

export default Question;
