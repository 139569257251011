import { selectors as settingsSelector } from '../stores/modules/settings';

import {
  PerformanceAttributes,
  PerformanceMetrics,
  startPerformanceTrace,
} from './firebase-performance';

export const startPerformanceTraceWithDefaultAttributes =
  (store: Record<string, any>) =>
  async (label: string, attributes?: PerformanceAttributes, metrics?: PerformanceMetrics) => {
    const year = settingsSelector.selectedYear(store as any);
    const augmentedAttributes = {
      ...attributes,
      taxCountry: settingsSelector.selectedCountry(store as any),
      year: year ? year.toString(10) : '',
    };
    return startPerformanceTrace(label, augmentedAttributes, metrics);
  };
