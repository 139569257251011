import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const ERIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#E1F8E6" />
    <Path
      d="M43.9999 20.8362V30.6313C43.997 38.0651 39.1201 44.6176 32 46.7542C24.8761 44.6179 19.998 38.0604 20 30.6231V20.8281C21.9809 20.8281 23.5893 19.227 23.5984 17.2461H40.4016C40.4061 19.2302 42.0158 20.8363 43.9999 20.8362Z"
      fill="#32C850"
    />
    <Path
      d="M38.5164 19.6406H25.496C24.8894 21.0262 23.7832 22.1324 22.3976 22.739V30.6324C22.3683 36.7519 26.2245 42.216 32.0001 44.2389C37.7756 42.216 41.6318 36.7519 41.6025 30.6324V22.739C40.223 22.1278 39.1221 21.0226 38.5164 19.6406V19.6406Z"
      fill="#E1F8E6"
    />
    <Path
      d="M31.9999 26C34.6504 26 36.799 28.1487 36.799 30.7992C36.799 33.4497 34.6504 35.5983 31.9999 35.5983C29.3493 35.5983 27.2007 33.4497 27.2007 30.7992C27.2029 28.1496 29.3503 26.0023 31.9999 26Z"
      fill="#203681"
    />
    <Path
      d="M30.6227 30.9753C30.4712 30.8237 30.2503 30.7646 30.0433 30.82C29.8363 30.8755 29.6746 31.0372 29.6191 31.2442C29.5636 31.4512 29.6228 31.6721 29.7743 31.8236L30.9752 33.0245C31.0875 33.1373 31.2401 33.2008 31.3993 33.2008C31.5586 33.2008 31.7112 33.1373 31.8235 33.0245L34.8235 30.0245C35.0564 29.7896 35.0564 29.411 34.8235 29.1761C34.7118 29.0622 34.5589 28.998 34.3993 28.998C34.2398 28.998 34.0869 29.0622 33.9752 29.1761L31.3973 31.7499L30.6227 30.9753Z"
      fill="#E1F8E6"
    />
  </Icon>
);
