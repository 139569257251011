import React from 'react';

import navigate, { Action } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';
import { useLargeScreenQuery } from 'src/components/core';

import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { ProductBundleContainer } from './product-bundle-container';

export const toProductBundle: Action = navigate(ScreenName.ProductBundle);

export const ProductBundle: React.FC = () => {
  const isLargeScreen = useLargeScreenQuery();
  useDefaultNavigationOptions(
    isLargeScreen ? { id: 'it.product-bundle-screen.nav.title' } : undefined,
    {
      headerBackTitleVisible: false,
    },
  );

  return <ProductBundleContainer />;
};
