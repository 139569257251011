import React, { ReactNode } from 'react';
import { BackHandler, AppState, Platform, NativeEventSubscription } from 'react-native';
import { Dispatch, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import Config from 'react-native-config';
import { getApplicationName, getVersion } from 'react-native-device-info';
import { Instance as SDKInstance, User } from '@taxfix/taxfix-sdk';
import { CountryCodes, Platforms } from '@taxfix/types';

import { getCountryConfigs } from 'src/routes/config';
import { selectors as lockSelectors } from 'src/stores/modules/lock';
import { isNative, isWeb } from 'src/utils/platform';
import { StripeRedirectStatus } from 'src/screens/payment/payment-route.common';
import { DynamicLink } from 'src/stores/modules/dynamic-link-types';
import type { State as RootState } from 'src/stores/store/initial';

import { getNavigationActions, getBoundNavigationsActions } from '../routes/config-util';
import {
  actions as OverlayActions,
  selectors as OverlaySelectors,
} from '../stores/modules/overlay';
import {
  selectors as userAuthSelectors,
  actions as userAuthActions,
} from '../stores/modules/user-auth';
import {
  actions as statusActions,
  clearTimeoutInterval as clearStatusTimeoutInterval,
} from '../stores/modules/status';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import InternetConnectionInfo from '../services/internetconnection';
import { actions as InternetConnectionActions } from '../stores/modules/internet-connection';
import { selectors as firebaseSelectors } from '../stores/modules/remote-config-firebase';

import { DynamiclinkRenderProps } from './dynamic-link';
import { AppStates, AppStateContext } from './app-state-context';

const mapStateToProps = (stores: RootState) => ({
  hasEmailAddress: userAuthSelectors.hasEmailAddress(stores),
  isAuthenticated: userAuthSelectors.isAuthenticated(stores),
  accessToken: userAuthSelectors.getAccessToken(stores),
  selectedCountry: settingsSelectors.selectedCountry(stores),
  hasActiveOverlay: !!OverlaySelectors.getActiveOverlayName(stores),
  hasLockedScreenDisabled: lockSelectors.hasLockedScreenDisabled(stores),
  dynamicLink: stores.dynamicLink.dynamicLink,
  appCountrySplitConfig: firebaseSelectors.getAppCountrySplitConfig(stores),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateConnectionInfo: bindActionCreators(
    InternetConnectionActions.updateConnectionInfo,
    dispatch,
  ),
  cancelStatusResolution: bindActionCreators(statusActions.cancelStatusResolution, dispatch),
  requestRefreshStatus: bindActionCreators(statusActions.requestRefreshStatus, dispatch),
  overlayActions: bindActionCreators(OverlayActions, dispatch),
  updateUserFromResponse: bindActionCreators(userAuthActions.updateUserFromResponse, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  children?: ReactNode;
} & DynamiclinkRenderProps &
  ReduxProps;

type State = {
  locked: boolean;
  appState: typeof AppState.currentState | null | undefined;
};

const LOCK_TIMEOUT = 1000 * 60 * 5;
const LOCK_TIMEOUT_WEB = 1000 * 60 * 10;
const showDebugScreen = Config.SHOW_DEBUG_SCREEN === 'true';
const STATUS_CODE_FORBIDDEN = 403;

const isLockScreenDisabled = Config.DISABLE_LOCK_SCREEN === 'true';

const isCurrentWebPathValid = () => {
  // only if accessed URL is not root one
  const pathname = window.location.pathname.split('/')[1];
  return !!pathname;
};

const initialState = {
  locked: false,
  appState: AppState.currentState,
};

export class AppStateManager extends React.Component<Props, State> {
  state = { ...initialState };

  async componentDidMount(): Promise<void> {
    // To restrict the user from manually changing the web url: https://taxfix.atlassian.net/browse/ITA-3107
    if (isWeb && window?.location?.pathname !== '/') {
      window.location.pathname = '/';
    }

    this.appStateListener = AppState.addEventListener('change', this.handleAppStateChange);
    this.backHandlerListener = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
    InternetConnectionInfo.addListener(this.props.updateConnectionInfo);
    SDKInstance.callInterceptor.addResponseInterceptorListener(this.handleInterceptedResponse);

    //TODO: Will be enabled after our API's start accepting these new headers with explicitly updating the Access-Control-Allow-Headers
    if (!isWeb) {
      SDKInstance.options.clientName = getApplicationName();
      SDKInstance.options.clientVersion = getVersion();
      SDKInstance.options.clientPlatform = Platform.OS as Platforms;
    }

    const { onInitialFetch } = this.props;

    const dynamicLink = await onInitialFetch();

    /*
    with the latest react and react-dom 18 upgrade there seem to be
    a change of the way components and their lifecycles are working.
    only web was crashing due to trying to redirect the user while
    the navigation stack is not ready yet.
    setTimeout allows the stack to be build until we redirect the user
    as a temporary solution.
    TODO: investigate further more clean apporach
     */
    setTimeout(() => {
      this.redirectOnAppStart(dynamicLink);
    }, 1000);
  }

  async componentDidUpdate(prevProps: Props): Promise<void> {
    const { onDynamicLink, hasEmailAddress, dynamicLink } = this.props;
    const navigationActions = getBoundNavigationsActions();
    let handledDLinkRedirect = false;

    if (!prevProps.dynamicLink && dynamicLink) {
      if (hasEmailAddress && dynamicLink.requiresAuth) {
        handledDLinkRedirect = await onDynamicLink();

        if (!handledDLinkRedirect) {
          navigationActions.reset({
            index: 0,
            actions: [getNavigationActions().toUniversalStatus('screen')],
          });
        }
      } else if (!hasEmailAddress && dynamicLink.requiresAuth) {
        navigationActions.reset({
          index: 0,
          actions: [
            getNavigationActions().toLogin('screen', {
              onConfirm: async () => {
                handledDLinkRedirect = await onDynamicLink();

                if (!handledDLinkRedirect) {
                  navigationActions.reset({
                    index: 0,
                    actions: [getNavigationActions().toUniversalStatus('screen')],
                  });
                }
              },
            }),
          ],
        });
      } else {
        handledDLinkRedirect = await onDynamicLink();
        if (!handledDLinkRedirect) this.handleOnboarding();
      }
    }
  }

  componentWillUnmount(): void {
    this.appStateListener?.remove();
    this.backHandlerListener?.remove();
    InternetConnectionInfo.removeListener();
    SDKInstance.callInterceptor.removeResponseInterceptorListener(this.handleInterceptedResponse);
  }

  backgroundedTimestamp = 0;

  appStateListener?: ReturnType<typeof AppState.addEventListener>;

  backHandlerListener?: NativeEventSubscription;

  redirectOnAppStart = (dynamicLink: DynamicLink | null | undefined): void => {
    const { selectedCountry, appCountrySplitConfig } = this.props;

    const { navigationActions } = getCountryConfigs(selectedCountry);
    const countryAppConfig = appCountrySplitConfig[selectedCountry];

    if (isWeb && window.location.search.includes('payment_intent')) {
      const url = new URL(window.location.href);
      const redirect_status = url.searchParams.get('redirect_status') as StripeRedirectStatus;

      if (redirect_status) {
        navigationActions.reset({
          index: 0,
          actions: [
            getNavigationActions().toItalyPayment('screen', {
              redirectStatus: redirect_status,
            }),
          ],
        });
      }
      return;
    }

    if (!showDebugScreen && countryAppConfig?.enabled) {
      navigationActions.reset({
        index: 0,
        actions: [getNavigationActions().toDownloadAppScreen('screen')],
      });
      return;
    }

    if (!dynamicLink && !this.shouldGoToPublic()) {
      if (this.shouldRedirectToOnboarding()) {
        this.handleOnboarding();
      } else if (!showDebugScreen && !isLockScreenDisabled) {
        const onConfirm = async () => {
          this.setState(
            {
              locked: false,
            },
            async () => {
              navigationActions.reset({
                index: 0,
                actions: [getNavigationActions().toUniversalStatus('screen')],
              });
            },
          );
        };

        this.setState(
          {
            locked: true,
          },
          () => {
            navigationActions.reset({
              index: 0,
              actions: [
                getNavigationActions().toLock('screen', {
                  onConfirm,
                }),
              ],
            });
          },
        );
      } else if (
        !showDebugScreen &&
        isLockScreenDisabled &&
        this.shouldRedirectToUniversalStatus()
      ) {
        navigationActions.reset({
          index: 0,
          actions: [getNavigationActions().toUniversalStatus('screen')],
        });
      }
    }
  };

  shouldRedirectToUniversalStatus = (): boolean => {
    switch (this.props.selectedCountry) {
      case CountryCodes.ES:
        return isNative || !isCurrentWebPathValid();
      default:
        return false;
    }
  };

  shouldGoToPublic = (): boolean => {
    const { selectedCountry } = this.props;
    let isPublic = false;

    if (selectedCountry === CountryCodes.IT && isWeb) {
      isPublic = isCurrentWebPathValid();
    }

    return isPublic;
  };

  shouldRedirectToOnboarding = (): boolean => {
    const { hasEmailAddress, selectedCountry } = this.props;
    switch (selectedCountry) {
      case CountryCodes.ES:
        if (!hasEmailAddress) {
          if (isNative) return true;
          // emails like reset pin and email address confirmation link to our webapp
          // So for web, we only redirect to onboarding if user didn't specify any valid path
          return isNative || !isCurrentWebPathValid();
        }
        return false;

      default:
        return !hasEmailAddress;
    }
  };

  handleOnboarding = (): void => {
    if (showDebugScreen) return;
    const navigationActions = getBoundNavigationsActions();

    navigationActions.reset({
      index: 0,
      actions: [getNavigationActions().toItalyOnboarding('screen')],
    });
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  handleInterceptedResponse = (statusCode: number, res: Record<string, any>): void => {
    if (statusCode === STATUS_CODE_FORBIDDEN) this.openLockScreen();
  };

  shouldOpenLockScreen = (): boolean =>
    !this.state.locked &&
    !this.props.hasLockedScreenDisabled &&
    this.props.hasEmailAddress &&
    this.props.isAuthenticated &&
    this.backgroundedTimestamp > 0 &&
    !isLockScreenDisabled &&
    Date.now() - this.backgroundedTimestamp >= (isWeb ? LOCK_TIMEOUT_WEB : LOCK_TIMEOUT);

  openLockScreen = (): void => {
    const { onDynamicLink, requestRefreshStatus, hasEmailAddress } = this.props;

    if (!hasEmailAddress || this.state.locked || isLockScreenDisabled) {
      return;
    }

    this.setState(
      {
        locked: true,
      },
      () => {
        const navigationActions = getBoundNavigationsActions();
        navigationActions.toLock('modal', {
          onConfirm: async () => {
            this.setState(
              {
                locked: false,
              },
              () => {
                navigationActions.back(); // dismiss lock->user-legal modal stack

                requestRefreshStatus();
                onDynamicLink();
                this.backgroundedTimestamp = 0;
              },
            );
          },
        });
      },
    );
  };

  shouldFetchCurrentUser = (): boolean => Boolean(this.props.accessToken && !this.state.locked);

  // Call User.current call when app becomes "active" again.
  // If it receives 'forbidden' error,
  // SDKInstance.callInterceptor will invoke the callback that opens the Lock-screen.
  updateUserAndHealthCheck = async (): Promise<void> => {
    const { accessToken, selectedCountry, updateUserFromResponse } = this.props;

    if (!this.shouldFetchCurrentUser()) return;

    try {
      const userResponse = await User.current(Config.API_BASE_URL, accessToken, {
        countryCode: selectedCountry,
      });
      updateUserFromResponse(userResponse);
    } catch (e) {}
  };

  handleAppStateChange = (nextAppState: typeof AppState.currentState): void => {
    const { cancelStatusResolution, requestRefreshStatus } = this.props;
    const { locked } = this.state;

    switch (nextAppState) {
      case AppStates.Background:
        clearStatusTimeoutInterval();
        cancelStatusResolution();

        if (!locked) {
          this.backgroundedTimestamp = Date.now();
        }

        break;

      case AppStates.Active:
        if (this.shouldOpenLockScreen()) {
          this.openLockScreen();
        } else if (this.state.appState === AppStates.Background) {
          requestRefreshStatus();
          this.updateUserAndHealthCheck();
        }

        break;

      case AppStates.Inactive:
        if (!locked) {
          this.backgroundedTimestamp = Date.now();
        }

        break;

      default:
        break;
    }

    this.setState({
      appState: nextAppState,
    });
  };

  handleBackPress = (): boolean => {
    const { overlayActions, hasActiveOverlay } = this.props;

    if (this.state.locked) {
      BackHandler.exitApp();
      return true;
    }

    if (hasActiveOverlay) {
      overlayActions.hide();
      return true;
    }

    return false;
  };

  handleAppStateReset = () => {
    this.setState({ ...initialState });
  };

  render(): JSX.Element {
    return (
      <AppStateContext.Provider
        value={{ ...this.state, onAppStateReset: this.handleAppStateReset }}
      >
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}
export default connector(AppStateManager);
