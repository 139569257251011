import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ScrollView, StyleSheet } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { NavButton } from 'src/taxfix-components/src';
import { AddressDetailsKeys, AddressDetailsFieldWithValue } from 'src/types/address';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';

import DetailAddressView from '../components/detail-address-view';
import { SimpleQFLayout } from '../../components/simple-qf-layout';
import { ErrorKeywords } from '../../components/answers/shared/validators/types';
import { BodySecondaryText, Box, TitleText, SmallSecondaryText } from '../../components/core';
import { UtilProps, withUtil } from '../../hocs/with-utils';
import formatNumber from '../../i18n/formatNumber';
import { Product } from '../../types/payment';
import { testID } from '../../common/testID';
import { UseAnswers, withAnswersStore } from '../../hocs/with-answers-store';
import { userProfileActions } from '../../stores/modules/user-profile';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { IT_POSTAL_CODE_LENGTH } from '../../common/constants-it';
import {
  isEmptyInput,
  validateAddressField,
  validateCityField,
  validatePostalCodeField,
  getSanitizedInput,
} from '../../validations/italy-input-fields';
import { validateState } from '../../components/answers/shared/validators/address-validator-it';

type Props = {
  onNext?: () => void;
  product: Product;
  updateBillingAddress: typeof userProfileActions.updateBillingAddress;
  locale: string;
  selectedCountry: CountryCodes;
  util: UtilProps;
  backNavigationText: string;
  isLargeScreen: boolean;
  onPressBackNavigation: () => void;
} & UseAnswers &
  WithNavigation;

type BillingAddress = {
  street: string | null | undefined;
  city: string | null | undefined;
  postalCode: string | null | undefined;
  state?: string | null | undefined;
  number: string | null | undefined;
  numberExtension: string | null | undefined;
  addition: string | null | undefined;
};

type Error = {
  errorKey: string;
  limit?: number;
  chars?: string;
};

type State = {
  initialFields: AddressDetailsFieldWithValue[];
  currentAddress: BillingAddress | any;
  errors: Record<string, Error>;
};

const fieldStyle = StyleSheet.create({
  fieldBox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const mapStateToProps = (state: any) => {
  return {
    locale: settingsSelectors.selectedLocale(state),
    selectedCountry: settingsSelectors.selectedCountry(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBillingAddress: (billingAddress: any) =>
      dispatch(userProfileActions.updateBillingAddress(billingAddress)),
  };
};

class BillingAddressContainerUndecorated extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    const {
      '730/ResidenzaAnagrafica/Indirizzo': street,
      '730/ResidenzaAnagrafica/NumCivico': number,
      '730/ResidenzaAnagrafica/Comune': city,
      '730/ResidenzaAnagrafica/Cap': zipcode,
      '730/ResidenzaAnagrafica/Provincia': state,
    } = props.form730;
    this.state = {
      initialFields: [
        {
          id: AddressDetailsKeys.Street,
          editable: true,
          translationKey: 'it.billing-address.detail.label.street',
          value: getSanitizedInput(`${street} ${number}`),
        },
        {
          id: AddressDetailsKeys.City,
          editable: true,
          translationKey: 'it.billing-address.detail.label.city',
          value: getSanitizedInput(city),
        },
        {
          id: AddressDetailsKeys.PostalCode,
          editable: true,
          translationKey: 'it.billing-address.detail.label.zipcode',
          value: zipcode,
        },
        {
          id: AddressDetailsKeys.State,
          editable: true,
          translationKey: 'it.billing-address.detail.label.state',
          value: state,
        },
        {
          id: AddressDetailsKeys.Country,
          editable: false,
          translationKey: 'it.billing-address.detail.label.country',
          value: this.props.selectedCountry,
        },
      ],
      currentAddress: {
        street: getSanitizedInput(`${street} ${number}`),
        city: getSanitizedInput(city),
        postalCode: zipcode,
        state,
        number: null,
        numberExtension: null,
        addition: null,
        country: this.props.selectedCountry,
      },
      errors: {},
    };
  }

  componentDidMount = () => {
    this.setErrors();
  };

  getErrorKey = (isEmpty: boolean, field?: string) => {
    if (field === AddressDetailsKeys.PostalCode) {
      return isEmpty ? ErrorKeywords.REQUIRED : ErrorKeywords.DIGITS_ONLY;
    }

    return isEmpty ? ErrorKeywords.REQUIRED : ErrorKeywords.NOT_VALID_CHARS;
  };

  setErrors = () => {
    const { postalCode, street, city, state } = this.state.currentAddress;
    const postalCodeFieldErrors = validatePostalCodeField(postalCode);
    const addressFieldsErrors = validateAddressField(street);
    const cityFieldErrors = validateCityField(city);
    const errors: Record<string, Error> = {};

    const isPostalCodeEmpty = isEmptyInput(postalCode);
    const isStreetEmpty = isEmptyInput(street);
    const isCityEmpty = isEmptyInput(city);

    if (!postalCodeFieldErrors.isValid || isPostalCodeEmpty) {
      errors[AddressDetailsKeys.PostalCode] = {
        errorKey: this.getErrorKey(isPostalCodeEmpty, AddressDetailsKeys.PostalCode),
        limit: IT_POSTAL_CODE_LENGTH,
      };
    }

    if (!addressFieldsErrors.isValid || isStreetEmpty) {
      errors[AddressDetailsKeys.Street] = {
        errorKey: this.getErrorKey(isStreetEmpty),
        chars: addressFieldsErrors.validationErrors,
      };
    }

    if (!validateState(state)) {
      errors[AddressDetailsKeys.State] = {
        errorKey: ErrorKeywords.REQUIRED,
      };
    }

    if (!cityFieldErrors.isValid || isCityEmpty) {
      errors[AddressDetailsKeys.City] = {
        errorKey: this.getErrorKey(isCityEmpty),
        chars: cityFieldErrors.validationErrors,
      };
    }

    this.setState({
      errors,
    });
  };

  isContinueDisabled = () => {
    const requiredFields = ['street', 'city', 'postalCode', 'state'];
    return (
      Object.keys(this.state.errors).length !== 0 ||
      !requiredFields.every((field) => this.state.currentAddress[field])
    );
  };

  handleChange = (address: BillingAddress) => {
    const sanitizedAddress = {
      ...address,
      street: getSanitizedInput(address.street),
      city: getSanitizedInput(address.city),
    };
    this.setState(
      {
        currentAddress: sanitizedAddress,
      },
      () => {
        this.setErrors();
      },
    );
  };

  onNextNavigationDefault = () => this.props.navigationActions.toItalyPayment('screen');

  handleOnNext = () => {
    const { onNext, selectedCountry } = this.props;
    const { street, city, postalCode, state } = this.state.currentAddress;

    if (street && city && postalCode) {
      this.props.updateBillingAddress({
        street,
        city,
        zipcode: postalCode,
        state,
        countryCode: selectedCountry,
      });
    }
    if (onNext) {
      onNext();
    } else {
      this.onNextNavigationDefault();
    }
  };

  render() {
    const { backNavigationText, isLargeScreen, onPressBackNavigation, util, product } = this.props;
    const formattedProductPrice = formatNumber(util.intl, {
      formatStyle: 'currency',
      useGrouping: true,
      value: product.amount / 100,
    });

    return (
      <>
        {isLargeScreen && <NavButton text={backNavigationText} onPress={onPressBackNavigation} />}
        <ScrollView
          {...testID('scrollview.billing-address.screen')}
          keyboardShouldPersistTaps="handled"
        >
          <SimpleQFLayout
            headerTranslationKey="it.billing-address.title"
            continueButtonTranslationKey="it.billing-address.continue-button"
            onPressContinue={this.handleOnNext}
            isContinueDisabled={this.isContinueDisabled()}
            size="wide"
            centered
            hideProgressBar
          >
            <Box bottom={1} />
            <BodySecondaryText
              id="it.billing-address.description"
              values={{
                price: formattedProductPrice,
              }}
            />
            <Box bottom={4} />
            <TitleText id="it.billing-address.step-title" />
            <Box bottom={1} />
            <SmallSecondaryText id="it.billing-address.step-description" />
            <DetailAddressView
              language={this.props.locale}
              fields={this.state.initialFields}
              onChange={this.handleChange}
              customStyles={fieldStyle}
              errors={this.state.errors}
              countryCode={this.props.selectedCountry}
            />
          </SimpleQFLayout>
        </ScrollView>
      </>
    );
  }
}

export const ItalyBillingAddressContainer = compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withAnswersStore,
  withUtil,
  withNavigation,
)(BillingAddressContainerUndecorated);
