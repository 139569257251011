import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { PressEvent, LayoutEvent } from 'react-native/Libraries/Types/CoreEventTypes';

import { Text } from 'src/taxfix-components/src';
import { isWeb, isAndroid } from 'src/utils/platform';

import { CoreText, theme } from './core';

type Props = {
  onPress?: (evt: PressEvent) => void;
  onLayout?: (evt: LayoutEvent) => void;
  color?: string;
  size?: 'xsmall' | 'small' | 'large';
  symbol?: string;
  disabled?: boolean;
  withText?: boolean;
  informationText?: string;
};
const SIZE_LARGE = 25;
const SIZE_SMALL = 20;
const SIZE_XSMALL = 15;
const paddingLeft = isWeb ? 3 : isAndroid ? 2 : 0;

const labelStyle = {
  textAlign: 'center',
  color: theme.color.lightText,
  backgroundColor: 'transparent',
  fontFamily: 'CircularStd-Bold',
};

const styles: any = {
  container: {
    flexDirection: 'row',
  },
  large: {
    container: {
      width: SIZE_LARGE,
      height: SIZE_LARGE,
      borderRadius: SIZE_LARGE / 2,
      paddingLeft: paddingLeft,
    },
    label: {
      ...labelStyle,
      fontSize: 16,
      lineHeight: SIZE_LARGE,
    },
  },
  small: {
    container: {
      width: SIZE_SMALL,
      height: SIZE_SMALL,
      borderRadius: SIZE_SMALL / 2,
      paddingLeft: paddingLeft,
    },
    label: {
      ...labelStyle,
      fontSize: 12,
      lineHeight: SIZE_SMALL,
    },
  },
  xsmall: {
    container: {
      width: SIZE_XSMALL,
      height: SIZE_XSMALL,
      borderRadius: SIZE_XSMALL / 2,
      paddingLeft: paddingLeft,
    },
    label: {
      ...labelStyle,
      fontSize: 12,
      lineHeight: SIZE_XSMALL,
    },
  },
};
const InformationButton = React.forwardRef<Props, any>(
  (
    {
      onPress,
      onLayout,
      color = theme.color.primaryBackgroundText,
      size = 'large',
      symbol = '?',
      disabled = false,
      withText,
      informationText,
    },
    ref: any,
  ) => {
    const style = StyleSheet.create(styles[size]);
    return (
      <TouchableOpacity
        testID="test.infoButton"
        onPress={onPress}
        ref={ref}
        onLayout={onLayout}
        disabled={disabled}
        style={styles.container}
      >
        <View
          style={[
            style.container,
            {
              backgroundColor: color,
            },
          ]}
          hitSlop={{
            top: 25,
            bottom: 25,
            left: 25,
            right: 25,
          }}
        >
          <CoreText style={style.label}>{symbol} </CoreText>
        </View>
        {withText && (
          <Text
            variant="bodyXSBold"
            value={informationText}
            color="brand.brandGreen"
            marginLeft="10px"
          />
        )}
      </TouchableOpacity>
    );
  },
);
export { InformationButton };
