import { Pressable, useBreakpointValue } from 'native-base';
import React from 'react';

import { Image } from '../../general/Image';
import { Text } from '../../general/Text';
import { Container } from '../../layout/Container';

import { Props } from './types';

export const DocumentPreviewCard: React.FC<Props> = ({
  fileName,
  title,
  subtitle,
  imageUri,
  documentTypePreviewImage = 'cuPreview',
  badgeText,
  onPress,
  ...otherProps
}) => {
  const containerWidth = useBreakpointValue({ base: '100%', lg: '380px' });
  const containerHeight = useBreakpointValue({ base: '120px', md: '140px' });
  const imageWidth = useBreakpointValue({ base: '79px' });
  const imageHeight = useBreakpointValue({ base: '88px' });

  return (
    <Pressable
      borderWidth={1}
      borderColor="greytones.graphicsLight"
      alignItems="center"
      borderRadius="20px"
      flexDirection="row"
      width={containerWidth}
      height={containerHeight}
      py="16px"
      paddingLeft="16px"
      onPress={onPress}
      {...otherProps}
    >
      <Container mr="12px" width={imageWidth} height={imageHeight} borderRadius="10px">
        {imageUri ? (
          <Image
            source={{ uri: imageUri }}
            width={imageWidth}
            height={imageHeight}
            resizeMode="contain"
          />
        ) : (
          <Image
            name={documentTypePreviewImage}
            width={imageWidth}
            height={imageHeight}
            resizeMode="contain"
          />
        )}
      </Container>
      <Container mt={1}>
        {fileName && (
          <Text variant="bodyMBook" marginBottom="4px" value={fileName} numberOfLines={2} mr={1} />
        )}
        {title && <Text variant="bodyXSBook" color="greytones.graphicsDark" value={title} />}
        {subtitle && <Text variant="bodyXSBook" color="greytones.graphicsDark" value={subtitle} />}
      </Container>
      {badgeText && (
        <Container
          position="absolute"
          top="10px"
          right="10px"
          borderRadius={20}
          backgroundColor="secondaryCategory.skyHalf"
        >
          <Text
            variant="bodyXXSBook"
            fontSize="b_xxxs"
            color="secondaryFunctional.partnerBlueDark"
            value={badgeText}
            my="2px"
            mx="8px"
          />
        </Container>
      )}
    </Pressable>
  );
};
