import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';
import { useLogout } from 'src/hooks/logout-hook';
import { theme } from 'src/components/core';

type Props = ListRowComponentPropsType<any> & WithNavigation;

const Logout = (props: Props) => {
  const { showIcon = false } = props;
  const handleLogoutWeb = useLogout();

  const handleSelectItem = (item: MenuItemData) => {
    trackMenuItem(item);
    handleLogoutWeb({
      isAccountClosure: false,
    });
  };

  return props.children({
    hide: false,
    data: {
      iconName: showIcon ? 'icons.icon-logout' : undefined,
      tintColor: showIcon ? theme.color.primary : undefined,
      translationKey: menuItem.logout,
      testId: menuItem.logout,
      showArrow: true,
    },
    handleSelectItem,
  });
};

export const LogoutMenu = compose(withNavigation)(Logout);
