import * as React from 'react';

import { Box, TitleSecondaryText } from './core';

type Props = {
  section: {
    title: string;
  } & any;
};

function SectionListHeader({ section }: Props) {
  return (
    <Box left={2} bottom={1} top={1}>
      <TitleSecondaryText>{section.title}</TitleSecondaryText>
    </Box>
  );
}

export default SectionListHeader;
