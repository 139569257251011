import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Linking } from 'react-native';

import { zendeskService } from 'src/services/zendesk-service-initializer';
import {
  selectors as submissionSelectors,
  actions as submissionActions,
} from 'src/stores/modules/submission';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { ProductBundleValues, flagsQuestionIds } from 'src/common/constants-it';
import { isNative } from 'src/utils/platform';
import { AppDispatch } from 'src/stores/store/initial';
import { getBoundNavigationsActions } from 'src/routes/config-util';

import { selectors as remoteConfigFirebaseSelectors } from '../../stores/modules/remote-config-firebase';

import { useQuizmasterLight } from './use-quizmaster-light';
import { useItalyIntl } from './use-italy-intl';
import { useShowSideTabs } from './use-screen-size';

enum SupportMode {
  ShowGuidedFlowSupportOptions = 'ShowGuidedFlowSupportOptions',
  OpenLiveChat = 'OpenLiveChat',
  OpenEmail = 'OpenEmail',
}

export type UseChatPopupProps = {
  handleOpenChatPopup: () => void;
  supportMode: SupportMode;
};

export const useChatPopup = (): UseChatPopupProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [supportMode, setSupportMode] = useState<SupportMode>(SupportMode.OpenLiveChat);
  const { getTranslationText } = useItalyIntl();
  const showSideTabs = useShowSideTabs();

  const { mobEnabled: phoneSupportEnabledMobile, webEnabled: phoneSupportEnabledWeb } = useSelector(
    remoteConfigFirebaseSelectors.getPhoneSupportByCountry,
  );
  const { mobEnabled: chatEnabledMobile, webEnabled: chatEnabledWeb } = useSelector(
    remoteConfigFirebaseSelectors.getChatSupportByCountry,
  );
  const isLiveChatEnabled = isNative ? chatEnabledMobile : chatEnabledWeb;
  const isPhoneSupportEnabled = isNative ? phoneSupportEnabledMobile : phoneSupportEnabledWeb;

  const isPaidSubmission = useSelector(submissionSelectors.isPaidSubmission);
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
  const isGuidedFlow =
    quizmasterLight[flagsQuestionIds.productBundleSelection]?.answer === ProductBundleValues.guided;

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const userId = useSelector(userAuthSelectors.getUserId);

  useEffect(() => {
    if (!isPaidSubmission) {
      dispatch(submissionActions.updatePaymentStatus(accessToken, userId as number));
    }
  }, [accessToken, userId, dispatch, isPaidSubmission]);

  useEffect(() => {
    if (isPaidSubmission && isGuidedFlow && (isLiveChatEnabled || isPhoneSupportEnabled)) {
      setSupportMode(SupportMode.ShowGuidedFlowSupportOptions);
    } else if (!isLiveChatEnabled) {
      setSupportMode(SupportMode.OpenEmail);
    }
  }, [isPaidSubmission, isGuidedFlow, isLiveChatEnabled, isPhoneSupportEnabled]);

  const showContactOptions = React.useCallback(() => {
    if (showSideTabs) {
      dispatch(overlayActions.show('ChatPopup'));
    } else {
      const navigationActions = getBoundNavigationsActions();
      navigationActions.toItalyChat('screen');
    }
  }, [dispatch, showSideTabs]);

  const openLiveChat = React.useCallback(() => {
    zendeskService.openChat();
  }, []);

  const handleSendEmail = React.useCallback(() => {
    const defaultSubject = getTranslationText('it.customer-support-email.default-subject');
    const link = `mailto:support+info@taxfix.it?subject=${defaultSubject}`;
    Linking.canOpenURL(link)
      .then((supported) => {
        if (supported) {
          Linking.openURL(link);
        }
      })
      .catch(() => {});
  }, [getTranslationText]);

  const handleOpenChatPopup = React.useCallback(() => {
    // dismissing all currently opened overlay - the action handleOpenChatPopup
    // can be called within another overlay
    dispatch(overlayActions.hide());

    switch (supportMode) {
      case SupportMode.ShowGuidedFlowSupportOptions:
        showContactOptions();
        break;
      case SupportMode.OpenEmail:
        handleSendEmail();
        break;
      default:
        openLiveChat();
    }
  }, [showContactOptions, openLiveChat, handleSendEmail, supportMode, dispatch]);

  return { handleOpenChatPopup, supportMode };
};
