import { Documents } from '@taxfix/types';

import { getNavigationActions } from 'src/routes/config-util';
import { selectors as prefillSelectors } from 'src/stores/modules/prefill';
import { PrefillStates } from 'src/types/prefills';
import {
  StatusMiddleware,
  StatusSkipFunction,
  StatusStepRedirect,
} from 'src/stores/modules/status';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

export const checkIdDocument: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  // if users have not yet uploaded ID document,
  // we still want to show them the prefill documents result screen again
  // then the expert review screen -> ID upload step
  const status = prefillSelectors.getStatus(getState());
  switch (status) {
    // Status is null when prefill has not been created yet or rejected
    case PrefillStates.Rejected:
    case null: {
      // redirect users to PrefillDocumentsResult screen (with prefill documents)
      dispatch(breakAction());
      break;
    }

    // After creating pre-fill (with ID) we're not blocking the user.
    case PrefillStates.Created:
    case PrefillStates.Reviewed:
    case PrefillStates.Processing:
    case PrefillStates.Successful: {
      next();
      break;
    }

    case PrefillStates.Failed:
    default: {
      // All above states as well as any other not known state
      dispatch(breakAction());
      break;
    }
  }
};

export const redirectIDDocumentUpload: StatusStepRedirect = (state, dispatch) => (navigation) => {
  const year = settingsSelectors.selectedYear(state);
  navigation.reset({
    index: 0,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toPrefillDocumentUpload('screen', {
        prefillDocumentType: Documents.NonReceiptTypes.Id,
        year,
      }),
    ],
  });
};

export const skipIfIDUploaded: StatusSkipFunction = async (getState) => {
  const status = prefillSelectors.getStatus(getState());
  if (status === null) {
    return false;
  } else {
    return true;
  }
};
