import React, { useState } from 'react';
import { TextInputProps } from 'react-native';
import { View, useTheme } from 'native-base';

// Screens
import { QuestionScreen } from './screens/question-screen';
import { ThanksScreen } from './screens/thanks-screen';
import { NeedHelpScreen } from './screens/need-help-screen';

export type ArticleRatingProps = TextInputProps & {
  onRateSelected: (rate: number) => void;
  onContactUsSelected: () => void;
  ratingTitle: string;
  thanksTitle: string;
  needHelpTitle: string;
  sendMessageText: string;
};

export const ArticleRating = ({
  onRateSelected,
  onContactUsSelected,
  ratingTitle,
  thanksTitle,
  needHelpTitle,
  sendMessageText,
}: ArticleRatingProps) => {
  const [rate, setRate] = useState<number | null>(null);
  const { colors } = useTheme();

  const handleRateSelected = (rateSelected: number) => {
    setRate(rateSelected);
    onRateSelected(rateSelected);
  };

  const getScreen = () => {
    switch (rate) {
      case null:
        return QuestionScreen({ onRateSelected: handleRateSelected, ratingTitle });
      case 0:
      case 1:
        return ThanksScreen({ thanksTitle });
      case 2:
        return NeedHelpScreen({
          needHelpTitle,
          sendMessageText,
          onNeedHelp: onContactUsSelected,
        });

      default:
        return QuestionScreen({ onRateSelected: handleRateSelected, ratingTitle });
    }
  };

  return (
    <View
      backgroundColor={colors.greytones.background}
      borderRadius="10px"
      flexDirection="column"
      alignItems="center"
      padding="16px"
      minHeight="80px"
    >
      {getScreen()}
    </View>
  );
};
