import { isNull, isUndefined } from 'lodash';
import React from 'react';

import { Container } from '../../layout/Container';
import { RadioOptionButton } from '../RadioOptionButton';
import { RadioGroupValue } from '../RadioOptionButton/types';

import { Props } from './types';

export const RadioGroup: React.FC<Props> = ({
  options,
  value,
  hasBorderInTheBottom = false,
  defaultValue,
  onChange,
  ...otherProps
}): JSX.Element => {
  const [selected, setSelected] = React.useState(defaultValue ?? value);

  React.useEffect(() => {
    if (!(isNull(value) || isUndefined(value))) {
      setSelected(value);
    }
  }, [value]);

  const isSelected = (_value: RadioGroupValue) => _value === selected;
  const handlePress = (_value: RadioGroupValue) => {
    onChange?.(_value);
    setSelected(_value);
  };

  return (
    <Container {...otherProps}>
      {options.map((option, index) => (
        <RadioOptionButton
          key={option.value}
          option={option}
          isSelected={isSelected(option.value)}
          onPress={handlePress}
          hasBorderBottom={hasBorderInTheBottom && index === options.length - 1}
        />
      ))}
    </Container>
  );
};
