import createSensitiveStorage from 'redux-persist-sensitive-storage';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { getItem, setItem } from '../../utils/asyncStorage';

type SecureStorage = {
  getItem: (key: string, callback?: () => any) => Promise<any>;
  setItem: (key: string, value: any, callback?: () => any) => Promise<void>;
  removeItem: (key: string, callback?: () => any) => Promise<void>;
  getAllKeys: (callback?: () => any) => Promise<string[]>;
};

class SelfClearingSecureStorage {
  storage: SecureStorage;

  storageKey: string;

  isCleared = false;

  constructor(storageKey: string, options: Record<string, any>) {
    this.storage = createSensitiveStorage(options);
    this.storageKey = `SelfClearingSecureStorage-${storageKey}`;
    this.clearStorageOnFreshInstall();
  }

  clearStorageOnFreshInstall = async () => {
    try {
      const isStorageCleared = await getItem(this.storageKey);

      if (isStorageCleared !== true) {
        const allKeys = await this.storage.getAllKeys();
        await Promise.all(
          allKeys.map(async (key) => {
            await this.storage.removeItem(key);
          }),
        );
        await setItem(this.storageKey, true);
      }

      this.isCleared = true;
    } catch (err) {
      logger.warn(err, {
        message: 'Clearing secure storage failed',
      });
    }
  };

  getItem = async (key: string, callback?: () => any) => {
    if (!this.isCleared) await this.clearStorageOnFreshInstall();
    return this.storage.getItem(key, callback);
  };

  setItem = async (key: string, value: any, callback?: () => any) => {
    if (!this.isCleared) await this.clearStorageOnFreshInstall();
    return this.storage.setItem(key, value, callback);
  };

  removeItem = async (key: string, callback?: () => any) => {
    if (!this.isCleared) await this.clearStorageOnFreshInstall();
    return this.storage.removeItem(key, callback);
  };

  getAllKeys = async (callback?: () => any) => {
    if (!this.isCleared) await this.clearStorageOnFreshInstall();
    return this.storage.getAllKeys(callback);
  };
}

export const createSelfClearingSecureStorage: (
  storageKey: string,
  options: Record<string, any>,
) => SecureStorage = (storageKey, options) => new SelfClearingSecureStorage(storageKey, options);
