import { Box, useBreakpointValue, useTheme } from 'native-base';
import React from 'react';
import HTML from 'react-native-render-html';

import { Icon, Text, TextLink, icons } from 'src/taxfix-components/src';
import { theme } from 'src/components/core';

import { ToastBannerLevel, Props } from './types';

export const Toast: React.FC<Props> = ({
  text,
  linkText,
  onLinkPress,
  iconName,
  iconSize = 25,
  iconColor,
  contentColor,
  level = ToastBannerLevel.Reminder,
  isHtmlContent,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();
  const textPaddingRight = useBreakpointValue({ base: '20px', md: '39px', lg: '39px' });

  const resolveContentColor = (level: ToastBannerLevel) => {
    switch (level) {
      case ToastBannerLevel.Info:
        return theme.color.partnerBackgroundText;
      default:
        return colors.secondaryFeedback.errorRed;
    }
  };

  const resolveBgColor = (level: ToastBannerLevel) => {
    switch (level) {
      case ToastBannerLevel.Info:
        return colors.secondaryCategory.skyHalf;
      default:
        return colors.secondaryCategory.orangeHalf;
    }
  };

  return (
    <Box
      bg={resolveBgColor(level)}
      pl={4}
      pr={4}
      py={17}
      mt={2}
      borderRadius={10}
      flexDirection="row"
      justifyContent="center"
      {...otherProps}
    >
      <Box mr="10px">
        <Icon
          color={iconColor || resolveContentColor(level)}
          size={iconSize}
          path={icons[iconName]}
        />
      </Box>
      <Box flexDir="column" width="100%" paddingRight={textPaddingRight}>
        {isHtmlContent ? (
          <HTML
            source={{ html: `<span>${text}</span>` }}
            tagsStyles={{
              span: {
                color: resolveContentColor(level),
              },
            }}
          />
        ) : (
          <Text
            value={text}
            variant="bodySBook"
            color={contentColor || resolveContentColor(level)}
          />
        )}
        {linkText && onLinkPress && (
          <TextLink
            text={linkText}
            variant="bodySBold"
            color={resolveContentColor(level)}
            onPress={onLinkPress}
            isUnderlined
          />
        )}
      </Box>
    </Box>
  );
};
