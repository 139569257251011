import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import { getAnswer, updateAnswer } from 'src/stores-legacy/helpers';
import {
  flagsQuestionIds,
  prefillQuestionIds,
  questionFlowCategories,
} from 'src/common/constants-it';
import { StatusMiddleware, StatusStepRedirect } from 'src/stores/modules/status';

export const checkTaxIdQF: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  const userTaxId = getAnswer(getState(), prefillQuestionIds.taxId);
  if (userTaxId) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const redirectTaxIdQF: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toQuizMaster('screen', {
        root: questionFlowCategories.taxId,
        editing: false,
        backNavigationText: 'it.tax-id-question-flow.come-back-button.text',
        onNext: () => {
          updateAnswer(flagsQuestionIds.flowStarted, true, state);
          getBoundNavigationsActions().toSPIDConsentSign('screen');
        },
      }),
    ],
  });
};
