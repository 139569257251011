import React from 'react';
import { ScrollView, View } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { NavButton } from 'src/taxfix-components/src';
import {
  DisplayPrimaryText,
  SmallPrimaryText,
  List,
  Section,
  theme,
  BodyPrimaryText,
  Box,
} from 'src/components/core';
import { testID } from 'src/common/testID';
import {
  getImprintConfigByCountry,
  getMenuConfigByCountry,
} from 'src/screens/account-menu/menu-items-config';
import { AccountFooterList } from 'src/screens/account-menu/account-footer-list';
import { MenuConfig, MenuTypes } from 'src/screens/account-menu/types';
import { navigationActions } from 'src/routes/intl/config-common';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';

type Props = {
  email: string;
  country: CountryCodes;
};
type MixedMenuProps = {
  menuConfigs: Array<MenuConfig>;
};

const MixedMenuDisplay = ({ menuConfigs }: MixedMenuProps): any => {
  return menuConfigs.map(({ type, heading, items }: MenuConfig, menuIndex: number) => {
    return (
      <View
        key={menuIndex}
        style={{
          marginBottom: 32,
        }}
      >
        {type === MenuTypes.LIST && (
          <View>
            <View
              style={{
                marginBottom: 16,
              }}
            >
              <SmallPrimaryText
                id={heading}
                color={theme.color.greytonesText}
                textTransform="uppercase"
                style={{
                  fontSize: 10,
                  letterSpacing: 1,
                }}
              />
            </View>
            <List
              items={items}
              rowStyle={{
                row: {
                  minHeight: 56,
                },
                arrow: {
                  tintColor: theme.color.greytonesText,
                },
                text: {
                  fontWeight: '400',
                },
              }}
              listRowComponentProps={{
                showIcon: true,
                showInfo: false,
              }}
            />
          </View>
        )}
        {type === MenuTypes.FOOTER && (
          <View>
            <AccountFooterList menuItems={items} />
            <View
              style={{
                marginTop: -24,
              }}
            />
          </View>
        )}
      </View>
    );
  });
};

export const Account = ({ email, country }: Props) => {
  const MENUS_CONFIGS = getMenuConfigByCountry();
  const imprintConfig = getImprintConfigByCountry(country);
  const showSideTabs = useShowSideTabs();
  const { getTranslationText } = useItalyIntl();
  return (
    <>
      {showSideTabs && (
        <Box left={1} bottom={2}>
          <NavButton
            onPress={navigationActions.back}
            text={getTranslationText('account.back-button.title')}
          />
        </Box>
      )}

      <Box alignHorizontally="center">
        <Section size="wide" centered>
          <ScrollView {...testID('account-scrollview')}>
            <View
              style={{
                paddingTop: 8,
                paddingBottom: 16,
                paddingLeft: 24,
                paddingRight: 24,
                marginBottom: 16,
              }}
            >
              <DisplayPrimaryText
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  lineHeight: 28,
                }}
                id="account.heading.title"
              />
              <BodyPrimaryText>{email}</BodyPrimaryText>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'space-between',
                paddingLeft: 24,
                paddingRight: 24,
                marginBottom: 48,
              }}
            >
              <MixedMenuDisplay menuConfigs={MENUS_CONFIGS} />
              {imprintConfig.enabled && (
                <View>
                  <SmallPrimaryText
                    id="account.footer.company.imprint"
                    color={theme.color.greytonesText}
                  />
                </View>
              )}
            </View>
          </ScrollView>
        </Section>
      </Box>
    </>
  );
};
