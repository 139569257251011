// Minimum income threshold for the application of arbeitslohn tax
const bruttoArbeitslohnThreshold: Record<string, Record<'single' | 'joint', number>> = {
  '2019': {
    single: 9168,
    joint: 18336,
  },
  '2018': {
    single: 9000,
    joint: 18000,
  },
  '2017': {
    single: 8820,
    joint: 17640,
  },
  '2016': {
    single: 8652,
    joint: 17304,
  },
};

export const getBruttoArbeitslohnThreshold = (year: number, isJA = false): number =>
  bruttoArbeitslohnThreshold[`${year}`][isJA ? 'joint' : 'single'];
