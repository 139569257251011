type RetryPromiseOptions = {
  retriesLeft: number;
  interval: number;
  exponential: true;
  onRetry: () => void;
};

const defaultOptions: RetryPromiseOptions = {
  retriesLeft: 5,
  interval: 1000,
  exponential: true,
  onRetry: () => {},
};

/**
 * Tries to resolve a promise a number of times (retriesLeft). Throws an error
 * if all attempts failed.
 *
 * @param fn Promise to retry until it is resolved
 * @param options RetryPromiseOptions
 * @param options.retriesLeft Maximum number of retries
 * @param options.interval Minimum time between retries
 * @param options.exponential If true, interval is calculated exponentially between retries
 * @param optinos.onRetry Callback fires on retrying
 *
 * @typeparam T the result type of the resolved Promise.
 *
 * @returns Promise<T>
 */
async function retryPromise<T>(
  fn: () => Promise<T>,
  options: Partial<RetryPromiseOptions> = {},
): Promise<T> {
  const { retriesLeft, interval, exponential, onRetry } = { ...defaultOptions, ...options };

  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval));
      onRetry();
      return retryPromise(fn, {
        retriesLeft: retriesLeft - 1,
        interval: exponential ? interval * 2 : interval,
        exponential,
        onRetry,
      });
    }

    throw new Error(`Maximum retries reached for promise, last reject reason: ${error}`);
  }
}

export default retryPromise;
