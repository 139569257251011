import * as React from 'react';
import { Answer, Inputs, TreeNode, PlausibilityErrors } from '@taxfix/quizmaster/dist/types';
import { CountryCodes } from '@taxfix/types';

import { Container } from 'src/taxfix-components/src';
import resolveITAnswerFromType from 'src/_italy/quizmaster/components/answer-type-resolver';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { translationKeys } from 'src/i18n';
import PlausibilityCheck from 'src/containers/plausibility-check';
import { testID } from 'src/common/testID';
import { AnswerComponentProps } from 'src/components/answers/types';
import { Text } from 'src/components/core';

export type Props = {
  question: TreeNode;
  inputs?: Inputs;
  answerValue: Answer | null | undefined;
  onAnswer: (node: TreeNode, answer: Answer) => void;
  onChange?: (value: Answer) => void;
  editing?: boolean;
  year: number;
  country?: CountryCodes;
  isLoading?: boolean;
  color?: string;
  shouldShowSingleQuestionPlausibilityCheck?: boolean;
  multipleWarnings?: PlausibilityErrors[];
  withoutTruncation?: boolean;
};

type ResponseProps = WithNavigation & Props;

class ResponseBase extends React.PureComponent<ResponseProps> {
  handleOnPressInfo = (warning: PlausibilityErrors): void => {
    this.props.navigationActions.toInformation('modal', {
      translationKey: warning.infoTranslationKey,
      isMarkdown: true,
    });
  };

  render() {
    const {
      question,
      inputs,
      answerValue,
      onAnswer,
      onChange,
      editing,
      year,
      country,
      isLoading,
      color,
      shouldShowSingleQuestionPlausibilityCheck,
      multipleWarnings,
      withoutTruncation,
    } = this.props;

    if (question.response == null) {
      return null;
    }

    const answerTypeProperties = question.response.properties || {};
    const answerTypeMetadata = question.response.metadata || {};
    const AnAnswerComponent: React.ComponentType<AnswerComponentProps> = resolveITAnswerFromType(
      question.response.type,
      question.id,
    );
    const questionText = <Text id={translationKeys(question.id).long} values={inputs} />;
    const answerType = question.response ? question.response.type : 'unknown';
    const WithPlausibilityChecksAnswer = (
      <PlausibilityCheck value={answerValue} onAnswer={onAnswer} year={year}>
        {({ handleOnAnswer, warnings }) => (
          <AnAnswerComponent
            key={(question && question.id) || 'generic'}
            autoFocus={isLoading !== undefined ? !isLoading : true}
            {...answerTypeProperties}
            {...answerTypeMetadata}
            question={question}
            questionText={questionText}
            onAnswer={handleOnAnswer}
            onChange={onChange}
            value={answerValue}
            editing={editing}
            year={year}
            country={country}
            color={color}
            warnings={warnings}
            onPressInfo={this.handleOnPressInfo}
            withoutTruncation={withoutTruncation}
          />
        )}
      </PlausibilityCheck>
    );

    const PlainAnswer = (
      <AnAnswerComponent
        key={(question && question.id) || 'generic'}
        autoFocus={isLoading !== undefined ? !isLoading : true}
        {...answerTypeProperties}
        {...answerTypeMetadata}
        question={question}
        questionText={questionText}
        onAnswer={onAnswer}
        onChange={onChange}
        value={answerValue}
        editing={editing}
        year={year}
        country={country}
        color={color}
        warnings={multipleWarnings}
        withoutTruncation={withoutTruncation}
        values={inputs}
      />
    );
    return (
      <Container {...testID(answerType)}>
        {shouldShowSingleQuestionPlausibilityCheck && (multipleWarnings as any)?.length === 0
          ? WithPlausibilityChecksAnswer
          : PlainAnswer}
      </Container>
    );
  }
}

const Response = withNavigation(ResponseBase);

export { Response };
