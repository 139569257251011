import { useTheme } from 'native-base';
import { useSelector } from 'react-redux';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { CardProps, CardSetting, FlowStage } from './use-dashboard';

type Props = {
  getPreseasonCardContent: (card: CardSetting) => CardProps;
};

export const usePreseasonDashboardContent = (): Props => {
  const { getTranslationText } = useItalyIntl();

  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;
  const taxSeason = selectedYear + 1;
  const { colors } = useTheme();

  const getPreseasonCardContent = (card: CardSetting): CardProps => {
    const collapsableTitle = getTranslationText('it.dashboard.preseason-card.collapsable-title', {
      taxSeason,
    });

    switch (card.stage) {
      case FlowStage.preseasonCUDownloaded:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard.preseason-end-card.title'),
          subtitle: getTranslationText('it.dashboard.preseason-end-card.subtitle'),
          buttonText: getTranslationText('it.dashboard.preseason-end-card.button-title'),
          sourceImageName: 'certificazioneUnica',
          titleColor: colors.greytones.white,
          bgColor: colors.brand.brandGreen,
          buttonVariant: 'primaryWhite',
        };
      default:
        return {
          collapsableTitle,
          title: getTranslationText('it.dashboard.preseason-start-card.title'),
          subtitle: getTranslationText('it.dashboard.preseason-start-card.subtitle'),
          buttonText: getTranslationText('it.dashboard.preseason-start-card.button-title'),
          sourceImageName: 'certificazioneUnica',
        };
    }
  };

  return { getPreseasonCardContent };
};
