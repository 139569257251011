import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import Overlay from 'src/components/overlay';
import { Button, Container, Image, Text } from 'src/taxfix-components/src';

export type Props = Partial<
  Record<
    'primaryAction' | 'secondaryAction',
    {
      buttonName: string;
      handler: () => void;
      translationKey: string;
    }
  >
> & {
  translationKeys: Partial<Record<'title' | 'description', string>>;
};

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

const SCREEN_NAME = 'SPIDError';

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

export const SPIDError: React.FC<Props> = ({ primaryAction, secondaryAction, translationKeys }) => {
  const { getTranslationText } = useItalyIntl();

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: SCREEN_NAME,
    });
  }, []);

  return (
    <Overlay disableBackgroundTouch style={styles.overlay} appearFromTop>
      {() => (
        <Container
          borderRadius={1}
          maxWidth="600px"
          maxHeight="600px"
          backgroundColor="white"
          p={{ base: 2, md: 5 }}
          m={1}
        >
          <Image name="errorBarrier" alignSelf="center" width={100} />

          {translationKeys?.title && (
            <Text
              value={getTranslationText(translationKeys.title)}
              variant="titleMBold"
              textAlign="center"
              color="greytones.textDefault"
              mb={2}
              mx={1}
            />
          )}

          {translationKeys?.description && (
            <Text
              value={getTranslationText(translationKeys.description, {
                bold: getBoldText,
              })}
              variant="bodyMBook"
              textAlign="center"
              color="greytones.textLight"
            />
          )}

          {primaryAction && (
            <Button
              variant="primary"
              title={getTranslationText(primaryAction.translationKey)}
              onPress={() => {
                Analytics.log(AnalyticsEvent.buttonPressed, {
                  screenName: SCREEN_NAME,
                  buttonName: primaryAction.buttonName,
                });
                primaryAction.handler();
              }}
              width={{ base: 'xl', md: 'l' }}
              mt={[1, 1, 4]}
            />
          )}

          {secondaryAction && (
            <Button
              variant="tertiary"
              title={getTranslationText(secondaryAction.translationKey)}
              onPress={() => {
                Analytics.log(AnalyticsEvent.buttonPressed, {
                  screenName: SCREEN_NAME,
                  buttonName: secondaryAction.buttonName,
                });

                secondaryAction.handler();
              }}
              width={{ base: 'xl', md: 'l' }}
              mt={1}
            />
          )}
        </Container>
      )}
    </Overlay>
  );
};
