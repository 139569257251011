import { Dispatch } from 'redux';

import {
  selectors as requiredDocumentsSelectors,
  actions as requiredDocumentsActions,
  RootState as DocumentsRootState,
} from 'src/stores/modules/required-documents';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as submissionSelectors } from 'src/stores/modules/submission';
import {
  StatusMiddleware,
  SectionResolverFunction,
  StatusSkipFunction,
  StatusStepNavigationData,
  StatusStepSection,
  NavigationLink,
} from 'src/stores/modules/status';
import { getNavigationActions, getBoundNavigationsActions } from 'src/routes/config-util';
import {
  flagsQuestionIds,
  incomeDocumentCategories,
  expenseDocumentCategories,
  DocumentUploadMode,
} from 'src/common/constants-it';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { getAnswers } from 'src/stores/hooks/answers-stores-hook';
import {
  getQuestionStoreByYearAndCountry,
  getAnswer,
  updateAnswer,
} from 'src/stores-legacy/helpers';
import { getDefaultTaxYear } from 'src/services/country-and-year';

// utils functions to required-documents

const getDocumentsUploadStatus = (
  userDocList: string[],
  state: DocumentsRootState,
  categorySelected: DocumentUploadMode,
): boolean => {
  const documentUploadCategories = userDocList.filter((category) =>
    categorySelected === DocumentUploadMode.INCOME_STEP
      ? incomeDocumentCategories.includes(category)
      : expenseDocumentCategories.includes(category),
  );
  const areTailoredDocumentsUploaded =
    requiredDocumentsSelectors.hasUploadedEachTailoredCategory(state)(documentUploadCategories);
  return areTailoredDocumentsUploaded;
};

const getDocUploadStatus = async (
  dispatch: Dispatch<any>,
  getState: () => any,
  categorySelected: DocumentUploadMode,
): Promise<{ areTailoredCategoriesCompleted: boolean; areTailoredDocumentsUploaded: boolean }> => {
  const taxYear = settingsSelectors.selectedYear(getState()) || getDefaultTaxYear();
  const userId = userAuthSelectors.getUserId(getState());

  if (!taxYear || !userId) {
    throw new Error(
      'retreving required-documents needs user ID, user accessToken, and selected year',
    );
  }

  const accessToken = userAuthSelectors.getAccessToken(getState());
  await dispatch(requiredDocumentsActions.updateRequiredDocuments(accessToken, userId));
  const questionStore = getQuestionStoreByYearAndCountry(getState());

  const { tailoredCategories: userDocList } = getAnswers(questionStore);

  const areTailoredCategoriesCompleted = requiredDocumentsSelectors.hasUploadedEachTailoredCategory(
    getState(),
  )(userDocList);

  const areTailoredDocumentsUploaded = getDocumentsUploadStatus(
    userDocList,
    getState(),
    categorySelected,
  );

  return {
    areTailoredCategoriesCompleted,
    areTailoredDocumentsUploaded,
  };
};

const getSectionStatus = (getState: () => any, categorySelected: DocumentUploadMode) => {
  const taxYear = settingsSelectors.selectedYear(getState()) || getDefaultTaxYear();
  const section =
    categorySelected === DocumentUploadMode.INCOME_STEP
      ? flagsQuestionIds.incomeUploadSkipped
      : flagsQuestionIds.expensesUploadSkipped;

  const areDocumentsConfirmedOnResubmission = getAnswer(
    getState(),
    flagsQuestionIds.areDocumentConfirmedOnResubmission,
  );

  const isSectionInSkipStatus = getAnswer(getState(), section);
  const isResubmission = submissionSelectors.isResubmissionByYear(getState(), taxYear);
  return { isResubmission, isSectionInSkipStatus, areDocumentsConfirmedOnResubmission };
};

// ------------------------------------------
// INCOME DOCUMENTS UPLOAD SCREEN
// ------------------------------------------

export const checkIncomeDocUploadCompleteFlow: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const { areTailoredDocumentsUploaded } = await getDocUploadStatus(
      dispatch,
      getState,
      DocumentUploadMode.INCOME_STEP,
    );

    const customNavigationLink: NavigationLink = {
      action: 'toRequiredDocumentsDigitalCaf',
      config: 'screen',
      params: {
        italyWebFooterButtonTitleId: 'status.it.start.button.title',
        onNext: () => {
          getBoundNavigationsActions().reset({
            index: 0,
            actions: [getNavigationActions().toStatus('screen')],
          });
        },
        screenMode: DocumentUploadMode.INCOME_STEP,
      },
    };

    if (areTailoredDocumentsUploaded) {
      next();
    } else {
      const customNavigation: StatusStepNavigationData = {
        title: 'status.it.navigation.default',
        link: {
          ...customNavigationLink,
        },
      };

      const customSection = {
        ...config.section?.new,
        navigation: {
          title: 'status.it.navigation.default',
          link: {
            ...customNavigationLink,
          },
        },
      } as StatusStepSection;

      dispatch(breakAction(customNavigation, customSection));
    }
  } catch (e) {
    dispatch(failureAction(`error status income-upload: ${(e as Error).message}`));
    return false;
  }
};

export const addCompleteIncomeUploadStatus: SectionResolverFunction = (
  getState,
  sections,
  index,
) => {
  const questionStore = getQuestionStoreByYearAndCountry(getState());
  const { tailoredCategories: userDocList } = getAnswers(questionStore);
  const areTailoredDocumentsUploaded = getDocumentsUploadStatus(
    userDocList,
    getState(),
    DocumentUploadMode.INCOME_STEP,
  );

  const { isResubmission, isSectionInSkipStatus, areDocumentsConfirmedOnResubmission } =
    getSectionStatus(getState, DocumentUploadMode.INCOME_STEP);

  const customSectionNavigationLink: NavigationLink = {
    action: 'toRequiredDocumentsDigitalCaf',
    config: 'screen',
    params: {
      onNext: () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      },
      screenMode: DocumentUploadMode.INCOME_STEP,
    },
  };

  const sectionLink: NavigationLink = {
    action: 'toRequiredDocumentsDigitalCaf',
    config: 'screen',
    params: {
      onNext: () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      },
      screenMode: DocumentUploadMode.INCOME_STEP,
    },
  };

  if (isResubmission && !areDocumentsConfirmedOnResubmission) {
    return {
      index,
      ...sections.todo,
      navigation: {
        id: 'it.digital-caf.required-documents.upload',
        link: {
          ...sectionLink,
        },
      },
      warning: true,
    };
  }

  if (isSectionInSkipStatus) {
    return {
      index,
      ...sections.todo,
      navigation: {
        link: { ...customSectionNavigationLink },
      },
      skipped: !areTailoredDocumentsUploaded,
    };
  } else if (areTailoredDocumentsUploaded) {
    return {
      index,
      ...sections.complete,
      navigation: {
        link: { ...customSectionNavigationLink },
      },
    };
  }

  return null;
};

export const hasSkippedIncomeUploadSection: StatusSkipFunction = async (getState) => {
  return getAnswer(getState(), flagsQuestionIds.incomeUploadSkipped);
};

// ------------------------------------------
// EXPENSES DOCUMENTS UPLOAD SCREEN
// ------------------------------------------

export const checkExpenseDocUploadCompleteFlow: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const { areTailoredCategoriesCompleted, areTailoredDocumentsUploaded } =
      await getDocUploadStatus(dispatch, getState, DocumentUploadMode.EXPENSES_STEP);

    const hasExpenseUploadScreenSeen = getAnswer(
      getState(),
      flagsQuestionIds.expenseUploadScreenSeen,
    );

    if (!areTailoredCategoriesCompleted) {
      dispatch(requiredDocumentsActions.resetConsentAndConfirmation());
      updateAnswer(flagsQuestionIds.documentsUploadConsent, false, getState());
    }

    if (areTailoredDocumentsUploaded && hasExpenseUploadScreenSeen) {
      next();
    } else {
      const resetToStatusScreen = () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      };

      const customNavigationLink: NavigationLink = {
        action: 'toRequiredDocumentsDigitalCaf',
        config: 'screen',
        params: {
          onNext: resetToStatusScreen,
          screenMode: DocumentUploadMode.EXPENSES_STEP,
        },
      };

      const customNavigation: {
        title: string;
        tracking: { eventName: AnalyticsEvent };
        link?: any;
      } = {
        title: 'status.it.navigation.categoryNotComplete',
        tracking: {
          eventName: AnalyticsEvent.receiptsUploadInitiated,
        },
        link: { ...customNavigationLink },
      };

      const customSection = {
        ...config.section?.new,
        navigation: {
          link: {
            ...customNavigationLink,
          },
        },
      } as StatusStepSection;

      if (!customSection) throw new Error('No receipt section found');
      dispatch(breakAction(customNavigation, customSection));
    }

    return true;
  } catch (e) {
    dispatch(failureAction(`error status required-documents: ${(e as Error).message}`));
    return false;
  }
};

export const addCompleteExpenseUploadStatus: SectionResolverFunction = (
  getState,
  sections,
  index,
) => {
  const { isResubmission, isSectionInSkipStatus, areDocumentsConfirmedOnResubmission } =
    getSectionStatus(getState, DocumentUploadMode.EXPENSES_STEP);

  const sectionLink: NavigationLink = {
    action: 'toRequiredDocumentsDigitalCaf',
    config: 'screen',
    params: {
      onNext: () => {
        getBoundNavigationsActions().reset({
          index: 0,
          actions: [getNavigationActions().toStatus('screen')],
        });
      },
      screenMode: DocumentUploadMode.EXPENSES_STEP,
    },
  };

  if (isResubmission && !areDocumentsConfirmedOnResubmission) {
    return {
      index,
      ...sections.todo,
      navigation: {
        id: 'it.digital-caf.required-documents.upload',
        link: {
          ...sectionLink,
        },
      },
      warning: true,
    };
  }

  if (isSectionInSkipStatus) {
    return {
      index,
      ...sections.todo,
      navigation: {
        id: 'it.digital-caf.required-documents.upload',
        link: {
          ...sectionLink,
        },
      },
      skipped: true,
    };
  }

  return null;
};

export const hasSkippedExpenseUploadSection: StatusSkipFunction = async (getState) => {
  return getAnswer(getState(), flagsQuestionIds.expensesUploadSkipped);
};
