import React from 'react';
import { useSelector } from 'react-redux';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';

import { icons, NavHeader, Container } from 'src/taxfix-components/src';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';
import { SupportButtonContainer } from 'src/containers/support-button/support-button-container';

import { getNavigationActions, safeResetNavigation } from '../routes/config-util';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import AccountButton from '../containers/account-button.web';
import { LoginLink, OnboardingLink } from '../containers/header-links';
import { NavigationState } from '../services/navigation-service';
import { useAppStateContext } from '../hooks/useAppStateContext';

import { extractScreenName } from './utils';

type Props = {
  headerTitle: string;
  navigationState: NavigationState;
  navigation: StackNavigationProp<{}>;
  onGoBack?: () => any;
  headerStyle?: Record<string, any>;
};

// TODO:  supply header[Right|Left] via useNavigationOptions or similar from screen component
function getRightHeaderControlByScreen(screenName: string) {
  switch (screenName) {
    case 'screen/Login':
    case 'screen/LogoutSuccess':
    case 'screen/ForgotPassword':
    case 'screen/Lock':
      return <OnboardingLink />;
    case 'screen/ItExpertCheck':
      return <></>;
    default:
      return <LoginLink />;
  }
}

const getHeaderChildren = (showSideTabs: boolean, screenName: string) => {
  return showSideTabs ? <AccountButton /> : <SupportButtonContainer screenName={screenName} />;
};

export const Header = ({
  headerTitle,
  headerStyle,
  navigationState,
  navigation,
  onGoBack,
}: Props): JSX.Element => {
  const showSideTabs = useShowSideTabs();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const marginBottom = useBreakpointValue({ base: 0, md: '12px' });
  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);
  const isAppLocked = useAppStateContext().locked;
  const { name } = navigationState.routes[navigationState.index];
  //TODO create the condition to display or not the come back arrow in other screens in desktop web
  //that are not with the new IT layout
  const canGoBack = navigation && navigation.canGoBack();
  const handleGoBack = onGoBack || (canGoBack ? navigation.goBack : undefined);

  const handlePressHomeButton = () => {
    if (isAuthenticated) {
      safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
    } else {
      safeResetNavigation([getNavigationActions().toOnboardingSplash('screen')]);
    }
  };

  const isAppLockedHandleCondition = !isAppLocked ? handlePressHomeButton : () => {};

  const getChildrenElement = () => {
    return isAuthenticated && !isAppLocked
      ? getHeaderChildren(showSideTabs, extractScreenName(name))
      : getRightHeaderControlByScreen(name);
  };

  return (
    <Container
      paddingX="10px"
      marginBottom={marginBottom}
      backgroundColor="greytones.white"
      {...headerStyle}
    >
      <NavHeader
        iconComeBack={icons.arrowLeft}
        routeName={headerTitle}
        //TODO create the condition to display or not the come back arrow in other screens in desktop web
        //that are not with the new IT layout
        textArrow=""
        onPressHomeButton={!isAuthenticated ? isAppLockedHandleCondition : undefined}
        //TODO create the condition to display or not the come back arrow in other screens in desktop web
        //that are not with the new IT layout
        onPressArrow={isSmallScreen && !isAppLocked ? handleGoBack : undefined}
      >
        {getChildrenElement()}
      </NavHeader>
    </Container>
  );
};
