import { ButtonVariants } from 'src/taxfix-components/src';

export enum TrackingDocSubTypes {
  Passport = 'passport',
  IdCard = 'idCard',
  DrivingId = 'drivingId',
}

export type State = {
  selectedIdType: TrackingDocSubTypes | null;
};

export type Option = {
  label: string;
  value: TrackingDocSubTypes;
};
export type ButtonProps = {
  title: string;
  onPress?: () => void;
  variant: ButtonVariants;
  testId?: string;
};

export type ButtonsContentProps = {
  infoButton?: {
    handlePressed: () => void;
    shouldShowTooltip: boolean;
    handleTooltipOpened: () => void;
    handleTooltipClosed: (pressedHiglight: boolean) => void;
  };
  buttons: {
    buttonContinue: ButtonProps;
    buttonRemind?: ButtonProps;
  };
};

export type ComponentProps = ButtonsContentProps & {
  backNavigationText: string;
  uploadOptions: Option[];
  onPressBackNavigation?: () => void;
  selectedIdType: TrackingDocSubTypes | null;
  handleUploadOptionSelected: (idType: TrackingDocSubTypes) => void;
};
