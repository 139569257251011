/* global __DEV__ */
import { useRef } from 'react';
import { NavigationState, Route } from '@react-navigation/native';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import type { NavigationParams, Tracking, TrackingUnion } from 'src/routes/navigate';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';

import { stopPerformanceTraceWithLabel } from '../firebase-performance';
import { PerformanceLabels } from '../firebase-performance.labels';

import { getCurrentRoute } from './navigation-common-hook.utils';

function getTracking(
  tracking: TrackingUnion,
  routeParams: NavigationParams<{}>,
  getState: (...args: Array<any>) => any,
): Tracking {
  if (typeof tracking === 'function') {
    return tracking(getState(), routeParams);
  }

  if (typeof tracking === 'string') {
    return {
      name: tracking,
      params: undefined,
    };
  }

  return tracking;
}

export type TrackingMap = Record<CountryCodes, Record<string, TrackingUnion> | undefined>;

export const useNavigationTracking = (options?: { trackingMap?: TrackingMap }) => {
  const store = useStore();
  const prevState = useRef<Route<any> | Record<any, any>>();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const dispatch = useDispatch();

  return (currentState?: NavigationState) => {
    const currentScreen = getCurrentRoute(currentState);
    const prevScreenName = prevState.current?.name;
    const currentScreenName = currentScreen.name;

    if (prevScreenName !== currentScreenName) {
      // eslint-disable-next-line no-console
      if (__DEV__) console.log('>> Current Route: ', currentScreenName);
      if (prevScreenName && currentScreenName) {
        stopPerformanceTraceWithLabel(PerformanceLabels.screenNavigation, {
          destination: currentScreenName.replace('screen/', ''),
        });
      }
      const { params } = currentScreen;
      const { tracking: trackingParam, ...routeParams } = params ?? {};
      const selectedCountryTrackingMap = options?.trackingMap?.[selectedCountry] ?? {};
      const tracking = trackingParam || selectedCountryTrackingMap[currentScreenName];

      if (tracking) {
        const trackingParams = getTracking(tracking, routeParams, store.getState);
        Analytics.log(trackingParams.name as AnalyticsEvent, trackingParams.params);
      }

      prevState.current = currentScreen;
    }
  };
};
