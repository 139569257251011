import * as React from 'react';

import { stringLengthValidator } from 'src/components/answers/shared/validators/lengthValidator';

import ConfirmableInput from '../confirmable-input';

import FreeText from './FreeText';

export const InputString = (props: any): JSX.Element => {
  return (
    <ConfirmableInput
      {...props}
      value={props.value || ''}
      InputComponent={FreeText}
      validator={props.validator || stringLengthValidator}
    />
  );
};

export default InputString;
