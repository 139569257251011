import React from 'react';

import { Card, Text } from 'src/taxfix-components/src';

import { AvatarGroup } from '../AvatarGroup';

import { Props } from './types';

/**
 * card with the content of the basic plan tax advisor proposition
 */

export const TaxAdvisorCard: React.FC<Props> = ({
  onPress,
  content,
  avatarSources,
  buttonText,
  ...otherProps
}): JSX.Element => {
  return (
    <Card
      withNavigation
      backgroundColor="greytones.greyBlue"
      navigationHandler={onPress}
      navigationLabel={buttonText}
      variant="sticky1"
      {...otherProps}
    >
      <AvatarGroup avatarSources={avatarSources} />
      <Text mb={37} mt={24} variant="bodySBook" color="greytones.textDefault" value={content} />
    </Card>
  );
};
