import * as React from 'react';

import { Box, theme } from '../core';
import Separator from '../separator';
import Button from '../answer/payroll-inline-button';
import Overlay from '../overlay';

export type Option = {
  translationKey?: string;
  onPress?: (...args: Array<any>) => any;
};
type Props = {
  options: Option[];
  onSelect: (id: string) => void;
  onClose: () => void;
  color?: string;
  isSelectFullScreenHeight?: boolean;
  Header?: React.ComponentType<any> | null;
  Footer?: React.ComponentType<any> | null;
  fullWidthOnWeb?: boolean;
};

const SelectButtonOverlay = ({
  options,
  onClose: onCloseComplete,
  color,
  isSelectFullScreenHeight,
  Header = null,
  Footer = null,
  fullWidthOnWeb,
}: Props) => {
  return (
    <Overlay
      bottomFiller
      onCloseComplete={onCloseComplete}
      backgroundColor={theme.color.overlay}
      fullWidthOnWeb={fullWidthOnWeb}
    >
      {({ onClose }: any) => (
        <Box
          style={{
            backgroundColor: theme.color.lightFill,
            marginTop: isSelectFullScreenHeight ? 56 : 0,
          }}
        >
          <Box>
            <Box>
              {Header ? (
                <>
                  <Header />
                  <Separator />
                </>
              ) : null}
            </Box>

            {options &&
              options.map((option) => (
                <Box key={option.translationKey}>
                  <Button
                    id={option.translationKey || ''}
                    color={color || ''}
                    onPress={option.onPress || onClose}
                  />
                  <Separator />
                </Box>
              ))}

            <Box>
              {Footer ? (
                <>
                  <Separator />
                  <Footer />
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </Overlay>
  );
};

export default SelectButtonOverlay;
