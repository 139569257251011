import React, { useEffect, useCallback, ReactNode } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { contentForIT } from '../ContentMagic';
import { formats } from '../i18n';
import LocaleInfoProvider from '../i18n/locale-info';
import { appMessages, getAppLanguage, getDeviceLocale, supportedLanguages } from '../lang';
import {
  actions as settingsActionCreators,
  selectors as settingsSelectors,
} from '../stores/modules/settings';
import { LanguageCode } from '../types';
import { selectors as devToolsSelectors } from '../stores/modules/dev-tools';
import { selectors as remoteConfigFirebaseSelectors } from '../stores/modules/remote-config-firebase';
import { GlobalIntl } from '../i18n/locale-info/GlobalIntl';

type TranslationInfo = {
  hasDifference: boolean;
  remoteValue: string;
  localValue: string;
  translationKey: string;
};

type Props = {
  children?: ReactNode;
  messages: any | null | undefined;
};
// MOB-601: polyfill DOMParser for react-intl with xmldom library
// https://github.com/formatjs/react-intl/blob/master/docs/Getting-Started.md#domparser
// the recommended jsdom library is only supported in Node.js env. and not by React-Native
// https://github.com/formatjs/react-intl/issues/1430
// eslint-disable-next-line no-undef
window.DOMParser = require('xmldom').DOMParser;
// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

function RootWithIntl({ children, messages }: Props) {
  const countryCode = CountryCodes.IT;
  const { selectLocale } = settingsActionCreators;

  const dispatch: any = useDispatch();
  const language = useSelector(settingsSelectors.selectedLocale) as LanguageCode;
  const devToolsIsEnglishDisabled = useSelector(devToolsSelectors.isEnglishDisabled);
  const injectTranslations = useSelector(remoteConfigFirebaseSelectors.getTranslations);

  const setLanguage = useCallback(() => {
    const appLanguage = getAppLanguage(
      countryCode,
      devToolsIsEnglishDisabled ? [LanguageCode.EN] : [],
    );
    dispatch(selectLocale(appLanguage, false));

    return appLanguage;
  }, [countryCode, devToolsIsEnglishDisabled, selectLocale, dispatch]);

  useEffect(() => {
    if (language && supportedLanguages(countryCode).includes(language)) return;
    setLanguage();
  }, [countryCode, setLanguage, language]);

  useEffect(() => {
    setLanguage();
  }, [devToolsIsEnglishDisabled, setLanguage]);

  const intlLanguage = language || setLanguage();

  const countryMessages = {
    ...appMessages(intlLanguage),
    ...contentForIT.messages[intlLanguage],
  };

  // In case there are differences between remote and local translation strings,
  // we report them to the console, but only during the local development
  if (__DEV__) {
    const remoteTranslations = injectTranslations?.[intlLanguage] || {};
    const translationsWithDifference = Object.entries(remoteTranslations).reduce(
      (acc: TranslationInfo[], [key, value]) => {
        const hasDifference = countryMessages[key] !== value;
        if (hasDifference) {
          return acc.concat({
            hasDifference,
            remoteValue: value,
            localValue: countryMessages[key],
            translationKey: key,
          });
        }
        return acc;
      },
      [],
    );

    // eslint-disable-next-line no-console
    console.log(
      `Remote config translation keys that override project's defaults:`,
      translationsWithDifference,
    );
  }

  if (messages && messages[intlLanguage]) {
    Object.assign(countryMessages, messages[intlLanguage]);
  }

  const overwrittenMessages = Object.assign(
    {},
    countryMessages,
    injectTranslations?.[intlLanguage] || {},
  );

  const intl = createIntl(
    {
      locale: intlLanguage,
      messages: overwrittenMessages,
      formats,
      defaultFormats: formats,
    },
    cache,
  );
  GlobalIntl.initialise(intl);
  const locale = getDeviceLocale();

  return (
    <RawIntlProvider value={intl} key={locale}>
      <LocaleInfoProvider key={locale} language={intlLanguage} locale={locale}>
        {children}
      </LocaleInfoProvider>
    </RawIntlProvider>
  );
}

export const RootContainer: React.FC<Props> = ({ ...props }) => <RootWithIntl {...props} />;
