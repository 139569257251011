import { useEffect, useState } from 'react';

export type FileWithPreview = File & { preview: string };

export const useObjectUrl = (files: File[]): FileWithPreview[] => {
  const [filesWithPreview, setFilesWithPreview] = useState<FileWithPreview[]>([]);

  useEffect(() => {
    setFilesWithPreview(
      files.map((file: File) =>
        // Object.assign is needed here since we need to
        // 1. keep object as File object
        // 2. have access to [[Prototype]]: File
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    return () => filesWithPreview.forEach((file) => URL.revokeObjectURL(file.preview));
    // no need to add filesWithPreview as it would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return filesWithPreview;
};
