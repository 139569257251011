import type { PathConfigMap } from '@react-navigation/core';

import { isMobileScreen } from 'src/utils/platform';

import { NavigationActions } from './config-util';
import { ScreenConfig, RouteProps, ScreenType, ParentTabs } from './navigate';

export type NaiveRouteScreenType = [routeName: string, screenConfig: ScreenConfig];

export type NaiveCountryRoutesConfigType = {
  navigationActions: NavigationActions;
  screens: NaiveRouteScreenType[];
};

export type NaiveNavigate<ActionParams = {}> = {
  name: string;
  params: ActionParams;
};

export type NaiveAction<ActionParams = {}> = (
  ...args: [params?: ActionParams, legacyParams?: ActionParams]
) => NaiveNavigate<ActionParams>;

export type NaiveRouteProps = RouteProps & {
  linkingOptions?: PathConfigMap<Record<string, unknown>>;
  parentTab: ParentTabs;
};

export type NaiveRouteScreenProps<ActionParams> = {
  routeName: string;
  action: NaiveAction<ActionParams>;
  screen: ScreenType;
  tracking: RouteProps['tracking'] | undefined;
  linking: PathConfigMap<Record<string, string>> | undefined;
};

export const naiveNavigate =
  <ActionParams>(name: string): NaiveAction<ActionParams> =>
  (params, legacyParams) => {
    let actionParams = params || ({} as ActionParams);
    // We still have some legacy redirects in the codebase.
    // Legacy navigation params are passed as the second argument
    // And first is the "mode" which is a string
    if (typeof params === 'string') {
      actionParams = legacyParams || ({} as ActionParams);
    }
    return {
      name: name,
      params: actionParams,
    };
  };

export const getNaiveRoute = <ActionParams>({
  routeName,
  screenParams,
  screenOptions,
  screenNavigationOptions,
  screenComponent: ScreenComponent,
  linkingOptions,
  tracking,
  parentTab,
}: NaiveRouteProps): NaiveRouteScreenProps<ActionParams> => {
  const screenTitle = screenOptions?.title || (isMobileScreen ? '' : 'Taxfix');

  return {
    routeName,
    action: naiveNavigate<ActionParams>(routeName),
    screen: [
      routeName,
      {
        screen: ScreenComponent,
        params: { ...screenParams },
        options: {
          ...screenOptions,
          title: screenTitle,
          headerRightContainerStyle: { flex: 3 },
        },
        navigationOptions: {
          ...screenNavigationOptions,
          parentTab,
        },
      },
    ],
    tracking,
    linking: linkingOptions,
  };
};

export default naiveNavigate;
