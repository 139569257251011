import * as React from 'react';
import { Stack, useBreakpointValue } from 'native-base';
import { ScrollView } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import { Container, Image, NavButton, Text } from 'src/taxfix-components/src';
import { isWeb } from 'src/utils/platform';

import { useItalyIntl } from '../../_italy/_hooks';

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

const Check = () => <Image name="faCheck" resizeMode="contain" width="16px" height="16px" />;
const Null = () => <Image name="faNull" resizeMode="contain" width="16px" height="16px" />;

const BulletPoint: React.FC<{ item: number; text: string; showPremiumProduct: boolean }> = ({
  item,
  text,
  showPremiumProduct,
}) => {
  const isLargeScreen = useLargeScreenQuery();
  const checkBoxContainerProps = {
    alignItems: 'center',
    width: showPremiumProduct && !isLargeScreen ? '50px' : '65px',
  };
  const textContainerProps = {
    flexGrow: 2,
    flexBasis: '55%',
    mr: showPremiumProduct && !isLargeScreen ? '8px' : '16px',
  };

  const INSTANT_KEYS = showPremiumProduct ? [2, 10] : [2, 10, 11];
  const PREMIUM_ONLY_KEYS = showPremiumProduct ? [4, 12, 13] : [];

  return (
    <Container flexDirection="row" alignItems="center">
      <Container {...textContainerProps}>
        <Text value={text} variant="bodySBook" color="rgba(15,12,15,0.8)" />
      </Container>
      <Container {...checkBoxContainerProps}>
        {INSTANT_KEYS.includes(item) ? <Check /> : <Null />}
      </Container>
      <Container {...checkBoxContainerProps}>
        {!PREMIUM_ONLY_KEYS.includes(item) ? <Check /> : <Null />}
      </Container>
      {showPremiumProduct && (
        <Container {...checkBoxContainerProps} alignItems="center">
          <Check />
        </Container>
      )}
    </Container>
  );
};

type ProductBundleComponentProps = {
  onBackButtonPress?(): void;
  showPremiumProduct: boolean;
};

export const ProductBundleDetailsComponent: React.FC<ProductBundleComponentProps> = ({
  onBackButtonPress,
  showPremiumProduct = false,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
  });
  const pageTitleMarginTop = useBreakpointValue({ base: isWeb ? '24px' : 0, md: '48px' });
  const pageTitleMarginBottom = useBreakpointValue({ base: '20px', md: '12px' });
  const pageContainerWidth = useBreakpointValue({ md: '580px' });
  const pageContainerHorizontalMargin = useBreakpointValue({ md: 'auto' });
  const contentHorizontalMargin = useBreakpointValue({ base: '24px', lg: 'auto' });
  const contentMarginBottom = useBreakpointValue({ base: '28px', lg: '24px' });
  const contentDescriptionVariant = useBreakpointValue({ base: 'bodyXSBook', md: 'bodySBook' });

  const comparisonHeaderProps = {
    variant: 'overlineS',
    fontSize: !isLargeScreen && showPremiumProduct ? '7px' : 'b_xxxs',
    fontWeight: 'medium',
    color: 'rgba(15,12,15,0.8)',
  };

  const TOTAL_BULLET_POINTS = showPremiumProduct ? 13 : 11;

  const BULLET_POINTS_KEYS = Array.from(
    { length: TOTAL_BULLET_POINTS },
    (_, i) => `it.product-bundle-details-screen.bullet${showPremiumProduct ? '.v2.' : '.'}${i + 1}`,
  );

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <Container position="absolute" top={0} zIndex={1}>
          <NavButton
            onPress={onBackButtonPress}
            text={getTranslationText('it.product-bundle-details-screen.back-button.text')}
          />
        </Container>
      )}
      <ScrollView>
        <Container
          mx={pageContainerHorizontalMargin}
          width={pageContainerWidth}
          maxHeight={isLargeScreen ? '590px' : 'auto'}
        >
          <Container mx={contentHorizontalMargin} mb={contentMarginBottom}>
            <Text
              mt={pageTitleMarginTop}
              mb={pageTitleMarginBottom}
              variant={titleVariant}
              value={getTranslationText(
                `it.product-bundle-details-screen${showPremiumProduct ? '.v2.' : '.'}title`,
              )}
            />
            <Text
              variant={contentDescriptionVariant}
              value={getTranslationText(
                `it.product-bundle-details-screenscreen${
                  showPremiumProduct ? '.v2.' : '.'
                }content-description`,
                {
                  bold: getBoldText,
                },
              )}
            />
          </Container>

          <Stack space={4} height="full" p="24px" backgroundColor="greytones.backgroundLight">
            <Container flexDirection="row" ml="auto">
              <Text
                {...comparisonHeaderProps}
                value={getTranslationText('it.product-bundle-details-screen.bundle-name.instant')}
                mr="10px"
              />
              <Text
                {...comparisonHeaderProps}
                value={getTranslationText('it.product-bundle-details-screen.bundle-name.guided')}
              />
              {showPremiumProduct && (
                <Text
                  ml="10px"
                  {...comparisonHeaderProps}
                  value={getTranslationText('it.product-bundle-details-screen.bundle-name.premium')}
                />
              )}
            </Container>
            {BULLET_POINTS_KEYS.map((bulletKey, index) => (
              <BulletPoint
                key={index}
                item={index + 1}
                text={getTranslationText(bulletKey)}
                showPremiumProduct={showPremiumProduct}
              />
            ))}
          </Stack>
        </Container>
      </ScrollView>
    </>
  );
};
