import React from 'react';

import { Product, CouponResult } from '../../types/payment';
import { ItalySidebar } from '../../containers/sidebar/italy-sidebar';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';

import { VoucherInputContainer } from './voucher-input-container';

export type ActionParams = {
  product?: Product;
  onCouponResult: (arg0: CouponResult) => void;
};

type Props = Navigation<
  Routes.VoucherInput,
  { [Routes.VoucherInput]: NavigationParams<ActionParams> }
>;

export const VoucherInputScreen: React.FC<Props> = ({ route }) => {
  useDefaultNavigationOptions();

  const sidebar = () => <ItalySidebar />;
  useWebNavigationOptions({
    sidebar,
  });
  const { product, onCouponResult } = route.params;
  return <VoucherInputContainer product={product} onCouponResult={onCouponResult} />;
};

export const { action: toVoucherInput, screen: voucherInputScreen } = getRoute<ActionParams>({
  routeName: Routes.VoucherInput,
  screenComponent: VoucherInputScreen,
});
