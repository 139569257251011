export const icons = {
  tickBlack: require('./assets/ic_check_black_48pt.png'),
  tickWhite: require('./assets/ic_check_white_48pt.png'),
  crossWhite: require('./assets/ic_close_white_48pt.png'),
  crossBlack: require('./assets/ic_close_black_48pt.png'),
  crossGray: require('./assets/ic_close_gray_48pt.png'),
  'submitted-tick': require('./assets/submitted-tick/submitted-tick.png'),
  ui: {
    arrow: {
      uri: 'NavigationBar-BackIndicatorImage',
    },
  },
  duo: {
    computer: require('./assets/computer/computer.png'),
  },
  color: {
    'arrow-dark': require('./assets/arrow-dark/arrow-dark.png'),
  },
  tabBar: {
    account: require('./assets/nav/icon-menu-account.png'),
    overview: require('./assets/tab-bar/icon-menu-tax.png'),
    toDo: require('./assets/nav/nav-todo.png'),
  },
  nav: {
    account: require('./assets/nav/icon-menu-account.png'),
    share: require('./assets/nav/icon-share.png'),
    todo: require('./assets/nav/nav-todo.png'),
  },
  cross: require('./assets/cross.png'),
  clock: require('./assets/clock.png'),
  happy: require('./assets/happy.png'),
  disappointed: require('./assets/disappointed.png'),
  toggleDown: require('./assets/toggle-down.png'),
  toggleUp: require('./assets/toggle-up.png'),
  calendar: require('./assets/calendar.png'),
  calendarActive: require('./assets/calendar-active.png'),
  jointAssessment: require('./assets/joint-assessment.png'),
  letting: require('./assets/letting.png'),
  pension: require('./assets/pension.png'),
  survivorPension: require('./assets/survivor-pension.png'),
  silentPartnerships: require('./assets/silent-partnerships.png'),
  loans: require('./assets/loans.png'),
  farmer: require('./assets/farmer.png'),
  foreign: require('./assets/foreign.png'),
  parents: require('./assets/parents.png'),
  business: require('./assets/business.png'),
  foreignBanks: require('./assets/foreign-banks.png'),
  unsupportedDefault: require('./assets/icon-unsupported-default.png'),
  mapA: require('./assets/map-a.png'),
  mapB: require('./assets/map-b.png'),
  mapAActive: require('./assets/map-a-active.png'),
  mapBActive: require('./assets/map-b-active.png'),
  taxfixLogo: require('./assets/taxfix-logo.png'),
  magnifier: require('./assets/magnifier.png'),
  magnifierActive: require('./assets/magnifier-active.png'),
  downArrow: require('./assets/down-arrow.png'),
  payslip: {
    default: {
      form: require('./assets/payslip-form.png'),
      camera: require('./assets/payslip-camera.png'),
    },
    left: require('./assets/payslip-left.png'),
    right: require('./assets/payslip-right.png'),
    leftJA: require('./assets/payslip-left-ja.png'),
    rightJA: require('./assets/payslip-right-ja.png'),
  },
  checkWhiteBackground: require('./assets/check-white-background.png'),
  checkPrimaryBackground: require('./assets/check-primary-background.png'),
  greenMoneyBag: require('./assets/green-money-bag.png'),
  greenLightBulb: require('./assets/green-light-bulb.png'),
  greenMail: require('./assets/green-mail.png'),
  greenCv: require('./assets/green-cv.png'),
  greenChat: require('./assets/green-chat.png'),
  greenPhone: require('./assets/green-phone.png'),
  greenArticle: require('./assets/green-article.png'),
  capturePhoto: require('./assets/capture-photo.png'),
  flashOff: require('./assets/flash-off.png'),
  flash: require('./assets/flash.png'),
  calculator: require('./assets/calculator.png'),
  penToPaper: require('./assets/pen-to-paper.png'),
  phoneLetter: require('./assets/phone-letter.png'),
  medal: require('./assets/medal.png'),
  info: require('./assets/info-icon.png'),
  infoRed: require('./assets/info-icon-red.png'),
  infoClearBackground: require('./assets/info-clear.png'),
  attention: require('./assets/attention.png'),
  question: require('./assets/question-icon.png'),
  edit: require('./assets/edit-pencil.png'),
  editPartner: require('./assets/edit-pencil-partner.png'),
  editWarning: require('./assets/warning-edit-pencil.png'),
  questionPartner: require('./assets/question-partner.png'),
  questionPartnerDark: require('./assets/question-partner-dark.png'),
  iconOnboardingPriceGrey: require('./assets/icon-onboarding-price-grey.png'),
  iconOnboardingPrice: require('./assets/icon-onboarding-price.png'),
  iconOnboardingResult: require('./assets/icon-onboarding-result.png'),
  iconOnboardingDatasecurityGrey: require('./assets/icon-onboarding-datasecurity-grey.png'),
  iconOnboardingDatasecurity: require('./assets/icon-onboarding-datasecurity.png'),
  iconOnboardingInterviewGrey: require('./assets/icon-onboarding-interview-grey.png'),
  iconOnboardingInterview: require('./assets/icon-onboarding-interview.png'),
  iconOnboardingSubmission: require('./assets/icon-onboarding-submission.png'),
  illuOnboardingSecurity: require('./assets/illu-onboarding-security.png'),
  illuOnboardingPrice: require('./assets/illu-onboarding-price.png'),
  illuLanguageSelectionIcon: require('./assets/illu-language-selection-icon.png'),
  elsterLogo: require('./assets/elster-logo.png'),
  cameraGuideDocument: require('./assets/icon-document.png'),
  cameraGuideNewIdBack: require('./assets/icon-new-id-back.png'),
  cameraGuideNewIdFront: require('./assets/icon-new-id-front.png'),
  cameraGuideItalyOldIdInside: require('./assets/icon-italy-old-id-inside.png'),
  cameraGuideItalyOldIdOutside: require('./assets/icon-italy-old-id-outside.png'),
  cameraGuidePassport: require('./assets/icon-passport.png'),
  statusDone: require('./assets/status-icon-done.png'),
  statusStart: require('./assets/status-icon-start.png'),
  statusInProgress: require('./assets/status-icon-inprogress.png'),
  statusError: require('./assets/status-icon-error.png'),
  statusWait: require('./assets/status-icon-wait.png'),
  statusWarning: require('./assets/status-icon-warning.png'),
  accountReferral: require('./assets/account-referral.png'),
  tooltipArrow: {
    'top-right': require('./assets/tooltip-arrow-top-right.png'),
    'bottom-right': require('./assets/tooltip-arrow-bottom-right.png'),
    'top-left': require('./assets/tooltip-arrow-top-left.png'),
    'bottom-left': require('./assets/tooltip-arrow-bottom-left.png'),
  },
  illuTrafficBlocked: require('./assets/illu-traffic-blocked.png'),
  amazonGiftCard30: require('./assets/amazon-gift-card-30.png'),
  amazonLogo: require('./assets/amazon-logo.png'),
  amazonLogoWhite: require('./assets/amazon-logo-white.png'),
  birthday: require('./assets/birthday.png'),
  social: {
    facebook: require('./assets/social/facebook.png'),
    instagram: require('./assets/social/instagram.png'),
    linkedin: require('./assets/social/linkedin.png'),
    youtube: require('./assets/social/youtube.png'),
  },
  arrowLeft: require('./assets/left-arrow.png'),
  lock: require('./assets/lock-active.png'),
  watch: require('./assets/watch.png'),
  commercialista: require('./assets/commercialista/commercialista.png'),
  commercialistaBadge: require('./assets/commercialista/commercialistaBadge.png'),
  commercialistaBorder: require('./assets/commercialista/commercialistaBorder.png'),
  editAnswers: require('./assets/edit-answers.png'),
  // gifs
  fancyLoading: require('./assets/animation-loading.gif'),
  payslipCorrectionAnimation: require('./assets/payslip-correction-animation.gif'),
  // emoji
  emojiAlarmClock: require('./assets/emoji/emoji-alarm-clock.png'),
  specialFeeVoucherEN: require('./assets/voucher-en.png'),
  specialFeeVoucherDE: require('./assets/voucher-de.png'),
  confettiOnce: require('./assets/confetti-once.gif'),
  confettiOverTransparentBg: require('./assets/confetti-on-transparent-background.gif'),
  whatsappSupport: require('./assets/whatsapp.png'),
  dropbox: require('./assets/dropbox.png'),
  googleDrive: require('./assets/google-drive.png'),
  addDocumentIcon: require('./assets/ic-add-doc-mweb.png'),
  menuItems: {
    magic: require('./assets/menu-items/magic.png'),
    lock: require('./assets/menu-items/lock.png'),
    envelope: require('./assets/menu-items/envelope.png'),
    location: require('./assets/menu-items/location.png'),
    globe: require('./assets/menu-items/globe.png'),
    trash: require('./assets/menu-items/trash.png'),
    messaging: require('./assets/menu-items/messaging.png'),
    support: require('./assets/menu-items/support.png'),
    diagnostic: require('./assets/menu-items/diagnostic.png'),
    privacy: require('./assets/menu-items/shield-check.png'),
    documents: require('./assets/menu-items/documents.png'),
    account: require('./assets/nav/icon-menu-account.png'),
  },
  sensitiveDataConsent: {
    'intro-svg': require('./assets/sensitive-data-consent/Intro.svg'),
  },
  deleteFolder: require('./assets/delete-folder.png'),
  appleLogo: require('./assets/single-sign-on/apple-logo.png'),
  appleLogoDisabled: require('./assets/single-sign-on/apple-disabled.png'),
  googleLogo: require('./assets/single-sign-on/google-logo.png'),
  googleLogoDisabled: require('./assets/single-sign-on/google-disabled.png'),
  firstCategoryDone: require('./assets/single-sign-on/first-category-done-icon.png'),
  shield: require('./assets/shield.png'),
  greenShield: require('./assets/green-shield.png'),
  reviewEye: require('./assets/review_eye.png'),
  itUnsupportedOccupation: {
    'overlay-svg': require('./assets/it-unsupported-overlay.svg'),
  },
  contactSupportGreen: require('./assets/icon-contact-support-green.png'),
  email: require('./assets/email.png'),
  ukraineSupport: require('./assets/Ukraine.png'),
  itChatSupport: require('./assets/chat-support.png'),
  firstCategoryDoneInstantRefund: require('./assets/single-sign-on/first-category-done-instant-refund-icon.png'),
  verifiedBadgeInstantRefund: require('./assets/single-sign-on/instant-refund-verified-icon.png'),
  handsWithMoney: require('./assets/handswithmoney.png'),
  handsWithMoneySmall: require('./assets/handswithmoneysmall.png'),
};
