import taxValueTransformer from '@taxfix/quizmaster/dist/transformer/taxValueTransformer';
import tree2list from '@taxfix/quizmaster/dist/transformer/tree2list';
import { IncomeTaxValue, Responses, TreeNode, Cache } from '@taxfix/quizmaster/dist/types';

export const transformTaxValues = (
  year: number,
  tree: TreeNode,
  cache: Cache,
  activeResponseJS: Responses,
): IncomeTaxValue[] => {
  const treeList = tree2list(tree) as TreeNode[];
  const incomeFields = taxValueTransformer(treeList, activeResponseJS, cache, year);

  return incomeFields;
};
