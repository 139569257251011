import * as React from 'react';

import { SentryErrorBoundary } from 'src/services/sentry/sentry-error-tracking';
import { SentryCustomTag, SentryTagBooleanValue } from 'src/services/sentry/types';

import { ErrorBoundaryFallbackContainer } from './error-boundary-fallback';

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => (
  <SentryErrorBoundary
    fallback={<ErrorBoundaryFallbackContainer />}
    beforeCapture={(scope) => {
      scope.setTag(SentryCustomTag.CaughtByErrorBoundary, SentryTagBooleanValue.Yes);
    }}
  >
    {children}
  </SentryErrorBoundary>
);
