import { Buffer } from 'buffer';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Documents } from '@taxfix/taxfix-sdk';
import { DocumentsAPI } from '@taxfix/documents-sdk';
import Config from 'react-native-config';

import { DocumentPreviewCard, Text } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { isWeb } from 'src/utils/platform';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { useCWBIDocumentsConfig } from 'src/hooks/use-cwbi-documents-config';

import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import { getPreviewImage, resolveFileName } from '../utils';

import { PrefillDocumentsResultListScreen } from './prefill-documents-result-list-screen';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ShareFile = (!isWeb && require('react-native-share').default) || {};

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

type Props = {
  prefillDocumentsList: Documents.types.Document[];
  resultScreenExperiment?: boolean;
};

type RenderArgs = {
  item: any;
  index: any;
};

export const PrefillDocumentsResultListContainer = ({
  prefillDocumentsList,
  resultScreenExperiment,
}: Props): JSX.Element => {
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const dispatch = useDispatch();
  const { navigationActions, getNavigationActions, safeResetNavigation, navigation } =
    useNavigation();
  const { getTranslationText } = useItalyIntl();
  const { asyncDownloadDocsTranslations } = useCWBIDocumentsConfig();

  const translationKeys = {
    title: getTranslationText(
      resultScreenExperiment
        ? 'it.prefill-documents-list-screen.experiment-content.title'
        : 'it.prefill-documents-list-screen.title',
    ),
    backTitle: getTranslationText('it.prefill-documents-list-screen.back-header-title'),
    infoButton: getTranslationText('it.prefill-documents-list-screen.info-button.text'),
    ...(resultScreenExperiment
      ? {
          experimentBannerTitle: getTranslationText(
            'it.prefill-documents-list-screen.experiment-content.banner.title',
          ),
          experimentBannerText: getTranslationText(
            'it.prefill-documents-list-screen.experiment-content.banner.text',
            {
              bold: getBoldText,
            },
          ),
        }
      : {}),
  };

  const mapCWBIDocumentsList = () =>
    asyncDownloadDocsTranslations.map((document) => `<li>${document}</li>`).join('');

  const handlePressInfoButton = () => {
    dispatch(
      overlayActions.show('PrefillDocumentsResultListInfoOverlay', {
        content: getTranslationText('prefill-result-list-screen.info-overlay.content'),
        translationValues: {
          CWBI_documents: mapCWBIDocumentsList(),
        },
        navigateToSPIDConsent: () => navigationActions.toSPIDConsentScreen('modal'),
      }),
    );
  };

  const onNativeShare = async (id: number, fileName: string) => {
    try {
      const response = await DocumentsAPI.v1.getOne(Config.API_BASE_URL, accessToken, {
        id,
        getFileContents: true,
        acceptHeader: 'application/pdf',
      });

      const fileRaw = response.data as ArrayBuffer;
      const fileData = new Uint8Array(fileRaw);
      const fileBase64 = Buffer.from(fileData).toString('base64');

      await ShareFile.open({
        url: `data:application/pdf;base64,${fileBase64}`,
        filename: fileName,
      });
    } catch (error) {
      // this catch block execute always if the user didn't finish the share process
      logger.warn(error as Error, {
        message: `Failed/Stopped share document ${id} action in PrefillDocumentsResults screen`,
      });
    }
  };

  const renderDocumentListItems = ({
    item: prefillDocument,
    index,
  }: RenderArgs): React.ReactElement => {
    const { metadata, id, thumbnail, path, type } = prefillDocument;
    let parsedMetadata: undefined | Documents.types.Document['metadata'];

    // Safely parse metadata as JSON
    if (metadata) {
      try {
        parsedMetadata = JSON.parse(metadata);
      } catch (error) {
        // swallow the error;
      }
    }

    return (
      <DocumentPreviewCard
        maxHeight="120px"
        maxW="380px"
        marginBottom="20px"
        fileName={
          resolveFileName(prefillDocument, index) ||
          getTranslationText('it.prefill-result-screen.files-list.default-file-name')
        }
        badgeText={getTranslationText('it.prefill-result-screen.files-list.badge-text')}
        imageUri={thumbnail ? `data:image/jpeg;base64,${thumbnail}` : undefined}
        documentTypePreviewImage={getPreviewImage(type)}
        onPress={() => {
          Analytics.log(AnalyticsEvent.buttonPressed, {
            screenName: ScreenName.PrefillDocumentsResultList,
            buttonName: 'DocumentPreview',
            documentType: type,
          });
          navigationActions.toRequiredDocumentsPreview('screen', {
            id,
            document: {
              uri: path,
              contentType: parsedMetadata?.contentType,
              fileName: parsedMetadata?.originalName ?? '',
            },
            onNativeShare: () => onNativeShare(id, parsedMetadata?.originalName ?? ''),
          });
        }}
      />
    );
  };

  const handleOnBackButtonPress = () =>
    navigation.canGoBack()
      ? navigationActions.back()
      : safeResetNavigation([getNavigationActions().toPrefillDocumentsResult('screen')]);

  return (
    <PrefillDocumentsResultListScreen
      handleOnBackButtonPress={handleOnBackButtonPress}
      translationKeys={translationKeys}
      prefillDocumentsToRender={prefillDocumentsList}
      renderDocumentListItems={renderDocumentListItems}
      onPressInfoButton={handlePressInfoButton}
    />
  );
};
