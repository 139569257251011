import { Dispatch, bindActionCreators } from 'redux';

import { createDynamicLinkWithRedirect } from '../../services/dynamic-link';

import { DynamicLink, CreateRequestData } from './dynamic-link-types';

export const REQUEST_INITIAL_FETCH = 'dynamic-link/REQUEST_INITIAL_FETCH';
export const SAVE_DYNAMIC_LINK = 'dynamic-link/SAVE_DYNAMIC_LINK';
export const DISMISS_DYNAMIC_LINK = 'dynamic-link/DISMISS_DYNAMIC_LINK';
export type StoreStateType = 'pending' | 'initial-fetching' | 'handled';
export type State = {
  state: StoreStateType;
  dynamicLink: DynamicLink | null | undefined;
};
export const initial: State = {
  state: 'pending',
  dynamicLink: null,
};
type RequestInitialFetch = {
  type: typeof REQUEST_INITIAL_FETCH;
};
type SaveDynamicLink = {
  type: typeof SAVE_DYNAMIC_LINK;
  payload: {
    dynamicLink: DynamicLink;
  };
};
type DismissDynamicLink = {
  type: typeof DISMISS_DYNAMIC_LINK;
};
type Action = RequestInitialFetch | SaveDynamicLink | DismissDynamicLink;

const requestInitialFetch = () => ({
  type: REQUEST_INITIAL_FETCH,
});

const dismissDynamicLink = () => ({
  type: DISMISS_DYNAMIC_LINK,
});

const createAndSaveDynamicLink =
  (data: CreateRequestData) =>
  (dispatch: Dispatch<any>, getState: () => any): DynamicLink | null => {
    const dynamicLink = createDynamicLinkWithRedirect({
      ...data,
      onDismissDynamicLink: bindActionCreators(dismissDynamicLink, dispatch),
      dispatch,
      getState,
    });
    dispatch({
      type: SAVE_DYNAMIC_LINK,
      payload: {
        dynamicLink,
      },
    });
    return dynamicLink;
  };

export const actions = {
  requestInitialFetch,
  createAndSaveDynamicLink,
  dismissDynamicLink,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case REQUEST_INITIAL_FETCH:
      return { ...state, state: 'initial-fetching' };

    case SAVE_DYNAMIC_LINK:
      return { ...state, state: 'handled', dynamicLink: action.payload.dynamicLink };

    case DISMISS_DYNAMIC_LINK:
      return { ...state, state: 'handled', dynamicLink: null };

    default:
      return state;
  }
};
