/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { Container, Text, TextLink, Image } from 'src/taxfix-components/src';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import HtmlContent from 'src/components/html-content';
import { parseMarkdown } from 'src/services/markdown-parser';
import { ScreenName } from 'src/types/screen-name';

import { theme } from '../components/core';
import IconButton from '../components/core/Button/IconButton';
import Overlay from '../components/overlay';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { isWeb } from '../utils/platform';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
  },
  overlayWeb: {
    maxWidth: 500,
    height: '100%',
  },
  header: {
    height: 58,
    backgroundColor: theme.color.lightFill,
    width: '100%',
    justifyContent: 'flex-start',
  },
  closeButton: {
    padding: 20,
  },
});

export type DocumentUploadGuidanceSection = {
  title?: string;
  icon?: string;
  content: string;
};

type Props = {
  onClose: () => void;
  sections: Array<DocumentUploadGuidanceSection>;
  canDelete?: boolean;
  overlayTitleKey?: string;
};

const contentStyles = `
<style type="text/css">
* {
  font-size: 14px;
  }
#content {
  margin: 0px;
}
ul {
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
}
ul ul {
  list-style-type: disc;
}
li{
  margin-bottom: 5px;
}
</style>
`;

export const DocumentUploadGuidanceOverlay = ({
  onClose: onCloseComplete,
  sections = [],
  overlayTitleKey,
  canDelete = false,
}: Props): JSX.Element => {
  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'DocumentsUploadGuidance',
    });
  }, []);

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const supportLinkText = getTranslationText('it.overlays.document.upload.guidance.footer.link');
  const handleOnPressSupport = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ITRequiredDocumentsUploadGuidance,
      buttonName: supportLinkText,
    });
    handleOpenChatPopup();
  };

  return (
    <Overlay
      onCloseComplete={onCloseComplete}
      style={isWeb ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isWeb ? 'right' : 'bottom'}
    >
      {({ onClose }: any) => (
        <>
          <ScrollView style={{ height: '100%', padding: 30, display: 'flex' }}>
            <Container
              marginBottom={50}
              style={styles.header}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Container marginLeft={-15}>
                <IconButton
                  iconKey="cross"
                  onPress={onClose}
                  tintColor={theme.color.disabledGray}
                  style={styles.closeButton}
                />
              </Container>
              <Text
                variant="titleMBold"
                value={getTranslationText(
                  overlayTitleKey || 'it.overlays.document.upload.guidance.title',
                )}
              />
            </Container>
            <View onStartShouldSetResponder={() => true}>
              {sections.map((section) => {
                return (
                  <Container minHeight={300} marginBottom={40} flexDirection={'column'}>
                    <Container marginBottom={30} alignItems="center" flexDirection="row">
                      {section.icon && <Image name={section.icon} marginRight={10} size={10} />}
                      {section.title && (
                        <Text
                          color="brand.brandGreen"
                          value={getTranslationText(section.title)}
                          variant="bodyMBook"
                        />
                      )}
                    </Container>
                    <HtmlContent
                      scrollEnabled={false}
                      contentStyles={contentStyles}
                      content={String(parseMarkdown(getTranslationText(section.content))).replace(
                        /<a/g,
                        '<a target="_blank" ',
                      )}
                    />
                  </Container>
                );
              })}
            </View>
            <Container marginBottom={60} flexDirection="column">
              {canDelete && (
                <Container padding={20} marginBottom={40} background={'greytones.background'}>
                  <Text
                    variant="bodySBold"
                    value={getTranslationText(
                      'it.overlays.document.upload.guidance.canDelete.title',
                    )}
                  />
                  <Text
                    variant="bodySBook"
                    value={getTranslationText(
                      'it.overlays.document.upload.guidance.canDelete.text',
                    )}
                  />
                </Container>
              )}
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.overlays.document.upload.guidance.footer.title')}
              />
              <TextLink
                variant="titleXSMedium"
                color={'brand.brandGreen'}
                text={supportLinkText}
                onPress={handleOnPressSupport}
              />
            </Container>
          </ScrollView>
        </>
      )}
    </Overlay>
  );
};
