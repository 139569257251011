import React, { ComponentType, ReactElement } from 'react';

import {
  BackNavigationControlMethods,
  useBackNavigationControl,
} from '../hooks/back-navigation-control-hook';

export type { BackNavigationControlMethods as WithBackNavigationControl };
type OriginalProps = Record<string, any>;
export const withBackNavigationControl = (
  WrappedComponent: ComponentType<OriginalProps>,
): ComponentType<OriginalProps> => {
  const Wrapper = (props: OriginalProps): ReactElement<typeof WrappedComponent> => {
    const { enableGoBack, disableGoBack } = useBackNavigationControl();
    return (
      <WrappedComponent enableGoBack={enableGoBack} disableGoBack={disableGoBack} {...props} />
    );
  };

  return Wrapper;
};
