import { logger } from 'src/taxfix-business-logic/utils/logger';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getNodesByIds, getQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { migrationQuestionIds, questionFlowCategories } from 'src/common/constants-it';
import { StatusMiddleware } from 'src/stores/modules/status';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';

const ANSWERS_MIGRATION_YEAR = 2022;

export const QFChangeMigration: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const selectedYear = settingsSelectors.selectedYear(getState()) || getDefaultTaxYear();
    // it's only 2022 tax year migration, we will not have to make it for the users
    // who start with the new question store for 2022 ++
    if (selectedYear !== ANSWERS_MIGRATION_YEAR) {
      return next();
    }
    const questionStore = getQuestionStoreByYearAndCountry(getState());

    const questionIds: string[] = [...Object.values(migrationQuestionIds)];

    const nodes = getNodesByIds(questionStore, questionIds);

    const quizmasterLight = getQuizmasterLight(questionStore, nodes);

    const cityLastYear = quizmasterLight[migrationQuestionIds.cityLastYear].answer;
    const cityCurrentYear = quizmasterLight[migrationQuestionIds.cityCurrentYear].answer;

    const addressCategoryStarted =
      questionStore.getCategory(questionFlowCategories.residenceAddress).progress > 0;

    // The address category is in progress, that means that the user has finished the residence address question.
    // We can fill the answers of related to 'sameCity' based on the answers for the two other questions cityLastYear, cityCurrentYear.
    // cityLastYear and cityCurrentYear questions only appear if the reply to 'same city' was false (due to that the user needs to fill the new address)
    // Following this logic we implemented the if statement as follows:

    if (addressCategoryStarted) {
      if (cityLastYear) {
        quizmasterLight[migrationQuestionIds.sameCityLastYear].saveAnswer(false);
      } else {
        quizmasterLight[migrationQuestionIds.sameCityLastYear].saveAnswer(true);
      }
      if (cityCurrentYear) {
        quizmasterLight[migrationQuestionIds.sameCityCurrentYear].saveAnswer(false);
      } else {
        quizmasterLight[migrationQuestionIds.sameCityCurrentYear].saveAnswer(true);
      }
    }
  } catch (error) {
    logger.log('Error in in-season qf change answer migration', error);
    return next();
  }
  return next();
};
