import React from 'react';
import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

import { SVGProps } from '../types';

/**
 * StepsIcon ID with several paths, coloured
 * Temporary component
 */

export const IDIcon: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <G fill="none">
      <Path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#E1F8E6"
      />
      <Path
        d="M26.502 38.8545C27.0359 39.1714 27.8144 39.3267 28.409 39.5127C28.7259 39.612 29.7155 39.6722 29.8526 39.9689C29.9909 40.269 29.4856 40.6038 29.6926 40.8699C29.8811 41.1123 30.4764 40.914 30.73 40.9149C31.7489 40.9189 31.2722 41.1797 31.7032 41.8967C32.1894 42.7052 33.7698 42.3944 34.5665 42.5385C34.5732 43.2281 35.0591 43.443 35.6556 43.5916"
        stroke="#3B3B3B"
        stroke-width="0.745887"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.6766 31.555L27.8933 36.4178L29.6092 37.3991L44.7582 33.2565L45.6766 31.555Z"
        fill="white"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.6064 46.6947L18.5976 46.9032L18.3934 17.3055L45.4022 17.0968L45.6064 46.6947Z"
        fill="#F2F2F2"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.6064 46.6947L18.5976 46.9032L18.3934 17.3055L45.4022 17.0968L45.6064 46.6947Z"
        fill="white"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.6064 46.6947L18.5976 46.9032L18.3934 17.3055L45.4022 17.0968L45.6064 46.6947Z"
        stroke="#F6F6F6"
        stroke-width="0.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9556 41.5346L21.1768 41.7029L21.0264 19.8964L42.8052 19.7281L42.9556 41.5346Z"
        fill="white"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2333 29.9759C29.2434 31.4744 30.4121 32.6802 31.8435 32.6691C33.2747 32.658 34.4264 31.4345 34.4163 29.9359C34.4057 28.4375 33.2374 27.2318 31.806 27.2429C30.3749 27.2538 29.2231 28.4775 29.2333 29.9759Z"
        fill="#FBD144"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2333 29.9759C29.2434 31.4744 30.4121 32.6802 31.8435 32.6691C33.2747 32.658 34.4264 31.4345 34.4163 29.9359C34.4057 28.4375 33.2374 27.2318 31.806 27.2429C30.3749 27.2538 29.2231 28.4775 29.2333 29.9759Z"
        stroke="#3B3B3B"
        stroke-width="0.621573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1091 30.322C30.1091 30.322 31.3216 29.9611 31.8751 28.2304C31.8751 28.2304 32.2215 29.7067 33.652 30.3262L34.3999 30.7426L34.4831 29.0655C34.4831 29.0655 33.9644 27.0122 31.8037 26.9145C31.8037 26.9145 29.5762 26.9284 29.2483 28.7865L29.2429 30.5393L30.1091 30.322Z"
        fill="#8C6239"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1091 30.322C30.1091 30.322 31.3216 29.9611 31.8751 28.2304C31.8751 28.2304 32.2215 29.7067 33.652 30.3262L34.3999 30.7426L34.4831 29.0655C34.4831 29.0655 33.9644 27.0122 31.8037 26.9145C31.8037 26.9145 29.5762 26.9284 29.2483 28.7865L29.2429 30.5393L30.1091 30.322Z"
        stroke="#3B3B3B"
        stroke-width="0.621573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M31.4225 31.0201C31.4241 31.2683 31.6264 31.4678 31.8741 31.4658C32.1217 31.4642 32.3207 31.2614 32.319 31.0132"
        stroke="#3B3B3B"
        stroke-width="0.621573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0475 23.0722C23.0546 24.1241 23.9122 24.9704 24.9628 24.9622C26.0134 24.9543 26.8593 24.0949 26.852 23.0428C26.8449 21.991 25.9871 21.1448 24.9365 21.1527C23.886 21.1611 23.04 22.0204 23.0475 23.0722Z"
        fill="white"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1636 32.5088C30.1636 32.5088 28.3175 32.5231 28.342 36.0874L29.3999 36.5705L29.7916 35.542L29.4133 41.5204L34.8028 41.4376L34.0623 35.5087L34.2402 36.0421L35.7416 35.5374C35.7416 35.5374 34.9895 32.9507 33.469 32.3673C33.469 32.3673 31.0857 33.2539 30.1636 32.5088Z"
        fill="#FCEBE6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1634 32.5088C30.1634 32.5088 28.3174 32.5231 28.3418 36.0874L29.3998 36.5705L29.7915 35.542L29.4131 41.5204L34.8026 41.4376L34.0621 35.5087L34.24 36.0421L35.7415 35.5374C35.7415 35.5374 34.6677 33.0922 33.1472 32.5089C33.1472 32.5089 31.0855 33.2539 30.1634 32.5088Z"
        stroke="#3B3B3B"
        stroke-width="0.621573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.469 35.8673L35.969 41.3673L34.969 41.3673L34.6569 36.1027L35.469 35.8673Z"
        fill="#FBD144"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.469 35.8673L35.969 41.3673L34.969 41.3673L34.4689 36.1027L35.469 35.8673Z"
        stroke="#3B3B3B"
        stroke-width="0.621573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.469 36.3673L27.969 41.3673L28.969 41.3673L29.469 36.8673L28.469 36.3673Z"
        fill="#FBD144"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.469 36.3673L27.969 41.3673L28.969 41.3673L29.469 36.8673L28.469 36.3673Z"
        stroke="#3B3B3B"
        stroke-width="0.745887"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.2958 39.6076L26.4897 36.5279L26.2958 39.6076Z"
        fill="#FBD144"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9556 41.5346L21.1768 41.7029L21.0264 19.8964L42.8052 19.7281L42.9556 41.5346Z"
        stroke="#32C850"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </G>
  </Icon>
);
