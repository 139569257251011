/* global __DEV__ */
import AsyncStorage from '@react-native-community/async-storage';

export async function setItem<T>(key: string, value: T): Promise<T> {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(`Error storing ${key} in async storage`, error);
    }

    throw error;
  }

  return value;
}
export async function getItem<T>(key: string): Promise<T> {
  try {
    return JSON.parse((await AsyncStorage.getItem(key)) as any);
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching ${key} from async storage`, error);
    }

    throw error;
  }
}
export async function clear(): Promise<any> {
  try {
    await AsyncStorage.clear();
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(`Error cleaning async storage`, error);
    }

    throw error;
  }
}
