import React from 'react';
import { View } from 'react-native';

import { isWeb } from '../../utils/platform';
import { StyleProp } from '../types';
import {
  WEB_SECTION_NARROW_WIDTH,
  WEB_SECTION_WIDE_WIDTH,
  WEB_SECTION_LEFT_MARGIN,
  WEB_SECTION_RIGHT_MARGIN,
} from '../../utils/constants';

import { useLargeScreenQuery } from './MediaQuery';

export type SectionSize = 'narrow' | 'wide';
type Props = {
  children: React.ReactNode;
  centered?: boolean;
  size: SectionSize;
  style?: StyleProp | any;
  testId?: any;
};
export const Section = (props: Props): any => {
  const { style: extraStyles, size, centered, children } = props;
  const isLargeScreen = useLargeScreenQuery();
  let style = {};

  if (isLargeScreen) {
    switch (size) {
      case 'narrow':
        style = {
          ...style,
          maxWidth: WEB_SECTION_NARROW_WIDTH,
          minWidth: centered ? WEB_SECTION_NARROW_WIDTH : 0,
          marginLeft: centered ? 0 : WEB_SECTION_LEFT_MARGIN,
          marginRight: centered ? 0 : WEB_SECTION_RIGHT_MARGIN,
        };
        break;

      case 'wide':
        style = {
          ...style,
          maxWidth: WEB_SECTION_WIDE_WIDTH,
          minWidth: centered ? WEB_SECTION_WIDE_WIDTH : 0,
          marginLeft: centered ? 0 : WEB_SECTION_LEFT_MARGIN,
          marginRight: centered ? 0 : WEB_SECTION_RIGHT_MARGIN,
        };
        break;

      default:
        break;
    }

    if (centered) {
      style = { ...style, alignSelf: 'center' };
    }
  }

  return isWeb ? <View style={[style, extraStyles]}>{children}</View> : children;
};
