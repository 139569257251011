// folder request
export enum PreSeasonSpidMessage {
  errorScreenShown = 'error',
  credentialsErrorShown = 'folderRequestStep2B/hasActionErrors',
  credentialsValidationFailed = 'folderRequestStep2B/hasFieldErrors',
  spidFlowAuthorized = 'folderRequestDetails/init/AUTHORIZED',
  spidFlowRejected = 'folderRequestDetails/init/REJECTED',
  spidFlowExpired = 'folderRequestDetails/init/EXPIRED',
  spidFlowError = 'folderRequestDetails/init/ERROR',
}

// tokenized credentials request
export enum SpidMessage {
  spidURLLoaded = 'tokenizeCredentialRequestStep2A/init',
  providerSelectionScreenShown = 'tokenizeCredentialRequestStep2A/init',
  identityProviderSelected = 'tokenizeCredentialRequestStep2A/submitForm',
  providerScreenShown = 'tokenizeCredentialRequestStep2B/init',
  requestLoginWithProvider = 'tokenizeCredentialRequestStep2B/submitForm',
  credentialsErrorShown = 'tokenizeCredentialRequestStep2B/hasActionErrors',
  credentialsValidationFailed = 'tokenizeCredentialRequestStep2B/hasFieldErrors',
  spidFlowAuthorized = 'tokenizeCredentialRequestDetails/init/AUTHORIZED',
  spidFlowRejected = 'tokenizeCredentialRequestDetails/init/REJECTED',
  spidFlowExpired = 'tokenizeCredentialRequestDetails/init/EXPIRED',
  spidFlowError = 'tokenizeCredentialRequestDetails/init/ERROR',
  errorScreenShown = 'error',
}

// the same AUTHORIZED event in this format is triggered from the mock response
// in both - folder status and tokenized credentials spid login
export enum CWBIMockServerSPIDMessage {
  authorized = 'folderRequestDetails/init/AUTHORIZED',
}
