import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Button, Container, Icon, Text, icons } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';

type Props = {
  onPressDelete: () => void;
  onPressClose: () => void;
  translationKeys: {
    title: string;
    description?: string;
    CTAButton: string;
    secondaryButton?: string;
  };
  categoryKey: string;
};

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export const ExpenseItemDeleteOverlay: React.FC<Props> = ({
  onPressDelete,
  onPressClose,
  translationKeys,
  categoryKey,
}) => {
  const { getTranslationText } = useItalyIntl();
  const { title, description, CTAButton, secondaryButton } = translationKeys;
  const screenNameWithCategoryKey = ScreenName.ExpenseItemDeleteOverlay + `/${categoryKey}`;

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: screenNameWithCategoryKey,
    });
  }, []);

  const handlePressDelete = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: screenNameWithCategoryKey,
      buttonName: 'Delete expense',
    });

    onPressDelete();
  };

  const handlePressClose = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: screenNameWithCategoryKey,
      buttonName: 'Cancel',
    });

    onPressClose();
  };

  const binSize = useBreakpointValue(['80px', '100px']);
  const titleSize = useBreakpointValue(['titleSBold', 'titleMBold']);

  return (
    <Overlay disableBackgroundTouch style={styles.overlay} appearFromTop>
      {() => (
        <Container
          borderRadius={1}
          maxWidth="600px"
          maxHeight="600px"
          backgroundColor="white"
          p={[2, 5]}
          m={1}
        >
          <Container alignItems="center" justifyContent={'center'} paddingTop={2}>
            <Container
              alignItems="center"
              justifyContent="center"
              backgroundColor="secondaryCategory.pinkHalf"
              width={binSize}
              height={binSize}
              borderRadius={'200px'}
            >
              <Icon size={'43px'} color="secondaryCategory.pinkFull" path={icons.bin} />
            </Container>
          </Container>
          <Text
            value={getTranslationText(title)}
            variant={titleSize}
            textAlign="center"
            color="greytones.textDefault"
            mt={4}
            mb={2}
            mx={1}
          />
          {description && (
            <Text
              value={getTranslationText(description)}
              variant="bodyMBook"
              textAlign="center"
              color="greytones.textLight"
              paddingBottom={2}
            />
          )}
          <Button
            variant="primaryAlarm"
            title={getTranslationText(CTAButton)}
            onPress={handlePressDelete}
            width={['l', 'm']}
            mt={[1, 1, 4]}
          />
          {secondaryButton && (
            <Button
              variant="tertiaryAlarm"
              title={getTranslationText(secondaryButton)}
              onPress={handlePressClose}
              width={['xl', 'm']}
              mt={1}
            />
          )}
        </Container>
      )}
    </Overlay>
  );
};
