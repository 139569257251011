import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import { theme } from '../theme';
import Icon from '../Icon';
import { SmallLinkText } from '../Text';
import { CheckBox } from '../../checkbox/checkbox';
import Box from '../Box';
import { isWeb } from '../../../utils/platform';

import ExpandContent from './ExpandContent';
import InternalButton, { InternalButtonPropsWithStyle } from './_InternalButton';

type Props = InternalButtonPropsWithStyle & {
  align?: 'left' | 'center' | 'right';
  translationKey?: string;
  selected?: boolean;
  iconKey?: string | null | undefined;
  index?: number;
  color?: string;
  colorSecondary?: string;
  testId?: string;
  label?: string;
  withoutTruncation?: boolean;
  hasBorderInTheBottom?: boolean;
  onPress: () => void;
};
type CheckBoxButtonState = {
  height: number;
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  border: {
    borderWidth: 1,
    borderColor: theme.color.border,
  },
  borderFirstElement: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.color.border,
  },
  borderLastElement: {
    borderBottomWidth: 1,
    borderColor: theme.color.border,
  },
  icon: {
    width: 42,
    height: 42,
    marginHorizontal: 15,
  },
});
const stylesWeb = StyleSheet.create({
  border: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.color.border,
  },
});
export const DEFAULT_COMPONENT_HEIGHT = 60;
export const EXTRA_PADDING = 15;

class CheckBoxButton extends Component<Props, CheckBoxButtonState> {
  state = {
    height: DEFAULT_COMPONENT_HEIGHT,
  };

  layoutChange = (layout: any) => {
    const newHeight = layout.height + EXTRA_PADDING;
    this.setState(({ height }) => ({
      height: this.calculateHeight(height, newHeight),
    }));
  };

  calculateHeight = (height: number, newHeight: number): number => {
    // User has expanded the text.
    if (height < newHeight) {
      return newHeight;
    }

    // User has collapsed text.
    if (newHeight < DEFAULT_COMPONENT_HEIGHT) {
      return DEFAULT_COMPONENT_HEIGHT;
    }

    return height;
  };

  calculateStyle = (index: number) => {
    if (isWeb) {
      return index % 2 === 0 ? stylesWeb.border : null;
    }

    if (index === 0) {
      return styles.borderFirstElement;
    }

    return index % 2 === 0 ? styles.border : null;
  };

  render() {
    const {
      translationKey,
      label,
      iconKey,
      selected = false,
      index = 0,
      color = theme.color.primary,
      colorSecondary = theme.color.secondary,
      testId,
      withoutTruncation = false,
      hasBorderInTheBottom = false,
      ...props
    } = this.props;
    const appliedStyles = [
      styles.container,
      this.calculateStyle(index),
      hasBorderInTheBottom ? styles.borderLastElement : null,
      selected
        ? {
            backgroundColor: colorSecondary,
          }
        : null,
      {
        minHeight: this.state.height,
      },
    ];
    const Text = (
      <SmallLinkText ellipsizeMode="tail" id={translationKey} color={color}>
        {translationKey == null ? label : null}
      </SmallLinkText>
    );
    return (
      <InternalButton {...props} style={appliedStyles as any}>
        <CheckBox checked={selected} color={color} onToggle={props.onPress} testId={testId} />
        {iconKey ? (
          <View style={styles.icon}>
            <Icon name={iconKey} tintColor={color} />
          </View>
        ) : (
          <View
            style={{
              width: 15,
            }}
          />
        )}
        {withoutTruncation ? (
          <Box flex>{Text}</Box>
        ) : (
          <ExpandContent layoutChange={this.layoutChange}>{Text}</ExpandContent>
        )}
      </InternalButton>
    );
  }
}

export default CheckBoxButton;
