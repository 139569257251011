import { Linking } from 'react-native';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ListRowComponentPropsType } from 'src/components/core/List/types';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { WithNavigation, withNavigation } from 'src/hocs/with-navigation';
import { trackMenuItem } from 'src/screens/account-menu/menuItems/utils';

const mapStateToProps = (stores: any) => ({
  selectedLocale: settingsSelectors.selectedLocale(stores),
  selectedCountry: settingsSelectors.selectedCountry(stores),
});

const connector = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ListRowComponentPropsType<any> & WithNavigation & ReduxProps;

const Imprint = (props: Props) => {
  const extraPayload = {};

  const handleSelectItem = (item: MenuItemData) => {
    trackMenuItem(item);

    Linking.openURL('http://taxfix.it/index.php/impronta/');
  };

  return (props as any).children({
    hide: false,
    data: {
      translationKey: menuItem.imprint,
      testId: menuItem.imprint,
      showArrow: true,
      ...extraPayload,
    },
    handleSelectItem,
  });
};

export const ImprintMenu = compose(withNavigation, connector)(Imprint);
