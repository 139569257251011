import { indexFromId } from '@taxfix/quizmaster/dist/reference';
import { Responses, TreeNode, Cache, UserResponse } from '@taxfix/quizmaster/dist/types';
import list2refs from '@taxfix/quizmaster/dist/transformer/list2refs';
import preconditionsMet from '@taxfix/quizmaster/dist/q-and-a/preconditionHelper';
import { isAnswered } from '@taxfix/quizmaster/dist/q-and-a/answersHelper';
import inputResolver from '@taxfix/quizmaster/dist/calculator/inputResolver';
import { toJS } from 'mobx';

import { GlobalIntl } from 'src/i18n/locale-info/GlobalIntl';
import QuestionStore from 'src/stores-legacy/QuestionStore';

export type QuestionAndAnswer = {
  questionID: string;
  questionText: string;
  answerText?: string;
  answer: unknown;
};

export const getQuestionAndAnswers = (responses: Responses): QuestionAndAnswer[] => {
  const intl = GlobalIntl.getIntlInstance();
  const result = Object.values(responses).map(({ answerID, questionID, answer }: UserResponse) => {
    let questionText = intl.formatMessage({
      id: `question::${questionID}`,
    });

    // For all answer we must also send the answerText if available
    // e.g. for multiple choice we have answer 1,2,3..etc but text should be like "Spain resident", etc.
    const answerArr = Array.isArray(answer) ? answer : [answer];
    const answerText = answerArr
      .map((a) => {
        const id = `answer::${questionID}.${a}`;

        return intl.messages[id]
          ? intl.formatMessage({ id: `answer::${questionID}.${a}` })
          : undefined;
      })
      .join('\n');
    const index = indexFromId(answerID);

    // Add index to looped question text
    if (index) {
      questionText = `${questionText} [${index[0] + 1}]`;
    }

    return {
      questionID: answerID,
      questionText,
      answerText,
      answer: typeof answer === 'object' ? JSON.stringify(answer) : String(answer),
    };
  });
  return result;
};

export type Answer = {
  questionID: string;
  outputs: {
    output: string;
    value: any;
  }[];
};

export type UseAnswers = {
  answers: Answer[];
  questionAndAnswers: QuestionAndAnswer[];
  responsesJS: Responses;
};

export type UseActiveAnswers = {
  answers: Answer[];
  questionAndAnswers: QuestionAndAnswer[];
  activeResponsesJS: Responses;
};

type ExtractedAnswers = {
  answers: Answer[];
};

export const extractAnswers = (
  list: TreeNode[],
  responses: Responses,
  cache: Cache,
  year: number,
): ExtractedAnswers => {
  const refs = list2refs(list);

  const getMappedOutputs = (node: TreeNode) => {
    const { inputs, outputs, id } = node;

    if (inputs == null || outputs == null) {
      return null;
    }

    const resolvedOutputs = outputs.map((output: any) => ({
      output,
      value: inputResolver(inputs[output], responses, inputs, refs, id, cache, year),
    }));
    return {
      questionID: id,
      outputs: resolvedOutputs,
    };
  };

  const candidates = list.filter((node) => preconditionsMet(node, responses, refs, cache, year));
  const candidatesAnswered = candidates.filter((node) => {
    if (node.response == null) {
      return true;
    }

    return isAnswered(node, responses);
  });
  const answers: Answer[] = [];
  candidatesAnswered.forEach((candidate) => {
    const mapped = getMappedOutputs(candidate);
    if (mapped) answers.push(mapped);
  });
  return {
    answers,
  };
};

export const getAnswers = (questionStore: QuestionStore): UseAnswers => {
  questionStore.runCleanRebuild();
  const { list, responsesJS, cache, year } = questionStore;
  const { answers } = extractAnswers(list, responsesJS, cache, year);
  const questionAndAnswers = getQuestionAndAnswers(responsesJS);
  return {
    answers,
    questionAndAnswers,
    responsesJS,
  };
};

export const getActiveAnswers = (questionStore: QuestionStore): UseActiveAnswers => {
  questionStore.runCleanRebuild();
  const { list, cache, year } = questionStore;
  const quizmaster = questionStore.quizmaster('root', false, year);
  // issue with questionStore activeResponsesJS getter.
  const activeResponsesJS = toJS(quizmaster.activeResponses());
  const { answers } = extractAnswers(list, activeResponsesJS, cache, year);
  const questionAndAnswers = getQuestionAndAnswers(activeResponsesJS);
  return {
    answers,
    activeResponsesJS,
    questionAndAnswers,
  };
};
