import { Documents as DocumentsTypes } from '@taxfix/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useNavigation } from 'src/hooks/navigation-hook';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { selectors as settingSelectors } from 'src/stores/modules/settings';
import { ScreenName } from 'src/types/screen-name';
import { ButtonName } from 'src/types/button-name';

import { InstantFilingNextStepsComponent } from './component';

type Params = {
  onNext?(): void;
};

export const InstantFilingNextStepsContainer = (): JSX.Element => {
  const { getNavigationActions, safeResetNavigation, navigationActions, navigation } =
    useNavigation();
  const year = useSelector(settingSelectors.selectedYear ?? getDefaultTaxYear());
  const route = useRoute();
  const { onNext } = route.params as Params;

  const onBackNavigation = (btn?: ButtonName) => {
    if (navigation.canGoBack()) {
      if (btn === ButtonName.ChangePlan) {
        Analytics.log(AnalyticsEvent.buttonPressed, {
          screenName: ScreenName.InstantFilingNextSteps,
          buttonName: ButtonName.ChangePlan,
        });
      }
      navigation.goBack();
    } else {
      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    }
  };

  const onContinueNavigation = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.InstantFilingNextSteps,
      buttonName: 'continueButton',
    });

    if (onNext) {
      onNext();
    } else {
      navigationActions.toPrefillDocumentUpload('screen', {
        prefillDocumentType: DocumentsTypes.NonReceiptTypes.Id,
        year,
        onNext: safeResetNavigation([getNavigationActions().toStatus('screen')]),
      });
    }
  };

  return (
    <InstantFilingNextStepsComponent
      onPressContinueNavigation={onContinueNavigation}
      onPressBackNavigation={onBackNavigation}
    />
  );
};
