// version user profile fields
// Data version is set to 2019 which was the fiscal year when
// the user profile got introduced but it wasn't versioned yet,
// so if there's any data in it before the migration it must
// have been set in this year.
const DATA_VERSION = 2019;
export const migration = (state: Record<string, any>) => {
  const { userProfile } = state;
  if (userProfile.data) return state;
  const data: any = {};
  const dataVersion: any = {};
  const fields = ['firstName', 'lastName', 'taxId', 'residenceCity', 'phoneNumber'];
  fields.forEach((field) => {
    data[field] = userProfile[field];

    if (userProfile[field] !== '') {
      dataVersion[field] = DATA_VERSION;
    }
  });
  data.occupation = userProfile.occupation;

  if (userProfile.occupation.length) {
    dataVersion.occupation = DATA_VERSION;
  }

  if (userProfile.hasEmployer !== undefined) {
    data.employer = {
      hasEmployer: userProfile.hasEmployer,
      name: userProfile.employerName,
      taxId: userProfile.employerTaxId,
    };
    dataVersion.employer = DATA_VERSION;
  } else {
    data.employer = undefined;
  }

  // initialize billingAddress field with empty billing address if it's undefined
  data.billingAddress = userProfile.billingAddress || {
    street: '',
    city: '',
    zipcode: '',
    countryCode: '',
  };
  // billingAddress wasn't available in fiscal year 2019, so its value doesn't have to be checked
  dataVersion.billingAddress = undefined;
  return {
    ...state,
    userProfile: {
      data,
      dataVersion,
    },
  };
};
