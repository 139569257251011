import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import Icon from '../Icon';
import { StyleProp } from '../../types';
import { testID } from '../../../common/testID';

type ButtonProps = {
  iconKey: string;
  onPress: () => void;
  style?: StyleProp;
  iconStyle?: StyleProp;
  tintColor?: string;
  testId?: string;
  disabled?: boolean;
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function IconButton({
  iconKey,
  onPress,
  style,
  tintColor,
  testId,
  iconStyle,
  disabled,
}: ButtonProps): JSX.Element {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.button, style]}
      hitSlop={{
        top: 20,
        left: 20,
        bottom: 20,
        right: 20,
      }}
      {...testID(testId)}
    >
      <Icon name={iconKey} tintColor={tintColor} style={iconStyle} />
    </TouchableOpacity>
  );
}

export default IconButton;
