import { getNavigationActions } from 'src/routes/config-util';

import { StatusMiddleware, StatusStepRedirect } from '../../../stores/modules/status';

export const checkPreseasonCUResult: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  dispatch(breakAction());
};

export const redirectPreseasonCUResult: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toCUDocumentResultScreen('screen'),
    ],
  });
};
