import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import { flagsQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useQuizmasterLight } from 'src/_italy/_hooks';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { logger } from 'src/taxfix-business-logic/utils/logger';

import { FreelancersSurveyComponent } from './freelancers-survey-component';
import { FreelancersSurveyError, FreelancersSurveyErrorMessages } from './errors';

export const FeelancersSurveyContainer: React.FC = () => {
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const accessToken = useSelector(userAuthSelectors.getAccessToken);

  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.freelancerSurveyCompleted]);

  const { surveyUrl, events, originUrl } = useSelector(
    firebaseSelectors.getItalyFreelancerExperience,
  );

  const handleOnPressCTA = () =>
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);

  const onMessage = useCallback(
    (event: { nativeEvent: { data: unknown } }): void => {
      if (typeof event.nativeEvent.data !== 'string') return;

      const data: string = event.nativeEvent.data;

      if (events.includes(data)) {
        quizmasterLight[flagsQuestionIds.freelancerSurveyCompleted].saveAnswer(true);
        setIsSurveyCompleted(true);
      }

      if (data === FreelancersSurveyErrorMessages.parsingIFrameEventsError) {
        logger.warn(
          new FreelancersSurveyError(FreelancersSurveyErrorMessages.parsingIFrameEventsError),
        );
      }
    },
    [events, quizmasterLight],
  );

  return (
    <FreelancersSurveyComponent
      url={surveyUrl}
      originUrl={originUrl}
      onMessage={onMessage}
      userToken={accessToken}
      surveyCompletedEvents={events}
      isSurveyCompleted={isSurveyCompleted}
      handleOnPressCTA={handleOnPressCTA}
    />
  );
};
