import { FileError, FileRejection } from 'react-dropzone';

export const getErrorMessageKey = (errorCode: FileError['code']): string => {
  const messageKeys = {
    'file-too-large': 'identity-upload.errors.max-file-size',
    'too-many-files': 'identity-upload.errors.too-many-files',
    'file-invalid-type': 'identity-upload.errors.invalid-type',
    default: 'identity-upload.errors.generic-error',
  } as const;
  const messageKey = messageKeys[errorCode as keyof typeof messageKeys];
  return messageKey ? messageKey : messageKeys.default;
};

export const getUniqueErrorCodes = (rejections: FileRejection[]): Array<FileError['code']> => {
  const uniqueRejectionCodes = new Set();
  rejections.forEach(({ errors }) => {
    errors.forEach(({ code }) => {
      uniqueRejectionCodes.add(code);
    });
  });
  return [...uniqueRejectionCodes] as Array<FileError['code']>;
};
