import * as React from 'react';
import { ResponseType } from '@taxfix/quizmaster/dist/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { AnswerComponentProps } from 'src/components/answers/types';
import Address from 'src/containers/answers/Address';
import {
  prefillQuestionIds,
  shortSummaryAnswersTranslationKeys,
  specificAnswerTypesQuestionIds,
} from 'src/common/constants-it';
import { ShortSummary } from 'src/_italy/quizmaster/components/summaries/shortSummary';

import { ItalySingleSelect } from '../single-select/container';
import { InputNumber } from '../containers/input-number';
import { InputString } from '../containers/input-string';
import { YesNoContainer, Options, Props as YesNoContainerProps } from '../containers/yes-no';
import {
  DependentHasItalianFCComponent,
  DependentHasItalianFCComponentProps,
} from '../containers/dependent-it-fc';
import ITTaxNumber, { ITTaxNumberProps } from '../containers/tax-number';
import TextSummary from '../../../components/answer/summary/components/text';
import NumberSummary from '../../../components/answer/summary/components/number';
import ListSummary from '../../../components/answer/summary/components/list';
import AddressSummary from '../../../components/answer/summary/components/address';
import EmptySummary from '../../../components/answer/summary/components/empty';
import { MultiSelectContainerLegacy } from '../multi-select';
import { FirstTimeFilingComponent } from '../containers/first-time-filing';

import EmployerName from './EmployerName';
import { ItalyCitySearch } from './CitySearch';
import { YesNoSummary } from './summaries/yes-no-summary';

const MultipleSelectComponent = (props: any) => (
  <MultiSelectContainerLegacy {...props} ignoreEmptyValue />
);

const ItalianSingleSelect = (props: any) => <ItalySingleSelect {...props} />;

const ItalianAddressComponent = (props: AnswerComponentProps) => <Address {...props} isItalian />;

const SameCityAnswerComponent = (props: YesNoContainerProps) => (
  <YesNoContainer
    {...props}
    optionKeys={{
      [Options.YES_KEY]: 'it.answers.same-city-question.yes',
      [Options.NO_KEY]: 'it.answers.same-city-question.no',
    }}
  />
);

const SpouseHasItalianFCComponent = (props: DependentHasItalianFCComponentProps) => (
  <DependentHasItalianFCComponent {...props} dependent="spouse" />
);

const ChildHasItalianFCComponent = (props: DependentHasItalianFCComponentProps) => (
  <DependentHasItalianFCComponent {...props} dependent="child" />
);

const RelativeHasItalianFCComponent = (props: DependentHasItalianFCComponentProps) => (
  <DependentHasItalianFCComponent {...props} dependent="relative" />
);

const getTaxIDComponent = (isPersonal: boolean) => (props: ITTaxNumberProps) =>
  <ITTaxNumber {...props} isPersonal={isPersonal} />;

const resolveAnswerFromType = (
  type: ResponseType,
  questionId: string,
): React.ComponentType<any> => {
  const childItalianFCQuestion = specificAnswerTypesQuestionIds.childHasItalianFiscalCode;
  const relativeItalianFCQuestion = specificAnswerTypesQuestionIds.relativeHasItalianFiscalCode;

  // in case of looping (same asked question multiple times) the questionID contains a bracket notation
  // with the index value of each loop:
  // e.g.: if someone has 3 children - the same question (or questions sequence in the loop) will have ids
  // - 7a23469ba71da5dc3340[0]
  // - 7a23469ba71da5dc3340[1]
  // - 7a23469ba71da5dc3340[2]

  if (questionId.includes(childItalianFCQuestion)) {
    return ChildHasItalianFCComponent;
  }

  if (questionId.includes(relativeItalianFCQuestion)) {
    return RelativeHasItalianFCComponent;
  }

  switch (questionId) {
    case specificAnswerTypesQuestionIds.employerName:
      return EmployerName;
    case specificAnswerTypesQuestionIds.employerTaxCode:
      return getTaxIDComponent(false);
    case specificAnswerTypesQuestionIds.sameResidenceCityYearStart:
    case specificAnswerTypesQuestionIds.sameResidenceCityYearEnd:
      return SameCityAnswerComponent;
    case specificAnswerTypesQuestionIds.spouseHasItalianFiscalCode:
      return SpouseHasItalianFCComponent;
    case specificAnswerTypesQuestionIds.currentYearCity:
    case specificAnswerTypesQuestionIds.prevYearCity:
      return ItalyCitySearch;
    case prefillQuestionIds.firstTimeFiling:
      return FirstTimeFilingComponent;
  }

  switch (type) {
    case 'IT_address':
      return ItalianAddressComponent;

    case 'yes_no':
      return YesNoContainer;

    case 'string':
      return InputString;

    case 'number':
      return InputNumber;

    case 'multiple_choice':
      return ItalianSingleSelect;

    case 'multiple_select':
      return MultipleSelectComponent;

    case 'IT_tax_number':
      return getTaxIDComponent(true);

    default:
      logger.warn(`Cannot find answer type: ${type}`);
      return InputString;
  }
};

const SameCitySummaryComponent = (props: YesNoContainerProps) => (
  <YesNoSummary
    {...props}
    optionsTextIds={{
      [Options.YES_KEY]: 'it.answers.same-city-question.summary.yes',
      [Options.NO_KEY]: 'it.answers.same-city-question.summary.no',
    }}
  />
);

export const resolveDefaultSummaryFromType = (
  type: string,
  questionId: string,
): React.ComponentType<any> => {
  switch (questionId) {
    case specificAnswerTypesQuestionIds.sameResidenceCityYearStart:
    case specificAnswerTypesQuestionIds.sameResidenceCityYearEnd:
      return SameCitySummaryComponent;
  }

  const isQuestionIdLoop = questionId[questionId.length - 1] === ']';
  const questionIdWithoutLoop = isQuestionIdLoop
    ? questionId.slice(0, questionId.length - 3)
    : questionId;
  const isShortSummary = shortSummaryAnswersTranslationKeys.includes(questionIdWithoutLoop);

  if (isShortSummary) {
    return ShortSummary;
  }

  switch (type) {
    case 'yes_no':
      return YesNoSummary;

    case 'string':
    case 'tax_number':
    case 'IT_tax_number':
      return TextSummary;

    case 'number':
      return NumberSummary;

    case 'multiple_choice':
      return ListSummary;

    case 'multiple_select':
      return ListSummary;

    case 'IT_address':
      return AddressSummary;

    default:
      logger.warn(`Cannot find summary type: ${type}`);
      return EmptySummary;
  }
};

export default resolveAnswerFromType;
