import Config from 'react-native-config';
// @ts-ignore
import { singularSdk, SingularConfig } from 'singular-sdk';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { SINGULAR_REVENUE_EVENT } from '.';

export const setupSingular = (): void => {
  const singularConfig = new SingularConfig(
    Config.SINGULAR_SDK_KEY,
    Config.SINGULAR_SDK_PASSPHRASE,
    Config.PRODUCT_ID,
  );

  singularSdk.init(singularConfig);
};

export default {
  login: (userId: string): void => {
    singularSdk.login(userId);
  },
  logout: (): void => {
    singularSdk.logout();
  },
  track: (eventName: string, params: Record<string, string>): void => {
    const eventParams = params || {};

    if (eventName === SINGULAR_REVENUE_EVENT) {
      const { revenue, currency } = eventParams;
      if (currency?.toString() && revenue != null && +revenue >= 0) {
        return singularSdk.revenue(eventName, currency.toString(), +revenue, eventParams);
      }

      logger?.warn(`Revenue event ${eventName} was sent without revenue or currency properties`, {
        name: eventName,
        ...eventParams,
      });
    }

    return singularSdk.event(eventName, eventParams);
  },
  // Not available in singular-sdk for web
  limitDataSharing: (limitDataSharing: boolean): void => {},
  getSingularDeviceId: (): string | undefined => singularSdk.getSingularDeviceId(),
};
