import { useBreakpointValue } from 'native-base';
import React from 'react';
import { FlatList } from 'react-native';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { StateIconType, StatusStepItem, StepsIconType } from 'src/taxfix-components/src';
import { isE2E } from 'src/utils/platform';

import {
  StatusStepSectionNavigation,
  StatusStepSectionWithIndex,
} from '../../stores/modules/status';

type Props = {
  sections: StatusStepSectionWithIndex[];
  stepIndex: number;
  onSectionView: (navigation: StatusStepSectionNavigation) => void;
  price: string;
};

export const StatusStepsListItaly = ({
  sections,
  stepIndex,
  onSectionView,
  price,
}: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const getCurrentSectionState = (item: StatusStepSectionWithIndex): StateIconType => {
    if (item.warning) {
      return 'warning';
    }
    if (item.skipped || item.inProgress) {
      return 'progress';
    }
    if (item.index < stepIndex && !item.skipped) {
      return 'success';
    }
    return undefined;
  };

  return (
    <FlatList
      data={sections}
      renderItem={({ item, index }) =>
        item.title ? (
          <StatusStepItem
            key={index}
            stepText={getTranslationText(item.title.id, { price })}
            stepIcon={item.icon as StepsIconType}
            onPress={
              item.navigation && !item.stepDisabled
                ? () => onSectionView(item.navigation as StatusStepSectionNavigation)
                : undefined
            }
            showChevronIcon={!!item.navigation && !item.stepDisabled}
            stateIconType={getCurrentSectionState(item)}
            isIconDisabled={item.index > stepIndex || !!item.stepDisabled}
            borderInTheBottom={index === sections.length - 1}
            buttonTitle={getTranslationText('status.it.button.title')}
            //Added isE2E condition as the button doesn't appear on 800x1280 Android emulator
            showButton={
              (isSmallScreen || isE2E) &&
              !!item.navigation &&
              item.index === stepIndex &&
              !item.warning &&
              !item.stepDisabled &&
              !item.buttonDisabled
            }
            buttonTestID="continue.button"
          />
        ) : null
      }
    />
  );
};
