import React from 'react';
import { useBreakpointValue } from 'native-base';
import { StyleSheet } from 'react-native';

import { OverlayContent } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';

type Props = {
  title: string;
  description: string;
  ctaTitle: string;
  onClose: () => void;
  onCTAPress: () => void;
};
const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export const ItalyPostSubmissionOverlay: React.FC<Props> = ({
  title,
  description,
  ctaTitle,
  onClose,
  onCTAPress,
}) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Overlay onCloseComplete={onClose} style={styles.overlay} appearFromTop>
      {() => (
        <OverlayContent
          handleOnClose={onClose}
          handleMainButtonOnPress={onCTAPress}
          imageName="celebratingHands"
          imageSize={{
            width: isSmallScreen ? '248px' : '275px',
            height: isSmallScreen ? '89px' : '99px',
          }}
          title={title}
          description={description}
          buttonTitle={ctaTitle}
          mainButtonTestId="post-submission-overlay-button"
        />
      )}
    </Overlay>
  );
};
