import React from 'react';
import { ScrollView } from 'native-base';
import isNil from 'lodash/isNil';

import { RadioOptionButton } from 'src/taxfix-components/src';
import AnswerForm from 'src/_italy/quizmaster/components/AnswerForm';

import { useItalyIntl } from '../../_hooks/use-italy-intl';
import { testID } from '../../../common/testID';

import { Option, SelectedOption } from './types';

export const getIsSelected = (option: Option, selected?: SelectedOption): boolean => {
  let isSelected = false;

  if (!isNil(selected)) {
    if (selected === option) {
      isSelected = true;
    } else if (option.id != null && selected === option.id) {
      isSelected = true;
    } else if (option.value != null && selected === option.value) {
      isSelected = true;
    }
  }

  return isSelected;
};

export type Props = {
  options: Option[];
  onAnswer: (answer: any) => any;
  isFullHeight?: boolean;
  testId?: string | null | undefined;
  selectedValue?: SelectedOption;
  onChange: (value: any) => void;
};

export const SingleSelectComponent = ({
  options,
  onAnswer,
  selectedValue,
  onChange,
}: Props): JSX.Element => {
  const [value, setValue] = React.useState<SelectedOption>();
  const [hasSelectedOption, setHaSelectedOption] = React.useState<boolean>();

  React.useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const handleOptionPress = (option: Option) => {
    const { id, value = '' } = option;
    const answer = id != null && typeof id !== 'undefined' ? id : value;
    onChange(answer);
    setValue(answer);
    setHaSelectedOption(true);
  };

  const handleOnPress = () => {
    onAnswer(value);
  };

  const { getTranslationText } = useItalyIntl();
  const optionValue = hasSelectedOption ? value : selectedValue;
  const isDisabled = !hasSelectedOption && !selectedValue;

  return (
    <AnswerForm noGutters onConfirm={handleOnPress} disabled={isDisabled}>
      <ScrollView nestedScrollEnabled {...testID('test.scroll.single')}>
        {options.map((option, index) => {
          const isSelected = getIsSelected(option, optionValue);

          return (
            <RadioOptionButton
              option={{
                label: getTranslationText(option.translationKey),
                value: option.translationKey,
              }}
              isSelected={isSelected}
              testID={`answer::${option.label || option.id || ''}`}
              key={index}
              onPress={() => handleOptionPress(option)}
              width="100%"
            />
          );
        })}
      </ScrollView>
    </AnswerForm>
  );
};
