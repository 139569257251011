import { CountryCodes } from '@taxfix/types';

import { appDataFunction, configKey } from '../services/firebase-functions';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';

import * as Storage from './asyncStorage';
import { isWeb } from './platform';

type Options = {
  accessToken?: string;
  selectedCountry: CountryCodes;
};
export const OPT_OUT_FROM_TRACKING_SYNCED = 'tracking-opt-out-synced';
export const checkTrackingAvailability = async ({
  accessToken,
  selectedCountry,
}: Options): Promise<void> => {
  // on web we have the cookie-consent banner
  if (isWeb) return;
  try {
    // Sets false as default case the key is not present in async storage.
    const localConfig = (await Storage.getItem(configKey.optOutFromTracking)) || false;
    const remotelySynced = await Storage.getItem(OPT_OUT_FROM_TRACKING_SYNCED);

    /*
      Sets true as default case the key is not present in async storage.
      Users that didn't change the tracking option will not have this key set yet
      in this case doesn't need to update remote.
    */
    const isSynced = remotelySynced !== null ? remotelySynced : true;

    /*
      Check if local configuration is out of sync with firebase
      and updates firebase configs in case the local config is newer than the remote.
    */
    if (!isSynced && accessToken) {
      await appDataFunction.updateData({
        country: selectedCountry,
        accessToken,
        key: configKey.optOutFromTracking,
        updatedValue: localConfig,
        successHandler: async () => {
          await Storage.setItem(OPT_OUT_FROM_TRACKING_SYNCED, true);
        },
      });
    }

    /*
      This needs to be executed after login because it's necessary
      the user's access token to fetch user's app configs.
    */
    const isOptedOut = accessToken
      ? await appDataFunction.getData({
          country: selectedCountry,
          accessToken,
          key: configKey.optOutFromTracking,
          defaultValue: localConfig,
        })
      : localConfig;

    /*
      Analytics tracking is enabled by default
      so every time the user re-opens the app we need to
      deactivate tracking in case they opted-out.
    */
    Analytics.optOutTracking(isOptedOut);

    /*
      Firebase data is the single source of truth and
      case the local information differs from firebase
      we update the local info with the result fetched.
    */
    if (localConfig !== isOptedOut) {
      await Storage.setItem(configKey.optOutFromTracking, isOptedOut);
    }
  } catch (error) {
    Analytics.optOutTracking(true);
  }
};

type UpdateTrackingParams = {
  consent: boolean;
  userId: number;
  email: string;
  accessToken: string;
  selectedCountry?: CountryCodes;
  screenName: string;
};
export const updateTrackingConsent = async ({
  consent,
  userId,
  email,
  accessToken,
  selectedCountry,
  screenName,
}: UpdateTrackingParams): Promise<void> => {
  const optOut = !consent;
  await Storage.setItem(
    configKey.optOutFromTracking,
    selectedCountry === CountryCodes.DE ? consent : optOut,
  );

  Analytics.optOutTracking(optOut, async () => {
    /*
          Needs to identify the user in case they deleted the app,
          reinstall it and opt in on tracking because the anonymous id will change.
        */
    if (consent) {
      await Analytics.identifyRegisteredUser({ userId, accessToken }, { email });
    }

    Analytics.log(AnalyticsEvent.optOutTracking, {
      value: optOut,
      screenName,
    });

    /*
          Sync with firebase the config and in case the sync fails
          for some connection issue it is marked as out of sync.
          Next time the user sign in a new sync attempt is executed.
          With this approach the tracking is enabled or disable
          even if the sync with firebase fails.
        */
    if (accessToken) {
      appDataFunction.updateData({
        country: selectedCountry as CountryCodes,
        accessToken: accessToken,
        key: configKey.optOutFromTracking,
        updatedValue: consent,
        errorHandler: async () => {
          await Storage.setItem(OPT_OUT_FROM_TRACKING_SYNCED, false);
        },
        successHandler: async () => {
          await Storage.setItem(OPT_OUT_FROM_TRACKING_SYNCED, true);
        },
      });
    }
  });
};
