import * as React from 'react';
import RNProgressBar from 'react-native-progress/Bar';

import { theme } from './theme';

type ProgressBarProps = {
  progress?: number;
  indeterminate?: boolean;
  height?: number;
  borderRadius?: number;
  width?: number | null;
  color?: string;
  unfilledColor?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  indeterminate = false,
  height = 3,
  borderRadius = 0,
  color = theme.color.primary,
  unfilledColor = theme.color.border,
}) => {
  return (
    <RNProgressBar
      progress={progress} // null width to use automatic flexbox sizing.
      width={null}
      height={height}
      color={color}
      unfilledColor={unfilledColor}
      borderWidth={0}
      borderRadius={borderRadius}
      indeterminate={indeterminate}
    />
  );
};

export { ProgressBar };
