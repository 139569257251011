import { $PropertyType } from 'utility-types';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { StyleProp } from '../types';

import Box from './Box';
import { Button } from './Button';

export type ButtonConfig = {
  translationKey: string;
  translationValue?: any;
  value: any;
};
type Props = {
  buttons: ButtonConfig[];
  onSelect: (value: $PropertyType<ButtonConfig, 'value'>, index: number) => void;
  separation?: number;
  buttonsCustomStyle?: StyleProp;
  value?: any;
  testId?: string;
  disabled?: boolean;
  color?: string;
};
const styles = StyleSheet.create({
  button: {
    minHeight: 40,
    maxHeight: 40,
  },
});
export const ButtonGroup = ({
  buttons,
  onSelect,
  separation = 1,
  buttonsCustomStyle = {},
  value,
  testId,
  disabled = false,
  color,
}: Props) => {
  const [btnIndex, setBtnIndex] = useState<number>(buttons.findIndex((btn) => btn.value === value));

  const selectButton = (button: ButtonConfig, index: number) => {
    setBtnIndex(index);
    onSelect(button.value, index);
  };

  return (
    <Box top={1} bottom={1} direction="row">
      {buttons.map((btn, index) => (
        <Button
          type={btnIndex === index ? 'primary' : 'secondary'}
          style={StyleSheet.flatten([
            styles.button,
            {
              marginRight: index < buttons.length - 1 ? separation * 10 : 0,
            },
            buttonsCustomStyle,
          ])}
          disabled={disabled}
          key={btn.translationKey}
          testId={`${testId}::${index}`}
          translationKey={btn.translationKey}
          onPress={() => selectButton(btn, index)}
          typeColor={color}
        />
      ))}
    </Box>
  );
};
