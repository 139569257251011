import _ from 'lodash';
import { CountryCodes } from '@taxfix/types';

import { getTaxSeasonInfo } from 'src/services/tax-season';

import { getStore } from '../../src/stores/util';
import { selectors } from '../../src/stores/modules/settings';
import { selectors as firebaseSelectors } from '../../src/stores/modules/remote-config-firebase';

import privacyItalian from './it/privacy';
import preseasonPrivacyItalian from './it/privacy-preseason';
import termsItalian from './it/terms';
import termsPreseasonItalian from './it/terms-preseason';
import commercialistaItalian from './it/commercialista';
import commercialistaUniItalian from './it/commercialista-uni';
import amlItalian from './it/aml';

const getReduxState = () => {
  const store = getStore();

  if (!store) {
    throw new Error('Store is not defined');
  }
  return store.getState();
};

// TODO other country codes than IT may be removed as soon as we modify related screens where docs are used
type DocRecords = {
  IT?: { it: string; en: string; version?: string };
  DE?: { de: string; en: string; version?: string };
  FR?: { fr: string; en: string; version?: string };
  ES?: { es: string; en: string; version?: string };
};

type Docs = {
  privacy: DocRecords;
  terms: DocRecords;
  commercialista: DocRecords;
  aml: DocRecords;
};

export const docs = (): Docs => {
  const state = getReduxState();
  const isDeclarationTypeUNI: boolean = firebaseSelectors.isDeclarationTypeUNI(state);
  const taxSeasonInfo = getTaxSeasonInfo(state);
  const isPreseason = taxSeasonInfo.isPreSeason;

  return {
    terms: {
      IT: {
        it: isPreseason ? termsPreseasonItalian : termsItalian,
        en: isPreseason ? termsPreseasonItalian : termsItalian,
        version: isPreseason ? '4.1.0' : '5.0.0',
      },
    },
    commercialista: {
      IT: {
        it: isDeclarationTypeUNI ? commercialistaUniItalian : commercialistaItalian,
        en: commercialistaItalian,
        version: isDeclarationTypeUNI ? '5.2.0' : '5.1.1',
      },
    },
    privacy: {
      IT: {
        it: isPreseason ? preseasonPrivacyItalian : privacyItalian,
        en: isPreseason ? preseasonPrivacyItalian : privacyItalian,
        version: isPreseason ? '4.0.0' : '5.0.0',
      },
    },
    aml: {
      IT: {
        it: amlItalian,
        en: amlItalian,
      },
    },
  };
};

const localized = (type: string) => (locale: string, country?: CountryCodes) => {
  const state = getReduxState();
  const selectedCountry = country || selectors.selectedCountry(state);

  const [language] = locale.split('-');

  const docType = _.get(docs(), type);

  const countryDocs = _.get(docType, selectedCountry);

  return countryDocs?.[language as keyof typeof countryDocs] || countryDocs?.en;
};

export default {
  privacy: localized('privacy'),
  terms: localized('terms'),
  commercialista: localized('commercialista'),
  aml: localized('aml'),
};
