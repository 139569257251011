import * as React from 'react';

import { Box, TextInput, Icon, TextButton } from '../../components/core';
import FormField from '../../components/form-field-extended';

import { CouponInputErrors, getCouponInputErrorMessageKey } from './coupon-input-container.types';

type Props = {
  couponCode: string;
  isEditable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onCouponUpdate: (coupon: string) => any;
  onCouponReset: () => any;
  onCouponApply: () => any;
  onInputRef?: () => any;
  onSubmitEditing?: () => any;
  errorType?: CouponInputErrors;
  showReset?: boolean;
};
export const CouponInput = ({
  errorType,
  couponCode = '',
  isEditable = true,
  isDisabled = false,
  isLoading = false,
  showReset = true,
  onCouponUpdate,
  onCouponReset,
  onCouponApply,
  onInputRef,
  onSubmitEditing,
}: Props) => (
  <Box flex direction="row">
    <Box flex={1} expand right={2}>
      <FormField
        error={
          errorType
            ? {
                key: getCouponInputErrorMessageKey(errorType),
                level: 'Error',
              }
            : undefined
        }
      >
        {({ onBlur, onFocus }) => (
          <Box flex direction="row" alignVertically="center">
            <Box expand>
              <TextInput
                onSubmitEditing={onSubmitEditing}
                value={couponCode}
                onChangeText={onCouponUpdate}
                onBlur={onBlur}
                onFocus={onFocus}
                autoCapitalize="none"
                editable={isEditable && !isDisabled}
                style={{
                  height: '100%',
                }}
                ref={onInputRef}
                maxLength={125}
                testId="test.field.voucher"
                autoFocus={false}
              />
            </Box>
            {!isEditable && (
              <Box shrink>
                <Icon name="checkWhiteBackground" />
              </Box>
            )}
          </Box>
        )}
      </FormField>
    </Box>
    {!isDisabled && (
      <Box
        shrink
        style={{
          width: 75,
        }}
      >
        {isEditable ? (
          <TextButton
            disabled={!couponCode.trim()}
            size="small"
            onPress={onCouponApply}
            translationKey="submission.payment.coupon.apply-button"
            loading={isLoading}
            testId="test.button.voucher.apply"
          />
        ) : showReset ? (
          <TextButton
            size="small"
            onPress={onCouponReset}
            translationKey="submission.payment.coupon.reset-button"
          />
        ) : null}
      </Box>
    )}
  </Box>
);
