import * as React from 'react';
import * as _ from 'lodash';
import Config from 'react-native-config';
import { User } from '@taxfix/taxfix-sdk';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';
import { bindActionCreators } from 'redux';
import { Responses } from '@taxfix/quizmaster/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { CurrentUserResponse } from '@taxfix/taxfix-sdk/dist/user/current';
import { LoginResponse } from '@taxfix/taxfix-sdk/dist/user/login';
import jwtDecode from 'jwt-decode';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  actions as userAuthActions,
  Provider,
  selectors as userAuthSelectors,
} from 'src/stores/modules/user-auth';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { enableBioAuth } from 'src/utils/biometricAuth';
import { LoginError, LoginErrorKey } from 'src/screens/login/Login.types';
import { actions as UserActions, UserSelectors } from 'src/stores/modules/user';
import { actions as submissionActions } from 'src/stores/modules/submission';
import { getNavigationActions } from 'src/routes/config-util';
import {
  syncUploadAnswersForSelectedCountry,
  syncRetrieveAnswers,
} from 'src/services/sync-answers';
import { getUniqueDeviceID } from 'src/services/device';
import { actions as remoteConfigFirebaseActions } from 'src/stores/modules/remote-config-firebase';
import { checkTrackingAvailability } from 'src/utils/tracking';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useStores } from 'src/stores/hooks/mobx-stores-hook';
import { State } from 'src/stores/store/initial';
import { actions as userConsentsActions } from 'src/stores/modules/user-consents';
import { transparencyTracker } from 'src/utils/transparency-tracker';

import { useLogout } from '../logout-hook';

import { getProvider } from './utils';

export type LoginSsoPayload = {
  idToken: string;
  firstName?: string | null;
  lastName?: string | null;
};

type LoginFormPayload = {
  email: string;
  password: string;
};

type OtcPayload = {
  email: string;
  userId: number;
  otcCode: number;
};

type OtcOptions = {
  disableRedirect?: boolean;
};

export type OnOtcValidationRequiredType = {
  email: string;
  userId: number;
};

type LoginParams = LoginFormPayload | LoginSsoPayload;

export type UseLoginProps = {
  onConfirm: () => Promise<void>;
  errorKey?: LoginErrorKey;
  webDeviceId?: string;
};

type OnSuccess = () => void;

export type UseLogin = {
  isLoading: boolean;
  errorKey?: LoginErrorKey;
  handleLogin: (
    arg0: LoginFormPayload,
    onSuccess?: OnSuccess,
    onOtcRedirect?: (params: OnOtcValidationRequiredType) => void,
  ) => Promise<void>;
  handleSsoLogin: (arg0: LoginSsoPayload, onSuccess?: OnSuccess) => Promise<void>;
  handleOtcLogin: (arg0: OtcPayload, options?: OtcOptions) => Promise<void>;
  handleError: (arg0: LoginErrorKey | unknown, provider?: Provider) => void;
};

type AuthResponse = { isOtcValidationRequired: boolean; apiResponse: LoginResponse };

enum AuthType {
  sso = 'sso',
  form = 'form',
}

class SignInError extends Error {
  constructor(message?: string) {
    super(message ?? 'Sign in process failed');
    this.name = 'SignInError';
  }
}

const mapStateToProps = (stores: State) => ({
  selectedYear: settingsSelectors.selectedYear(stores),
  selectedCountry: CountryCodes.IT,
  isBioAuthSetup: UserSelectors.isBioAuthEnabled(stores),
  provider: userAuthSelectors.getProvider(stores),
  prevSessionEmail: userAuthSelectors.getEmailAddress(stores),
});

enum SdkErrorKey {
  userBlocked = 'user_blocked',
  userUnknown = 'user_unknown',
  ssoError = 'sso_error',
}

const transformSdkError = (sdkErrorKey: SdkErrorKey): LoginError => {
  switch (sdkErrorKey) {
    case SdkErrorKey.userBlocked:
      return LoginError.UserBlocked;
    case SdkErrorKey.userUnknown:
      return LoginError.UserUnknown;
    case SdkErrorKey.ssoError:
      return LoginError.LoginSsoError;
    default:
      return LoginError.LoginFailed;
  }
};

export const useLogin = ({
  onConfirm,
  webDeviceId,
  errorKey: errorKeyFromProps,
}: UseLoginProps): UseLogin => {
  const deviceId = React.useRef('');
  const currentUser = React.useRef<CurrentUserResponse>();
  const logOut = useLogout();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorKey, setErrorKey] = React.useState<LoginErrorKey>(null);
  const { navigationActions } = useNavigation();

  const { selectedCountry, isBioAuthSetup, provider, prevSessionEmail } =
    useSelector(mapStateToProps);
  const authProvider = React.useRef<Provider>(provider);
  const dispatch = useDispatch();
  const actions = React.useMemo(
    () =>
      bindActionCreators(
        {
          ...UserActions,
          ...submissionActions,
          updateUserFromResponse: userAuthActions.updateUserFromResponse,
          requestUpdateRemoteConfig: remoteConfigFirebaseActions.requestUpdate,
          updateProvider: userAuthActions.updateProvider,
          getSensitiveDataConsent: userConsentsActions.getSensitiveDataConsent,
        },
        dispatch,
      ),
    [dispatch],
  );
  const intl = useIntl();
  const { questionStores } = useStores();

  const handleOnConfirm = React.useCallback(() => {
    navigationActions.reset({
      index: 0,
      actions: [
        getNavigationActions().toUserLegalCheck('screen', {
          onConfirm,
        }),
      ],
    });
  }, [navigationActions, onConfirm]);

  const nativeLogin = React.useCallback(
    async (email: string, loginResponse: LoginResponse) => {
      // If user is logging in with a different email, make sure the previous user is logged out
      // and all the data that belonged to the previous user is deleted.
      if (prevSessionEmail && email !== prevSessionEmail) {
        await logOut({ skipRedirect: true, withBackendLogout: true });
      }
      const { accessToken } = loginResponse;
      actions.updateUserFromResponse(loginResponse, email, accessToken);

      try {
        await checkTrackingAvailability({
          accessToken,
          selectedCountry,
        });
      } catch (e) {
        logger.warn(new SignInError(e?.message), { error: e });
      }

      await Analytics.identifyRegisteredUser(
        { userId: loginResponse.id, accessToken },
        {
          email,
        },
      );
      Analytics.log(AnalyticsEvent.isEmailVerified, {
        isEmailVerified: !!loginResponse.confirmed,
      });
      Analytics.log(AnalyticsEvent.logInSuccess, {
        identityProvider: authProvider.current,
      });
    },
    [actions, prevSessionEmail, selectedCountry],
  );

  const handleAuthenticate = React.useCallback(
    async (email: string, loginResponse: LoginResponse): Promise<AuthResponse> => {
      const isOtcValidationRequired = Boolean(
        loginResponse && loginResponse.id && !loginResponse.accessToken,
      );

      if (!isOtcValidationRequired) {
        await nativeLogin(email, loginResponse);
      } else {
        Analytics.log(AnalyticsEvent.otcEntryRequested);
      }

      return {
        isOtcValidationRequired,
        apiResponse: loginResponse,
      };
    },
    [nativeLogin],
  );

  const enableBiometricAuth = React.useCallback(
    ({ email, password }: LoginFormPayload) => {
      if (!isBioAuthSetup) {
        // ask the user for permission to enable bio auth process
        const userData = {
          password,
          emailAddress: email,
          updateBioAuthPermission: actions.updateBioAuthPermission,
        };
        return enableBioAuth(userData, intl);
      }

      return true;
    },
    [actions.updateBioAuthPermission, intl, isBioAuthSetup],
  );

  const syncAnswers = async () => {
    try {
      // upload unsynced answers first
      await syncUploadAnswersForSelectedCountry();
      // retrieve answers
      await syncRetrieveAnswers();
    } catch (err) {
      logger.warn(new Error('Unable to sync user answers after login'), { error: err });
    }
  };

  const postLogin = React.useCallback(
    async (_: LoginResponse) => {
      actions.getSensitiveDataConsent();
      await syncAnswers();
      actions.requestUpdateRemoteConfig();
    },
    [actions],
  );

  const handleOtcRedirect = React.useCallback(
    async (
      apiResponse: LoginResponse,
      email: string,
      isOtcValidationRequired: boolean,
      onOtcRedirect?: (params: OnOtcValidationRequiredType) => void,
    ) => {
      // After a successful authentication register this device id if a deviceId was provided
      // by a dynamic link.
      if (webDeviceId) {
        await User.registerDevice(Config.API_BASE_URL, apiResponse.accessToken, {
          deviceId: deviceId.current,
        });
      }

      if (!isOtcValidationRequired) {
        handleOnConfirm();
      } else if (onOtcRedirect) {
        onOtcRedirect({ email, userId: apiResponse.id });
      } else {
        navigationActions.toOtcValidation('screen', {
          onConfirm,
          emailAddress: email,
          userId: apiResponse.id,
        });
      }
    },
    [handleOnConfirm, navigationActions, onConfirm, webDeviceId],
  );

  const getEmail = (params: LoginParams): string => {
    if ('idToken' in params) {
      return jwtDecode<{ email: string }>(params.idToken).email;
    }

    return params.email;
  };

  const handleError = (error: unknown, provider?: Provider) => {
    if (error) {
      Analytics.log(AnalyticsEvent.logInFailed, {
        identityProvider: provider,
      });
      const transformedErrorKey = transformSdkError(
        _.get(error, 'response.data.message', SdkErrorKey.userUnknown),
      );
      logger.warn(new SignInError(), { error, provider, transformedErrorKey });
      setErrorKey(transformedErrorKey);
    } else {
      setErrorKey(null);
    }
    setIsLoading(false);
    Keyboard.dismiss();
  };

  const callLogin = React.useCallback(
    (authType: AuthType) =>
      async (
        params: LoginParams,
        onSuccess?: OnSuccess,
        onOtcRedirect?: (params: OnOtcValidationRequiredType) => void,
      ) => {
        setIsLoading(true);
        setErrorKey(null);
        Keyboard.dismiss();

        try {
          // If a user downloaded the app via a Firebase dynamic link a deviceId might already have
          // been provided. We use that one to avoid triggering the otc flow since the user is already
          // on the same device.
          const email = getEmail(params);
          const currentDeviceId = webDeviceId || deviceId.current;
          let result: LoginResponse;
          switch (authType) {
            case AuthType.form:
              {
                const { password } = params as LoginFormPayload;

                authProvider.current = Provider.email;

                result = await User.loginDevice(Config.API_BASE_URL, {
                  email,
                  pwd: password,
                  deviceId: currentDeviceId,
                  countryCode: selectedCountry,
                });

                actions.updateProvider(Provider.email);
              }
              break;
            case AuthType.sso:
              {
                const { idToken } = params as LoginSsoPayload;
                const provider = getProvider(idToken);

                authProvider.current = provider;

                result = await User.loginSSO(Config.API_BASE_URL, {
                  deviceId: currentDeviceId,
                  idToken,
                });

                actions.updateProvider(provider);
              }
              break;
          }

          const { apiResponse, isOtcValidationRequired } = await handleAuthenticate(email, result);

          if (!isOtcValidationRequired) {
            if ('password' in params) {
              await enableBiometricAuth({
                email,
                password: params.password,
              });
            }
            await postLogin(apiResponse);
            if (onSuccess) onSuccess();
          }

          await handleOtcRedirect(apiResponse, email, isOtcValidationRequired, onOtcRedirect);
        } catch (error: any) {
          let errorResponse = error;
          switch (authType) {
            case AuthType.sso:
              errorResponse = { response: { data: { message: SdkErrorKey.ssoError } } };
              handleError(errorResponse, authProvider.current);
              break;

            default:
              handleError(error, authProvider.current);
              break;
          }
          if (!error.response && !error.request) {
            logger.error(new SignInError(error?.message), { error, authType });
          }
        } finally {
          setIsLoading(false);
        }
      },
    [actions, enableBiometricAuth, handleAuthenticate, handleOtcRedirect, postLogin, webDeviceId],
  );

  // TODO: extract to a separate hook
  const handleOtcAuthenticate = async ({ email, userId, otcCode }: OtcPayload) => {
    const loginResponse = await User.otcValidation(Config.API_BASE_URL, {
      userId,
      deviceId: deviceId.current,
      otc: otcCode,
    });
    Analytics.log(AnalyticsEvent.otcEntrySuccessful);
    await nativeLogin(email, loginResponse);
    return loginResponse;
  };

  const handleOtcLogin = async (
    { email, userId, otcCode }: OtcPayload,
    options?: OtcOptions,
  ): Promise<void> => {
    setIsLoading(true);
    Keyboard.dismiss();

    try {
      const loginResponse = await handleOtcAuthenticate({
        email,
        userId,
        otcCode,
      });

      await postLogin(loginResponse);
      if (!options?.disableRedirect) {
        handleOnConfirm();
      }
    } catch (error: any) {
      Analytics.log(AnalyticsEvent.otcEntryFailed);
      Keyboard.dismiss();
      throw new SignInError('Error handling OTC authentication');
    } finally {
      setIsLoading(false);
    }
  };

  // didMount behaviour expected. Don't add any exhaustive deps.
  React.useEffect(() => {
    const setupATTAndDeviceID = async () => {
      await transparencyTracker();
      const id = await getUniqueDeviceID();
      deviceId.current = id;
    };

    setupATTAndDeviceID();
  }, []);

  return {
    isLoading,
    errorKey: errorKey || errorKeyFromProps,
    handleLogin: callLogin(AuthType.form),
    handleSsoLogin: callLogin(AuthType.sso),
    handleOtcLogin,
    handleError,
  };
};
