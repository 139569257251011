/* global __DEV__ */
import * as React from 'react';
import { StyleSheet, View, Platform, LayoutAnimation } from 'react-native';
import { IntlShape, injectIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import Quizmaster, { isQuestion } from '@taxfix/quizmaster';
import inputsForQuestion from '@taxfix/quizmaster/dist/calculator/inputsForQuestion';
import { Answer, Id, TreeNode } from '@taxfix/quizmaster/dist/types';
import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';

import { Container, Text } from 'src/taxfix-components/src';
import { Response } from 'src/_italy/quizmaster/components/response';
import { InformationButton } from 'src/components/information-button';

import QuestionStore from '../../../stores-legacy/QuestionStore';
import { withQuestionStore } from '../../../utils/withQuestionStore';
import Summary from '../../../containers/answer/summaries-it';
import translationValuesWithDefaults, {
  KeysAndValues,
} from '../../../utils/translationValuesWithDefaults';
import { translationKeys } from '../../../i18n';
import {
  WithInfoButtonTooltipProps,
  withInfoButtonTooltip,
} from '../../../hocs/with-info-button-tooltip';
import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';
import { setItem, getItem } from '../../../utils/asyncStorage';
import { shouldHideMoreInfoIconFor } from '../../../common/question-flow-exceptions';
import { WithNavigation, withNavigation } from '../../../hocs/with-navigation';
import { isMobileScreen, isWeb } from '../../../utils/platform';
import { SkippedQuestionsMessage } from '../../../components/skipped-questions-message';
import { Loading } from '../../../components/loading';
import { QuestionNavigator as DebugQuestionNavigator } from '../../../components/question-navigator';
import QuestionAndAnswer from '../../../components/answer/question-and-answer';
import Transitions from '../../../components/answer/transitions';
import {
  SetIsLoading,
  TriggerScroll,
  QuestionMasterScroll,
} from '../../../components/question-master-scroll';
import { Box, theme } from '../../../components/core';

type Props = {
  intl: IntlShape;
  root: Id;
  editing: boolean;
  hideIntroCard?: boolean;
  skippable?: boolean;
  shouldAutoCompleteOnMount?: boolean;
  onAnswer: (node: TreeNode, answer: Answer) => VoidFunction;
  onEdit: (node: TreeNode, onResponseWithTransitions: any) => any;
  onInformation: (node: TreeNode, translationValues: KeysAndValues) => void;
  onSkip: (node: TreeNode) => void;
  onComplete?: (id: Id) => void;
  summaries?: React.ReactNode;
  questionStore: QuestionStore;
  year: number;
  children?: React.ReactNode;
  isWhoAreYou?: boolean;
  isAuthenticated: boolean;
  showPrefilledSummaryContent: boolean;
  prefilledTranslationKeys?: {
    title: string;
    subtitle: string;
  };
  onMoreInfoPressed: () => void;
  hideSummary: boolean;
  skipFinalSummary: boolean;
} & WithNavigation &
  WithInfoButtonTooltipProps;

enum CompletionStatus {
  NOT_STARTED = 'NOT_STARTED',
  INCOMPLETE = 'INCOMPLETE',
  RESPONDED = 'RESPONDED',
  ANSWERED = 'ANSWERED',
}

type State = {
  index: number;
  categoryId: Id | null | undefined;
  completionStatus: CompletionStatus;
  isLoading: boolean;
  scrollViewEnabled: boolean;
  value: Answer;
  showResponse: boolean;
  hasSeenSwipeTutorial: boolean;
  prevIndex: number;
  canShowTooltipTutorial: boolean;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    paddingBottom: 10,
    fontSize: 20,
  },
  summaries: {
    flex: isMobileScreen ? 1 : undefined,
    paddingHorizontal: !isMobileScreen ? 0 : 20,
  },
  questionContainerWeb: {
    flex: isMobileScreen ? undefined : 1,
    paddingBottom: 20,
  },
  question: {
    margin: 10,
  },
});

const cardColor = theme.color.lightFill;
const primaryTextColor = theme.color.primaryText;
const secondaryTextColor = theme.color.primary;
const subtitleLineSeparator = '• ';

export class QuestionMaster extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      categoryId: null,
      completionStatus: CompletionStatus.NOT_STARTED,
      index: 0,
      isLoading: true,
      scrollViewEnabled: true,
      value: null,
      showResponse: true,
      hasSeenSwipeTutorial: true,
      // eslint-disable-next-line react/no-unused-state
      prevIndex: 0,
      canShowTooltipTutorial: false,
    };
    (this as any).onPressNext = this.handlePressNext.bind(this);
    (this as any).onPressPrevious = this.handlePressPrevious.bind(this);
    (this as any).handleComplete = this.handleComplete.bind(this);
    (this as any).handleAnimationStateChange = this.handleAnimationStateChange.bind(this);
    (this as any).load = this.load.bind(this);
  }
  state: State;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.questionStoreUpdateHandler = autorun(() => {
      if (this.props.questionStore.responses == null) {
        return;
      }

      this.setState(() => this.stateForProps(this.props));
    });
  }

  componentDidMount(): void {
    setTimeout(this.load, 0);

    if (this.props.hideIntroCard) {
      Analytics.log(AnalyticsEvent.categoryStarted, {
        questionId: (this.quizmaster as any).tree.questionId,
        year: this.props.year,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(this.stateForProps(nextProps));
  }

  componentDidUpdate(_prevProps: Props, prevState: State): void {
    try {
      const previousQuestion: any = this.quizmaster?.questions[prevState.index];
      const previousQuestionId = previousQuestion ? previousQuestion.id : null;
      this.trackAnswerPerformance(previousQuestionId); // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  componentWillUnmount(): void {
    if (this.questionStoreUpdateHandler) {
      this.questionStoreUpdateHandler();
      this.questionStoreUpdateHandler = null;
    }
  }

  answeringStartTimestamp: number | null = null;

  questionStoreUpdateHandler?: (() => void) | null;

  transitions: Transitions | null | undefined = null;

  quizmaster?: Quizmaster;

  handleQuestionAnswer = async (node: TreeNode, answer: Answer): Promise<void> => {
    this.answeringStartTimestamp = Date.now();
    await this.props.onAnswer(node, answer);

    if (
      this.quizmaster?.hasAllAnswers() &&
      (this.state.prevIndex === 0 || this.props.skipFinalSummary)
    ) {
      this.handleComplete();
    }
  };

  userHasSeenTutorial = (): void => {
    setItem('hasSeenSwipeTutorial', {
      hasSeenSwipeTutorial: true,
    });
    this.setState({
      hasSeenSwipeTutorial: true,
    });
  };

  setHasSeenSwipeTutorial = async (): Promise<boolean | void> => {
    try {
      const hasSeenSwipeTutorial = await getItem('hasSeenSwipeTutorial');
      return Boolean(hasSeenSwipeTutorial);
    } catch (error) {
      return Promise.resolve(false);
    }
  };

  handleEdit = (node: TreeNode): void => {
    const { onEdit } = this.props;
    onEdit(node, this.transitions && this.transitions.handleResponse);
  };

  handleInformation = (node: TreeNode, translationValues: KeysAndValues) => (): void => {
    const { information } = node;

    if (typeof this.props.onInformation === 'function' && information != null) {
      if (information.translationKey == null) {
        return;
      }

      const content = this.props.intl.formatMessage(
        {
          id: information.translationKey,
        },
        translationValues,
      );

      if (content == null) {
        return;
      }

      const nodeWithContent = {
        ...node,
        information: {
          type: information.type,
          content,
        },
      };
      this.props.onInformation(nodeWithContent, translationValues);
    }
  };

  handleValueChange = (value: Answer): void => {
    this.setState({
      value,
    });
  };

  handleSkip = (node: TreeNode) => (): void => {
    this.props.onSkip(node);
  };

  handleStartSkip = (): void => {
    this.setState({
      showResponse: false,
    });
  };

  easeInAndOut = (nextIndex: number, prevIndex: number): void => {
    if (nextIndex !== prevIndex && nextIndex !== 0 && Platform.OS === 'ios') {
      LayoutAnimation.easeInEaseOut();
    }
  };

  handleTransitionReady = (): void => {
    if (!this.props.hasSeenInfoButtonTooltip) {
      this.setState({
        canShowTooltipTutorial: true,
      });
    }
  };

  handleAnimationStateChange(isAnimating: boolean): void {
    this.setState({
      scrollViewEnabled: !isAnimating,
    });
  }

  handlePressNext(): void {
    if (this.quizmaster && this.state.index >= this.quizmaster.questions.length - 1) {
      return;
    }

    const nextIndex = this.quizmaster?.questions.findIndex(
      (node, index) => isQuestion(node) && index > this.state.index,
    );

    if (nextIndex === -1) {
      return;
    }

    this.setState({
      completionStatus: this.completionStatus(),
      index: nextIndex as number,
    });
  }

  handlePressPrevious(): void {
    if (this.state.index <= 0) {
      return;
    }

    const copy = this.quizmaster?.questions.slice(0, this.state.index).reverse();
    const previous = copy?.find(
      (node) =>
        this.quizmaster &&
        isQuestion(node) &&
        this.quizmaster.questions.indexOf(node) < this.state.index,
    );

    if (previous == null) {
      return;
    }

    const previousIndex = this.quizmaster?.questions.indexOf(previous);

    if (previousIndex === -1) {
      return;
    }

    this.setState({
      completionStatus: this.completionStatus(),
      index: previousIndex as number,
    });
  }

  handleComplete() {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete(this.props.root);
    }
  }

  completionStatus() {
    if (this.quizmaster?.hasAllAnswers()) {
      return CompletionStatus.ANSWERED;
    }

    if (this.quizmaster?.hasAllResponses()) {
      return CompletionStatus.RESPONDED;
    }

    if (this.quizmaster?.isNotStarted()) {
      return CompletionStatus.NOT_STARTED;
    }

    return CompletionStatus.INCOMPLETE;
  }

  stateForProps(props: Props): any {
    this.quizmaster = props.questionStore.quizmaster(
      props.root,
      !!props.editing,
      props.questionStore.year,
    );
    const { index: prevIndex, value } = this.state;
    const index = this.quizmaster.nextQuestionIndex();
    const isSameQuestion = prevIndex === index;
    const answer = isSameQuestion && value ? value : this.quizmaster.answerAt(index);
    this.easeInAndOut(index, this.state.index);

    return {
      categoryId: props.editing ? null : this.quizmaster.tree?.id,
      completionStatus: this.completionStatus(),
      index,
      value: answer,
      showResponse: index !== this.state.index ? true : this.state.showResponse,
      prevIndex,
    };
  }

  private transformSubtitle(subtitle: string) {
    if (!subtitle.includes(subtitleLineSeparator)) {
      return subtitle;
    }

    // separate the text based on the subtitleLineSeparator
    // then join it by adding a line separator '\n' to each line
    // e.g. "• 1• 2• 3" => "• 1\n• 2\n• 3"
    const textSeparated = subtitle
      .split(subtitleLineSeparator)
      .slice(1)
      .map((line) => subtitleLineSeparator + line)
      .join('\n');

    return textSeparated;
  }

  handleQuestionSubtitle(
    questionTranslationKey: string | undefined,
    translationValues: KeysAndValues,
  ): { questionTransformed: string; subtitleTransformed: string | undefined } {
    const { intl } = this.props;
    const questionValue = intl.formatMessage(
      {
        id: questionTranslationKey,
      },
      translationValues,
    );
    const parts = questionValue.split('#SUBTITLE#');
    let subtitleTransformed: string | undefined = undefined;
    let questionTransformed: string = questionValue;

    if (parts && parts.length > 1) {
      questionTransformed = parts[0];
      subtitleTransformed = this.transformSubtitle(parts[1]);
    }
    return {
      questionTransformed,
      subtitleTransformed,
    };
  }

  renderQuestion = (
    question: TreeNode,
    setIsLoading: SetIsLoading,
    triggerScroll: TriggerScroll,
  ) => {
    const {
      editing,
      hasSeenInfoButtonTooltip,
      onInfoButtonTooltipClosed,
      onInfoButtonTooltipOpened,
      questionStore,
      questionStore: { refs = {} },
      year,
      intl,
    } = this.props;
    const parameters: any = {
      questionId: question.questionId,
      year,
    };

    Analytics.log(AnalyticsEvent.question, parameters);
    const inputs = this.quizmaster?.responses
      ? inputsForQuestion(
          question,
          refs,
          this.quizmaster.responses,
          this.quizmaster?.inputResolverCache,
          questionStore.year,
        )
      : null;

    const loopQuestion =
      !isEmpty(refs) && question.loopContext ? refs[question.loopContext.loopId] : false;

    const isPersonLoop = (_loopQuestion: any) =>
      _loopQuestion && _loopQuestion.tags && _loopQuestion.tags.includes('person-loop');

    const loopQuestionTranslationKey =
      loopQuestion && !isPersonLoop(loopQuestion)
        ? translationKeys(loopQuestion.questionId).short
        : undefined;
    const translationValues = translationValuesWithDefaults(inputs, {
      question,
      year,
    });
    const questionTranslationKey = (question.translationKeys || {}).question;
    const { questionTransformed, subtitleTransformed } = this.handleQuestionSubtitle(
      questionTranslationKey,
      translationValues,
    );
    const handleInformation = question.information
      ? this.handleInformation(question, translationValues)
      : undefined;
    const questionElementId = 'questionElement';
    return (
      <View
        onLayout={() => {
          triggerScroll(questionElementId);
        }}
        style={isWeb ? styles.questionContainerWeb : undefined}
        nativeID={questionElementId}
      >
        <Transitions
          ref={(ref) => {
            this.transitions = ref;
          }}
          onAnswer={this.handleQuestionAnswer}
          onSkip={this.handleSkip(question)}
          showTransition={!editing}
          delay={250}
          onFinish={this.handleTransitionReady}
        >
          {({ onAnswer, onSkip, isLoading }) => {
            setIsLoading(isLoading);
            if (isWeb && isLoading) return null;
            const response = (
              <Response
                year={year}
                country={CountryCodes.IT}
                question={question}
                answerValue={this.state.value}
                onAnswer={onAnswer}
                onChange={this.handleValueChange}
                inputs={inputs}
                editing={editing}
                isLoading={isLoading}
                color={cardColor}
              />
            );

            return (
              <QuestionAndAnswer
                top={0}
                bottom={!isMobileScreen ? 4 : 0}
                left={0}
                right={0}
                type="normal"
                onSkip={onSkip}
                skippable={false}
                onHandleStartSkip={this.handleStartSkip}
                onInformation={!shouldHideMoreInfoIconFor(question.questionId) && handleInformation}
                informationPositionTop={false}
                informationPositionWithText={true}
                informationText={intl.formatMessage({ id: 'it.season-question-flow.info.text' })}
                onAnimationStateChange={this.handleAnimationStateChange}
                hasSeenSwipeTutorial={this.state.hasSeenSwipeTutorial}
                questionTranslationKey={questionTranslationKey}
                subtitleTransformed={subtitleTransformed}
                questionTransformed={questionTransformed}
                loopQuestionTranslationKey={loopQuestionTranslationKey}
                translationValues={translationValues}
                isLoading={isLoading}
                cardColor={cardColor}
                primaryTextColor={primaryTextColor}
                secondaryTextColor={secondaryTextColor}
                shouldShowInfoButtonTooltip={false}
                onInfoButtonTooltipOpened={onInfoButtonTooltipOpened}
                onInfoButtonTooltipClosed={onInfoButtonTooltipClosed}
                customCardInnerStyle={{
                  paddingHorizontal: !isMobileScreen ? 0 : 20,
                }}
                responseComponent={response}
              />
            );
          }}
        </Transitions>
      </View>
    );
  };

  trackAnswerPerformance(questionId: string | null) {
    if (this.answeringStartTimestamp != null) {
      const ts = this.answeringStartTimestamp;
      Analytics.log(AnalyticsEvent.questionFlowTransition, {
        questionId,
        year: this.props.year,
        duration: Date.now() - ts,
      });
      this.answeringStartTimestamp = null;
    }
  }

  async load() {
    const hasSeenSwipeTutorial = await this.setHasSeenSwipeTutorial();
    this.setState({
      isLoading: false,
      ...this.stateForProps(this.props),
      hasSeenSwipeTutorial,
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return <Loading />;
    }

    const intl = (id: string) => this.props.intl.formatMessage({ id });
    let title, subtitle;
    if (this.props.prefilledTranslationKeys) {
      title = this.props.prefilledTranslationKeys.title;
      subtitle = this.props.prefilledTranslationKeys.subtitle;
    }
    const infoText = intl('it.season-question-flow.info.text');
    const intlTitle = title && intl(title);
    const intlSubtitle = subtitle && intl(subtitle);

    return (
      <View style={styles.container}>
        <Box flex={1}>
          <QuestionMasterScroll
            scrollEnabled={this.state.scrollViewEnabled}
            animatedOnContentChange
          >
            {({ setIsLoading, triggerScroll }) => (
              <View style={styles.container}>
                <View style={styles.summaries}>
                  {this.props.showPrefilledSummaryContent && (
                    <Container paddingBottom={2}>
                      <Text variant="titleSBold" value={intlTitle} paddingBottom={1} />
                      <Text variant="titleXSBook" value={intlSubtitle} paddingBottom={1} />
                      <InformationButton
                        color={theme.color.primary}
                        size="xsmall"
                        withText
                        informationText={infoText}
                        onPress={this.props.onMoreInfoPressed}
                      />
                    </Container>
                  )}

                  {this.props.summaries}
                  {!this.props.hideSummary && (
                    <Box>
                      <Summary
                        year={this.props.year}
                        root={this.props.root}
                        onEdit={this.handleEdit}
                      />
                    </Box>
                  )}
                </View>
                {this.quizmaster?.questions[this.state.index] &&
                  this.renderQuestion(
                    this.quizmaster.questions[this.state.index],
                    setIsLoading,
                    triggerScroll,
                  )}
                {this.props.children}
                {this.state.completionStatus === CompletionStatus.RESPONDED && (
                  <SkippedQuestionsMessage />
                )}
              </View>
            )}
          </QuestionMasterScroll>
        </Box>
        {__DEV__ ? (
          <Box>
            <DebugQuestionNavigator
              onComplete={this.handleComplete}
              onPrevious={this.handlePressPrevious}
              onNext={this.handlePressNext}
              current={this.state.index + 1}
              total={this.quizmaster?.questions.length ?? 0}
            />
          </Box>
        ) : null}
      </View>
    );
  }
}
export default compose<any>(
  withNavigation,
  withQuestionStore,
  withInfoButtonTooltip('WAY'),
  injectIntl,
  observer,
)(QuestionMaster);
