import React, { useCallback, useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { TextInputMask } from 'react-native-masked-text';
import { compose } from 'redux';

import { getTaxSeasonInfo } from 'src/services/tax-season';

import { useNavigation } from '../../hooks/navigation-hook';
import { testID } from '../../common/testID';
import { PDField, PDFields } from '../../stores/modules/user-profile';
import FormField from '../../components/form-field-extended';
import {
  Box,
  TextInput,
  Section,
  titleTextStyle,
  theme,
  useLargeScreenQuery,
} from '../../components/core';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';
import { wayQuestionIds, prefillQuestionIds } from '../../common/constants-it';

type Props = {
  quizmasterLight: QuizmasterLight;
};

const inputTitleStyle = [
  titleTextStyle,
  {
    color: theme.color.primaryText,
  },
];

export const ProfileDetailsContainerUndecorated: React.FC<Props> = ({ quizmasterLight }) => {
  const { navigationActions } = useNavigation();
  const isLargeScreen = useLargeScreenQuery();
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const isPreseason = useSelector(getTaxSeasonInfo).isPreSeason;

  const firstName = quizmasterLight[wayQuestionIds.firstName].answer;
  const lastName = quizmasterLight[wayQuestionIds.lastName].answer;
  const phoneNumber = quizmasterLight[wayQuestionIds.phoneNumber].answer;

  const taxIdQuestionId = isPreseason
    ? prefillQuestionIds.preseasonTaxId
    : prefillQuestionIds.taxId;
  const taxId = quizmasterLight[taxIdQuestionId]?.answer;

  const PDFieldToQuestionId = useMemo(() => {
    return {
      [PDFields.firstName]: wayQuestionIds.firstName,
      [PDFields.lastName]: wayQuestionIds.lastName,
      [PDFields.phoneNumber]: wayQuestionIds.phoneNumber,
      [PDFields.taxId]: taxIdQuestionId,
    };
  }, []);

  const navigateToEdit = useCallback<(arg0: PDField) => void>(
    (PDFieldName: PDField) => {
      navigationActions.toProfileEdit('screen', {
        value: quizmasterLight[PDFieldToQuestionId[PDFieldName]].answer,
        onSubmit: (newValue: string) => {
          quizmasterLight[PDFieldToQuestionId[PDFieldName]].saveAnswer(newValue);

          navigationActions.back();
        },
        PDFieldName,
        selectedCountry,
      });
    },
    [navigationActions, quizmasterLight, selectedCountry, PDFieldToQuestionId],
  );

  return (
    <Section
      size="wide"
      style={
        isLargeScreen
          ? {
              marginTop: 70,
            }
          : undefined
      }
    >
      <Box flex>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          keyboardShouldPersistTaps="handled"
          {...testID('scrollview::ProfileDetails::screen')}
        >
          <Box top={2} bottom={2} right={2} left={2} flex>
            <Box bottom={3}>
              <TouchableOpacity onPress={() => navigateToEdit(PDFields.firstName as any)}>
                <FormField
                  label={{
                    key: 'account.profile.screen.input1.label',
                  }}
                  // @ts-ignore
                  labelStyle={inputTitleStyle}
                  labelTextTransform="uppercase"
                >
                  {() => (
                    <TextInput
                      value={firstName}
                      style={{
                        flex: 1,
                      }}
                      maxLength={86}
                      pointerEvents="none"
                      editable={false}
                    />
                  )}
                </FormField>
              </TouchableOpacity>
            </Box>
            <Box bottom={3}>
              <TouchableOpacity onPress={() => navigateToEdit(PDFields.lastName as any)}>
                <FormField
                  label={{
                    key: 'account.profile.screen.input2.label',
                  }}
                  // @ts-ignore
                  labelStyle={inputTitleStyle}
                  labelTextTransform="uppercase"
                >
                  {() => (
                    <TextInput
                      value={lastName}
                      style={{
                        flex: 1,
                      }}
                      maxLength={86}
                      pointerEvents="none"
                      editable={false}
                    />
                  )}
                </FormField>
              </TouchableOpacity>
            </Box>
            <Box bottom={3}>
              <TouchableOpacity onPress={() => navigateToEdit(PDFields.phoneNumber as any)}>
                <FormField
                  label={{
                    key: 'account.profile.screen.input3.label',
                  }}
                  // @ts-ignore
                  labelStyle={inputTitleStyle}
                  labelTextTransform="uppercase"
                >
                  {() => (
                    <TextInput
                      value={phoneNumber}
                      style={{
                        flex: 1,
                      }}
                      maxLength={86}
                      pointerEvents="none"
                      editable={false}
                    />
                  )}
                </FormField>
              </TouchableOpacity>
            </Box>
            <Box bottom={3}>
              <TouchableOpacity onPress={() => navigateToEdit(PDFields.taxId as any)}>
                <FormField
                  label={{
                    key: 'account.profile.screen.input4.label',
                  }}
                  // @ts-ignore
                  labelStyle={inputTitleStyle}
                  labelTextTransform="uppercase"
                >
                  {() => (
                    <TextInputMask
                      options={{
                        mask: 'SSS SSS SSSSS SSSSS',
                      }}
                      type="custom"
                      value={taxId}
                      editable={false}
                      pointerEvents="none"
                    />
                  )}
                </FormField>
              </TouchableOpacity>
            </Box>
          </Box>
        </ScrollView>
      </Box>
    </Section>
  );
};
export const ProfileDetailsContainer = compose(
  withQuizmasterLight([
    wayQuestionIds.firstName,
    wayQuestionIds.lastName,
    wayQuestionIds.phoneNumber,
    prefillQuestionIds.preseasonTaxId,
    prefillQuestionIds.taxId,
  ]),
)(ProfileDetailsContainerUndecorated as any);
