import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';

import { Box } from '../components/core';
import { AppNavigator } from '../routes/app-navigator';
import { overlayScreens } from '../routes/config-overlay';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { WithAppStateContextType, withAppStateContext } from '../hocs/with-app-state-context';

import OverlayNavigator from './overlay-navigator';

type Props = WithAppStateContextType & {
  selectedCountry: CountryCodes;
};
export const NavigatorContainer = (props: Props) => {
  const { locked } = props.appStateContext;
  return (
    <Box flex={1}>
      <AppNavigator country={props.selectedCountry} />
      {/*
      //@ts-ignore */}
      <OverlayNavigator screens={overlayScreens} locked={locked} />
    </Box>
  );
};

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
});

export default compose<any>(
  withAppStateContext,
  connect(mapStateToProps, null),
)(NavigatorContainer);
