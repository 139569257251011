import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { IntlShape, injectIntl } from 'react-intl';
import { CountryCodes } from '@taxfix/types';

import policyContent from '../../../assets/docs';
import { privacyStyles } from '../../../assets/templates/custom-styles/privacy';
import AcceptTermsUI from '../../components/core/AcceptTerms';
import { injectLocaleInfo } from '../../i18n/locale-info';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';

const trackingMapping = {
  privacy: AnalyticsEvent.privacyPolicyPressed,
  terms: AnalyticsEvent.termsAndConditionsPressed,
  commercialista: AnalyticsEvent.commercilistaPolicyPressed,
};
export type Policy = 'terms' | 'privacy' | 'commercialista';
type Props = {
  onTermsChange: (arg0: boolean) => void;
  termsAccepted: boolean;
  onTermsClicked?: () => void;
  intl: IntlShape;
  year: number;
  translationKey?: string;
  textComponent?: React.ComponentType<any>;
  linkComponent?: React.ComponentType<any>;
  country?: CountryCodes;
} & WithNavigation;

class UndecoratedAcceptTerms extends PureComponent<Props> {
  handleInformation = (type: Policy) => {
    const { country } = this.props;

    const content = policyContent[type];
    const { navigationActions } = this.props;

    if (content != null) {
      Analytics.log(trackingMapping[type], {
        year: this.props.year,
      });

      if (type === 'terms' || type === 'commercialista') {
        navigationActions.toInformation('modal', {
          content: content(this.props.intl.locale, country),
        });
      }

      if (type === 'privacy') {
        navigationActions.toPrivacyPolicy('modal', {
          content: content(this.props.intl.locale, country),
          contentStyles: privacyStyles,
        });
      }
    }
  };

  render() {
    const { onTermsClicked, translationKey, linkComponent, textComponent } = this.props;
    const handleOnTerms = onTermsClicked || this.handleInformation;
    return (
      <AcceptTermsUI
        linkComponent={linkComponent}
        textComponent={textComponent}
        onTerms={handleOnTerms}
        translationKey={translationKey}
      />
    );
  }
}

export const AcceptTerms: any = compose(
  injectIntl,
  injectLocaleInfo,
  withNavigation,
)(UndecoratedAcceptTerms);
