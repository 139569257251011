import * as React from 'react';
import { CountryCodes } from '@taxfix/types';

import { State as RootState } from 'src/stores/store/initial';
import { DownloadAppContainer } from 'src/screens/download-app/download-app-container';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { Routes } from 'src/routes/routes';
import { getRoute, Tracking } from 'src/routes/navigate';

type ActionParams = never;

const screenTracking = (
  state: RootState,
  { countryCode }: Record<string, CountryCodes>,
): Tracking => ({
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: `DownloadApp${countryCode}`,
  },
});

const DownloadAppScreen = (): JSX.Element => <DownloadAppContainer />;

export const { action: toDownloadAppScreen, screen: downloadAppScreen } = getRoute<ActionParams>({
  routeName: Routes.DownloadScreen,
  tracking: screenTracking,
  screenComponent: DownloadAppScreen,
});

export default DownloadAppScreen;
