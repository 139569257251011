import React, { useState, useCallback, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { useBreakpointValue } from 'native-base';

import { Container } from 'src/taxfix-components/src';
import { useToastBannerToggler } from 'src/components/toast-banner';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { withQuizmasterLight, QuizmasterLight } from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';

import { WithSubmitItaly, withSubmitItaly } from '../../hocs/with-submit-italy';
import { SubmissionConsent, withSubmissionConsent } from '../../hocs/with-submission-consent';
import { actions as statusActions } from '../../stores/modules/status';
import {
  selectors as SubmissionSelectors,
  actions as SubmissionActions,
} from '../../stores/modules/submission';
import { CheckboxRow } from '../../components/checkbox/checkbox-row';
import { Button, theme } from '../../components/core';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { Banner } from '../../components/toast';
import { logErrorMessages } from '../../common/log-error-messages';

type Props = WithSubmitItaly &
  SubmissionConsent & {
    quizmasterLight: QuizmasterLight;
    translationKey: string;
    requestRefreshStatus: () => Dispatch<any>;
  };

const mapDispatchToProps = (dispatch: any) => ({
  requestRefreshStatus: bindActionCreators(statusActions.requestRefreshStatus, dispatch),
});

const ButtonWithCheckBox = ({
  translationKey,
  createSubmission,
  requestRefreshStatus,
  createSubmissionConsentDocument,
  isConsentGiven,
  quizmasterLight,
}: Props) => {
  const onResubmissionConsentGiven =
    quizmasterLight[flagsQuestionIds.onResubmissionConsentGiven].answer;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isConsentChecked, setConsentChecked] = useState<boolean>(onResubmissionConsentGiven);
  const documentCreationError = useSelector(SubmissionSelectors.error);
  const { safeResetNavigation, getNavigationActions } = useNavigation();
  const dispatch = useDispatch();
  const { showBanner } = useToastBannerToggler();
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const height = useBreakpointValue({ base: '164px', md: '96px' });
  const alignItems = useBreakpointValue({ base: undefined, md: 'center' });
  const shadow = useBreakpointValue({ base: 'sticky2', md: 'sticky1' });

  const showErrorBanner = useCallback(
    (errorId: string) => {
      showBanner({
        contentView: <Banner titleId={errorId} />,
        backgroundColor: theme.color.errorBackground,
        duration: 7000,
      });
    },
    [showBanner],
  );

  useEffect(() => {
    if (
      documentCreationError &&
      documentCreationError.id &&
      typeof documentCreationError.id === 'string'
    ) {
      showErrorBanner(documentCreationError.id);
    }
  }, [documentCreationError]);

  useEffect(() => {
    return () => {
      dispatch(SubmissionActions.createError({ id: '' }));
    };
  }, []);

  const handleConsentCheck = useCallback(
    (flag: boolean) => {
      if (!isConsentChecked) {
        Analytics.log(AnalyticsEvent.consentPermissionClicked);
      }
      setConsentChecked(flag);
      quizmasterLight[flagsQuestionIds.onResubmissionConsentGiven].saveAnswer(!isConsentChecked);
      dispatch(statusActions.requestRefreshStatus());
    },
    [isConsentChecked],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      if (!isConsentGiven) {
        await createSubmissionConsentDocument();
      }
      await createSubmission();
      requestRefreshStatus();
      quizmasterLight[flagsQuestionIds.areDocumentConfirmedOnResubmission].saveAnswer(false);
      quizmasterLight[flagsQuestionIds.onResubmissionConsentGiven].saveAnswer(false);
      quizmasterLight[flagsQuestionIds.isConfirmationAnsweredOnResubmission].saveAnswer(false);
    } catch (error) {
      logger.error(error as Error, {
        message: logErrorMessages.resubmissionError,
      });
    }
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  }, [
    createSubmissionConsentDocument,
    createSubmission,
    isConsentGiven,
    requestRefreshStatus,
    quizmasterLight,
    safeResetNavigation,
    getNavigationActions,
  ]);

  return (
    <Container
      flexDir={flexDirection}
      justifyContent="center"
      alignItems={alignItems}
      height={height}
      paddingX="20px"
      paddingY="14px"
      shadow={shadow}
    >
      <CheckboxRow
        isChecked={isConsentChecked}
        isDisabled={isLoading}
        onChange={handleConsentCheck}
        translationKey="status.it.submit.resubmission.consent"
        testId="submission.checkbox.confirm.data"
        style={{
          maxWidth: 550,
          justifyContent: isSmallScreen ? 'flex-start' : 'center',
          content: { alignVertically: 'center' },
        }}
      />
      <Button
        type={'primary'}
        onPress={handleSubmit}
        translationKey={translationKey}
        floating
        loading={isLoading}
        disabled={!isConsentChecked}
        testId={translationKey}
        style={{
          flex: 1,
          maxWidth: isSmallScreen ? undefined : 200,
          maxHeight: isSmallScreen ? 32 : 52,
          marginLeft: isSmallScreen ? 0 : 110,
        }}
      />
    </Container>
  );
};

export const SubmitButtonWithCheckBox: any = compose(
  withSubmitItaly,
  withSubmissionConsent,
  withQuizmasterLight([
    flagsQuestionIds.areDocumentConfirmedOnResubmission,
    flagsQuestionIds.onResubmissionConsentGiven,
    flagsQuestionIds.expensesUploadSkipped,
    flagsQuestionIds.isConfirmationAnsweredOnResubmission,
  ]),
  connect(null, mapDispatchToProps),
)(ButtonWithCheckBox);
