/*
 * NOTES
 * This file exists to prevent errors caused by @stripe/stripe-react-native trying to initialize
 * itself on web environment. Because of this file, web platform will use this file
 * and will not call the @stripe/stripe-react-native library.
 *
 * In the future, if web platform wanted to use similar native approach, we can use
 * the https://github.com/stripe/react-stripe-js library.
 */

export const PaymentCreditCardComponentNative = (): null => {
  return null;
};
