export type PrivacyConfig = {
  name: string;
  translationKey: string;
};
export const privacyConfig: PrivacyConfig[] = [
  {
    name: 'terms',
    translationKey: 'account.accept-terms.terms',
  },
];
