import { $Keys } from 'utility-types';
import { CountryCodes } from '@taxfix/types';

export type PostSubmissionScreenVariant = $Keys<typeof PostSubmissionScreenVariants>;

export type Notification = {
  image: string;
  title: string;
  content: string;
  primaryButtonText: string;
};

export enum SubmissionStatus {
  editAddress = 'edit-address',
  submitted = 'submitted',
  reIdentify = 're-identify',
  deleted = 'deleted',
  prepaidAndUnavailable = 'prepaid-and-unavailable',
  unsupported = 'unsupported',
  closed = 'closed',
  started = 'started',
  completed = 'completed',
  unavailable = 'unavailable',
  reset = 'reset',
  instantRefundPending = 'instant-refund-pending',
  received = 'received',
  unstarted = 'unstarted',
  error = 'error',
  onReview = 'onReview',
}

export const CategoryNodeId = {
  Onboarding: 'intro',
  WhoAreYou: '1479980423020',
  Income: 'e2ba323d87a63f0edcbd',
  Home: '84f22d163a42f22805e5',
  Work: '940a9978eea5cef31be7',
  Training: 'dce4dbe3a926bb207f7f',
  Family: '0ef9d4b00a376a96793d',
  HealthCare: '2dd38b82c7a33ed27ae4',
  Financials: '48563353df163a089513',
  PersonalDetails: '779d9bfc25fe50223405',
  Difm: '21ed7ea32f67895bdd8c',
};

export const PostSubmissionScreenVariants = Object.freeze({
  default: 'default',
  variant: 'variant',
  assessment: 'assessment',
});

export const Notifications: Record<string, Notification> = Object.freeze({
  DEFAULT: {
    image: 'notificationsOnIt',
    title: 'onboarding.notifications.title',
    content: 'onboarding.notifications.content',
    primaryButtonText: 'onboarding.notifications.primary-button',
  },
  DE_BASELINE: {
    image: 'notificationConsent.calendar',
    title: 'onboarding.notifications.v2.title',
    content: 'onboarding.notifications.v2.content',
    primaryButtonText: 'onboarding.notifications.v2.primary-button',
  },
});

export const MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB = 10;

export const MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION =
  MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB * 1024 * 1024;

export const MAX_FILE_SIZE_ERROR_CODE = 'uploading.file-size-limit-error';

export const FILE_TYPE_NOT_PDF_ERROR = 'uploading.file-type-error';

export const PDF_PASSWORD_PROTECTED_ERROR = 'uploading.password-protected-pdf';

export const MAX_FILES_BATCH = 10;

export const CategoryName = {
  [CategoryNodeId.Onboarding]: 'onboarding',
  [CategoryNodeId.WhoAreYou]: 'introduction',
  [CategoryNodeId.Income]: 'income',
  [CategoryNodeId.Home]: 'home',
  [CategoryNodeId.Work]: 'work',
  [CategoryNodeId.Training]: 'training',
  [CategoryNodeId.Family]: 'family',
  [CategoryNodeId.HealthCare]: 'healthcare',
  [CategoryNodeId.Financials]: 'financials',
  [CategoryNodeId.PersonalDetails]: 'personal-details',
  [CategoryNodeId.Difm]: 'difm',
};

export const WEB_CONTAINER_WIDTH = 1180;

export const WEB_SIDBEAR_WIDTH = 280;

export const WEB_CONTENT_WIDTH = 900;

export const WEB_HEADER_LARGE_HEIGHT = 75;

export const WEB_HEADER_SMALL_HEIGHT = 50;

export const WEB_SECTION_NARROW_WIDTH = 400;

export const WEB_SECTION_WIDE_WIDTH = 700;

export const WEB_SECTION_LEFT_MARGIN = 100;

export const WEB_SECTION_RIGHT_MARGIN = 80;

export const DELETED_BY_USER_MESSAGE = 'Submission deleted by user';

export const ANSWER_TYPES = {
  DE_PAYSLIP: 'De.Payslip',
};

export default {
  TaxYear: 2016,
  IntroductionCategoryNodeId: 'intro',
  WhoAreYouCategoryNodeId: CategoryNodeId.WhoAreYou,
};

export const quizmasterSyncAnswersCountries = [CountryCodes.IT];
