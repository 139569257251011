import React from 'react';

import { CommonResultComponent } from './CommonResult';

type Props = {
  onOpenStore: () => any;
  onDismiss: () => void;
};
export const ResultPromoterComponent = ({ onDismiss, onOpenStore }: Props) => {
  return (
    <CommonResultComponent
      title="feedback-modal.promoter.title"
      subtitle={'feedback-modal.promoter.subtitle'}
      ctaTranslationKey={'feedback-modal.promoter.cta'}
      dismissTranslationKey={'feedback-modal.promoter.button.dismiss'}
      onCtaPress={onOpenStore}
      onDismiss={onDismiss}
    />
  );
};
