// tag users who have seen free flow
export const migration = (state: Record<string, any>) => {
  const { franceSimulation } = state;

  if (franceSimulation && franceSimulation.DATA_VERSION === 1) {
    return {
      ...state,
      franceSimulation: {
        ...state.franceSimulation,
        migrations: {
          hasSeenFreeFlowAvailable: true,
        },
      },
    };
  }

  return state;
};
