// Overlay containers
import { DigicafOccupationUnsupportedOverlayContainer } from 'src/containers/italy-digicaf-occupation-unsupported-overlay';
import { EndOfSeasonOverlay } from 'src/_italy/_components/overlays/end-of-season';
import { QuestionITGuidanceOverlay } from 'src/containers/questions-it-guidance-overlay';
import { ItalyPostSubmissionOverlay } from 'src/_italy/_components/italy-post-submission-overlay';
import { IdentityUploadOverlay } from 'src/_italy/id-document-upload/components/identity-upload-overlay';
import { SPIDError } from 'src/_italy/prefill-experiment/components/spid-error-overlay';
import { UnsupportedSPIDOverlay } from 'src/_italy/prefill-experiment/components/unsupported-spid-overlay';
import { CompletePrefillTestOverlay } from 'src/_italy/prefill-experiment/components/complete-prefill-test-overlay';
import { PrefillDocumentsResultInfoOverlay } from 'src/_italy/prefill-documents-result/components/prefill-documents-result-info-overlay';
// This component has two separate implementations for mobile and web
import { TaxGuideOverlay } from 'src/_italy/tax-guide/tax-guide-overlay';
import { PrefilledQuestionFlowInfoOverlay } from 'src/containers/question-flow-prefilled-overlay';
import { ExpenseItemDeleteOverlay } from 'src/screens/containers/required-documents/expense-item-delete-overlay';
import { CUDocumentResultInfoOverlay } from 'src/screens/cu-documents-result/cu-document-result-info-overlay';
import { SPIDConsentDocumentsInfo } from 'src/screens/spid-consent-sign/spid-consent-documents-info-overlay';
import { PrefillDocumentsResultListInfoOverlay } from 'src/containers/prefill-documents-list-info-overlay';
import { PopupOverlay } from 'src/screens/components/popup-overlay';

import { FeedbackModal } from '../containers/FeedbackModal/FeedbackModal';
import SelectOverlay from '../components/input/select/overlay';
import ButtonOverlay from '../components/input/button-overlay';
import InfoOverlay from '../components/info-overlay';
import { UploadProgressOverlayContainer } from '../containers/upload-progress-overlay';
import { MultipleUploadProgressOverlayContainer } from '../containers/multiple-upload-progress-overlay';
import { AlertOverlay } from '../components/alert-overlay';
import { OverlayHighlightWithCaptionAndButton } from '../components/overlay-highlight/with-caption-and-button';
import { OverlayHighlightWithCaption } from '../components/overlay-highlight/with-caption';
import { OnboardingSplashReferralOverlay } from '../components/onboarding-splash-referral-overlay';
import { LoadingOverlay } from '../components/loading-overlay';
import { LanguageSelectionOverlay } from '../components/language-selection-overlay';
import { SuccessOverlay } from '../components/success-overlay';
import { ChatPopupOverlay } from '../components/chat-popup-overlay-italy';
import { ReferralLinkPopup } from '../components/referral-link-popup.web';
import { DocumentUploadGuidanceOverlay } from '../containers/document-upload-guidance-overlay';
import { UploadProgressOverlay } from '../components/upload-progress-overlay';
import { DocumentUploadInfoOverlay } from '../containers/document-upload-info-overlay';
import { MoreInfoOverlay } from '../_italy/product-bundle/more-info-overlay/more-info-overlay';
import { LoadingOverlayWithLogo } from '../screens/components/loading-overlay-with-logo';
import { ResultOptimizationInfoOverlay } from '../containers/result-optimization-info-overlay';
import { ResultScreenIdleHelpPopup } from '../screens/components/result-screen-idle-help-popup';

export const overlayScreens = [
  {
    name: 'Feedback',
    component: FeedbackModal,
  },
  {
    name: 'SelectOverlay',
    component: SelectOverlay,
  },
  {
    name: 'ButtonOverlay',
    component: ButtonOverlay,
  },
  {
    name: 'InfoOverlay',
    component: InfoOverlay,
  },
  {
    name: 'PrefillDocumentsResultInfoOverlay',
    component: PrefillDocumentsResultInfoOverlay,
  },
  {
    name: 'AlertOverlay',
    component: AlertOverlay,
  },
  {
    name: 'UploadOverlay',
    component: UploadProgressOverlay,
  },
  {
    name: 'UploadProgressOverlay',
    component: UploadProgressOverlayContainer,
  },
  {
    name: 'MultipleUploadProgressOverlay',
    component: MultipleUploadProgressOverlayContainer,
  },
  {
    name: 'HighlightOverlay',
    component: OverlayHighlightWithCaption,
  },
  {
    name: 'HighlightWithButtonOverlay',
    component: OverlayHighlightWithCaptionAndButton,
  },
  {
    name: 'IdentityUploadOverlay',
    component: IdentityUploadOverlay,
  },
  {
    name: 'OnboardingSplashReferralOverlay',
    component: OnboardingSplashReferralOverlay,
  },
  {
    name: 'LoadingOverlay',
    component: LoadingOverlay,
  },
  {
    name: 'CUDocumentResultInfoOverlay',
    component: CUDocumentResultInfoOverlay,
  },
  {
    name: 'DocumentUploadGuidanceOverlay',
    component: DocumentUploadGuidanceOverlay,
  },
  {
    name: 'DocumentUploadInfoOverlay',
    component: DocumentUploadInfoOverlay,
  },
  {
    name: 'QuestionITGuidanceOverlay',
    component: QuestionITGuidanceOverlay,
  },
  {
    name: 'LanguageSelectionOverlay',
    component: LanguageSelectionOverlay,
  },
  {
    name: 'SuccessOverlay',
    component: SuccessOverlay,
  },
  {
    name: 'LoadingOverlayWithLogo',
    component: LoadingOverlayWithLogo,
  },
  { name: 'ChatPopup', component: ChatPopupOverlay },
  { name: 'ReferralLinkPopup', component: ReferralLinkPopup },
  { name: 'ItalyPostSubmissionOverlay', component: ItalyPostSubmissionOverlay },
  {
    name: 'DigicafOccupationUnsupportedOverlay',
    component: DigicafOccupationUnsupportedOverlayContainer,
  },
  { name: 'EndOfSeasonOverlay', component: EndOfSeasonOverlay },
  { name: 'TaxGuideOverlay', component: TaxGuideOverlay },
  { name: 'SPIDError', component: SPIDError },
  { name: 'UnsupportedSPIDOverlay', component: UnsupportedSPIDOverlay },
  { name: 'CompletePrefillTestOverlay', component: CompletePrefillTestOverlay },
  { name: 'PrefilledQuestionFlowInfoOverlay', component: PrefilledQuestionFlowInfoOverlay },
  { name: 'MoreInfoProductBundleOverlay', component: MoreInfoOverlay },
  { name: 'ExpenseItemDeleteOverlay', component: ExpenseItemDeleteOverlay },
  { name: 'SPIDConsentDocumentsInfo', component: SPIDConsentDocumentsInfo },
  {
    name: 'PrefillDocumentsResultListInfoOverlay',
    component: PrefillDocumentsResultListInfoOverlay,
  },
  {
    name: 'ResultOptimizationInfoOverlay',
    component: ResultOptimizationInfoOverlay,
  },
  {
    name: 'ResultScreenIdleHelpPopup',
    component: ResultScreenIdleHelpPopup,
  },
  {
    name: 'PopupOverlay',
    component: PopupOverlay,
  },
];
