import React from 'react';
import { StyleSheet } from 'react-native';

import { Box, TextButton, theme } from 'src/components/core';

import { isWeb } from '../utils/platform';

import { StyleProp } from './types';

const styles = StyleSheet.create({
  backButtonBorder: {
    borderWidth: 2,
    borderColor: theme.color.primary,
    borderRadius: 6,
  },
});
type Props = {
  onPress: () => void;
  translationKey?: string;
  withBorder?: boolean;
  style?: StyleProp;
};
export const BackButton = ({ onPress, translationKey, withBorder = false, style }: Props) => {
  const backButtonTranslationKey =
    (translationKey && translationKey) ||
    (isWeb ? 'web.footer.previous' : 'account.create.header.back');
  const containerStyles = {
    ...(withBorder ? styles.backButtonBorder : {}),
    ...style,
  };
  return (
    <Box top={1} bottom={1} style={containerStyles}>
      <TextButton translationKey={backButtonTranslationKey} onPress={onPress} />
    </Box>
  );
};
