import { useTheme } from 'native-base';
import React from 'react';

import { icons } from '../../../Icons/Icons';
import { Container } from '../../layout/Container';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { Text } from '../Text';

import { StyledHeaderTaxAdvisorProps } from './types';

/**
 * display the photo, the name and the job of the tax advisor
 */

export const HeaderTaxAdvisor: React.FC<StyledHeaderTaxAdvisorProps> = ({
  photo,
  name,
  job,
  iconSize = 6,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Container flexDirection="row" justifyContent="space-between" paddingBottom={2} {...otherProps}>
      <Image path={photo} size={62} />
      <Container ml="18px">
        <Container flexDirection="row">
          <Text variant="titleXSMedium" marginRight="6px">
            {name}
          </Text>
          <Icon path={icons.certified} size={iconSize} color={colors.brand.brandGreen} />
        </Container>
        <Text variant="bodySBook" color={colors.greytones.textLight}>
          {job}
        </Text>
      </Container>
    </Container>
  );
};
