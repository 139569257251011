import React from 'react';

import { withHiddenOnKeyboard } from '../../hocs/with-hidden-on-keyboard';
import { StyleProp } from '../types';
import { ScreenName } from '../../types/screen-name';

type Props = {
  containerStyle?: StyleProp;
  screenName: ScreenName;
  position?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  variant?: 'colored' | 'mono';
};

export const ChatBubbleUndecorated = ({
  containerStyle,
  screenName,
  position,
  variant = 'colored',
}: Props) => {
  return <></>;
};
export const ChatBubble = withHiddenOnKeyboard<Props>(ChatBubbleUndecorated);
