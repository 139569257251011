import React, { Component } from 'react';
import { Text, View } from 'react-native';

import { SmallLinkText } from './Text';

type Props = {
  numberOfLines?: number;
  children?: React.ReactElement<React.ComponentProps<any>, any>;
  onPressViewMore?: () => void;
  expandButtonId?: string;
};
type State = {
  measured: boolean;
  shouldShowReadMore: boolean;
  showAllText: boolean;
};
export default class ExpandText extends Component<Props, State> {
  text: Text | null | undefined;

  static defaultProps = {
    expandButtonId: 'more.info',
  };

  state = {
    measured: false,
    shouldShowReadMore: false,
    showAllText: false,
  };

  componentDidMount() {
    this.startMeasure();
  }

  startMeasure = async () => {
    await this.nextFrameAsync();
    // Get the height of the text with no restriction on number of lines
    const fullHeight = await this.measureHeightAsync(this.text);
    this.setState({
      measured: true,
    });
    await this.nextFrameAsync();
    // Get the height of the text now that number of lines has been set
    const limitedHeight = await this.measureHeightAsync(this.text);

    if (fullHeight > limitedHeight) {
      this.setState({
        shouldShowReadMore: true,
      });
    }
  };

  measureHeightAsync = (component: Text | null | undefined): Promise<any> =>
    new Promise((resolve) => {
      if (component) {
        component.measure((x: any, y: any, w: any, h: any) => {
          resolve(h);
        });
      }
    });

  // eslint-disable-next-line no-undef
  nextFrameAsync = (): Promise<any> => new Promise((resolve) => requestAnimationFrame(resolve));

  render() {
    const { measured, showAllText } = this.state;
    const { numberOfLines, onPressViewMore } = this.props;
    return (
      <View>
        <Text
          numberOfLines={measured && !showAllText ? numberOfLines : 0}
          ref={(text: Text | null | undefined) => {
            this.text = text;
          }}
        >
          {this.props.children}
        </Text>
        {this.state.shouldShowReadMore && (
          <SmallLinkText id={this.props.expandButtonId} onPress={onPressViewMore} />
        )}
      </View>
    );
  }
}
