import React from 'react';
import { ImageStyle, StyleSheet, View } from 'react-native';

import { theme, BodyTitleText, Icon, Box, SmallSecondaryText } from './core';
import { StyleProp } from './types';

export type ToastProps = {
  iconName?: string | null | undefined;
  tintColor?: string;
  iconColor?: string;
  bgColor?: string;
  titleId: string;
  translationValues?: Record<string, any>;
  style?: StyleProp;
  iconStyle?: ImageStyle;
  titleStyle?: StyleProp;
};

const Banner = ({
  iconName = 'mono.icon-nav-arrow-up',
  iconStyle,
  tintColor = theme.color.lightText,
  titleId,
  titleStyle,
  translationValues,
  style,
  ...props
}: ToastProps) => {
  return (
    <View
      {...props}
      style={[
        {
          flex: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 20,
          ...(style || {}),
        },
      ]}
    >
      <BodyTitleText
        color={tintColor}
        id={titleId}
        values={translationValues}
        style={titleStyle || {}}
      />
      {iconName && <Icon name={iconName} style={iconStyle} tintColor={tintColor} />}
    </View>
  );
};

const styles = StyleSheet.create({
  alertBanner: {
    backgroundColor: theme.color.orangeBackground,
    color: theme.color.secondaryText,
    borderRadius: 8,
  },
  statusIcon: {
    width: 25,
    height: 25,
  },
});

const Toast = ({
  iconName,
  iconStyle,
  tintColor = theme.color.warningBackground,
  iconColor,
  bgColor = theme.color.orangeBackground,
  titleId,
  translationValues,
  style,
}: ToastProps) => (
  <Box style={[styles.alertBanner, { backgroundColor: bgColor }, style]}>
    <Box top={1.5} bottom={1.5} left={2} right={1.5} direction="row" alignVertically="center">
      {iconName && (
        <Box right={1}>
          <Icon
            name={iconName}
            style={StyleSheet.flatten([styles.statusIcon, iconStyle])}
            tintColor={iconColor ?? tintColor}
            resizeMode="contain"
          />
        </Box>
      )}
      <Box flex>
        <SmallSecondaryText id={titleId} color={tintColor} values={translationValues} />
      </Box>
    </Box>
  </Box>
);

export { Banner, Toast };
