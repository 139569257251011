import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { Share, Platform, PermissionsAndroid, InteractionManager, Clipboard } from 'react-native';
import RNFetchBlob from 'react-native-blob-util';
import _ from 'lodash';
import { injectIntl, IntlShape } from 'react-intl';
import inputsForQuestion from '@taxfix/quizmaster/dist/calculator/inputsForQuestion';

import { WithToastBannerTogglerProps, withToastBannerToggler } from 'src/components/toast-banner';
import QuestionStore from 'src/stores-legacy/QuestionStore';
import translationValuesWithDefaults from 'src/utils/translationValuesWithDefaults';
import { translationKeys } from 'src/i18n';
import initial from 'src/stores/store/initial';
import { Banner } from 'src/components/toast';
import { theme } from 'src/components/core';

import { questionStoresIT, deleteAllAnswersIT } from '../../stores-legacy';
import { withSyncAnswers } from '../../hocs/with-sync-answers';
import { SyncMetaMap } from '../../stores/utils/sync-answers';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { getSyncMetaMapCountryPath } from '../../stores/modules/sync-answers';

import DebugResponses, { Data, Section } from './debug-responses-view';

type State = {
  isLoading: boolean;
  sectionConfigs: Record<
    string,
    {
      show: boolean;
      reverse: boolean;
      searchTerm: string;
    }
  >;
};

const mapStateToProps = (stores: any) => ({
  selectedCountry: settingsSelectors.selectedCountry(stores),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type Props = {
  onImport: () => void;
  syncMetaMap: SyncMetaMap;
  syncMetaMapAllCountries: typeof initial.syncAnswers;
  intl: IntlShape;
} & ReduxProps &
  WithToastBannerTogglerProps;

class UndecoratedDebugResponsesContainer extends React.Component<Props, State> {
  state: State = {
    isLoading: true,
    sectionConfigs: {},
  };

  async componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      const questionStoreKeys = Object.keys(this.getQuestionStores());

      for (let i = 0; i < questionStoreKeys.length; i += 1) {
        const questionStore: QuestionStore = this.getQuestionStores()[questionStoreKeys[i]];
        questionStore.runCleanRebuild();
      }

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isLoading: false,
        sectionConfigs: questionStoreKeys.reduce((acc, year) => {
          return { ...acc, [year]: { show: false, reverse: false, searchTerm: '' } };
        }, {}),
      });
    });
  }

  getQuestionStores = () => questionStoresIT;

  getData = (questionStore: QuestionStore): Data[] => {
    const { syncMetaMapAllCountries, selectedCountry }: any = this.props;
    const syncMetaMapThisCountry =
      syncMetaMapAllCountries[getSyncMetaMapCountryPath(selectedCountry)];
    const { cache, responses, refs, year, answers } = questionStore;
    if (refs == null) return [];

    return Object.keys(questionStore.answers).map((key, index) => {
      const question = refs[key];
      const answer = answers[key];
      const syncMeta = syncMetaMapThisCountry[`${year}.${key}`];
      let translationValues;
      try {
        const inputs = inputsForQuestion(question, refs, responses, cache, year);
        translationValues = translationValuesWithDefaults(inputs, {
          question,
          year,
        });
      } catch (e) {}

      const questionTranslationKey = translationKeys(key).short;
      const questionText =
        translationValues && questionTranslationKey
          ? this.props.intl.formatMessage(
              {
                id: questionTranslationKey,
              },
              translationValues,
            )
          : '';

      return {
        id: key,
        answer: JSON.stringify(answer),
        syncMeta: JSON.stringify(syncMeta),
        question: questionText,
        index,
      };
    });
  };

  getSections = (): Section[] => {
    const questionStores = this.getQuestionStores();

    return Object.keys(this.state.sectionConfigs)
      .filter((year) => questionStores[year])
      .sort()
      .reverse()
      .map((year) => {
        const { show, searchTerm, reverse } = this.state.sectionConfigs[year];
        const questionStore = questionStores[year];

        let data: Data[] = [];
        if (show) {
          data = this.getData(questionStore);
        }
        const hasAnswers = Boolean(data.length);
        if (searchTerm) {
          data = data.filter((item) => {
            const { id, question, answer } = item;
            return (
              id.includes(searchTerm) ||
              question.includes(searchTerm) ||
              answer.includes(searchTerm)
            );
          });
        }
        if (reverse) {
          data = data.reverse();
        }
        return {
          year,
          show,
          reverse,
          data,
          hasAnswers,
        };
      });
  };

  handleRemoveResponse = (year: string, id: string) => {
    this.getQuestionStores()[year].deleteResponse(id);
    this.forceUpdate();
  };

  handleRemoveAllResponses = (yearToDel?: string) => {
    deleteAllAnswersIT(yearToDel);
    this.forceUpdate();
  };

  handleExportAllResponses = async (yearToExport?: string): Promise<string> => {
    const fileData = {
      version: 2,
      responses: [],
      timestamp: new Date().toISOString(),
    };

    if (yearToExport && this.getQuestionStores()[yearToExport]) {
      // @ts-ignore
      fileData.responses = [
        ...fileData.responses,
        ...Object.values(this.getQuestionStores()[yearToExport].responsesJS),
      ];
    } else {
      Object.keys(this.getQuestionStores()).forEach((year) => {
        // @ts-ignore
        fileData.responses = [
          ...fileData.responses,
          ..._.flatMap(this.getQuestionStores()[year].responsesJS, (res) => [res]),
        ];
      });
    }

    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: 'Taxfix export txfx files',
          message: 'Taxfix App needs access to your storage to export txfx files.',
          buttonPositive: 'OK',
        },
      );

      if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
        throw new Error('Storage permission denied');
      }
    }

    const filename = `export_${Date.now()}.txfx`;
    let filePath;

    if (Platform.OS === 'ios') {
      filePath = `${RNFetchBlob.fs.dirs.DocumentDir}/Inbox/${filename}`;
    } else {
      filePath = `${RNFetchBlob.fs.dirs.DownloadDir}/${filename}`;
    }

    await RNFetchBlob.fs.writeFile(filePath, JSON.stringify(fileData), 'utf8');

    if (Platform.OS === 'ios') {
      Share.share({
        url: `file://${filePath}`,
      });
    }

    return filename;
  };

  toggleSection = (year: string) => {
    this.setState((prevState) => ({
      sectionConfigs: {
        ...prevState.sectionConfigs,
        [year]: {
          ...(prevState.sectionConfigs[year] || {}),
          show: !prevState.sectionConfigs[year]?.show,
          searchTerm: '',
        },
      },
    }));
  };

  reverseSection = (year: string) => {
    this.setState((prevState) => ({
      sectionConfigs: {
        ...prevState.sectionConfigs,
        [year]: {
          ...(prevState.sectionConfigs[year] || {}),
          reverse: !prevState.sectionConfigs[year]?.reverse,
        },
      },
    }));
  };

  handleSearchTermChange = (year: string, searchTerm: string) => {
    this.setState((prevState) => ({
      sectionConfigs: {
        ...prevState.sectionConfigs,
        [year]: {
          ...(prevState.sectionConfigs[year] || {}),
          searchTerm: searchTerm,
        },
      },
    }));
  };

  handleAnswerPressed = (string: string) => {
    Clipboard.setString(string);
    this.props.showBanner({
      contentView: (
        <Banner titleId="Copied id, question and answer to clippoard" style={{ padding: 20 }} />
      ),
      backgroundColor: theme.color.primary,
    });
  };

  render() {
    const { onImport } = this.props;
    const { isLoading } = this.state;
    const sections = this.getSections();
    return (
      <DebugResponses
        onRemoveResponse={this.handleRemoveResponse}
        onRemoveAllResponses={this.handleRemoveAllResponses}
        onExportAllResponses={this.handleExportAllResponses}
        onImport={onImport}
        sections={sections}
        toggleSection={this.toggleSection}
        reverseSection={this.reverseSection}
        handleSearchTermChange={this.handleSearchTermChange}
        handleAnswerPressed={this.handleAnswerPressed}
        isLoading={isLoading}
        intl={this.props.intl}
      />
    );
  }
}

export const DebugResponsesContainer = compose(
  withSyncAnswers,
  withToastBannerToggler,
  connector,
  injectIntl,
)(UndecoratedDebugResponsesContainer);
