import * as React from 'react';

import {
  UseRegister,
  useRegister,
  UseRegisterProps,
  SignUpFormPayload,
} from 'src/hooks/auth/register-hook';

export type { SignUpFormPayload as SignupParams };
export type WithRegister = UseRegister;

type WithRegisterWrapperType<T> = (props: Omit<T, keyof WithRegister>) => React.ReactElement<T>;

type Props<T> = UseRegisterProps & T;

function withRegister<T extends WithRegister = WithRegister>(
  WrappedComponent: React.ComponentType<T>,
): WithRegisterWrapperType<T> {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithRegisterWrapper = (props: Omit<Props<T>, keyof WithRegister>) => {
    const { handleSignup } = useRegister();

    return <WrappedComponent {...(props as T)} handleSignup={handleSignup} />;
  };

  WithRegisterWrapper.displayName = `withRegister(${displayName})`;

  return WithRegisterWrapper;
}

export default withRegister;
