/* eslint-disable @typescript-eslint/no-empty-function */
import { Platform } from 'react-native';
// import { Trace } from '@react-native-firebase/perf';

const perf = Platform.OS !== 'web' && require('@react-native-firebase/perf').default;

export type PerformanceAttributes = Record<string, string>;
export type PerformanceMetrics = Record<string, number>;
const MockTrace = {
  stop: () => {},
  putAttribute: () => {},
  putMetric: () => {},
  getAttribute: () => undefined,
  getMetric: () => undefined,
};
export class TraceDecorator {
  static registry: any = {};

  trace: any; //Trace;

  label: string;

  constructor(label: string, trace: any) {
    this.label = label;
    this.trace = trace;
    TraceDecorator.registry[this.label] = this;
  }

  stop() {
    this.trace.stop();
    delete TraceDecorator.registry[this.label];
  }

  putAttribute(label: string, value: string) {
    this.trace.putAttribute(label, value);
  }

  putMetric(label: string, value: number) {
    this.trace.putMetric(label, value);
  }

  getMetric(label: string) {
    return this.trace.getMetric(label);
  }

  getAttribute(label: string) {
    return this.trace.getAttribute(label);
  }

  attachAttributesAndMetrics(attributes?: PerformanceAttributes, metrics?: PerformanceMetrics) {
    if (attributes)
      Object.entries(attributes).map((attribute) =>
        this.trace.putAttribute(attribute[0], attribute[1]),
      );
    if (metrics)
      Object.entries(metrics).map((metric) => this.trace.putMetric(metric[0], metric[1]));
  }

  static async start(
    label: string,
    attributes?: PerformanceAttributes,
    metrics?: PerformanceMetrics,
  ) {
    const trace = perf ? await perf().startTrace(label) : MockTrace;
    const decoratedTrace = new TraceDecorator(label, trace);
    decoratedTrace.attachAttributesAndMetrics(attributes, metrics);
    return decoratedTrace;
  }

  static get(label: string) {
    return TraceDecorator.registry[label];
  }
}
export const startPerformanceTrace = async (
  label: string,
  attributes?: PerformanceAttributes,
  metrics?: PerformanceMetrics,
) => {
  return TraceDecorator.start(label, attributes, metrics);
};
export const stopPerformanceTraceWithLabel = (
  label: string,
  attributes?: PerformanceAttributes,
  metrics?: PerformanceMetrics,
) => {
  const trace = TraceDecorator.get(label);

  if (trace) {
    trace.attachAttributesAndMetrics(attributes, metrics);
    trace.stop();
  }
};
