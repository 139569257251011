import { useTheme } from 'native-base';
import React from 'react';

import { Card, Image, Text } from 'src/taxfix-components/src';

import { Props } from './types';

export const PremiumTierCard: React.FC<Props> = ({
  onPress,
  content,
  imageSource = 'premiumTier',
  buttonText,
  ...otherProps
}): JSX.Element => {
  const { colors } = useTheme();

  return (
    <Card
      withNavigation
      backgroundColor="greytones.greyBlue"
      navigationHandler={onPress}
      navigationLabel={buttonText}
      variant="sticky1"
      maxW={['full', '300px']}
      {...otherProps}
    >
      <Image name={imageSource} minW={213} minH={168} mt={14} mb={18} alignSelf="left" />
      <Text variant="bodyMBook" color={colors.greytones.textDefault} value={content} />
    </Card>
  );
};
