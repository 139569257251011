import { MenuItemData, menuItem } from 'src/screens/account-menu/types';
import { ScreenName } from 'src/types/screen-name';
import { ButtonName } from 'src/types/button-name';

import Analytics, { AnalyticsEvent } from '../../../biz-logic/analytics';

export const trackMenuItem = (item: MenuItemData) => {
  const { translationKey: subSection } = item;
  Analytics.log(AnalyticsEvent.sectionAccount, {
    subSection,
  });

  if (subSection === menuItem.emailChange) {
    Analytics.log(AnalyticsEvent.changeEmailModalOpened);
  }

  if (subSection === menuItem.languageChange) {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.AccountScreen,
      buttonName: ButtonName.AccountMenuItemButton,
    });
  }
};
