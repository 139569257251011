import * as React from 'react';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import { ItalyWAYSidebar } from '../../containers/sidebar/italy-way-sidebar';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';

import { CredentialsContainer } from './signup-container';
import { NavigationParams as CredParams } from './signup-route.types';

export type ActionParams = CredParams;

type Props = Navigation<Routes.Signup, { [Routes.Signup]: NavigationParams<ActionParams> }>;

const SignupCredentialsScreen: React.FC<Props> = ({ route }) => {
  const { onConfirm, progress } = route.params;

  const sidebar = () => <ItalyWAYSidebar step="create-account" />;

  useWebNavigationOptions({
    sidebar,
  });

  useDefaultNavigationOptions(
    { id: 'it.signup.header' },
    {
      headerBackTitleVisible: false,
    },
  );

  return <CredentialsContainer onConfirm={onConfirm} progress={progress} />;
};

export const { action: toSignup, screen: signupScreen } = getRoute<ActionParams>({
  routeName: Routes.Signup,
  tracking: AnalyticsEvent.sectionSignUp,
  screenComponent: SignupCredentialsScreen,
});

export default SignupCredentialsScreen;
