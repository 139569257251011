import * as React from 'react';
import { StyleSheet, Switch } from 'react-native';

import { testID } from 'src/common/testID';
import { Box, TextButton, theme } from 'src/components/core';
import { Text } from 'src/taxfix-components/src';

type Props = {
  children?: React.ReactNode;
  text: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
  onExpandOrCollapse?: () => void;
  isExpanded?: boolean;
  disabled?: boolean;
  withBottomBorder?: boolean;
  testId?: string;
};
const styles = StyleSheet.create({
  toggleContainer: {},
  expandCollapseButton: {
    marginLeft: 5,
  },
  expandableArea: {
    backgroundColor: theme.color.background,
    borderTopWidth: 1,
    borderColor: theme.color.border,
  },
});
export const ExpandableToggle = ({
  children,
  text,
  value,
  onValueChange,
  onExpandOrCollapse,
  isExpanded = false,
  disabled = false,
  withBottomBorder = false,
  testId,
}: Props): JSX.Element => (
  <Box>
    <Box
      left={1}
      right={2}
      direction="row"
      alignVertically="center"
      style={[
        styles.toggleContainer,
        {
          borderBottomWidth: !isExpanded && withBottomBorder ? 1 : 0,
        },
      ]}
    >
      <Box flex alignHorizontally="start">
        <Box left={1}>
          <Text value={text} variant="titleXSBook" color={'greytones.textLight'} />
        </Box>
        {onExpandOrCollapse && (
          <TextButton
            style={styles.expandCollapseButton}
            onPress={onExpandOrCollapse}
            {...testID(`${testId || ''}.${isExpanded ? 'collapse' : 'expand'}`)}
          ></TextButton>
        )}
      </Box>
      <Box
        flex
        alignVertically="start"
        style={{
          maxWidth: 48,
        }}
      >
        <Box flex alignHorizontally="end" alignVertically="start">
          <Switch
            value={value}
            onValueChange={onValueChange}
            disabled={disabled}
            {...testID(testId)}
          />
        </Box>
      </Box>
    </Box>
    {isExpanded && (
      <Box
        style={[
          styles.expandableArea,
          {
            borderBottomWidth: withBottomBorder ? 1 : 0,
          },
        ]}
      >
        {children}
      </Box>
    )}
  </Box>
);
