import React from 'react';
import { Keyboard, Platform, KeyboardEvent } from 'react-native';

type KeyboardEventListenerType = (e: KeyboardEvent) => void;

export const useKeyboardBottomInset = (): number => {
  const [bottom, setBottom] = React.useState<number>(0);
  const subscriptions = React.useRef<{ remove: () => void }[]>([]);

  React.useEffect(() => {
    const onKeyboardChange: KeyboardEventListenerType = (e) => {
      if (e.startCoordinates && e.endCoordinates.screenY < e.startCoordinates.screenY)
        setBottom(e.endCoordinates.height);
      else setBottom(0);
    };

    if (Platform.OS === 'ios') {
      subscriptions.current = [Keyboard.addListener('keyboardWillChangeFrame', onKeyboardChange)];
    } else {
      subscriptions.current = [
        Keyboard.addListener('keyboardDidHide', onKeyboardChange),
        Keyboard.addListener('keyboardDidShow', onKeyboardChange),
      ];
    }
    return () => {
      subscriptions.current.forEach((subscription) => {
        subscription.remove();
      });
    };
  }, [setBottom, subscriptions]);

  return bottom;
};
