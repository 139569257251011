import { HStack, Pressable, VStack, useBreakpointValue } from 'native-base';
import React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

import { SecondaryArticleProps } from './types';

/**
 * element representing secondary links to blog articles
 */

export const SecondaryArticle: React.FC<SecondaryArticleProps> = ({
  title,
  image,
  subtitle,
  imageResizeMode = 'contain',
  onPress,
  ...otherProps
}): JSX.Element => {
  const containerWidth = useBreakpointValue({ base: '100%', md: '342px', lg: '430px' });
  const containerElevation = {
    shadow: 'elevation2',
  };

  const titleFont = useBreakpointValue({
    base: 'titleXSMedium',
    lg: 'subtitleMMedium',
  });
  const subtitleFont = useBreakpointValue({
    base: 'bodySBook',
    lg: 'bodyMBook',
  });
  const titleMarginTop = useBreakpointValue({ base: '12px', md: '19px' });
  const titleMarginLeft = useBreakpointValue({ base: '17px', md: '18px', lg: '22px' });
  const titleLineHeight = useBreakpointValue({ base: '20px', lg: '24px', md: '20px' });
  const titleMaxWidth = useBreakpointValue({ base: '172px', lg: '244px', md: '164px' });

  const imageWidth = useBreakpointValue({ base: '155px', lg: '164px', sm: '125px' });
  const imageBottomAlignement = useBreakpointValue({ sm: -10, lg: -1 });

  return (
    <Pressable
      width={containerWidth}
      height="120px"
      marginTop="20px"
      justifyItems="space-between"
      onPress={onPress}
      borderRadius={1}
      borderWidth="1px"
      borderColor="greytones.graphicsLight"
      overflow="hidden"
      _hover={containerElevation}
      {...otherProps}
    >
      <HStack justifyContent="space-between" h="100%" w="100%">
        <VStack>
          <Text
            variant={titleFont}
            value={title}
            marginTop={titleMarginTop}
            marginLeft={titleMarginLeft}
            lineHeight={titleLineHeight}
            maxWidth={titleMaxWidth}
          />
          {subtitle && (
            <Text
              variant={subtitleFont}
              value={subtitle}
              color="greytones.textLight"
              marginTop={0}
              marginLeft={titleMarginLeft}
              lineHeight={titleLineHeight}
              maxWidth={titleMaxWidth}
            />
          )}
        </VStack>

        <Container height="100%">
          <Image
            name={image}
            width={imageWidth}
            resizeMode={imageResizeMode}
            bottom={imageBottomAlignement}
          />
        </Container>
      </HStack>
    </Pressable>
  );
};
