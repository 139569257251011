import React from 'react';
import { ImageBackground } from 'react-native';

import { Container, Text, AvatarGroup, Icon, icons, TextLink } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getImageAsset } from 'src/assets/image-assets';

import confetti from '../../../assets/Confetti.png';
import { useNumberFormatter } from '../../../utils/useNumberFormatter';

type ResultCardProps = {
  title: string;
  description?: string;
  link?: string;
  bgColor?: string;
  estimatedResult: number;
  showConfetti?: boolean;
  showResult: boolean;
  hasPrefillDocuments: boolean;
  onPressLink: () => void;
};

type TaxExpertCardProps = {
  isLargeScreen: boolean;
  taxExpertCardWarning?: string;
  taxExpertCardTitle?: string;
  taxExpertCardDescription: string;
  averageRefundChipText?: string;
  onPressLink: () => void;
  linkText?: string;
  optimizationValue: number;
};

const cardExpertsPhotos = [
  'commercialista.commercialistaLaura',
  'commercialista.commercialistaStephan',
  'commercialista.commercialistaAlessandra',
].map(getImageAsset);

export const TaxExpertCard: React.FC<TaxExpertCardProps> = ({
  isLargeScreen,
  taxExpertCardTitle,
  taxExpertCardDescription,
  averageRefundChipText,
  onPressLink,
  linkText,
  optimizationValue,
}) => {
  const { getTranslationText } = useItalyIntl();

  return (
    <Container
      borderRadius="24px"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bg={isLargeScreen ? 'greytones.background' : 'greytones.white'}
      padding="24px"
      maxW={580}
    >
      <Container>
        <AvatarGroup
          avatarSources={cardExpertsPhotos}
          avatarProps={{ size: '52px', marginLeft: '-24px', borderWidth: 4 }}
        />
      </Container>
      <Container alignItems="center">
        {taxExpertCardTitle && ( // this check is for Experiment only content  https://taxfix.atlassian.net/browse/ITA-4419
          <Text
            variant={isLargeScreen ? 'titleSBold' : 'subtitleMMedium'}
            fontWeight="bold"
            value={getTranslationText(taxExpertCardTitle, {
              value: String(optimizationValue),
              bold: (text: string) => <Text value={text} color="brand.brandGreen" />,
            })}
            textAlign="center"
            marginTop="16px"
          />
        )}
        <Text
          variant="bodySBook"
          value={getTranslationText(taxExpertCardDescription, {
            bold: (text: string) => <Text fontWeight="bold" value={text} />,
          })}
          textAlign="center"
          marginTop="16px"
        />
        {averageRefundChipText && (
          <Container
            marginTop="16px"
            paddingX="16px"
            paddingY="8px"
            backgroundColor="secondaryCategory.limeHalf"
            borderRadius={999}
            flexDir="row"
            justifyContent="center"
            alignItems="center"
          >
            <Icon path={icons.doubleChevronUp} size="16px" color="secondaryFeedback.greenDark" />
            <Text
              variant="bodyXSBook"
              value={getTranslationText(averageRefundChipText, {
                bold: (text: string) => <Text fontWeight="bold" value={text} variant="bodySBook" />,
              })}
              color="secondaryFeedback.greenDark"
              marginLeft="8px"
            />
          </Container>
        )}
        {linkText && onPressLink && (
          <TextLink
            variant="titleXSMedium"
            text={getTranslationText(linkText)}
            color="secondaryFeedback.greenDark"
            onPress={onPressLink}
            marginTop="16px"
            alignItems="center"
            iconProps={{
              path: icons.arrowRight,
              color: 'secondaryFeedback.greenDark',
              size: '14px',
              marginLeft: '4px',
            }}
            cursor="pointer"
          />
        )}
      </Container>
    </Container>
  );
};

export const ResultCardLargeScreen: React.FC<ResultCardProps> = ({
  onPressLink,
  showResult,
  estimatedResult,
  title,
  description,
  link,
  bgColor,
  showConfetti,
  hasPrefillDocuments,
}) => {
  const { getTranslationText } = useItalyIntl();
  const getFormattedNumber = useNumberFormatter();

  return (
    <Container
      borderRadius="24px"
      justifyContent="center"
      alignItems="center"
      width="100%"
      backgroundColor={bgColor}
      maxW={580}
    >
      <ImageBackground
        source={showConfetti ? confetti : undefined}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 82,
          paddingBottom: 45,
        }}
        resizeMode="repeat"
      >
        <Text
          variant="titleXLBold"
          value={showResult ? getFormattedNumber(estimatedResult) : getTranslationText(title)}
          textAlign="center"
          paddingTop="45px"
          paddingBottom="16px"
          fontSize={showResult ? '42px' : '24px'}
        />
        {!!description && (
          <Text
            variant="bodySBook"
            color="greytones.textLight"
            value={getTranslationText(description)}
            textAlign="center"
          />
        )}
        {link && hasPrefillDocuments && (
          <TextLink
            variant="titleXSMedium"
            text={getTranslationText(link)}
            color="secondaryFeedback.greenDark"
            onPress={onPressLink}
            marginTop="30px"
            alignItems="center"
            iconProps={{
              path: icons.arrowRight,
              color: 'secondaryFeedback.greenDark',
              size: '14px',
              marginLeft: '4px',
            }}
            cursor="pointer"
          />
        )}
      </ImageBackground>
    </Container>
  );
};

export const ResultCardMobile: React.FC<ResultCardProps> = ({
  showConfetti,
  onPressLink,
  showResult,
  estimatedResult,
  title,
  description,
  link,
  hasPrefillDocuments,
}) => {
  const { getTranslationText } = useItalyIntl();
  const getFormattedNumber = useNumberFormatter();

  return (
    <ImageBackground
      source={showConfetti ? confetti : undefined}
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      resizeMode="cover"
    >
      <Container alignItems={'center'} width="100%" paddingX={['24px']} paddingBottom={['40px']}>
        <Text
          paddingTop={['10px']}
          paddingBottom={['24px']}
          variant={'titleSBold'}
          value={getTranslationText(title)}
          textAlign="center"
          width="100%"
        />
        {showResult && (
          <Text
            paddingTop={['4px']}
            paddingBottom={['16px']}
            fontWeight={700}
            fontSize={42}
            lineHeight={38}
            textAlign="center"
            value={getFormattedNumber(estimatedResult)}
          />
        )}
        {!!description && (
          <Text
            variant="bodySBook"
            color="greytones.textDefault"
            value={getTranslationText(description)}
            maxW="386px"
            textAlign="center"
          />
        )}
        {link && hasPrefillDocuments && (
          <TextLink
            variant="titleXSMedium"
            text={getTranslationText(link)}
            color="secondaryFeedback.greenDark"
            onPress={onPressLink}
            marginTop="16px"
            alignItems="center"
            iconProps={{
              path: icons.arrowRight,
              color: 'secondaryFeedback.greenDark',
              size: '14px',
              marginLeft: '4px',
            }}
          />
        )}
      </Container>
    </ImageBackground>
  );
};
