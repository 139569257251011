import { CountryCodes } from '@taxfix/types';
import { ScrollView, useBreakpointValue } from 'native-base';
import React from 'react';
import { Linking, StyleSheet, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';

import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { getImageAsset } from 'src/assets/image-assets';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { TabNames } from 'src/common/constants-it';
import { useSmallScreenQuery } from 'src/components/core';
import { ITALY_TABS_HEIGHT, ItalyTabs } from 'src/routes/italy-navigation/italy-tabs';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  Container,
  ExpertDetailsCardsProps,
  ExpertProfileCard,
  TaxExpertsCardV2,
  Text,
} from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';
import { isNative } from 'src/utils/platform';

import { useItalyIntl } from '../_hooks/use-italy-intl';
import { useShowSideTabs } from '../_hooks/use-screen-size';

export const TaxExpertContainer: React.FC = () => {
  // general
  const containerFlexDir = useBreakpointValue({ base: 'column', lg: 'row' });
  const isHorizontalLayout = useBreakpointValue({ base: false, lg: true });
  const subTitleMarginBottom = useBreakpointValue({ base: 24, md: 39 });
  const cardContainerMarginBottom = useBreakpointValue({ base: 48, md: 43, lg: 55, xl: '0px' });
  const isSmallScreen = useSmallScreenQuery();
  const showSideTabs = useShowSideTabs();
  const subtitleTextAlign = useBreakpointValue({ base: 'left', sm: 'center', lg: 'left' });

  // Experts card section
  const expertsSectionAlignment = useBreakpointValue({
    base: 'flex-start',
    sm: 'center',
    lg: 'flex-start',
  });
  const expertsSectionRightMargin = useBreakpointValue({
    base: '0px',
    lg: '4%',
    xl: '10%',
  });
  const headerTextVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  const subheaderTextVariant = useBreakpointValue({
    base: 'bodyMBook',
    lg: 'bodyLBook',
  });

  // Expert Profile cards section
  const profilesContainerMarginTop = useBreakpointValue({ base: '0px', lg: 35, xl: 70 });
  const profilesContainerMaxWidth = useBreakpointValue({ base: '100%', lg: 330, xl: 380 });
  const profilesContainerMarginLeft = useBreakpointValue({
    base: isNative ? 24 : 64,
    sm: 24,
    lg: '0px',
  });
  const profileCardFlexBasis = useBreakpointValue({ base: 294, md: 380, lg: 'auto' });
  const profilesContainerWidth = useBreakpointValue({ base: 660, md: 'auto' });

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();
  const taxExpertsButtonTitle = getTranslationText('it.tax-expert.main-card-button-title');
  const cardExpertsPhotos = [
    'commercialista.commercialistaDeborah',
    'commercialista.commercialistaClaudio',
    'commercialista.commercialistaLaura2',
  ].map(getImageAsset);
  const services = [
    'it.tax-expert.main-card.services.first',
    'it.tax-expert.main-card.services.second',
    'it.tax-expert.main-card.services.third',
    'it.tax-expert.main-card.services.fourth',
  ].map((key) => getTranslationText(key));

  const selectedYear = useSelector(settingsSelectors.selectedYear);
  const handleLinkedinProfilePressed = (expertLink: string, expertName: string) => {
    Analytics.log(AnalyticsEvent.expertLinkedinProfileVisited, {
      screenName: ScreenName.ITTaxEpert,
      expertName: expertName,
      expertLink: expertLink,
      year: selectedYear,
      countryCode: CountryCodes.IT,
    });

    Linking.openURL(expertLink);
  };

  const expertLinks = useSelector(firebaseSelectors.getItalyTaxExpertsLinks);
  const allExpertsDetails: Array<ExpertDetailsCardsProps['expertDetails']> = React.useMemo(
    () => [
      {
        name: 'Alessandra',
        job: getTranslationText('it.tax-expert.main-card-first.expert.job'),
        experience: getTranslationText('it.tax-expert.expert-profile.experience-years', {
          experienceYears: 11,
        }),
        content: getTranslationText('it.tax-expert.main-card-first.expert.content'),
        photo: getImageAsset('commercialista.commercialistaAlessandra2'),
        onPress: () => handleLinkedinProfilePressed(expertLinks.alessandra, 'Alessandra'),
      },
      {
        name: 'Stephan',
        job: getTranslationText('it.tax-expert.main-card-second.expert.job'),
        experience: getTranslationText('it.tax-expert.expert-profile.experience-years', {
          experienceYears: 12,
        }),
        content: getTranslationText('it.tax-expert.main-card-second.expert.content'),
        photo: getImageAsset('commercialista.commercialistaStephan'),
        onPress: () => handleLinkedinProfilePressed(expertLinks.stephan, 'Stephan'),
      },
    ],
    [expertLinks],
  );

  const handleOnPressMainCTA = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ITTaxEpert,
      buttonName: taxExpertsButtonTitle,
    });
    handleOpenChatPopup();
  };

  const { height } = useWindowDimensions();
  return (
    <Container h={height - ITALY_TABS_HEIGHT} overflow="hidden">
      <ScrollView paddingTop="45px">
        <Container
          flexDirection={containerFlexDir}
          alignItems="center"
          width="100%"
          maxWidth={1000}
          marginX="auto"
          paddingBottom={30}
        >
          <Container
            alignItems={expertsSectionAlignment}
            width={isHorizontalLayout ? 'auto' : '100%'}
            marginBottom={cardContainerMarginBottom}
            marginRight={expertsSectionRightMargin}
            paddingX={22}
          >
            <Text
              value={getTranslationText('it.tax-expert.header')}
              variant={headerTextVariant}
              marginBottom={12}
              width="100%"
              textAlign={subtitleTextAlign}
            />
            <Text
              value={getTranslationText('it.tax-expert.sub-header')}
              variant={subheaderTextVariant}
              color="greytones.graphicsDark"
              flexShrink={1}
              maxWidth={480}
              marginBottom={subTitleMarginBottom}
              textAlign={subtitleTextAlign}
            />

            <TaxExpertsCardV2
              avatarSources={cardExpertsPhotos}
              headerTitle={getTranslationText('it.tax-expert.main-card-title')}
              services={services}
              ctaButtonTitle={taxExpertsButtonTitle}
              ctaButtonTestID="it.experts.expers-card.cta"
              ctaOnPress={handleOnPressMainCTA}
              footerTitle={getTranslationText('it.tax-expert.main-card-footer')}
            />
          </Container>
          <Container
            maxWidth={profilesContainerMaxWidth}
            marginLeft={profilesContainerMarginLeft}
            marginRight="10px"
            marginTop={profilesContainerMarginTop}
          >
            <Text
              value={getTranslationText('it.tax-expert.expert-team-leaders.title')}
              variant="subtitleMMedium"
              marginBottom={isSmallScreen ? 16 : 24}
              textAlign={subtitleTextAlign}
            />
            <ScrollView
              horizontal={!isHorizontalLayout}
              snapToInterval={380}
              maxWidth="100%"
              showsHorizontalScrollIndicator={false}
              _contentContainerStyle={{
                paddingRight: isHorizontalLayout && isNative ? '0px' : 20,
                justifyContent: expertsSectionAlignment,
                width: isNative ? 'auto' : profilesContainerWidth,
              }}
            >
              {allExpertsDetails.map((expert, index) => (
                <ExpertProfileCard
                  key={expert.name}
                  expertDetails={expert}
                  handleOnPress={expert.onPress}
                  marginLeft={index === 0 || isHorizontalLayout ? '0px' : 24}
                  marginTop={index === 0 || !isHorizontalLayout ? '0px' : 24}
                  flexBasis={profileCardFlexBasis}
                  maxWidth={isNative ? profileCardFlexBasis : 380}
                  maxHeight={isNative ? 218 : 'auto'}
                />
              ))}
            </ScrollView>
          </Container>
        </Container>
      </ScrollView>
      {!showSideTabs && <ItalyTabs highlighedTab={TabNames.Experts} />}
    </Container>
  );
};
