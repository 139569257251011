import * as React from 'react';
import { CountryCodes } from '@taxfix/types';

import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';

import { Terms } from './terms';

export type ActionParams = {
  onAccept: () => Promise<void>;
};

type Props = Navigation<Routes.Terms, { [Routes.Terms]: NavigationParams<ActionParams> }>;

const TermsScreen = ({ route }: Props) => {
  const { onAccept } = route.params;

  return <Terms onAccept={onAccept} country={CountryCodes.IT} />;
};

export const { action: toTerms, screen: termsScreen } = getRoute<ActionParams>({
  routeName: Routes.Terms,
  screenComponent: TermsScreen,
});

export default TermsScreen;
