import { NativeBaseProvider as Provider } from 'native-base';
import React from 'react';

import { NBTheme } from 'src/taxfix-components/src';

/**

 * Italy theme wrapper for taxfix components library

 */

type Props = {
  children: React.ReactNode;
};

export const NativeBaseProvider: React.ComponentType<Props> = (props) => (
  <Provider {...props} theme={NBTheme}>
    {props.children}
  </Provider>
);
