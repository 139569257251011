import { IntlShape } from 'react-intl';

export type IntlFormatterTypes = 'decimal' | 'percent' | 'currency';
export type FormatNumberParams = {
  formatStyle: IntlFormatterTypes;
  currency?: string;
  precision?: 0 | 1 | 2;
  value: number;
  useGrouping?: boolean;
  style?: any;
};
type IntlParams = {
  style: string;
  currency?: string;
  useGrouping?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const formatNumber = (intl: IntlShape, params: FormatNumberParams) => {
  const { formatStyle: style, currency = 'EUR', precision, value, useGrouping = false } = params;
  let convertedValue = value;
  const intlOpts: IntlParams = {
    style,
    useGrouping,
  };

  if (style === 'currency') {
    intlOpts.currency = currency;
  }

  if (precision != null) {
    intlOpts.minimumFractionDigits = 0;
    intlOpts.maximumFractionDigits = precision;
  }

  if (style === 'percent') {
    convertedValue = value / 100;
  }

  // @ts-ignore
  return intl.formatNumber(convertedValue, intlOpts);
};

export default formatNumber;
