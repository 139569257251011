import * as React from 'react';
import { StyleSheet, Text as ReactNativeText, TextStyle, StyleProp } from 'react-native';

import IntlText from '../../i18n/IntlText';
import screenSize from '../../utils/screenSize';
import { EllipsizeMode } from '../types';
import { testID } from '../../common/testID';

import { theme } from './theme';

const styles = StyleSheet.create({
  default: {
    color: theme.color.primaryText,
  },
  bold: {
    fontFamily: theme.fontFamily.bold,
  },
  medium: {
    fontFamily: theme.fontFamily.medium,
  },
  book: {
    fontFamily: theme.fontFamily.book,
  },
  titleText: {
    fontSize: 12,
    letterSpacing: 1.2,
  },
  titleSecondaryText: {
    color: theme.color.secondaryText,
  },
  bodyTitleText: {
    fontSize: 16,
  },
  bodyPrimaryText: {
    fontSize: 16,
  },
  bodySecondaryText: {
    fontSize: 16,
    color: theme.color.secondaryText,
  },
  bigBodySecondaryText: {
    fontSize: 20,
    color: theme.color.secondaryText,
  },
  displayLinkText: {
    fontSize: 20,
    color: theme.color.primary,
  },
  linkText: {
    fontSize: 16,
    color: theme.color.primary,
  },
  secondaryLinkText: {
    fontSize: 16,
    color: theme.color.primary,
  },
  disabledLinkText: {
    fontSize: 16,
    color: theme.color.secondaryText,
  },
  inputText: {
    fontSize: 30,
  },
  hugeTitleText: {
    fontSize: 32,
    lineHeight: 40,
  },
  largeDisplayTitleText: {
    fontSize: 28,
  },
  bigDisplayTitleText: {
    fontSize: 25,
  },
  displayTitleText: {
    fontSize: 20,
  },
  largeDisplayPrimaryText: {
    fontSize: 28,
  },
  displayPrimaryText: {
    fontSize: 20,
  },
  smallTitleText: {
    fontSize: 14,
  },
  smallPrimaryText: {
    fontSize: 14,
  },
  smallSecondaryText: {
    fontSize: 14,
    color: theme.color.secondaryText,
  },
  tinyPrimaryText: {
    fontSize: 12,
    color: theme.color.primaryText,
  },
  tinySecondaryText: {
    fontSize: 12,
    color: theme.color.secondaryText,
  },
  smallLinkText: {
    fontSize: screenSize.isSmallMobileWebScreen() ? 11 : 14,
    color: theme.color.primary,
  },
  smallSecondaryLinkText: {
    fontSize: 14,
    color: theme.color.primary,
  },
  smallLightText: {
    fontSize: 14,
    color: theme.color.lightText,
  },
  smallText: {
    fontSize: 14,
  },
  questionText: {
    fontSize: 22,
    color: theme.color.lightText,
  },
  displayHighlightText: {
    fontSize: screenSize.isSmallScreen() ? 30 : 35,
    color: theme.color.primaryText,
  },
  onboardingText: {
    fontSize: 28,
    color: theme.color.primaryText,
  },
  summaryHeaderText: {
    fontSize: 28,
  },
});
export type TextTransform = 'uppercase' | 'lowercase' | 'capitalize';
type CoreTextProps = {
  style?: StyleProp<TextStyle>;
  children?: React.ReactNode;
  textTransform?: TextTransform;
  ellipsizeMode?: EllipsizeMode;
  testId?: string;
  selectable?: boolean;
  numberOfLines?: number;
  disabled?: boolean;
  onPress?: () => void;
};
export type TextProps = CoreTextProps & {
  id?: string | any;
  values?: Record<string, any>;
  color?: string;
  secureTextEntry?: any;
};

const changeCase = (string: string, transform: TextTransform): string => {
  switch (transform) {
    case 'uppercase':
      return string.toUpperCase();

    case 'lowercase':
      return string.toLowerCase();

    default:
      return string;
  }
};

const transform = (children: any, direction: TextTransform) =>
  React.Children.map(children, (child) =>
    typeof child === 'string' ? changeCase(child, direction) : null,
  );

/*
  CoreText supports textTransform
*/
const CoreText: React.FC<CoreTextProps> = ({ textTransform, children, style, ...props }) => {
  const combinedStyles = [styles.default, style];
  return (
    <ReactNativeText {...props} style={combinedStyles}>
      {textTransform ? transform(children, textTransform) : children}
    </ReactNativeText>
  );
};

/*
  Text supports both i18n ids and normal
  children style. Either renders out to
  the CoreText component.
*/
const Text: React.FC<TextProps> = ({ id, values, ...props }) => {
  if (id) {
    return <IntlText Component={CoreText} id={id} values={values} {...props} />;
  }

  return <CoreText {...props} />;
};

const TitleText: React.FC<TextProps> = ({ style, ...props }) => (
  <Text {...props} textTransform="uppercase" style={[styles.bold, styles.titleText, style]} />
);

export const titleTextStyle = [styles.bold, styles.titleText];

const TitleSecondaryText: React.FC<TextProps> = ({ style, ...props }) => (
  <TitleText {...props} style={[style, styles.titleSecondaryText]} />
);

const createTextStyleComponent = (...otherStyles: StyleProp<TextStyle>[]) => {
  const Component = ({ style, color, ...props }: TextProps) => {
    const stylesToPass = [...otherStyles, style];

    if (color) {
      stylesToPass.push({
        color,
      });
    }

    return <Text {...props} style={stylesToPass} {...testID(props.testId)} />;
  };

  return Component;
};

export const bodyTitleTextStyle = [styles.medium, styles.bodyTitleText];
const BodyTitleText = createTextStyleComponent(bodyTitleTextStyle);
const BodyTitleTextBold = createTextStyleComponent(styles.bold, styles.bodyTitleText);
// Export for use in TextInputs and other
// non-Text components
export const bodyPrimaryTextStyle = [styles.book, styles.bodyPrimaryText];
const BodyPrimaryText = createTextStyleComponent(styles.book, styles.bodyPrimaryText);
const BodyPrimaryTextStyleBold = createTextStyleComponent(styles.bold, styles.bodyPrimaryText);
const BodyPrimaryTextStyle = BodyPrimaryText;
const BigBodySecondaryText = createTextStyleComponent(styles.book, styles.bigBodySecondaryText);
const BodySecondaryText = createTextStyleComponent(styles.book, styles.bodySecondaryText);
const BodySecondaryTextBold = createTextStyleComponent(styles.bold, styles.bodySecondaryText);
const DisplayLinkText = createTextStyleComponent(styles.book, styles.displayLinkText);
const LinkText = createTextStyleComponent(styles.medium, styles.linkText);
const SecondaryLinkText = createTextStyleComponent(styles.book, styles.secondaryLinkText);
const DisabledLinkText = createTextStyleComponent(styles.medium, styles.disabledLinkText);
// Export for use in TextInputs and other
// non-Text components
export const inputTextStyle = [styles.book, styles.inputText];
const InputText = createTextStyleComponent(inputTextStyle);
const HugeTitleText = createTextStyleComponent(styles.bold, styles.hugeTitleText);
const LargeDisplayTitleText = createTextStyleComponent(styles.bold, styles.largeDisplayTitleText);
const BigDisplayTitleText = createTextStyleComponent(styles.bold, styles.bigDisplayTitleText);
const DisplayTitleText = createTextStyleComponent(styles.bold, styles.displayTitleText);
const LargeDisplayPrimaryText = createTextStyleComponent(
  styles.book,
  styles.largeDisplayPrimaryText,
);
// Export for use in TextInputs and other
// non-Text components
export const displayPrimaryTextStyle = [styles.book, styles.displayPrimaryText];
const DisplayPrimaryText = createTextStyleComponent(styles.book, styles.displayPrimaryText);
const SmallTitleText = createTextStyleComponent(styles.bold, styles.smallTitleText);
const SmallPrimaryText = createTextStyleComponent(styles.book, styles.smallPrimaryText);
const SmallPrimaryTextBold = createTextStyleComponent(styles.bold, styles.smallPrimaryText);
// Export for use in TextInputs and other
// non-Text components
export const smallSecondaryTextStyle = [styles.book, styles.smallSecondaryText];
const SmallSecondaryText = createTextStyleComponent(smallSecondaryTextStyle);
const TinyPrimaryText = createTextStyleComponent(styles.book, styles.tinyPrimaryText);

const TinyPrimaryTextBold = createTextStyleComponent(styles.bold, styles.tinyPrimaryText);

const TinySecondaryText = createTextStyleComponent(styles.book, styles.tinySecondaryText);

const SmallLinkText = createTextStyleComponent(styles.medium, styles.smallLinkText);
const SmallSecondaryLinkText = createTextStyleComponent(styles.book, styles.smallSecondaryLinkText);
const SmallLightText = createTextStyleComponent(styles.medium, styles.smallLightText);
const SmallText = createTextStyleComponent(styles.medium, styles.smallText);
const QuestionText = createTextStyleComponent(styles.medium, styles.questionText);
const DisplayHighlightText = createTextStyleComponent(styles.bold, styles.displayHighlightText);
const OnboardingText = createTextStyleComponent(styles.book, styles.onboardingText);
const SummaryHeaderText = createTextStyleComponent(styles.bold, styles.summaryHeaderText);

export {
  CoreText,
  HugeTitleText,
  TitleText,
  TitleSecondaryText,
  BodyTitleText,
  BodyTitleTextBold,
  BodyPrimaryText,
  BigBodySecondaryText,
  BodySecondaryText,
  BodySecondaryTextBold,
  DisplayLinkText,
  LinkText,
  SecondaryLinkText,
  DisabledLinkText,
  InputText,
  LargeDisplayTitleText,
  BigDisplayTitleText,
  DisplayTitleText,
  LargeDisplayPrimaryText,
  DisplayPrimaryText,
  SmallTitleText,
  SmallPrimaryText,
  SmallPrimaryTextBold,
  SmallSecondaryText,
  SmallLinkText,
  SmallSecondaryLinkText,
  QuestionText,
  DisplayHighlightText,
  SmallLightText,
  BodyPrimaryTextStyle,
  OnboardingText,
  BodyPrimaryTextStyleBold,
  SummaryHeaderText,
  SmallText,
  TinyPrimaryText,
  TinyPrimaryTextBold,
  TinySecondaryText,
};
export default Text;
