import { useState } from 'react';
import { Platform, Image as RNImage } from 'react-native';
import { ImageURISource } from 'react-native/Libraries/Image/ImageSource';

type ImageSource = ImageURISource | string | null;
type Options = {
  maxHeight?: number;
  maxWidth?: number;
};
export const useImageSizeStyle = (source: ImageSource, options: Options = {}) => {
  const [size, setSize] = useState<{ width: number; height: number } | undefined>(undefined);
  const [ratio, setRatio] = useState(1);
  const uri = (source && source.uri) || source;

  if (!size && typeof uri === 'string' && Platform.OS === 'web') {
    RNImage.getSize(uri, (width, height) => {
      return setSize({
        width,
        height,
      });
    });
  }

  if (size) {
    const heightRatio =
      options.maxHeight && options.maxHeight > 0 && size.height > 0
        ? options.maxHeight / size.height
        : 1;
    const widthRatio =
      options.maxWidth && options.maxWidth > 0 && size.width > 0
        ? options.maxWidth / size.width
        : 1;
    const currentRatio = Math.min(heightRatio, widthRatio, 1);

    if (currentRatio !== ratio) {
      setRatio(currentRatio);
    }
  }

  return Platform.select({
    web: size
      ? {
          width: size.width * ratio,
          height: size.height * ratio,
        }
      : undefined,
  });
};
