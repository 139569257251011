import React from 'react';
import { StyleSheet } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';

import { Box, theme, Icon, DisplayTitleText, SmallTitleText } from './core';
import { TextHtml } from './core/TextHtml';

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  imageContainer: {
    width: '100%',
    height: 300,
  },
  text: {
    textAlign: 'center',
  },
  title: {
    marginTop: -20,
  },
  progressContainer: {
    width: '100%',
  },
});
type Props = {
  progress: number;
  titleTranslationKey: string;
  progressSubtitleTranslationKey?: string;
  values?: {
    minutes: number;
  };
};
export const FancyLoading = ({
  progress,
  progressSubtitleTranslationKey,
  titleTranslationKey,
  values,
}: Props) => (
  <Box alignHorizontally="center" flex>
    <Box style={styles.imageContainer}>
      <Icon name="fancyLoading" style={styles.image} />
    </Box>
    <Box style={[styles.title]}>
      <DisplayTitleText
        style={styles.text}
        color={theme.color.primaryText}
        id={titleTranslationKey}
      />
    </Box>
    <Box top={3} alignHorizontally="center">
      {progressSubtitleTranslationKey && values?.minutes != null && (
        <TextHtml id={progressSubtitleTranslationKey} values={values} />
      )}
    </Box>
    <Box top={3} style={styles.progressContainer}>
      <ProgressBar
        progress={progress} // null width to use automatic flexbox sizing.
        width={null}
        height={8}
        color={theme.color.primary}
        unfilledColor={theme.color.fill}
        borderColor={theme.color.background}
        animationType="timing"
        useNativeDriver
      />
      <Box alignHorizontally="end">
        <SmallTitleText>{Math.floor(progress * 100)}%</SmallTitleText>
      </Box>
    </Box>
  </Box>
);
