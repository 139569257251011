import React from 'react';
import { useBreakpointValue } from 'native-base';

import { Container, Text, Icon, icons, ContainerProps } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { useNumberFormatter } from '../../../utils/useNumberFormatter';

type ResultCardExperimentProps = ContainerProps & {
  description?: string;
  bgColor?: string;
  estimatedResult: number;
  showResult: boolean;
};

type TaxExpertCardExperimentProps = ContainerProps & {
  taxExpertCardTitle?: string;
  taxExpertCardDescription: string;
  averageRefundChipText?: string;
  optimizationValue: number;
  taxExpertCardHeaderText?: string;
};

export const TaxExpertCardExperiment: React.FC<TaxExpertCardExperimentProps> = ({
  taxExpertCardTitle,
  taxExpertCardDescription,
  averageRefundChipText,
  optimizationValue,
  taxExpertCardHeaderText,
  ...otherProps
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  return (
    <Container
      borderRadius="24px"
      justifyContent="center"
      alignItems="center"
      padding="16px"
      width="100%"
      bg={isLargeScreen ? 'greytones.background' : 'greytones.white'}
      {...otherProps}
    >
      {taxExpertCardHeaderText && (
        <Text
          variant="bodySBook"
          value={getTranslationText(taxExpertCardHeaderText).toUpperCase()}
          textAlign="center"
          marginBottom="16px"
          color="greytones.textLight"
        />
      )}
      {!taxExpertCardTitle && (
        <Text
          variant={isLargeScreen ? 'titleXLBold' : 'titleXLBold'}
          value={'+' + String(optimizationValue) + '€'}
          textAlign="center"
          marginBottom="8px"
        />
      )}
      {taxExpertCardTitle && (
        <Text
          variant={isLargeScreen ? 'titleLBold' : 'subtitleMMedium'}
          value={getTranslationText(taxExpertCardTitle)}
          textAlign="center"
          marginBottom="8px"
        />
      )}
      <Text
        variant={isLargeScreen ? 'bodyMBook' : 'bodySBook'}
        value={getTranslationText(taxExpertCardDescription, {
          bold: (text: string) => <Text fontWeight="bold" value={text} />,
        })}
        textAlign="center"
        marginBottom="8px"
        maxW="540px"
      />
      {averageRefundChipText && (
        <Container
          marginTop="8px"
          paddingX="16px"
          paddingY="8px"
          backgroundColor="secondaryCategory.limeHalf"
          borderRadius={999}
          flexDir="row"
          justifyContent="center"
          alignItems="center"
        >
          <Icon path={icons.doubleChevronUp} size="16px" color="secondaryFeedback.greenDark" />
          <Text
            variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
            value={getTranslationText(averageRefundChipText, {
              bold: (text: string) => <Text fontWeight="bold" value={text} variant="bodySBook" />,
            })}
            color="secondaryFeedback.greenDark"
            marginLeft="8px"
          />
        </Container>
      )}
    </Container>
  );
};

export const ResultCardExperiment: React.FC<ResultCardExperimentProps> = ({
  showResult,
  estimatedResult,
  description,
  bgColor,
  ...otherProps
}) => {
  const { getTranslationText } = useItalyIntl();
  const getFormattedNumber = useNumberFormatter();

  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  return (
    <Container
      borderRadius="24px"
      justifyContent="center"
      alignItems="center"
      backgroundColor={bgColor}
      width="100%"
      paddingTop={isLargeScreen ? '16px' : '10px'}
      paddingBottom={isLargeScreen ? '16px' : '12px'}
      {...otherProps}
    >
      <Text
        variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
        color="greytones.textDefault"
        value={getTranslationText(
          'prefill-result-screen.result-card.title-experiment',
        ).toUpperCase()}
        textAlign="center"
      />
      <Text
        variant={isLargeScreen ? 'titleMBold' : 'titleSBold'}
        value={showResult ? getFormattedNumber(estimatedResult) : '-'}
        textAlign="center"
        paddingTop="8px"
        paddingBottom="8px"
      />

      {description && (
        <Text
          variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
          color="greytones.textDefault"
          value={getTranslationText(description)}
          textAlign="center"
          width={isLargeScreen ? '303px' : '260px'}
        />
      )}
    </Container>
  );
};
