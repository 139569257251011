import React from 'react';
import { StyleSheet, View } from 'react-native';

import { StyleProp } from '../types';

import { theme } from './theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 13,
    overflow: 'hidden',
  },
  border: {
    borderWidth: 1,
  },
});
type Props = {
  style?: StyleProp;
  children?: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
};

const Card = ({
  style,
  children,
  backgroundColor = theme.color.lightFill,
  borderColor = theme.color.border,
}: Props) => (
  <View
    style={[
      styles.container,
      {
        backgroundColor,
        borderColor,
      },
      styles.border,
      style,
    ]}
  >
    {children}
  </View>
);

export default Card;
