import * as React from 'react';

import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import navigate, { Action } from 'src/routes/navigate';

import { TaxExpertContainer } from './tax-expert-container';

export const toTaxExpert: Action = navigate('TaxExpert', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'TaxExpert',
  },
});

export const TaxExpert: React.FC = () => {
  useDefaultNavigationOptions(
    { id: 'it.tax-expert.nav.title' },
    {
      headerBackTitleVisible: false,
      animationEnabled: false,
    },
  );

  return <TaxExpertContainer />;
};
