import { DocumentsTypes } from '@taxfix/documents-sdk';
import { Documents } from '@taxfix/taxfix-sdk';
import { HStack, Pressable, ScrollView, VStack, useBreakpointValue } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { theme } from 'src/components/core';
import { InformationButton } from 'src/components/information-button';
import { Loading } from 'src/components/loading';
import {
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
} from 'src/components/notification-banner-legacy';
import { useNavigation } from 'src/hooks/navigation-hook';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import {
  Container,
  DocumentPreviewCard,
  Footer,
  Grid,
  Image,
  NavButton,
  Text,
} from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';
import { resolveFileName } from 'src/_italy/prefill-documents-result/utils';
import { usePrivacySettings } from 'src/hooks/privacy-settings-hook';
import { ButtonName } from 'src/types/button-name';
import { prefillQuestionIds } from 'src/common/constants-it';
import { useItalyIntl, useQuizmasterLight } from 'src/_italy/_hooks';

import { ExpandableToggle } from './expandable-toggle';
import { getCUDocuments, onNativeShare } from './utils';

enum SectionName {
  PreSeasonCUDocumentsResultList = 'PreSeasonCUDocumentsResultList',
  PreSeasonCUDocumentsEmpty = 'PreSeasonCUDocumentsEmpty',
}

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} variant="bodySBook" />;

export const CUDocumentResultContainer = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { marketingEmails, handleMarketingChange } = usePrivacySettings();
  const [CUDocuments, setCUDocuments] = useState<DocumentsTypes.v1.Documents.Document[]>([]);

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const year = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const currentYear = year + 1;
  const userId = useSelector(userAuthSelectors.getUserId);

  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const textAlignment = useBreakpointValue({ base: 'left', md: 'center' });
  const numberColumnsGrid = useBreakpointValue({ base: 1, md: 2 });
  const infoButtonAlignment = useBreakpointValue({ base: 'flex-start', md: 'center' });
  const paragraphFontStyle = useBreakpointValue({ base: 'bodyMBook', md: 'titleXSBook' });
  const tipCardTitleVariant = useBreakpointValue({
    base: 'titleXXSBold',
    md: 'titleXXSBold',
    lg: 'titleXSBold',
  });
  const tipCardSubtitleVariant = useBreakpointValue({
    base: 'bodyXSBook',
    md: 'bodyXSBook',
    lg: 'bodySBook',
  });

  const titleFontWithCUDocuments = useBreakpointValue({ base: 'titleMBold', md: 'titleLBold' });

  const dispatch = useDispatch();
  const { getTranslationText } = useItalyIntl();
  const { getNavigationActions, safeResetNavigation, navigationActions } = useNavigation();

  const hasCUDocuments = !!CUDocuments.length;

  const quizmasterLight = useQuizmasterLight([prefillQuestionIds.preseasonTaxId]);

  const noCUTitle = 'it.cu-document-result.no-documents.screen.title';
  const withCUTitle = 'it.cu-document-result.with-documents.screen.title';
  const screenTitle = getTranslationText(hasCUDocuments ? withCUTitle : noCUTitle, { currentYear });

  const screenSubtitle = getTranslationText('it.cu-document-result.screen.subtitle');

  const tipCardTitle = getTranslationText(
    hasCUDocuments
      ? 'it.cu-document-result.with-cu-documents.tip-card.title'
      : 'it.cu-document-result.no-cu-documents.tip-card.title',
  );

  const tipCardSubtitle = getTranslationText(
    hasCUDocuments
      ? 'it.cu-document-result.with-cu-documents.tip-card.subtitle'
      : 'it.cu-document-result.no-cu-documents.tip-card.subtitle',
    { bold: getBoldText },
  );

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setError(false);
      try {
        const docs = await getCUDocuments(accessToken, userId, year);
        setCUDocuments(docs);
        if (docs.length === 0) {
          quizmasterLight[prefillQuestionIds.preseasonTaxId].saveAnswer(null);
        }
        Analytics.log(AnalyticsEvent.sectionShown, {
          sectionName: docs.length
            ? SectionName.PreSeasonCUDocumentsResultList
            : SectionName.PreSeasonCUDocumentsEmpty,
        });
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, [accessToken, userId, year]);

  const handleOnBackButtonPress = () => {
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const handleGoBackHomePress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.CUDocumentResult,
      buttonName: 'Close',
    });
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  };

  const handleOnPressInformationIcon = () => {
    dispatch(
      overlayActions.show('CUDocumentResultInfoOverlay', {
        titleKey: 'it.cu-document-result.info-overlay.title',
        contentKey: 'it.cu-document-result.info-overlay.content',
      }),
    );
  };

  const handleNotificationToggleChange = (value: boolean): void => {
    handleMarketingChange(value);
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.CUDocumentResult,
      buttonName: value ? ButtonName.PreseasonReminderOptIn : ButtonName.PreseasonReminderOptOut,
    });
  };

  const noDocumentsInformationComponent = (): JSX.Element => (
    <>
      <Text
        variant={paragraphFontStyle}
        textAlign={textAlignment}
        marginY="12px"
        value={screenSubtitle}
        width="100%"
        maxW="580px"
        color="greytones.textLight"
      />
      <Pressable
        alignItems="center"
        flexDir="row"
        alignSelf={infoButtonAlignment}
        onPress={handleOnPressInformationIcon}
      >
        <InformationButton color={theme.color.primary} symbol="?" size="small" disabled />
        <Text
          variant="bodyXSBold"
          value={getTranslationText('it.cu-document-result.info-button')}
          color="brand.brandGreen"
          marginLeft="10px"
        />
      </Pressable>
    </>
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <NotificationBannerLegacy notificationType={NotificationBannerLegacyType.ConnectionFailed} />
    );
  }

  return (
    <>
      {/* TODO: extract as main component */}
      <Container alignContent={'space-between'} flex={1}>
        {isLargeScreen && (
          <NavButton
            text={getTranslationText('it.navigation.back-to-home')}
            onPress={handleOnBackButtonPress}
          />
        )}
        <ScrollView
          flex={1}
          contentContainerStyle={{ alignItems: 'center', justifyContent: 'space-between' }}
          width="100%"
          alignSelf="center"
        >
          <Container alignSelf="center" alignItems="center" width="100%" maxW={['327px', '800px']}>
            <Text
              marginTop={['16px', '40px']}
              variant={hasCUDocuments ? titleFontWithCUDocuments : 'titleMBold'}
              value={screenTitle}
              textAlign={textAlignment}
              width="100%"
            />
            {!hasCUDocuments && noDocumentsInformationComponent()}
            <Container marginTop="45px" marginBottom={['40px', '56px']}>
              <Image name="documentSpark" width={['80px', '110px']} />
            </Container>
            {hasCUDocuments && (
              <Grid
                data={CUDocuments}
                key={Date.now()}
                columns={numberColumnsGrid}
                justifyItems="center"
                width="100%"
                maxW="800px"
                renderItemBoxProps={{
                  flexBasis: '140',
                }}
                renderItem={({ item, index }): React.ReactElement => {
                  const { metadata, id, thumbnail, path } = item;
                  let parsedMetadata: undefined | Documents.types.Document['metadata'];

                  // Safely parse metadata as JSON
                  if (metadata) {
                    try {
                      parsedMetadata = JSON.parse(metadata);
                    } catch (error) {
                      // swallow the error;
                    }
                  }
                  return (
                    <DocumentPreviewCard
                      maxHeight="120px"
                      maxW="380px"
                      marginBottom="20px"
                      fileName={resolveFileName(item, index)}
                      badgeText="DA AGENZIA ENTRATE"
                      imageUri={thumbnail ? `data:image/jpeg;base64,${thumbnail}` : undefined}
                      onPress={() => {
                        Analytics.log(AnalyticsEvent.buttonPressed, {
                          screenName: ScreenName.CUDocumentResult,
                          buttonName: 'DocumentPreview',
                        });
                        navigationActions.toRequiredDocumentsPreview('screen', {
                          id,
                          document: {
                            uri: path,
                            contentType: parsedMetadata?.contentType,
                            fileName: parsedMetadata?.originalName ?? '',
                          },
                          onNativeShare: () =>
                            onNativeShare(id, parsedMetadata?.originalName ?? '', accessToken),
                        });
                      }}
                    />
                  );
                }}
              />
            )}
            <Container width="100%" maxW="380px" alignSelf="flex-start" marginBottom="72px">
              <Container
                width="100%"
                marginBottom="24px"
                backgroundColor="secondaryFeedback.greenLight"
                borderRadius="15px"
                overflow="hidden"
              >
                <HStack w="100%" alignItems="center" justifyContent="space-between">
                  <VStack maxW={['200px', '200px', '249px']} paddingY="20px" paddingLeft="20px">
                    <Text variant={tipCardTitleVariant} value={tipCardTitle} marginBottom="8px" />
                    <Text
                      variant={tipCardSubtitleVariant}
                      value={tipCardSubtitle}
                      color="greytones.textLight"
                      lineHeight="16px"
                    />
                  </VStack>
                  <Container margin="auto">
                    <Image name="cake" width="40px" height="44px" resizeMode="contain" />
                  </Container>
                </HStack>
              </Container>
              <ExpandableToggle
                text={getTranslationText('it.cu-document-result.toggle')}
                value={marketingEmails}
                onValueChange={(value) => handleNotificationToggleChange(value)}
              />
            </Container>
          </Container>
        </ScrollView>
      </Container>
      <Footer
        px="14px"
        primaryButtonTitle={
          hasCUDocuments
            ? getTranslationText('it.cu-document-result.footer.with-cu-documents.button-primary')
            : getTranslationText('it.cu-document-result.footer.no-cu-documents.button-primary')
        }
        onPressPrimary={handleGoBackHomePress}
      />
    </>
  );
};
