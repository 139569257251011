import * as React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as SubmissionSDK from '@taxfix/submissions-sdk';
import Config from 'react-native-config';
import { SubmissionWithId } from '@taxfix/submissions-types';

import { WithToastBannerTogglerProps, withToastBannerToggler } from 'src/components/toast-banner';

import { theme } from '../components/core';
import {
  selectors as SubmissionSelectors,
  actions as SubmissionActions,
} from '../stores/modules/submission';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import { FullscreenLoader } from '../components/loading';
import { NavigationActions } from '../routes/config-util';
import { Banner } from '../components/toast';
import { pdfToBase64URI } from '../services/utils';

import { WithNavigation, withNavigation } from './with-navigation';

export type WithSubmissionPDFDownloadProps = {
  submissions: SubmissionWithId[];
  isLoadingSubmissions: boolean;
  hasSubmissionsError: boolean;
  fetchAndOpenPDF: (arg0: SubmissionWithId) => Promise<void>;
};
type HoCState = {
  hasSubmissionsError: boolean;
  isLoading: boolean;
};
type HoCProps = {
  navigationActions: NavigationActions;
  submissions: SubmissionWithId[];
  submissionActions: typeof SubmissionActions;
  isLoadingSubmissions: boolean;
  accessToken: string;
  userId?: number;
} & WithNavigation &
  WithToastBannerTogglerProps;

const mapStateToProps = (state: any) => ({
  submissions: [...SubmissionSelectors.getSubmittedSubmissions(state)].sort(
    (s1, s2) => s2.year - s1.year,
  ),
  isLoadingSubmissions: SubmissionSelectors.isLoading(state),
  accessToken: userAuthSelectors.getAccessToken(state),
  userId: userAuthSelectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  submissionActions: bindActionCreators(SubmissionActions, dispatch),
});

export const withSubmissionPDFDownload = (WrappedComponent: any) => {
  class Enhancer extends React.Component<HoCProps, HoCState> {
    state = {
      hasSubmissionsError: false,
      isLoading: false,
    };

    componentDidMount() {
      if (this.props.userId != null) {
        this.getSubmissions();
      }
    }

    handlePDFDownloadSuccess: (arg0: any) => void = ({ uri, submission }) => {
      const submissionYear: number | null | undefined = submission.year;

      if (!submissionYear) {
        return;
      }

      this.props.navigationActions.toOpenPDF('modal', {
        uri,
        year: submissionYear,
      });
      this.setState({
        hasSubmissionsError: false,
      });
    };

    handleOnPDFDownloadError = () => {
      this.setState({
        hasSubmissionsError: true,
      });
      this.props.showBanner({
        contentView: <Banner titleId="status.error.fetch-failed.message" />,
        backgroundColor: theme.color.errorBackground,
        duration: 7000,
      });
    };

    getSubmissions = async () => {
      const { accessToken, submissionActions, userId } = this.props;

      try {
        if (userId == null) {
          throw Error('userId must be set to get submissions');
        }

        await submissionActions.fetchSubmissions(accessToken, userId);
      } catch {
        this.setState({
          hasSubmissionsError: true,
        });
      }
    };

    onFetchAndOpenPDF = async (submission: SubmissionWithId) => {
      if (submission != null) {
        const { accessToken } = this.props;
        const submissionId = submission?.id;

        try {
          this.setState({
            isLoading: true,
          });
          const result: any = await SubmissionSDK.getLatestResult(
            Config.API_BASE_URL,
            accessToken,
            {
              submissionId,
            },
          );
          const resultId = result.id;
          if (resultId == null) throw new Error('Result ID should persist');
          const uri = pdfToBase64URI(result.pdf);
          this.handlePDFDownloadSuccess({
            uri,
            submission,
          });
        } catch (error) {
          this.handleOnPDFDownloadError();
        } finally {
          this.setState({
            isLoading: false,
          });
        }
      }
    };

    render() {
      const { hasSubmissionsError, isLoading } = this.state;
      const { isLoadingSubmissions } = this.props;
      return (
        <>
          <WrappedComponent
            {...this.props}
            hasSubmissionsError={hasSubmissionsError}
            fetchAndOpenPDF={this.onFetchAndOpenPDF}
          />
          {(isLoadingSubmissions || isLoading) && <FullscreenLoader />}
        </>
      );
    }
  }

  return compose(
    withNavigation,
    withToastBannerToggler,
    connect(mapStateToProps, mapDispatchToProps),
  )(Enhancer);
};
