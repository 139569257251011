import {
  checkTaxIdQF,
  redirectTaxIdQF,
} from 'src/screens/status-screen/middlewares/tax-id-question-flow';
import { skipIfIDUploaded } from 'src/screens/status-screen/middlewares/identification';
import {
  checkPrefillDocumentsDownloaded,
  redirectSPIDLogin,
} from 'src/screens/status-screen/middlewares/spid-login';
import { StatusConfig, StatusStates } from 'src/stores/modules/status';
import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import { DocumentUploadMode, questionFlowCategories } from 'src/common/constants-it';
import { AnalyticsEvent } from 'src/biz-logic/analytics';

import {
  addCompleteIdentificationUploadSubtitle,
  checkIdentification,
  fetchPrefills,
  hasSkippedPrefillSection,
} from '../middlewares/prefill';
import {
  hasSkippedExpenseUploadSection,
  checkExpenseDocUploadCompleteFlow,
  checkIncomeDocUploadCompleteFlow,
  addCompleteIncomeUploadStatus,
  hasSkippedIncomeUploadSection,
  addCompleteExpenseUploadStatus,
} from '../middlewares/required-documents';
import { checkQFProgress } from '../middlewares/season-question-flow';
import {
  statusMiddlewareUpdateIT as updateSubmission,
  statusMiddlewareCompleteIT as submitComplete,
  statusMiddlewareFetch as fetchSubmissions,
  hasActiveSubmissions,
  updateLatestSubmissionStatusAndPayment,
  isLatestSubmissionSubmitted,
  checkUserPayment,
  resubmissionInfoBanner,
  checkSkippedStepsForCompletion,
  skipIfResubmission,
  showDocumentConsentFooter,
  resolveStepTitleInResubmission,
  skipAlreadySubmitted,
} from '../middlewares/submission';
import { profileMigration } from '../middlewares/profile-migration';
import { flagsMigration } from '../middlewares/flags-migration';
import {
  endOfSeasonStatusBanner,
  skipIfEndOfSeason,
  endOfSeasonSubmitComplete,
  endOfSeasonDisableSteps,
  endOfSeasonDisableNavigation,
} from '../middlewares/tax-season';
import { redirectToNotifications } from '../middlewares/redirect';
import {
  checkNotificationsPermissions,
  shouldSkipAskPermission,
} from '../middlewares/notifications';
import { checkAndUpdateUserData } from '../middlewares/user-profile';
import { checkEmailConfirmed } from '../middlewares/email';
import { prefillQF } from '../middlewares/prefill-qf';
import {
  checkProductBundleSelection,
  redirectBundleSelection,
} from '../middlewares/product-bundle';
import { QFChangeMigration } from '../middlewares/qf-change-migration';

// INFO: Complete flow, after every step of the journey we redirect the user to status screen
// This config file it is shown on Resubmission / Resubmission + ID rejection case
export const statusConfig: StatusConfig = {
  title: {
    id: 'status.it.title',
  },
  subtitle: {
    id: 'status.it.subtitle',
  },
  steps: [
    {
      id: StatusStates.StoresMigrations,
      middlewares: [profileMigration, flagsMigration, QFChangeMigration],
    },
    {
      id: StatusStates.FetchSubmissions,
      middlewares: [fetchSubmissions],
    },
    {
      id: StatusStates.UpdateSubmissionAndPaymentStatus,
      middlewares: [updateLatestSubmissionStatusAndPayment()],
    },
    {
      id: StatusStates.EnableNotifications,
      skip: [shouldSkipAskPermission],
      middlewares: [checkNotificationsPermissions],
      redirect: redirectToNotifications,
    },
    {
      id: StatusStates.CheckAndUpdateUserData,
      middlewares: [checkAndUpdateUserData],
    },
    {
      id: StatusStates.TaxIdQF,
      skip: [skipAlreadySubmitted],
      middlewares: [checkTaxIdQF],
      redirect: redirectTaxIdQF,
    },
    {
      id: StatusStates.FetchPrefills,
      middlewares: [fetchPrefills],
    },
    {
      id: StatusStates.PrefillSPIDLogin,
      skip: [skipAlreadySubmitted, skipIfIDUploaded],
      middlewares: [checkPrefillDocumentsDownloaded],
      redirect: redirectSPIDLogin,
    },
    {
      id: StatusStates.ProductBundleSelection,
      skip: [skipAlreadySubmitted, skipIfIDUploaded],
      middlewares: [checkProductBundleSelection],
      redirect: redirectBundleSelection,
    },
    {
      id: StatusStates.SeasonQF,
      skip: [hasActiveSubmissions],
      middlewares: [prefillQF, checkQFProgress],
      postBreakProcessing: [endOfSeasonStatusBanner, resubmissionInfoBanner],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
        },
        new: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
        },
        complete: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toQuizMaster',
              config: 'screen',
              params: {
                root: questionFlowCategories.livingSituation,
                editing: false,
                onNext: () => {
                  getBoundNavigationsActions().toQuizMaster('screen', {
                    root: questionFlowCategories.residenceAddress,
                    editing: false,
                    onNext: () => {
                      getBoundNavigationsActions().toQuizMaster('screen', {
                        root: questionFlowCategories.property,
                        editing: false,
                        onNext: () => {
                          getBoundNavigationsActions().toQuizMaster('screen', {
                            root: questionFlowCategories.dependants,
                            editing: false,
                            onNext: () => {
                              getBoundNavigationsActions().reset({
                                index: 0,
                                actions: [getNavigationActions().toStatus('screen')],
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              },
            },
          },
        },
      },
    },
    {
      id: StatusStates.IncomeDocUpload,
      skip: [
        hasActiveSubmissions,
        skipIfEndOfSeason,
        hasSkippedIncomeUploadSection,
        skipIfResubmission,
      ],
      middlewares: [checkIncomeDocUploadCompleteFlow],
      postBreakProcessing: [
        checkEmailConfirmed('email-new-user'),
        endOfSeasonStatusBanner,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
        },
        complete: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toRequiredDocumentsDigitalCaf',
              config: 'screen',
              params: {
                onNext: () => {
                  getBoundNavigationsActions().reset({
                    index: 0,
                    actions: [getNavigationActions().toStatus('screen')],
                  });
                },
                screenMode: DocumentUploadMode.INCOME_STEP,
              },
            },
          },
        },
        categoryNotComplete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Income',
        },
        new: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
        },
      },
      sectionResolver: addCompleteIncomeUploadStatus,
    },
    {
      id: StatusStates.ExpenseDocUpload,
      skip: [
        hasActiveSubmissions,
        skipIfEndOfSeason,
        hasSkippedExpenseUploadSection,
        skipIfResubmission,
      ],
      middlewares: [checkExpenseDocUploadCompleteFlow],
      postBreakProcessing: [
        checkEmailConfirmed('email-new-user'),
        endOfSeasonStatusBanner,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
        },
        new: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
        },
        complete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toRequiredDocumentsDigitalCaf',
              config: 'screen',
              params: {
                onNext: () => {
                  getBoundNavigationsActions().reset({
                    index: 0,
                    actions: [getNavigationActions().toStatus('screen')],
                  });
                },
                screenMode: DocumentUploadMode.EXPENSES_STEP,
              },
            },
          },
        },
        receipt: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          inProgress: true,
          icon: 'Expense',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'next-receipts',
            },
          },
        },
        categoryNotComplete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
          inProgress: true,
        },
      },
      sectionResolver: addCompleteExpenseUploadStatus,
    },
    {
      id: StatusStates.FetchPrefills,
      middlewares: [fetchPrefills],
    },
    {
      id: StatusStates.Identification,
      skip: [isLatestSubmissionSubmitted, skipIfEndOfSeason, hasSkippedPrefillSection],
      middlewares: [checkIdentification],
      postBreakProcessing: [
        checkEmailConfirmed('email'),
        endOfSeasonStatusBanner,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      navigation: {
        default: {
          title: 'status.it.navigation.default',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
            params: { italyWebFooterButtonTitleId: 'status.it.start.button.title' },
          },
          tracking: {
            eventName: AnalyticsEvent.prefillStarted,
          },
        },
        waiting: null,
        rejectedDocument: {
          title: 'status.it.identification.navigation.rejected',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
          },
        },
        rejectedInfo: {
          title: 'status.it.identification.navigation.rejected',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
          },
        },
      },
      section: {
        todo: {
          title: {
            id: 'status.it.identification.title',
          },
          icon: 'Identification',
        },
        complete: {
          title: {
            id: 'status.it.identification.title',
          },
          icon: 'Identification',
        },
        new: {
          title: {
            id: 'status.it.identification.title',
          },
          icon: 'Identification',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'next-prefill',
            },
          },
        },
        rejected: {
          title: {
            id: 'status.it.identification.title',
          },
          icon: 'Identification',
          warning: true,
        },
      },
      sectionResolver: addCompleteIdentificationUploadSubtitle,
    },
    {
      id: StatusStates.Submit,
      middlewares: [
        checkSkippedStepsForCompletion,
        checkUserPayment,
        updateSubmission,
        // add the consent middleware in case user edits documents after payment
        showDocumentConsentFooter,
      ],
      postBreakProcessing: [
        checkEmailConfirmed('email'),
        endOfSeasonStatusBanner,
        endOfSeasonDisableSteps,
        endOfSeasonDisableNavigation,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      navigation: {
        default: {
          title: 'status.navigation.default.title',
          link: {
            action: 'toBillingAddress',
            config: 'screen',
          },
          tracking: {
            eventName: AnalyticsEvent.filingFlowStarted,
          },
        },
        payment: {
          title: 'status.navigation.default.title',
          link: {
            action: 'toBillingAddress',
            config: 'screen',
          },
        },
        reminder: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toSkippedSectionReminder',
            config: 'screen',
          },
        },
        phoneNumber: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toPhoneNumber',
            config: 'screen',
          },
        },
        draftSubmission: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toItalyDonation',
            config: 'screen',
          },
        },
        pending: null,
        blocked: null,
      },
      section: {
        todo: {
          title: {
            id: 'status.it.submit.title',
          },
          icon: 'ER',
        },
        new: {
          title: {
            id: 'status.it.submit.title',
          },
          icon: 'ER',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'next-payment',
            },
          },
        },
        draftSubmission: {
          title: {
            id: 'status.it.submit.paid.title',
          },
          icon: 'ER',
          inProgress: true,
          tracking: {
            eventName: AnalyticsEvent.filingFlowStarted,
          },
          navigation: {
            link: {
              action: 'toItalyDonation',
              config: 'screen',
            },
          },
        },
        resubmission: {
          title: {
            id: 'status.it.submit.resubmission.title',
          },
          subtitleOverride: 'status.it.submit.resubmission.override-subtitle',
          icon: 'ER',
          stepDisabled: true,
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'resubmission',
            },
          },
        },
        pending: {
          title: {
            id: 'status.it.submit.pending.title',
          },
          icon: 'ER',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'wait-submission-review',
            },
          },
        },
        complete: {
          title: {
            id: 'status.it.submit.paid.title',
          },
          icon: 'ER',
        },
        blocked: {
          title: {
            id: 'status.it.submit.blocked.title',
          },
          icon: 'ER',
        },
      },
      sectionResolver: resolveStepTitleInResubmission,
    },
    {
      id: StatusStates.Complete,
      middlewares: [submitComplete, endOfSeasonSubmitComplete],
      postBreakProcessing: [
        endOfSeasonStatusBanner,
        endOfSeasonDisableSteps,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
    },
  ],
};
