import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { theme } from '../theme';
import { DisplayTitleText } from '../Text';
import { StyleProp } from '../../types';

import InternalButton, { InternalButtonPropsWithStyle } from './_InternalButton';

type CircleButtonProps = InternalButtonPropsWithStyle & {
  translationKey?: string;
  textStyle?: StyleProp;
  values?: Record<string, any>;
  testId?: string;
};
const DIAMETER = 36;
const BORDER_WIDTH = 2;
const styles = StyleSheet.create({
  container: {
    width: DIAMETER,
    height: DIAMETER,
    borderWidth: BORDER_WIDTH,
    borderColor: theme.color.primary,
    borderRadius: DIAMETER / 2,
    justifyContent: 'center',
  },
  pressedContainer: {
    backgroundColor: theme.color.primary,
  },
  text: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: DIAMETER - BORDER_WIDTH,
  },
  pressedText: {
    color: theme.color.lightText,
  },
});
export const CircleButton = ({
  translationKey,
  values = {},
  style,
  textStyle,
  onPressIn,
  onPressOut,
  ...props
}: CircleButtonProps) => {
  const [isBeingPressed, setIsBeingPressed] = useState(false);

  const handlePressIn = () => {
    setIsBeingPressed(true);

    if (onPressIn) {
      onPressIn();
    }
  };

  const handlePressOut = () => {
    setIsBeingPressed(false);

    if (onPressOut) {
      onPressOut();
    }
  };

  let children = null;
  const textStyles: any = [styles.text, isBeingPressed ? styles.pressedText : null, textStyle];
  const containerStyles: any = [
    styles.container,
    isBeingPressed ? styles.pressedContainer : null,
    style,
  ];

  if (translationKey != null) {
    children = (
      <DisplayTitleText
        id={translationKey}
        values={values}
        style={textStyles}
        testId={translationKey}
      />
    );
  } else {
    children = <DisplayTitleText style={textStyles}>{props.children}</DisplayTitleText>;
  }

  return (
    <InternalButton
      {...props}
      style={containerStyles}
      disabled={props.disabled}
      testId={props.testId}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      {children}
    </InternalButton>
  );
};
