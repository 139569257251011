import { CountryCodes } from '@taxfix/types';
import React, { useCallback, useEffect } from 'react';

import { Box } from 'src/components/core';
import { Toast } from 'src/components/toast';
import { Transition, useToastBannerToggler } from 'src/components/toast-banner';
import { useNavigation } from 'src/hooks/navigation-hook';
import { stopPerformanceTraceWithLabel } from 'src/services/firebase-performance';
import { PerformanceLabels } from 'src/services/firebase-performance.labels';

import { ItalyOnboarding } from '../components/italy-onboarding';

import { ContainerProps, ToastBannerConfig } from './italy-onboarding';

export const ItalyOnboardingContainer: React.FC<ContainerProps> = ({ toastConfig }) => {
  const { safeResetNavigation, getNavigationActions } = useNavigation();

  const { showBanner } = useToastBannerToggler();
  const showToastBanner = useCallback((toastConfig: ToastBannerConfig) => {
    showBanner({
      contentView: (
        <Box top={2} left={2} right={2}>
          <Toast
            titleId={toastConfig.titleId}
            iconName={toastConfig.iconName}
            tintColor={toastConfig.tintColor}
          />
        </Box>
      ),
      transitions: [Transition.FadeInOut],
      disableHideOnPress: true,
    });
  }, []);

  useEffect(() => {
    stopPerformanceTraceWithLabel(PerformanceLabels.appLaunch, {
      taxCountry: CountryCodes.IT,
      isAuthenticated: 'no',
    });
    if (toastConfig) {
      showToastBanner(toastConfig);
    }
  }, [toastConfig, showToastBanner]);

  const handleNext = () => {
    safeResetNavigation([getNavigationActions().toProfileOccupation('screen')]);
  };

  return <ItalyOnboarding onPress={handleNext} />;
};
