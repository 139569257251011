import { Prefill } from '@taxfix/italy-types';

import { OptimizationVersion } from 'src/_italy/prefill-documents-result/result-optimization/result-optimization-types';

import { navigationActions } from '../../../../routes/intl/it/config-it';

import { MenuOption } from './types';

export function buildResultOptimizationPossible(): MenuOption {
  const handlePress = () => {
    navigationActions.reset({
      index: 0,
      actions: [
        navigationActions.toResultOptimizationScreen('screen', {
          optimizationVersion: OptimizationVersion.OptimizationPossible,
          filteredPrefillDocuments: [],
          optimizationResult: {
            total: 700,
            optimizations: [
              {
                name: Prefill.Optimizations.MissingWorkdays,
                value: 100,
              },
              {
                description: Prefill.Optimizations.MissingBenefits,
                value: 250,
              },
              {
                description: Prefill.Optimizations.ChildrenExpenses,
                value: 350,
              },
            ],
          },
          primaryButtonAction: () => null,
        }),
      ],
    });
  };

  return {
    text: 'Result Optimization (optimization possible)',
    onPress: handlePress,
    showArrow: true,
  };
}

export function buildResultOptimizationNoOptimization(): MenuOption {
  const handlePress = () => {
    navigationActions.reset({
      index: 0,
      actions: [
        navigationActions.toResultOptimizationScreen('screen', {
          optimizationVersion: OptimizationVersion.NoOptimization,
          optimizationResult: { total: 0, optimizations: [] },
          filteredPrefillDocuments: [],
          primaryButtonAction: () => null,
        }),
      ],
    });
  };

  return { text: 'Result Optimization (No optimization)', onPress: handlePress, showArrow: true };
}
