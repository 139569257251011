import React, { ComponentType, ReactElement } from 'react';

import { NavigationHook, useNavigation } from '../hooks/navigation-hook';

export type { NavigationHook as WithNavigation };

export const withNavigation = (WrappedComponent: ComponentType<any>): any => {
  const WithNavigationWrapper = (props: any): ReactElement<typeof WrappedComponent> => {
    const {
      navigation,
      navigationActions,
      navigationParams,
      navigationState,
      isFocused,
      safeResetNavigation,
    } = useNavigation();
    return (
      <WrappedComponent
        navigation={navigation}
        navigationState={navigationState}
        navigationActions={navigationActions}
        navigationParams={navigationParams}
        isFocused={isFocused}
        safeResetNavigation={safeResetNavigation}
        {...props}
      />
    );
  };

  return WithNavigationWrapper;
};
