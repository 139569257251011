import * as React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';

import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { ItalySubmissionContainer } from '../../../screens/containers/italy-submission';
import { DonationPreferences } from '../../../screens/containers/italy-donation';
import { useDefaultNavigationOptions } from '../../hooks/navigation-options-hook';

type ActionParams = {
  donationPreferences: DonationPreferences;
};

type Props = Navigation<
  'ItalySubmission',
  {
    ItalySubmission: NavigationParams<ActionParams>;
  }
>;

export const navigateTo: Action = navigate('ItalySubmission', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ITSubmission,
  },
});

export const ItalySubmissionRoute = ({ route }: Props): JSX.Element => {
  const { donationPreferences } = route.params;

  useDefaultNavigationOptions({ id: 'italy-submission.header-title' });

  return <ItalySubmissionContainer donationPreferences={donationPreferences} />;
};
