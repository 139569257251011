import { CountryCodes } from '@taxfix/types';

import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import { getStore } from '../stores/util';
import { IgnoredWarnings } from '../stores-legacy/QuestionStore.types';

import { appDataFunction, configKey } from './firebase-functions';

export const getMultipleWarnings = async ({
  countryCode,
  year,
}: {
  countryCode: CountryCodes;
  year: number;
}): Promise<IgnoredWarnings> => {
  const store = getStore();
  const accessToken = store && userAuthSelectors.getAccessToken(store.getState());

  if (accessToken) {
    const data = await appDataFunction.getData({
      country: countryCode,
      accessToken,
      key: `${countryCode}.${year.toString()}.${configKey.ignoredWarnings}`,
      defaultValue: {},
    });
    return data || {};
  }

  return {};
};
export const updateMultipleWarnings = async ({
  countryCode,
  year,
  updatedValue,
}: {
  countryCode: CountryCodes;
  year: number;
  updatedValue: IgnoredWarnings;
}) => {
  const store = getStore();
  const accessToken = store && userAuthSelectors.getAccessToken(store.getState());

  if (accessToken) {
    await appDataFunction.updateData({
      country: countryCode,
      accessToken,
      key: `${countryCode}.${year.toString()}.${configKey.ignoredWarnings}`,
      updatedValue,
    });
  }
};
