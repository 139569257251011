import React from 'react';
import { useSelector } from 'react-redux';
import { Linking } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import { useNavigation } from 'src/hooks/navigation-hook';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { Policy, WithLegalConditions, withLegalConditions } from 'src/hocs/with-legal-conditions';

import { useItalyIntl } from '../_hooks/use-italy-intl';

import { ItalyGDPRConsentComponent } from './italy-gdpr-consent';

type Props = WithLegalConditions;

const ItalyGDPRConsent: React.FC<Props> = ({ handleTermsClicked }) => {
  const { getTranslationText } = useItalyIntl();
  const { navigation } = useNavigation();

  const isLargeScreen = useLargeScreenQuery();
  const isPreseason = useSelector(getTaxSeasonInfo).isPreSeason;

  const content = getTranslationText(
    isPreseason ? 'gdpr.consent.preseason.content' : 'gdpr.consent.content',
  );

  const handleLinkPress = (url: string) => {
    if (url === 'toPrivacyPolicy') {
      handleTermsClicked(Policy.privacy);
    } else {
      Linking.openURL(url);
    }
  };

  return (
    <ItalyGDPRConsentComponent
      content={content}
      onLinkPress={handleLinkPress}
      backNavigationAction={navigation.goBack}
      shouldShowBackButton={isLargeScreen && navigation.canGoBack()}
    />
  );
};

export const ItalyGDPRContainer = withLegalConditions(ItalyGDPRConsent);
