import * as React from 'react';

import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { getRoute } from 'src/routes/navigate';
import { ScreenName } from 'src/types/screen-name';

import PrivacySettingsContainer from '../component/privacy-settings';

export type ActionParams = {
  content: string;
  contentStyles: string;
};

const PrivacySettingsScreen = (): JSX.Element => {
  useDefaultNavigationOptions({ id: 'it.account.privacy-settings.title' });
  return <PrivacySettingsContainer />;
};

export const { action: toPrivacySettingsIT, screen: PrivacySettingsScreenIT } =
  getRoute<ActionParams>({
    routeName: ScreenName.ItalyPrivacySettings,
    screenComponent: PrivacySettingsScreen,
  });

export default PrivacySettingsScreen;
