const iconMap: Record<string, string> = {
  DEFAULT: 'documentFile',
  cat_residence_house_owned: 'house',
  cat_properties_rented: 'houseEuro',
  cat_residence_house_rented: 'houseEuro',
  cat_medical: 'firstAidBox',
  cat_agency_fees: 'houseParagraph',
  cat_insurance: 'handShield',
  cat_transportation: 'bus',
  cat_mortgage: 'handBarChart',
  cat_veterinary: 'cat',
  cat_education: 'appleBook',
  cat_sport_for_children: 'bike',
  cat_donations: 'handHeart',
  cat_funeral: 'coffin',
  cat_home: 'paintRoller',
  cat_electric_vehicle_charging: 'carTaxi',
  cat_pension_contributions: 'moneyHand',
  cat_alimony: 'heart',
  cat_paid_back_to_employer: 'handPercent',
  cat_tax_credit: 'coin',
  cat_prepayment_and_carryover: 'bagMoney',
  cat_other_deductible_costs: 'bagPercent',
  cat_other_income: 'briefcase',
  cat_others: 'addMore',
  cat_music_bonus: 'organKeys',
  cat_superbonus: 'lightBulb',
  cat_green_works: 'wateringCan',
  cat_facade_bonus: 'houseHome',
  cat_contributions_dependants: 'hatStudent',
  cat_compensatory_instruments: 'book',
  cat_energy_efficiency_works: 'energy',
  cat_sisma: 'houseWalls',
  cat_house_purchase: 'housePercent',
  cat_furniture_bonus: 'sofa',
  cat_medical_disabled: 'nurseHat',
  DOCUMENT_INCOME_RESIDENCE_HOUSE_OWNED: 'house',
  DOCUMENT_INCOME_PROPERTIES_RENTED_OUT: 'houseEuro',
  DOCUMENT_EXPENSE_HOME_RENT_GENERAL: 'houseEuro',
  DOCUMENT_EXPENSE_HEALTH_HEALTHCARE_GENERAL: 'firstAidBox',
  DOCUMENT_EXPENSE_FINANCES_PUBLIC_TRANSPORT: 'bus',
  DOCUMENT_EXPENSE_HOME_MORTGAGES_GENERAL: 'handBarChart',
  DOCUMENT_EXPENSE_HEALTH_VETERINARY: 'cat',
  DOCUMENT_EXPENSE_EDUCATION_GENERAL: 'appleBook',
  DOCUMENT_EXPENSE_FAMILY_SPORTING_ACTIVITIES: 'bike',
  DOCUMENT_EXPENSE_FINANCES_DONATIONS_GENERAL: 'handHeart',
  DOCUMENT_EXPENSE_FAMILY_FUNERAL: 'coffin',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_AGENCY_FEES: 'houseParagraph',
  DOCUMENT_EXPENSE_FAMILY_EDUCATION_DISABILITY_EQUIPMENT: 'book',
  DOCUMENT_EXPENSE_MUSIC_BONUS: 'organKeys',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_RENOVATION: 'paintRoller',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SISMA: 'houseWalls',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_GREEN: 'wateringCan',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FACADE: 'houseHome',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_ENERGY: 'energy',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FURNITURE: 'sofa',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SUPERBONUS: 'lightBulb',
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_HOUSE_A_B: 'housePercent',
  DOCUMENT_EXPENSE_FINANCES_CHARGING_STATIONS: 'carTaxi',
  DOCUMENT_EXPENSE_FINANCES_PENSIONS_UNIVERSITY_DEPENDANTS: 'hatStudent',
  DOCUMENT_EXPENSE_FINANCES_PENSIONS_GENERAL: 'moneyHand',
  DOCUMENT_EXPENSE_FINANCES_EX_SPOUSE_ALIMONY: 'heart',
  DOCUMENT_EXPENSE_INCOME_RETURNED_TO_EMPLOYER: 'handPercent',
  DOCUMENT_EXPENSE_FINANCES_TAX_CREDITS_GENERAL: 'coin',
  DOCUMENT_EXPENSE_PREPAYMENT_AND_CARRYOVER: 'bagMoney',
  DOCUMENT_EXPENSE_OTHER_DEDUCTIBLE: 'bagPercent',
  DOCUMENT_INCOME_OTHER_INCOME: 'briefcase',
  DOCUMENT_EXPENSE_CURRENT_HEALTH_HEALTHCARE_DISABLED: 'nurseHat',
  DOCUMENT_EXPENSE_FINANCES_INSURANCES_GENERAL: 'handShield',
  ADD_OTHER_EXPENSES: 'searchFile',
};

export default iconMap;
