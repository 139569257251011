import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

export type Props = {
  id: string;
  testId?: string;
  defaultMessage?: string;
  values?: Record<string, any>;
  Component: React.ElementType;
};
type IntlComponentProps = Props & {
  Component: React.ElementType;
};
type WrappedProps = IntlComponentProps & {
  intl: IntlShape;
};

const IntlComponent = function IntlText(props: WrappedProps) {
  const { intl, id, defaultMessage, testId, values, Component }: any = props;
  return (
    <Component {...props}>
      {intl.formatMessage(
        {
          id,
          defaultMessage,
          testId,
        },
        values,
      )}
    </Component>
  );
};

const FlowFriendlyComponent = injectIntl(IntlComponent);
export default (props: IntlComponentProps) => <FlowFriendlyComponent {...props} />;
