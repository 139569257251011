import Config from 'react-native-config';
import { CountryCodes } from '@taxfix/types';

export const getStripePublishableKey = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.IT:
      return Config.STRIPE_PUBLISHABLE_KEY_IT;

    case CountryCodes.DE:
      return Config.STRIPE_PUBLISHABLE_KEY_DE;

    case CountryCodes.ES:
      return Config.STRIPE_PUBLISHABLE_KEY_ES;

    default:
      throw new Error(`Unsupported Stripe config for country ${countryCode}`);
  }
};

export const maskIban = (iban: string | null | undefined) => {
  if (iban) {
    const arr = iban.split('');
    return arr
      .map((letter, index) => {
        if (index > 3 && index < arr.length - 4) {
          return '*';
        }

        return letter;
      })
      .join('');
  }

  return '';
};
