import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Config from 'react-native-config';
import { Platform } from 'react-native';
import { useEffect } from 'react';

const codePush = Platform.OS !== 'web' && require('react-native-code-push');

import { State as RootState } from 'src/stores/store/initial';

import { selectors as remoteConfigSelectors } from '../stores/modules/remote-config-firebase';
import {
  actions as codePushActions,
  selectors as codePushSelectors,
} from '../stores/modules/code-push';
import useAppState from '../hooks/useAppState';

const isCodePushDisabled = Config.CODE_PUSH_DISABLE_SYNC === 'true';

const mapStateToProps = (state: RootState) => ({
  shouldCheckForUpdate:
    !isCodePushDisabled &&
    remoteConfigSelectors.isEnabledCodePush(state) && // Make sure dynamic link has been entirely processed, as it may
    // overwrite deployment key
    state.dynamicLink.state === 'handled' &&
    !state.dynamicLink.dynamicLink &&
    !codePushSelectors.skipInstallUpdate(state),
  willInstallUpdate: codePushSelectors.willInstallUpdate(state),
  codePushStatus: codePushSelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  syncCodePush: bindActionCreators(codePushActions.sync, dispatch),
  setWillInstallUpdate: bindActionCreators(codePushActions.setWillInstallUpdate, dispatch),
  setMetadata: bindActionCreators(codePushActions.setMetadata, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

const CodePushCheckerUndecorated = ({
  shouldCheckForUpdate,
  codePushStatus,
  willInstallUpdate,
  setWillInstallUpdate,
  syncCodePush,
  setMetadata,
}: Props) => {
  useEffect(() => {
    setMetadata();
  }, []);

  useEffect(() => {
    if (shouldCheckForUpdate) {
      syncCodePush();
    }
  }, [shouldCheckForUpdate, syncCodePush]);

  // Attach listener to check for CP updates
  useAppState(
    shouldCheckForUpdate
      ? {
          onForeground: () => syncCodePush(),
          onBackground: () => {
            if (!willInstallUpdate && codePushStatus === codePush.SyncStatus.UPDATE_INSTALLED) {
              // This enables restarting the app after an update when the is back to foreground
              // in case the disallowRestart() call is called somewhere in the app.
              codePush.allowRestart();

              // We need to unmount any component that attaches gesture handlers like react-navigation
              // for avoiding crashes when codePush restarts the app https://taxfix.atlassian.net/browse/FPT-158
              setWillInstallUpdate(true);
            }
          },
        }
      : {},
  );

  return null;
};

export const CodePushChecker = connector(CodePushCheckerUndecorated);
