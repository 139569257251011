import React from 'react';
import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { SVGProps } from '../types';

export const IdentificationIconGrey: React.FC<SVGProps> = ({ size = '100%' }): JSX.Element => (
  <Icon viewBox="0 0 64 64" size={size}>
    <Rect width="64" height="64" rx="32" fill="#F6F6F6" />
    <Rect x="12.0713" y="18.5" width="39.8571" height="27" rx="1.07527" fill="white" />
    <Rect x="14.6426" y="41.4277" width="34.5" height="1.92857" rx="0.430108" fill="#E1E1E1" />
    <Path
      d="M31.4475 28.7866L39.9288 28.8937"
      stroke="#949494"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M31.5083 31.9994L45.7146 32.1066"
      stroke="#949494"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M31.4475 35.4291L39.9288 35.5363"
      stroke="#949494"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M15.5306 21.7143L38.6423 21.8214"
      stroke="#949494"
      stroke-width="1.72043"
      stroke-linecap="round"
    />
    <Path
      d="M24.8701 26.5645H26.6157C26.748 26.5645 26.8749 26.617 26.9684 26.7105C27.0619 26.8041 27.1145 26.9309 27.1145 27.0632V28.8088"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M24.8701 37.5373H26.6157C26.748 37.5373 26.8749 37.4848 26.9684 37.3913C27.0619 37.2977 27.1145 37.1709 27.1145 37.0386V35.293"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3869 37.5373H16.6413C16.509 37.5373 16.3822 37.4848 16.2887 37.3913C16.1951 37.2977 16.1426 37.1709 16.1426 37.0386V35.293"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3869 26.5645H16.6413C16.509 26.5645 16.3822 26.617 16.2887 26.7105C16.1951 26.8041 16.1426 26.9309 16.1426 27.0632V28.8088"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M21.6288 33.0486C22.7306 33.0486 23.6238 32.1554 23.6238 31.0536C23.6238 29.9518 22.7306 29.0586 21.6288 29.0586C20.527 29.0586 19.6338 29.9518 19.6338 31.0536C19.6338 32.1554 20.527 33.0486 21.6288 33.0486Z"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M18.3184 35.0427C18.6372 34.4413 19.1138 33.9381 19.697 33.5871C20.2803 33.2362 20.9481 33.0508 21.6288 33.0508C22.3095 33.0508 22.9773 33.2362 23.5605 33.5871C24.1438 33.9381 24.6204 34.4413 24.9392 35.0427"
      stroke="#E1E1E1"
      stroke-width="0.645162"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Icon>
);
