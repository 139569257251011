import { shape, string, arrayOf } from 'prop-types';

import { LanguageCode } from '../../types';

const translations = arrayOf(string);
const localeInfoShape = shape({
  language: string,
  locale: string,
  days: shape({
    abbreviated: translations,
    narrow: translations,
    short: translations,
    wide: translations,
  }),
  months: shape({
    abbreviated: translations,
    narrow: translations,
    wide: translations,
  }),
  numbers: shape({
    symbols: shape({
      decimal: string,
      group: string,
      minusSign: string,
      percentSign: string,
      plusSign: string,
    }),
  }),
});
type Translations = string[];
export type LocaleInfo = {
  language: LanguageCode;
  locale: string;
  days: {
    abbreviated: Translations;
    narrow: Translations;
    short: Translations;
    wide: Translations;
  };
  months: {
    abbreviated: Translations;
    narrow: Translations;
    wide: Translations;
  };
  numbers: {
    symbols: {
      decimal: string;
      group: string;
      minusSign: string;
      percentSign: string;
      plusSign: string;
    };
  };
};
export { localeInfoShape };
