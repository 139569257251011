import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDefaultTaxYear } from 'src/services/country-and-year';
import { OverrideStatusConfigs } from 'src/screens/status-screen/config/types';

import { useBackNavigationControl } from '../../hooks/back-navigation-control-hook';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import {
  useDefaultNavigationOptions,
  useWebNavigationOptions,
} from '../../routes/hooks/navigation-options-hook';

import { StatusContainer } from './status-screen-container';

export type ActionParams = {
  overrideConfig?: OverrideStatusConfigs;
  goBackEnabled?: boolean;
};

type Props = Navigation<Routes.Status, { [Routes.Status]: NavigationParams<ActionParams> }>;

const StatusRouteUndecorated: React.FC<Props> = ({ route }) => {
  const { enableGoBack, disableGoBack } = useBackNavigationControl();
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const { overrideConfig, goBackEnabled } = route.params;

  useDefaultNavigationOptions({
    id: 'it.status-screen.nav.title',
    values: {
      taxSeason: selectedYear + 1,
    },
  });

  useWebNavigationOptions({
    footer: () => {},
    isFullHeight: true,
  });

  useEffect(() => {
    if (goBackEnabled) {
      enableGoBack();
    } else {
      disableGoBack();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StatusContainer overrideConfig={overrideConfig} />;
};

export const StatusRoute = StatusRouteUndecorated;

export const { action: toStatus, screen: statusScreen } = getRoute<ActionParams>({
  routeName: Routes.Status,
  screenComponent: StatusRoute,
});
