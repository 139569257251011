import { CountryCodes } from '@taxfix/types';

import { privacyConfig as defaultConfig, PrivacyConfig } from './signup/privacy-config';
import { privacyConfig as privacyConfigIT } from './signup/privacy-config-it';
import { privacyConfig as privacyConfigITPreSeason } from './signup/privacy-config-it-pre-season';
import { privacyConfig as privacyConfigDE } from './signup/privacy-config-de';

export const getSignUpLegalRequirements = (
  countryCode: CountryCodes,
  isPreSeason = false,
): PrivacyConfig[] => {
  switch (countryCode) {
    case CountryCodes.IT:
      return isPreSeason ? privacyConfigITPreSeason : privacyConfigIT;

    case CountryCodes.DE:
      return privacyConfigDE;

    default:
      return defaultConfig;
  }
};
