import * as React from 'react';
import { useDispatch } from 'react-redux';
import type { Dispatch } from 'redux';
import { useIsFocused } from '@react-navigation/native';

import { useTimeout } from 'src/hooks/use-timeout';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { setItem, getItem } from 'src/utils/asyncStorage';

import { IdleHelpPopupProps } from '../../screens/components/result-screen-idle-help-popup';

const TIMEOUT_DELAY = 10000; // 10 seconds
const ASYNC_STORAGE_KEY = 'resultScreenIdlePopupShown';

async function showPopup(dispatch: Dispatch, popupParams: IdleHelpPopupProps): Promise<void> {
  await setItem(ASYNC_STORAGE_KEY, 'true');

  dispatch(overlayActions.show('ResultScreenIdleHelpPopup', popupParams));
}

export const useIdlePopup = (popupParams: IdleHelpPopupProps): void => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { start, clear } = useTimeout(() => showPopup(dispatch, popupParams), TIMEOUT_DELAY);

  React.useEffect(() => {
    if (isFocused) {
      const checkIfAlreadyShown = async () => {
        const alreadyShown = await getItem(ASYNC_STORAGE_KEY);
        if (alreadyShown !== 'true') {
          start();
        }
      };

      checkIfAlreadyShown();
    }

    return () => {
      if (isFocused) {
        clear();
      }
    };
  }, [clear, start, isFocused]);
};
