import React from 'react';
import { View } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Container, Text } from 'src/taxfix-components/src';
import { DocumentPreview } from 'src/components/document-preview';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { RefViewShotElement } from '../types';

type Props = {
  files: string[];
  refViewShot?: RefViewShotElement;
};

export const Preview: React.FC<Props> = ({ files, refViewShot }) => {
  const { getTranslationText } = useItalyIntl();
  const titleVariant = useBreakpointValue({ base: 'titleSMedium', md: 'titleLBold' });
  const titleMarginBottom = useBreakpointValue({ base: '48px', md: '32px' });

  return (
    <Container alignItems="center">
      <Text
        variant={titleVariant}
        color="greytones.backgroundDark"
        value={getTranslationText('prefill.document-upload.review.title')}
        marginBottom={titleMarginBottom}
      />
      <View
        collapsable={false}
        style={{
          flex: 1,
          maxHeight: '99%',
          minHeight: 300,
          width: '100%',
        }}
        // @ts-ignore
        ref={refViewShot}
      >
        {files.map((uri) => (
          <DocumentPreview key={uri} source={{ uri }} style={{ marginBottom: 30 }} />
        ))}
      </View>
    </Container>
  );
};
