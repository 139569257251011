import { CountryCodes } from '@taxfix/types';

import { Address, Coordinate } from '../../../types/geo';

const emptyAddress: Address = {
  number: null,
  numberExtension: null,
  postalCode: null,
  addition: null,
  street: null,
  city: null,
};
const emptyCoordinate: Coordinate = {
  latitude: -1,
  longitude: -1,
};
export const buildEmptyPlace = (country?: CountryCodes | null | undefined) => ({
  address: emptyAddress,
  displayName: null,
  countryCode: country || '',
  coordinate: emptyCoordinate,
  postalCode: '',
  state: null,
});
