import { useBreakpointValue, useTheme } from 'native-base';
import React from 'react';

import { AvatarGroup, Button, Card, Container, Icon, icons, Text } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * V2 of Tax Experts Card
 */

export const TaxExpertsCardV2: React.FC<Props> = ({
  avatarSources,
  headerTitle,
  services = [],
  ctaButtonTitle,
  ctaButtonTestID,
  ctaOnPress,
  footerTitle,
  ...otherProps
}): JSX.Element => {
  const { colors, sizes } = useTheme();
  const cardTitleMarginBottom = useBreakpointValue({ base: 3, md: 5 });
  const cardMaxWidth = useBreakpointValue({ base: 480, md: '64%', lg: 480 });
  const cardMinWidth = useBreakpointValue({ base: 'auto', md: 480 });
  const avatarSize = useBreakpointValue({ base: 12, md: 16 });
  const avatarBorderWidth = useBreakpointValue({ base: 2, md: 4 });
  const avatarMarginLeft = useBreakpointValue({ base: '-28px', md: '-32px' });
  const avatarProps = {
    borderWidth: avatarBorderWidth,
    marginLeft: avatarMarginLeft,
    size: avatarSize,
  };
  const serviceTextVariant = useBreakpointValue({ base: 'bodySBook', md: 'bodyMBook' });
  const serviceMarginTop = useBreakpointValue({ base: 32, md: 28 });
  const buttonWidth = useBreakpointValue({ base: sizes.xl, md: '60%' });

  return (
    <Card
      padding={2}
      backgroundColor={colors.greytones.white}
      variant="elevation2"
      width={sizes.full}
      maxWidth={cardMaxWidth}
      minWidth={cardMinWidth}
      {...otherProps}
    >
      <Container
        flexDirection="row"
        alignItems="center"
        marginBottom={cardTitleMarginBottom}
        width="100%"
      >
        <AvatarGroup avatarSources={avatarSources} certified={false} avatarProps={avatarProps} />
        <Text marginLeft={20} variant="titleXSBold" value={headerTitle} flexShrink={1} />
      </Container>
      <Container paddingLeft="4px" marginBottom={52}>
        {services.map((label, index) => (
          <Container
            flexDirection="row"
            alignItems="center"
            key={label}
            marginTop={index === 0 ? '0px' : serviceMarginTop}
          >
            <Icon
              path={icons.checkMark}
              color={colors.brand.brandGreen}
              size="6"
              minW={6}
              marginRight={12}
            />
            <Text
              variant={serviceTextVariant}
              value={label}
              color={colors.greytones.graphicsDark}
              flexShrink={1}
            />
          </Container>
        ))}
      </Container>
      <Container>
        <Button
          variant="primary"
          textAlign="center"
          alignContent="center"
          alignItems="center"
          title={ctaButtonTitle}
          width={buttonWidth}
          onPress={ctaOnPress}
          testID={ctaButtonTestID}
        />

        <Text
          value={footerTitle}
          color={colors.greytones.graphicsDark}
          marginTop={1}
          marginBottom={3}
          marginX="auto"
          variant="bodyXSBook"
        />
      </Container>
    </Card>
  );
};
