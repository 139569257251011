import {
  createDynamicMiddlewares,
  addMiddleware,
  removeMiddleware,
} from 'redux-dynamic-middlewares';

export const dynamicMiddleware = createDynamicMiddlewares();
export const getDynamicMiddlewares = (passedMiddlewares: Array<(arg0: any) => any>) => {
  const middlewares = [...passedMiddlewares, dynamicMiddleware.enhancer];
  return middlewares;
};
export { addMiddleware, removeMiddleware };
