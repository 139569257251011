import { PrivacyConfig } from './privacy-config';

export const privacyConfig: PrivacyConfig[] = [
  {
    name: 'terms',
    translationKey: 'account.accept-terms.terms.it',
  },
  {
    name: 'spid',
    translationKey: 'account.accept.spid-consent',
  },
  {
    name: 'gdpr',
    translationKey: 'account.accept.gdpr-consent',
  },
];
