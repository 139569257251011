import { GestureHandlerRootView } from 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Provider, useSelector } from 'react-redux';
import { Provider as MobxProvider } from 'mobx-react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PersistGate } from 'redux-persist/integration/react';
import { enableFreeze, enableScreens } from 'react-native-screens';
import Config from 'react-native-config';
import type { CountryCodes } from '@taxfix/types';
import type { LinkingOptions } from '@react-navigation/native';

import { selectors as codePushSelectors } from 'src/stores/modules/code-push';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { questionStoresIT } from 'src/stores-legacy';
import { create, createPersistor, attachListeners } from 'src/stores/store';
import { Loading } from 'src/components/loading';
import { SmartAppBanner } from 'src/components/smart-app-banner';
import { NavigationContainer } from 'src/services/navigation-service';
import { isWeb } from 'src/utils/platform';
import type { TrackingMap } from 'src/services/hooks/navigation-tracking-hook';

import { Splashscreen } from './splashscreen';
import Bootstrap from './bootstrap';

// flowlint untyped-import:error
// Don't enable in prod as breaks bottom-tabs-navigation
enableFreeze(__DEV__);
enableScreens(true);

const store = create();
const persistor = createPersistor(store);
attachListeners(store);
const styles = StyleSheet.create({
  webContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  rootContainer: {
    flex: 1,
  },
});

const WebContainer = ({ children }: any) => {
  React.useEffect(() => {
    if (isWeb) {
      try {
        const preAppElement: HTMLElement | null = document.getElementById('pre-app-root');

        if (preAppElement) {
          // Finish animations
          preAppElement.className = `${preAppElement.className} pre-app-container_finishing`;
        }

        const timeOnPage = new Date().getTime() - performance.timing.navigationStart;
        let finishTimeout: number;

        if (timeOnPage <= 10000) {
          finishTimeout = Math.max(5000 - (timeOnPage % 5000), 2000);
        } else {
          finishTimeout = 2000;
        }

        if (Config.SHOW_DEBUG_SCREEN === 'true') {
          finishTimeout = 0;
        }

        // Remove pre-app
        setTimeout(function removePreApp() {
          if (preAppElement && preAppElement.parentNode) {
            preAppElement.parentNode.removeChild(preAppElement);
          }

          if (window.preAppWorker != null) {
            window.preAppWorker.terminate();
          }
        }, finishTimeout);
      } catch (error) {
        logger.warn("Can't remove pre-app");
      }
    }
  }, []);
  return isWeb ? <View style={styles.webContainer}>{children}</View> : children;
};

const linkingByCountryCode: Record<
  CountryCodes,
  LinkingOptions<ReactNavigation.RootParamList> | undefined
> = {
  ES: undefined,
  IT: undefined,
  DE: undefined,
  FR: undefined,
};
const trackingByCountryCode: TrackingMap = {
  ES: undefined,
  IT: undefined,
  DE: undefined,
  FR: undefined,
};
const showBannerByCountryCode = {
  ES: false,
  IT: false,
  DE: false,
  FR: false,
};

const AppContainer = () => {
  const willInstallCodePushUpdate = useSelector(codePushSelectors.willInstallUpdate);
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const linking = linkingByCountryCode[selectedCountry];

  if (willInstallCodePushUpdate) {
    return <Loading />;
  }

  return (
    <WebContainer>
      <SafeAreaProvider>
        <NavigationContainer linking={linking} trackingMap={trackingByCountryCode}>
          {showBannerByCountryCode[selectedCountry] && <SmartAppBanner />}
          <Bootstrap />
        </NavigationContainer>
      </SafeAreaProvider>
    </WebContainer>
  );
};

export const BootstrapRoot = () => {
  React.useEffect(() => {
    Splashscreen.hide();
  }, []);
  return (
    <GestureHandlerRootView style={styles.rootContainer}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <MobxProvider {...{ questionStores: {}, questionStoresIT }}>
            <AppContainer />
          </MobxProvider>
        </PersistGate>
      </Provider>
    </GestureHandlerRootView>
  );
};
