import { useBreakpointValue, useTheme } from 'native-base';
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import HTML from 'react-native-render-html';

import { useChatPopup } from 'src/_italy/_hooks';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { IconButton, theme } from 'src/components/core';
import Overlay from 'src/components/overlay';
import { parseMarkdown } from 'src/services/markdown-parser';
import { Container, Text, TextLink } from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
    justifyContent: 'space-between',
  },
  overlayWeb: {
    maxWidth: 540,
    height: '100%',
  },
  header: {
    backgroundColor: theme.color.lightFill,
    width: '100%',
    justifyContent: 'flex-start',
  },
});

type Props = {
  onClose: () => void;
  contentKey: string;
  titleKey: string;
};

export const CUDocumentResultInfoOverlay = ({
  onClose: onOverlayClose,
  titleKey,
  contentKey,
}: Props): JSX.Element => {
  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'CUDocumentResultInfoOverlay',
    });
  }, []);

  const isLargeScreen = useBreakpointValue({ base: false, md: true });
  const { getTranslationText } = useItalyIntl();
  const { colors } = useTheme();
  const { handleOpenChatPopup } = useChatPopup();

  const contentFontStyle = {
    fontFamily: 'CircularStd-Book',
    color: colors.greytones.textDefault,
    fontSize: 16,
    lineHeight: 20,
    padding: 0,
  };

  const htmlStyles = {
    h2: {
      fontFamily: 'CircularStd-Bold',
      color: colors.greytones.textDefault,
      marginTop: 20,
      lineHeight: 20,
      fontSize: 16,
    },

    p: {
      ...contentFontStyle,
      marginBottom: 10,
    },
    ul: {
      marginBottom: 3,
      padding: 0,
    },
    li: {
      ...contentFontStyle,
    },
  };

  const handleOnPressSupport = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.CUDocumentResult,
      buttonName: getTranslationText('it.overlays.cu-document-result.footer.link'),
    });
    handleOpenChatPopup();
  };
  return (
    <Overlay
      onCloseComplete={onOverlayClose}
      style={isLargeScreen ? [styles.overlay, styles.overlayWeb] : styles.overlay}
      position={isLargeScreen ? 'right' : 'bottom'}
    >
      {({ onClose }: any) => (
        <>
          <ScrollView
            style={{ display: 'flex' }}
            contentContainerStyle={{
              alignContent: 'flex-end',
              paddingLeft: isLargeScreen ? 0 : 64,
              paddingRight: isLargeScreen ? 0 : 44,
              padding: isLargeScreen ? 64 : 30,
              height: '100%',
            }}
          >
            <Container
              style={styles.header}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Container position="absolute" marginLeft={-30} top={0}>
                <IconButton
                  iconKey="cross"
                  onPress={onClose}
                  tintColor={theme.color.greyFillIcon}
                />
              </Container>
              <Text variant="titleMBold" value={getTranslationText(titleKey)} />
            </Container>
            <View onStartShouldSetResponder={() => true}>
              <Container marginBottom={0} marginTop={30} flexDirection="column">
                <HTML
                  html={parseMarkdown(getTranslationText(contentKey))}
                  tagsStyles={htmlStyles}
                />
              </Container>
            </View>
            <Container flexDirection="column" justifyContent="flex-end" flexGrow={1}>
              <Text
                variant="titleXSMedium"
                value={getTranslationText('it.overlays.cu-document-result.footer.text')}
              />
              <TextLink
                variant="titleXSMedium"
                color="brand.brandGreen"
                text={getTranslationText('it.overlays.cu-document-result.footer.link')}
                onPress={handleOnPressSupport}
              />
            </Container>
          </ScrollView>
        </>
      )}
    </Overlay>
  );
};
