import { useState, useEffect } from 'react';
import { AppState } from 'react-native';
import { noop } from 'lodash';

type AppStateStatus = typeof AppState.currentState;
type Settings = {
  onChange?: (status: AppStateStatus) => void;
  onForeground?: () => void;
  onBackground?: () => void;
};
export default function useAppState(settings: Settings): { appState: AppStateStatus } {
  const { onChange = noop, onForeground = noop, onBackground = noop } = settings || {};
  const [appState, setAppState] = useState<AppStateStatus>(AppState.currentState || '');
  useEffect(() => {
    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        onForeground();
      } else if (appState === 'active' && nextAppState.match(/inactive|background/)) {
        onBackground();
      }

      setAppState(nextAppState);
      onChange(nextAppState);
    };

    const appStateListener = AppState.addEventListener('change', handleAppStateChange);

    return () => appStateListener?.remove();
  }, [onForeground, onBackground, onChange, appState]);
  return {
    appState,
  };
}
