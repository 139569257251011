import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { Text } from '../components/core';

import formatNumber, { FormatNumberParams } from './formatNumber';

type FlowFriendlyComponentProps = FormatNumberParams & {
  Component?: React.ComponentType<any>;
  currencyDisplay?: any;
  unit?: any;
};
type WrappedProps = FlowFriendlyComponentProps & {
  intl: IntlShape;
};

const WrappedComponent = function IntlText(props: WrappedProps) {
  const { intl, Component = Text, ...params } = props;
  return <Component {...props}>{formatNumber(intl, params)}</Component>;
};

const FlowFriendlyComponent = injectIntl(WrappedComponent);
export default (props: FlowFriendlyComponentProps) => (
  // @ts-ignore
  <FlowFriendlyComponent formatStyle={props.formatStyle} value={props.value} {...props} />
);
