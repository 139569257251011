import { $Keys } from 'utility-types';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View as AnimatableView } from 'react-native-animatable';
import { Id } from '@taxfix/quizmaster/dist/types';

import { LinkText, theme, SmallTitleText, Box } from 'src/components/core';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    alignItems: 'center',
    paddingVertical: 10,
    minHeight: 50,
  },
  containerError: {
    backgroundColor: theme.color.errorBackground,
  },
  containerWarning: {
    backgroundColor: theme.color.warningBackground,
  },
  text: {
    flex: 0.9,
  },
  textError: {
    color: theme.color.lightText,
  },
  textWarning: {
    color: theme.color.lightText,
  },
  link: {
    zIndex: 1,
  },
});

enum Type {
  Error = 'Error',
  Warning = 'Warning',
}

type Props = {
  translationKey: Id;
  type: $Keys<typeof Type>;
  values?: Record<string, unknown>;
  testId?: string;
  onPressInfo?: (arg0: string) => void;
};

const InputValidationBar = ({ onPressInfo, translationKey, type, values, testId }: Props) => {
  const containerStyling = [
    styles.container,
    type === Type.Warning ? styles.containerWarning : styles.containerError,
  ];
  const textStyling = [styles.text, type === Type.Warning ? styles.textWarning : styles.textError];
  const linkTextColor = type !== Type.Warning ? theme.color.errorTextDark : theme.color.lightText;
  const linkStyle = [
    styles.link,
    {
      color: linkTextColor,
    },
  ];
  return (
    <AnimatableView duration={100} style={containerStyling}>
      <SmallTitleText
        id={translationKey}
        style={textStyling}
        values={values}
        ellipsizeMode="tail"
        testId={testId}
      />
      {onPressInfo && (
        <Box alignVertically="center" alignHorizontally="center" direction="row" flex={0.1}>
          <LinkText
            id="more.info"
            color={linkTextColor}
            onPress={onPressInfo as () => void}
            style={linkStyle}
          />
        </Box>
      )}
    </AnimatableView>
  );
};

InputValidationBar.Type = Type;
export default InputValidationBar;
