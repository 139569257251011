import { $Values } from 'utility-types';
import { Id } from '@taxfix/quizmaster/dist/types';

export type Validation = {
  limit?: number;
  chars?: string;
  translationKey: Id;
};

export type DateValidation = {
  limit?: string;
  translationKey: Id;
};

export const ErrorKeywords = Object.freeze({
  MAX_LENGTH: 'maxLength',
  MIN_LENGTH: 'minLength',
  REQUIRED: 'required',
  TYPE: 'type',
  ADDITIONAL_PROPERTIES: 'additionalProperties',
  PATTERN: 'pattern',
  MAXIMUM: 'maximum',
  MINIMUM: 'minimum',
  DIGITS_ONLY: 'digitsOnly',
  NOT_VALID_CHARS: 'notValidChars',
});

export type ErrorKeyword = $Values<typeof ErrorKeywords>;

export type Error = Record<string, any>;

export type ValidationErrors = Record<string, Error>;

export type ValidationResult = {
  isValid: boolean;
  errors: Error[] | ValidationErrors;
};
