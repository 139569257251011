import CodiceFiscale from 'codice-fiscale-js';
import { Cache, Responses, TreeNode } from '@taxfix/quizmaster/dist/types';
import { unindexed, indexFromId, appendIndex } from '@taxfix/quizmaster/dist/reference';

import {
  Answer,
  extractAnswers as quizMasterAnswersExtractor,
} from 'src/services/quiz-master-store';

import { childLoopQuestionIds, childLoopTaxId, childLoopCountId } from '../common/constants-it';

type ExtractedAnswers = {
  sortedAnswers: Answer[];
  answers: Answer[];
};
type SortingTaxID = {
  taxId: string;
  oldIndex?: number | null;
};

// helper function to get birthdate timestamp from a taxId
const getBirthDateTimestampFromTaxId = (taxId: any) => {
  const taxIdData: any = CodiceFiscale.computeInverse(taxId);
  return new Date(taxIdData.birthday).getTime();
};

// Reorder child loop based on birth date extracted from taxid
export const orderChildLoopsByBirthDate = (answers: Answer[]): Answer[] => {
  try {
    // if theres no child loop, return unmodified answers
    const childLoopQuestion = answers.find(({ questionID }) => questionID === childLoopCountId);
    const answerChildLoopCount = childLoopQuestion && childLoopQuestion.outputs[0].value;

    if (!answerChildLoopCount) {
      return answers;
    }

    // extract all child loop answers
    const childLoopAnswersUnordered = answers.filter(({ questionID }) =>
      childLoopQuestionIds.includes(unindexed(questionID)),
    );
    // Unsorted child tax IDs with current indexes
    const unsortedChildTaxIDs: SortingTaxID[] = childLoopAnswersUnordered
      .filter(({ questionID }) => unindexed(questionID) === childLoopTaxId)
      .map(({ questionID, outputs }) => {
        const [oldIndex] = indexFromId(questionID) || [];
        return {
          taxId: outputs[0].value,
          oldIndex,
        };
      });
    // Sorted child tax IDs with current indexes
    const sortedChildTaxIDs: SortingTaxID[] = [...unsortedChildTaxIDs].sort((a, b) => {
      const taxIdA = a.taxId;
      const taxIdB = b.taxId;
      const aBirthDateTS = getBirthDateTimestampFromTaxId(taxIdA);
      const bBirthDateTS = getBirthDateTimestampFromTaxId(taxIdB);

      if (aBirthDateTS === bBirthDateTS) {
        return 0;
      }

      if (aBirthDateTS < bBirthDateTS) {
        return -1;
      }

      return 1;
    });
    // Change indexes
    const reIndexedAnswers = answers.map((answer) => {
      const { questionID } = answer;

      if (childLoopQuestionIds.includes(unindexed(questionID))) {
        const newIndex = sortedChildTaxIDs.findIndex(({ oldIndex }) => {
          const [newIndex] = indexFromId(questionID) || [];
          return oldIndex === newIndex;
        });
        const newQuestionID = appendIndex(unindexed(questionID), newIndex);
        return { ...answer, questionID: newQuestionID };
      }

      return { ...answer };
    });
    return reIndexedAnswers;
  } catch (e) {
    // return original answers in case of invalid tax id
    return answers;
  }
};

const postProcessAnswers = (answers: Answer[]): Answer[] => {
  return orderChildLoopsByBirthDate(answers);
};

export const extractAnswers = (
  list: TreeNode[],
  responses: Responses,
  cache: Cache,
  year: number,
): ExtractedAnswers => {
  const { answers } = quizMasterAnswersExtractor(list, responses, cache, year);
  return {
    sortedAnswers: postProcessAnswers(answers),
    answers,
  };
};
