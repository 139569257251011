import { provinceList } from 'src/common/constants-it';
import { validateITAddress } from 'src/utils/answerType';

import { ValidationResult } from './types';

export const validateState = (state: string | null | undefined) => {
  return state && provinceList.some((province) => province.code === state);
};

export const addressValidatorIT = (input: any): ValidationResult => {
  const newInput = { ...input };
  const { state } = newInput;

  if (!validateState(state)) {
    newInput.state = null;
  }

  return validateITAddress(newInput);
};
