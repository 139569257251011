import {
  NavigationLink,
  StatusMiddleware,
  StatusStepNavigationData,
} from 'src/stores/modules/status';
import { questionFlowCategories, wayQuestionIds } from 'src/common/constants-it';
import { getNavigationActions, getBoundNavigationsActions } from 'src/routes/config-util';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { navigation } from 'src/services/navigation-service';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from 'src/stores/modules/settings';

export const checkQFProgress: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const questionStore = getQuestionStoreByYearAndCountry(getState());
    const livingSituationCategory = questionStore.getCategory(
      questionFlowCategories.livingSituation,
    );
    const residenceAddressCategory = questionStore.getCategory(
      questionFlowCategories.residenceAddress,
    );
    const propertyCategory = questionStore.getCategory(questionFlowCategories.property);
    const depententsCategory = questionStore.getCategory(questionFlowCategories.dependants);

    if (
      !depententsCategory ||
      !livingSituationCategory ||
      !residenceAddressCategory ||
      !propertyCategory
    ) {
      throw new Error('question flow category missing');
    }

    const isQFCompleted =
      livingSituationCategory.isComplete &&
      residenceAddressCategory.isComplete &&
      propertyCategory.isComplete &&
      depententsCategory.isComplete;

    if (isQFCompleted) {
      next();
    } else {
      const navigateBackToStatusScreen = () => {
        const userName = questionStore.responsesJS?.[wayQuestionIds.firstName]?.answer;
        dispatch(
          overlayActions.show('SuccessOverlay', {
            icon: 'interface-icons-svg.icon-checkmark-white',
            titleId: 'it.success-overlay.mandatory-questions.title',
            descriptionId: 'it.success-overlay.mandatory-questions.description',
            buttonId: 'it.success-overlay.mandatory-questions.button',
            exitDirection: 'top',
            translationValues: {
              userName,
            },
            onNext: () => {
              getBoundNavigationsActions().reset({
                index: 0,
                actions: [getNavigationActions().toStatus('screen')],
              });
              overlayActions.hide();
            },
          }),
        );
      };

      // if we don't use the `push` method to navigate to the next screen with the same `name`
      // the screen is not added to the stack and the back button will not work properly
      // https://reactnavigation.org/docs/navigating#navigate-to-a-route-multiple-times
      const quizMasterParams = {
        root: questionFlowCategories.livingSituation,
        editing: false,
        onNext: () => {
          navigation.push('screen/QuizMaster', {
            root: questionFlowCategories.residenceAddress,
            onNext: () => {
              navigation.push('screen/QuizMaster', {
                root: questionFlowCategories.property,
                onNext: () => {
                  navigation.push('screen/QuizMaster', {
                    root: questionFlowCategories.dependants,
                    onNext: navigateBackToStatusScreen,
                  });
                },
              });
            },
          });
        },
      };

      const navigationLink: NavigationLink = {
        action: 'toQuizMaster',
        config: 'screen',
        params: quizMasterParams,
      };

      const customNavigation = {
        title: 'status.it.navigation.default',
        link: {
          ...navigationLink,
        },
      };

      const isQFEmpty =
        livingSituationCategory.progress === 0 &&
        residenceAddressCategory.progress === 0 &&
        propertyCategory.progress === 0 &&
        depententsCategory.progress === 0;

      const customSection = {
        title: {
          id: 'status.it.mandatory-questions.title',
        },
        icon: 'PersonalInfo',
        inProgress: !isQFCompleted && !isQFEmpty,
        navigation: {
          id: 'status.section.completed.edit-button',
          link: {
            action: 'toQuizMaster',
            config: 'screen',
            params: quizMasterParams,
          },
        },
      };

      dispatch(
        breakAction(customNavigation as StatusStepNavigationData, {
          ...customSection,
          navigation: {
            id: 'status.it.navigation.default',
            link: { ...navigationLink },
          },
        }),
      );
    }
  } catch (e) {
    dispatch(failureAction(`error status mandatory-questions: ${(e as Error).message}`));
  }
};

export const checkQFProgressPaymentBeforeQF: StatusMiddleware = async (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  try {
    const questionStore = getQuestionStoreByYearAndCountry(getState());
    const isQFVisited = settingsSelectors.selectIsQFVisited(getState());

    const livingSituationCategory = questionStore.getCategory(
      questionFlowCategories.livingSituation,
    );
    const residenceAddressCategory = questionStore.getCategory(
      questionFlowCategories.residenceAddress,
    );
    const propertyCategory = questionStore.getCategory(questionFlowCategories.property);
    const depententsCategory = questionStore.getCategory(questionFlowCategories.dependants);

    if (
      !depententsCategory ||
      !livingSituationCategory ||
      !residenceAddressCategory ||
      !propertyCategory
    ) {
      throw new Error('question flow category missing');
    }

    const isQFCompleted =
      livingSituationCategory.isComplete &&
      residenceAddressCategory.isComplete &&
      propertyCategory.isComplete &&
      depententsCategory.isComplete;

    if (isQFCompleted) {
      next();
    } else if (!isQFVisited) {
      dispatch(settingsActions.setIsQFVisited(true));
      const actions = [
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toStatus('screen'),
        getNavigationActions().toQuizMaster('screen', {
          root: questionFlowCategories.livingSituation,
          editing: false,
          backNavigationText: 'web.navigation.italy.back',
          onNext: () => {
            getBoundNavigationsActions().toQuizMaster('screen', {
              root: questionFlowCategories.residenceAddress,
              editing: false,
              onNext: () => {
                getBoundNavigationsActions().toQuizMaster('screen', {
                  root: questionFlowCategories.property,
                  editing: false,
                  onNext: () => {
                    getBoundNavigationsActions().toQuizMaster('screen', {
                      root: questionFlowCategories.dependants,
                      editing: false,
                      onNext: () => {
                        getBoundNavigationsActions().reset({
                          index: 0,
                          actions: [getNavigationActions().toStatus('screen')],
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        }),
      ];
      navigation.reset({
        index: 2,
        actions,
      });
    } else {
      const navigateBackToStatusScreen = () => {
        const userName = questionStore.responsesJS?.[wayQuestionIds.firstName]?.answer;
        dispatch(
          overlayActions.show('SuccessOverlay', {
            icon: 'interface-icons-svg.icon-checkmark-white',
            titleId: 'it.success-overlay.mandatory-questions.title',
            descriptionId: 'it.success-overlay.mandatory-questions.description',
            buttonId: 'it.success-overlay.mandatory-questions.button',
            exitDirection: 'top',
            translationValues: {
              userName,
            },
            onNext: () => {
              getBoundNavigationsActions().reset({
                index: 0,
                actions: [getNavigationActions().toStatus('screen')],
              });
              overlayActions.hide();
            },
          }),
        );
      };

      // if we don't use the `push` method to navigate to the next screen with the same `name`
      // the screen is not added to the stack and the back button will not work properly
      // https://reactnavigation.org/docs/navigating#navigate-to-a-route-multiple-times
      const quizMasterParams = {
        root: questionFlowCategories.livingSituation,
        editing: false,
        onNext: () => {
          navigation.push('screen/QuizMaster', {
            root: questionFlowCategories.residenceAddress,
            onNext: () => {
              navigation.push('screen/QuizMaster', {
                root: questionFlowCategories.property,
                onNext: () => {
                  navigation.push('screen/QuizMaster', {
                    root: questionFlowCategories.dependants,
                    onNext: navigateBackToStatusScreen,
                  });
                },
              });
            },
          });
        },
      };

      const navigationLink: NavigationLink = {
        action: 'toQuizMaster',
        config: 'screen',
        params: quizMasterParams,
      };

      const customNavigation = {
        title: 'status.it.navigation.default',
        link: {
          ...navigationLink,
        },
      };

      const isQFEmpty =
        livingSituationCategory.progress === 0 &&
        residenceAddressCategory.progress === 0 &&
        propertyCategory.progress === 0 &&
        depententsCategory.progress === 0;

      const customSection = {
        title: {
          id: 'status.it.mandatory-questions.title',
        },
        icon: 'PersonalInfo',
        inProgress: !isQFCompleted && !isQFEmpty,
        navigation: {
          id: 'status.section.completed.edit-button',
          link: {
            action: 'toQuizMaster',
            config: 'screen',
            params: quizMasterParams,
          },
        },
      };

      dispatch(
        breakAction(customNavigation as StatusStepNavigationData, {
          ...customSection,
          navigation: {
            id: 'status.it.navigation.default',
            link: { ...navigationLink },
          },
        }),
      );
    }
  } catch (e) {
    dispatch(failureAction(`error status mandatory-questions: ${(e as Error).message}`));
  }
};
