import * as React from 'react';

import { SupportButton } from 'src/taxfix-components/src/components/general/SupportButton';
import { icons } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks';

type Props = {
  mainButtonOnPress: () => void;
};

const ActionButton = ({ mainButtonOnPress }: Props): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  return (
    <SupportButton
      onPress={mainButtonOnPress}
      iconPath={icons.closeSupport}
      buttonText={getTranslationText('it.support.button.close.text')}
    />
  );
};

export { ActionButton };
