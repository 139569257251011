import { useBreakpointValue } from 'native-base';
import * as React from 'react';
import { ScrollView } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import {
  Container,
  Footer,
  NavButton,
  Stepper,
  StepperProps,
  Text,
  Toast,
} from 'src/taxfix-components/src';
import { ToastBannerLevel } from 'src/taxfix-components/src/components/general/Toast/types';

type SpidTimeoutNextStepsProps = {
  getTranslationText: (id: string, values?: any) => string;
  onBackButtonPress: () => void;
  onPressPrimaryButton: () => void;
  steps: StepperProps['steps'];
};

export const SpidTimeoutNextStepsScreen: React.FC<SpidTimeoutNextStepsProps> = ({
  getTranslationText,
  onBackButtonPress,
  onPressPrimaryButton,
  steps,
}) => {
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
  });
  const pageContainerWidth = useBreakpointValue({ md: '580px' });
  const pageTitleMarginLeft = useBreakpointValue({ md: '0px' });
  const footerCtaStyles = useBreakpointValue({
    base: { width: 'full' },
    md: { width: 'auto', px: '20px' },
  });

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <NavButton
          onPress={onBackButtonPress}
          text={getTranslationText('it.spid-timeout-next-steps-screen.back-button.text')}
        />
      )}

      <ScrollView>
        <Container alignSelf="center" flex={1} width={pageContainerWidth} mt="24px">
          <Container mx={['24px', '0px']}>
            <Text
              mx={pageTitleMarginLeft}
              variant={titleVariant}
              value={getTranslationText('it.spid-timeout-next-steps-screen.title')}
            />
            <Toast
              alignItems={{ base: 'flex-start', md: 'center' }}
              text={getTranslationText('it.spid-timeout-next-steps-screen.banner')}
              iconName="faPercent"
              level={ToastBannerLevel.Info}
              mb="24px"
            />
          </Container>
          <Container p="24px" pb="4px" backgroundColor="greytones.background">
            <Stepper steps={steps} direction="vertical" borderStyle="solid" showConnector />
          </Container>
        </Container>
      </ScrollView>
      <Footer
        primaryButtonTitle={getTranslationText('it.spid-timeout-next-steps-screen.cta.text')}
        primaryButtonProps={footerCtaStyles}
        onPressPrimary={onPressPrimaryButton}
        mt="auto"
        px="24px"
        backgroundColor="white"
        borderTopWidth={0}
      />
    </>
  );
};
