/* global window document */
import { PlacesApiResponse, PlacesApiPrediction } from '../../google-maps-places';
import {
  GeocodeApiResponse,
  GeocodeApiResultWeb,
  GeocodeApiResult,
} from '../../google-maps-geocode';

import {
  PlaceAutocompleteParams,
  PlaceAutocompleteRequest,
  GeocodeParams,
  GeocodeRequest,
  Directions,
  ComponentRestrictions,
} from './types';

type Config = {
  baseUrl?: string;
  apiKey: string;
};
type AutocompleteService = {
  getPlacePredictions: (
    request: PlaceAutocompleteRequest,
    callback: (predictions: PlacesApiPrediction[], status: string) => void,
  ) => Promise<PlacesApiResponse>;
};
type Geocoder = {
  geocode: (
    request: GeocodeRequest,
    callback: (results: GeocodeApiResultWeb[], status: string) => void,
  ) => Promise<GeocodeApiResponse>;
};
type GoogleApi = {
  maps: {
    places: {
      AutocompleteService: (options: Record<string, any>) => any;
    };
    Geocoder: () => Geocoder;
  };
};

const convertComponentRestrictions = (
  components?: string,
): ComponentRestrictions | null | undefined => {
  return components
    ? components.split('|').reduce((acc: any, restriction) => {
        const [component, value] = restriction.split(':');

        if (!acc[component]) {
          acc[component] = value;
        } else {
          if (!Array.isArray(acc[component])) {
            acc[component] = [acc[component]];
          }

          acc[component].push(value);
        }

        return acc;
      }, {})
    : undefined;
};

const createPlaceAutocompleteRequest = (
  params: PlaceAutocompleteParams,
): PlaceAutocompleteRequest => {
  const { input, language, types, components } = params;
  return {
    input,
    language,
    types: types ? [types] : undefined,
    componentRestrictions: convertComponentRestrictions(components),
  };
};

const createGeocodeRequest = (params: GeocodeParams): GeocodeRequest => {
  const { address, components } = params;
  return {
    address,
    componentRestrictions: convertComponentRestrictions(components),
  };
};

class GoogleMaps {
  static apiKey: string;

  static baseUrl: string;

  static googleApiPromise: Promise<GoogleApi>;

  static autocompleteService: AutocompleteService;

  static geocoder: Geocoder;

  static initialize(config: Config) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.apiKey}&libraries=places`;
    script.async = true;
    GoogleMaps.googleApiPromise = new Promise((resolve, reject) => {
      script.onload = () => resolve((window as any).google);

      script.onerror = reject;
    });

    if (document.body) {
      document.body.appendChild(script);
    }
  }

  static getGoogleApi() {
    return GoogleMaps.googleApiPromise;
  }

  static getAutocompleteService = async () => {
    if (!GoogleMaps.autocompleteService) {
      const googleApi = await GoogleMaps.getGoogleApi();
      // @ts-ignore
      GoogleMaps.autocompleteService = new googleApi.maps.places.AutocompleteService({
        options: ['geocode'],
      });
    }

    return GoogleMaps.autocompleteService;
  };

  static getGeocoder = async () => {
    if (!GoogleMaps.geocoder) {
      const googleApi = await GoogleMaps.getGoogleApi();
      // @ts-ignore
      GoogleMaps.geocoder = new googleApi.maps.Geocoder();
    }

    return GoogleMaps.geocoder;
  };

  static placeAutocomplete = async (
    params: PlaceAutocompleteParams,
  ): Promise<PlacesApiResponse> => {
    const autocompleteService = await GoogleMaps.getAutocompleteService();
    return new Promise((resolve) => {
      autocompleteService.getPlacePredictions(
        createPlaceAutocompleteRequest(params),
        (predictions: any, status: string) => {
          resolve({
            predictions,
            status,
          });
        },
      );
    });
  };

  static geocode = async (params: GeocodeParams): Promise<GeocodeApiResponse> => {
    const geocoder = await GoogleMaps.getGeocoder();
    return new Promise((resolve) => {
      geocoder.geocode(
        createGeocodeRequest(params),
        (webResults: GeocodeApiResultWeb[], status: string) => {
          const results: GeocodeApiResult[] = webResults.map((webResult) => {
            // eslint-disable-next-line camelcase
            const { address_components, formatted_address, types, geometry } = webResult;
            return {
              address_components,
              formatted_address,
              types,
              geometry: {
                location: {
                  lat: geometry.location.lat(),
                  lng: geometry.location.lng(),
                },
              },
            };
          });
          resolve({
            results,
            status,
          });
        },
      );
    });
  };

  // eslint-disable-next-line no-unused-vars
  static routes = async (params: Directions) => {
    throw new Error('not implemented');
  };

  static resposeStatus = {
    OK: 'OK',
    NETWORK_ERROR: 'NETWORK_ERROR',
    ZERO_RESULTS: 'ZERO_RESULTS',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
    REQUEST_DENIED: 'REQUEST_DENIED',
    INVALID_REQUEST: 'INVALID_REQUEST',
  };
}

export { GoogleMaps };
