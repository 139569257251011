import React, { useRef } from 'react';
import { CountryCodes } from '@taxfix/types';
import { ScrollView } from 'react-native';

import { NavButton } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { getStripePublishableKey } from 'src/services/payment';

import StripePaymentForm from './stripe/stripe-payment-form';
import { usePayment } from './hooks/use-payment';
import { PaymentContextProvider } from './payment.context';

type Props = {
  backButton?: {
    text: string;
    onPress: () => void;
  };
};

const PaymentContainer: React.FC<Props> = ({ backButton }) => {
  const { getTranslationText } = useItalyIntl();
  const containerRef = useRef<ScrollView>(null);
  const {
    handleOnSubmit,
    handleFinished,
    onApplyVoucher,
    onRemoveVoucher,
    productPrice,
    productOriginalPrice,
    validateForm,
    paypalError,
  } = usePayment();

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ y: 0, animated: true });
  };

  return (
    <>
      {backButton && (
        <NavButton text={getTranslationText(backButton.text)} onPress={backButton.onPress} />
      )}
      <PaymentContextProvider
        productOriginalPrice={productOriginalPrice}
        productPrice={productPrice}
        paypalError={paypalError}
      >
        <ScrollView ref={containerRef}>
          <StripePaymentForm
            stripePublishableKey={getStripePublishableKey(CountryCodes.IT)}
            onSubmit={handleOnSubmit}
            onFinish={handleFinished}
            onApplyVoucher={onApplyVoucher}
            onRemoveVoucher={onRemoveVoucher}
            productOriginalPrice={productOriginalPrice}
            productPrice={productPrice}
            validateForm={validateForm}
            scrollToTop={scrollToTop}
          />
        </ScrollView>
      </PaymentContextProvider>
    </>
  );
};

export { PaymentContainer };
