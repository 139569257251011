import React from 'react';

import { Notifications as NOTIFICATIONS } from '../../utils/constants';
import Layout from '../../components/onboarding-legacy/Layout';
import Page from '../../components/onboarding-legacy/Page';

type Props = {
  image?: string;
  floatingButton?: boolean;
  title?: string;
  content?: string;
  primaryButtonText?: string;
  onPressPrimaryButton: () => void;
  onPressSecondaryButton: () => void;
  children?: React.ReactNode;
};

const Notifications: React.FC<Props> = ({
  image,
  floatingButton,
  onPressPrimaryButton,
  onPressSecondaryButton,
  title,
  content,
  primaryButtonText,
}) => {
  return (
    <Layout
      primaryButtonText={primaryButtonText || NOTIFICATIONS.DEFAULT.primaryButtonText}
      secondaryButtonText="onboarding.notifications.secondary-button"
      onPressPrimaryButton={onPressPrimaryButton}
      onPressSecondaryButton={onPressSecondaryButton}
      floatingButton={floatingButton}
    >
      <Page
        imageProps={{
          name: image || NOTIFICATIONS.DEFAULT.image,
          height: 180,
          width: 180,
          marginBottom: 24,
        }}
        title={title || NOTIFICATIONS.DEFAULT.title}
        content={content || NOTIFICATIONS.DEFAULT.content}
      />
    </Layout>
  );
};

export default Notifications;
