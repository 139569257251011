import { isWeb } from '../../utils/platform';
import { mergeImages as mergeImagesWeb, Base64String } from '../../services/merge-images.web';
import { mergeImages as mergeImagesNative } from '../../services/merge-images';

import { NativeFileResponse, RefViewShotElement } from './types';

export const isNativeFile = (file: NativeFileResponse | File): file is NativeFileResponse => {
  return 'uri' in file || 'didCancel' in file || 'error' in file;
};

export const combineImages = async (
  filesToMerge: Base64String[],
  refElement: RefViewShotElement,
): Promise<Base64String | string | null> => {
  try {
    if (isWeb) {
      // for Mobile web and Desktop web
      return await mergeImagesWeb(filesToMerge);
    }
    // for iOS and Android
    return await mergeImagesNative(refElement, {
      format: 'jpg',
      quality: 1,
    });
  } catch (e) {
    return null; // swallow error
  }
};

export const isFileValid = (response: (NativeFileResponse | null)[]) => {
  const hasSomeError = response.some((it) => it?.error);
  const hasSomeDidCancel = response.some((it) => it === null || it?.didCancel);
  return {
    hasError: hasSomeError,
    isCanceled: hasSomeDidCancel,
  };
};
