import { isQuestion } from '@taxfix/quizmaster';
import findSubtree from '@taxfix/quizmaster/dist/findSubtree';
import inputResolver from '@taxfix/quizmaster/dist/calculator/inputResolver';
import { Cache, Id, Responses, TreeNode, Answer } from '@taxfix/quizmaster/dist/types';

// eslint-disable-next-line import/no-cycle
import QuestionStore from '../stores-legacy/QuestionStore';
import translationValuesWithDefaults, {
  KeysAndValues,
} from '../utils/translationValuesWithDefaults';

const isSilent = (node: TreeNode) => node.response == null && node.children == null;

export type SummaryItem = {
  id: Id;
  translationValues: KeysAndValues;
  answer?: Answer;
  question: TreeNode;
};
export type SummaryGroup = {
  id: string;
  node: TreeNode | null | undefined;
  type: string;
  items?: SummaryItem[];
  translationValues: KeysAndValues;
};

const getInputs = (
  questions: Record<Id, TreeNode>,
  qnId: string,
  responses: Responses,
  cache: Cache,
  year: number,
  question?: TreeNode | null,
) => {
  const out: Record<string, any> = {};
  const map = question ? question.inputs : null;

  if (!map) {
    return out;
  }

  for (const key in map) {
    const input = map[key];

    if (input != null) {
      out[key] = inputResolver(input, responses, map, questions, qnId, cache, year);
    }
  }

  return out;
};

class Summaries {
  static createTranslationValues(
    questions: Record<Id, TreeNode>,
    qnId: string,
    responses: Responses,
    cache: Cache,
    year: number,
  ): KeysAndValues {
    const question: TreeNode | null = questions[qnId];

    const inputs = getInputs(questions, qnId, responses, cache, year, question);

    return translationValuesWithDefaults(inputs, {
      question,
      year,
    });
  }

  static createItems(
    questions: Record<Id, TreeNode>,
    questionIds: Array<string>,
    responses: Responses,
    cache: Cache,
    year: number,
  ): SummaryItem[] {
    return questionIds.map((questionId: string) => {
      const question = questions[questionId];
      const translationValues = this.createTranslationValues(
        questions,
        questionId,
        responses,
        cache,
        year,
      );
      return {
        id: questionId,
        translationValues,
        answer: responses[questionId] ? responses[questionId].answer : undefined,
        question,
      };
    });
  }

  static createQuestionIdsArray(
    questions: Record<Id, TreeNode>,
    tree: {
      children?: TreeNode[];
    },
  ): Array<string> {
    const acc: string[] = [];
    const children = tree.children != null ? tree.children : [];
    children.forEach((node) => {
      if (isQuestion(node) === true) {
        acc.push(node.id);
      } else if (node.children) {
        const allAreSilent = node.children.every((child) => isSilent(child));

        if (!allAreSilent) {
          acc.push(node.id, ...this.createQuestionIdsArray(questions, node));
        }
      }
    });
    return acc;
  }

  static createGroupFromNode(tree: TreeNode): SummaryGroup {
    const translationValues: any = {};
    const child = tree.children && tree.children[0];

    if (child && child.loopContext != null) {
      translationValues.count = child.loopContext.indexes[child.loopContext.loopId] + 1;
      translationValues.count1 = translationValues.count + 1;
    }

    return {
      id: `summary:default:${tree.id}`,
      type: 'default_summary',
      node: tree,
      translationValues,
    };
  }

  static createGroups(
    questionStore: QuestionStore,
    root: string,
    isQuestionPrefilled: (item: SummaryItem) => boolean = () => false,
  ): SummaryGroup[] {
    const { tree, refs = {}, activeResponseJS, cache, year } = questionStore;
    const subTree = findSubtree(tree, root);
    const childrenOfRoot = subTree?.children || [];
    return childrenOfRoot.reduce((groups, node) => {
      let loopCount = 1;

      if (node.loop && node.loop.count) {
        loopCount = node.loop.count;
      }

      // Here we create one group for each loop index
      for (let index = 0; index < loopCount; index += 1) {
        let nodeChildren = node.children || [];
        nodeChildren = nodeChildren.filter(
          (child) =>
            child.loopContext == null ||
            child.loopContext.indexes[child.loopContext.loopId] === index,
        );
        const filteredNode = { ...node, children: nodeChildren };
        const group = this.createGroupFromNode(filteredNode);

        if (loopCount > 1) {
          group.id = `${group.id}[${index}]`;
        }

        const questionIds = this.createQuestionIdsArray(refs, filteredNode);
        group.items = this.createItems(refs, questionIds, activeResponseJS || {}, cache, year).map(
          (item) => {
            if (item == null) return item;
            const questionIsPrefilled = isQuestionPrefilled(item);
            const question = { ...(item.question || {}), questionIsPrefilled };
            return { ...item, question };
          },
        );
        groups.push(group);
      }

      return groups;
    }, [] as SummaryGroup[]);
  }
}

export default Summaries;
