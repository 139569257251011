/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dispatch } from 'redux';
import * as SubmissionSDK from '@taxfix/submissions-sdk';
import { taxDeclarationUtils } from '@taxfix/submission/dist/browser';
import { MessageDescriptor } from 'react-intl';
import { createSelector } from 'reselect';
import { createFilter } from 'redux-persist-transform-filter';
import _ from 'lodash';
import { TaxAssessments, Documents, CountryCodes } from '@taxfix/types';
import { MappedPayment } from '@taxfix/payment-sdk/dist/payment';
import {
  SubmissionWithId,
  SubmissionWithIdAndIdentificationState,
  TaxOfficeDetails,
  States as SentSubmissionStates,
  SubmissionReviewStatusIT,
} from '@taxfix/submissions-types';
import { ConsentRequest } from '@taxfix/submissions-sdk/dist/consent/consent';
import { TokenOrAuthContext } from '@taxfix/submissions-sdk/dist/types';

import { useQuizmasterLight } from 'src/_italy/_hooks';
import { flagsQuestionIds } from 'src/common/constants-it';

import { PaymentStatusByYear, PaymentStates } from '../../types/payment';
import {
  DraftAndSentSubmissionState,
  Options,
  CreateRequestDefaultParams,
  ExtraPaymentParams,
  getAddressMatch,
  checkIsResubmission,
  fetchPaymentsStatus,
  DraftSubmissionStates,
  getPaymentFromPaymentId,
  getAssessmentForSubmission,
  getPaymentIdFromSubmissions,
  getAllSubmissionsForUserAndYear,
  resolveDraftSubmissionStatusForCountry,
  createSubmission as createSubmissionService,
  getPaymentListForCountry,
  DraftAndSentSubmissionStates,
  CreateSubmissionRequestWithUserId,
} from '../../services/submissions';
import type { State as RootState } from '../store/initial';

import { selectors as settingsSelectors } from './settings';
import { selectors as userAuthSelectors } from './user-auth';

export type State = {
  readonly isLoading: boolean;
  readonly isLoadingTaxAssessment: boolean;
  readonly isLoadingSubmissionTaxOffice: boolean;
  readonly isLoadingPayment: boolean;
  readonly status: DraftAndSentSubmissionState;
  readonly error?: MessageDescriptor;
  readonly submissions: SubmissionWithId[];
  readonly hasResubmissionDraft: boolean;
  readonly payment: MappedPayment | null | undefined;
  readonly paymentStatus?: PaymentStatusByYear;
  readonly taxOfficeDetails?: Record<number, TaxOfficeDetails>;
  readonly taxAssessments?: Record<number, TaxAssessments.TaxAssessmentData>;
  readonly statusReview: UpdateStatusReview;
};
export const CREATE_REQUEST = 'submission/CREATE_REQUEST';
export const CREATE_SUCCESS = 'submission/CREATE_SUCCESS';
export const CREATE_ERROR = 'submission/CREATE_ERROR';
export const UPDATE_SUBMISSION_STATUS = 'submission/UPDATE_SUBMISSION_STATUS';
export const SET_HAS_RESUBMISSION_DRAFT = 'submission/SET_HAS_RESUBMISSION_DRAFT';
export const UPDATE_PAYMENT_STATUS = 'submission/UPDATE_PAYMENT_STATUS';
export const GET_SUBMISSIONS = 'submission/GET_SUBMISSIONS';
export const GET_SUBMISSIONS_SUCCESS = 'submission/GET_SUBMISSIONS_SUCCESS';
export const DISMISS_ERROR = 'submission/DISMISS_ERROR';
export const UPDATE_PAYMENT_STATUS_BY_YEAR = 'submission/UPDATE_PAYMENT_STATUS_BY_YEAR';
export const GET_TAX_OFFICE_BY_SUBMISSION = 'submission/GET_TAX_OFFICE_BY_SUBMISSION';
export const UPDATE_TAX_OFFICE_BY_SUBMISSION = 'submission/UPDATE_TAX_OFFICE_BY_SUBMISSION';
export const GET_ASSESSMENT = 'submission/GET_ASSESSMENT';
export const GET_ASSESSMENT_SUCCESS = 'submission/GET_ASSESSMENT_SUCCESS';
export const UPDATE_SUBMISSION_REVIEW_STATUS = 'submission/UPDATE_SUBMISSION_REVIEW_STATUS';

type CreateErrorAction = {
  type: typeof CREATE_ERROR;
  payload: {
    error: MessageDescriptor;
  };
};
type CreateRequestAction = {
  type: typeof CREATE_REQUEST;
};
type CreateSuccessAction = {
  type: typeof CREATE_SUCCESS;
};
type UpdateLatestSubmissionStatusAction = {
  type: typeof UPDATE_SUBMISSION_STATUS;
  payload: {
    status: DraftAndSentSubmissionState;
    submissions: SubmissionWithId[];
  };
};
type SetHasResubmissionDraftAction = {
  type: typeof SET_HAS_RESUBMISSION_DRAFT;
  payload: {
    hasResubmissionDraft: boolean;
  };
};
type UpdatePaymentStatusAction = {
  type: typeof UPDATE_PAYMENT_STATUS;
  payload: {
    payment: MappedPayment | null | undefined;
  };
};
type GetSubmissions = {
  type: typeof GET_SUBMISSIONS;
};
type GetSubmissionsSuccess = {
  type: typeof GET_SUBMISSIONS_SUCCESS;
  payload: {
    submissions: SubmissionWithId[];
  };
};
type DismissErrorAction = {
  type: typeof DISMISS_ERROR;
};
type UpdatePaymentStatusByYear = {
  type: typeof UPDATE_PAYMENT_STATUS_BY_YEAR;
  payload: {
    paymentStatus: PaymentStatusByYear;
  };
};
export type UpdateStatusReviewCreditorDebit =
  | SubmissionReviewStatusIT.MrCredito
  | SubmissionReviewStatusIT.MrDebito
  | undefined;

export type UpdateStatusReview = SubmissionReviewStatusIT | UpdateStatusReviewCreditorDebit;

type UpdateStatusReviewAction = {
  type: typeof UPDATE_SUBMISSION_REVIEW_STATUS;
  payload: {
    statusReview: UpdateStatusReview;
  };
};

type Action =
  | CreateErrorAction
  | CreateRequestAction
  | CreateSuccessAction
  | UpdateLatestSubmissionStatusAction
  | SetHasResubmissionDraftAction
  | UpdatePaymentStatusAction
  | GetSubmissionsSuccess
  | GetSubmissions
  | DismissErrorAction
  | UpdatePaymentStatusByYear
  | UpdateStatusReviewAction;

const createError = (error: { id: string }) => ({
  type: CREATE_ERROR,
  payload: {
    error,
  },
});

type DonationXMLContext = {
  firstName: string;
  lastName: string;
  taxId: string;
  sex: string;
  dateBirth: string;
  placeBirth: string;
  province: string;
  eight1000: string;
  eight1000_area?: string;
  five1000: string;
  five1000_code?: string;
  two1000: string;
  permissionText: string;
};
export type CreateDonationRequest = DonationXMLContext &
  Documents.Italy.IntesaUserData &
  CreateRequestDefaultParams;

const createDonation =
  (data: CreateDonationRequest, options: Options) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch({
      type: CREATE_REQUEST,
    });
    const { apiBaseUrl, accessToken } = options;

    try {
      await SubmissionSDK.donation(apiBaseUrl, accessToken, {
        ...data,
        // @ts-ignore
        year: settingsSelectors.selectedYear(getState()),
        countryCode: settingsSelectors.selectedCountry(getState()),
      });
      dispatch({
        type: CREATE_SUCCESS,
      });
    } catch (err) {
      dispatch(
        createError({
          id: 'donation.submit.error',
        }),
      );
      throw err;
    }
  };

export type CreateConsentRequest = Omit<ConsentRequest, 'year' | 'countryCode'>;

const createConsent =
  (data: CreateConsentRequest, options: Options) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch({
      type: CREATE_REQUEST,
    });
    const { apiBaseUrl, accessToken } = options;

    try {
      await SubmissionSDK.consent(apiBaseUrl, accessToken, {
        ...data,
        // @ts-ignore
        year: settingsSelectors.selectedYear(getState()),
        countryCode: settingsSelectors.selectedCountry(getState()),
      });
      dispatch({
        type: CREATE_SUCCESS,
      });
    } catch (err) {
      dispatch(
        createError({
          id: 'consent.submit.error',
        }),
      );
      throw err;
    }
  };

const createSubmission =
  (accessToken: string, data: CreateSubmissionRequestWithUserId) =>
  async (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
    const state = getState();
    const taxYear = settingsSelectors.selectedYear(state);

    if (!taxYear) {
      throw new Error('Cannot create submission without a selected year');
    }

    dispatch({
      type: CREATE_REQUEST,
    });

    try {
      await createSubmissionService(taxYear)(accessToken, data);
      dispatch({
        type: CREATE_SUCCESS,
      });
    } catch (err) {
      dispatch(
        createError({
          id: 'submit-te.submit.error',
        }),
      );
      throw err;
    }
  };

const updateLatestSubmissionStatus =
  (
    accessToken: string,
    userId: number,
    extraPaymentParams?: ExtraPaymentParams | null | undefined,
    additionalProjections?: string[],
  ) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    const stores = getState();
    const countryCode = settingsSelectors.selectedCountry(stores);
    const year = settingsSelectors.selectedYear(stores);

    if (!year) {
      throw new Error('cannot update status without current year');
    }

    await dispatch(fetchSubmissions(accessToken, userId, additionalProjections));
    const activeSubmission = getActiveSubmissionByYear(getState(), year);
    const status: any = activeSubmission
      ? activeSubmission.state
      : await resolveDraftSubmissionStatusForCountry(countryCode, year, accessToken, userId);

    if (SentSubmissionStates[status]) {
      // eslint-disable-next-line no-use-before-define
      dispatch(setHasResubmissionDraft(false));
    }

    await dispatch(updatePaymentStatus(accessToken, userId, extraPaymentParams));
    dispatch({
      type: UPDATE_SUBMISSION_STATUS,
      payload: {
        status,
      },
    });
    const isPaidSubmission = selectors.isPaidSubmission(getState());
    const ret = {
      status,
      isPaidSubmission,
    };
    return ret;
  };

const fetchSubmissions =
  (accessToken: string, userId: number | null | undefined, additionalProjections?: string[]) =>
  async (dispatch: Dispatch<any>, getState: () => any): Promise<any> => {
    dispatch({
      type: GET_SUBMISSIONS,
    });

    if (!userId) {
      throw new Error('cannot fetch submissions without userId');
    }

    const stores = getState();
    const countryCode = settingsSelectors.selectedCountry(stores);
    const submissions = await getAllSubmissionsForUserAndYear(
      accessToken,
      userId,
      undefined,
      countryCode,
      additionalProjections,
    );
    dispatch({
      type: GET_SUBMISSIONS_SUCCESS,
      payload: {
        submissions,
      },
    });
    return submissions;
  };

const fetchSubmissionsForSelectedYear =
  (accessToken: string, userId: number) => async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch({
      type: GET_SUBMISSIONS,
    });
    const state = getState();
    const countryCode = settingsSelectors.selectedCountry(state);
    const year = settingsSelectors.selectedYear(state);

    if (!year) {
      throw new Error('cannot fetch submissions without current year');
    }

    const submissions = await getAllSubmissionsForUserAndYear(
      accessToken,
      userId,
      year,
      countryCode,
    );
    dispatch({
      type: GET_SUBMISSIONS_SUCCESS,
      payload: {
        submissions,
      },
    });
    return submissions;
  };

const fetchSubmissionAssessment =
  (year: number) => async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch({
      type: GET_ASSESSMENT,
      payload: {
        isLoading: true,
      },
    });

    if (!year) {
      throw new Error('cannot fetch assessment without current year');
    }

    const state = getState();
    const submission: SubmissionWithId | null | undefined = getSubmittedSubmissionsByYear(
      state,
      year,
    );
    const accessToken = userAuthSelectors.getAccessToken(state);
    const assessment = submission
      ? await getAssessmentForSubmission(accessToken, submission.id)
      : null;
    dispatch({
      type: GET_ASSESSMENT_SUCCESS,
      payload: {
        taxAssessments: {
          ...state.taxAssessments,
          ...(submission && assessment
            ? {
                [submission.id]: assessment,
              }
            : {}),
        },
      },
    });
    dispatch({
      type: GET_ASSESSMENT,
      payload: {
        isLoading: false,
      },
    });
    return assessment;
  };

const updatePaymentStatus =
  (
    accessToken: string,
    userId: number,
    extraPaymentParams?: ExtraPaymentParams | null | undefined,
  ) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    const stores = getState();
    let payment;
    const year = settingsSelectors.selectedYear(stores);
    const quizmasterLight = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
    const selectedProductBundle = quizmasterLight[flagsQuestionIds.productBundleSelection].answer;

    if (!year) {
      throw new Error('cannot update payment status without current year');
    }

    const activeSubmission = getActiveSubmissionByYear(getState(), year);
    const defaultSubmission = {} as SubmissionWithId;
    const paymentIdFromSubmissions = getPaymentIdFromSubmissions([
      activeSubmission || defaultSubmission,
    ]);

    if (paymentIdFromSubmissions) {
      payment = await getPaymentFromPaymentId(accessToken, paymentIdFromSubmissions);
    } else {
      const countryCode = settingsSelectors.selectedCountry(stores);

      if (!year) {
        throw new Error('fetching payment lists requires year');
      }

      const paymentList = await getPaymentListForCountry({
        userId,
        accessToken,
        year,
        countryCode,
        productVariation: selectedProductBundle,
        ...extraPaymentParams,
      });
      const payments = paymentList.filter((p: any) =>
        [PaymentStates.Completed, PaymentStates.Processing].includes(p.state),
      );
      payment = payments && payments.length > 0 ? payments[0] : null;
    }

    dispatch({
      type: UPDATE_PAYMENT_STATUS,
      payload: {
        payment,
      },
    });
  };

const setHasResubmissionDraft = (hasResubmissionDraft: boolean) => ({
  type: SET_HAS_RESUBMISSION_DRAFT,
  payload: {
    hasResubmissionDraft,
  },
});

const dismissError = () => ({
  type: DISMISS_ERROR,
});

const fetchPaymentStatusForYears = (accessToken: string) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: CREATE_REQUEST,
  });
  const status = await fetchPaymentsStatus(accessToken);

  if (status && Array.isArray(status)) {
    try {
      const statusByYear = status.reduce(
        (acc, current) => ({
          ...acc,
          // @ts-ignore
          [parseInt(current.year, 10)]: {
            productId: current.productId,
            state: current.state,
          },
        }),
        {},
      );
      dispatch({
        type: UPDATE_PAYMENT_STATUS_BY_YEAR,
        payload: {
          paymentStatus: statusByYear,
        },
      });
      dispatch({
        type: CREATE_SUCCESS,
      });
    } catch (err) {
      dispatch(
        createError({
          id: 'payment.paymentError',
        }),
      );
    }
  }
};

type AddressMatchRequest = {
  accessToken: string;
  year: number;
};

const getAddressMatchByYear =
  ({ accessToken, year }: AddressMatchRequest) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: CREATE_REQUEST,
    });

    try {
      const result = await getAddressMatch(accessToken, CountryCodes.DE, year);
      dispatch({
        type: CREATE_SUCCESS,
      });
      return result;
    } catch (err) {
      dispatch(
        // @ts-ignore
        createError({
          id: 'error-screen.subtitle-1',
        }),
      );
    }

    return null;
  };

const getSubmissionReview =
  ({
    configUrl,
    accessToken,
    submissionId,
  }: {
    configUrl: string;
    accessToken: TokenOrAuthContext;
    submissionId: number;
  }) =>
  async (dispatch: Dispatch<any>) => {
    // Fetch SubmissionStatusReview
    const submissionReview = await SubmissionSDK.SubmissionIT.getStatus(
      configUrl,
      accessToken,
      submissionId,
    );
    // Save SubmissionStatusReview in store
    dispatch({
      type: UPDATE_SUBMISSION_REVIEW_STATUS,
      payload: {
        statusReview: submissionReview.status,
      },
    });
  };

const updateStatusReview = (statusReview: UpdateStatusReview) => ({
  type: UPDATE_SUBMISSION_REVIEW_STATUS,
  payload: { statusReview },
});

export const actions = {
  createDonation,
  createConsent,
  createSubmission,
  createError,
  updateLatestSubmissionStatus,
  setHasResubmissionDraft,
  updatePaymentStatus,
  fetchSubmissionsForSelectedYear,
  fetchSubmissions,
  dismissError,
  fetchPaymentStatusForYears,
  getAddressMatchByYear,
  fetchSubmissionAssessment,
  getSubmissionReview,
  updateStatusReview,
};
export const initial: State = {
  isLoading: false,
  isLoadingTaxAssessment: false,
  isLoadingSubmissionTaxOffice: false,
  isLoadingPayment: true,
  status: DraftSubmissionStates.Initial,
  error: undefined,
  hasResubmissionDraft: false,
  submissions: [],
  payment: null,
  paymentStatus: {},
  taxOfficeDetails: {},
  taxAssessments: {},
  statusReview: SubmissionReviewStatusIT.None,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case CREATE_REQUEST:
      return { ...state, isLoading: true, error: undefined };

    case CREATE_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };

    case UPDATE_SUBMISSION_STATUS: {
      return { ...state, status: action.payload.status };
    }

    case SET_HAS_RESUBMISSION_DRAFT: {
      return { ...state, hasResubmissionDraft: action.payload.hasResubmissionDraft };
    }

    case UPDATE_PAYMENT_STATUS: {
      return { ...state, payment: action.payload.payment, isLoadingPayment: false };
    }

    case DISMISS_ERROR:
      return { ...state, error: undefined };

    case GET_SUBMISSIONS:
      return { ...state, isLoading: true };

    case GET_SUBMISSIONS_SUCCESS:
      return { ...state, submissions: action.payload.submissions, isLoading: false };

    case UPDATE_PAYMENT_STATUS_BY_YEAR:
      return { ...state, paymentStatus: action.payload.paymentStatus };

    // @ts-ignore
    case GET_TAX_OFFICE_BY_SUBMISSION:
      // @ts-ignore
      return { ...state, isLoadingSubmissionTaxOffice: action.payload.isLoading };

    // @ts-ignore
    case UPDATE_TAX_OFFICE_BY_SUBMISSION:
      // @ts-ignore
      return { ...state, taxOfficeDetails: action.payload.taxOfficeDetails };

    // @ts-ignore
    case GET_ASSESSMENT:
      // @ts-ignore
      return { ...state, isLoadingTaxAssessment: action.payload.isLoading };

    // @ts-ignore
    case GET_ASSESSMENT_SUCCESS:
      // @ts-ignore
      return { ...state, taxAssessments: action.payload.taxAssessments };

    case UPDATE_SUBMISSION_REVIEW_STATUS:
      return { ...state, statusReview: action.payload.statusReview };

    default:
      // @ts-ignore
      action.type as never; // eslint-disable-line

      return state;
  }
};

const isLoading = (state: RootState): boolean => state.submission.isLoading;

const isTaxAssessmentLoading = (state: RootState): boolean =>
  state.submission.isLoadingTaxAssessment;

const isSubmissionTaxOfficeLoading = (state: RootState): boolean =>
  state.submission.isLoadingSubmissionTaxOffice;

const isPaymentLoading = (state: RootState): boolean => state.submission.isLoadingPayment;

const error = (state: RootState): MessageDescriptor | void => state.submission.error;

const getLatestSubmissionStatus = (state: RootState): DraftAndSentSubmissionState =>
  state.submission.status;

const isLatestSubmissionCompleted: (state: RootState) => boolean = createSelector(
  getLatestSubmissionStatus,
  (status) => status === DraftAndSentSubmissionStates.Submitted,
);

const isLatestSubmissionSent: (state: RootState) => boolean = createSelector(
  getLatestSubmissionStatus,
  (status) => Object.values(SentSubmissionStates).includes(status),
);

const hasResubmissionDraft = (state: RootState): boolean => state.submission.hasResubmissionDraft;

const getSubmissions = (state: RootState): SubmissionWithId[] => state.submission.submissions;

const hasSubmissions: (state: RootState) => boolean = createSelector(
  getSubmissions,
  (submissions) => !!submissions.length,
);

const getSubmissionsByYear = (state: RootState, year: number): SubmissionWithId[] =>
  // @ts-ignore
  state.submission.submissions.filter((submission) => parseInt(submission.year, 10) === year);

const getDeletedSubmissions: (state: RootState) => SubmissionWithId[] = createSelector(
  getSubmissions,
  (submissions) =>
    submissions.filter((submission) => submission.state === SentSubmissionStates.Deleted),
);
const getActiveSubmissions: (state: RootState) => SubmissionWithId[] = createSelector(
  getSubmissions,
  (submissions) =>
    submissions.filter((submission) => submission.state !== SentSubmissionStates.Deleted),
);
const getActiveSubmission: (state: RootState) => SubmissionWithId | null | undefined =
  createSelector(getSubmissions, (submissions) =>
    submissions.find((submission) => submission.state !== SentSubmissionStates.Deleted),
  );
const getSubmittedSubmissions: (state: RootState) => SubmissionWithId[] = createSelector(
  getSubmissions,
  (submissions) =>
    submissions.filter((submission) => submission.state === SentSubmissionStates.Submitted),
);
const getSubmittedSubmissionsByYear: (
  state: RootState,
  year: number,
) => SubmissionWithId | null | undefined = createSelector(getSubmissionsByYear, (submissions) =>
  submissions.find((submission) => submission.state === SentSubmissionStates.Submitted),
);
const getActiveSubmissionsByYear: (
  state: RootState,
  year: number,
) => SubmissionWithIdAndIdentificationState[] = createSelector(
  getSubmissionsByYear,
  (submissions) =>
    submissions.filter((submission) => submission.state !== SentSubmissionStates.Deleted),
);
const getLatestActiveSubmission: (state: RootState) => SubmissionWithId | undefined =
  createSelector(getActiveSubmissions, (submissions) => _.maxBy(submissions, 'year'));
// @ts-ignore
const getLatestSubmittedSubmission: (state: RootState) => SubmissionWithId = createSelector(
  getSubmittedSubmissions,
  (submissions) => _.maxBy(submissions, 'year'),
);
const isResubmission: (state: RootState) => boolean = createSelector(
  getActiveSubmission,
  getDeletedSubmissions,
  (activeSubmission, deletedSubmissions) =>
    checkIsResubmission(activeSubmission, deletedSubmissions),
);
const getDeletedSubmissionsByYear: (state: RootState, year: number) => SubmissionWithId[] =
  createSelector(getSubmissionsByYear, (submissions) =>
    submissions.filter((submission) => submission.state === SentSubmissionStates.Deleted),
  );

const getDeletedSubmissionByYear: (state: RootState, year: number) => SubmissionWithId | undefined =
  createSelector(getSubmissionsByYear, (submissions) =>
    submissions.find((submission) => submission.state === SentSubmissionStates.Deleted),
  );

// @ts-ignore
const getActiveSubmissionByYear: (state: RootState, year: number) => SubmissionWithId | undefined =
  createSelector(getSubmissionsByYear, (submissions) =>
    submissions.find((submission) => submission.state !== SentSubmissionStates.Deleted),
  );

const getRefundAmountByYear: (state: RootState, year: number) => number = createSelector(
  getActiveSubmissionByYear,
  //@ts-expect-error
  (submission) => submission?.calculationResult?.tax as number,
);

const isResubmissionByYear: (state: RootState, year: number) => boolean = createSelector(
  getDeletedSubmissionsByYear,
  getActiveSubmissionByYear,
  (deletedSubmissions, activeSubmission) =>
    checkIsResubmission(activeSubmission, deletedSubmissions),
);

const isPaidSubmission = (state: RootState): boolean => !!state.submission.payment;

const getPayment = (state: RootState): MappedPayment | null | undefined => state.submission.payment;

const getPaymentStatusForAllYears = (state: RootState): PaymentStatusByYear | null | undefined =>
  state.submission.paymentStatus;

const getAllTaxOfficeDetails = (state: RootState): any => state.submission.taxOfficeDetails;

const getSubmissionTaxOfficeDetails: (
  state: RootState,
  year: number,
) => TaxOfficeDetails | null | undefined = createSelector(
  getActiveSubmissionByYear,
  getAllTaxOfficeDetails,
  (activeSubmission, submissionOffices) => {
    return activeSubmission ? submissionOffices[activeSubmission?.id] : undefined;
  },
);
// @ts-ignore
const canViewPreviousSections: (state: RootState, year: number) => boolean = createSelector(
  getActiveSubmissionByYear,
  getLatestSubmissionStatus,
  isResubmissionByYear,
  settingsSelectors.selectedCountry,
  (activeSubmission, status, resubmission, selectedCountry: CountryCodes) => {
    switch (selectedCountry) {
      case CountryCodes.IT: {
        return !activeSubmission && (status === DraftSubmissionStates.Initial || resubmission);
      }

      default: {
        return !activeSubmission && status === DraftSubmissionStates.Initial;
      }
    }
  },
);
const getLastDeletedSubmissionByYear: (
  state: RootState,
  year: number,
) => SubmissionWithId | null | undefined = createSelector(
  getDeletedSubmissionsByYear,
  (deletedSubmissions) =>
    deletedSubmissions.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    )[0],
);
const hasCompletedAllYears = createSelector(
  getSubmissions,
  settingsSelectors.selectedCountry,
  (__: any, _year: any, taxSeasonConfig: any) => taxSeasonConfig,
  (__, year) => year,
  (submissions: SubmissionWithId[], selectedCountry: CountryCodes, taxSeason, year) => {
    const { getSupportedYearList } = taxDeclarationUtils;
    const currentYear = new Date().getFullYear();
    const supportedYearList = getSupportedYearList({
      countryCode: selectedCountry,
    });

    if (
      taxSeason &&
      currentYear === year &&
      taxSeason.enabled &&
      !supportedYearList.includes(currentYear)
    ) {
      supportedYearList.push(currentYear);
    }

    // map submission-year -> boolean
    const completeSubmissionsByYear: Record<number, boolean> = submissions.reduce(
      (map, submission) => ({
        ...map,
        [submission.year]: submission.state !== SentSubmissionStates.Deleted,
      }),
      {},
    );
    // return true if every year is complete
    return supportedYearList.every((y) => completeSubmissionsByYear[y]);
  },
);

const getAllTaxAssessments = (
  state: RootState,
): Record<number, TaxAssessments.TaxAssessmentData> | null | undefined =>
  state.submission.taxAssessments;

const getSubmissionTaxAssessment: (
  state: RootState,
  year: number,
) => TaxAssessments.TaxAssessmentData | null | undefined = createSelector(
  getSubmittedSubmissionsByYear,
  getAllTaxAssessments,
  // @ts-ignore
  (submission, taxAssessments) => taxAssessments[submission?.id],
);

const getSubmissionReviewStatus = (state: RootState) => state.submission.statusReview;

export const selectors = {
  isLoading,
  isTaxAssessmentLoading,
  isSubmissionTaxOfficeLoading,
  isPaymentLoading,
  error,
  getLatestSubmissionStatus,
  isLatestSubmissionCompleted,
  hasResubmissionDraft,
  canViewPreviousSections,
  isPaidSubmission,
  isResubmission,
  getPayment,
  getDeletedSubmissions,
  getActiveSubmissions,
  getSubmissions,
  hasSubmissions,
  getActiveSubmission,
  getActiveSubmissionByYear,
  getActiveSubmissionsByYear,
  getLatestSubmittedSubmission,
  isLatestSubmissionSent,
  getSubmittedSubmissions,
  getSubmittedSubmissionsByYear,
  getPaymentStatusForAllYears,
  getDeletedSubmissionByYear,
  getDeletedSubmissionsByYear,
  isResubmissionByYear,
  getSubmissionsByYear,
  getSubmissionTaxOfficeDetails,
  getLastDeletedSubmissionByYear,
  getLatestActiveSubmission,
  getSubmissionTaxAssessment,
  hasCompletedAllYears,
  getRefundAmountByYear,
  getSubmissionReviewStatus,
};

export const persistFilter = createFilter('submission', ['hasResubmissionDraft']);
