import * as React from 'react';
import { ScrollView, ActivityIndicator } from 'react-native';

import { Box, DisplayTitleText, BodyPrimaryText, TextButton, Button } from '../../components/core';
import { NotificationBannerLegacy } from '../../components/notification-banner-legacy';

type Props = {
  onConfirm: () => any;
  onFullTac: () => any;
  onFullPrivacy: () => any;
  loading?: boolean;
  error: Error | null | undefined;
};
export function LegalChange({
  loading = false,
  onConfirm,
  onFullTac,
  onFullPrivacy,
  error,
}: Props) {
  if (loading) {
    return (
      <Box flex alignHorizontally="center" alignVertically="center">
        <ActivityIndicator />
      </Box>
    );
  }

  return (
    <Box flex>
      {error && <NotificationBannerLegacy notificationType="TaCAcceptError" />}
      <Box flex top={2} right={2} left={2} bottom={2}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <Box top={1.5}>
            <DisplayTitleText id="legal-change.headline" />
          </Box>
          <Box top={1.5}>
            <BodyPrimaryText id="legal-change.summary" />
          </Box>
          <Box top={3}>
            <TextButton
              onPress={onFullTac}
              translationKey="legal-change.button.full-tac"
              align="left"
              style={{
                padding: 0,
              }}
            />
          </Box>
          <Box top={3}>
            <TextButton
              onPress={onFullPrivacy}
              translationKey="legal-change.button.full-privacy"
              align="left"
              style={{
                padding: 0,
              }}
            />
          </Box>
        </ScrollView>
        <Box top={3}>
          <Button
            floating
            onPress={onConfirm}
            translationKey="legal-change.button.confirm"
            testId="accept.policies.button"
          />
        </Box>
      </Box>
    </Box>
  );
}
