import * as React from 'react';
import { Linking } from 'react-native';
import SmartBanner from 'react-smartbanner';

import { isWeb } from 'src/utils/platform';
import { getSimpleAppOpenningDynamicLink } from 'src/services/dynamic-link';

type Props = {};

export const SmartAppBanner: React.FC<Props> = () => {
  return isWeb ? (
    <SmartBanner
      title={'Taxfix'}
      position="top"
      daysHidden={1}
      daysReminder={1}
      onInstall={() => {
        const link = getSimpleAppOpenningDynamicLink();
        Linking.openURL(link);
      }}
    />
  ) : null;
};
