import * as React from 'react';

import { InputField } from 'src/taxfix-components/src';
import { AnswerComponentProps } from 'src/components/answers/types';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';
import { specificAnswerTypesQuestionIds } from 'src/common/constants-it';
import AnswerForm from 'src/_italy/quizmaster/components/AnswerForm';
import { isEmptyInput } from 'src/validations/italy-input-fields';

type Props = AnswerComponentProps & {
  quizmasterLight: QuizmasterLight;
};

type ValidationError = {
  translationKey: string;
};

type State = {
  value: string;
  errors: ValidationError[];
};

const EmployerName: React.VFC<Props> = (props) => {
  const { getTranslationText } = useItalyIntl();
  const label = getTranslationText('answers.employer-name.label');

  const initialState: State = {
    value: props.value || '',
    errors: [],
  };

  const [state, setState] = React.useState<State>(initialState);

  const handleChange = (value: string) => {
    setState({
      value,
      errors: [],
    });
  };

  const { editing } = props;

  const handleConfirm = (value: string) => {
    setState({
      value,
      errors: [],
    });

    const { quizmasterLight } = props;
    const employerTaxCode = quizmasterLight[specificAnswerTypesQuestionIds.employerTaxCode];
    const employerAddress = quizmasterLight[specificAnswerTypesQuestionIds.employerAddress];
    if (value.toLocaleUpperCase().includes('INPS')) {
      employerTaxCode.saveAnswer('80078750587');
      employerAddress.saveAnswer({
        address: {
          street: 'Via Ciro il Grande',
          number: '21',
          postalCode: '00144',
          city: 'Roma',
          numberExtension: '',
        },
        coordinate: { latitude: 41.8357278, longitude: 12.4707516 },
        countryCode: 'IT',
        displayName: 'Via Ciro il Grande, 21, 00144 Roma RM, Italy',
        postalCode: '00144',
        state: 'RM',
      });
    } else if (editing && props.value.toLocaleUpperCase().includes('INPS')) {
      employerTaxCode.deleteResponse();
      employerAddress.deleteResponse();
    }

    props.onAnswer(props.question, value);
  };

  const form = {
    onConfirm: () => handleConfirm(state.value),
    disabled: isEmptyInput(state.value),
  };

  return (
    <AnswerForm {...form}>
      <InputField label={label} value={state.value} onChangeText={handleChange} />
    </AnswerForm>
  );
};

export default withQuizmasterLight([
  specificAnswerTypesQuestionIds.employerTaxCode,
  specificAnswerTypesQuestionIds.employerAddress,
])(
  // @ts-ignore
  EmployerName,
);
