import { useBreakpointValue } from 'native-base';
import React from 'react';

import { AvatarGroup, Card, Container, Text, TextLink } from 'src/taxfix-components/src';

import { Props } from './types';

/**
 * Display a contact for solving questions
 */

export const QuestionDocCard: React.FC<Props> = ({
  onPress,
  title,
  titleMobile,
  content,
  textLink,
  avatarSources,
  ...otherProps
}): JSX.Element => {
  const showFullVersion = useBreakpointValue({ base: false, lg: true });
  return (
    <Card
      variant="sticky1"
      bg="secondaryFeedback.greenLight"
      padding={1}
      flexDirection="row"
      maxW="360px"
      alignItems="flex-start"
      {...otherProps}
    >
      <AvatarGroup
        avatarSources={avatarSources}
        certifiedIconSize={3}
        avatarProps={{ size: 44, marginLeft: '-20px', borderWidth: 2 }}
      />
      <Container marginLeft={1} justifyContent="space-between" flex={1}>
        <Text variant="titleXXSBold" value={showFullVersion ? title : titleMobile} />
        {showFullVersion && content && <Text variant="bodyXSBook" value={content} mt="4px" />}
        {textLink && onPress && (
          <TextLink variant="linkS" text={textLink} onPress={onPress} mt="4px" />
        )}
      </Container>
    </Card>
  );
};
