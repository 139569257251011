import { IncomeTaxValue, Responses } from '@taxfix/quizmaster/dist/types';
import { createSelector } from 'reselect';
import { Calculations } from '@taxfix/income-tax-calculator';

import { BaseTaxCalculator } from 'src/taxfix-business-logic/tax-calculator';
import { QuizmasterTaxCalculator } from 'src/taxfix-business-logic/tax-calculator/Quizmaster';

import { IncomeTaxCalculatorState, initialYearState, RootState } from './reducers';

type TaxYear = number | null | undefined;

const incomeFieldsSelector = (question: IncomeTaxCalculatorState) => question.incomeFields || [];

const calculationsSelector = (question: IncomeTaxCalculatorState) => question.calculations;

const incomeFieldHashSelector = (taxYearState: IncomeTaxCalculatorState) =>
  taxYearState.incomeFieldHash;

export const currentTaxYearStateSelector: (
  state: RootState,
  year: number,
) => IncomeTaxCalculatorState = createSelector(
  (_: RootState, year: TaxYear) => year,
  (state: RootState) => state.questions,
  (taxYear, questions) =>
    taxYear != null ? questions[`${taxYear}`] || initialYearState : initialYearState,
);

export const currentIncomeFieldHashSelector = createSelector(
  [currentTaxYearStateSelector],
  incomeFieldHashSelector,
);

export const currentIncomeFieldsSelector: (state: RootState, year: number) => IncomeTaxValue[] =
  createSelector(currentTaxYearStateSelector, incomeFieldsSelector);

export const currentCalculationsSelector: (
  state: RootState,
  year: number,
) => Calculations | null | undefined = createSelector(
  currentTaxYearStateSelector,
  calculationsSelector,
);

export const taxCalculatorSelector: (
  state: RootState,
  year: number,
  activeResponseJS?: Responses,
) => BaseTaxCalculator | QuizmasterTaxCalculator = createSelector(
  (_: RootState, year: TaxYear) => year,
  currentIncomeFieldsSelector,
  currentCalculationsSelector,
  (_: RootState, _year: TaxYear, activeResponseJS: Responses) => activeResponseJS,
  (
    year: TaxYear,
    incomeFields: IncomeTaxValue[],
    calculations: Calculations | null | undefined,
    activeResponseJS: Responses,
  ) => {
    if (year && activeResponseJS && incomeFields.length && Boolean(calculations)) {
      return new QuizmasterTaxCalculator({
        year,
        responses: activeResponseJS,
        incomeFields: incomeFields,
        calculations: calculations as Calculations,
      });
    } else return new BaseTaxCalculator();
  },
);
