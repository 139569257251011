import * as React from 'react';
import { useSelector } from 'react-redux';

import { getNavigationActions } from 'src/routes/config-util';
import { navigation } from 'src/services/navigation-service';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { useNavigation } from 'src/hooks/navigation-hook';

import { Sidebar, MenuItem } from '../../components/sidebar';

export const ItalySidebar = (): JSX.Element => {
  const { safeResetNavigation } = useNavigation();
  const isLoggedIn = useSelector(userAuthSelectors.isAuthenticated);

  const handlePress = () => {
    isLoggedIn
      ? safeResetNavigation([getNavigationActions().toStatus('screen')])
      : navigation.back();
  };

  return (
    <Sidebar>
      <MenuItem
        translationId={
          isLoggedIn ? 'web.sidebar.italy.back-to-journey' : 'web.navigation.italy.back'
        }
        iconName="interface-icons-svg.icon-chevron-left-thin-sm"
        onPress={handlePress}
      />
    </Sidebar>
  );
};
