import _ from 'lodash';
import format from 'date-fns/format';
import CodiceFiscale from 'codice-fiscale-js';
import names from 'i18n-iso-countries';
import { CountryCodes, Documents } from '@taxfix/types';
import { States as SubmissionStates } from '@taxfix/submissions-types';

import DataExtractor from '../biz-logic/data-extractor';

import { handleRawValue } from './validations';

export type PartnerData = {
  firstName: string;
  lastName: string;
  taxId: number;
  birthdate: string;
};
export type PersonalData = {
  firstName: string;
  lastName: string;
  taxId: number;
  birthdate: string;
  city: string;
  partner?: PartnerData;
};
export const extractAssignmentFormData = (
  responses: Record<string, any>,
  selectedCountryCodes: CountryCodes,
) => {
  const dataExtractor = new DataExtractor(responses);
  const { firstName, lastName, taxId, taxAuthority, dateOfBirth, addressWithCountryCode } =
    dataExtractor;
  return {
    firstName,
    lastName,
    taxId,
    taxAuthority,
    // @ts-ignore
    birthdate: format(new Date(dateOfBirth), 'YYYY-MM-DD'),
    address: {
      ...addressWithCountryCode,
      countryName: names.getName(addressWithCountryCode?.countryCode, selectedCountryCodes),
    },
    countryCode: selectedCountryCodes,
  };
};
export const extractPersonalData = (responses: Record<string, any>) => {
  const dataExtractor = new DataExtractor(responses);
  const { firstName, lastName, taxId, dateOfBirth, address, jointAssessment } = dataExtractor;
  const { city } = address ?? {};
  let data: PersonalData = {
    // @ts-ignore
    firstName,
    // @ts-ignore
    lastName,
    // @ts-ignore
    taxId,
    // @ts-ignore
    birthdate: format(new Date(dateOfBirth), 'MM/DD/YYYY'),
    // @ts-ignore
    city,
  };

  if (jointAssessment) {
    const { partnerDateOfBirth, partnerFirstName, partnerLastName, partnerTaxId } = dataExtractor;
    data = {
      ...data,
      partner: {
        // @ts-ignore All bellow values must exist since the submission is joint
        firstName: partnerFirstName,
        // @ts-ignore
        lastName: partnerLastName,
        // @ts-ignore
        taxId: partnerTaxId,
        // @ts-ignore
        birthdate: partnerDateOfBirth,
      },
    };
  }

  return data;
};

/** Auxiliary function to prepare personal data object for the DE prefill request.
 *  The user personal data is fetched from the most fresh year the user submitted in.
 *  This is used instead of redux: user.data.personal, because it is necessary to make sure
 *  that the data used for pre-filling is fresh, while redux data comes from the last year the user
 *  submitted in, which isn't necessarily the newest submitted taxYear. */
export function extractPersonalDataFromQuizMaster(
  fromYear: number,
  toYear: number,
  submissions: Array<any>,
  questionStores: any,
  currentSubmissionYear?: number,
): PersonalData | null | undefined {
  const existingSubmissionYears = submissions
    .filter((sub) => sub.state !== SubmissionStates.Deleted)
    .reduce(
      (map, sub) => ({
        ...map,
        // If submission is submitted (filed), we dont show re-identify
        [parseInt(sub.year, 10)]:
          sub.state === SubmissionStates.Submitted ||
          sub.identificationDocumentState !== Documents.States.Rejected
            ? 'submitted'
            : 're-identify',
      }),
      {},
    );

  const supportedYearsDescending = _.range(toYear, fromYear - 1);

  let mostRecentSubmittedYear;

  // eslint-disable-next-line no-restricted-syntax
  for (const year of supportedYearsDescending) {
    const status = existingSubmissionYears[year];

    if (status === 'submitted') {
      mostRecentSubmittedYear = year;
      break;
    }
  }

  const yearForPersonalDataInfo = mostRecentSubmittedYear || currentSubmissionYear;
  const responses = questionStores[yearForPersonalDataInfo!]?.responsesJS;
  if (!yearForPersonalDataInfo || !responses) return {} as PersonalData;
  return extractPersonalData(responses);
}
export const extractPersonalDataFromItalianTaxId = (
  taxId: string,
): {
  birthdate: Date;
  birthdateFormatted: string;
  birthplace: string;
  birthplaceProvince: string;
  gender: string;
} => {
  const normalizedTaxId = handleRawValue(taxId).toUpperCase();
  const codiceFiscale = new CodiceFiscale(normalizedTaxId);
  const { gender, year, month, day, birthplace, birthplaceProvincia } = codiceFiscale.toJSON();
  return {
    birthdate: codiceFiscale.birthday,
    birthdateFormatted: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
    birthplace,
    birthplaceProvince: birthplaceProvincia,
    gender,
  };
};
