import * as React from 'react';
import { FlatList } from 'react-native';

import { testID } from '../common/testID';

import Separator from './separator';

type Props = React.ComponentProps<typeof FlatList> & {
  testId: string;
  renderItem?: any;
};

const List = (
  props: Props, // @ts-ignore (RN update 0.63.3)
) => <FlatList ItemSeparatorComponent={Separator} {...props} {...testID(props.testId)} />;

// TODO: Once we update to flow 0.63.0 remove this
// and change React.ElementProps to React.ElementConfig
// @ts-ignore
List.defaultProps = { ...FlatList.defaultProps };
export default List;
