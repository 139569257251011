import { useBreakpointValue } from 'native-base';
import * as React from 'react';
import { StyleSheet, SafeAreaView } from 'react-native';

import { Button, Container, NavButton, QuestionDocCard, Text } from 'src/taxfix-components/src';
import { getImageAsset } from 'src/assets/image-assets';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { isAndroid, isIOS } from 'src/utils/platform';
import { useChatPopup } from 'src/_italy/_hooks/use-chat-popup';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { ScreenName } from '../../../types/screen-name';
import { ScrollView } from '../../../components/core';
import { Loading } from '../../../components/loading';
import {
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
  NotificationBannerTypeKey,
} from '../../../components/notification-banner-legacy';
import { DocumentCategory } from '../digital-caf-documents/documents-list';

type BannerProps = {
  bannerType: NotificationBannerTypeKey;
  values: Record<string, any>;
  onAction?: () => void;
};

type Props = {
  onCTAPress: () => void;
  data: DocumentCategory[];
  isLoading: boolean;
  titleTranslationKey?: string;
  subtitleTranslationKey?: string;
  footerButtonTranslationKey: string;
  footerAdditionalInfoText: string;
  renderSummary: (arg0: any) => React.ReactNode;
  banner?: BannerProps | null | undefined | any;
  showConnectionErrorBanner: boolean;
  firstName?: string;
  onBackButtonPress?(): () => void;
};

const groupAvatarImages = [
  getImageAsset('commercialista.commercialistaStephan'),
  getImageAsset('commercialista.commercialistav2'),
];

const styles = StyleSheet.create({
  safeAreaView: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'space-between',
  },
  scrollView: {
    width: '100%',
  },
});

export const RequiredDocuments: React.FC<Props> = ({
  onCTAPress,
  data,
  isLoading,
  titleTranslationKey = 'required-document.review.title',
  subtitleTranslationKey = 'required-document.review.subtitle',
  footerButtonTranslationKey,
  footerAdditionalInfoText,
  renderSummary,
  banner,
  showConnectionErrorBanner,
  firstName,
  onBackButtonPress,
}: Props) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  // header
  const flexDirectionHeader = useBreakpointValue({ base: 'column', md: 'row' });

  const marginTopHeader = useBreakpointValue({ base: '16px', md: '40px' });
  const paddingRightHeader = useBreakpointValue({ base: '24px', md: '20px', lg: '20px' });
  const paddingLeftHeader = useBreakpointValue({ base: '24px', md: '20px', lg: '0px' });
  const textVariantTitle = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  // footer
  const shadowFooter = useBreakpointValue({ base: 'sticky2', md: 'sticky1' });
  const heightFooter = useBreakpointValue({ base: '56px', md: '84px' });
  const flexDirectionFooter = useBreakpointValue({ base: 'column', md: 'row' });
  const maxWidthButton = useBreakpointValue({ md: '201px', lg: '237px' });
  const marginRightButton = useBreakpointValue({ base: '0px', lg: '20px' });

  const { getTranslationText } = useItalyIntl();
  const { handleOpenChatPopup } = useChatPopup();

  const backNavigationText = getTranslationText('web.italy.navigation.back-to-declaration');
  const questionsCardTitle = getTranslationText(
    'it.digital-caf.required-documents.questions.card.title',
    {
      name: firstName || '',
    },
  );
  const questionsCardAction = getTranslationText(
    'it.digital-caf.required-documents.questions.card.action',
  );
  const questionsCardTitleMobile = getTranslationText(
    'it.digital-caf.required-documents.questions.card.title.mobile',
    {
      name: firstName || '',
    },
  );

  const handleBackNavigationPress = () => {
    onBackButtonPress ? onBackButtonPress() : onCTAPress();
  };

  const handleQuestionCardLinkOnPress = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ITRequiredDocuments,
      buttonName: questionsCardAction,
    });
    handleOpenChatPopup();
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <SafeAreaView data-testid="required-documents-root" style={styles.safeAreaView}>
        {banner && (
          <NotificationBannerLegacy
            notificationType={banner.bannerType}
            values={banner.values}
            onAction={banner.onAction}
          />
        )}
        {showConnectionErrorBanner && (
          <NotificationBannerLegacy
            notificationType={NotificationBannerLegacyType.ConnectionFailed}
          />
        )}
        {!isSmallScreen && (
          <NavButton onPress={handleBackNavigationPress} text={backNavigationText} />
        )}
        <ScrollView
          contentContainerStyle={{
            alignItems: 'center',
          }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
          testID="required-documents-root"
        >
          <Container width="100%" maxWidth="900px">
            <Container
              flexDirection={flexDirectionHeader}
              marginTop={marginTopHeader}
              paddingRight={paddingRightHeader}
              paddingLeft={paddingLeftHeader}
            >
              <Container
                marginBottom={isSmallScreen ? '20px' : '0px'}
                width={isSmallScreen ? '100%' : '55%'}
                paddingRight={isSmallScreen ? '0px' : '24px'}
              >
                <Text
                  value={getTranslationText(titleTranslationKey)}
                  variant={textVariantTitle}
                  marginBottom={isSmallScreen ? '10px' : '8px'}
                />
                <Text
                  value={getTranslationText(subtitleTranslationKey)}
                  variant="bodyMBook"
                  color="greytones.graphicsDark"
                />
              </Container>
              <Container
                width={isSmallScreen ? '100%' : '45%'}
                alignItems={isSmallScreen ? 'flex-start' : 'flex-end'}
              >
                <QuestionDocCard
                  onPress={handleQuestionCardLinkOnPress}
                  title={questionsCardTitle}
                  titleMobile={questionsCardTitleMobile}
                  textLink={questionsCardAction}
                  avatarSources={groupAvatarImages}
                />
              </Container>
            </Container>

            <Container>{data.map(renderSummary)}</Container>
          </Container>
        </ScrollView>
        <Container
          shadow={shadowFooter}
          height={heightFooter}
          backgroundColor="white"
          alignItems="center"
          justifyContent="center"
        >
          {!isSmallScreen ? (
            <Container
              width="100%"
              maxW="900px"
              flexDirection={flexDirectionFooter}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                textAlign="left"
                value={getTranslationText(footerAdditionalInfoText)}
                variant="bodySBook"
                color="greytones.graphicsDark"
                marginRight="20px"
              />

              <Button
                variant="secondary"
                title={getTranslationText(footerButtonTranslationKey)}
                testID={footerButtonTranslationKey}
                width="100%"
                margin="0px"
                onPress={onCTAPress}
                maxWidth={isSmallScreen ? '100%' : maxWidthButton}
                marginRight={marginRightButton}
              />
            </Container>
          ) : (
            <Button
              variant="tertiary"
              title={getTranslationText(footerButtonTranslationKey)}
              testID={footerButtonTranslationKey}
              width="100%"
              marginBottom="0px"
              onPress={onCTAPress}
              maxWidth={isSmallScreen ? '100%' : maxWidthButton}
              marginRight={marginRightButton}
            />
          )}
        </Container>
      </SafeAreaView>
      {/* in order to hide the shadow of the footer at the bottom in iOS */}
      {isSmallScreen && isIOS && <Container height="18px" bgColor="white" />}
      {/* in order to hide the shadow of the footer at the bottom in Android */}
      {isSmallScreen && isAndroid && (
        <Container height="20px" bgColor="white" marginBottom="-10px" />
      )}
    </>
  );
};
