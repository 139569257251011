/* global window */

export const stripe = (price: number, isPaypalEnabled: boolean): void => {
  let isSubmitting = false;

  const post = (data: any) => {
    // Android webview seems to be unhappy if we use postMessage stored in own variable
    // see https://github.com/react-native-community/react-native-webview/issues/323#issuecomment-511824940
    if (window.ReactNativeWebView.postMessage)
      window.ReactNativeWebView.postMessage(JSON.stringify(data)); // eslint-disable-line no-undef
  };

  const log = (...message: any[]) => {
    post({
      type: 'log',
      value: message.join(','),
    });
  };

  const form = document.getElementById('payment-form'); // eslint-disable-line no-undef

  const couponForm = document.getElementById('coupon-form'); // eslint-disable-line no-undef

  const errors = document.getElementById('errors'); // eslint-disable-line no-undef

  const submit: any = document.getElementById('submit'); // eslint-disable-line no-undef

  const customerFields = Array.from(document.querySelectorAll('.txf-input')); // eslint-disable-line no-undef

  if (!form || !errors || !submit || !couponForm) {
    log('form, errors or submit element not found on page');
    return;
  }

  // # CREATE STRIPE INSTANCE
  const stripeInstance = Stripe('<%= stripePublishableKey %>', {
    locale: '<%= locale %>',
  }); // eslint-disable-line no-undef

  // # INITIATE STRIPE ELEMENTS
  // without elements (optional) object the paypal (express checkout element) button will not show
  const stripeElements = stripeInstance.elements({
    mode: 'payment',
    currency: 'eur',
    amount: price, // price in cents - minimum charge https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
  });

  log('Stripe initialized', 'used locale: ', '<%= locale %>');

  const style = {
    base: {
      color: '#3B3B3B',
      fontFamily: 'Circular-Book, "Helvetica Neue", Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#D5D5D5',
      },
    },
    invalid: {
      color: '#3B3B3B',
    },
  };

  // we can modify the look of the PayPal button
  // https://stripe.com/docs/js/elements_object/create_express_checkout_element
  const expressCheckoutOptions = {
    buttonType: {
      paypal: 'paypal',
    },
    buttonTheme: { paypal: 'blue' },
  };

  const elements: any = {
    cardNumber: {
      id: '#card-number',
      stripeElement: stripeElements.create('cardNumber', {
        placeholder: '<%= cardNumber.placeholder %>',
        style,
      }),
      errorElement: document.querySelector('#card-number + .input-field--error'),
      // eslint-disable-line no-undef
      isReady: false,
      isComplete: false,
    },
    cardExpiry: {
      id: '#card-expiry',
      stripeElement: stripeElements.create('cardExpiry', {
        placeholder: '<%= cardExpiry.placeholder %>',
        style,
      }),
      errorElement: document.querySelector('#card-expiry + .input-field--error'),
      // eslint-disable-line no-undef
      isReady: false,
      isComplete: false,
    },
    cardCvc: {
      id: '#card-cvc',
      stripeElement: stripeElements.create('cardCvc', {
        placeholder: '<%= cardCvc.placeholder %>',
        style,
      }),
      errorElement: document.querySelector('#card-cvc + .input-field--error'),
      // eslint-disable-line no-undef
      isReady: false,
      isComplete: false,
    },
  };

  if (isPaypalEnabled) {
    elements.paypalCheckout = {
      id: '#express-checkout-element',
      stripeElement: stripeElements.create('expressCheckout', expressCheckoutOptions),
      isReady: false,
      isComplete: true,
    };
  }

  const checkAllElements = (flag: any) =>
    Object.keys(elements).reduce((result, elementKey) => {
      return result && elements[elementKey][flag];
    }, true);

  const postReady = () => {
    const allReady = checkAllElements('isReady');
    if (allReady)
      post({
        type: 'ready',
      });
  };

  const setSubmitDisabled = (isDisabled: any) => {
    submit.disabled = isDisabled;
    post({
      type: 'disableSubmit',
      value: isDisabled,
    });
  };

  const checkCustomerDataComplete = () => customerFields.every((field: any) => !!field.value);

  const unlockSubmit = () => {
    const cardFieldsComplete = checkAllElements('isComplete');
    const customerFieldsComplete = checkCustomerDataComplete();
    const allComplete = cardFieldsComplete && customerFieldsComplete;
    setSubmitDisabled(!allComplete || isSubmitting);
  };

  const initElements = () => {
    const onElementChange = (event: any) => {
      const { elementType, error, complete } = event;
      const element = elements[elementType];
      const { errorElement } = element;
      element.isComplete = complete;
      unlockSubmit();

      if (error) {
        errorElement.textContent = error.message;
      } else {
        errorElement.textContent = '';
      }
    };

    const onElementReady = (elementKey: any) => () => {
      elements[elementKey].isReady = true;
      postReady();
    };

    Object.keys(elements).forEach((elementKey) => {
      const { stripeElement, id } = elements[elementKey];
      stripeElement.mount(id);
      stripeElement.on('change', onElementChange);
      stripeElement.once('ready', onElementReady(elementKey));
    });
  };

  const setDisabled = (isDisabled: any) => {
    Object.keys(elements).forEach((elementKey) => {
      const { stripeElement } = elements[elementKey];
      stripeElement.update({
        disabled: isDisabled,
      });
      setSubmitDisabled(isDisabled);
    });
    customerFields.forEach((input) => {
      if (isDisabled) input.setAttribute('disabled', isDisabled);
      else input.removeAttribute('disabled');
    });
  };

  const setLoading = (isLoading: any) => {
    if (isLoading) {
      document!.getElementById('submit-text')!.style.display = 'none'; // eslint-disable-line no-undef

      document!.getElementById('loader')!.style.display = 'inline'; // eslint-disable-line no-undef
    } else {
      document!.getElementById('submit-text')!.style.display = 'inline'; // eslint-disable-line no-undef

      document!.getElementById('loader')!.style.display = 'none'; // eslint-disable-line no-undef
    }
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    log('Form submitted');
    isSubmitting = true;
    setDisabled(true);
    setLoading(true);
    const customerData: any = {};
    customerFields.forEach((input: any) => {
      customerData[input.id] = input.value;
    });
    post({
      type: 'submit',
      value: customerData,
    });
  };

  const onConfirmCardPayment = (event: any) => {
    stripeInstance
      .confirmCardPayment(event.detail.clientSecret, {
        payment_method: {
          card: elements.cardNumber.stripeElement,
        },
      })
      .then((result: any) => {
        log('Confirm payment with stripe done');

        if (result.error) {
          setDisabled(false);
          setLoading(false);
          errors.textContent = result.error.message;
          post({
            type: 'failed',
            value: result.error,
          });
        } else {
          post({
            type: 'finished',
          });
        }
      })
      .catch((error: any) => {
        log('Error confirm payment with stripe', error.message);
      })
      .then(() => {
        isSubmitting = false;
      });
  };

  const onConfirmPayPalPayment = (event: any) => {
    // TODO: check comment in payment-credit-card.tsx
    // We should't have to handle the error here as this callback is to handle payment success, but we need access to post function
    if (event.detail.clientSecret === 'API::ERROR') {
      post({
        type: 'reload',
      });
      return;
    }

    stripeInstance
      .confirmPayPalPayment(event.detail.clientSecret, {
        return_url: event.detail.redirectUrl, // after redirection, Stripe appends paramaters (containers/app-state.tsx)
      })
      .then(function (result: any) {
        // this block will only be executed when there's an error from Paypal popup
        if (result.error) {
          // Check errors in https://stripe.com/docs/api/errors
          post({
            type: 'failed',
            value: result.error,
          });

          post({
            type: 'reload',
          });
        }
      })
      .catch(function (error: any) {
        log('Error confirm payment with stripe', error.message);

        post({
          type: 'reload',
        });
      });
  };

  const onRefreshWindowWithError = (event: any) => {
    window.location.reload();
  };

  const onEditCoupon = (event: any) => {
    event.preventDefault();
    log('Coupon submitted');
    post({
      type: 'editCoupon',
    });
  };

  const onUpdateCoupon = (event: any) => {
    log('onUpdateCoupon');
    // eslint-disable-next-line prefer-destructuring
    const hasCoupon = event.detail.hasCoupon;

    if (!hasCoupon) {
      document!.getElementById('add-coupon')!.style.display = 'flex'; // eslint-disable-line no-undef

      document!.getElementById('remove-coupon')!.style.display = 'none'; // eslint-disable-line no-undef
    } else {
      document!.getElementById('add-coupon')!.style.display = 'none'; // eslint-disable-line no-undef

      document!.getElementById('remove-coupon')!.style.display = 'flex'; // eslint-disable-line no-undef
    }

    unlockSubmit();
    const elements = document?.getElementsByClassName('summary-amount');
    for (let i = 0; i < elements.length; i++) {
      elements[i].innerHTML = event.detail.amount;
    }
    stripeElements.update({ amount: event.detail.priceInCents });
  };

  // internal event triggered when button pay is clicked
  form.addEventListener('submit', onFormSubmit);

  // event triggered from react-native
  couponForm.addEventListener('submit', onEditCoupon);

  document.addEventListener('submitForm', onFormSubmit); // eslint-disable-line no-undef

  document.addEventListener('updateCoupon', onUpdateCoupon); // eslint-disable-line no-undef

  document.addEventListener('confirmCardPayment', onConfirmCardPayment); // eslint-disable-line no-undef

  document.addEventListener('confirmPayPalPayment', onConfirmPayPalPayment); // eslint-disable-line no-undef

  document.addEventListener('refreshWindowWithError', onRefreshWindowWithError); // eslint-disable-line no-undef

  customerFields.forEach((input) => input.addEventListener('input', unlockSubmit));

  // EXPRESS CHECKOUT ELEMENT - PAYPAL
  const expressCheckoutElement = stripeElements.getElement('expressCheckout');

  if (expressCheckoutElement) {
    expressCheckoutElement.on('confirm', function (event: any) {
      // call Stripe function to initiate payment confirmation
      // TODO: customerFields currently empty. they might be needed in the future to fix stripe dashboard data
      const customerData: any = customerFields.forEach((input: any) => {
        customerData[input.id] = input.value;
      });

      post({
        type: 'submit-paypal',
        value: customerData,
      });
    });
  }

  initElements();
  log('Elements initialized', couponForm);
};
