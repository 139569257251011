import { Skeleton, Text, VStack, View, useTheme } from 'native-base';
import React from 'react';

import { ArticleListItem } from 'src/taxfix-components/src';

import { ArticleListItemData } from '../../general/ArticleListItem';

export type ArticleListProps = {
  data: ArticleListItemData[];
  onItemPress: (id: number | string) => void;
  size?: number;
  searchTerm?: string;
  showSummary?: boolean;
  showBodySnippet?: boolean;
  isLoading: boolean;
  noResultsText: string;
};

export function ArticleList({
  data,
  onItemPress,
  size = 3,
  searchTerm,
  showSummary = false,
  showBodySnippet = false,
  isLoading,
  noResultsText,
}: ArticleListProps) {
  const { colors } = useTheme();
  if (isLoading) {
    return (
      <VStack space="2">
        {[...Array(size)].map((_, index: number) => (
          <Skeleton key={index.toString()} h="10" startColor="coolGray.100" />
        ))}
      </VStack>
    );
  }

  if (!data?.length) {
    return (
      <VStack space="2">
        <Text
          fontWeight="400"
          fontSize="16px"
          textAlign="center"
          color={colors.greytones.textLight}
        >
          {noResultsText}
        </Text>
      </VStack>
    );
  }

  const getSummary = (showSum: boolean, showBody: boolean, faq: any) =>
    showSum ? faq.summary || (showBody ? faq.highlight?.body : null) : null;

  return (
    <View
      borderTopColor={colors.greytones.graphicsLight}
      borderTopWidth={1}
      flexDirection="column"
      flex={1}
    >
      {data.map((item) => (
        <ArticleListItem
          key={item.id}
          id={item.id}
          title={item.title}
          searchTerm={searchTerm}
          summary={getSummary(showSummary, showBodySnippet, item)}
          onPress={onItemPress}
        />
      ))}
    </View>
  );
}
