export const migration = (state: Record<string, any>) => {
  const newState = {
    ...state,
    prefillES: {
      ...state.prefillES,
      address: undefined,
      status: {
        ...state.prefillES.status,
        addressState: undefined,
        hasRatifiedAddress: undefined,
        hasFetchedPrefill: undefined,
        hasPrefill: state.prefillES?.status?.hasFetchedPrefill ?? 'idle',
        hasFetchedAnswers:
          Object.keys(state.prefillES?.prefillData ?? {}).length > 0 ? 'done' : 'idle',
      },
    },
  };

  return newState as Record<string, any>;
};
