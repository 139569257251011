import * as React from 'react';
import { useTheme } from 'native-base';

import { ConfirmableProps } from 'src/components/answers/types';
import translationKeyForOption from 'src/components/answers/translationKeyForOption';

import { MultiSelect } from './multi-select';
import { MultiSelectOption, MultiSelectOptionId } from './multi-select-list';

type Props = ConfirmableProps & {
  value?: MultiSelectOption[];
  // Empty value must be an option key such as 'NONE' and cannot be a null key
  emptyValue?: MultiSelectOptionId;
  ignoreEmptyValue?: boolean;
  required?: boolean;
  alignment?: 'vertical' | 'horizontal';
  options: MultiSelectOption[];
};

const MultiSelectContainerLegacy = (props: Props) => {
  const { colors } = useTheme();

  const { options, emptyValue, ignoreEmptyValue } = props;

  const emptyOption = options.find((option) => option.id === emptyValue);
  const realOptions = ignoreEmptyValue
    ? options
    : options.filter((option) => option.id !== emptyValue);
  const optionsWithoutEmpty = realOptions.map((option) => ({
    ...option,
    keyForCheckBox: option.id,
  }));

  const emptyOptionTranslationKey =
    !ignoreEmptyValue && emptyOption?.translationKey
      ? translationKeyForOption(emptyOption.translationKey)
      : 'answers.leave-empty';
  const submitButtonTranslationKey = !emptyValue ? emptyOptionTranslationKey : 'answers.submit';

  return (
    <MultiSelect
      {...props}
      onAnswer={props.onAnswer}
      question={props.question}
      questionText={props.questionText}
      year={props.year}
      color={colors.brand.brandGreen}
      colorSecondary={colors.secondaryFeedback.greenLight}
      options={optionsWithoutEmpty}
      submitButtonTranslationKey={submitButtonTranslationKey}
    />
  );
};

export { MultiSelectContainerLegacy };
