import React from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import {
  useNavigationBuilder,
  TabRouter,
  TabActions,
  createNavigatorFactory,
  ParamListBase,
  TabNavigationState,
  TabRouterOptions,
  TabActionHelpers,
} from '@react-navigation/native';

import { Container } from 'src/taxfix-components/src';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { theme } from 'src/components/core';
import { isMobileWeb, isNative } from 'src/utils/platform';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { getTaxSeasonInfo } from '../../services/tax-season';
import { useAppStateContext } from '../../hooks/useAppStateContext';

import LogoIcon from './logo';
import {
  TabBarProps,
  ContentScreenProps,
  TabNavigationOptions,
  TabNavigationEventMap,
  Props,
} from './types';
import { TabItem } from './tab-item';

const styles = StyleSheet.create({
  mobileTab: {
    backgroundColor: theme.color.lightFill,
    alignSelf: 'center',
    flexDirection: 'row',
    width: '100%',
    height: 74,
    borderTopColor: theme.color.fill,
    borderTopWidth: 1,
  },
  webTab: {
    flex: 1,
    flexDirection: 'column',
    width: 120,
    justifyContent: 'center',
  },
});

const ContentScreen: React.FC<ContentScreenProps> = ({ state, descriptors }) => {
  return (
    <View style={[{ flex: 1 }]}>
      {state.routes.map((route, i) => {
        return (
          <View
            key={route.key}
            style={[StyleSheet.absoluteFill, { display: i === state.index ? 'flex' : 'none' }]}
          >
            {descriptors[route.key].render()}
          </View>
        );
      })}
    </View>
  );
};

export const TabBar: React.FC<TabBarProps> = ({ state, navigation, isLargeScreen }) => {
  const { getTranslationText } = useItalyIntl();

  return (
    <View style={isLargeScreen ? styles.webTab : styles.mobileTab}>
      {state.routes.map((route, tabIndex) => {
        const isActive = tabIndex === state.index;
        const isLastTab = tabIndex === state.routes.length - 1;

        //@ts-ignore
        const { iconName, translationKey, onResetStack } = route.params;

        const handleOnTabPress = () => {
          if (onResetStack && state.index === tabIndex) {
            onResetStack();
          } else {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!event.defaultPrevented) {
              Analytics.log(AnalyticsEvent.sectionShown, {
                sectionName: route.name,
              });
              navigation.dispatch({
                ...TabActions.jumpTo(route.name),
                target: state.key,
              });
            }
          }
        };

        const Tab = (
          <TabItem
            isActive={isActive}
            onPress={handleOnTabPress}
            iconKey={iconName}
            text={getTranslationText(translationKey)}
            isLargeScreen={isLargeScreen}
            isLastTab={isLastTab}
          />
        );

        return Tab;
      })}
    </View>
  );
};

const ItalyTabNavigator: React.FC<Props> = ({ initialRouteName, children, screenOptions }) => {
  const { state, navigation, descriptors } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });
  const isLargeScreen = !isMobileWeb && !isNative;

  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);
  const isAppLocked = useAppStateContext().locked;
  const isOffSeason = useSelector(getTaxSeasonInfo).isOffSeason;

  return (
    <SafeAreaView style={{ flex: 1, flexDirection: isLargeScreen ? 'row' : 'column' }}>
      {!isLargeScreen && <ContentScreen state={state} descriptors={descriptors} />}
      {isAuthenticated && !isAppLocked && !isOffSeason && (
        <Container mr={isLargeScreen ? 1 : '0px'} shadow="sticky2">
          {isLargeScreen && (
            <Container mt={2} position="absolute">
              <LogoIcon />
            </Container>
          )}
          <TabBar state={state} navigation={navigation} isLargeScreen={isLargeScreen} />
        </Container>
      )}

      {isLargeScreen && <ContentScreen state={state} descriptors={descriptors} />}
    </SafeAreaView>
  );
};

export const createItalyNavigator = createNavigatorFactory(ItalyTabNavigator);
