import React, { useCallback, useMemo } from 'react';
import { Linking } from 'react-native';
import { useTheme } from 'native-base';
import { useDispatch, useSelector } from 'react-redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { isIOS } from 'src/utils/platform';

import { selectors as firebaseSelectors } from '../../stores/modules/remote-config-firebase';
import { useLargeScreenQuery } from '../../components/core';

import { TaxGuideComponent } from './tax-guide-component';

type OnLinkPress = (link: string, buttonName: string) => () => void;

export type TaxGuideArticle = {
  image: string;
  title: string;
  onPress(): void;
  bgColor?: string;
};

export const TaxGuideContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { basics, goodToKnow, curiosities } = useSelector(
    firebaseSelectors.getItalyTaxGuideArticles,
  );
  const {
    colors: {
      secondaryCategory: { orangeHalf, goldHalf },
      secondaryFeedback: { greenLight },
    },
  } = useTheme();
  const isLargeScreen = useLargeScreenQuery();

  const handleLinkPress: OnLinkPress = useCallback(
    (url, buttonName) => () => {
      Analytics.log(AnalyticsEvent.buttonPressed, {
        screenName: ScreenName.ITTaxGuide,
        buttonName,
      });
      if (isIOS) {
        Linking.openURL(url);
      } else {
        dispatch(
          overlayActions.show('TaxGuideOverlay', {
            url,
            onClose: () => dispatch(overlayActions.hide()),
            openLinkExternally: () => Linking.openURL(url),
          }),
        );
      }
    },
    [dispatch],
  );

  const basicsArticles: TaxGuideArticle[] = useMemo(() => {
    const getStartedTitle = 'it.tax-guide.basics.article.get-started.title';
    const documents730Title = 'it.tax-guide.basics.article.documents-730.title';
    const deductionsTitle = 'it.tax-guide.basics.article.deductions.title';
    return [
      {
        image: isLargeScreen ? 'laptopAndHandWithPhoneDesktop' : 'laptopAndHandWithPhoneMobile',
        title: getStartedTitle,
        bgColor: greenLight,
        onPress: handleLinkPress(basics.getStarted, getStartedTitle),
      },
      {
        image: isLargeScreen ? 'letterDesktop' : 'letterMobile',
        title: documents730Title,
        bgColor: orangeHalf,
        onPress: handleLinkPress(basics.documents730, documents730Title),
      },
      {
        image: isLargeScreen ? 'handsWithMoneyDesktop' : 'handsWithMoneyMobile',
        title: deductionsTitle,
        bgColor: goldHalf,
        onPress: handleLinkPress(basics.deductions, deductionsTitle),
      },
    ];
  }, [handleLinkPress, basics, orangeHalf, goldHalf, greenLight, isLargeScreen]);

  const goodToKnowArticles: TaxGuideArticle[] = useMemo(() => {
    const newsTitle = 'it.tax-guide.good-to-know.article.news.title';
    const deadlinesTitle = 'it.tax-guide.good-to-know.article.deadlines.title';
    const mandatoryTaxReturnsTitle =
      'it.tax-guide.good-to-know.article.mandatory-tax-returns.title';
    const howToReadTaxReturnTitle =
      'it.tax-guide.good-to-know.article.how-to-read-tax-return.title';
    return [
      {
        image: 'handsCoins',
        title: howToReadTaxReturnTitle,
        onPress: handleLinkPress(goodToKnow.howToReadTaxReturn, howToReadTaxReturnTitle),
      },
      {
        image: 'newsITA2024',
        title: newsTitle,
        onPress: handleLinkPress(goodToKnow.news, newsTitle),
      },
      {
        image: 'peopleAchievement',
        title: mandatoryTaxReturnsTitle,
        onPress: handleLinkPress(goodToKnow.mandatoryTaxReturn, mandatoryTaxReturnsTitle),
      },
      {
        image: 'calendar2',
        title: deadlinesTitle,
        onPress: handleLinkPress(goodToKnow.deadlines, deadlinesTitle),
      },
    ];
  }, [goodToKnow, handleLinkPress]);

  const curiositiesArticles: TaxGuideArticle[] = useMemo(() => {
    const musicBonusTitle = 'it.tax-guide.curiosities.article.music-bonus.title';
    const housingBonusTitle = 'it.tax-guide.curiosities.article.housing-bonus.title';
    const irpefReformTitle = 'it.tax-guide.curiosities.article.irpef-reform.title';
    const bonus100euroTitle = 'it.tax-guide.curiosities.article.bonus-100-euro.title';
    return [
      {
        image: 'coinsMediumSkin',
        title: bonus100euroTitle,
        onPress: handleLinkPress(curiosities.bonus100euro, bonus100euroTitle),
      },
      {
        image: 'personComputerPlant',
        title: musicBonusTitle,
        onPress: handleLinkPress(curiosities.musicBonus, musicBonusTitle),
      },
      {
        image: 'geometricFigures',
        title: irpefReformTitle,
        onPress: handleLinkPress(curiosities.irpefReform, irpefReformTitle),
      },
      {
        image: 'houses',
        title: housingBonusTitle,
        onPress: handleLinkPress(curiosities.housingBonus, housingBonusTitle),
      },
    ];
  }, [curiosities, handleLinkPress]);

  return (
    <TaxGuideComponent
      basicsArticles={basicsArticles}
      goodToKnowArticles={goodToKnowArticles}
      curiositiesArticles={curiositiesArticles}
    />
  );
};
