import * as React from 'react';
import { compose } from 'redux';
import { SubmissionWithId } from '@taxfix/submissions-types';

import {
  WithSubmissionPDFDownloadProps,
  withSubmissionPDFDownload,
} from '../../hocs/with-submission-pdf-download';
import {
  Box,
  DisplayTitleText,
  BodyPrimaryText,
  Section,
  useLargeScreenQuery,
  ScrollView,
} from '../../components/core';

type Content = {
  title: string;
  subtitle?: string;
  noSubmissionsTitle: string;
  noSubmissionsBody?: string;
};

export type RenderSubmissionDownloadItem = {
  submission: SubmissionWithId;
  fetchAndOpenPDF: WithSubmissionPDFDownloadProps['fetchAndOpenPDF'];
};

type OwnProps = {
  content: Content;
  renderSubmissionDownloadItem: (args: RenderSubmissionDownloadItem) => JSX.Element;
  children?: (props: { submissions: SubmissionWithId[] }) => React.ReactNode;
};

type Props = WithSubmissionPDFDownloadProps & OwnProps;

const SubmissionDownloadList = ({
  submissions,
  fetchAndOpenPDF,
  renderSubmissionDownloadItem,
  content,
  children,
}: Props) => {
  return (
    <Box left={2} right={2} bottom={2} flex>
      <ScrollView>
        <Box top={2} bottom={3}>
          <DisplayTitleText id={content.title} />
        </Box>
        {content.subtitle && (
          <Box bottom={2}>
            <BodyPrimaryText id={content.subtitle} />
          </Box>
        )}
        {submissions.map((submission, key) => (
          <Box key={key} bottom={1}>
            {renderSubmissionDownloadItem({ submission, fetchAndOpenPDF })}
          </Box>
        ))}
        {children && children({ submissions })}
      </ScrollView>
    </Box>
  );
};
const UndecoratedSubmissionsDownload = (props: Props) => {
  const { submissions, content, children } = props;
  const isLargeScreen = useLargeScreenQuery();

  return submissions.length ? (
    <SubmissionDownloadList {...props} />
  ) : (
    <Section
      size="wide"
      style={
        isLargeScreen
          ? {
              marginTop: 70,
            }
          : undefined
      }
    >
      <Box left={2} right={2} bottom={2} flex>
        <Box top={2} bottom={3}>
          <DisplayTitleText id={content.noSubmissionsTitle} />
        </Box>
        {content.noSubmissionsBody && (
          <Box>
            <BodyPrimaryText id={content.noSubmissionsBody} />
          </Box>
        )}
      </Box>
    </Section>
  );
};

export const SubmissionsDownloadContainer = compose<React.ComponentType<OwnProps>>(
  withSubmissionPDFDownload,
)(UndecoratedSubmissionsDownload);
