import { $Values } from 'utility-types';
// This types should be aligned with @taxfix/types.
// We need duplicated types here because @taxfix/types is written with TS
// and there is no way to get it works.
export const PrefillStates = {
  Created: 'created',
  Reviewed: 'reviewed',
  Processing: 'processing',
  Rejected: 'rejected',
  Failed: 'failed',
  Successful: 'successful',
  Revoked: 'revoked',
};
export type PrefillState = $Values<typeof PrefillStates>;
export const DocumentIdRejectedReasons = {
  IdNameInconsistent: 'id-name-inconsistent',
  IdPictureMissing: 'id-picture-missing',
  IdWrongType: 'id-wrong-type',
  IdExpired: 'id-expired',
  IdUnreadable: 'id-unreadable',
  IdPartial: 'id-partial',
};
export const PrefillRejectedReasons = {
  ...DocumentIdRejectedReasons,
  PastDeclarationWrongType: 'past-declaration-wrong-type',
  PastDeclarationWrongPage: 'past-declaration-wrong-page',
  PastDeclarationUnreadable: 'past-declaration-unreadable',
  PastDeclarationPartial: 'past-declaration-partial',
  PastDeclarationInconsistent: 'past-declaration-inconsistent',
  TaxIdInvalid: 'tax-id-invalid',
  PrefillNotPredisposed: 'prefill-not-predisposed',
};
export type PrefillRejectedReason = $Values<typeof PrefillRejectedReasons>;
export const PrefillTypes = {
  taxAdvisor: 'tax-advisor',
  nonTaxAdvisor: 'non-tax-advisor',
};
export const defaultPrefillType = PrefillTypes.nonTaxAdvisor;
export enum PrefillActivationContentType {
  activationForm = 'prefill-activation-form',
  activationSuccess = 'prefill-activation-success',
  elster = 'prefill-activation-elster',
}
