import React from 'react';
import { useBreakpointValue } from 'native-base';

import { Button, Container } from 'src/taxfix-components/src';
import { theme } from 'src/components/core';
import { testID } from 'src/common/testID';

type FooterType = {
  label: string;
  disabled?: boolean;
  onPress: () => void;
};

const Footer: React.VFC<FooterType> = ({ label, disabled = false, onPress }) => {
  const dropShadow = useBreakpointValue({
    base: theme.styles.dropShadow,
    md: {},
  });
  const paddingHorizontal = useBreakpointValue({ base: '20px', md: '0px' });
  return (
    <Container flexDirection="row" style={dropShadow} paddingX={paddingHorizontal}>
      <Button
        maxWidth={['100%', '178px']}
        variant="primary"
        title={label}
        disabled={disabled}
        onPress={onPress}
        my="20px"
        {...testID('answer::submit')}
      />
    </Container>
  );
};

export default Footer;
