import React from 'react';
import _ from 'lodash';
import { View } from 'react-native';

import translationKeyForOption from '../../../answers/translationKeyForOption';

import SummaryText from './summary-text';

const NUM_ITEMS_TO_DISPLAY = 3;

const findOptionTranslation = ({ options }: any, option: string) =>
  (
    _.find(options, {
      id: option,
    }) || {}
  ).translationKey;

type ListProps = {
  value: string[] | string;
  properties: Record<string, any>;
  linkable?: boolean;
  color?: string;
};

function List({ value, properties, linkable, color }: ListProps) {
  const mappable = typeof value === 'string' ? [value] : value;
  const emptyValue = properties != null ? properties.emptyValue : null;
  const more =
    mappable.length > NUM_ITEMS_TO_DISPLAY ? (
      <SummaryText key="ellipsis" id="summaries.default.ellipsis" />
    ) : null;
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'flex-end',
      }}
    >
      {mappable.slice(0, NUM_ITEMS_TO_DISPLAY).map((id) => {
        const translationKey = findOptionTranslation(properties, id);

        if (translationKey == null && emptyValue != null && mappable.indexOf(emptyValue) > -1) {
          return (
            <SummaryText
              style={{
                textAlign: 'right',
              }}
              numberOfLines={1}
              ellipsizeMode="middle"
              id="summaries.default.left-empty.multiple-select"
              linkable={linkable}
              key={id}
              color={color}
            />
          );
        }

        if (translationKey == null) {
          return null;
        }

        return (
          <SummaryText
            style={{
              textAlign: 'right',
            }}
            numberOfLines={1}
            ellipsizeMode="middle"
            id={translationKeyForOption(translationKey)}
            linkable={linkable}
            key={id}
            color={color}
          />
        );
      })}
      {more}
    </View>
  );
}

export default List;
