import * as React from 'react';

import { AnalyticsEvent } from '../../biz-logic/analytics';
import { Routes } from '../../routes/routes';
import { Navigation, Tracking, NavigationParams, getRoute } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import LegalChangeContainer, { LegalChangeData } from './legal-change-container';

export type ActionParams = {
  onConfirm: () => Promise<void>;
  data: LegalChangeData;
};

type Props = Navigation<
  Routes.LegalChange,
  {
    [Routes.LegalChange]: NavigationParams<ActionParams>;
  }
>;

const screenTracking = (state: Record<string, any>, routeParams: Record<string, any>): Tracking => {
  const { tac, privacy } = routeParams.data;

  if (tac.isUpdated) {
    return {
      name: AnalyticsEvent.tacChangeScreenShown,
      params: {
        oldTacVersion: tac.acceptedVersion,
        newTacVersion: tac.currentVersion,
      },
    };
  }

  return {
    name: AnalyticsEvent.privacyChangeScreenShown,
    params: {
      oldVersion: privacy.acceptedVersion,
      newVersion: privacy.currentVersion,
    },
  };
};

const LegalChangeScreen: React.FC<Props> = ({ route }) => {
  const { onConfirm, data } = route.params;
  useDefaultNavigationOptions(undefined, {
    headerShown: false,
  });

  return <LegalChangeContainer onConfirm={onConfirm} data={data} />;
};

export const { action: toLegalChange, screen: legalChangeScreen } = getRoute<ActionParams>({
  routeName: Routes.LegalChange,
  tracking: screenTracking,
  screenNavigationOptions: {
    gesturesEnabled: false,
  },
  screenComponent: LegalChangeScreen,
});

export default LegalChangeScreen;
