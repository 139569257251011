import { HStack, Stack, useBreakpointValue, VStack } from 'native-base';
import React from 'react';

import { Button, Collapsable, Icon, icons, Image } from 'src/taxfix-components/src';

import { Text } from '../../general/Text/index';

import { Props } from './types';

/**
 * card that shows your previous tax declarations
 */
export const DownloadYourDeclarationCard: React.FC<Props> = ({
  cardTitle,
  cardMainNote,
  secondarySubmissionNote,
  btnTitle,
  handleTaxDeclarationDownload,
}): JSX.Element => {
  const padding = useBreakpointValue({ base: '1', md: '4' });
  const width = useBreakpointValue({ base: '100%', md: '580px' });
  const mainTextVariant = useBreakpointValue({ base: 'titleXSMedium', md: 'bodyLBook' });
  const secondaryTextVariant = useBreakpointValue({ base: 'titleXXSMedium' });

  return (
    <Collapsable
      title={cardTitle}
      startOpen={false}
      minHeight="20"
      maxWidth={width}
      backgroundColor="greytones.white"
    >
      <VStack
        space={{ base: '2', md: '4' }}
        direction="column"
        px={padding}
        py={padding}
        alignItems="center"
      >
        <HStack space={{ base: '4', md: '7' }}>
          <Image size="md" name="oldTaxDeclarationImage" alt="old declaration image" />

          <VStack space={{ base: '2', md: '5' }} alignItems="flex-start" flex={1}>
            <Text variant={mainTextVariant} value={cardMainNote} />

            <HStack space={{ base: '1', md: '2' }}>
              <Icon color="brand.brandGreen" size={4} path={icons.checkMarkSolid} />
              <Text
                variant={secondaryTextVariant}
                value={secondarySubmissionNote}
                color="greytones.graphicsDark"
              />
            </HStack>
          </VStack>
        </HStack>
        <Stack my={{ base: '2', md: '7' }}>
          <Button variant="secondary" title={btnTitle} onPress={handleTaxDeclarationDownload} />
        </Stack>
      </VStack>
    </Collapsable>
  );
};
