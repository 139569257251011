import React from 'react';
import { View } from 'react-native';

import { StyleProp } from '../types';

import { theme } from './theme';

type OverlayProps = {
  children?: React.ReactNode;
  style?: StyleProp;
};

const Overlay: React.FC<OverlayProps> = ({ children, style }) => (
  <View
    style={[
      {
        backgroundColor: theme.color.overlay,
      },
      style,
    ]}
  >
    {children}
  </View>
);

export default Overlay;
