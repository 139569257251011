import * as React from 'react';
import { AppStateStatus } from 'react-native';

export const AppStates = Object.freeze({
  Active: 'active',
  Inactive: 'inactive',
  Background: 'background',
});
export type AppStateContextState = {
  locked: boolean;
  appState: AppStateStatus;
  onAppStateReset: () => void;
};
export const defaultAppStateContextState: AppStateContextState = {
  locked: false,
  appState: AppStates.Active,
  onAppStateReset: () => {},
};
export const AppStateContext = React.createContext<AppStateContextState>(
  defaultAppStateContextState,
);
