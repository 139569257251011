import React from 'react';
import { ScrollView as RNScrollView, ScrollViewProps } from 'react-native';

import { testID as toTestID } from 'src/common/testID';

import scrollToElement from '../../utils/scrollToElement';

type Props = ScrollViewProps & {
  children?: React.ReactNode;
  offset?: number;
  scrollTo?: React.Component<any, any>;
  scrollViewRef?: (ref: RNScrollView | null | undefined) => void;
};

class ScrollView extends React.Component<Props> {
  scrollViewRef: RNScrollView | null | undefined;

  constructor(props: Props) {
    super(props);
    (this as any).handleScrollViewRef = this.handleScrollViewRef.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.scrollTo != null && prevProps.scrollTo !== this.props.scrollTo) {
      scrollToElement(this, this.props.scrollTo, this.props.offset);
    }
  }

  handleScrollViewRef(ref: RNScrollView | null | undefined) {
    this.scrollViewRef = ref;

    if (this.props.scrollViewRef) {
      this.props.scrollViewRef(ref);
    }
  }

  render() {
    const {
      scrollTo,
      // eslint-disable-line no-unused-vars
      offset,
      // eslint-disable-line no-unused-vars
      scrollViewRef,
      // eslint-disable-line no-unused-vars
      testID,
      ...props
    } = this.props;
    return <RNScrollView {...props} {...toTestID(testID)} ref={this.handleScrollViewRef} />;
  }
}

export default ScrollView;
