import React, { ComponentType, useState } from 'react';
import { Switch as RNSwitch } from 'react-native';

import { testID } from '../../common/testID';

import { theme } from './theme';

type SwitchProps = {
  disabled?: boolean;
  initialValue?: boolean;
  onValueChange: (value: boolean) => void;
  testId?: string;
};

export const Switch: ComponentType<SwitchProps> = ({
  disabled = false,
  initialValue = false,
  onValueChange,
  testId,
}: SwitchProps) => {
  const [value, setValue] = useState(initialValue);

  return (
    <RNSwitch
      {...testID(testId)}
      disabled={disabled}
      onValueChange={(value) => {
        setValue(value);
        onValueChange(value);
      }}
      value={value}
      trackColor={{
        true: theme.color.primary,
        false: theme.color.disabledButtonBackground,
      }}
      ios_backgroundColor={theme.color.disabledButtonBackground}
      thumbColor={theme.color.lightText}
    />
  );
};
