import React from 'react';
import { StyleSheet } from 'react-native';
import Config from 'react-native-config';
import { WebView, WebViewProps } from 'react-native-webview';

import { useItalyIntl } from 'src/_italy/_hooks';
import { Loading } from 'src/components/loading';
import { Button, Container } from 'src/taxfix-components/src';

import { FreelancersSurveyErrorMessages } from './errors';

const styles = StyleSheet.create({
  containerWebView: {
    height: '100%',
    width: '100%',
  },
});

type Props = WebViewProps & {
  url: string;
  originUrl: string;
  surveyCompletedEvents: string[];
  userToken: string;
  isSurveyCompleted: boolean;
  handleOnPressCTA(): void;
};

export const FreelancersSurveyComponent: React.FC<Props> = ({
  url,
  originUrl,
  surveyCompletedEvents,
  onMessage = () => {},
  userToken,
  isSurveyCompleted,
  handleOnPressCTA,
  ...props
}) => {
  const { getTranslationText } = useItalyIntl();
  const uri = `${Config.API_BASE_URL}/api/payments/view`;

  const INJECTED_JAVASCRIPT = `
    //* Script to bridge WebView --> Native *//
    const script = document.createElement('script');
    script.innerHTML = 'function sendMessage(eventName) {window.ReactNativeWebView.postMessage(eventName)}';
    document.body.appendChild(script);
    
    // Listen to message from child window
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
    const validMessages = ${JSON.stringify(surveyCompletedEvents)};

    eventer(messageEvent, function (e) {
      if (e.origin !== '${originUrl}') return;
      try {
        const receivedEvent = JSON.parse(e.data)?.event;
        if (validMessages.includes(receivedEvent) && typeof receivedEvent === 'string') {
          sendMessage(receivedEvent);
        }
      } catch (error) {
        window.ReactNativeWebView.postMessage('${
          FreelancersSurveyErrorMessages.parsingIFrameEventsError
        }');
      }

    });
    
    //* style outer iframe
    const style = document.createElement('style');
    style.textContent = '::-webkit-scrollbar { display: none; } body { margin: 0; }';
    document.head.append(style);

    //* make the outer iframe responsive
    const meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute("content", "width=device-width, initial-scale=1");
    document.head.append(meta);
    
    // * create inner iframe
    const ifr = document.createElement('iframe');
    ifr.setAttribute('src', '${url}');
    ifr.style.width = '100%';
    ifr.style.height = '100vh';
    ifr.style.margin = '0';
    ifr.style.borderWidth = '0px';
    document.body.prepend(ifr);
    true; // Required for injectedJS to work
  `;

  return (
    <>
      <WebView
        javaScriptEnabled={true}
        injectedJavaScriptForMainFrameOnly={true}
        containerStyle={styles.containerWebView}
        source={{
          uri,
          headers: {
            Authorization: userToken ? `Token ${userToken}` : null,
          },
        }}
        startInLoadingState={true}
        renderLoading={() => <Loading />}
        injectedJavaScript={INJECTED_JAVASCRIPT}
        scrollEnabled
        nestedScrollEnabled
        onMessage={onMessage}
        {...props}
      />
      {isSurveyCompleted && (
        <Container maxW="700px" marginBottom={[10, 20, 103]}>
          <Button
            onPress={handleOnPressCTA}
            variant="primary"
            title={getTranslationText('it.freelancer-survey.button.text')}
            width={['90%', '90%', 236]}
          />
        </Container>
      )}
    </>
  );
};
