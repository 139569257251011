import * as React from 'react';
import { ReactNativeZoomableView } from '@dudigital/react-native-zoomable-view';
import { StyleSheet, Image } from 'react-native';
import { ImageURISource } from 'react-native/Libraries/Image/ImageSource';

import { Box } from './core';

type Props = {
  source: ImageURISource;
  style?: any;
  maxZoom?: number;
  minZoom?: number;
  zoomStep?: number;
  initialZoom?: number;
};
const styles = StyleSheet.create({
  zoomableContainer: {
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

const ImageZoomableView = ({
  maxZoom = 2,
  minZoom = 1,
  zoomStep = 0.5,
  initialZoom = 1,
  source,
  style,
  ...rest
}: Props) => {
  return (
    <Box flex style={styles.zoomableContainer}>
      <ReactNativeZoomableView
        zoomEnabled
        maxZoom={maxZoom}
        minZoom={minZoom}
        zoomStep={zoomStep}
        initialZoom={initialZoom}
        bindToBorders
        pinchToZoomInSensitivity={5}
        movementSensibility={1.5}
        zoomCenteringLevelDistance={5}
      >
        <Image source={source} style={[styles.image, style]} resizeMode="contain" {...rest} />
      </ReactNativeZoomableView>
    </Box>
  );
};

export { ImageZoomableView };
