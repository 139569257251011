import _ from 'lodash';
import AsyncStorage from '@react-native-community/async-storage';
import { action, computed, observable, toJS } from 'mobx';
import { persist, create as mobxCreatePersist } from 'mobx-persist';
import Quizmaster, { checkPlausibility, isQuestion } from '@taxfix/quizmaster';
import findSubtree from '@taxfix/quizmaster/dist/findSubtree';
import nodes2tree from '@taxfix/quizmaster/dist/transformer/nodes2tree';
import tree2list from '@taxfix/quizmaster/dist/transformer/tree2list';
import list2refs from '@taxfix/quizmaster/dist/transformer/list2refs';
import { resolveLoopsWithRefs } from '@taxfix/quizmaster/dist/processor/incrementalResolveLoops';
import { unindexed, isIndexed, indexFromId, appendIndex } from '@taxfix/quizmaster/dist/reference';
import { isSupported } from '@taxfix/who-are-you';
import {
  Answer,
  Answers,
  Cache,
  Id,
  Refs,
  Responses,
  TreeNode,
  UserResponse,
  Ancestors,
  PlausibilityErrors,
  InputProperties,
} from '@taxfix/quizmaster/dist/types';
import { TypeProbably } from '@taxfix/answer-types/dist/flowtypes';
import { getJointAssessmentInfo } from '@taxfix/quizmaster/dist/extensions/jointAssessment';
import { CountryCodes } from '@taxfix/types';

import {
  ANSWER_VALUE_EMPLOYEE,
  ANSWER_VALUE_SINGLE,
  DATE_OF_BIRTH,
  DO_YOU_HAVE_CHILDREN,
  FIRST_NAME,
  HOW_MANY_PAYSLIPS_DO_YOU_HAVE,
  PAYSLIP_DATA,
  WHAT_IS_YOUR_MARITAL_STATUS,
  WAGE_REPLACEMENT_BENEFITS,
  DID_YOU_GET_ANY_OTHER_INCOME,
} from 'src/taxfix-business-logic/constants/question-answer';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { updateIncomeFields } from 'src/stores/modules/questions';

import createCache from '../utils/createCache';
import AnswerTypeInstance from '../utils/answerType';
import Constants, { CategoryName, CategoryNodeId } from '../utils/constants';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import Summaries from '../biz-logic/Summaries';
import { treeWithoutIntroduction, withoutIntroduction } from '../utils/categories';
import { translationKeys } from '../i18n';
import { unwrapAnswer } from '../biz-logic/responses/wrapping';
import fetchResponses from '../biz-logic/responses/fetchAll';
import { getStore } from '../stores/util';
import { actions as syncActions } from '../stores/modules/sync-answers';
import { actions as plausibilityChecksActions } from '../stores/modules/plausibility-checks';
import { selectors as submissionSelectors } from '../stores/modules/submission';
import { syncUploadAnswersForSelectedCountry } from '../services/sync-answers';
import { getMultipleWarnings, updateMultipleWarnings } from '../services/storeIgnoredWarning';
import { tailoredExperienceQuestionIds, wayQuestionIds } from '../common/constants-it';
import { FastLaneCriteria } from '../types';

import { questionEffects } from './effects';
import {
  Category,
  Status,
  MultipleQuestionWarnings,
  MultipleQuestionWarningsByCategory,
  IgnoredWarnings,
  IQuestionStore,
  Dependencies,
} from './QuestionStore.types';
import { migrateResponses as migrateResponsesDE } from './migration-scripts-de';

const hydrate = mobxCreatePersist({
  storage: AsyncStorage,
});
type CategoriesState = {
  hasTodos: boolean;
  isComplete: boolean;
  finishedWithTodos: boolean;
};
type Nodes = Record<Id, TreeNode>;
export enum NodesType {
  normal = 'normal',
  onlyWay = 'only-way',
}
// naturalFlow = false forces the user to complete the incomplete categories above current category
type NextCategoryArgs = {
  currentCategoryId?: string;
  naturalFlow?: boolean;
};
const questionToTrack = [
  HOW_MANY_PAYSLIPS_DO_YOU_HAVE.questionID,
  PAYSLIP_DATA.questionID,
  DATE_OF_BIRTH.questionID,
  FIRST_NAME.questionID,
  WAGE_REPLACEMENT_BENEFITS.questionID,
  wayQuestionIds.firstName,
  wayQuestionIds.newbie,
  wayQuestionIds.occupation,
  tailoredExperienceQuestionIds.lastyearExpense,
  tailoredExperienceQuestionIds.lastyearExpense,
  tailoredExperienceQuestionIds.importantAspect,
];

const unfinishedCategory = (categories: Category[]): Category | null | undefined =>
  _.find(categories, (category) => category.progress < 1);

class QuestionStore implements IQuestionStore {
  constructor(
    year: number,
    nodes: Nodes,
    root: Id,
    version: string,
    nodesType: NodesType = NodesType.normal,
    countryCode = CountryCodes.IT,
  ) {
    this.cache = createCache();
    this.nodes = nodes;
    this.nodesType = nodesType;
    this.rootNode = Object.freeze(nodes2tree(this.nodes, 'root'));
    this.refs = undefined;
    this.rootRefs = Object.freeze(list2refs(tree2list(this.rootNode)));
    this.tree = this.rootNode;
    this.root = root;
    this.year = year;
    this.version = version;
    this.responses = {};
    this.isMigrated = false;
    this.countryCode = countryCode;
    this.multipleQuestionWarnings = JSON.stringify({});
    this.ignoredMultipleQuestionWarnings = {};
    this.dependencies = new Map();
  }

  cache: Cache;

  // A map of unindexed questionIds to their indexed dependencies
  dependencies: Dependencies;

  nodes: Nodes;

  nodesType: NodesType;

  root: Id;

  year: number;

  rootNode: TreeNode;

  rootRefs: Refs;

  tree: TreeNode;

  refs: Refs | undefined;

  countryCode: CountryCodes;

  @persist('object')
  @observable
  responses: Responses;

  @persist
  @observable
  isMigrated: boolean;

  needRebuild = true;

  isEffectsStarted = false;

  version: string;

  @persist
  @observable
  multipleQuestionWarnings: string;

  @persist('object')
  @observable
  ignoredMultipleQuestionWarnings: IgnoredWarnings;

  switchNodes = (nodes: Nodes, nodesType: NodesType) => {
    if (this.nodesType === nodesType) return;
    this.refs = undefined;
    this.rootRefs = {};
    this.nodes = nodes;
    this.nodesType = nodesType;
    this.rootNode = Object.freeze(nodes2tree(this.nodes, 'root'));
    this.rootRefs = Object.freeze(list2refs(tree2list(this.rootNode)));
    this.tree = this.rootNode;
    this.rebuild();
    this.responses = { ...this.responses }; // We need this to recompute categories
  };

  getAsyncStorageKey = (): string => {
    // Keys for Germany: questionStore.<year>
    if (this.countryCode === CountryCodes.DE) return `questionStore.${this.year}`;
    // Keys for other countries: questionStore.<country code>.<year>
    return `questionStore.${this.countryCode}.${this.year}`;
  };

  hydrate = async () => {
    await hydrate(this.getAsyncStorageKey(), this, {});
    await this.migrateResponsesFromNative();
    this.deleteInvalidResponses();
    this.migrateResponses();
    const store = getStore();
    if (store) await store.dispatch(syncActions.resetSyncMap(this.responsesJS, this.countryCode));
  };

  startEffects = () => {
    if (!this.isEffectsStarted) {
      this.isEffectsStarted = true;
      questionEffects(this);
    }
  };

  async migrateResponsesFromNative() {
    if (this.isMigrated) {
      return;
    }

    const responses = await fetchResponses();

    if (_.isEmpty(responses)) {
      this.setIsMigrated(true);
      return;
    }

    const filtered = this.filterResponses(responses);
    this.migratePreJAResponses(filtered);
  }

  addDependency = (dependencies: Dependencies, master: string, slave: string): void => {
    if (dependencies.has(master)) {
      dependencies.get(master)?.add(slave);
    } else {
      dependencies.set(master, new Set());
      dependencies.get(master)?.add(slave);
    }
  };

  getDependecies = (refs: Refs): Dependencies => {
    const dependencies = new Map();
    if (!refs) return dependencies;
    for (const node of Object.values(refs)) {
      const { id, parentId, inputs, targetLoops } = node;
      if (inputs) {
        const inputsWithReferences = Object.values(inputs).filter(
          (input) => input?.properties?.reference != null,
        );

        for (const input of inputsWithReferences) {
          const ref = unindexed(input.properties.reference);
          // If input changes recalculate this node
          this.addDependency(dependencies, ref, id);
        }
      }

      if (parentId) {
        // If parent changes recalculate this node
        this.addDependency(dependencies, parentId, id);
      }
      if (targetLoops?.length) {
        // If this node changes recalculate target loops
        targetLoops.map((targetLoop) => this.addDependency(dependencies, id, targetLoop));
      }
    }
    return dependencies;
  };

  // eslint-disable-next-line
  rebuild(targetLoops?: string[] | null | undefined) {
    const { tree, refs } = resolveLoopsWithRefs(
      this.tree,
      this.answers,
      this.year,
      this.cache,
      this.refs,
      targetLoops ? targetLoops.map((id) => this.rootRefs[id]) : undefined,
    );
    this.tree = tree;
    this.refs = refs;
    this.dependencies = this.getDependecies(refs);
  }

  runCleanRebuild(recomputeCategories = false) {
    this.tree = this.rootNode;
    this.rebuild();
    if (recomputeCategories) {
      this.responses = { ...this.responses }; // We need this to recompute categories
    }
  }

  rebuildIfResponsesUpdated = (recomputeCategories = false) => {
    if (this.needRebuild) {
      this.runCleanRebuild(recomputeCategories);
      this.needRebuild = false;
    }
  };

  isPreJAVersion = (version: string) => {
    const versionSplit = version.split('.');
    const mayor = versionSplit.length === 3 ? parseInt(versionSplit[0], 10) : null;
    return mayor != null && mayor < 2;
  };

  // NOTE: this is only used to import responses from .txfx files
  // @ts-ignore
  @action
  async importResponses(responses: Responses) {
    const filtered = this.filterResponses(responses);

    if (_.every(filtered, (response) => this.isPreJAVersion(response.version || ''))) {
      this.migratePreJAResponses(filtered);
    } else {
      this.cache.clear();
      this.responses = filtered;
      this.runCleanRebuild();
      const store = getStore();
      if (store) store.dispatch(syncActions.resetSyncMap(this.responsesJS, this.countryCode));
    }
  }

  @action
  persistWAYData = async (responses: Responses) => {
    const filtered = this.filterResponses(responses);
    let resolvedResponses = _.mapValues(filtered, (response) => {
      // We need one standard data type here, the data we store to server from App and Web
      // are different. Currently Mobile app is sending Object(with 'value' key) to server
      // and Web is sending only value.
      const userAnswerAmbiguous = _.get(response, 'answer');

      const userAnswer =
        userAnswerAmbiguous && userAnswerAmbiguous.value != null
          ? unwrapAnswer(userAnswerAmbiguous)
          : userAnswerAmbiguous;
      response.answer = userAnswer;
      return response;
    });

    if (_.every(resolvedResponses, (response) => this.isPreJAVersion(response.version || ''))) {
      resolvedResponses = this.addJAIndexes(responses);
    }

    this.cache.clear();
    this.responses = { ...this.responses, ...resolvedResponses };
    const store = getStore();
    if (store) store.dispatch(syncActions.resetSyncMap(this.responsesJS, this.countryCode));
  };

  removeFromCache = (id: Id, visited = new Set()): void => {
    if (visited.has(id)) return;
    visited.add(id);
    const depIds = this.dependencies.get(id);
    if (depIds) {
      for (const depId of depIds) {
        this.cache.delete(depId);
        this.removeFromCache(unindexed(depId), visited);
      }
    }
  };

  updateCache = (
    nodeAnswers: {
      node: TreeNode;
      answer: Answer;
      trackingParams?: Record<string, any>;
    }[],
  ): void => {
    try {
      for (const node of nodeAnswers) {
        this.cache.delete(node.node.id);
        this.removeFromCache(node.node.questionId);
      }
    } catch (err) {
      logger.log("Couldn't get the dependencies, clearing the whole cache", err);
      this.cache.clear();
    }
  };

  @action
  saveAnswer = async (node: TreeNode, answer: Answer, trackingParams?: Record<string, any>) => {
    await this.saveAnswers([
      {
        node,
        answer,
        trackingParams,
      },
    ]);
  };

  @action
  editAnswerAndResetDependant = async (
    node: TreeNode,
    answer: Answer,
    resetId: string,
    trackingParams?: Record<string, any>,
  ) => {
    this.responses = _.omit(this.responses, resetId);
    await this.saveAnswers([
      {
        node,
        answer,
        trackingParams,
      },
    ]);
  };

  @action
  saveAnswers = async (
    nodeAnswers: {
      node: TreeNode;
      answer: Answer;
      trackingParams?: Record<string, any>;
    }[],
  ) => {
    let targetLoops: any = [];
    let responses = {};
    nodeAnswers.forEach(({ node, answer, trackingParams }) => {
      this.trackQuestionAnswered(node, answer, trackingParams);
      responses = { ...responses, ...this.createResponse(node, answer) };
      targetLoops = _.union(targetLoops, node.targetLoops || []);
    });
    /*
      TODO: there is a glitch when question jumps to loading-page. This problem can be fixed if we upload the
      answers befor updating. But this needs to be review
      await syncUploadAnswersForSelectedCountry(responses).catch((e) => console.log(e));
    */
    this.updateCache(nodeAnswers);
    this.responses = { ...this.responses, ...responses };
    if (!_.isEmpty(targetLoops)) this.rebuild(targetLoops);
    // eslint-disable-next-line no-console
    await syncUploadAnswersForSelectedCountry(responses).catch((e) => console.log(e));

    /**
     * Transform Elster fields values and stored in the redux store.
     * Only for DE.
     */
    if (this.countryCode === CountryCodes.DE) {
      this.updateIncomeTaxFields();
    }
  };

  updateIncomeTaxFields = (): void => {
    const store = getStore();
    if (store) {
      store.dispatch(updateIncomeFields(this.year, this.tree, this.cache, this.activeResponseJS));
    }
  };

  migrateResponses = () => {
    // TODO: figure out WHY this migration of responses impacts the question store for italy
    // when removed - logging in with an existing user - answers are not synced
    // DO NOT remove until properly debugged
    if (this.countryCode === CountryCodes.DE) {
      migrateResponsesDE(this.responses);
    }
  };

  createResponse = (node: TreeNode, answer: Answer) => ({
    [node.id]: {
      // need to clone answer object, so mobx is working correctly for nested objects
      answer: JSON.parse(JSON.stringify(answer)),
      answerID: node.id,
      questionID: node.questionId,
      skipped: false,
      version: this.version,
      year: `${this.year}`,
    },
  });

  trackQuestionAnswered = (
    node: TreeNode,
    answer: Answer,
    trackingParams: Record<string, any> = {},
  ) => {
    switch (this.countryCode) {
      case CountryCodes.IT:
        this.trackQuestionAnsweredIT(node, answer, trackingParams);
        break;
      case CountryCodes.DE:
        this.trackQuestionAnsweredDE(node, answer, trackingParams);
        break;
    }
  };

  trackQuestionAnsweredDE = (
    node: TreeNode,
    answer: Answer,
    trackingParams: Record<string, any> = {},
  ): void => {
    let parameters: any = {
      questionId: node.questionId,
      year: this.year,
      ...trackingParams,
    };

    if (this.isWhoAreYouQuestion(node) || questionToTrack.includes(node.questionId)) {
      parameters = { ...parameters, questionValue: answer };
    }

    const JAInfo = this.refs ? getJointAssessmentInfo(node, this.refs) : {};

    if (JAInfo.isPartner) {
      parameters = { ...parameters, partnerQuestion: true };
    }

    Analytics.log(AnalyticsEvent.questionAnswered, parameters);
  };

  trackQuestionAnsweredIT = (
    node: TreeNode,
    answer: Answer,
    trackingParams: Record<string, any> = {},
  ) => {
    let parameters: any = {
      questionId: node.questionId,
      year: this.year,
      ...trackingParams,
    };

    if (questionToTrack.includes(node.questionId)) {
      parameters = { ...parameters, questionValue: answer };
    }

    Analytics.log(AnalyticsEvent.questionAnswered, parameters);
  };

  skipQuestion = (node: TreeNode) => {
    const skipResponses = {
      [node.id]: {
        answer: null,
        answerID: node.id,
        questionID: node.questionId,
        skipped: true,
        version: this.version,
        year: `${this.year}`,
      },
    };
    const newResponses = { ...this.responses, ...skipResponses };
    this.responses = newResponses;
    this.runCleanRebuild();
    return syncUploadAnswersForSelectedCountry(skipResponses);
  };

  filterResponses = (responses: Responses) => {
    // only choose responses relevant to this year
    let filtered = _.pickBy(
      responses,
      (response: UserResponse) => Number.parseInt(response.year, 10) === this.year,
    );

    // remap keys to force keys to the ID
    filtered = _.mapKeys(filtered, (response: UserResponse) => response.answerID);

    return filtered;
  };

  addJAIndexes = (responses: Responses) =>
    _.reduce(
      responses,
      (acc: any, response) => {
        if (!this.refs) {
          return acc;
        }

        const id = response.answerID;
        const node = this.refs[response.questionID];

        if (!node) {
          return acc;
        }

        const JAInfo = getJointAssessmentInfo(node, this.refs);

        if (!JAInfo.isInPersonLoop) {
          acc[id] = { ...response };
          return acc;
        }

        if (!isIndexed(id)) {
          const newId = appendIndex(id, 0);
          acc[newId] = { ...response, answerID: newId };
          return acc;
        }

        const index = indexFromId(id);
        const idWithoutIndex = unindexed(id);
        let newId = appendIndex(idWithoutIndex, 0);

        if (index != null) {
          newId = appendIndex(newId, index[0]);
        }

        acc[newId] = { ...response, answerID: newId };
        return acc;
      },
      {},
    );

  @action
  migratePreJAResponses = (responses: Responses) => {
    // We have to run rebuild here because this.addJAIndexes() uses refs
    this.runCleanRebuild();
    this.responses = this.addJAIndexes(responses);
    this.setIsMigrated(true);
  };

  @action
  setIsMigrated = (value: boolean) => {
    this.isMigrated = value;
  };

  answerTypeForQuestion = (question: TreeNode): TypeProbably | undefined => {
    if (question.responseType == null) {
      return undefined;
    }

    return AnswerTypeInstance.getAnswerTypeByName(question.responseType);
  };

  findInvalidResponses = (questions: Nodes, responses: Responses): Responses =>
    _.pickBy(responses, (response) => {
      if (response.skipped === true && response.answer == null) {
        return false;
      }

      const question = questions[response.questionID];

      if (question == null) {
        return false;
      }

      const answerType = this.answerTypeForQuestion(question);

      if (!answerType) {
        return false;
      }

      return !answerType.validate(response.answer);
    });

  @action
  deleteInvalidResponses = () => {
    const questions = _.pickBy(this.nodes, isQuestion);

    const questionIds = Object.keys(questions);

    const responsesForQuestionsThatExist = _.pickBy(this.responsesJS, (response) =>
      questionIds.includes(response.questionID),
    );

    const invalid = this.findInvalidResponses(questions, responsesForQuestionsThatExist);

    Object.keys(invalid).forEach(this.deleteResponse);
  };

  @action
  deleteResponse = (responseId: Id) => {
    this.responses = _.omit(this.responses, responseId);
    const store = getStore();
    if (store) store.dispatch(syncActions.deleteSyncMeta(this.year, responseId, this.countryCode));
  };

  @action
  deleteResponses = (responseIds: Id[]) => {
    responseIds.forEach((id) => {
      this.deleteResponse(id);
    });
    this.cache.clear();
  };

  @action
  deleteAllResponses = () => {
    this.responses = {};
    const store = getStore();
    if (store) store.dispatch(syncActions.deleteSyncMetaByYear(this.year, this.countryCode));
  };

  @action
  deleteResponsesForQuestions = (questionIds: Array<string>) => {
    const existingResponses = Object.keys(this.responses);
    const responsesToRemove = questionIds.flatMap((questionId) =>
      existingResponses.filter((answerId) => answerId.indexOf(questionId) === 0),
    );
    this.deleteResponses(responsesToRemove);
  };

  getQuestionIdsForCategory = (categoryId: string): Set<string | any> => {
    const { tree: root } = this;
    const questionIds = new Set();
    const category = root.children
      ? root.children.find(({ id }: any) => id === categoryId)
      : undefined;

    if (!category) {
      return questionIds;
    }

    // Depth-first search traversal
    const dfs = (node: TreeNode) => {
      const { children, id } = node;
      questionIds.add(id);

      if (children && children.length > 0) {
        children.forEach((child: any) => dfs(child));
      }
    };

    dfs(category);
    return questionIds;
  };

  @action
  deleteResponsesByCategory = (categoryId: string) => {
    const responseIdsList = Object.keys(this.responses);
    const questionIdsSet = this.getQuestionIdsForCategory(categoryId);
    const responsesToBeDeleted = responseIdsList.filter((id) => questionIdsSet.has(id));
    return this.deleteResponses(responsesToBeDeleted);
  };

  // @ts-ignore
  @computed
  get answers(): Answers {
    return _.mapValues(this.responses, (response) => response.answer);
  }

  // @ts-ignore
  @computed
  get activeAnswers(): Answers {
    return _.mapValues(this.activeResponseJS, (response) => response.answer);
  }

  // @ts-ignore
  @computed
  get list(): TreeNode[] {
    return tree2list(this.tree);
  }

  // End-Of-Life getter to easily inject mock data for unit testing
  // eslint-disable-next-line class-methods-use-this
  get initCategories(): Category[] {
    return [];
  }

  @computed
  get categories(): Category[] {
    const rootCategories =
      this.tree && this.tree.children
        ? this.tree.children.filter((node: any) => node?.children?.length)
        : [];
    const productionCategories = this.initCategories;
    rootCategories.forEach(({ id }) => {
      const subtree = findSubtree(this.tree, id);
      const refs = this.refs;

      if (!refs) {
        return;
      }

      if (subtree == null) {
        throw new Error(`Can't find subtree for ${id}`);
      }

      const quizmaster = new Quizmaster(
        subtree,
        this.responses,
        refs,
        false,
        /* editing */
        this.cache,
        this.year,
        false, // We don't care about translations
      );
      const categoryName = CategoryName[id];
      let iconName = 'placeholder';

      if (categoryName != null && quizmaster.isNotStarted()) {
        iconName = `category-icons.icon-category-${CategoryName[id]}-inactive`;
      } else if (categoryName != null) {
        iconName = `category-icons.icon-category-${CategoryName[id]}`;
      }

      if (quizmaster.isNotStarted() && quizmaster.hasAllResponses()) {
        return; // Don't include "empty" categories
      }

      productionCategories.push({
        id,
        translationKey: translationKeys(id).short,
        iconName,
        isComplete: quizmaster.hasAllAnswers(),
        progress: quizmaster.progress(),
      });
    });
    return productionCategories;
  }

  /**
   * Returns a list of categories that are partially complete.
   * i.e They are started, but not complete yet.
   */
  get partiallyCompleteCategories(): Category[] {
    const { categories } = this;
    return categories.filter(({ progress }) => progress > 0 && progress < 1);
  }

  // Returns the underlying plain old JavaScript
  // representation of the responses for use
  // with external modules
  // @ts-ignore
  @computed
  get responsesJS(): Responses {
    return toJS(this.responses);
  }

  // @ts-ignore
  @computed
  get activeResponseJS(): Responses {
    return toJS(this.quizmaster(this.root, false, this.year).activeResponses());
  }

  // @ts-ignore
  @computed
  get responsesWithActive(): Responses {
    // @ts-ignore
    return toJS(this.quizmaster(this.root, false, this.year).responsesWithActive());
  }

  // @ts-ignore
  @computed
  get linearFlowProgress(): number {
    const withoutIntro = withoutIntroduction(this.categories);
    const progress = withoutIntro.reduce((prev, current) => prev + current.progress, 0);
    const currentProgress = progress / withoutIntro.length;
    return _.isNaN(currentProgress) ? 0 : currentProgress;
  }

  // @ts-ignore
  @computed
  get currentProgress(): number {
    const responses = Object.keys(this.responses).length;
    return (responses - this.skippedQuestions.length) / responses;
  }

  nextCategory = ({ currentCategoryId, naturalFlow = true }: NextCategoryArgs = {}):
    | Category
    | null
    | undefined => {
    const modifiedCategories = this.categoriesWithoutIntro;

    const currentCategory = _.findLastIndex(modifiedCategories, (category: Category) => {
      if (currentCategoryId) {
        return category.id === currentCategoryId;
      }

      return category.progress === 1;
    });

    const beforeCategories = modifiedCategories.slice(0, currentCategory);
    const afterCategories = modifiedCategories.slice(currentCategory + 1);
    const groupA = naturalFlow ? afterCategories : beforeCategories;
    const groupB = naturalFlow ? beforeCategories : afterCategories;
    return unfinishedCategory(groupA) || unfinishedCategory(groupB) || null;
  };

  getCategory = (categoryId: string): Category | null | undefined => {
    const modifiedCategories = this.categoriesWithoutIntro;
    return modifiedCategories.find((category) => category.id === categoryId);
  };

  getQuestionById = (answerId: string, categoryId: string): TreeNode => {
    const questions = this.quizmaster(categoryId, true, this.year).questions;
    return questions.find((question) => question.id === answerId) as TreeNode;
  };

  categoriesState = (): CategoriesState => {
    const modifiedCategories = this.categoriesWithoutIntro;
    return {
      hasTodos: modifiedCategories.some(
        (category) => category.progress >= 1 && !category.isComplete,
      ),
      isComplete: modifiedCategories.every(
        (category) => category.isComplete && category.progress >= 1,
      ),
      finishedWithTodos:
        modifiedCategories.every((category) => category.progress >= 1) &&
        modifiedCategories.some((category) => !category.isComplete),
    };
  };

  get categoriesWithoutIntro(): Category[] {
    const { categories } = this;
    return withoutIntroduction(categories);
  }

  // @ts-ignore
  @computed
  get fastLaneCriteria(): FastLaneCriteria {
    const { status, responses } = this;

    try {
      const hasChildren = responses[DO_YOU_HAVE_CHILDREN.answerID]?.answer;
      const isEmployee =
        `${responses[DID_YOU_GET_ANY_OTHER_INCOME.answerID]?.answer}` === ANSWER_VALUE_EMPLOYEE;
      const isSingle =
        `${responses[WHAT_IS_YOUR_MARITAL_STATUS.answerID]?.answer}` === ANSWER_VALUE_SINGLE;

      return {
        isSingle,
        isEmployee,
        hasNoChildren: !hasChildren,
        hasUncompletedCategories: status === 'started',
      };
    } catch (e) {
      /*
       * TODO @vitormalencar for some reason the mobx store changes for a second
       * it tries to get the WHAT_IS_YOUR_MARITAL_STATUS questionId [1479986195761] and fails
       * this try catch solves this issue for now but we need to investigate more what happens under the hood here
       */
      return {
        isSingle: false,
        isEmployee: false,
        hasNoChildren: false,
        hasUncompletedCategories: false,
      };
    }
  }

  quizmaster(root: Id, editing: boolean, year: number): Quizmaster {
    const subtree = findSubtree(this.tree, root);
    const refs = this.refs || {};

    if (subtree == null) {
      throw new Error(`Can't find subtree for ${root}`);
    }

    return new Quizmaster(
      subtree,
      this.responsesJS,
      refs,
      editing,
      this.cache, // FIXME: where can we share a cache?!
      year,
    );
  }

  // @ts-ignore
  @computed
  get hasResponses(): boolean {
    return this.responses && _.size(this.responses) > 0;
  }

  findCategoryNode = (ancestors: Ancestors = [], refs: Refs): TreeNode | null | undefined => {
    const nodeId = ancestors.find((id: any) => CategoryName[id] != null);

    if (nodeId != null) {
      return refs[nodeId];
    }

    return null;
  };

  // @ts-ignore
  @computed
  get skippedQuestions(): TreeNode[] {
    const skipped = _.filter(this.activeResponseJS, {
      skipped: true,
    });

    return _.chain(skipped)
      .sortBy((response) => _.findIndex(this.list, (node) => node.id === response.answerID))
      .map((response) => this.refs?.[response.answerID])
      .filter(
        (question) => this.findCategoryNode(question?.ancestors || [], this.refs || {}) != null,
      )
      .compact()
      .value();
  }

  // @ts-ignore
  @computed
  get hasSkippedQuestions(): boolean {
    return !_.isEmpty(this.skippedQuestions);
  }

  // @ts-ignore
  @computed
  get skippedQuestionsES(): TreeNode[] {
    const skipped = _.filter(this.activeResponseJS, {
      skipped: true,
    });

    return _.chain(skipped)
      .sortBy((response) => _.findIndex(this.list, (node) => node.id === response.answerID))
      .map((response) => this.refs?.[response.answerID])
      .compact()
      .value();
  }

  @computed
  get hasSkippedQuestionsES(): boolean {
    return !_.isEmpty(this.skippedQuestionsES);
  }

  // @ts-ignore
  @computed
  get isWhoAreYouCompleted(): boolean {
    return this.quizmaster(Constants.WhoAreYouCategoryNodeId, false, this.year).hasAllAnswers();
  }

  isWhoAreYouQuestion = (node: TreeNode) =>
    _.get(node, 'ancestors', []).some((id: any) => id === CategoryNodeId.WhoAreYou);

  // @ts-ignore
  @computed
  get completedCategories(): Category[] {
    const withoutIntro = withoutIntroduction(this.categories);
    return withoutIntro.filter((category) => category.progress >= 1);
  }

  // @ts-ignore
  @computed
  get status(): Status | null | undefined {
    if (!this.refs) this.refs = {};

    const quizmaster = new Quizmaster(
      treeWithoutIntroduction(this.tree),
      this.responsesJS,
      this.refs,
      false,
      this.cache,
      this.year,
      false, // We don't care about translations
    );
    let result: Status | null | undefined;

    if (!quizmaster.isNotStarted()) {
      result = 'started';
      if (quizmaster.hasAllAnswers()) result = 'completed';
      if (quizmaster.hasAllResponses()) result = 'completed';

      // TODO ITA-938 this check is to prevent calling isSupported before its updated for IT
      if (!isSupported(this.activeResponseJS, this.countryCode)) {
        result = 'unsupported';
      }
    }

    // ITA-938 consider submissions in status for DE only
    if (this.countryCode === CountryCodes.DE) {
      const store = getStore();
      if (store && submissionSelectors.getActiveSubmissionByYear(store.getState(), this.year)) {
        result = 'submitted';
      }
    }
    return result;
  }

  // @ts-ignore
  @computed
  get multipleWarnings(): MultipleQuestionWarnings {
    return JSON.parse(this.multipleQuestionWarnings);
  }

  hasMultipleWarningsByCategory(categoryId: string): boolean {
    const warnings = this.multipleWarnings;
    return warnings[categoryId] && Object.keys(warnings[categoryId]).length > 0;
  }

  multipleWarningByQuestion(categoryId: string, questionId: string): PlausibilityErrors[] {
    const warnings = JSON.parse(this.multipleQuestionWarnings);

    if (warnings[categoryId] && warnings[categoryId][questionId]?.length) {
      return warnings[categoryId][questionId];
    }

    return [];
  }

  getWarningByCategory(root: Id): MultipleQuestionWarningsByCategory {
    const groups = Summaries.createGroups(this, root);
    const plausibilityErrors: any = {};
    groups.forEach((currentGroup) => {
      const items = currentGroup.items || [];
      const itemsWithAnswers = items.filter(
        (item) => item.answer != null && item.question.response != null,
      );
      let silentQuestionsWithRules: TreeNode[] = [];

      const getChildrenWithRules = (children: TreeNode[]) => {
        children.forEach((child) => {
          if (!child.response && !child.children?.length && child.rules) {
            silentQuestionsWithRules = silentQuestionsWithRules.concat([child]);
          }

          if (child.children?.length) {
            getChildrenWithRules(child.children);
          }
        });
      };

      if (currentGroup.node && currentGroup.node.children) {
        getChildrenWithRules(currentGroup.node.children);
      }

      if (silentQuestionsWithRules.length) {
        silentQuestionsWithRules.forEach((answer) => {
          const node = this.refs?.[answer.id];
          const warningsResult = checkPlausibility(
            node,
            this.refs,
            this.responses,
            this.cache,
            this.year,
          );
          const warnings = warningsResult.filter((warning) => warning.type === 'multiple');

          if (warnings.length) {
            const inputsAffected: string[] = [];

            const getInputDependencies = (nodeId: string, input: InputProperties) => {
              const hasChild = input && input.reference && input.reference !== nodeId;

              // loop finishes, last value
              if (input?.value === nodeId) {
                inputsAffected.push(nodeId);
              } else if (hasChild && input?.value) {
                const childNode = this.refs?.[input.reference];

                if (childNode && childNode.inputs) {
                  const childInput = childNode.inputs[input.value];
                  getInputDependencies(childNode.id, childInput.properties);
                } // TODO: remove this condition when fixing indexes for this specific question, for now, just add it with the condition fpr the current person loop
                else if (input.reference.startsWith('4086649f74cf6b13c0e8[')) {
                  const currentIndex = nodeId.substring(
                    nodeId.indexOf('[') + 1,
                    nodeId.indexOf(']'),
                  );
                  inputsAffected.push(`4086649f74cf6b13c0e8[${currentIndex}]`);
                }
              } else if (input?.values?.length > 0) {
                // has multiple values contributing to that input
                input.values.forEach((singleInput: any) => {
                  const currentNode = this.refs?.[nodeId];

                  if (currentNode?.inputs?.[singleInput]) {
                    const siblingInput =
                      currentNode.inputs[singleInput] && currentNode.inputs[singleInput].properties;
                    getInputDependencies(nodeId, siblingInput);
                  }
                });
              } else if (input?.value) {
                // has only one value contributing to that input
                const currentNode = this.refs?.[nodeId];

                if (currentNode && currentNode.inputs && currentNode.inputs[input.value]) {
                  const siblingInput =
                    currentNode.inputs[input.value] && currentNode.inputs[input.value].properties;
                  getInputDependencies(nodeId, siblingInput);
                }
              }
            };

            if (node && node.outputs && node.outputs.length) {
              const [output] = node.outputs;
              if (output && node.inputs) {
                const input = node.inputs[output].properties;

                getInputDependencies(answer.id, input);
              }
            }
            // Get the answers related to the inputs affected
            let answersAffected: any = [];
            inputsAffected.forEach((inputAffected: any) => {
              const answers = itemsWithAnswers.filter((c) =>
                c.id.startsWith(inputAffected.slice(0, -1)),
              );
              answersAffected = answersAffected.concat(answers);
            });
            answersAffected.forEach((answerAffected: any) => {
              // creates a plausibility error per input involved in error.
              const { id } = answerAffected;

              if (
                !this.ignoredMultipleQuestionWarnings[root] ||
                this.ignoredMultipleQuestionWarnings[root].indexOf(id) < 0
              ) {
                plausibilityErrors[id] = warnings;
              }
            });
          }
        });
      }
    });

    // If there are no errors remove ignored ones for that category
    if (!Object.keys(plausibilityErrors).length) {
      delete this.ignoredMultipleQuestionWarnings[root];
    }

    return plausibilityErrors;
  }

  @action
  deletePersistedWarnings = () => {
    this.multipleQuestionWarnings = JSON.stringify({});
  };

  @action
  initialMultipleQuestionWarnings = async () => {
    const ignoredWarnings = await getMultipleWarnings({
      countryCode: this.countryCode,
      year: this.year,
    });
    this.ignoredMultipleQuestionWarnings = ignoredWarnings;
    const multipleWarnings: any = {};
    this.categories.forEach((category) => {
      if (category.isComplete) {
        const warnings = this.getWarningByCategory(category.id);

        if (Object.keys(warnings).length) {
          const store = getStore();
          if (store) store.dispatch(plausibilityChecksActions.addWarning(this.year, category.id));
          multipleWarnings[category.id] = warnings;
        }
      }
    });
    this.multipleQuestionWarnings = JSON.stringify(multipleWarnings);
  };

  @action
  updateMultipleQuestionWarningsByCategory = (root: Id): MultipleQuestionWarningsByCategory => {
    const plausibilityErrors = this.getWarningByCategory(root);
    // update multipleQuestionWarnings values
    const warnings = JSON.parse(this.multipleQuestionWarnings);

    if (Object.keys(plausibilityErrors).length) {
      warnings[root] = plausibilityErrors;
    } else {
      delete warnings[root];
    }

    this.multipleQuestionWarnings = JSON.stringify(warnings);
    return plausibilityErrors;
  };

  @action
  addIgnoredMultipleWarning = async (categoryId: Id, questionId: Id) => {
    if (this.ignoredMultipleQuestionWarnings[categoryId]) {
      this.ignoredMultipleQuestionWarnings[categoryId].push(questionId);
    } else {
      this.ignoredMultipleQuestionWarnings[categoryId] = [questionId];
    }

    await updateMultipleWarnings({
      countryCode: this.countryCode,
      year: this.year,
      updatedValue: this.ignoredMultipleQuestionWarnings,
    });
  };
}

export default QuestionStore;
