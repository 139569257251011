import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'native-base';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { Account } from 'src/screens/account-menu/account';
import { FullscreenLoader } from 'src/components/loading';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';
import { Container } from 'src/taxfix-components/src';
import { ITALY_TABS_HEIGHT, ItalyTabs } from 'src/routes/italy-navigation/italy-tabs';
import { TabNames } from 'src/common/constants-it';

import { AccountContext } from './account-context';

export const AccountContainer = (): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const userEmail = useSelector(userAuthSelectors.getEmailAddress);
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const showSideTabs = useShowSideTabs();

  const { height } = useWindowDimensions();

  return (
    <Container h={height - ITALY_TABS_HEIGHT} overflow="hidden">
      <ScrollView paddingTop="24px">
        <AccountContext.Provider
          value={{
            isLoading,
            setLoading,
          }}
        >
          {isLoading ? (
            <FullscreenLoader />
          ) : (
            <Account country={selectedCountry} email={userEmail || ''} />
          )}
        </AccountContext.Provider>
      </ScrollView>
      {!showSideTabs && <ItalyTabs highlighedTab={TabNames.Account} />}
    </Container>
  );
};
