import { TreeNode } from '@taxfix/quizmaster/dist/types';

export type KeysAndValues = { [key: string]: string | number };

const translationValuesWithDefaults = (
  keysAndValues: KeysAndValues | null | undefined,
  sources: {
    question?: TreeNode;
    year?: number;
  },
): KeysAndValues => {
  const out = keysAndValues || {};

  // attach 'count' as the loop context
  // indexes are 0-based, but we'll +1 to be more human friendly
  if (sources.question != null && sources.question.loopContext != null) {
    const count = sources.question.loopContext.indexes[sources.question.loopContext.loopId] + 1;
    out.count = `${count}`;
    out.count1 = `${count + 1}`;
  }

  if (sources.year != null) {
    const { year } = sources;
    out.previous_tax_year = `${year - 1}`;
    out.tax_year = `${year}`;
    out.next_tax_year = `${year + 1}`;
  }

  return out;
};

export default translationValuesWithDefaults;
