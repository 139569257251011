import { DebugMenuOptions, MenuOption } from './types';

export function buildResponsesOption(props: DebugMenuOptions): MenuOption {
  const { navigationActions } = props;

  const handleDebugResponses = () => {
    navigationActions.toDebugResponses('screen');
  };

  return {
    text: 'Show Responses(Legacy)',
    onPress: handleDebugResponses,
    showArrow: true,
  };
}
