import React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IntlShape, injectIntl } from 'react-intl';

import { QuizmasterLight, withQuizmasterLight } from 'src/utils/with-quizmaster-light';

import {
  DonationFormData,
  DonationFormFieldName,
  SelectFieldData,
  ItalyDonationComponent,
} from '../components/italy-donation';
import { Error as FormError } from '../../components/form-field-extended';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { actions as OverlayActions } from '../../stores/modules/overlay';
import {
  DonationOption,
  ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
  ITALY_DONATION_PREFERENCE_STATE_VALUE,
  ITALY_DONATION_NON_PROFIT_WITHOUT_CODE,
  ITALY_DONATION_OPTIONS_OTTO_X_MILLE,
  ITALY_DONATION_OPTIONS_OTTO_X_MILLE_AREA,
  ITALY_DONATION_OPTIONS_CINQUE_X_MILLE,
  ITALY_DONATION_OPTIONS_DUE_X_MILLE,
  ITALY_DONATION_FIELDS_OTTO_X_MILLE,
  ITALY_DONATION_FIELD_OTTO_X_MILLE_STATO_CODICE,
  ITALY_DONATION_FIELDS_OTTO_X_MILLE_STATE_AREA,
  ITALY_DONATION_FIELDS_CINQUE_X_MILLE_FIRMA,
  ITALY_DONATION_FIELDS_CINQUE_X_MILLE_CODICE_FISCALE,
  ITALY_DONATION_FIELD_DUE_X_MILLE_NONE,
  ITALY_DONATION_FIELD_DUE_X_MILLE_FIRMA,
  ITALY_DONATION_FIELD_DUE_X_MILLE_CODICE,
  flagsQuestionIds,
  donationQuestionsIds,
  questionFlowCategories,
} from '../../common/constants-it';

export type DonationPreferences = {
  labels: {
    churchAndState: string;
    churchAndStateArea?: string;
    nonProfit: string;
    nonProfitCode?: string;
    politicalParty: string;
  };
  fields: Record<string, string | number>;
};

type State = {
  isLoading: boolean;
  donationFormData: DonationFormData;
  nonProfitCodeInputError: FormError | null | undefined;
};

type Props = WithNavigation & {
  overlayActions: typeof OverlayActions;
  intl: IntlShape;
  quizmasterLight: QuizmasterLight;
  backNavigationText?: string;
};

const getDonationChoices = (intl: IntlShape, donationOptions: DonationOption[]) =>
  donationOptions.map(({ value, translationKey }) => ({
    value,
    label: intl.formatMessage({
      id: translationKey,
    }),
    type: 'Choice',
  }));

const mapDispatchToProps = (dispatch: Dispatch) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

class ItalyDonation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { intl, quizmasterLight } = props;
    const churchAndStateValue = quizmasterLight[donationQuestionsIds.churchAndState].answer;
    const churchAndStateAreaValue = quizmasterLight[donationQuestionsIds.churchAndStateArea].answer;
    const nonProfitOrgValue = quizmasterLight[donationQuestionsIds.nonProfit].answer;
    const nonProfitCodeValue = quizmasterLight[donationQuestionsIds.nonProfitCode].answer;
    const politicalPartyValue = quizmasterLight[donationQuestionsIds.politicalParty].answer;

    const donationFormData = {
      churchAndState: {
        selectedValue: churchAndStateValue ?? ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
        options: getDonationChoices(intl, ITALY_DONATION_OPTIONS_OTTO_X_MILLE),
      },
      churchAndStateArea: {
        selectedValue: churchAndStateAreaValue ?? ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
        options: getDonationChoices(intl, ITALY_DONATION_OPTIONS_OTTO_X_MILLE_AREA),
      },
      nonProfit: {
        selectedValue: nonProfitOrgValue ?? ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
        options: getDonationChoices(intl, ITALY_DONATION_OPTIONS_CINQUE_X_MILLE),
      },
      nonProfitCode: {
        inputValue: nonProfitCodeValue ?? '',
      },
      politicalParty: {
        selectedValue: politicalPartyValue ?? ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
        options: getDonationChoices(intl, ITALY_DONATION_OPTIONS_DUE_X_MILLE),
      },
    };
    this.state = {
      isLoading: false,
      donationFormData,
      nonProfitCodeInputError: null,
    };
  }

  getDefaultDonationOptionLabel = () =>
    this.props.intl.formatMessage({
      id: 'it.digital-caf.donation-option.none',
    });

  shouldDisplayStateAreaField = () =>
    ITALY_DONATION_PREFERENCE_STATE_VALUE ===
    this.state.donationFormData.churchAndState.selectedValue;

  shouldDisplayNonProfitCodeField = () =>
    !ITALY_DONATION_NON_PROFIT_WITHOUT_CODE.includes(
      this.state.donationFormData.nonProfit.selectedValue,
    );

  isNonProfitCodeInputValueValid = () => {
    const { inputValue: nonProfitCodeInputValue } = this.state.donationFormData.nonProfitCode;
    return nonProfitCodeInputValue === '' || nonProfitCodeInputValue.length === 11;
  };

  getSelectedOptionValue = (fieldData: SelectFieldData): string => {
    return fieldData.selectedValue || ITALY_DONATION_PREFERENCE_DEFAULT_VALUE;
  };

  getSelectedOptionLabel = (fieldData: SelectFieldData): string => {
    const selectedOption = fieldData.options.find(
      (option) => option.value === fieldData.selectedValue,
    );
    return selectedOption?.label || this.getDefaultDonationOptionLabel();
  };

  getDonationPreferenceLabels = () => {
    const { donationFormData } = this.state;
    const churchAndState = this.getSelectedOptionLabel(donationFormData.churchAndState);
    const churchAndStateArea = this.shouldDisplayStateAreaField()
      ? this.getSelectedOptionLabel(donationFormData.churchAndStateArea)
      : undefined;
    const nonProfit = this.getSelectedOptionLabel(donationFormData.nonProfit);
    const nonProfitCode = this.shouldDisplayNonProfitCodeField()
      ? donationFormData.nonProfitCode.inputValue
      : undefined;
    const politicalParty = this.getSelectedOptionLabel(donationFormData.politicalParty);

    return {
      churchAndState,
      churchAndStateArea,
      nonProfit,
      nonProfitCode,
      politicalParty,
    };
  };

  getDonationPreference730FormFields = () => {
    const { donationFormData } = this.state;
    const formFields: any = {};
    // 8x1000
    const churchAndState = this.getSelectedOptionValue(donationFormData.churchAndState);
    formFields[ITALY_DONATION_FIELDS_OTTO_X_MILLE[churchAndState]] = 1;

    if (this.shouldDisplayStateAreaField()) {
      const churchAndStateArea = this.getSelectedOptionValue(donationFormData.churchAndStateArea);
      formFields[ITALY_DONATION_FIELD_OTTO_X_MILLE_STATO_CODICE] =
        ITALY_DONATION_FIELDS_OTTO_X_MILLE_STATE_AREA[churchAndStateArea];
    }

    // 5x1000
    const nonProfit = this.getSelectedOptionValue(donationFormData.nonProfit);
    formFields[ITALY_DONATION_FIELDS_CINQUE_X_MILLE_FIRMA[nonProfit]] = 1;

    if (this.shouldDisplayNonProfitCodeField()) {
      formFields[ITALY_DONATION_FIELDS_CINQUE_X_MILLE_CODICE_FISCALE[nonProfit]] =
        donationFormData.nonProfitCode.inputValue;
    }

    // 2x1000
    const politicalParty = this.getSelectedOptionValue(donationFormData.politicalParty);

    if (politicalParty === ITALY_DONATION_PREFERENCE_DEFAULT_VALUE) {
      formFields[ITALY_DONATION_FIELD_DUE_X_MILLE_NONE] = 1;
    } else {
      formFields[ITALY_DONATION_FIELD_DUE_X_MILLE_FIRMA] = 1;
      formFields[ITALY_DONATION_FIELD_DUE_X_MILLE_CODICE] = politicalParty;
    }

    return formFields;
  };

  getDonationPreferences = (): DonationPreferences => {
    return {
      labels: this.getDonationPreferenceLabels(),
      fields: this.getDonationPreference730FormFields(),
    };
  };

  validateNonProfitCode = () => {
    if (this.isNonProfitCodeInputValueValid()) {
      this.setState({
        nonProfitCodeInputError: null,
      });
    } else {
      this.setState({
        nonProfitCodeInputError: {
          level: 'Error',
          key: 'donation.non-profit-code-select.error',
        },
      });
    }
  };

  handleNonProfitCodeInputFocus = () =>
    this.setState({
      nonProfitCodeInputError: null,
    });

  handleNonProfitCodeInputBlur = () => this.validateNonProfitCode();

  updateDonationFromData = (
    donationFormData: DonationFormData,
    field: DonationFormFieldName,
    value: string,
    textLabel?: string,
  ) => {
    const updatedDonationFormData: any = { ...donationFormData };

    this.props.quizmasterLight[donationQuestionsIds[field]].saveAnswer(value);

    if (field === 'nonProfitCode') {
      updatedDonationFormData[field] = {
        inputValue: value,
      };
    } else {
      updatedDonationFormData[field] = {
        ...donationFormData[field],
        selectedValue: value,
        textLabel,
      };

      if (
        field === 'churchAndState' &&
        value !== ITALY_DONATION_PREFERENCE_STATE_VALUE &&
        donationFormData.churchAndStateArea.selectedValue !==
          ITALY_DONATION_PREFERENCE_DEFAULT_VALUE
      ) {
        updatedDonationFormData.churchAndStateArea = {
          ...donationFormData.churchAndStateArea,
          selectedValue: ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
        };
      }
    }

    return updatedDonationFormData;
  };

  handleDonationFieldChange =
    (field: DonationFormFieldName) => (value: string, textLabel?: string) => {
      this.setState(({ donationFormData }) => {
        const updatedDonationFormData = this.updateDonationFromData(
          donationFormData,
          field,
          value,
          textLabel,
        );
        return {
          donationFormData: updatedDonationFormData,
        };
      });
    };

  handleBackButtonPress = () => {
    const { navigationActions } = this.props;
    navigationActions.back();
  };

  handleNext = () => {
    this.props.navigationActions.toQuizMaster('screen', {
      root: questionFlowCategories.taxSubstitute,
      backNavigationText: 'it.web.navigation.back-to-donation',
      onNext: () =>
        this.props.navigationActions.toItalySubmission('screen', {
          donationPreferences: this.getDonationPreferences(),
        }),
    });
  };

  render() {
    const { isLoading, donationFormData, nonProfitCodeInputError } = this.state;

    return (
      <ItalyDonationComponent
        onDonationFieldChange={this.handleDonationFieldChange}
        shouldDisplayStateAreaField={this.shouldDisplayStateAreaField()}
        shouldDisplayNonProfitCodeField={this.shouldDisplayNonProfitCodeField()}
        onNonProfitCodeInputFocus={this.handleNonProfitCodeInputFocus}
        onNonProfitCodeInputBlur={this.handleNonProfitCodeInputBlur}
        nonProfitCodeInputError={nonProfitCodeInputError || undefined}
        onBackButtonPress={this.handleBackButtonPress}
        onNext={this.handleNext}
        isLoading={isLoading}
        donationFormData={donationFormData}
        backNavigationText={this.props.backNavigationText}
      />
    );
  }
}

export const ItalyDonationContainer = compose<any>(
  connect(null, mapDispatchToProps),
  injectIntl,
  withNavigation,
  withQuizmasterLight([
    flagsQuestionIds.prefillMandateSigned,
    donationQuestionsIds.churchAndState,
    donationQuestionsIds.nonProfit,
    donationQuestionsIds.nonProfitCode,
    donationQuestionsIds.churchAndStateArea,
    donationQuestionsIds.politicalParty,
  ]),
)(ItalyDonation);
