import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Box, Icon, BodyTitleText, BodyPrimaryText, SecondaryLinkText } from '../core';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    height: 24,
  },
});
type Props = {
  label?: {
    key: string;
    values?: Record<string, any>;
  };
  textLabel?: string;
  color: string;
  onPress?: () => any;
  disabled?: boolean;
  dropdownType?: 'icon' | 'text';
  spacedSelector?: boolean;
  testId?: any;
  style?: any;
};

const InputSelect = ({
  disabled = false,
  label,
  color,
  onPress,
  textLabel,
  dropdownType = 'icon',
  spacedSelector = true,
}: Props) => {
  const SelectedItemComponent = dropdownType === 'icon' ? BodyTitleText : BodyPrimaryText;
  return (
    <TouchableOpacity
      disabled={disabled}
      activeOpacity={disabled ? 1 : 0.7}
      onPress={!disabled ? onPress : () => {}}
      style={styles.container}
    >
      <Box alignVertically="center" direction="row">
        <Box flex={spacedSelector} right={0.5}>
          <SelectedItemComponent
            id={label && label.key}
            values={label && label.values}
            ellipsizeMode="tail"
            numberOfLines={1}
            style={{
              color,
            }}
          >
            {textLabel}
          </SelectedItemComponent>
        </Box>
        {!disabled &&
          (dropdownType === 'icon' ? (
            <Icon name="downArrow" style={styles.icon} tintColor={color} />
          ) : (
            <SecondaryLinkText style={styles.text} id="answers.select.edit" />
          ))}
      </Box>
    </TouchableOpacity>
  );
};

export default InputSelect;
