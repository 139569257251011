export enum Routes {
  Account = 'Account',
  AddressSearch = 'AddressSearch',
  AnswersAddressDetails = 'AnswersAddressDetails',
  AnswersAddressSearch = 'AnswersAddressSearch',
  AnswersDuration = 'AnswersDuration',
  AnswersPayrollPayslip = 'AnswersPayrollPayslip',
  AnswersPayrollUploadingCamera = 'AnswersPayrollUploadingCamera',
  Article = 'Article',

  CalculationBreakdownLegacy = 'CalculationBreakdownLegacy',
  Camera = 'Camera',
  ChangeEmail = 'ChangeEmail',
  CloseAccount = 'CloseAccount',

  DifmLoadingScreen = 'LoadingScreen',
  DownloadScreen = 'DownloadScreen',

  EndCard = 'EndCard',

  FirebaseRemoteConfig = 'FirebaseRemoteConfig',
  ForgotPassword = 'ForgotPassword',
  FranceCalculationBreakdown = 'FranceCalculationBreakdown',

  Home = 'Home',

  Identification = 'Identification',
  IdentificationLegacy = 'IdentificationLegacy',
  IdentificationRejectionDetails = 'IdentificationRejectionDetails',
  ImagePreview = 'ImagePreview',

  LegalChange = 'LegalChange',
  LinkWebView = 'LinkWebView',
  Lock = 'Lock',
  Login = 'Login',

  MandatoryFilerReason = 'MandatoryFilerReason',
  MonthlyExpenses = 'MonthlyExpenses',

  Notifications = 'Notifications',

  OnboardingChatEdit = 'OnboardingChatEdit',
  OnboardingInfoModal = 'OnboardingInfoModal',
  OnboardingSplash = 'OnboardingSplash',
  OpenPDF = 'OpenPDF',
  OtcValidation = 'OtcValidation',

  Payment = 'Payment',
  Playground = 'Playground',
  PrivacyPolicy = 'PrivacyPolicy',

  Questions = 'Questions',

  ReviewInstructions = 'ReviewInstructions',

  ScanbotMock = 'ScanbotMock',
  SearchableSingleSelect = 'SearchableSingleSelect',

  Status = 'Status',
  Signup = 'Signup',
  SignupWithEmail = 'SignupWithEmail',

  TaxYearsAuthenticatedOverview = 'TaxYearsAuthenticatedOverview',
  TaxYearsUnauthenticatedOverview = 'TaxYearsUnauthenticatedOverview',
  TaxYearsUnauthenticatedOverviewExperiment = 'TaxYearsUnauthenticatedOverviewExperiment',
  Terms = 'Terms',
  Test1 = 'Test1',
  Test3 = 'Test3',
  Test2 = 'Test2',
  Test4 = 'Test4',

  UserLegalCheck = 'UserLegalCheck',

  VoucherInput = 'VoucherInput',

  WhoAreYou = 'WhoAreYou',
  WrongAddressDetails = 'WrongAddressDetails',
}
