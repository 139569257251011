import { FlatList, Pressable, useTheme } from 'native-base';
import React, { useState } from 'react';

import { Container, Icon, Text, icons } from 'src/taxfix-components/src';

import { HeaderProps, ListProps, AccordionProps } from './types';

const Header: React.FC<HeaderProps> = ({ title, value, open }) => {
  const { colors } = useTheme();
  return (
    <Container flexDir="row" justifyContent="space-between" paddingLeft={20} paddingRight={20}>
      <Container flexDirection="row" flex={1} alignItems="center">
        <Text variant="bodyMBook" alignSelf="flex-start" value={title} />
        <Icon
          color={colors.brand.brandGreen}
          marginLeft={1}
          paddingTop="0px"
          path={open ? icons.chevronUp : icons.chevronDown}
          size="16px"
        />
      </Container>
      <Text variant="bodyMBook" alignSelf="flex-start" value={value} />
    </Container>
  );
};

const List: React.FC<ListProps> = ({ item: { description, comment, value } }) => {
  const { colors } = useTheme();
  return (
    <Container
      flexDir="row"
      justifyContent="space-between"
      paddingTop={16}
      paddingBottom={16}
      paddingLeft={30}
      paddingRight={20}
      background={colors.overlay(colors.brand.brandGreen, 10)}
    >
      <Text
        variant="bodyMBook"
        color="greytones.textLight"
        alignSelf="flex-start"
        flex={2}
        value={description}
      />
      <Container flexDir="row">
        {comment && <Text variant="bodySBook" color="greytones.textLight" mr={1} value={comment} />}
        <Text variant="bodyMBook" color="greytones.textLight" value={value} />
      </Container>
    </Container>
  );
};

export const Accordion: React.FC<AccordionProps> = ({ title, value, data, isOpen }) => {
  const [open, setOpen] = useState(isOpen ?? false);
  const onPress = () => setOpen(!open);

  return (
    <Container>
      <Pressable onPress={onPress}>
        <Header title={title} value={value} open={open} />
      </Pressable>
      {open && (
        <FlatList
          data={data}
          renderItem={(props) => <List {...props} />}
          style={{ paddingTop: 10 }}
        />
      )}
    </Container>
  );
};
