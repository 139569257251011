/* global __DEV__ */
import { Platform } from 'react-native';
import { Dispatch } from 'redux';
import { createFilter } from 'redux-persist-transform-filter';
import { LocalPackage } from 'react-native-code-push';

const codePush = Platform.OS !== 'web' && require('react-native-code-push');

export type State = {
  status: number;
  customKey: string | null | undefined;
  willInstallUpdate: boolean;
  skipInstallUpdate: boolean;
  metadata: LocalPackage | null;
};
type RootState = {
  codepush: State;
};
export const initial: State = {
  status: codePush ? codePush.SyncStatus.UP_TO_DATE : 0,
  metadata: null,
  customKey: null,
  willInstallUpdate: false,
  skipInstallUpdate: false,
};
export const SET_STATUS = 'codepush/SET_STATUS';
export const SET_METADATA = 'codepush/SET_METADATA';
export const SET_CUSTOM_KEY = 'codepush/SET_CUSTOM_KEY';
export const SET_WILL_INSTALL_UPDATE = 'codepush/SET_WILL_INSTALL_UPDATE';
export const SET_SKIP_INSTALL_UPDATE = 'codepush/SET_SKIP_INSTALL_UPDATE';

type SetStatusAction = {
  type: typeof SET_STATUS;
  payload: {
    status: number;
  };
};
type SetMetadataAction = {
  type: typeof SET_METADATA;
  payload: {
    metadata: LocalPackage;
  };
};
type SetCustomKeyAction = {
  type: typeof SET_CUSTOM_KEY;
  payload: {
    customKey: string;
  };
};
type SetWillInstallUpdateAction = {
  type: typeof SET_WILL_INSTALL_UPDATE;
  payload: {
    willInstallUpdate: boolean;
  };
};
type SetSkipInstallUpdateAction = {
  type: typeof SET_SKIP_INSTALL_UPDATE;
  payload: {
    skipInstallUpdate: boolean;
  };
};
const syncStatusDescriptor = codePush
  ? {
      [codePush.SyncStatus.UP_TO_DATE]:
        '[UP_TO_DATE] The app is fully up-to-date with the configured deployment.',
      [codePush.SyncStatus.UPDATE_INSTALLED]:
        '[UPDATE_INSTALLED] An available update has been installed and will be run either immediately after the syncStatusChangedCallback function returns or the next time the app resumes/restarts, depending on the InstallMode specified in SyncOptions.',
      [codePush.SyncStatus.UPDATE_IGNORED]:
        '[UPDATE_IGNORED] The app has an optional update, which the end user chose to ignore. (This is only applicable when the updateDialog is used)',
      [codePush.SyncStatus.UNKNOWN_ERROR]:
        '[UNKNOWN_ERROR] The sync operation encountered an unknown error.',
      [codePush.SyncStatus.SYNC_IN_PROGRESS]:
        '[SYNC_IN_PROGRESS] There is an ongoing sync operation running which prevents the current call from being executed.',
      [codePush.SyncStatus.CHECKING_FOR_UPDATE]:
        '[CHECKING_FOR_UPDATE] The CodePush server is being queried for an update.',
      [codePush.SyncStatus.AWAITING_USER_ACTION]:
        '[AWAITING_USER_ACTION] An update is available, and a confirmation dialog was shown to the end user. (This is only applicable when the updateDialog is used)',
      [codePush.SyncStatus.DOWNLOADING_PACKAGE]:
        '[DOWNLOADING_PACKAGE] An available update is being downloaded from the CodePush server.',
      [codePush.SyncStatus.INSTALLING_UPDATE]:
        '[INSTALLING_UPDATE] An available update was downloaded and is about to be installed.',
    }
  : {};

export const codePushInstallationOptions = Object.freeze(
  codePush
    ? {
        /**
         * Installs the update, but restarts the app the next time the user resumes
         * it from the background, so we don't disrupt their current session, but they get the update
         * in front of them sooner then having to wait for the next natural restart.
         */
        installMode: codePush.InstallMode.ON_NEXT_RESUME,
        mandatoryInstallMode: codePush.InstallMode.ON_NEXT_RESUME,
      }
    : {},
);

type Action =
  | SetStatusAction
  | SetCustomKeyAction
  | SetMetadataAction
  | SetWillInstallUpdateAction
  | SetSkipInstallUpdateAction;

// selectors
const getCustomKey = (state: RootState): string | null | undefined => state.codepush.customKey;
const getStatus = (state: RootState): number | null | undefined => state.codepush.status;
const getVersion = (state: RootState): string | undefined => state.codepush.metadata?.label;
const willInstallUpdate = (state: RootState): boolean => state.codepush.willInstallUpdate;
const skipInstallUpdate = (state: RootState): boolean => state.codepush.skipInstallUpdate;

export const selectors = {
  getCustomKey,
  getStatus,
  getVersion,
  willInstallUpdate,
  skipInstallUpdate,
};

// actions
const codepushStatus = (status: number): SetStatusAction => ({
  type: SET_STATUS,
  payload: {
    status,
  },
});

const customKeyAction = (customKey: string): SetCustomKeyAction => ({
  type: SET_CUSTOM_KEY,
  payload: {
    customKey,
  },
});

const setWillInstallUpdate = (willInstallUpdate: boolean): SetWillInstallUpdateAction => ({
  type: SET_WILL_INSTALL_UPDATE,
  payload: {
    willInstallUpdate,
  },
});

const setSkipInstallUpdate = (skipInstallUpdate: boolean): SetSkipInstallUpdateAction => ({
  type: SET_SKIP_INSTALL_UPDATE,
  payload: {
    skipInstallUpdate,
  },
});

const sync = () => async (dispatch: Dispatch<any>, getState: () => any) => {
  // overwrite deployment key if any
  const options: any = {
    ...codePushInstallationOptions,
  };

  if (getCustomKey(getState())) {
    options.deploymentKey = getCustomKey(getState());
  }

  // fully silent update and synch, without
  // interrupting the user
  if (codePush)
    codePush.sync(options, (status: any) => {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`[CodePush]${syncStatusDescriptor[status]}`);
      }

      dispatch(codepushStatus(status));
    });
};

const setMetadata = () => async (dispatch: Dispatch<any>) => {
  codePush.getUpdateMetadata().then((metadata: LocalPackage) => {
    if (metadata) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`[CodePush] Obtained update metadata: ${JSON.stringify(metadata, null, 2)}`);
      }
      dispatch({ type: SET_METADATA, payload: { metadata } });
    }
  });
};

const saveCustomKey = (customKey: string) => async (dispatch: Dispatch<any>) =>
  dispatch(customKeyAction(customKey));

export const actions = {
  sync,
  saveCustomKey,
  setWillInstallUpdate,
  setSkipInstallUpdate,
  setMetadata,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case SET_STATUS: {
      const { status } = action.payload;
      return { ...state, status };
    }

    case SET_CUSTOM_KEY: {
      const { customKey } = action.payload;
      return { ...state, customKey };
    }

    case SET_WILL_INSTALL_UPDATE: {
      const { willInstallUpdate } = action.payload;
      return { ...state, willInstallUpdate };
    }

    case SET_SKIP_INSTALL_UPDATE: {
      const { skipInstallUpdate } = action.payload;
      return { ...state, skipInstallUpdate };
    }

    case SET_METADATA: {
      const { metadata } = action.payload;
      return { ...state, metadata };
    }

    default:
      return state;
  }
};
// Persist custom key so we don't overwrite js bundle with default key changes
export const persistFilter = createFilter('codepush', ['customKey']);
