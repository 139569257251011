import * as React from 'react';
import { Dimensions, StyleSheet } from 'react-native';

import { Box, theme, useLargeScreenQuery } from '../../core';
import {
  Option,
  SelectedOption,
  SingleSelect,
} from '../../../moved-modules/single-select/single-select';
import Separator from '../../separator';
import Overlay from '../../overlay';
import IconButton from '../../core/Button/IconButton';
import { isMobileWeb } from '../../../utils/platform';
import { WEB_SECTION_NARROW_WIDTH } from '../../../utils/constants';

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  outerContainer: {
    maxHeight: '100%',
    overflow: 'hidden',
  },
  outerContainerLarge: {
    width: WEB_SECTION_NARROW_WIDTH,
    alignSelf: 'center',
  },
  innerContainer: {
    backgroundColor: theme.color.lightFill,
    marginTop: 56,
  },
  innerContainerFullScreen: {
    marginTop: 0,
  },
  innerContainerLarge: {
    marginTop: 0,
    height: windowHeight - 96,
  },
  innerContainerMobileWeb: {
    marginTop: 0,
    maxheight: windowHeight - 96,
  },
});
type Props = {
  options: Option[];
  onSelect: (id: string) => any;
  onClose: () => any;
  color?: string;
  isSelectFullScreenHeight?: boolean;
  selectedValue?: SelectedOption;
};

const InputSelectOverlay = ({
  options,
  onSelect,
  onClose: onCloseComplete,
  color,
  isSelectFullScreenHeight,
  selectedValue,
}: Props) => {
  const isLargeScreen = useLargeScreenQuery();
  return (
    <Overlay
      bottomFiller
      bottomFillerSeparator
      onCloseComplete={onCloseComplete}
      backgroundColor={theme.color.overlay}
    >
      {({ onClose }: any) => (
        <Box
          style={[styles.outerContainer, isLargeScreen ? styles.outerContainerLarge : undefined]}
        >
          {isLargeScreen && (
            <Box flex direction="row" alignHorizontally="end" alignVertically="center">
              <Box top={4} bottom={4}>
                <IconButton iconKey="cross" onPress={onClose} tintColor={theme.color.lightFill} />
              </Box>
            </Box>
          )}
          <Box
            style={
              isLargeScreen
                ? [styles.innerContainer, styles.innerContainerLarge]
                : isMobileWeb
                ? [styles.innerContainer, styles.innerContainerMobileWeb]
                : [
                    styles.innerContainer,
                    isSelectFullScreenHeight ? styles.innerContainerFullScreen : undefined,
                  ]
            }
          >
            <Separator />
            <SingleSelect
              options={options}
              optionType="text"
              onAnswer={(id: string) => {
                onSelect(id);
                onClose();
              }}
              color={color}
              isFullHeight={isSelectFullScreenHeight}
              selectedValue={selectedValue}
            />
          </Box>
        </Box>
      )}
    </Overlay>
  );
};

export default InputSelectOverlay;
