import * as React from 'react';

import { ToastBannerConfig } from 'src/screens/containers/italy-onboarding';

import { LogoutSuccess } from '../../../screens/components/logout-success';
import navigate, { Action, Navigation, NavigationParams } from '../../navigate';
import { AnalyticsEvent } from '../../../biz-logic/analytics';

type ActionParams = {
  toastConfig: ToastBannerConfig;
};

type Props = Navigation<
  'ProfileOccupation',
  {
    ProfileOccupation: NavigationParams<ActionParams>;
  }
>;

export const toLogoutSuccess: Action<ActionParams> = navigate('LogoutSuccess', {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: 'LogoutSuccess',
  },
});
export const LogoutSuccessScreen = ({ route }: Props) => {
  const { toastConfig } = route.params;
  return <LogoutSuccess toastConfig={toastConfig} />;
};
