import _ from 'lodash';
import { Answer, Responses, UserResponse } from '@taxfix/quizmaster/dist/types';

import { WrappedAnswer, WrappedResponses, WrappedUserResponse } from './types';

const unwrapAnswer = (answerWithValue: WrappedAnswer): Answer => answerWithValue.value;

const unwrapResponse = (response: WrappedUserResponse): UserResponse => ({
  ...response,
  answer: unwrapAnswer(response.answer),
});

const unwrapResponses = (responses: WrappedResponses): Responses =>
  _.mapValues(responses, unwrapResponse);

const wrapAnswer = (answer: Answer): WrappedAnswer => ({
  value: answer,
});

export { unwrapAnswer, unwrapResponse, unwrapResponses, wrapAnswer };
