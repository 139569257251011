import * as React from 'react';

import { Box, theme } from './core';

type Props = {
  errorType: 'Error' | 'Warning' | 'Success' | 'Custom';
  children: React.ReactNode;
  floating?: boolean;
  backgroundColor?: string;
};

function NotificationBanner({
  errorType,
  children,
  floating = false,
  backgroundColor: backgroundColorProp,
}: Props) {
  let backgroundColor;

  switch (errorType) {
    case 'Error':
      backgroundColor = theme.color.errorBackground;
      break;

    case 'Warning':
      backgroundColor = theme.color.warningBackground;
      break;

    case 'Custom':
      backgroundColor = backgroundColorProp || theme.color.primary;
      break;

    default:
      backgroundColor = theme.color.primary;
      break;
  }

  const floatingStyle = floating
    ? {
        position: 'absolute',
        top: 0,
        zIndex: 1000,
        width: '100%',
      }
    : {};
  return (
    <Box
      style={[
        {
          backgroundColor,
        },
        floatingStyle,
      ]}
      left={2}
      right={2}
      top={2}
      bottom={2}
      alignVertically="center"
      testId="notification-banner.message"
    >
      {children}
    </Box>
  );
}

export default NotificationBanner;
