import * as React from 'react';
import { Keyboard, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Option } from '../../moved-modules/single-select/single-select';
import Select from '../../components/input/select';
import { actions as OverlayActions } from '../../stores/modules/overlay';
import { theme } from '../../components/core';
import { testID } from '../../common/testID';

type Props = {
  overlayActions: typeof OverlayActions;
  value: any;
  options: Option[];
  onChange: (value: any) => any;
  selectedItemColor?: string;
  itemsColor?: string;
  children?: React.ReactNode;
  isSelectFullScreenHeight?: boolean;
  testId?: string;
  disabled?: boolean;
  customDropdown?: React.ReactNode;
  dropdownType?: 'icon' | 'text';
  spacedSelector?: boolean;
  shouldFocus?: boolean;
  textLabel?: string;
};

const mapDispatchToProps = (dispatch: any) => ({
  overlayActions: bindActionCreators(OverlayActions, dispatch),
});

class SelectWithOverlay extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { shouldFocus } = this.props;

    if (prevProps.shouldFocus !== shouldFocus && shouldFocus) {
      this.handleSelectPress();
    }
  }

  getLabelProps = () => {
    const { options, value, textLabel } = this.props;
    if (textLabel)
      return {
        textLabel,
      };

    if (this.isOptionsFromTE()) {
      const selectedValue = options.find((option) => option.value === value);
      return {
        textLabel: selectedValue && selectedValue.label,
      };
    }

    const selectedValue = options.find((option) => option.id === value);
    const key =
      selectedValue && selectedValue.translationKey
        ? selectedValue.translationKey
        : 'default.field.select';
    const values = selectedValue && selectedValue.values ? selectedValue.values : {};
    return {
      label: {
        key,
        values,
      },
    };
  };

  handleSelectPress = () => {
    const { overlayActions, value, options, onChange, itemsColor, isSelectFullScreenHeight } =
      this.props;
    const selectedValue = options.find((option) => option.id === value || option.value === value);
    Keyboard.dismiss();
    overlayActions.show('SelectOverlay', {
      color: itemsColor,
      value,
      options,
      onSelect: onChange,
      isSelectFullScreenHeight,
      selectedValue,
    });
  };

  isOptionsFromTE = () =>
    this.props.options.length > 0 && this.props.options[0].label && this.props.options[0].value;

  render() {
    const {
      selectedItemColor = theme.color.primary,
      testId,
      dropdownType,
      disabled = false,
      spacedSelector,
    } = this.props;
    return (
      // react-native bug, so we need to set activeOpacity, onPress null
      <TouchableOpacity
        disabled={disabled}
        activeOpacity={disabled ? 1 : 0.7}
        onPress={!disabled ? this.handleSelectPress : () => {}}
        {...testID(testId)}
      >
        {this.props.children || (
          <Select
            disabled={disabled}
            onPress={this.handleSelectPress}
            color={disabled ? theme.color.disabledGray : selectedItemColor}
            dropdownType={dropdownType}
            spacedSelector={spacedSelector}
            {...this.getLabelProps()}
          />
        )}
      </TouchableOpacity>
    );
  }
}

const SelectWithOverlayContainer = connect(null, mapDispatchToProps)(SelectWithOverlay);

export default SelectWithOverlayContainer;
