import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Collapsable, Container, Text } from 'src/taxfix-components/src';

import { Image } from '../../general/Image';

import { Props } from './types';

/**
 * End-of-season card
 */

export const EoSCard: React.FC<Props> = ({
  collapsableTitle,
  cardTitle,
  ...otherProps
}): JSX.Element => {
  const titleVariant = useBreakpointValue({ base: 'titleSBold', md: 'titleMMedium' });

  return (
    <Collapsable
      title={collapsableTitle}
      py={[25, 25, 32]}
      maxW={580}
      bg="white"
      startOpen
      {...otherProps}
    >
      <Container width="100%" alignItems="center" mt={[36, 42, 75]}>
        <Image name="calendar30Sep" width={[110, 110, 170]} mb={[40, 40, 80]} />
        <Text
          value={cardTitle}
          lineHeight="xs"
          textAlign="center"
          variant={titleVariant}
          mb={[40, 58, 115]}
        />
      </Container>
    </Collapsable>
  );
};
