/* eslint-disable class-methods-use-this */
import { Platform } from 'react-native';
import Config from 'react-native-config';

import { User } from '../stores/modules/user-auth';

import { SentryErrorTracking } from './sentry/sentry-error-tracking';
import { SentryContext, SentryError, SentryTags } from './sentry/types';

const codePush = Platform.OS !== 'web' && require('react-native-code-push');

export type ErrorType = SentryError;
type TagsType = SentryTags;
export type ContextType = SentryContext;

export class ErrorTracking {
  previousGlobalTags?: Record<string, any>;

  constructor() {
    const environment = Config.SENTRY_ENVIRONMENT;
    const dsn = Config.SENTRY_DSN;
    SentryErrorTracking.init({
      dsn,
      environment,
    });
    if (codePush)
      codePush.getUpdateMetadata().then((update: any) => {
        if (update) {
          SentryErrorTracking.init({
            dsn,
            environment,
            release: `${update.appVersion}+codepush:${update.label}`,
          } as any);
        }
      });
  }

  identify(user: User | null | undefined) {
    SentryErrorTracking.setUser({
      id: user?.userId,
    });
  }

  updateGlobalTags(tags: Record<string, any>) {
    if (tags !== this.previousGlobalTags) {
      SentryErrorTracking.updateGlobalScope(tags);
      this.previousGlobalTags = tags;
    }
  }

  setTags(tags: Record<string, any>) {
    SentryErrorTracking.setScope(tags as any);
  }

  recordError(error: ErrorType, context: ContextType = {}, tags: TagsType = {}, severity: number) {
    SentryErrorTracking.recordError(error, context, tags, severity);
  }

  triggerCrash() {
    SentryErrorTracking.triggerCrash();
  }
}
