import React from 'react';

import { DigicafOccupationUnsupportedOverlay } from '../screens/components/italy-digicaf-occupation-unsupported-overlay';

import { ContactSupportContainer } from './contact-support-container';

type Props = {
  onClose: () => void;
  taxYear?: number;
};

const DigicafOccupationUnsupportedOverlayContainer = ({ onClose, taxYear }: Props): JSX.Element => (
  <ContactSupportContainer>
    {(props: any) => (
      <DigicafOccupationUnsupportedOverlay {...props} onClose={onClose} taxYear={taxYear} />
    )}
  </ContactSupportContainer>
);

export { DigicafOccupationUnsupportedOverlayContainer };
