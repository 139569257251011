import { reaction } from 'mobx';

import { IQuestionStore } from '../QuestionStore.types';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';

export const equals = (prev: any, current: any) => !(prev.length > 0 && current.length === 0);
export default (store: IQuestionStore) => {
  reaction(
    () => store.skippedQuestions,
    () => {
      Analytics.log(AnalyticsEvent.toDoComplete, {
        year: store.year,
      });
    },
    {
      compareStructural: true,
      fireImmediately: false,
      equals,
    } as any,
  );
};
