import { Alert, Linking, Platform } from 'react-native';
import { Documents as DocumentsSDK } from '@taxfix/taxfix-sdk';
import { CreateResponse } from '@taxfix/taxfix-sdk/dist/documents/create';
import { Documents } from '@taxfix/types';
import Config from 'react-native-config';
import Device from 'react-native-device-info';
import DocumentPicker from 'react-native-document-picker';
import {
  launchImageLibrary,
  launchCamera,
  MediaType,
  Asset,
  ImageLibraryOptions,
} from 'react-native-image-picker';
import { check, PERMISSIONS, RESULTS } from 'react-native-permissions';
import { IntlShape } from 'react-intl';

import { logger } from 'src/taxfix-business-logic/utils/logger';
import { CameraImageResult } from 'src/screens/android-camera-upload/camera';
import { isWeb } from 'src/utils/platform';

import { NavigationActions } from '../routes/config-util';
import { theme } from '../components/core';
import {
  MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION,
  MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
  MAX_FILE_SIZE_ERROR_CODE,
  FILE_TYPE_NOT_PDF_ERROR,
  PDF_PASSWORD_PROTECTED_ERROR,
} from '../utils/constants';
import { createFormData } from '../utils/form-data';

import { pickFiles, FileSizeError, FileTypeError, ProtectedPDFError } from './document-picker';

// DocumentType should be aligned with documentDB.
export type DocumentType = 'id' | 'previous-tax-declaration';
export type PhotoType =
  | 'prefill-id-front'
  | 'prefill-id-back'
  | 'prefill-id-passport'
  | 'prefill-730'
  | 'required-documents';
const FILE_ERRORS_MAP = new Map([
  [
    FileSizeError,
    {
      titleId: MAX_FILE_SIZE_ERROR_CODE,
      iconName: 'mono.icon-nav-arrow-down',
      translationValues: {
        size: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
      },
    },
  ],
  [
    FileTypeError,
    {
      titleId: FILE_TYPE_NOT_PDF_ERROR,
    },
  ],
  [
    ProtectedPDFError,
    {
      titleId: PDF_PASSWORD_PROTECTED_ERROR,
    },
  ],
]);
// 5 minutes
const TIMEOUT_DURATION = 5 * 60 * 1000;

const ImagePicker = (isWeb && require('expo-image-picker').default) || {};

export const getBannerOptionsForFileBrowserErrors = (error: any) =>
  FILE_ERRORS_MAP.get(error) ?? {
    titleId: 'error-screen.title',
  };
const openPhotoLibraryOnIOSSimulator =
  Platform.OS === 'ios' && Config.OPEN_PHOTO_LIBRARY_ON_IOS_SIMULATOR === 'true';

const uploadDocument = async (
  type: Documents.NonReceiptTypes,
  uri: string,
  accessToken: string,
  year: number,
  countryCode: string,
  onUploadProgress?: ((progressEvent: ProgressEvent) => void) | null,
  pdf?: boolean,
  skipProcessing?: boolean,
  uploadedFileName?: string,
): Promise<CreateResponse> => {
  const options: any = {};

  if (onUploadProgress) {
    options.onUploadProgress = onUploadProgress;
  }

  const uploadType = pdf ? 'application/pdf' : 'image/jpeg';
  const name = uploadedFileName ?? (pdf ? 'uploadFile.pdf' : 'imageFile.jpeg');
  const formFields = {
    upload: {
      uri,
      name,
      type: uploadType,
    },
    year: String(year),
    countryCode,
    type,
    platform: Platform.OS,
    platformVersion: Device.getVersion(),
    skipProcessing: skipProcessing ? 'true' : 'false',
  };
  const data = await createFormData(formFields);
  return DocumentsSDK.create(Config.API_BASE_URL, accessToken, data as any, {
    ...options,
    timeout: TIMEOUT_DURATION,
  });
};

const imagePickerDefaultOptions = {
  mediaType: 'photo' as MediaType,
  maxWidth: 1600,
  maxHeight: 1600,
};

export type OnPhotoTakenSuccess = (
  img: any,
  onProgress?: (event: ProgressEvent) => void,
  onError?: (error: any | null | undefined) => void,
) => void;

export type OnPhotoTakenError = (response: any) => void;

type ImagePickerResult = {
  cancelled: boolean;
  uri: string;
  width: number;
  height: number;
  type?: 'image' | 'video';
  exif?: Record<string, any>;
  base64?: string;
};

const calculateFileSize = (uri: string) => (uri.length - uri.indexOf(',') - 1) * 0.75;

const transformExpoResultToRNResponse = (result: ImagePickerResult) => {
  const { cancelled, uri } = result;

  if (cancelled) {
    return {
      didCancel: true,
    };
  }

  const fileSize = calculateFileSize(uri);

  return {
    fileSize,
    uri,
  };
};

const askForPhotoLibraryPermission = (intl: IntlShape) => {
  Alert.alert(
    intl.formatMessage({
      id: 'submission.identify.permission.denied.title',
    }),
    intl.formatMessage({
      id: 'submission.identify.permission.denied.text',
    }),
    [
      {
        text: intl.formatMessage({
          id: 'submission.identify.permission.denied.cancel',
        }),
        style: 'cancel',
      },
      {
        text: intl.formatMessage({
          id: 'submission.identify.permission.denied.settings',
        }),
        onPress: () => {
          Linking.openURL('app-settings://');
        },
      },
    ],
    {
      cancelable: false,
    },
  );
};

const openCamera = (
  navigationActions: NavigationActions,
  onPhotoTaken: (response: Asset) => void,
): void => {
  if (Platform.OS === 'web') {
    ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    }).then((result: ImagePickerResult) => {
      // @ts-ignore
      onPhotoTaken(transformExpoResultToRNResponse(result));
    });
  } else if (Platform.OS === 'android') {
    navigationActions.toCamera('modal', {
      onCapture: (img: CameraImageResult) => {
        navigationActions.back();
        try {
          const fileSize = calculateFileSize(img.uri);
          onPhotoTaken({ ...img, fileSize });
        } catch (e) {
          onPhotoTaken(img);
        }
      },
      onCancel: () => navigationActions.back(),
      asBase64: false,
    });
  } else {
    if (openPhotoLibraryOnIOSSimulator) {
      /* for simulator test */
      launchImageLibrary(imagePickerDefaultOptions, (imgPickerResponse) => {
        if (imgPickerResponse.assets?.[0]) {
          onPhotoTaken(imgPickerResponse.assets[0]);
        }
      });
    } else {
      launchCamera(imagePickerDefaultOptions, (imgPickerResponse) => {
        if (imgPickerResponse.assets?.[0]) {
          onPhotoTaken(imgPickerResponse.assets[0]);
        }
      });
    }
  }
};

const openGallery = async (
  intl: IntlShape,
  onPhotoTaken: (response: Asset | Asset[]) => void,
  hideOverlay?: (() => void) | null,
  allowMultipleImageUpload?: boolean,
): Promise<void> => {
  if (Platform.OS === 'web') {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    }).then((result: ImagePickerResult) => {
      if (hideOverlay) hideOverlay();
      onPhotoTaken(transformExpoResultToRNResponse(result as ImagePickerResult));
    });
  } else {
    const permission = Platform.OS === 'ios' ? await check(PERMISSIONS.IOS.PHOTO_LIBRARY) : '';
    const imagePickerOptions = allowMultipleImageUpload
      ? { ...imagePickerDefaultOptions, selectionLimit: 0 }
      : imagePickerDefaultOptions;

    launchImageLibrary(imagePickerOptions as ImageLibraryOptions, (imgPickerResponse) => {
      if (imgPickerResponse.errorCode) {
        if (permission === RESULTS.BLOCKED) askForPhotoLibraryPermission(intl);
        logger.warn('Error while launching image library', {
          error: imgPickerResponse.errorCode,
          permission,
        });
        Alert.alert(
          intl.formatMessage({
            id: 'error-screen.title',
          }),
          imgPickerResponse.errorCode,
        );
      }
      if (hideOverlay) hideOverlay();

      if (allowMultipleImageUpload && imgPickerResponse.assets?.length) {
        onPhotoTaken(imgPickerResponse.assets);
        return;
      }

      if (imgPickerResponse.assets?.[0]) {
        onPhotoTaken(imgPickerResponse.assets?.[0]);
      }
    });
  }
};

const openDocumentPicker = async (
  onDocumentSelection: (arg0: any) => void,
  showBanner: any,
  hideOverlay?: () => any,
): Promise<void> => {
  try {
    const filesData = await pickFiles(
      DocumentPicker.types.pdf as any,
      MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION,
    );
    if (filesData.length === 0) return;
    if (hideOverlay) hideOverlay();
    onDocumentSelection(filesData);
  } catch (err) {
    const bannerOptions = getBannerOptionsForFileBrowserErrors(err);
    showBanner({ ...bannerOptions, color: theme.color.errorTextLight });
  }
};

export { uploadDocument, openCamera, openGallery, openDocumentPicker };
