import * as React from 'react';
import { HeaderBackButton } from '@react-navigation/elements';

import { useNavigation } from '../../../hooks/navigation-hook';
import navigate, { Action, Navigation, NavigationParams } from '../../../routes/navigate';
import { useDefaultNavigationOptions } from '../../../routes/hooks/navigation-options-hook';
import { ScreenName } from '../../../types/screen-name';
import { AnalyticsEvent } from '../../../biz-logic/analytics';

import { ResultOptimizationContainer } from './result-optimization-container';
import { NavigationParams as ActionParams } from './result-optimization-types';

type Props = Navigation<
  ScreenName.ResultOptimization,
  {
    ResultOptimization: NavigationParams<ActionParams>;
  }
>;

export const toResultOptimizationScreen: Action = navigate(ScreenName.ResultOptimization, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ResultOptimization,
  },
});

export const ResultOptimization: React.FC<Props> = ({ route }: Props) => {
  const {
    optimizationVersion,
    optimizationResult,
    primaryButtonAction,
    filteredPrefillDocuments,
    resultScreenExperiment,
  } = route.params;
  const { navigation, navigationActions, safeResetNavigation, getNavigationActions } =
    useNavigation();

  const onBack = () => {
    if (navigation.canGoBack()) {
      navigationActions.back();
    } else {
      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    }
  };

  const headerLeftComponent = ({ ...props }) => {
    return <HeaderBackButton {...props} onPress={onBack} />;
  };

  useDefaultNavigationOptions(
    { id: 'it.result-optimization-screen.nav.title' },
    {
      headerLeft: headerLeftComponent,
    },
  );

  return (
    <ResultOptimizationContainer
      optimizationVersion={optimizationVersion}
      optimizationResult={optimizationResult}
      primaryButtonAction={primaryButtonAction}
      onBackButtonPress={onBack}
      filteredPrefillDocuments={filteredPrefillDocuments}
      resultScreenExperiment={resultScreenExperiment}
    />
  );
};
