import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Box, Button, Icon, theme, BodySecondaryText } from '../../../core';
import { APIErrors } from '../../../../services/utils';

type Props = {
  onPressManual?: () => any;
  error?: string;
  color?: string;
};
const styles = StyleSheet.create({
  textStyles: {
    textAlign: 'center',
  },
});

const getErrorKey = (errorCode?: string) => {
  let key: string;

  switch (errorCode) {
    case APIErrors.ZeroResults:
      key = 'answers.address.results.empty';
      break;

    default:
      key = 'answers.address.results.error';
      break;
  }

  return key;
};

const Error = ({ onPressManual, error, color = theme.color.primary }: Props) => (
  <Box flex alignVertically="center" alignHorizontally="center">
    <Icon name="mono.icon-nav-location-other" />
    <Box top={1} left={1} right={1}>
      <BodySecondaryText id={getErrorKey(error)} style={styles.textStyles} />
    </Box>
    {onPressManual && (
      <Box top={2} left={3} right={3}>
        <Button
          floating
          onPress={onPressManual}
          translationKey="answers.address.results.enter-manually"
          type="secondary"
          textColor={color}
          style={{
            borderColor: color,
          }}
          testId="answers.address.results.enter-manually"
        />
      </Box>
    )}
  </Box>
);

export default Error;
