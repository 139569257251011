// TODO: All the locale info is already in the bundle
//       Might want to have only the specific info loaded from disk
//       when needed
import { FormatNumberOptions } from 'react-intl';

import localeInfo from './data.json';
import { LocaleInfo } from './types';

type Symbols = {
  decimal: string;
  group: string;
};
type FormatNumber = (number: number, options?: FormatNumberOptions) => string;

const getNumberExtractionResults = (formatNumber: FormatNumber, locale: string) => {
  let formattedNumber = formatNumber(1111.11);
  let symbolRegex = /1(.)111(.)11/;
  if (locale === 'es') {
    formattedNumber = new Intl.NumberFormat('es-ES').format(11111.11);
    symbolRegex = /11(.)111(,)11/;
  }
  return symbolRegex.exec(formattedNumber);
};

const extractNumberSymbols = (formatNumber: FormatNumber, locale: string): Symbols => {
  const result = getNumberExtractionResults(formatNumber, locale);
  if (!result) throw new Error('extractNumberSymbols expect parsable number');
  const [, group, decimal] = result;
  return {
    decimal,
    group,
  };
};

const findLocaleInfo = (locale: string): LocaleInfo => {
  if ((localeInfo as any)[locale] != null) {
    return (localeInfo as any)[locale];
  }

  // If the more specific form 'de-DE' doesn't yield
  // results then try the shorter form 'de'
  const firstPart = locale.split('-')[0];

  if ((localeInfo as any)[firstPart] != null) {
    return (localeInfo as any)[firstPart];
  }

  return (localeInfo as any).de;
};

const getLocaleInfo = (locale: string, formatNumber: FormatNumber): LocaleInfo => {
  const info = findLocaleInfo(locale);
  const symbols = extractNumberSymbols(formatNumber, locale);
  info.locale = locale;
  info.numbers.symbols.decimal = symbols.decimal;
  info.numbers.symbols.group = symbols.group;
  return info;
};

export default getLocaleInfo;
