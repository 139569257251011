import * as React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { useNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { UserLegalCheck } from './user-legal-check';

export type ActionParams = {
  onConfirm: () => Promise<void>;
};

type Props = Navigation<
  Routes.UserLegalCheck,
  { [Routes.UserLegalCheck]: NavigationParams<ActionParams> }
>;

const UserLegalCheckRoute = ({ route }: Props): JSX.Element => {
  const { onConfirm } = route.params;

  useNavigationOptions({
    header: () => null,
  });

  return <UserLegalCheck onConfirm={onConfirm} />;
};

export const { action: toUserLegalCheck, screen: userLegalCheckScreen } = getRoute<ActionParams>({
  routeName: Routes.UserLegalCheck,
  screenComponent: UserLegalCheckRoute,
});

export { UserLegalCheckRoute };
