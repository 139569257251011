import * as React from 'react';
import { connect } from 'react-redux';
import { IntlShape, injectIntl } from 'react-intl';
import { CountryCodes } from '@taxfix/types';

import { selectors as settingsSelectors } from '../stores/modules/settings';
import initial from '../stores/store/initial';
import { ContactSupportParams, getSupportEmail } from '../utils/contactSupport';

type OwnProps = {
  intl: IntlShape;
};

const mapStateToProps = (state: typeof initial, { intl }: OwnProps) => {
  const selectedCountry = settingsSelectors.selectedCountry(state);
  const supportEmail = getSupportEmail(selectedCountry);
  const emailErrorMessages = {
    errorTitle: intl.formatMessage({
      id: 'contact-support.email.error.title',
    }),
    errorMessage: intl.formatMessage(
      {
        id: 'contact-support.email.error.message',
      },
      {
        email: supportEmail,
      },
    ),
  };
  return {
    intl,
    supportEmail,
    defaultContactSupportMessages: {
      ...emailErrorMessages,
      address: supportEmail,
      subject: intl.formatMessage({
        id: 'contact-support.email.subject',
      }),
    },
  };
};

export type UtilProps = {
  intl: IntlShape;
  supportEmail: CountryCodes;
  defaultContactSupportMessages: ContactSupportParams;
};
export const withUtil = (WrappedComponent: any) => {
  class Enhancer extends React.PureComponent<any & UtilProps> {
    render() {
      const { intl, supportEmail, defaultContactSupportMessages, ...rest } = this.props;
      return (
        <WrappedComponent
          util={{
            intl,
            supportEmail,
            defaultContactSupportMessages,
          }}
          {...rest}
        />
      );
    }
  }

  return injectIntl(connect(mapStateToProps)(Enhancer));
};
