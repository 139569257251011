import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import Device from 'react-native-device-info';
import { Platform } from 'react-native';
import { CountryCodes, Platforms, SystemVersion } from '@taxfix/types';
import {
  Field,
  IT_Field,
  SubmissionWithId,
  States as SubmissionStates,
} from '@taxfix/submissions-types';

import { ErrorType, logger } from 'src/taxfix-business-logic/utils/logger';

import initial from '../stores/store/initial';
import { Product, Payment } from '../types/payment';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import {
  actions as SubmissionActions,
  selectors as submissionSelectors,
} from '../stores/modules/submission';
import {
  getProduct as getProductService,
  getAllSubmissionsForUserAndYear,
} from '../services/submissions';
import { extractPersonalDataFromItalianTaxId } from '../services/personal-data';
import {
  wayQuestionIds,
  ITALY_DONATION_FIELD_PREFIX,
  flagsQuestionIds,
} from '../common/constants-it';
import { DonationPreferences } from '../screens/containers/italy-donation';
import { logErrorMessages } from '../common/log-error-messages';

import { UseAnswers, withAnswersStore } from './with-answers-store';

type Props = UseAnswers & {
  year: number;
  countryCode: CountryCodes;
  payment: Payment;
  submissionActions: typeof SubmissionActions;
  accessToken: string;
  userId: number;
  donationPreferences?: DonationPreferences;
};
type State = {
  paymentId: number | null | undefined;
};

const mapStateToProps = (state: typeof initial) => {
  const countryCode = settingsSelectors.selectedCountry(state);
  return {
    year: settingsSelectors.selectedYear(state),
    countryCode,
    payment: submissionSelectors.getPayment(state),
    accessToken: userAuthSelectors.getAccessToken(state),
    userId: userAuthSelectors.getUserId(state),
    error: submissionSelectors.error(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submissionActions: bindActionCreators(SubmissionActions, dispatch),
});

export type WithSubmitItaly = {
  createSubmission: () => Promise<void>;
};
export const withSubmitItaly = (WrappedComponent: any) => {
  class WithSubmit extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        paymentId: this.props.payment?.id,
      };
    }

    fetchPaymentId = async () => {
      const { accessToken, year, countryCode, questionAndAnswers } = this.props;
      const productVariation = questionAndAnswers?.find(
        ({ questionID }) => questionID === flagsQuestionIds.productBundleSelection,
      )?.answer as string;

      const { paymentId }: Product = await getProductService(
        accessToken,
        year,
        countryCode,
        productVariation,
      );

      this.setState({
        paymentId,
      });
      return paymentId;
    };

    createDonationFormFieldReducer =
      (overrides: any) => (acc: Record<string, any>, dataField: Field, index: number) => {
        // eslint-disable-next-line camelcase
        const field = dataField as any as IT_Field;
        const fieldName = field.coordinate.id || '';
        let fieldValue = field.value;

        if (fieldName.startsWith(ITALY_DONATION_FIELD_PREFIX)) {
          const override = overrides[index] || {};

          if (!override.deleted) {
            if (override.override) {
              fieldValue = override.value;
            }

            return { ...acc, [fieldName]: fieldValue };
          }
        }

        return acc;
      };

    getDonationPreferencesFromSubmission = (submission: SubmissionWithId) => {
      const { data } = submission;
      const overrides = submission.overrides?.overrides || [];
      const formFields = data.reduce(this.createDonationFormFieldReducer(overrides), {});

      if (!submission.overrides?.additionalFields) {
        return formFields;
      }

      const additionalData = submission.overrides?.additionalFields || [];
      const additionalOverrides = submission.overrides?.additionalOverrides || [];
      return additionalData.reduce(
        this.createDonationFormFieldReducer(additionalOverrides),
        formFields,
      );
    };

    getDonationFields = async () => {
      const { donationPreferences, accessToken, year, countryCode } = this.props;

      if (donationPreferences) {
        return donationPreferences.fields;
      }

      const allSubmissions = await getAllSubmissionsForUserAndYear(
        accessToken,
        this.props.userId,
        year,
        countryCode,
        ['data', 'overrides'],
      );
      const latestDeletedSubmission = allSubmissions
        .filter((submission) => submission.state === SubmissionStates.Deleted)
        // @ts-ignore
        .sort((a, b) => new Date(b.created) - new Date(a.created))[0];
      // @ts-ignore
      return this.getDonationPreferencesFromSubmission(latestDeletedSubmission);
    };

    getAppData = async () => {
      // We need to force an update for the questionAndAnswers prop.
      this.forceUpdate();
      const { form730, questionAndAnswers, submissionActions } = this.props;
      const { '730/SoggettoFiscale/CodiceFiscale': taxId } = form730;
      const { gender, birthdateFormatted, birthplace, birthplaceProvince } =
        extractPersonalDataFromItalianTaxId(taxId);
      let donationPreferences;

      try {
        donationPreferences = await this.getDonationFields();
      } catch (err) {
        submissionActions.createError({ id: 'donation.submit.error' });
        logger.warn(err as ErrorType, {
          message: logErrorMessages.donationOptionsFetchError,
        });
        throw err;
      }

      return {
        form730: {
          '730/DatiAnagrafici/Sesso': gender,
          '730/DatiAnagrafici/DataNascita': birthdateFormatted,
          '730/DatiAnagrafici/ComuneNascita': birthplace,
          '730/DatiAnagrafici/ProvinciaNascita': birthplaceProvince,
          ...donationPreferences,
          ...form730,
        },
        questionAndAnswers,
      };
    };

    getNotes = () => {
      const { questionAndAnswers } = this.props;
      const phoneNumber =
        questionAndAnswers &&
        questionAndAnswers.find(({ questionID }) => questionID === wayQuestionIds.phoneNumber)
          ?.answer;
      if (!phoneNumber) return undefined;
      return `
        Phone Number: ${phoneNumber}

        Zipcode: <zipcode>
        Street: <street>
        Number: <number>
      `;
    };

    createSubmission = async () => {
      const { accessToken, userId, submissionActions } = this.props;
      let { paymentId } = this.state;

      if (!paymentId) {
        paymentId = await this.fetchPaymentId();
      }

      const appData = await this.getAppData();

      await submissionActions.createSubmission(accessToken, {
        userId,
        paymentId,
        systemVersion: SystemVersion.TaxEngine, // has to stay SystemVersion.TaxEngine otherwise the call fails
        appData,
        notes: this.getNotes(),
        metadata: {
          platform: Platform.OS as Platforms,
          platformVersion: Device.getVersion(),
        },
      });
      await submissionActions.fetchSubmissions(accessToken, userId);
      submissionActions.setHasResubmissionDraft(false);
    };

    render() {
      return <WrappedComponent createSubmission={this.createSubmission} {...this.props} />;
    }
  }

  return compose(connect(mapStateToProps, mapDispatchToProps), withAnswersStore)(WithSubmit);
};
