import * as React from 'react';

import { getRoute, Navigation, NavigationParams } from '../../routes/navigate';
import { Routes } from '../../routes/routes';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { NavigationParams as ActionParams } from './forgot-password.types';
import { ForgotPasswordContainer } from './forgot-password-container';

type Props = Navigation<
  Routes.ForgotPassword,
  {
    [Routes.ForgotPassword]: NavigationParams<ActionParams>;
  }
>;

const ForgotPasswordScreen: React.FC<Props> = ({ route }) => {
  const { email, showSidebar = false } = route.params;
  useDefaultNavigationOptions({ id: 'account.request-password-reset.modal.title' });

  return <ForgotPasswordContainer email={email} centered={!showSidebar} />;
};

export const { action: toForgotPassword, screen: forgotPasswordScreen } = getRoute<ActionParams>({
  routeName: Routes.ForgotPassword,
  screenComponent: ForgotPasswordScreen,
});

export default ForgotPasswordScreen;
