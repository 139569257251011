import { saveAnswers, getShapedAnswers } from '@taxfix/question-flow-sdk';
import Config from 'react-native-config';
import { Responses, UserResponse } from '@taxfix/quizmaster/dist/types';
import { CountryCodes } from '@taxfix/types';

import { logger } from 'src/taxfix-business-logic/utils/logger';

import { getQuestionStores } from '../stores-legacy/util';
import Analytics, { AnalyticsEvent } from '../biz-logic/analytics';
import { getStore } from '../stores/util';
import { actions as syncActions, selectors as syncSelectors } from '../stores/modules/sync-answers';
import { selectors as userAuthSelectors } from '../stores/modules/user-auth';
import {
  AnswerWithAnsweredAt,
  AnswersByCountryAndYear,
  RetrievedAnswer,
  getAnswersCount,
} from '../stores/utils/sync-answers';

const errorMessageNoToken = 'no-usertoken';

const sendAnalytics = (eventName: AnalyticsEvent, message = '', answercount: number) => {
  let result: string;
  if (message === 'success') result = 'success';
  else if (message.includes('timeout')) result = 'timeout';
  else if (message.includes(errorMessageNoToken)) result = 'error-no-usertoken';
  else result = 'servererror';
  Analytics.log(eventName, {
    result,
    answercount,
    year: null,
  });
};

const isAuthenticated = () => {
  const store = getStore();
  return store && userAuthSelectors.isAuthenticated(store.getState());
};

const getAccessToken = () => {
  const store = getStore();
  return (store && userAuthSelectors.getAccessToken(store.getState())) || '';
};

const uploadAnswersToSync = async (
  answers: AnswerWithAnsweredAt[],
  countryCode: CountryCodes,
): Promise<void> => {
  try {
    if (!isAuthenticated()) {
      throw new Error(`sync-save-${errorMessageNoToken}`);
    }

    if (answers.length !== 0) {
      await saveAnswers(Config.API_BASE_URL, getAccessToken(), answers, countryCode);
      sendAnalytics(AnalyticsEvent.answerSyncSave, 'success', answers.length);
    }
  } catch (e) {
    sendAnalytics(AnalyticsEvent.answerSyncSave, e.message, answers.length);
    throw e;
  }
};

const retrieveAnswersFromServer = async (): Promise<AnswersByCountryAndYear> => {
  try {
    if (!isAuthenticated()) {
      throw new Error(`sync-retrieve-${errorMessageNoToken}`);
    }

    const response: AnswersByCountryAndYear = (await getShapedAnswers(
      Config.API_BASE_URL,
      getAccessToken(),
      {},
    )) as AnswersByCountryAndYear;
    sendAnalytics(AnalyticsEvent.answerSyncRetrieve, 'success', getAnswersCount(response));
    return response;
  } catch (e) {
    sendAnalytics(AnalyticsEvent.answerSyncRetrieve, e.message, 0);
    throw e;
  }
};

const syncUploadAnswersForSelectedCountry = async (newAnswers: Responses = {}) => {
  const store = getStore();
  if (!store) return;
  const selectedCountry = CountryCodes.IT;
  const countryQuestionStores = getQuestionStores(selectedCountry);
  const syncMetaMap = syncSelectors.getSyncMetaMapByCountry(store.getState(), selectedCountry);
  const answersToUpload: AnswerWithAnsweredAt[] = [];
  Object.keys(syncMetaMap).forEach((id: string) => {
    const syncMeta = syncMetaMap[id];
    const [year, answerID] = id.split('.');
    const answer: UserResponse = countryQuestionStores[year].responsesJS[answerID];

    if (syncMeta.isSynced === false && answer != null) {
      answersToUpload.push({ ...answer, answeredAt: syncMeta.answeredAt });
    }
  });
  Object.values(newAnswers).forEach((answer: any) => {
    answersToUpload.push({ ...answer, answeredAt: new Date() });
  });

  try {
    await uploadAnswersToSync(answersToUpload, selectedCountry);
    store.dispatch(syncActions.updateSyncMap(answersToUpload, true, selectedCountry));
  } catch (e) {
    store.dispatch(syncActions.updateSyncMap(answersToUpload, false, selectedCountry));
    throw e;
  }
};

const syncRetrieveAnswers = async () => {
  const store = getStore();
  if (!store) return;
  const answersByCountry: AnswersByCountryAndYear = await retrieveAnswersFromServer();
  const countryCode = CountryCodes.IT;
  try {
    const countryAnswers = answersByCountry[countryCode];
    const countryQuestionStores = getQuestionStores(countryCode);
    Object.keys(countryAnswers).forEach((year) => {
      const yearAnswers = countryAnswers[year];
      const questionStore: any = countryQuestionStores && countryQuestionStores[year];

      if (questionStore != null) {
        const yearAnswersWithAnswerIdKeys: Record<string, RetrievedAnswer> = yearAnswers.reduce(
          (acc: any, answer: any) => ({ ...acc, [answer.answerID]: answer }),
          {},
        );
        questionStore.responses = { ...questionStore.responses, ...yearAnswersWithAnswerIdKeys };
        questionStore.needRebuild = true;
        questionStore.deleteInvalidResponses();
        questionStore.migrateResponses();
      }
    });
    store.dispatch(syncActions.updateSyncMap([], true, countryCode));
  } catch (err) {
    logger.error(err, {
      message: 'retrieving saved answers sync failed',
    });
  }
};

export { syncUploadAnswersForSelectedCountry, syncRetrieveAnswers };
