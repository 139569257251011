import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

import { testID } from '../../common/testID';
import { Box, Button, DisplayPrimaryText, ScrollView } from '../../components/core';
import FlatList from '../../components/flat-list';

type Props = {
  onImportResponses: (file: string) => void;
  onContinue: () => void;
  isLoading: boolean;
  isComplete: boolean;
  fileList: string[];
  importedCount: number;
  error: string | null | undefined;
  onDeleteFile: (file: string) => Promise<void>;
};
type State = {
  selectedFile: string;
};

class ImportResponses extends React.PureComponent<Props, State> {
  state = {
    selectedFile: '',
  };

  handleSelectFile = (file: string) => {
    this.setState({
      selectedFile: file,
    });
  };

  handleDeleteFile = (file: string) => {
    this.setState({
      selectedFile: '',
    });
    this.props.onDeleteFile(file);
  };

  render() {
    const { onImportResponses, onContinue, isLoading, isComplete, fileList, importedCount, error } =
      this.props;
    const { selectedFile } = this.state;
    const data = fileList
      .filter((file) => file.match(/.txfx/g))
      .map((file) => ({
        key: file,
        data: file,
      }));
    return (
      <Box flex={1} bottom={2} left={2} right={2}>
        <ScrollView>
          <FlatList
            data={data}
            renderItem={(item: any) => {
              const { data: file } = item.item;
              return (
                <Box
                  top={1}
                  bottom={1}
                  direction="row"
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <TouchableOpacity
                    onPress={() => this.handleSelectFile(file)}
                    {...testID('test.button.debug.importFile')}
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingBottom: 10,
                      alignSelf: 'center',
                    }}
                  >
                    <Text>{file}</Text>
                  </TouchableOpacity>
                  <Button onPress={() => this.handleDeleteFile(file)} fillColor="red">
                    <Text
                      style={{
                        color: 'white',
                      }}
                    >
                      DEL
                    </Text>
                  </Button>
                </Box>
              );
            }}
          />
        </ScrollView>
        {error && (
          <Box alignHorizontally="center">
            <DisplayPrimaryText>{error}</DisplayPrimaryText>
          </Box>
        )}
        {!isComplete && selectedFile !== '' && (
          <Box alignHorizontally="center" bottom={1}>
            <DisplayPrimaryText>Selected file: {selectedFile}</DisplayPrimaryText>
          </Box>
        )}
        {!isComplete && (
          <Button
            onPress={() => onImportResponses(selectedFile)}
            disabled={!selectedFile}
            loading={isLoading}
            testId="test.button.debug.importResponses"
          >
            <Text>Import Responses</Text>
          </Button>
        )}
        {isComplete && (
          <Box>
            <Box alignHorizontally="center" bottom={1}>
              <DisplayPrimaryText>{importedCount} Responses were imported!</DisplayPrimaryText>
            </Box>
            <Box>
              <Button onPress={onContinue}>
                <Text>Continue</Text>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export default ImportResponses;
