import React from 'react';
import { Svg, Defs, Rect, Mask, Circle } from 'react-native-svg';

export const highlightElement = {
  RADIUS: 40,
};
export type HighlightCoordinate = {
  x: number;
  y: number;
};
type OverlayHighlightDefaultProps = {
  backgroundColor?: string;
  coordinate: HighlightCoordinate;
  radius?: number;
};
export const OverlayHighlightDefault = ({
  coordinate,
  radius = highlightElement.RADIUS,
  backgroundColor,
}: OverlayHighlightDefaultProps) => (
  <Svg height="100%" width="100%">
    <Defs>
      {/* @ts-ignore */}
      <Mask id="mask">
        <Rect height="100%" width="100%" fill="#fff" />
        <Circle r={radius} cx={coordinate.x} cy={coordinate.y} fill="black" />
      </Mask>
    </Defs>
    <Rect height="100%" width="100%" fill={backgroundColor} mask="url(#mask)" fill-opacity="0" />
  </Svg>
);
