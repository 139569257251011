import { Store } from 'redux';

import initial from './store/initial';

let currentReduxStore: Store<any, any> | null | undefined = null;
export const setStore = (store: Store<any, any>) => {
  currentReduxStore = store;
};
export const getStore = () => currentReduxStore;
// set callback listening for state change
// https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
export const observeStore = <T>(
  select: (arg0: typeof initial) => T,
  onChange: (arg0: T) => void,
) => {
  let currentState: any;
  const store = getStore();
  if (!store) throw Error('redux store is not available');

  function handleChange() {
    const nextState = select(store?.getState());

    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};
