import * as React from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { Box, SmallSecondaryText, TextButton } from '../../../components/core';
import screenSize from '../../../utils/screenSize';
import { formatDate } from '../../../i18n';
import { isWeb } from '../../../utils/platform';

type OuterProps = {
  trackShowMore: () => void;
  consentReferenceDate: Date;
  taxYear: number;
};
type InnerProps = {
  intl: IntlShape;
};
type Props = OuterProps & InnerProps;
type State = {
  isShowingFullLegalText: boolean;
};

class ConsentTextComponent extends React.PureComponent<Props, State> {
  state = {
    isShowingFullLegalText: isWeb,
  };

  handleToggleFullText = () => {
    if (!this.state.isShowingFullLegalText) this.props.trackShowMore();
    this.setState((prevState) => ({
      isShowingFullLegalText: !prevState.isShowingFullLegalText,
    }));
  };

  render() {
    const { consentReferenceDate, taxYear } = this.props;
    return (
      <Box bottom={3}>
        <SmallSecondaryText
          id="consent.consent.text"
          values={{
            date: formatDate(this.props.intl, consentReferenceDate),
            taxSeason: taxYear + 1,
            taxYear,
          }}
          ellipsizeMode="tail"
          numberOfLines={
            this.state.isShowingFullLegalText ? undefined : screenSize.isSmallScreen() ? 4 : 8
          }
        />
        {!isWeb && (
          <TextButton
            size="small"
            translationKey={
              this.state.isShowingFullLegalText
                ? 'consent.consent.button.less'
                : 'consent.consent.button.more'
            }
            onPress={this.handleToggleFullText}
            align="left"
            style={{
              paddingLeft: 0,
            }}
          />
        )}
      </Box>
    );
  }
}

export const ConsentText: React.ComponentType<OuterProps> = injectIntl(ConsentTextComponent);
