import { Prefill as PrefillTypes } from '@taxfix/italy-types';

import { ProductBundleValues, flagsQuestionIds } from 'src/common/constants-it';

import { useQuizmasterLight } from '../_hooks';

const PrefillResultTaxScenario = PrefillTypes.PrefillResultScenarios;

export const useProductBundleRecommendation = (): ProductBundleValues => {
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.taxScenario]);
  const taxScenario = quizmasterLight[flagsQuestionIds.taxScenario].answer;

  switch (taxScenario) {
    case PrefillResultTaxScenario.PositiveNoOptimization:
    case PrefillResultTaxScenario.PositiveNoOptimizationMandatoryNoCedolare:
    case PrefillResultTaxScenario.ResultZeroNoOptimization:
      return ProductBundleValues.instant;
    default:
      return ProductBundleValues.guided;
  }
};
