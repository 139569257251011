import { $Values } from 'utility-types';
import * as React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import {
  DisplayTitleText,
  BodyPrimaryText,
  BodySecondaryText,
  SmallSecondaryText,
  Box,
  theme,
  Button,
  Icon,
  useLargeScreenQuery,
} from './core';
import Overlay from './overlay';
import { StyleProp } from './types';

const style = StyleSheet.create({
  pillContainer: {
    backgroundColor: theme.color.positiveBackground,
    borderRadius: 100,
  },
});
const ButtonType = Object.freeze({
  confirm: 'confirm',
  warning: 'warning',
  warningNoFill: 'warning-no-fill',
  secondary: 'secondary',
  secondaryWithBorder: 'secondary-with-border',
});

export type AlertOverlayButton = {
  text?: string;
  translationKey?: string;
  onPress?: () => void;
  disableCloseOnPress?: boolean;
  type: $Values<typeof ButtonType>;
  style?: StyleProp;
};

export type AlertOverlayProps = {
  topIcon?: {
    name: string;
    style?: StyleProp;
  };
  titleTranslationKey?: string;
  titleTranslationText?: string;
  subTitlePillTranslationKey?: string;
  leadTranslationKey?: string;
  leadTranslationText?: string;
  subLeadTranslationKey?: string;
  translationValues?: Record<string, any>;
  buttons: AlertOverlayButton[];
  onClose: () => any;
  appearFromTop?: boolean;
};
const secondaryProps = {
  fillColor: theme.color.lightFill,
  textColor: theme.color.primary,
};
const buttonStyleProps: Record<$Values<typeof ButtonType>, Record<string, any>> = {
  [ButtonType.confirm]: {
    fillColor: theme.color.primary,
    textColor: theme.color.lightText,
  },
  [ButtonType.warning]: {
    fillColor: theme.color.errorBackground,
    textColor: theme.color.lightText,
  },
  [ButtonType.warningNoFill]: {
    fillColor: theme.color.lightFill,
    textColor: theme.color.errorBackground,
  },
  [ButtonType.secondary]: secondaryProps,
  [ButtonType.secondaryWithBorder]: { ...secondaryProps, borderColor: theme.color.primary },
};

const Alert = ({
  topIcon,
  titleTranslationKey,
  titleTranslationText,
  subTitlePillTranslationKey,
  leadTranslationKey,
  leadTranslationText,
  subLeadTranslationKey,
  translationValues,
  buttons,
  onClose,
}: AlertOverlayProps) => {
  const isLargeScreen = useLargeScreenQuery();
  return (
    <TouchableOpacity
      onPress={() => {
        /* swallow touch! */
      }}
      activeOpacity={1}
    >
      <Box
        left={isLargeScreen ? 12 : 2.5}
        right={isLargeScreen ? 12 : 2.5}
        top={1.5}
        bottom={0.5}
        alignVertically={isLargeScreen ? 'center' : undefined}
        style={{
          backgroundColor: theme.color.lightFill,
          alignSelf: 'center',
          borderRadius: 13,
          marginHorizontal: 25,
          maxWidth: isLargeScreen ? 580 : 350,
          height: isLargeScreen ? 580 : undefined,
        }}
        alignHorizontally="center"
      >
        {topIcon && (
          <Box top={0.5} bottom={0.5}>
            <Icon resizeMode="contain" name={topIcon.name} style={topIcon.style} />
          </Box>
        )}
        <Box
          top={1}
          style={{
            width: '100%',
          }}
        >
          <DisplayTitleText
            style={{
              marginBottom: 10,
              textAlign: 'center',
            }}
            id={titleTranslationKey}
            values={translationValues}
          >
            {titleTranslationText}
          </DisplayTitleText>
          {subTitlePillTranslationKey && (
            <Box
              alignHorizontally="center"
              alignVertically="center"
              style={{
                marginBottom: 10,
              }}
            >
              <Box style={style.pillContainer}>
                <SmallSecondaryText
                  color={theme.color.primary}
                  style={{
                    textAlign: 'center',
                    marginHorizontal: 15,
                    marginVertical: 3,
                  }}
                  id={subTitlePillTranslationKey}
                  values={translationValues}
                />
              </Box>
            </Box>
          )}
          <BodyPrimaryText
            style={{
              marginTop: 10,
              marginBottom: 15,
              textAlign: 'center',
            }}
            id={leadTranslationKey}
            values={translationValues}
          >
            {leadTranslationText}
          </BodyPrimaryText>
          {subLeadTranslationKey && (
            <BodySecondaryText
              style={{
                marginTop: 0,
                marginBottom: 20,
                textAlign: 'center',
              }}
              id={subLeadTranslationKey}
              values={translationValues}
            />
          )}
          <Box
            top={0.3}
            bottom={1}
            alignVertically={isLargeScreen ? 'center' : undefined}
            alignHorizontally={isLargeScreen ? 'center' : undefined}
          >
            {buttons.map((button, index) => (
              <Button
                {...buttonStyleProps[button.type]}
                style={[
                  {
                    marginTop: 5,
                    paddingLeft: isLargeScreen ? 40 : undefined,
                    paddingRight: isLargeScreen ? 40 : undefined,
                  },
                  button.style,
                ]}
                textColor={
                  button.style && button.style.color
                    ? button.style.color
                    : buttonStyleProps[button.type]
                    ? buttonStyleProps[button.type].textColor
                    : undefined
                }
                key={index}
                translationKey={button.translationKey}
                values={translationValues}
                onPress={() => {
                  if (button.onPress) {
                    button.onPress();
                  }

                  if (!button.disableCloseOnPress) {
                    onClose();
                  }
                }}
                testId={`test.button.alert-overlay.${index + 1}`}
              >
                {button.text}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const AlertOverlay = ({
  onClose: onCloseComplete,
  titleTranslationKey,
  titleTranslationText,
  subTitlePillTranslationKey,
  leadTranslationKey,
  leadTranslationText,
  subLeadTranslationKey,
  translationValues,
  buttons,
  topIcon,
  appearFromTop,
}: AlertOverlayProps) => (
  <Overlay
    onCloseComplete={onCloseComplete}
    backgroundColor={theme.color.overlay}
    style={{
      justifyContent: 'center',
    }}
    appearFromTop={appearFromTop}
  >
    {({ onClose }: any) => (
      <Alert
        titleTranslationKey={titleTranslationKey}
        titleTranslationText={titleTranslationText}
        subTitlePillTranslationKey={subTitlePillTranslationKey}
        leadTranslationKey={leadTranslationKey}
        leadTranslationText={leadTranslationText}
        translationValues={translationValues}
        subLeadTranslationKey={subLeadTranslationKey}
        buttons={buttons}
        onClose={onClose}
        topIcon={topIcon}
      />
    )}
  </Overlay>
);

export { AlertOverlay, Alert, ButtonType };
