import React from 'react';
import { StyleSheet } from 'react-native';

import { Box, BodyPrimaryText, DisplayTitleText, Icon, Button, useLargeScreenQuery } from '../core';

const styles = StyleSheet.create({
  containerSmall: {
    backgroundColor: 'white',
    width: '80%',
    borderRadius: 10,
    paddingTop: 30,
    paddingLeft: 20,
    paddingBottom: 10,
    paddingRight: 20,
  },
  containerLarge: {
    paddingTop: 175,
    paddingLeft: 115,
    paddingRight: 115,
    backgroundColor: 'white',
    width: 580,
    height: 580,
    borderRadius: 12,
  },
  centered: {
    textAlign: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  actions: {
    justifyContent: 'space-between',
  },
});
type CommonProps = {
  icon?: 'happy' | 'disappointed';
  title: string;
  subtitle: string;
  ctaTranslationKey: string;
  dismissTranslationKey?: string;
  platform?: string;
  onCtaPress: () => any;
  onDismiss: () => void;
};

function CommonResultComponent({
  icon,
  title,
  subtitle,
  ctaTranslationKey,
  dismissTranslationKey = 'feedback-modal.dismiss',
  platform,
  onCtaPress,
  onDismiss,
}: CommonProps) {
  const isLargeScreen = useLargeScreenQuery();
  return (
    <Box
      alignHorizontally="center"
      style={isLargeScreen ? styles.containerLarge : styles.containerSmall}
    >
      {icon && <Icon name={icon} />}
      <Box alignHorizontally="center" alignVertically="center" top={2}>
        <DisplayTitleText style={styles.centered} id={title} />
        <Box bottom={1.5} />
        <BodyPrimaryText
          style={styles.centered}
          id={subtitle}
          values={{
            platform,
          }}
        />
      </Box>
      <Box style={[styles.fullWidth, styles.actions]} alignHorizontally="center" top={2}>
        <Button
          style={isLargeScreen ? undefined : styles.fullWidth}
          onPress={onCtaPress}
          translationKey={ctaTranslationKey}
        />
        <Button
          onPress={onDismiss}
          floating={false}
          type="secondary"
          style={isLargeScreen ? undefined : styles.fullWidth}
          translationKey={dismissTranslationKey}
        />
      </Box>
    </Box>
  );
}

export { CommonResultComponent };
