import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { CouponResult, Product } from '../../types/payment';
import { selectors as settingsSelectors } from '../../stores/modules/settings';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import initial from '../../stores/store/initial';
import {
  WithScreenSizes,
  TitleText,
  theme,
  ScrollView,
  Box,
  Section,
  withScreenSizes,
} from '../../components/core';

import { CouponInputContainer } from './coupon-input-container';

type Props = {
  product?: Product;
  existingCoupon?: CouponResult;
  year: number;
  accessToken: string;
  onCouponResult: (arg0: CouponResult) => void;
} & WithNavigation &
  WithScreenSizes;

const mapStateToProps = (state: typeof initial) => ({
  year: settingsSelectors.selectedYear(state),
  countryCode: settingsSelectors.selectedCountry(state),
  accessToken: userAuthSelectors.getAccessToken(state),
});

class VoucherInput extends React.Component<Props> {
  handleOnCouponResultUpdate = (couponResult?: CouponResult) => {
    if (couponResult) {
      this.props.onCouponResult(couponResult);
      setTimeout(() => this.props.navigation.goBack(), 1000);
    }
  };

  handleOnCouponCreateInit = () => {};

  render() {
    const { existingCoupon, product, isLargeScreen } = this.props;
    // for unknown reason CouponInput component needs a scrollview to be displayed correctly
    return (
      <ScrollView keyboardShouldPersistTaps="handled">
        <Section
          size="narrow"
          style={
            isLargeScreen
              ? {
                  marginTop: 70,
                }
              : undefined
          }
        >
          <Box top={2} right={2} left={2}>
            <TitleText
              id="payment.voucher"
              style={{
                color: theme.color.primaryText,
              }}
            />
            <CouponInputContainer
              onCouponResultUpdate={this.handleOnCouponResultUpdate}
              onCouponCreateInit={this.handleOnCouponCreateInit}
              product={product}
              existingCouponCode={existingCoupon}
              showReset={false}
            />
          </Box>
        </Section>
      </ScrollView>
    );
  }
}

export const VoucherInputContainer: any = compose(
  connect(mapStateToProps, null),
  withNavigation,
  withScreenSizes,
)(VoucherInput);
