import { requestTrackingPermission } from 'react-native-tracking-transparency';
import DeviceInfo from 'react-native-device-info';

import { isIOS } from 'src/utils/platform';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

export const transparencyTracker = async (doFinally?: () => void): Promise<void> => {
  let iOSTrackingStatus = await requestTrackingPermission();

  try {
    if (
      isIOS &&
      DeviceInfo.getSystemVersion() >= '14.5' &&
      iOSTrackingStatus === 'not-determined'
    ) {
      iOSTrackingStatus = await requestTrackingPermission();
    }
  } catch (error) {
    logger.error(error as Error, { message: 'Error requesting tracking permission' });
  } finally {
    const hasUserConsented =
      iOSTrackingStatus === 'authorized' || iOSTrackingStatus === 'unavailable';

    Analytics.log(AnalyticsEvent.consentNativeModalResponse, {
      consentName: 'attConsent',
      hasUserConsented,
      taxCountry: 'IT',
    });
    doFinally && doFinally();
  }
};
