import * as React from 'react';

import { RadioOptionButton, Toast } from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { AnswerComponentProps } from 'src/components/answers/types';
import { isMobile } from 'src/utils/platform';

import AnswerForm from '../components/AnswerForm';

export enum Options {
  YES_KEY = 'yes',
  NO_KEY = 'no',
}

const DEFAULT_OPTION_KEYS = {
  [Options.YES_KEY]: 'answers.yes',
  [Options.NO_KEY]: 'answers.no',
};

type OptionKeys = {
  [Options.YES_KEY]: string;
  [Options.NO_KEY]: string;
};

export type MessageBannerConfig = {
  selectedValue: boolean;
  message: string;
  linkText?: string;
  onLinkPress?: () => void;
};

export type Props = AnswerComponentProps & {
  optionKeys: OptionKeys;
  messageBannerConfig?: MessageBannerConfig;
  handlePressYes?: () => void;
};

export const YesNoContainer = ({
  question,
  onAnswer,
  optionKeys = DEFAULT_OPTION_KEYS,
  value,
  values,
  messageBannerConfig,
  handlePressYes,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<boolean | undefined>(value);
  const { getTranslationText } = useItalyIntl();

  const handleAnswer = (selection: boolean) => {
    setSelected(selection);
  };

  const handleContinue = () => {
    onAnswer(question, selected);
    if (handlePressYes && selected) handlePressYes();
  };

  const isSelected = (_value: boolean) => _value === selected;

  const form = {
    disabled: selected === undefined,
    onConfirm: handleContinue,
  };

  return (
    <AnswerForm noGutters {...form}>
      <RadioOptionButton
        option={{
          label: getTranslationText(optionKeys.yes, values),
          value: true,
        }}
        onPress={handleAnswer}
        isSelected={isSelected(true)}
        testID="test.box.answerCard.no"
      />
      <RadioOptionButton
        option={{
          label: getTranslationText(optionKeys.no, values),
          value: false,
        }}
        onPress={handleAnswer}
        isSelected={isSelected(false)}
        testID="test.box.answerCard.yes"
      />
      {messageBannerConfig &&
        messageBannerConfig.selectedValue === selected &&
        messageBannerConfig.message && (
          <Toast
            text={getTranslationText(messageBannerConfig.message)}
            linkText={
              messageBannerConfig.linkText && getTranslationText(messageBannerConfig.linkText)
            }
            onLinkPress={messageBannerConfig.onLinkPress && messageBannerConfig.onLinkPress}
            marginX={isMobile ? '20px' : 0}
            marginBottom={isMobile ? '20px' : 0}
            iconName="exclamationMark"
          />
        )}
    </AnswerForm>
  );
};
