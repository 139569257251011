import { useSelector } from 'react-redux';

import {
  ItalyCWBIDocumentsDownloadConfig,
  selectors as firebaseSelectors,
} from '../stores/modules/remote-config-firebase';

type CWBIDocumentsConfig = {
  CWBIDocumentsConfig: ItalyCWBIDocumentsDownloadConfig;
  completeRequestedDocsList: string[];
  previewRequestedDocsList: string[];
  asyncDownloadDocsTranslations: string[];
};

export const useCWBIDocumentsConfig = (): CWBIDocumentsConfig => {
  const CWBIDocumentsConfig = useSelector(firebaseSelectors.getItalyCWBIDocumentsDownloadConfig);
  const allRequestedDocsTranslations = [
    ...CWBIDocumentsConfig.syncDownload,
    ...CWBIDocumentsConfig.asyncDownload,
  ].map((doc) => doc.translation);

  const asyncDownloadDocsTranslations = [
    ...new Set(CWBIDocumentsConfig.asyncDownload.map((doc) => doc.translation)),
  ];
  const completeRequestedDocsList = [...new Set(allRequestedDocsTranslations)];

  const previewRequestedDocsList = completeRequestedDocsList.slice(0, 3);
  return {
    CWBIDocumentsConfig,
    completeRequestedDocsList,
    previewRequestedDocsList,
    asyncDownloadDocsTranslations,
  };
};
