import React from 'react';
import { G, Path } from 'react-native-svg';
import { Icon } from 'native-base';

import { Props } from './types';

/**
 * Renders an encircled arrow icon
 */

export const EncircledArrow: React.FC<Props> = ({
  size = '100%',
  iconColor = '#32C850',
}): JSX.Element => {
  return (
    <Icon viewBox="0 0 22 22" size={size}>
      <G fill="none">
        <Path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          stroke={iconColor}
          strokeWidth="2"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <Path
          d="M10.1683 7.0406C9.92331 7.2856 9.94781 7.6531 10.1683 7.89809L13.1328 10.6911H6.10136C5.75836 10.6911 5.51336 10.9606 5.51336 11.2791V12.063C5.51336 12.406 5.75836 12.651 6.10136 12.651H13.1328L10.1683 15.4685C9.94781 15.7135 9.94781 16.081 10.1683 16.326L10.7073 16.865C10.9523 17.0855 11.3198 17.0855 11.5403 16.865L16.3177 12.0875C16.5382 11.8671 16.5382 11.4996 16.3177 11.2546L11.5403 6.50161C11.3198 6.28111 10.9523 6.28111 10.7073 6.50161L10.1683 7.0406Z"
          fill={iconColor}
        />
      </G>
    </Icon>
  );
};
