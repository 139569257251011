import _ from 'lodash';
import { connect } from 'react-redux';

import { Place } from '../../types/geo';
import { State as RootState } from '../../stores/store/initial';

type Props = {
  render: (arg0: { places: Place[] }) => JSX.Element;
  places: Readonly<Place[]>;
  searchTerm?: string;
};
export function PreviousPlaces({ render, places, searchTerm = '' }: Props): JSX.Element {
  return render({
    places: places.filter((place) => {
      if (place.displayName) {
        return _.includes(place.displayName.toLowerCase(), searchTerm.toLowerCase());
      }

      return false;
    }),
  });
}

const mapStateToProps = (stores: RootState) => ({
  places: stores.places.places,
});

export default connect(mapStateToProps)(PreviousPlaces);
