import { IBoxProps } from 'native-base';

import { Variants as TextVariants } from '../../../theme/it/fonts/fontTypes';
import { TextProps } from '../Text/types';

export type StepIconType = 'success' | 'disabled' | 'progress' | 'warning' | 'start';

type StepDirection = 'horizontal' | 'vertical';

export enum STEP_STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  NOT_STARTED = 'notStarted',
}

export type NumberStylesProps = {
  numberBackgroundColor?: string;
  numberColor?: string;
  numberFont?: TextVariants;
};

export type StepCommonProps = {
  titleFont?: TextVariants;
  labelFont?: TextVariants;
  status?: STEP_STATUS;
  direction?: StepDirection;
  stepIcon?: StepIconType;
  active?: boolean;
  numberStyles?: NumberStylesProps;
  commonTitleStyles?: TextProps;
} & IBoxProps;

export type StepProps = {
  titleText?: string;
  labelText?: string;
  number?: number;
} & StepCommonProps;
