import { Documents } from '@taxfix/taxfix-sdk';
import { PrefillTypes } from '@taxfix/italy-sdk';

export enum OptimizationVersion {
  OptimizationPossible = 'optimizationPossible',
  NoOptimization = 'noOptimization',
}

export type OptimizationConfig = {
  description: string;
  order: number;
  comment?: string;
};

export type NavigationParams = {
  optimizationVersion: OptimizationVersion;
  optimizationResult: {
    total: number;
    optimizations: PrefillTypes.DownloadPrefillDocuments.Optimization[];
  };
  primaryButtonAction: () => Promise<void>;
  filteredPrefillDocuments: Documents.types.Document[];
  resultScreenExperiment?: boolean;
};
