import React from 'react';
import { useBreakpointValue } from 'native-base';

import { useItalyIntl } from 'src/_italy/_hooks';

import { OnFilesRejected, OnFilesSelected } from '../types';

import { UploadedContentArea } from './uploaded-content-area';

type Props = {
  imageName: string;
  onFilesSelected: OnFilesSelected;
  onFilesRejected: OnFilesRejected;
};

export const UploadWrapper: React.FC<Props> = ({ imageName, onFilesSelected, onFilesRejected }) => {
  const { getTranslationText } = useItalyIntl();

  const imageWidth = useBreakpointValue({ base: '153px', md: '214px' });
  const imageHeight = useBreakpointValue({ base: '200px', md: '267px' });
  const marginY = useBreakpointValue({ base: '18px', md: '40px' });

  const dropzoneTitle = getTranslationText('identity-upload.dropzone-title');

  const contentProps = {
    title: {
      value: dropzoneTitle,
      marginBottom: '23px',
    },
    image: {
      name: imageName,
      width: imageWidth,
      height: imageHeight,
    },
    marginY,
  };

  return (
    <UploadedContentArea
      contentProps={contentProps}
      onFilesSelected={onFilesSelected}
      onFilesRejected={onFilesRejected}
    />
  );
};
