import { useBreakpointValue } from 'native-base';
import * as React from 'react';
import { ScrollView } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import { Container, Footer, Image, NavButton, Text } from 'src/taxfix-components/src';

type SpidTimeoutProps = {
  getTranslationText: (id: string, values?: any) => string;
  onBackButtonPress: () => void;
  onPressPrimary: () => void;
  onPressSecondary: () => void;
};

const translationKeys = {
  title: 'it.spid-login.spid-timeout-screen.title',
  text: 'it.spid-login.spid-timeout-screen.text',
  continueButton: 'it.spid-login.spid-timeout-screen.continue-button.label',
  closeButton: 'it.spid-login.spid-timeout-screen.close-button.label',
  backButton: 'it.spid-login.spid-timeout-screen.back',
};

export const SpidTimeoutScreen: React.FC<SpidTimeoutProps> = ({
  getTranslationText,
  onBackButtonPress,
  onPressPrimary,
  onPressSecondary,
}) => {
  const isLargeScreen = useLargeScreenQuery();

  const footerStyles = useBreakpointValue({
    base: { borderColor: 'white' },
    md: { boderColor: 'greytones.graphicsLight' },
  });

  const primaryButtonProps = useBreakpointValue({ md: { maxWidth: '330px' } });
  const secondaryButtonProps = useBreakpointValue({
    md: { maxWidth: '145px' },
  });

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <NavButton
          onPress={onBackButtonPress}
          text={getTranslationText(translationKeys.backButton)}
        />
      )}

      <ScrollView contentContainerStyle={{ height: '100%' }}>
        <Container
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          flex={1}
          mx="32px"
        >
          <Image name="robot" width={[135, 178]} height={[149, 197]} />
          <Text
            value={getTranslationText(translationKeys.title)}
            variant={['titleSBold', 'titleMBold']}
            marginTop={['40px', '64px']}
            marginBottom="32px"
            color="greytones.textDefault"
            textAlign="center"
          />
          <Text
            value={getTranslationText(translationKeys.text)}
            variant={['titleXSBook', 'titleXSBook']}
            maxWidth={['290px', '472px']}
            marginBottom={['32px', '54px']}
            color="greytones.textLight"
            textAlign="center"
          />
        </Container>
      </ScrollView>
      <Footer
        px="32px"
        primaryButtonTitle={getTranslationText(translationKeys.continueButton)}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonTitle={getTranslationText(translationKeys.closeButton)}
        secondaryButtonProps={{ variant: 'tertiary', ...secondaryButtonProps }}
        onPressPrimary={onPressPrimary}
        onPressSecondary={onPressSecondary}
        {...footerStyles}
      />
    </>
  );
};
