import { CountryCodes } from '@taxfix/types';
import { useCallback, useEffect, useState } from 'react';
import Config from 'react-native-config';
import { useSelector } from 'react-redux';
import { Prefill, PrefillTypes as PrefillTypesSDK } from '@taxfix/italy-sdk';
import { Prefill as PrefillTypes } from '@taxfix/italy-types';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { getDefaultTaxYear } from 'src/services/country-and-year/utils';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { useQuizmasterLight } from 'src/_italy/_hooks';
import { logPrefillDocumentDownloadError } from 'src/_italy/iframe-spid-login/errors';
import { flagsQuestionIds, prefillQuestionIds } from 'src/common/constants-it';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';

const useFetchDownloadPrefillDocuments = (
  enabled: boolean,
): { downloadPrefillDocumentsStatus: string; isLoadingPrefillsDocCall: boolean } => {
  const [isLoadingPrefillsDocCall, setLoadingPrefillsDocCall] = useState<boolean>(false);
  const [downloadPrefillDocumentsStatus, setDownloadPrefillDocumentsStatus] = useState('');

  const email = useSelector(userAuthSelectors.getEmailAddress) || '';
  const { mockServerEnabled, whitelistedTaxIds } = useSelector(
    firebaseSelectors.getCwbiMockServerConfig,
  );

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const selectedYear = useSelector(settingsSelectors.selectedYear) || getDefaultTaxYear();
  const requestedDocumentsList = useSelector(firebaseSelectors.getItalyCWBIDocumentsDownloadConfig);

  const quizmasterLight = useQuizmasterLight([
    flagsQuestionIds.taxScenario,
    flagsQuestionIds.prefillDocumentsDownloaded,
    flagsQuestionIds.prefilledCreditResult,
    prefillQuestionIds.taxId,
    flagsQuestionIds.taxOptimizations,
    flagsQuestionIds.reasonWhyRefundIsZero,
    flagsQuestionIds.SPIDConsentXMLSigned,
  ]);
  const taxId = quizmasterLight[prefillQuestionIds.taxId]?.answer;

  const spidConsentSigned = quizmasterLight[flagsQuestionIds.SPIDConsentXMLSigned]?.answer;

  const hasPrefillDocuments = quizmasterLight[flagsQuestionIds.prefillDocumentsDownloaded].answer;
  const isMock = mockServerEnabled && whitelistedTaxIds.includes(taxId);

  const fetchDownloadPrefillDocuments = useCallback(async () => {
    setLoadingPrefillsDocCall(true);
    try {
      const response = await Prefill.downloadPrefillDocuments(Config.API_BASE_URL, accessToken, {
        documentMap: requestedDocumentsList,
        countryCode: CountryCodes.IT,
        taxId,
        email,
        taxYear: selectedYear,
        isMock,
      });
      if (response.status === PrefillTypes.PrefillResultStatus.Success) {
        const { taxScenario, estimatedRefund, optimizations, reasonWhyRefundIsZero } =
          response as PrefillTypesSDK.DownloadPrefillDocuments.SuccessResponse;
        quizmasterLight[flagsQuestionIds.prefillDocumentsDownloaded].saveAnswer(true);
        quizmasterLight[flagsQuestionIds.prefilledCreditResult].saveAnswer(String(estimatedRefund));
        quizmasterLight[flagsQuestionIds.taxScenario].saveAnswer(taxScenario);

        if (optimizations?.length) {
          quizmasterLight[flagsQuestionIds.taxOptimizations].saveAnswer(
            JSON.stringify(optimizations),
          );
        }

        if (reasonWhyRefundIsZero) {
          quizmasterLight[flagsQuestionIds.reasonWhyRefundIsZero].saveAnswer(reasonWhyRefundIsZero);
        }
      }
      setDownloadPrefillDocumentsStatus(response.status);
    } catch (error) {
      logPrefillDocumentDownloadError(logger, error);
      setDownloadPrefillDocumentsStatus(PrefillTypes.PrefillResultStatus.Failed);
    } finally {
      setLoadingPrefillsDocCall(false);
    }
  }, [accessToken, email, isMock, selectedYear, taxId, requestedDocumentsList]);

  useEffect(() => {
    if (enabled && spidConsentSigned && !hasPrefillDocuments) {
      fetchDownloadPrefillDocuments();
    }
  }, [fetchDownloadPrefillDocuments, hasPrefillDocuments, spidConsentSigned, enabled]);

  return { downloadPrefillDocumentsStatus, isLoadingPrefillsDocCall };
};

export { useFetchDownloadPrefillDocuments };
