import { TreeNode } from '@taxfix/quizmaster/dist/types';

import { Category } from '../stores-legacy/QuestionStore.types';

import Constants from './constants';

const noIntroCategoryFilter = (node: Category | TreeNode) =>
  node.id !== Constants.IntroductionCategoryNodeId;

export const withoutIntroduction = (list: Category[] = []): Category[] =>
  list.filter(noIntroCategoryFilter);

export const treeWithoutIntroduction = (tree: TreeNode) => ({
  ...tree,
  children: tree.children && tree.children.filter(noIntroCategoryFilter),
});

export const isIncomeCategoryDoneFirst = (
  incomeCategoryId: string | null | undefined,
  ignoredCategoryIds: string[],
  completedCategoryIds: string[],
): boolean => {
  const isNotInIgnoredCategories = (categoryId: string) => !ignoredCategoryIds.includes(categoryId);
  const isIncomeCategory = (categoryId: string) => categoryId === incomeCategoryId;

  return (
    completedCategoryIds.some(isIncomeCategory) &&
    completedCategoryIds.filter(isNotInIgnoredCategories).length === 0
  );
};

export default {
  withoutIntroduction,
  treeWithoutIntroduction,
};
