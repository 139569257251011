import React from 'react';
import { TouchableOpacity, StyleSheet, ImageStyle } from 'react-native';

import { testAccessibility, testID } from '../../../common/testID';
import { BodySecondaryText } from '../Text';
import { StyleProp, StyleTextProp } from '../../types';
import Icon from '../Icon';
import Box from '../Box';

type Props = {
  translationKey: string;
  onPress: () => void;
  iconKey: string;
  iconRotate?: number;
  testId?: string;
  textStyle?: StyleTextProp;
  iconStyle?: ImageStyle;
  style?: StyleProp;
  iconFirst?: boolean;
};

const styles = StyleSheet.create({
  iconLeft: {
    marginRight: 5,
  },
  iconRight: {
    marginLeft: 5,
  },
});

function IconTextButton({
  translationKey,
  onPress,
  iconKey,
  iconRotate,
  testId,
  textStyle,
  iconStyle,
  style,
  iconFirst,
}: Props) {
  return (
    <TouchableOpacity style={style} onPress={onPress} {...testAccessibility()} {...testID(testId)}>
      <Box direction="row" alignVertically="center">
        {iconFirst && (
          <Icon
            name={iconKey}
            rotate={iconRotate}
            style={StyleSheet.flatten([iconStyle, styles.iconLeft])}
          />
        )}
        <BodySecondaryText id={translationKey} style={textStyle} />
        {!iconFirst && (
          <Icon
            name={iconKey}
            rotate={iconRotate}
            style={StyleSheet.flatten([iconStyle, styles.iconRight])}
          />
        )}
      </Box>
    </TouchableOpacity>
  );
}

export default IconTextButton;
